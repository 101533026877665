import Image from 'next/legacy/image'
import { ReqImage, isReqImage } from 'types/util'
import { Cloudinary } from '@cloudinary/url-gen'
import { resizeCldImg } from '@/components/cloudinary/Cloudinary'

type ImageLoaderProps = {
  src: string
  width: number
  quality: number
}
type ContentfulImageFit = 'scale' | 'crop' | 'thumb' | 'fill' | 'pad'
type ContentfulImageFormat = 'jpg' | 'png' | 'webp' | 'gif' | 'avif' | 'svg'
type ContentfulImageFocus =
  | 'center'
  | 'top'
  | 'right'
  | 'left'
  | 'bottom'
  | 'top_right'
  | 'top_left'
  | 'bottom_right'
  | 'bottom_left'
  | 'face'
  | 'faces'

export interface ContentfulImageLoaderProps {
  src: string
  width?: number
  height?: number
  quality?: number
  fit?: ContentfulImageFit
  format?: ContentfulImageFormat
  focus?: ContentfulImageFocus
}

interface CompatibleCloudinaryImageLoaderProps {
  public_id: string
  width: number
  height?: number
  quality?: number
  format?: ContentfulImageFormat
  fit?: ContentfulImageFit
}

export const compatibleCloudinaryImageLoader = ({
  public_id,
  width,
  height,
  quality,
  format,
  fit,
}: CompatibleCloudinaryImageLoaderProps) => {
  const cld = new Cloudinary({
    cloud: {
      cloudName: 'foratravelweb',
    },
  })
  let cldImg = cld.image(public_id)
  if (width || height || fit) {
    cldImg = cldImg.resize(
      resizeCldImg({ width, height, method: fit || 'scale' })
    )
  } else {
    cldImg = cld.image(public_id).quality('auto')
  }
  cldImg = cldImg.format(format || 'webp')
  cldImg = cldImg.quality(quality || 75)
  return cldImg.toURL()
}

export const imageLoader = ({ src, width, quality }: ImageLoaderProps) => {
  return `${src}?w=${width}&q=${quality || 90}`
}

/**
 * Custom loader used to create a Contentful Image API resource url
 * @param props - {@link ContentfulImageLoaderProps}
 * @returns string - Contentful Image API  resource url
 *
 * @example
 * ```
 *  <Image
 *   loader={() => contentfulImageLoader({
 *     src: contentfulImage.url,
 *     width: 600,
 *     height: 600,
 *     quality: 75,
 *     fit: 'scale',
 *     format: 'jpg'
 *   })}
 *   src={contenfulImage.url}
 *   width={600}
 *   height={600}
 *  />
 *
 * // https://images.ctfassets.net/<space_id>/<asset_id>/<asset_id>/some-image.png?w=600&h=600&q=75&fit=scale&fm=jpg&fl=progressive
 * ```
 */
export const contentfulImageLoader = ({
  src,
  width,
  height,
  quality,
  fit,
  format,
  focus,
}: ContentfulImageLoaderProps) => {
  return `${src}?w=${width}${height ? `&h=${height}` : ''}${
    fit ? `&fit=${fit}` : ''
  }${focus ? `&f=${focus}` : ''}&q=${quality || 90}${
    format ? `&fm=${format}${format === 'jpg' ? '&fl=progressive' : ''}` : ''
  }`
}

interface ContentfulImageProps {
  asset: ReqImage
  preserveDimensions?: boolean
  preserveAspectRatio?: boolean
  fixedWidth?: number
  fixedHeight?: number
  fit?: ContentfulImageFit
  format?: ContentfulImageFormat
  alt?: string
  fullWidth?: boolean
}

export const ContentfulImage = ({
  asset,
  fixedWidth,
  fixedHeight,
  fit = 'fill',
  format,
  alt = '',
  fullWidth = false,
}: ContentfulImageProps) => {
  if (!isReqImage(asset)) {
    return null
  }
  // Set unassigned formats to webp
  // For safety SVGs and GIFs are reset content type regardless of coded input.
  if (asset.url.endsWith('.svg')) {
    format = 'svg'
  } else if (asset.url.endsWith('.gif')) {
    format = 'gif'
  } else if (!format) {
    format = 'webp'
  }

  const sizer = new ImageSizer({
    asset,
    fixedWidth,
    fixedHeight,
    fullWidth,
  })

  return (
    <Image
      src={asset.url}
      width={sizer.width}
      height={sizer.height}
      alt={alt}
      loader={() =>
        contentfulImageLoader({
          src: asset.url,
          width: sizer.width,
          height: sizer.height,
          quality: 75,
          fit: fit,
          format: format,
        })
      }
    />
  )
}

type ImageSizerProps = {
  asset: { width: number; height: number }
  fixedWidth?: number
  fixedHeight?: number
  fullWidth?: boolean
  expand?: boolean
}

export class ImageSizer {
  asset: { width: number; height: number }
  fixedWidth?: number
  fixedHeight?: number
  fullWidth?: boolean
  ratio: number
  fixed: boolean
  expand: boolean

  static screenWidth = 1900
  static columnWidth = 1200
  contextWidth: number
  static MAX_WIDTH = 4000

  constructor(props: ImageSizerProps) {
    this.asset = props.asset
    this.fullWidth = props.fullWidth
    this.ratio = this.asset.width / this.asset.height
    this.fixed = Boolean(props.fixedWidth || props.fixedHeight)
    this.expand = Boolean(props.expand)
    this.contextWidth = this.fullWidth
      ? ImageSizer.screenWidth
      : ImageSizer.columnWidth
    if (props.fixedWidth && !props.fixedHeight) {
      this.fixedHeight = props.fixedWidth / this.ratio
    } else if (props.fixedHeight && !props.fixedWidth) {
      this.fixedWidth = props.fixedHeight * this.ratio
    } else if (props.fixedWidth && props.fixedHeight) {
      this.fixedWidth = props.fixedWidth
      this.fixedHeight = props.fixedHeight
    }
    if (this.fixedWidth && this.fixedWidth > ImageSizer.MAX_WIDTH) {
      this.fixedHeight = ImageSizer.MAX_WIDTH / this.ratio
      this.fixedWidth = ImageSizer.MAX_WIDTH
    }
    if (this.fixedHeight && this.fixedHeight > ImageSizer.MAX_WIDTH) {
      this.fixedWidth = ImageSizer.MAX_WIDTH * this.ratio
      this.fixedHeight = ImageSizer.MAX_WIDTH
    }
    if (typeof this.fixedWidth === 'number') {
      this.fixedWidth = Math.floor(this.fixedWidth)
    }
    if (typeof this.fixedHeight === 'number') {
      this.fixedHeight = Math.floor(this.fixedHeight)
    }
  }

  get width() {
    if (this.fixedWidth) {
      return this.fixedWidth
    } else if (
      this.expand ||
      this.fullWidth ||
      this.asset.width > this.contextWidth
    ) {
      return this.contextWidth
    } else {
      return this.asset.width
    }
  }
  get height() {
    if (this.fixedHeight) {
      return this.fixedHeight
    } else if (
      this.expand ||
      this.fullWidth ||
      this.asset.width > this.contextWidth
    ) {
      return Math.floor(this.contextWidth / this.ratio)
    } else {
      return this.asset.height
    }
  }
}

export class ContentfulImageLoader {
  sizer: ImageSizer
  constructor(props: ImageSizerProps) {
    this.sizer = new ImageSizer(props)
  }
}
