export type Maybe<T> = T | null
export type InputMaybe<T> = Maybe<T>
export type Exact<T extends { [key: string]: unknown }> = {
  [K in keyof T]: T[K]
}
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & {
  [SubKey in K]?: Maybe<T[SubKey]>
}
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & {
  [SubKey in K]: Maybe<T[SubKey]>
}
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string
  String: string
  Boolean: boolean
  Int: number
  Float: number
  DateTime: any
  Dimension: any
  HexColor: any
  JSON: any
  Quality: any
}

/** Reviews of Fora by advisors [See type definition](https://app.contentful.com/spaces/9cxgygtadmvl/content_types/advisorReviews) */
export type AdvisorReviews = Entry &
  _Node & {
    __typename?: 'AdvisorReviews'
    _id: Scalars['ID']
    advisor?: Maybe<Advisors>
    contentfulMetadata: ContentfulMetadata
    eyebrowText?: Maybe<Scalars['String']>
    internalLabel?: Maybe<Scalars['String']>
    linkedFrom?: Maybe<AdvisorReviewsLinkingCollections>
    mediaAsset?: Maybe<Scalars['JSON']>
    mobileMediaAsset?: Maybe<Scalars['JSON']>
    mobilePreviewImage?: Maybe<Scalars['JSON']>
    review?: Maybe<Scalars['String']>
    sys: Sys
  }

/** Reviews of Fora by advisors [See type definition](https://app.contentful.com/spaces/9cxgygtadmvl/content_types/advisorReviews) */
export type AdvisorReviewsAdvisorArgs = {
  locale?: InputMaybe<Scalars['String']>
  preview?: InputMaybe<Scalars['Boolean']>
  where?: InputMaybe<AdvisorsFilter>
}

/** Reviews of Fora by advisors [See type definition](https://app.contentful.com/spaces/9cxgygtadmvl/content_types/advisorReviews) */
export type AdvisorReviewsEyebrowTextArgs = {
  locale?: InputMaybe<Scalars['String']>
}

/** Reviews of Fora by advisors [See type definition](https://app.contentful.com/spaces/9cxgygtadmvl/content_types/advisorReviews) */
export type AdvisorReviewsInternalLabelArgs = {
  locale?: InputMaybe<Scalars['String']>
}

/** Reviews of Fora by advisors [See type definition](https://app.contentful.com/spaces/9cxgygtadmvl/content_types/advisorReviews) */
export type AdvisorReviewsLinkedFromArgs = {
  allowedLocales?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
}

/** Reviews of Fora by advisors [See type definition](https://app.contentful.com/spaces/9cxgygtadmvl/content_types/advisorReviews) */
export type AdvisorReviewsMediaAssetArgs = {
  locale?: InputMaybe<Scalars['String']>
}

/** Reviews of Fora by advisors [See type definition](https://app.contentful.com/spaces/9cxgygtadmvl/content_types/advisorReviews) */
export type AdvisorReviewsMobileMediaAssetArgs = {
  locale?: InputMaybe<Scalars['String']>
}

/** Reviews of Fora by advisors [See type definition](https://app.contentful.com/spaces/9cxgygtadmvl/content_types/advisorReviews) */
export type AdvisorReviewsMobilePreviewImageArgs = {
  locale?: InputMaybe<Scalars['String']>
}

/** Reviews of Fora by advisors [See type definition](https://app.contentful.com/spaces/9cxgygtadmvl/content_types/advisorReviews) */
export type AdvisorReviewsReviewArgs = {
  locale?: InputMaybe<Scalars['String']>
}

export type AdvisorReviewsCollection = {
  __typename?: 'AdvisorReviewsCollection'
  items: Array<Maybe<AdvisorReviews>>
  limit: Scalars['Int']
  skip: Scalars['Int']
  total: Scalars['Int']
}

export type AdvisorReviewsFilter = {
  AND?: InputMaybe<Array<InputMaybe<AdvisorReviewsFilter>>>
  OR?: InputMaybe<Array<InputMaybe<AdvisorReviewsFilter>>>
  Review?: InputMaybe<Scalars['String']>
  Review_contains?: InputMaybe<Scalars['String']>
  Review_exists?: InputMaybe<Scalars['Boolean']>
  Review_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  Review_not?: InputMaybe<Scalars['String']>
  Review_not_contains?: InputMaybe<Scalars['String']>
  Review_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  advisor?: InputMaybe<CfAdvisorsNestedFilter>
  advisor_exists?: InputMaybe<Scalars['Boolean']>
  contentfulMetadata?: InputMaybe<ContentfulMetadataFilter>
  eyebrowText?: InputMaybe<Scalars['String']>
  eyebrowText_contains?: InputMaybe<Scalars['String']>
  eyebrowText_exists?: InputMaybe<Scalars['Boolean']>
  eyebrowText_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  eyebrowText_not?: InputMaybe<Scalars['String']>
  eyebrowText_not_contains?: InputMaybe<Scalars['String']>
  eyebrowText_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  internalLabel?: InputMaybe<Scalars['String']>
  internalLabel_contains?: InputMaybe<Scalars['String']>
  internalLabel_exists?: InputMaybe<Scalars['Boolean']>
  internalLabel_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  internalLabel_not?: InputMaybe<Scalars['String']>
  internalLabel_not_contains?: InputMaybe<Scalars['String']>
  internalLabel_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  mediaAsset_exists?: InputMaybe<Scalars['Boolean']>
  mobileMediaAsset_exists?: InputMaybe<Scalars['Boolean']>
  mobilePreviewImage_exists?: InputMaybe<Scalars['Boolean']>
  sys?: InputMaybe<SysFilter>
}

export type AdvisorReviewsLinkingCollections = {
  __typename?: 'AdvisorReviewsLinkingCollections'
  componentAdvisorReviewsCollection?: Maybe<ComponentAdvisorReviewsCollection>
  entryCollection?: Maybe<EntryCollection>
}

export type AdvisorReviewsLinkingCollectionsComponentAdvisorReviewsCollectionArgs =
  {
    limit?: InputMaybe<Scalars['Int']>
    locale?: InputMaybe<Scalars['String']>
    order?: InputMaybe<
      Array<
        InputMaybe<AdvisorReviewsLinkingCollectionsComponentAdvisorReviewsCollectionOrder>
      >
    >
    preview?: InputMaybe<Scalars['Boolean']>
    skip?: InputMaybe<Scalars['Int']>
  }

export type AdvisorReviewsLinkingCollectionsEntryCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>
  locale?: InputMaybe<Scalars['String']>
  preview?: InputMaybe<Scalars['Boolean']>
  skip?: InputMaybe<Scalars['Int']>
}

export enum AdvisorReviewsLinkingCollectionsComponentAdvisorReviewsCollectionOrder {
  InternalLabelAsc = 'internalLabel_ASC',
  InternalLabelDesc = 'internalLabel_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
}

export enum AdvisorReviewsOrder {
  ReviewAsc = 'Review_ASC',
  ReviewDesc = 'Review_DESC',
  EyebrowTextAsc = 'eyebrowText_ASC',
  EyebrowTextDesc = 'eyebrowText_DESC',
  InternalLabelAsc = 'internalLabel_ASC',
  InternalLabelDesc = 'internalLabel_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
}

/** Create and manage all Advisors content. [See type definition](https://app.contentful.com/spaces/9cxgygtadmvl/content_types/advisors) */
export type Advisors = Entry &
  _Node & {
    __typename?: 'Advisors'
    _id: Scalars['ID']
    aboutMeHomeAirport?: Maybe<AdvisorsAboutMeHomeAirport>
    aboutMeIAm?: Maybe<AdvisorsAboutMeIAm>
    advisorContactEnabled?: Maybe<Scalars['Boolean']>
    advisorVariant?: Maybe<Scalars['String']>
    basedIn?: Maybe<AdvisorsBasedIn>
    categoriesCollection?: Maybe<AdvisorsCategoriesCollection>
    cloudinaryImage?: Maybe<Scalars['JSON']>
    contentfulMetadata: ContentfulMetadata
    emailAddress?: Maybe<Scalars['String']>
    expertise?: Maybe<Array<Maybe<Scalars['String']>>>
    facebookUrl?: Maybe<Scalars['String']>
    firstName?: Maybe<Scalars['String']>
    foraAdvisorHotels?: Maybe<Scalars['String']>
    foraAdvisorId?: Maybe<Scalars['String']>
    hotelsCollection?: Maybe<AdvisorsHotelsCollection>
    image?: Maybe<Asset>
    imageCarouselCollection?: Maybe<AdvisorsImageCarouselCollection>
    instagramUrl?: Maybe<Scalars['String']>
    internalComments?: Maybe<Scalars['String']>
    languagesSpoken?: Maybe<AdvisorsLanguagesSpoken>
    lastName?: Maybe<Scalars['String']>
    linkedFrom?: Maybe<AdvisorsLinkingCollections>
    linkedinUrl?: Maybe<Scalars['String']>
    mailChimpForm?: Maybe<Scalars['String']>
    minimumBooking?: Maybe<Scalars['Int']>
    myTravelStyleCantLiveWithout?: Maybe<AdvisorsMyTravelStyleCantLiveWithout>
    myTravelStyleDreamingOf?: Maybe<AdvisorsMyTravelStyleDreamingOf>
    myTravelStyleQuote?: Maybe<AdvisorsMyTravelStyleQuote>
    myTravelStyleRecentlyReturnedFrom?: Maybe<AdvisorsMyTravelStyleRecentlyReturnedFrom>
    myTravelStyleTwoTruthsOneLie?: Maybe<AdvisorsMyTravelStyleTwoTruthsOneLie>
    pinterestUrl?: Maybe<Scalars['String']>
    preferredGuidesCollection?: Maybe<AdvisorsPreferredGuidesCollection>
    pronouns?: Maybe<Scalars['String']>
    shortDescription?: Maybe<Scalars['String']>
    showOnAdvisorsPage?: Maybe<Scalars['Boolean']>
    showOnHomepage?: Maybe<Scalars['Boolean']>
    showOnOtherLandingPages?: Maybe<Scalars['Boolean']>
    slug?: Maybe<Scalars['String']>
    sys: Sys
    tier?: Maybe<Scalars['String']>
    tikTokUrl?: Maybe<Scalars['String']>
    title?: Maybe<Scalars['String']>
    twitterUrl?: Maybe<Scalars['String']>
    websiteUrl?: Maybe<Scalars['String']>
    youtubeUrl?: Maybe<Scalars['String']>
  }

/** Create and manage all Advisors content. [See type definition](https://app.contentful.com/spaces/9cxgygtadmvl/content_types/advisors) */
export type AdvisorsAboutMeHomeAirportArgs = {
  locale?: InputMaybe<Scalars['String']>
}

/** Create and manage all Advisors content. [See type definition](https://app.contentful.com/spaces/9cxgygtadmvl/content_types/advisors) */
export type AdvisorsAboutMeIAmArgs = {
  locale?: InputMaybe<Scalars['String']>
}

/** Create and manage all Advisors content. [See type definition](https://app.contentful.com/spaces/9cxgygtadmvl/content_types/advisors) */
export type AdvisorsAdvisorContactEnabledArgs = {
  locale?: InputMaybe<Scalars['String']>
}

/** Create and manage all Advisors content. [See type definition](https://app.contentful.com/spaces/9cxgygtadmvl/content_types/advisors) */
export type AdvisorsAdvisorVariantArgs = {
  locale?: InputMaybe<Scalars['String']>
}

/** Create and manage all Advisors content. [See type definition](https://app.contentful.com/spaces/9cxgygtadmvl/content_types/advisors) */
export type AdvisorsBasedInArgs = {
  locale?: InputMaybe<Scalars['String']>
}

/** Create and manage all Advisors content. [See type definition](https://app.contentful.com/spaces/9cxgygtadmvl/content_types/advisors) */
export type AdvisorsCategoriesCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>
  locale?: InputMaybe<Scalars['String']>
  preview?: InputMaybe<Scalars['Boolean']>
  skip?: InputMaybe<Scalars['Int']>
  where?: InputMaybe<AdvisorsCategoriesFilter>
}

/** Create and manage all Advisors content. [See type definition](https://app.contentful.com/spaces/9cxgygtadmvl/content_types/advisors) */
export type AdvisorsCloudinaryImageArgs = {
  locale?: InputMaybe<Scalars['String']>
}

/** Create and manage all Advisors content. [See type definition](https://app.contentful.com/spaces/9cxgygtadmvl/content_types/advisors) */
export type AdvisorsEmailAddressArgs = {
  locale?: InputMaybe<Scalars['String']>
}

/** Create and manage all Advisors content. [See type definition](https://app.contentful.com/spaces/9cxgygtadmvl/content_types/advisors) */
export type AdvisorsExpertiseArgs = {
  locale?: InputMaybe<Scalars['String']>
}

/** Create and manage all Advisors content. [See type definition](https://app.contentful.com/spaces/9cxgygtadmvl/content_types/advisors) */
export type AdvisorsFacebookUrlArgs = {
  locale?: InputMaybe<Scalars['String']>
}

/** Create and manage all Advisors content. [See type definition](https://app.contentful.com/spaces/9cxgygtadmvl/content_types/advisors) */
export type AdvisorsFirstNameArgs = {
  locale?: InputMaybe<Scalars['String']>
}

/** Create and manage all Advisors content. [See type definition](https://app.contentful.com/spaces/9cxgygtadmvl/content_types/advisors) */
export type AdvisorsForaAdvisorHotelsArgs = {
  locale?: InputMaybe<Scalars['String']>
}

/** Create and manage all Advisors content. [See type definition](https://app.contentful.com/spaces/9cxgygtadmvl/content_types/advisors) */
export type AdvisorsForaAdvisorIdArgs = {
  locale?: InputMaybe<Scalars['String']>
}

/** Create and manage all Advisors content. [See type definition](https://app.contentful.com/spaces/9cxgygtadmvl/content_types/advisors) */
export type AdvisorsHotelsCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>
  locale?: InputMaybe<Scalars['String']>
  order?: InputMaybe<Array<InputMaybe<AdvisorsHotelsCollectionOrder>>>
  preview?: InputMaybe<Scalars['Boolean']>
  skip?: InputMaybe<Scalars['Int']>
  where?: InputMaybe<HotelsFilter>
}

/** Create and manage all Advisors content. [See type definition](https://app.contentful.com/spaces/9cxgygtadmvl/content_types/advisors) */
export type AdvisorsImageArgs = {
  locale?: InputMaybe<Scalars['String']>
  preview?: InputMaybe<Scalars['Boolean']>
}

/** Create and manage all Advisors content. [See type definition](https://app.contentful.com/spaces/9cxgygtadmvl/content_types/advisors) */
export type AdvisorsImageCarouselCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>
  locale?: InputMaybe<Scalars['String']>
  order?: InputMaybe<Array<InputMaybe<AdvisorsImageCarouselCollectionOrder>>>
  preview?: InputMaybe<Scalars['Boolean']>
  skip?: InputMaybe<Scalars['Int']>
  where?: InputMaybe<ComponentImageFilter>
}

/** Create and manage all Advisors content. [See type definition](https://app.contentful.com/spaces/9cxgygtadmvl/content_types/advisors) */
export type AdvisorsInstagramUrlArgs = {
  locale?: InputMaybe<Scalars['String']>
}

/** Create and manage all Advisors content. [See type definition](https://app.contentful.com/spaces/9cxgygtadmvl/content_types/advisors) */
export type AdvisorsInternalCommentsArgs = {
  locale?: InputMaybe<Scalars['String']>
}

/** Create and manage all Advisors content. [See type definition](https://app.contentful.com/spaces/9cxgygtadmvl/content_types/advisors) */
export type AdvisorsLanguagesSpokenArgs = {
  locale?: InputMaybe<Scalars['String']>
}

/** Create and manage all Advisors content. [See type definition](https://app.contentful.com/spaces/9cxgygtadmvl/content_types/advisors) */
export type AdvisorsLastNameArgs = {
  locale?: InputMaybe<Scalars['String']>
}

/** Create and manage all Advisors content. [See type definition](https://app.contentful.com/spaces/9cxgygtadmvl/content_types/advisors) */
export type AdvisorsLinkedFromArgs = {
  allowedLocales?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
}

/** Create and manage all Advisors content. [See type definition](https://app.contentful.com/spaces/9cxgygtadmvl/content_types/advisors) */
export type AdvisorsLinkedinUrlArgs = {
  locale?: InputMaybe<Scalars['String']>
}

/** Create and manage all Advisors content. [See type definition](https://app.contentful.com/spaces/9cxgygtadmvl/content_types/advisors) */
export type AdvisorsMailChimpFormArgs = {
  locale?: InputMaybe<Scalars['String']>
}

/** Create and manage all Advisors content. [See type definition](https://app.contentful.com/spaces/9cxgygtadmvl/content_types/advisors) */
export type AdvisorsMinimumBookingArgs = {
  locale?: InputMaybe<Scalars['String']>
}

/** Create and manage all Advisors content. [See type definition](https://app.contentful.com/spaces/9cxgygtadmvl/content_types/advisors) */
export type AdvisorsMyTravelStyleCantLiveWithoutArgs = {
  locale?: InputMaybe<Scalars['String']>
}

/** Create and manage all Advisors content. [See type definition](https://app.contentful.com/spaces/9cxgygtadmvl/content_types/advisors) */
export type AdvisorsMyTravelStyleDreamingOfArgs = {
  locale?: InputMaybe<Scalars['String']>
}

/** Create and manage all Advisors content. [See type definition](https://app.contentful.com/spaces/9cxgygtadmvl/content_types/advisors) */
export type AdvisorsMyTravelStyleQuoteArgs = {
  locale?: InputMaybe<Scalars['String']>
}

/** Create and manage all Advisors content. [See type definition](https://app.contentful.com/spaces/9cxgygtadmvl/content_types/advisors) */
export type AdvisorsMyTravelStyleRecentlyReturnedFromArgs = {
  locale?: InputMaybe<Scalars['String']>
}

/** Create and manage all Advisors content. [See type definition](https://app.contentful.com/spaces/9cxgygtadmvl/content_types/advisors) */
export type AdvisorsMyTravelStyleTwoTruthsOneLieArgs = {
  locale?: InputMaybe<Scalars['String']>
}

/** Create and manage all Advisors content. [See type definition](https://app.contentful.com/spaces/9cxgygtadmvl/content_types/advisors) */
export type AdvisorsPinterestUrlArgs = {
  locale?: InputMaybe<Scalars['String']>
}

/** Create and manage all Advisors content. [See type definition](https://app.contentful.com/spaces/9cxgygtadmvl/content_types/advisors) */
export type AdvisorsPreferredGuidesCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>
  locale?: InputMaybe<Scalars['String']>
  preview?: InputMaybe<Scalars['Boolean']>
  skip?: InputMaybe<Scalars['Int']>
  where?: InputMaybe<AdvisorsPreferredGuidesFilter>
}

/** Create and manage all Advisors content. [See type definition](https://app.contentful.com/spaces/9cxgygtadmvl/content_types/advisors) */
export type AdvisorsPronounsArgs = {
  locale?: InputMaybe<Scalars['String']>
}

/** Create and manage all Advisors content. [See type definition](https://app.contentful.com/spaces/9cxgygtadmvl/content_types/advisors) */
export type AdvisorsShortDescriptionArgs = {
  locale?: InputMaybe<Scalars['String']>
}

/** Create and manage all Advisors content. [See type definition](https://app.contentful.com/spaces/9cxgygtadmvl/content_types/advisors) */
export type AdvisorsShowOnAdvisorsPageArgs = {
  locale?: InputMaybe<Scalars['String']>
}

/** Create and manage all Advisors content. [See type definition](https://app.contentful.com/spaces/9cxgygtadmvl/content_types/advisors) */
export type AdvisorsShowOnHomepageArgs = {
  locale?: InputMaybe<Scalars['String']>
}

/** Create and manage all Advisors content. [See type definition](https://app.contentful.com/spaces/9cxgygtadmvl/content_types/advisors) */
export type AdvisorsShowOnOtherLandingPagesArgs = {
  locale?: InputMaybe<Scalars['String']>
}

/** Create and manage all Advisors content. [See type definition](https://app.contentful.com/spaces/9cxgygtadmvl/content_types/advisors) */
export type AdvisorsSlugArgs = {
  locale?: InputMaybe<Scalars['String']>
}

/** Create and manage all Advisors content. [See type definition](https://app.contentful.com/spaces/9cxgygtadmvl/content_types/advisors) */
export type AdvisorsTierArgs = {
  locale?: InputMaybe<Scalars['String']>
}

/** Create and manage all Advisors content. [See type definition](https://app.contentful.com/spaces/9cxgygtadmvl/content_types/advisors) */
export type AdvisorsTikTokUrlArgs = {
  locale?: InputMaybe<Scalars['String']>
}

/** Create and manage all Advisors content. [See type definition](https://app.contentful.com/spaces/9cxgygtadmvl/content_types/advisors) */
export type AdvisorsTitleArgs = {
  locale?: InputMaybe<Scalars['String']>
}

/** Create and manage all Advisors content. [See type definition](https://app.contentful.com/spaces/9cxgygtadmvl/content_types/advisors) */
export type AdvisorsTwitterUrlArgs = {
  locale?: InputMaybe<Scalars['String']>
}

/** Create and manage all Advisors content. [See type definition](https://app.contentful.com/spaces/9cxgygtadmvl/content_types/advisors) */
export type AdvisorsWebsiteUrlArgs = {
  locale?: InputMaybe<Scalars['String']>
}

/** Create and manage all Advisors content. [See type definition](https://app.contentful.com/spaces/9cxgygtadmvl/content_types/advisors) */
export type AdvisorsYoutubeUrlArgs = {
  locale?: InputMaybe<Scalars['String']>
}

export type AdvisorsAboutMeHomeAirport = {
  __typename?: 'AdvisorsAboutMeHomeAirport'
  json: Scalars['JSON']
  links: AdvisorsAboutMeHomeAirportLinks
}

export type AdvisorsAboutMeHomeAirportAssets = {
  __typename?: 'AdvisorsAboutMeHomeAirportAssets'
  block: Array<Maybe<Asset>>
  hyperlink: Array<Maybe<Asset>>
}

export type AdvisorsAboutMeHomeAirportEntries = {
  __typename?: 'AdvisorsAboutMeHomeAirportEntries'
  block: Array<Maybe<Entry>>
  hyperlink: Array<Maybe<Entry>>
  inline: Array<Maybe<Entry>>
}

export type AdvisorsAboutMeHomeAirportLinks = {
  __typename?: 'AdvisorsAboutMeHomeAirportLinks'
  assets: AdvisorsAboutMeHomeAirportAssets
  entries: AdvisorsAboutMeHomeAirportEntries
  resources: AdvisorsAboutMeHomeAirportResources
}

export type AdvisorsAboutMeHomeAirportResources = {
  __typename?: 'AdvisorsAboutMeHomeAirportResources'
  block: Array<AdvisorsAboutMeHomeAirportResourcesBlock>
  hyperlink: Array<AdvisorsAboutMeHomeAirportResourcesHyperlink>
  inline: Array<AdvisorsAboutMeHomeAirportResourcesInline>
}

export type AdvisorsAboutMeHomeAirportResourcesBlock = ResourceLink & {
  __typename?: 'AdvisorsAboutMeHomeAirportResourcesBlock'
  sys: ResourceSys
}

export type AdvisorsAboutMeHomeAirportResourcesHyperlink = ResourceLink & {
  __typename?: 'AdvisorsAboutMeHomeAirportResourcesHyperlink'
  sys: ResourceSys
}

export type AdvisorsAboutMeHomeAirportResourcesInline = ResourceLink & {
  __typename?: 'AdvisorsAboutMeHomeAirportResourcesInline'
  sys: ResourceSys
}

export type AdvisorsAboutMeIAm = {
  __typename?: 'AdvisorsAboutMeIAm'
  json: Scalars['JSON']
  links: AdvisorsAboutMeIAmLinks
}

export type AdvisorsAboutMeIAmAssets = {
  __typename?: 'AdvisorsAboutMeIAmAssets'
  block: Array<Maybe<Asset>>
  hyperlink: Array<Maybe<Asset>>
}

export type AdvisorsAboutMeIAmEntries = {
  __typename?: 'AdvisorsAboutMeIAmEntries'
  block: Array<Maybe<Entry>>
  hyperlink: Array<Maybe<Entry>>
  inline: Array<Maybe<Entry>>
}

export type AdvisorsAboutMeIAmLinks = {
  __typename?: 'AdvisorsAboutMeIAmLinks'
  assets: AdvisorsAboutMeIAmAssets
  entries: AdvisorsAboutMeIAmEntries
  resources: AdvisorsAboutMeIAmResources
}

export type AdvisorsAboutMeIAmResources = {
  __typename?: 'AdvisorsAboutMeIAmResources'
  block: Array<AdvisorsAboutMeIAmResourcesBlock>
  hyperlink: Array<AdvisorsAboutMeIAmResourcesHyperlink>
  inline: Array<AdvisorsAboutMeIAmResourcesInline>
}

export type AdvisorsAboutMeIAmResourcesBlock = ResourceLink & {
  __typename?: 'AdvisorsAboutMeIAmResourcesBlock'
  sys: ResourceSys
}

export type AdvisorsAboutMeIAmResourcesHyperlink = ResourceLink & {
  __typename?: 'AdvisorsAboutMeIAmResourcesHyperlink'
  sys: ResourceSys
}

export type AdvisorsAboutMeIAmResourcesInline = ResourceLink & {
  __typename?: 'AdvisorsAboutMeIAmResourcesInline'
  sys: ResourceSys
}

export type AdvisorsBasedIn = {
  __typename?: 'AdvisorsBasedIn'
  json: Scalars['JSON']
  links: AdvisorsBasedInLinks
}

export type AdvisorsBasedInAssets = {
  __typename?: 'AdvisorsBasedInAssets'
  block: Array<Maybe<Asset>>
  hyperlink: Array<Maybe<Asset>>
}

export type AdvisorsBasedInEntries = {
  __typename?: 'AdvisorsBasedInEntries'
  block: Array<Maybe<Entry>>
  hyperlink: Array<Maybe<Entry>>
  inline: Array<Maybe<Entry>>
}

export type AdvisorsBasedInLinks = {
  __typename?: 'AdvisorsBasedInLinks'
  assets: AdvisorsBasedInAssets
  entries: AdvisorsBasedInEntries
  resources: AdvisorsBasedInResources
}

export type AdvisorsBasedInResources = {
  __typename?: 'AdvisorsBasedInResources'
  block: Array<AdvisorsBasedInResourcesBlock>
  hyperlink: Array<AdvisorsBasedInResourcesHyperlink>
  inline: Array<AdvisorsBasedInResourcesInline>
}

export type AdvisorsBasedInResourcesBlock = ResourceLink & {
  __typename?: 'AdvisorsBasedInResourcesBlock'
  sys: ResourceSys
}

export type AdvisorsBasedInResourcesHyperlink = ResourceLink & {
  __typename?: 'AdvisorsBasedInResourcesHyperlink'
  sys: ResourceSys
}

export type AdvisorsBasedInResourcesInline = ResourceLink & {
  __typename?: 'AdvisorsBasedInResourcesInline'
  sys: ResourceSys
}

export type AdvisorsCategoriesCollection = {
  __typename?: 'AdvisorsCategoriesCollection'
  items: Array<Maybe<AdvisorsCategoriesItem>>
  limit: Scalars['Int']
  skip: Scalars['Int']
  total: Scalars['Int']
}

export type AdvisorsCategoriesFilter = {
  AND?: InputMaybe<Array<InputMaybe<AdvisorsCategoriesFilter>>>
  OR?: InputMaybe<Array<InputMaybe<AdvisorsCategoriesFilter>>>
  contentfulMetadata?: InputMaybe<ContentfulMetadataFilter>
  featuredImage_exists?: InputMaybe<Scalars['Boolean']>
  generateLandingPage?: InputMaybe<Scalars['Boolean']>
  generateLandingPage_exists?: InputMaybe<Scalars['Boolean']>
  generateLandingPage_not?: InputMaybe<Scalars['Boolean']>
  metaContent_exists?: InputMaybe<Scalars['Boolean']>
  questionsCollection_exists?: InputMaybe<Scalars['Boolean']>
  slug?: InputMaybe<Scalars['String']>
  slug_contains?: InputMaybe<Scalars['String']>
  slug_exists?: InputMaybe<Scalars['Boolean']>
  slug_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  slug_not?: InputMaybe<Scalars['String']>
  slug_not_contains?: InputMaybe<Scalars['String']>
  slug_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  sys?: InputMaybe<SysFilter>
  teaserCopy_contains?: InputMaybe<Scalars['String']>
  teaserCopy_exists?: InputMaybe<Scalars['Boolean']>
  teaserCopy_not_contains?: InputMaybe<Scalars['String']>
  title?: InputMaybe<Scalars['String']>
  title_contains?: InputMaybe<Scalars['String']>
  title_exists?: InputMaybe<Scalars['Boolean']>
  title_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  title_not?: InputMaybe<Scalars['String']>
  title_not_contains?: InputMaybe<Scalars['String']>
  title_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
}

export type AdvisorsCategoriesItem =
  | DestinationsSubcategories
  | TravelBySubcategories

export type AdvisorsCollection = {
  __typename?: 'AdvisorsCollection'
  items: Array<Maybe<Advisors>>
  limit: Scalars['Int']
  skip: Scalars['Int']
  total: Scalars['Int']
}

export type AdvisorsFilter = {
  AND?: InputMaybe<Array<InputMaybe<AdvisorsFilter>>>
  OR?: InputMaybe<Array<InputMaybe<AdvisorsFilter>>>
  aboutMeHomeAirport_contains?: InputMaybe<Scalars['String']>
  aboutMeHomeAirport_exists?: InputMaybe<Scalars['Boolean']>
  aboutMeHomeAirport_not_contains?: InputMaybe<Scalars['String']>
  aboutMeIAm_contains?: InputMaybe<Scalars['String']>
  aboutMeIAm_exists?: InputMaybe<Scalars['Boolean']>
  aboutMeIAm_not_contains?: InputMaybe<Scalars['String']>
  advisorContactEnabled?: InputMaybe<Scalars['Boolean']>
  advisorContactEnabled_exists?: InputMaybe<Scalars['Boolean']>
  advisorContactEnabled_not?: InputMaybe<Scalars['Boolean']>
  advisorVariant?: InputMaybe<Scalars['String']>
  advisorVariant_contains?: InputMaybe<Scalars['String']>
  advisorVariant_exists?: InputMaybe<Scalars['Boolean']>
  advisorVariant_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  advisorVariant_not?: InputMaybe<Scalars['String']>
  advisorVariant_not_contains?: InputMaybe<Scalars['String']>
  advisorVariant_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  basedIn_contains?: InputMaybe<Scalars['String']>
  basedIn_exists?: InputMaybe<Scalars['Boolean']>
  basedIn_not_contains?: InputMaybe<Scalars['String']>
  categories?: InputMaybe<CfcategoriesMultiTypeNestedFilter>
  categoriesCollection_exists?: InputMaybe<Scalars['Boolean']>
  cloudinaryImage_exists?: InputMaybe<Scalars['Boolean']>
  contentfulMetadata?: InputMaybe<ContentfulMetadataFilter>
  emailAddress?: InputMaybe<Scalars['String']>
  emailAddress_contains?: InputMaybe<Scalars['String']>
  emailAddress_exists?: InputMaybe<Scalars['Boolean']>
  emailAddress_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  emailAddress_not?: InputMaybe<Scalars['String']>
  emailAddress_not_contains?: InputMaybe<Scalars['String']>
  emailAddress_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  expertise_contains_all?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  expertise_contains_none?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  expertise_contains_some?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  expertise_exists?: InputMaybe<Scalars['Boolean']>
  facebookUrl?: InputMaybe<Scalars['String']>
  facebookUrl_contains?: InputMaybe<Scalars['String']>
  facebookUrl_exists?: InputMaybe<Scalars['Boolean']>
  facebookUrl_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  facebookUrl_not?: InputMaybe<Scalars['String']>
  facebookUrl_not_contains?: InputMaybe<Scalars['String']>
  facebookUrl_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  firstName?: InputMaybe<Scalars['String']>
  firstName_contains?: InputMaybe<Scalars['String']>
  firstName_exists?: InputMaybe<Scalars['Boolean']>
  firstName_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  firstName_not?: InputMaybe<Scalars['String']>
  firstName_not_contains?: InputMaybe<Scalars['String']>
  firstName_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  foraAdvisorHotels?: InputMaybe<Scalars['String']>
  foraAdvisorHotels_contains?: InputMaybe<Scalars['String']>
  foraAdvisorHotels_exists?: InputMaybe<Scalars['Boolean']>
  foraAdvisorHotels_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  foraAdvisorHotels_not?: InputMaybe<Scalars['String']>
  foraAdvisorHotels_not_contains?: InputMaybe<Scalars['String']>
  foraAdvisorHotels_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  foraAdvisorId?: InputMaybe<Scalars['String']>
  foraAdvisorId_contains?: InputMaybe<Scalars['String']>
  foraAdvisorId_exists?: InputMaybe<Scalars['Boolean']>
  foraAdvisorId_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  foraAdvisorId_not?: InputMaybe<Scalars['String']>
  foraAdvisorId_not_contains?: InputMaybe<Scalars['String']>
  foraAdvisorId_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  hotels?: InputMaybe<CfHotelsNestedFilter>
  hotelsCollection_exists?: InputMaybe<Scalars['Boolean']>
  imageCarousel?: InputMaybe<CfComponentImageNestedFilter>
  imageCarouselCollection_exists?: InputMaybe<Scalars['Boolean']>
  image_exists?: InputMaybe<Scalars['Boolean']>
  instagramUrl?: InputMaybe<Scalars['String']>
  instagramUrl_contains?: InputMaybe<Scalars['String']>
  instagramUrl_exists?: InputMaybe<Scalars['Boolean']>
  instagramUrl_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  instagramUrl_not?: InputMaybe<Scalars['String']>
  instagramUrl_not_contains?: InputMaybe<Scalars['String']>
  instagramUrl_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  internalComments?: InputMaybe<Scalars['String']>
  internalComments_contains?: InputMaybe<Scalars['String']>
  internalComments_exists?: InputMaybe<Scalars['Boolean']>
  internalComments_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  internalComments_not?: InputMaybe<Scalars['String']>
  internalComments_not_contains?: InputMaybe<Scalars['String']>
  internalComments_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  languagesSpoken_contains?: InputMaybe<Scalars['String']>
  languagesSpoken_exists?: InputMaybe<Scalars['Boolean']>
  languagesSpoken_not_contains?: InputMaybe<Scalars['String']>
  lastName?: InputMaybe<Scalars['String']>
  lastName_contains?: InputMaybe<Scalars['String']>
  lastName_exists?: InputMaybe<Scalars['Boolean']>
  lastName_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  lastName_not?: InputMaybe<Scalars['String']>
  lastName_not_contains?: InputMaybe<Scalars['String']>
  lastName_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  linkedinUrl?: InputMaybe<Scalars['String']>
  linkedinUrl_contains?: InputMaybe<Scalars['String']>
  linkedinUrl_exists?: InputMaybe<Scalars['Boolean']>
  linkedinUrl_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  linkedinUrl_not?: InputMaybe<Scalars['String']>
  linkedinUrl_not_contains?: InputMaybe<Scalars['String']>
  linkedinUrl_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  mailChimpForm?: InputMaybe<Scalars['String']>
  mailChimpForm_contains?: InputMaybe<Scalars['String']>
  mailChimpForm_exists?: InputMaybe<Scalars['Boolean']>
  mailChimpForm_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  mailChimpForm_not?: InputMaybe<Scalars['String']>
  mailChimpForm_not_contains?: InputMaybe<Scalars['String']>
  mailChimpForm_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  minimumBooking?: InputMaybe<Scalars['Int']>
  minimumBooking_exists?: InputMaybe<Scalars['Boolean']>
  minimumBooking_gt?: InputMaybe<Scalars['Int']>
  minimumBooking_gte?: InputMaybe<Scalars['Int']>
  minimumBooking_in?: InputMaybe<Array<InputMaybe<Scalars['Int']>>>
  minimumBooking_lt?: InputMaybe<Scalars['Int']>
  minimumBooking_lte?: InputMaybe<Scalars['Int']>
  minimumBooking_not?: InputMaybe<Scalars['Int']>
  minimumBooking_not_in?: InputMaybe<Array<InputMaybe<Scalars['Int']>>>
  myTravelStyleCantLiveWithout_contains?: InputMaybe<Scalars['String']>
  myTravelStyleCantLiveWithout_exists?: InputMaybe<Scalars['Boolean']>
  myTravelStyleCantLiveWithout_not_contains?: InputMaybe<Scalars['String']>
  myTravelStyleDreamingOf_contains?: InputMaybe<Scalars['String']>
  myTravelStyleDreamingOf_exists?: InputMaybe<Scalars['Boolean']>
  myTravelStyleDreamingOf_not_contains?: InputMaybe<Scalars['String']>
  myTravelStyleQuote_contains?: InputMaybe<Scalars['String']>
  myTravelStyleQuote_exists?: InputMaybe<Scalars['Boolean']>
  myTravelStyleQuote_not_contains?: InputMaybe<Scalars['String']>
  myTravelStyleRecentlyReturnedFrom_contains?: InputMaybe<Scalars['String']>
  myTravelStyleRecentlyReturnedFrom_exists?: InputMaybe<Scalars['Boolean']>
  myTravelStyleRecentlyReturnedFrom_not_contains?: InputMaybe<Scalars['String']>
  myTravelStyleTwoTruthsOneLie_contains?: InputMaybe<Scalars['String']>
  myTravelStyleTwoTruthsOneLie_exists?: InputMaybe<Scalars['Boolean']>
  myTravelStyleTwoTruthsOneLie_not_contains?: InputMaybe<Scalars['String']>
  pinterestUrl?: InputMaybe<Scalars['String']>
  pinterestUrl_contains?: InputMaybe<Scalars['String']>
  pinterestUrl_exists?: InputMaybe<Scalars['Boolean']>
  pinterestUrl_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  pinterestUrl_not?: InputMaybe<Scalars['String']>
  pinterestUrl_not_contains?: InputMaybe<Scalars['String']>
  pinterestUrl_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  preferredGuides?: InputMaybe<CfpreferredGuidesMultiTypeNestedFilter>
  preferredGuidesCollection_exists?: InputMaybe<Scalars['Boolean']>
  pronouns?: InputMaybe<Scalars['String']>
  pronouns_contains?: InputMaybe<Scalars['String']>
  pronouns_exists?: InputMaybe<Scalars['Boolean']>
  pronouns_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  pronouns_not?: InputMaybe<Scalars['String']>
  pronouns_not_contains?: InputMaybe<Scalars['String']>
  pronouns_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  shortDescription?: InputMaybe<Scalars['String']>
  shortDescription_contains?: InputMaybe<Scalars['String']>
  shortDescription_exists?: InputMaybe<Scalars['Boolean']>
  shortDescription_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  shortDescription_not?: InputMaybe<Scalars['String']>
  shortDescription_not_contains?: InputMaybe<Scalars['String']>
  shortDescription_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  showOnAdvisorsPage?: InputMaybe<Scalars['Boolean']>
  showOnAdvisorsPage_exists?: InputMaybe<Scalars['Boolean']>
  showOnAdvisorsPage_not?: InputMaybe<Scalars['Boolean']>
  showOnHomepage?: InputMaybe<Scalars['Boolean']>
  showOnHomepage_exists?: InputMaybe<Scalars['Boolean']>
  showOnHomepage_not?: InputMaybe<Scalars['Boolean']>
  showOnOtherLandingPages?: InputMaybe<Scalars['Boolean']>
  showOnOtherLandingPages_exists?: InputMaybe<Scalars['Boolean']>
  showOnOtherLandingPages_not?: InputMaybe<Scalars['Boolean']>
  slug?: InputMaybe<Scalars['String']>
  slug_contains?: InputMaybe<Scalars['String']>
  slug_exists?: InputMaybe<Scalars['Boolean']>
  slug_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  slug_not?: InputMaybe<Scalars['String']>
  slug_not_contains?: InputMaybe<Scalars['String']>
  slug_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  sys?: InputMaybe<SysFilter>
  tier?: InputMaybe<Scalars['String']>
  tier_contains?: InputMaybe<Scalars['String']>
  tier_exists?: InputMaybe<Scalars['Boolean']>
  tier_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  tier_not?: InputMaybe<Scalars['String']>
  tier_not_contains?: InputMaybe<Scalars['String']>
  tier_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  tikTokUrl?: InputMaybe<Scalars['String']>
  tikTokUrl_contains?: InputMaybe<Scalars['String']>
  tikTokUrl_exists?: InputMaybe<Scalars['Boolean']>
  tikTokUrl_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  tikTokUrl_not?: InputMaybe<Scalars['String']>
  tikTokUrl_not_contains?: InputMaybe<Scalars['String']>
  tikTokUrl_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  title?: InputMaybe<Scalars['String']>
  title_contains?: InputMaybe<Scalars['String']>
  title_exists?: InputMaybe<Scalars['Boolean']>
  title_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  title_not?: InputMaybe<Scalars['String']>
  title_not_contains?: InputMaybe<Scalars['String']>
  title_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  twitterUrl?: InputMaybe<Scalars['String']>
  twitterUrl_contains?: InputMaybe<Scalars['String']>
  twitterUrl_exists?: InputMaybe<Scalars['Boolean']>
  twitterUrl_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  twitterUrl_not?: InputMaybe<Scalars['String']>
  twitterUrl_not_contains?: InputMaybe<Scalars['String']>
  twitterUrl_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  websiteUrl?: InputMaybe<Scalars['String']>
  websiteUrl_contains?: InputMaybe<Scalars['String']>
  websiteUrl_exists?: InputMaybe<Scalars['Boolean']>
  websiteUrl_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  websiteUrl_not?: InputMaybe<Scalars['String']>
  websiteUrl_not_contains?: InputMaybe<Scalars['String']>
  websiteUrl_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  youtubeUrl?: InputMaybe<Scalars['String']>
  youtubeUrl_contains?: InputMaybe<Scalars['String']>
  youtubeUrl_exists?: InputMaybe<Scalars['Boolean']>
  youtubeUrl_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  youtubeUrl_not?: InputMaybe<Scalars['String']>
  youtubeUrl_not_contains?: InputMaybe<Scalars['String']>
  youtubeUrl_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
}

export type AdvisorsHotelsCollection = {
  __typename?: 'AdvisorsHotelsCollection'
  items: Array<Maybe<Hotels>>
  limit: Scalars['Int']
  skip: Scalars['Int']
  total: Scalars['Int']
}

export enum AdvisorsHotelsCollectionOrder {
  ArchivedAsc = 'archived_ASC',
  ArchivedDesc = 'archived_DESC',
  ForaSupplierIdAsc = 'foraSupplierId_ASC',
  ForaSupplierIdDesc = 'foraSupplierId_DESC',
  GenerateLandingPageAsc = 'generateLandingPage_ASC',
  GenerateLandingPageDesc = 'generateLandingPage_DESC',
  HeroImageAsc = 'heroImage_ASC',
  HeroImageDesc = 'heroImage_DESC',
  HideFromTopHotelsPagesAsc = 'hideFromTopHotelsPages_ASC',
  HideFromTopHotelsPagesDesc = 'hideFromTopHotelsPages_DESC',
  MichelinKeysAsc = 'michelinKeys_ASC',
  MichelinKeysDesc = 'michelinKeys_DESC',
  SlugAsc = 'slug_ASC',
  SlugDesc = 'slug_DESC',
  StarRatingAsc = 'starRating_ASC',
  StarRatingDesc = 'starRating_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC',
}

export type AdvisorsImageCarouselCollection = {
  __typename?: 'AdvisorsImageCarouselCollection'
  items: Array<Maybe<ComponentImage>>
  limit: Scalars['Int']
  skip: Scalars['Int']
  total: Scalars['Int']
}

export enum AdvisorsImageCarouselCollectionOrder {
  AltTextAsc = 'altText_ASC',
  AltTextDesc = 'altText_DESC',
  ImageCreditAsc = 'imageCredit_ASC',
  ImageCreditDesc = 'imageCredit_DESC',
  InternalTitleAsc = 'internalTitle_ASC',
  InternalTitleDesc = 'internalTitle_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
}

export type AdvisorsLanguagesSpoken = {
  __typename?: 'AdvisorsLanguagesSpoken'
  json: Scalars['JSON']
  links: AdvisorsLanguagesSpokenLinks
}

export type AdvisorsLanguagesSpokenAssets = {
  __typename?: 'AdvisorsLanguagesSpokenAssets'
  block: Array<Maybe<Asset>>
  hyperlink: Array<Maybe<Asset>>
}

export type AdvisorsLanguagesSpokenEntries = {
  __typename?: 'AdvisorsLanguagesSpokenEntries'
  block: Array<Maybe<Entry>>
  hyperlink: Array<Maybe<Entry>>
  inline: Array<Maybe<Entry>>
}

export type AdvisorsLanguagesSpokenLinks = {
  __typename?: 'AdvisorsLanguagesSpokenLinks'
  assets: AdvisorsLanguagesSpokenAssets
  entries: AdvisorsLanguagesSpokenEntries
  resources: AdvisorsLanguagesSpokenResources
}

export type AdvisorsLanguagesSpokenResources = {
  __typename?: 'AdvisorsLanguagesSpokenResources'
  block: Array<AdvisorsLanguagesSpokenResourcesBlock>
  hyperlink: Array<AdvisorsLanguagesSpokenResourcesHyperlink>
  inline: Array<AdvisorsLanguagesSpokenResourcesInline>
}

export type AdvisorsLanguagesSpokenResourcesBlock = ResourceLink & {
  __typename?: 'AdvisorsLanguagesSpokenResourcesBlock'
  sys: ResourceSys
}

export type AdvisorsLanguagesSpokenResourcesHyperlink = ResourceLink & {
  __typename?: 'AdvisorsLanguagesSpokenResourcesHyperlink'
  sys: ResourceSys
}

export type AdvisorsLanguagesSpokenResourcesInline = ResourceLink & {
  __typename?: 'AdvisorsLanguagesSpokenResourcesInline'
  sys: ResourceSys
}

export type AdvisorsLinkingCollections = {
  __typename?: 'AdvisorsLinkingCollections'
  advisorReviewsCollection?: Maybe<AdvisorReviewsCollection>
  componentFeaturedAdvisorsCollection?: Maybe<ComponentFeaturedAdvisorsCollection>
  entryCollection?: Maybe<EntryCollection>
  entryTestimonialCollection?: Maybe<EntryTestimonialCollection>
  entryTripReportCollection?: Maybe<EntryTripReportCollection>
  itinerariesCollection?: Maybe<ItinerariesCollection>
  pagesTwoColumnCtaCollection?: Maybe<PagesTwoColumnCtaCollection>
}

export type AdvisorsLinkingCollectionsAdvisorReviewsCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>
  locale?: InputMaybe<Scalars['String']>
  order?: InputMaybe<
    Array<InputMaybe<AdvisorsLinkingCollectionsAdvisorReviewsCollectionOrder>>
  >
  preview?: InputMaybe<Scalars['Boolean']>
  skip?: InputMaybe<Scalars['Int']>
}

export type AdvisorsLinkingCollectionsComponentFeaturedAdvisorsCollectionArgs =
  {
    limit?: InputMaybe<Scalars['Int']>
    locale?: InputMaybe<Scalars['String']>
    order?: InputMaybe<
      Array<
        InputMaybe<AdvisorsLinkingCollectionsComponentFeaturedAdvisorsCollectionOrder>
      >
    >
    preview?: InputMaybe<Scalars['Boolean']>
    skip?: InputMaybe<Scalars['Int']>
  }

export type AdvisorsLinkingCollectionsEntryCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>
  locale?: InputMaybe<Scalars['String']>
  preview?: InputMaybe<Scalars['Boolean']>
  skip?: InputMaybe<Scalars['Int']>
}

export type AdvisorsLinkingCollectionsEntryTestimonialCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>
  locale?: InputMaybe<Scalars['String']>
  order?: InputMaybe<
    Array<InputMaybe<AdvisorsLinkingCollectionsEntryTestimonialCollectionOrder>>
  >
  preview?: InputMaybe<Scalars['Boolean']>
  skip?: InputMaybe<Scalars['Int']>
}

export type AdvisorsLinkingCollectionsEntryTripReportCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>
  locale?: InputMaybe<Scalars['String']>
  order?: InputMaybe<
    Array<InputMaybe<AdvisorsLinkingCollectionsEntryTripReportCollectionOrder>>
  >
  preview?: InputMaybe<Scalars['Boolean']>
  skip?: InputMaybe<Scalars['Int']>
}

export type AdvisorsLinkingCollectionsItinerariesCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>
  locale?: InputMaybe<Scalars['String']>
  order?: InputMaybe<
    Array<InputMaybe<AdvisorsLinkingCollectionsItinerariesCollectionOrder>>
  >
  preview?: InputMaybe<Scalars['Boolean']>
  skip?: InputMaybe<Scalars['Int']>
}

export type AdvisorsLinkingCollectionsPagesTwoColumnCtaCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>
  locale?: InputMaybe<Scalars['String']>
  order?: InputMaybe<
    Array<
      InputMaybe<AdvisorsLinkingCollectionsPagesTwoColumnCtaCollectionOrder>
    >
  >
  preview?: InputMaybe<Scalars['Boolean']>
  skip?: InputMaybe<Scalars['Int']>
}

export enum AdvisorsLinkingCollectionsAdvisorReviewsCollectionOrder {
  ReviewAsc = 'Review_ASC',
  ReviewDesc = 'Review_DESC',
  EyebrowTextAsc = 'eyebrowText_ASC',
  EyebrowTextDesc = 'eyebrowText_DESC',
  InternalLabelAsc = 'internalLabel_ASC',
  InternalLabelDesc = 'internalLabel_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
}

export enum AdvisorsLinkingCollectionsComponentFeaturedAdvisorsCollectionOrder {
  CtaTargetAsc = 'ctaTarget_ASC',
  CtaTargetDesc = 'ctaTarget_DESC',
  CtaTextAsc = 'ctaText_ASC',
  CtaTextDesc = 'ctaText_DESC',
  InternalLabelAsc = 'internalLabel_ASC',
  InternalLabelDesc = 'internalLabel_DESC',
  SectionIdAsc = 'sectionId_ASC',
  SectionIdDesc = 'sectionId_DESC',
  ShowCtaAsc = 'showCta_ASC',
  ShowCtaDesc = 'showCta_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  TitleStyleAsc = 'titleStyle_ASC',
  TitleStyleDesc = 'titleStyle_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC',
}

export enum AdvisorsLinkingCollectionsEntryTestimonialCollectionOrder {
  AboutForaAsc = 'aboutFora_ASC',
  AboutForaDesc = 'aboutFora_DESC',
  BeginTravelDateAsc = 'beginTravelDate_ASC',
  BeginTravelDateDesc = 'beginTravelDate_DESC',
  DateSubmittedAsc = 'dateSubmitted_ASC',
  DateSubmittedDesc = 'dateSubmitted_DESC',
  DisplayAsc = 'display_ASC',
  DisplayDesc = 'display_DESC',
  EndTravelDateAsc = 'endTravelDate_ASC',
  EndTravelDateDesc = 'endTravelDate_DESC',
  InternalLabelAsc = 'internalLabel_ASC',
  InternalLabelDesc = 'internalLabel_DESC',
  IsFeaturedAsc = 'isFeatured_ASC',
  IsFeaturedDesc = 'isFeatured_DESC',
  StarsAsc = 'stars_ASC',
  StarsDesc = 'stars_DESC',
  SubmitterEmailAsc = 'submitterEmail_ASC',
  SubmitterEmailDesc = 'submitterEmail_DESC',
  SubmitterFirstNameAsc = 'submitterFirstName_ASC',
  SubmitterFirstNameDesc = 'submitterFirstName_DESC',
  SubmitterLastNameAsc = 'submitterLastName_ASC',
  SubmitterLastNameDesc = 'submitterLastName_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  TestimonialSourceAsc = 'testimonialSource_ASC',
  TestimonialSourceDesc = 'testimonialSource_DESC',
  TitleOfReviewAsc = 'titleOfReview_ASC',
  TitleOfReviewDesc = 'titleOfReview_DESC',
}

export enum AdvisorsLinkingCollectionsEntryTripReportCollectionOrder {
  LocationForTitlesAsc = 'locationForTitles_ASC',
  LocationForTitlesDesc = 'locationForTitles_DESC',
  MetaDescriptionAsc = 'metaDescription_ASC',
  MetaDescriptionDesc = 'metaDescription_DESC',
  MetaTitleAsc = 'metaTitle_ASC',
  MetaTitleDesc = 'metaTitle_DESC',
  SeoSlugAsc = 'seoSlug_ASC',
  SeoSlugDesc = 'seoSlug_DESC',
  ShowOnGuidesAndDestinationsPageAsc = 'showOnGuidesAndDestinationsPage_ASC',
  ShowOnGuidesAndDestinationsPageDesc = 'showOnGuidesAndDestinationsPage_DESC',
  ShowOnHomepageAsc = 'showOnHomepage_ASC',
  ShowOnHomepageDesc = 'showOnHomepage_DESC',
  ShowOnOtherLandingPagesAsc = 'showOnOtherLandingPages_ASC',
  ShowOnOtherLandingPagesDesc = 'showOnOtherLandingPages_DESC',
  SlugIdAsc = 'slugID_ASC',
  SlugIdDesc = 'slugID_DESC',
  SlugAsc = 'slug_ASC',
  SlugDesc = 'slug_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  TestUpdatedSlugIdAsc = 'testUpdatedSlugId_ASC',
  TestUpdatedSlugIdDesc = 'testUpdatedSlugId_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC',
}

export enum AdvisorsLinkingCollectionsItinerariesCollectionOrder {
  ItineraryVariantAsc = 'itineraryVariant_ASC',
  ItineraryVariantDesc = 'itineraryVariant_DESC',
  LocationForTitlesAsc = 'locationForTitles_ASC',
  LocationForTitlesDesc = 'locationForTitles_DESC',
  MetaDescriptionAsc = 'metaDescription_ASC',
  MetaDescriptionDesc = 'metaDescription_DESC',
  MetaTitleAsc = 'metaTitle_ASC',
  MetaTitleDesc = 'metaTitle_DESC',
  SeoSlugAsc = 'seoSlug_ASC',
  SeoSlugDesc = 'seoSlug_DESC',
  ShowOnHomepageAsc = 'showOnHomepage_ASC',
  ShowOnHomepageDesc = 'showOnHomepage_DESC',
  ShowOnItinerariesPageAsc = 'showOnItinerariesPage_ASC',
  ShowOnItinerariesPageDesc = 'showOnItinerariesPage_DESC',
  ShowOnOtherLandingPagesAsc = 'showOnOtherLandingPages_ASC',
  ShowOnOtherLandingPagesDesc = 'showOnOtherLandingPages_DESC',
  SlugIdAsc = 'slugID_ASC',
  SlugIdDesc = 'slugID_DESC',
  SlugAsc = 'slug_ASC',
  SlugDesc = 'slug_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC',
}

export enum AdvisorsLinkingCollectionsPagesTwoColumnCtaCollectionOrder {
  AdvisorDescriptionAsc = 'advisorDescription_ASC',
  AdvisorDescriptionDesc = 'advisorDescription_DESC',
  BackgroundColorAsc = 'backgroundColor_ASC',
  BackgroundColorDesc = 'backgroundColor_DESC',
  ButtonTextAsc = 'buttonText_ASC',
  ButtonTextDesc = 'buttonText_DESC',
  ButtonThemeAsc = 'buttonTheme_ASC',
  ButtonThemeDesc = 'buttonTheme_DESC',
  ButtonUrlAsc = 'buttonUrl_ASC',
  ButtonUrlDesc = 'buttonUrl_DESC',
  CardSizeAsc = 'cardSize_ASC',
  CardSizeDesc = 'cardSize_DESC',
  ContentColumnAsc = 'contentColumn_ASC',
  ContentColumnDesc = 'contentColumn_DESC',
  FormIdAsc = 'formId_ASC',
  FormIdDesc = 'formId_DESC',
  InternalLabelAsc = 'internalLabel_ASC',
  InternalLabelDesc = 'internalLabel_DESC',
  MobileAlignmentAsc = 'mobileAlignment_ASC',
  MobileAlignmentDesc = 'mobileAlignment_DESC',
  SectionIdAsc = 'sectionId_ASC',
  SectionIdDesc = 'sectionId_DESC',
  SolidBackgroundAsc = 'solidBackground_ASC',
  SolidBackgroundDesc = 'solidBackground_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  TitleStyleAsc = 'titleStyle_ASC',
  TitleStyleDesc = 'titleStyle_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC',
  UrlTargetAsc = 'urlTarget_ASC',
  UrlTargetDesc = 'urlTarget_DESC',
  YMarginAsc = 'yMargin_ASC',
  YMarginDesc = 'yMargin_DESC',
}

export type AdvisorsMyTravelStyleCantLiveWithout = {
  __typename?: 'AdvisorsMyTravelStyleCantLiveWithout'
  json: Scalars['JSON']
  links: AdvisorsMyTravelStyleCantLiveWithoutLinks
}

export type AdvisorsMyTravelStyleCantLiveWithoutAssets = {
  __typename?: 'AdvisorsMyTravelStyleCantLiveWithoutAssets'
  block: Array<Maybe<Asset>>
  hyperlink: Array<Maybe<Asset>>
}

export type AdvisorsMyTravelStyleCantLiveWithoutEntries = {
  __typename?: 'AdvisorsMyTravelStyleCantLiveWithoutEntries'
  block: Array<Maybe<Entry>>
  hyperlink: Array<Maybe<Entry>>
  inline: Array<Maybe<Entry>>
}

export type AdvisorsMyTravelStyleCantLiveWithoutLinks = {
  __typename?: 'AdvisorsMyTravelStyleCantLiveWithoutLinks'
  assets: AdvisorsMyTravelStyleCantLiveWithoutAssets
  entries: AdvisorsMyTravelStyleCantLiveWithoutEntries
  resources: AdvisorsMyTravelStyleCantLiveWithoutResources
}

export type AdvisorsMyTravelStyleCantLiveWithoutResources = {
  __typename?: 'AdvisorsMyTravelStyleCantLiveWithoutResources'
  block: Array<AdvisorsMyTravelStyleCantLiveWithoutResourcesBlock>
  hyperlink: Array<AdvisorsMyTravelStyleCantLiveWithoutResourcesHyperlink>
  inline: Array<AdvisorsMyTravelStyleCantLiveWithoutResourcesInline>
}

export type AdvisorsMyTravelStyleCantLiveWithoutResourcesBlock =
  ResourceLink & {
    __typename?: 'AdvisorsMyTravelStyleCantLiveWithoutResourcesBlock'
    sys: ResourceSys
  }

export type AdvisorsMyTravelStyleCantLiveWithoutResourcesHyperlink =
  ResourceLink & {
    __typename?: 'AdvisorsMyTravelStyleCantLiveWithoutResourcesHyperlink'
    sys: ResourceSys
  }

export type AdvisorsMyTravelStyleCantLiveWithoutResourcesInline =
  ResourceLink & {
    __typename?: 'AdvisorsMyTravelStyleCantLiveWithoutResourcesInline'
    sys: ResourceSys
  }

export type AdvisorsMyTravelStyleDreamingOf = {
  __typename?: 'AdvisorsMyTravelStyleDreamingOf'
  json: Scalars['JSON']
  links: AdvisorsMyTravelStyleDreamingOfLinks
}

export type AdvisorsMyTravelStyleDreamingOfAssets = {
  __typename?: 'AdvisorsMyTravelStyleDreamingOfAssets'
  block: Array<Maybe<Asset>>
  hyperlink: Array<Maybe<Asset>>
}

export type AdvisorsMyTravelStyleDreamingOfEntries = {
  __typename?: 'AdvisorsMyTravelStyleDreamingOfEntries'
  block: Array<Maybe<Entry>>
  hyperlink: Array<Maybe<Entry>>
  inline: Array<Maybe<Entry>>
}

export type AdvisorsMyTravelStyleDreamingOfLinks = {
  __typename?: 'AdvisorsMyTravelStyleDreamingOfLinks'
  assets: AdvisorsMyTravelStyleDreamingOfAssets
  entries: AdvisorsMyTravelStyleDreamingOfEntries
  resources: AdvisorsMyTravelStyleDreamingOfResources
}

export type AdvisorsMyTravelStyleDreamingOfResources = {
  __typename?: 'AdvisorsMyTravelStyleDreamingOfResources'
  block: Array<AdvisorsMyTravelStyleDreamingOfResourcesBlock>
  hyperlink: Array<AdvisorsMyTravelStyleDreamingOfResourcesHyperlink>
  inline: Array<AdvisorsMyTravelStyleDreamingOfResourcesInline>
}

export type AdvisorsMyTravelStyleDreamingOfResourcesBlock = ResourceLink & {
  __typename?: 'AdvisorsMyTravelStyleDreamingOfResourcesBlock'
  sys: ResourceSys
}

export type AdvisorsMyTravelStyleDreamingOfResourcesHyperlink = ResourceLink & {
  __typename?: 'AdvisorsMyTravelStyleDreamingOfResourcesHyperlink'
  sys: ResourceSys
}

export type AdvisorsMyTravelStyleDreamingOfResourcesInline = ResourceLink & {
  __typename?: 'AdvisorsMyTravelStyleDreamingOfResourcesInline'
  sys: ResourceSys
}

export type AdvisorsMyTravelStyleQuote = {
  __typename?: 'AdvisorsMyTravelStyleQuote'
  json: Scalars['JSON']
  links: AdvisorsMyTravelStyleQuoteLinks
}

export type AdvisorsMyTravelStyleQuoteAssets = {
  __typename?: 'AdvisorsMyTravelStyleQuoteAssets'
  block: Array<Maybe<Asset>>
  hyperlink: Array<Maybe<Asset>>
}

export type AdvisorsMyTravelStyleQuoteEntries = {
  __typename?: 'AdvisorsMyTravelStyleQuoteEntries'
  block: Array<Maybe<Entry>>
  hyperlink: Array<Maybe<Entry>>
  inline: Array<Maybe<Entry>>
}

export type AdvisorsMyTravelStyleQuoteLinks = {
  __typename?: 'AdvisorsMyTravelStyleQuoteLinks'
  assets: AdvisorsMyTravelStyleQuoteAssets
  entries: AdvisorsMyTravelStyleQuoteEntries
  resources: AdvisorsMyTravelStyleQuoteResources
}

export type AdvisorsMyTravelStyleQuoteResources = {
  __typename?: 'AdvisorsMyTravelStyleQuoteResources'
  block: Array<AdvisorsMyTravelStyleQuoteResourcesBlock>
  hyperlink: Array<AdvisorsMyTravelStyleQuoteResourcesHyperlink>
  inline: Array<AdvisorsMyTravelStyleQuoteResourcesInline>
}

export type AdvisorsMyTravelStyleQuoteResourcesBlock = ResourceLink & {
  __typename?: 'AdvisorsMyTravelStyleQuoteResourcesBlock'
  sys: ResourceSys
}

export type AdvisorsMyTravelStyleQuoteResourcesHyperlink = ResourceLink & {
  __typename?: 'AdvisorsMyTravelStyleQuoteResourcesHyperlink'
  sys: ResourceSys
}

export type AdvisorsMyTravelStyleQuoteResourcesInline = ResourceLink & {
  __typename?: 'AdvisorsMyTravelStyleQuoteResourcesInline'
  sys: ResourceSys
}

export type AdvisorsMyTravelStyleRecentlyReturnedFrom = {
  __typename?: 'AdvisorsMyTravelStyleRecentlyReturnedFrom'
  json: Scalars['JSON']
  links: AdvisorsMyTravelStyleRecentlyReturnedFromLinks
}

export type AdvisorsMyTravelStyleRecentlyReturnedFromAssets = {
  __typename?: 'AdvisorsMyTravelStyleRecentlyReturnedFromAssets'
  block: Array<Maybe<Asset>>
  hyperlink: Array<Maybe<Asset>>
}

export type AdvisorsMyTravelStyleRecentlyReturnedFromEntries = {
  __typename?: 'AdvisorsMyTravelStyleRecentlyReturnedFromEntries'
  block: Array<Maybe<Entry>>
  hyperlink: Array<Maybe<Entry>>
  inline: Array<Maybe<Entry>>
}

export type AdvisorsMyTravelStyleRecentlyReturnedFromLinks = {
  __typename?: 'AdvisorsMyTravelStyleRecentlyReturnedFromLinks'
  assets: AdvisorsMyTravelStyleRecentlyReturnedFromAssets
  entries: AdvisorsMyTravelStyleRecentlyReturnedFromEntries
  resources: AdvisorsMyTravelStyleRecentlyReturnedFromResources
}

export type AdvisorsMyTravelStyleRecentlyReturnedFromResources = {
  __typename?: 'AdvisorsMyTravelStyleRecentlyReturnedFromResources'
  block: Array<AdvisorsMyTravelStyleRecentlyReturnedFromResourcesBlock>
  hyperlink: Array<AdvisorsMyTravelStyleRecentlyReturnedFromResourcesHyperlink>
  inline: Array<AdvisorsMyTravelStyleRecentlyReturnedFromResourcesInline>
}

export type AdvisorsMyTravelStyleRecentlyReturnedFromResourcesBlock =
  ResourceLink & {
    __typename?: 'AdvisorsMyTravelStyleRecentlyReturnedFromResourcesBlock'
    sys: ResourceSys
  }

export type AdvisorsMyTravelStyleRecentlyReturnedFromResourcesHyperlink =
  ResourceLink & {
    __typename?: 'AdvisorsMyTravelStyleRecentlyReturnedFromResourcesHyperlink'
    sys: ResourceSys
  }

export type AdvisorsMyTravelStyleRecentlyReturnedFromResourcesInline =
  ResourceLink & {
    __typename?: 'AdvisorsMyTravelStyleRecentlyReturnedFromResourcesInline'
    sys: ResourceSys
  }

export type AdvisorsMyTravelStyleTwoTruthsOneLie = {
  __typename?: 'AdvisorsMyTravelStyleTwoTruthsOneLie'
  json: Scalars['JSON']
  links: AdvisorsMyTravelStyleTwoTruthsOneLieLinks
}

export type AdvisorsMyTravelStyleTwoTruthsOneLieAssets = {
  __typename?: 'AdvisorsMyTravelStyleTwoTruthsOneLieAssets'
  block: Array<Maybe<Asset>>
  hyperlink: Array<Maybe<Asset>>
}

export type AdvisorsMyTravelStyleTwoTruthsOneLieEntries = {
  __typename?: 'AdvisorsMyTravelStyleTwoTruthsOneLieEntries'
  block: Array<Maybe<Entry>>
  hyperlink: Array<Maybe<Entry>>
  inline: Array<Maybe<Entry>>
}

export type AdvisorsMyTravelStyleTwoTruthsOneLieLinks = {
  __typename?: 'AdvisorsMyTravelStyleTwoTruthsOneLieLinks'
  assets: AdvisorsMyTravelStyleTwoTruthsOneLieAssets
  entries: AdvisorsMyTravelStyleTwoTruthsOneLieEntries
  resources: AdvisorsMyTravelStyleTwoTruthsOneLieResources
}

export type AdvisorsMyTravelStyleTwoTruthsOneLieResources = {
  __typename?: 'AdvisorsMyTravelStyleTwoTruthsOneLieResources'
  block: Array<AdvisorsMyTravelStyleTwoTruthsOneLieResourcesBlock>
  hyperlink: Array<AdvisorsMyTravelStyleTwoTruthsOneLieResourcesHyperlink>
  inline: Array<AdvisorsMyTravelStyleTwoTruthsOneLieResourcesInline>
}

export type AdvisorsMyTravelStyleTwoTruthsOneLieResourcesBlock =
  ResourceLink & {
    __typename?: 'AdvisorsMyTravelStyleTwoTruthsOneLieResourcesBlock'
    sys: ResourceSys
  }

export type AdvisorsMyTravelStyleTwoTruthsOneLieResourcesHyperlink =
  ResourceLink & {
    __typename?: 'AdvisorsMyTravelStyleTwoTruthsOneLieResourcesHyperlink'
    sys: ResourceSys
  }

export type AdvisorsMyTravelStyleTwoTruthsOneLieResourcesInline =
  ResourceLink & {
    __typename?: 'AdvisorsMyTravelStyleTwoTruthsOneLieResourcesInline'
    sys: ResourceSys
  }

export enum AdvisorsOrder {
  AdvisorContactEnabledAsc = 'advisorContactEnabled_ASC',
  AdvisorContactEnabledDesc = 'advisorContactEnabled_DESC',
  AdvisorVariantAsc = 'advisorVariant_ASC',
  AdvisorVariantDesc = 'advisorVariant_DESC',
  EmailAddressAsc = 'emailAddress_ASC',
  EmailAddressDesc = 'emailAddress_DESC',
  FacebookUrlAsc = 'facebookUrl_ASC',
  FacebookUrlDesc = 'facebookUrl_DESC',
  FirstNameAsc = 'firstName_ASC',
  FirstNameDesc = 'firstName_DESC',
  ForaAdvisorHotelsAsc = 'foraAdvisorHotels_ASC',
  ForaAdvisorHotelsDesc = 'foraAdvisorHotels_DESC',
  ForaAdvisorIdAsc = 'foraAdvisorId_ASC',
  ForaAdvisorIdDesc = 'foraAdvisorId_DESC',
  InstagramUrlAsc = 'instagramUrl_ASC',
  InstagramUrlDesc = 'instagramUrl_DESC',
  LastNameAsc = 'lastName_ASC',
  LastNameDesc = 'lastName_DESC',
  LinkedinUrlAsc = 'linkedinUrl_ASC',
  LinkedinUrlDesc = 'linkedinUrl_DESC',
  MinimumBookingAsc = 'minimumBooking_ASC',
  MinimumBookingDesc = 'minimumBooking_DESC',
  PinterestUrlAsc = 'pinterestUrl_ASC',
  PinterestUrlDesc = 'pinterestUrl_DESC',
  PronounsAsc = 'pronouns_ASC',
  PronounsDesc = 'pronouns_DESC',
  ShortDescriptionAsc = 'shortDescription_ASC',
  ShortDescriptionDesc = 'shortDescription_DESC',
  ShowOnAdvisorsPageAsc = 'showOnAdvisorsPage_ASC',
  ShowOnAdvisorsPageDesc = 'showOnAdvisorsPage_DESC',
  ShowOnHomepageAsc = 'showOnHomepage_ASC',
  ShowOnHomepageDesc = 'showOnHomepage_DESC',
  ShowOnOtherLandingPagesAsc = 'showOnOtherLandingPages_ASC',
  ShowOnOtherLandingPagesDesc = 'showOnOtherLandingPages_DESC',
  SlugAsc = 'slug_ASC',
  SlugDesc = 'slug_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  TierAsc = 'tier_ASC',
  TierDesc = 'tier_DESC',
  TikTokUrlAsc = 'tikTokUrl_ASC',
  TikTokUrlDesc = 'tikTokUrl_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC',
  TwitterUrlAsc = 'twitterUrl_ASC',
  TwitterUrlDesc = 'twitterUrl_DESC',
  WebsiteUrlAsc = 'websiteUrl_ASC',
  WebsiteUrlDesc = 'websiteUrl_DESC',
  YoutubeUrlAsc = 'youtubeUrl_ASC',
  YoutubeUrlDesc = 'youtubeUrl_DESC',
}

export type AdvisorsPreferredGuidesCollection = {
  __typename?: 'AdvisorsPreferredGuidesCollection'
  items: Array<Maybe<AdvisorsPreferredGuidesItem>>
  limit: Scalars['Int']
  skip: Scalars['Int']
  total: Scalars['Int']
}

export type AdvisorsPreferredGuidesFilter = {
  AND?: InputMaybe<Array<InputMaybe<AdvisorsPreferredGuidesFilter>>>
  OR?: InputMaybe<Array<InputMaybe<AdvisorsPreferredGuidesFilter>>>
  advisor_exists?: InputMaybe<Scalars['Boolean']>
  atAGlanceTags_contains_all?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  atAGlanceTags_contains_none?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  atAGlanceTags_contains_some?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  atAGlanceTags_exists?: InputMaybe<Scalars['Boolean']>
  cloudinaryImage_exists?: InputMaybe<Scalars['Boolean']>
  contentfulMetadata?: InputMaybe<ContentfulMetadataFilter>
  curatorsStatement_contains?: InputMaybe<Scalars['String']>
  curatorsStatement_exists?: InputMaybe<Scalars['Boolean']>
  curatorsStatement_not_contains?: InputMaybe<Scalars['String']>
  hotelsCollection_exists?: InputMaybe<Scalars['Boolean']>
  image_exists?: InputMaybe<Scalars['Boolean']>
  locationForTitles?: InputMaybe<Scalars['String']>
  locationForTitles_contains?: InputMaybe<Scalars['String']>
  locationForTitles_exists?: InputMaybe<Scalars['Boolean']>
  locationForTitles_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  locationForTitles_not?: InputMaybe<Scalars['String']>
  locationForTitles_not_contains?: InputMaybe<Scalars['String']>
  locationForTitles_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  metaDescription?: InputMaybe<Scalars['String']>
  metaDescription_contains?: InputMaybe<Scalars['String']>
  metaDescription_exists?: InputMaybe<Scalars['Boolean']>
  metaDescription_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  metaDescription_not?: InputMaybe<Scalars['String']>
  metaDescription_not_contains?: InputMaybe<Scalars['String']>
  metaDescription_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  metaTitle?: InputMaybe<Scalars['String']>
  metaTitle_contains?: InputMaybe<Scalars['String']>
  metaTitle_exists?: InputMaybe<Scalars['Boolean']>
  metaTitle_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  metaTitle_not?: InputMaybe<Scalars['String']>
  metaTitle_not_contains?: InputMaybe<Scalars['String']>
  metaTitle_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  needToKnow_contains?: InputMaybe<Scalars['String']>
  needToKnow_exists?: InputMaybe<Scalars['Boolean']>
  needToKnow_not_contains?: InputMaybe<Scalars['String']>
  seoSlug?: InputMaybe<Scalars['String']>
  seoSlug_contains?: InputMaybe<Scalars['String']>
  seoSlug_exists?: InputMaybe<Scalars['Boolean']>
  seoSlug_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  seoSlug_not?: InputMaybe<Scalars['String']>
  seoSlug_not_contains?: InputMaybe<Scalars['String']>
  seoSlug_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  showOnHomepage?: InputMaybe<Scalars['Boolean']>
  showOnHomepage_exists?: InputMaybe<Scalars['Boolean']>
  showOnHomepage_not?: InputMaybe<Scalars['Boolean']>
  showOnOtherLandingPages?: InputMaybe<Scalars['Boolean']>
  showOnOtherLandingPages_exists?: InputMaybe<Scalars['Boolean']>
  showOnOtherLandingPages_not?: InputMaybe<Scalars['Boolean']>
  slug?: InputMaybe<Scalars['String']>
  slugID?: InputMaybe<Scalars['String']>
  slugID_contains?: InputMaybe<Scalars['String']>
  slugID_exists?: InputMaybe<Scalars['Boolean']>
  slugID_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  slugID_not?: InputMaybe<Scalars['String']>
  slugID_not_contains?: InputMaybe<Scalars['String']>
  slugID_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  slug_contains?: InputMaybe<Scalars['String']>
  slug_exists?: InputMaybe<Scalars['Boolean']>
  slug_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  slug_not?: InputMaybe<Scalars['String']>
  slug_not_contains?: InputMaybe<Scalars['String']>
  slug_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  sys?: InputMaybe<SysFilter>
  title?: InputMaybe<Scalars['String']>
  title_contains?: InputMaybe<Scalars['String']>
  title_exists?: InputMaybe<Scalars['Boolean']>
  title_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  title_not?: InputMaybe<Scalars['String']>
  title_not_contains?: InputMaybe<Scalars['String']>
  title_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
}

export type AdvisorsPreferredGuidesItem = EntryTripReport | Itineraries

/** Represents a binary file in a space. An asset can be any file type. */
export type Asset = {
  __typename?: 'Asset'
  contentType?: Maybe<Scalars['String']>
  contentfulMetadata: ContentfulMetadata
  description?: Maybe<Scalars['String']>
  fileName?: Maybe<Scalars['String']>
  height?: Maybe<Scalars['Int']>
  linkedFrom?: Maybe<AssetLinkingCollections>
  size?: Maybe<Scalars['Int']>
  sys: Sys
  title?: Maybe<Scalars['String']>
  url?: Maybe<Scalars['String']>
  width?: Maybe<Scalars['Int']>
}

/** Represents a binary file in a space. An asset can be any file type. */
export type AssetContentTypeArgs = {
  locale?: InputMaybe<Scalars['String']>
}

/** Represents a binary file in a space. An asset can be any file type. */
export type AssetDescriptionArgs = {
  locale?: InputMaybe<Scalars['String']>
}

/** Represents a binary file in a space. An asset can be any file type. */
export type AssetFileNameArgs = {
  locale?: InputMaybe<Scalars['String']>
}

/** Represents a binary file in a space. An asset can be any file type. */
export type AssetHeightArgs = {
  locale?: InputMaybe<Scalars['String']>
}

/** Represents a binary file in a space. An asset can be any file type. */
export type AssetLinkedFromArgs = {
  allowedLocales?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
}

/** Represents a binary file in a space. An asset can be any file type. */
export type AssetSizeArgs = {
  locale?: InputMaybe<Scalars['String']>
}

/** Represents a binary file in a space. An asset can be any file type. */
export type AssetTitleArgs = {
  locale?: InputMaybe<Scalars['String']>
}

/** Represents a binary file in a space. An asset can be any file type. */
export type AssetUrlArgs = {
  locale?: InputMaybe<Scalars['String']>
  transform?: InputMaybe<ImageTransformOptions>
}

/** Represents a binary file in a space. An asset can be any file type. */
export type AssetWidthArgs = {
  locale?: InputMaybe<Scalars['String']>
}

export type AssetCollection = {
  __typename?: 'AssetCollection'
  items: Array<Maybe<Asset>>
  limit: Scalars['Int']
  skip: Scalars['Int']
  total: Scalars['Int']
}

export type AssetFilter = {
  AND?: InputMaybe<Array<InputMaybe<AssetFilter>>>
  OR?: InputMaybe<Array<InputMaybe<AssetFilter>>>
  contentType?: InputMaybe<Scalars['String']>
  contentType_contains?: InputMaybe<Scalars['String']>
  contentType_exists?: InputMaybe<Scalars['Boolean']>
  contentType_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  contentType_not?: InputMaybe<Scalars['String']>
  contentType_not_contains?: InputMaybe<Scalars['String']>
  contentType_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  contentfulMetadata?: InputMaybe<ContentfulMetadataFilter>
  description?: InputMaybe<Scalars['String']>
  description_contains?: InputMaybe<Scalars['String']>
  description_exists?: InputMaybe<Scalars['Boolean']>
  description_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  description_not?: InputMaybe<Scalars['String']>
  description_not_contains?: InputMaybe<Scalars['String']>
  description_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  fileName?: InputMaybe<Scalars['String']>
  fileName_contains?: InputMaybe<Scalars['String']>
  fileName_exists?: InputMaybe<Scalars['Boolean']>
  fileName_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  fileName_not?: InputMaybe<Scalars['String']>
  fileName_not_contains?: InputMaybe<Scalars['String']>
  fileName_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  height?: InputMaybe<Scalars['Int']>
  height_exists?: InputMaybe<Scalars['Boolean']>
  height_gt?: InputMaybe<Scalars['Int']>
  height_gte?: InputMaybe<Scalars['Int']>
  height_in?: InputMaybe<Array<InputMaybe<Scalars['Int']>>>
  height_lt?: InputMaybe<Scalars['Int']>
  height_lte?: InputMaybe<Scalars['Int']>
  height_not?: InputMaybe<Scalars['Int']>
  height_not_in?: InputMaybe<Array<InputMaybe<Scalars['Int']>>>
  size?: InputMaybe<Scalars['Int']>
  size_exists?: InputMaybe<Scalars['Boolean']>
  size_gt?: InputMaybe<Scalars['Int']>
  size_gte?: InputMaybe<Scalars['Int']>
  size_in?: InputMaybe<Array<InputMaybe<Scalars['Int']>>>
  size_lt?: InputMaybe<Scalars['Int']>
  size_lte?: InputMaybe<Scalars['Int']>
  size_not?: InputMaybe<Scalars['Int']>
  size_not_in?: InputMaybe<Array<InputMaybe<Scalars['Int']>>>
  sys?: InputMaybe<SysFilter>
  title?: InputMaybe<Scalars['String']>
  title_contains?: InputMaybe<Scalars['String']>
  title_exists?: InputMaybe<Scalars['Boolean']>
  title_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  title_not?: InputMaybe<Scalars['String']>
  title_not_contains?: InputMaybe<Scalars['String']>
  title_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  url?: InputMaybe<Scalars['String']>
  url_contains?: InputMaybe<Scalars['String']>
  url_exists?: InputMaybe<Scalars['Boolean']>
  url_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  url_not?: InputMaybe<Scalars['String']>
  url_not_contains?: InputMaybe<Scalars['String']>
  url_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  width?: InputMaybe<Scalars['Int']>
  width_exists?: InputMaybe<Scalars['Boolean']>
  width_gt?: InputMaybe<Scalars['Int']>
  width_gte?: InputMaybe<Scalars['Int']>
  width_in?: InputMaybe<Array<InputMaybe<Scalars['Int']>>>
  width_lt?: InputMaybe<Scalars['Int']>
  width_lte?: InputMaybe<Scalars['Int']>
  width_not?: InputMaybe<Scalars['Int']>
  width_not_in?: InputMaybe<Array<InputMaybe<Scalars['Int']>>>
}

export type AssetLinkingCollections = {
  __typename?: 'AssetLinkingCollections'
  advisorsCollection?: Maybe<AdvisorsCollection>
  blogAuthorsCollection?: Maybe<BlogAuthorsCollection>
  blogPostsCollection?: Maybe<BlogPostsCollection>
  cloudinaryAssetsCollection?: Maybe<CloudinaryAssetsCollection>
  componentCarouselItemCollection?: Maybe<ComponentCarouselItemCollection>
  componentCenteredHeroCollection?: Maybe<ComponentCenteredHeroCollection>
  componentDataItemCollection?: Maybe<ComponentDataItemCollection>
  componentFeaturedReviewsCollection?: Maybe<ComponentFeaturedReviewsCollection>
  componentForaLogoCollection?: Maybe<ComponentForaLogoCollection>
  componentHeroCenteredCollection?: Maybe<ComponentHeroCenteredCollection>
  componentHeroFullWidthCollection?: Maybe<ComponentHeroFullWidthCollection>
  componentImageCollection?: Maybe<ComponentImageCollection>
  componentImageGallerySlideItemCollection?: Maybe<ComponentImageGallerySlideItemCollection>
  componentImageHeaderSubCollection?: Maybe<ComponentImageHeaderSubCollection>
  componentImageSideBySideCollection?: Maybe<ComponentImageSideBySideCollection>
  componentLargePhotoCarouselItemCollection?: Maybe<ComponentLargePhotoCarouselItemCollection>
  componentPictureSectionBannerCollection?: Maybe<ComponentPictureSectionBannerCollection>
  componentSpacerCollection?: Maybe<ComponentSpacerCollection>
  componentStickyHeaderCollection?: Maybe<ComponentStickyHeaderCollection>
  componentThreeIconSectionCollection?: Maybe<ComponentThreeIconSectionCollection>
  componentThreeIconSectionWithPictureCollection?: Maybe<ComponentThreeIconSectionWithPictureCollection>
  componentTwoColumnCalloutCollection?: Maybe<ComponentTwoColumnCalloutCollection>
  componentTwoColumnPerksSectionCollection?: Maybe<ComponentTwoColumnPerksSectionCollection>
  componentVideoSectionCollection?: Maybe<ComponentVideoSectionCollection>
  componentWhatYouGetCardCollection?: Maybe<ComponentWhatYouGetCardCollection>
  destinationsCategoriesCollection?: Maybe<DestinationsCategoriesCollection>
  destinationsSubcategoriesCollection?: Maybe<DestinationsSubcategoriesCollection>
  entryCollection?: Maybe<EntryCollection>
  entryPartnerCollection?: Maybe<EntryPartnerCollection>
  entryTestimonialCollection?: Maybe<EntryTestimonialCollection>
  entryTripReportCollection?: Maybe<EntryTripReportCollection>
  eventsCollection?: Maybe<EventsCollection>
  hotelsCollection?: Maybe<HotelsCollection>
  itinerariesCollection?: Maybe<ItinerariesCollection>
  itinerariesPlansCollection?: Maybe<ItinerariesPlansCollection>
  pagesHeaderBlockCollection?: Maybe<PagesHeaderBlockCollection>
  pagesHeroBlockCollection?: Maybe<PagesHeroBlockCollection>
  pagesLearnMoreBlockCollection?: Maybe<PagesLearnMoreBlockCollection>
  pagesMetaContentCollection?: Maybe<PagesMetaContentCollection>
  pagesPartnerBannerCollection?: Maybe<PagesPartnerBannerCollection>
  pagesPerksListCollection?: Maybe<PagesPerksListCollection>
  pagesPressBannerCollection?: Maybe<PagesPressBannerCollection>
  pagesTwoColumnCtaCollection?: Maybe<PagesTwoColumnCtaCollection>
  pressCollection?: Maybe<PressCollection>
  teamCollection?: Maybe<TeamCollection>
  templateAboutUsCollection?: Maybe<TemplateAboutUsCollection>
  templatePageCollection?: Maybe<TemplatePageCollection>
  templatePressCollection?: Maybe<TemplatePressCollection>
  travelByCategoriesCollection?: Maybe<TravelByCategoriesCollection>
  travelBySubcategoriesCollection?: Maybe<TravelBySubcategoriesCollection>
}

export type AssetLinkingCollectionsAdvisorsCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>
  locale?: InputMaybe<Scalars['String']>
  preview?: InputMaybe<Scalars['Boolean']>
  skip?: InputMaybe<Scalars['Int']>
}

export type AssetLinkingCollectionsBlogAuthorsCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>
  locale?: InputMaybe<Scalars['String']>
  preview?: InputMaybe<Scalars['Boolean']>
  skip?: InputMaybe<Scalars['Int']>
}

export type AssetLinkingCollectionsBlogPostsCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>
  locale?: InputMaybe<Scalars['String']>
  preview?: InputMaybe<Scalars['Boolean']>
  skip?: InputMaybe<Scalars['Int']>
}

export type AssetLinkingCollectionsCloudinaryAssetsCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>
  locale?: InputMaybe<Scalars['String']>
  preview?: InputMaybe<Scalars['Boolean']>
  skip?: InputMaybe<Scalars['Int']>
}

export type AssetLinkingCollectionsComponentCarouselItemCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>
  locale?: InputMaybe<Scalars['String']>
  preview?: InputMaybe<Scalars['Boolean']>
  skip?: InputMaybe<Scalars['Int']>
}

export type AssetLinkingCollectionsComponentCenteredHeroCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>
  locale?: InputMaybe<Scalars['String']>
  preview?: InputMaybe<Scalars['Boolean']>
  skip?: InputMaybe<Scalars['Int']>
}

export type AssetLinkingCollectionsComponentDataItemCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>
  locale?: InputMaybe<Scalars['String']>
  preview?: InputMaybe<Scalars['Boolean']>
  skip?: InputMaybe<Scalars['Int']>
}

export type AssetLinkingCollectionsComponentFeaturedReviewsCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>
  locale?: InputMaybe<Scalars['String']>
  preview?: InputMaybe<Scalars['Boolean']>
  skip?: InputMaybe<Scalars['Int']>
}

export type AssetLinkingCollectionsComponentForaLogoCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>
  locale?: InputMaybe<Scalars['String']>
  preview?: InputMaybe<Scalars['Boolean']>
  skip?: InputMaybe<Scalars['Int']>
}

export type AssetLinkingCollectionsComponentHeroCenteredCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>
  locale?: InputMaybe<Scalars['String']>
  preview?: InputMaybe<Scalars['Boolean']>
  skip?: InputMaybe<Scalars['Int']>
}

export type AssetLinkingCollectionsComponentHeroFullWidthCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>
  locale?: InputMaybe<Scalars['String']>
  preview?: InputMaybe<Scalars['Boolean']>
  skip?: InputMaybe<Scalars['Int']>
}

export type AssetLinkingCollectionsComponentImageCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>
  locale?: InputMaybe<Scalars['String']>
  preview?: InputMaybe<Scalars['Boolean']>
  skip?: InputMaybe<Scalars['Int']>
}

export type AssetLinkingCollectionsComponentImageGallerySlideItemCollectionArgs =
  {
    limit?: InputMaybe<Scalars['Int']>
    locale?: InputMaybe<Scalars['String']>
    preview?: InputMaybe<Scalars['Boolean']>
    skip?: InputMaybe<Scalars['Int']>
  }

export type AssetLinkingCollectionsComponentImageHeaderSubCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>
  locale?: InputMaybe<Scalars['String']>
  preview?: InputMaybe<Scalars['Boolean']>
  skip?: InputMaybe<Scalars['Int']>
}

export type AssetLinkingCollectionsComponentImageSideBySideCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>
  locale?: InputMaybe<Scalars['String']>
  preview?: InputMaybe<Scalars['Boolean']>
  skip?: InputMaybe<Scalars['Int']>
}

export type AssetLinkingCollectionsComponentLargePhotoCarouselItemCollectionArgs =
  {
    limit?: InputMaybe<Scalars['Int']>
    locale?: InputMaybe<Scalars['String']>
    preview?: InputMaybe<Scalars['Boolean']>
    skip?: InputMaybe<Scalars['Int']>
  }

export type AssetLinkingCollectionsComponentPictureSectionBannerCollectionArgs =
  {
    limit?: InputMaybe<Scalars['Int']>
    locale?: InputMaybe<Scalars['String']>
    preview?: InputMaybe<Scalars['Boolean']>
    skip?: InputMaybe<Scalars['Int']>
  }

export type AssetLinkingCollectionsComponentSpacerCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>
  locale?: InputMaybe<Scalars['String']>
  preview?: InputMaybe<Scalars['Boolean']>
  skip?: InputMaybe<Scalars['Int']>
}

export type AssetLinkingCollectionsComponentStickyHeaderCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>
  locale?: InputMaybe<Scalars['String']>
  preview?: InputMaybe<Scalars['Boolean']>
  skip?: InputMaybe<Scalars['Int']>
}

export type AssetLinkingCollectionsComponentThreeIconSectionCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>
  locale?: InputMaybe<Scalars['String']>
  preview?: InputMaybe<Scalars['Boolean']>
  skip?: InputMaybe<Scalars['Int']>
}

export type AssetLinkingCollectionsComponentThreeIconSectionWithPictureCollectionArgs =
  {
    limit?: InputMaybe<Scalars['Int']>
    locale?: InputMaybe<Scalars['String']>
    preview?: InputMaybe<Scalars['Boolean']>
    skip?: InputMaybe<Scalars['Int']>
  }

export type AssetLinkingCollectionsComponentTwoColumnCalloutCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>
  locale?: InputMaybe<Scalars['String']>
  preview?: InputMaybe<Scalars['Boolean']>
  skip?: InputMaybe<Scalars['Int']>
}

export type AssetLinkingCollectionsComponentTwoColumnPerksSectionCollectionArgs =
  {
    limit?: InputMaybe<Scalars['Int']>
    locale?: InputMaybe<Scalars['String']>
    preview?: InputMaybe<Scalars['Boolean']>
    skip?: InputMaybe<Scalars['Int']>
  }

export type AssetLinkingCollectionsComponentVideoSectionCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>
  locale?: InputMaybe<Scalars['String']>
  preview?: InputMaybe<Scalars['Boolean']>
  skip?: InputMaybe<Scalars['Int']>
}

export type AssetLinkingCollectionsComponentWhatYouGetCardCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>
  locale?: InputMaybe<Scalars['String']>
  preview?: InputMaybe<Scalars['Boolean']>
  skip?: InputMaybe<Scalars['Int']>
}

export type AssetLinkingCollectionsDestinationsCategoriesCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>
  locale?: InputMaybe<Scalars['String']>
  preview?: InputMaybe<Scalars['Boolean']>
  skip?: InputMaybe<Scalars['Int']>
}

export type AssetLinkingCollectionsDestinationsSubcategoriesCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>
  locale?: InputMaybe<Scalars['String']>
  preview?: InputMaybe<Scalars['Boolean']>
  skip?: InputMaybe<Scalars['Int']>
}

export type AssetLinkingCollectionsEntryCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>
  locale?: InputMaybe<Scalars['String']>
  preview?: InputMaybe<Scalars['Boolean']>
  skip?: InputMaybe<Scalars['Int']>
}

export type AssetLinkingCollectionsEntryPartnerCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>
  locale?: InputMaybe<Scalars['String']>
  preview?: InputMaybe<Scalars['Boolean']>
  skip?: InputMaybe<Scalars['Int']>
}

export type AssetLinkingCollectionsEntryTestimonialCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>
  locale?: InputMaybe<Scalars['String']>
  preview?: InputMaybe<Scalars['Boolean']>
  skip?: InputMaybe<Scalars['Int']>
}

export type AssetLinkingCollectionsEntryTripReportCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>
  locale?: InputMaybe<Scalars['String']>
  preview?: InputMaybe<Scalars['Boolean']>
  skip?: InputMaybe<Scalars['Int']>
}

export type AssetLinkingCollectionsEventsCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>
  locale?: InputMaybe<Scalars['String']>
  preview?: InputMaybe<Scalars['Boolean']>
  skip?: InputMaybe<Scalars['Int']>
}

export type AssetLinkingCollectionsHotelsCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>
  locale?: InputMaybe<Scalars['String']>
  preview?: InputMaybe<Scalars['Boolean']>
  skip?: InputMaybe<Scalars['Int']>
}

export type AssetLinkingCollectionsItinerariesCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>
  locale?: InputMaybe<Scalars['String']>
  preview?: InputMaybe<Scalars['Boolean']>
  skip?: InputMaybe<Scalars['Int']>
}

export type AssetLinkingCollectionsItinerariesPlansCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>
  locale?: InputMaybe<Scalars['String']>
  preview?: InputMaybe<Scalars['Boolean']>
  skip?: InputMaybe<Scalars['Int']>
}

export type AssetLinkingCollectionsPagesHeaderBlockCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>
  locale?: InputMaybe<Scalars['String']>
  preview?: InputMaybe<Scalars['Boolean']>
  skip?: InputMaybe<Scalars['Int']>
}

export type AssetLinkingCollectionsPagesHeroBlockCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>
  locale?: InputMaybe<Scalars['String']>
  preview?: InputMaybe<Scalars['Boolean']>
  skip?: InputMaybe<Scalars['Int']>
}

export type AssetLinkingCollectionsPagesLearnMoreBlockCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>
  locale?: InputMaybe<Scalars['String']>
  preview?: InputMaybe<Scalars['Boolean']>
  skip?: InputMaybe<Scalars['Int']>
}

export type AssetLinkingCollectionsPagesMetaContentCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>
  locale?: InputMaybe<Scalars['String']>
  preview?: InputMaybe<Scalars['Boolean']>
  skip?: InputMaybe<Scalars['Int']>
}

export type AssetLinkingCollectionsPagesPartnerBannerCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>
  locale?: InputMaybe<Scalars['String']>
  preview?: InputMaybe<Scalars['Boolean']>
  skip?: InputMaybe<Scalars['Int']>
}

export type AssetLinkingCollectionsPagesPerksListCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>
  locale?: InputMaybe<Scalars['String']>
  preview?: InputMaybe<Scalars['Boolean']>
  skip?: InputMaybe<Scalars['Int']>
}

export type AssetLinkingCollectionsPagesPressBannerCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>
  locale?: InputMaybe<Scalars['String']>
  preview?: InputMaybe<Scalars['Boolean']>
  skip?: InputMaybe<Scalars['Int']>
}

export type AssetLinkingCollectionsPagesTwoColumnCtaCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>
  locale?: InputMaybe<Scalars['String']>
  preview?: InputMaybe<Scalars['Boolean']>
  skip?: InputMaybe<Scalars['Int']>
}

export type AssetLinkingCollectionsPressCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>
  locale?: InputMaybe<Scalars['String']>
  preview?: InputMaybe<Scalars['Boolean']>
  skip?: InputMaybe<Scalars['Int']>
}

export type AssetLinkingCollectionsTeamCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>
  locale?: InputMaybe<Scalars['String']>
  preview?: InputMaybe<Scalars['Boolean']>
  skip?: InputMaybe<Scalars['Int']>
}

export type AssetLinkingCollectionsTemplateAboutUsCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>
  locale?: InputMaybe<Scalars['String']>
  preview?: InputMaybe<Scalars['Boolean']>
  skip?: InputMaybe<Scalars['Int']>
}

export type AssetLinkingCollectionsTemplatePageCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>
  locale?: InputMaybe<Scalars['String']>
  preview?: InputMaybe<Scalars['Boolean']>
  skip?: InputMaybe<Scalars['Int']>
}

export type AssetLinkingCollectionsTemplatePressCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>
  locale?: InputMaybe<Scalars['String']>
  preview?: InputMaybe<Scalars['Boolean']>
  skip?: InputMaybe<Scalars['Int']>
}

export type AssetLinkingCollectionsTravelByCategoriesCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>
  locale?: InputMaybe<Scalars['String']>
  preview?: InputMaybe<Scalars['Boolean']>
  skip?: InputMaybe<Scalars['Int']>
}

export type AssetLinkingCollectionsTravelBySubcategoriesCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>
  locale?: InputMaybe<Scalars['String']>
  preview?: InputMaybe<Scalars['Boolean']>
  skip?: InputMaybe<Scalars['Int']>
}

export enum AssetOrder {
  ContentTypeAsc = 'contentType_ASC',
  ContentTypeDesc = 'contentType_DESC',
  FileNameAsc = 'fileName_ASC',
  FileNameDesc = 'fileName_DESC',
  HeightAsc = 'height_ASC',
  HeightDesc = 'height_DESC',
  SizeAsc = 'size_ASC',
  SizeDesc = 'size_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  UrlAsc = 'url_ASC',
  UrlDesc = 'url_DESC',
  WidthAsc = 'width_ASC',
  WidthDesc = 'width_DESC',
}

/** Authors of corporate blog posts. Kept separate from Team members because authors may be Fora employees but not necessarily. [See type definition](https://app.contentful.com/spaces/9cxgygtadmvl/content_types/blogAuthors) */
export type BlogAuthors = Entry &
  _Node & {
    __typename?: 'BlogAuthors'
    _id: Scalars['ID']
    bio?: Maybe<BlogAuthorsBio>
    contentfulMetadata: ContentfulMetadata
    image?: Maybe<Asset>
    jobTitle?: Maybe<Scalars['String']>
    linkedFrom?: Maybe<BlogAuthorsLinkingCollections>
    name?: Maybe<Scalars['String']>
    sys: Sys
  }

/** Authors of corporate blog posts. Kept separate from Team members because authors may be Fora employees but not necessarily. [See type definition](https://app.contentful.com/spaces/9cxgygtadmvl/content_types/blogAuthors) */
export type BlogAuthorsBioArgs = {
  locale?: InputMaybe<Scalars['String']>
}

/** Authors of corporate blog posts. Kept separate from Team members because authors may be Fora employees but not necessarily. [See type definition](https://app.contentful.com/spaces/9cxgygtadmvl/content_types/blogAuthors) */
export type BlogAuthorsImageArgs = {
  locale?: InputMaybe<Scalars['String']>
  preview?: InputMaybe<Scalars['Boolean']>
}

/** Authors of corporate blog posts. Kept separate from Team members because authors may be Fora employees but not necessarily. [See type definition](https://app.contentful.com/spaces/9cxgygtadmvl/content_types/blogAuthors) */
export type BlogAuthorsJobTitleArgs = {
  locale?: InputMaybe<Scalars['String']>
}

/** Authors of corporate blog posts. Kept separate from Team members because authors may be Fora employees but not necessarily. [See type definition](https://app.contentful.com/spaces/9cxgygtadmvl/content_types/blogAuthors) */
export type BlogAuthorsLinkedFromArgs = {
  allowedLocales?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
}

/** Authors of corporate blog posts. Kept separate from Team members because authors may be Fora employees but not necessarily. [See type definition](https://app.contentful.com/spaces/9cxgygtadmvl/content_types/blogAuthors) */
export type BlogAuthorsNameArgs = {
  locale?: InputMaybe<Scalars['String']>
}

export type BlogAuthorsBio = {
  __typename?: 'BlogAuthorsBio'
  json: Scalars['JSON']
  links: BlogAuthorsBioLinks
}

export type BlogAuthorsBioAssets = {
  __typename?: 'BlogAuthorsBioAssets'
  block: Array<Maybe<Asset>>
  hyperlink: Array<Maybe<Asset>>
}

export type BlogAuthorsBioEntries = {
  __typename?: 'BlogAuthorsBioEntries'
  block: Array<Maybe<Entry>>
  hyperlink: Array<Maybe<Entry>>
  inline: Array<Maybe<Entry>>
}

export type BlogAuthorsBioLinks = {
  __typename?: 'BlogAuthorsBioLinks'
  assets: BlogAuthorsBioAssets
  entries: BlogAuthorsBioEntries
  resources: BlogAuthorsBioResources
}

export type BlogAuthorsBioResources = {
  __typename?: 'BlogAuthorsBioResources'
  block: Array<BlogAuthorsBioResourcesBlock>
  hyperlink: Array<BlogAuthorsBioResourcesHyperlink>
  inline: Array<BlogAuthorsBioResourcesInline>
}

export type BlogAuthorsBioResourcesBlock = ResourceLink & {
  __typename?: 'BlogAuthorsBioResourcesBlock'
  sys: ResourceSys
}

export type BlogAuthorsBioResourcesHyperlink = ResourceLink & {
  __typename?: 'BlogAuthorsBioResourcesHyperlink'
  sys: ResourceSys
}

export type BlogAuthorsBioResourcesInline = ResourceLink & {
  __typename?: 'BlogAuthorsBioResourcesInline'
  sys: ResourceSys
}

export type BlogAuthorsCollection = {
  __typename?: 'BlogAuthorsCollection'
  items: Array<Maybe<BlogAuthors>>
  limit: Scalars['Int']
  skip: Scalars['Int']
  total: Scalars['Int']
}

export type BlogAuthorsFilter = {
  AND?: InputMaybe<Array<InputMaybe<BlogAuthorsFilter>>>
  OR?: InputMaybe<Array<InputMaybe<BlogAuthorsFilter>>>
  bio_contains?: InputMaybe<Scalars['String']>
  bio_exists?: InputMaybe<Scalars['Boolean']>
  bio_not_contains?: InputMaybe<Scalars['String']>
  contentfulMetadata?: InputMaybe<ContentfulMetadataFilter>
  image_exists?: InputMaybe<Scalars['Boolean']>
  jobTitle?: InputMaybe<Scalars['String']>
  jobTitle_contains?: InputMaybe<Scalars['String']>
  jobTitle_exists?: InputMaybe<Scalars['Boolean']>
  jobTitle_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  jobTitle_not?: InputMaybe<Scalars['String']>
  jobTitle_not_contains?: InputMaybe<Scalars['String']>
  jobTitle_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  name?: InputMaybe<Scalars['String']>
  name_contains?: InputMaybe<Scalars['String']>
  name_exists?: InputMaybe<Scalars['Boolean']>
  name_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  name_not?: InputMaybe<Scalars['String']>
  name_not_contains?: InputMaybe<Scalars['String']>
  name_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  sys?: InputMaybe<SysFilter>
}

export type BlogAuthorsLinkingCollections = {
  __typename?: 'BlogAuthorsLinkingCollections'
  blogPostsCollection?: Maybe<BlogPostsCollection>
  entryCollection?: Maybe<EntryCollection>
}

export type BlogAuthorsLinkingCollectionsBlogPostsCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>
  locale?: InputMaybe<Scalars['String']>
  order?: InputMaybe<
    Array<InputMaybe<BlogAuthorsLinkingCollectionsBlogPostsCollectionOrder>>
  >
  preview?: InputMaybe<Scalars['Boolean']>
  skip?: InputMaybe<Scalars['Int']>
}

export type BlogAuthorsLinkingCollectionsEntryCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>
  locale?: InputMaybe<Scalars['String']>
  preview?: InputMaybe<Scalars['Boolean']>
  skip?: InputMaybe<Scalars['Int']>
}

export enum BlogAuthorsLinkingCollectionsBlogPostsCollectionOrder {
  BlogCategoryAsc = 'blogCategory_ASC',
  BlogCategoryDesc = 'blogCategory_DESC',
  BottomCtaAsc = 'bottomCta_ASC',
  BottomCtaDesc = 'bottomCta_DESC',
  EnableAdvisorShareAsc = 'enableAdvisorShare_ASC',
  EnableAdvisorShareDesc = 'enableAdvisorShare_DESC',
  MetaDescriptionAsc = 'metaDescription_ASC',
  MetaDescriptionDesc = 'metaDescription_DESC',
  MetaTitleAsc = 'metaTitle_ASC',
  MetaTitleDesc = 'metaTitle_DESC',
  NoindexNofollowAsc = 'noindexNofollow_ASC',
  NoindexNofollowDesc = 'noindexNofollow_DESC',
  PublishedDateAsc = 'publishedDate_ASC',
  PublishedDateDesc = 'publishedDate_DESC',
  ShowOnBlogCategoryPageAsc = 'showOnBlogCategoryPage_ASC',
  ShowOnBlogCategoryPageDesc = 'showOnBlogCategoryPage_DESC',
  ShowOnBlogLandingPageAsc = 'showOnBlogLandingPage_ASC',
  ShowOnBlogLandingPageDesc = 'showOnBlogLandingPage_DESC',
  ShowOnTravelPagesAsc = 'showOnTravelPages_ASC',
  ShowOnTravelPagesDesc = 'showOnTravelPages_DESC',
  SlugAsc = 'slug_ASC',
  SlugDesc = 'slug_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC',
}

export enum BlogAuthorsOrder {
  JobTitleAsc = 'jobTitle_ASC',
  JobTitleDesc = 'jobTitle_DESC',
  NameAsc = 'name_ASC',
  NameDesc = 'name_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
}

/** Corporate-authored blog posts covering community updates, content roundups, and corporate news. [See type definition](https://app.contentful.com/spaces/9cxgygtadmvl/content_types/blogPosts) */
export type BlogPosts = Entry &
  _Node & {
    __typename?: 'BlogPosts'
    _id: Scalars['ID']
    author?: Maybe<BlogAuthors>
    blogCategory?: Maybe<Scalars['String']>
    bottomCta?: Maybe<Scalars['String']>
    content?: Maybe<BlogPostsContent>
    contentfulMetadata: ContentfulMetadata
    enableAdvisorShare?: Maybe<Scalars['Boolean']>
    featuredImage?: Maybe<Asset>
    internalComments?: Maybe<Scalars['String']>
    linkedFrom?: Maybe<BlogPostsLinkingCollections>
    metaDescription?: Maybe<Scalars['String']>
    metaTitle?: Maybe<Scalars['String']>
    noindexNofollow?: Maybe<Scalars['Boolean']>
    publishedDate?: Maybe<Scalars['DateTime']>
    relatedCategoriesCollection?: Maybe<BlogPostsRelatedCategoriesCollection>
    showOnBlogCategoryPage?: Maybe<Scalars['Boolean']>
    showOnBlogLandingPage?: Maybe<Scalars['Boolean']>
    showOnTravelPages?: Maybe<Scalars['Boolean']>
    slug?: Maybe<Scalars['String']>
    sys: Sys
    title?: Maybe<Scalars['String']>
  }

/** Corporate-authored blog posts covering community updates, content roundups, and corporate news. [See type definition](https://app.contentful.com/spaces/9cxgygtadmvl/content_types/blogPosts) */
export type BlogPostsAuthorArgs = {
  locale?: InputMaybe<Scalars['String']>
  preview?: InputMaybe<Scalars['Boolean']>
  where?: InputMaybe<BlogAuthorsFilter>
}

/** Corporate-authored blog posts covering community updates, content roundups, and corporate news. [See type definition](https://app.contentful.com/spaces/9cxgygtadmvl/content_types/blogPosts) */
export type BlogPostsBlogCategoryArgs = {
  locale?: InputMaybe<Scalars['String']>
}

/** Corporate-authored blog posts covering community updates, content roundups, and corporate news. [See type definition](https://app.contentful.com/spaces/9cxgygtadmvl/content_types/blogPosts) */
export type BlogPostsBottomCtaArgs = {
  locale?: InputMaybe<Scalars['String']>
}

/** Corporate-authored blog posts covering community updates, content roundups, and corporate news. [See type definition](https://app.contentful.com/spaces/9cxgygtadmvl/content_types/blogPosts) */
export type BlogPostsContentArgs = {
  locale?: InputMaybe<Scalars['String']>
}

/** Corporate-authored blog posts covering community updates, content roundups, and corporate news. [See type definition](https://app.contentful.com/spaces/9cxgygtadmvl/content_types/blogPosts) */
export type BlogPostsEnableAdvisorShareArgs = {
  locale?: InputMaybe<Scalars['String']>
}

/** Corporate-authored blog posts covering community updates, content roundups, and corporate news. [See type definition](https://app.contentful.com/spaces/9cxgygtadmvl/content_types/blogPosts) */
export type BlogPostsFeaturedImageArgs = {
  locale?: InputMaybe<Scalars['String']>
  preview?: InputMaybe<Scalars['Boolean']>
}

/** Corporate-authored blog posts covering community updates, content roundups, and corporate news. [See type definition](https://app.contentful.com/spaces/9cxgygtadmvl/content_types/blogPosts) */
export type BlogPostsInternalCommentsArgs = {
  locale?: InputMaybe<Scalars['String']>
}

/** Corporate-authored blog posts covering community updates, content roundups, and corporate news. [See type definition](https://app.contentful.com/spaces/9cxgygtadmvl/content_types/blogPosts) */
export type BlogPostsLinkedFromArgs = {
  allowedLocales?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
}

/** Corporate-authored blog posts covering community updates, content roundups, and corporate news. [See type definition](https://app.contentful.com/spaces/9cxgygtadmvl/content_types/blogPosts) */
export type BlogPostsMetaDescriptionArgs = {
  locale?: InputMaybe<Scalars['String']>
}

/** Corporate-authored blog posts covering community updates, content roundups, and corporate news. [See type definition](https://app.contentful.com/spaces/9cxgygtadmvl/content_types/blogPosts) */
export type BlogPostsMetaTitleArgs = {
  locale?: InputMaybe<Scalars['String']>
}

/** Corporate-authored blog posts covering community updates, content roundups, and corporate news. [See type definition](https://app.contentful.com/spaces/9cxgygtadmvl/content_types/blogPosts) */
export type BlogPostsNoindexNofollowArgs = {
  locale?: InputMaybe<Scalars['String']>
}

/** Corporate-authored blog posts covering community updates, content roundups, and corporate news. [See type definition](https://app.contentful.com/spaces/9cxgygtadmvl/content_types/blogPosts) */
export type BlogPostsPublishedDateArgs = {
  locale?: InputMaybe<Scalars['String']>
}

/** Corporate-authored blog posts covering community updates, content roundups, and corporate news. [See type definition](https://app.contentful.com/spaces/9cxgygtadmvl/content_types/blogPosts) */
export type BlogPostsRelatedCategoriesCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>
  locale?: InputMaybe<Scalars['String']>
  preview?: InputMaybe<Scalars['Boolean']>
  skip?: InputMaybe<Scalars['Int']>
  where?: InputMaybe<BlogPostsRelatedCategoriesFilter>
}

/** Corporate-authored blog posts covering community updates, content roundups, and corporate news. [See type definition](https://app.contentful.com/spaces/9cxgygtadmvl/content_types/blogPosts) */
export type BlogPostsShowOnBlogCategoryPageArgs = {
  locale?: InputMaybe<Scalars['String']>
}

/** Corporate-authored blog posts covering community updates, content roundups, and corporate news. [See type definition](https://app.contentful.com/spaces/9cxgygtadmvl/content_types/blogPosts) */
export type BlogPostsShowOnBlogLandingPageArgs = {
  locale?: InputMaybe<Scalars['String']>
}

/** Corporate-authored blog posts covering community updates, content roundups, and corporate news. [See type definition](https://app.contentful.com/spaces/9cxgygtadmvl/content_types/blogPosts) */
export type BlogPostsShowOnTravelPagesArgs = {
  locale?: InputMaybe<Scalars['String']>
}

/** Corporate-authored blog posts covering community updates, content roundups, and corporate news. [See type definition](https://app.contentful.com/spaces/9cxgygtadmvl/content_types/blogPosts) */
export type BlogPostsSlugArgs = {
  locale?: InputMaybe<Scalars['String']>
}

/** Corporate-authored blog posts covering community updates, content roundups, and corporate news. [See type definition](https://app.contentful.com/spaces/9cxgygtadmvl/content_types/blogPosts) */
export type BlogPostsTitleArgs = {
  locale?: InputMaybe<Scalars['String']>
}

export type BlogPostsCollection = {
  __typename?: 'BlogPostsCollection'
  items: Array<Maybe<BlogPosts>>
  limit: Scalars['Int']
  skip: Scalars['Int']
  total: Scalars['Int']
}

export type BlogPostsContent = {
  __typename?: 'BlogPostsContent'
  json: Scalars['JSON']
  links: BlogPostsContentLinks
}

export type BlogPostsContentAssets = {
  __typename?: 'BlogPostsContentAssets'
  block: Array<Maybe<Asset>>
  hyperlink: Array<Maybe<Asset>>
}

export type BlogPostsContentEntries = {
  __typename?: 'BlogPostsContentEntries'
  block: Array<Maybe<Entry>>
  hyperlink: Array<Maybe<Entry>>
  inline: Array<Maybe<Entry>>
}

export type BlogPostsContentLinks = {
  __typename?: 'BlogPostsContentLinks'
  assets: BlogPostsContentAssets
  entries: BlogPostsContentEntries
  resources: BlogPostsContentResources
}

export type BlogPostsContentResources = {
  __typename?: 'BlogPostsContentResources'
  block: Array<BlogPostsContentResourcesBlock>
  hyperlink: Array<BlogPostsContentResourcesHyperlink>
  inline: Array<BlogPostsContentResourcesInline>
}

export type BlogPostsContentResourcesBlock = ResourceLink & {
  __typename?: 'BlogPostsContentResourcesBlock'
  sys: ResourceSys
}

export type BlogPostsContentResourcesHyperlink = ResourceLink & {
  __typename?: 'BlogPostsContentResourcesHyperlink'
  sys: ResourceSys
}

export type BlogPostsContentResourcesInline = ResourceLink & {
  __typename?: 'BlogPostsContentResourcesInline'
  sys: ResourceSys
}

export type BlogPostsFilter = {
  AND?: InputMaybe<Array<InputMaybe<BlogPostsFilter>>>
  OR?: InputMaybe<Array<InputMaybe<BlogPostsFilter>>>
  author?: InputMaybe<CfBlogAuthorsNestedFilter>
  author_exists?: InputMaybe<Scalars['Boolean']>
  blogCategory?: InputMaybe<Scalars['String']>
  blogCategory_contains?: InputMaybe<Scalars['String']>
  blogCategory_exists?: InputMaybe<Scalars['Boolean']>
  blogCategory_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  blogCategory_not?: InputMaybe<Scalars['String']>
  blogCategory_not_contains?: InputMaybe<Scalars['String']>
  blogCategory_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  bottomCta?: InputMaybe<Scalars['String']>
  bottomCta_contains?: InputMaybe<Scalars['String']>
  bottomCta_exists?: InputMaybe<Scalars['Boolean']>
  bottomCta_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  bottomCta_not?: InputMaybe<Scalars['String']>
  bottomCta_not_contains?: InputMaybe<Scalars['String']>
  bottomCta_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  content_contains?: InputMaybe<Scalars['String']>
  content_exists?: InputMaybe<Scalars['Boolean']>
  content_not_contains?: InputMaybe<Scalars['String']>
  contentfulMetadata?: InputMaybe<ContentfulMetadataFilter>
  enableAdvisorShare?: InputMaybe<Scalars['Boolean']>
  enableAdvisorShare_exists?: InputMaybe<Scalars['Boolean']>
  enableAdvisorShare_not?: InputMaybe<Scalars['Boolean']>
  featuredImage_exists?: InputMaybe<Scalars['Boolean']>
  internalComments?: InputMaybe<Scalars['String']>
  internalComments_contains?: InputMaybe<Scalars['String']>
  internalComments_exists?: InputMaybe<Scalars['Boolean']>
  internalComments_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  internalComments_not?: InputMaybe<Scalars['String']>
  internalComments_not_contains?: InputMaybe<Scalars['String']>
  internalComments_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  metaDescription?: InputMaybe<Scalars['String']>
  metaDescription_contains?: InputMaybe<Scalars['String']>
  metaDescription_exists?: InputMaybe<Scalars['Boolean']>
  metaDescription_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  metaDescription_not?: InputMaybe<Scalars['String']>
  metaDescription_not_contains?: InputMaybe<Scalars['String']>
  metaDescription_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  metaTitle?: InputMaybe<Scalars['String']>
  metaTitle_contains?: InputMaybe<Scalars['String']>
  metaTitle_exists?: InputMaybe<Scalars['Boolean']>
  metaTitle_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  metaTitle_not?: InputMaybe<Scalars['String']>
  metaTitle_not_contains?: InputMaybe<Scalars['String']>
  metaTitle_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  noindexNofollow?: InputMaybe<Scalars['Boolean']>
  noindexNofollow_exists?: InputMaybe<Scalars['Boolean']>
  noindexNofollow_not?: InputMaybe<Scalars['Boolean']>
  publishedDate?: InputMaybe<Scalars['DateTime']>
  publishedDate_exists?: InputMaybe<Scalars['Boolean']>
  publishedDate_gt?: InputMaybe<Scalars['DateTime']>
  publishedDate_gte?: InputMaybe<Scalars['DateTime']>
  publishedDate_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']>>>
  publishedDate_lt?: InputMaybe<Scalars['DateTime']>
  publishedDate_lte?: InputMaybe<Scalars['DateTime']>
  publishedDate_not?: InputMaybe<Scalars['DateTime']>
  publishedDate_not_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']>>>
  relatedCategories?: InputMaybe<CfrelatedCategoriesMultiTypeNestedFilter>
  relatedCategoriesCollection_exists?: InputMaybe<Scalars['Boolean']>
  showOnBlogCategoryPage?: InputMaybe<Scalars['Boolean']>
  showOnBlogCategoryPage_exists?: InputMaybe<Scalars['Boolean']>
  showOnBlogCategoryPage_not?: InputMaybe<Scalars['Boolean']>
  showOnBlogLandingPage?: InputMaybe<Scalars['Boolean']>
  showOnBlogLandingPage_exists?: InputMaybe<Scalars['Boolean']>
  showOnBlogLandingPage_not?: InputMaybe<Scalars['Boolean']>
  showOnTravelPages?: InputMaybe<Scalars['Boolean']>
  showOnTravelPages_exists?: InputMaybe<Scalars['Boolean']>
  showOnTravelPages_not?: InputMaybe<Scalars['Boolean']>
  slug?: InputMaybe<Scalars['String']>
  slug_contains?: InputMaybe<Scalars['String']>
  slug_exists?: InputMaybe<Scalars['Boolean']>
  slug_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  slug_not?: InputMaybe<Scalars['String']>
  slug_not_contains?: InputMaybe<Scalars['String']>
  slug_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  sys?: InputMaybe<SysFilter>
  title?: InputMaybe<Scalars['String']>
  title_contains?: InputMaybe<Scalars['String']>
  title_exists?: InputMaybe<Scalars['Boolean']>
  title_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  title_not?: InputMaybe<Scalars['String']>
  title_not_contains?: InputMaybe<Scalars['String']>
  title_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
}

export type BlogPostsLinkingCollections = {
  __typename?: 'BlogPostsLinkingCollections'
  entryCollection?: Maybe<EntryCollection>
  entryPartnerCollection?: Maybe<EntryPartnerCollection>
}

export type BlogPostsLinkingCollectionsEntryCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>
  locale?: InputMaybe<Scalars['String']>
  preview?: InputMaybe<Scalars['Boolean']>
  skip?: InputMaybe<Scalars['Int']>
}

export type BlogPostsLinkingCollectionsEntryPartnerCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>
  locale?: InputMaybe<Scalars['String']>
  order?: InputMaybe<
    Array<InputMaybe<BlogPostsLinkingCollectionsEntryPartnerCollectionOrder>>
  >
  preview?: InputMaybe<Scalars['Boolean']>
  skip?: InputMaybe<Scalars['Int']>
}

export enum BlogPostsLinkingCollectionsEntryPartnerCollectionOrder {
  DetailSummaryAsc = 'detailSummary_ASC',
  DetailSummaryDesc = 'detailSummary_DESC',
  FeaturedAsc = 'featured_ASC',
  FeaturedDesc = 'featured_DESC',
  HasDetailPageAsc = 'hasDetailPage_ASC',
  HasDetailPageDesc = 'hasDetailPage_DESC',
  HideFromPartnerGridAsc = 'hideFromPartnerGrid_ASC',
  HideFromPartnerGridDesc = 'hideFromPartnerGrid_DESC',
  NameAsc = 'name_ASC',
  NameDesc = 'name_DESC',
  PartnerCategoryAsc = 'partnerCategory_ASC',
  PartnerCategoryDesc = 'partnerCategory_DESC',
  PartnerTypeAsc = 'partnerType_ASC',
  PartnerTypeDesc = 'partnerType_DESC',
  SlugAsc = 'slug_ASC',
  SlugDesc = 'slug_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
}

export enum BlogPostsOrder {
  BlogCategoryAsc = 'blogCategory_ASC',
  BlogCategoryDesc = 'blogCategory_DESC',
  BottomCtaAsc = 'bottomCta_ASC',
  BottomCtaDesc = 'bottomCta_DESC',
  EnableAdvisorShareAsc = 'enableAdvisorShare_ASC',
  EnableAdvisorShareDesc = 'enableAdvisorShare_DESC',
  MetaDescriptionAsc = 'metaDescription_ASC',
  MetaDescriptionDesc = 'metaDescription_DESC',
  MetaTitleAsc = 'metaTitle_ASC',
  MetaTitleDesc = 'metaTitle_DESC',
  NoindexNofollowAsc = 'noindexNofollow_ASC',
  NoindexNofollowDesc = 'noindexNofollow_DESC',
  PublishedDateAsc = 'publishedDate_ASC',
  PublishedDateDesc = 'publishedDate_DESC',
  ShowOnBlogCategoryPageAsc = 'showOnBlogCategoryPage_ASC',
  ShowOnBlogCategoryPageDesc = 'showOnBlogCategoryPage_DESC',
  ShowOnBlogLandingPageAsc = 'showOnBlogLandingPage_ASC',
  ShowOnBlogLandingPageDesc = 'showOnBlogLandingPage_DESC',
  ShowOnTravelPagesAsc = 'showOnTravelPages_ASC',
  ShowOnTravelPagesDesc = 'showOnTravelPages_DESC',
  SlugAsc = 'slug_ASC',
  SlugDesc = 'slug_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC',
}

export type BlogPostsRelatedCategoriesCollection = {
  __typename?: 'BlogPostsRelatedCategoriesCollection'
  items: Array<Maybe<BlogPostsRelatedCategoriesItem>>
  limit: Scalars['Int']
  skip: Scalars['Int']
  total: Scalars['Int']
}

export type BlogPostsRelatedCategoriesFilter = {
  AND?: InputMaybe<Array<InputMaybe<BlogPostsRelatedCategoriesFilter>>>
  OR?: InputMaybe<Array<InputMaybe<BlogPostsRelatedCategoriesFilter>>>
  contentfulMetadata?: InputMaybe<ContentfulMetadataFilter>
  featuredImage_exists?: InputMaybe<Scalars['Boolean']>
  generateLandingPage?: InputMaybe<Scalars['Boolean']>
  generateLandingPage_exists?: InputMaybe<Scalars['Boolean']>
  generateLandingPage_not?: InputMaybe<Scalars['Boolean']>
  metaContent_exists?: InputMaybe<Scalars['Boolean']>
  questionsCollection_exists?: InputMaybe<Scalars['Boolean']>
  slug?: InputMaybe<Scalars['String']>
  slug_contains?: InputMaybe<Scalars['String']>
  slug_exists?: InputMaybe<Scalars['Boolean']>
  slug_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  slug_not?: InputMaybe<Scalars['String']>
  slug_not_contains?: InputMaybe<Scalars['String']>
  slug_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  sys?: InputMaybe<SysFilter>
  teaserCopy_contains?: InputMaybe<Scalars['String']>
  teaserCopy_exists?: InputMaybe<Scalars['Boolean']>
  teaserCopy_not_contains?: InputMaybe<Scalars['String']>
  title?: InputMaybe<Scalars['String']>
  title_contains?: InputMaybe<Scalars['String']>
  title_exists?: InputMaybe<Scalars['Boolean']>
  title_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  title_not?: InputMaybe<Scalars['String']>
  title_not_contains?: InputMaybe<Scalars['String']>
  title_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
}

export type BlogPostsRelatedCategoriesItem =
  | DestinationsSubcategories
  | TravelBySubcategories

/** [See type definition](https://app.contentful.com/spaces/9cxgygtadmvl/content_types/brynetteMediaTest) */
export type BrynetteMediaTest = Entry &
  _Node & {
    __typename?: 'BrynetteMediaTest'
    _id: Scalars['ID']
    contentfulMetadata: ContentfulMetadata
    linkedFrom?: Maybe<BrynetteMediaTestLinkingCollections>
    mediaType?: Maybe<Array<Maybe<Scalars['String']>>>
    sys: Sys
  }

/** [See type definition](https://app.contentful.com/spaces/9cxgygtadmvl/content_types/brynetteMediaTest) */
export type BrynetteMediaTestLinkedFromArgs = {
  allowedLocales?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
}

/** [See type definition](https://app.contentful.com/spaces/9cxgygtadmvl/content_types/brynetteMediaTest) */
export type BrynetteMediaTestMediaTypeArgs = {
  locale?: InputMaybe<Scalars['String']>
}

export type BrynetteMediaTestCollection = {
  __typename?: 'BrynetteMediaTestCollection'
  items: Array<Maybe<BrynetteMediaTest>>
  limit: Scalars['Int']
  skip: Scalars['Int']
  total: Scalars['Int']
}

export type BrynetteMediaTestFilter = {
  AND?: InputMaybe<Array<InputMaybe<BrynetteMediaTestFilter>>>
  OR?: InputMaybe<Array<InputMaybe<BrynetteMediaTestFilter>>>
  contentfulMetadata?: InputMaybe<ContentfulMetadataFilter>
  mediaType_contains_all?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  mediaType_contains_none?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  mediaType_contains_some?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  mediaType_exists?: InputMaybe<Scalars['Boolean']>
  sys?: InputMaybe<SysFilter>
}

export type BrynetteMediaTestLinkingCollections = {
  __typename?: 'BrynetteMediaTestLinkingCollections'
  entryCollection?: Maybe<EntryCollection>
}

export type BrynetteMediaTestLinkingCollectionsEntryCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>
  locale?: InputMaybe<Scalars['String']>
  preview?: InputMaybe<Scalars['Boolean']>
  skip?: InputMaybe<Scalars['Int']>
}

export enum BrynetteMediaTestOrder {
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
}

/** Marketing campaign landing pages [See type definition](https://app.contentful.com/spaces/9cxgygtadmvl/content_types/campaignPages) */
export type CampaignPages = Entry &
  _Node & {
    __typename?: 'CampaignPages'
    _id: Scalars['ID']
    analyticsModule?: Maybe<SettingsAnalyticsModule>
    collapseFooter?: Maybe<Scalars['Boolean']>
    collapseHeader?: Maybe<Scalars['Boolean']>
    content?: Maybe<CampaignPagesContent>
    contentfulMetadata: ContentfulMetadata
    hideFooter?: Maybe<Scalars['Boolean']>
    hideGlobalBanner?: Maybe<Scalars['Boolean']>
    jotformId?: Maybe<Scalars['String']>
    linkedFrom?: Maybe<CampaignPagesLinkingCollections>
    metaContent?: Maybe<PagesMetaContent>
    noindexNofollow?: Maybe<Scalars['Boolean']>
    pageComponentsCollection?: Maybe<CampaignPagesPageComponentsCollection>
    showHeader?: Maybe<Scalars['Boolean']>
    slug?: Maybe<Scalars['String']>
    sys: Sys
    title?: Maybe<Scalars['String']>
  }

/** Marketing campaign landing pages [See type definition](https://app.contentful.com/spaces/9cxgygtadmvl/content_types/campaignPages) */
export type CampaignPagesAnalyticsModuleArgs = {
  locale?: InputMaybe<Scalars['String']>
  preview?: InputMaybe<Scalars['Boolean']>
  where?: InputMaybe<SettingsAnalyticsModuleFilter>
}

/** Marketing campaign landing pages [See type definition](https://app.contentful.com/spaces/9cxgygtadmvl/content_types/campaignPages) */
export type CampaignPagesCollapseFooterArgs = {
  locale?: InputMaybe<Scalars['String']>
}

/** Marketing campaign landing pages [See type definition](https://app.contentful.com/spaces/9cxgygtadmvl/content_types/campaignPages) */
export type CampaignPagesCollapseHeaderArgs = {
  locale?: InputMaybe<Scalars['String']>
}

/** Marketing campaign landing pages [See type definition](https://app.contentful.com/spaces/9cxgygtadmvl/content_types/campaignPages) */
export type CampaignPagesContentArgs = {
  locale?: InputMaybe<Scalars['String']>
}

/** Marketing campaign landing pages [See type definition](https://app.contentful.com/spaces/9cxgygtadmvl/content_types/campaignPages) */
export type CampaignPagesHideFooterArgs = {
  locale?: InputMaybe<Scalars['String']>
}

/** Marketing campaign landing pages [See type definition](https://app.contentful.com/spaces/9cxgygtadmvl/content_types/campaignPages) */
export type CampaignPagesHideGlobalBannerArgs = {
  locale?: InputMaybe<Scalars['String']>
}

/** Marketing campaign landing pages [See type definition](https://app.contentful.com/spaces/9cxgygtadmvl/content_types/campaignPages) */
export type CampaignPagesJotformIdArgs = {
  locale?: InputMaybe<Scalars['String']>
}

/** Marketing campaign landing pages [See type definition](https://app.contentful.com/spaces/9cxgygtadmvl/content_types/campaignPages) */
export type CampaignPagesLinkedFromArgs = {
  allowedLocales?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
}

/** Marketing campaign landing pages [See type definition](https://app.contentful.com/spaces/9cxgygtadmvl/content_types/campaignPages) */
export type CampaignPagesMetaContentArgs = {
  locale?: InputMaybe<Scalars['String']>
  preview?: InputMaybe<Scalars['Boolean']>
  where?: InputMaybe<PagesMetaContentFilter>
}

/** Marketing campaign landing pages [See type definition](https://app.contentful.com/spaces/9cxgygtadmvl/content_types/campaignPages) */
export type CampaignPagesNoindexNofollowArgs = {
  locale?: InputMaybe<Scalars['String']>
}

/** Marketing campaign landing pages [See type definition](https://app.contentful.com/spaces/9cxgygtadmvl/content_types/campaignPages) */
export type CampaignPagesPageComponentsCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>
  locale?: InputMaybe<Scalars['String']>
  preview?: InputMaybe<Scalars['Boolean']>
  skip?: InputMaybe<Scalars['Int']>
}

/** Marketing campaign landing pages [See type definition](https://app.contentful.com/spaces/9cxgygtadmvl/content_types/campaignPages) */
export type CampaignPagesShowHeaderArgs = {
  locale?: InputMaybe<Scalars['String']>
}

/** Marketing campaign landing pages [See type definition](https://app.contentful.com/spaces/9cxgygtadmvl/content_types/campaignPages) */
export type CampaignPagesSlugArgs = {
  locale?: InputMaybe<Scalars['String']>
}

/** Marketing campaign landing pages [See type definition](https://app.contentful.com/spaces/9cxgygtadmvl/content_types/campaignPages) */
export type CampaignPagesTitleArgs = {
  locale?: InputMaybe<Scalars['String']>
}

export type CampaignPagesCollection = {
  __typename?: 'CampaignPagesCollection'
  items: Array<Maybe<CampaignPages>>
  limit: Scalars['Int']
  skip: Scalars['Int']
  total: Scalars['Int']
}

export type CampaignPagesContent = {
  __typename?: 'CampaignPagesContent'
  json: Scalars['JSON']
  links: CampaignPagesContentLinks
}

export type CampaignPagesContentAssets = {
  __typename?: 'CampaignPagesContentAssets'
  block: Array<Maybe<Asset>>
  hyperlink: Array<Maybe<Asset>>
}

export type CampaignPagesContentEntries = {
  __typename?: 'CampaignPagesContentEntries'
  block: Array<Maybe<Entry>>
  hyperlink: Array<Maybe<Entry>>
  inline: Array<Maybe<Entry>>
}

export type CampaignPagesContentLinks = {
  __typename?: 'CampaignPagesContentLinks'
  assets: CampaignPagesContentAssets
  entries: CampaignPagesContentEntries
  resources: CampaignPagesContentResources
}

export type CampaignPagesContentResources = {
  __typename?: 'CampaignPagesContentResources'
  block: Array<CampaignPagesContentResourcesBlock>
  hyperlink: Array<CampaignPagesContentResourcesHyperlink>
  inline: Array<CampaignPagesContentResourcesInline>
}

export type CampaignPagesContentResourcesBlock = ResourceLink & {
  __typename?: 'CampaignPagesContentResourcesBlock'
  sys: ResourceSys
}

export type CampaignPagesContentResourcesHyperlink = ResourceLink & {
  __typename?: 'CampaignPagesContentResourcesHyperlink'
  sys: ResourceSys
}

export type CampaignPagesContentResourcesInline = ResourceLink & {
  __typename?: 'CampaignPagesContentResourcesInline'
  sys: ResourceSys
}

export type CampaignPagesFilter = {
  AND?: InputMaybe<Array<InputMaybe<CampaignPagesFilter>>>
  OR?: InputMaybe<Array<InputMaybe<CampaignPagesFilter>>>
  analyticsModule?: InputMaybe<CfSettingsAnalyticsModuleNestedFilter>
  analyticsModule_exists?: InputMaybe<Scalars['Boolean']>
  collapseFooter?: InputMaybe<Scalars['Boolean']>
  collapseFooter_exists?: InputMaybe<Scalars['Boolean']>
  collapseFooter_not?: InputMaybe<Scalars['Boolean']>
  collapseHeader?: InputMaybe<Scalars['Boolean']>
  collapseHeader_exists?: InputMaybe<Scalars['Boolean']>
  collapseHeader_not?: InputMaybe<Scalars['Boolean']>
  content_contains?: InputMaybe<Scalars['String']>
  content_exists?: InputMaybe<Scalars['Boolean']>
  content_not_contains?: InputMaybe<Scalars['String']>
  contentfulMetadata?: InputMaybe<ContentfulMetadataFilter>
  hideFooter?: InputMaybe<Scalars['Boolean']>
  hideFooter_exists?: InputMaybe<Scalars['Boolean']>
  hideFooter_not?: InputMaybe<Scalars['Boolean']>
  hideGlobalBanner?: InputMaybe<Scalars['Boolean']>
  hideGlobalBanner_exists?: InputMaybe<Scalars['Boolean']>
  hideGlobalBanner_not?: InputMaybe<Scalars['Boolean']>
  jotformId?: InputMaybe<Scalars['String']>
  jotformId_contains?: InputMaybe<Scalars['String']>
  jotformId_exists?: InputMaybe<Scalars['Boolean']>
  jotformId_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  jotformId_not?: InputMaybe<Scalars['String']>
  jotformId_not_contains?: InputMaybe<Scalars['String']>
  jotformId_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  metaContent?: InputMaybe<CfPagesMetaContentNestedFilter>
  metaContent_exists?: InputMaybe<Scalars['Boolean']>
  noindexNofollow?: InputMaybe<Scalars['Boolean']>
  noindexNofollow_exists?: InputMaybe<Scalars['Boolean']>
  noindexNofollow_not?: InputMaybe<Scalars['Boolean']>
  pageComponentsCollection_exists?: InputMaybe<Scalars['Boolean']>
  showHeader?: InputMaybe<Scalars['Boolean']>
  showHeader_exists?: InputMaybe<Scalars['Boolean']>
  showHeader_not?: InputMaybe<Scalars['Boolean']>
  slug?: InputMaybe<Scalars['String']>
  slug_contains?: InputMaybe<Scalars['String']>
  slug_exists?: InputMaybe<Scalars['Boolean']>
  slug_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  slug_not?: InputMaybe<Scalars['String']>
  slug_not_contains?: InputMaybe<Scalars['String']>
  slug_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  sys?: InputMaybe<SysFilter>
  title?: InputMaybe<Scalars['String']>
  title_contains?: InputMaybe<Scalars['String']>
  title_exists?: InputMaybe<Scalars['Boolean']>
  title_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  title_not?: InputMaybe<Scalars['String']>
  title_not_contains?: InputMaybe<Scalars['String']>
  title_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
}

export type CampaignPagesLinkingCollections = {
  __typename?: 'CampaignPagesLinkingCollections'
  entryCollection?: Maybe<EntryCollection>
}

export type CampaignPagesLinkingCollectionsEntryCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>
  locale?: InputMaybe<Scalars['String']>
  preview?: InputMaybe<Scalars['Boolean']>
  skip?: InputMaybe<Scalars['Int']>
}

export enum CampaignPagesOrder {
  CollapseFooterAsc = 'collapseFooter_ASC',
  CollapseFooterDesc = 'collapseFooter_DESC',
  CollapseHeaderAsc = 'collapseHeader_ASC',
  CollapseHeaderDesc = 'collapseHeader_DESC',
  HideFooterAsc = 'hideFooter_ASC',
  HideFooterDesc = 'hideFooter_DESC',
  HideGlobalBannerAsc = 'hideGlobalBanner_ASC',
  HideGlobalBannerDesc = 'hideGlobalBanner_DESC',
  JotformIdAsc = 'jotformId_ASC',
  JotformIdDesc = 'jotformId_DESC',
  NoindexNofollowAsc = 'noindexNofollow_ASC',
  NoindexNofollowDesc = 'noindexNofollow_DESC',
  ShowHeaderAsc = 'showHeader_ASC',
  ShowHeaderDesc = 'showHeader_DESC',
  SlugAsc = 'slug_ASC',
  SlugDesc = 'slug_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC',
}

export type CampaignPagesPageComponentsCollection = {
  __typename?: 'CampaignPagesPageComponentsCollection'
  items: Array<Maybe<CampaignPagesPageComponentsItem>>
  limit: Scalars['Int']
  skip: Scalars['Int']
  total: Scalars['Int']
}

export type CampaignPagesPageComponentsItem =
  | ComponentAdvisorPlanTable
  | ComponentAdvisorReviews
  | ComponentAnimatedTiles
  | ComponentButtonCta
  | ComponentCarouselSection
  | ComponentComparisonTable
  | ComponentCtaBanner
  | ComponentDataBanner
  | ComponentFeaturedAdvisors
  | ComponentFeaturedArticles
  | ComponentFeaturedGuides
  | ComponentFeaturedReviews
  | ComponentForaLogo
  | ComponentForm
  | ComponentHeroCentered
  | ComponentHeroFullWidth
  | ComponentImageGalleryCarousel
  | ComponentImageSideBySide
  | ComponentInfoGrid
  | ComponentPartnerGrid
  | ComponentPictureSectionBanner
  | ComponentReviews
  | ComponentSingleUse
  | ComponentSpacer
  | ComponentStickyHeader
  | ComponentSubNavBar
  | ComponentSubscriptionCard
  | ComponentTeamSection
  | ComponentTestimonialCarousel
  | ComponentTextSection
  | ComponentThreeIconSectionWithPicture
  | ComponentTwoColumnInfographic
  | ComponentTwoColumnTextSection
  | ComponentTypeformEmbed
  | ComponentVideoSection
  | ComponentWhatYouGetModule
  | FaqsQuestionGroups
  | PagesFeaturedHotels
  | PagesHeaderBlock
  | PagesHeroBlock
  | PagesLearnMoreBlock
  | PagesPartnerBanner
  | PagesPerksList
  | PagesPressBanner
  | PagesTwoColumnCta

/** A single, or collection of, Cloudinary assets. [See type definition](https://app.contentful.com/spaces/9cxgygtadmvl/content_types/cloudinaryAssets) */
export type CloudinaryAssets = Entry &
  _Node & {
    __typename?: 'CloudinaryAssets'
    _id: Scalars['ID']
    assets?: Maybe<Scalars['JSON']>
    contentfulMedia?: Maybe<Asset>
    contentfulMetadata: ContentfulMetadata
    linkedFrom?: Maybe<CloudinaryAssetsLinkingCollections>
    sys: Sys
    title?: Maybe<Scalars['String']>
  }

/** A single, or collection of, Cloudinary assets. [See type definition](https://app.contentful.com/spaces/9cxgygtadmvl/content_types/cloudinaryAssets) */
export type CloudinaryAssetsAssetsArgs = {
  locale?: InputMaybe<Scalars['String']>
}

/** A single, or collection of, Cloudinary assets. [See type definition](https://app.contentful.com/spaces/9cxgygtadmvl/content_types/cloudinaryAssets) */
export type CloudinaryAssetsContentfulMediaArgs = {
  locale?: InputMaybe<Scalars['String']>
  preview?: InputMaybe<Scalars['Boolean']>
}

/** A single, or collection of, Cloudinary assets. [See type definition](https://app.contentful.com/spaces/9cxgygtadmvl/content_types/cloudinaryAssets) */
export type CloudinaryAssetsLinkedFromArgs = {
  allowedLocales?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
}

/** A single, or collection of, Cloudinary assets. [See type definition](https://app.contentful.com/spaces/9cxgygtadmvl/content_types/cloudinaryAssets) */
export type CloudinaryAssetsTitleArgs = {
  locale?: InputMaybe<Scalars['String']>
}

export type CloudinaryAssetsCollection = {
  __typename?: 'CloudinaryAssetsCollection'
  items: Array<Maybe<CloudinaryAssets>>
  limit: Scalars['Int']
  skip: Scalars['Int']
  total: Scalars['Int']
}

export type CloudinaryAssetsFilter = {
  AND?: InputMaybe<Array<InputMaybe<CloudinaryAssetsFilter>>>
  OR?: InputMaybe<Array<InputMaybe<CloudinaryAssetsFilter>>>
  assets_exists?: InputMaybe<Scalars['Boolean']>
  contentfulMedia_exists?: InputMaybe<Scalars['Boolean']>
  contentfulMetadata?: InputMaybe<ContentfulMetadataFilter>
  sys?: InputMaybe<SysFilter>
  title?: InputMaybe<Scalars['String']>
  title_contains?: InputMaybe<Scalars['String']>
  title_exists?: InputMaybe<Scalars['Boolean']>
  title_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  title_not?: InputMaybe<Scalars['String']>
  title_not_contains?: InputMaybe<Scalars['String']>
  title_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
}

export type CloudinaryAssetsLinkingCollections = {
  __typename?: 'CloudinaryAssetsLinkingCollections'
  entryCollection?: Maybe<EntryCollection>
}

export type CloudinaryAssetsLinkingCollectionsEntryCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>
  locale?: InputMaybe<Scalars['String']>
  preview?: InputMaybe<Scalars['Boolean']>
  skip?: InputMaybe<Scalars['Int']>
}

export enum CloudinaryAssetsOrder {
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC',
}

/** [See type definition](https://app.contentful.com/spaces/9cxgygtadmvl/content_types/componentAdvisorPlanRow) */
export type ComponentAdvisorPlanRow = Entry &
  _Node & {
    __typename?: 'ComponentAdvisorPlanRow'
    _id: Scalars['ID']
    contentfulMetadata: ContentfulMetadata
    linkedFrom?: Maybe<ComponentAdvisorPlanRowLinkingCollections>
    sys: Sys
    title?: Maybe<Scalars['String']>
    valueOne?: Maybe<Scalars['Boolean']>
    valueThree?: Maybe<Scalars['Boolean']>
    valueTwo?: Maybe<Scalars['Boolean']>
  }

/** [See type definition](https://app.contentful.com/spaces/9cxgygtadmvl/content_types/componentAdvisorPlanRow) */
export type ComponentAdvisorPlanRowLinkedFromArgs = {
  allowedLocales?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
}

/** [See type definition](https://app.contentful.com/spaces/9cxgygtadmvl/content_types/componentAdvisorPlanRow) */
export type ComponentAdvisorPlanRowTitleArgs = {
  locale?: InputMaybe<Scalars['String']>
}

/** [See type definition](https://app.contentful.com/spaces/9cxgygtadmvl/content_types/componentAdvisorPlanRow) */
export type ComponentAdvisorPlanRowValueOneArgs = {
  locale?: InputMaybe<Scalars['String']>
}

/** [See type definition](https://app.contentful.com/spaces/9cxgygtadmvl/content_types/componentAdvisorPlanRow) */
export type ComponentAdvisorPlanRowValueThreeArgs = {
  locale?: InputMaybe<Scalars['String']>
}

/** [See type definition](https://app.contentful.com/spaces/9cxgygtadmvl/content_types/componentAdvisorPlanRow) */
export type ComponentAdvisorPlanRowValueTwoArgs = {
  locale?: InputMaybe<Scalars['String']>
}

export type ComponentAdvisorPlanRowCollection = {
  __typename?: 'ComponentAdvisorPlanRowCollection'
  items: Array<Maybe<ComponentAdvisorPlanRow>>
  limit: Scalars['Int']
  skip: Scalars['Int']
  total: Scalars['Int']
}

export type ComponentAdvisorPlanRowFilter = {
  AND?: InputMaybe<Array<InputMaybe<ComponentAdvisorPlanRowFilter>>>
  OR?: InputMaybe<Array<InputMaybe<ComponentAdvisorPlanRowFilter>>>
  contentfulMetadata?: InputMaybe<ContentfulMetadataFilter>
  sys?: InputMaybe<SysFilter>
  title?: InputMaybe<Scalars['String']>
  title_contains?: InputMaybe<Scalars['String']>
  title_exists?: InputMaybe<Scalars['Boolean']>
  title_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  title_not?: InputMaybe<Scalars['String']>
  title_not_contains?: InputMaybe<Scalars['String']>
  title_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  valueOne?: InputMaybe<Scalars['Boolean']>
  valueOne_exists?: InputMaybe<Scalars['Boolean']>
  valueOne_not?: InputMaybe<Scalars['Boolean']>
  valueThree?: InputMaybe<Scalars['Boolean']>
  valueThree_exists?: InputMaybe<Scalars['Boolean']>
  valueThree_not?: InputMaybe<Scalars['Boolean']>
  valueTwo?: InputMaybe<Scalars['Boolean']>
  valueTwo_exists?: InputMaybe<Scalars['Boolean']>
  valueTwo_not?: InputMaybe<Scalars['Boolean']>
}

export type ComponentAdvisorPlanRowLinkingCollections = {
  __typename?: 'ComponentAdvisorPlanRowLinkingCollections'
  componentAdvisorPlanSectionCollection?: Maybe<ComponentAdvisorPlanSectionCollection>
  entryCollection?: Maybe<EntryCollection>
}

export type ComponentAdvisorPlanRowLinkingCollectionsComponentAdvisorPlanSectionCollectionArgs =
  {
    limit?: InputMaybe<Scalars['Int']>
    locale?: InputMaybe<Scalars['String']>
    order?: InputMaybe<
      Array<
        InputMaybe<ComponentAdvisorPlanRowLinkingCollectionsComponentAdvisorPlanSectionCollectionOrder>
      >
    >
    preview?: InputMaybe<Scalars['Boolean']>
    skip?: InputMaybe<Scalars['Int']>
  }

export type ComponentAdvisorPlanRowLinkingCollectionsEntryCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>
  locale?: InputMaybe<Scalars['String']>
  preview?: InputMaybe<Scalars['Boolean']>
  skip?: InputMaybe<Scalars['Int']>
}

export enum ComponentAdvisorPlanRowLinkingCollectionsComponentAdvisorPlanSectionCollectionOrder {
  HeaderAsc = 'header_ASC',
  HeaderDesc = 'header_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
}

export enum ComponentAdvisorPlanRowOrder {
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC',
  ValueOneAsc = 'valueOne_ASC',
  ValueOneDesc = 'valueOne_DESC',
  ValueThreeAsc = 'valueThree_ASC',
  ValueThreeDesc = 'valueThree_DESC',
  ValueTwoAsc = 'valueTwo_ASC',
  ValueTwoDesc = 'valueTwo_DESC',
}

/** [See type definition](https://app.contentful.com/spaces/9cxgygtadmvl/content_types/componentAdvisorPlanSection) */
export type ComponentAdvisorPlanSection = Entry &
  _Node & {
    __typename?: 'ComponentAdvisorPlanSection'
    _id: Scalars['ID']
    contentfulMetadata: ContentfulMetadata
    header?: Maybe<Scalars['String']>
    linkedFrom?: Maybe<ComponentAdvisorPlanSectionLinkingCollections>
    rowsCollection?: Maybe<ComponentAdvisorPlanSectionRowsCollection>
    sys: Sys
  }

/** [See type definition](https://app.contentful.com/spaces/9cxgygtadmvl/content_types/componentAdvisorPlanSection) */
export type ComponentAdvisorPlanSectionHeaderArgs = {
  locale?: InputMaybe<Scalars['String']>
}

/** [See type definition](https://app.contentful.com/spaces/9cxgygtadmvl/content_types/componentAdvisorPlanSection) */
export type ComponentAdvisorPlanSectionLinkedFromArgs = {
  allowedLocales?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
}

/** [See type definition](https://app.contentful.com/spaces/9cxgygtadmvl/content_types/componentAdvisorPlanSection) */
export type ComponentAdvisorPlanSectionRowsCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>
  locale?: InputMaybe<Scalars['String']>
  order?: InputMaybe<
    Array<InputMaybe<ComponentAdvisorPlanSectionRowsCollectionOrder>>
  >
  preview?: InputMaybe<Scalars['Boolean']>
  skip?: InputMaybe<Scalars['Int']>
  where?: InputMaybe<ComponentAdvisorPlanRowFilter>
}

export type ComponentAdvisorPlanSectionCollection = {
  __typename?: 'ComponentAdvisorPlanSectionCollection'
  items: Array<Maybe<ComponentAdvisorPlanSection>>
  limit: Scalars['Int']
  skip: Scalars['Int']
  total: Scalars['Int']
}

export type ComponentAdvisorPlanSectionFilter = {
  AND?: InputMaybe<Array<InputMaybe<ComponentAdvisorPlanSectionFilter>>>
  OR?: InputMaybe<Array<InputMaybe<ComponentAdvisorPlanSectionFilter>>>
  contentfulMetadata?: InputMaybe<ContentfulMetadataFilter>
  header?: InputMaybe<Scalars['String']>
  header_contains?: InputMaybe<Scalars['String']>
  header_exists?: InputMaybe<Scalars['Boolean']>
  header_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  header_not?: InputMaybe<Scalars['String']>
  header_not_contains?: InputMaybe<Scalars['String']>
  header_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  rows?: InputMaybe<CfComponentAdvisorPlanRowNestedFilter>
  rowsCollection_exists?: InputMaybe<Scalars['Boolean']>
  sys?: InputMaybe<SysFilter>
}

export type ComponentAdvisorPlanSectionLinkingCollections = {
  __typename?: 'ComponentAdvisorPlanSectionLinkingCollections'
  componentAdvisorPlanTableCollection?: Maybe<ComponentAdvisorPlanTableCollection>
  entryCollection?: Maybe<EntryCollection>
}

export type ComponentAdvisorPlanSectionLinkingCollectionsComponentAdvisorPlanTableCollectionArgs =
  {
    limit?: InputMaybe<Scalars['Int']>
    locale?: InputMaybe<Scalars['String']>
    order?: InputMaybe<
      Array<
        InputMaybe<ComponentAdvisorPlanSectionLinkingCollectionsComponentAdvisorPlanTableCollectionOrder>
      >
    >
    preview?: InputMaybe<Scalars['Boolean']>
    skip?: InputMaybe<Scalars['Int']>
  }

export type ComponentAdvisorPlanSectionLinkingCollectionsEntryCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>
  locale?: InputMaybe<Scalars['String']>
  preview?: InputMaybe<Scalars['Boolean']>
  skip?: InputMaybe<Scalars['Int']>
}

export enum ComponentAdvisorPlanSectionLinkingCollectionsComponentAdvisorPlanTableCollectionOrder {
  InternalLabelAsc = 'internalLabel_ASC',
  InternalLabelDesc = 'internalLabel_DESC',
  SectionIdAsc = 'sectionId_ASC',
  SectionIdDesc = 'sectionId_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
}

export enum ComponentAdvisorPlanSectionOrder {
  HeaderAsc = 'header_ASC',
  HeaderDesc = 'header_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
}

export type ComponentAdvisorPlanSectionRowsCollection = {
  __typename?: 'ComponentAdvisorPlanSectionRowsCollection'
  items: Array<Maybe<ComponentAdvisorPlanRow>>
  limit: Scalars['Int']
  skip: Scalars['Int']
  total: Scalars['Int']
}

export enum ComponentAdvisorPlanSectionRowsCollectionOrder {
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC',
  ValueOneAsc = 'valueOne_ASC',
  ValueOneDesc = 'valueOne_DESC',
  ValueThreeAsc = 'valueThree_ASC',
  ValueThreeDesc = 'valueThree_DESC',
  ValueTwoAsc = 'valueTwo_ASC',
  ValueTwoDesc = 'valueTwo_DESC',
}

/** [See type definition](https://app.contentful.com/spaces/9cxgygtadmvl/content_types/componentAdvisorPlanTable) */
export type ComponentAdvisorPlanTable = Entry &
  _Node & {
    __typename?: 'ComponentAdvisorPlanTable'
    _id: Scalars['ID']
    contentfulMetadata: ContentfulMetadata
    internalLabel?: Maybe<Scalars['String']>
    linkedFrom?: Maybe<ComponentAdvisorPlanTableLinkingCollections>
    sectionId?: Maybe<Scalars['String']>
    sectionsCollection?: Maybe<ComponentAdvisorPlanTableSectionsCollection>
    sys: Sys
  }

/** [See type definition](https://app.contentful.com/spaces/9cxgygtadmvl/content_types/componentAdvisorPlanTable) */
export type ComponentAdvisorPlanTableInternalLabelArgs = {
  locale?: InputMaybe<Scalars['String']>
}

/** [See type definition](https://app.contentful.com/spaces/9cxgygtadmvl/content_types/componentAdvisorPlanTable) */
export type ComponentAdvisorPlanTableLinkedFromArgs = {
  allowedLocales?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
}

/** [See type definition](https://app.contentful.com/spaces/9cxgygtadmvl/content_types/componentAdvisorPlanTable) */
export type ComponentAdvisorPlanTableSectionIdArgs = {
  locale?: InputMaybe<Scalars['String']>
}

/** [See type definition](https://app.contentful.com/spaces/9cxgygtadmvl/content_types/componentAdvisorPlanTable) */
export type ComponentAdvisorPlanTableSectionsCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>
  locale?: InputMaybe<Scalars['String']>
  order?: InputMaybe<
    Array<InputMaybe<ComponentAdvisorPlanTableSectionsCollectionOrder>>
  >
  preview?: InputMaybe<Scalars['Boolean']>
  skip?: InputMaybe<Scalars['Int']>
  where?: InputMaybe<ComponentAdvisorPlanSectionFilter>
}

export type ComponentAdvisorPlanTableCollection = {
  __typename?: 'ComponentAdvisorPlanTableCollection'
  items: Array<Maybe<ComponentAdvisorPlanTable>>
  limit: Scalars['Int']
  skip: Scalars['Int']
  total: Scalars['Int']
}

export type ComponentAdvisorPlanTableFilter = {
  AND?: InputMaybe<Array<InputMaybe<ComponentAdvisorPlanTableFilter>>>
  OR?: InputMaybe<Array<InputMaybe<ComponentAdvisorPlanTableFilter>>>
  contentfulMetadata?: InputMaybe<ContentfulMetadataFilter>
  internalLabel?: InputMaybe<Scalars['String']>
  internalLabel_contains?: InputMaybe<Scalars['String']>
  internalLabel_exists?: InputMaybe<Scalars['Boolean']>
  internalLabel_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  internalLabel_not?: InputMaybe<Scalars['String']>
  internalLabel_not_contains?: InputMaybe<Scalars['String']>
  internalLabel_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  sectionId?: InputMaybe<Scalars['String']>
  sectionId_contains?: InputMaybe<Scalars['String']>
  sectionId_exists?: InputMaybe<Scalars['Boolean']>
  sectionId_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  sectionId_not?: InputMaybe<Scalars['String']>
  sectionId_not_contains?: InputMaybe<Scalars['String']>
  sectionId_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  sections?: InputMaybe<CfComponentAdvisorPlanSectionNestedFilter>
  sectionsCollection_exists?: InputMaybe<Scalars['Boolean']>
  sys?: InputMaybe<SysFilter>
}

export type ComponentAdvisorPlanTableLinkingCollections = {
  __typename?: 'ComponentAdvisorPlanTableLinkingCollections'
  campaignPagesCollection?: Maybe<CampaignPagesCollection>
  entryCollection?: Maybe<EntryCollection>
  templateAboutUsCollection?: Maybe<TemplateAboutUsCollection>
  templateBookingCollection?: Maybe<TemplateBookingCollection>
  templateCareersCollection?: Maybe<TemplateCareersCollection>
  templateContactUsCollection?: Maybe<TemplateContactUsCollection>
  templateDestinationsCollection?: Maybe<TemplateDestinationsCollection>
  templateForaReserveCollection?: Maybe<TemplateForaReserveCollection>
  templateHomeCollection?: Maybe<TemplateHomeCollection>
  templateJoinUsCollection?: Maybe<TemplateJoinUsCollection>
  templateNewsletterCollection?: Maybe<TemplateNewsletterCollection>
  templatePartnersCollection?: Maybe<TemplatePartnersCollection>
  templateReviewsCollection?: Maybe<TemplateReviewsCollection>
  templateWhyBookWithForaCollection?: Maybe<TemplateWhyBookWithForaCollection>
}

export type ComponentAdvisorPlanTableLinkingCollectionsCampaignPagesCollectionArgs =
  {
    limit?: InputMaybe<Scalars['Int']>
    locale?: InputMaybe<Scalars['String']>
    order?: InputMaybe<
      Array<
        InputMaybe<ComponentAdvisorPlanTableLinkingCollectionsCampaignPagesCollectionOrder>
      >
    >
    preview?: InputMaybe<Scalars['Boolean']>
    skip?: InputMaybe<Scalars['Int']>
  }

export type ComponentAdvisorPlanTableLinkingCollectionsEntryCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>
  locale?: InputMaybe<Scalars['String']>
  preview?: InputMaybe<Scalars['Boolean']>
  skip?: InputMaybe<Scalars['Int']>
}

export type ComponentAdvisorPlanTableLinkingCollectionsTemplateAboutUsCollectionArgs =
  {
    limit?: InputMaybe<Scalars['Int']>
    locale?: InputMaybe<Scalars['String']>
    order?: InputMaybe<
      Array<
        InputMaybe<ComponentAdvisorPlanTableLinkingCollectionsTemplateAboutUsCollectionOrder>
      >
    >
    preview?: InputMaybe<Scalars['Boolean']>
    skip?: InputMaybe<Scalars['Int']>
  }

export type ComponentAdvisorPlanTableLinkingCollectionsTemplateBookingCollectionArgs =
  {
    limit?: InputMaybe<Scalars['Int']>
    locale?: InputMaybe<Scalars['String']>
    order?: InputMaybe<
      Array<
        InputMaybe<ComponentAdvisorPlanTableLinkingCollectionsTemplateBookingCollectionOrder>
      >
    >
    preview?: InputMaybe<Scalars['Boolean']>
    skip?: InputMaybe<Scalars['Int']>
  }

export type ComponentAdvisorPlanTableLinkingCollectionsTemplateCareersCollectionArgs =
  {
    limit?: InputMaybe<Scalars['Int']>
    locale?: InputMaybe<Scalars['String']>
    order?: InputMaybe<
      Array<
        InputMaybe<ComponentAdvisorPlanTableLinkingCollectionsTemplateCareersCollectionOrder>
      >
    >
    preview?: InputMaybe<Scalars['Boolean']>
    skip?: InputMaybe<Scalars['Int']>
  }

export type ComponentAdvisorPlanTableLinkingCollectionsTemplateContactUsCollectionArgs =
  {
    limit?: InputMaybe<Scalars['Int']>
    locale?: InputMaybe<Scalars['String']>
    order?: InputMaybe<
      Array<
        InputMaybe<ComponentAdvisorPlanTableLinkingCollectionsTemplateContactUsCollectionOrder>
      >
    >
    preview?: InputMaybe<Scalars['Boolean']>
    skip?: InputMaybe<Scalars['Int']>
  }

export type ComponentAdvisorPlanTableLinkingCollectionsTemplateDestinationsCollectionArgs =
  {
    limit?: InputMaybe<Scalars['Int']>
    locale?: InputMaybe<Scalars['String']>
    order?: InputMaybe<
      Array<
        InputMaybe<ComponentAdvisorPlanTableLinkingCollectionsTemplateDestinationsCollectionOrder>
      >
    >
    preview?: InputMaybe<Scalars['Boolean']>
    skip?: InputMaybe<Scalars['Int']>
  }

export type ComponentAdvisorPlanTableLinkingCollectionsTemplateForaReserveCollectionArgs =
  {
    limit?: InputMaybe<Scalars['Int']>
    locale?: InputMaybe<Scalars['String']>
    order?: InputMaybe<
      Array<
        InputMaybe<ComponentAdvisorPlanTableLinkingCollectionsTemplateForaReserveCollectionOrder>
      >
    >
    preview?: InputMaybe<Scalars['Boolean']>
    skip?: InputMaybe<Scalars['Int']>
  }

export type ComponentAdvisorPlanTableLinkingCollectionsTemplateHomeCollectionArgs =
  {
    limit?: InputMaybe<Scalars['Int']>
    locale?: InputMaybe<Scalars['String']>
    order?: InputMaybe<
      Array<
        InputMaybe<ComponentAdvisorPlanTableLinkingCollectionsTemplateHomeCollectionOrder>
      >
    >
    preview?: InputMaybe<Scalars['Boolean']>
    skip?: InputMaybe<Scalars['Int']>
  }

export type ComponentAdvisorPlanTableLinkingCollectionsTemplateJoinUsCollectionArgs =
  {
    limit?: InputMaybe<Scalars['Int']>
    locale?: InputMaybe<Scalars['String']>
    order?: InputMaybe<
      Array<
        InputMaybe<ComponentAdvisorPlanTableLinkingCollectionsTemplateJoinUsCollectionOrder>
      >
    >
    preview?: InputMaybe<Scalars['Boolean']>
    skip?: InputMaybe<Scalars['Int']>
  }

export type ComponentAdvisorPlanTableLinkingCollectionsTemplateNewsletterCollectionArgs =
  {
    limit?: InputMaybe<Scalars['Int']>
    locale?: InputMaybe<Scalars['String']>
    order?: InputMaybe<
      Array<
        InputMaybe<ComponentAdvisorPlanTableLinkingCollectionsTemplateNewsletterCollectionOrder>
      >
    >
    preview?: InputMaybe<Scalars['Boolean']>
    skip?: InputMaybe<Scalars['Int']>
  }

export type ComponentAdvisorPlanTableLinkingCollectionsTemplatePartnersCollectionArgs =
  {
    limit?: InputMaybe<Scalars['Int']>
    locale?: InputMaybe<Scalars['String']>
    order?: InputMaybe<
      Array<
        InputMaybe<ComponentAdvisorPlanTableLinkingCollectionsTemplatePartnersCollectionOrder>
      >
    >
    preview?: InputMaybe<Scalars['Boolean']>
    skip?: InputMaybe<Scalars['Int']>
  }

export type ComponentAdvisorPlanTableLinkingCollectionsTemplateReviewsCollectionArgs =
  {
    limit?: InputMaybe<Scalars['Int']>
    locale?: InputMaybe<Scalars['String']>
    order?: InputMaybe<
      Array<
        InputMaybe<ComponentAdvisorPlanTableLinkingCollectionsTemplateReviewsCollectionOrder>
      >
    >
    preview?: InputMaybe<Scalars['Boolean']>
    skip?: InputMaybe<Scalars['Int']>
  }

export type ComponentAdvisorPlanTableLinkingCollectionsTemplateWhyBookWithForaCollectionArgs =
  {
    limit?: InputMaybe<Scalars['Int']>
    locale?: InputMaybe<Scalars['String']>
    order?: InputMaybe<
      Array<
        InputMaybe<ComponentAdvisorPlanTableLinkingCollectionsTemplateWhyBookWithForaCollectionOrder>
      >
    >
    preview?: InputMaybe<Scalars['Boolean']>
    skip?: InputMaybe<Scalars['Int']>
  }

export enum ComponentAdvisorPlanTableLinkingCollectionsCampaignPagesCollectionOrder {
  CollapseFooterAsc = 'collapseFooter_ASC',
  CollapseFooterDesc = 'collapseFooter_DESC',
  CollapseHeaderAsc = 'collapseHeader_ASC',
  CollapseHeaderDesc = 'collapseHeader_DESC',
  HideFooterAsc = 'hideFooter_ASC',
  HideFooterDesc = 'hideFooter_DESC',
  HideGlobalBannerAsc = 'hideGlobalBanner_ASC',
  HideGlobalBannerDesc = 'hideGlobalBanner_DESC',
  JotformIdAsc = 'jotformId_ASC',
  JotformIdDesc = 'jotformId_DESC',
  NoindexNofollowAsc = 'noindexNofollow_ASC',
  NoindexNofollowDesc = 'noindexNofollow_DESC',
  ShowHeaderAsc = 'showHeader_ASC',
  ShowHeaderDesc = 'showHeader_DESC',
  SlugAsc = 'slug_ASC',
  SlugDesc = 'slug_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC',
}

export enum ComponentAdvisorPlanTableLinkingCollectionsTemplateAboutUsCollectionOrder {
  CollapseHeaderAsc = 'collapseHeader_ASC',
  CollapseHeaderDesc = 'collapseHeader_DESC',
  HeaderBlockTitleAsc = 'headerBlockTitle_ASC',
  HeaderBlockTitleDesc = 'headerBlockTitle_DESC',
  ShowHeaderAsc = 'showHeader_ASC',
  ShowHeaderDesc = 'showHeader_DESC',
  SubtitleAsc = 'subtitle_ASC',
  SubtitleDesc = 'subtitle_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC',
}

export enum ComponentAdvisorPlanTableLinkingCollectionsTemplateBookingCollectionOrder {
  CollapseHeaderAsc = 'collapseHeader_ASC',
  CollapseHeaderDesc = 'collapseHeader_DESC',
  ShowHeaderAsc = 'showHeader_ASC',
  ShowHeaderDesc = 'showHeader_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC',
}

export enum ComponentAdvisorPlanTableLinkingCollectionsTemplateCareersCollectionOrder {
  CollapseHeaderAsc = 'collapseHeader_ASC',
  CollapseHeaderDesc = 'collapseHeader_DESC',
  ShowHeaderAsc = 'showHeader_ASC',
  ShowHeaderDesc = 'showHeader_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC',
}

export enum ComponentAdvisorPlanTableLinkingCollectionsTemplateContactUsCollectionOrder {
  CollapseHeaderAsc = 'collapseHeader_ASC',
  CollapseHeaderDesc = 'collapseHeader_DESC',
  ShowHeaderAsc = 'showHeader_ASC',
  ShowHeaderDesc = 'showHeader_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC',
}

export enum ComponentAdvisorPlanTableLinkingCollectionsTemplateDestinationsCollectionOrder {
  CollapseHeaderAsc = 'collapseHeader_ASC',
  CollapseHeaderDesc = 'collapseHeader_DESC',
  CollapsibleHeadingAsc = 'collapsibleHeading_ASC',
  CollapsibleHeadingDesc = 'collapsibleHeading_DESC',
  ShowHeaderAsc = 'showHeader_ASC',
  ShowHeaderDesc = 'showHeader_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC',
}

export enum ComponentAdvisorPlanTableLinkingCollectionsTemplateForaReserveCollectionOrder {
  CollapseHeaderAsc = 'collapseHeader_ASC',
  CollapseHeaderDesc = 'collapseHeader_DESC',
  ShowHeaderAsc = 'showHeader_ASC',
  ShowHeaderDesc = 'showHeader_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC',
}

export enum ComponentAdvisorPlanTableLinkingCollectionsTemplateHomeCollectionOrder {
  CollapseHeaderAsc = 'collapseHeader_ASC',
  CollapseHeaderDesc = 'collapseHeader_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC',
}

export enum ComponentAdvisorPlanTableLinkingCollectionsTemplateJoinUsCollectionOrder {
  CollapseHeaderAsc = 'collapseHeader_ASC',
  CollapseHeaderDesc = 'collapseHeader_DESC',
  HideGlobalBannerAsc = 'hideGlobalBanner_ASC',
  HideGlobalBannerDesc = 'hideGlobalBanner_DESC',
  ShowHeaderAsc = 'showHeader_ASC',
  ShowHeaderDesc = 'showHeader_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC',
}

export enum ComponentAdvisorPlanTableLinkingCollectionsTemplateNewsletterCollectionOrder {
  CollapseHeaderAsc = 'collapseHeader_ASC',
  CollapseHeaderDesc = 'collapseHeader_DESC',
  ShowHeaderAsc = 'showHeader_ASC',
  ShowHeaderDesc = 'showHeader_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC',
}

export enum ComponentAdvisorPlanTableLinkingCollectionsTemplatePartnersCollectionOrder {
  CollapseHeaderAsc = 'collapseHeader_ASC',
  CollapseHeaderDesc = 'collapseHeader_DESC',
  ShowHeaderAsc = 'showHeader_ASC',
  ShowHeaderDesc = 'showHeader_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC',
}

export enum ComponentAdvisorPlanTableLinkingCollectionsTemplateReviewsCollectionOrder {
  CollapseHeaderAsc = 'collapseHeader_ASC',
  CollapseHeaderDesc = 'collapseHeader_DESC',
  ShowHeaderAsc = 'showHeader_ASC',
  ShowHeaderDesc = 'showHeader_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC',
}

export enum ComponentAdvisorPlanTableLinkingCollectionsTemplateWhyBookWithForaCollectionOrder {
  CollapseHeaderAsc = 'collapseHeader_ASC',
  CollapseHeaderDesc = 'collapseHeader_DESC',
  ShowHeaderAsc = 'showHeader_ASC',
  ShowHeaderDesc = 'showHeader_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC',
}

export enum ComponentAdvisorPlanTableOrder {
  InternalLabelAsc = 'internalLabel_ASC',
  InternalLabelDesc = 'internalLabel_DESC',
  SectionIdAsc = 'sectionId_ASC',
  SectionIdDesc = 'sectionId_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
}

export type ComponentAdvisorPlanTableSectionsCollection = {
  __typename?: 'ComponentAdvisorPlanTableSectionsCollection'
  items: Array<Maybe<ComponentAdvisorPlanSection>>
  limit: Scalars['Int']
  skip: Scalars['Int']
  total: Scalars['Int']
}

export enum ComponentAdvisorPlanTableSectionsCollectionOrder {
  HeaderAsc = 'header_ASC',
  HeaderDesc = 'header_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
}

/** A page block that displays advisor reviews [See type definition](https://app.contentful.com/spaces/9cxgygtadmvl/content_types/componentAdvisorReviews) */
export type ComponentAdvisorReviews = Entry &
  _Node & {
    __typename?: 'ComponentAdvisorReviews'
    _id: Scalars['ID']
    advisorReviewsCollection?: Maybe<ComponentAdvisorReviewsAdvisorReviewsCollection>
    contentfulMetadata: ContentfulMetadata
    internalLabel?: Maybe<Scalars['String']>
    linkedFrom?: Maybe<ComponentAdvisorReviewsLinkingCollections>
    sys: Sys
  }

/** A page block that displays advisor reviews [See type definition](https://app.contentful.com/spaces/9cxgygtadmvl/content_types/componentAdvisorReviews) */
export type ComponentAdvisorReviewsAdvisorReviewsCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>
  locale?: InputMaybe<Scalars['String']>
  order?: InputMaybe<
    Array<InputMaybe<ComponentAdvisorReviewsAdvisorReviewsCollectionOrder>>
  >
  preview?: InputMaybe<Scalars['Boolean']>
  skip?: InputMaybe<Scalars['Int']>
  where?: InputMaybe<AdvisorReviewsFilter>
}

/** A page block that displays advisor reviews [See type definition](https://app.contentful.com/spaces/9cxgygtadmvl/content_types/componentAdvisorReviews) */
export type ComponentAdvisorReviewsInternalLabelArgs = {
  locale?: InputMaybe<Scalars['String']>
}

/** A page block that displays advisor reviews [See type definition](https://app.contentful.com/spaces/9cxgygtadmvl/content_types/componentAdvisorReviews) */
export type ComponentAdvisorReviewsLinkedFromArgs = {
  allowedLocales?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
}

export type ComponentAdvisorReviewsAdvisorReviewsCollection = {
  __typename?: 'ComponentAdvisorReviewsAdvisorReviewsCollection'
  items: Array<Maybe<AdvisorReviews>>
  limit: Scalars['Int']
  skip: Scalars['Int']
  total: Scalars['Int']
}

export enum ComponentAdvisorReviewsAdvisorReviewsCollectionOrder {
  ReviewAsc = 'Review_ASC',
  ReviewDesc = 'Review_DESC',
  EyebrowTextAsc = 'eyebrowText_ASC',
  EyebrowTextDesc = 'eyebrowText_DESC',
  InternalLabelAsc = 'internalLabel_ASC',
  InternalLabelDesc = 'internalLabel_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
}

export type ComponentAdvisorReviewsCollection = {
  __typename?: 'ComponentAdvisorReviewsCollection'
  items: Array<Maybe<ComponentAdvisorReviews>>
  limit: Scalars['Int']
  skip: Scalars['Int']
  total: Scalars['Int']
}

export type ComponentAdvisorReviewsFilter = {
  AND?: InputMaybe<Array<InputMaybe<ComponentAdvisorReviewsFilter>>>
  OR?: InputMaybe<Array<InputMaybe<ComponentAdvisorReviewsFilter>>>
  advisorReviews?: InputMaybe<CfAdvisorReviewsNestedFilter>
  advisorReviewsCollection_exists?: InputMaybe<Scalars['Boolean']>
  contentfulMetadata?: InputMaybe<ContentfulMetadataFilter>
  internalLabel?: InputMaybe<Scalars['String']>
  internalLabel_contains?: InputMaybe<Scalars['String']>
  internalLabel_exists?: InputMaybe<Scalars['Boolean']>
  internalLabel_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  internalLabel_not?: InputMaybe<Scalars['String']>
  internalLabel_not_contains?: InputMaybe<Scalars['String']>
  internalLabel_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  sys?: InputMaybe<SysFilter>
}

export type ComponentAdvisorReviewsLinkingCollections = {
  __typename?: 'ComponentAdvisorReviewsLinkingCollections'
  campaignPagesCollection?: Maybe<CampaignPagesCollection>
  entryCollection?: Maybe<EntryCollection>
  templateAboutUsCollection?: Maybe<TemplateAboutUsCollection>
  templateBookingCollection?: Maybe<TemplateBookingCollection>
  templateCareersCollection?: Maybe<TemplateCareersCollection>
  templateContactUsCollection?: Maybe<TemplateContactUsCollection>
  templateDestinationsCollection?: Maybe<TemplateDestinationsCollection>
  templateForaReserveCollection?: Maybe<TemplateForaReserveCollection>
  templateForaXCollection?: Maybe<TemplateForaXCollection>
  templateHomeCollection?: Maybe<TemplateHomeCollection>
  templateJoinUsCollection?: Maybe<TemplateJoinUsCollection>
  templateNewsletterCollection?: Maybe<TemplateNewsletterCollection>
  templatePartnersCollection?: Maybe<TemplatePartnersCollection>
  templateReviewsCollection?: Maybe<TemplateReviewsCollection>
  templateWhyBookWithForaCollection?: Maybe<TemplateWhyBookWithForaCollection>
}

export type ComponentAdvisorReviewsLinkingCollectionsCampaignPagesCollectionArgs =
  {
    limit?: InputMaybe<Scalars['Int']>
    locale?: InputMaybe<Scalars['String']>
    order?: InputMaybe<
      Array<
        InputMaybe<ComponentAdvisorReviewsLinkingCollectionsCampaignPagesCollectionOrder>
      >
    >
    preview?: InputMaybe<Scalars['Boolean']>
    skip?: InputMaybe<Scalars['Int']>
  }

export type ComponentAdvisorReviewsLinkingCollectionsEntryCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>
  locale?: InputMaybe<Scalars['String']>
  preview?: InputMaybe<Scalars['Boolean']>
  skip?: InputMaybe<Scalars['Int']>
}

export type ComponentAdvisorReviewsLinkingCollectionsTemplateAboutUsCollectionArgs =
  {
    limit?: InputMaybe<Scalars['Int']>
    locale?: InputMaybe<Scalars['String']>
    order?: InputMaybe<
      Array<
        InputMaybe<ComponentAdvisorReviewsLinkingCollectionsTemplateAboutUsCollectionOrder>
      >
    >
    preview?: InputMaybe<Scalars['Boolean']>
    skip?: InputMaybe<Scalars['Int']>
  }

export type ComponentAdvisorReviewsLinkingCollectionsTemplateBookingCollectionArgs =
  {
    limit?: InputMaybe<Scalars['Int']>
    locale?: InputMaybe<Scalars['String']>
    order?: InputMaybe<
      Array<
        InputMaybe<ComponentAdvisorReviewsLinkingCollectionsTemplateBookingCollectionOrder>
      >
    >
    preview?: InputMaybe<Scalars['Boolean']>
    skip?: InputMaybe<Scalars['Int']>
  }

export type ComponentAdvisorReviewsLinkingCollectionsTemplateCareersCollectionArgs =
  {
    limit?: InputMaybe<Scalars['Int']>
    locale?: InputMaybe<Scalars['String']>
    order?: InputMaybe<
      Array<
        InputMaybe<ComponentAdvisorReviewsLinkingCollectionsTemplateCareersCollectionOrder>
      >
    >
    preview?: InputMaybe<Scalars['Boolean']>
    skip?: InputMaybe<Scalars['Int']>
  }

export type ComponentAdvisorReviewsLinkingCollectionsTemplateContactUsCollectionArgs =
  {
    limit?: InputMaybe<Scalars['Int']>
    locale?: InputMaybe<Scalars['String']>
    order?: InputMaybe<
      Array<
        InputMaybe<ComponentAdvisorReviewsLinkingCollectionsTemplateContactUsCollectionOrder>
      >
    >
    preview?: InputMaybe<Scalars['Boolean']>
    skip?: InputMaybe<Scalars['Int']>
  }

export type ComponentAdvisorReviewsLinkingCollectionsTemplateDestinationsCollectionArgs =
  {
    limit?: InputMaybe<Scalars['Int']>
    locale?: InputMaybe<Scalars['String']>
    order?: InputMaybe<
      Array<
        InputMaybe<ComponentAdvisorReviewsLinkingCollectionsTemplateDestinationsCollectionOrder>
      >
    >
    preview?: InputMaybe<Scalars['Boolean']>
    skip?: InputMaybe<Scalars['Int']>
  }

export type ComponentAdvisorReviewsLinkingCollectionsTemplateForaReserveCollectionArgs =
  {
    limit?: InputMaybe<Scalars['Int']>
    locale?: InputMaybe<Scalars['String']>
    order?: InputMaybe<
      Array<
        InputMaybe<ComponentAdvisorReviewsLinkingCollectionsTemplateForaReserveCollectionOrder>
      >
    >
    preview?: InputMaybe<Scalars['Boolean']>
    skip?: InputMaybe<Scalars['Int']>
  }

export type ComponentAdvisorReviewsLinkingCollectionsTemplateForaXCollectionArgs =
  {
    limit?: InputMaybe<Scalars['Int']>
    locale?: InputMaybe<Scalars['String']>
    order?: InputMaybe<
      Array<
        InputMaybe<ComponentAdvisorReviewsLinkingCollectionsTemplateForaXCollectionOrder>
      >
    >
    preview?: InputMaybe<Scalars['Boolean']>
    skip?: InputMaybe<Scalars['Int']>
  }

export type ComponentAdvisorReviewsLinkingCollectionsTemplateHomeCollectionArgs =
  {
    limit?: InputMaybe<Scalars['Int']>
    locale?: InputMaybe<Scalars['String']>
    order?: InputMaybe<
      Array<
        InputMaybe<ComponentAdvisorReviewsLinkingCollectionsTemplateHomeCollectionOrder>
      >
    >
    preview?: InputMaybe<Scalars['Boolean']>
    skip?: InputMaybe<Scalars['Int']>
  }

export type ComponentAdvisorReviewsLinkingCollectionsTemplateJoinUsCollectionArgs =
  {
    limit?: InputMaybe<Scalars['Int']>
    locale?: InputMaybe<Scalars['String']>
    order?: InputMaybe<
      Array<
        InputMaybe<ComponentAdvisorReviewsLinkingCollectionsTemplateJoinUsCollectionOrder>
      >
    >
    preview?: InputMaybe<Scalars['Boolean']>
    skip?: InputMaybe<Scalars['Int']>
  }

export type ComponentAdvisorReviewsLinkingCollectionsTemplateNewsletterCollectionArgs =
  {
    limit?: InputMaybe<Scalars['Int']>
    locale?: InputMaybe<Scalars['String']>
    order?: InputMaybe<
      Array<
        InputMaybe<ComponentAdvisorReviewsLinkingCollectionsTemplateNewsletterCollectionOrder>
      >
    >
    preview?: InputMaybe<Scalars['Boolean']>
    skip?: InputMaybe<Scalars['Int']>
  }

export type ComponentAdvisorReviewsLinkingCollectionsTemplatePartnersCollectionArgs =
  {
    limit?: InputMaybe<Scalars['Int']>
    locale?: InputMaybe<Scalars['String']>
    order?: InputMaybe<
      Array<
        InputMaybe<ComponentAdvisorReviewsLinkingCollectionsTemplatePartnersCollectionOrder>
      >
    >
    preview?: InputMaybe<Scalars['Boolean']>
    skip?: InputMaybe<Scalars['Int']>
  }

export type ComponentAdvisorReviewsLinkingCollectionsTemplateReviewsCollectionArgs =
  {
    limit?: InputMaybe<Scalars['Int']>
    locale?: InputMaybe<Scalars['String']>
    order?: InputMaybe<
      Array<
        InputMaybe<ComponentAdvisorReviewsLinkingCollectionsTemplateReviewsCollectionOrder>
      >
    >
    preview?: InputMaybe<Scalars['Boolean']>
    skip?: InputMaybe<Scalars['Int']>
  }

export type ComponentAdvisorReviewsLinkingCollectionsTemplateWhyBookWithForaCollectionArgs =
  {
    limit?: InputMaybe<Scalars['Int']>
    locale?: InputMaybe<Scalars['String']>
    order?: InputMaybe<
      Array<
        InputMaybe<ComponentAdvisorReviewsLinkingCollectionsTemplateWhyBookWithForaCollectionOrder>
      >
    >
    preview?: InputMaybe<Scalars['Boolean']>
    skip?: InputMaybe<Scalars['Int']>
  }

export enum ComponentAdvisorReviewsLinkingCollectionsCampaignPagesCollectionOrder {
  CollapseFooterAsc = 'collapseFooter_ASC',
  CollapseFooterDesc = 'collapseFooter_DESC',
  CollapseHeaderAsc = 'collapseHeader_ASC',
  CollapseHeaderDesc = 'collapseHeader_DESC',
  HideFooterAsc = 'hideFooter_ASC',
  HideFooterDesc = 'hideFooter_DESC',
  HideGlobalBannerAsc = 'hideGlobalBanner_ASC',
  HideGlobalBannerDesc = 'hideGlobalBanner_DESC',
  JotformIdAsc = 'jotformId_ASC',
  JotformIdDesc = 'jotformId_DESC',
  NoindexNofollowAsc = 'noindexNofollow_ASC',
  NoindexNofollowDesc = 'noindexNofollow_DESC',
  ShowHeaderAsc = 'showHeader_ASC',
  ShowHeaderDesc = 'showHeader_DESC',
  SlugAsc = 'slug_ASC',
  SlugDesc = 'slug_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC',
}

export enum ComponentAdvisorReviewsLinkingCollectionsTemplateAboutUsCollectionOrder {
  CollapseHeaderAsc = 'collapseHeader_ASC',
  CollapseHeaderDesc = 'collapseHeader_DESC',
  HeaderBlockTitleAsc = 'headerBlockTitle_ASC',
  HeaderBlockTitleDesc = 'headerBlockTitle_DESC',
  ShowHeaderAsc = 'showHeader_ASC',
  ShowHeaderDesc = 'showHeader_DESC',
  SubtitleAsc = 'subtitle_ASC',
  SubtitleDesc = 'subtitle_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC',
}

export enum ComponentAdvisorReviewsLinkingCollectionsTemplateBookingCollectionOrder {
  CollapseHeaderAsc = 'collapseHeader_ASC',
  CollapseHeaderDesc = 'collapseHeader_DESC',
  ShowHeaderAsc = 'showHeader_ASC',
  ShowHeaderDesc = 'showHeader_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC',
}

export enum ComponentAdvisorReviewsLinkingCollectionsTemplateCareersCollectionOrder {
  CollapseHeaderAsc = 'collapseHeader_ASC',
  CollapseHeaderDesc = 'collapseHeader_DESC',
  ShowHeaderAsc = 'showHeader_ASC',
  ShowHeaderDesc = 'showHeader_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC',
}

export enum ComponentAdvisorReviewsLinkingCollectionsTemplateContactUsCollectionOrder {
  CollapseHeaderAsc = 'collapseHeader_ASC',
  CollapseHeaderDesc = 'collapseHeader_DESC',
  ShowHeaderAsc = 'showHeader_ASC',
  ShowHeaderDesc = 'showHeader_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC',
}

export enum ComponentAdvisorReviewsLinkingCollectionsTemplateDestinationsCollectionOrder {
  CollapseHeaderAsc = 'collapseHeader_ASC',
  CollapseHeaderDesc = 'collapseHeader_DESC',
  CollapsibleHeadingAsc = 'collapsibleHeading_ASC',
  CollapsibleHeadingDesc = 'collapsibleHeading_DESC',
  ShowHeaderAsc = 'showHeader_ASC',
  ShowHeaderDesc = 'showHeader_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC',
}

export enum ComponentAdvisorReviewsLinkingCollectionsTemplateForaReserveCollectionOrder {
  CollapseHeaderAsc = 'collapseHeader_ASC',
  CollapseHeaderDesc = 'collapseHeader_DESC',
  ShowHeaderAsc = 'showHeader_ASC',
  ShowHeaderDesc = 'showHeader_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC',
}

export enum ComponentAdvisorReviewsLinkingCollectionsTemplateForaXCollectionOrder {
  AdvisorGridHeaderAsc = 'advisorGridHeader_ASC',
  AdvisorGridHeaderDesc = 'advisorGridHeader_DESC',
  AdvisorGridSubCopyAsc = 'advisorGridSubCopy_ASC',
  AdvisorGridSubCopyDesc = 'advisorGridSubCopy_DESC',
  CollapseHeaderAsc = 'collapseHeader_ASC',
  CollapseHeaderDesc = 'collapseHeader_DESC',
  ShowHeaderAsc = 'showHeader_ASC',
  ShowHeaderDesc = 'showHeader_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC',
}

export enum ComponentAdvisorReviewsLinkingCollectionsTemplateHomeCollectionOrder {
  CollapseHeaderAsc = 'collapseHeader_ASC',
  CollapseHeaderDesc = 'collapseHeader_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC',
}

export enum ComponentAdvisorReviewsLinkingCollectionsTemplateJoinUsCollectionOrder {
  CollapseHeaderAsc = 'collapseHeader_ASC',
  CollapseHeaderDesc = 'collapseHeader_DESC',
  HideGlobalBannerAsc = 'hideGlobalBanner_ASC',
  HideGlobalBannerDesc = 'hideGlobalBanner_DESC',
  ShowHeaderAsc = 'showHeader_ASC',
  ShowHeaderDesc = 'showHeader_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC',
}

export enum ComponentAdvisorReviewsLinkingCollectionsTemplateNewsletterCollectionOrder {
  CollapseHeaderAsc = 'collapseHeader_ASC',
  CollapseHeaderDesc = 'collapseHeader_DESC',
  ShowHeaderAsc = 'showHeader_ASC',
  ShowHeaderDesc = 'showHeader_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC',
}

export enum ComponentAdvisorReviewsLinkingCollectionsTemplatePartnersCollectionOrder {
  CollapseHeaderAsc = 'collapseHeader_ASC',
  CollapseHeaderDesc = 'collapseHeader_DESC',
  ShowHeaderAsc = 'showHeader_ASC',
  ShowHeaderDesc = 'showHeader_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC',
}

export enum ComponentAdvisorReviewsLinkingCollectionsTemplateReviewsCollectionOrder {
  CollapseHeaderAsc = 'collapseHeader_ASC',
  CollapseHeaderDesc = 'collapseHeader_DESC',
  ShowHeaderAsc = 'showHeader_ASC',
  ShowHeaderDesc = 'showHeader_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC',
}

export enum ComponentAdvisorReviewsLinkingCollectionsTemplateWhyBookWithForaCollectionOrder {
  CollapseHeaderAsc = 'collapseHeader_ASC',
  CollapseHeaderDesc = 'collapseHeader_DESC',
  ShowHeaderAsc = 'showHeader_ASC',
  ShowHeaderDesc = 'showHeader_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC',
}

export enum ComponentAdvisorReviewsOrder {
  InternalLabelAsc = 'internalLabel_ASC',
  InternalLabelDesc = 'internalLabel_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
}

/** [See type definition](https://app.contentful.com/spaces/9cxgygtadmvl/content_types/componentAnimatedTiles) */
export type ComponentAnimatedTiles = Entry &
  _Node & {
    __typename?: 'ComponentAnimatedTiles'
    _id: Scalars['ID']
    contentfulMetadata: ContentfulMetadata
    heading?: Maybe<Scalars['String']>
    internalLabel?: Maybe<Scalars['String']>
    linkedFrom?: Maybe<ComponentAnimatedTilesLinkingCollections>
    subHeading?: Maybe<Scalars['String']>
    sys: Sys
  }

/** [See type definition](https://app.contentful.com/spaces/9cxgygtadmvl/content_types/componentAnimatedTiles) */
export type ComponentAnimatedTilesHeadingArgs = {
  locale?: InputMaybe<Scalars['String']>
}

/** [See type definition](https://app.contentful.com/spaces/9cxgygtadmvl/content_types/componentAnimatedTiles) */
export type ComponentAnimatedTilesInternalLabelArgs = {
  locale?: InputMaybe<Scalars['String']>
}

/** [See type definition](https://app.contentful.com/spaces/9cxgygtadmvl/content_types/componentAnimatedTiles) */
export type ComponentAnimatedTilesLinkedFromArgs = {
  allowedLocales?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
}

/** [See type definition](https://app.contentful.com/spaces/9cxgygtadmvl/content_types/componentAnimatedTiles) */
export type ComponentAnimatedTilesSubHeadingArgs = {
  locale?: InputMaybe<Scalars['String']>
}

export type ComponentAnimatedTilesCollection = {
  __typename?: 'ComponentAnimatedTilesCollection'
  items: Array<Maybe<ComponentAnimatedTiles>>
  limit: Scalars['Int']
  skip: Scalars['Int']
  total: Scalars['Int']
}

export type ComponentAnimatedTilesFilter = {
  AND?: InputMaybe<Array<InputMaybe<ComponentAnimatedTilesFilter>>>
  OR?: InputMaybe<Array<InputMaybe<ComponentAnimatedTilesFilter>>>
  contentfulMetadata?: InputMaybe<ContentfulMetadataFilter>
  heading?: InputMaybe<Scalars['String']>
  heading_contains?: InputMaybe<Scalars['String']>
  heading_exists?: InputMaybe<Scalars['Boolean']>
  heading_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  heading_not?: InputMaybe<Scalars['String']>
  heading_not_contains?: InputMaybe<Scalars['String']>
  heading_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  internalLabel?: InputMaybe<Scalars['String']>
  internalLabel_contains?: InputMaybe<Scalars['String']>
  internalLabel_exists?: InputMaybe<Scalars['Boolean']>
  internalLabel_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  internalLabel_not?: InputMaybe<Scalars['String']>
  internalLabel_not_contains?: InputMaybe<Scalars['String']>
  internalLabel_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  subHeading?: InputMaybe<Scalars['String']>
  subHeading_contains?: InputMaybe<Scalars['String']>
  subHeading_exists?: InputMaybe<Scalars['Boolean']>
  subHeading_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  subHeading_not?: InputMaybe<Scalars['String']>
  subHeading_not_contains?: InputMaybe<Scalars['String']>
  subHeading_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  sys?: InputMaybe<SysFilter>
}

export type ComponentAnimatedTilesLinkingCollections = {
  __typename?: 'ComponentAnimatedTilesLinkingCollections'
  campaignPagesCollection?: Maybe<CampaignPagesCollection>
  entryCollection?: Maybe<EntryCollection>
  templateAboutUsCollection?: Maybe<TemplateAboutUsCollection>
  templateBookingCollection?: Maybe<TemplateBookingCollection>
  templateCareersCollection?: Maybe<TemplateCareersCollection>
  templateContactUsCollection?: Maybe<TemplateContactUsCollection>
  templateDestinationsCollection?: Maybe<TemplateDestinationsCollection>
  templateForaReserveCollection?: Maybe<TemplateForaReserveCollection>
  templateForaXCollection?: Maybe<TemplateForaXCollection>
  templateHomeCollection?: Maybe<TemplateHomeCollection>
  templateJoinUsCollection?: Maybe<TemplateJoinUsCollection>
  templateNewsletterCollection?: Maybe<TemplateNewsletterCollection>
  templatePageCollection?: Maybe<TemplatePageCollection>
  templatePartnersCollection?: Maybe<TemplatePartnersCollection>
  templateReviewsCollection?: Maybe<TemplateReviewsCollection>
  templateWhyBookWithForaCollection?: Maybe<TemplateWhyBookWithForaCollection>
}

export type ComponentAnimatedTilesLinkingCollectionsCampaignPagesCollectionArgs =
  {
    limit?: InputMaybe<Scalars['Int']>
    locale?: InputMaybe<Scalars['String']>
    order?: InputMaybe<
      Array<
        InputMaybe<ComponentAnimatedTilesLinkingCollectionsCampaignPagesCollectionOrder>
      >
    >
    preview?: InputMaybe<Scalars['Boolean']>
    skip?: InputMaybe<Scalars['Int']>
  }

export type ComponentAnimatedTilesLinkingCollectionsEntryCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>
  locale?: InputMaybe<Scalars['String']>
  preview?: InputMaybe<Scalars['Boolean']>
  skip?: InputMaybe<Scalars['Int']>
}

export type ComponentAnimatedTilesLinkingCollectionsTemplateAboutUsCollectionArgs =
  {
    limit?: InputMaybe<Scalars['Int']>
    locale?: InputMaybe<Scalars['String']>
    order?: InputMaybe<
      Array<
        InputMaybe<ComponentAnimatedTilesLinkingCollectionsTemplateAboutUsCollectionOrder>
      >
    >
    preview?: InputMaybe<Scalars['Boolean']>
    skip?: InputMaybe<Scalars['Int']>
  }

export type ComponentAnimatedTilesLinkingCollectionsTemplateBookingCollectionArgs =
  {
    limit?: InputMaybe<Scalars['Int']>
    locale?: InputMaybe<Scalars['String']>
    order?: InputMaybe<
      Array<
        InputMaybe<ComponentAnimatedTilesLinkingCollectionsTemplateBookingCollectionOrder>
      >
    >
    preview?: InputMaybe<Scalars['Boolean']>
    skip?: InputMaybe<Scalars['Int']>
  }

export type ComponentAnimatedTilesLinkingCollectionsTemplateCareersCollectionArgs =
  {
    limit?: InputMaybe<Scalars['Int']>
    locale?: InputMaybe<Scalars['String']>
    order?: InputMaybe<
      Array<
        InputMaybe<ComponentAnimatedTilesLinkingCollectionsTemplateCareersCollectionOrder>
      >
    >
    preview?: InputMaybe<Scalars['Boolean']>
    skip?: InputMaybe<Scalars['Int']>
  }

export type ComponentAnimatedTilesLinkingCollectionsTemplateContactUsCollectionArgs =
  {
    limit?: InputMaybe<Scalars['Int']>
    locale?: InputMaybe<Scalars['String']>
    order?: InputMaybe<
      Array<
        InputMaybe<ComponentAnimatedTilesLinkingCollectionsTemplateContactUsCollectionOrder>
      >
    >
    preview?: InputMaybe<Scalars['Boolean']>
    skip?: InputMaybe<Scalars['Int']>
  }

export type ComponentAnimatedTilesLinkingCollectionsTemplateDestinationsCollectionArgs =
  {
    limit?: InputMaybe<Scalars['Int']>
    locale?: InputMaybe<Scalars['String']>
    order?: InputMaybe<
      Array<
        InputMaybe<ComponentAnimatedTilesLinkingCollectionsTemplateDestinationsCollectionOrder>
      >
    >
    preview?: InputMaybe<Scalars['Boolean']>
    skip?: InputMaybe<Scalars['Int']>
  }

export type ComponentAnimatedTilesLinkingCollectionsTemplateForaReserveCollectionArgs =
  {
    limit?: InputMaybe<Scalars['Int']>
    locale?: InputMaybe<Scalars['String']>
    order?: InputMaybe<
      Array<
        InputMaybe<ComponentAnimatedTilesLinkingCollectionsTemplateForaReserveCollectionOrder>
      >
    >
    preview?: InputMaybe<Scalars['Boolean']>
    skip?: InputMaybe<Scalars['Int']>
  }

export type ComponentAnimatedTilesLinkingCollectionsTemplateForaXCollectionArgs =
  {
    limit?: InputMaybe<Scalars['Int']>
    locale?: InputMaybe<Scalars['String']>
    order?: InputMaybe<
      Array<
        InputMaybe<ComponentAnimatedTilesLinkingCollectionsTemplateForaXCollectionOrder>
      >
    >
    preview?: InputMaybe<Scalars['Boolean']>
    skip?: InputMaybe<Scalars['Int']>
  }

export type ComponentAnimatedTilesLinkingCollectionsTemplateHomeCollectionArgs =
  {
    limit?: InputMaybe<Scalars['Int']>
    locale?: InputMaybe<Scalars['String']>
    order?: InputMaybe<
      Array<
        InputMaybe<ComponentAnimatedTilesLinkingCollectionsTemplateHomeCollectionOrder>
      >
    >
    preview?: InputMaybe<Scalars['Boolean']>
    skip?: InputMaybe<Scalars['Int']>
  }

export type ComponentAnimatedTilesLinkingCollectionsTemplateJoinUsCollectionArgs =
  {
    limit?: InputMaybe<Scalars['Int']>
    locale?: InputMaybe<Scalars['String']>
    order?: InputMaybe<
      Array<
        InputMaybe<ComponentAnimatedTilesLinkingCollectionsTemplateJoinUsCollectionOrder>
      >
    >
    preview?: InputMaybe<Scalars['Boolean']>
    skip?: InputMaybe<Scalars['Int']>
  }

export type ComponentAnimatedTilesLinkingCollectionsTemplateNewsletterCollectionArgs =
  {
    limit?: InputMaybe<Scalars['Int']>
    locale?: InputMaybe<Scalars['String']>
    order?: InputMaybe<
      Array<
        InputMaybe<ComponentAnimatedTilesLinkingCollectionsTemplateNewsletterCollectionOrder>
      >
    >
    preview?: InputMaybe<Scalars['Boolean']>
    skip?: InputMaybe<Scalars['Int']>
  }

export type ComponentAnimatedTilesLinkingCollectionsTemplatePageCollectionArgs =
  {
    limit?: InputMaybe<Scalars['Int']>
    locale?: InputMaybe<Scalars['String']>
    order?: InputMaybe<
      Array<
        InputMaybe<ComponentAnimatedTilesLinkingCollectionsTemplatePageCollectionOrder>
      >
    >
    preview?: InputMaybe<Scalars['Boolean']>
    skip?: InputMaybe<Scalars['Int']>
  }

export type ComponentAnimatedTilesLinkingCollectionsTemplatePartnersCollectionArgs =
  {
    limit?: InputMaybe<Scalars['Int']>
    locale?: InputMaybe<Scalars['String']>
    order?: InputMaybe<
      Array<
        InputMaybe<ComponentAnimatedTilesLinkingCollectionsTemplatePartnersCollectionOrder>
      >
    >
    preview?: InputMaybe<Scalars['Boolean']>
    skip?: InputMaybe<Scalars['Int']>
  }

export type ComponentAnimatedTilesLinkingCollectionsTemplateReviewsCollectionArgs =
  {
    limit?: InputMaybe<Scalars['Int']>
    locale?: InputMaybe<Scalars['String']>
    order?: InputMaybe<
      Array<
        InputMaybe<ComponentAnimatedTilesLinkingCollectionsTemplateReviewsCollectionOrder>
      >
    >
    preview?: InputMaybe<Scalars['Boolean']>
    skip?: InputMaybe<Scalars['Int']>
  }

export type ComponentAnimatedTilesLinkingCollectionsTemplateWhyBookWithForaCollectionArgs =
  {
    limit?: InputMaybe<Scalars['Int']>
    locale?: InputMaybe<Scalars['String']>
    order?: InputMaybe<
      Array<
        InputMaybe<ComponentAnimatedTilesLinkingCollectionsTemplateWhyBookWithForaCollectionOrder>
      >
    >
    preview?: InputMaybe<Scalars['Boolean']>
    skip?: InputMaybe<Scalars['Int']>
  }

export enum ComponentAnimatedTilesLinkingCollectionsCampaignPagesCollectionOrder {
  CollapseFooterAsc = 'collapseFooter_ASC',
  CollapseFooterDesc = 'collapseFooter_DESC',
  CollapseHeaderAsc = 'collapseHeader_ASC',
  CollapseHeaderDesc = 'collapseHeader_DESC',
  HideFooterAsc = 'hideFooter_ASC',
  HideFooterDesc = 'hideFooter_DESC',
  HideGlobalBannerAsc = 'hideGlobalBanner_ASC',
  HideGlobalBannerDesc = 'hideGlobalBanner_DESC',
  JotformIdAsc = 'jotformId_ASC',
  JotformIdDesc = 'jotformId_DESC',
  NoindexNofollowAsc = 'noindexNofollow_ASC',
  NoindexNofollowDesc = 'noindexNofollow_DESC',
  ShowHeaderAsc = 'showHeader_ASC',
  ShowHeaderDesc = 'showHeader_DESC',
  SlugAsc = 'slug_ASC',
  SlugDesc = 'slug_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC',
}

export enum ComponentAnimatedTilesLinkingCollectionsTemplateAboutUsCollectionOrder {
  CollapseHeaderAsc = 'collapseHeader_ASC',
  CollapseHeaderDesc = 'collapseHeader_DESC',
  HeaderBlockTitleAsc = 'headerBlockTitle_ASC',
  HeaderBlockTitleDesc = 'headerBlockTitle_DESC',
  ShowHeaderAsc = 'showHeader_ASC',
  ShowHeaderDesc = 'showHeader_DESC',
  SubtitleAsc = 'subtitle_ASC',
  SubtitleDesc = 'subtitle_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC',
}

export enum ComponentAnimatedTilesLinkingCollectionsTemplateBookingCollectionOrder {
  CollapseHeaderAsc = 'collapseHeader_ASC',
  CollapseHeaderDesc = 'collapseHeader_DESC',
  ShowHeaderAsc = 'showHeader_ASC',
  ShowHeaderDesc = 'showHeader_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC',
}

export enum ComponentAnimatedTilesLinkingCollectionsTemplateCareersCollectionOrder {
  CollapseHeaderAsc = 'collapseHeader_ASC',
  CollapseHeaderDesc = 'collapseHeader_DESC',
  ShowHeaderAsc = 'showHeader_ASC',
  ShowHeaderDesc = 'showHeader_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC',
}

export enum ComponentAnimatedTilesLinkingCollectionsTemplateContactUsCollectionOrder {
  CollapseHeaderAsc = 'collapseHeader_ASC',
  CollapseHeaderDesc = 'collapseHeader_DESC',
  ShowHeaderAsc = 'showHeader_ASC',
  ShowHeaderDesc = 'showHeader_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC',
}

export enum ComponentAnimatedTilesLinkingCollectionsTemplateDestinationsCollectionOrder {
  CollapseHeaderAsc = 'collapseHeader_ASC',
  CollapseHeaderDesc = 'collapseHeader_DESC',
  CollapsibleHeadingAsc = 'collapsibleHeading_ASC',
  CollapsibleHeadingDesc = 'collapsibleHeading_DESC',
  ShowHeaderAsc = 'showHeader_ASC',
  ShowHeaderDesc = 'showHeader_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC',
}

export enum ComponentAnimatedTilesLinkingCollectionsTemplateForaReserveCollectionOrder {
  CollapseHeaderAsc = 'collapseHeader_ASC',
  CollapseHeaderDesc = 'collapseHeader_DESC',
  ShowHeaderAsc = 'showHeader_ASC',
  ShowHeaderDesc = 'showHeader_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC',
}

export enum ComponentAnimatedTilesLinkingCollectionsTemplateForaXCollectionOrder {
  AdvisorGridHeaderAsc = 'advisorGridHeader_ASC',
  AdvisorGridHeaderDesc = 'advisorGridHeader_DESC',
  AdvisorGridSubCopyAsc = 'advisorGridSubCopy_ASC',
  AdvisorGridSubCopyDesc = 'advisorGridSubCopy_DESC',
  CollapseHeaderAsc = 'collapseHeader_ASC',
  CollapseHeaderDesc = 'collapseHeader_DESC',
  ShowHeaderAsc = 'showHeader_ASC',
  ShowHeaderDesc = 'showHeader_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC',
}

export enum ComponentAnimatedTilesLinkingCollectionsTemplateHomeCollectionOrder {
  CollapseHeaderAsc = 'collapseHeader_ASC',
  CollapseHeaderDesc = 'collapseHeader_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC',
}

export enum ComponentAnimatedTilesLinkingCollectionsTemplateJoinUsCollectionOrder {
  CollapseHeaderAsc = 'collapseHeader_ASC',
  CollapseHeaderDesc = 'collapseHeader_DESC',
  HideGlobalBannerAsc = 'hideGlobalBanner_ASC',
  HideGlobalBannerDesc = 'hideGlobalBanner_DESC',
  ShowHeaderAsc = 'showHeader_ASC',
  ShowHeaderDesc = 'showHeader_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC',
}

export enum ComponentAnimatedTilesLinkingCollectionsTemplateNewsletterCollectionOrder {
  CollapseHeaderAsc = 'collapseHeader_ASC',
  CollapseHeaderDesc = 'collapseHeader_DESC',
  ShowHeaderAsc = 'showHeader_ASC',
  ShowHeaderDesc = 'showHeader_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC',
}

export enum ComponentAnimatedTilesLinkingCollectionsTemplatePageCollectionOrder {
  DirectoryAsc = 'directory_ASC',
  DirectoryDesc = 'directory_DESC',
  InternalLabelAsc = 'internalLabel_ASC',
  InternalLabelDesc = 'internalLabel_DESC',
  MetaTitleAsc = 'metaTitle_ASC',
  MetaTitleDesc = 'metaTitle_DESC',
  NoindexNofollowAsc = 'noindexNofollow_ASC',
  NoindexNofollowDesc = 'noindexNofollow_DESC',
  SlugAsc = 'slug_ASC',
  SlugDesc = 'slug_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
}

export enum ComponentAnimatedTilesLinkingCollectionsTemplatePartnersCollectionOrder {
  CollapseHeaderAsc = 'collapseHeader_ASC',
  CollapseHeaderDesc = 'collapseHeader_DESC',
  ShowHeaderAsc = 'showHeader_ASC',
  ShowHeaderDesc = 'showHeader_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC',
}

export enum ComponentAnimatedTilesLinkingCollectionsTemplateReviewsCollectionOrder {
  CollapseHeaderAsc = 'collapseHeader_ASC',
  CollapseHeaderDesc = 'collapseHeader_DESC',
  ShowHeaderAsc = 'showHeader_ASC',
  ShowHeaderDesc = 'showHeader_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC',
}

export enum ComponentAnimatedTilesLinkingCollectionsTemplateWhyBookWithForaCollectionOrder {
  CollapseHeaderAsc = 'collapseHeader_ASC',
  CollapseHeaderDesc = 'collapseHeader_DESC',
  ShowHeaderAsc = 'showHeader_ASC',
  ShowHeaderDesc = 'showHeader_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC',
}

export enum ComponentAnimatedTilesOrder {
  HeadingAsc = 'heading_ASC',
  HeadingDesc = 'heading_DESC',
  InternalLabelAsc = 'internalLabel_ASC',
  InternalLabelDesc = 'internalLabel_DESC',
  SubHeadingAsc = 'subHeading_ASC',
  SubHeadingDesc = 'subHeading_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
}

/** [See type definition](https://app.contentful.com/spaces/9cxgygtadmvl/content_types/componentButtonCta) */
export type ComponentButtonCta = Entry &
  _Node & {
    __typename?: 'ComponentButtonCta'
    _id: Scalars['ID']
    buttonOneStyle?: Maybe<Scalars['String']>
    buttonOneText?: Maybe<Scalars['String']>
    buttonOneUrl?: Maybe<Scalars['String']>
    buttonOneUrlTarget?: Maybe<Scalars['String']>
    buttonTwoStyle?: Maybe<Scalars['String']>
    buttonTwoText?: Maybe<Scalars['String']>
    buttonTwoUrl?: Maybe<Scalars['String']>
    buttonTwoUrlTarget?: Maybe<Scalars['String']>
    contentfulMetadata: ContentfulMetadata
    internalTitle?: Maybe<Scalars['String']>
    linkedFrom?: Maybe<ComponentButtonCtaLinkingCollections>
    sys: Sys
  }

/** [See type definition](https://app.contentful.com/spaces/9cxgygtadmvl/content_types/componentButtonCta) */
export type ComponentButtonCtaButtonOneStyleArgs = {
  locale?: InputMaybe<Scalars['String']>
}

/** [See type definition](https://app.contentful.com/spaces/9cxgygtadmvl/content_types/componentButtonCta) */
export type ComponentButtonCtaButtonOneTextArgs = {
  locale?: InputMaybe<Scalars['String']>
}

/** [See type definition](https://app.contentful.com/spaces/9cxgygtadmvl/content_types/componentButtonCta) */
export type ComponentButtonCtaButtonOneUrlArgs = {
  locale?: InputMaybe<Scalars['String']>
}

/** [See type definition](https://app.contentful.com/spaces/9cxgygtadmvl/content_types/componentButtonCta) */
export type ComponentButtonCtaButtonOneUrlTargetArgs = {
  locale?: InputMaybe<Scalars['String']>
}

/** [See type definition](https://app.contentful.com/spaces/9cxgygtadmvl/content_types/componentButtonCta) */
export type ComponentButtonCtaButtonTwoStyleArgs = {
  locale?: InputMaybe<Scalars['String']>
}

/** [See type definition](https://app.contentful.com/spaces/9cxgygtadmvl/content_types/componentButtonCta) */
export type ComponentButtonCtaButtonTwoTextArgs = {
  locale?: InputMaybe<Scalars['String']>
}

/** [See type definition](https://app.contentful.com/spaces/9cxgygtadmvl/content_types/componentButtonCta) */
export type ComponentButtonCtaButtonTwoUrlArgs = {
  locale?: InputMaybe<Scalars['String']>
}

/** [See type definition](https://app.contentful.com/spaces/9cxgygtadmvl/content_types/componentButtonCta) */
export type ComponentButtonCtaButtonTwoUrlTargetArgs = {
  locale?: InputMaybe<Scalars['String']>
}

/** [See type definition](https://app.contentful.com/spaces/9cxgygtadmvl/content_types/componentButtonCta) */
export type ComponentButtonCtaInternalTitleArgs = {
  locale?: InputMaybe<Scalars['String']>
}

/** [See type definition](https://app.contentful.com/spaces/9cxgygtadmvl/content_types/componentButtonCta) */
export type ComponentButtonCtaLinkedFromArgs = {
  allowedLocales?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
}

export type ComponentButtonCtaCollection = {
  __typename?: 'ComponentButtonCtaCollection'
  items: Array<Maybe<ComponentButtonCta>>
  limit: Scalars['Int']
  skip: Scalars['Int']
  total: Scalars['Int']
}

export type ComponentButtonCtaFilter = {
  AND?: InputMaybe<Array<InputMaybe<ComponentButtonCtaFilter>>>
  OR?: InputMaybe<Array<InputMaybe<ComponentButtonCtaFilter>>>
  buttonOneStyle?: InputMaybe<Scalars['String']>
  buttonOneStyle_contains?: InputMaybe<Scalars['String']>
  buttonOneStyle_exists?: InputMaybe<Scalars['Boolean']>
  buttonOneStyle_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  buttonOneStyle_not?: InputMaybe<Scalars['String']>
  buttonOneStyle_not_contains?: InputMaybe<Scalars['String']>
  buttonOneStyle_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  buttonOneText?: InputMaybe<Scalars['String']>
  buttonOneText_contains?: InputMaybe<Scalars['String']>
  buttonOneText_exists?: InputMaybe<Scalars['Boolean']>
  buttonOneText_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  buttonOneText_not?: InputMaybe<Scalars['String']>
  buttonOneText_not_contains?: InputMaybe<Scalars['String']>
  buttonOneText_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  buttonOneUrl?: InputMaybe<Scalars['String']>
  buttonOneUrlTarget?: InputMaybe<Scalars['String']>
  buttonOneUrlTarget_contains?: InputMaybe<Scalars['String']>
  buttonOneUrlTarget_exists?: InputMaybe<Scalars['Boolean']>
  buttonOneUrlTarget_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  buttonOneUrlTarget_not?: InputMaybe<Scalars['String']>
  buttonOneUrlTarget_not_contains?: InputMaybe<Scalars['String']>
  buttonOneUrlTarget_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  buttonOneUrl_contains?: InputMaybe<Scalars['String']>
  buttonOneUrl_exists?: InputMaybe<Scalars['Boolean']>
  buttonOneUrl_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  buttonOneUrl_not?: InputMaybe<Scalars['String']>
  buttonOneUrl_not_contains?: InputMaybe<Scalars['String']>
  buttonOneUrl_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  buttonTwoStyle?: InputMaybe<Scalars['String']>
  buttonTwoStyle_contains?: InputMaybe<Scalars['String']>
  buttonTwoStyle_exists?: InputMaybe<Scalars['Boolean']>
  buttonTwoStyle_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  buttonTwoStyle_not?: InputMaybe<Scalars['String']>
  buttonTwoStyle_not_contains?: InputMaybe<Scalars['String']>
  buttonTwoStyle_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  buttonTwoText?: InputMaybe<Scalars['String']>
  buttonTwoText_contains?: InputMaybe<Scalars['String']>
  buttonTwoText_exists?: InputMaybe<Scalars['Boolean']>
  buttonTwoText_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  buttonTwoText_not?: InputMaybe<Scalars['String']>
  buttonTwoText_not_contains?: InputMaybe<Scalars['String']>
  buttonTwoText_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  buttonTwoUrl?: InputMaybe<Scalars['String']>
  buttonTwoUrlTarget?: InputMaybe<Scalars['String']>
  buttonTwoUrlTarget_contains?: InputMaybe<Scalars['String']>
  buttonTwoUrlTarget_exists?: InputMaybe<Scalars['Boolean']>
  buttonTwoUrlTarget_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  buttonTwoUrlTarget_not?: InputMaybe<Scalars['String']>
  buttonTwoUrlTarget_not_contains?: InputMaybe<Scalars['String']>
  buttonTwoUrlTarget_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  buttonTwoUrl_contains?: InputMaybe<Scalars['String']>
  buttonTwoUrl_exists?: InputMaybe<Scalars['Boolean']>
  buttonTwoUrl_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  buttonTwoUrl_not?: InputMaybe<Scalars['String']>
  buttonTwoUrl_not_contains?: InputMaybe<Scalars['String']>
  buttonTwoUrl_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  contentfulMetadata?: InputMaybe<ContentfulMetadataFilter>
  internalTitle?: InputMaybe<Scalars['String']>
  internalTitle_contains?: InputMaybe<Scalars['String']>
  internalTitle_exists?: InputMaybe<Scalars['Boolean']>
  internalTitle_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  internalTitle_not?: InputMaybe<Scalars['String']>
  internalTitle_not_contains?: InputMaybe<Scalars['String']>
  internalTitle_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  sys?: InputMaybe<SysFilter>
}

export type ComponentButtonCtaLinkingCollections = {
  __typename?: 'ComponentButtonCtaLinkingCollections'
  campaignPagesCollection?: Maybe<CampaignPagesCollection>
  entryCollection?: Maybe<EntryCollection>
  templateAboutUsCollection?: Maybe<TemplateAboutUsCollection>
  templateBookingCollection?: Maybe<TemplateBookingCollection>
  templateCareersCollection?: Maybe<TemplateCareersCollection>
  templateContactUsCollection?: Maybe<TemplateContactUsCollection>
  templateDestinationsCollection?: Maybe<TemplateDestinationsCollection>
  templateForaReserveCollection?: Maybe<TemplateForaReserveCollection>
  templateForaXCollection?: Maybe<TemplateForaXCollection>
  templateHomeCollection?: Maybe<TemplateHomeCollection>
  templateJoinUsCollection?: Maybe<TemplateJoinUsCollection>
  templateNewsletterCollection?: Maybe<TemplateNewsletterCollection>
  templatePageCollection?: Maybe<TemplatePageCollection>
  templatePartnersCollection?: Maybe<TemplatePartnersCollection>
  templateReviewsCollection?: Maybe<TemplateReviewsCollection>
  templateWhyBookWithForaCollection?: Maybe<TemplateWhyBookWithForaCollection>
}

export type ComponentButtonCtaLinkingCollectionsCampaignPagesCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>
  locale?: InputMaybe<Scalars['String']>
  order?: InputMaybe<
    Array<
      InputMaybe<ComponentButtonCtaLinkingCollectionsCampaignPagesCollectionOrder>
    >
  >
  preview?: InputMaybe<Scalars['Boolean']>
  skip?: InputMaybe<Scalars['Int']>
}

export type ComponentButtonCtaLinkingCollectionsEntryCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>
  locale?: InputMaybe<Scalars['String']>
  preview?: InputMaybe<Scalars['Boolean']>
  skip?: InputMaybe<Scalars['Int']>
}

export type ComponentButtonCtaLinkingCollectionsTemplateAboutUsCollectionArgs =
  {
    limit?: InputMaybe<Scalars['Int']>
    locale?: InputMaybe<Scalars['String']>
    order?: InputMaybe<
      Array<
        InputMaybe<ComponentButtonCtaLinkingCollectionsTemplateAboutUsCollectionOrder>
      >
    >
    preview?: InputMaybe<Scalars['Boolean']>
    skip?: InputMaybe<Scalars['Int']>
  }

export type ComponentButtonCtaLinkingCollectionsTemplateBookingCollectionArgs =
  {
    limit?: InputMaybe<Scalars['Int']>
    locale?: InputMaybe<Scalars['String']>
    order?: InputMaybe<
      Array<
        InputMaybe<ComponentButtonCtaLinkingCollectionsTemplateBookingCollectionOrder>
      >
    >
    preview?: InputMaybe<Scalars['Boolean']>
    skip?: InputMaybe<Scalars['Int']>
  }

export type ComponentButtonCtaLinkingCollectionsTemplateCareersCollectionArgs =
  {
    limit?: InputMaybe<Scalars['Int']>
    locale?: InputMaybe<Scalars['String']>
    order?: InputMaybe<
      Array<
        InputMaybe<ComponentButtonCtaLinkingCollectionsTemplateCareersCollectionOrder>
      >
    >
    preview?: InputMaybe<Scalars['Boolean']>
    skip?: InputMaybe<Scalars['Int']>
  }

export type ComponentButtonCtaLinkingCollectionsTemplateContactUsCollectionArgs =
  {
    limit?: InputMaybe<Scalars['Int']>
    locale?: InputMaybe<Scalars['String']>
    order?: InputMaybe<
      Array<
        InputMaybe<ComponentButtonCtaLinkingCollectionsTemplateContactUsCollectionOrder>
      >
    >
    preview?: InputMaybe<Scalars['Boolean']>
    skip?: InputMaybe<Scalars['Int']>
  }

export type ComponentButtonCtaLinkingCollectionsTemplateDestinationsCollectionArgs =
  {
    limit?: InputMaybe<Scalars['Int']>
    locale?: InputMaybe<Scalars['String']>
    order?: InputMaybe<
      Array<
        InputMaybe<ComponentButtonCtaLinkingCollectionsTemplateDestinationsCollectionOrder>
      >
    >
    preview?: InputMaybe<Scalars['Boolean']>
    skip?: InputMaybe<Scalars['Int']>
  }

export type ComponentButtonCtaLinkingCollectionsTemplateForaReserveCollectionArgs =
  {
    limit?: InputMaybe<Scalars['Int']>
    locale?: InputMaybe<Scalars['String']>
    order?: InputMaybe<
      Array<
        InputMaybe<ComponentButtonCtaLinkingCollectionsTemplateForaReserveCollectionOrder>
      >
    >
    preview?: InputMaybe<Scalars['Boolean']>
    skip?: InputMaybe<Scalars['Int']>
  }

export type ComponentButtonCtaLinkingCollectionsTemplateForaXCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>
  locale?: InputMaybe<Scalars['String']>
  order?: InputMaybe<
    Array<
      InputMaybe<ComponentButtonCtaLinkingCollectionsTemplateForaXCollectionOrder>
    >
  >
  preview?: InputMaybe<Scalars['Boolean']>
  skip?: InputMaybe<Scalars['Int']>
}

export type ComponentButtonCtaLinkingCollectionsTemplateHomeCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>
  locale?: InputMaybe<Scalars['String']>
  order?: InputMaybe<
    Array<
      InputMaybe<ComponentButtonCtaLinkingCollectionsTemplateHomeCollectionOrder>
    >
  >
  preview?: InputMaybe<Scalars['Boolean']>
  skip?: InputMaybe<Scalars['Int']>
}

export type ComponentButtonCtaLinkingCollectionsTemplateJoinUsCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>
  locale?: InputMaybe<Scalars['String']>
  order?: InputMaybe<
    Array<
      InputMaybe<ComponentButtonCtaLinkingCollectionsTemplateJoinUsCollectionOrder>
    >
  >
  preview?: InputMaybe<Scalars['Boolean']>
  skip?: InputMaybe<Scalars['Int']>
}

export type ComponentButtonCtaLinkingCollectionsTemplateNewsletterCollectionArgs =
  {
    limit?: InputMaybe<Scalars['Int']>
    locale?: InputMaybe<Scalars['String']>
    order?: InputMaybe<
      Array<
        InputMaybe<ComponentButtonCtaLinkingCollectionsTemplateNewsletterCollectionOrder>
      >
    >
    preview?: InputMaybe<Scalars['Boolean']>
    skip?: InputMaybe<Scalars['Int']>
  }

export type ComponentButtonCtaLinkingCollectionsTemplatePageCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>
  locale?: InputMaybe<Scalars['String']>
  order?: InputMaybe<
    Array<
      InputMaybe<ComponentButtonCtaLinkingCollectionsTemplatePageCollectionOrder>
    >
  >
  preview?: InputMaybe<Scalars['Boolean']>
  skip?: InputMaybe<Scalars['Int']>
}

export type ComponentButtonCtaLinkingCollectionsTemplatePartnersCollectionArgs =
  {
    limit?: InputMaybe<Scalars['Int']>
    locale?: InputMaybe<Scalars['String']>
    order?: InputMaybe<
      Array<
        InputMaybe<ComponentButtonCtaLinkingCollectionsTemplatePartnersCollectionOrder>
      >
    >
    preview?: InputMaybe<Scalars['Boolean']>
    skip?: InputMaybe<Scalars['Int']>
  }

export type ComponentButtonCtaLinkingCollectionsTemplateReviewsCollectionArgs =
  {
    limit?: InputMaybe<Scalars['Int']>
    locale?: InputMaybe<Scalars['String']>
    order?: InputMaybe<
      Array<
        InputMaybe<ComponentButtonCtaLinkingCollectionsTemplateReviewsCollectionOrder>
      >
    >
    preview?: InputMaybe<Scalars['Boolean']>
    skip?: InputMaybe<Scalars['Int']>
  }

export type ComponentButtonCtaLinkingCollectionsTemplateWhyBookWithForaCollectionArgs =
  {
    limit?: InputMaybe<Scalars['Int']>
    locale?: InputMaybe<Scalars['String']>
    order?: InputMaybe<
      Array<
        InputMaybe<ComponentButtonCtaLinkingCollectionsTemplateWhyBookWithForaCollectionOrder>
      >
    >
    preview?: InputMaybe<Scalars['Boolean']>
    skip?: InputMaybe<Scalars['Int']>
  }

export enum ComponentButtonCtaLinkingCollectionsCampaignPagesCollectionOrder {
  CollapseFooterAsc = 'collapseFooter_ASC',
  CollapseFooterDesc = 'collapseFooter_DESC',
  CollapseHeaderAsc = 'collapseHeader_ASC',
  CollapseHeaderDesc = 'collapseHeader_DESC',
  HideFooterAsc = 'hideFooter_ASC',
  HideFooterDesc = 'hideFooter_DESC',
  HideGlobalBannerAsc = 'hideGlobalBanner_ASC',
  HideGlobalBannerDesc = 'hideGlobalBanner_DESC',
  JotformIdAsc = 'jotformId_ASC',
  JotformIdDesc = 'jotformId_DESC',
  NoindexNofollowAsc = 'noindexNofollow_ASC',
  NoindexNofollowDesc = 'noindexNofollow_DESC',
  ShowHeaderAsc = 'showHeader_ASC',
  ShowHeaderDesc = 'showHeader_DESC',
  SlugAsc = 'slug_ASC',
  SlugDesc = 'slug_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC',
}

export enum ComponentButtonCtaLinkingCollectionsTemplateAboutUsCollectionOrder {
  CollapseHeaderAsc = 'collapseHeader_ASC',
  CollapseHeaderDesc = 'collapseHeader_DESC',
  HeaderBlockTitleAsc = 'headerBlockTitle_ASC',
  HeaderBlockTitleDesc = 'headerBlockTitle_DESC',
  ShowHeaderAsc = 'showHeader_ASC',
  ShowHeaderDesc = 'showHeader_DESC',
  SubtitleAsc = 'subtitle_ASC',
  SubtitleDesc = 'subtitle_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC',
}

export enum ComponentButtonCtaLinkingCollectionsTemplateBookingCollectionOrder {
  CollapseHeaderAsc = 'collapseHeader_ASC',
  CollapseHeaderDesc = 'collapseHeader_DESC',
  ShowHeaderAsc = 'showHeader_ASC',
  ShowHeaderDesc = 'showHeader_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC',
}

export enum ComponentButtonCtaLinkingCollectionsTemplateCareersCollectionOrder {
  CollapseHeaderAsc = 'collapseHeader_ASC',
  CollapseHeaderDesc = 'collapseHeader_DESC',
  ShowHeaderAsc = 'showHeader_ASC',
  ShowHeaderDesc = 'showHeader_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC',
}

export enum ComponentButtonCtaLinkingCollectionsTemplateContactUsCollectionOrder {
  CollapseHeaderAsc = 'collapseHeader_ASC',
  CollapseHeaderDesc = 'collapseHeader_DESC',
  ShowHeaderAsc = 'showHeader_ASC',
  ShowHeaderDesc = 'showHeader_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC',
}

export enum ComponentButtonCtaLinkingCollectionsTemplateDestinationsCollectionOrder {
  CollapseHeaderAsc = 'collapseHeader_ASC',
  CollapseHeaderDesc = 'collapseHeader_DESC',
  CollapsibleHeadingAsc = 'collapsibleHeading_ASC',
  CollapsibleHeadingDesc = 'collapsibleHeading_DESC',
  ShowHeaderAsc = 'showHeader_ASC',
  ShowHeaderDesc = 'showHeader_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC',
}

export enum ComponentButtonCtaLinkingCollectionsTemplateForaReserveCollectionOrder {
  CollapseHeaderAsc = 'collapseHeader_ASC',
  CollapseHeaderDesc = 'collapseHeader_DESC',
  ShowHeaderAsc = 'showHeader_ASC',
  ShowHeaderDesc = 'showHeader_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC',
}

export enum ComponentButtonCtaLinkingCollectionsTemplateForaXCollectionOrder {
  AdvisorGridHeaderAsc = 'advisorGridHeader_ASC',
  AdvisorGridHeaderDesc = 'advisorGridHeader_DESC',
  AdvisorGridSubCopyAsc = 'advisorGridSubCopy_ASC',
  AdvisorGridSubCopyDesc = 'advisorGridSubCopy_DESC',
  CollapseHeaderAsc = 'collapseHeader_ASC',
  CollapseHeaderDesc = 'collapseHeader_DESC',
  ShowHeaderAsc = 'showHeader_ASC',
  ShowHeaderDesc = 'showHeader_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC',
}

export enum ComponentButtonCtaLinkingCollectionsTemplateHomeCollectionOrder {
  CollapseHeaderAsc = 'collapseHeader_ASC',
  CollapseHeaderDesc = 'collapseHeader_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC',
}

export enum ComponentButtonCtaLinkingCollectionsTemplateJoinUsCollectionOrder {
  CollapseHeaderAsc = 'collapseHeader_ASC',
  CollapseHeaderDesc = 'collapseHeader_DESC',
  HideGlobalBannerAsc = 'hideGlobalBanner_ASC',
  HideGlobalBannerDesc = 'hideGlobalBanner_DESC',
  ShowHeaderAsc = 'showHeader_ASC',
  ShowHeaderDesc = 'showHeader_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC',
}

export enum ComponentButtonCtaLinkingCollectionsTemplateNewsletterCollectionOrder {
  CollapseHeaderAsc = 'collapseHeader_ASC',
  CollapseHeaderDesc = 'collapseHeader_DESC',
  ShowHeaderAsc = 'showHeader_ASC',
  ShowHeaderDesc = 'showHeader_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC',
}

export enum ComponentButtonCtaLinkingCollectionsTemplatePageCollectionOrder {
  DirectoryAsc = 'directory_ASC',
  DirectoryDesc = 'directory_DESC',
  InternalLabelAsc = 'internalLabel_ASC',
  InternalLabelDesc = 'internalLabel_DESC',
  MetaTitleAsc = 'metaTitle_ASC',
  MetaTitleDesc = 'metaTitle_DESC',
  NoindexNofollowAsc = 'noindexNofollow_ASC',
  NoindexNofollowDesc = 'noindexNofollow_DESC',
  SlugAsc = 'slug_ASC',
  SlugDesc = 'slug_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
}

export enum ComponentButtonCtaLinkingCollectionsTemplatePartnersCollectionOrder {
  CollapseHeaderAsc = 'collapseHeader_ASC',
  CollapseHeaderDesc = 'collapseHeader_DESC',
  ShowHeaderAsc = 'showHeader_ASC',
  ShowHeaderDesc = 'showHeader_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC',
}

export enum ComponentButtonCtaLinkingCollectionsTemplateReviewsCollectionOrder {
  CollapseHeaderAsc = 'collapseHeader_ASC',
  CollapseHeaderDesc = 'collapseHeader_DESC',
  ShowHeaderAsc = 'showHeader_ASC',
  ShowHeaderDesc = 'showHeader_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC',
}

export enum ComponentButtonCtaLinkingCollectionsTemplateWhyBookWithForaCollectionOrder {
  CollapseHeaderAsc = 'collapseHeader_ASC',
  CollapseHeaderDesc = 'collapseHeader_DESC',
  ShowHeaderAsc = 'showHeader_ASC',
  ShowHeaderDesc = 'showHeader_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC',
}

export enum ComponentButtonCtaOrder {
  ButtonOneStyleAsc = 'buttonOneStyle_ASC',
  ButtonOneStyleDesc = 'buttonOneStyle_DESC',
  ButtonOneTextAsc = 'buttonOneText_ASC',
  ButtonOneTextDesc = 'buttonOneText_DESC',
  ButtonOneUrlTargetAsc = 'buttonOneUrlTarget_ASC',
  ButtonOneUrlTargetDesc = 'buttonOneUrlTarget_DESC',
  ButtonOneUrlAsc = 'buttonOneUrl_ASC',
  ButtonOneUrlDesc = 'buttonOneUrl_DESC',
  ButtonTwoStyleAsc = 'buttonTwoStyle_ASC',
  ButtonTwoStyleDesc = 'buttonTwoStyle_DESC',
  ButtonTwoTextAsc = 'buttonTwoText_ASC',
  ButtonTwoTextDesc = 'buttonTwoText_DESC',
  ButtonTwoUrlTargetAsc = 'buttonTwoUrlTarget_ASC',
  ButtonTwoUrlTargetDesc = 'buttonTwoUrlTarget_DESC',
  ButtonTwoUrlAsc = 'buttonTwoUrl_ASC',
  ButtonTwoUrlDesc = 'buttonTwoUrl_DESC',
  InternalTitleAsc = 'internalTitle_ASC',
  InternalTitleDesc = 'internalTitle_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
}

/** A card item to be displayed within a Carousel. [See type definition](https://app.contentful.com/spaces/9cxgygtadmvl/content_types/componentCarouselItem) */
export type ComponentCarouselItem = Entry &
  _Node & {
    __typename?: 'ComponentCarouselItem'
    _id: Scalars['ID']
    analyticsModule?: Maybe<SettingsAnalyticsModule>
    contentfulMetadata: ContentfulMetadata
    image?: Maybe<Asset>
    internalLabel?: Maybe<Scalars['String']>
    linkedFrom?: Maybe<ComponentCarouselItemLinkingCollections>
    primaryLabel?: Maybe<Scalars['String']>
    secondaryLabel?: Maybe<Scalars['String']>
    statement?: Maybe<Scalars['String']>
    sys: Sys
  }

/** A card item to be displayed within a Carousel. [See type definition](https://app.contentful.com/spaces/9cxgygtadmvl/content_types/componentCarouselItem) */
export type ComponentCarouselItemAnalyticsModuleArgs = {
  locale?: InputMaybe<Scalars['String']>
  preview?: InputMaybe<Scalars['Boolean']>
  where?: InputMaybe<SettingsAnalyticsModuleFilter>
}

/** A card item to be displayed within a Carousel. [See type definition](https://app.contentful.com/spaces/9cxgygtadmvl/content_types/componentCarouselItem) */
export type ComponentCarouselItemImageArgs = {
  locale?: InputMaybe<Scalars['String']>
  preview?: InputMaybe<Scalars['Boolean']>
}

/** A card item to be displayed within a Carousel. [See type definition](https://app.contentful.com/spaces/9cxgygtadmvl/content_types/componentCarouselItem) */
export type ComponentCarouselItemInternalLabelArgs = {
  locale?: InputMaybe<Scalars['String']>
}

/** A card item to be displayed within a Carousel. [See type definition](https://app.contentful.com/spaces/9cxgygtadmvl/content_types/componentCarouselItem) */
export type ComponentCarouselItemLinkedFromArgs = {
  allowedLocales?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
}

/** A card item to be displayed within a Carousel. [See type definition](https://app.contentful.com/spaces/9cxgygtadmvl/content_types/componentCarouselItem) */
export type ComponentCarouselItemPrimaryLabelArgs = {
  locale?: InputMaybe<Scalars['String']>
}

/** A card item to be displayed within a Carousel. [See type definition](https://app.contentful.com/spaces/9cxgygtadmvl/content_types/componentCarouselItem) */
export type ComponentCarouselItemSecondaryLabelArgs = {
  locale?: InputMaybe<Scalars['String']>
}

/** A card item to be displayed within a Carousel. [See type definition](https://app.contentful.com/spaces/9cxgygtadmvl/content_types/componentCarouselItem) */
export type ComponentCarouselItemStatementArgs = {
  locale?: InputMaybe<Scalars['String']>
}

export type ComponentCarouselItemCollection = {
  __typename?: 'ComponentCarouselItemCollection'
  items: Array<Maybe<ComponentCarouselItem>>
  limit: Scalars['Int']
  skip: Scalars['Int']
  total: Scalars['Int']
}

export type ComponentCarouselItemFilter = {
  AND?: InputMaybe<Array<InputMaybe<ComponentCarouselItemFilter>>>
  OR?: InputMaybe<Array<InputMaybe<ComponentCarouselItemFilter>>>
  analyticsModule?: InputMaybe<CfSettingsAnalyticsModuleNestedFilter>
  analyticsModule_exists?: InputMaybe<Scalars['Boolean']>
  contentfulMetadata?: InputMaybe<ContentfulMetadataFilter>
  image_exists?: InputMaybe<Scalars['Boolean']>
  internalLabel?: InputMaybe<Scalars['String']>
  internalLabel_contains?: InputMaybe<Scalars['String']>
  internalLabel_exists?: InputMaybe<Scalars['Boolean']>
  internalLabel_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  internalLabel_not?: InputMaybe<Scalars['String']>
  internalLabel_not_contains?: InputMaybe<Scalars['String']>
  internalLabel_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  primaryLabel?: InputMaybe<Scalars['String']>
  primaryLabel_contains?: InputMaybe<Scalars['String']>
  primaryLabel_exists?: InputMaybe<Scalars['Boolean']>
  primaryLabel_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  primaryLabel_not?: InputMaybe<Scalars['String']>
  primaryLabel_not_contains?: InputMaybe<Scalars['String']>
  primaryLabel_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  secondaryLabel?: InputMaybe<Scalars['String']>
  secondaryLabel_contains?: InputMaybe<Scalars['String']>
  secondaryLabel_exists?: InputMaybe<Scalars['Boolean']>
  secondaryLabel_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  secondaryLabel_not?: InputMaybe<Scalars['String']>
  secondaryLabel_not_contains?: InputMaybe<Scalars['String']>
  secondaryLabel_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  statement?: InputMaybe<Scalars['String']>
  statement_contains?: InputMaybe<Scalars['String']>
  statement_exists?: InputMaybe<Scalars['Boolean']>
  statement_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  statement_not?: InputMaybe<Scalars['String']>
  statement_not_contains?: InputMaybe<Scalars['String']>
  statement_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  sys?: InputMaybe<SysFilter>
}

export type ComponentCarouselItemLinkingCollections = {
  __typename?: 'ComponentCarouselItemLinkingCollections'
  componentCarouselSectionCollection?: Maybe<ComponentCarouselSectionCollection>
  entryCollection?: Maybe<EntryCollection>
}

export type ComponentCarouselItemLinkingCollectionsComponentCarouselSectionCollectionArgs =
  {
    limit?: InputMaybe<Scalars['Int']>
    locale?: InputMaybe<Scalars['String']>
    order?: InputMaybe<
      Array<
        InputMaybe<ComponentCarouselItemLinkingCollectionsComponentCarouselSectionCollectionOrder>
      >
    >
    preview?: InputMaybe<Scalars['Boolean']>
    skip?: InputMaybe<Scalars['Int']>
  }

export type ComponentCarouselItemLinkingCollectionsEntryCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>
  locale?: InputMaybe<Scalars['String']>
  preview?: InputMaybe<Scalars['Boolean']>
  skip?: InputMaybe<Scalars['Int']>
}

export enum ComponentCarouselItemLinkingCollectionsComponentCarouselSectionCollectionOrder {
  ArrowStyleAsc = 'arrowStyle_ASC',
  ArrowStyleDesc = 'arrowStyle_DESC',
  DotsStyleAsc = 'dotsStyle_ASC',
  DotsStyleDesc = 'dotsStyle_DESC',
  HeadingAsc = 'heading_ASC',
  HeadingDesc = 'heading_DESC',
  InternalLabelAsc = 'internalLabel_ASC',
  InternalLabelDesc = 'internalLabel_DESC',
  ItemsPerPageAsc = 'itemsPerPage_ASC',
  ItemsPerPageDesc = 'itemsPerPage_DESC',
  SectionIdAsc = 'sectionId_ASC',
  SectionIdDesc = 'sectionId_DESC',
  ShowNextMobileAsc = 'showNextMobile_ASC',
  ShowNextMobileDesc = 'showNextMobile_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  TitleStyleAsc = 'titleStyle_ASC',
  TitleStyleDesc = 'titleStyle_DESC',
}

export enum ComponentCarouselItemOrder {
  InternalLabelAsc = 'internalLabel_ASC',
  InternalLabelDesc = 'internalLabel_DESC',
  PrimaryLabelAsc = 'primaryLabel_ASC',
  PrimaryLabelDesc = 'primaryLabel_DESC',
  SecondaryLabelAsc = 'secondaryLabel_ASC',
  SecondaryLabelDesc = 'secondaryLabel_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
}

/** A page section containing a carousel of items [See type definition](https://app.contentful.com/spaces/9cxgygtadmvl/content_types/componentCarouselSection) */
export type ComponentCarouselSection = Entry &
  _Node & {
    __typename?: 'ComponentCarouselSection'
    _id: Scalars['ID']
    analyticsModule?: Maybe<SettingsAnalyticsModule>
    arrowStyle?: Maybe<Scalars['String']>
    contentfulMetadata: ContentfulMetadata
    dotsStyle?: Maybe<Scalars['String']>
    heading?: Maybe<Scalars['String']>
    internalLabel?: Maybe<Scalars['String']>
    itemsCollection?: Maybe<ComponentCarouselSectionItemsCollection>
    itemsPerPage?: Maybe<Scalars['Int']>
    linkedFrom?: Maybe<ComponentCarouselSectionLinkingCollections>
    sectionId?: Maybe<Scalars['String']>
    showNextMobile?: Maybe<Scalars['Boolean']>
    sys: Sys
    teaserCopy?: Maybe<ComponentCarouselSectionTeaserCopy>
    titleStyle?: Maybe<Scalars['String']>
  }

/** A page section containing a carousel of items [See type definition](https://app.contentful.com/spaces/9cxgygtadmvl/content_types/componentCarouselSection) */
export type ComponentCarouselSectionAnalyticsModuleArgs = {
  locale?: InputMaybe<Scalars['String']>
  preview?: InputMaybe<Scalars['Boolean']>
  where?: InputMaybe<SettingsAnalyticsModuleFilter>
}

/** A page section containing a carousel of items [See type definition](https://app.contentful.com/spaces/9cxgygtadmvl/content_types/componentCarouselSection) */
export type ComponentCarouselSectionArrowStyleArgs = {
  locale?: InputMaybe<Scalars['String']>
}

/** A page section containing a carousel of items [See type definition](https://app.contentful.com/spaces/9cxgygtadmvl/content_types/componentCarouselSection) */
export type ComponentCarouselSectionDotsStyleArgs = {
  locale?: InputMaybe<Scalars['String']>
}

/** A page section containing a carousel of items [See type definition](https://app.contentful.com/spaces/9cxgygtadmvl/content_types/componentCarouselSection) */
export type ComponentCarouselSectionHeadingArgs = {
  locale?: InputMaybe<Scalars['String']>
}

/** A page section containing a carousel of items [See type definition](https://app.contentful.com/spaces/9cxgygtadmvl/content_types/componentCarouselSection) */
export type ComponentCarouselSectionInternalLabelArgs = {
  locale?: InputMaybe<Scalars['String']>
}

/** A page section containing a carousel of items [See type definition](https://app.contentful.com/spaces/9cxgygtadmvl/content_types/componentCarouselSection) */
export type ComponentCarouselSectionItemsCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>
  locale?: InputMaybe<Scalars['String']>
  preview?: InputMaybe<Scalars['Boolean']>
  skip?: InputMaybe<Scalars['Int']>
  where?: InputMaybe<ComponentCarouselSectionItemsFilter>
}

/** A page section containing a carousel of items [See type definition](https://app.contentful.com/spaces/9cxgygtadmvl/content_types/componentCarouselSection) */
export type ComponentCarouselSectionItemsPerPageArgs = {
  locale?: InputMaybe<Scalars['String']>
}

/** A page section containing a carousel of items [See type definition](https://app.contentful.com/spaces/9cxgygtadmvl/content_types/componentCarouselSection) */
export type ComponentCarouselSectionLinkedFromArgs = {
  allowedLocales?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
}

/** A page section containing a carousel of items [See type definition](https://app.contentful.com/spaces/9cxgygtadmvl/content_types/componentCarouselSection) */
export type ComponentCarouselSectionSectionIdArgs = {
  locale?: InputMaybe<Scalars['String']>
}

/** A page section containing a carousel of items [See type definition](https://app.contentful.com/spaces/9cxgygtadmvl/content_types/componentCarouselSection) */
export type ComponentCarouselSectionShowNextMobileArgs = {
  locale?: InputMaybe<Scalars['String']>
}

/** A page section containing a carousel of items [See type definition](https://app.contentful.com/spaces/9cxgygtadmvl/content_types/componentCarouselSection) */
export type ComponentCarouselSectionTeaserCopyArgs = {
  locale?: InputMaybe<Scalars['String']>
}

/** A page section containing a carousel of items [See type definition](https://app.contentful.com/spaces/9cxgygtadmvl/content_types/componentCarouselSection) */
export type ComponentCarouselSectionTitleStyleArgs = {
  locale?: InputMaybe<Scalars['String']>
}

export type ComponentCarouselSectionCollection = {
  __typename?: 'ComponentCarouselSectionCollection'
  items: Array<Maybe<ComponentCarouselSection>>
  limit: Scalars['Int']
  skip: Scalars['Int']
  total: Scalars['Int']
}

export type ComponentCarouselSectionFilter = {
  AND?: InputMaybe<Array<InputMaybe<ComponentCarouselSectionFilter>>>
  OR?: InputMaybe<Array<InputMaybe<ComponentCarouselSectionFilter>>>
  analyticsModule?: InputMaybe<CfSettingsAnalyticsModuleNestedFilter>
  analyticsModule_exists?: InputMaybe<Scalars['Boolean']>
  arrowStyle?: InputMaybe<Scalars['String']>
  arrowStyle_contains?: InputMaybe<Scalars['String']>
  arrowStyle_exists?: InputMaybe<Scalars['Boolean']>
  arrowStyle_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  arrowStyle_not?: InputMaybe<Scalars['String']>
  arrowStyle_not_contains?: InputMaybe<Scalars['String']>
  arrowStyle_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  contentfulMetadata?: InputMaybe<ContentfulMetadataFilter>
  dotsStyle?: InputMaybe<Scalars['String']>
  dotsStyle_contains?: InputMaybe<Scalars['String']>
  dotsStyle_exists?: InputMaybe<Scalars['Boolean']>
  dotsStyle_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  dotsStyle_not?: InputMaybe<Scalars['String']>
  dotsStyle_not_contains?: InputMaybe<Scalars['String']>
  dotsStyle_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  heading?: InputMaybe<Scalars['String']>
  heading_contains?: InputMaybe<Scalars['String']>
  heading_exists?: InputMaybe<Scalars['Boolean']>
  heading_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  heading_not?: InputMaybe<Scalars['String']>
  heading_not_contains?: InputMaybe<Scalars['String']>
  heading_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  internalLabel?: InputMaybe<Scalars['String']>
  internalLabel_contains?: InputMaybe<Scalars['String']>
  internalLabel_exists?: InputMaybe<Scalars['Boolean']>
  internalLabel_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  internalLabel_not?: InputMaybe<Scalars['String']>
  internalLabel_not_contains?: InputMaybe<Scalars['String']>
  internalLabel_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  items?: InputMaybe<CfitemsMultiTypeNestedFilter>
  itemsCollection_exists?: InputMaybe<Scalars['Boolean']>
  itemsPerPage?: InputMaybe<Scalars['Int']>
  itemsPerPage_exists?: InputMaybe<Scalars['Boolean']>
  itemsPerPage_gt?: InputMaybe<Scalars['Int']>
  itemsPerPage_gte?: InputMaybe<Scalars['Int']>
  itemsPerPage_in?: InputMaybe<Array<InputMaybe<Scalars['Int']>>>
  itemsPerPage_lt?: InputMaybe<Scalars['Int']>
  itemsPerPage_lte?: InputMaybe<Scalars['Int']>
  itemsPerPage_not?: InputMaybe<Scalars['Int']>
  itemsPerPage_not_in?: InputMaybe<Array<InputMaybe<Scalars['Int']>>>
  sectionId?: InputMaybe<Scalars['String']>
  sectionId_contains?: InputMaybe<Scalars['String']>
  sectionId_exists?: InputMaybe<Scalars['Boolean']>
  sectionId_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  sectionId_not?: InputMaybe<Scalars['String']>
  sectionId_not_contains?: InputMaybe<Scalars['String']>
  sectionId_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  showNextMobile?: InputMaybe<Scalars['Boolean']>
  showNextMobile_exists?: InputMaybe<Scalars['Boolean']>
  showNextMobile_not?: InputMaybe<Scalars['Boolean']>
  sys?: InputMaybe<SysFilter>
  teaserCopy_contains?: InputMaybe<Scalars['String']>
  teaserCopy_exists?: InputMaybe<Scalars['Boolean']>
  teaserCopy_not_contains?: InputMaybe<Scalars['String']>
  titleStyle?: InputMaybe<Scalars['String']>
  titleStyle_contains?: InputMaybe<Scalars['String']>
  titleStyle_exists?: InputMaybe<Scalars['Boolean']>
  titleStyle_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  titleStyle_not?: InputMaybe<Scalars['String']>
  titleStyle_not_contains?: InputMaybe<Scalars['String']>
  titleStyle_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
}

export type ComponentCarouselSectionItemsCollection = {
  __typename?: 'ComponentCarouselSectionItemsCollection'
  items: Array<Maybe<ComponentCarouselSectionItemsItem>>
  limit: Scalars['Int']
  skip: Scalars['Int']
  total: Scalars['Int']
}

export type ComponentCarouselSectionItemsFilter = {
  AND?: InputMaybe<Array<InputMaybe<ComponentCarouselSectionItemsFilter>>>
  OR?: InputMaybe<Array<InputMaybe<ComponentCarouselSectionItemsFilter>>>
  analyticsModule_exists?: InputMaybe<Scalars['Boolean']>
  contentfulMetadata?: InputMaybe<ContentfulMetadataFilter>
  internalLabel?: InputMaybe<Scalars['String']>
  internalLabel_contains?: InputMaybe<Scalars['String']>
  internalLabel_exists?: InputMaybe<Scalars['Boolean']>
  internalLabel_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  internalLabel_not?: InputMaybe<Scalars['String']>
  internalLabel_not_contains?: InputMaybe<Scalars['String']>
  internalLabel_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  sys?: InputMaybe<SysFilter>
}

export type ComponentCarouselSectionItemsItem =
  | ComponentCarouselItem
  | ComponentLargePhotoCarouselItem

export type ComponentCarouselSectionLinkingCollections = {
  __typename?: 'ComponentCarouselSectionLinkingCollections'
  campaignPagesCollection?: Maybe<CampaignPagesCollection>
  entryCollection?: Maybe<EntryCollection>
  templateAboutUsCollection?: Maybe<TemplateAboutUsCollection>
  templateBookingCollection?: Maybe<TemplateBookingCollection>
  templateCareersCollection?: Maybe<TemplateCareersCollection>
  templateContactUsCollection?: Maybe<TemplateContactUsCollection>
  templateDestinationsCollection?: Maybe<TemplateDestinationsCollection>
  templateForaReserveCollection?: Maybe<TemplateForaReserveCollection>
  templateForaXCollection?: Maybe<TemplateForaXCollection>
  templateHomeCollection?: Maybe<TemplateHomeCollection>
  templateJoinUsCollection?: Maybe<TemplateJoinUsCollection>
  templateNewsletterCollection?: Maybe<TemplateNewsletterCollection>
  templatePageCollection?: Maybe<TemplatePageCollection>
  templatePartnerDetailCollection?: Maybe<TemplatePartnerDetailCollection>
  templatePartnersCollection?: Maybe<TemplatePartnersCollection>
  templateReviewsCollection?: Maybe<TemplateReviewsCollection>
  templateWhyBookWithForaCollection?: Maybe<TemplateWhyBookWithForaCollection>
}

export type ComponentCarouselSectionLinkingCollectionsCampaignPagesCollectionArgs =
  {
    limit?: InputMaybe<Scalars['Int']>
    locale?: InputMaybe<Scalars['String']>
    order?: InputMaybe<
      Array<
        InputMaybe<ComponentCarouselSectionLinkingCollectionsCampaignPagesCollectionOrder>
      >
    >
    preview?: InputMaybe<Scalars['Boolean']>
    skip?: InputMaybe<Scalars['Int']>
  }

export type ComponentCarouselSectionLinkingCollectionsEntryCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>
  locale?: InputMaybe<Scalars['String']>
  preview?: InputMaybe<Scalars['Boolean']>
  skip?: InputMaybe<Scalars['Int']>
}

export type ComponentCarouselSectionLinkingCollectionsTemplateAboutUsCollectionArgs =
  {
    limit?: InputMaybe<Scalars['Int']>
    locale?: InputMaybe<Scalars['String']>
    order?: InputMaybe<
      Array<
        InputMaybe<ComponentCarouselSectionLinkingCollectionsTemplateAboutUsCollectionOrder>
      >
    >
    preview?: InputMaybe<Scalars['Boolean']>
    skip?: InputMaybe<Scalars['Int']>
  }

export type ComponentCarouselSectionLinkingCollectionsTemplateBookingCollectionArgs =
  {
    limit?: InputMaybe<Scalars['Int']>
    locale?: InputMaybe<Scalars['String']>
    order?: InputMaybe<
      Array<
        InputMaybe<ComponentCarouselSectionLinkingCollectionsTemplateBookingCollectionOrder>
      >
    >
    preview?: InputMaybe<Scalars['Boolean']>
    skip?: InputMaybe<Scalars['Int']>
  }

export type ComponentCarouselSectionLinkingCollectionsTemplateCareersCollectionArgs =
  {
    limit?: InputMaybe<Scalars['Int']>
    locale?: InputMaybe<Scalars['String']>
    order?: InputMaybe<
      Array<
        InputMaybe<ComponentCarouselSectionLinkingCollectionsTemplateCareersCollectionOrder>
      >
    >
    preview?: InputMaybe<Scalars['Boolean']>
    skip?: InputMaybe<Scalars['Int']>
  }

export type ComponentCarouselSectionLinkingCollectionsTemplateContactUsCollectionArgs =
  {
    limit?: InputMaybe<Scalars['Int']>
    locale?: InputMaybe<Scalars['String']>
    order?: InputMaybe<
      Array<
        InputMaybe<ComponentCarouselSectionLinkingCollectionsTemplateContactUsCollectionOrder>
      >
    >
    preview?: InputMaybe<Scalars['Boolean']>
    skip?: InputMaybe<Scalars['Int']>
  }

export type ComponentCarouselSectionLinkingCollectionsTemplateDestinationsCollectionArgs =
  {
    limit?: InputMaybe<Scalars['Int']>
    locale?: InputMaybe<Scalars['String']>
    order?: InputMaybe<
      Array<
        InputMaybe<ComponentCarouselSectionLinkingCollectionsTemplateDestinationsCollectionOrder>
      >
    >
    preview?: InputMaybe<Scalars['Boolean']>
    skip?: InputMaybe<Scalars['Int']>
  }

export type ComponentCarouselSectionLinkingCollectionsTemplateForaReserveCollectionArgs =
  {
    limit?: InputMaybe<Scalars['Int']>
    locale?: InputMaybe<Scalars['String']>
    order?: InputMaybe<
      Array<
        InputMaybe<ComponentCarouselSectionLinkingCollectionsTemplateForaReserveCollectionOrder>
      >
    >
    preview?: InputMaybe<Scalars['Boolean']>
    skip?: InputMaybe<Scalars['Int']>
  }

export type ComponentCarouselSectionLinkingCollectionsTemplateForaXCollectionArgs =
  {
    limit?: InputMaybe<Scalars['Int']>
    locale?: InputMaybe<Scalars['String']>
    order?: InputMaybe<
      Array<
        InputMaybe<ComponentCarouselSectionLinkingCollectionsTemplateForaXCollectionOrder>
      >
    >
    preview?: InputMaybe<Scalars['Boolean']>
    skip?: InputMaybe<Scalars['Int']>
  }

export type ComponentCarouselSectionLinkingCollectionsTemplateHomeCollectionArgs =
  {
    limit?: InputMaybe<Scalars['Int']>
    locale?: InputMaybe<Scalars['String']>
    order?: InputMaybe<
      Array<
        InputMaybe<ComponentCarouselSectionLinkingCollectionsTemplateHomeCollectionOrder>
      >
    >
    preview?: InputMaybe<Scalars['Boolean']>
    skip?: InputMaybe<Scalars['Int']>
  }

export type ComponentCarouselSectionLinkingCollectionsTemplateJoinUsCollectionArgs =
  {
    limit?: InputMaybe<Scalars['Int']>
    locale?: InputMaybe<Scalars['String']>
    order?: InputMaybe<
      Array<
        InputMaybe<ComponentCarouselSectionLinkingCollectionsTemplateJoinUsCollectionOrder>
      >
    >
    preview?: InputMaybe<Scalars['Boolean']>
    skip?: InputMaybe<Scalars['Int']>
  }

export type ComponentCarouselSectionLinkingCollectionsTemplateNewsletterCollectionArgs =
  {
    limit?: InputMaybe<Scalars['Int']>
    locale?: InputMaybe<Scalars['String']>
    order?: InputMaybe<
      Array<
        InputMaybe<ComponentCarouselSectionLinkingCollectionsTemplateNewsletterCollectionOrder>
      >
    >
    preview?: InputMaybe<Scalars['Boolean']>
    skip?: InputMaybe<Scalars['Int']>
  }

export type ComponentCarouselSectionLinkingCollectionsTemplatePageCollectionArgs =
  {
    limit?: InputMaybe<Scalars['Int']>
    locale?: InputMaybe<Scalars['String']>
    order?: InputMaybe<
      Array<
        InputMaybe<ComponentCarouselSectionLinkingCollectionsTemplatePageCollectionOrder>
      >
    >
    preview?: InputMaybe<Scalars['Boolean']>
    skip?: InputMaybe<Scalars['Int']>
  }

export type ComponentCarouselSectionLinkingCollectionsTemplatePartnerDetailCollectionArgs =
  {
    limit?: InputMaybe<Scalars['Int']>
    locale?: InputMaybe<Scalars['String']>
    order?: InputMaybe<
      Array<
        InputMaybe<ComponentCarouselSectionLinkingCollectionsTemplatePartnerDetailCollectionOrder>
      >
    >
    preview?: InputMaybe<Scalars['Boolean']>
    skip?: InputMaybe<Scalars['Int']>
  }

export type ComponentCarouselSectionLinkingCollectionsTemplatePartnersCollectionArgs =
  {
    limit?: InputMaybe<Scalars['Int']>
    locale?: InputMaybe<Scalars['String']>
    order?: InputMaybe<
      Array<
        InputMaybe<ComponentCarouselSectionLinkingCollectionsTemplatePartnersCollectionOrder>
      >
    >
    preview?: InputMaybe<Scalars['Boolean']>
    skip?: InputMaybe<Scalars['Int']>
  }

export type ComponentCarouselSectionLinkingCollectionsTemplateReviewsCollectionArgs =
  {
    limit?: InputMaybe<Scalars['Int']>
    locale?: InputMaybe<Scalars['String']>
    order?: InputMaybe<
      Array<
        InputMaybe<ComponentCarouselSectionLinkingCollectionsTemplateReviewsCollectionOrder>
      >
    >
    preview?: InputMaybe<Scalars['Boolean']>
    skip?: InputMaybe<Scalars['Int']>
  }

export type ComponentCarouselSectionLinkingCollectionsTemplateWhyBookWithForaCollectionArgs =
  {
    limit?: InputMaybe<Scalars['Int']>
    locale?: InputMaybe<Scalars['String']>
    order?: InputMaybe<
      Array<
        InputMaybe<ComponentCarouselSectionLinkingCollectionsTemplateWhyBookWithForaCollectionOrder>
      >
    >
    preview?: InputMaybe<Scalars['Boolean']>
    skip?: InputMaybe<Scalars['Int']>
  }

export enum ComponentCarouselSectionLinkingCollectionsCampaignPagesCollectionOrder {
  CollapseFooterAsc = 'collapseFooter_ASC',
  CollapseFooterDesc = 'collapseFooter_DESC',
  CollapseHeaderAsc = 'collapseHeader_ASC',
  CollapseHeaderDesc = 'collapseHeader_DESC',
  HideFooterAsc = 'hideFooter_ASC',
  HideFooterDesc = 'hideFooter_DESC',
  HideGlobalBannerAsc = 'hideGlobalBanner_ASC',
  HideGlobalBannerDesc = 'hideGlobalBanner_DESC',
  JotformIdAsc = 'jotformId_ASC',
  JotformIdDesc = 'jotformId_DESC',
  NoindexNofollowAsc = 'noindexNofollow_ASC',
  NoindexNofollowDesc = 'noindexNofollow_DESC',
  ShowHeaderAsc = 'showHeader_ASC',
  ShowHeaderDesc = 'showHeader_DESC',
  SlugAsc = 'slug_ASC',
  SlugDesc = 'slug_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC',
}

export enum ComponentCarouselSectionLinkingCollectionsTemplateAboutUsCollectionOrder {
  CollapseHeaderAsc = 'collapseHeader_ASC',
  CollapseHeaderDesc = 'collapseHeader_DESC',
  HeaderBlockTitleAsc = 'headerBlockTitle_ASC',
  HeaderBlockTitleDesc = 'headerBlockTitle_DESC',
  ShowHeaderAsc = 'showHeader_ASC',
  ShowHeaderDesc = 'showHeader_DESC',
  SubtitleAsc = 'subtitle_ASC',
  SubtitleDesc = 'subtitle_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC',
}

export enum ComponentCarouselSectionLinkingCollectionsTemplateBookingCollectionOrder {
  CollapseHeaderAsc = 'collapseHeader_ASC',
  CollapseHeaderDesc = 'collapseHeader_DESC',
  ShowHeaderAsc = 'showHeader_ASC',
  ShowHeaderDesc = 'showHeader_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC',
}

export enum ComponentCarouselSectionLinkingCollectionsTemplateCareersCollectionOrder {
  CollapseHeaderAsc = 'collapseHeader_ASC',
  CollapseHeaderDesc = 'collapseHeader_DESC',
  ShowHeaderAsc = 'showHeader_ASC',
  ShowHeaderDesc = 'showHeader_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC',
}

export enum ComponentCarouselSectionLinkingCollectionsTemplateContactUsCollectionOrder {
  CollapseHeaderAsc = 'collapseHeader_ASC',
  CollapseHeaderDesc = 'collapseHeader_DESC',
  ShowHeaderAsc = 'showHeader_ASC',
  ShowHeaderDesc = 'showHeader_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC',
}

export enum ComponentCarouselSectionLinkingCollectionsTemplateDestinationsCollectionOrder {
  CollapseHeaderAsc = 'collapseHeader_ASC',
  CollapseHeaderDesc = 'collapseHeader_DESC',
  CollapsibleHeadingAsc = 'collapsibleHeading_ASC',
  CollapsibleHeadingDesc = 'collapsibleHeading_DESC',
  ShowHeaderAsc = 'showHeader_ASC',
  ShowHeaderDesc = 'showHeader_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC',
}

export enum ComponentCarouselSectionLinkingCollectionsTemplateForaReserveCollectionOrder {
  CollapseHeaderAsc = 'collapseHeader_ASC',
  CollapseHeaderDesc = 'collapseHeader_DESC',
  ShowHeaderAsc = 'showHeader_ASC',
  ShowHeaderDesc = 'showHeader_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC',
}

export enum ComponentCarouselSectionLinkingCollectionsTemplateForaXCollectionOrder {
  AdvisorGridHeaderAsc = 'advisorGridHeader_ASC',
  AdvisorGridHeaderDesc = 'advisorGridHeader_DESC',
  AdvisorGridSubCopyAsc = 'advisorGridSubCopy_ASC',
  AdvisorGridSubCopyDesc = 'advisorGridSubCopy_DESC',
  CollapseHeaderAsc = 'collapseHeader_ASC',
  CollapseHeaderDesc = 'collapseHeader_DESC',
  ShowHeaderAsc = 'showHeader_ASC',
  ShowHeaderDesc = 'showHeader_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC',
}

export enum ComponentCarouselSectionLinkingCollectionsTemplateHomeCollectionOrder {
  CollapseHeaderAsc = 'collapseHeader_ASC',
  CollapseHeaderDesc = 'collapseHeader_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC',
}

export enum ComponentCarouselSectionLinkingCollectionsTemplateJoinUsCollectionOrder {
  CollapseHeaderAsc = 'collapseHeader_ASC',
  CollapseHeaderDesc = 'collapseHeader_DESC',
  HideGlobalBannerAsc = 'hideGlobalBanner_ASC',
  HideGlobalBannerDesc = 'hideGlobalBanner_DESC',
  ShowHeaderAsc = 'showHeader_ASC',
  ShowHeaderDesc = 'showHeader_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC',
}

export enum ComponentCarouselSectionLinkingCollectionsTemplateNewsletterCollectionOrder {
  CollapseHeaderAsc = 'collapseHeader_ASC',
  CollapseHeaderDesc = 'collapseHeader_DESC',
  ShowHeaderAsc = 'showHeader_ASC',
  ShowHeaderDesc = 'showHeader_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC',
}

export enum ComponentCarouselSectionLinkingCollectionsTemplatePageCollectionOrder {
  DirectoryAsc = 'directory_ASC',
  DirectoryDesc = 'directory_DESC',
  InternalLabelAsc = 'internalLabel_ASC',
  InternalLabelDesc = 'internalLabel_DESC',
  MetaTitleAsc = 'metaTitle_ASC',
  MetaTitleDesc = 'metaTitle_DESC',
  NoindexNofollowAsc = 'noindexNofollow_ASC',
  NoindexNofollowDesc = 'noindexNofollow_DESC',
  SlugAsc = 'slug_ASC',
  SlugDesc = 'slug_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
}

export enum ComponentCarouselSectionLinkingCollectionsTemplatePartnerDetailCollectionOrder {
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
}

export enum ComponentCarouselSectionLinkingCollectionsTemplatePartnersCollectionOrder {
  CollapseHeaderAsc = 'collapseHeader_ASC',
  CollapseHeaderDesc = 'collapseHeader_DESC',
  ShowHeaderAsc = 'showHeader_ASC',
  ShowHeaderDesc = 'showHeader_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC',
}

export enum ComponentCarouselSectionLinkingCollectionsTemplateReviewsCollectionOrder {
  CollapseHeaderAsc = 'collapseHeader_ASC',
  CollapseHeaderDesc = 'collapseHeader_DESC',
  ShowHeaderAsc = 'showHeader_ASC',
  ShowHeaderDesc = 'showHeader_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC',
}

export enum ComponentCarouselSectionLinkingCollectionsTemplateWhyBookWithForaCollectionOrder {
  CollapseHeaderAsc = 'collapseHeader_ASC',
  CollapseHeaderDesc = 'collapseHeader_DESC',
  ShowHeaderAsc = 'showHeader_ASC',
  ShowHeaderDesc = 'showHeader_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC',
}

export enum ComponentCarouselSectionOrder {
  ArrowStyleAsc = 'arrowStyle_ASC',
  ArrowStyleDesc = 'arrowStyle_DESC',
  DotsStyleAsc = 'dotsStyle_ASC',
  DotsStyleDesc = 'dotsStyle_DESC',
  HeadingAsc = 'heading_ASC',
  HeadingDesc = 'heading_DESC',
  InternalLabelAsc = 'internalLabel_ASC',
  InternalLabelDesc = 'internalLabel_DESC',
  ItemsPerPageAsc = 'itemsPerPage_ASC',
  ItemsPerPageDesc = 'itemsPerPage_DESC',
  SectionIdAsc = 'sectionId_ASC',
  SectionIdDesc = 'sectionId_DESC',
  ShowNextMobileAsc = 'showNextMobile_ASC',
  ShowNextMobileDesc = 'showNextMobile_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  TitleStyleAsc = 'titleStyle_ASC',
  TitleStyleDesc = 'titleStyle_DESC',
}

export type ComponentCarouselSectionTeaserCopy = {
  __typename?: 'ComponentCarouselSectionTeaserCopy'
  json: Scalars['JSON']
  links: ComponentCarouselSectionTeaserCopyLinks
}

export type ComponentCarouselSectionTeaserCopyAssets = {
  __typename?: 'ComponentCarouselSectionTeaserCopyAssets'
  block: Array<Maybe<Asset>>
  hyperlink: Array<Maybe<Asset>>
}

export type ComponentCarouselSectionTeaserCopyEntries = {
  __typename?: 'ComponentCarouselSectionTeaserCopyEntries'
  block: Array<Maybe<Entry>>
  hyperlink: Array<Maybe<Entry>>
  inline: Array<Maybe<Entry>>
}

export type ComponentCarouselSectionTeaserCopyLinks = {
  __typename?: 'ComponentCarouselSectionTeaserCopyLinks'
  assets: ComponentCarouselSectionTeaserCopyAssets
  entries: ComponentCarouselSectionTeaserCopyEntries
  resources: ComponentCarouselSectionTeaserCopyResources
}

export type ComponentCarouselSectionTeaserCopyResources = {
  __typename?: 'ComponentCarouselSectionTeaserCopyResources'
  block: Array<ComponentCarouselSectionTeaserCopyResourcesBlock>
  hyperlink: Array<ComponentCarouselSectionTeaserCopyResourcesHyperlink>
  inline: Array<ComponentCarouselSectionTeaserCopyResourcesInline>
}

export type ComponentCarouselSectionTeaserCopyResourcesBlock = ResourceLink & {
  __typename?: 'ComponentCarouselSectionTeaserCopyResourcesBlock'
  sys: ResourceSys
}

export type ComponentCarouselSectionTeaserCopyResourcesHyperlink =
  ResourceLink & {
    __typename?: 'ComponentCarouselSectionTeaserCopyResourcesHyperlink'
    sys: ResourceSys
  }

export type ComponentCarouselSectionTeaserCopyResourcesInline = ResourceLink & {
  __typename?: 'ComponentCarouselSectionTeaserCopyResourcesInline'
  sys: ResourceSys
}

/** A simple centered Hero page block with full-width image. [See type definition](https://app.contentful.com/spaces/9cxgygtadmvl/content_types/componentCenteredHero) */
export type ComponentCenteredHero = Entry &
  _Node & {
    __typename?: 'ComponentCenteredHero'
    _id: Scalars['ID']
    analyticsModule?: Maybe<SettingsAnalyticsModule>
    contentfulMetadata: ContentfulMetadata
    ctaText?: Maybe<Scalars['String']>
    ctaUrl?: Maybe<Scalars['String']>
    image?: Maybe<Asset>
    internalLabel?: Maybe<Scalars['String']>
    linkedFrom?: Maybe<ComponentCenteredHeroLinkingCollections>
    sys: Sys
    teaserCopy?: Maybe<ComponentCenteredHeroTeaserCopy>
    title?: Maybe<Scalars['String']>
    urlTarget?: Maybe<Scalars['String']>
  }

/** A simple centered Hero page block with full-width image. [See type definition](https://app.contentful.com/spaces/9cxgygtadmvl/content_types/componentCenteredHero) */
export type ComponentCenteredHeroAnalyticsModuleArgs = {
  locale?: InputMaybe<Scalars['String']>
  preview?: InputMaybe<Scalars['Boolean']>
  where?: InputMaybe<SettingsAnalyticsModuleFilter>
}

/** A simple centered Hero page block with full-width image. [See type definition](https://app.contentful.com/spaces/9cxgygtadmvl/content_types/componentCenteredHero) */
export type ComponentCenteredHeroCtaTextArgs = {
  locale?: InputMaybe<Scalars['String']>
}

/** A simple centered Hero page block with full-width image. [See type definition](https://app.contentful.com/spaces/9cxgygtadmvl/content_types/componentCenteredHero) */
export type ComponentCenteredHeroCtaUrlArgs = {
  locale?: InputMaybe<Scalars['String']>
}

/** A simple centered Hero page block with full-width image. [See type definition](https://app.contentful.com/spaces/9cxgygtadmvl/content_types/componentCenteredHero) */
export type ComponentCenteredHeroImageArgs = {
  locale?: InputMaybe<Scalars['String']>
  preview?: InputMaybe<Scalars['Boolean']>
}

/** A simple centered Hero page block with full-width image. [See type definition](https://app.contentful.com/spaces/9cxgygtadmvl/content_types/componentCenteredHero) */
export type ComponentCenteredHeroInternalLabelArgs = {
  locale?: InputMaybe<Scalars['String']>
}

/** A simple centered Hero page block with full-width image. [See type definition](https://app.contentful.com/spaces/9cxgygtadmvl/content_types/componentCenteredHero) */
export type ComponentCenteredHeroLinkedFromArgs = {
  allowedLocales?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
}

/** A simple centered Hero page block with full-width image. [See type definition](https://app.contentful.com/spaces/9cxgygtadmvl/content_types/componentCenteredHero) */
export type ComponentCenteredHeroTeaserCopyArgs = {
  locale?: InputMaybe<Scalars['String']>
}

/** A simple centered Hero page block with full-width image. [See type definition](https://app.contentful.com/spaces/9cxgygtadmvl/content_types/componentCenteredHero) */
export type ComponentCenteredHeroTitleArgs = {
  locale?: InputMaybe<Scalars['String']>
}

/** A simple centered Hero page block with full-width image. [See type definition](https://app.contentful.com/spaces/9cxgygtadmvl/content_types/componentCenteredHero) */
export type ComponentCenteredHeroUrlTargetArgs = {
  locale?: InputMaybe<Scalars['String']>
}

export type ComponentCenteredHeroCollection = {
  __typename?: 'ComponentCenteredHeroCollection'
  items: Array<Maybe<ComponentCenteredHero>>
  limit: Scalars['Int']
  skip: Scalars['Int']
  total: Scalars['Int']
}

export type ComponentCenteredHeroFilter = {
  AND?: InputMaybe<Array<InputMaybe<ComponentCenteredHeroFilter>>>
  OR?: InputMaybe<Array<InputMaybe<ComponentCenteredHeroFilter>>>
  analyticsModule?: InputMaybe<CfSettingsAnalyticsModuleNestedFilter>
  analyticsModule_exists?: InputMaybe<Scalars['Boolean']>
  contentfulMetadata?: InputMaybe<ContentfulMetadataFilter>
  ctaText?: InputMaybe<Scalars['String']>
  ctaText_contains?: InputMaybe<Scalars['String']>
  ctaText_exists?: InputMaybe<Scalars['Boolean']>
  ctaText_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  ctaText_not?: InputMaybe<Scalars['String']>
  ctaText_not_contains?: InputMaybe<Scalars['String']>
  ctaText_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  ctaUrl?: InputMaybe<Scalars['String']>
  ctaUrl_contains?: InputMaybe<Scalars['String']>
  ctaUrl_exists?: InputMaybe<Scalars['Boolean']>
  ctaUrl_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  ctaUrl_not?: InputMaybe<Scalars['String']>
  ctaUrl_not_contains?: InputMaybe<Scalars['String']>
  ctaUrl_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  image_exists?: InputMaybe<Scalars['Boolean']>
  internalLabel?: InputMaybe<Scalars['String']>
  internalLabel_contains?: InputMaybe<Scalars['String']>
  internalLabel_exists?: InputMaybe<Scalars['Boolean']>
  internalLabel_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  internalLabel_not?: InputMaybe<Scalars['String']>
  internalLabel_not_contains?: InputMaybe<Scalars['String']>
  internalLabel_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  sys?: InputMaybe<SysFilter>
  teaserCopy_contains?: InputMaybe<Scalars['String']>
  teaserCopy_exists?: InputMaybe<Scalars['Boolean']>
  teaserCopy_not_contains?: InputMaybe<Scalars['String']>
  title?: InputMaybe<Scalars['String']>
  title_contains?: InputMaybe<Scalars['String']>
  title_exists?: InputMaybe<Scalars['Boolean']>
  title_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  title_not?: InputMaybe<Scalars['String']>
  title_not_contains?: InputMaybe<Scalars['String']>
  title_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  urlTarget?: InputMaybe<Scalars['String']>
  urlTarget_contains?: InputMaybe<Scalars['String']>
  urlTarget_exists?: InputMaybe<Scalars['Boolean']>
  urlTarget_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  urlTarget_not?: InputMaybe<Scalars['String']>
  urlTarget_not_contains?: InputMaybe<Scalars['String']>
  urlTarget_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
}

export type ComponentCenteredHeroLinkingCollections = {
  __typename?: 'ComponentCenteredHeroLinkingCollections'
  entryCollection?: Maybe<EntryCollection>
}

export type ComponentCenteredHeroLinkingCollectionsEntryCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>
  locale?: InputMaybe<Scalars['String']>
  preview?: InputMaybe<Scalars['Boolean']>
  skip?: InputMaybe<Scalars['Int']>
}

export enum ComponentCenteredHeroOrder {
  CtaTextAsc = 'ctaText_ASC',
  CtaTextDesc = 'ctaText_DESC',
  CtaUrlAsc = 'ctaUrl_ASC',
  CtaUrlDesc = 'ctaUrl_DESC',
  InternalLabelAsc = 'internalLabel_ASC',
  InternalLabelDesc = 'internalLabel_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC',
  UrlTargetAsc = 'urlTarget_ASC',
  UrlTargetDesc = 'urlTarget_DESC',
}

export type ComponentCenteredHeroTeaserCopy = {
  __typename?: 'ComponentCenteredHeroTeaserCopy'
  json: Scalars['JSON']
  links: ComponentCenteredHeroTeaserCopyLinks
}

export type ComponentCenteredHeroTeaserCopyAssets = {
  __typename?: 'ComponentCenteredHeroTeaserCopyAssets'
  block: Array<Maybe<Asset>>
  hyperlink: Array<Maybe<Asset>>
}

export type ComponentCenteredHeroTeaserCopyEntries = {
  __typename?: 'ComponentCenteredHeroTeaserCopyEntries'
  block: Array<Maybe<Entry>>
  hyperlink: Array<Maybe<Entry>>
  inline: Array<Maybe<Entry>>
}

export type ComponentCenteredHeroTeaserCopyLinks = {
  __typename?: 'ComponentCenteredHeroTeaserCopyLinks'
  assets: ComponentCenteredHeroTeaserCopyAssets
  entries: ComponentCenteredHeroTeaserCopyEntries
  resources: ComponentCenteredHeroTeaserCopyResources
}

export type ComponentCenteredHeroTeaserCopyResources = {
  __typename?: 'ComponentCenteredHeroTeaserCopyResources'
  block: Array<ComponentCenteredHeroTeaserCopyResourcesBlock>
  hyperlink: Array<ComponentCenteredHeroTeaserCopyResourcesHyperlink>
  inline: Array<ComponentCenteredHeroTeaserCopyResourcesInline>
}

export type ComponentCenteredHeroTeaserCopyResourcesBlock = ResourceLink & {
  __typename?: 'ComponentCenteredHeroTeaserCopyResourcesBlock'
  sys: ResourceSys
}

export type ComponentCenteredHeroTeaserCopyResourcesHyperlink = ResourceLink & {
  __typename?: 'ComponentCenteredHeroTeaserCopyResourcesHyperlink'
  sys: ResourceSys
}

export type ComponentCenteredHeroTeaserCopyResourcesInline = ResourceLink & {
  __typename?: 'ComponentCenteredHeroTeaserCopyResourcesInline'
  sys: ResourceSys
}

/** [See type definition](https://app.contentful.com/spaces/9cxgygtadmvl/content_types/componentCheckeredCallout) */
export type ComponentCheckeredCallout = Entry &
  _Node & {
    __typename?: 'ComponentCheckeredCallout'
    _id: Scalars['ID']
    analyticsModule?: Maybe<SettingsAnalyticsModule>
    calloutsCollection?: Maybe<ComponentCheckeredCalloutCalloutsCollection>
    contentfulMetadata: ContentfulMetadata
    fullWidth?: Maybe<Scalars['Boolean']>
    internalLabel?: Maybe<Scalars['String']>
    linkedFrom?: Maybe<ComponentCheckeredCalloutLinkingCollections>
    sectionId?: Maybe<Scalars['String']>
    sys: Sys
  }

/** [See type definition](https://app.contentful.com/spaces/9cxgygtadmvl/content_types/componentCheckeredCallout) */
export type ComponentCheckeredCalloutAnalyticsModuleArgs = {
  locale?: InputMaybe<Scalars['String']>
  preview?: InputMaybe<Scalars['Boolean']>
  where?: InputMaybe<SettingsAnalyticsModuleFilter>
}

/** [See type definition](https://app.contentful.com/spaces/9cxgygtadmvl/content_types/componentCheckeredCallout) */
export type ComponentCheckeredCalloutCalloutsCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>
  locale?: InputMaybe<Scalars['String']>
  order?: InputMaybe<
    Array<InputMaybe<ComponentCheckeredCalloutCalloutsCollectionOrder>>
  >
  preview?: InputMaybe<Scalars['Boolean']>
  skip?: InputMaybe<Scalars['Int']>
  where?: InputMaybe<ComponentTwoColumnCalloutFilter>
}

/** [See type definition](https://app.contentful.com/spaces/9cxgygtadmvl/content_types/componentCheckeredCallout) */
export type ComponentCheckeredCalloutFullWidthArgs = {
  locale?: InputMaybe<Scalars['String']>
}

/** [See type definition](https://app.contentful.com/spaces/9cxgygtadmvl/content_types/componentCheckeredCallout) */
export type ComponentCheckeredCalloutInternalLabelArgs = {
  locale?: InputMaybe<Scalars['String']>
}

/** [See type definition](https://app.contentful.com/spaces/9cxgygtadmvl/content_types/componentCheckeredCallout) */
export type ComponentCheckeredCalloutLinkedFromArgs = {
  allowedLocales?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
}

/** [See type definition](https://app.contentful.com/spaces/9cxgygtadmvl/content_types/componentCheckeredCallout) */
export type ComponentCheckeredCalloutSectionIdArgs = {
  locale?: InputMaybe<Scalars['String']>
}

export type ComponentCheckeredCalloutCalloutsCollection = {
  __typename?: 'ComponentCheckeredCalloutCalloutsCollection'
  items: Array<Maybe<ComponentTwoColumnCallout>>
  limit: Scalars['Int']
  skip: Scalars['Int']
  total: Scalars['Int']
}

export enum ComponentCheckeredCalloutCalloutsCollectionOrder {
  CtaTextAsc = 'ctaText_ASC',
  CtaTextDesc = 'ctaText_DESC',
  CtaThemeAsc = 'ctaTheme_ASC',
  CtaThemeDesc = 'ctaTheme_DESC',
  CtaUrlAsc = 'ctaUrl_ASC',
  CtaUrlDesc = 'ctaUrl_DESC',
  InternalLabelAsc = 'internalLabel_ASC',
  InternalLabelDesc = 'internalLabel_DESC',
  SectionIdAsc = 'sectionId_ASC',
  SectionIdDesc = 'sectionId_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
}

export type ComponentCheckeredCalloutCollection = {
  __typename?: 'ComponentCheckeredCalloutCollection'
  items: Array<Maybe<ComponentCheckeredCallout>>
  limit: Scalars['Int']
  skip: Scalars['Int']
  total: Scalars['Int']
}

export type ComponentCheckeredCalloutFilter = {
  AND?: InputMaybe<Array<InputMaybe<ComponentCheckeredCalloutFilter>>>
  OR?: InputMaybe<Array<InputMaybe<ComponentCheckeredCalloutFilter>>>
  analyticsModule?: InputMaybe<CfSettingsAnalyticsModuleNestedFilter>
  analyticsModule_exists?: InputMaybe<Scalars['Boolean']>
  callouts?: InputMaybe<CfComponentTwoColumnCalloutNestedFilter>
  calloutsCollection_exists?: InputMaybe<Scalars['Boolean']>
  contentfulMetadata?: InputMaybe<ContentfulMetadataFilter>
  fullWidth?: InputMaybe<Scalars['Boolean']>
  fullWidth_exists?: InputMaybe<Scalars['Boolean']>
  fullWidth_not?: InputMaybe<Scalars['Boolean']>
  internalLabel?: InputMaybe<Scalars['String']>
  internalLabel_contains?: InputMaybe<Scalars['String']>
  internalLabel_exists?: InputMaybe<Scalars['Boolean']>
  internalLabel_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  internalLabel_not?: InputMaybe<Scalars['String']>
  internalLabel_not_contains?: InputMaybe<Scalars['String']>
  internalLabel_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  sectionId?: InputMaybe<Scalars['String']>
  sectionId_contains?: InputMaybe<Scalars['String']>
  sectionId_exists?: InputMaybe<Scalars['Boolean']>
  sectionId_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  sectionId_not?: InputMaybe<Scalars['String']>
  sectionId_not_contains?: InputMaybe<Scalars['String']>
  sectionId_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  sys?: InputMaybe<SysFilter>
}

export type ComponentCheckeredCalloutLinkingCollections = {
  __typename?: 'ComponentCheckeredCalloutLinkingCollections'
  entryCollection?: Maybe<EntryCollection>
  templateAboutUsCollection?: Maybe<TemplateAboutUsCollection>
  templateJoinUsCollection?: Maybe<TemplateJoinUsCollection>
  templatePageCollection?: Maybe<TemplatePageCollection>
}

export type ComponentCheckeredCalloutLinkingCollectionsEntryCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>
  locale?: InputMaybe<Scalars['String']>
  preview?: InputMaybe<Scalars['Boolean']>
  skip?: InputMaybe<Scalars['Int']>
}

export type ComponentCheckeredCalloutLinkingCollectionsTemplateAboutUsCollectionArgs =
  {
    limit?: InputMaybe<Scalars['Int']>
    locale?: InputMaybe<Scalars['String']>
    order?: InputMaybe<
      Array<
        InputMaybe<ComponentCheckeredCalloutLinkingCollectionsTemplateAboutUsCollectionOrder>
      >
    >
    preview?: InputMaybe<Scalars['Boolean']>
    skip?: InputMaybe<Scalars['Int']>
  }

export type ComponentCheckeredCalloutLinkingCollectionsTemplateJoinUsCollectionArgs =
  {
    limit?: InputMaybe<Scalars['Int']>
    locale?: InputMaybe<Scalars['String']>
    order?: InputMaybe<
      Array<
        InputMaybe<ComponentCheckeredCalloutLinkingCollectionsTemplateJoinUsCollectionOrder>
      >
    >
    preview?: InputMaybe<Scalars['Boolean']>
    skip?: InputMaybe<Scalars['Int']>
  }

export type ComponentCheckeredCalloutLinkingCollectionsTemplatePageCollectionArgs =
  {
    limit?: InputMaybe<Scalars['Int']>
    locale?: InputMaybe<Scalars['String']>
    order?: InputMaybe<
      Array<
        InputMaybe<ComponentCheckeredCalloutLinkingCollectionsTemplatePageCollectionOrder>
      >
    >
    preview?: InputMaybe<Scalars['Boolean']>
    skip?: InputMaybe<Scalars['Int']>
  }

export enum ComponentCheckeredCalloutLinkingCollectionsTemplateAboutUsCollectionOrder {
  CollapseHeaderAsc = 'collapseHeader_ASC',
  CollapseHeaderDesc = 'collapseHeader_DESC',
  HeaderBlockTitleAsc = 'headerBlockTitle_ASC',
  HeaderBlockTitleDesc = 'headerBlockTitle_DESC',
  ShowHeaderAsc = 'showHeader_ASC',
  ShowHeaderDesc = 'showHeader_DESC',
  SubtitleAsc = 'subtitle_ASC',
  SubtitleDesc = 'subtitle_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC',
}

export enum ComponentCheckeredCalloutLinkingCollectionsTemplateJoinUsCollectionOrder {
  CollapseHeaderAsc = 'collapseHeader_ASC',
  CollapseHeaderDesc = 'collapseHeader_DESC',
  HideGlobalBannerAsc = 'hideGlobalBanner_ASC',
  HideGlobalBannerDesc = 'hideGlobalBanner_DESC',
  ShowHeaderAsc = 'showHeader_ASC',
  ShowHeaderDesc = 'showHeader_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC',
}

export enum ComponentCheckeredCalloutLinkingCollectionsTemplatePageCollectionOrder {
  DirectoryAsc = 'directory_ASC',
  DirectoryDesc = 'directory_DESC',
  InternalLabelAsc = 'internalLabel_ASC',
  InternalLabelDesc = 'internalLabel_DESC',
  MetaTitleAsc = 'metaTitle_ASC',
  MetaTitleDesc = 'metaTitle_DESC',
  NoindexNofollowAsc = 'noindexNofollow_ASC',
  NoindexNofollowDesc = 'noindexNofollow_DESC',
  SlugAsc = 'slug_ASC',
  SlugDesc = 'slug_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
}

export enum ComponentCheckeredCalloutOrder {
  FullWidthAsc = 'fullWidth_ASC',
  FullWidthDesc = 'fullWidth_DESC',
  InternalLabelAsc = 'internalLabel_ASC',
  InternalLabelDesc = 'internalLabel_DESC',
  SectionIdAsc = 'sectionId_ASC',
  SectionIdDesc = 'sectionId_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
}

/** A comparison table between Fora and others for use within pages. [See type definition](https://app.contentful.com/spaces/9cxgygtadmvl/content_types/componentComparisonTable) */
export type ComponentComparisonTable = Entry &
  _Node & {
    __typename?: 'ComponentComparisonTable'
    _id: Scalars['ID']
    analyticsModule?: Maybe<SettingsAnalyticsModule>
    contentfulMetadata: ContentfulMetadata
    ctaText?: Maybe<Scalars['String']>
    ctaUrl?: Maybe<Scalars['String']>
    foraColumnHeading?: Maybe<Scalars['String']>
    internalLabel?: Maybe<Scalars['String']>
    linkedFrom?: Maybe<ComponentComparisonTableLinkingCollections>
    othersColumnHeading?: Maybe<Scalars['String']>
    rowsCollection?: Maybe<ComponentComparisonTableRowsCollection>
    sectionId?: Maybe<Scalars['String']>
    sys: Sys
    urlTarget?: Maybe<Scalars['String']>
  }

/** A comparison table between Fora and others for use within pages. [See type definition](https://app.contentful.com/spaces/9cxgygtadmvl/content_types/componentComparisonTable) */
export type ComponentComparisonTableAnalyticsModuleArgs = {
  locale?: InputMaybe<Scalars['String']>
  preview?: InputMaybe<Scalars['Boolean']>
  where?: InputMaybe<SettingsAnalyticsModuleFilter>
}

/** A comparison table between Fora and others for use within pages. [See type definition](https://app.contentful.com/spaces/9cxgygtadmvl/content_types/componentComparisonTable) */
export type ComponentComparisonTableCtaTextArgs = {
  locale?: InputMaybe<Scalars['String']>
}

/** A comparison table between Fora and others for use within pages. [See type definition](https://app.contentful.com/spaces/9cxgygtadmvl/content_types/componentComparisonTable) */
export type ComponentComparisonTableCtaUrlArgs = {
  locale?: InputMaybe<Scalars['String']>
}

/** A comparison table between Fora and others for use within pages. [See type definition](https://app.contentful.com/spaces/9cxgygtadmvl/content_types/componentComparisonTable) */
export type ComponentComparisonTableForaColumnHeadingArgs = {
  locale?: InputMaybe<Scalars['String']>
}

/** A comparison table between Fora and others for use within pages. [See type definition](https://app.contentful.com/spaces/9cxgygtadmvl/content_types/componentComparisonTable) */
export type ComponentComparisonTableInternalLabelArgs = {
  locale?: InputMaybe<Scalars['String']>
}

/** A comparison table between Fora and others for use within pages. [See type definition](https://app.contentful.com/spaces/9cxgygtadmvl/content_types/componentComparisonTable) */
export type ComponentComparisonTableLinkedFromArgs = {
  allowedLocales?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
}

/** A comparison table between Fora and others for use within pages. [See type definition](https://app.contentful.com/spaces/9cxgygtadmvl/content_types/componentComparisonTable) */
export type ComponentComparisonTableOthersColumnHeadingArgs = {
  locale?: InputMaybe<Scalars['String']>
}

/** A comparison table between Fora and others for use within pages. [See type definition](https://app.contentful.com/spaces/9cxgygtadmvl/content_types/componentComparisonTable) */
export type ComponentComparisonTableRowsCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>
  locale?: InputMaybe<Scalars['String']>
  order?: InputMaybe<
    Array<InputMaybe<ComponentComparisonTableRowsCollectionOrder>>
  >
  preview?: InputMaybe<Scalars['Boolean']>
  skip?: InputMaybe<Scalars['Int']>
  where?: InputMaybe<ComponentComparisonTableRowFilter>
}

/** A comparison table between Fora and others for use within pages. [See type definition](https://app.contentful.com/spaces/9cxgygtadmvl/content_types/componentComparisonTable) */
export type ComponentComparisonTableSectionIdArgs = {
  locale?: InputMaybe<Scalars['String']>
}

/** A comparison table between Fora and others for use within pages. [See type definition](https://app.contentful.com/spaces/9cxgygtadmvl/content_types/componentComparisonTable) */
export type ComponentComparisonTableUrlTargetArgs = {
  locale?: InputMaybe<Scalars['String']>
}

export type ComponentComparisonTableCollection = {
  __typename?: 'ComponentComparisonTableCollection'
  items: Array<Maybe<ComponentComparisonTable>>
  limit: Scalars['Int']
  skip: Scalars['Int']
  total: Scalars['Int']
}

export type ComponentComparisonTableFilter = {
  AND?: InputMaybe<Array<InputMaybe<ComponentComparisonTableFilter>>>
  OR?: InputMaybe<Array<InputMaybe<ComponentComparisonTableFilter>>>
  analyticsModule?: InputMaybe<CfSettingsAnalyticsModuleNestedFilter>
  analyticsModule_exists?: InputMaybe<Scalars['Boolean']>
  contentfulMetadata?: InputMaybe<ContentfulMetadataFilter>
  ctaText?: InputMaybe<Scalars['String']>
  ctaText_contains?: InputMaybe<Scalars['String']>
  ctaText_exists?: InputMaybe<Scalars['Boolean']>
  ctaText_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  ctaText_not?: InputMaybe<Scalars['String']>
  ctaText_not_contains?: InputMaybe<Scalars['String']>
  ctaText_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  ctaUrl?: InputMaybe<Scalars['String']>
  ctaUrl_contains?: InputMaybe<Scalars['String']>
  ctaUrl_exists?: InputMaybe<Scalars['Boolean']>
  ctaUrl_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  ctaUrl_not?: InputMaybe<Scalars['String']>
  ctaUrl_not_contains?: InputMaybe<Scalars['String']>
  ctaUrl_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  foraColumnHeading?: InputMaybe<Scalars['String']>
  foraColumnHeading_contains?: InputMaybe<Scalars['String']>
  foraColumnHeading_exists?: InputMaybe<Scalars['Boolean']>
  foraColumnHeading_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  foraColumnHeading_not?: InputMaybe<Scalars['String']>
  foraColumnHeading_not_contains?: InputMaybe<Scalars['String']>
  foraColumnHeading_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  internalLabel?: InputMaybe<Scalars['String']>
  internalLabel_contains?: InputMaybe<Scalars['String']>
  internalLabel_exists?: InputMaybe<Scalars['Boolean']>
  internalLabel_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  internalLabel_not?: InputMaybe<Scalars['String']>
  internalLabel_not_contains?: InputMaybe<Scalars['String']>
  internalLabel_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  othersColumnHeading?: InputMaybe<Scalars['String']>
  othersColumnHeading_contains?: InputMaybe<Scalars['String']>
  othersColumnHeading_exists?: InputMaybe<Scalars['Boolean']>
  othersColumnHeading_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  othersColumnHeading_not?: InputMaybe<Scalars['String']>
  othersColumnHeading_not_contains?: InputMaybe<Scalars['String']>
  othersColumnHeading_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  rows?: InputMaybe<CfComponentComparisonTableRowNestedFilter>
  rowsCollection_exists?: InputMaybe<Scalars['Boolean']>
  sectionId?: InputMaybe<Scalars['String']>
  sectionId_contains?: InputMaybe<Scalars['String']>
  sectionId_exists?: InputMaybe<Scalars['Boolean']>
  sectionId_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  sectionId_not?: InputMaybe<Scalars['String']>
  sectionId_not_contains?: InputMaybe<Scalars['String']>
  sectionId_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  sys?: InputMaybe<SysFilter>
  urlTarget?: InputMaybe<Scalars['String']>
  urlTarget_contains?: InputMaybe<Scalars['String']>
  urlTarget_exists?: InputMaybe<Scalars['Boolean']>
  urlTarget_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  urlTarget_not?: InputMaybe<Scalars['String']>
  urlTarget_not_contains?: InputMaybe<Scalars['String']>
  urlTarget_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
}

export type ComponentComparisonTableLinkingCollections = {
  __typename?: 'ComponentComparisonTableLinkingCollections'
  campaignPagesCollection?: Maybe<CampaignPagesCollection>
  entryCollection?: Maybe<EntryCollection>
  templateAboutUsCollection?: Maybe<TemplateAboutUsCollection>
  templateBookingCollection?: Maybe<TemplateBookingCollection>
  templateCareersCollection?: Maybe<TemplateCareersCollection>
  templateContactUsCollection?: Maybe<TemplateContactUsCollection>
  templateDestinationsCollection?: Maybe<TemplateDestinationsCollection>
  templateForaReserveCollection?: Maybe<TemplateForaReserveCollection>
  templateForaXCollection?: Maybe<TemplateForaXCollection>
  templateHomeCollection?: Maybe<TemplateHomeCollection>
  templateJoinUsCollection?: Maybe<TemplateJoinUsCollection>
  templateNewsletterCollection?: Maybe<TemplateNewsletterCollection>
  templatePageCollection?: Maybe<TemplatePageCollection>
  templatePartnersCollection?: Maybe<TemplatePartnersCollection>
  templateReviewsCollection?: Maybe<TemplateReviewsCollection>
  templateWhyBookWithForaCollection?: Maybe<TemplateWhyBookWithForaCollection>
}

export type ComponentComparisonTableLinkingCollectionsCampaignPagesCollectionArgs =
  {
    limit?: InputMaybe<Scalars['Int']>
    locale?: InputMaybe<Scalars['String']>
    order?: InputMaybe<
      Array<
        InputMaybe<ComponentComparisonTableLinkingCollectionsCampaignPagesCollectionOrder>
      >
    >
    preview?: InputMaybe<Scalars['Boolean']>
    skip?: InputMaybe<Scalars['Int']>
  }

export type ComponentComparisonTableLinkingCollectionsEntryCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>
  locale?: InputMaybe<Scalars['String']>
  preview?: InputMaybe<Scalars['Boolean']>
  skip?: InputMaybe<Scalars['Int']>
}

export type ComponentComparisonTableLinkingCollectionsTemplateAboutUsCollectionArgs =
  {
    limit?: InputMaybe<Scalars['Int']>
    locale?: InputMaybe<Scalars['String']>
    order?: InputMaybe<
      Array<
        InputMaybe<ComponentComparisonTableLinkingCollectionsTemplateAboutUsCollectionOrder>
      >
    >
    preview?: InputMaybe<Scalars['Boolean']>
    skip?: InputMaybe<Scalars['Int']>
  }

export type ComponentComparisonTableLinkingCollectionsTemplateBookingCollectionArgs =
  {
    limit?: InputMaybe<Scalars['Int']>
    locale?: InputMaybe<Scalars['String']>
    order?: InputMaybe<
      Array<
        InputMaybe<ComponentComparisonTableLinkingCollectionsTemplateBookingCollectionOrder>
      >
    >
    preview?: InputMaybe<Scalars['Boolean']>
    skip?: InputMaybe<Scalars['Int']>
  }

export type ComponentComparisonTableLinkingCollectionsTemplateCareersCollectionArgs =
  {
    limit?: InputMaybe<Scalars['Int']>
    locale?: InputMaybe<Scalars['String']>
    order?: InputMaybe<
      Array<
        InputMaybe<ComponentComparisonTableLinkingCollectionsTemplateCareersCollectionOrder>
      >
    >
    preview?: InputMaybe<Scalars['Boolean']>
    skip?: InputMaybe<Scalars['Int']>
  }

export type ComponentComparisonTableLinkingCollectionsTemplateContactUsCollectionArgs =
  {
    limit?: InputMaybe<Scalars['Int']>
    locale?: InputMaybe<Scalars['String']>
    order?: InputMaybe<
      Array<
        InputMaybe<ComponentComparisonTableLinkingCollectionsTemplateContactUsCollectionOrder>
      >
    >
    preview?: InputMaybe<Scalars['Boolean']>
    skip?: InputMaybe<Scalars['Int']>
  }

export type ComponentComparisonTableLinkingCollectionsTemplateDestinationsCollectionArgs =
  {
    limit?: InputMaybe<Scalars['Int']>
    locale?: InputMaybe<Scalars['String']>
    order?: InputMaybe<
      Array<
        InputMaybe<ComponentComparisonTableLinkingCollectionsTemplateDestinationsCollectionOrder>
      >
    >
    preview?: InputMaybe<Scalars['Boolean']>
    skip?: InputMaybe<Scalars['Int']>
  }

export type ComponentComparisonTableLinkingCollectionsTemplateForaReserveCollectionArgs =
  {
    limit?: InputMaybe<Scalars['Int']>
    locale?: InputMaybe<Scalars['String']>
    order?: InputMaybe<
      Array<
        InputMaybe<ComponentComparisonTableLinkingCollectionsTemplateForaReserveCollectionOrder>
      >
    >
    preview?: InputMaybe<Scalars['Boolean']>
    skip?: InputMaybe<Scalars['Int']>
  }

export type ComponentComparisonTableLinkingCollectionsTemplateForaXCollectionArgs =
  {
    limit?: InputMaybe<Scalars['Int']>
    locale?: InputMaybe<Scalars['String']>
    order?: InputMaybe<
      Array<
        InputMaybe<ComponentComparisonTableLinkingCollectionsTemplateForaXCollectionOrder>
      >
    >
    preview?: InputMaybe<Scalars['Boolean']>
    skip?: InputMaybe<Scalars['Int']>
  }

export type ComponentComparisonTableLinkingCollectionsTemplateHomeCollectionArgs =
  {
    limit?: InputMaybe<Scalars['Int']>
    locale?: InputMaybe<Scalars['String']>
    order?: InputMaybe<
      Array<
        InputMaybe<ComponentComparisonTableLinkingCollectionsTemplateHomeCollectionOrder>
      >
    >
    preview?: InputMaybe<Scalars['Boolean']>
    skip?: InputMaybe<Scalars['Int']>
  }

export type ComponentComparisonTableLinkingCollectionsTemplateJoinUsCollectionArgs =
  {
    limit?: InputMaybe<Scalars['Int']>
    locale?: InputMaybe<Scalars['String']>
    order?: InputMaybe<
      Array<
        InputMaybe<ComponentComparisonTableLinkingCollectionsTemplateJoinUsCollectionOrder>
      >
    >
    preview?: InputMaybe<Scalars['Boolean']>
    skip?: InputMaybe<Scalars['Int']>
  }

export type ComponentComparisonTableLinkingCollectionsTemplateNewsletterCollectionArgs =
  {
    limit?: InputMaybe<Scalars['Int']>
    locale?: InputMaybe<Scalars['String']>
    order?: InputMaybe<
      Array<
        InputMaybe<ComponentComparisonTableLinkingCollectionsTemplateNewsletterCollectionOrder>
      >
    >
    preview?: InputMaybe<Scalars['Boolean']>
    skip?: InputMaybe<Scalars['Int']>
  }

export type ComponentComparisonTableLinkingCollectionsTemplatePageCollectionArgs =
  {
    limit?: InputMaybe<Scalars['Int']>
    locale?: InputMaybe<Scalars['String']>
    order?: InputMaybe<
      Array<
        InputMaybe<ComponentComparisonTableLinkingCollectionsTemplatePageCollectionOrder>
      >
    >
    preview?: InputMaybe<Scalars['Boolean']>
    skip?: InputMaybe<Scalars['Int']>
  }

export type ComponentComparisonTableLinkingCollectionsTemplatePartnersCollectionArgs =
  {
    limit?: InputMaybe<Scalars['Int']>
    locale?: InputMaybe<Scalars['String']>
    order?: InputMaybe<
      Array<
        InputMaybe<ComponentComparisonTableLinkingCollectionsTemplatePartnersCollectionOrder>
      >
    >
    preview?: InputMaybe<Scalars['Boolean']>
    skip?: InputMaybe<Scalars['Int']>
  }

export type ComponentComparisonTableLinkingCollectionsTemplateReviewsCollectionArgs =
  {
    limit?: InputMaybe<Scalars['Int']>
    locale?: InputMaybe<Scalars['String']>
    order?: InputMaybe<
      Array<
        InputMaybe<ComponentComparisonTableLinkingCollectionsTemplateReviewsCollectionOrder>
      >
    >
    preview?: InputMaybe<Scalars['Boolean']>
    skip?: InputMaybe<Scalars['Int']>
  }

export type ComponentComparisonTableLinkingCollectionsTemplateWhyBookWithForaCollectionArgs =
  {
    limit?: InputMaybe<Scalars['Int']>
    locale?: InputMaybe<Scalars['String']>
    order?: InputMaybe<
      Array<
        InputMaybe<ComponentComparisonTableLinkingCollectionsTemplateWhyBookWithForaCollectionOrder>
      >
    >
    preview?: InputMaybe<Scalars['Boolean']>
    skip?: InputMaybe<Scalars['Int']>
  }

export enum ComponentComparisonTableLinkingCollectionsCampaignPagesCollectionOrder {
  CollapseFooterAsc = 'collapseFooter_ASC',
  CollapseFooterDesc = 'collapseFooter_DESC',
  CollapseHeaderAsc = 'collapseHeader_ASC',
  CollapseHeaderDesc = 'collapseHeader_DESC',
  HideFooterAsc = 'hideFooter_ASC',
  HideFooterDesc = 'hideFooter_DESC',
  HideGlobalBannerAsc = 'hideGlobalBanner_ASC',
  HideGlobalBannerDesc = 'hideGlobalBanner_DESC',
  JotformIdAsc = 'jotformId_ASC',
  JotformIdDesc = 'jotformId_DESC',
  NoindexNofollowAsc = 'noindexNofollow_ASC',
  NoindexNofollowDesc = 'noindexNofollow_DESC',
  ShowHeaderAsc = 'showHeader_ASC',
  ShowHeaderDesc = 'showHeader_DESC',
  SlugAsc = 'slug_ASC',
  SlugDesc = 'slug_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC',
}

export enum ComponentComparisonTableLinkingCollectionsTemplateAboutUsCollectionOrder {
  CollapseHeaderAsc = 'collapseHeader_ASC',
  CollapseHeaderDesc = 'collapseHeader_DESC',
  HeaderBlockTitleAsc = 'headerBlockTitle_ASC',
  HeaderBlockTitleDesc = 'headerBlockTitle_DESC',
  ShowHeaderAsc = 'showHeader_ASC',
  ShowHeaderDesc = 'showHeader_DESC',
  SubtitleAsc = 'subtitle_ASC',
  SubtitleDesc = 'subtitle_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC',
}

export enum ComponentComparisonTableLinkingCollectionsTemplateBookingCollectionOrder {
  CollapseHeaderAsc = 'collapseHeader_ASC',
  CollapseHeaderDesc = 'collapseHeader_DESC',
  ShowHeaderAsc = 'showHeader_ASC',
  ShowHeaderDesc = 'showHeader_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC',
}

export enum ComponentComparisonTableLinkingCollectionsTemplateCareersCollectionOrder {
  CollapseHeaderAsc = 'collapseHeader_ASC',
  CollapseHeaderDesc = 'collapseHeader_DESC',
  ShowHeaderAsc = 'showHeader_ASC',
  ShowHeaderDesc = 'showHeader_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC',
}

export enum ComponentComparisonTableLinkingCollectionsTemplateContactUsCollectionOrder {
  CollapseHeaderAsc = 'collapseHeader_ASC',
  CollapseHeaderDesc = 'collapseHeader_DESC',
  ShowHeaderAsc = 'showHeader_ASC',
  ShowHeaderDesc = 'showHeader_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC',
}

export enum ComponentComparisonTableLinkingCollectionsTemplateDestinationsCollectionOrder {
  CollapseHeaderAsc = 'collapseHeader_ASC',
  CollapseHeaderDesc = 'collapseHeader_DESC',
  CollapsibleHeadingAsc = 'collapsibleHeading_ASC',
  CollapsibleHeadingDesc = 'collapsibleHeading_DESC',
  ShowHeaderAsc = 'showHeader_ASC',
  ShowHeaderDesc = 'showHeader_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC',
}

export enum ComponentComparisonTableLinkingCollectionsTemplateForaReserveCollectionOrder {
  CollapseHeaderAsc = 'collapseHeader_ASC',
  CollapseHeaderDesc = 'collapseHeader_DESC',
  ShowHeaderAsc = 'showHeader_ASC',
  ShowHeaderDesc = 'showHeader_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC',
}

export enum ComponentComparisonTableLinkingCollectionsTemplateForaXCollectionOrder {
  AdvisorGridHeaderAsc = 'advisorGridHeader_ASC',
  AdvisorGridHeaderDesc = 'advisorGridHeader_DESC',
  AdvisorGridSubCopyAsc = 'advisorGridSubCopy_ASC',
  AdvisorGridSubCopyDesc = 'advisorGridSubCopy_DESC',
  CollapseHeaderAsc = 'collapseHeader_ASC',
  CollapseHeaderDesc = 'collapseHeader_DESC',
  ShowHeaderAsc = 'showHeader_ASC',
  ShowHeaderDesc = 'showHeader_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC',
}

export enum ComponentComparisonTableLinkingCollectionsTemplateHomeCollectionOrder {
  CollapseHeaderAsc = 'collapseHeader_ASC',
  CollapseHeaderDesc = 'collapseHeader_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC',
}

export enum ComponentComparisonTableLinkingCollectionsTemplateJoinUsCollectionOrder {
  CollapseHeaderAsc = 'collapseHeader_ASC',
  CollapseHeaderDesc = 'collapseHeader_DESC',
  HideGlobalBannerAsc = 'hideGlobalBanner_ASC',
  HideGlobalBannerDesc = 'hideGlobalBanner_DESC',
  ShowHeaderAsc = 'showHeader_ASC',
  ShowHeaderDesc = 'showHeader_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC',
}

export enum ComponentComparisonTableLinkingCollectionsTemplateNewsletterCollectionOrder {
  CollapseHeaderAsc = 'collapseHeader_ASC',
  CollapseHeaderDesc = 'collapseHeader_DESC',
  ShowHeaderAsc = 'showHeader_ASC',
  ShowHeaderDesc = 'showHeader_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC',
}

export enum ComponentComparisonTableLinkingCollectionsTemplatePageCollectionOrder {
  DirectoryAsc = 'directory_ASC',
  DirectoryDesc = 'directory_DESC',
  InternalLabelAsc = 'internalLabel_ASC',
  InternalLabelDesc = 'internalLabel_DESC',
  MetaTitleAsc = 'metaTitle_ASC',
  MetaTitleDesc = 'metaTitle_DESC',
  NoindexNofollowAsc = 'noindexNofollow_ASC',
  NoindexNofollowDesc = 'noindexNofollow_DESC',
  SlugAsc = 'slug_ASC',
  SlugDesc = 'slug_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
}

export enum ComponentComparisonTableLinkingCollectionsTemplatePartnersCollectionOrder {
  CollapseHeaderAsc = 'collapseHeader_ASC',
  CollapseHeaderDesc = 'collapseHeader_DESC',
  ShowHeaderAsc = 'showHeader_ASC',
  ShowHeaderDesc = 'showHeader_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC',
}

export enum ComponentComparisonTableLinkingCollectionsTemplateReviewsCollectionOrder {
  CollapseHeaderAsc = 'collapseHeader_ASC',
  CollapseHeaderDesc = 'collapseHeader_DESC',
  ShowHeaderAsc = 'showHeader_ASC',
  ShowHeaderDesc = 'showHeader_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC',
}

export enum ComponentComparisonTableLinkingCollectionsTemplateWhyBookWithForaCollectionOrder {
  CollapseHeaderAsc = 'collapseHeader_ASC',
  CollapseHeaderDesc = 'collapseHeader_DESC',
  ShowHeaderAsc = 'showHeader_ASC',
  ShowHeaderDesc = 'showHeader_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC',
}

export enum ComponentComparisonTableOrder {
  CtaTextAsc = 'ctaText_ASC',
  CtaTextDesc = 'ctaText_DESC',
  CtaUrlAsc = 'ctaUrl_ASC',
  CtaUrlDesc = 'ctaUrl_DESC',
  ForaColumnHeadingAsc = 'foraColumnHeading_ASC',
  ForaColumnHeadingDesc = 'foraColumnHeading_DESC',
  InternalLabelAsc = 'internalLabel_ASC',
  InternalLabelDesc = 'internalLabel_DESC',
  OthersColumnHeadingAsc = 'othersColumnHeading_ASC',
  OthersColumnHeadingDesc = 'othersColumnHeading_DESC',
  SectionIdAsc = 'sectionId_ASC',
  SectionIdDesc = 'sectionId_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  UrlTargetAsc = 'urlTarget_ASC',
  UrlTargetDesc = 'urlTarget_DESC',
}

/** A row within a Comparison Table on a page. [See type definition](https://app.contentful.com/spaces/9cxgygtadmvl/content_types/componentComparisonTableRow) */
export type ComponentComparisonTableRow = Entry &
  _Node & {
    __typename?: 'ComponentComparisonTableRow'
    _id: Scalars['ID']
    analyticsModule?: Maybe<SettingsAnalyticsModule>
    contentfulMetadata: ContentfulMetadata
    foraValue?: Maybe<Scalars['String']>
    internalLabel?: Maybe<Scalars['String']>
    linkedFrom?: Maybe<ComponentComparisonTableRowLinkingCollections>
    othersValue?: Maybe<Scalars['String']>
    sys: Sys
    title?: Maybe<Scalars['String']>
  }

/** A row within a Comparison Table on a page. [See type definition](https://app.contentful.com/spaces/9cxgygtadmvl/content_types/componentComparisonTableRow) */
export type ComponentComparisonTableRowAnalyticsModuleArgs = {
  locale?: InputMaybe<Scalars['String']>
  preview?: InputMaybe<Scalars['Boolean']>
  where?: InputMaybe<SettingsAnalyticsModuleFilter>
}

/** A row within a Comparison Table on a page. [See type definition](https://app.contentful.com/spaces/9cxgygtadmvl/content_types/componentComparisonTableRow) */
export type ComponentComparisonTableRowForaValueArgs = {
  locale?: InputMaybe<Scalars['String']>
}

/** A row within a Comparison Table on a page. [See type definition](https://app.contentful.com/spaces/9cxgygtadmvl/content_types/componentComparisonTableRow) */
export type ComponentComparisonTableRowInternalLabelArgs = {
  locale?: InputMaybe<Scalars['String']>
}

/** A row within a Comparison Table on a page. [See type definition](https://app.contentful.com/spaces/9cxgygtadmvl/content_types/componentComparisonTableRow) */
export type ComponentComparisonTableRowLinkedFromArgs = {
  allowedLocales?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
}

/** A row within a Comparison Table on a page. [See type definition](https://app.contentful.com/spaces/9cxgygtadmvl/content_types/componentComparisonTableRow) */
export type ComponentComparisonTableRowOthersValueArgs = {
  locale?: InputMaybe<Scalars['String']>
}

/** A row within a Comparison Table on a page. [See type definition](https://app.contentful.com/spaces/9cxgygtadmvl/content_types/componentComparisonTableRow) */
export type ComponentComparisonTableRowTitleArgs = {
  locale?: InputMaybe<Scalars['String']>
}

export type ComponentComparisonTableRowCollection = {
  __typename?: 'ComponentComparisonTableRowCollection'
  items: Array<Maybe<ComponentComparisonTableRow>>
  limit: Scalars['Int']
  skip: Scalars['Int']
  total: Scalars['Int']
}

export type ComponentComparisonTableRowFilter = {
  AND?: InputMaybe<Array<InputMaybe<ComponentComparisonTableRowFilter>>>
  OR?: InputMaybe<Array<InputMaybe<ComponentComparisonTableRowFilter>>>
  analyticsModule?: InputMaybe<CfSettingsAnalyticsModuleNestedFilter>
  analyticsModule_exists?: InputMaybe<Scalars['Boolean']>
  contentfulMetadata?: InputMaybe<ContentfulMetadataFilter>
  foraValue?: InputMaybe<Scalars['String']>
  foraValue_contains?: InputMaybe<Scalars['String']>
  foraValue_exists?: InputMaybe<Scalars['Boolean']>
  foraValue_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  foraValue_not?: InputMaybe<Scalars['String']>
  foraValue_not_contains?: InputMaybe<Scalars['String']>
  foraValue_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  internalLabel?: InputMaybe<Scalars['String']>
  internalLabel_contains?: InputMaybe<Scalars['String']>
  internalLabel_exists?: InputMaybe<Scalars['Boolean']>
  internalLabel_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  internalLabel_not?: InputMaybe<Scalars['String']>
  internalLabel_not_contains?: InputMaybe<Scalars['String']>
  internalLabel_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  othersValue?: InputMaybe<Scalars['String']>
  othersValue_contains?: InputMaybe<Scalars['String']>
  othersValue_exists?: InputMaybe<Scalars['Boolean']>
  othersValue_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  othersValue_not?: InputMaybe<Scalars['String']>
  othersValue_not_contains?: InputMaybe<Scalars['String']>
  othersValue_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  sys?: InputMaybe<SysFilter>
  title?: InputMaybe<Scalars['String']>
  title_contains?: InputMaybe<Scalars['String']>
  title_exists?: InputMaybe<Scalars['Boolean']>
  title_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  title_not?: InputMaybe<Scalars['String']>
  title_not_contains?: InputMaybe<Scalars['String']>
  title_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
}

export type ComponentComparisonTableRowLinkingCollections = {
  __typename?: 'ComponentComparisonTableRowLinkingCollections'
  componentComparisonTableCollection?: Maybe<ComponentComparisonTableCollection>
  entryCollection?: Maybe<EntryCollection>
}

export type ComponentComparisonTableRowLinkingCollectionsComponentComparisonTableCollectionArgs =
  {
    limit?: InputMaybe<Scalars['Int']>
    locale?: InputMaybe<Scalars['String']>
    order?: InputMaybe<
      Array<
        InputMaybe<ComponentComparisonTableRowLinkingCollectionsComponentComparisonTableCollectionOrder>
      >
    >
    preview?: InputMaybe<Scalars['Boolean']>
    skip?: InputMaybe<Scalars['Int']>
  }

export type ComponentComparisonTableRowLinkingCollectionsEntryCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>
  locale?: InputMaybe<Scalars['String']>
  preview?: InputMaybe<Scalars['Boolean']>
  skip?: InputMaybe<Scalars['Int']>
}

export enum ComponentComparisonTableRowLinkingCollectionsComponentComparisonTableCollectionOrder {
  CtaTextAsc = 'ctaText_ASC',
  CtaTextDesc = 'ctaText_DESC',
  CtaUrlAsc = 'ctaUrl_ASC',
  CtaUrlDesc = 'ctaUrl_DESC',
  ForaColumnHeadingAsc = 'foraColumnHeading_ASC',
  ForaColumnHeadingDesc = 'foraColumnHeading_DESC',
  InternalLabelAsc = 'internalLabel_ASC',
  InternalLabelDesc = 'internalLabel_DESC',
  OthersColumnHeadingAsc = 'othersColumnHeading_ASC',
  OthersColumnHeadingDesc = 'othersColumnHeading_DESC',
  SectionIdAsc = 'sectionId_ASC',
  SectionIdDesc = 'sectionId_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  UrlTargetAsc = 'urlTarget_ASC',
  UrlTargetDesc = 'urlTarget_DESC',
}

export enum ComponentComparisonTableRowOrder {
  InternalLabelAsc = 'internalLabel_ASC',
  InternalLabelDesc = 'internalLabel_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC',
}

export type ComponentComparisonTableRowsCollection = {
  __typename?: 'ComponentComparisonTableRowsCollection'
  items: Array<Maybe<ComponentComparisonTableRow>>
  limit: Scalars['Int']
  skip: Scalars['Int']
  total: Scalars['Int']
}

export enum ComponentComparisonTableRowsCollectionOrder {
  InternalLabelAsc = 'internalLabel_ASC',
  InternalLabelDesc = 'internalLabel_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC',
}

/** A Banner CTA which can embed a navigation link or form [See type definition](https://app.contentful.com/spaces/9cxgygtadmvl/content_types/componentCtaBanner) */
export type ComponentCtaBanner = Entry &
  _Node & {
    __typename?: 'ComponentCtaBanner'
    _id: Scalars['ID']
    analyticsModule?: Maybe<SettingsAnalyticsModule>
    contentfulMetadata: ContentfulMetadata
    ctaDestination?: Maybe<Scalars['String']>
    ctaText?: Maybe<Scalars['String']>
    formType?: Maybe<Scalars['String']>
    heading?: Maybe<Scalars['String']>
    includeForm?: Maybe<Scalars['Boolean']>
    internalLabel?: Maybe<Scalars['String']>
    linkedFrom?: Maybe<ComponentCtaBannerLinkingCollections>
    sectionId?: Maybe<Scalars['String']>
    sys: Sys
    teaserCopy?: Maybe<ComponentCtaBannerTeaserCopy>
    titleStyle?: Maybe<Scalars['String']>
    urlTarget?: Maybe<Scalars['String']>
    zapierId?: Maybe<Scalars['String']>
  }

/** A Banner CTA which can embed a navigation link or form [See type definition](https://app.contentful.com/spaces/9cxgygtadmvl/content_types/componentCtaBanner) */
export type ComponentCtaBannerAnalyticsModuleArgs = {
  locale?: InputMaybe<Scalars['String']>
  preview?: InputMaybe<Scalars['Boolean']>
  where?: InputMaybe<SettingsAnalyticsModuleFilter>
}

/** A Banner CTA which can embed a navigation link or form [See type definition](https://app.contentful.com/spaces/9cxgygtadmvl/content_types/componentCtaBanner) */
export type ComponentCtaBannerCtaDestinationArgs = {
  locale?: InputMaybe<Scalars['String']>
}

/** A Banner CTA which can embed a navigation link or form [See type definition](https://app.contentful.com/spaces/9cxgygtadmvl/content_types/componentCtaBanner) */
export type ComponentCtaBannerCtaTextArgs = {
  locale?: InputMaybe<Scalars['String']>
}

/** A Banner CTA which can embed a navigation link or form [See type definition](https://app.contentful.com/spaces/9cxgygtadmvl/content_types/componentCtaBanner) */
export type ComponentCtaBannerFormTypeArgs = {
  locale?: InputMaybe<Scalars['String']>
}

/** A Banner CTA which can embed a navigation link or form [See type definition](https://app.contentful.com/spaces/9cxgygtadmvl/content_types/componentCtaBanner) */
export type ComponentCtaBannerHeadingArgs = {
  locale?: InputMaybe<Scalars['String']>
}

/** A Banner CTA which can embed a navigation link or form [See type definition](https://app.contentful.com/spaces/9cxgygtadmvl/content_types/componentCtaBanner) */
export type ComponentCtaBannerIncludeFormArgs = {
  locale?: InputMaybe<Scalars['String']>
}

/** A Banner CTA which can embed a navigation link or form [See type definition](https://app.contentful.com/spaces/9cxgygtadmvl/content_types/componentCtaBanner) */
export type ComponentCtaBannerInternalLabelArgs = {
  locale?: InputMaybe<Scalars['String']>
}

/** A Banner CTA which can embed a navigation link or form [See type definition](https://app.contentful.com/spaces/9cxgygtadmvl/content_types/componentCtaBanner) */
export type ComponentCtaBannerLinkedFromArgs = {
  allowedLocales?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
}

/** A Banner CTA which can embed a navigation link or form [See type definition](https://app.contentful.com/spaces/9cxgygtadmvl/content_types/componentCtaBanner) */
export type ComponentCtaBannerSectionIdArgs = {
  locale?: InputMaybe<Scalars['String']>
}

/** A Banner CTA which can embed a navigation link or form [See type definition](https://app.contentful.com/spaces/9cxgygtadmvl/content_types/componentCtaBanner) */
export type ComponentCtaBannerTeaserCopyArgs = {
  locale?: InputMaybe<Scalars['String']>
}

/** A Banner CTA which can embed a navigation link or form [See type definition](https://app.contentful.com/spaces/9cxgygtadmvl/content_types/componentCtaBanner) */
export type ComponentCtaBannerTitleStyleArgs = {
  locale?: InputMaybe<Scalars['String']>
}

/** A Banner CTA which can embed a navigation link or form [See type definition](https://app.contentful.com/spaces/9cxgygtadmvl/content_types/componentCtaBanner) */
export type ComponentCtaBannerUrlTargetArgs = {
  locale?: InputMaybe<Scalars['String']>
}

/** A Banner CTA which can embed a navigation link or form [See type definition](https://app.contentful.com/spaces/9cxgygtadmvl/content_types/componentCtaBanner) */
export type ComponentCtaBannerZapierIdArgs = {
  locale?: InputMaybe<Scalars['String']>
}

export type ComponentCtaBannerCollection = {
  __typename?: 'ComponentCtaBannerCollection'
  items: Array<Maybe<ComponentCtaBanner>>
  limit: Scalars['Int']
  skip: Scalars['Int']
  total: Scalars['Int']
}

export type ComponentCtaBannerFilter = {
  AND?: InputMaybe<Array<InputMaybe<ComponentCtaBannerFilter>>>
  OR?: InputMaybe<Array<InputMaybe<ComponentCtaBannerFilter>>>
  analyticsModule?: InputMaybe<CfSettingsAnalyticsModuleNestedFilter>
  analyticsModule_exists?: InputMaybe<Scalars['Boolean']>
  contentfulMetadata?: InputMaybe<ContentfulMetadataFilter>
  ctaDestination?: InputMaybe<Scalars['String']>
  ctaDestination_contains?: InputMaybe<Scalars['String']>
  ctaDestination_exists?: InputMaybe<Scalars['Boolean']>
  ctaDestination_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  ctaDestination_not?: InputMaybe<Scalars['String']>
  ctaDestination_not_contains?: InputMaybe<Scalars['String']>
  ctaDestination_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  ctaText?: InputMaybe<Scalars['String']>
  ctaText_contains?: InputMaybe<Scalars['String']>
  ctaText_exists?: InputMaybe<Scalars['Boolean']>
  ctaText_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  ctaText_not?: InputMaybe<Scalars['String']>
  ctaText_not_contains?: InputMaybe<Scalars['String']>
  ctaText_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  formType?: InputMaybe<Scalars['String']>
  formType_contains?: InputMaybe<Scalars['String']>
  formType_exists?: InputMaybe<Scalars['Boolean']>
  formType_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  formType_not?: InputMaybe<Scalars['String']>
  formType_not_contains?: InputMaybe<Scalars['String']>
  formType_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  heading?: InputMaybe<Scalars['String']>
  heading_contains?: InputMaybe<Scalars['String']>
  heading_exists?: InputMaybe<Scalars['Boolean']>
  heading_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  heading_not?: InputMaybe<Scalars['String']>
  heading_not_contains?: InputMaybe<Scalars['String']>
  heading_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  includeForm?: InputMaybe<Scalars['Boolean']>
  includeForm_exists?: InputMaybe<Scalars['Boolean']>
  includeForm_not?: InputMaybe<Scalars['Boolean']>
  internalLabel?: InputMaybe<Scalars['String']>
  internalLabel_contains?: InputMaybe<Scalars['String']>
  internalLabel_exists?: InputMaybe<Scalars['Boolean']>
  internalLabel_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  internalLabel_not?: InputMaybe<Scalars['String']>
  internalLabel_not_contains?: InputMaybe<Scalars['String']>
  internalLabel_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  sectionId?: InputMaybe<Scalars['String']>
  sectionId_contains?: InputMaybe<Scalars['String']>
  sectionId_exists?: InputMaybe<Scalars['Boolean']>
  sectionId_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  sectionId_not?: InputMaybe<Scalars['String']>
  sectionId_not_contains?: InputMaybe<Scalars['String']>
  sectionId_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  sys?: InputMaybe<SysFilter>
  teaserCopy_contains?: InputMaybe<Scalars['String']>
  teaserCopy_exists?: InputMaybe<Scalars['Boolean']>
  teaserCopy_not_contains?: InputMaybe<Scalars['String']>
  titleStyle?: InputMaybe<Scalars['String']>
  titleStyle_contains?: InputMaybe<Scalars['String']>
  titleStyle_exists?: InputMaybe<Scalars['Boolean']>
  titleStyle_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  titleStyle_not?: InputMaybe<Scalars['String']>
  titleStyle_not_contains?: InputMaybe<Scalars['String']>
  titleStyle_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  urlTarget?: InputMaybe<Scalars['String']>
  urlTarget_contains?: InputMaybe<Scalars['String']>
  urlTarget_exists?: InputMaybe<Scalars['Boolean']>
  urlTarget_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  urlTarget_not?: InputMaybe<Scalars['String']>
  urlTarget_not_contains?: InputMaybe<Scalars['String']>
  urlTarget_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  zapierId?: InputMaybe<Scalars['String']>
  zapierId_contains?: InputMaybe<Scalars['String']>
  zapierId_exists?: InputMaybe<Scalars['Boolean']>
  zapierId_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  zapierId_not?: InputMaybe<Scalars['String']>
  zapierId_not_contains?: InputMaybe<Scalars['String']>
  zapierId_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
}

export type ComponentCtaBannerLinkingCollections = {
  __typename?: 'ComponentCtaBannerLinkingCollections'
  campaignPagesCollection?: Maybe<CampaignPagesCollection>
  entryCollection?: Maybe<EntryCollection>
  templateAboutUsCollection?: Maybe<TemplateAboutUsCollection>
  templateBookingCollection?: Maybe<TemplateBookingCollection>
  templateCareersCollection?: Maybe<TemplateCareersCollection>
  templateContactUsCollection?: Maybe<TemplateContactUsCollection>
  templateDestinationsCollection?: Maybe<TemplateDestinationsCollection>
  templateForaReserveCollection?: Maybe<TemplateForaReserveCollection>
  templateForaXCollection?: Maybe<TemplateForaXCollection>
  templateHomeCollection?: Maybe<TemplateHomeCollection>
  templateJoinUsCollection?: Maybe<TemplateJoinUsCollection>
  templateNewsletterCollection?: Maybe<TemplateNewsletterCollection>
  templatePageCollection?: Maybe<TemplatePageCollection>
  templatePartnersCollection?: Maybe<TemplatePartnersCollection>
  templateReviewsCollection?: Maybe<TemplateReviewsCollection>
  templateWhyBookWithForaCollection?: Maybe<TemplateWhyBookWithForaCollection>
}

export type ComponentCtaBannerLinkingCollectionsCampaignPagesCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>
  locale?: InputMaybe<Scalars['String']>
  order?: InputMaybe<
    Array<
      InputMaybe<ComponentCtaBannerLinkingCollectionsCampaignPagesCollectionOrder>
    >
  >
  preview?: InputMaybe<Scalars['Boolean']>
  skip?: InputMaybe<Scalars['Int']>
}

export type ComponentCtaBannerLinkingCollectionsEntryCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>
  locale?: InputMaybe<Scalars['String']>
  preview?: InputMaybe<Scalars['Boolean']>
  skip?: InputMaybe<Scalars['Int']>
}

export type ComponentCtaBannerLinkingCollectionsTemplateAboutUsCollectionArgs =
  {
    limit?: InputMaybe<Scalars['Int']>
    locale?: InputMaybe<Scalars['String']>
    order?: InputMaybe<
      Array<
        InputMaybe<ComponentCtaBannerLinkingCollectionsTemplateAboutUsCollectionOrder>
      >
    >
    preview?: InputMaybe<Scalars['Boolean']>
    skip?: InputMaybe<Scalars['Int']>
  }

export type ComponentCtaBannerLinkingCollectionsTemplateBookingCollectionArgs =
  {
    limit?: InputMaybe<Scalars['Int']>
    locale?: InputMaybe<Scalars['String']>
    order?: InputMaybe<
      Array<
        InputMaybe<ComponentCtaBannerLinkingCollectionsTemplateBookingCollectionOrder>
      >
    >
    preview?: InputMaybe<Scalars['Boolean']>
    skip?: InputMaybe<Scalars['Int']>
  }

export type ComponentCtaBannerLinkingCollectionsTemplateCareersCollectionArgs =
  {
    limit?: InputMaybe<Scalars['Int']>
    locale?: InputMaybe<Scalars['String']>
    order?: InputMaybe<
      Array<
        InputMaybe<ComponentCtaBannerLinkingCollectionsTemplateCareersCollectionOrder>
      >
    >
    preview?: InputMaybe<Scalars['Boolean']>
    skip?: InputMaybe<Scalars['Int']>
  }

export type ComponentCtaBannerLinkingCollectionsTemplateContactUsCollectionArgs =
  {
    limit?: InputMaybe<Scalars['Int']>
    locale?: InputMaybe<Scalars['String']>
    order?: InputMaybe<
      Array<
        InputMaybe<ComponentCtaBannerLinkingCollectionsTemplateContactUsCollectionOrder>
      >
    >
    preview?: InputMaybe<Scalars['Boolean']>
    skip?: InputMaybe<Scalars['Int']>
  }

export type ComponentCtaBannerLinkingCollectionsTemplateDestinationsCollectionArgs =
  {
    limit?: InputMaybe<Scalars['Int']>
    locale?: InputMaybe<Scalars['String']>
    order?: InputMaybe<
      Array<
        InputMaybe<ComponentCtaBannerLinkingCollectionsTemplateDestinationsCollectionOrder>
      >
    >
    preview?: InputMaybe<Scalars['Boolean']>
    skip?: InputMaybe<Scalars['Int']>
  }

export type ComponentCtaBannerLinkingCollectionsTemplateForaReserveCollectionArgs =
  {
    limit?: InputMaybe<Scalars['Int']>
    locale?: InputMaybe<Scalars['String']>
    order?: InputMaybe<
      Array<
        InputMaybe<ComponentCtaBannerLinkingCollectionsTemplateForaReserveCollectionOrder>
      >
    >
    preview?: InputMaybe<Scalars['Boolean']>
    skip?: InputMaybe<Scalars['Int']>
  }

export type ComponentCtaBannerLinkingCollectionsTemplateForaXCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>
  locale?: InputMaybe<Scalars['String']>
  order?: InputMaybe<
    Array<
      InputMaybe<ComponentCtaBannerLinkingCollectionsTemplateForaXCollectionOrder>
    >
  >
  preview?: InputMaybe<Scalars['Boolean']>
  skip?: InputMaybe<Scalars['Int']>
}

export type ComponentCtaBannerLinkingCollectionsTemplateHomeCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>
  locale?: InputMaybe<Scalars['String']>
  order?: InputMaybe<
    Array<
      InputMaybe<ComponentCtaBannerLinkingCollectionsTemplateHomeCollectionOrder>
    >
  >
  preview?: InputMaybe<Scalars['Boolean']>
  skip?: InputMaybe<Scalars['Int']>
}

export type ComponentCtaBannerLinkingCollectionsTemplateJoinUsCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>
  locale?: InputMaybe<Scalars['String']>
  order?: InputMaybe<
    Array<
      InputMaybe<ComponentCtaBannerLinkingCollectionsTemplateJoinUsCollectionOrder>
    >
  >
  preview?: InputMaybe<Scalars['Boolean']>
  skip?: InputMaybe<Scalars['Int']>
}

export type ComponentCtaBannerLinkingCollectionsTemplateNewsletterCollectionArgs =
  {
    limit?: InputMaybe<Scalars['Int']>
    locale?: InputMaybe<Scalars['String']>
    order?: InputMaybe<
      Array<
        InputMaybe<ComponentCtaBannerLinkingCollectionsTemplateNewsletterCollectionOrder>
      >
    >
    preview?: InputMaybe<Scalars['Boolean']>
    skip?: InputMaybe<Scalars['Int']>
  }

export type ComponentCtaBannerLinkingCollectionsTemplatePageCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>
  locale?: InputMaybe<Scalars['String']>
  order?: InputMaybe<
    Array<
      InputMaybe<ComponentCtaBannerLinkingCollectionsTemplatePageCollectionOrder>
    >
  >
  preview?: InputMaybe<Scalars['Boolean']>
  skip?: InputMaybe<Scalars['Int']>
}

export type ComponentCtaBannerLinkingCollectionsTemplatePartnersCollectionArgs =
  {
    limit?: InputMaybe<Scalars['Int']>
    locale?: InputMaybe<Scalars['String']>
    order?: InputMaybe<
      Array<
        InputMaybe<ComponentCtaBannerLinkingCollectionsTemplatePartnersCollectionOrder>
      >
    >
    preview?: InputMaybe<Scalars['Boolean']>
    skip?: InputMaybe<Scalars['Int']>
  }

export type ComponentCtaBannerLinkingCollectionsTemplateReviewsCollectionArgs =
  {
    limit?: InputMaybe<Scalars['Int']>
    locale?: InputMaybe<Scalars['String']>
    order?: InputMaybe<
      Array<
        InputMaybe<ComponentCtaBannerLinkingCollectionsTemplateReviewsCollectionOrder>
      >
    >
    preview?: InputMaybe<Scalars['Boolean']>
    skip?: InputMaybe<Scalars['Int']>
  }

export type ComponentCtaBannerLinkingCollectionsTemplateWhyBookWithForaCollectionArgs =
  {
    limit?: InputMaybe<Scalars['Int']>
    locale?: InputMaybe<Scalars['String']>
    order?: InputMaybe<
      Array<
        InputMaybe<ComponentCtaBannerLinkingCollectionsTemplateWhyBookWithForaCollectionOrder>
      >
    >
    preview?: InputMaybe<Scalars['Boolean']>
    skip?: InputMaybe<Scalars['Int']>
  }

export enum ComponentCtaBannerLinkingCollectionsCampaignPagesCollectionOrder {
  CollapseFooterAsc = 'collapseFooter_ASC',
  CollapseFooterDesc = 'collapseFooter_DESC',
  CollapseHeaderAsc = 'collapseHeader_ASC',
  CollapseHeaderDesc = 'collapseHeader_DESC',
  HideFooterAsc = 'hideFooter_ASC',
  HideFooterDesc = 'hideFooter_DESC',
  HideGlobalBannerAsc = 'hideGlobalBanner_ASC',
  HideGlobalBannerDesc = 'hideGlobalBanner_DESC',
  JotformIdAsc = 'jotformId_ASC',
  JotformIdDesc = 'jotformId_DESC',
  NoindexNofollowAsc = 'noindexNofollow_ASC',
  NoindexNofollowDesc = 'noindexNofollow_DESC',
  ShowHeaderAsc = 'showHeader_ASC',
  ShowHeaderDesc = 'showHeader_DESC',
  SlugAsc = 'slug_ASC',
  SlugDesc = 'slug_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC',
}

export enum ComponentCtaBannerLinkingCollectionsTemplateAboutUsCollectionOrder {
  CollapseHeaderAsc = 'collapseHeader_ASC',
  CollapseHeaderDesc = 'collapseHeader_DESC',
  HeaderBlockTitleAsc = 'headerBlockTitle_ASC',
  HeaderBlockTitleDesc = 'headerBlockTitle_DESC',
  ShowHeaderAsc = 'showHeader_ASC',
  ShowHeaderDesc = 'showHeader_DESC',
  SubtitleAsc = 'subtitle_ASC',
  SubtitleDesc = 'subtitle_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC',
}

export enum ComponentCtaBannerLinkingCollectionsTemplateBookingCollectionOrder {
  CollapseHeaderAsc = 'collapseHeader_ASC',
  CollapseHeaderDesc = 'collapseHeader_DESC',
  ShowHeaderAsc = 'showHeader_ASC',
  ShowHeaderDesc = 'showHeader_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC',
}

export enum ComponentCtaBannerLinkingCollectionsTemplateCareersCollectionOrder {
  CollapseHeaderAsc = 'collapseHeader_ASC',
  CollapseHeaderDesc = 'collapseHeader_DESC',
  ShowHeaderAsc = 'showHeader_ASC',
  ShowHeaderDesc = 'showHeader_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC',
}

export enum ComponentCtaBannerLinkingCollectionsTemplateContactUsCollectionOrder {
  CollapseHeaderAsc = 'collapseHeader_ASC',
  CollapseHeaderDesc = 'collapseHeader_DESC',
  ShowHeaderAsc = 'showHeader_ASC',
  ShowHeaderDesc = 'showHeader_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC',
}

export enum ComponentCtaBannerLinkingCollectionsTemplateDestinationsCollectionOrder {
  CollapseHeaderAsc = 'collapseHeader_ASC',
  CollapseHeaderDesc = 'collapseHeader_DESC',
  CollapsibleHeadingAsc = 'collapsibleHeading_ASC',
  CollapsibleHeadingDesc = 'collapsibleHeading_DESC',
  ShowHeaderAsc = 'showHeader_ASC',
  ShowHeaderDesc = 'showHeader_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC',
}

export enum ComponentCtaBannerLinkingCollectionsTemplateForaReserveCollectionOrder {
  CollapseHeaderAsc = 'collapseHeader_ASC',
  CollapseHeaderDesc = 'collapseHeader_DESC',
  ShowHeaderAsc = 'showHeader_ASC',
  ShowHeaderDesc = 'showHeader_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC',
}

export enum ComponentCtaBannerLinkingCollectionsTemplateForaXCollectionOrder {
  AdvisorGridHeaderAsc = 'advisorGridHeader_ASC',
  AdvisorGridHeaderDesc = 'advisorGridHeader_DESC',
  AdvisorGridSubCopyAsc = 'advisorGridSubCopy_ASC',
  AdvisorGridSubCopyDesc = 'advisorGridSubCopy_DESC',
  CollapseHeaderAsc = 'collapseHeader_ASC',
  CollapseHeaderDesc = 'collapseHeader_DESC',
  ShowHeaderAsc = 'showHeader_ASC',
  ShowHeaderDesc = 'showHeader_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC',
}

export enum ComponentCtaBannerLinkingCollectionsTemplateHomeCollectionOrder {
  CollapseHeaderAsc = 'collapseHeader_ASC',
  CollapseHeaderDesc = 'collapseHeader_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC',
}

export enum ComponentCtaBannerLinkingCollectionsTemplateJoinUsCollectionOrder {
  CollapseHeaderAsc = 'collapseHeader_ASC',
  CollapseHeaderDesc = 'collapseHeader_DESC',
  HideGlobalBannerAsc = 'hideGlobalBanner_ASC',
  HideGlobalBannerDesc = 'hideGlobalBanner_DESC',
  ShowHeaderAsc = 'showHeader_ASC',
  ShowHeaderDesc = 'showHeader_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC',
}

export enum ComponentCtaBannerLinkingCollectionsTemplateNewsletterCollectionOrder {
  CollapseHeaderAsc = 'collapseHeader_ASC',
  CollapseHeaderDesc = 'collapseHeader_DESC',
  ShowHeaderAsc = 'showHeader_ASC',
  ShowHeaderDesc = 'showHeader_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC',
}

export enum ComponentCtaBannerLinkingCollectionsTemplatePageCollectionOrder {
  DirectoryAsc = 'directory_ASC',
  DirectoryDesc = 'directory_DESC',
  InternalLabelAsc = 'internalLabel_ASC',
  InternalLabelDesc = 'internalLabel_DESC',
  MetaTitleAsc = 'metaTitle_ASC',
  MetaTitleDesc = 'metaTitle_DESC',
  NoindexNofollowAsc = 'noindexNofollow_ASC',
  NoindexNofollowDesc = 'noindexNofollow_DESC',
  SlugAsc = 'slug_ASC',
  SlugDesc = 'slug_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
}

export enum ComponentCtaBannerLinkingCollectionsTemplatePartnersCollectionOrder {
  CollapseHeaderAsc = 'collapseHeader_ASC',
  CollapseHeaderDesc = 'collapseHeader_DESC',
  ShowHeaderAsc = 'showHeader_ASC',
  ShowHeaderDesc = 'showHeader_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC',
}

export enum ComponentCtaBannerLinkingCollectionsTemplateReviewsCollectionOrder {
  CollapseHeaderAsc = 'collapseHeader_ASC',
  CollapseHeaderDesc = 'collapseHeader_DESC',
  ShowHeaderAsc = 'showHeader_ASC',
  ShowHeaderDesc = 'showHeader_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC',
}

export enum ComponentCtaBannerLinkingCollectionsTemplateWhyBookWithForaCollectionOrder {
  CollapseHeaderAsc = 'collapseHeader_ASC',
  CollapseHeaderDesc = 'collapseHeader_DESC',
  ShowHeaderAsc = 'showHeader_ASC',
  ShowHeaderDesc = 'showHeader_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC',
}

export enum ComponentCtaBannerOrder {
  CtaDestinationAsc = 'ctaDestination_ASC',
  CtaDestinationDesc = 'ctaDestination_DESC',
  CtaTextAsc = 'ctaText_ASC',
  CtaTextDesc = 'ctaText_DESC',
  FormTypeAsc = 'formType_ASC',
  FormTypeDesc = 'formType_DESC',
  HeadingAsc = 'heading_ASC',
  HeadingDesc = 'heading_DESC',
  IncludeFormAsc = 'includeForm_ASC',
  IncludeFormDesc = 'includeForm_DESC',
  InternalLabelAsc = 'internalLabel_ASC',
  InternalLabelDesc = 'internalLabel_DESC',
  SectionIdAsc = 'sectionId_ASC',
  SectionIdDesc = 'sectionId_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  TitleStyleAsc = 'titleStyle_ASC',
  TitleStyleDesc = 'titleStyle_DESC',
  UrlTargetAsc = 'urlTarget_ASC',
  UrlTargetDesc = 'urlTarget_DESC',
  ZapierIdAsc = 'zapierId_ASC',
  ZapierIdDesc = 'zapierId_DESC',
}

export type ComponentCtaBannerTeaserCopy = {
  __typename?: 'ComponentCtaBannerTeaserCopy'
  json: Scalars['JSON']
  links: ComponentCtaBannerTeaserCopyLinks
}

export type ComponentCtaBannerTeaserCopyAssets = {
  __typename?: 'ComponentCtaBannerTeaserCopyAssets'
  block: Array<Maybe<Asset>>
  hyperlink: Array<Maybe<Asset>>
}

export type ComponentCtaBannerTeaserCopyEntries = {
  __typename?: 'ComponentCtaBannerTeaserCopyEntries'
  block: Array<Maybe<Entry>>
  hyperlink: Array<Maybe<Entry>>
  inline: Array<Maybe<Entry>>
}

export type ComponentCtaBannerTeaserCopyLinks = {
  __typename?: 'ComponentCtaBannerTeaserCopyLinks'
  assets: ComponentCtaBannerTeaserCopyAssets
  entries: ComponentCtaBannerTeaserCopyEntries
  resources: ComponentCtaBannerTeaserCopyResources
}

export type ComponentCtaBannerTeaserCopyResources = {
  __typename?: 'ComponentCtaBannerTeaserCopyResources'
  block: Array<ComponentCtaBannerTeaserCopyResourcesBlock>
  hyperlink: Array<ComponentCtaBannerTeaserCopyResourcesHyperlink>
  inline: Array<ComponentCtaBannerTeaserCopyResourcesInline>
}

export type ComponentCtaBannerTeaserCopyResourcesBlock = ResourceLink & {
  __typename?: 'ComponentCtaBannerTeaserCopyResourcesBlock'
  sys: ResourceSys
}

export type ComponentCtaBannerTeaserCopyResourcesHyperlink = ResourceLink & {
  __typename?: 'ComponentCtaBannerTeaserCopyResourcesHyperlink'
  sys: ResourceSys
}

export type ComponentCtaBannerTeaserCopyResourcesInline = ResourceLink & {
  __typename?: 'ComponentCtaBannerTeaserCopyResourcesInline'
  sys: ResourceSys
}

/** [See type definition](https://app.contentful.com/spaces/9cxgygtadmvl/content_types/componentDataBanner) */
export type ComponentDataBanner = Entry &
  _Node & {
    __typename?: 'ComponentDataBanner'
    _id: Scalars['ID']
    contentfulMetadata: ContentfulMetadata
    itemsCollection?: Maybe<ComponentDataBannerItemsCollection>
    linkedFrom?: Maybe<ComponentDataBannerLinkingCollections>
    sys: Sys
    title?: Maybe<Scalars['String']>
  }

/** [See type definition](https://app.contentful.com/spaces/9cxgygtadmvl/content_types/componentDataBanner) */
export type ComponentDataBannerItemsCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>
  locale?: InputMaybe<Scalars['String']>
  order?: InputMaybe<Array<InputMaybe<ComponentDataBannerItemsCollectionOrder>>>
  preview?: InputMaybe<Scalars['Boolean']>
  skip?: InputMaybe<Scalars['Int']>
  where?: InputMaybe<ComponentDataItemFilter>
}

/** [See type definition](https://app.contentful.com/spaces/9cxgygtadmvl/content_types/componentDataBanner) */
export type ComponentDataBannerLinkedFromArgs = {
  allowedLocales?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
}

/** [See type definition](https://app.contentful.com/spaces/9cxgygtadmvl/content_types/componentDataBanner) */
export type ComponentDataBannerTitleArgs = {
  locale?: InputMaybe<Scalars['String']>
}

export type ComponentDataBannerCollection = {
  __typename?: 'ComponentDataBannerCollection'
  items: Array<Maybe<ComponentDataBanner>>
  limit: Scalars['Int']
  skip: Scalars['Int']
  total: Scalars['Int']
}

export type ComponentDataBannerFilter = {
  AND?: InputMaybe<Array<InputMaybe<ComponentDataBannerFilter>>>
  OR?: InputMaybe<Array<InputMaybe<ComponentDataBannerFilter>>>
  contentfulMetadata?: InputMaybe<ContentfulMetadataFilter>
  items?: InputMaybe<CfComponentDataItemNestedFilter>
  itemsCollection_exists?: InputMaybe<Scalars['Boolean']>
  sys?: InputMaybe<SysFilter>
  title?: InputMaybe<Scalars['String']>
  title_contains?: InputMaybe<Scalars['String']>
  title_exists?: InputMaybe<Scalars['Boolean']>
  title_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  title_not?: InputMaybe<Scalars['String']>
  title_not_contains?: InputMaybe<Scalars['String']>
  title_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
}

export type ComponentDataBannerItemsCollection = {
  __typename?: 'ComponentDataBannerItemsCollection'
  items: Array<Maybe<ComponentDataItem>>
  limit: Scalars['Int']
  skip: Scalars['Int']
  total: Scalars['Int']
}

export enum ComponentDataBannerItemsCollectionOrder {
  NumberAsc = 'number_ASC',
  NumberDesc = 'number_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC',
}

export type ComponentDataBannerLinkingCollections = {
  __typename?: 'ComponentDataBannerLinkingCollections'
  campaignPagesCollection?: Maybe<CampaignPagesCollection>
  entryCollection?: Maybe<EntryCollection>
  templateAboutUsCollection?: Maybe<TemplateAboutUsCollection>
  templateBookingCollection?: Maybe<TemplateBookingCollection>
  templateCareersCollection?: Maybe<TemplateCareersCollection>
  templateContactUsCollection?: Maybe<TemplateContactUsCollection>
  templateDestinationsCollection?: Maybe<TemplateDestinationsCollection>
  templateForaReserveCollection?: Maybe<TemplateForaReserveCollection>
  templateForaXCollection?: Maybe<TemplateForaXCollection>
  templateHomeCollection?: Maybe<TemplateHomeCollection>
  templateJoinUsCollection?: Maybe<TemplateJoinUsCollection>
  templateNewsletterCollection?: Maybe<TemplateNewsletterCollection>
  templatePartnersCollection?: Maybe<TemplatePartnersCollection>
  templateReviewsCollection?: Maybe<TemplateReviewsCollection>
  templateWhyBookWithForaCollection?: Maybe<TemplateWhyBookWithForaCollection>
}

export type ComponentDataBannerLinkingCollectionsCampaignPagesCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>
  locale?: InputMaybe<Scalars['String']>
  order?: InputMaybe<
    Array<
      InputMaybe<ComponentDataBannerLinkingCollectionsCampaignPagesCollectionOrder>
    >
  >
  preview?: InputMaybe<Scalars['Boolean']>
  skip?: InputMaybe<Scalars['Int']>
}

export type ComponentDataBannerLinkingCollectionsEntryCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>
  locale?: InputMaybe<Scalars['String']>
  preview?: InputMaybe<Scalars['Boolean']>
  skip?: InputMaybe<Scalars['Int']>
}

export type ComponentDataBannerLinkingCollectionsTemplateAboutUsCollectionArgs =
  {
    limit?: InputMaybe<Scalars['Int']>
    locale?: InputMaybe<Scalars['String']>
    order?: InputMaybe<
      Array<
        InputMaybe<ComponentDataBannerLinkingCollectionsTemplateAboutUsCollectionOrder>
      >
    >
    preview?: InputMaybe<Scalars['Boolean']>
    skip?: InputMaybe<Scalars['Int']>
  }

export type ComponentDataBannerLinkingCollectionsTemplateBookingCollectionArgs =
  {
    limit?: InputMaybe<Scalars['Int']>
    locale?: InputMaybe<Scalars['String']>
    order?: InputMaybe<
      Array<
        InputMaybe<ComponentDataBannerLinkingCollectionsTemplateBookingCollectionOrder>
      >
    >
    preview?: InputMaybe<Scalars['Boolean']>
    skip?: InputMaybe<Scalars['Int']>
  }

export type ComponentDataBannerLinkingCollectionsTemplateCareersCollectionArgs =
  {
    limit?: InputMaybe<Scalars['Int']>
    locale?: InputMaybe<Scalars['String']>
    order?: InputMaybe<
      Array<
        InputMaybe<ComponentDataBannerLinkingCollectionsTemplateCareersCollectionOrder>
      >
    >
    preview?: InputMaybe<Scalars['Boolean']>
    skip?: InputMaybe<Scalars['Int']>
  }

export type ComponentDataBannerLinkingCollectionsTemplateContactUsCollectionArgs =
  {
    limit?: InputMaybe<Scalars['Int']>
    locale?: InputMaybe<Scalars['String']>
    order?: InputMaybe<
      Array<
        InputMaybe<ComponentDataBannerLinkingCollectionsTemplateContactUsCollectionOrder>
      >
    >
    preview?: InputMaybe<Scalars['Boolean']>
    skip?: InputMaybe<Scalars['Int']>
  }

export type ComponentDataBannerLinkingCollectionsTemplateDestinationsCollectionArgs =
  {
    limit?: InputMaybe<Scalars['Int']>
    locale?: InputMaybe<Scalars['String']>
    order?: InputMaybe<
      Array<
        InputMaybe<ComponentDataBannerLinkingCollectionsTemplateDestinationsCollectionOrder>
      >
    >
    preview?: InputMaybe<Scalars['Boolean']>
    skip?: InputMaybe<Scalars['Int']>
  }

export type ComponentDataBannerLinkingCollectionsTemplateForaReserveCollectionArgs =
  {
    limit?: InputMaybe<Scalars['Int']>
    locale?: InputMaybe<Scalars['String']>
    order?: InputMaybe<
      Array<
        InputMaybe<ComponentDataBannerLinkingCollectionsTemplateForaReserveCollectionOrder>
      >
    >
    preview?: InputMaybe<Scalars['Boolean']>
    skip?: InputMaybe<Scalars['Int']>
  }

export type ComponentDataBannerLinkingCollectionsTemplateForaXCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>
  locale?: InputMaybe<Scalars['String']>
  order?: InputMaybe<
    Array<
      InputMaybe<ComponentDataBannerLinkingCollectionsTemplateForaXCollectionOrder>
    >
  >
  preview?: InputMaybe<Scalars['Boolean']>
  skip?: InputMaybe<Scalars['Int']>
}

export type ComponentDataBannerLinkingCollectionsTemplateHomeCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>
  locale?: InputMaybe<Scalars['String']>
  order?: InputMaybe<
    Array<
      InputMaybe<ComponentDataBannerLinkingCollectionsTemplateHomeCollectionOrder>
    >
  >
  preview?: InputMaybe<Scalars['Boolean']>
  skip?: InputMaybe<Scalars['Int']>
}

export type ComponentDataBannerLinkingCollectionsTemplateJoinUsCollectionArgs =
  {
    limit?: InputMaybe<Scalars['Int']>
    locale?: InputMaybe<Scalars['String']>
    order?: InputMaybe<
      Array<
        InputMaybe<ComponentDataBannerLinkingCollectionsTemplateJoinUsCollectionOrder>
      >
    >
    preview?: InputMaybe<Scalars['Boolean']>
    skip?: InputMaybe<Scalars['Int']>
  }

export type ComponentDataBannerLinkingCollectionsTemplateNewsletterCollectionArgs =
  {
    limit?: InputMaybe<Scalars['Int']>
    locale?: InputMaybe<Scalars['String']>
    order?: InputMaybe<
      Array<
        InputMaybe<ComponentDataBannerLinkingCollectionsTemplateNewsletterCollectionOrder>
      >
    >
    preview?: InputMaybe<Scalars['Boolean']>
    skip?: InputMaybe<Scalars['Int']>
  }

export type ComponentDataBannerLinkingCollectionsTemplatePartnersCollectionArgs =
  {
    limit?: InputMaybe<Scalars['Int']>
    locale?: InputMaybe<Scalars['String']>
    order?: InputMaybe<
      Array<
        InputMaybe<ComponentDataBannerLinkingCollectionsTemplatePartnersCollectionOrder>
      >
    >
    preview?: InputMaybe<Scalars['Boolean']>
    skip?: InputMaybe<Scalars['Int']>
  }

export type ComponentDataBannerLinkingCollectionsTemplateReviewsCollectionArgs =
  {
    limit?: InputMaybe<Scalars['Int']>
    locale?: InputMaybe<Scalars['String']>
    order?: InputMaybe<
      Array<
        InputMaybe<ComponentDataBannerLinkingCollectionsTemplateReviewsCollectionOrder>
      >
    >
    preview?: InputMaybe<Scalars['Boolean']>
    skip?: InputMaybe<Scalars['Int']>
  }

export type ComponentDataBannerLinkingCollectionsTemplateWhyBookWithForaCollectionArgs =
  {
    limit?: InputMaybe<Scalars['Int']>
    locale?: InputMaybe<Scalars['String']>
    order?: InputMaybe<
      Array<
        InputMaybe<ComponentDataBannerLinkingCollectionsTemplateWhyBookWithForaCollectionOrder>
      >
    >
    preview?: InputMaybe<Scalars['Boolean']>
    skip?: InputMaybe<Scalars['Int']>
  }

export enum ComponentDataBannerLinkingCollectionsCampaignPagesCollectionOrder {
  CollapseFooterAsc = 'collapseFooter_ASC',
  CollapseFooterDesc = 'collapseFooter_DESC',
  CollapseHeaderAsc = 'collapseHeader_ASC',
  CollapseHeaderDesc = 'collapseHeader_DESC',
  HideFooterAsc = 'hideFooter_ASC',
  HideFooterDesc = 'hideFooter_DESC',
  HideGlobalBannerAsc = 'hideGlobalBanner_ASC',
  HideGlobalBannerDesc = 'hideGlobalBanner_DESC',
  JotformIdAsc = 'jotformId_ASC',
  JotformIdDesc = 'jotformId_DESC',
  NoindexNofollowAsc = 'noindexNofollow_ASC',
  NoindexNofollowDesc = 'noindexNofollow_DESC',
  ShowHeaderAsc = 'showHeader_ASC',
  ShowHeaderDesc = 'showHeader_DESC',
  SlugAsc = 'slug_ASC',
  SlugDesc = 'slug_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC',
}

export enum ComponentDataBannerLinkingCollectionsTemplateAboutUsCollectionOrder {
  CollapseHeaderAsc = 'collapseHeader_ASC',
  CollapseHeaderDesc = 'collapseHeader_DESC',
  HeaderBlockTitleAsc = 'headerBlockTitle_ASC',
  HeaderBlockTitleDesc = 'headerBlockTitle_DESC',
  ShowHeaderAsc = 'showHeader_ASC',
  ShowHeaderDesc = 'showHeader_DESC',
  SubtitleAsc = 'subtitle_ASC',
  SubtitleDesc = 'subtitle_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC',
}

export enum ComponentDataBannerLinkingCollectionsTemplateBookingCollectionOrder {
  CollapseHeaderAsc = 'collapseHeader_ASC',
  CollapseHeaderDesc = 'collapseHeader_DESC',
  ShowHeaderAsc = 'showHeader_ASC',
  ShowHeaderDesc = 'showHeader_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC',
}

export enum ComponentDataBannerLinkingCollectionsTemplateCareersCollectionOrder {
  CollapseHeaderAsc = 'collapseHeader_ASC',
  CollapseHeaderDesc = 'collapseHeader_DESC',
  ShowHeaderAsc = 'showHeader_ASC',
  ShowHeaderDesc = 'showHeader_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC',
}

export enum ComponentDataBannerLinkingCollectionsTemplateContactUsCollectionOrder {
  CollapseHeaderAsc = 'collapseHeader_ASC',
  CollapseHeaderDesc = 'collapseHeader_DESC',
  ShowHeaderAsc = 'showHeader_ASC',
  ShowHeaderDesc = 'showHeader_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC',
}

export enum ComponentDataBannerLinkingCollectionsTemplateDestinationsCollectionOrder {
  CollapseHeaderAsc = 'collapseHeader_ASC',
  CollapseHeaderDesc = 'collapseHeader_DESC',
  CollapsibleHeadingAsc = 'collapsibleHeading_ASC',
  CollapsibleHeadingDesc = 'collapsibleHeading_DESC',
  ShowHeaderAsc = 'showHeader_ASC',
  ShowHeaderDesc = 'showHeader_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC',
}

export enum ComponentDataBannerLinkingCollectionsTemplateForaReserveCollectionOrder {
  CollapseHeaderAsc = 'collapseHeader_ASC',
  CollapseHeaderDesc = 'collapseHeader_DESC',
  ShowHeaderAsc = 'showHeader_ASC',
  ShowHeaderDesc = 'showHeader_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC',
}

export enum ComponentDataBannerLinkingCollectionsTemplateForaXCollectionOrder {
  AdvisorGridHeaderAsc = 'advisorGridHeader_ASC',
  AdvisorGridHeaderDesc = 'advisorGridHeader_DESC',
  AdvisorGridSubCopyAsc = 'advisorGridSubCopy_ASC',
  AdvisorGridSubCopyDesc = 'advisorGridSubCopy_DESC',
  CollapseHeaderAsc = 'collapseHeader_ASC',
  CollapseHeaderDesc = 'collapseHeader_DESC',
  ShowHeaderAsc = 'showHeader_ASC',
  ShowHeaderDesc = 'showHeader_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC',
}

export enum ComponentDataBannerLinkingCollectionsTemplateHomeCollectionOrder {
  CollapseHeaderAsc = 'collapseHeader_ASC',
  CollapseHeaderDesc = 'collapseHeader_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC',
}

export enum ComponentDataBannerLinkingCollectionsTemplateJoinUsCollectionOrder {
  CollapseHeaderAsc = 'collapseHeader_ASC',
  CollapseHeaderDesc = 'collapseHeader_DESC',
  HideGlobalBannerAsc = 'hideGlobalBanner_ASC',
  HideGlobalBannerDesc = 'hideGlobalBanner_DESC',
  ShowHeaderAsc = 'showHeader_ASC',
  ShowHeaderDesc = 'showHeader_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC',
}

export enum ComponentDataBannerLinkingCollectionsTemplateNewsletterCollectionOrder {
  CollapseHeaderAsc = 'collapseHeader_ASC',
  CollapseHeaderDesc = 'collapseHeader_DESC',
  ShowHeaderAsc = 'showHeader_ASC',
  ShowHeaderDesc = 'showHeader_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC',
}

export enum ComponentDataBannerLinkingCollectionsTemplatePartnersCollectionOrder {
  CollapseHeaderAsc = 'collapseHeader_ASC',
  CollapseHeaderDesc = 'collapseHeader_DESC',
  ShowHeaderAsc = 'showHeader_ASC',
  ShowHeaderDesc = 'showHeader_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC',
}

export enum ComponentDataBannerLinkingCollectionsTemplateReviewsCollectionOrder {
  CollapseHeaderAsc = 'collapseHeader_ASC',
  CollapseHeaderDesc = 'collapseHeader_DESC',
  ShowHeaderAsc = 'showHeader_ASC',
  ShowHeaderDesc = 'showHeader_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC',
}

export enum ComponentDataBannerLinkingCollectionsTemplateWhyBookWithForaCollectionOrder {
  CollapseHeaderAsc = 'collapseHeader_ASC',
  CollapseHeaderDesc = 'collapseHeader_DESC',
  ShowHeaderAsc = 'showHeader_ASC',
  ShowHeaderDesc = 'showHeader_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC',
}

export enum ComponentDataBannerOrder {
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC',
}

/** [See type definition](https://app.contentful.com/spaces/9cxgygtadmvl/content_types/componentDataItem) */
export type ComponentDataItem = Entry &
  _Node & {
    __typename?: 'ComponentDataItem'
    _id: Scalars['ID']
    contentfulMetadata: ContentfulMetadata
    icon?: Maybe<Asset>
    linkedFrom?: Maybe<ComponentDataItemLinkingCollections>
    number?: Maybe<Scalars['Int']>
    sys: Sys
    title?: Maybe<Scalars['String']>
  }

/** [See type definition](https://app.contentful.com/spaces/9cxgygtadmvl/content_types/componentDataItem) */
export type ComponentDataItemIconArgs = {
  locale?: InputMaybe<Scalars['String']>
  preview?: InputMaybe<Scalars['Boolean']>
}

/** [See type definition](https://app.contentful.com/spaces/9cxgygtadmvl/content_types/componentDataItem) */
export type ComponentDataItemLinkedFromArgs = {
  allowedLocales?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
}

/** [See type definition](https://app.contentful.com/spaces/9cxgygtadmvl/content_types/componentDataItem) */
export type ComponentDataItemNumberArgs = {
  locale?: InputMaybe<Scalars['String']>
}

/** [See type definition](https://app.contentful.com/spaces/9cxgygtadmvl/content_types/componentDataItem) */
export type ComponentDataItemTitleArgs = {
  locale?: InputMaybe<Scalars['String']>
}

export type ComponentDataItemCollection = {
  __typename?: 'ComponentDataItemCollection'
  items: Array<Maybe<ComponentDataItem>>
  limit: Scalars['Int']
  skip: Scalars['Int']
  total: Scalars['Int']
}

export type ComponentDataItemFilter = {
  AND?: InputMaybe<Array<InputMaybe<ComponentDataItemFilter>>>
  OR?: InputMaybe<Array<InputMaybe<ComponentDataItemFilter>>>
  contentfulMetadata?: InputMaybe<ContentfulMetadataFilter>
  icon_exists?: InputMaybe<Scalars['Boolean']>
  number?: InputMaybe<Scalars['Int']>
  number_exists?: InputMaybe<Scalars['Boolean']>
  number_gt?: InputMaybe<Scalars['Int']>
  number_gte?: InputMaybe<Scalars['Int']>
  number_in?: InputMaybe<Array<InputMaybe<Scalars['Int']>>>
  number_lt?: InputMaybe<Scalars['Int']>
  number_lte?: InputMaybe<Scalars['Int']>
  number_not?: InputMaybe<Scalars['Int']>
  number_not_in?: InputMaybe<Array<InputMaybe<Scalars['Int']>>>
  sys?: InputMaybe<SysFilter>
  title?: InputMaybe<Scalars['String']>
  title_contains?: InputMaybe<Scalars['String']>
  title_exists?: InputMaybe<Scalars['Boolean']>
  title_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  title_not?: InputMaybe<Scalars['String']>
  title_not_contains?: InputMaybe<Scalars['String']>
  title_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
}

export type ComponentDataItemLinkingCollections = {
  __typename?: 'ComponentDataItemLinkingCollections'
  componentDataBannerCollection?: Maybe<ComponentDataBannerCollection>
  entryCollection?: Maybe<EntryCollection>
}

export type ComponentDataItemLinkingCollectionsComponentDataBannerCollectionArgs =
  {
    limit?: InputMaybe<Scalars['Int']>
    locale?: InputMaybe<Scalars['String']>
    order?: InputMaybe<
      Array<
        InputMaybe<ComponentDataItemLinkingCollectionsComponentDataBannerCollectionOrder>
      >
    >
    preview?: InputMaybe<Scalars['Boolean']>
    skip?: InputMaybe<Scalars['Int']>
  }

export type ComponentDataItemLinkingCollectionsEntryCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>
  locale?: InputMaybe<Scalars['String']>
  preview?: InputMaybe<Scalars['Boolean']>
  skip?: InputMaybe<Scalars['Int']>
}

export enum ComponentDataItemLinkingCollectionsComponentDataBannerCollectionOrder {
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC',
}

export enum ComponentDataItemOrder {
  NumberAsc = 'number_ASC',
  NumberDesc = 'number_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC',
}

/** A page section with teaser copy followed by a curate grid of featured advisors. [See type definition](https://app.contentful.com/spaces/9cxgygtadmvl/content_types/componentFeaturedAdvisors) */
export type ComponentFeaturedAdvisors = Entry &
  _Node & {
    __typename?: 'ComponentFeaturedAdvisors'
    _id: Scalars['ID']
    advisorsCollection?: Maybe<ComponentFeaturedAdvisorsAdvisorsCollection>
    analyticsModule?: Maybe<SettingsAnalyticsModule>
    contentfulMetadata: ContentfulMetadata
    ctaTarget?: Maybe<Scalars['String']>
    ctaText?: Maybe<Scalars['String']>
    internalLabel?: Maybe<Scalars['String']>
    linkedFrom?: Maybe<ComponentFeaturedAdvisorsLinkingCollections>
    sectionId?: Maybe<Scalars['String']>
    showCta?: Maybe<Scalars['Boolean']>
    sys: Sys
    teaserCopy?: Maybe<ComponentFeaturedAdvisorsTeaserCopy>
    title?: Maybe<Scalars['String']>
    titleStyle?: Maybe<Scalars['String']>
  }

/** A page section with teaser copy followed by a curate grid of featured advisors. [See type definition](https://app.contentful.com/spaces/9cxgygtadmvl/content_types/componentFeaturedAdvisors) */
export type ComponentFeaturedAdvisorsAdvisorsCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>
  locale?: InputMaybe<Scalars['String']>
  order?: InputMaybe<
    Array<InputMaybe<ComponentFeaturedAdvisorsAdvisorsCollectionOrder>>
  >
  preview?: InputMaybe<Scalars['Boolean']>
  skip?: InputMaybe<Scalars['Int']>
  where?: InputMaybe<AdvisorsFilter>
}

/** A page section with teaser copy followed by a curate grid of featured advisors. [See type definition](https://app.contentful.com/spaces/9cxgygtadmvl/content_types/componentFeaturedAdvisors) */
export type ComponentFeaturedAdvisorsAnalyticsModuleArgs = {
  locale?: InputMaybe<Scalars['String']>
  preview?: InputMaybe<Scalars['Boolean']>
  where?: InputMaybe<SettingsAnalyticsModuleFilter>
}

/** A page section with teaser copy followed by a curate grid of featured advisors. [See type definition](https://app.contentful.com/spaces/9cxgygtadmvl/content_types/componentFeaturedAdvisors) */
export type ComponentFeaturedAdvisorsCtaTargetArgs = {
  locale?: InputMaybe<Scalars['String']>
}

/** A page section with teaser copy followed by a curate grid of featured advisors. [See type definition](https://app.contentful.com/spaces/9cxgygtadmvl/content_types/componentFeaturedAdvisors) */
export type ComponentFeaturedAdvisorsCtaTextArgs = {
  locale?: InputMaybe<Scalars['String']>
}

/** A page section with teaser copy followed by a curate grid of featured advisors. [See type definition](https://app.contentful.com/spaces/9cxgygtadmvl/content_types/componentFeaturedAdvisors) */
export type ComponentFeaturedAdvisorsInternalLabelArgs = {
  locale?: InputMaybe<Scalars['String']>
}

/** A page section with teaser copy followed by a curate grid of featured advisors. [See type definition](https://app.contentful.com/spaces/9cxgygtadmvl/content_types/componentFeaturedAdvisors) */
export type ComponentFeaturedAdvisorsLinkedFromArgs = {
  allowedLocales?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
}

/** A page section with teaser copy followed by a curate grid of featured advisors. [See type definition](https://app.contentful.com/spaces/9cxgygtadmvl/content_types/componentFeaturedAdvisors) */
export type ComponentFeaturedAdvisorsSectionIdArgs = {
  locale?: InputMaybe<Scalars['String']>
}

/** A page section with teaser copy followed by a curate grid of featured advisors. [See type definition](https://app.contentful.com/spaces/9cxgygtadmvl/content_types/componentFeaturedAdvisors) */
export type ComponentFeaturedAdvisorsShowCtaArgs = {
  locale?: InputMaybe<Scalars['String']>
}

/** A page section with teaser copy followed by a curate grid of featured advisors. [See type definition](https://app.contentful.com/spaces/9cxgygtadmvl/content_types/componentFeaturedAdvisors) */
export type ComponentFeaturedAdvisorsTeaserCopyArgs = {
  locale?: InputMaybe<Scalars['String']>
}

/** A page section with teaser copy followed by a curate grid of featured advisors. [See type definition](https://app.contentful.com/spaces/9cxgygtadmvl/content_types/componentFeaturedAdvisors) */
export type ComponentFeaturedAdvisorsTitleArgs = {
  locale?: InputMaybe<Scalars['String']>
}

/** A page section with teaser copy followed by a curate grid of featured advisors. [See type definition](https://app.contentful.com/spaces/9cxgygtadmvl/content_types/componentFeaturedAdvisors) */
export type ComponentFeaturedAdvisorsTitleStyleArgs = {
  locale?: InputMaybe<Scalars['String']>
}

export type ComponentFeaturedAdvisorsAdvisorsCollection = {
  __typename?: 'ComponentFeaturedAdvisorsAdvisorsCollection'
  items: Array<Maybe<Advisors>>
  limit: Scalars['Int']
  skip: Scalars['Int']
  total: Scalars['Int']
}

export enum ComponentFeaturedAdvisorsAdvisorsCollectionOrder {
  AdvisorContactEnabledAsc = 'advisorContactEnabled_ASC',
  AdvisorContactEnabledDesc = 'advisorContactEnabled_DESC',
  AdvisorVariantAsc = 'advisorVariant_ASC',
  AdvisorVariantDesc = 'advisorVariant_DESC',
  EmailAddressAsc = 'emailAddress_ASC',
  EmailAddressDesc = 'emailAddress_DESC',
  FacebookUrlAsc = 'facebookUrl_ASC',
  FacebookUrlDesc = 'facebookUrl_DESC',
  FirstNameAsc = 'firstName_ASC',
  FirstNameDesc = 'firstName_DESC',
  ForaAdvisorHotelsAsc = 'foraAdvisorHotels_ASC',
  ForaAdvisorHotelsDesc = 'foraAdvisorHotels_DESC',
  ForaAdvisorIdAsc = 'foraAdvisorId_ASC',
  ForaAdvisorIdDesc = 'foraAdvisorId_DESC',
  InstagramUrlAsc = 'instagramUrl_ASC',
  InstagramUrlDesc = 'instagramUrl_DESC',
  LastNameAsc = 'lastName_ASC',
  LastNameDesc = 'lastName_DESC',
  LinkedinUrlAsc = 'linkedinUrl_ASC',
  LinkedinUrlDesc = 'linkedinUrl_DESC',
  MinimumBookingAsc = 'minimumBooking_ASC',
  MinimumBookingDesc = 'minimumBooking_DESC',
  PinterestUrlAsc = 'pinterestUrl_ASC',
  PinterestUrlDesc = 'pinterestUrl_DESC',
  PronounsAsc = 'pronouns_ASC',
  PronounsDesc = 'pronouns_DESC',
  ShortDescriptionAsc = 'shortDescription_ASC',
  ShortDescriptionDesc = 'shortDescription_DESC',
  ShowOnAdvisorsPageAsc = 'showOnAdvisorsPage_ASC',
  ShowOnAdvisorsPageDesc = 'showOnAdvisorsPage_DESC',
  ShowOnHomepageAsc = 'showOnHomepage_ASC',
  ShowOnHomepageDesc = 'showOnHomepage_DESC',
  ShowOnOtherLandingPagesAsc = 'showOnOtherLandingPages_ASC',
  ShowOnOtherLandingPagesDesc = 'showOnOtherLandingPages_DESC',
  SlugAsc = 'slug_ASC',
  SlugDesc = 'slug_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  TierAsc = 'tier_ASC',
  TierDesc = 'tier_DESC',
  TikTokUrlAsc = 'tikTokUrl_ASC',
  TikTokUrlDesc = 'tikTokUrl_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC',
  TwitterUrlAsc = 'twitterUrl_ASC',
  TwitterUrlDesc = 'twitterUrl_DESC',
  WebsiteUrlAsc = 'websiteUrl_ASC',
  WebsiteUrlDesc = 'websiteUrl_DESC',
  YoutubeUrlAsc = 'youtubeUrl_ASC',
  YoutubeUrlDesc = 'youtubeUrl_DESC',
}

export type ComponentFeaturedAdvisorsCollection = {
  __typename?: 'ComponentFeaturedAdvisorsCollection'
  items: Array<Maybe<ComponentFeaturedAdvisors>>
  limit: Scalars['Int']
  skip: Scalars['Int']
  total: Scalars['Int']
}

export type ComponentFeaturedAdvisorsFilter = {
  AND?: InputMaybe<Array<InputMaybe<ComponentFeaturedAdvisorsFilter>>>
  OR?: InputMaybe<Array<InputMaybe<ComponentFeaturedAdvisorsFilter>>>
  advisors?: InputMaybe<CfAdvisorsNestedFilter>
  advisorsCollection_exists?: InputMaybe<Scalars['Boolean']>
  analyticsModule?: InputMaybe<CfSettingsAnalyticsModuleNestedFilter>
  analyticsModule_exists?: InputMaybe<Scalars['Boolean']>
  contentfulMetadata?: InputMaybe<ContentfulMetadataFilter>
  ctaTarget?: InputMaybe<Scalars['String']>
  ctaTarget_contains?: InputMaybe<Scalars['String']>
  ctaTarget_exists?: InputMaybe<Scalars['Boolean']>
  ctaTarget_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  ctaTarget_not?: InputMaybe<Scalars['String']>
  ctaTarget_not_contains?: InputMaybe<Scalars['String']>
  ctaTarget_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  ctaText?: InputMaybe<Scalars['String']>
  ctaText_contains?: InputMaybe<Scalars['String']>
  ctaText_exists?: InputMaybe<Scalars['Boolean']>
  ctaText_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  ctaText_not?: InputMaybe<Scalars['String']>
  ctaText_not_contains?: InputMaybe<Scalars['String']>
  ctaText_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  internalLabel?: InputMaybe<Scalars['String']>
  internalLabel_contains?: InputMaybe<Scalars['String']>
  internalLabel_exists?: InputMaybe<Scalars['Boolean']>
  internalLabel_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  internalLabel_not?: InputMaybe<Scalars['String']>
  internalLabel_not_contains?: InputMaybe<Scalars['String']>
  internalLabel_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  sectionId?: InputMaybe<Scalars['String']>
  sectionId_contains?: InputMaybe<Scalars['String']>
  sectionId_exists?: InputMaybe<Scalars['Boolean']>
  sectionId_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  sectionId_not?: InputMaybe<Scalars['String']>
  sectionId_not_contains?: InputMaybe<Scalars['String']>
  sectionId_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  showCta?: InputMaybe<Scalars['Boolean']>
  showCta_exists?: InputMaybe<Scalars['Boolean']>
  showCta_not?: InputMaybe<Scalars['Boolean']>
  sys?: InputMaybe<SysFilter>
  teaserCopy_contains?: InputMaybe<Scalars['String']>
  teaserCopy_exists?: InputMaybe<Scalars['Boolean']>
  teaserCopy_not_contains?: InputMaybe<Scalars['String']>
  title?: InputMaybe<Scalars['String']>
  titleStyle?: InputMaybe<Scalars['String']>
  titleStyle_contains?: InputMaybe<Scalars['String']>
  titleStyle_exists?: InputMaybe<Scalars['Boolean']>
  titleStyle_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  titleStyle_not?: InputMaybe<Scalars['String']>
  titleStyle_not_contains?: InputMaybe<Scalars['String']>
  titleStyle_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  title_contains?: InputMaybe<Scalars['String']>
  title_exists?: InputMaybe<Scalars['Boolean']>
  title_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  title_not?: InputMaybe<Scalars['String']>
  title_not_contains?: InputMaybe<Scalars['String']>
  title_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
}

export type ComponentFeaturedAdvisorsLinkingCollections = {
  __typename?: 'ComponentFeaturedAdvisorsLinkingCollections'
  campaignPagesCollection?: Maybe<CampaignPagesCollection>
  entryCollection?: Maybe<EntryCollection>
  templateAboutUsCollection?: Maybe<TemplateAboutUsCollection>
  templateBookingCollection?: Maybe<TemplateBookingCollection>
  templateCareersCollection?: Maybe<TemplateCareersCollection>
  templateContactUsCollection?: Maybe<TemplateContactUsCollection>
  templateDestinationsCollection?: Maybe<TemplateDestinationsCollection>
  templateForaReserveCollection?: Maybe<TemplateForaReserveCollection>
  templateForaXCollection?: Maybe<TemplateForaXCollection>
  templateHomeCollection?: Maybe<TemplateHomeCollection>
  templateJoinUsCollection?: Maybe<TemplateJoinUsCollection>
  templateNewsletterCollection?: Maybe<TemplateNewsletterCollection>
  templatePageCollection?: Maybe<TemplatePageCollection>
  templatePartnersCollection?: Maybe<TemplatePartnersCollection>
  templateReviewsCollection?: Maybe<TemplateReviewsCollection>
  templateWhyBookWithForaCollection?: Maybe<TemplateWhyBookWithForaCollection>
}

export type ComponentFeaturedAdvisorsLinkingCollectionsCampaignPagesCollectionArgs =
  {
    limit?: InputMaybe<Scalars['Int']>
    locale?: InputMaybe<Scalars['String']>
    order?: InputMaybe<
      Array<
        InputMaybe<ComponentFeaturedAdvisorsLinkingCollectionsCampaignPagesCollectionOrder>
      >
    >
    preview?: InputMaybe<Scalars['Boolean']>
    skip?: InputMaybe<Scalars['Int']>
  }

export type ComponentFeaturedAdvisorsLinkingCollectionsEntryCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>
  locale?: InputMaybe<Scalars['String']>
  preview?: InputMaybe<Scalars['Boolean']>
  skip?: InputMaybe<Scalars['Int']>
}

export type ComponentFeaturedAdvisorsLinkingCollectionsTemplateAboutUsCollectionArgs =
  {
    limit?: InputMaybe<Scalars['Int']>
    locale?: InputMaybe<Scalars['String']>
    order?: InputMaybe<
      Array<
        InputMaybe<ComponentFeaturedAdvisorsLinkingCollectionsTemplateAboutUsCollectionOrder>
      >
    >
    preview?: InputMaybe<Scalars['Boolean']>
    skip?: InputMaybe<Scalars['Int']>
  }

export type ComponentFeaturedAdvisorsLinkingCollectionsTemplateBookingCollectionArgs =
  {
    limit?: InputMaybe<Scalars['Int']>
    locale?: InputMaybe<Scalars['String']>
    order?: InputMaybe<
      Array<
        InputMaybe<ComponentFeaturedAdvisorsLinkingCollectionsTemplateBookingCollectionOrder>
      >
    >
    preview?: InputMaybe<Scalars['Boolean']>
    skip?: InputMaybe<Scalars['Int']>
  }

export type ComponentFeaturedAdvisorsLinkingCollectionsTemplateCareersCollectionArgs =
  {
    limit?: InputMaybe<Scalars['Int']>
    locale?: InputMaybe<Scalars['String']>
    order?: InputMaybe<
      Array<
        InputMaybe<ComponentFeaturedAdvisorsLinkingCollectionsTemplateCareersCollectionOrder>
      >
    >
    preview?: InputMaybe<Scalars['Boolean']>
    skip?: InputMaybe<Scalars['Int']>
  }

export type ComponentFeaturedAdvisorsLinkingCollectionsTemplateContactUsCollectionArgs =
  {
    limit?: InputMaybe<Scalars['Int']>
    locale?: InputMaybe<Scalars['String']>
    order?: InputMaybe<
      Array<
        InputMaybe<ComponentFeaturedAdvisorsLinkingCollectionsTemplateContactUsCollectionOrder>
      >
    >
    preview?: InputMaybe<Scalars['Boolean']>
    skip?: InputMaybe<Scalars['Int']>
  }

export type ComponentFeaturedAdvisorsLinkingCollectionsTemplateDestinationsCollectionArgs =
  {
    limit?: InputMaybe<Scalars['Int']>
    locale?: InputMaybe<Scalars['String']>
    order?: InputMaybe<
      Array<
        InputMaybe<ComponentFeaturedAdvisorsLinkingCollectionsTemplateDestinationsCollectionOrder>
      >
    >
    preview?: InputMaybe<Scalars['Boolean']>
    skip?: InputMaybe<Scalars['Int']>
  }

export type ComponentFeaturedAdvisorsLinkingCollectionsTemplateForaReserveCollectionArgs =
  {
    limit?: InputMaybe<Scalars['Int']>
    locale?: InputMaybe<Scalars['String']>
    order?: InputMaybe<
      Array<
        InputMaybe<ComponentFeaturedAdvisorsLinkingCollectionsTemplateForaReserveCollectionOrder>
      >
    >
    preview?: InputMaybe<Scalars['Boolean']>
    skip?: InputMaybe<Scalars['Int']>
  }

export type ComponentFeaturedAdvisorsLinkingCollectionsTemplateForaXCollectionArgs =
  {
    limit?: InputMaybe<Scalars['Int']>
    locale?: InputMaybe<Scalars['String']>
    order?: InputMaybe<
      Array<
        InputMaybe<ComponentFeaturedAdvisorsLinkingCollectionsTemplateForaXCollectionOrder>
      >
    >
    preview?: InputMaybe<Scalars['Boolean']>
    skip?: InputMaybe<Scalars['Int']>
  }

export type ComponentFeaturedAdvisorsLinkingCollectionsTemplateHomeCollectionArgs =
  {
    limit?: InputMaybe<Scalars['Int']>
    locale?: InputMaybe<Scalars['String']>
    order?: InputMaybe<
      Array<
        InputMaybe<ComponentFeaturedAdvisorsLinkingCollectionsTemplateHomeCollectionOrder>
      >
    >
    preview?: InputMaybe<Scalars['Boolean']>
    skip?: InputMaybe<Scalars['Int']>
  }

export type ComponentFeaturedAdvisorsLinkingCollectionsTemplateJoinUsCollectionArgs =
  {
    limit?: InputMaybe<Scalars['Int']>
    locale?: InputMaybe<Scalars['String']>
    order?: InputMaybe<
      Array<
        InputMaybe<ComponentFeaturedAdvisorsLinkingCollectionsTemplateJoinUsCollectionOrder>
      >
    >
    preview?: InputMaybe<Scalars['Boolean']>
    skip?: InputMaybe<Scalars['Int']>
  }

export type ComponentFeaturedAdvisorsLinkingCollectionsTemplateNewsletterCollectionArgs =
  {
    limit?: InputMaybe<Scalars['Int']>
    locale?: InputMaybe<Scalars['String']>
    order?: InputMaybe<
      Array<
        InputMaybe<ComponentFeaturedAdvisorsLinkingCollectionsTemplateNewsletterCollectionOrder>
      >
    >
    preview?: InputMaybe<Scalars['Boolean']>
    skip?: InputMaybe<Scalars['Int']>
  }

export type ComponentFeaturedAdvisorsLinkingCollectionsTemplatePageCollectionArgs =
  {
    limit?: InputMaybe<Scalars['Int']>
    locale?: InputMaybe<Scalars['String']>
    order?: InputMaybe<
      Array<
        InputMaybe<ComponentFeaturedAdvisorsLinkingCollectionsTemplatePageCollectionOrder>
      >
    >
    preview?: InputMaybe<Scalars['Boolean']>
    skip?: InputMaybe<Scalars['Int']>
  }

export type ComponentFeaturedAdvisorsLinkingCollectionsTemplatePartnersCollectionArgs =
  {
    limit?: InputMaybe<Scalars['Int']>
    locale?: InputMaybe<Scalars['String']>
    order?: InputMaybe<
      Array<
        InputMaybe<ComponentFeaturedAdvisorsLinkingCollectionsTemplatePartnersCollectionOrder>
      >
    >
    preview?: InputMaybe<Scalars['Boolean']>
    skip?: InputMaybe<Scalars['Int']>
  }

export type ComponentFeaturedAdvisorsLinkingCollectionsTemplateReviewsCollectionArgs =
  {
    limit?: InputMaybe<Scalars['Int']>
    locale?: InputMaybe<Scalars['String']>
    order?: InputMaybe<
      Array<
        InputMaybe<ComponentFeaturedAdvisorsLinkingCollectionsTemplateReviewsCollectionOrder>
      >
    >
    preview?: InputMaybe<Scalars['Boolean']>
    skip?: InputMaybe<Scalars['Int']>
  }

export type ComponentFeaturedAdvisorsLinkingCollectionsTemplateWhyBookWithForaCollectionArgs =
  {
    limit?: InputMaybe<Scalars['Int']>
    locale?: InputMaybe<Scalars['String']>
    order?: InputMaybe<
      Array<
        InputMaybe<ComponentFeaturedAdvisorsLinkingCollectionsTemplateWhyBookWithForaCollectionOrder>
      >
    >
    preview?: InputMaybe<Scalars['Boolean']>
    skip?: InputMaybe<Scalars['Int']>
  }

export enum ComponentFeaturedAdvisorsLinkingCollectionsCampaignPagesCollectionOrder {
  CollapseFooterAsc = 'collapseFooter_ASC',
  CollapseFooterDesc = 'collapseFooter_DESC',
  CollapseHeaderAsc = 'collapseHeader_ASC',
  CollapseHeaderDesc = 'collapseHeader_DESC',
  HideFooterAsc = 'hideFooter_ASC',
  HideFooterDesc = 'hideFooter_DESC',
  HideGlobalBannerAsc = 'hideGlobalBanner_ASC',
  HideGlobalBannerDesc = 'hideGlobalBanner_DESC',
  JotformIdAsc = 'jotformId_ASC',
  JotformIdDesc = 'jotformId_DESC',
  NoindexNofollowAsc = 'noindexNofollow_ASC',
  NoindexNofollowDesc = 'noindexNofollow_DESC',
  ShowHeaderAsc = 'showHeader_ASC',
  ShowHeaderDesc = 'showHeader_DESC',
  SlugAsc = 'slug_ASC',
  SlugDesc = 'slug_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC',
}

export enum ComponentFeaturedAdvisorsLinkingCollectionsTemplateAboutUsCollectionOrder {
  CollapseHeaderAsc = 'collapseHeader_ASC',
  CollapseHeaderDesc = 'collapseHeader_DESC',
  HeaderBlockTitleAsc = 'headerBlockTitle_ASC',
  HeaderBlockTitleDesc = 'headerBlockTitle_DESC',
  ShowHeaderAsc = 'showHeader_ASC',
  ShowHeaderDesc = 'showHeader_DESC',
  SubtitleAsc = 'subtitle_ASC',
  SubtitleDesc = 'subtitle_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC',
}

export enum ComponentFeaturedAdvisorsLinkingCollectionsTemplateBookingCollectionOrder {
  CollapseHeaderAsc = 'collapseHeader_ASC',
  CollapseHeaderDesc = 'collapseHeader_DESC',
  ShowHeaderAsc = 'showHeader_ASC',
  ShowHeaderDesc = 'showHeader_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC',
}

export enum ComponentFeaturedAdvisorsLinkingCollectionsTemplateCareersCollectionOrder {
  CollapseHeaderAsc = 'collapseHeader_ASC',
  CollapseHeaderDesc = 'collapseHeader_DESC',
  ShowHeaderAsc = 'showHeader_ASC',
  ShowHeaderDesc = 'showHeader_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC',
}

export enum ComponentFeaturedAdvisorsLinkingCollectionsTemplateContactUsCollectionOrder {
  CollapseHeaderAsc = 'collapseHeader_ASC',
  CollapseHeaderDesc = 'collapseHeader_DESC',
  ShowHeaderAsc = 'showHeader_ASC',
  ShowHeaderDesc = 'showHeader_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC',
}

export enum ComponentFeaturedAdvisorsLinkingCollectionsTemplateDestinationsCollectionOrder {
  CollapseHeaderAsc = 'collapseHeader_ASC',
  CollapseHeaderDesc = 'collapseHeader_DESC',
  CollapsibleHeadingAsc = 'collapsibleHeading_ASC',
  CollapsibleHeadingDesc = 'collapsibleHeading_DESC',
  ShowHeaderAsc = 'showHeader_ASC',
  ShowHeaderDesc = 'showHeader_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC',
}

export enum ComponentFeaturedAdvisorsLinkingCollectionsTemplateForaReserveCollectionOrder {
  CollapseHeaderAsc = 'collapseHeader_ASC',
  CollapseHeaderDesc = 'collapseHeader_DESC',
  ShowHeaderAsc = 'showHeader_ASC',
  ShowHeaderDesc = 'showHeader_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC',
}

export enum ComponentFeaturedAdvisorsLinkingCollectionsTemplateForaXCollectionOrder {
  AdvisorGridHeaderAsc = 'advisorGridHeader_ASC',
  AdvisorGridHeaderDesc = 'advisorGridHeader_DESC',
  AdvisorGridSubCopyAsc = 'advisorGridSubCopy_ASC',
  AdvisorGridSubCopyDesc = 'advisorGridSubCopy_DESC',
  CollapseHeaderAsc = 'collapseHeader_ASC',
  CollapseHeaderDesc = 'collapseHeader_DESC',
  ShowHeaderAsc = 'showHeader_ASC',
  ShowHeaderDesc = 'showHeader_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC',
}

export enum ComponentFeaturedAdvisorsLinkingCollectionsTemplateHomeCollectionOrder {
  CollapseHeaderAsc = 'collapseHeader_ASC',
  CollapseHeaderDesc = 'collapseHeader_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC',
}

export enum ComponentFeaturedAdvisorsLinkingCollectionsTemplateJoinUsCollectionOrder {
  CollapseHeaderAsc = 'collapseHeader_ASC',
  CollapseHeaderDesc = 'collapseHeader_DESC',
  HideGlobalBannerAsc = 'hideGlobalBanner_ASC',
  HideGlobalBannerDesc = 'hideGlobalBanner_DESC',
  ShowHeaderAsc = 'showHeader_ASC',
  ShowHeaderDesc = 'showHeader_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC',
}

export enum ComponentFeaturedAdvisorsLinkingCollectionsTemplateNewsletterCollectionOrder {
  CollapseHeaderAsc = 'collapseHeader_ASC',
  CollapseHeaderDesc = 'collapseHeader_DESC',
  ShowHeaderAsc = 'showHeader_ASC',
  ShowHeaderDesc = 'showHeader_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC',
}

export enum ComponentFeaturedAdvisorsLinkingCollectionsTemplatePageCollectionOrder {
  DirectoryAsc = 'directory_ASC',
  DirectoryDesc = 'directory_DESC',
  InternalLabelAsc = 'internalLabel_ASC',
  InternalLabelDesc = 'internalLabel_DESC',
  MetaTitleAsc = 'metaTitle_ASC',
  MetaTitleDesc = 'metaTitle_DESC',
  NoindexNofollowAsc = 'noindexNofollow_ASC',
  NoindexNofollowDesc = 'noindexNofollow_DESC',
  SlugAsc = 'slug_ASC',
  SlugDesc = 'slug_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
}

export enum ComponentFeaturedAdvisorsLinkingCollectionsTemplatePartnersCollectionOrder {
  CollapseHeaderAsc = 'collapseHeader_ASC',
  CollapseHeaderDesc = 'collapseHeader_DESC',
  ShowHeaderAsc = 'showHeader_ASC',
  ShowHeaderDesc = 'showHeader_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC',
}

export enum ComponentFeaturedAdvisorsLinkingCollectionsTemplateReviewsCollectionOrder {
  CollapseHeaderAsc = 'collapseHeader_ASC',
  CollapseHeaderDesc = 'collapseHeader_DESC',
  ShowHeaderAsc = 'showHeader_ASC',
  ShowHeaderDesc = 'showHeader_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC',
}

export enum ComponentFeaturedAdvisorsLinkingCollectionsTemplateWhyBookWithForaCollectionOrder {
  CollapseHeaderAsc = 'collapseHeader_ASC',
  CollapseHeaderDesc = 'collapseHeader_DESC',
  ShowHeaderAsc = 'showHeader_ASC',
  ShowHeaderDesc = 'showHeader_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC',
}

export enum ComponentFeaturedAdvisorsOrder {
  CtaTargetAsc = 'ctaTarget_ASC',
  CtaTargetDesc = 'ctaTarget_DESC',
  CtaTextAsc = 'ctaText_ASC',
  CtaTextDesc = 'ctaText_DESC',
  InternalLabelAsc = 'internalLabel_ASC',
  InternalLabelDesc = 'internalLabel_DESC',
  SectionIdAsc = 'sectionId_ASC',
  SectionIdDesc = 'sectionId_DESC',
  ShowCtaAsc = 'showCta_ASC',
  ShowCtaDesc = 'showCta_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  TitleStyleAsc = 'titleStyle_ASC',
  TitleStyleDesc = 'titleStyle_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC',
}

export type ComponentFeaturedAdvisorsTeaserCopy = {
  __typename?: 'ComponentFeaturedAdvisorsTeaserCopy'
  json: Scalars['JSON']
  links: ComponentFeaturedAdvisorsTeaserCopyLinks
}

export type ComponentFeaturedAdvisorsTeaserCopyAssets = {
  __typename?: 'ComponentFeaturedAdvisorsTeaserCopyAssets'
  block: Array<Maybe<Asset>>
  hyperlink: Array<Maybe<Asset>>
}

export type ComponentFeaturedAdvisorsTeaserCopyEntries = {
  __typename?: 'ComponentFeaturedAdvisorsTeaserCopyEntries'
  block: Array<Maybe<Entry>>
  hyperlink: Array<Maybe<Entry>>
  inline: Array<Maybe<Entry>>
}

export type ComponentFeaturedAdvisorsTeaserCopyLinks = {
  __typename?: 'ComponentFeaturedAdvisorsTeaserCopyLinks'
  assets: ComponentFeaturedAdvisorsTeaserCopyAssets
  entries: ComponentFeaturedAdvisorsTeaserCopyEntries
  resources: ComponentFeaturedAdvisorsTeaserCopyResources
}

export type ComponentFeaturedAdvisorsTeaserCopyResources = {
  __typename?: 'ComponentFeaturedAdvisorsTeaserCopyResources'
  block: Array<ComponentFeaturedAdvisorsTeaserCopyResourcesBlock>
  hyperlink: Array<ComponentFeaturedAdvisorsTeaserCopyResourcesHyperlink>
  inline: Array<ComponentFeaturedAdvisorsTeaserCopyResourcesInline>
}

export type ComponentFeaturedAdvisorsTeaserCopyResourcesBlock = ResourceLink & {
  __typename?: 'ComponentFeaturedAdvisorsTeaserCopyResourcesBlock'
  sys: ResourceSys
}

export type ComponentFeaturedAdvisorsTeaserCopyResourcesHyperlink =
  ResourceLink & {
    __typename?: 'ComponentFeaturedAdvisorsTeaserCopyResourcesHyperlink'
    sys: ResourceSys
  }

export type ComponentFeaturedAdvisorsTeaserCopyResourcesInline =
  ResourceLink & {
    __typename?: 'ComponentFeaturedAdvisorsTeaserCopyResourcesInline'
    sys: ResourceSys
  }

/** [See type definition](https://app.contentful.com/spaces/9cxgygtadmvl/content_types/componentFeaturedArticles) */
export type ComponentFeaturedArticles = Entry &
  _Node & {
    __typename?: 'ComponentFeaturedArticles'
    _id: Scalars['ID']
    articleCategory?: Maybe<Scalars['String']>
    contentfulMetadata: ContentfulMetadata
    internalLabel?: Maybe<Scalars['String']>
    linkedFrom?: Maybe<ComponentFeaturedArticlesLinkingCollections>
    sys: Sys
  }

/** [See type definition](https://app.contentful.com/spaces/9cxgygtadmvl/content_types/componentFeaturedArticles) */
export type ComponentFeaturedArticlesArticleCategoryArgs = {
  locale?: InputMaybe<Scalars['String']>
}

/** [See type definition](https://app.contentful.com/spaces/9cxgygtadmvl/content_types/componentFeaturedArticles) */
export type ComponentFeaturedArticlesInternalLabelArgs = {
  locale?: InputMaybe<Scalars['String']>
}

/** [See type definition](https://app.contentful.com/spaces/9cxgygtadmvl/content_types/componentFeaturedArticles) */
export type ComponentFeaturedArticlesLinkedFromArgs = {
  allowedLocales?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
}

export type ComponentFeaturedArticlesCollection = {
  __typename?: 'ComponentFeaturedArticlesCollection'
  items: Array<Maybe<ComponentFeaturedArticles>>
  limit: Scalars['Int']
  skip: Scalars['Int']
  total: Scalars['Int']
}

export type ComponentFeaturedArticlesFilter = {
  AND?: InputMaybe<Array<InputMaybe<ComponentFeaturedArticlesFilter>>>
  OR?: InputMaybe<Array<InputMaybe<ComponentFeaturedArticlesFilter>>>
  articleCategory?: InputMaybe<Scalars['String']>
  articleCategory_contains?: InputMaybe<Scalars['String']>
  articleCategory_exists?: InputMaybe<Scalars['Boolean']>
  articleCategory_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  articleCategory_not?: InputMaybe<Scalars['String']>
  articleCategory_not_contains?: InputMaybe<Scalars['String']>
  articleCategory_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  contentfulMetadata?: InputMaybe<ContentfulMetadataFilter>
  internalLabel?: InputMaybe<Scalars['String']>
  internalLabel_contains?: InputMaybe<Scalars['String']>
  internalLabel_exists?: InputMaybe<Scalars['Boolean']>
  internalLabel_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  internalLabel_not?: InputMaybe<Scalars['String']>
  internalLabel_not_contains?: InputMaybe<Scalars['String']>
  internalLabel_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  sys?: InputMaybe<SysFilter>
}

export type ComponentFeaturedArticlesLinkingCollections = {
  __typename?: 'ComponentFeaturedArticlesLinkingCollections'
  campaignPagesCollection?: Maybe<CampaignPagesCollection>
  entryCollection?: Maybe<EntryCollection>
  templateAboutUsCollection?: Maybe<TemplateAboutUsCollection>
  templateBookingCollection?: Maybe<TemplateBookingCollection>
  templateCareersCollection?: Maybe<TemplateCareersCollection>
  templateContactUsCollection?: Maybe<TemplateContactUsCollection>
  templateDestinationsCollection?: Maybe<TemplateDestinationsCollection>
  templateForaReserveCollection?: Maybe<TemplateForaReserveCollection>
  templateForaXCollection?: Maybe<TemplateForaXCollection>
  templateHomeCollection?: Maybe<TemplateHomeCollection>
  templateJoinUsCollection?: Maybe<TemplateJoinUsCollection>
  templateNewsletterCollection?: Maybe<TemplateNewsletterCollection>
  templatePageCollection?: Maybe<TemplatePageCollection>
  templatePartnersCollection?: Maybe<TemplatePartnersCollection>
  templateReviewsCollection?: Maybe<TemplateReviewsCollection>
  templateWhyBookWithForaCollection?: Maybe<TemplateWhyBookWithForaCollection>
}

export type ComponentFeaturedArticlesLinkingCollectionsCampaignPagesCollectionArgs =
  {
    limit?: InputMaybe<Scalars['Int']>
    locale?: InputMaybe<Scalars['String']>
    order?: InputMaybe<
      Array<
        InputMaybe<ComponentFeaturedArticlesLinkingCollectionsCampaignPagesCollectionOrder>
      >
    >
    preview?: InputMaybe<Scalars['Boolean']>
    skip?: InputMaybe<Scalars['Int']>
  }

export type ComponentFeaturedArticlesLinkingCollectionsEntryCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>
  locale?: InputMaybe<Scalars['String']>
  preview?: InputMaybe<Scalars['Boolean']>
  skip?: InputMaybe<Scalars['Int']>
}

export type ComponentFeaturedArticlesLinkingCollectionsTemplateAboutUsCollectionArgs =
  {
    limit?: InputMaybe<Scalars['Int']>
    locale?: InputMaybe<Scalars['String']>
    order?: InputMaybe<
      Array<
        InputMaybe<ComponentFeaturedArticlesLinkingCollectionsTemplateAboutUsCollectionOrder>
      >
    >
    preview?: InputMaybe<Scalars['Boolean']>
    skip?: InputMaybe<Scalars['Int']>
  }

export type ComponentFeaturedArticlesLinkingCollectionsTemplateBookingCollectionArgs =
  {
    limit?: InputMaybe<Scalars['Int']>
    locale?: InputMaybe<Scalars['String']>
    order?: InputMaybe<
      Array<
        InputMaybe<ComponentFeaturedArticlesLinkingCollectionsTemplateBookingCollectionOrder>
      >
    >
    preview?: InputMaybe<Scalars['Boolean']>
    skip?: InputMaybe<Scalars['Int']>
  }

export type ComponentFeaturedArticlesLinkingCollectionsTemplateCareersCollectionArgs =
  {
    limit?: InputMaybe<Scalars['Int']>
    locale?: InputMaybe<Scalars['String']>
    order?: InputMaybe<
      Array<
        InputMaybe<ComponentFeaturedArticlesLinkingCollectionsTemplateCareersCollectionOrder>
      >
    >
    preview?: InputMaybe<Scalars['Boolean']>
    skip?: InputMaybe<Scalars['Int']>
  }

export type ComponentFeaturedArticlesLinkingCollectionsTemplateContactUsCollectionArgs =
  {
    limit?: InputMaybe<Scalars['Int']>
    locale?: InputMaybe<Scalars['String']>
    order?: InputMaybe<
      Array<
        InputMaybe<ComponentFeaturedArticlesLinkingCollectionsTemplateContactUsCollectionOrder>
      >
    >
    preview?: InputMaybe<Scalars['Boolean']>
    skip?: InputMaybe<Scalars['Int']>
  }

export type ComponentFeaturedArticlesLinkingCollectionsTemplateDestinationsCollectionArgs =
  {
    limit?: InputMaybe<Scalars['Int']>
    locale?: InputMaybe<Scalars['String']>
    order?: InputMaybe<
      Array<
        InputMaybe<ComponentFeaturedArticlesLinkingCollectionsTemplateDestinationsCollectionOrder>
      >
    >
    preview?: InputMaybe<Scalars['Boolean']>
    skip?: InputMaybe<Scalars['Int']>
  }

export type ComponentFeaturedArticlesLinkingCollectionsTemplateForaReserveCollectionArgs =
  {
    limit?: InputMaybe<Scalars['Int']>
    locale?: InputMaybe<Scalars['String']>
    order?: InputMaybe<
      Array<
        InputMaybe<ComponentFeaturedArticlesLinkingCollectionsTemplateForaReserveCollectionOrder>
      >
    >
    preview?: InputMaybe<Scalars['Boolean']>
    skip?: InputMaybe<Scalars['Int']>
  }

export type ComponentFeaturedArticlesLinkingCollectionsTemplateForaXCollectionArgs =
  {
    limit?: InputMaybe<Scalars['Int']>
    locale?: InputMaybe<Scalars['String']>
    order?: InputMaybe<
      Array<
        InputMaybe<ComponentFeaturedArticlesLinkingCollectionsTemplateForaXCollectionOrder>
      >
    >
    preview?: InputMaybe<Scalars['Boolean']>
    skip?: InputMaybe<Scalars['Int']>
  }

export type ComponentFeaturedArticlesLinkingCollectionsTemplateHomeCollectionArgs =
  {
    limit?: InputMaybe<Scalars['Int']>
    locale?: InputMaybe<Scalars['String']>
    order?: InputMaybe<
      Array<
        InputMaybe<ComponentFeaturedArticlesLinkingCollectionsTemplateHomeCollectionOrder>
      >
    >
    preview?: InputMaybe<Scalars['Boolean']>
    skip?: InputMaybe<Scalars['Int']>
  }

export type ComponentFeaturedArticlesLinkingCollectionsTemplateJoinUsCollectionArgs =
  {
    limit?: InputMaybe<Scalars['Int']>
    locale?: InputMaybe<Scalars['String']>
    order?: InputMaybe<
      Array<
        InputMaybe<ComponentFeaturedArticlesLinkingCollectionsTemplateJoinUsCollectionOrder>
      >
    >
    preview?: InputMaybe<Scalars['Boolean']>
    skip?: InputMaybe<Scalars['Int']>
  }

export type ComponentFeaturedArticlesLinkingCollectionsTemplateNewsletterCollectionArgs =
  {
    limit?: InputMaybe<Scalars['Int']>
    locale?: InputMaybe<Scalars['String']>
    order?: InputMaybe<
      Array<
        InputMaybe<ComponentFeaturedArticlesLinkingCollectionsTemplateNewsletterCollectionOrder>
      >
    >
    preview?: InputMaybe<Scalars['Boolean']>
    skip?: InputMaybe<Scalars['Int']>
  }

export type ComponentFeaturedArticlesLinkingCollectionsTemplatePageCollectionArgs =
  {
    limit?: InputMaybe<Scalars['Int']>
    locale?: InputMaybe<Scalars['String']>
    order?: InputMaybe<
      Array<
        InputMaybe<ComponentFeaturedArticlesLinkingCollectionsTemplatePageCollectionOrder>
      >
    >
    preview?: InputMaybe<Scalars['Boolean']>
    skip?: InputMaybe<Scalars['Int']>
  }

export type ComponentFeaturedArticlesLinkingCollectionsTemplatePartnersCollectionArgs =
  {
    limit?: InputMaybe<Scalars['Int']>
    locale?: InputMaybe<Scalars['String']>
    order?: InputMaybe<
      Array<
        InputMaybe<ComponentFeaturedArticlesLinkingCollectionsTemplatePartnersCollectionOrder>
      >
    >
    preview?: InputMaybe<Scalars['Boolean']>
    skip?: InputMaybe<Scalars['Int']>
  }

export type ComponentFeaturedArticlesLinkingCollectionsTemplateReviewsCollectionArgs =
  {
    limit?: InputMaybe<Scalars['Int']>
    locale?: InputMaybe<Scalars['String']>
    order?: InputMaybe<
      Array<
        InputMaybe<ComponentFeaturedArticlesLinkingCollectionsTemplateReviewsCollectionOrder>
      >
    >
    preview?: InputMaybe<Scalars['Boolean']>
    skip?: InputMaybe<Scalars['Int']>
  }

export type ComponentFeaturedArticlesLinkingCollectionsTemplateWhyBookWithForaCollectionArgs =
  {
    limit?: InputMaybe<Scalars['Int']>
    locale?: InputMaybe<Scalars['String']>
    order?: InputMaybe<
      Array<
        InputMaybe<ComponentFeaturedArticlesLinkingCollectionsTemplateWhyBookWithForaCollectionOrder>
      >
    >
    preview?: InputMaybe<Scalars['Boolean']>
    skip?: InputMaybe<Scalars['Int']>
  }

export enum ComponentFeaturedArticlesLinkingCollectionsCampaignPagesCollectionOrder {
  CollapseFooterAsc = 'collapseFooter_ASC',
  CollapseFooterDesc = 'collapseFooter_DESC',
  CollapseHeaderAsc = 'collapseHeader_ASC',
  CollapseHeaderDesc = 'collapseHeader_DESC',
  HideFooterAsc = 'hideFooter_ASC',
  HideFooterDesc = 'hideFooter_DESC',
  HideGlobalBannerAsc = 'hideGlobalBanner_ASC',
  HideGlobalBannerDesc = 'hideGlobalBanner_DESC',
  JotformIdAsc = 'jotformId_ASC',
  JotformIdDesc = 'jotformId_DESC',
  NoindexNofollowAsc = 'noindexNofollow_ASC',
  NoindexNofollowDesc = 'noindexNofollow_DESC',
  ShowHeaderAsc = 'showHeader_ASC',
  ShowHeaderDesc = 'showHeader_DESC',
  SlugAsc = 'slug_ASC',
  SlugDesc = 'slug_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC',
}

export enum ComponentFeaturedArticlesLinkingCollectionsTemplateAboutUsCollectionOrder {
  CollapseHeaderAsc = 'collapseHeader_ASC',
  CollapseHeaderDesc = 'collapseHeader_DESC',
  HeaderBlockTitleAsc = 'headerBlockTitle_ASC',
  HeaderBlockTitleDesc = 'headerBlockTitle_DESC',
  ShowHeaderAsc = 'showHeader_ASC',
  ShowHeaderDesc = 'showHeader_DESC',
  SubtitleAsc = 'subtitle_ASC',
  SubtitleDesc = 'subtitle_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC',
}

export enum ComponentFeaturedArticlesLinkingCollectionsTemplateBookingCollectionOrder {
  CollapseHeaderAsc = 'collapseHeader_ASC',
  CollapseHeaderDesc = 'collapseHeader_DESC',
  ShowHeaderAsc = 'showHeader_ASC',
  ShowHeaderDesc = 'showHeader_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC',
}

export enum ComponentFeaturedArticlesLinkingCollectionsTemplateCareersCollectionOrder {
  CollapseHeaderAsc = 'collapseHeader_ASC',
  CollapseHeaderDesc = 'collapseHeader_DESC',
  ShowHeaderAsc = 'showHeader_ASC',
  ShowHeaderDesc = 'showHeader_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC',
}

export enum ComponentFeaturedArticlesLinkingCollectionsTemplateContactUsCollectionOrder {
  CollapseHeaderAsc = 'collapseHeader_ASC',
  CollapseHeaderDesc = 'collapseHeader_DESC',
  ShowHeaderAsc = 'showHeader_ASC',
  ShowHeaderDesc = 'showHeader_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC',
}

export enum ComponentFeaturedArticlesLinkingCollectionsTemplateDestinationsCollectionOrder {
  CollapseHeaderAsc = 'collapseHeader_ASC',
  CollapseHeaderDesc = 'collapseHeader_DESC',
  CollapsibleHeadingAsc = 'collapsibleHeading_ASC',
  CollapsibleHeadingDesc = 'collapsibleHeading_DESC',
  ShowHeaderAsc = 'showHeader_ASC',
  ShowHeaderDesc = 'showHeader_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC',
}

export enum ComponentFeaturedArticlesLinkingCollectionsTemplateForaReserveCollectionOrder {
  CollapseHeaderAsc = 'collapseHeader_ASC',
  CollapseHeaderDesc = 'collapseHeader_DESC',
  ShowHeaderAsc = 'showHeader_ASC',
  ShowHeaderDesc = 'showHeader_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC',
}

export enum ComponentFeaturedArticlesLinkingCollectionsTemplateForaXCollectionOrder {
  AdvisorGridHeaderAsc = 'advisorGridHeader_ASC',
  AdvisorGridHeaderDesc = 'advisorGridHeader_DESC',
  AdvisorGridSubCopyAsc = 'advisorGridSubCopy_ASC',
  AdvisorGridSubCopyDesc = 'advisorGridSubCopy_DESC',
  CollapseHeaderAsc = 'collapseHeader_ASC',
  CollapseHeaderDesc = 'collapseHeader_DESC',
  ShowHeaderAsc = 'showHeader_ASC',
  ShowHeaderDesc = 'showHeader_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC',
}

export enum ComponentFeaturedArticlesLinkingCollectionsTemplateHomeCollectionOrder {
  CollapseHeaderAsc = 'collapseHeader_ASC',
  CollapseHeaderDesc = 'collapseHeader_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC',
}

export enum ComponentFeaturedArticlesLinkingCollectionsTemplateJoinUsCollectionOrder {
  CollapseHeaderAsc = 'collapseHeader_ASC',
  CollapseHeaderDesc = 'collapseHeader_DESC',
  HideGlobalBannerAsc = 'hideGlobalBanner_ASC',
  HideGlobalBannerDesc = 'hideGlobalBanner_DESC',
  ShowHeaderAsc = 'showHeader_ASC',
  ShowHeaderDesc = 'showHeader_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC',
}

export enum ComponentFeaturedArticlesLinkingCollectionsTemplateNewsletterCollectionOrder {
  CollapseHeaderAsc = 'collapseHeader_ASC',
  CollapseHeaderDesc = 'collapseHeader_DESC',
  ShowHeaderAsc = 'showHeader_ASC',
  ShowHeaderDesc = 'showHeader_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC',
}

export enum ComponentFeaturedArticlesLinkingCollectionsTemplatePageCollectionOrder {
  DirectoryAsc = 'directory_ASC',
  DirectoryDesc = 'directory_DESC',
  InternalLabelAsc = 'internalLabel_ASC',
  InternalLabelDesc = 'internalLabel_DESC',
  MetaTitleAsc = 'metaTitle_ASC',
  MetaTitleDesc = 'metaTitle_DESC',
  NoindexNofollowAsc = 'noindexNofollow_ASC',
  NoindexNofollowDesc = 'noindexNofollow_DESC',
  SlugAsc = 'slug_ASC',
  SlugDesc = 'slug_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
}

export enum ComponentFeaturedArticlesLinkingCollectionsTemplatePartnersCollectionOrder {
  CollapseHeaderAsc = 'collapseHeader_ASC',
  CollapseHeaderDesc = 'collapseHeader_DESC',
  ShowHeaderAsc = 'showHeader_ASC',
  ShowHeaderDesc = 'showHeader_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC',
}

export enum ComponentFeaturedArticlesLinkingCollectionsTemplateReviewsCollectionOrder {
  CollapseHeaderAsc = 'collapseHeader_ASC',
  CollapseHeaderDesc = 'collapseHeader_DESC',
  ShowHeaderAsc = 'showHeader_ASC',
  ShowHeaderDesc = 'showHeader_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC',
}

export enum ComponentFeaturedArticlesLinkingCollectionsTemplateWhyBookWithForaCollectionOrder {
  CollapseHeaderAsc = 'collapseHeader_ASC',
  CollapseHeaderDesc = 'collapseHeader_DESC',
  ShowHeaderAsc = 'showHeader_ASC',
  ShowHeaderDesc = 'showHeader_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC',
}

export enum ComponentFeaturedArticlesOrder {
  ArticleCategoryAsc = 'articleCategory_ASC',
  ArticleCategoryDesc = 'articleCategory_DESC',
  InternalLabelAsc = 'internalLabel_ASC',
  InternalLabelDesc = 'internalLabel_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
}

/** A page section with teaser copy followed by a curated grid of featured guides. [See type definition](https://app.contentful.com/spaces/9cxgygtadmvl/content_types/componentFeaturedGuides) */
export type ComponentFeaturedGuides = Entry &
  _Node & {
    __typename?: 'ComponentFeaturedGuides'
    _id: Scalars['ID']
    analyticsModule?: Maybe<SettingsAnalyticsModule>
    contentfulMetadata: ContentfulMetadata
    ctaTarget?: Maybe<Scalars['String']>
    ctaText?: Maybe<Scalars['String']>
    guidesCollection?: Maybe<ComponentFeaturedGuidesGuidesCollection>
    internalLabel?: Maybe<Scalars['String']>
    linkedFrom?: Maybe<ComponentFeaturedGuidesLinkingCollections>
    sectionId?: Maybe<Scalars['String']>
    showCta?: Maybe<Scalars['Boolean']>
    sys: Sys
    teaserCopy?: Maybe<ComponentFeaturedGuidesTeaserCopy>
    title?: Maybe<Scalars['String']>
    titleStyle?: Maybe<Scalars['String']>
  }

/** A page section with teaser copy followed by a curated grid of featured guides. [See type definition](https://app.contentful.com/spaces/9cxgygtadmvl/content_types/componentFeaturedGuides) */
export type ComponentFeaturedGuidesAnalyticsModuleArgs = {
  locale?: InputMaybe<Scalars['String']>
  preview?: InputMaybe<Scalars['Boolean']>
  where?: InputMaybe<SettingsAnalyticsModuleFilter>
}

/** A page section with teaser copy followed by a curated grid of featured guides. [See type definition](https://app.contentful.com/spaces/9cxgygtadmvl/content_types/componentFeaturedGuides) */
export type ComponentFeaturedGuidesCtaTargetArgs = {
  locale?: InputMaybe<Scalars['String']>
}

/** A page section with teaser copy followed by a curated grid of featured guides. [See type definition](https://app.contentful.com/spaces/9cxgygtadmvl/content_types/componentFeaturedGuides) */
export type ComponentFeaturedGuidesCtaTextArgs = {
  locale?: InputMaybe<Scalars['String']>
}

/** A page section with teaser copy followed by a curated grid of featured guides. [See type definition](https://app.contentful.com/spaces/9cxgygtadmvl/content_types/componentFeaturedGuides) */
export type ComponentFeaturedGuidesGuidesCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>
  locale?: InputMaybe<Scalars['String']>
  order?: InputMaybe<
    Array<InputMaybe<ComponentFeaturedGuidesGuidesCollectionOrder>>
  >
  preview?: InputMaybe<Scalars['Boolean']>
  skip?: InputMaybe<Scalars['Int']>
  where?: InputMaybe<ItinerariesFilter>
}

/** A page section with teaser copy followed by a curated grid of featured guides. [See type definition](https://app.contentful.com/spaces/9cxgygtadmvl/content_types/componentFeaturedGuides) */
export type ComponentFeaturedGuidesInternalLabelArgs = {
  locale?: InputMaybe<Scalars['String']>
}

/** A page section with teaser copy followed by a curated grid of featured guides. [See type definition](https://app.contentful.com/spaces/9cxgygtadmvl/content_types/componentFeaturedGuides) */
export type ComponentFeaturedGuidesLinkedFromArgs = {
  allowedLocales?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
}

/** A page section with teaser copy followed by a curated grid of featured guides. [See type definition](https://app.contentful.com/spaces/9cxgygtadmvl/content_types/componentFeaturedGuides) */
export type ComponentFeaturedGuidesSectionIdArgs = {
  locale?: InputMaybe<Scalars['String']>
}

/** A page section with teaser copy followed by a curated grid of featured guides. [See type definition](https://app.contentful.com/spaces/9cxgygtadmvl/content_types/componentFeaturedGuides) */
export type ComponentFeaturedGuidesShowCtaArgs = {
  locale?: InputMaybe<Scalars['String']>
}

/** A page section with teaser copy followed by a curated grid of featured guides. [See type definition](https://app.contentful.com/spaces/9cxgygtadmvl/content_types/componentFeaturedGuides) */
export type ComponentFeaturedGuidesTeaserCopyArgs = {
  locale?: InputMaybe<Scalars['String']>
}

/** A page section with teaser copy followed by a curated grid of featured guides. [See type definition](https://app.contentful.com/spaces/9cxgygtadmvl/content_types/componentFeaturedGuides) */
export type ComponentFeaturedGuidesTitleArgs = {
  locale?: InputMaybe<Scalars['String']>
}

/** A page section with teaser copy followed by a curated grid of featured guides. [See type definition](https://app.contentful.com/spaces/9cxgygtadmvl/content_types/componentFeaturedGuides) */
export type ComponentFeaturedGuidesTitleStyleArgs = {
  locale?: InputMaybe<Scalars['String']>
}

export type ComponentFeaturedGuidesCollection = {
  __typename?: 'ComponentFeaturedGuidesCollection'
  items: Array<Maybe<ComponentFeaturedGuides>>
  limit: Scalars['Int']
  skip: Scalars['Int']
  total: Scalars['Int']
}

export type ComponentFeaturedGuidesFilter = {
  AND?: InputMaybe<Array<InputMaybe<ComponentFeaturedGuidesFilter>>>
  OR?: InputMaybe<Array<InputMaybe<ComponentFeaturedGuidesFilter>>>
  analyticsModule?: InputMaybe<CfSettingsAnalyticsModuleNestedFilter>
  analyticsModule_exists?: InputMaybe<Scalars['Boolean']>
  contentfulMetadata?: InputMaybe<ContentfulMetadataFilter>
  ctaTarget?: InputMaybe<Scalars['String']>
  ctaTarget_contains?: InputMaybe<Scalars['String']>
  ctaTarget_exists?: InputMaybe<Scalars['Boolean']>
  ctaTarget_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  ctaTarget_not?: InputMaybe<Scalars['String']>
  ctaTarget_not_contains?: InputMaybe<Scalars['String']>
  ctaTarget_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  ctaText?: InputMaybe<Scalars['String']>
  ctaText_contains?: InputMaybe<Scalars['String']>
  ctaText_exists?: InputMaybe<Scalars['Boolean']>
  ctaText_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  ctaText_not?: InputMaybe<Scalars['String']>
  ctaText_not_contains?: InputMaybe<Scalars['String']>
  ctaText_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  guides?: InputMaybe<CfItinerariesNestedFilter>
  guidesCollection_exists?: InputMaybe<Scalars['Boolean']>
  internalLabel?: InputMaybe<Scalars['String']>
  internalLabel_contains?: InputMaybe<Scalars['String']>
  internalLabel_exists?: InputMaybe<Scalars['Boolean']>
  internalLabel_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  internalLabel_not?: InputMaybe<Scalars['String']>
  internalLabel_not_contains?: InputMaybe<Scalars['String']>
  internalLabel_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  sectionId?: InputMaybe<Scalars['String']>
  sectionId_contains?: InputMaybe<Scalars['String']>
  sectionId_exists?: InputMaybe<Scalars['Boolean']>
  sectionId_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  sectionId_not?: InputMaybe<Scalars['String']>
  sectionId_not_contains?: InputMaybe<Scalars['String']>
  sectionId_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  showCta?: InputMaybe<Scalars['Boolean']>
  showCta_exists?: InputMaybe<Scalars['Boolean']>
  showCta_not?: InputMaybe<Scalars['Boolean']>
  sys?: InputMaybe<SysFilter>
  teaserCopy_contains?: InputMaybe<Scalars['String']>
  teaserCopy_exists?: InputMaybe<Scalars['Boolean']>
  teaserCopy_not_contains?: InputMaybe<Scalars['String']>
  title?: InputMaybe<Scalars['String']>
  titleStyle?: InputMaybe<Scalars['String']>
  titleStyle_contains?: InputMaybe<Scalars['String']>
  titleStyle_exists?: InputMaybe<Scalars['Boolean']>
  titleStyle_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  titleStyle_not?: InputMaybe<Scalars['String']>
  titleStyle_not_contains?: InputMaybe<Scalars['String']>
  titleStyle_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  title_contains?: InputMaybe<Scalars['String']>
  title_exists?: InputMaybe<Scalars['Boolean']>
  title_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  title_not?: InputMaybe<Scalars['String']>
  title_not_contains?: InputMaybe<Scalars['String']>
  title_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
}

export type ComponentFeaturedGuidesGuidesCollection = {
  __typename?: 'ComponentFeaturedGuidesGuidesCollection'
  items: Array<Maybe<Itineraries>>
  limit: Scalars['Int']
  skip: Scalars['Int']
  total: Scalars['Int']
}

export enum ComponentFeaturedGuidesGuidesCollectionOrder {
  ItineraryVariantAsc = 'itineraryVariant_ASC',
  ItineraryVariantDesc = 'itineraryVariant_DESC',
  LocationForTitlesAsc = 'locationForTitles_ASC',
  LocationForTitlesDesc = 'locationForTitles_DESC',
  MetaDescriptionAsc = 'metaDescription_ASC',
  MetaDescriptionDesc = 'metaDescription_DESC',
  MetaTitleAsc = 'metaTitle_ASC',
  MetaTitleDesc = 'metaTitle_DESC',
  SeoSlugAsc = 'seoSlug_ASC',
  SeoSlugDesc = 'seoSlug_DESC',
  ShowOnHomepageAsc = 'showOnHomepage_ASC',
  ShowOnHomepageDesc = 'showOnHomepage_DESC',
  ShowOnItinerariesPageAsc = 'showOnItinerariesPage_ASC',
  ShowOnItinerariesPageDesc = 'showOnItinerariesPage_DESC',
  ShowOnOtherLandingPagesAsc = 'showOnOtherLandingPages_ASC',
  ShowOnOtherLandingPagesDesc = 'showOnOtherLandingPages_DESC',
  SlugIdAsc = 'slugID_ASC',
  SlugIdDesc = 'slugID_DESC',
  SlugAsc = 'slug_ASC',
  SlugDesc = 'slug_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC',
}

export type ComponentFeaturedGuidesLinkingCollections = {
  __typename?: 'ComponentFeaturedGuidesLinkingCollections'
  campaignPagesCollection?: Maybe<CampaignPagesCollection>
  entryCollection?: Maybe<EntryCollection>
  templateAboutUsCollection?: Maybe<TemplateAboutUsCollection>
  templateBookingCollection?: Maybe<TemplateBookingCollection>
  templateCareersCollection?: Maybe<TemplateCareersCollection>
  templateContactUsCollection?: Maybe<TemplateContactUsCollection>
  templateDestinationsCollection?: Maybe<TemplateDestinationsCollection>
  templateForaReserveCollection?: Maybe<TemplateForaReserveCollection>
  templateForaXCollection?: Maybe<TemplateForaXCollection>
  templateHomeCollection?: Maybe<TemplateHomeCollection>
  templateJoinUsCollection?: Maybe<TemplateJoinUsCollection>
  templateNewsletterCollection?: Maybe<TemplateNewsletterCollection>
  templatePageCollection?: Maybe<TemplatePageCollection>
  templatePartnersCollection?: Maybe<TemplatePartnersCollection>
  templateReviewsCollection?: Maybe<TemplateReviewsCollection>
  templateWhyBookWithForaCollection?: Maybe<TemplateWhyBookWithForaCollection>
}

export type ComponentFeaturedGuidesLinkingCollectionsCampaignPagesCollectionArgs =
  {
    limit?: InputMaybe<Scalars['Int']>
    locale?: InputMaybe<Scalars['String']>
    order?: InputMaybe<
      Array<
        InputMaybe<ComponentFeaturedGuidesLinkingCollectionsCampaignPagesCollectionOrder>
      >
    >
    preview?: InputMaybe<Scalars['Boolean']>
    skip?: InputMaybe<Scalars['Int']>
  }

export type ComponentFeaturedGuidesLinkingCollectionsEntryCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>
  locale?: InputMaybe<Scalars['String']>
  preview?: InputMaybe<Scalars['Boolean']>
  skip?: InputMaybe<Scalars['Int']>
}

export type ComponentFeaturedGuidesLinkingCollectionsTemplateAboutUsCollectionArgs =
  {
    limit?: InputMaybe<Scalars['Int']>
    locale?: InputMaybe<Scalars['String']>
    order?: InputMaybe<
      Array<
        InputMaybe<ComponentFeaturedGuidesLinkingCollectionsTemplateAboutUsCollectionOrder>
      >
    >
    preview?: InputMaybe<Scalars['Boolean']>
    skip?: InputMaybe<Scalars['Int']>
  }

export type ComponentFeaturedGuidesLinkingCollectionsTemplateBookingCollectionArgs =
  {
    limit?: InputMaybe<Scalars['Int']>
    locale?: InputMaybe<Scalars['String']>
    order?: InputMaybe<
      Array<
        InputMaybe<ComponentFeaturedGuidesLinkingCollectionsTemplateBookingCollectionOrder>
      >
    >
    preview?: InputMaybe<Scalars['Boolean']>
    skip?: InputMaybe<Scalars['Int']>
  }

export type ComponentFeaturedGuidesLinkingCollectionsTemplateCareersCollectionArgs =
  {
    limit?: InputMaybe<Scalars['Int']>
    locale?: InputMaybe<Scalars['String']>
    order?: InputMaybe<
      Array<
        InputMaybe<ComponentFeaturedGuidesLinkingCollectionsTemplateCareersCollectionOrder>
      >
    >
    preview?: InputMaybe<Scalars['Boolean']>
    skip?: InputMaybe<Scalars['Int']>
  }

export type ComponentFeaturedGuidesLinkingCollectionsTemplateContactUsCollectionArgs =
  {
    limit?: InputMaybe<Scalars['Int']>
    locale?: InputMaybe<Scalars['String']>
    order?: InputMaybe<
      Array<
        InputMaybe<ComponentFeaturedGuidesLinkingCollectionsTemplateContactUsCollectionOrder>
      >
    >
    preview?: InputMaybe<Scalars['Boolean']>
    skip?: InputMaybe<Scalars['Int']>
  }

export type ComponentFeaturedGuidesLinkingCollectionsTemplateDestinationsCollectionArgs =
  {
    limit?: InputMaybe<Scalars['Int']>
    locale?: InputMaybe<Scalars['String']>
    order?: InputMaybe<
      Array<
        InputMaybe<ComponentFeaturedGuidesLinkingCollectionsTemplateDestinationsCollectionOrder>
      >
    >
    preview?: InputMaybe<Scalars['Boolean']>
    skip?: InputMaybe<Scalars['Int']>
  }

export type ComponentFeaturedGuidesLinkingCollectionsTemplateForaReserveCollectionArgs =
  {
    limit?: InputMaybe<Scalars['Int']>
    locale?: InputMaybe<Scalars['String']>
    order?: InputMaybe<
      Array<
        InputMaybe<ComponentFeaturedGuidesLinkingCollectionsTemplateForaReserveCollectionOrder>
      >
    >
    preview?: InputMaybe<Scalars['Boolean']>
    skip?: InputMaybe<Scalars['Int']>
  }

export type ComponentFeaturedGuidesLinkingCollectionsTemplateForaXCollectionArgs =
  {
    limit?: InputMaybe<Scalars['Int']>
    locale?: InputMaybe<Scalars['String']>
    order?: InputMaybe<
      Array<
        InputMaybe<ComponentFeaturedGuidesLinkingCollectionsTemplateForaXCollectionOrder>
      >
    >
    preview?: InputMaybe<Scalars['Boolean']>
    skip?: InputMaybe<Scalars['Int']>
  }

export type ComponentFeaturedGuidesLinkingCollectionsTemplateHomeCollectionArgs =
  {
    limit?: InputMaybe<Scalars['Int']>
    locale?: InputMaybe<Scalars['String']>
    order?: InputMaybe<
      Array<
        InputMaybe<ComponentFeaturedGuidesLinkingCollectionsTemplateHomeCollectionOrder>
      >
    >
    preview?: InputMaybe<Scalars['Boolean']>
    skip?: InputMaybe<Scalars['Int']>
  }

export type ComponentFeaturedGuidesLinkingCollectionsTemplateJoinUsCollectionArgs =
  {
    limit?: InputMaybe<Scalars['Int']>
    locale?: InputMaybe<Scalars['String']>
    order?: InputMaybe<
      Array<
        InputMaybe<ComponentFeaturedGuidesLinkingCollectionsTemplateJoinUsCollectionOrder>
      >
    >
    preview?: InputMaybe<Scalars['Boolean']>
    skip?: InputMaybe<Scalars['Int']>
  }

export type ComponentFeaturedGuidesLinkingCollectionsTemplateNewsletterCollectionArgs =
  {
    limit?: InputMaybe<Scalars['Int']>
    locale?: InputMaybe<Scalars['String']>
    order?: InputMaybe<
      Array<
        InputMaybe<ComponentFeaturedGuidesLinkingCollectionsTemplateNewsletterCollectionOrder>
      >
    >
    preview?: InputMaybe<Scalars['Boolean']>
    skip?: InputMaybe<Scalars['Int']>
  }

export type ComponentFeaturedGuidesLinkingCollectionsTemplatePageCollectionArgs =
  {
    limit?: InputMaybe<Scalars['Int']>
    locale?: InputMaybe<Scalars['String']>
    order?: InputMaybe<
      Array<
        InputMaybe<ComponentFeaturedGuidesLinkingCollectionsTemplatePageCollectionOrder>
      >
    >
    preview?: InputMaybe<Scalars['Boolean']>
    skip?: InputMaybe<Scalars['Int']>
  }

export type ComponentFeaturedGuidesLinkingCollectionsTemplatePartnersCollectionArgs =
  {
    limit?: InputMaybe<Scalars['Int']>
    locale?: InputMaybe<Scalars['String']>
    order?: InputMaybe<
      Array<
        InputMaybe<ComponentFeaturedGuidesLinkingCollectionsTemplatePartnersCollectionOrder>
      >
    >
    preview?: InputMaybe<Scalars['Boolean']>
    skip?: InputMaybe<Scalars['Int']>
  }

export type ComponentFeaturedGuidesLinkingCollectionsTemplateReviewsCollectionArgs =
  {
    limit?: InputMaybe<Scalars['Int']>
    locale?: InputMaybe<Scalars['String']>
    order?: InputMaybe<
      Array<
        InputMaybe<ComponentFeaturedGuidesLinkingCollectionsTemplateReviewsCollectionOrder>
      >
    >
    preview?: InputMaybe<Scalars['Boolean']>
    skip?: InputMaybe<Scalars['Int']>
  }

export type ComponentFeaturedGuidesLinkingCollectionsTemplateWhyBookWithForaCollectionArgs =
  {
    limit?: InputMaybe<Scalars['Int']>
    locale?: InputMaybe<Scalars['String']>
    order?: InputMaybe<
      Array<
        InputMaybe<ComponentFeaturedGuidesLinkingCollectionsTemplateWhyBookWithForaCollectionOrder>
      >
    >
    preview?: InputMaybe<Scalars['Boolean']>
    skip?: InputMaybe<Scalars['Int']>
  }

export enum ComponentFeaturedGuidesLinkingCollectionsCampaignPagesCollectionOrder {
  CollapseFooterAsc = 'collapseFooter_ASC',
  CollapseFooterDesc = 'collapseFooter_DESC',
  CollapseHeaderAsc = 'collapseHeader_ASC',
  CollapseHeaderDesc = 'collapseHeader_DESC',
  HideFooterAsc = 'hideFooter_ASC',
  HideFooterDesc = 'hideFooter_DESC',
  HideGlobalBannerAsc = 'hideGlobalBanner_ASC',
  HideGlobalBannerDesc = 'hideGlobalBanner_DESC',
  JotformIdAsc = 'jotformId_ASC',
  JotformIdDesc = 'jotformId_DESC',
  NoindexNofollowAsc = 'noindexNofollow_ASC',
  NoindexNofollowDesc = 'noindexNofollow_DESC',
  ShowHeaderAsc = 'showHeader_ASC',
  ShowHeaderDesc = 'showHeader_DESC',
  SlugAsc = 'slug_ASC',
  SlugDesc = 'slug_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC',
}

export enum ComponentFeaturedGuidesLinkingCollectionsTemplateAboutUsCollectionOrder {
  CollapseHeaderAsc = 'collapseHeader_ASC',
  CollapseHeaderDesc = 'collapseHeader_DESC',
  HeaderBlockTitleAsc = 'headerBlockTitle_ASC',
  HeaderBlockTitleDesc = 'headerBlockTitle_DESC',
  ShowHeaderAsc = 'showHeader_ASC',
  ShowHeaderDesc = 'showHeader_DESC',
  SubtitleAsc = 'subtitle_ASC',
  SubtitleDesc = 'subtitle_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC',
}

export enum ComponentFeaturedGuidesLinkingCollectionsTemplateBookingCollectionOrder {
  CollapseHeaderAsc = 'collapseHeader_ASC',
  CollapseHeaderDesc = 'collapseHeader_DESC',
  ShowHeaderAsc = 'showHeader_ASC',
  ShowHeaderDesc = 'showHeader_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC',
}

export enum ComponentFeaturedGuidesLinkingCollectionsTemplateCareersCollectionOrder {
  CollapseHeaderAsc = 'collapseHeader_ASC',
  CollapseHeaderDesc = 'collapseHeader_DESC',
  ShowHeaderAsc = 'showHeader_ASC',
  ShowHeaderDesc = 'showHeader_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC',
}

export enum ComponentFeaturedGuidesLinkingCollectionsTemplateContactUsCollectionOrder {
  CollapseHeaderAsc = 'collapseHeader_ASC',
  CollapseHeaderDesc = 'collapseHeader_DESC',
  ShowHeaderAsc = 'showHeader_ASC',
  ShowHeaderDesc = 'showHeader_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC',
}

export enum ComponentFeaturedGuidesLinkingCollectionsTemplateDestinationsCollectionOrder {
  CollapseHeaderAsc = 'collapseHeader_ASC',
  CollapseHeaderDesc = 'collapseHeader_DESC',
  CollapsibleHeadingAsc = 'collapsibleHeading_ASC',
  CollapsibleHeadingDesc = 'collapsibleHeading_DESC',
  ShowHeaderAsc = 'showHeader_ASC',
  ShowHeaderDesc = 'showHeader_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC',
}

export enum ComponentFeaturedGuidesLinkingCollectionsTemplateForaReserveCollectionOrder {
  CollapseHeaderAsc = 'collapseHeader_ASC',
  CollapseHeaderDesc = 'collapseHeader_DESC',
  ShowHeaderAsc = 'showHeader_ASC',
  ShowHeaderDesc = 'showHeader_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC',
}

export enum ComponentFeaturedGuidesLinkingCollectionsTemplateForaXCollectionOrder {
  AdvisorGridHeaderAsc = 'advisorGridHeader_ASC',
  AdvisorGridHeaderDesc = 'advisorGridHeader_DESC',
  AdvisorGridSubCopyAsc = 'advisorGridSubCopy_ASC',
  AdvisorGridSubCopyDesc = 'advisorGridSubCopy_DESC',
  CollapseHeaderAsc = 'collapseHeader_ASC',
  CollapseHeaderDesc = 'collapseHeader_DESC',
  ShowHeaderAsc = 'showHeader_ASC',
  ShowHeaderDesc = 'showHeader_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC',
}

export enum ComponentFeaturedGuidesLinkingCollectionsTemplateHomeCollectionOrder {
  CollapseHeaderAsc = 'collapseHeader_ASC',
  CollapseHeaderDesc = 'collapseHeader_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC',
}

export enum ComponentFeaturedGuidesLinkingCollectionsTemplateJoinUsCollectionOrder {
  CollapseHeaderAsc = 'collapseHeader_ASC',
  CollapseHeaderDesc = 'collapseHeader_DESC',
  HideGlobalBannerAsc = 'hideGlobalBanner_ASC',
  HideGlobalBannerDesc = 'hideGlobalBanner_DESC',
  ShowHeaderAsc = 'showHeader_ASC',
  ShowHeaderDesc = 'showHeader_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC',
}

export enum ComponentFeaturedGuidesLinkingCollectionsTemplateNewsletterCollectionOrder {
  CollapseHeaderAsc = 'collapseHeader_ASC',
  CollapseHeaderDesc = 'collapseHeader_DESC',
  ShowHeaderAsc = 'showHeader_ASC',
  ShowHeaderDesc = 'showHeader_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC',
}

export enum ComponentFeaturedGuidesLinkingCollectionsTemplatePageCollectionOrder {
  DirectoryAsc = 'directory_ASC',
  DirectoryDesc = 'directory_DESC',
  InternalLabelAsc = 'internalLabel_ASC',
  InternalLabelDesc = 'internalLabel_DESC',
  MetaTitleAsc = 'metaTitle_ASC',
  MetaTitleDesc = 'metaTitle_DESC',
  NoindexNofollowAsc = 'noindexNofollow_ASC',
  NoindexNofollowDesc = 'noindexNofollow_DESC',
  SlugAsc = 'slug_ASC',
  SlugDesc = 'slug_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
}

export enum ComponentFeaturedGuidesLinkingCollectionsTemplatePartnersCollectionOrder {
  CollapseHeaderAsc = 'collapseHeader_ASC',
  CollapseHeaderDesc = 'collapseHeader_DESC',
  ShowHeaderAsc = 'showHeader_ASC',
  ShowHeaderDesc = 'showHeader_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC',
}

export enum ComponentFeaturedGuidesLinkingCollectionsTemplateReviewsCollectionOrder {
  CollapseHeaderAsc = 'collapseHeader_ASC',
  CollapseHeaderDesc = 'collapseHeader_DESC',
  ShowHeaderAsc = 'showHeader_ASC',
  ShowHeaderDesc = 'showHeader_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC',
}

export enum ComponentFeaturedGuidesLinkingCollectionsTemplateWhyBookWithForaCollectionOrder {
  CollapseHeaderAsc = 'collapseHeader_ASC',
  CollapseHeaderDesc = 'collapseHeader_DESC',
  ShowHeaderAsc = 'showHeader_ASC',
  ShowHeaderDesc = 'showHeader_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC',
}

export enum ComponentFeaturedGuidesOrder {
  CtaTargetAsc = 'ctaTarget_ASC',
  CtaTargetDesc = 'ctaTarget_DESC',
  CtaTextAsc = 'ctaText_ASC',
  CtaTextDesc = 'ctaText_DESC',
  InternalLabelAsc = 'internalLabel_ASC',
  InternalLabelDesc = 'internalLabel_DESC',
  SectionIdAsc = 'sectionId_ASC',
  SectionIdDesc = 'sectionId_DESC',
  ShowCtaAsc = 'showCta_ASC',
  ShowCtaDesc = 'showCta_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  TitleStyleAsc = 'titleStyle_ASC',
  TitleStyleDesc = 'titleStyle_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC',
}

export type ComponentFeaturedGuidesTeaserCopy = {
  __typename?: 'ComponentFeaturedGuidesTeaserCopy'
  json: Scalars['JSON']
  links: ComponentFeaturedGuidesTeaserCopyLinks
}

export type ComponentFeaturedGuidesTeaserCopyAssets = {
  __typename?: 'ComponentFeaturedGuidesTeaserCopyAssets'
  block: Array<Maybe<Asset>>
  hyperlink: Array<Maybe<Asset>>
}

export type ComponentFeaturedGuidesTeaserCopyEntries = {
  __typename?: 'ComponentFeaturedGuidesTeaserCopyEntries'
  block: Array<Maybe<Entry>>
  hyperlink: Array<Maybe<Entry>>
  inline: Array<Maybe<Entry>>
}

export type ComponentFeaturedGuidesTeaserCopyLinks = {
  __typename?: 'ComponentFeaturedGuidesTeaserCopyLinks'
  assets: ComponentFeaturedGuidesTeaserCopyAssets
  entries: ComponentFeaturedGuidesTeaserCopyEntries
  resources: ComponentFeaturedGuidesTeaserCopyResources
}

export type ComponentFeaturedGuidesTeaserCopyResources = {
  __typename?: 'ComponentFeaturedGuidesTeaserCopyResources'
  block: Array<ComponentFeaturedGuidesTeaserCopyResourcesBlock>
  hyperlink: Array<ComponentFeaturedGuidesTeaserCopyResourcesHyperlink>
  inline: Array<ComponentFeaturedGuidesTeaserCopyResourcesInline>
}

export type ComponentFeaturedGuidesTeaserCopyResourcesBlock = ResourceLink & {
  __typename?: 'ComponentFeaturedGuidesTeaserCopyResourcesBlock'
  sys: ResourceSys
}

export type ComponentFeaturedGuidesTeaserCopyResourcesHyperlink =
  ResourceLink & {
    __typename?: 'ComponentFeaturedGuidesTeaserCopyResourcesHyperlink'
    sys: ResourceSys
  }

export type ComponentFeaturedGuidesTeaserCopyResourcesInline = ResourceLink & {
  __typename?: 'ComponentFeaturedGuidesTeaserCopyResourcesInline'
  sys: ResourceSys
}

/** Displays testimonial entries with a background [See type definition](https://app.contentful.com/spaces/9cxgygtadmvl/content_types/componentFeaturedReviews) */
export type ComponentFeaturedReviews = Entry &
  _Node & {
    __typename?: 'ComponentFeaturedReviews'
    _id: Scalars['ID']
    analyticsModule?: Maybe<SettingsAnalyticsModule>
    background?: Maybe<Asset>
    cloudinaryBackground?: Maybe<Scalars['JSON']>
    contentfulMetadata: ContentfulMetadata
    heading?: Maybe<Scalars['String']>
    internalLabel?: Maybe<Scalars['String']>
    linkedFrom?: Maybe<ComponentFeaturedReviewsLinkingCollections>
    sectionId?: Maybe<Scalars['String']>
    sys: Sys
    testimonialsCollection?: Maybe<ComponentFeaturedReviewsTestimonialsCollection>
    titleStyle?: Maybe<Scalars['String']>
  }

/** Displays testimonial entries with a background [See type definition](https://app.contentful.com/spaces/9cxgygtadmvl/content_types/componentFeaturedReviews) */
export type ComponentFeaturedReviewsAnalyticsModuleArgs = {
  locale?: InputMaybe<Scalars['String']>
  preview?: InputMaybe<Scalars['Boolean']>
  where?: InputMaybe<SettingsAnalyticsModuleFilter>
}

/** Displays testimonial entries with a background [See type definition](https://app.contentful.com/spaces/9cxgygtadmvl/content_types/componentFeaturedReviews) */
export type ComponentFeaturedReviewsBackgroundArgs = {
  locale?: InputMaybe<Scalars['String']>
  preview?: InputMaybe<Scalars['Boolean']>
}

/** Displays testimonial entries with a background [See type definition](https://app.contentful.com/spaces/9cxgygtadmvl/content_types/componentFeaturedReviews) */
export type ComponentFeaturedReviewsCloudinaryBackgroundArgs = {
  locale?: InputMaybe<Scalars['String']>
}

/** Displays testimonial entries with a background [See type definition](https://app.contentful.com/spaces/9cxgygtadmvl/content_types/componentFeaturedReviews) */
export type ComponentFeaturedReviewsHeadingArgs = {
  locale?: InputMaybe<Scalars['String']>
}

/** Displays testimonial entries with a background [See type definition](https://app.contentful.com/spaces/9cxgygtadmvl/content_types/componentFeaturedReviews) */
export type ComponentFeaturedReviewsInternalLabelArgs = {
  locale?: InputMaybe<Scalars['String']>
}

/** Displays testimonial entries with a background [See type definition](https://app.contentful.com/spaces/9cxgygtadmvl/content_types/componentFeaturedReviews) */
export type ComponentFeaturedReviewsLinkedFromArgs = {
  allowedLocales?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
}

/** Displays testimonial entries with a background [See type definition](https://app.contentful.com/spaces/9cxgygtadmvl/content_types/componentFeaturedReviews) */
export type ComponentFeaturedReviewsSectionIdArgs = {
  locale?: InputMaybe<Scalars['String']>
}

/** Displays testimonial entries with a background [See type definition](https://app.contentful.com/spaces/9cxgygtadmvl/content_types/componentFeaturedReviews) */
export type ComponentFeaturedReviewsTestimonialsCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>
  locale?: InputMaybe<Scalars['String']>
  order?: InputMaybe<
    Array<InputMaybe<ComponentFeaturedReviewsTestimonialsCollectionOrder>>
  >
  preview?: InputMaybe<Scalars['Boolean']>
  skip?: InputMaybe<Scalars['Int']>
  where?: InputMaybe<EntryTestimonialFilter>
}

/** Displays testimonial entries with a background [See type definition](https://app.contentful.com/spaces/9cxgygtadmvl/content_types/componentFeaturedReviews) */
export type ComponentFeaturedReviewsTitleStyleArgs = {
  locale?: InputMaybe<Scalars['String']>
}

export type ComponentFeaturedReviewsCollection = {
  __typename?: 'ComponentFeaturedReviewsCollection'
  items: Array<Maybe<ComponentFeaturedReviews>>
  limit: Scalars['Int']
  skip: Scalars['Int']
  total: Scalars['Int']
}

export type ComponentFeaturedReviewsFilter = {
  AND?: InputMaybe<Array<InputMaybe<ComponentFeaturedReviewsFilter>>>
  OR?: InputMaybe<Array<InputMaybe<ComponentFeaturedReviewsFilter>>>
  analyticsModule?: InputMaybe<CfSettingsAnalyticsModuleNestedFilter>
  analyticsModule_exists?: InputMaybe<Scalars['Boolean']>
  background_exists?: InputMaybe<Scalars['Boolean']>
  cloudinaryBackground_exists?: InputMaybe<Scalars['Boolean']>
  contentfulMetadata?: InputMaybe<ContentfulMetadataFilter>
  heading?: InputMaybe<Scalars['String']>
  heading_contains?: InputMaybe<Scalars['String']>
  heading_exists?: InputMaybe<Scalars['Boolean']>
  heading_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  heading_not?: InputMaybe<Scalars['String']>
  heading_not_contains?: InputMaybe<Scalars['String']>
  heading_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  internalLabel?: InputMaybe<Scalars['String']>
  internalLabel_contains?: InputMaybe<Scalars['String']>
  internalLabel_exists?: InputMaybe<Scalars['Boolean']>
  internalLabel_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  internalLabel_not?: InputMaybe<Scalars['String']>
  internalLabel_not_contains?: InputMaybe<Scalars['String']>
  internalLabel_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  sectionId?: InputMaybe<Scalars['String']>
  sectionId_contains?: InputMaybe<Scalars['String']>
  sectionId_exists?: InputMaybe<Scalars['Boolean']>
  sectionId_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  sectionId_not?: InputMaybe<Scalars['String']>
  sectionId_not_contains?: InputMaybe<Scalars['String']>
  sectionId_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  sys?: InputMaybe<SysFilter>
  testimonials?: InputMaybe<CfEntryTestimonialNestedFilter>
  testimonialsCollection_exists?: InputMaybe<Scalars['Boolean']>
  titleStyle?: InputMaybe<Scalars['String']>
  titleStyle_contains?: InputMaybe<Scalars['String']>
  titleStyle_exists?: InputMaybe<Scalars['Boolean']>
  titleStyle_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  titleStyle_not?: InputMaybe<Scalars['String']>
  titleStyle_not_contains?: InputMaybe<Scalars['String']>
  titleStyle_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
}

export type ComponentFeaturedReviewsLinkingCollections = {
  __typename?: 'ComponentFeaturedReviewsLinkingCollections'
  campaignPagesCollection?: Maybe<CampaignPagesCollection>
  entryCollection?: Maybe<EntryCollection>
  templateAboutUsCollection?: Maybe<TemplateAboutUsCollection>
  templateBookingCollection?: Maybe<TemplateBookingCollection>
  templateCareersCollection?: Maybe<TemplateCareersCollection>
  templateContactUsCollection?: Maybe<TemplateContactUsCollection>
  templateDestinationsCollection?: Maybe<TemplateDestinationsCollection>
  templateForaReserveCollection?: Maybe<TemplateForaReserveCollection>
  templateForaXCollection?: Maybe<TemplateForaXCollection>
  templateHomeCollection?: Maybe<TemplateHomeCollection>
  templateJoinUsCollection?: Maybe<TemplateJoinUsCollection>
  templateNewsletterCollection?: Maybe<TemplateNewsletterCollection>
  templatePartnersCollection?: Maybe<TemplatePartnersCollection>
  templateReviewsCollection?: Maybe<TemplateReviewsCollection>
  templateWhyBookWithForaCollection?: Maybe<TemplateWhyBookWithForaCollection>
}

export type ComponentFeaturedReviewsLinkingCollectionsCampaignPagesCollectionArgs =
  {
    limit?: InputMaybe<Scalars['Int']>
    locale?: InputMaybe<Scalars['String']>
    order?: InputMaybe<
      Array<
        InputMaybe<ComponentFeaturedReviewsLinkingCollectionsCampaignPagesCollectionOrder>
      >
    >
    preview?: InputMaybe<Scalars['Boolean']>
    skip?: InputMaybe<Scalars['Int']>
  }

export type ComponentFeaturedReviewsLinkingCollectionsEntryCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>
  locale?: InputMaybe<Scalars['String']>
  preview?: InputMaybe<Scalars['Boolean']>
  skip?: InputMaybe<Scalars['Int']>
}

export type ComponentFeaturedReviewsLinkingCollectionsTemplateAboutUsCollectionArgs =
  {
    limit?: InputMaybe<Scalars['Int']>
    locale?: InputMaybe<Scalars['String']>
    order?: InputMaybe<
      Array<
        InputMaybe<ComponentFeaturedReviewsLinkingCollectionsTemplateAboutUsCollectionOrder>
      >
    >
    preview?: InputMaybe<Scalars['Boolean']>
    skip?: InputMaybe<Scalars['Int']>
  }

export type ComponentFeaturedReviewsLinkingCollectionsTemplateBookingCollectionArgs =
  {
    limit?: InputMaybe<Scalars['Int']>
    locale?: InputMaybe<Scalars['String']>
    order?: InputMaybe<
      Array<
        InputMaybe<ComponentFeaturedReviewsLinkingCollectionsTemplateBookingCollectionOrder>
      >
    >
    preview?: InputMaybe<Scalars['Boolean']>
    skip?: InputMaybe<Scalars['Int']>
  }

export type ComponentFeaturedReviewsLinkingCollectionsTemplateCareersCollectionArgs =
  {
    limit?: InputMaybe<Scalars['Int']>
    locale?: InputMaybe<Scalars['String']>
    order?: InputMaybe<
      Array<
        InputMaybe<ComponentFeaturedReviewsLinkingCollectionsTemplateCareersCollectionOrder>
      >
    >
    preview?: InputMaybe<Scalars['Boolean']>
    skip?: InputMaybe<Scalars['Int']>
  }

export type ComponentFeaturedReviewsLinkingCollectionsTemplateContactUsCollectionArgs =
  {
    limit?: InputMaybe<Scalars['Int']>
    locale?: InputMaybe<Scalars['String']>
    order?: InputMaybe<
      Array<
        InputMaybe<ComponentFeaturedReviewsLinkingCollectionsTemplateContactUsCollectionOrder>
      >
    >
    preview?: InputMaybe<Scalars['Boolean']>
    skip?: InputMaybe<Scalars['Int']>
  }

export type ComponentFeaturedReviewsLinkingCollectionsTemplateDestinationsCollectionArgs =
  {
    limit?: InputMaybe<Scalars['Int']>
    locale?: InputMaybe<Scalars['String']>
    order?: InputMaybe<
      Array<
        InputMaybe<ComponentFeaturedReviewsLinkingCollectionsTemplateDestinationsCollectionOrder>
      >
    >
    preview?: InputMaybe<Scalars['Boolean']>
    skip?: InputMaybe<Scalars['Int']>
  }

export type ComponentFeaturedReviewsLinkingCollectionsTemplateForaReserveCollectionArgs =
  {
    limit?: InputMaybe<Scalars['Int']>
    locale?: InputMaybe<Scalars['String']>
    order?: InputMaybe<
      Array<
        InputMaybe<ComponentFeaturedReviewsLinkingCollectionsTemplateForaReserveCollectionOrder>
      >
    >
    preview?: InputMaybe<Scalars['Boolean']>
    skip?: InputMaybe<Scalars['Int']>
  }

export type ComponentFeaturedReviewsLinkingCollectionsTemplateForaXCollectionArgs =
  {
    limit?: InputMaybe<Scalars['Int']>
    locale?: InputMaybe<Scalars['String']>
    order?: InputMaybe<
      Array<
        InputMaybe<ComponentFeaturedReviewsLinkingCollectionsTemplateForaXCollectionOrder>
      >
    >
    preview?: InputMaybe<Scalars['Boolean']>
    skip?: InputMaybe<Scalars['Int']>
  }

export type ComponentFeaturedReviewsLinkingCollectionsTemplateHomeCollectionArgs =
  {
    limit?: InputMaybe<Scalars['Int']>
    locale?: InputMaybe<Scalars['String']>
    order?: InputMaybe<
      Array<
        InputMaybe<ComponentFeaturedReviewsLinkingCollectionsTemplateHomeCollectionOrder>
      >
    >
    preview?: InputMaybe<Scalars['Boolean']>
    skip?: InputMaybe<Scalars['Int']>
  }

export type ComponentFeaturedReviewsLinkingCollectionsTemplateJoinUsCollectionArgs =
  {
    limit?: InputMaybe<Scalars['Int']>
    locale?: InputMaybe<Scalars['String']>
    order?: InputMaybe<
      Array<
        InputMaybe<ComponentFeaturedReviewsLinkingCollectionsTemplateJoinUsCollectionOrder>
      >
    >
    preview?: InputMaybe<Scalars['Boolean']>
    skip?: InputMaybe<Scalars['Int']>
  }

export type ComponentFeaturedReviewsLinkingCollectionsTemplateNewsletterCollectionArgs =
  {
    limit?: InputMaybe<Scalars['Int']>
    locale?: InputMaybe<Scalars['String']>
    order?: InputMaybe<
      Array<
        InputMaybe<ComponentFeaturedReviewsLinkingCollectionsTemplateNewsletterCollectionOrder>
      >
    >
    preview?: InputMaybe<Scalars['Boolean']>
    skip?: InputMaybe<Scalars['Int']>
  }

export type ComponentFeaturedReviewsLinkingCollectionsTemplatePartnersCollectionArgs =
  {
    limit?: InputMaybe<Scalars['Int']>
    locale?: InputMaybe<Scalars['String']>
    order?: InputMaybe<
      Array<
        InputMaybe<ComponentFeaturedReviewsLinkingCollectionsTemplatePartnersCollectionOrder>
      >
    >
    preview?: InputMaybe<Scalars['Boolean']>
    skip?: InputMaybe<Scalars['Int']>
  }

export type ComponentFeaturedReviewsLinkingCollectionsTemplateReviewsCollectionArgs =
  {
    limit?: InputMaybe<Scalars['Int']>
    locale?: InputMaybe<Scalars['String']>
    order?: InputMaybe<
      Array<
        InputMaybe<ComponentFeaturedReviewsLinkingCollectionsTemplateReviewsCollectionOrder>
      >
    >
    preview?: InputMaybe<Scalars['Boolean']>
    skip?: InputMaybe<Scalars['Int']>
  }

export type ComponentFeaturedReviewsLinkingCollectionsTemplateWhyBookWithForaCollectionArgs =
  {
    limit?: InputMaybe<Scalars['Int']>
    locale?: InputMaybe<Scalars['String']>
    order?: InputMaybe<
      Array<
        InputMaybe<ComponentFeaturedReviewsLinkingCollectionsTemplateWhyBookWithForaCollectionOrder>
      >
    >
    preview?: InputMaybe<Scalars['Boolean']>
    skip?: InputMaybe<Scalars['Int']>
  }

export enum ComponentFeaturedReviewsLinkingCollectionsCampaignPagesCollectionOrder {
  CollapseFooterAsc = 'collapseFooter_ASC',
  CollapseFooterDesc = 'collapseFooter_DESC',
  CollapseHeaderAsc = 'collapseHeader_ASC',
  CollapseHeaderDesc = 'collapseHeader_DESC',
  HideFooterAsc = 'hideFooter_ASC',
  HideFooterDesc = 'hideFooter_DESC',
  HideGlobalBannerAsc = 'hideGlobalBanner_ASC',
  HideGlobalBannerDesc = 'hideGlobalBanner_DESC',
  JotformIdAsc = 'jotformId_ASC',
  JotformIdDesc = 'jotformId_DESC',
  NoindexNofollowAsc = 'noindexNofollow_ASC',
  NoindexNofollowDesc = 'noindexNofollow_DESC',
  ShowHeaderAsc = 'showHeader_ASC',
  ShowHeaderDesc = 'showHeader_DESC',
  SlugAsc = 'slug_ASC',
  SlugDesc = 'slug_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC',
}

export enum ComponentFeaturedReviewsLinkingCollectionsTemplateAboutUsCollectionOrder {
  CollapseHeaderAsc = 'collapseHeader_ASC',
  CollapseHeaderDesc = 'collapseHeader_DESC',
  HeaderBlockTitleAsc = 'headerBlockTitle_ASC',
  HeaderBlockTitleDesc = 'headerBlockTitle_DESC',
  ShowHeaderAsc = 'showHeader_ASC',
  ShowHeaderDesc = 'showHeader_DESC',
  SubtitleAsc = 'subtitle_ASC',
  SubtitleDesc = 'subtitle_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC',
}

export enum ComponentFeaturedReviewsLinkingCollectionsTemplateBookingCollectionOrder {
  CollapseHeaderAsc = 'collapseHeader_ASC',
  CollapseHeaderDesc = 'collapseHeader_DESC',
  ShowHeaderAsc = 'showHeader_ASC',
  ShowHeaderDesc = 'showHeader_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC',
}

export enum ComponentFeaturedReviewsLinkingCollectionsTemplateCareersCollectionOrder {
  CollapseHeaderAsc = 'collapseHeader_ASC',
  CollapseHeaderDesc = 'collapseHeader_DESC',
  ShowHeaderAsc = 'showHeader_ASC',
  ShowHeaderDesc = 'showHeader_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC',
}

export enum ComponentFeaturedReviewsLinkingCollectionsTemplateContactUsCollectionOrder {
  CollapseHeaderAsc = 'collapseHeader_ASC',
  CollapseHeaderDesc = 'collapseHeader_DESC',
  ShowHeaderAsc = 'showHeader_ASC',
  ShowHeaderDesc = 'showHeader_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC',
}

export enum ComponentFeaturedReviewsLinkingCollectionsTemplateDestinationsCollectionOrder {
  CollapseHeaderAsc = 'collapseHeader_ASC',
  CollapseHeaderDesc = 'collapseHeader_DESC',
  CollapsibleHeadingAsc = 'collapsibleHeading_ASC',
  CollapsibleHeadingDesc = 'collapsibleHeading_DESC',
  ShowHeaderAsc = 'showHeader_ASC',
  ShowHeaderDesc = 'showHeader_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC',
}

export enum ComponentFeaturedReviewsLinkingCollectionsTemplateForaReserveCollectionOrder {
  CollapseHeaderAsc = 'collapseHeader_ASC',
  CollapseHeaderDesc = 'collapseHeader_DESC',
  ShowHeaderAsc = 'showHeader_ASC',
  ShowHeaderDesc = 'showHeader_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC',
}

export enum ComponentFeaturedReviewsLinkingCollectionsTemplateForaXCollectionOrder {
  AdvisorGridHeaderAsc = 'advisorGridHeader_ASC',
  AdvisorGridHeaderDesc = 'advisorGridHeader_DESC',
  AdvisorGridSubCopyAsc = 'advisorGridSubCopy_ASC',
  AdvisorGridSubCopyDesc = 'advisorGridSubCopy_DESC',
  CollapseHeaderAsc = 'collapseHeader_ASC',
  CollapseHeaderDesc = 'collapseHeader_DESC',
  ShowHeaderAsc = 'showHeader_ASC',
  ShowHeaderDesc = 'showHeader_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC',
}

export enum ComponentFeaturedReviewsLinkingCollectionsTemplateHomeCollectionOrder {
  CollapseHeaderAsc = 'collapseHeader_ASC',
  CollapseHeaderDesc = 'collapseHeader_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC',
}

export enum ComponentFeaturedReviewsLinkingCollectionsTemplateJoinUsCollectionOrder {
  CollapseHeaderAsc = 'collapseHeader_ASC',
  CollapseHeaderDesc = 'collapseHeader_DESC',
  HideGlobalBannerAsc = 'hideGlobalBanner_ASC',
  HideGlobalBannerDesc = 'hideGlobalBanner_DESC',
  ShowHeaderAsc = 'showHeader_ASC',
  ShowHeaderDesc = 'showHeader_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC',
}

export enum ComponentFeaturedReviewsLinkingCollectionsTemplateNewsletterCollectionOrder {
  CollapseHeaderAsc = 'collapseHeader_ASC',
  CollapseHeaderDesc = 'collapseHeader_DESC',
  ShowHeaderAsc = 'showHeader_ASC',
  ShowHeaderDesc = 'showHeader_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC',
}

export enum ComponentFeaturedReviewsLinkingCollectionsTemplatePartnersCollectionOrder {
  CollapseHeaderAsc = 'collapseHeader_ASC',
  CollapseHeaderDesc = 'collapseHeader_DESC',
  ShowHeaderAsc = 'showHeader_ASC',
  ShowHeaderDesc = 'showHeader_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC',
}

export enum ComponentFeaturedReviewsLinkingCollectionsTemplateReviewsCollectionOrder {
  CollapseHeaderAsc = 'collapseHeader_ASC',
  CollapseHeaderDesc = 'collapseHeader_DESC',
  ShowHeaderAsc = 'showHeader_ASC',
  ShowHeaderDesc = 'showHeader_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC',
}

export enum ComponentFeaturedReviewsLinkingCollectionsTemplateWhyBookWithForaCollectionOrder {
  CollapseHeaderAsc = 'collapseHeader_ASC',
  CollapseHeaderDesc = 'collapseHeader_DESC',
  ShowHeaderAsc = 'showHeader_ASC',
  ShowHeaderDesc = 'showHeader_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC',
}

export enum ComponentFeaturedReviewsOrder {
  HeadingAsc = 'heading_ASC',
  HeadingDesc = 'heading_DESC',
  InternalLabelAsc = 'internalLabel_ASC',
  InternalLabelDesc = 'internalLabel_DESC',
  SectionIdAsc = 'sectionId_ASC',
  SectionIdDesc = 'sectionId_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  TitleStyleAsc = 'titleStyle_ASC',
  TitleStyleDesc = 'titleStyle_DESC',
}

export type ComponentFeaturedReviewsTestimonialsCollection = {
  __typename?: 'ComponentFeaturedReviewsTestimonialsCollection'
  items: Array<Maybe<EntryTestimonial>>
  limit: Scalars['Int']
  skip: Scalars['Int']
  total: Scalars['Int']
}

export enum ComponentFeaturedReviewsTestimonialsCollectionOrder {
  AboutForaAsc = 'aboutFora_ASC',
  AboutForaDesc = 'aboutFora_DESC',
  BeginTravelDateAsc = 'beginTravelDate_ASC',
  BeginTravelDateDesc = 'beginTravelDate_DESC',
  DateSubmittedAsc = 'dateSubmitted_ASC',
  DateSubmittedDesc = 'dateSubmitted_DESC',
  DisplayAsc = 'display_ASC',
  DisplayDesc = 'display_DESC',
  EndTravelDateAsc = 'endTravelDate_ASC',
  EndTravelDateDesc = 'endTravelDate_DESC',
  InternalLabelAsc = 'internalLabel_ASC',
  InternalLabelDesc = 'internalLabel_DESC',
  IsFeaturedAsc = 'isFeatured_ASC',
  IsFeaturedDesc = 'isFeatured_DESC',
  StarsAsc = 'stars_ASC',
  StarsDesc = 'stars_DESC',
  SubmitterEmailAsc = 'submitterEmail_ASC',
  SubmitterEmailDesc = 'submitterEmail_DESC',
  SubmitterFirstNameAsc = 'submitterFirstName_ASC',
  SubmitterFirstNameDesc = 'submitterFirstName_DESC',
  SubmitterLastNameAsc = 'submitterLastName_ASC',
  SubmitterLastNameDesc = 'submitterLastName_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  TestimonialSourceAsc = 'testimonialSource_ASC',
  TestimonialSourceDesc = 'testimonialSource_DESC',
  TitleOfReviewAsc = 'titleOfReview_ASC',
  TitleOfReviewDesc = 'titleOfReview_DESC',
}

/** [See type definition](https://app.contentful.com/spaces/9cxgygtadmvl/content_types/componentForaLogo) */
export type ComponentForaLogo = Entry &
  _Node & {
    __typename?: 'ComponentForaLogo'
    _id: Scalars['ID']
    contentfulMetadata: ContentfulMetadata
    image?: Maybe<Asset>
    internalLabel?: Maybe<Scalars['String']>
    linkedFrom?: Maybe<ComponentForaLogoLinkingCollections>
    sys: Sys
    url?: Maybe<Scalars['String']>
  }

/** [See type definition](https://app.contentful.com/spaces/9cxgygtadmvl/content_types/componentForaLogo) */
export type ComponentForaLogoImageArgs = {
  locale?: InputMaybe<Scalars['String']>
  preview?: InputMaybe<Scalars['Boolean']>
}

/** [See type definition](https://app.contentful.com/spaces/9cxgygtadmvl/content_types/componentForaLogo) */
export type ComponentForaLogoInternalLabelArgs = {
  locale?: InputMaybe<Scalars['String']>
}

/** [See type definition](https://app.contentful.com/spaces/9cxgygtadmvl/content_types/componentForaLogo) */
export type ComponentForaLogoLinkedFromArgs = {
  allowedLocales?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
}

/** [See type definition](https://app.contentful.com/spaces/9cxgygtadmvl/content_types/componentForaLogo) */
export type ComponentForaLogoUrlArgs = {
  locale?: InputMaybe<Scalars['String']>
}

export type ComponentForaLogoCollection = {
  __typename?: 'ComponentForaLogoCollection'
  items: Array<Maybe<ComponentForaLogo>>
  limit: Scalars['Int']
  skip: Scalars['Int']
  total: Scalars['Int']
}

export type ComponentForaLogoFilter = {
  AND?: InputMaybe<Array<InputMaybe<ComponentForaLogoFilter>>>
  OR?: InputMaybe<Array<InputMaybe<ComponentForaLogoFilter>>>
  contentfulMetadata?: InputMaybe<ContentfulMetadataFilter>
  image_exists?: InputMaybe<Scalars['Boolean']>
  internalLabel?: InputMaybe<Scalars['String']>
  internalLabel_contains?: InputMaybe<Scalars['String']>
  internalLabel_exists?: InputMaybe<Scalars['Boolean']>
  internalLabel_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  internalLabel_not?: InputMaybe<Scalars['String']>
  internalLabel_not_contains?: InputMaybe<Scalars['String']>
  internalLabel_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  sys?: InputMaybe<SysFilter>
  url?: InputMaybe<Scalars['String']>
  url_contains?: InputMaybe<Scalars['String']>
  url_exists?: InputMaybe<Scalars['Boolean']>
  url_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  url_not?: InputMaybe<Scalars['String']>
  url_not_contains?: InputMaybe<Scalars['String']>
  url_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
}

export type ComponentForaLogoLinkingCollections = {
  __typename?: 'ComponentForaLogoLinkingCollections'
  campaignPagesCollection?: Maybe<CampaignPagesCollection>
  entryCollection?: Maybe<EntryCollection>
  templateAboutUsCollection?: Maybe<TemplateAboutUsCollection>
  templateBookingCollection?: Maybe<TemplateBookingCollection>
  templateCareersCollection?: Maybe<TemplateCareersCollection>
  templateContactUsCollection?: Maybe<TemplateContactUsCollection>
  templateDestinationsCollection?: Maybe<TemplateDestinationsCollection>
  templateForaReserveCollection?: Maybe<TemplateForaReserveCollection>
  templateForaXCollection?: Maybe<TemplateForaXCollection>
  templateHomeCollection?: Maybe<TemplateHomeCollection>
  templateJoinUsCollection?: Maybe<TemplateJoinUsCollection>
  templateNewsletterCollection?: Maybe<TemplateNewsletterCollection>
  templatePartnersCollection?: Maybe<TemplatePartnersCollection>
  templateReviewsCollection?: Maybe<TemplateReviewsCollection>
  templateWhyBookWithForaCollection?: Maybe<TemplateWhyBookWithForaCollection>
}

export type ComponentForaLogoLinkingCollectionsCampaignPagesCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>
  locale?: InputMaybe<Scalars['String']>
  order?: InputMaybe<
    Array<
      InputMaybe<ComponentForaLogoLinkingCollectionsCampaignPagesCollectionOrder>
    >
  >
  preview?: InputMaybe<Scalars['Boolean']>
  skip?: InputMaybe<Scalars['Int']>
}

export type ComponentForaLogoLinkingCollectionsEntryCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>
  locale?: InputMaybe<Scalars['String']>
  preview?: InputMaybe<Scalars['Boolean']>
  skip?: InputMaybe<Scalars['Int']>
}

export type ComponentForaLogoLinkingCollectionsTemplateAboutUsCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>
  locale?: InputMaybe<Scalars['String']>
  order?: InputMaybe<
    Array<
      InputMaybe<ComponentForaLogoLinkingCollectionsTemplateAboutUsCollectionOrder>
    >
  >
  preview?: InputMaybe<Scalars['Boolean']>
  skip?: InputMaybe<Scalars['Int']>
}

export type ComponentForaLogoLinkingCollectionsTemplateBookingCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>
  locale?: InputMaybe<Scalars['String']>
  order?: InputMaybe<
    Array<
      InputMaybe<ComponentForaLogoLinkingCollectionsTemplateBookingCollectionOrder>
    >
  >
  preview?: InputMaybe<Scalars['Boolean']>
  skip?: InputMaybe<Scalars['Int']>
}

export type ComponentForaLogoLinkingCollectionsTemplateCareersCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>
  locale?: InputMaybe<Scalars['String']>
  order?: InputMaybe<
    Array<
      InputMaybe<ComponentForaLogoLinkingCollectionsTemplateCareersCollectionOrder>
    >
  >
  preview?: InputMaybe<Scalars['Boolean']>
  skip?: InputMaybe<Scalars['Int']>
}

export type ComponentForaLogoLinkingCollectionsTemplateContactUsCollectionArgs =
  {
    limit?: InputMaybe<Scalars['Int']>
    locale?: InputMaybe<Scalars['String']>
    order?: InputMaybe<
      Array<
        InputMaybe<ComponentForaLogoLinkingCollectionsTemplateContactUsCollectionOrder>
      >
    >
    preview?: InputMaybe<Scalars['Boolean']>
    skip?: InputMaybe<Scalars['Int']>
  }

export type ComponentForaLogoLinkingCollectionsTemplateDestinationsCollectionArgs =
  {
    limit?: InputMaybe<Scalars['Int']>
    locale?: InputMaybe<Scalars['String']>
    order?: InputMaybe<
      Array<
        InputMaybe<ComponentForaLogoLinkingCollectionsTemplateDestinationsCollectionOrder>
      >
    >
    preview?: InputMaybe<Scalars['Boolean']>
    skip?: InputMaybe<Scalars['Int']>
  }

export type ComponentForaLogoLinkingCollectionsTemplateForaReserveCollectionArgs =
  {
    limit?: InputMaybe<Scalars['Int']>
    locale?: InputMaybe<Scalars['String']>
    order?: InputMaybe<
      Array<
        InputMaybe<ComponentForaLogoLinkingCollectionsTemplateForaReserveCollectionOrder>
      >
    >
    preview?: InputMaybe<Scalars['Boolean']>
    skip?: InputMaybe<Scalars['Int']>
  }

export type ComponentForaLogoLinkingCollectionsTemplateForaXCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>
  locale?: InputMaybe<Scalars['String']>
  order?: InputMaybe<
    Array<
      InputMaybe<ComponentForaLogoLinkingCollectionsTemplateForaXCollectionOrder>
    >
  >
  preview?: InputMaybe<Scalars['Boolean']>
  skip?: InputMaybe<Scalars['Int']>
}

export type ComponentForaLogoLinkingCollectionsTemplateHomeCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>
  locale?: InputMaybe<Scalars['String']>
  order?: InputMaybe<
    Array<
      InputMaybe<ComponentForaLogoLinkingCollectionsTemplateHomeCollectionOrder>
    >
  >
  preview?: InputMaybe<Scalars['Boolean']>
  skip?: InputMaybe<Scalars['Int']>
}

export type ComponentForaLogoLinkingCollectionsTemplateJoinUsCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>
  locale?: InputMaybe<Scalars['String']>
  order?: InputMaybe<
    Array<
      InputMaybe<ComponentForaLogoLinkingCollectionsTemplateJoinUsCollectionOrder>
    >
  >
  preview?: InputMaybe<Scalars['Boolean']>
  skip?: InputMaybe<Scalars['Int']>
}

export type ComponentForaLogoLinkingCollectionsTemplateNewsletterCollectionArgs =
  {
    limit?: InputMaybe<Scalars['Int']>
    locale?: InputMaybe<Scalars['String']>
    order?: InputMaybe<
      Array<
        InputMaybe<ComponentForaLogoLinkingCollectionsTemplateNewsletterCollectionOrder>
      >
    >
    preview?: InputMaybe<Scalars['Boolean']>
    skip?: InputMaybe<Scalars['Int']>
  }

export type ComponentForaLogoLinkingCollectionsTemplatePartnersCollectionArgs =
  {
    limit?: InputMaybe<Scalars['Int']>
    locale?: InputMaybe<Scalars['String']>
    order?: InputMaybe<
      Array<
        InputMaybe<ComponentForaLogoLinkingCollectionsTemplatePartnersCollectionOrder>
      >
    >
    preview?: InputMaybe<Scalars['Boolean']>
    skip?: InputMaybe<Scalars['Int']>
  }

export type ComponentForaLogoLinkingCollectionsTemplateReviewsCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>
  locale?: InputMaybe<Scalars['String']>
  order?: InputMaybe<
    Array<
      InputMaybe<ComponentForaLogoLinkingCollectionsTemplateReviewsCollectionOrder>
    >
  >
  preview?: InputMaybe<Scalars['Boolean']>
  skip?: InputMaybe<Scalars['Int']>
}

export type ComponentForaLogoLinkingCollectionsTemplateWhyBookWithForaCollectionArgs =
  {
    limit?: InputMaybe<Scalars['Int']>
    locale?: InputMaybe<Scalars['String']>
    order?: InputMaybe<
      Array<
        InputMaybe<ComponentForaLogoLinkingCollectionsTemplateWhyBookWithForaCollectionOrder>
      >
    >
    preview?: InputMaybe<Scalars['Boolean']>
    skip?: InputMaybe<Scalars['Int']>
  }

export enum ComponentForaLogoLinkingCollectionsCampaignPagesCollectionOrder {
  CollapseFooterAsc = 'collapseFooter_ASC',
  CollapseFooterDesc = 'collapseFooter_DESC',
  CollapseHeaderAsc = 'collapseHeader_ASC',
  CollapseHeaderDesc = 'collapseHeader_DESC',
  HideFooterAsc = 'hideFooter_ASC',
  HideFooterDesc = 'hideFooter_DESC',
  HideGlobalBannerAsc = 'hideGlobalBanner_ASC',
  HideGlobalBannerDesc = 'hideGlobalBanner_DESC',
  JotformIdAsc = 'jotformId_ASC',
  JotformIdDesc = 'jotformId_DESC',
  NoindexNofollowAsc = 'noindexNofollow_ASC',
  NoindexNofollowDesc = 'noindexNofollow_DESC',
  ShowHeaderAsc = 'showHeader_ASC',
  ShowHeaderDesc = 'showHeader_DESC',
  SlugAsc = 'slug_ASC',
  SlugDesc = 'slug_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC',
}

export enum ComponentForaLogoLinkingCollectionsTemplateAboutUsCollectionOrder {
  CollapseHeaderAsc = 'collapseHeader_ASC',
  CollapseHeaderDesc = 'collapseHeader_DESC',
  HeaderBlockTitleAsc = 'headerBlockTitle_ASC',
  HeaderBlockTitleDesc = 'headerBlockTitle_DESC',
  ShowHeaderAsc = 'showHeader_ASC',
  ShowHeaderDesc = 'showHeader_DESC',
  SubtitleAsc = 'subtitle_ASC',
  SubtitleDesc = 'subtitle_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC',
}

export enum ComponentForaLogoLinkingCollectionsTemplateBookingCollectionOrder {
  CollapseHeaderAsc = 'collapseHeader_ASC',
  CollapseHeaderDesc = 'collapseHeader_DESC',
  ShowHeaderAsc = 'showHeader_ASC',
  ShowHeaderDesc = 'showHeader_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC',
}

export enum ComponentForaLogoLinkingCollectionsTemplateCareersCollectionOrder {
  CollapseHeaderAsc = 'collapseHeader_ASC',
  CollapseHeaderDesc = 'collapseHeader_DESC',
  ShowHeaderAsc = 'showHeader_ASC',
  ShowHeaderDesc = 'showHeader_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC',
}

export enum ComponentForaLogoLinkingCollectionsTemplateContactUsCollectionOrder {
  CollapseHeaderAsc = 'collapseHeader_ASC',
  CollapseHeaderDesc = 'collapseHeader_DESC',
  ShowHeaderAsc = 'showHeader_ASC',
  ShowHeaderDesc = 'showHeader_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC',
}

export enum ComponentForaLogoLinkingCollectionsTemplateDestinationsCollectionOrder {
  CollapseHeaderAsc = 'collapseHeader_ASC',
  CollapseHeaderDesc = 'collapseHeader_DESC',
  CollapsibleHeadingAsc = 'collapsibleHeading_ASC',
  CollapsibleHeadingDesc = 'collapsibleHeading_DESC',
  ShowHeaderAsc = 'showHeader_ASC',
  ShowHeaderDesc = 'showHeader_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC',
}

export enum ComponentForaLogoLinkingCollectionsTemplateForaReserveCollectionOrder {
  CollapseHeaderAsc = 'collapseHeader_ASC',
  CollapseHeaderDesc = 'collapseHeader_DESC',
  ShowHeaderAsc = 'showHeader_ASC',
  ShowHeaderDesc = 'showHeader_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC',
}

export enum ComponentForaLogoLinkingCollectionsTemplateForaXCollectionOrder {
  AdvisorGridHeaderAsc = 'advisorGridHeader_ASC',
  AdvisorGridHeaderDesc = 'advisorGridHeader_DESC',
  AdvisorGridSubCopyAsc = 'advisorGridSubCopy_ASC',
  AdvisorGridSubCopyDesc = 'advisorGridSubCopy_DESC',
  CollapseHeaderAsc = 'collapseHeader_ASC',
  CollapseHeaderDesc = 'collapseHeader_DESC',
  ShowHeaderAsc = 'showHeader_ASC',
  ShowHeaderDesc = 'showHeader_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC',
}

export enum ComponentForaLogoLinkingCollectionsTemplateHomeCollectionOrder {
  CollapseHeaderAsc = 'collapseHeader_ASC',
  CollapseHeaderDesc = 'collapseHeader_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC',
}

export enum ComponentForaLogoLinkingCollectionsTemplateJoinUsCollectionOrder {
  CollapseHeaderAsc = 'collapseHeader_ASC',
  CollapseHeaderDesc = 'collapseHeader_DESC',
  HideGlobalBannerAsc = 'hideGlobalBanner_ASC',
  HideGlobalBannerDesc = 'hideGlobalBanner_DESC',
  ShowHeaderAsc = 'showHeader_ASC',
  ShowHeaderDesc = 'showHeader_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC',
}

export enum ComponentForaLogoLinkingCollectionsTemplateNewsletterCollectionOrder {
  CollapseHeaderAsc = 'collapseHeader_ASC',
  CollapseHeaderDesc = 'collapseHeader_DESC',
  ShowHeaderAsc = 'showHeader_ASC',
  ShowHeaderDesc = 'showHeader_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC',
}

export enum ComponentForaLogoLinkingCollectionsTemplatePartnersCollectionOrder {
  CollapseHeaderAsc = 'collapseHeader_ASC',
  CollapseHeaderDesc = 'collapseHeader_DESC',
  ShowHeaderAsc = 'showHeader_ASC',
  ShowHeaderDesc = 'showHeader_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC',
}

export enum ComponentForaLogoLinkingCollectionsTemplateReviewsCollectionOrder {
  CollapseHeaderAsc = 'collapseHeader_ASC',
  CollapseHeaderDesc = 'collapseHeader_DESC',
  ShowHeaderAsc = 'showHeader_ASC',
  ShowHeaderDesc = 'showHeader_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC',
}

export enum ComponentForaLogoLinkingCollectionsTemplateWhyBookWithForaCollectionOrder {
  CollapseHeaderAsc = 'collapseHeader_ASC',
  CollapseHeaderDesc = 'collapseHeader_DESC',
  ShowHeaderAsc = 'showHeader_ASC',
  ShowHeaderDesc = 'showHeader_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC',
}

export enum ComponentForaLogoOrder {
  InternalLabelAsc = 'internalLabel_ASC',
  InternalLabelDesc = 'internalLabel_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  UrlAsc = 'url_ASC',
  UrlDesc = 'url_DESC',
}

/** Renders one of the speficied Form types on the page [See type definition](https://app.contentful.com/spaces/9cxgygtadmvl/content_types/componentForm) */
export type ComponentForm = Entry &
  _Node & {
    __typename?: 'ComponentForm'
    _id: Scalars['ID']
    analyticsModule?: Maybe<SettingsAnalyticsModule>
    buttonText?: Maybe<Scalars['String']>
    buttonTextSharedState?: Maybe<Scalars['String']>
    contentfulMetadata: ContentfulMetadata
    formId?: Maybe<Scalars['String']>
    internalLabel?: Maybe<Scalars['String']>
    linkedFrom?: Maybe<ComponentFormLinkingCollections>
    sectionId?: Maybe<Scalars['String']>
    subcopy?: Maybe<Scalars['String']>
    subcopySharedState?: Maybe<Scalars['String']>
    subscriptionCard?: Maybe<ComponentSubscriptionCard>
    successText?: Maybe<Scalars['String']>
    sys: Sys
    teasercopy?: Maybe<Scalars['String']>
    title?: Maybe<Scalars['String']>
    titleSharedState?: Maybe<Scalars['String']>
    typeformPopOutUrl?: Maybe<Scalars['String']>
    typeformUrl?: Maybe<Scalars['String']>
    zapierId?: Maybe<Scalars['String']>
  }

/** Renders one of the speficied Form types on the page [See type definition](https://app.contentful.com/spaces/9cxgygtadmvl/content_types/componentForm) */
export type ComponentFormAnalyticsModuleArgs = {
  locale?: InputMaybe<Scalars['String']>
  preview?: InputMaybe<Scalars['Boolean']>
  where?: InputMaybe<SettingsAnalyticsModuleFilter>
}

/** Renders one of the speficied Form types on the page [See type definition](https://app.contentful.com/spaces/9cxgygtadmvl/content_types/componentForm) */
export type ComponentFormButtonTextArgs = {
  locale?: InputMaybe<Scalars['String']>
}

/** Renders one of the speficied Form types on the page [See type definition](https://app.contentful.com/spaces/9cxgygtadmvl/content_types/componentForm) */
export type ComponentFormButtonTextSharedStateArgs = {
  locale?: InputMaybe<Scalars['String']>
}

/** Renders one of the speficied Form types on the page [See type definition](https://app.contentful.com/spaces/9cxgygtadmvl/content_types/componentForm) */
export type ComponentFormFormIdArgs = {
  locale?: InputMaybe<Scalars['String']>
}

/** Renders one of the speficied Form types on the page [See type definition](https://app.contentful.com/spaces/9cxgygtadmvl/content_types/componentForm) */
export type ComponentFormInternalLabelArgs = {
  locale?: InputMaybe<Scalars['String']>
}

/** Renders one of the speficied Form types on the page [See type definition](https://app.contentful.com/spaces/9cxgygtadmvl/content_types/componentForm) */
export type ComponentFormLinkedFromArgs = {
  allowedLocales?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
}

/** Renders one of the speficied Form types on the page [See type definition](https://app.contentful.com/spaces/9cxgygtadmvl/content_types/componentForm) */
export type ComponentFormSectionIdArgs = {
  locale?: InputMaybe<Scalars['String']>
}

/** Renders one of the speficied Form types on the page [See type definition](https://app.contentful.com/spaces/9cxgygtadmvl/content_types/componentForm) */
export type ComponentFormSubcopyArgs = {
  locale?: InputMaybe<Scalars['String']>
}

/** Renders one of the speficied Form types on the page [See type definition](https://app.contentful.com/spaces/9cxgygtadmvl/content_types/componentForm) */
export type ComponentFormSubcopySharedStateArgs = {
  locale?: InputMaybe<Scalars['String']>
}

/** Renders one of the speficied Form types on the page [See type definition](https://app.contentful.com/spaces/9cxgygtadmvl/content_types/componentForm) */
export type ComponentFormSubscriptionCardArgs = {
  locale?: InputMaybe<Scalars['String']>
  preview?: InputMaybe<Scalars['Boolean']>
  where?: InputMaybe<ComponentSubscriptionCardFilter>
}

/** Renders one of the speficied Form types on the page [See type definition](https://app.contentful.com/spaces/9cxgygtadmvl/content_types/componentForm) */
export type ComponentFormSuccessTextArgs = {
  locale?: InputMaybe<Scalars['String']>
}

/** Renders one of the speficied Form types on the page [See type definition](https://app.contentful.com/spaces/9cxgygtadmvl/content_types/componentForm) */
export type ComponentFormTeasercopyArgs = {
  locale?: InputMaybe<Scalars['String']>
}

/** Renders one of the speficied Form types on the page [See type definition](https://app.contentful.com/spaces/9cxgygtadmvl/content_types/componentForm) */
export type ComponentFormTitleArgs = {
  locale?: InputMaybe<Scalars['String']>
}

/** Renders one of the speficied Form types on the page [See type definition](https://app.contentful.com/spaces/9cxgygtadmvl/content_types/componentForm) */
export type ComponentFormTitleSharedStateArgs = {
  locale?: InputMaybe<Scalars['String']>
}

/** Renders one of the speficied Form types on the page [See type definition](https://app.contentful.com/spaces/9cxgygtadmvl/content_types/componentForm) */
export type ComponentFormTypeformPopOutUrlArgs = {
  locale?: InputMaybe<Scalars['String']>
}

/** Renders one of the speficied Form types on the page [See type definition](https://app.contentful.com/spaces/9cxgygtadmvl/content_types/componentForm) */
export type ComponentFormTypeformUrlArgs = {
  locale?: InputMaybe<Scalars['String']>
}

/** Renders one of the speficied Form types on the page [See type definition](https://app.contentful.com/spaces/9cxgygtadmvl/content_types/componentForm) */
export type ComponentFormZapierIdArgs = {
  locale?: InputMaybe<Scalars['String']>
}

export type ComponentFormCollection = {
  __typename?: 'ComponentFormCollection'
  items: Array<Maybe<ComponentForm>>
  limit: Scalars['Int']
  skip: Scalars['Int']
  total: Scalars['Int']
}

export type ComponentFormFilter = {
  AND?: InputMaybe<Array<InputMaybe<ComponentFormFilter>>>
  OR?: InputMaybe<Array<InputMaybe<ComponentFormFilter>>>
  analyticsModule?: InputMaybe<CfSettingsAnalyticsModuleNestedFilter>
  analyticsModule_exists?: InputMaybe<Scalars['Boolean']>
  buttonText?: InputMaybe<Scalars['String']>
  buttonTextSharedState?: InputMaybe<Scalars['String']>
  buttonTextSharedState_contains?: InputMaybe<Scalars['String']>
  buttonTextSharedState_exists?: InputMaybe<Scalars['Boolean']>
  buttonTextSharedState_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  buttonTextSharedState_not?: InputMaybe<Scalars['String']>
  buttonTextSharedState_not_contains?: InputMaybe<Scalars['String']>
  buttonTextSharedState_not_in?: InputMaybe<
    Array<InputMaybe<Scalars['String']>>
  >
  buttonText_contains?: InputMaybe<Scalars['String']>
  buttonText_exists?: InputMaybe<Scalars['Boolean']>
  buttonText_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  buttonText_not?: InputMaybe<Scalars['String']>
  buttonText_not_contains?: InputMaybe<Scalars['String']>
  buttonText_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  contentfulMetadata?: InputMaybe<ContentfulMetadataFilter>
  formId?: InputMaybe<Scalars['String']>
  formId_contains?: InputMaybe<Scalars['String']>
  formId_exists?: InputMaybe<Scalars['Boolean']>
  formId_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  formId_not?: InputMaybe<Scalars['String']>
  formId_not_contains?: InputMaybe<Scalars['String']>
  formId_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  internalLabel?: InputMaybe<Scalars['String']>
  internalLabel_contains?: InputMaybe<Scalars['String']>
  internalLabel_exists?: InputMaybe<Scalars['Boolean']>
  internalLabel_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  internalLabel_not?: InputMaybe<Scalars['String']>
  internalLabel_not_contains?: InputMaybe<Scalars['String']>
  internalLabel_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  sectionId?: InputMaybe<Scalars['String']>
  sectionId_contains?: InputMaybe<Scalars['String']>
  sectionId_exists?: InputMaybe<Scalars['Boolean']>
  sectionId_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  sectionId_not?: InputMaybe<Scalars['String']>
  sectionId_not_contains?: InputMaybe<Scalars['String']>
  sectionId_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  subcopy?: InputMaybe<Scalars['String']>
  subcopySharedState?: InputMaybe<Scalars['String']>
  subcopySharedState_contains?: InputMaybe<Scalars['String']>
  subcopySharedState_exists?: InputMaybe<Scalars['Boolean']>
  subcopySharedState_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  subcopySharedState_not?: InputMaybe<Scalars['String']>
  subcopySharedState_not_contains?: InputMaybe<Scalars['String']>
  subcopySharedState_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  subcopy_contains?: InputMaybe<Scalars['String']>
  subcopy_exists?: InputMaybe<Scalars['Boolean']>
  subcopy_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  subcopy_not?: InputMaybe<Scalars['String']>
  subcopy_not_contains?: InputMaybe<Scalars['String']>
  subcopy_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  subscriptionCard?: InputMaybe<CfComponentSubscriptionCardNestedFilter>
  subscriptionCard_exists?: InputMaybe<Scalars['Boolean']>
  successText?: InputMaybe<Scalars['String']>
  successText_contains?: InputMaybe<Scalars['String']>
  successText_exists?: InputMaybe<Scalars['Boolean']>
  successText_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  successText_not?: InputMaybe<Scalars['String']>
  successText_not_contains?: InputMaybe<Scalars['String']>
  successText_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  sys?: InputMaybe<SysFilter>
  teasercopy?: InputMaybe<Scalars['String']>
  teasercopy_contains?: InputMaybe<Scalars['String']>
  teasercopy_exists?: InputMaybe<Scalars['Boolean']>
  teasercopy_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  teasercopy_not?: InputMaybe<Scalars['String']>
  teasercopy_not_contains?: InputMaybe<Scalars['String']>
  teasercopy_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  title?: InputMaybe<Scalars['String']>
  titleSharedState?: InputMaybe<Scalars['String']>
  titleSharedState_contains?: InputMaybe<Scalars['String']>
  titleSharedState_exists?: InputMaybe<Scalars['Boolean']>
  titleSharedState_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  titleSharedState_not?: InputMaybe<Scalars['String']>
  titleSharedState_not_contains?: InputMaybe<Scalars['String']>
  titleSharedState_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  title_contains?: InputMaybe<Scalars['String']>
  title_exists?: InputMaybe<Scalars['Boolean']>
  title_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  title_not?: InputMaybe<Scalars['String']>
  title_not_contains?: InputMaybe<Scalars['String']>
  title_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  typeformPopOutUrl?: InputMaybe<Scalars['String']>
  typeformPopOutUrl_contains?: InputMaybe<Scalars['String']>
  typeformPopOutUrl_exists?: InputMaybe<Scalars['Boolean']>
  typeformPopOutUrl_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  typeformPopOutUrl_not?: InputMaybe<Scalars['String']>
  typeformPopOutUrl_not_contains?: InputMaybe<Scalars['String']>
  typeformPopOutUrl_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  typeformUrl?: InputMaybe<Scalars['String']>
  typeformUrl_contains?: InputMaybe<Scalars['String']>
  typeformUrl_exists?: InputMaybe<Scalars['Boolean']>
  typeformUrl_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  typeformUrl_not?: InputMaybe<Scalars['String']>
  typeformUrl_not_contains?: InputMaybe<Scalars['String']>
  typeformUrl_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  zapierId?: InputMaybe<Scalars['String']>
  zapierId_contains?: InputMaybe<Scalars['String']>
  zapierId_exists?: InputMaybe<Scalars['Boolean']>
  zapierId_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  zapierId_not?: InputMaybe<Scalars['String']>
  zapierId_not_contains?: InputMaybe<Scalars['String']>
  zapierId_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
}

export type ComponentFormLinkingCollections = {
  __typename?: 'ComponentFormLinkingCollections'
  campaignPagesCollection?: Maybe<CampaignPagesCollection>
  entryCollection?: Maybe<EntryCollection>
  templateAboutUsCollection?: Maybe<TemplateAboutUsCollection>
  templateBookingCollection?: Maybe<TemplateBookingCollection>
  templateCareersCollection?: Maybe<TemplateCareersCollection>
  templateContactUsCollection?: Maybe<TemplateContactUsCollection>
  templateDestinationsCollection?: Maybe<TemplateDestinationsCollection>
  templateForaReserveCollection?: Maybe<TemplateForaReserveCollection>
  templateForaXCollection?: Maybe<TemplateForaXCollection>
  templateHomeCollection?: Maybe<TemplateHomeCollection>
  templateJoinUsCollection?: Maybe<TemplateJoinUsCollection>
  templateNewsletterCollection?: Maybe<TemplateNewsletterCollection>
  templatePageCollection?: Maybe<TemplatePageCollection>
  templatePartnersCollection?: Maybe<TemplatePartnersCollection>
  templateReviewsCollection?: Maybe<TemplateReviewsCollection>
  templateWhyBookWithForaCollection?: Maybe<TemplateWhyBookWithForaCollection>
}

export type ComponentFormLinkingCollectionsCampaignPagesCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>
  locale?: InputMaybe<Scalars['String']>
  order?: InputMaybe<
    Array<
      InputMaybe<ComponentFormLinkingCollectionsCampaignPagesCollectionOrder>
    >
  >
  preview?: InputMaybe<Scalars['Boolean']>
  skip?: InputMaybe<Scalars['Int']>
}

export type ComponentFormLinkingCollectionsEntryCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>
  locale?: InputMaybe<Scalars['String']>
  preview?: InputMaybe<Scalars['Boolean']>
  skip?: InputMaybe<Scalars['Int']>
}

export type ComponentFormLinkingCollectionsTemplateAboutUsCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>
  locale?: InputMaybe<Scalars['String']>
  order?: InputMaybe<
    Array<
      InputMaybe<ComponentFormLinkingCollectionsTemplateAboutUsCollectionOrder>
    >
  >
  preview?: InputMaybe<Scalars['Boolean']>
  skip?: InputMaybe<Scalars['Int']>
}

export type ComponentFormLinkingCollectionsTemplateBookingCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>
  locale?: InputMaybe<Scalars['String']>
  order?: InputMaybe<
    Array<
      InputMaybe<ComponentFormLinkingCollectionsTemplateBookingCollectionOrder>
    >
  >
  preview?: InputMaybe<Scalars['Boolean']>
  skip?: InputMaybe<Scalars['Int']>
}

export type ComponentFormLinkingCollectionsTemplateCareersCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>
  locale?: InputMaybe<Scalars['String']>
  order?: InputMaybe<
    Array<
      InputMaybe<ComponentFormLinkingCollectionsTemplateCareersCollectionOrder>
    >
  >
  preview?: InputMaybe<Scalars['Boolean']>
  skip?: InputMaybe<Scalars['Int']>
}

export type ComponentFormLinkingCollectionsTemplateContactUsCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>
  locale?: InputMaybe<Scalars['String']>
  order?: InputMaybe<
    Array<
      InputMaybe<ComponentFormLinkingCollectionsTemplateContactUsCollectionOrder>
    >
  >
  preview?: InputMaybe<Scalars['Boolean']>
  skip?: InputMaybe<Scalars['Int']>
}

export type ComponentFormLinkingCollectionsTemplateDestinationsCollectionArgs =
  {
    limit?: InputMaybe<Scalars['Int']>
    locale?: InputMaybe<Scalars['String']>
    order?: InputMaybe<
      Array<
        InputMaybe<ComponentFormLinkingCollectionsTemplateDestinationsCollectionOrder>
      >
    >
    preview?: InputMaybe<Scalars['Boolean']>
    skip?: InputMaybe<Scalars['Int']>
  }

export type ComponentFormLinkingCollectionsTemplateForaReserveCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>
  locale?: InputMaybe<Scalars['String']>
  order?: InputMaybe<
    Array<
      InputMaybe<ComponentFormLinkingCollectionsTemplateForaReserveCollectionOrder>
    >
  >
  preview?: InputMaybe<Scalars['Boolean']>
  skip?: InputMaybe<Scalars['Int']>
}

export type ComponentFormLinkingCollectionsTemplateForaXCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>
  locale?: InputMaybe<Scalars['String']>
  order?: InputMaybe<
    Array<
      InputMaybe<ComponentFormLinkingCollectionsTemplateForaXCollectionOrder>
    >
  >
  preview?: InputMaybe<Scalars['Boolean']>
  skip?: InputMaybe<Scalars['Int']>
}

export type ComponentFormLinkingCollectionsTemplateHomeCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>
  locale?: InputMaybe<Scalars['String']>
  order?: InputMaybe<
    Array<
      InputMaybe<ComponentFormLinkingCollectionsTemplateHomeCollectionOrder>
    >
  >
  preview?: InputMaybe<Scalars['Boolean']>
  skip?: InputMaybe<Scalars['Int']>
}

export type ComponentFormLinkingCollectionsTemplateJoinUsCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>
  locale?: InputMaybe<Scalars['String']>
  order?: InputMaybe<
    Array<
      InputMaybe<ComponentFormLinkingCollectionsTemplateJoinUsCollectionOrder>
    >
  >
  preview?: InputMaybe<Scalars['Boolean']>
  skip?: InputMaybe<Scalars['Int']>
}

export type ComponentFormLinkingCollectionsTemplateNewsletterCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>
  locale?: InputMaybe<Scalars['String']>
  order?: InputMaybe<
    Array<
      InputMaybe<ComponentFormLinkingCollectionsTemplateNewsletterCollectionOrder>
    >
  >
  preview?: InputMaybe<Scalars['Boolean']>
  skip?: InputMaybe<Scalars['Int']>
}

export type ComponentFormLinkingCollectionsTemplatePageCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>
  locale?: InputMaybe<Scalars['String']>
  order?: InputMaybe<
    Array<
      InputMaybe<ComponentFormLinkingCollectionsTemplatePageCollectionOrder>
    >
  >
  preview?: InputMaybe<Scalars['Boolean']>
  skip?: InputMaybe<Scalars['Int']>
}

export type ComponentFormLinkingCollectionsTemplatePartnersCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>
  locale?: InputMaybe<Scalars['String']>
  order?: InputMaybe<
    Array<
      InputMaybe<ComponentFormLinkingCollectionsTemplatePartnersCollectionOrder>
    >
  >
  preview?: InputMaybe<Scalars['Boolean']>
  skip?: InputMaybe<Scalars['Int']>
}

export type ComponentFormLinkingCollectionsTemplateReviewsCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>
  locale?: InputMaybe<Scalars['String']>
  order?: InputMaybe<
    Array<
      InputMaybe<ComponentFormLinkingCollectionsTemplateReviewsCollectionOrder>
    >
  >
  preview?: InputMaybe<Scalars['Boolean']>
  skip?: InputMaybe<Scalars['Int']>
}

export type ComponentFormLinkingCollectionsTemplateWhyBookWithForaCollectionArgs =
  {
    limit?: InputMaybe<Scalars['Int']>
    locale?: InputMaybe<Scalars['String']>
    order?: InputMaybe<
      Array<
        InputMaybe<ComponentFormLinkingCollectionsTemplateWhyBookWithForaCollectionOrder>
      >
    >
    preview?: InputMaybe<Scalars['Boolean']>
    skip?: InputMaybe<Scalars['Int']>
  }

export enum ComponentFormLinkingCollectionsCampaignPagesCollectionOrder {
  CollapseFooterAsc = 'collapseFooter_ASC',
  CollapseFooterDesc = 'collapseFooter_DESC',
  CollapseHeaderAsc = 'collapseHeader_ASC',
  CollapseHeaderDesc = 'collapseHeader_DESC',
  HideFooterAsc = 'hideFooter_ASC',
  HideFooterDesc = 'hideFooter_DESC',
  HideGlobalBannerAsc = 'hideGlobalBanner_ASC',
  HideGlobalBannerDesc = 'hideGlobalBanner_DESC',
  JotformIdAsc = 'jotformId_ASC',
  JotformIdDesc = 'jotformId_DESC',
  NoindexNofollowAsc = 'noindexNofollow_ASC',
  NoindexNofollowDesc = 'noindexNofollow_DESC',
  ShowHeaderAsc = 'showHeader_ASC',
  ShowHeaderDesc = 'showHeader_DESC',
  SlugAsc = 'slug_ASC',
  SlugDesc = 'slug_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC',
}

export enum ComponentFormLinkingCollectionsTemplateAboutUsCollectionOrder {
  CollapseHeaderAsc = 'collapseHeader_ASC',
  CollapseHeaderDesc = 'collapseHeader_DESC',
  HeaderBlockTitleAsc = 'headerBlockTitle_ASC',
  HeaderBlockTitleDesc = 'headerBlockTitle_DESC',
  ShowHeaderAsc = 'showHeader_ASC',
  ShowHeaderDesc = 'showHeader_DESC',
  SubtitleAsc = 'subtitle_ASC',
  SubtitleDesc = 'subtitle_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC',
}

export enum ComponentFormLinkingCollectionsTemplateBookingCollectionOrder {
  CollapseHeaderAsc = 'collapseHeader_ASC',
  CollapseHeaderDesc = 'collapseHeader_DESC',
  ShowHeaderAsc = 'showHeader_ASC',
  ShowHeaderDesc = 'showHeader_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC',
}

export enum ComponentFormLinkingCollectionsTemplateCareersCollectionOrder {
  CollapseHeaderAsc = 'collapseHeader_ASC',
  CollapseHeaderDesc = 'collapseHeader_DESC',
  ShowHeaderAsc = 'showHeader_ASC',
  ShowHeaderDesc = 'showHeader_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC',
}

export enum ComponentFormLinkingCollectionsTemplateContactUsCollectionOrder {
  CollapseHeaderAsc = 'collapseHeader_ASC',
  CollapseHeaderDesc = 'collapseHeader_DESC',
  ShowHeaderAsc = 'showHeader_ASC',
  ShowHeaderDesc = 'showHeader_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC',
}

export enum ComponentFormLinkingCollectionsTemplateDestinationsCollectionOrder {
  CollapseHeaderAsc = 'collapseHeader_ASC',
  CollapseHeaderDesc = 'collapseHeader_DESC',
  CollapsibleHeadingAsc = 'collapsibleHeading_ASC',
  CollapsibleHeadingDesc = 'collapsibleHeading_DESC',
  ShowHeaderAsc = 'showHeader_ASC',
  ShowHeaderDesc = 'showHeader_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC',
}

export enum ComponentFormLinkingCollectionsTemplateForaReserveCollectionOrder {
  CollapseHeaderAsc = 'collapseHeader_ASC',
  CollapseHeaderDesc = 'collapseHeader_DESC',
  ShowHeaderAsc = 'showHeader_ASC',
  ShowHeaderDesc = 'showHeader_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC',
}

export enum ComponentFormLinkingCollectionsTemplateForaXCollectionOrder {
  AdvisorGridHeaderAsc = 'advisorGridHeader_ASC',
  AdvisorGridHeaderDesc = 'advisorGridHeader_DESC',
  AdvisorGridSubCopyAsc = 'advisorGridSubCopy_ASC',
  AdvisorGridSubCopyDesc = 'advisorGridSubCopy_DESC',
  CollapseHeaderAsc = 'collapseHeader_ASC',
  CollapseHeaderDesc = 'collapseHeader_DESC',
  ShowHeaderAsc = 'showHeader_ASC',
  ShowHeaderDesc = 'showHeader_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC',
}

export enum ComponentFormLinkingCollectionsTemplateHomeCollectionOrder {
  CollapseHeaderAsc = 'collapseHeader_ASC',
  CollapseHeaderDesc = 'collapseHeader_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC',
}

export enum ComponentFormLinkingCollectionsTemplateJoinUsCollectionOrder {
  CollapseHeaderAsc = 'collapseHeader_ASC',
  CollapseHeaderDesc = 'collapseHeader_DESC',
  HideGlobalBannerAsc = 'hideGlobalBanner_ASC',
  HideGlobalBannerDesc = 'hideGlobalBanner_DESC',
  ShowHeaderAsc = 'showHeader_ASC',
  ShowHeaderDesc = 'showHeader_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC',
}

export enum ComponentFormLinkingCollectionsTemplateNewsletterCollectionOrder {
  CollapseHeaderAsc = 'collapseHeader_ASC',
  CollapseHeaderDesc = 'collapseHeader_DESC',
  ShowHeaderAsc = 'showHeader_ASC',
  ShowHeaderDesc = 'showHeader_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC',
}

export enum ComponentFormLinkingCollectionsTemplatePageCollectionOrder {
  DirectoryAsc = 'directory_ASC',
  DirectoryDesc = 'directory_DESC',
  InternalLabelAsc = 'internalLabel_ASC',
  InternalLabelDesc = 'internalLabel_DESC',
  MetaTitleAsc = 'metaTitle_ASC',
  MetaTitleDesc = 'metaTitle_DESC',
  NoindexNofollowAsc = 'noindexNofollow_ASC',
  NoindexNofollowDesc = 'noindexNofollow_DESC',
  SlugAsc = 'slug_ASC',
  SlugDesc = 'slug_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
}

export enum ComponentFormLinkingCollectionsTemplatePartnersCollectionOrder {
  CollapseHeaderAsc = 'collapseHeader_ASC',
  CollapseHeaderDesc = 'collapseHeader_DESC',
  ShowHeaderAsc = 'showHeader_ASC',
  ShowHeaderDesc = 'showHeader_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC',
}

export enum ComponentFormLinkingCollectionsTemplateReviewsCollectionOrder {
  CollapseHeaderAsc = 'collapseHeader_ASC',
  CollapseHeaderDesc = 'collapseHeader_DESC',
  ShowHeaderAsc = 'showHeader_ASC',
  ShowHeaderDesc = 'showHeader_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC',
}

export enum ComponentFormLinkingCollectionsTemplateWhyBookWithForaCollectionOrder {
  CollapseHeaderAsc = 'collapseHeader_ASC',
  CollapseHeaderDesc = 'collapseHeader_DESC',
  ShowHeaderAsc = 'showHeader_ASC',
  ShowHeaderDesc = 'showHeader_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC',
}

export enum ComponentFormOrder {
  ButtonTextSharedStateAsc = 'buttonTextSharedState_ASC',
  ButtonTextSharedStateDesc = 'buttonTextSharedState_DESC',
  ButtonTextAsc = 'buttonText_ASC',
  ButtonTextDesc = 'buttonText_DESC',
  FormIdAsc = 'formId_ASC',
  FormIdDesc = 'formId_DESC',
  InternalLabelAsc = 'internalLabel_ASC',
  InternalLabelDesc = 'internalLabel_DESC',
  SectionIdAsc = 'sectionId_ASC',
  SectionIdDesc = 'sectionId_DESC',
  SuccessTextAsc = 'successText_ASC',
  SuccessTextDesc = 'successText_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  TitleSharedStateAsc = 'titleSharedState_ASC',
  TitleSharedStateDesc = 'titleSharedState_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC',
  TypeformPopOutUrlAsc = 'typeformPopOutUrl_ASC',
  TypeformPopOutUrlDesc = 'typeformPopOutUrl_DESC',
  TypeformUrlAsc = 'typeformUrl_ASC',
  TypeformUrlDesc = 'typeformUrl_DESC',
  ZapierIdAsc = 'zapierId_ASC',
  ZapierIdDesc = 'zapierId_DESC',
}

/** Create and manage an all site announcement banner [See type definition](https://app.contentful.com/spaces/9cxgygtadmvl/content_types/componentGlobalBanner) */
export type ComponentGlobalBanner = Entry &
  _Node & {
    __typename?: 'ComponentGlobalBanner'
    _id: Scalars['ID']
    backgroundColor?: Maybe<Scalars['String']>
    content?: Maybe<ComponentGlobalBannerContent>
    contentfulMetadata: ContentfulMetadata
    expirationDate?: Maybe<Scalars['DateTime']>
    internalLabel?: Maybe<Scalars['String']>
    isActive?: Maybe<Scalars['Boolean']>
    linkedFrom?: Maybe<ComponentGlobalBannerLinkingCollections>
    sys: Sys
  }

/** Create and manage an all site announcement banner [See type definition](https://app.contentful.com/spaces/9cxgygtadmvl/content_types/componentGlobalBanner) */
export type ComponentGlobalBannerBackgroundColorArgs = {
  locale?: InputMaybe<Scalars['String']>
}

/** Create and manage an all site announcement banner [See type definition](https://app.contentful.com/spaces/9cxgygtadmvl/content_types/componentGlobalBanner) */
export type ComponentGlobalBannerContentArgs = {
  locale?: InputMaybe<Scalars['String']>
}

/** Create and manage an all site announcement banner [See type definition](https://app.contentful.com/spaces/9cxgygtadmvl/content_types/componentGlobalBanner) */
export type ComponentGlobalBannerExpirationDateArgs = {
  locale?: InputMaybe<Scalars['String']>
}

/** Create and manage an all site announcement banner [See type definition](https://app.contentful.com/spaces/9cxgygtadmvl/content_types/componentGlobalBanner) */
export type ComponentGlobalBannerInternalLabelArgs = {
  locale?: InputMaybe<Scalars['String']>
}

/** Create and manage an all site announcement banner [See type definition](https://app.contentful.com/spaces/9cxgygtadmvl/content_types/componentGlobalBanner) */
export type ComponentGlobalBannerIsActiveArgs = {
  locale?: InputMaybe<Scalars['String']>
}

/** Create and manage an all site announcement banner [See type definition](https://app.contentful.com/spaces/9cxgygtadmvl/content_types/componentGlobalBanner) */
export type ComponentGlobalBannerLinkedFromArgs = {
  allowedLocales?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
}

export type ComponentGlobalBannerCollection = {
  __typename?: 'ComponentGlobalBannerCollection'
  items: Array<Maybe<ComponentGlobalBanner>>
  limit: Scalars['Int']
  skip: Scalars['Int']
  total: Scalars['Int']
}

export type ComponentGlobalBannerContent = {
  __typename?: 'ComponentGlobalBannerContent'
  json: Scalars['JSON']
  links: ComponentGlobalBannerContentLinks
}

export type ComponentGlobalBannerContentAssets = {
  __typename?: 'ComponentGlobalBannerContentAssets'
  block: Array<Maybe<Asset>>
  hyperlink: Array<Maybe<Asset>>
}

export type ComponentGlobalBannerContentEntries = {
  __typename?: 'ComponentGlobalBannerContentEntries'
  block: Array<Maybe<Entry>>
  hyperlink: Array<Maybe<Entry>>
  inline: Array<Maybe<Entry>>
}

export type ComponentGlobalBannerContentLinks = {
  __typename?: 'ComponentGlobalBannerContentLinks'
  assets: ComponentGlobalBannerContentAssets
  entries: ComponentGlobalBannerContentEntries
  resources: ComponentGlobalBannerContentResources
}

export type ComponentGlobalBannerContentResources = {
  __typename?: 'ComponentGlobalBannerContentResources'
  block: Array<ComponentGlobalBannerContentResourcesBlock>
  hyperlink: Array<ComponentGlobalBannerContentResourcesHyperlink>
  inline: Array<ComponentGlobalBannerContentResourcesInline>
}

export type ComponentGlobalBannerContentResourcesBlock = ResourceLink & {
  __typename?: 'ComponentGlobalBannerContentResourcesBlock'
  sys: ResourceSys
}

export type ComponentGlobalBannerContentResourcesHyperlink = ResourceLink & {
  __typename?: 'ComponentGlobalBannerContentResourcesHyperlink'
  sys: ResourceSys
}

export type ComponentGlobalBannerContentResourcesInline = ResourceLink & {
  __typename?: 'ComponentGlobalBannerContentResourcesInline'
  sys: ResourceSys
}

export type ComponentGlobalBannerFilter = {
  AND?: InputMaybe<Array<InputMaybe<ComponentGlobalBannerFilter>>>
  OR?: InputMaybe<Array<InputMaybe<ComponentGlobalBannerFilter>>>
  backgroundColor?: InputMaybe<Scalars['String']>
  backgroundColor_contains?: InputMaybe<Scalars['String']>
  backgroundColor_exists?: InputMaybe<Scalars['Boolean']>
  backgroundColor_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  backgroundColor_not?: InputMaybe<Scalars['String']>
  backgroundColor_not_contains?: InputMaybe<Scalars['String']>
  backgroundColor_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  content_contains?: InputMaybe<Scalars['String']>
  content_exists?: InputMaybe<Scalars['Boolean']>
  content_not_contains?: InputMaybe<Scalars['String']>
  contentfulMetadata?: InputMaybe<ContentfulMetadataFilter>
  expirationDate?: InputMaybe<Scalars['DateTime']>
  expirationDate_exists?: InputMaybe<Scalars['Boolean']>
  expirationDate_gt?: InputMaybe<Scalars['DateTime']>
  expirationDate_gte?: InputMaybe<Scalars['DateTime']>
  expirationDate_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']>>>
  expirationDate_lt?: InputMaybe<Scalars['DateTime']>
  expirationDate_lte?: InputMaybe<Scalars['DateTime']>
  expirationDate_not?: InputMaybe<Scalars['DateTime']>
  expirationDate_not_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']>>>
  internalLabel?: InputMaybe<Scalars['String']>
  internalLabel_contains?: InputMaybe<Scalars['String']>
  internalLabel_exists?: InputMaybe<Scalars['Boolean']>
  internalLabel_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  internalLabel_not?: InputMaybe<Scalars['String']>
  internalLabel_not_contains?: InputMaybe<Scalars['String']>
  internalLabel_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  isActive?: InputMaybe<Scalars['Boolean']>
  isActive_exists?: InputMaybe<Scalars['Boolean']>
  isActive_not?: InputMaybe<Scalars['Boolean']>
  sys?: InputMaybe<SysFilter>
}

export type ComponentGlobalBannerLinkingCollections = {
  __typename?: 'ComponentGlobalBannerLinkingCollections'
  entryCollection?: Maybe<EntryCollection>
}

export type ComponentGlobalBannerLinkingCollectionsEntryCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>
  locale?: InputMaybe<Scalars['String']>
  preview?: InputMaybe<Scalars['Boolean']>
  skip?: InputMaybe<Scalars['Int']>
}

export enum ComponentGlobalBannerOrder {
  BackgroundColorAsc = 'backgroundColor_ASC',
  BackgroundColorDesc = 'backgroundColor_DESC',
  ExpirationDateAsc = 'expirationDate_ASC',
  ExpirationDateDesc = 'expirationDate_DESC',
  InternalLabelAsc = 'internalLabel_ASC',
  InternalLabelDesc = 'internalLabel_DESC',
  IsActiveAsc = 'isActive_ASC',
  IsActiveDesc = 'isActive_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
}

/** A simple centered Hero page block with full-width image. [See type definition](https://app.contentful.com/spaces/9cxgygtadmvl/content_types/componentHeroCentered) */
export type ComponentHeroCentered = Entry &
  _Node & {
    __typename?: 'ComponentHeroCentered'
    _id: Scalars['ID']
    analyticsModule?: Maybe<SettingsAnalyticsModule>
    contentfulMetadata: ContentfulMetadata
    ctaText?: Maybe<Scalars['String']>
    ctaUrl?: Maybe<Scalars['String']>
    image?: Maybe<Asset>
    internalLabel?: Maybe<Scalars['String']>
    linkedFrom?: Maybe<ComponentHeroCenteredLinkingCollections>
    sectionId?: Maybe<Scalars['String']>
    sys: Sys
    teaserCopy?: Maybe<ComponentHeroCenteredTeaserCopy>
    title?: Maybe<Scalars['String']>
    titleStyle?: Maybe<Scalars['String']>
    urlTarget?: Maybe<Scalars['String']>
  }

/** A simple centered Hero page block with full-width image. [See type definition](https://app.contentful.com/spaces/9cxgygtadmvl/content_types/componentHeroCentered) */
export type ComponentHeroCenteredAnalyticsModuleArgs = {
  locale?: InputMaybe<Scalars['String']>
  preview?: InputMaybe<Scalars['Boolean']>
  where?: InputMaybe<SettingsAnalyticsModuleFilter>
}

/** A simple centered Hero page block with full-width image. [See type definition](https://app.contentful.com/spaces/9cxgygtadmvl/content_types/componentHeroCentered) */
export type ComponentHeroCenteredCtaTextArgs = {
  locale?: InputMaybe<Scalars['String']>
}

/** A simple centered Hero page block with full-width image. [See type definition](https://app.contentful.com/spaces/9cxgygtadmvl/content_types/componentHeroCentered) */
export type ComponentHeroCenteredCtaUrlArgs = {
  locale?: InputMaybe<Scalars['String']>
}

/** A simple centered Hero page block with full-width image. [See type definition](https://app.contentful.com/spaces/9cxgygtadmvl/content_types/componentHeroCentered) */
export type ComponentHeroCenteredImageArgs = {
  locale?: InputMaybe<Scalars['String']>
  preview?: InputMaybe<Scalars['Boolean']>
}

/** A simple centered Hero page block with full-width image. [See type definition](https://app.contentful.com/spaces/9cxgygtadmvl/content_types/componentHeroCentered) */
export type ComponentHeroCenteredInternalLabelArgs = {
  locale?: InputMaybe<Scalars['String']>
}

/** A simple centered Hero page block with full-width image. [See type definition](https://app.contentful.com/spaces/9cxgygtadmvl/content_types/componentHeroCentered) */
export type ComponentHeroCenteredLinkedFromArgs = {
  allowedLocales?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
}

/** A simple centered Hero page block with full-width image. [See type definition](https://app.contentful.com/spaces/9cxgygtadmvl/content_types/componentHeroCentered) */
export type ComponentHeroCenteredSectionIdArgs = {
  locale?: InputMaybe<Scalars['String']>
}

/** A simple centered Hero page block with full-width image. [See type definition](https://app.contentful.com/spaces/9cxgygtadmvl/content_types/componentHeroCentered) */
export type ComponentHeroCenteredTeaserCopyArgs = {
  locale?: InputMaybe<Scalars['String']>
}

/** A simple centered Hero page block with full-width image. [See type definition](https://app.contentful.com/spaces/9cxgygtadmvl/content_types/componentHeroCentered) */
export type ComponentHeroCenteredTitleArgs = {
  locale?: InputMaybe<Scalars['String']>
}

/** A simple centered Hero page block with full-width image. [See type definition](https://app.contentful.com/spaces/9cxgygtadmvl/content_types/componentHeroCentered) */
export type ComponentHeroCenteredTitleStyleArgs = {
  locale?: InputMaybe<Scalars['String']>
}

/** A simple centered Hero page block with full-width image. [See type definition](https://app.contentful.com/spaces/9cxgygtadmvl/content_types/componentHeroCentered) */
export type ComponentHeroCenteredUrlTargetArgs = {
  locale?: InputMaybe<Scalars['String']>
}

export type ComponentHeroCenteredCollection = {
  __typename?: 'ComponentHeroCenteredCollection'
  items: Array<Maybe<ComponentHeroCentered>>
  limit: Scalars['Int']
  skip: Scalars['Int']
  total: Scalars['Int']
}

export type ComponentHeroCenteredFilter = {
  AND?: InputMaybe<Array<InputMaybe<ComponentHeroCenteredFilter>>>
  OR?: InputMaybe<Array<InputMaybe<ComponentHeroCenteredFilter>>>
  analyticsModule?: InputMaybe<CfSettingsAnalyticsModuleNestedFilter>
  analyticsModule_exists?: InputMaybe<Scalars['Boolean']>
  contentfulMetadata?: InputMaybe<ContentfulMetadataFilter>
  ctaText?: InputMaybe<Scalars['String']>
  ctaText_contains?: InputMaybe<Scalars['String']>
  ctaText_exists?: InputMaybe<Scalars['Boolean']>
  ctaText_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  ctaText_not?: InputMaybe<Scalars['String']>
  ctaText_not_contains?: InputMaybe<Scalars['String']>
  ctaText_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  ctaUrl?: InputMaybe<Scalars['String']>
  ctaUrl_contains?: InputMaybe<Scalars['String']>
  ctaUrl_exists?: InputMaybe<Scalars['Boolean']>
  ctaUrl_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  ctaUrl_not?: InputMaybe<Scalars['String']>
  ctaUrl_not_contains?: InputMaybe<Scalars['String']>
  ctaUrl_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  image_exists?: InputMaybe<Scalars['Boolean']>
  internalLabel?: InputMaybe<Scalars['String']>
  internalLabel_contains?: InputMaybe<Scalars['String']>
  internalLabel_exists?: InputMaybe<Scalars['Boolean']>
  internalLabel_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  internalLabel_not?: InputMaybe<Scalars['String']>
  internalLabel_not_contains?: InputMaybe<Scalars['String']>
  internalLabel_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  sectionId?: InputMaybe<Scalars['String']>
  sectionId_contains?: InputMaybe<Scalars['String']>
  sectionId_exists?: InputMaybe<Scalars['Boolean']>
  sectionId_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  sectionId_not?: InputMaybe<Scalars['String']>
  sectionId_not_contains?: InputMaybe<Scalars['String']>
  sectionId_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  sys?: InputMaybe<SysFilter>
  teaserCopy_contains?: InputMaybe<Scalars['String']>
  teaserCopy_exists?: InputMaybe<Scalars['Boolean']>
  teaserCopy_not_contains?: InputMaybe<Scalars['String']>
  title?: InputMaybe<Scalars['String']>
  titleStyle?: InputMaybe<Scalars['String']>
  titleStyle_contains?: InputMaybe<Scalars['String']>
  titleStyle_exists?: InputMaybe<Scalars['Boolean']>
  titleStyle_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  titleStyle_not?: InputMaybe<Scalars['String']>
  titleStyle_not_contains?: InputMaybe<Scalars['String']>
  titleStyle_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  title_contains?: InputMaybe<Scalars['String']>
  title_exists?: InputMaybe<Scalars['Boolean']>
  title_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  title_not?: InputMaybe<Scalars['String']>
  title_not_contains?: InputMaybe<Scalars['String']>
  title_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  urlTarget?: InputMaybe<Scalars['String']>
  urlTarget_contains?: InputMaybe<Scalars['String']>
  urlTarget_exists?: InputMaybe<Scalars['Boolean']>
  urlTarget_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  urlTarget_not?: InputMaybe<Scalars['String']>
  urlTarget_not_contains?: InputMaybe<Scalars['String']>
  urlTarget_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
}

export type ComponentHeroCenteredLinkingCollections = {
  __typename?: 'ComponentHeroCenteredLinkingCollections'
  campaignPagesCollection?: Maybe<CampaignPagesCollection>
  entryCollection?: Maybe<EntryCollection>
  templateAboutUsCollection?: Maybe<TemplateAboutUsCollection>
  templateBookingCollection?: Maybe<TemplateBookingCollection>
  templateCareersCollection?: Maybe<TemplateCareersCollection>
  templateContactUsCollection?: Maybe<TemplateContactUsCollection>
  templateDestinationsCollection?: Maybe<TemplateDestinationsCollection>
  templateForaReserveCollection?: Maybe<TemplateForaReserveCollection>
  templateForaXCollection?: Maybe<TemplateForaXCollection>
  templateHomeCollection?: Maybe<TemplateHomeCollection>
  templateJoinUsCollection?: Maybe<TemplateJoinUsCollection>
  templateNewsletterCollection?: Maybe<TemplateNewsletterCollection>
  templatePageCollection?: Maybe<TemplatePageCollection>
  templatePartnersCollection?: Maybe<TemplatePartnersCollection>
  templateReviewsCollection?: Maybe<TemplateReviewsCollection>
  templateWhyBookWithForaCollection?: Maybe<TemplateWhyBookWithForaCollection>
}

export type ComponentHeroCenteredLinkingCollectionsCampaignPagesCollectionArgs =
  {
    limit?: InputMaybe<Scalars['Int']>
    locale?: InputMaybe<Scalars['String']>
    order?: InputMaybe<
      Array<
        InputMaybe<ComponentHeroCenteredLinkingCollectionsCampaignPagesCollectionOrder>
      >
    >
    preview?: InputMaybe<Scalars['Boolean']>
    skip?: InputMaybe<Scalars['Int']>
  }

export type ComponentHeroCenteredLinkingCollectionsEntryCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>
  locale?: InputMaybe<Scalars['String']>
  preview?: InputMaybe<Scalars['Boolean']>
  skip?: InputMaybe<Scalars['Int']>
}

export type ComponentHeroCenteredLinkingCollectionsTemplateAboutUsCollectionArgs =
  {
    limit?: InputMaybe<Scalars['Int']>
    locale?: InputMaybe<Scalars['String']>
    order?: InputMaybe<
      Array<
        InputMaybe<ComponentHeroCenteredLinkingCollectionsTemplateAboutUsCollectionOrder>
      >
    >
    preview?: InputMaybe<Scalars['Boolean']>
    skip?: InputMaybe<Scalars['Int']>
  }

export type ComponentHeroCenteredLinkingCollectionsTemplateBookingCollectionArgs =
  {
    limit?: InputMaybe<Scalars['Int']>
    locale?: InputMaybe<Scalars['String']>
    order?: InputMaybe<
      Array<
        InputMaybe<ComponentHeroCenteredLinkingCollectionsTemplateBookingCollectionOrder>
      >
    >
    preview?: InputMaybe<Scalars['Boolean']>
    skip?: InputMaybe<Scalars['Int']>
  }

export type ComponentHeroCenteredLinkingCollectionsTemplateCareersCollectionArgs =
  {
    limit?: InputMaybe<Scalars['Int']>
    locale?: InputMaybe<Scalars['String']>
    order?: InputMaybe<
      Array<
        InputMaybe<ComponentHeroCenteredLinkingCollectionsTemplateCareersCollectionOrder>
      >
    >
    preview?: InputMaybe<Scalars['Boolean']>
    skip?: InputMaybe<Scalars['Int']>
  }

export type ComponentHeroCenteredLinkingCollectionsTemplateContactUsCollectionArgs =
  {
    limit?: InputMaybe<Scalars['Int']>
    locale?: InputMaybe<Scalars['String']>
    order?: InputMaybe<
      Array<
        InputMaybe<ComponentHeroCenteredLinkingCollectionsTemplateContactUsCollectionOrder>
      >
    >
    preview?: InputMaybe<Scalars['Boolean']>
    skip?: InputMaybe<Scalars['Int']>
  }

export type ComponentHeroCenteredLinkingCollectionsTemplateDestinationsCollectionArgs =
  {
    limit?: InputMaybe<Scalars['Int']>
    locale?: InputMaybe<Scalars['String']>
    order?: InputMaybe<
      Array<
        InputMaybe<ComponentHeroCenteredLinkingCollectionsTemplateDestinationsCollectionOrder>
      >
    >
    preview?: InputMaybe<Scalars['Boolean']>
    skip?: InputMaybe<Scalars['Int']>
  }

export type ComponentHeroCenteredLinkingCollectionsTemplateForaReserveCollectionArgs =
  {
    limit?: InputMaybe<Scalars['Int']>
    locale?: InputMaybe<Scalars['String']>
    order?: InputMaybe<
      Array<
        InputMaybe<ComponentHeroCenteredLinkingCollectionsTemplateForaReserveCollectionOrder>
      >
    >
    preview?: InputMaybe<Scalars['Boolean']>
    skip?: InputMaybe<Scalars['Int']>
  }

export type ComponentHeroCenteredLinkingCollectionsTemplateForaXCollectionArgs =
  {
    limit?: InputMaybe<Scalars['Int']>
    locale?: InputMaybe<Scalars['String']>
    order?: InputMaybe<
      Array<
        InputMaybe<ComponentHeroCenteredLinkingCollectionsTemplateForaXCollectionOrder>
      >
    >
    preview?: InputMaybe<Scalars['Boolean']>
    skip?: InputMaybe<Scalars['Int']>
  }

export type ComponentHeroCenteredLinkingCollectionsTemplateHomeCollectionArgs =
  {
    limit?: InputMaybe<Scalars['Int']>
    locale?: InputMaybe<Scalars['String']>
    order?: InputMaybe<
      Array<
        InputMaybe<ComponentHeroCenteredLinkingCollectionsTemplateHomeCollectionOrder>
      >
    >
    preview?: InputMaybe<Scalars['Boolean']>
    skip?: InputMaybe<Scalars['Int']>
  }

export type ComponentHeroCenteredLinkingCollectionsTemplateJoinUsCollectionArgs =
  {
    limit?: InputMaybe<Scalars['Int']>
    locale?: InputMaybe<Scalars['String']>
    order?: InputMaybe<
      Array<
        InputMaybe<ComponentHeroCenteredLinkingCollectionsTemplateJoinUsCollectionOrder>
      >
    >
    preview?: InputMaybe<Scalars['Boolean']>
    skip?: InputMaybe<Scalars['Int']>
  }

export type ComponentHeroCenteredLinkingCollectionsTemplateNewsletterCollectionArgs =
  {
    limit?: InputMaybe<Scalars['Int']>
    locale?: InputMaybe<Scalars['String']>
    order?: InputMaybe<
      Array<
        InputMaybe<ComponentHeroCenteredLinkingCollectionsTemplateNewsletterCollectionOrder>
      >
    >
    preview?: InputMaybe<Scalars['Boolean']>
    skip?: InputMaybe<Scalars['Int']>
  }

export type ComponentHeroCenteredLinkingCollectionsTemplatePageCollectionArgs =
  {
    limit?: InputMaybe<Scalars['Int']>
    locale?: InputMaybe<Scalars['String']>
    order?: InputMaybe<
      Array<
        InputMaybe<ComponentHeroCenteredLinkingCollectionsTemplatePageCollectionOrder>
      >
    >
    preview?: InputMaybe<Scalars['Boolean']>
    skip?: InputMaybe<Scalars['Int']>
  }

export type ComponentHeroCenteredLinkingCollectionsTemplatePartnersCollectionArgs =
  {
    limit?: InputMaybe<Scalars['Int']>
    locale?: InputMaybe<Scalars['String']>
    order?: InputMaybe<
      Array<
        InputMaybe<ComponentHeroCenteredLinkingCollectionsTemplatePartnersCollectionOrder>
      >
    >
    preview?: InputMaybe<Scalars['Boolean']>
    skip?: InputMaybe<Scalars['Int']>
  }

export type ComponentHeroCenteredLinkingCollectionsTemplateReviewsCollectionArgs =
  {
    limit?: InputMaybe<Scalars['Int']>
    locale?: InputMaybe<Scalars['String']>
    order?: InputMaybe<
      Array<
        InputMaybe<ComponentHeroCenteredLinkingCollectionsTemplateReviewsCollectionOrder>
      >
    >
    preview?: InputMaybe<Scalars['Boolean']>
    skip?: InputMaybe<Scalars['Int']>
  }

export type ComponentHeroCenteredLinkingCollectionsTemplateWhyBookWithForaCollectionArgs =
  {
    limit?: InputMaybe<Scalars['Int']>
    locale?: InputMaybe<Scalars['String']>
    order?: InputMaybe<
      Array<
        InputMaybe<ComponentHeroCenteredLinkingCollectionsTemplateWhyBookWithForaCollectionOrder>
      >
    >
    preview?: InputMaybe<Scalars['Boolean']>
    skip?: InputMaybe<Scalars['Int']>
  }

export enum ComponentHeroCenteredLinkingCollectionsCampaignPagesCollectionOrder {
  CollapseFooterAsc = 'collapseFooter_ASC',
  CollapseFooterDesc = 'collapseFooter_DESC',
  CollapseHeaderAsc = 'collapseHeader_ASC',
  CollapseHeaderDesc = 'collapseHeader_DESC',
  HideFooterAsc = 'hideFooter_ASC',
  HideFooterDesc = 'hideFooter_DESC',
  HideGlobalBannerAsc = 'hideGlobalBanner_ASC',
  HideGlobalBannerDesc = 'hideGlobalBanner_DESC',
  JotformIdAsc = 'jotformId_ASC',
  JotformIdDesc = 'jotformId_DESC',
  NoindexNofollowAsc = 'noindexNofollow_ASC',
  NoindexNofollowDesc = 'noindexNofollow_DESC',
  ShowHeaderAsc = 'showHeader_ASC',
  ShowHeaderDesc = 'showHeader_DESC',
  SlugAsc = 'slug_ASC',
  SlugDesc = 'slug_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC',
}

export enum ComponentHeroCenteredLinkingCollectionsTemplateAboutUsCollectionOrder {
  CollapseHeaderAsc = 'collapseHeader_ASC',
  CollapseHeaderDesc = 'collapseHeader_DESC',
  HeaderBlockTitleAsc = 'headerBlockTitle_ASC',
  HeaderBlockTitleDesc = 'headerBlockTitle_DESC',
  ShowHeaderAsc = 'showHeader_ASC',
  ShowHeaderDesc = 'showHeader_DESC',
  SubtitleAsc = 'subtitle_ASC',
  SubtitleDesc = 'subtitle_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC',
}

export enum ComponentHeroCenteredLinkingCollectionsTemplateBookingCollectionOrder {
  CollapseHeaderAsc = 'collapseHeader_ASC',
  CollapseHeaderDesc = 'collapseHeader_DESC',
  ShowHeaderAsc = 'showHeader_ASC',
  ShowHeaderDesc = 'showHeader_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC',
}

export enum ComponentHeroCenteredLinkingCollectionsTemplateCareersCollectionOrder {
  CollapseHeaderAsc = 'collapseHeader_ASC',
  CollapseHeaderDesc = 'collapseHeader_DESC',
  ShowHeaderAsc = 'showHeader_ASC',
  ShowHeaderDesc = 'showHeader_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC',
}

export enum ComponentHeroCenteredLinkingCollectionsTemplateContactUsCollectionOrder {
  CollapseHeaderAsc = 'collapseHeader_ASC',
  CollapseHeaderDesc = 'collapseHeader_DESC',
  ShowHeaderAsc = 'showHeader_ASC',
  ShowHeaderDesc = 'showHeader_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC',
}

export enum ComponentHeroCenteredLinkingCollectionsTemplateDestinationsCollectionOrder {
  CollapseHeaderAsc = 'collapseHeader_ASC',
  CollapseHeaderDesc = 'collapseHeader_DESC',
  CollapsibleHeadingAsc = 'collapsibleHeading_ASC',
  CollapsibleHeadingDesc = 'collapsibleHeading_DESC',
  ShowHeaderAsc = 'showHeader_ASC',
  ShowHeaderDesc = 'showHeader_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC',
}

export enum ComponentHeroCenteredLinkingCollectionsTemplateForaReserveCollectionOrder {
  CollapseHeaderAsc = 'collapseHeader_ASC',
  CollapseHeaderDesc = 'collapseHeader_DESC',
  ShowHeaderAsc = 'showHeader_ASC',
  ShowHeaderDesc = 'showHeader_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC',
}

export enum ComponentHeroCenteredLinkingCollectionsTemplateForaXCollectionOrder {
  AdvisorGridHeaderAsc = 'advisorGridHeader_ASC',
  AdvisorGridHeaderDesc = 'advisorGridHeader_DESC',
  AdvisorGridSubCopyAsc = 'advisorGridSubCopy_ASC',
  AdvisorGridSubCopyDesc = 'advisorGridSubCopy_DESC',
  CollapseHeaderAsc = 'collapseHeader_ASC',
  CollapseHeaderDesc = 'collapseHeader_DESC',
  ShowHeaderAsc = 'showHeader_ASC',
  ShowHeaderDesc = 'showHeader_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC',
}

export enum ComponentHeroCenteredLinkingCollectionsTemplateHomeCollectionOrder {
  CollapseHeaderAsc = 'collapseHeader_ASC',
  CollapseHeaderDesc = 'collapseHeader_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC',
}

export enum ComponentHeroCenteredLinkingCollectionsTemplateJoinUsCollectionOrder {
  CollapseHeaderAsc = 'collapseHeader_ASC',
  CollapseHeaderDesc = 'collapseHeader_DESC',
  HideGlobalBannerAsc = 'hideGlobalBanner_ASC',
  HideGlobalBannerDesc = 'hideGlobalBanner_DESC',
  ShowHeaderAsc = 'showHeader_ASC',
  ShowHeaderDesc = 'showHeader_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC',
}

export enum ComponentHeroCenteredLinkingCollectionsTemplateNewsletterCollectionOrder {
  CollapseHeaderAsc = 'collapseHeader_ASC',
  CollapseHeaderDesc = 'collapseHeader_DESC',
  ShowHeaderAsc = 'showHeader_ASC',
  ShowHeaderDesc = 'showHeader_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC',
}

export enum ComponentHeroCenteredLinkingCollectionsTemplatePageCollectionOrder {
  DirectoryAsc = 'directory_ASC',
  DirectoryDesc = 'directory_DESC',
  InternalLabelAsc = 'internalLabel_ASC',
  InternalLabelDesc = 'internalLabel_DESC',
  MetaTitleAsc = 'metaTitle_ASC',
  MetaTitleDesc = 'metaTitle_DESC',
  NoindexNofollowAsc = 'noindexNofollow_ASC',
  NoindexNofollowDesc = 'noindexNofollow_DESC',
  SlugAsc = 'slug_ASC',
  SlugDesc = 'slug_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
}

export enum ComponentHeroCenteredLinkingCollectionsTemplatePartnersCollectionOrder {
  CollapseHeaderAsc = 'collapseHeader_ASC',
  CollapseHeaderDesc = 'collapseHeader_DESC',
  ShowHeaderAsc = 'showHeader_ASC',
  ShowHeaderDesc = 'showHeader_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC',
}

export enum ComponentHeroCenteredLinkingCollectionsTemplateReviewsCollectionOrder {
  CollapseHeaderAsc = 'collapseHeader_ASC',
  CollapseHeaderDesc = 'collapseHeader_DESC',
  ShowHeaderAsc = 'showHeader_ASC',
  ShowHeaderDesc = 'showHeader_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC',
}

export enum ComponentHeroCenteredLinkingCollectionsTemplateWhyBookWithForaCollectionOrder {
  CollapseHeaderAsc = 'collapseHeader_ASC',
  CollapseHeaderDesc = 'collapseHeader_DESC',
  ShowHeaderAsc = 'showHeader_ASC',
  ShowHeaderDesc = 'showHeader_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC',
}

export enum ComponentHeroCenteredOrder {
  CtaTextAsc = 'ctaText_ASC',
  CtaTextDesc = 'ctaText_DESC',
  CtaUrlAsc = 'ctaUrl_ASC',
  CtaUrlDesc = 'ctaUrl_DESC',
  InternalLabelAsc = 'internalLabel_ASC',
  InternalLabelDesc = 'internalLabel_DESC',
  SectionIdAsc = 'sectionId_ASC',
  SectionIdDesc = 'sectionId_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  TitleStyleAsc = 'titleStyle_ASC',
  TitleStyleDesc = 'titleStyle_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC',
  UrlTargetAsc = 'urlTarget_ASC',
  UrlTargetDesc = 'urlTarget_DESC',
}

export type ComponentHeroCenteredTeaserCopy = {
  __typename?: 'ComponentHeroCenteredTeaserCopy'
  json: Scalars['JSON']
  links: ComponentHeroCenteredTeaserCopyLinks
}

export type ComponentHeroCenteredTeaserCopyAssets = {
  __typename?: 'ComponentHeroCenteredTeaserCopyAssets'
  block: Array<Maybe<Asset>>
  hyperlink: Array<Maybe<Asset>>
}

export type ComponentHeroCenteredTeaserCopyEntries = {
  __typename?: 'ComponentHeroCenteredTeaserCopyEntries'
  block: Array<Maybe<Entry>>
  hyperlink: Array<Maybe<Entry>>
  inline: Array<Maybe<Entry>>
}

export type ComponentHeroCenteredTeaserCopyLinks = {
  __typename?: 'ComponentHeroCenteredTeaserCopyLinks'
  assets: ComponentHeroCenteredTeaserCopyAssets
  entries: ComponentHeroCenteredTeaserCopyEntries
  resources: ComponentHeroCenteredTeaserCopyResources
}

export type ComponentHeroCenteredTeaserCopyResources = {
  __typename?: 'ComponentHeroCenteredTeaserCopyResources'
  block: Array<ComponentHeroCenteredTeaserCopyResourcesBlock>
  hyperlink: Array<ComponentHeroCenteredTeaserCopyResourcesHyperlink>
  inline: Array<ComponentHeroCenteredTeaserCopyResourcesInline>
}

export type ComponentHeroCenteredTeaserCopyResourcesBlock = ResourceLink & {
  __typename?: 'ComponentHeroCenteredTeaserCopyResourcesBlock'
  sys: ResourceSys
}

export type ComponentHeroCenteredTeaserCopyResourcesHyperlink = ResourceLink & {
  __typename?: 'ComponentHeroCenteredTeaserCopyResourcesHyperlink'
  sys: ResourceSys
}

export type ComponentHeroCenteredTeaserCopyResourcesInline = ResourceLink & {
  __typename?: 'ComponentHeroCenteredTeaserCopyResourcesInline'
  sys: ResourceSys
}

/** [See type definition](https://app.contentful.com/spaces/9cxgygtadmvl/content_types/componentHeroFullWidth) */
export type ComponentHeroFullWidth = Entry &
  _Node & {
    __typename?: 'ComponentHeroFullWidth'
    _id: Scalars['ID']
    analyticsModule?: Maybe<SettingsAnalyticsModule>
    bottomText?: Maybe<Scalars['String']>
    buttonCtaText?: Maybe<Scalars['String']>
    buttonCtaUrl?: Maybe<Scalars['String']>
    cloudinaryAsset?: Maybe<Scalars['JSON']>
    contentfulMetadata: ContentfulMetadata
    fallbackImage?: Maybe<Asset>
    image?: Maybe<Asset>
    internalLabel?: Maybe<Scalars['String']>
    lightText?: Maybe<Scalars['Boolean']>
    linkedFrom?: Maybe<ComponentHeroFullWidthLinkingCollections>
    mediaSource?: Maybe<Scalars['String']>
    mobileCloudinaryAsset?: Maybe<Scalars['JSON']>
    mobileImage?: Maybe<Asset>
    mobileMediaSource?: Maybe<Scalars['String']>
    rotatingText?: Maybe<Array<Maybe<Scalars['String']>>>
    sectionId?: Maybe<Scalars['String']>
    showAverageRating?: Maybe<Scalars['Boolean']>
    showForaLogo?: Maybe<Scalars['Boolean']>
    subCopy?: Maybe<Scalars['String']>
    sys: Sys
    title?: Maybe<Scalars['String']>
    urlTarget?: Maybe<Scalars['String']>
  }

/** [See type definition](https://app.contentful.com/spaces/9cxgygtadmvl/content_types/componentHeroFullWidth) */
export type ComponentHeroFullWidthAnalyticsModuleArgs = {
  locale?: InputMaybe<Scalars['String']>
  preview?: InputMaybe<Scalars['Boolean']>
  where?: InputMaybe<SettingsAnalyticsModuleFilter>
}

/** [See type definition](https://app.contentful.com/spaces/9cxgygtadmvl/content_types/componentHeroFullWidth) */
export type ComponentHeroFullWidthBottomTextArgs = {
  locale?: InputMaybe<Scalars['String']>
}

/** [See type definition](https://app.contentful.com/spaces/9cxgygtadmvl/content_types/componentHeroFullWidth) */
export type ComponentHeroFullWidthButtonCtaTextArgs = {
  locale?: InputMaybe<Scalars['String']>
}

/** [See type definition](https://app.contentful.com/spaces/9cxgygtadmvl/content_types/componentHeroFullWidth) */
export type ComponentHeroFullWidthButtonCtaUrlArgs = {
  locale?: InputMaybe<Scalars['String']>
}

/** [See type definition](https://app.contentful.com/spaces/9cxgygtadmvl/content_types/componentHeroFullWidth) */
export type ComponentHeroFullWidthCloudinaryAssetArgs = {
  locale?: InputMaybe<Scalars['String']>
}

/** [See type definition](https://app.contentful.com/spaces/9cxgygtadmvl/content_types/componentHeroFullWidth) */
export type ComponentHeroFullWidthFallbackImageArgs = {
  locale?: InputMaybe<Scalars['String']>
  preview?: InputMaybe<Scalars['Boolean']>
}

/** [See type definition](https://app.contentful.com/spaces/9cxgygtadmvl/content_types/componentHeroFullWidth) */
export type ComponentHeroFullWidthImageArgs = {
  locale?: InputMaybe<Scalars['String']>
  preview?: InputMaybe<Scalars['Boolean']>
}

/** [See type definition](https://app.contentful.com/spaces/9cxgygtadmvl/content_types/componentHeroFullWidth) */
export type ComponentHeroFullWidthInternalLabelArgs = {
  locale?: InputMaybe<Scalars['String']>
}

/** [See type definition](https://app.contentful.com/spaces/9cxgygtadmvl/content_types/componentHeroFullWidth) */
export type ComponentHeroFullWidthLightTextArgs = {
  locale?: InputMaybe<Scalars['String']>
}

/** [See type definition](https://app.contentful.com/spaces/9cxgygtadmvl/content_types/componentHeroFullWidth) */
export type ComponentHeroFullWidthLinkedFromArgs = {
  allowedLocales?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
}

/** [See type definition](https://app.contentful.com/spaces/9cxgygtadmvl/content_types/componentHeroFullWidth) */
export type ComponentHeroFullWidthMediaSourceArgs = {
  locale?: InputMaybe<Scalars['String']>
}

/** [See type definition](https://app.contentful.com/spaces/9cxgygtadmvl/content_types/componentHeroFullWidth) */
export type ComponentHeroFullWidthMobileCloudinaryAssetArgs = {
  locale?: InputMaybe<Scalars['String']>
}

/** [See type definition](https://app.contentful.com/spaces/9cxgygtadmvl/content_types/componentHeroFullWidth) */
export type ComponentHeroFullWidthMobileImageArgs = {
  locale?: InputMaybe<Scalars['String']>
  preview?: InputMaybe<Scalars['Boolean']>
}

/** [See type definition](https://app.contentful.com/spaces/9cxgygtadmvl/content_types/componentHeroFullWidth) */
export type ComponentHeroFullWidthMobileMediaSourceArgs = {
  locale?: InputMaybe<Scalars['String']>
}

/** [See type definition](https://app.contentful.com/spaces/9cxgygtadmvl/content_types/componentHeroFullWidth) */
export type ComponentHeroFullWidthRotatingTextArgs = {
  locale?: InputMaybe<Scalars['String']>
}

/** [See type definition](https://app.contentful.com/spaces/9cxgygtadmvl/content_types/componentHeroFullWidth) */
export type ComponentHeroFullWidthSectionIdArgs = {
  locale?: InputMaybe<Scalars['String']>
}

/** [See type definition](https://app.contentful.com/spaces/9cxgygtadmvl/content_types/componentHeroFullWidth) */
export type ComponentHeroFullWidthShowAverageRatingArgs = {
  locale?: InputMaybe<Scalars['String']>
}

/** [See type definition](https://app.contentful.com/spaces/9cxgygtadmvl/content_types/componentHeroFullWidth) */
export type ComponentHeroFullWidthShowForaLogoArgs = {
  locale?: InputMaybe<Scalars['String']>
}

/** [See type definition](https://app.contentful.com/spaces/9cxgygtadmvl/content_types/componentHeroFullWidth) */
export type ComponentHeroFullWidthSubCopyArgs = {
  locale?: InputMaybe<Scalars['String']>
}

/** [See type definition](https://app.contentful.com/spaces/9cxgygtadmvl/content_types/componentHeroFullWidth) */
export type ComponentHeroFullWidthTitleArgs = {
  locale?: InputMaybe<Scalars['String']>
}

/** [See type definition](https://app.contentful.com/spaces/9cxgygtadmvl/content_types/componentHeroFullWidth) */
export type ComponentHeroFullWidthUrlTargetArgs = {
  locale?: InputMaybe<Scalars['String']>
}

export type ComponentHeroFullWidthCollection = {
  __typename?: 'ComponentHeroFullWidthCollection'
  items: Array<Maybe<ComponentHeroFullWidth>>
  limit: Scalars['Int']
  skip: Scalars['Int']
  total: Scalars['Int']
}

export type ComponentHeroFullWidthFilter = {
  AND?: InputMaybe<Array<InputMaybe<ComponentHeroFullWidthFilter>>>
  OR?: InputMaybe<Array<InputMaybe<ComponentHeroFullWidthFilter>>>
  analyticsModule?: InputMaybe<CfSettingsAnalyticsModuleNestedFilter>
  analyticsModule_exists?: InputMaybe<Scalars['Boolean']>
  bottomText?: InputMaybe<Scalars['String']>
  bottomText_contains?: InputMaybe<Scalars['String']>
  bottomText_exists?: InputMaybe<Scalars['Boolean']>
  bottomText_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  bottomText_not?: InputMaybe<Scalars['String']>
  bottomText_not_contains?: InputMaybe<Scalars['String']>
  bottomText_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  buttonCtaText?: InputMaybe<Scalars['String']>
  buttonCtaText_contains?: InputMaybe<Scalars['String']>
  buttonCtaText_exists?: InputMaybe<Scalars['Boolean']>
  buttonCtaText_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  buttonCtaText_not?: InputMaybe<Scalars['String']>
  buttonCtaText_not_contains?: InputMaybe<Scalars['String']>
  buttonCtaText_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  buttonCtaUrl?: InputMaybe<Scalars['String']>
  buttonCtaUrl_contains?: InputMaybe<Scalars['String']>
  buttonCtaUrl_exists?: InputMaybe<Scalars['Boolean']>
  buttonCtaUrl_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  buttonCtaUrl_not?: InputMaybe<Scalars['String']>
  buttonCtaUrl_not_contains?: InputMaybe<Scalars['String']>
  buttonCtaUrl_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  cloudinaryAsset_exists?: InputMaybe<Scalars['Boolean']>
  contentfulMetadata?: InputMaybe<ContentfulMetadataFilter>
  fallbackImage_exists?: InputMaybe<Scalars['Boolean']>
  image_exists?: InputMaybe<Scalars['Boolean']>
  internalLabel?: InputMaybe<Scalars['String']>
  internalLabel_contains?: InputMaybe<Scalars['String']>
  internalLabel_exists?: InputMaybe<Scalars['Boolean']>
  internalLabel_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  internalLabel_not?: InputMaybe<Scalars['String']>
  internalLabel_not_contains?: InputMaybe<Scalars['String']>
  internalLabel_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  lightText?: InputMaybe<Scalars['Boolean']>
  lightText_exists?: InputMaybe<Scalars['Boolean']>
  lightText_not?: InputMaybe<Scalars['Boolean']>
  mediaSource?: InputMaybe<Scalars['String']>
  mediaSource_contains?: InputMaybe<Scalars['String']>
  mediaSource_exists?: InputMaybe<Scalars['Boolean']>
  mediaSource_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  mediaSource_not?: InputMaybe<Scalars['String']>
  mediaSource_not_contains?: InputMaybe<Scalars['String']>
  mediaSource_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  mobileCloudinaryAsset_exists?: InputMaybe<Scalars['Boolean']>
  mobileImage_exists?: InputMaybe<Scalars['Boolean']>
  mobileMediaSource?: InputMaybe<Scalars['String']>
  mobileMediaSource_contains?: InputMaybe<Scalars['String']>
  mobileMediaSource_exists?: InputMaybe<Scalars['Boolean']>
  mobileMediaSource_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  mobileMediaSource_not?: InputMaybe<Scalars['String']>
  mobileMediaSource_not_contains?: InputMaybe<Scalars['String']>
  mobileMediaSource_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  rotatingText_contains_all?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  rotatingText_contains_none?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  rotatingText_contains_some?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  rotatingText_exists?: InputMaybe<Scalars['Boolean']>
  sectionId?: InputMaybe<Scalars['String']>
  sectionId_contains?: InputMaybe<Scalars['String']>
  sectionId_exists?: InputMaybe<Scalars['Boolean']>
  sectionId_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  sectionId_not?: InputMaybe<Scalars['String']>
  sectionId_not_contains?: InputMaybe<Scalars['String']>
  sectionId_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  showAverageRating?: InputMaybe<Scalars['Boolean']>
  showAverageRating_exists?: InputMaybe<Scalars['Boolean']>
  showAverageRating_not?: InputMaybe<Scalars['Boolean']>
  showForaLogo?: InputMaybe<Scalars['Boolean']>
  showForaLogo_exists?: InputMaybe<Scalars['Boolean']>
  showForaLogo_not?: InputMaybe<Scalars['Boolean']>
  subCopy?: InputMaybe<Scalars['String']>
  subCopy_contains?: InputMaybe<Scalars['String']>
  subCopy_exists?: InputMaybe<Scalars['Boolean']>
  subCopy_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  subCopy_not?: InputMaybe<Scalars['String']>
  subCopy_not_contains?: InputMaybe<Scalars['String']>
  subCopy_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  sys?: InputMaybe<SysFilter>
  title?: InputMaybe<Scalars['String']>
  title_contains?: InputMaybe<Scalars['String']>
  title_exists?: InputMaybe<Scalars['Boolean']>
  title_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  title_not?: InputMaybe<Scalars['String']>
  title_not_contains?: InputMaybe<Scalars['String']>
  title_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  urlTarget?: InputMaybe<Scalars['String']>
  urlTarget_contains?: InputMaybe<Scalars['String']>
  urlTarget_exists?: InputMaybe<Scalars['Boolean']>
  urlTarget_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  urlTarget_not?: InputMaybe<Scalars['String']>
  urlTarget_not_contains?: InputMaybe<Scalars['String']>
  urlTarget_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
}

export type ComponentHeroFullWidthLinkingCollections = {
  __typename?: 'ComponentHeroFullWidthLinkingCollections'
  campaignPagesCollection?: Maybe<CampaignPagesCollection>
  entryCollection?: Maybe<EntryCollection>
  templateAboutUsCollection?: Maybe<TemplateAboutUsCollection>
  templateBookingCollection?: Maybe<TemplateBookingCollection>
  templateCareersCollection?: Maybe<TemplateCareersCollection>
  templateContactUsCollection?: Maybe<TemplateContactUsCollection>
  templateDestinationsCollection?: Maybe<TemplateDestinationsCollection>
  templateEventsCollection?: Maybe<TemplateEventsCollection>
  templateForaReserveCollection?: Maybe<TemplateForaReserveCollection>
  templateForaXCollection?: Maybe<TemplateForaXCollection>
  templateHomeCollection?: Maybe<TemplateHomeCollection>
  templateJoinUsCollection?: Maybe<TemplateJoinUsCollection>
  templateNewsletterCollection?: Maybe<TemplateNewsletterCollection>
  templatePageCollection?: Maybe<TemplatePageCollection>
  templatePartnersCollection?: Maybe<TemplatePartnersCollection>
  templateReviewsCollection?: Maybe<TemplateReviewsCollection>
  templateWhyBookWithForaCollection?: Maybe<TemplateWhyBookWithForaCollection>
}

export type ComponentHeroFullWidthLinkingCollectionsCampaignPagesCollectionArgs =
  {
    limit?: InputMaybe<Scalars['Int']>
    locale?: InputMaybe<Scalars['String']>
    order?: InputMaybe<
      Array<
        InputMaybe<ComponentHeroFullWidthLinkingCollectionsCampaignPagesCollectionOrder>
      >
    >
    preview?: InputMaybe<Scalars['Boolean']>
    skip?: InputMaybe<Scalars['Int']>
  }

export type ComponentHeroFullWidthLinkingCollectionsEntryCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>
  locale?: InputMaybe<Scalars['String']>
  preview?: InputMaybe<Scalars['Boolean']>
  skip?: InputMaybe<Scalars['Int']>
}

export type ComponentHeroFullWidthLinkingCollectionsTemplateAboutUsCollectionArgs =
  {
    limit?: InputMaybe<Scalars['Int']>
    locale?: InputMaybe<Scalars['String']>
    order?: InputMaybe<
      Array<
        InputMaybe<ComponentHeroFullWidthLinkingCollectionsTemplateAboutUsCollectionOrder>
      >
    >
    preview?: InputMaybe<Scalars['Boolean']>
    skip?: InputMaybe<Scalars['Int']>
  }

export type ComponentHeroFullWidthLinkingCollectionsTemplateBookingCollectionArgs =
  {
    limit?: InputMaybe<Scalars['Int']>
    locale?: InputMaybe<Scalars['String']>
    order?: InputMaybe<
      Array<
        InputMaybe<ComponentHeroFullWidthLinkingCollectionsTemplateBookingCollectionOrder>
      >
    >
    preview?: InputMaybe<Scalars['Boolean']>
    skip?: InputMaybe<Scalars['Int']>
  }

export type ComponentHeroFullWidthLinkingCollectionsTemplateCareersCollectionArgs =
  {
    limit?: InputMaybe<Scalars['Int']>
    locale?: InputMaybe<Scalars['String']>
    order?: InputMaybe<
      Array<
        InputMaybe<ComponentHeroFullWidthLinkingCollectionsTemplateCareersCollectionOrder>
      >
    >
    preview?: InputMaybe<Scalars['Boolean']>
    skip?: InputMaybe<Scalars['Int']>
  }

export type ComponentHeroFullWidthLinkingCollectionsTemplateContactUsCollectionArgs =
  {
    limit?: InputMaybe<Scalars['Int']>
    locale?: InputMaybe<Scalars['String']>
    order?: InputMaybe<
      Array<
        InputMaybe<ComponentHeroFullWidthLinkingCollectionsTemplateContactUsCollectionOrder>
      >
    >
    preview?: InputMaybe<Scalars['Boolean']>
    skip?: InputMaybe<Scalars['Int']>
  }

export type ComponentHeroFullWidthLinkingCollectionsTemplateDestinationsCollectionArgs =
  {
    limit?: InputMaybe<Scalars['Int']>
    locale?: InputMaybe<Scalars['String']>
    order?: InputMaybe<
      Array<
        InputMaybe<ComponentHeroFullWidthLinkingCollectionsTemplateDestinationsCollectionOrder>
      >
    >
    preview?: InputMaybe<Scalars['Boolean']>
    skip?: InputMaybe<Scalars['Int']>
  }

export type ComponentHeroFullWidthLinkingCollectionsTemplateEventsCollectionArgs =
  {
    limit?: InputMaybe<Scalars['Int']>
    locale?: InputMaybe<Scalars['String']>
    order?: InputMaybe<
      Array<
        InputMaybe<ComponentHeroFullWidthLinkingCollectionsTemplateEventsCollectionOrder>
      >
    >
    preview?: InputMaybe<Scalars['Boolean']>
    skip?: InputMaybe<Scalars['Int']>
  }

export type ComponentHeroFullWidthLinkingCollectionsTemplateForaReserveCollectionArgs =
  {
    limit?: InputMaybe<Scalars['Int']>
    locale?: InputMaybe<Scalars['String']>
    order?: InputMaybe<
      Array<
        InputMaybe<ComponentHeroFullWidthLinkingCollectionsTemplateForaReserveCollectionOrder>
      >
    >
    preview?: InputMaybe<Scalars['Boolean']>
    skip?: InputMaybe<Scalars['Int']>
  }

export type ComponentHeroFullWidthLinkingCollectionsTemplateForaXCollectionArgs =
  {
    limit?: InputMaybe<Scalars['Int']>
    locale?: InputMaybe<Scalars['String']>
    order?: InputMaybe<
      Array<
        InputMaybe<ComponentHeroFullWidthLinkingCollectionsTemplateForaXCollectionOrder>
      >
    >
    preview?: InputMaybe<Scalars['Boolean']>
    skip?: InputMaybe<Scalars['Int']>
  }

export type ComponentHeroFullWidthLinkingCollectionsTemplateHomeCollectionArgs =
  {
    limit?: InputMaybe<Scalars['Int']>
    locale?: InputMaybe<Scalars['String']>
    order?: InputMaybe<
      Array<
        InputMaybe<ComponentHeroFullWidthLinkingCollectionsTemplateHomeCollectionOrder>
      >
    >
    preview?: InputMaybe<Scalars['Boolean']>
    skip?: InputMaybe<Scalars['Int']>
  }

export type ComponentHeroFullWidthLinkingCollectionsTemplateJoinUsCollectionArgs =
  {
    limit?: InputMaybe<Scalars['Int']>
    locale?: InputMaybe<Scalars['String']>
    order?: InputMaybe<
      Array<
        InputMaybe<ComponentHeroFullWidthLinkingCollectionsTemplateJoinUsCollectionOrder>
      >
    >
    preview?: InputMaybe<Scalars['Boolean']>
    skip?: InputMaybe<Scalars['Int']>
  }

export type ComponentHeroFullWidthLinkingCollectionsTemplateNewsletterCollectionArgs =
  {
    limit?: InputMaybe<Scalars['Int']>
    locale?: InputMaybe<Scalars['String']>
    order?: InputMaybe<
      Array<
        InputMaybe<ComponentHeroFullWidthLinkingCollectionsTemplateNewsletterCollectionOrder>
      >
    >
    preview?: InputMaybe<Scalars['Boolean']>
    skip?: InputMaybe<Scalars['Int']>
  }

export type ComponentHeroFullWidthLinkingCollectionsTemplatePageCollectionArgs =
  {
    limit?: InputMaybe<Scalars['Int']>
    locale?: InputMaybe<Scalars['String']>
    order?: InputMaybe<
      Array<
        InputMaybe<ComponentHeroFullWidthLinkingCollectionsTemplatePageCollectionOrder>
      >
    >
    preview?: InputMaybe<Scalars['Boolean']>
    skip?: InputMaybe<Scalars['Int']>
  }

export type ComponentHeroFullWidthLinkingCollectionsTemplatePartnersCollectionArgs =
  {
    limit?: InputMaybe<Scalars['Int']>
    locale?: InputMaybe<Scalars['String']>
    order?: InputMaybe<
      Array<
        InputMaybe<ComponentHeroFullWidthLinkingCollectionsTemplatePartnersCollectionOrder>
      >
    >
    preview?: InputMaybe<Scalars['Boolean']>
    skip?: InputMaybe<Scalars['Int']>
  }

export type ComponentHeroFullWidthLinkingCollectionsTemplateReviewsCollectionArgs =
  {
    limit?: InputMaybe<Scalars['Int']>
    locale?: InputMaybe<Scalars['String']>
    order?: InputMaybe<
      Array<
        InputMaybe<ComponentHeroFullWidthLinkingCollectionsTemplateReviewsCollectionOrder>
      >
    >
    preview?: InputMaybe<Scalars['Boolean']>
    skip?: InputMaybe<Scalars['Int']>
  }

export type ComponentHeroFullWidthLinkingCollectionsTemplateWhyBookWithForaCollectionArgs =
  {
    limit?: InputMaybe<Scalars['Int']>
    locale?: InputMaybe<Scalars['String']>
    order?: InputMaybe<
      Array<
        InputMaybe<ComponentHeroFullWidthLinkingCollectionsTemplateWhyBookWithForaCollectionOrder>
      >
    >
    preview?: InputMaybe<Scalars['Boolean']>
    skip?: InputMaybe<Scalars['Int']>
  }

export enum ComponentHeroFullWidthLinkingCollectionsCampaignPagesCollectionOrder {
  CollapseFooterAsc = 'collapseFooter_ASC',
  CollapseFooterDesc = 'collapseFooter_DESC',
  CollapseHeaderAsc = 'collapseHeader_ASC',
  CollapseHeaderDesc = 'collapseHeader_DESC',
  HideFooterAsc = 'hideFooter_ASC',
  HideFooterDesc = 'hideFooter_DESC',
  HideGlobalBannerAsc = 'hideGlobalBanner_ASC',
  HideGlobalBannerDesc = 'hideGlobalBanner_DESC',
  JotformIdAsc = 'jotformId_ASC',
  JotformIdDesc = 'jotformId_DESC',
  NoindexNofollowAsc = 'noindexNofollow_ASC',
  NoindexNofollowDesc = 'noindexNofollow_DESC',
  ShowHeaderAsc = 'showHeader_ASC',
  ShowHeaderDesc = 'showHeader_DESC',
  SlugAsc = 'slug_ASC',
  SlugDesc = 'slug_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC',
}

export enum ComponentHeroFullWidthLinkingCollectionsTemplateAboutUsCollectionOrder {
  CollapseHeaderAsc = 'collapseHeader_ASC',
  CollapseHeaderDesc = 'collapseHeader_DESC',
  HeaderBlockTitleAsc = 'headerBlockTitle_ASC',
  HeaderBlockTitleDesc = 'headerBlockTitle_DESC',
  ShowHeaderAsc = 'showHeader_ASC',
  ShowHeaderDesc = 'showHeader_DESC',
  SubtitleAsc = 'subtitle_ASC',
  SubtitleDesc = 'subtitle_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC',
}

export enum ComponentHeroFullWidthLinkingCollectionsTemplateBookingCollectionOrder {
  CollapseHeaderAsc = 'collapseHeader_ASC',
  CollapseHeaderDesc = 'collapseHeader_DESC',
  ShowHeaderAsc = 'showHeader_ASC',
  ShowHeaderDesc = 'showHeader_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC',
}

export enum ComponentHeroFullWidthLinkingCollectionsTemplateCareersCollectionOrder {
  CollapseHeaderAsc = 'collapseHeader_ASC',
  CollapseHeaderDesc = 'collapseHeader_DESC',
  ShowHeaderAsc = 'showHeader_ASC',
  ShowHeaderDesc = 'showHeader_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC',
}

export enum ComponentHeroFullWidthLinkingCollectionsTemplateContactUsCollectionOrder {
  CollapseHeaderAsc = 'collapseHeader_ASC',
  CollapseHeaderDesc = 'collapseHeader_DESC',
  ShowHeaderAsc = 'showHeader_ASC',
  ShowHeaderDesc = 'showHeader_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC',
}

export enum ComponentHeroFullWidthLinkingCollectionsTemplateDestinationsCollectionOrder {
  CollapseHeaderAsc = 'collapseHeader_ASC',
  CollapseHeaderDesc = 'collapseHeader_DESC',
  CollapsibleHeadingAsc = 'collapsibleHeading_ASC',
  CollapsibleHeadingDesc = 'collapsibleHeading_DESC',
  ShowHeaderAsc = 'showHeader_ASC',
  ShowHeaderDesc = 'showHeader_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC',
}

export enum ComponentHeroFullWidthLinkingCollectionsTemplateEventsCollectionOrder {
  CollapseHeaderAsc = 'collapseHeader_ASC',
  CollapseHeaderDesc = 'collapseHeader_DESC',
  ShowHeaderAsc = 'showHeader_ASC',
  ShowHeaderDesc = 'showHeader_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC',
}

export enum ComponentHeroFullWidthLinkingCollectionsTemplateForaReserveCollectionOrder {
  CollapseHeaderAsc = 'collapseHeader_ASC',
  CollapseHeaderDesc = 'collapseHeader_DESC',
  ShowHeaderAsc = 'showHeader_ASC',
  ShowHeaderDesc = 'showHeader_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC',
}

export enum ComponentHeroFullWidthLinkingCollectionsTemplateForaXCollectionOrder {
  AdvisorGridHeaderAsc = 'advisorGridHeader_ASC',
  AdvisorGridHeaderDesc = 'advisorGridHeader_DESC',
  AdvisorGridSubCopyAsc = 'advisorGridSubCopy_ASC',
  AdvisorGridSubCopyDesc = 'advisorGridSubCopy_DESC',
  CollapseHeaderAsc = 'collapseHeader_ASC',
  CollapseHeaderDesc = 'collapseHeader_DESC',
  ShowHeaderAsc = 'showHeader_ASC',
  ShowHeaderDesc = 'showHeader_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC',
}

export enum ComponentHeroFullWidthLinkingCollectionsTemplateHomeCollectionOrder {
  CollapseHeaderAsc = 'collapseHeader_ASC',
  CollapseHeaderDesc = 'collapseHeader_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC',
}

export enum ComponentHeroFullWidthLinkingCollectionsTemplateJoinUsCollectionOrder {
  CollapseHeaderAsc = 'collapseHeader_ASC',
  CollapseHeaderDesc = 'collapseHeader_DESC',
  HideGlobalBannerAsc = 'hideGlobalBanner_ASC',
  HideGlobalBannerDesc = 'hideGlobalBanner_DESC',
  ShowHeaderAsc = 'showHeader_ASC',
  ShowHeaderDesc = 'showHeader_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC',
}

export enum ComponentHeroFullWidthLinkingCollectionsTemplateNewsletterCollectionOrder {
  CollapseHeaderAsc = 'collapseHeader_ASC',
  CollapseHeaderDesc = 'collapseHeader_DESC',
  ShowHeaderAsc = 'showHeader_ASC',
  ShowHeaderDesc = 'showHeader_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC',
}

export enum ComponentHeroFullWidthLinkingCollectionsTemplatePageCollectionOrder {
  DirectoryAsc = 'directory_ASC',
  DirectoryDesc = 'directory_DESC',
  InternalLabelAsc = 'internalLabel_ASC',
  InternalLabelDesc = 'internalLabel_DESC',
  MetaTitleAsc = 'metaTitle_ASC',
  MetaTitleDesc = 'metaTitle_DESC',
  NoindexNofollowAsc = 'noindexNofollow_ASC',
  NoindexNofollowDesc = 'noindexNofollow_DESC',
  SlugAsc = 'slug_ASC',
  SlugDesc = 'slug_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
}

export enum ComponentHeroFullWidthLinkingCollectionsTemplatePartnersCollectionOrder {
  CollapseHeaderAsc = 'collapseHeader_ASC',
  CollapseHeaderDesc = 'collapseHeader_DESC',
  ShowHeaderAsc = 'showHeader_ASC',
  ShowHeaderDesc = 'showHeader_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC',
}

export enum ComponentHeroFullWidthLinkingCollectionsTemplateReviewsCollectionOrder {
  CollapseHeaderAsc = 'collapseHeader_ASC',
  CollapseHeaderDesc = 'collapseHeader_DESC',
  ShowHeaderAsc = 'showHeader_ASC',
  ShowHeaderDesc = 'showHeader_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC',
}

export enum ComponentHeroFullWidthLinkingCollectionsTemplateWhyBookWithForaCollectionOrder {
  CollapseHeaderAsc = 'collapseHeader_ASC',
  CollapseHeaderDesc = 'collapseHeader_DESC',
  ShowHeaderAsc = 'showHeader_ASC',
  ShowHeaderDesc = 'showHeader_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC',
}

export enum ComponentHeroFullWidthOrder {
  BottomTextAsc = 'bottomText_ASC',
  BottomTextDesc = 'bottomText_DESC',
  ButtonCtaTextAsc = 'buttonCtaText_ASC',
  ButtonCtaTextDesc = 'buttonCtaText_DESC',
  ButtonCtaUrlAsc = 'buttonCtaUrl_ASC',
  ButtonCtaUrlDesc = 'buttonCtaUrl_DESC',
  InternalLabelAsc = 'internalLabel_ASC',
  InternalLabelDesc = 'internalLabel_DESC',
  LightTextAsc = 'lightText_ASC',
  LightTextDesc = 'lightText_DESC',
  MediaSourceAsc = 'mediaSource_ASC',
  MediaSourceDesc = 'mediaSource_DESC',
  MobileMediaSourceAsc = 'mobileMediaSource_ASC',
  MobileMediaSourceDesc = 'mobileMediaSource_DESC',
  SectionIdAsc = 'sectionId_ASC',
  SectionIdDesc = 'sectionId_DESC',
  ShowAverageRatingAsc = 'showAverageRating_ASC',
  ShowAverageRatingDesc = 'showAverageRating_DESC',
  ShowForaLogoAsc = 'showForaLogo_ASC',
  ShowForaLogoDesc = 'showForaLogo_DESC',
  SubCopyAsc = 'subCopy_ASC',
  SubCopyDesc = 'subCopy_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC',
  UrlTargetAsc = 'urlTarget_ASC',
  UrlTargetDesc = 'urlTarget_DESC',
}

/** [See type definition](https://app.contentful.com/spaces/9cxgygtadmvl/content_types/componentImage) */
export type ComponentImage = Entry &
  _Node & {
    __typename?: 'ComponentImage'
    _id: Scalars['ID']
    altText?: Maybe<Scalars['String']>
    analyticsModule?: Maybe<SettingsAnalyticsModule>
    cloudinaryImage?: Maybe<Scalars['JSON']>
    contentfulMetadata: ContentfulMetadata
    file?: Maybe<Asset>
    imageCredit?: Maybe<Scalars['String']>
    internalTitle?: Maybe<Scalars['String']>
    linkedFrom?: Maybe<ComponentImageLinkingCollections>
    sys: Sys
  }

/** [See type definition](https://app.contentful.com/spaces/9cxgygtadmvl/content_types/componentImage) */
export type ComponentImageAltTextArgs = {
  locale?: InputMaybe<Scalars['String']>
}

/** [See type definition](https://app.contentful.com/spaces/9cxgygtadmvl/content_types/componentImage) */
export type ComponentImageAnalyticsModuleArgs = {
  locale?: InputMaybe<Scalars['String']>
  preview?: InputMaybe<Scalars['Boolean']>
  where?: InputMaybe<SettingsAnalyticsModuleFilter>
}

/** [See type definition](https://app.contentful.com/spaces/9cxgygtadmvl/content_types/componentImage) */
export type ComponentImageCloudinaryImageArgs = {
  locale?: InputMaybe<Scalars['String']>
}

/** [See type definition](https://app.contentful.com/spaces/9cxgygtadmvl/content_types/componentImage) */
export type ComponentImageFileArgs = {
  locale?: InputMaybe<Scalars['String']>
  preview?: InputMaybe<Scalars['Boolean']>
}

/** [See type definition](https://app.contentful.com/spaces/9cxgygtadmvl/content_types/componentImage) */
export type ComponentImageImageCreditArgs = {
  locale?: InputMaybe<Scalars['String']>
}

/** [See type definition](https://app.contentful.com/spaces/9cxgygtadmvl/content_types/componentImage) */
export type ComponentImageInternalTitleArgs = {
  locale?: InputMaybe<Scalars['String']>
}

/** [See type definition](https://app.contentful.com/spaces/9cxgygtadmvl/content_types/componentImage) */
export type ComponentImageLinkedFromArgs = {
  allowedLocales?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
}

export type ComponentImageCollection = {
  __typename?: 'ComponentImageCollection'
  items: Array<Maybe<ComponentImage>>
  limit: Scalars['Int']
  skip: Scalars['Int']
  total: Scalars['Int']
}

export type ComponentImageFilter = {
  AND?: InputMaybe<Array<InputMaybe<ComponentImageFilter>>>
  OR?: InputMaybe<Array<InputMaybe<ComponentImageFilter>>>
  altText?: InputMaybe<Scalars['String']>
  altText_contains?: InputMaybe<Scalars['String']>
  altText_exists?: InputMaybe<Scalars['Boolean']>
  altText_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  altText_not?: InputMaybe<Scalars['String']>
  altText_not_contains?: InputMaybe<Scalars['String']>
  altText_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  analyticsModule?: InputMaybe<CfSettingsAnalyticsModuleNestedFilter>
  analyticsModule_exists?: InputMaybe<Scalars['Boolean']>
  cloudinaryImage_exists?: InputMaybe<Scalars['Boolean']>
  contentfulMetadata?: InputMaybe<ContentfulMetadataFilter>
  file_exists?: InputMaybe<Scalars['Boolean']>
  imageCredit?: InputMaybe<Scalars['String']>
  imageCredit_contains?: InputMaybe<Scalars['String']>
  imageCredit_exists?: InputMaybe<Scalars['Boolean']>
  imageCredit_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  imageCredit_not?: InputMaybe<Scalars['String']>
  imageCredit_not_contains?: InputMaybe<Scalars['String']>
  imageCredit_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  internalTitle?: InputMaybe<Scalars['String']>
  internalTitle_contains?: InputMaybe<Scalars['String']>
  internalTitle_exists?: InputMaybe<Scalars['Boolean']>
  internalTitle_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  internalTitle_not?: InputMaybe<Scalars['String']>
  internalTitle_not_contains?: InputMaybe<Scalars['String']>
  internalTitle_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  sys?: InputMaybe<SysFilter>
}

/** A page section containing a carousel of Image Gallery Slide items [See type definition](https://app.contentful.com/spaces/9cxgygtadmvl/content_types/componentImageGalleryCarousel) */
export type ComponentImageGalleryCarousel = Entry &
  _Node & {
    __typename?: 'ComponentImageGalleryCarousel'
    _id: Scalars['ID']
    contentfulMetadata: ContentfulMetadata
    imageGallerySlideItemsCollection?: Maybe<ComponentImageGalleryCarouselImageGallerySlideItemsCollection>
    linkedFrom?: Maybe<ComponentImageGalleryCarouselLinkingCollections>
    sys: Sys
    title?: Maybe<Scalars['String']>
  }

/** A page section containing a carousel of Image Gallery Slide items [See type definition](https://app.contentful.com/spaces/9cxgygtadmvl/content_types/componentImageGalleryCarousel) */
export type ComponentImageGalleryCarouselImageGallerySlideItemsCollectionArgs =
  {
    limit?: InputMaybe<Scalars['Int']>
    locale?: InputMaybe<Scalars['String']>
    order?: InputMaybe<
      Array<
        InputMaybe<ComponentImageGalleryCarouselImageGallerySlideItemsCollectionOrder>
      >
    >
    preview?: InputMaybe<Scalars['Boolean']>
    skip?: InputMaybe<Scalars['Int']>
    where?: InputMaybe<ComponentImageGallerySlideItemFilter>
  }

/** A page section containing a carousel of Image Gallery Slide items [See type definition](https://app.contentful.com/spaces/9cxgygtadmvl/content_types/componentImageGalleryCarousel) */
export type ComponentImageGalleryCarouselLinkedFromArgs = {
  allowedLocales?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
}

/** A page section containing a carousel of Image Gallery Slide items [See type definition](https://app.contentful.com/spaces/9cxgygtadmvl/content_types/componentImageGalleryCarousel) */
export type ComponentImageGalleryCarouselTitleArgs = {
  locale?: InputMaybe<Scalars['String']>
}

export type ComponentImageGalleryCarouselCollection = {
  __typename?: 'ComponentImageGalleryCarouselCollection'
  items: Array<Maybe<ComponentImageGalleryCarousel>>
  limit: Scalars['Int']
  skip: Scalars['Int']
  total: Scalars['Int']
}

export type ComponentImageGalleryCarouselFilter = {
  AND?: InputMaybe<Array<InputMaybe<ComponentImageGalleryCarouselFilter>>>
  OR?: InputMaybe<Array<InputMaybe<ComponentImageGalleryCarouselFilter>>>
  contentfulMetadata?: InputMaybe<ContentfulMetadataFilter>
  imageGallerySlideItems?: InputMaybe<CfComponentImageGallerySlideItemNestedFilter>
  imageGallerySlideItemsCollection_exists?: InputMaybe<Scalars['Boolean']>
  sys?: InputMaybe<SysFilter>
  title?: InputMaybe<Scalars['String']>
  title_contains?: InputMaybe<Scalars['String']>
  title_exists?: InputMaybe<Scalars['Boolean']>
  title_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  title_not?: InputMaybe<Scalars['String']>
  title_not_contains?: InputMaybe<Scalars['String']>
  title_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
}

export type ComponentImageGalleryCarouselImageGallerySlideItemsCollection = {
  __typename?: 'ComponentImageGalleryCarouselImageGallerySlideItemsCollection'
  items: Array<Maybe<ComponentImageGallerySlideItem>>
  limit: Scalars['Int']
  skip: Scalars['Int']
  total: Scalars['Int']
}

export enum ComponentImageGalleryCarouselImageGallerySlideItemsCollectionOrder {
  CtaTextAsc = 'ctaText_ASC',
  CtaTextDesc = 'ctaText_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC',
}

export type ComponentImageGalleryCarouselLinkingCollections = {
  __typename?: 'ComponentImageGalleryCarouselLinkingCollections'
  campaignPagesCollection?: Maybe<CampaignPagesCollection>
  entryCollection?: Maybe<EntryCollection>
}

export type ComponentImageGalleryCarouselLinkingCollectionsCampaignPagesCollectionArgs =
  {
    limit?: InputMaybe<Scalars['Int']>
    locale?: InputMaybe<Scalars['String']>
    order?: InputMaybe<
      Array<
        InputMaybe<ComponentImageGalleryCarouselLinkingCollectionsCampaignPagesCollectionOrder>
      >
    >
    preview?: InputMaybe<Scalars['Boolean']>
    skip?: InputMaybe<Scalars['Int']>
  }

export type ComponentImageGalleryCarouselLinkingCollectionsEntryCollectionArgs =
  {
    limit?: InputMaybe<Scalars['Int']>
    locale?: InputMaybe<Scalars['String']>
    preview?: InputMaybe<Scalars['Boolean']>
    skip?: InputMaybe<Scalars['Int']>
  }

export enum ComponentImageGalleryCarouselLinkingCollectionsCampaignPagesCollectionOrder {
  CollapseFooterAsc = 'collapseFooter_ASC',
  CollapseFooterDesc = 'collapseFooter_DESC',
  CollapseHeaderAsc = 'collapseHeader_ASC',
  CollapseHeaderDesc = 'collapseHeader_DESC',
  HideFooterAsc = 'hideFooter_ASC',
  HideFooterDesc = 'hideFooter_DESC',
  HideGlobalBannerAsc = 'hideGlobalBanner_ASC',
  HideGlobalBannerDesc = 'hideGlobalBanner_DESC',
  JotformIdAsc = 'jotformId_ASC',
  JotformIdDesc = 'jotformId_DESC',
  NoindexNofollowAsc = 'noindexNofollow_ASC',
  NoindexNofollowDesc = 'noindexNofollow_DESC',
  ShowHeaderAsc = 'showHeader_ASC',
  ShowHeaderDesc = 'showHeader_DESC',
  SlugAsc = 'slug_ASC',
  SlugDesc = 'slug_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC',
}

export enum ComponentImageGalleryCarouselOrder {
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC',
}

/** An item to be displayed within an Image Gallery Carousel. [See type definition](https://app.contentful.com/spaces/9cxgygtadmvl/content_types/componentImageGallerySlideItem) */
export type ComponentImageGallerySlideItem = Entry &
  _Node & {
    __typename?: 'ComponentImageGallerySlideItem'
    _id: Scalars['ID']
    cloudinaryFeaturedImage?: Maybe<Scalars['JSON']>
    cloudinaryImages?: Maybe<Scalars['JSON']>
    contentfulMetadata: ContentfulMetadata
    ctaText?: Maybe<Scalars['String']>
    description?: Maybe<ComponentImageGallerySlideItemDescription>
    featuredImage?: Maybe<Asset>
    imagesCollection?: Maybe<AssetCollection>
    linkedFrom?: Maybe<ComponentImageGallerySlideItemLinkingCollections>
    sys: Sys
    title?: Maybe<Scalars['String']>
  }

/** An item to be displayed within an Image Gallery Carousel. [See type definition](https://app.contentful.com/spaces/9cxgygtadmvl/content_types/componentImageGallerySlideItem) */
export type ComponentImageGallerySlideItemCloudinaryFeaturedImageArgs = {
  locale?: InputMaybe<Scalars['String']>
}

/** An item to be displayed within an Image Gallery Carousel. [See type definition](https://app.contentful.com/spaces/9cxgygtadmvl/content_types/componentImageGallerySlideItem) */
export type ComponentImageGallerySlideItemCloudinaryImagesArgs = {
  locale?: InputMaybe<Scalars['String']>
}

/** An item to be displayed within an Image Gallery Carousel. [See type definition](https://app.contentful.com/spaces/9cxgygtadmvl/content_types/componentImageGallerySlideItem) */
export type ComponentImageGallerySlideItemCtaTextArgs = {
  locale?: InputMaybe<Scalars['String']>
}

/** An item to be displayed within an Image Gallery Carousel. [See type definition](https://app.contentful.com/spaces/9cxgygtadmvl/content_types/componentImageGallerySlideItem) */
export type ComponentImageGallerySlideItemDescriptionArgs = {
  locale?: InputMaybe<Scalars['String']>
}

/** An item to be displayed within an Image Gallery Carousel. [See type definition](https://app.contentful.com/spaces/9cxgygtadmvl/content_types/componentImageGallerySlideItem) */
export type ComponentImageGallerySlideItemFeaturedImageArgs = {
  locale?: InputMaybe<Scalars['String']>
  preview?: InputMaybe<Scalars['Boolean']>
}

/** An item to be displayed within an Image Gallery Carousel. [See type definition](https://app.contentful.com/spaces/9cxgygtadmvl/content_types/componentImageGallerySlideItem) */
export type ComponentImageGallerySlideItemImagesCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>
  locale?: InputMaybe<Scalars['String']>
  preview?: InputMaybe<Scalars['Boolean']>
  skip?: InputMaybe<Scalars['Int']>
}

/** An item to be displayed within an Image Gallery Carousel. [See type definition](https://app.contentful.com/spaces/9cxgygtadmvl/content_types/componentImageGallerySlideItem) */
export type ComponentImageGallerySlideItemLinkedFromArgs = {
  allowedLocales?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
}

/** An item to be displayed within an Image Gallery Carousel. [See type definition](https://app.contentful.com/spaces/9cxgygtadmvl/content_types/componentImageGallerySlideItem) */
export type ComponentImageGallerySlideItemTitleArgs = {
  locale?: InputMaybe<Scalars['String']>
}

export type ComponentImageGallerySlideItemCollection = {
  __typename?: 'ComponentImageGallerySlideItemCollection'
  items: Array<Maybe<ComponentImageGallerySlideItem>>
  limit: Scalars['Int']
  skip: Scalars['Int']
  total: Scalars['Int']
}

export type ComponentImageGallerySlideItemDescription = {
  __typename?: 'ComponentImageGallerySlideItemDescription'
  json: Scalars['JSON']
  links: ComponentImageGallerySlideItemDescriptionLinks
}

export type ComponentImageGallerySlideItemDescriptionAssets = {
  __typename?: 'ComponentImageGallerySlideItemDescriptionAssets'
  block: Array<Maybe<Asset>>
  hyperlink: Array<Maybe<Asset>>
}

export type ComponentImageGallerySlideItemDescriptionEntries = {
  __typename?: 'ComponentImageGallerySlideItemDescriptionEntries'
  block: Array<Maybe<Entry>>
  hyperlink: Array<Maybe<Entry>>
  inline: Array<Maybe<Entry>>
}

export type ComponentImageGallerySlideItemDescriptionLinks = {
  __typename?: 'ComponentImageGallerySlideItemDescriptionLinks'
  assets: ComponentImageGallerySlideItemDescriptionAssets
  entries: ComponentImageGallerySlideItemDescriptionEntries
  resources: ComponentImageGallerySlideItemDescriptionResources
}

export type ComponentImageGallerySlideItemDescriptionResources = {
  __typename?: 'ComponentImageGallerySlideItemDescriptionResources'
  block: Array<ComponentImageGallerySlideItemDescriptionResourcesBlock>
  hyperlink: Array<ComponentImageGallerySlideItemDescriptionResourcesHyperlink>
  inline: Array<ComponentImageGallerySlideItemDescriptionResourcesInline>
}

export type ComponentImageGallerySlideItemDescriptionResourcesBlock =
  ResourceLink & {
    __typename?: 'ComponentImageGallerySlideItemDescriptionResourcesBlock'
    sys: ResourceSys
  }

export type ComponentImageGallerySlideItemDescriptionResourcesHyperlink =
  ResourceLink & {
    __typename?: 'ComponentImageGallerySlideItemDescriptionResourcesHyperlink'
    sys: ResourceSys
  }

export type ComponentImageGallerySlideItemDescriptionResourcesInline =
  ResourceLink & {
    __typename?: 'ComponentImageGallerySlideItemDescriptionResourcesInline'
    sys: ResourceSys
  }

export type ComponentImageGallerySlideItemFilter = {
  AND?: InputMaybe<Array<InputMaybe<ComponentImageGallerySlideItemFilter>>>
  OR?: InputMaybe<Array<InputMaybe<ComponentImageGallerySlideItemFilter>>>
  cloudinaryFeaturedImage_exists?: InputMaybe<Scalars['Boolean']>
  cloudinaryImages_exists?: InputMaybe<Scalars['Boolean']>
  contentfulMetadata?: InputMaybe<ContentfulMetadataFilter>
  ctaText?: InputMaybe<Scalars['String']>
  ctaText_contains?: InputMaybe<Scalars['String']>
  ctaText_exists?: InputMaybe<Scalars['Boolean']>
  ctaText_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  ctaText_not?: InputMaybe<Scalars['String']>
  ctaText_not_contains?: InputMaybe<Scalars['String']>
  ctaText_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  description_contains?: InputMaybe<Scalars['String']>
  description_exists?: InputMaybe<Scalars['Boolean']>
  description_not_contains?: InputMaybe<Scalars['String']>
  featuredImage_exists?: InputMaybe<Scalars['Boolean']>
  imagesCollection_exists?: InputMaybe<Scalars['Boolean']>
  sys?: InputMaybe<SysFilter>
  title?: InputMaybe<Scalars['String']>
  title_contains?: InputMaybe<Scalars['String']>
  title_exists?: InputMaybe<Scalars['Boolean']>
  title_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  title_not?: InputMaybe<Scalars['String']>
  title_not_contains?: InputMaybe<Scalars['String']>
  title_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
}

export type ComponentImageGallerySlideItemLinkingCollections = {
  __typename?: 'ComponentImageGallerySlideItemLinkingCollections'
  componentImageGalleryCarouselCollection?: Maybe<ComponentImageGalleryCarouselCollection>
  entryCollection?: Maybe<EntryCollection>
}

export type ComponentImageGallerySlideItemLinkingCollectionsComponentImageGalleryCarouselCollectionArgs =
  {
    limit?: InputMaybe<Scalars['Int']>
    locale?: InputMaybe<Scalars['String']>
    order?: InputMaybe<
      Array<
        InputMaybe<ComponentImageGallerySlideItemLinkingCollectionsComponentImageGalleryCarouselCollectionOrder>
      >
    >
    preview?: InputMaybe<Scalars['Boolean']>
    skip?: InputMaybe<Scalars['Int']>
  }

export type ComponentImageGallerySlideItemLinkingCollectionsEntryCollectionArgs =
  {
    limit?: InputMaybe<Scalars['Int']>
    locale?: InputMaybe<Scalars['String']>
    preview?: InputMaybe<Scalars['Boolean']>
    skip?: InputMaybe<Scalars['Int']>
  }

export enum ComponentImageGallerySlideItemLinkingCollectionsComponentImageGalleryCarouselCollectionOrder {
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC',
}

export enum ComponentImageGallerySlideItemOrder {
  CtaTextAsc = 'ctaText_ASC',
  CtaTextDesc = 'ctaText_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC',
}

/** [See type definition](https://app.contentful.com/spaces/9cxgygtadmvl/content_types/componentImageHeaderSub) */
export type ComponentImageHeaderSub = Entry &
  _Node & {
    __typename?: 'ComponentImageHeaderSub'
    _id: Scalars['ID']
    contentfulMetadata: ContentfulMetadata
    header?: Maybe<Scalars['String']>
    image?: Maybe<Asset>
    internalLabel?: Maybe<Scalars['String']>
    linkedFrom?: Maybe<ComponentImageHeaderSubLinkingCollections>
    subHeading?: Maybe<Scalars['String']>
    subheading?: Maybe<Scalars['String']>
    sys: Sys
    titleStyle?: Maybe<Scalars['String']>
  }

/** [See type definition](https://app.contentful.com/spaces/9cxgygtadmvl/content_types/componentImageHeaderSub) */
export type ComponentImageHeaderSubHeaderArgs = {
  locale?: InputMaybe<Scalars['String']>
}

/** [See type definition](https://app.contentful.com/spaces/9cxgygtadmvl/content_types/componentImageHeaderSub) */
export type ComponentImageHeaderSubImageArgs = {
  locale?: InputMaybe<Scalars['String']>
  preview?: InputMaybe<Scalars['Boolean']>
}

/** [See type definition](https://app.contentful.com/spaces/9cxgygtadmvl/content_types/componentImageHeaderSub) */
export type ComponentImageHeaderSubInternalLabelArgs = {
  locale?: InputMaybe<Scalars['String']>
}

/** [See type definition](https://app.contentful.com/spaces/9cxgygtadmvl/content_types/componentImageHeaderSub) */
export type ComponentImageHeaderSubLinkedFromArgs = {
  allowedLocales?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
}

/** [See type definition](https://app.contentful.com/spaces/9cxgygtadmvl/content_types/componentImageHeaderSub) */
export type ComponentImageHeaderSubSubHeadingArgs = {
  locale?: InputMaybe<Scalars['String']>
}

/** [See type definition](https://app.contentful.com/spaces/9cxgygtadmvl/content_types/componentImageHeaderSub) */
export type ComponentImageHeaderSubSubheadingArgs = {
  locale?: InputMaybe<Scalars['String']>
}

/** [See type definition](https://app.contentful.com/spaces/9cxgygtadmvl/content_types/componentImageHeaderSub) */
export type ComponentImageHeaderSubTitleStyleArgs = {
  locale?: InputMaybe<Scalars['String']>
}

export type ComponentImageHeaderSubCollection = {
  __typename?: 'ComponentImageHeaderSubCollection'
  items: Array<Maybe<ComponentImageHeaderSub>>
  limit: Scalars['Int']
  skip: Scalars['Int']
  total: Scalars['Int']
}

export type ComponentImageHeaderSubFilter = {
  AND?: InputMaybe<Array<InputMaybe<ComponentImageHeaderSubFilter>>>
  OR?: InputMaybe<Array<InputMaybe<ComponentImageHeaderSubFilter>>>
  contentfulMetadata?: InputMaybe<ContentfulMetadataFilter>
  header?: InputMaybe<Scalars['String']>
  header_contains?: InputMaybe<Scalars['String']>
  header_exists?: InputMaybe<Scalars['Boolean']>
  header_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  header_not?: InputMaybe<Scalars['String']>
  header_not_contains?: InputMaybe<Scalars['String']>
  header_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  image_exists?: InputMaybe<Scalars['Boolean']>
  internalLabel?: InputMaybe<Scalars['String']>
  internalLabel_contains?: InputMaybe<Scalars['String']>
  internalLabel_exists?: InputMaybe<Scalars['Boolean']>
  internalLabel_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  internalLabel_not?: InputMaybe<Scalars['String']>
  internalLabel_not_contains?: InputMaybe<Scalars['String']>
  internalLabel_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  subHeading?: InputMaybe<Scalars['String']>
  subHeading_contains?: InputMaybe<Scalars['String']>
  subHeading_exists?: InputMaybe<Scalars['Boolean']>
  subHeading_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  subHeading_not?: InputMaybe<Scalars['String']>
  subHeading_not_contains?: InputMaybe<Scalars['String']>
  subHeading_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  subheading?: InputMaybe<Scalars['String']>
  subheading_contains?: InputMaybe<Scalars['String']>
  subheading_exists?: InputMaybe<Scalars['Boolean']>
  subheading_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  subheading_not?: InputMaybe<Scalars['String']>
  subheading_not_contains?: InputMaybe<Scalars['String']>
  subheading_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  sys?: InputMaybe<SysFilter>
  titleStyle?: InputMaybe<Scalars['String']>
  titleStyle_contains?: InputMaybe<Scalars['String']>
  titleStyle_exists?: InputMaybe<Scalars['Boolean']>
  titleStyle_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  titleStyle_not?: InputMaybe<Scalars['String']>
  titleStyle_not_contains?: InputMaybe<Scalars['String']>
  titleStyle_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
}

export type ComponentImageHeaderSubLinkingCollections = {
  __typename?: 'ComponentImageHeaderSubLinkingCollections'
  componentInfoGridCollection?: Maybe<ComponentInfoGridCollection>
  entryCollection?: Maybe<EntryCollection>
  hotelsCollection?: Maybe<HotelsCollection>
  pagesTwoColumnCtaCollection?: Maybe<PagesTwoColumnCtaCollection>
}

export type ComponentImageHeaderSubLinkingCollectionsComponentInfoGridCollectionArgs =
  {
    limit?: InputMaybe<Scalars['Int']>
    locale?: InputMaybe<Scalars['String']>
    order?: InputMaybe<
      Array<
        InputMaybe<ComponentImageHeaderSubLinkingCollectionsComponentInfoGridCollectionOrder>
      >
    >
    preview?: InputMaybe<Scalars['Boolean']>
    skip?: InputMaybe<Scalars['Int']>
  }

export type ComponentImageHeaderSubLinkingCollectionsEntryCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>
  locale?: InputMaybe<Scalars['String']>
  preview?: InputMaybe<Scalars['Boolean']>
  skip?: InputMaybe<Scalars['Int']>
}

export type ComponentImageHeaderSubLinkingCollectionsHotelsCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>
  locale?: InputMaybe<Scalars['String']>
  order?: InputMaybe<
    Array<
      InputMaybe<ComponentImageHeaderSubLinkingCollectionsHotelsCollectionOrder>
    >
  >
  preview?: InputMaybe<Scalars['Boolean']>
  skip?: InputMaybe<Scalars['Int']>
}

export type ComponentImageHeaderSubLinkingCollectionsPagesTwoColumnCtaCollectionArgs =
  {
    limit?: InputMaybe<Scalars['Int']>
    locale?: InputMaybe<Scalars['String']>
    order?: InputMaybe<
      Array<
        InputMaybe<ComponentImageHeaderSubLinkingCollectionsPagesTwoColumnCtaCollectionOrder>
      >
    >
    preview?: InputMaybe<Scalars['Boolean']>
    skip?: InputMaybe<Scalars['Int']>
  }

export enum ComponentImageHeaderSubLinkingCollectionsComponentInfoGridCollectionOrder {
  CtaTextAsc = 'ctaText_ASC',
  CtaTextDesc = 'ctaText_DESC',
  CtaUrlAsc = 'ctaUrl_ASC',
  CtaUrlDesc = 'ctaUrl_DESC',
  HeaderAsc = 'header_ASC',
  HeaderDesc = 'header_DESC',
  SectionIdAsc = 'sectionId_ASC',
  SectionIdDesc = 'sectionId_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
}

export enum ComponentImageHeaderSubLinkingCollectionsHotelsCollectionOrder {
  ArchivedAsc = 'archived_ASC',
  ArchivedDesc = 'archived_DESC',
  ForaSupplierIdAsc = 'foraSupplierId_ASC',
  ForaSupplierIdDesc = 'foraSupplierId_DESC',
  GenerateLandingPageAsc = 'generateLandingPage_ASC',
  GenerateLandingPageDesc = 'generateLandingPage_DESC',
  HeroImageAsc = 'heroImage_ASC',
  HeroImageDesc = 'heroImage_DESC',
  HideFromTopHotelsPagesAsc = 'hideFromTopHotelsPages_ASC',
  HideFromTopHotelsPagesDesc = 'hideFromTopHotelsPages_DESC',
  MichelinKeysAsc = 'michelinKeys_ASC',
  MichelinKeysDesc = 'michelinKeys_DESC',
  SlugAsc = 'slug_ASC',
  SlugDesc = 'slug_DESC',
  StarRatingAsc = 'starRating_ASC',
  StarRatingDesc = 'starRating_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC',
}

export enum ComponentImageHeaderSubLinkingCollectionsPagesTwoColumnCtaCollectionOrder {
  AdvisorDescriptionAsc = 'advisorDescription_ASC',
  AdvisorDescriptionDesc = 'advisorDescription_DESC',
  BackgroundColorAsc = 'backgroundColor_ASC',
  BackgroundColorDesc = 'backgroundColor_DESC',
  ButtonTextAsc = 'buttonText_ASC',
  ButtonTextDesc = 'buttonText_DESC',
  ButtonThemeAsc = 'buttonTheme_ASC',
  ButtonThemeDesc = 'buttonTheme_DESC',
  ButtonUrlAsc = 'buttonUrl_ASC',
  ButtonUrlDesc = 'buttonUrl_DESC',
  CardSizeAsc = 'cardSize_ASC',
  CardSizeDesc = 'cardSize_DESC',
  ContentColumnAsc = 'contentColumn_ASC',
  ContentColumnDesc = 'contentColumn_DESC',
  FormIdAsc = 'formId_ASC',
  FormIdDesc = 'formId_DESC',
  InternalLabelAsc = 'internalLabel_ASC',
  InternalLabelDesc = 'internalLabel_DESC',
  MobileAlignmentAsc = 'mobileAlignment_ASC',
  MobileAlignmentDesc = 'mobileAlignment_DESC',
  SectionIdAsc = 'sectionId_ASC',
  SectionIdDesc = 'sectionId_DESC',
  SolidBackgroundAsc = 'solidBackground_ASC',
  SolidBackgroundDesc = 'solidBackground_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  TitleStyleAsc = 'titleStyle_ASC',
  TitleStyleDesc = 'titleStyle_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC',
  UrlTargetAsc = 'urlTarget_ASC',
  UrlTargetDesc = 'urlTarget_DESC',
  YMarginAsc = 'yMargin_ASC',
  YMarginDesc = 'yMargin_DESC',
}

export enum ComponentImageHeaderSubOrder {
  HeaderAsc = 'header_ASC',
  HeaderDesc = 'header_DESC',
  InternalLabelAsc = 'internalLabel_ASC',
  InternalLabelDesc = 'internalLabel_DESC',
  SubHeadingAsc = 'subHeading_ASC',
  SubHeadingDesc = 'subHeading_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  TitleStyleAsc = 'titleStyle_ASC',
  TitleStyleDesc = 'titleStyle_DESC',
}

export type ComponentImageLinkingCollections = {
  __typename?: 'ComponentImageLinkingCollections'
  advisorsCollection?: Maybe<AdvisorsCollection>
  entryCollection?: Maybe<EntryCollection>
  itinerariesPlansCollection?: Maybe<ItinerariesPlansCollection>
  templatePartnersCollection?: Maybe<TemplatePartnersCollection>
}

export type ComponentImageLinkingCollectionsAdvisorsCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>
  locale?: InputMaybe<Scalars['String']>
  order?: InputMaybe<
    Array<InputMaybe<ComponentImageLinkingCollectionsAdvisorsCollectionOrder>>
  >
  preview?: InputMaybe<Scalars['Boolean']>
  skip?: InputMaybe<Scalars['Int']>
}

export type ComponentImageLinkingCollectionsEntryCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>
  locale?: InputMaybe<Scalars['String']>
  preview?: InputMaybe<Scalars['Boolean']>
  skip?: InputMaybe<Scalars['Int']>
}

export type ComponentImageLinkingCollectionsItinerariesPlansCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>
  locale?: InputMaybe<Scalars['String']>
  order?: InputMaybe<
    Array<
      InputMaybe<ComponentImageLinkingCollectionsItinerariesPlansCollectionOrder>
    >
  >
  preview?: InputMaybe<Scalars['Boolean']>
  skip?: InputMaybe<Scalars['Int']>
}

export type ComponentImageLinkingCollectionsTemplatePartnersCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>
  locale?: InputMaybe<Scalars['String']>
  order?: InputMaybe<
    Array<
      InputMaybe<ComponentImageLinkingCollectionsTemplatePartnersCollectionOrder>
    >
  >
  preview?: InputMaybe<Scalars['Boolean']>
  skip?: InputMaybe<Scalars['Int']>
}

export enum ComponentImageLinkingCollectionsAdvisorsCollectionOrder {
  AdvisorContactEnabledAsc = 'advisorContactEnabled_ASC',
  AdvisorContactEnabledDesc = 'advisorContactEnabled_DESC',
  AdvisorVariantAsc = 'advisorVariant_ASC',
  AdvisorVariantDesc = 'advisorVariant_DESC',
  EmailAddressAsc = 'emailAddress_ASC',
  EmailAddressDesc = 'emailAddress_DESC',
  FacebookUrlAsc = 'facebookUrl_ASC',
  FacebookUrlDesc = 'facebookUrl_DESC',
  FirstNameAsc = 'firstName_ASC',
  FirstNameDesc = 'firstName_DESC',
  ForaAdvisorHotelsAsc = 'foraAdvisorHotels_ASC',
  ForaAdvisorHotelsDesc = 'foraAdvisorHotels_DESC',
  ForaAdvisorIdAsc = 'foraAdvisorId_ASC',
  ForaAdvisorIdDesc = 'foraAdvisorId_DESC',
  InstagramUrlAsc = 'instagramUrl_ASC',
  InstagramUrlDesc = 'instagramUrl_DESC',
  LastNameAsc = 'lastName_ASC',
  LastNameDesc = 'lastName_DESC',
  LinkedinUrlAsc = 'linkedinUrl_ASC',
  LinkedinUrlDesc = 'linkedinUrl_DESC',
  MinimumBookingAsc = 'minimumBooking_ASC',
  MinimumBookingDesc = 'minimumBooking_DESC',
  PinterestUrlAsc = 'pinterestUrl_ASC',
  PinterestUrlDesc = 'pinterestUrl_DESC',
  PronounsAsc = 'pronouns_ASC',
  PronounsDesc = 'pronouns_DESC',
  ShortDescriptionAsc = 'shortDescription_ASC',
  ShortDescriptionDesc = 'shortDescription_DESC',
  ShowOnAdvisorsPageAsc = 'showOnAdvisorsPage_ASC',
  ShowOnAdvisorsPageDesc = 'showOnAdvisorsPage_DESC',
  ShowOnHomepageAsc = 'showOnHomepage_ASC',
  ShowOnHomepageDesc = 'showOnHomepage_DESC',
  ShowOnOtherLandingPagesAsc = 'showOnOtherLandingPages_ASC',
  ShowOnOtherLandingPagesDesc = 'showOnOtherLandingPages_DESC',
  SlugAsc = 'slug_ASC',
  SlugDesc = 'slug_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  TierAsc = 'tier_ASC',
  TierDesc = 'tier_DESC',
  TikTokUrlAsc = 'tikTokUrl_ASC',
  TikTokUrlDesc = 'tikTokUrl_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC',
  TwitterUrlAsc = 'twitterUrl_ASC',
  TwitterUrlDesc = 'twitterUrl_DESC',
  WebsiteUrlAsc = 'websiteUrl_ASC',
  WebsiteUrlDesc = 'websiteUrl_DESC',
  YoutubeUrlAsc = 'youtubeUrl_ASC',
  YoutubeUrlDesc = 'youtubeUrl_DESC',
}

export enum ComponentImageLinkingCollectionsItinerariesPlansCollectionOrder {
  ImageCaptionAsc = 'imageCaption_ASC',
  ImageCaptionDesc = 'imageCaption_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC',
}

export enum ComponentImageLinkingCollectionsTemplatePartnersCollectionOrder {
  CollapseHeaderAsc = 'collapseHeader_ASC',
  CollapseHeaderDesc = 'collapseHeader_DESC',
  ShowHeaderAsc = 'showHeader_ASC',
  ShowHeaderDesc = 'showHeader_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC',
}

export enum ComponentImageOrder {
  AltTextAsc = 'altText_ASC',
  AltTextDesc = 'altText_DESC',
  ImageCreditAsc = 'imageCredit_ASC',
  ImageCreditDesc = 'imageCredit_DESC',
  InternalTitleAsc = 'internalTitle_ASC',
  InternalTitleDesc = 'internalTitle_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
}

/** [See type definition](https://app.contentful.com/spaces/9cxgygtadmvl/content_types/componentImageSideBySide) */
export type ComponentImageSideBySide = Entry &
  _Node & {
    __typename?: 'ComponentImageSideBySide'
    _id: Scalars['ID']
    analyticsModule?: Maybe<SettingsAnalyticsModule>
    caption?: Maybe<Scalars['String']>
    contentfulMetadata: ContentfulMetadata
    imageOne?: Maybe<Asset>
    imageTwo?: Maybe<Asset>
    internalLabel?: Maybe<Scalars['String']>
    linkedFrom?: Maybe<ComponentImageSideBySideLinkingCollections>
    sys: Sys
  }

/** [See type definition](https://app.contentful.com/spaces/9cxgygtadmvl/content_types/componentImageSideBySide) */
export type ComponentImageSideBySideAnalyticsModuleArgs = {
  locale?: InputMaybe<Scalars['String']>
  preview?: InputMaybe<Scalars['Boolean']>
  where?: InputMaybe<SettingsAnalyticsModuleFilter>
}

/** [See type definition](https://app.contentful.com/spaces/9cxgygtadmvl/content_types/componentImageSideBySide) */
export type ComponentImageSideBySideCaptionArgs = {
  locale?: InputMaybe<Scalars['String']>
}

/** [See type definition](https://app.contentful.com/spaces/9cxgygtadmvl/content_types/componentImageSideBySide) */
export type ComponentImageSideBySideImageOneArgs = {
  locale?: InputMaybe<Scalars['String']>
  preview?: InputMaybe<Scalars['Boolean']>
}

/** [See type definition](https://app.contentful.com/spaces/9cxgygtadmvl/content_types/componentImageSideBySide) */
export type ComponentImageSideBySideImageTwoArgs = {
  locale?: InputMaybe<Scalars['String']>
  preview?: InputMaybe<Scalars['Boolean']>
}

/** [See type definition](https://app.contentful.com/spaces/9cxgygtadmvl/content_types/componentImageSideBySide) */
export type ComponentImageSideBySideInternalLabelArgs = {
  locale?: InputMaybe<Scalars['String']>
}

/** [See type definition](https://app.contentful.com/spaces/9cxgygtadmvl/content_types/componentImageSideBySide) */
export type ComponentImageSideBySideLinkedFromArgs = {
  allowedLocales?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
}

export type ComponentImageSideBySideCollection = {
  __typename?: 'ComponentImageSideBySideCollection'
  items: Array<Maybe<ComponentImageSideBySide>>
  limit: Scalars['Int']
  skip: Scalars['Int']
  total: Scalars['Int']
}

export type ComponentImageSideBySideFilter = {
  AND?: InputMaybe<Array<InputMaybe<ComponentImageSideBySideFilter>>>
  OR?: InputMaybe<Array<InputMaybe<ComponentImageSideBySideFilter>>>
  analyticsModule?: InputMaybe<CfSettingsAnalyticsModuleNestedFilter>
  analyticsModule_exists?: InputMaybe<Scalars['Boolean']>
  caption?: InputMaybe<Scalars['String']>
  caption_contains?: InputMaybe<Scalars['String']>
  caption_exists?: InputMaybe<Scalars['Boolean']>
  caption_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  caption_not?: InputMaybe<Scalars['String']>
  caption_not_contains?: InputMaybe<Scalars['String']>
  caption_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  contentfulMetadata?: InputMaybe<ContentfulMetadataFilter>
  imageOne_exists?: InputMaybe<Scalars['Boolean']>
  imageTwo_exists?: InputMaybe<Scalars['Boolean']>
  internalLabel?: InputMaybe<Scalars['String']>
  internalLabel_contains?: InputMaybe<Scalars['String']>
  internalLabel_exists?: InputMaybe<Scalars['Boolean']>
  internalLabel_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  internalLabel_not?: InputMaybe<Scalars['String']>
  internalLabel_not_contains?: InputMaybe<Scalars['String']>
  internalLabel_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  sys?: InputMaybe<SysFilter>
}

export type ComponentImageSideBySideLinkingCollections = {
  __typename?: 'ComponentImageSideBySideLinkingCollections'
  campaignPagesCollection?: Maybe<CampaignPagesCollection>
  entryCollection?: Maybe<EntryCollection>
  itinerariesPlansCollection?: Maybe<ItinerariesPlansCollection>
  templateAboutUsCollection?: Maybe<TemplateAboutUsCollection>
  templateBookingCollection?: Maybe<TemplateBookingCollection>
  templateCareersCollection?: Maybe<TemplateCareersCollection>
  templateContactUsCollection?: Maybe<TemplateContactUsCollection>
  templateDestinationsCollection?: Maybe<TemplateDestinationsCollection>
  templateForaReserveCollection?: Maybe<TemplateForaReserveCollection>
  templateForaXCollection?: Maybe<TemplateForaXCollection>
  templateHomeCollection?: Maybe<TemplateHomeCollection>
  templateJoinUsCollection?: Maybe<TemplateJoinUsCollection>
  templateNewsletterCollection?: Maybe<TemplateNewsletterCollection>
  templatePageCollection?: Maybe<TemplatePageCollection>
  templatePartnersCollection?: Maybe<TemplatePartnersCollection>
  templateReviewsCollection?: Maybe<TemplateReviewsCollection>
  templateWhyBookWithForaCollection?: Maybe<TemplateWhyBookWithForaCollection>
}

export type ComponentImageSideBySideLinkingCollectionsCampaignPagesCollectionArgs =
  {
    limit?: InputMaybe<Scalars['Int']>
    locale?: InputMaybe<Scalars['String']>
    order?: InputMaybe<
      Array<
        InputMaybe<ComponentImageSideBySideLinkingCollectionsCampaignPagesCollectionOrder>
      >
    >
    preview?: InputMaybe<Scalars['Boolean']>
    skip?: InputMaybe<Scalars['Int']>
  }

export type ComponentImageSideBySideLinkingCollectionsEntryCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>
  locale?: InputMaybe<Scalars['String']>
  preview?: InputMaybe<Scalars['Boolean']>
  skip?: InputMaybe<Scalars['Int']>
}

export type ComponentImageSideBySideLinkingCollectionsItinerariesPlansCollectionArgs =
  {
    limit?: InputMaybe<Scalars['Int']>
    locale?: InputMaybe<Scalars['String']>
    order?: InputMaybe<
      Array<
        InputMaybe<ComponentImageSideBySideLinkingCollectionsItinerariesPlansCollectionOrder>
      >
    >
    preview?: InputMaybe<Scalars['Boolean']>
    skip?: InputMaybe<Scalars['Int']>
  }

export type ComponentImageSideBySideLinkingCollectionsTemplateAboutUsCollectionArgs =
  {
    limit?: InputMaybe<Scalars['Int']>
    locale?: InputMaybe<Scalars['String']>
    order?: InputMaybe<
      Array<
        InputMaybe<ComponentImageSideBySideLinkingCollectionsTemplateAboutUsCollectionOrder>
      >
    >
    preview?: InputMaybe<Scalars['Boolean']>
    skip?: InputMaybe<Scalars['Int']>
  }

export type ComponentImageSideBySideLinkingCollectionsTemplateBookingCollectionArgs =
  {
    limit?: InputMaybe<Scalars['Int']>
    locale?: InputMaybe<Scalars['String']>
    order?: InputMaybe<
      Array<
        InputMaybe<ComponentImageSideBySideLinkingCollectionsTemplateBookingCollectionOrder>
      >
    >
    preview?: InputMaybe<Scalars['Boolean']>
    skip?: InputMaybe<Scalars['Int']>
  }

export type ComponentImageSideBySideLinkingCollectionsTemplateCareersCollectionArgs =
  {
    limit?: InputMaybe<Scalars['Int']>
    locale?: InputMaybe<Scalars['String']>
    order?: InputMaybe<
      Array<
        InputMaybe<ComponentImageSideBySideLinkingCollectionsTemplateCareersCollectionOrder>
      >
    >
    preview?: InputMaybe<Scalars['Boolean']>
    skip?: InputMaybe<Scalars['Int']>
  }

export type ComponentImageSideBySideLinkingCollectionsTemplateContactUsCollectionArgs =
  {
    limit?: InputMaybe<Scalars['Int']>
    locale?: InputMaybe<Scalars['String']>
    order?: InputMaybe<
      Array<
        InputMaybe<ComponentImageSideBySideLinkingCollectionsTemplateContactUsCollectionOrder>
      >
    >
    preview?: InputMaybe<Scalars['Boolean']>
    skip?: InputMaybe<Scalars['Int']>
  }

export type ComponentImageSideBySideLinkingCollectionsTemplateDestinationsCollectionArgs =
  {
    limit?: InputMaybe<Scalars['Int']>
    locale?: InputMaybe<Scalars['String']>
    order?: InputMaybe<
      Array<
        InputMaybe<ComponentImageSideBySideLinkingCollectionsTemplateDestinationsCollectionOrder>
      >
    >
    preview?: InputMaybe<Scalars['Boolean']>
    skip?: InputMaybe<Scalars['Int']>
  }

export type ComponentImageSideBySideLinkingCollectionsTemplateForaReserveCollectionArgs =
  {
    limit?: InputMaybe<Scalars['Int']>
    locale?: InputMaybe<Scalars['String']>
    order?: InputMaybe<
      Array<
        InputMaybe<ComponentImageSideBySideLinkingCollectionsTemplateForaReserveCollectionOrder>
      >
    >
    preview?: InputMaybe<Scalars['Boolean']>
    skip?: InputMaybe<Scalars['Int']>
  }

export type ComponentImageSideBySideLinkingCollectionsTemplateForaXCollectionArgs =
  {
    limit?: InputMaybe<Scalars['Int']>
    locale?: InputMaybe<Scalars['String']>
    order?: InputMaybe<
      Array<
        InputMaybe<ComponentImageSideBySideLinkingCollectionsTemplateForaXCollectionOrder>
      >
    >
    preview?: InputMaybe<Scalars['Boolean']>
    skip?: InputMaybe<Scalars['Int']>
  }

export type ComponentImageSideBySideLinkingCollectionsTemplateHomeCollectionArgs =
  {
    limit?: InputMaybe<Scalars['Int']>
    locale?: InputMaybe<Scalars['String']>
    order?: InputMaybe<
      Array<
        InputMaybe<ComponentImageSideBySideLinkingCollectionsTemplateHomeCollectionOrder>
      >
    >
    preview?: InputMaybe<Scalars['Boolean']>
    skip?: InputMaybe<Scalars['Int']>
  }

export type ComponentImageSideBySideLinkingCollectionsTemplateJoinUsCollectionArgs =
  {
    limit?: InputMaybe<Scalars['Int']>
    locale?: InputMaybe<Scalars['String']>
    order?: InputMaybe<
      Array<
        InputMaybe<ComponentImageSideBySideLinkingCollectionsTemplateJoinUsCollectionOrder>
      >
    >
    preview?: InputMaybe<Scalars['Boolean']>
    skip?: InputMaybe<Scalars['Int']>
  }

export type ComponentImageSideBySideLinkingCollectionsTemplateNewsletterCollectionArgs =
  {
    limit?: InputMaybe<Scalars['Int']>
    locale?: InputMaybe<Scalars['String']>
    order?: InputMaybe<
      Array<
        InputMaybe<ComponentImageSideBySideLinkingCollectionsTemplateNewsletterCollectionOrder>
      >
    >
    preview?: InputMaybe<Scalars['Boolean']>
    skip?: InputMaybe<Scalars['Int']>
  }

export type ComponentImageSideBySideLinkingCollectionsTemplatePageCollectionArgs =
  {
    limit?: InputMaybe<Scalars['Int']>
    locale?: InputMaybe<Scalars['String']>
    order?: InputMaybe<
      Array<
        InputMaybe<ComponentImageSideBySideLinkingCollectionsTemplatePageCollectionOrder>
      >
    >
    preview?: InputMaybe<Scalars['Boolean']>
    skip?: InputMaybe<Scalars['Int']>
  }

export type ComponentImageSideBySideLinkingCollectionsTemplatePartnersCollectionArgs =
  {
    limit?: InputMaybe<Scalars['Int']>
    locale?: InputMaybe<Scalars['String']>
    order?: InputMaybe<
      Array<
        InputMaybe<ComponentImageSideBySideLinkingCollectionsTemplatePartnersCollectionOrder>
      >
    >
    preview?: InputMaybe<Scalars['Boolean']>
    skip?: InputMaybe<Scalars['Int']>
  }

export type ComponentImageSideBySideLinkingCollectionsTemplateReviewsCollectionArgs =
  {
    limit?: InputMaybe<Scalars['Int']>
    locale?: InputMaybe<Scalars['String']>
    order?: InputMaybe<
      Array<
        InputMaybe<ComponentImageSideBySideLinkingCollectionsTemplateReviewsCollectionOrder>
      >
    >
    preview?: InputMaybe<Scalars['Boolean']>
    skip?: InputMaybe<Scalars['Int']>
  }

export type ComponentImageSideBySideLinkingCollectionsTemplateWhyBookWithForaCollectionArgs =
  {
    limit?: InputMaybe<Scalars['Int']>
    locale?: InputMaybe<Scalars['String']>
    order?: InputMaybe<
      Array<
        InputMaybe<ComponentImageSideBySideLinkingCollectionsTemplateWhyBookWithForaCollectionOrder>
      >
    >
    preview?: InputMaybe<Scalars['Boolean']>
    skip?: InputMaybe<Scalars['Int']>
  }

export enum ComponentImageSideBySideLinkingCollectionsCampaignPagesCollectionOrder {
  CollapseFooterAsc = 'collapseFooter_ASC',
  CollapseFooterDesc = 'collapseFooter_DESC',
  CollapseHeaderAsc = 'collapseHeader_ASC',
  CollapseHeaderDesc = 'collapseHeader_DESC',
  HideFooterAsc = 'hideFooter_ASC',
  HideFooterDesc = 'hideFooter_DESC',
  HideGlobalBannerAsc = 'hideGlobalBanner_ASC',
  HideGlobalBannerDesc = 'hideGlobalBanner_DESC',
  JotformIdAsc = 'jotformId_ASC',
  JotformIdDesc = 'jotformId_DESC',
  NoindexNofollowAsc = 'noindexNofollow_ASC',
  NoindexNofollowDesc = 'noindexNofollow_DESC',
  ShowHeaderAsc = 'showHeader_ASC',
  ShowHeaderDesc = 'showHeader_DESC',
  SlugAsc = 'slug_ASC',
  SlugDesc = 'slug_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC',
}

export enum ComponentImageSideBySideLinkingCollectionsItinerariesPlansCollectionOrder {
  ImageCaptionAsc = 'imageCaption_ASC',
  ImageCaptionDesc = 'imageCaption_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC',
}

export enum ComponentImageSideBySideLinkingCollectionsTemplateAboutUsCollectionOrder {
  CollapseHeaderAsc = 'collapseHeader_ASC',
  CollapseHeaderDesc = 'collapseHeader_DESC',
  HeaderBlockTitleAsc = 'headerBlockTitle_ASC',
  HeaderBlockTitleDesc = 'headerBlockTitle_DESC',
  ShowHeaderAsc = 'showHeader_ASC',
  ShowHeaderDesc = 'showHeader_DESC',
  SubtitleAsc = 'subtitle_ASC',
  SubtitleDesc = 'subtitle_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC',
}

export enum ComponentImageSideBySideLinkingCollectionsTemplateBookingCollectionOrder {
  CollapseHeaderAsc = 'collapseHeader_ASC',
  CollapseHeaderDesc = 'collapseHeader_DESC',
  ShowHeaderAsc = 'showHeader_ASC',
  ShowHeaderDesc = 'showHeader_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC',
}

export enum ComponentImageSideBySideLinkingCollectionsTemplateCareersCollectionOrder {
  CollapseHeaderAsc = 'collapseHeader_ASC',
  CollapseHeaderDesc = 'collapseHeader_DESC',
  ShowHeaderAsc = 'showHeader_ASC',
  ShowHeaderDesc = 'showHeader_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC',
}

export enum ComponentImageSideBySideLinkingCollectionsTemplateContactUsCollectionOrder {
  CollapseHeaderAsc = 'collapseHeader_ASC',
  CollapseHeaderDesc = 'collapseHeader_DESC',
  ShowHeaderAsc = 'showHeader_ASC',
  ShowHeaderDesc = 'showHeader_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC',
}

export enum ComponentImageSideBySideLinkingCollectionsTemplateDestinationsCollectionOrder {
  CollapseHeaderAsc = 'collapseHeader_ASC',
  CollapseHeaderDesc = 'collapseHeader_DESC',
  CollapsibleHeadingAsc = 'collapsibleHeading_ASC',
  CollapsibleHeadingDesc = 'collapsibleHeading_DESC',
  ShowHeaderAsc = 'showHeader_ASC',
  ShowHeaderDesc = 'showHeader_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC',
}

export enum ComponentImageSideBySideLinkingCollectionsTemplateForaReserveCollectionOrder {
  CollapseHeaderAsc = 'collapseHeader_ASC',
  CollapseHeaderDesc = 'collapseHeader_DESC',
  ShowHeaderAsc = 'showHeader_ASC',
  ShowHeaderDesc = 'showHeader_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC',
}

export enum ComponentImageSideBySideLinkingCollectionsTemplateForaXCollectionOrder {
  AdvisorGridHeaderAsc = 'advisorGridHeader_ASC',
  AdvisorGridHeaderDesc = 'advisorGridHeader_DESC',
  AdvisorGridSubCopyAsc = 'advisorGridSubCopy_ASC',
  AdvisorGridSubCopyDesc = 'advisorGridSubCopy_DESC',
  CollapseHeaderAsc = 'collapseHeader_ASC',
  CollapseHeaderDesc = 'collapseHeader_DESC',
  ShowHeaderAsc = 'showHeader_ASC',
  ShowHeaderDesc = 'showHeader_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC',
}

export enum ComponentImageSideBySideLinkingCollectionsTemplateHomeCollectionOrder {
  CollapseHeaderAsc = 'collapseHeader_ASC',
  CollapseHeaderDesc = 'collapseHeader_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC',
}

export enum ComponentImageSideBySideLinkingCollectionsTemplateJoinUsCollectionOrder {
  CollapseHeaderAsc = 'collapseHeader_ASC',
  CollapseHeaderDesc = 'collapseHeader_DESC',
  HideGlobalBannerAsc = 'hideGlobalBanner_ASC',
  HideGlobalBannerDesc = 'hideGlobalBanner_DESC',
  ShowHeaderAsc = 'showHeader_ASC',
  ShowHeaderDesc = 'showHeader_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC',
}

export enum ComponentImageSideBySideLinkingCollectionsTemplateNewsletterCollectionOrder {
  CollapseHeaderAsc = 'collapseHeader_ASC',
  CollapseHeaderDesc = 'collapseHeader_DESC',
  ShowHeaderAsc = 'showHeader_ASC',
  ShowHeaderDesc = 'showHeader_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC',
}

export enum ComponentImageSideBySideLinkingCollectionsTemplatePageCollectionOrder {
  DirectoryAsc = 'directory_ASC',
  DirectoryDesc = 'directory_DESC',
  InternalLabelAsc = 'internalLabel_ASC',
  InternalLabelDesc = 'internalLabel_DESC',
  MetaTitleAsc = 'metaTitle_ASC',
  MetaTitleDesc = 'metaTitle_DESC',
  NoindexNofollowAsc = 'noindexNofollow_ASC',
  NoindexNofollowDesc = 'noindexNofollow_DESC',
  SlugAsc = 'slug_ASC',
  SlugDesc = 'slug_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
}

export enum ComponentImageSideBySideLinkingCollectionsTemplatePartnersCollectionOrder {
  CollapseHeaderAsc = 'collapseHeader_ASC',
  CollapseHeaderDesc = 'collapseHeader_DESC',
  ShowHeaderAsc = 'showHeader_ASC',
  ShowHeaderDesc = 'showHeader_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC',
}

export enum ComponentImageSideBySideLinkingCollectionsTemplateReviewsCollectionOrder {
  CollapseHeaderAsc = 'collapseHeader_ASC',
  CollapseHeaderDesc = 'collapseHeader_DESC',
  ShowHeaderAsc = 'showHeader_ASC',
  ShowHeaderDesc = 'showHeader_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC',
}

export enum ComponentImageSideBySideLinkingCollectionsTemplateWhyBookWithForaCollectionOrder {
  CollapseHeaderAsc = 'collapseHeader_ASC',
  CollapseHeaderDesc = 'collapseHeader_DESC',
  ShowHeaderAsc = 'showHeader_ASC',
  ShowHeaderDesc = 'showHeader_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC',
}

export enum ComponentImageSideBySideOrder {
  CaptionAsc = 'caption_ASC',
  CaptionDesc = 'caption_DESC',
  InternalLabelAsc = 'internalLabel_ASC',
  InternalLabelDesc = 'internalLabel_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
}

/** [See type definition](https://app.contentful.com/spaces/9cxgygtadmvl/content_types/componentInfoGrid) */
export type ComponentInfoGrid = Entry &
  _Node & {
    __typename?: 'ComponentInfoGrid'
    _id: Scalars['ID']
    contentfulMetadata: ContentfulMetadata
    ctaText?: Maybe<Scalars['String']>
    ctaUrl?: Maybe<Scalars['String']>
    header?: Maybe<Scalars['String']>
    headerSubCopy?: Maybe<ComponentInfoGridHeaderSubCopy>
    itemsCollection?: Maybe<ComponentInfoGridItemsCollection>
    linkedFrom?: Maybe<ComponentInfoGridLinkingCollections>
    sectionId?: Maybe<Scalars['String']>
    sys: Sys
    whatWeRequire?: Maybe<Array<Maybe<Scalars['String']>>>
  }

/** [See type definition](https://app.contentful.com/spaces/9cxgygtadmvl/content_types/componentInfoGrid) */
export type ComponentInfoGridCtaTextArgs = {
  locale?: InputMaybe<Scalars['String']>
}

/** [See type definition](https://app.contentful.com/spaces/9cxgygtadmvl/content_types/componentInfoGrid) */
export type ComponentInfoGridCtaUrlArgs = {
  locale?: InputMaybe<Scalars['String']>
}

/** [See type definition](https://app.contentful.com/spaces/9cxgygtadmvl/content_types/componentInfoGrid) */
export type ComponentInfoGridHeaderArgs = {
  locale?: InputMaybe<Scalars['String']>
}

/** [See type definition](https://app.contentful.com/spaces/9cxgygtadmvl/content_types/componentInfoGrid) */
export type ComponentInfoGridHeaderSubCopyArgs = {
  locale?: InputMaybe<Scalars['String']>
}

/** [See type definition](https://app.contentful.com/spaces/9cxgygtadmvl/content_types/componentInfoGrid) */
export type ComponentInfoGridItemsCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>
  locale?: InputMaybe<Scalars['String']>
  order?: InputMaybe<Array<InputMaybe<ComponentInfoGridItemsCollectionOrder>>>
  preview?: InputMaybe<Scalars['Boolean']>
  skip?: InputMaybe<Scalars['Int']>
  where?: InputMaybe<ComponentImageHeaderSubFilter>
}

/** [See type definition](https://app.contentful.com/spaces/9cxgygtadmvl/content_types/componentInfoGrid) */
export type ComponentInfoGridLinkedFromArgs = {
  allowedLocales?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
}

/** [See type definition](https://app.contentful.com/spaces/9cxgygtadmvl/content_types/componentInfoGrid) */
export type ComponentInfoGridSectionIdArgs = {
  locale?: InputMaybe<Scalars['String']>
}

/** [See type definition](https://app.contentful.com/spaces/9cxgygtadmvl/content_types/componentInfoGrid) */
export type ComponentInfoGridWhatWeRequireArgs = {
  locale?: InputMaybe<Scalars['String']>
}

export type ComponentInfoGridCollection = {
  __typename?: 'ComponentInfoGridCollection'
  items: Array<Maybe<ComponentInfoGrid>>
  limit: Scalars['Int']
  skip: Scalars['Int']
  total: Scalars['Int']
}

export type ComponentInfoGridFilter = {
  AND?: InputMaybe<Array<InputMaybe<ComponentInfoGridFilter>>>
  OR?: InputMaybe<Array<InputMaybe<ComponentInfoGridFilter>>>
  contentfulMetadata?: InputMaybe<ContentfulMetadataFilter>
  ctaText?: InputMaybe<Scalars['String']>
  ctaText_contains?: InputMaybe<Scalars['String']>
  ctaText_exists?: InputMaybe<Scalars['Boolean']>
  ctaText_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  ctaText_not?: InputMaybe<Scalars['String']>
  ctaText_not_contains?: InputMaybe<Scalars['String']>
  ctaText_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  ctaUrl?: InputMaybe<Scalars['String']>
  ctaUrl_contains?: InputMaybe<Scalars['String']>
  ctaUrl_exists?: InputMaybe<Scalars['Boolean']>
  ctaUrl_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  ctaUrl_not?: InputMaybe<Scalars['String']>
  ctaUrl_not_contains?: InputMaybe<Scalars['String']>
  ctaUrl_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  header?: InputMaybe<Scalars['String']>
  headerSubCopy_contains?: InputMaybe<Scalars['String']>
  headerSubCopy_exists?: InputMaybe<Scalars['Boolean']>
  headerSubCopy_not_contains?: InputMaybe<Scalars['String']>
  header_contains?: InputMaybe<Scalars['String']>
  header_exists?: InputMaybe<Scalars['Boolean']>
  header_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  header_not?: InputMaybe<Scalars['String']>
  header_not_contains?: InputMaybe<Scalars['String']>
  header_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  items?: InputMaybe<CfComponentImageHeaderSubNestedFilter>
  itemsCollection_exists?: InputMaybe<Scalars['Boolean']>
  sectionId?: InputMaybe<Scalars['String']>
  sectionId_contains?: InputMaybe<Scalars['String']>
  sectionId_exists?: InputMaybe<Scalars['Boolean']>
  sectionId_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  sectionId_not?: InputMaybe<Scalars['String']>
  sectionId_not_contains?: InputMaybe<Scalars['String']>
  sectionId_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  sys?: InputMaybe<SysFilter>
  whatWeRequire_contains_all?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  whatWeRequire_contains_none?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  whatWeRequire_contains_some?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  whatWeRequire_exists?: InputMaybe<Scalars['Boolean']>
}

export type ComponentInfoGridHeaderSubCopy = {
  __typename?: 'ComponentInfoGridHeaderSubCopy'
  json: Scalars['JSON']
  links: ComponentInfoGridHeaderSubCopyLinks
}

export type ComponentInfoGridHeaderSubCopyAssets = {
  __typename?: 'ComponentInfoGridHeaderSubCopyAssets'
  block: Array<Maybe<Asset>>
  hyperlink: Array<Maybe<Asset>>
}

export type ComponentInfoGridHeaderSubCopyEntries = {
  __typename?: 'ComponentInfoGridHeaderSubCopyEntries'
  block: Array<Maybe<Entry>>
  hyperlink: Array<Maybe<Entry>>
  inline: Array<Maybe<Entry>>
}

export type ComponentInfoGridHeaderSubCopyLinks = {
  __typename?: 'ComponentInfoGridHeaderSubCopyLinks'
  assets: ComponentInfoGridHeaderSubCopyAssets
  entries: ComponentInfoGridHeaderSubCopyEntries
  resources: ComponentInfoGridHeaderSubCopyResources
}

export type ComponentInfoGridHeaderSubCopyResources = {
  __typename?: 'ComponentInfoGridHeaderSubCopyResources'
  block: Array<ComponentInfoGridHeaderSubCopyResourcesBlock>
  hyperlink: Array<ComponentInfoGridHeaderSubCopyResourcesHyperlink>
  inline: Array<ComponentInfoGridHeaderSubCopyResourcesInline>
}

export type ComponentInfoGridHeaderSubCopyResourcesBlock = ResourceLink & {
  __typename?: 'ComponentInfoGridHeaderSubCopyResourcesBlock'
  sys: ResourceSys
}

export type ComponentInfoGridHeaderSubCopyResourcesHyperlink = ResourceLink & {
  __typename?: 'ComponentInfoGridHeaderSubCopyResourcesHyperlink'
  sys: ResourceSys
}

export type ComponentInfoGridHeaderSubCopyResourcesInline = ResourceLink & {
  __typename?: 'ComponentInfoGridHeaderSubCopyResourcesInline'
  sys: ResourceSys
}

export type ComponentInfoGridItemsCollection = {
  __typename?: 'ComponentInfoGridItemsCollection'
  items: Array<Maybe<ComponentImageHeaderSub>>
  limit: Scalars['Int']
  skip: Scalars['Int']
  total: Scalars['Int']
}

export enum ComponentInfoGridItemsCollectionOrder {
  HeaderAsc = 'header_ASC',
  HeaderDesc = 'header_DESC',
  InternalLabelAsc = 'internalLabel_ASC',
  InternalLabelDesc = 'internalLabel_DESC',
  SubHeadingAsc = 'subHeading_ASC',
  SubHeadingDesc = 'subHeading_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  TitleStyleAsc = 'titleStyle_ASC',
  TitleStyleDesc = 'titleStyle_DESC',
}

export type ComponentInfoGridLinkingCollections = {
  __typename?: 'ComponentInfoGridLinkingCollections'
  campaignPagesCollection?: Maybe<CampaignPagesCollection>
  entryCollection?: Maybe<EntryCollection>
  templateAboutUsCollection?: Maybe<TemplateAboutUsCollection>
  templateBookingCollection?: Maybe<TemplateBookingCollection>
  templateCareersCollection?: Maybe<TemplateCareersCollection>
  templateContactUsCollection?: Maybe<TemplateContactUsCollection>
  templateDestinationsCollection?: Maybe<TemplateDestinationsCollection>
  templateForaReserveCollection?: Maybe<TemplateForaReserveCollection>
  templateForaXCollection?: Maybe<TemplateForaXCollection>
  templateHomeCollection?: Maybe<TemplateHomeCollection>
  templateJoinUsCollection?: Maybe<TemplateJoinUsCollection>
  templateNewsletterCollection?: Maybe<TemplateNewsletterCollection>
  templatePartnersCollection?: Maybe<TemplatePartnersCollection>
  templateReviewsCollection?: Maybe<TemplateReviewsCollection>
  templateWhyBookWithForaCollection?: Maybe<TemplateWhyBookWithForaCollection>
}

export type ComponentInfoGridLinkingCollectionsCampaignPagesCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>
  locale?: InputMaybe<Scalars['String']>
  order?: InputMaybe<
    Array<
      InputMaybe<ComponentInfoGridLinkingCollectionsCampaignPagesCollectionOrder>
    >
  >
  preview?: InputMaybe<Scalars['Boolean']>
  skip?: InputMaybe<Scalars['Int']>
}

export type ComponentInfoGridLinkingCollectionsEntryCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>
  locale?: InputMaybe<Scalars['String']>
  preview?: InputMaybe<Scalars['Boolean']>
  skip?: InputMaybe<Scalars['Int']>
}

export type ComponentInfoGridLinkingCollectionsTemplateAboutUsCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>
  locale?: InputMaybe<Scalars['String']>
  order?: InputMaybe<
    Array<
      InputMaybe<ComponentInfoGridLinkingCollectionsTemplateAboutUsCollectionOrder>
    >
  >
  preview?: InputMaybe<Scalars['Boolean']>
  skip?: InputMaybe<Scalars['Int']>
}

export type ComponentInfoGridLinkingCollectionsTemplateBookingCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>
  locale?: InputMaybe<Scalars['String']>
  order?: InputMaybe<
    Array<
      InputMaybe<ComponentInfoGridLinkingCollectionsTemplateBookingCollectionOrder>
    >
  >
  preview?: InputMaybe<Scalars['Boolean']>
  skip?: InputMaybe<Scalars['Int']>
}

export type ComponentInfoGridLinkingCollectionsTemplateCareersCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>
  locale?: InputMaybe<Scalars['String']>
  order?: InputMaybe<
    Array<
      InputMaybe<ComponentInfoGridLinkingCollectionsTemplateCareersCollectionOrder>
    >
  >
  preview?: InputMaybe<Scalars['Boolean']>
  skip?: InputMaybe<Scalars['Int']>
}

export type ComponentInfoGridLinkingCollectionsTemplateContactUsCollectionArgs =
  {
    limit?: InputMaybe<Scalars['Int']>
    locale?: InputMaybe<Scalars['String']>
    order?: InputMaybe<
      Array<
        InputMaybe<ComponentInfoGridLinkingCollectionsTemplateContactUsCollectionOrder>
      >
    >
    preview?: InputMaybe<Scalars['Boolean']>
    skip?: InputMaybe<Scalars['Int']>
  }

export type ComponentInfoGridLinkingCollectionsTemplateDestinationsCollectionArgs =
  {
    limit?: InputMaybe<Scalars['Int']>
    locale?: InputMaybe<Scalars['String']>
    order?: InputMaybe<
      Array<
        InputMaybe<ComponentInfoGridLinkingCollectionsTemplateDestinationsCollectionOrder>
      >
    >
    preview?: InputMaybe<Scalars['Boolean']>
    skip?: InputMaybe<Scalars['Int']>
  }

export type ComponentInfoGridLinkingCollectionsTemplateForaReserveCollectionArgs =
  {
    limit?: InputMaybe<Scalars['Int']>
    locale?: InputMaybe<Scalars['String']>
    order?: InputMaybe<
      Array<
        InputMaybe<ComponentInfoGridLinkingCollectionsTemplateForaReserveCollectionOrder>
      >
    >
    preview?: InputMaybe<Scalars['Boolean']>
    skip?: InputMaybe<Scalars['Int']>
  }

export type ComponentInfoGridLinkingCollectionsTemplateForaXCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>
  locale?: InputMaybe<Scalars['String']>
  order?: InputMaybe<
    Array<
      InputMaybe<ComponentInfoGridLinkingCollectionsTemplateForaXCollectionOrder>
    >
  >
  preview?: InputMaybe<Scalars['Boolean']>
  skip?: InputMaybe<Scalars['Int']>
}

export type ComponentInfoGridLinkingCollectionsTemplateHomeCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>
  locale?: InputMaybe<Scalars['String']>
  order?: InputMaybe<
    Array<
      InputMaybe<ComponentInfoGridLinkingCollectionsTemplateHomeCollectionOrder>
    >
  >
  preview?: InputMaybe<Scalars['Boolean']>
  skip?: InputMaybe<Scalars['Int']>
}

export type ComponentInfoGridLinkingCollectionsTemplateJoinUsCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>
  locale?: InputMaybe<Scalars['String']>
  order?: InputMaybe<
    Array<
      InputMaybe<ComponentInfoGridLinkingCollectionsTemplateJoinUsCollectionOrder>
    >
  >
  preview?: InputMaybe<Scalars['Boolean']>
  skip?: InputMaybe<Scalars['Int']>
}

export type ComponentInfoGridLinkingCollectionsTemplateNewsletterCollectionArgs =
  {
    limit?: InputMaybe<Scalars['Int']>
    locale?: InputMaybe<Scalars['String']>
    order?: InputMaybe<
      Array<
        InputMaybe<ComponentInfoGridLinkingCollectionsTemplateNewsletterCollectionOrder>
      >
    >
    preview?: InputMaybe<Scalars['Boolean']>
    skip?: InputMaybe<Scalars['Int']>
  }

export type ComponentInfoGridLinkingCollectionsTemplatePartnersCollectionArgs =
  {
    limit?: InputMaybe<Scalars['Int']>
    locale?: InputMaybe<Scalars['String']>
    order?: InputMaybe<
      Array<
        InputMaybe<ComponentInfoGridLinkingCollectionsTemplatePartnersCollectionOrder>
      >
    >
    preview?: InputMaybe<Scalars['Boolean']>
    skip?: InputMaybe<Scalars['Int']>
  }

export type ComponentInfoGridLinkingCollectionsTemplateReviewsCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>
  locale?: InputMaybe<Scalars['String']>
  order?: InputMaybe<
    Array<
      InputMaybe<ComponentInfoGridLinkingCollectionsTemplateReviewsCollectionOrder>
    >
  >
  preview?: InputMaybe<Scalars['Boolean']>
  skip?: InputMaybe<Scalars['Int']>
}

export type ComponentInfoGridLinkingCollectionsTemplateWhyBookWithForaCollectionArgs =
  {
    limit?: InputMaybe<Scalars['Int']>
    locale?: InputMaybe<Scalars['String']>
    order?: InputMaybe<
      Array<
        InputMaybe<ComponentInfoGridLinkingCollectionsTemplateWhyBookWithForaCollectionOrder>
      >
    >
    preview?: InputMaybe<Scalars['Boolean']>
    skip?: InputMaybe<Scalars['Int']>
  }

export enum ComponentInfoGridLinkingCollectionsCampaignPagesCollectionOrder {
  CollapseFooterAsc = 'collapseFooter_ASC',
  CollapseFooterDesc = 'collapseFooter_DESC',
  CollapseHeaderAsc = 'collapseHeader_ASC',
  CollapseHeaderDesc = 'collapseHeader_DESC',
  HideFooterAsc = 'hideFooter_ASC',
  HideFooterDesc = 'hideFooter_DESC',
  HideGlobalBannerAsc = 'hideGlobalBanner_ASC',
  HideGlobalBannerDesc = 'hideGlobalBanner_DESC',
  JotformIdAsc = 'jotformId_ASC',
  JotformIdDesc = 'jotformId_DESC',
  NoindexNofollowAsc = 'noindexNofollow_ASC',
  NoindexNofollowDesc = 'noindexNofollow_DESC',
  ShowHeaderAsc = 'showHeader_ASC',
  ShowHeaderDesc = 'showHeader_DESC',
  SlugAsc = 'slug_ASC',
  SlugDesc = 'slug_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC',
}

export enum ComponentInfoGridLinkingCollectionsTemplateAboutUsCollectionOrder {
  CollapseHeaderAsc = 'collapseHeader_ASC',
  CollapseHeaderDesc = 'collapseHeader_DESC',
  HeaderBlockTitleAsc = 'headerBlockTitle_ASC',
  HeaderBlockTitleDesc = 'headerBlockTitle_DESC',
  ShowHeaderAsc = 'showHeader_ASC',
  ShowHeaderDesc = 'showHeader_DESC',
  SubtitleAsc = 'subtitle_ASC',
  SubtitleDesc = 'subtitle_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC',
}

export enum ComponentInfoGridLinkingCollectionsTemplateBookingCollectionOrder {
  CollapseHeaderAsc = 'collapseHeader_ASC',
  CollapseHeaderDesc = 'collapseHeader_DESC',
  ShowHeaderAsc = 'showHeader_ASC',
  ShowHeaderDesc = 'showHeader_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC',
}

export enum ComponentInfoGridLinkingCollectionsTemplateCareersCollectionOrder {
  CollapseHeaderAsc = 'collapseHeader_ASC',
  CollapseHeaderDesc = 'collapseHeader_DESC',
  ShowHeaderAsc = 'showHeader_ASC',
  ShowHeaderDesc = 'showHeader_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC',
}

export enum ComponentInfoGridLinkingCollectionsTemplateContactUsCollectionOrder {
  CollapseHeaderAsc = 'collapseHeader_ASC',
  CollapseHeaderDesc = 'collapseHeader_DESC',
  ShowHeaderAsc = 'showHeader_ASC',
  ShowHeaderDesc = 'showHeader_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC',
}

export enum ComponentInfoGridLinkingCollectionsTemplateDestinationsCollectionOrder {
  CollapseHeaderAsc = 'collapseHeader_ASC',
  CollapseHeaderDesc = 'collapseHeader_DESC',
  CollapsibleHeadingAsc = 'collapsibleHeading_ASC',
  CollapsibleHeadingDesc = 'collapsibleHeading_DESC',
  ShowHeaderAsc = 'showHeader_ASC',
  ShowHeaderDesc = 'showHeader_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC',
}

export enum ComponentInfoGridLinkingCollectionsTemplateForaReserveCollectionOrder {
  CollapseHeaderAsc = 'collapseHeader_ASC',
  CollapseHeaderDesc = 'collapseHeader_DESC',
  ShowHeaderAsc = 'showHeader_ASC',
  ShowHeaderDesc = 'showHeader_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC',
}

export enum ComponentInfoGridLinkingCollectionsTemplateForaXCollectionOrder {
  AdvisorGridHeaderAsc = 'advisorGridHeader_ASC',
  AdvisorGridHeaderDesc = 'advisorGridHeader_DESC',
  AdvisorGridSubCopyAsc = 'advisorGridSubCopy_ASC',
  AdvisorGridSubCopyDesc = 'advisorGridSubCopy_DESC',
  CollapseHeaderAsc = 'collapseHeader_ASC',
  CollapseHeaderDesc = 'collapseHeader_DESC',
  ShowHeaderAsc = 'showHeader_ASC',
  ShowHeaderDesc = 'showHeader_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC',
}

export enum ComponentInfoGridLinkingCollectionsTemplateHomeCollectionOrder {
  CollapseHeaderAsc = 'collapseHeader_ASC',
  CollapseHeaderDesc = 'collapseHeader_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC',
}

export enum ComponentInfoGridLinkingCollectionsTemplateJoinUsCollectionOrder {
  CollapseHeaderAsc = 'collapseHeader_ASC',
  CollapseHeaderDesc = 'collapseHeader_DESC',
  HideGlobalBannerAsc = 'hideGlobalBanner_ASC',
  HideGlobalBannerDesc = 'hideGlobalBanner_DESC',
  ShowHeaderAsc = 'showHeader_ASC',
  ShowHeaderDesc = 'showHeader_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC',
}

export enum ComponentInfoGridLinkingCollectionsTemplateNewsletterCollectionOrder {
  CollapseHeaderAsc = 'collapseHeader_ASC',
  CollapseHeaderDesc = 'collapseHeader_DESC',
  ShowHeaderAsc = 'showHeader_ASC',
  ShowHeaderDesc = 'showHeader_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC',
}

export enum ComponentInfoGridLinkingCollectionsTemplatePartnersCollectionOrder {
  CollapseHeaderAsc = 'collapseHeader_ASC',
  CollapseHeaderDesc = 'collapseHeader_DESC',
  ShowHeaderAsc = 'showHeader_ASC',
  ShowHeaderDesc = 'showHeader_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC',
}

export enum ComponentInfoGridLinkingCollectionsTemplateReviewsCollectionOrder {
  CollapseHeaderAsc = 'collapseHeader_ASC',
  CollapseHeaderDesc = 'collapseHeader_DESC',
  ShowHeaderAsc = 'showHeader_ASC',
  ShowHeaderDesc = 'showHeader_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC',
}

export enum ComponentInfoGridLinkingCollectionsTemplateWhyBookWithForaCollectionOrder {
  CollapseHeaderAsc = 'collapseHeader_ASC',
  CollapseHeaderDesc = 'collapseHeader_DESC',
  ShowHeaderAsc = 'showHeader_ASC',
  ShowHeaderDesc = 'showHeader_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC',
}

export enum ComponentInfoGridOrder {
  CtaTextAsc = 'ctaText_ASC',
  CtaTextDesc = 'ctaText_DESC',
  CtaUrlAsc = 'ctaUrl_ASC',
  CtaUrlDesc = 'ctaUrl_DESC',
  HeaderAsc = 'header_ASC',
  HeaderDesc = 'header_DESC',
  SectionIdAsc = 'sectionId_ASC',
  SectionIdDesc = 'sectionId_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
}

/** [See type definition](https://app.contentful.com/spaces/9cxgygtadmvl/content_types/componentLargePhotoCarouselItem) */
export type ComponentLargePhotoCarouselItem = Entry &
  _Node & {
    __typename?: 'ComponentLargePhotoCarouselItem'
    _id: Scalars['ID']
    analyticsModule?: Maybe<SettingsAnalyticsModule>
    contentfulMetadata: ContentfulMetadata
    internalLabel?: Maybe<Scalars['String']>
    linkedFrom?: Maybe<ComponentLargePhotoCarouselItemLinkingCollections>
    photo?: Maybe<Asset>
    sys: Sys
    textBelow?: Maybe<ComponentLargePhotoCarouselItemTextBelow>
  }

/** [See type definition](https://app.contentful.com/spaces/9cxgygtadmvl/content_types/componentLargePhotoCarouselItem) */
export type ComponentLargePhotoCarouselItemAnalyticsModuleArgs = {
  locale?: InputMaybe<Scalars['String']>
  preview?: InputMaybe<Scalars['Boolean']>
  where?: InputMaybe<SettingsAnalyticsModuleFilter>
}

/** [See type definition](https://app.contentful.com/spaces/9cxgygtadmvl/content_types/componentLargePhotoCarouselItem) */
export type ComponentLargePhotoCarouselItemInternalLabelArgs = {
  locale?: InputMaybe<Scalars['String']>
}

/** [See type definition](https://app.contentful.com/spaces/9cxgygtadmvl/content_types/componentLargePhotoCarouselItem) */
export type ComponentLargePhotoCarouselItemLinkedFromArgs = {
  allowedLocales?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
}

/** [See type definition](https://app.contentful.com/spaces/9cxgygtadmvl/content_types/componentLargePhotoCarouselItem) */
export type ComponentLargePhotoCarouselItemPhotoArgs = {
  locale?: InputMaybe<Scalars['String']>
  preview?: InputMaybe<Scalars['Boolean']>
}

/** [See type definition](https://app.contentful.com/spaces/9cxgygtadmvl/content_types/componentLargePhotoCarouselItem) */
export type ComponentLargePhotoCarouselItemTextBelowArgs = {
  locale?: InputMaybe<Scalars['String']>
}

export type ComponentLargePhotoCarouselItemCollection = {
  __typename?: 'ComponentLargePhotoCarouselItemCollection'
  items: Array<Maybe<ComponentLargePhotoCarouselItem>>
  limit: Scalars['Int']
  skip: Scalars['Int']
  total: Scalars['Int']
}

export type ComponentLargePhotoCarouselItemFilter = {
  AND?: InputMaybe<Array<InputMaybe<ComponentLargePhotoCarouselItemFilter>>>
  OR?: InputMaybe<Array<InputMaybe<ComponentLargePhotoCarouselItemFilter>>>
  analyticsModule?: InputMaybe<CfSettingsAnalyticsModuleNestedFilter>
  analyticsModule_exists?: InputMaybe<Scalars['Boolean']>
  contentfulMetadata?: InputMaybe<ContentfulMetadataFilter>
  internalLabel?: InputMaybe<Scalars['String']>
  internalLabel_contains?: InputMaybe<Scalars['String']>
  internalLabel_exists?: InputMaybe<Scalars['Boolean']>
  internalLabel_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  internalLabel_not?: InputMaybe<Scalars['String']>
  internalLabel_not_contains?: InputMaybe<Scalars['String']>
  internalLabel_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  photo_exists?: InputMaybe<Scalars['Boolean']>
  sys?: InputMaybe<SysFilter>
  textBelow_contains?: InputMaybe<Scalars['String']>
  textBelow_exists?: InputMaybe<Scalars['Boolean']>
  textBelow_not_contains?: InputMaybe<Scalars['String']>
}

export type ComponentLargePhotoCarouselItemLinkingCollections = {
  __typename?: 'ComponentLargePhotoCarouselItemLinkingCollections'
  componentCarouselSectionCollection?: Maybe<ComponentCarouselSectionCollection>
  entryCollection?: Maybe<EntryCollection>
}

export type ComponentLargePhotoCarouselItemLinkingCollectionsComponentCarouselSectionCollectionArgs =
  {
    limit?: InputMaybe<Scalars['Int']>
    locale?: InputMaybe<Scalars['String']>
    order?: InputMaybe<
      Array<
        InputMaybe<ComponentLargePhotoCarouselItemLinkingCollectionsComponentCarouselSectionCollectionOrder>
      >
    >
    preview?: InputMaybe<Scalars['Boolean']>
    skip?: InputMaybe<Scalars['Int']>
  }

export type ComponentLargePhotoCarouselItemLinkingCollectionsEntryCollectionArgs =
  {
    limit?: InputMaybe<Scalars['Int']>
    locale?: InputMaybe<Scalars['String']>
    preview?: InputMaybe<Scalars['Boolean']>
    skip?: InputMaybe<Scalars['Int']>
  }

export enum ComponentLargePhotoCarouselItemLinkingCollectionsComponentCarouselSectionCollectionOrder {
  ArrowStyleAsc = 'arrowStyle_ASC',
  ArrowStyleDesc = 'arrowStyle_DESC',
  DotsStyleAsc = 'dotsStyle_ASC',
  DotsStyleDesc = 'dotsStyle_DESC',
  HeadingAsc = 'heading_ASC',
  HeadingDesc = 'heading_DESC',
  InternalLabelAsc = 'internalLabel_ASC',
  InternalLabelDesc = 'internalLabel_DESC',
  ItemsPerPageAsc = 'itemsPerPage_ASC',
  ItemsPerPageDesc = 'itemsPerPage_DESC',
  SectionIdAsc = 'sectionId_ASC',
  SectionIdDesc = 'sectionId_DESC',
  ShowNextMobileAsc = 'showNextMobile_ASC',
  ShowNextMobileDesc = 'showNextMobile_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  TitleStyleAsc = 'titleStyle_ASC',
  TitleStyleDesc = 'titleStyle_DESC',
}

export enum ComponentLargePhotoCarouselItemOrder {
  InternalLabelAsc = 'internalLabel_ASC',
  InternalLabelDesc = 'internalLabel_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
}

export type ComponentLargePhotoCarouselItemTextBelow = {
  __typename?: 'ComponentLargePhotoCarouselItemTextBelow'
  json: Scalars['JSON']
  links: ComponentLargePhotoCarouselItemTextBelowLinks
}

export type ComponentLargePhotoCarouselItemTextBelowAssets = {
  __typename?: 'ComponentLargePhotoCarouselItemTextBelowAssets'
  block: Array<Maybe<Asset>>
  hyperlink: Array<Maybe<Asset>>
}

export type ComponentLargePhotoCarouselItemTextBelowEntries = {
  __typename?: 'ComponentLargePhotoCarouselItemTextBelowEntries'
  block: Array<Maybe<Entry>>
  hyperlink: Array<Maybe<Entry>>
  inline: Array<Maybe<Entry>>
}

export type ComponentLargePhotoCarouselItemTextBelowLinks = {
  __typename?: 'ComponentLargePhotoCarouselItemTextBelowLinks'
  assets: ComponentLargePhotoCarouselItemTextBelowAssets
  entries: ComponentLargePhotoCarouselItemTextBelowEntries
  resources: ComponentLargePhotoCarouselItemTextBelowResources
}

export type ComponentLargePhotoCarouselItemTextBelowResources = {
  __typename?: 'ComponentLargePhotoCarouselItemTextBelowResources'
  block: Array<ComponentLargePhotoCarouselItemTextBelowResourcesBlock>
  hyperlink: Array<ComponentLargePhotoCarouselItemTextBelowResourcesHyperlink>
  inline: Array<ComponentLargePhotoCarouselItemTextBelowResourcesInline>
}

export type ComponentLargePhotoCarouselItemTextBelowResourcesBlock =
  ResourceLink & {
    __typename?: 'ComponentLargePhotoCarouselItemTextBelowResourcesBlock'
    sys: ResourceSys
  }

export type ComponentLargePhotoCarouselItemTextBelowResourcesHyperlink =
  ResourceLink & {
    __typename?: 'ComponentLargePhotoCarouselItemTextBelowResourcesHyperlink'
    sys: ResourceSys
  }

export type ComponentLargePhotoCarouselItemTextBelowResourcesInline =
  ResourceLink & {
    __typename?: 'ComponentLargePhotoCarouselItemTextBelowResourcesInline'
    sys: ResourceSys
  }

/** [See type definition](https://app.contentful.com/spaces/9cxgygtadmvl/content_types/componentPartnerGrid) */
export type ComponentPartnerGrid = Entry &
  _Node & {
    __typename?: 'ComponentPartnerGrid'
    _id: Scalars['ID']
    analyticsModule?: Maybe<SettingsAnalyticsModule>
    contentfulMetadata: ContentfulMetadata
    featuredCruisesCollection?: Maybe<ComponentPartnerGridFeaturedCruisesCollection>
    featuredExperiencesCollection?: Maybe<ComponentPartnerGridFeaturedExperiencesCollection>
    featuredHotelsCollection?: Maybe<ComponentPartnerGridFeaturedHotelsCollection>
    foraReserveCollection?: Maybe<ComponentPartnerGridForaReserveCollection>
    heading?: Maybe<Scalars['String']>
    headingSharedState?: Maybe<Scalars['String']>
    internalLabel?: Maybe<Scalars['String']>
    linkedFrom?: Maybe<ComponentPartnerGridLinkingCollections>
    regularCruisesCollection?: Maybe<ComponentPartnerGridRegularCruisesCollection>
    regularExperiencesCollection?: Maybe<ComponentPartnerGridRegularExperiencesCollection>
    regularHotelsCollection?: Maybe<ComponentPartnerGridRegularHotelsCollection>
    subheading?: Maybe<ComponentPartnerGridSubheading>
    subheadingSharedState?: Maybe<ComponentPartnerGridSubheadingSharedState>
    sys: Sys
    titleStyle?: Maybe<Scalars['String']>
  }

/** [See type definition](https://app.contentful.com/spaces/9cxgygtadmvl/content_types/componentPartnerGrid) */
export type ComponentPartnerGridAnalyticsModuleArgs = {
  locale?: InputMaybe<Scalars['String']>
  preview?: InputMaybe<Scalars['Boolean']>
  where?: InputMaybe<SettingsAnalyticsModuleFilter>
}

/** [See type definition](https://app.contentful.com/spaces/9cxgygtadmvl/content_types/componentPartnerGrid) */
export type ComponentPartnerGridFeaturedCruisesCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>
  locale?: InputMaybe<Scalars['String']>
  order?: InputMaybe<
    Array<InputMaybe<ComponentPartnerGridFeaturedCruisesCollectionOrder>>
  >
  preview?: InputMaybe<Scalars['Boolean']>
  skip?: InputMaybe<Scalars['Int']>
  where?: InputMaybe<EntryPartnerFilter>
}

/** [See type definition](https://app.contentful.com/spaces/9cxgygtadmvl/content_types/componentPartnerGrid) */
export type ComponentPartnerGridFeaturedExperiencesCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>
  locale?: InputMaybe<Scalars['String']>
  order?: InputMaybe<
    Array<InputMaybe<ComponentPartnerGridFeaturedExperiencesCollectionOrder>>
  >
  preview?: InputMaybe<Scalars['Boolean']>
  skip?: InputMaybe<Scalars['Int']>
  where?: InputMaybe<EntryPartnerFilter>
}

/** [See type definition](https://app.contentful.com/spaces/9cxgygtadmvl/content_types/componentPartnerGrid) */
export type ComponentPartnerGridFeaturedHotelsCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>
  locale?: InputMaybe<Scalars['String']>
  order?: InputMaybe<
    Array<InputMaybe<ComponentPartnerGridFeaturedHotelsCollectionOrder>>
  >
  preview?: InputMaybe<Scalars['Boolean']>
  skip?: InputMaybe<Scalars['Int']>
  where?: InputMaybe<EntryPartnerFilter>
}

/** [See type definition](https://app.contentful.com/spaces/9cxgygtadmvl/content_types/componentPartnerGrid) */
export type ComponentPartnerGridForaReserveCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>
  locale?: InputMaybe<Scalars['String']>
  order?: InputMaybe<
    Array<InputMaybe<ComponentPartnerGridForaReserveCollectionOrder>>
  >
  preview?: InputMaybe<Scalars['Boolean']>
  skip?: InputMaybe<Scalars['Int']>
  where?: InputMaybe<EntryPartnerFilter>
}

/** [See type definition](https://app.contentful.com/spaces/9cxgygtadmvl/content_types/componentPartnerGrid) */
export type ComponentPartnerGridHeadingArgs = {
  locale?: InputMaybe<Scalars['String']>
}

/** [See type definition](https://app.contentful.com/spaces/9cxgygtadmvl/content_types/componentPartnerGrid) */
export type ComponentPartnerGridHeadingSharedStateArgs = {
  locale?: InputMaybe<Scalars['String']>
}

/** [See type definition](https://app.contentful.com/spaces/9cxgygtadmvl/content_types/componentPartnerGrid) */
export type ComponentPartnerGridInternalLabelArgs = {
  locale?: InputMaybe<Scalars['String']>
}

/** [See type definition](https://app.contentful.com/spaces/9cxgygtadmvl/content_types/componentPartnerGrid) */
export type ComponentPartnerGridLinkedFromArgs = {
  allowedLocales?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
}

/** [See type definition](https://app.contentful.com/spaces/9cxgygtadmvl/content_types/componentPartnerGrid) */
export type ComponentPartnerGridRegularCruisesCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>
  locale?: InputMaybe<Scalars['String']>
  order?: InputMaybe<
    Array<InputMaybe<ComponentPartnerGridRegularCruisesCollectionOrder>>
  >
  preview?: InputMaybe<Scalars['Boolean']>
  skip?: InputMaybe<Scalars['Int']>
  where?: InputMaybe<EntryPartnerFilter>
}

/** [See type definition](https://app.contentful.com/spaces/9cxgygtadmvl/content_types/componentPartnerGrid) */
export type ComponentPartnerGridRegularExperiencesCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>
  locale?: InputMaybe<Scalars['String']>
  order?: InputMaybe<
    Array<InputMaybe<ComponentPartnerGridRegularExperiencesCollectionOrder>>
  >
  preview?: InputMaybe<Scalars['Boolean']>
  skip?: InputMaybe<Scalars['Int']>
  where?: InputMaybe<EntryPartnerFilter>
}

/** [See type definition](https://app.contentful.com/spaces/9cxgygtadmvl/content_types/componentPartnerGrid) */
export type ComponentPartnerGridRegularHotelsCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>
  locale?: InputMaybe<Scalars['String']>
  order?: InputMaybe<
    Array<InputMaybe<ComponentPartnerGridRegularHotelsCollectionOrder>>
  >
  preview?: InputMaybe<Scalars['Boolean']>
  skip?: InputMaybe<Scalars['Int']>
  where?: InputMaybe<EntryPartnerFilter>
}

/** [See type definition](https://app.contentful.com/spaces/9cxgygtadmvl/content_types/componentPartnerGrid) */
export type ComponentPartnerGridSubheadingArgs = {
  locale?: InputMaybe<Scalars['String']>
}

/** [See type definition](https://app.contentful.com/spaces/9cxgygtadmvl/content_types/componentPartnerGrid) */
export type ComponentPartnerGridSubheadingSharedStateArgs = {
  locale?: InputMaybe<Scalars['String']>
}

/** [See type definition](https://app.contentful.com/spaces/9cxgygtadmvl/content_types/componentPartnerGrid) */
export type ComponentPartnerGridTitleStyleArgs = {
  locale?: InputMaybe<Scalars['String']>
}

export type ComponentPartnerGridCollection = {
  __typename?: 'ComponentPartnerGridCollection'
  items: Array<Maybe<ComponentPartnerGrid>>
  limit: Scalars['Int']
  skip: Scalars['Int']
  total: Scalars['Int']
}

export type ComponentPartnerGridFeaturedCruisesCollection = {
  __typename?: 'ComponentPartnerGridFeaturedCruisesCollection'
  items: Array<Maybe<EntryPartner>>
  limit: Scalars['Int']
  skip: Scalars['Int']
  total: Scalars['Int']
}

export enum ComponentPartnerGridFeaturedCruisesCollectionOrder {
  DetailSummaryAsc = 'detailSummary_ASC',
  DetailSummaryDesc = 'detailSummary_DESC',
  FeaturedAsc = 'featured_ASC',
  FeaturedDesc = 'featured_DESC',
  HasDetailPageAsc = 'hasDetailPage_ASC',
  HasDetailPageDesc = 'hasDetailPage_DESC',
  HideFromPartnerGridAsc = 'hideFromPartnerGrid_ASC',
  HideFromPartnerGridDesc = 'hideFromPartnerGrid_DESC',
  NameAsc = 'name_ASC',
  NameDesc = 'name_DESC',
  PartnerCategoryAsc = 'partnerCategory_ASC',
  PartnerCategoryDesc = 'partnerCategory_DESC',
  PartnerTypeAsc = 'partnerType_ASC',
  PartnerTypeDesc = 'partnerType_DESC',
  SlugAsc = 'slug_ASC',
  SlugDesc = 'slug_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
}

export type ComponentPartnerGridFeaturedExperiencesCollection = {
  __typename?: 'ComponentPartnerGridFeaturedExperiencesCollection'
  items: Array<Maybe<EntryPartner>>
  limit: Scalars['Int']
  skip: Scalars['Int']
  total: Scalars['Int']
}

export enum ComponentPartnerGridFeaturedExperiencesCollectionOrder {
  DetailSummaryAsc = 'detailSummary_ASC',
  DetailSummaryDesc = 'detailSummary_DESC',
  FeaturedAsc = 'featured_ASC',
  FeaturedDesc = 'featured_DESC',
  HasDetailPageAsc = 'hasDetailPage_ASC',
  HasDetailPageDesc = 'hasDetailPage_DESC',
  HideFromPartnerGridAsc = 'hideFromPartnerGrid_ASC',
  HideFromPartnerGridDesc = 'hideFromPartnerGrid_DESC',
  NameAsc = 'name_ASC',
  NameDesc = 'name_DESC',
  PartnerCategoryAsc = 'partnerCategory_ASC',
  PartnerCategoryDesc = 'partnerCategory_DESC',
  PartnerTypeAsc = 'partnerType_ASC',
  PartnerTypeDesc = 'partnerType_DESC',
  SlugAsc = 'slug_ASC',
  SlugDesc = 'slug_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
}

export type ComponentPartnerGridFeaturedHotelsCollection = {
  __typename?: 'ComponentPartnerGridFeaturedHotelsCollection'
  items: Array<Maybe<EntryPartner>>
  limit: Scalars['Int']
  skip: Scalars['Int']
  total: Scalars['Int']
}

export enum ComponentPartnerGridFeaturedHotelsCollectionOrder {
  DetailSummaryAsc = 'detailSummary_ASC',
  DetailSummaryDesc = 'detailSummary_DESC',
  FeaturedAsc = 'featured_ASC',
  FeaturedDesc = 'featured_DESC',
  HasDetailPageAsc = 'hasDetailPage_ASC',
  HasDetailPageDesc = 'hasDetailPage_DESC',
  HideFromPartnerGridAsc = 'hideFromPartnerGrid_ASC',
  HideFromPartnerGridDesc = 'hideFromPartnerGrid_DESC',
  NameAsc = 'name_ASC',
  NameDesc = 'name_DESC',
  PartnerCategoryAsc = 'partnerCategory_ASC',
  PartnerCategoryDesc = 'partnerCategory_DESC',
  PartnerTypeAsc = 'partnerType_ASC',
  PartnerTypeDesc = 'partnerType_DESC',
  SlugAsc = 'slug_ASC',
  SlugDesc = 'slug_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
}

export type ComponentPartnerGridFilter = {
  AND?: InputMaybe<Array<InputMaybe<ComponentPartnerGridFilter>>>
  OR?: InputMaybe<Array<InputMaybe<ComponentPartnerGridFilter>>>
  analyticsModule?: InputMaybe<CfSettingsAnalyticsModuleNestedFilter>
  analyticsModule_exists?: InputMaybe<Scalars['Boolean']>
  contentfulMetadata?: InputMaybe<ContentfulMetadataFilter>
  featuredCruises?: InputMaybe<CfEntryPartnerNestedFilter>
  featuredCruisesCollection_exists?: InputMaybe<Scalars['Boolean']>
  featuredExperiences?: InputMaybe<CfEntryPartnerNestedFilter>
  featuredExperiencesCollection_exists?: InputMaybe<Scalars['Boolean']>
  featuredHotels?: InputMaybe<CfEntryPartnerNestedFilter>
  featuredHotelsCollection_exists?: InputMaybe<Scalars['Boolean']>
  foraReserve?: InputMaybe<CfEntryPartnerNestedFilter>
  foraReserveCollection_exists?: InputMaybe<Scalars['Boolean']>
  heading?: InputMaybe<Scalars['String']>
  headingSharedState?: InputMaybe<Scalars['String']>
  headingSharedState_contains?: InputMaybe<Scalars['String']>
  headingSharedState_exists?: InputMaybe<Scalars['Boolean']>
  headingSharedState_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  headingSharedState_not?: InputMaybe<Scalars['String']>
  headingSharedState_not_contains?: InputMaybe<Scalars['String']>
  headingSharedState_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  heading_contains?: InputMaybe<Scalars['String']>
  heading_exists?: InputMaybe<Scalars['Boolean']>
  heading_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  heading_not?: InputMaybe<Scalars['String']>
  heading_not_contains?: InputMaybe<Scalars['String']>
  heading_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  internalLabel?: InputMaybe<Scalars['String']>
  internalLabel_contains?: InputMaybe<Scalars['String']>
  internalLabel_exists?: InputMaybe<Scalars['Boolean']>
  internalLabel_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  internalLabel_not?: InputMaybe<Scalars['String']>
  internalLabel_not_contains?: InputMaybe<Scalars['String']>
  internalLabel_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  regularCruises?: InputMaybe<CfEntryPartnerNestedFilter>
  regularCruisesCollection_exists?: InputMaybe<Scalars['Boolean']>
  regularExperiences?: InputMaybe<CfEntryPartnerNestedFilter>
  regularExperiencesCollection_exists?: InputMaybe<Scalars['Boolean']>
  regularHotels?: InputMaybe<CfEntryPartnerNestedFilter>
  regularHotelsCollection_exists?: InputMaybe<Scalars['Boolean']>
  subheadingSharedState_contains?: InputMaybe<Scalars['String']>
  subheadingSharedState_exists?: InputMaybe<Scalars['Boolean']>
  subheadingSharedState_not_contains?: InputMaybe<Scalars['String']>
  subheading_contains?: InputMaybe<Scalars['String']>
  subheading_exists?: InputMaybe<Scalars['Boolean']>
  subheading_not_contains?: InputMaybe<Scalars['String']>
  sys?: InputMaybe<SysFilter>
  titleStyle?: InputMaybe<Scalars['String']>
  titleStyle_contains?: InputMaybe<Scalars['String']>
  titleStyle_exists?: InputMaybe<Scalars['Boolean']>
  titleStyle_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  titleStyle_not?: InputMaybe<Scalars['String']>
  titleStyle_not_contains?: InputMaybe<Scalars['String']>
  titleStyle_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
}

export type ComponentPartnerGridForaReserveCollection = {
  __typename?: 'ComponentPartnerGridForaReserveCollection'
  items: Array<Maybe<EntryPartner>>
  limit: Scalars['Int']
  skip: Scalars['Int']
  total: Scalars['Int']
}

export enum ComponentPartnerGridForaReserveCollectionOrder {
  DetailSummaryAsc = 'detailSummary_ASC',
  DetailSummaryDesc = 'detailSummary_DESC',
  FeaturedAsc = 'featured_ASC',
  FeaturedDesc = 'featured_DESC',
  HasDetailPageAsc = 'hasDetailPage_ASC',
  HasDetailPageDesc = 'hasDetailPage_DESC',
  HideFromPartnerGridAsc = 'hideFromPartnerGrid_ASC',
  HideFromPartnerGridDesc = 'hideFromPartnerGrid_DESC',
  NameAsc = 'name_ASC',
  NameDesc = 'name_DESC',
  PartnerCategoryAsc = 'partnerCategory_ASC',
  PartnerCategoryDesc = 'partnerCategory_DESC',
  PartnerTypeAsc = 'partnerType_ASC',
  PartnerTypeDesc = 'partnerType_DESC',
  SlugAsc = 'slug_ASC',
  SlugDesc = 'slug_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
}

export type ComponentPartnerGridLinkingCollections = {
  __typename?: 'ComponentPartnerGridLinkingCollections'
  campaignPagesCollection?: Maybe<CampaignPagesCollection>
  entryCollection?: Maybe<EntryCollection>
  templateAboutUsCollection?: Maybe<TemplateAboutUsCollection>
  templateBookingCollection?: Maybe<TemplateBookingCollection>
  templateCareersCollection?: Maybe<TemplateCareersCollection>
  templateContactUsCollection?: Maybe<TemplateContactUsCollection>
  templateDestinationsCollection?: Maybe<TemplateDestinationsCollection>
  templateForaReserveCollection?: Maybe<TemplateForaReserveCollection>
  templateForaXCollection?: Maybe<TemplateForaXCollection>
  templateHomeCollection?: Maybe<TemplateHomeCollection>
  templateJoinUsCollection?: Maybe<TemplateJoinUsCollection>
  templateNewsletterCollection?: Maybe<TemplateNewsletterCollection>
  templatePageCollection?: Maybe<TemplatePageCollection>
  templatePartnersCollection?: Maybe<TemplatePartnersCollection>
  templateReviewsCollection?: Maybe<TemplateReviewsCollection>
  templateWhyBookWithForaCollection?: Maybe<TemplateWhyBookWithForaCollection>
}

export type ComponentPartnerGridLinkingCollectionsCampaignPagesCollectionArgs =
  {
    limit?: InputMaybe<Scalars['Int']>
    locale?: InputMaybe<Scalars['String']>
    order?: InputMaybe<
      Array<
        InputMaybe<ComponentPartnerGridLinkingCollectionsCampaignPagesCollectionOrder>
      >
    >
    preview?: InputMaybe<Scalars['Boolean']>
    skip?: InputMaybe<Scalars['Int']>
  }

export type ComponentPartnerGridLinkingCollectionsEntryCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>
  locale?: InputMaybe<Scalars['String']>
  preview?: InputMaybe<Scalars['Boolean']>
  skip?: InputMaybe<Scalars['Int']>
}

export type ComponentPartnerGridLinkingCollectionsTemplateAboutUsCollectionArgs =
  {
    limit?: InputMaybe<Scalars['Int']>
    locale?: InputMaybe<Scalars['String']>
    order?: InputMaybe<
      Array<
        InputMaybe<ComponentPartnerGridLinkingCollectionsTemplateAboutUsCollectionOrder>
      >
    >
    preview?: InputMaybe<Scalars['Boolean']>
    skip?: InputMaybe<Scalars['Int']>
  }

export type ComponentPartnerGridLinkingCollectionsTemplateBookingCollectionArgs =
  {
    limit?: InputMaybe<Scalars['Int']>
    locale?: InputMaybe<Scalars['String']>
    order?: InputMaybe<
      Array<
        InputMaybe<ComponentPartnerGridLinkingCollectionsTemplateBookingCollectionOrder>
      >
    >
    preview?: InputMaybe<Scalars['Boolean']>
    skip?: InputMaybe<Scalars['Int']>
  }

export type ComponentPartnerGridLinkingCollectionsTemplateCareersCollectionArgs =
  {
    limit?: InputMaybe<Scalars['Int']>
    locale?: InputMaybe<Scalars['String']>
    order?: InputMaybe<
      Array<
        InputMaybe<ComponentPartnerGridLinkingCollectionsTemplateCareersCollectionOrder>
      >
    >
    preview?: InputMaybe<Scalars['Boolean']>
    skip?: InputMaybe<Scalars['Int']>
  }

export type ComponentPartnerGridLinkingCollectionsTemplateContactUsCollectionArgs =
  {
    limit?: InputMaybe<Scalars['Int']>
    locale?: InputMaybe<Scalars['String']>
    order?: InputMaybe<
      Array<
        InputMaybe<ComponentPartnerGridLinkingCollectionsTemplateContactUsCollectionOrder>
      >
    >
    preview?: InputMaybe<Scalars['Boolean']>
    skip?: InputMaybe<Scalars['Int']>
  }

export type ComponentPartnerGridLinkingCollectionsTemplateDestinationsCollectionArgs =
  {
    limit?: InputMaybe<Scalars['Int']>
    locale?: InputMaybe<Scalars['String']>
    order?: InputMaybe<
      Array<
        InputMaybe<ComponentPartnerGridLinkingCollectionsTemplateDestinationsCollectionOrder>
      >
    >
    preview?: InputMaybe<Scalars['Boolean']>
    skip?: InputMaybe<Scalars['Int']>
  }

export type ComponentPartnerGridLinkingCollectionsTemplateForaReserveCollectionArgs =
  {
    limit?: InputMaybe<Scalars['Int']>
    locale?: InputMaybe<Scalars['String']>
    order?: InputMaybe<
      Array<
        InputMaybe<ComponentPartnerGridLinkingCollectionsTemplateForaReserveCollectionOrder>
      >
    >
    preview?: InputMaybe<Scalars['Boolean']>
    skip?: InputMaybe<Scalars['Int']>
  }

export type ComponentPartnerGridLinkingCollectionsTemplateForaXCollectionArgs =
  {
    limit?: InputMaybe<Scalars['Int']>
    locale?: InputMaybe<Scalars['String']>
    order?: InputMaybe<
      Array<
        InputMaybe<ComponentPartnerGridLinkingCollectionsTemplateForaXCollectionOrder>
      >
    >
    preview?: InputMaybe<Scalars['Boolean']>
    skip?: InputMaybe<Scalars['Int']>
  }

export type ComponentPartnerGridLinkingCollectionsTemplateHomeCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>
  locale?: InputMaybe<Scalars['String']>
  order?: InputMaybe<
    Array<
      InputMaybe<ComponentPartnerGridLinkingCollectionsTemplateHomeCollectionOrder>
    >
  >
  preview?: InputMaybe<Scalars['Boolean']>
  skip?: InputMaybe<Scalars['Int']>
}

export type ComponentPartnerGridLinkingCollectionsTemplateJoinUsCollectionArgs =
  {
    limit?: InputMaybe<Scalars['Int']>
    locale?: InputMaybe<Scalars['String']>
    order?: InputMaybe<
      Array<
        InputMaybe<ComponentPartnerGridLinkingCollectionsTemplateJoinUsCollectionOrder>
      >
    >
    preview?: InputMaybe<Scalars['Boolean']>
    skip?: InputMaybe<Scalars['Int']>
  }

export type ComponentPartnerGridLinkingCollectionsTemplateNewsletterCollectionArgs =
  {
    limit?: InputMaybe<Scalars['Int']>
    locale?: InputMaybe<Scalars['String']>
    order?: InputMaybe<
      Array<
        InputMaybe<ComponentPartnerGridLinkingCollectionsTemplateNewsletterCollectionOrder>
      >
    >
    preview?: InputMaybe<Scalars['Boolean']>
    skip?: InputMaybe<Scalars['Int']>
  }

export type ComponentPartnerGridLinkingCollectionsTemplatePageCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>
  locale?: InputMaybe<Scalars['String']>
  order?: InputMaybe<
    Array<
      InputMaybe<ComponentPartnerGridLinkingCollectionsTemplatePageCollectionOrder>
    >
  >
  preview?: InputMaybe<Scalars['Boolean']>
  skip?: InputMaybe<Scalars['Int']>
}

export type ComponentPartnerGridLinkingCollectionsTemplatePartnersCollectionArgs =
  {
    limit?: InputMaybe<Scalars['Int']>
    locale?: InputMaybe<Scalars['String']>
    order?: InputMaybe<
      Array<
        InputMaybe<ComponentPartnerGridLinkingCollectionsTemplatePartnersCollectionOrder>
      >
    >
    preview?: InputMaybe<Scalars['Boolean']>
    skip?: InputMaybe<Scalars['Int']>
  }

export type ComponentPartnerGridLinkingCollectionsTemplateReviewsCollectionArgs =
  {
    limit?: InputMaybe<Scalars['Int']>
    locale?: InputMaybe<Scalars['String']>
    order?: InputMaybe<
      Array<
        InputMaybe<ComponentPartnerGridLinkingCollectionsTemplateReviewsCollectionOrder>
      >
    >
    preview?: InputMaybe<Scalars['Boolean']>
    skip?: InputMaybe<Scalars['Int']>
  }

export type ComponentPartnerGridLinkingCollectionsTemplateWhyBookWithForaCollectionArgs =
  {
    limit?: InputMaybe<Scalars['Int']>
    locale?: InputMaybe<Scalars['String']>
    order?: InputMaybe<
      Array<
        InputMaybe<ComponentPartnerGridLinkingCollectionsTemplateWhyBookWithForaCollectionOrder>
      >
    >
    preview?: InputMaybe<Scalars['Boolean']>
    skip?: InputMaybe<Scalars['Int']>
  }

export enum ComponentPartnerGridLinkingCollectionsCampaignPagesCollectionOrder {
  CollapseFooterAsc = 'collapseFooter_ASC',
  CollapseFooterDesc = 'collapseFooter_DESC',
  CollapseHeaderAsc = 'collapseHeader_ASC',
  CollapseHeaderDesc = 'collapseHeader_DESC',
  HideFooterAsc = 'hideFooter_ASC',
  HideFooterDesc = 'hideFooter_DESC',
  HideGlobalBannerAsc = 'hideGlobalBanner_ASC',
  HideGlobalBannerDesc = 'hideGlobalBanner_DESC',
  JotformIdAsc = 'jotformId_ASC',
  JotformIdDesc = 'jotformId_DESC',
  NoindexNofollowAsc = 'noindexNofollow_ASC',
  NoindexNofollowDesc = 'noindexNofollow_DESC',
  ShowHeaderAsc = 'showHeader_ASC',
  ShowHeaderDesc = 'showHeader_DESC',
  SlugAsc = 'slug_ASC',
  SlugDesc = 'slug_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC',
}

export enum ComponentPartnerGridLinkingCollectionsTemplateAboutUsCollectionOrder {
  CollapseHeaderAsc = 'collapseHeader_ASC',
  CollapseHeaderDesc = 'collapseHeader_DESC',
  HeaderBlockTitleAsc = 'headerBlockTitle_ASC',
  HeaderBlockTitleDesc = 'headerBlockTitle_DESC',
  ShowHeaderAsc = 'showHeader_ASC',
  ShowHeaderDesc = 'showHeader_DESC',
  SubtitleAsc = 'subtitle_ASC',
  SubtitleDesc = 'subtitle_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC',
}

export enum ComponentPartnerGridLinkingCollectionsTemplateBookingCollectionOrder {
  CollapseHeaderAsc = 'collapseHeader_ASC',
  CollapseHeaderDesc = 'collapseHeader_DESC',
  ShowHeaderAsc = 'showHeader_ASC',
  ShowHeaderDesc = 'showHeader_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC',
}

export enum ComponentPartnerGridLinkingCollectionsTemplateCareersCollectionOrder {
  CollapseHeaderAsc = 'collapseHeader_ASC',
  CollapseHeaderDesc = 'collapseHeader_DESC',
  ShowHeaderAsc = 'showHeader_ASC',
  ShowHeaderDesc = 'showHeader_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC',
}

export enum ComponentPartnerGridLinkingCollectionsTemplateContactUsCollectionOrder {
  CollapseHeaderAsc = 'collapseHeader_ASC',
  CollapseHeaderDesc = 'collapseHeader_DESC',
  ShowHeaderAsc = 'showHeader_ASC',
  ShowHeaderDesc = 'showHeader_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC',
}

export enum ComponentPartnerGridLinkingCollectionsTemplateDestinationsCollectionOrder {
  CollapseHeaderAsc = 'collapseHeader_ASC',
  CollapseHeaderDesc = 'collapseHeader_DESC',
  CollapsibleHeadingAsc = 'collapsibleHeading_ASC',
  CollapsibleHeadingDesc = 'collapsibleHeading_DESC',
  ShowHeaderAsc = 'showHeader_ASC',
  ShowHeaderDesc = 'showHeader_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC',
}

export enum ComponentPartnerGridLinkingCollectionsTemplateForaReserveCollectionOrder {
  CollapseHeaderAsc = 'collapseHeader_ASC',
  CollapseHeaderDesc = 'collapseHeader_DESC',
  ShowHeaderAsc = 'showHeader_ASC',
  ShowHeaderDesc = 'showHeader_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC',
}

export enum ComponentPartnerGridLinkingCollectionsTemplateForaXCollectionOrder {
  AdvisorGridHeaderAsc = 'advisorGridHeader_ASC',
  AdvisorGridHeaderDesc = 'advisorGridHeader_DESC',
  AdvisorGridSubCopyAsc = 'advisorGridSubCopy_ASC',
  AdvisorGridSubCopyDesc = 'advisorGridSubCopy_DESC',
  CollapseHeaderAsc = 'collapseHeader_ASC',
  CollapseHeaderDesc = 'collapseHeader_DESC',
  ShowHeaderAsc = 'showHeader_ASC',
  ShowHeaderDesc = 'showHeader_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC',
}

export enum ComponentPartnerGridLinkingCollectionsTemplateHomeCollectionOrder {
  CollapseHeaderAsc = 'collapseHeader_ASC',
  CollapseHeaderDesc = 'collapseHeader_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC',
}

export enum ComponentPartnerGridLinkingCollectionsTemplateJoinUsCollectionOrder {
  CollapseHeaderAsc = 'collapseHeader_ASC',
  CollapseHeaderDesc = 'collapseHeader_DESC',
  HideGlobalBannerAsc = 'hideGlobalBanner_ASC',
  HideGlobalBannerDesc = 'hideGlobalBanner_DESC',
  ShowHeaderAsc = 'showHeader_ASC',
  ShowHeaderDesc = 'showHeader_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC',
}

export enum ComponentPartnerGridLinkingCollectionsTemplateNewsletterCollectionOrder {
  CollapseHeaderAsc = 'collapseHeader_ASC',
  CollapseHeaderDesc = 'collapseHeader_DESC',
  ShowHeaderAsc = 'showHeader_ASC',
  ShowHeaderDesc = 'showHeader_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC',
}

export enum ComponentPartnerGridLinkingCollectionsTemplatePageCollectionOrder {
  DirectoryAsc = 'directory_ASC',
  DirectoryDesc = 'directory_DESC',
  InternalLabelAsc = 'internalLabel_ASC',
  InternalLabelDesc = 'internalLabel_DESC',
  MetaTitleAsc = 'metaTitle_ASC',
  MetaTitleDesc = 'metaTitle_DESC',
  NoindexNofollowAsc = 'noindexNofollow_ASC',
  NoindexNofollowDesc = 'noindexNofollow_DESC',
  SlugAsc = 'slug_ASC',
  SlugDesc = 'slug_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
}

export enum ComponentPartnerGridLinkingCollectionsTemplatePartnersCollectionOrder {
  CollapseHeaderAsc = 'collapseHeader_ASC',
  CollapseHeaderDesc = 'collapseHeader_DESC',
  ShowHeaderAsc = 'showHeader_ASC',
  ShowHeaderDesc = 'showHeader_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC',
}

export enum ComponentPartnerGridLinkingCollectionsTemplateReviewsCollectionOrder {
  CollapseHeaderAsc = 'collapseHeader_ASC',
  CollapseHeaderDesc = 'collapseHeader_DESC',
  ShowHeaderAsc = 'showHeader_ASC',
  ShowHeaderDesc = 'showHeader_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC',
}

export enum ComponentPartnerGridLinkingCollectionsTemplateWhyBookWithForaCollectionOrder {
  CollapseHeaderAsc = 'collapseHeader_ASC',
  CollapseHeaderDesc = 'collapseHeader_DESC',
  ShowHeaderAsc = 'showHeader_ASC',
  ShowHeaderDesc = 'showHeader_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC',
}

export enum ComponentPartnerGridOrder {
  HeadingSharedStateAsc = 'headingSharedState_ASC',
  HeadingSharedStateDesc = 'headingSharedState_DESC',
  HeadingAsc = 'heading_ASC',
  HeadingDesc = 'heading_DESC',
  InternalLabelAsc = 'internalLabel_ASC',
  InternalLabelDesc = 'internalLabel_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  TitleStyleAsc = 'titleStyle_ASC',
  TitleStyleDesc = 'titleStyle_DESC',
}

export type ComponentPartnerGridRegularCruisesCollection = {
  __typename?: 'ComponentPartnerGridRegularCruisesCollection'
  items: Array<Maybe<EntryPartner>>
  limit: Scalars['Int']
  skip: Scalars['Int']
  total: Scalars['Int']
}

export enum ComponentPartnerGridRegularCruisesCollectionOrder {
  DetailSummaryAsc = 'detailSummary_ASC',
  DetailSummaryDesc = 'detailSummary_DESC',
  FeaturedAsc = 'featured_ASC',
  FeaturedDesc = 'featured_DESC',
  HasDetailPageAsc = 'hasDetailPage_ASC',
  HasDetailPageDesc = 'hasDetailPage_DESC',
  HideFromPartnerGridAsc = 'hideFromPartnerGrid_ASC',
  HideFromPartnerGridDesc = 'hideFromPartnerGrid_DESC',
  NameAsc = 'name_ASC',
  NameDesc = 'name_DESC',
  PartnerCategoryAsc = 'partnerCategory_ASC',
  PartnerCategoryDesc = 'partnerCategory_DESC',
  PartnerTypeAsc = 'partnerType_ASC',
  PartnerTypeDesc = 'partnerType_DESC',
  SlugAsc = 'slug_ASC',
  SlugDesc = 'slug_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
}

export type ComponentPartnerGridRegularExperiencesCollection = {
  __typename?: 'ComponentPartnerGridRegularExperiencesCollection'
  items: Array<Maybe<EntryPartner>>
  limit: Scalars['Int']
  skip: Scalars['Int']
  total: Scalars['Int']
}

export enum ComponentPartnerGridRegularExperiencesCollectionOrder {
  DetailSummaryAsc = 'detailSummary_ASC',
  DetailSummaryDesc = 'detailSummary_DESC',
  FeaturedAsc = 'featured_ASC',
  FeaturedDesc = 'featured_DESC',
  HasDetailPageAsc = 'hasDetailPage_ASC',
  HasDetailPageDesc = 'hasDetailPage_DESC',
  HideFromPartnerGridAsc = 'hideFromPartnerGrid_ASC',
  HideFromPartnerGridDesc = 'hideFromPartnerGrid_DESC',
  NameAsc = 'name_ASC',
  NameDesc = 'name_DESC',
  PartnerCategoryAsc = 'partnerCategory_ASC',
  PartnerCategoryDesc = 'partnerCategory_DESC',
  PartnerTypeAsc = 'partnerType_ASC',
  PartnerTypeDesc = 'partnerType_DESC',
  SlugAsc = 'slug_ASC',
  SlugDesc = 'slug_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
}

export type ComponentPartnerGridRegularHotelsCollection = {
  __typename?: 'ComponentPartnerGridRegularHotelsCollection'
  items: Array<Maybe<EntryPartner>>
  limit: Scalars['Int']
  skip: Scalars['Int']
  total: Scalars['Int']
}

export enum ComponentPartnerGridRegularHotelsCollectionOrder {
  DetailSummaryAsc = 'detailSummary_ASC',
  DetailSummaryDesc = 'detailSummary_DESC',
  FeaturedAsc = 'featured_ASC',
  FeaturedDesc = 'featured_DESC',
  HasDetailPageAsc = 'hasDetailPage_ASC',
  HasDetailPageDesc = 'hasDetailPage_DESC',
  HideFromPartnerGridAsc = 'hideFromPartnerGrid_ASC',
  HideFromPartnerGridDesc = 'hideFromPartnerGrid_DESC',
  NameAsc = 'name_ASC',
  NameDesc = 'name_DESC',
  PartnerCategoryAsc = 'partnerCategory_ASC',
  PartnerCategoryDesc = 'partnerCategory_DESC',
  PartnerTypeAsc = 'partnerType_ASC',
  PartnerTypeDesc = 'partnerType_DESC',
  SlugAsc = 'slug_ASC',
  SlugDesc = 'slug_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
}

export type ComponentPartnerGridSubheading = {
  __typename?: 'ComponentPartnerGridSubheading'
  json: Scalars['JSON']
  links: ComponentPartnerGridSubheadingLinks
}

export type ComponentPartnerGridSubheadingAssets = {
  __typename?: 'ComponentPartnerGridSubheadingAssets'
  block: Array<Maybe<Asset>>
  hyperlink: Array<Maybe<Asset>>
}

export type ComponentPartnerGridSubheadingEntries = {
  __typename?: 'ComponentPartnerGridSubheadingEntries'
  block: Array<Maybe<Entry>>
  hyperlink: Array<Maybe<Entry>>
  inline: Array<Maybe<Entry>>
}

export type ComponentPartnerGridSubheadingLinks = {
  __typename?: 'ComponentPartnerGridSubheadingLinks'
  assets: ComponentPartnerGridSubheadingAssets
  entries: ComponentPartnerGridSubheadingEntries
  resources: ComponentPartnerGridSubheadingResources
}

export type ComponentPartnerGridSubheadingResources = {
  __typename?: 'ComponentPartnerGridSubheadingResources'
  block: Array<ComponentPartnerGridSubheadingResourcesBlock>
  hyperlink: Array<ComponentPartnerGridSubheadingResourcesHyperlink>
  inline: Array<ComponentPartnerGridSubheadingResourcesInline>
}

export type ComponentPartnerGridSubheadingResourcesBlock = ResourceLink & {
  __typename?: 'ComponentPartnerGridSubheadingResourcesBlock'
  sys: ResourceSys
}

export type ComponentPartnerGridSubheadingResourcesHyperlink = ResourceLink & {
  __typename?: 'ComponentPartnerGridSubheadingResourcesHyperlink'
  sys: ResourceSys
}

export type ComponentPartnerGridSubheadingResourcesInline = ResourceLink & {
  __typename?: 'ComponentPartnerGridSubheadingResourcesInline'
  sys: ResourceSys
}

export type ComponentPartnerGridSubheadingSharedState = {
  __typename?: 'ComponentPartnerGridSubheadingSharedState'
  json: Scalars['JSON']
  links: ComponentPartnerGridSubheadingSharedStateLinks
}

export type ComponentPartnerGridSubheadingSharedStateAssets = {
  __typename?: 'ComponentPartnerGridSubheadingSharedStateAssets'
  block: Array<Maybe<Asset>>
  hyperlink: Array<Maybe<Asset>>
}

export type ComponentPartnerGridSubheadingSharedStateEntries = {
  __typename?: 'ComponentPartnerGridSubheadingSharedStateEntries'
  block: Array<Maybe<Entry>>
  hyperlink: Array<Maybe<Entry>>
  inline: Array<Maybe<Entry>>
}

export type ComponentPartnerGridSubheadingSharedStateLinks = {
  __typename?: 'ComponentPartnerGridSubheadingSharedStateLinks'
  assets: ComponentPartnerGridSubheadingSharedStateAssets
  entries: ComponentPartnerGridSubheadingSharedStateEntries
  resources: ComponentPartnerGridSubheadingSharedStateResources
}

export type ComponentPartnerGridSubheadingSharedStateResources = {
  __typename?: 'ComponentPartnerGridSubheadingSharedStateResources'
  block: Array<ComponentPartnerGridSubheadingSharedStateResourcesBlock>
  hyperlink: Array<ComponentPartnerGridSubheadingSharedStateResourcesHyperlink>
  inline: Array<ComponentPartnerGridSubheadingSharedStateResourcesInline>
}

export type ComponentPartnerGridSubheadingSharedStateResourcesBlock =
  ResourceLink & {
    __typename?: 'ComponentPartnerGridSubheadingSharedStateResourcesBlock'
    sys: ResourceSys
  }

export type ComponentPartnerGridSubheadingSharedStateResourcesHyperlink =
  ResourceLink & {
    __typename?: 'ComponentPartnerGridSubheadingSharedStateResourcesHyperlink'
    sys: ResourceSys
  }

export type ComponentPartnerGridSubheadingSharedStateResourcesInline =
  ResourceLink & {
    __typename?: 'ComponentPartnerGridSubheadingSharedStateResourcesInline'
    sys: ResourceSys
  }

/** [See type definition](https://app.contentful.com/spaces/9cxgygtadmvl/content_types/componentPictureSectionBanner) */
export type ComponentPictureSectionBanner = Entry &
  _Node & {
    __typename?: 'ComponentPictureSectionBanner'
    _id: Scalars['ID']
    analyticsModule?: Maybe<SettingsAnalyticsModule>
    cardSize?: Maybe<Scalars['String']>
    contentfulMetadata: ContentfulMetadata
    internalLabel?: Maybe<Scalars['String']>
    linkedFrom?: Maybe<ComponentPictureSectionBannerLinkingCollections>
    mobilePicture?: Maybe<Asset>
    picture?: Maybe<Asset>
    sys: Sys
    title?: Maybe<Scalars['String']>
  }

/** [See type definition](https://app.contentful.com/spaces/9cxgygtadmvl/content_types/componentPictureSectionBanner) */
export type ComponentPictureSectionBannerAnalyticsModuleArgs = {
  locale?: InputMaybe<Scalars['String']>
  preview?: InputMaybe<Scalars['Boolean']>
  where?: InputMaybe<SettingsAnalyticsModuleFilter>
}

/** [See type definition](https://app.contentful.com/spaces/9cxgygtadmvl/content_types/componentPictureSectionBanner) */
export type ComponentPictureSectionBannerCardSizeArgs = {
  locale?: InputMaybe<Scalars['String']>
}

/** [See type definition](https://app.contentful.com/spaces/9cxgygtadmvl/content_types/componentPictureSectionBanner) */
export type ComponentPictureSectionBannerInternalLabelArgs = {
  locale?: InputMaybe<Scalars['String']>
}

/** [See type definition](https://app.contentful.com/spaces/9cxgygtadmvl/content_types/componentPictureSectionBanner) */
export type ComponentPictureSectionBannerLinkedFromArgs = {
  allowedLocales?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
}

/** [See type definition](https://app.contentful.com/spaces/9cxgygtadmvl/content_types/componentPictureSectionBanner) */
export type ComponentPictureSectionBannerMobilePictureArgs = {
  locale?: InputMaybe<Scalars['String']>
  preview?: InputMaybe<Scalars['Boolean']>
}

/** [See type definition](https://app.contentful.com/spaces/9cxgygtadmvl/content_types/componentPictureSectionBanner) */
export type ComponentPictureSectionBannerPictureArgs = {
  locale?: InputMaybe<Scalars['String']>
  preview?: InputMaybe<Scalars['Boolean']>
}

/** [See type definition](https://app.contentful.com/spaces/9cxgygtadmvl/content_types/componentPictureSectionBanner) */
export type ComponentPictureSectionBannerTitleArgs = {
  locale?: InputMaybe<Scalars['String']>
}

export type ComponentPictureSectionBannerCollection = {
  __typename?: 'ComponentPictureSectionBannerCollection'
  items: Array<Maybe<ComponentPictureSectionBanner>>
  limit: Scalars['Int']
  skip: Scalars['Int']
  total: Scalars['Int']
}

export type ComponentPictureSectionBannerFilter = {
  AND?: InputMaybe<Array<InputMaybe<ComponentPictureSectionBannerFilter>>>
  OR?: InputMaybe<Array<InputMaybe<ComponentPictureSectionBannerFilter>>>
  analyticsModule?: InputMaybe<CfSettingsAnalyticsModuleNestedFilter>
  analyticsModule_exists?: InputMaybe<Scalars['Boolean']>
  cardSize?: InputMaybe<Scalars['String']>
  cardSize_contains?: InputMaybe<Scalars['String']>
  cardSize_exists?: InputMaybe<Scalars['Boolean']>
  cardSize_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  cardSize_not?: InputMaybe<Scalars['String']>
  cardSize_not_contains?: InputMaybe<Scalars['String']>
  cardSize_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  contentfulMetadata?: InputMaybe<ContentfulMetadataFilter>
  internalLabel?: InputMaybe<Scalars['String']>
  internalLabel_contains?: InputMaybe<Scalars['String']>
  internalLabel_exists?: InputMaybe<Scalars['Boolean']>
  internalLabel_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  internalLabel_not?: InputMaybe<Scalars['String']>
  internalLabel_not_contains?: InputMaybe<Scalars['String']>
  internalLabel_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  mobilePicture_exists?: InputMaybe<Scalars['Boolean']>
  picture_exists?: InputMaybe<Scalars['Boolean']>
  sys?: InputMaybe<SysFilter>
  title?: InputMaybe<Scalars['String']>
  title_contains?: InputMaybe<Scalars['String']>
  title_exists?: InputMaybe<Scalars['Boolean']>
  title_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  title_not?: InputMaybe<Scalars['String']>
  title_not_contains?: InputMaybe<Scalars['String']>
  title_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
}

export type ComponentPictureSectionBannerLinkingCollections = {
  __typename?: 'ComponentPictureSectionBannerLinkingCollections'
  campaignPagesCollection?: Maybe<CampaignPagesCollection>
  entryCollection?: Maybe<EntryCollection>
  templateAboutUsCollection?: Maybe<TemplateAboutUsCollection>
  templateBookingCollection?: Maybe<TemplateBookingCollection>
  templateCareersCollection?: Maybe<TemplateCareersCollection>
  templateContactUsCollection?: Maybe<TemplateContactUsCollection>
  templateDestinationsCollection?: Maybe<TemplateDestinationsCollection>
  templateForaReserveCollection?: Maybe<TemplateForaReserveCollection>
  templateForaXCollection?: Maybe<TemplateForaXCollection>
  templateHomeCollection?: Maybe<TemplateHomeCollection>
  templateJoinUsCollection?: Maybe<TemplateJoinUsCollection>
  templateNewsletterCollection?: Maybe<TemplateNewsletterCollection>
  templatePageCollection?: Maybe<TemplatePageCollection>
  templatePartnersCollection?: Maybe<TemplatePartnersCollection>
  templateReviewsCollection?: Maybe<TemplateReviewsCollection>
  templateWhyBookWithForaCollection?: Maybe<TemplateWhyBookWithForaCollection>
}

export type ComponentPictureSectionBannerLinkingCollectionsCampaignPagesCollectionArgs =
  {
    limit?: InputMaybe<Scalars['Int']>
    locale?: InputMaybe<Scalars['String']>
    order?: InputMaybe<
      Array<
        InputMaybe<ComponentPictureSectionBannerLinkingCollectionsCampaignPagesCollectionOrder>
      >
    >
    preview?: InputMaybe<Scalars['Boolean']>
    skip?: InputMaybe<Scalars['Int']>
  }

export type ComponentPictureSectionBannerLinkingCollectionsEntryCollectionArgs =
  {
    limit?: InputMaybe<Scalars['Int']>
    locale?: InputMaybe<Scalars['String']>
    preview?: InputMaybe<Scalars['Boolean']>
    skip?: InputMaybe<Scalars['Int']>
  }

export type ComponentPictureSectionBannerLinkingCollectionsTemplateAboutUsCollectionArgs =
  {
    limit?: InputMaybe<Scalars['Int']>
    locale?: InputMaybe<Scalars['String']>
    order?: InputMaybe<
      Array<
        InputMaybe<ComponentPictureSectionBannerLinkingCollectionsTemplateAboutUsCollectionOrder>
      >
    >
    preview?: InputMaybe<Scalars['Boolean']>
    skip?: InputMaybe<Scalars['Int']>
  }

export type ComponentPictureSectionBannerLinkingCollectionsTemplateBookingCollectionArgs =
  {
    limit?: InputMaybe<Scalars['Int']>
    locale?: InputMaybe<Scalars['String']>
    order?: InputMaybe<
      Array<
        InputMaybe<ComponentPictureSectionBannerLinkingCollectionsTemplateBookingCollectionOrder>
      >
    >
    preview?: InputMaybe<Scalars['Boolean']>
    skip?: InputMaybe<Scalars['Int']>
  }

export type ComponentPictureSectionBannerLinkingCollectionsTemplateCareersCollectionArgs =
  {
    limit?: InputMaybe<Scalars['Int']>
    locale?: InputMaybe<Scalars['String']>
    order?: InputMaybe<
      Array<
        InputMaybe<ComponentPictureSectionBannerLinkingCollectionsTemplateCareersCollectionOrder>
      >
    >
    preview?: InputMaybe<Scalars['Boolean']>
    skip?: InputMaybe<Scalars['Int']>
  }

export type ComponentPictureSectionBannerLinkingCollectionsTemplateContactUsCollectionArgs =
  {
    limit?: InputMaybe<Scalars['Int']>
    locale?: InputMaybe<Scalars['String']>
    order?: InputMaybe<
      Array<
        InputMaybe<ComponentPictureSectionBannerLinkingCollectionsTemplateContactUsCollectionOrder>
      >
    >
    preview?: InputMaybe<Scalars['Boolean']>
    skip?: InputMaybe<Scalars['Int']>
  }

export type ComponentPictureSectionBannerLinkingCollectionsTemplateDestinationsCollectionArgs =
  {
    limit?: InputMaybe<Scalars['Int']>
    locale?: InputMaybe<Scalars['String']>
    order?: InputMaybe<
      Array<
        InputMaybe<ComponentPictureSectionBannerLinkingCollectionsTemplateDestinationsCollectionOrder>
      >
    >
    preview?: InputMaybe<Scalars['Boolean']>
    skip?: InputMaybe<Scalars['Int']>
  }

export type ComponentPictureSectionBannerLinkingCollectionsTemplateForaReserveCollectionArgs =
  {
    limit?: InputMaybe<Scalars['Int']>
    locale?: InputMaybe<Scalars['String']>
    order?: InputMaybe<
      Array<
        InputMaybe<ComponentPictureSectionBannerLinkingCollectionsTemplateForaReserveCollectionOrder>
      >
    >
    preview?: InputMaybe<Scalars['Boolean']>
    skip?: InputMaybe<Scalars['Int']>
  }

export type ComponentPictureSectionBannerLinkingCollectionsTemplateForaXCollectionArgs =
  {
    limit?: InputMaybe<Scalars['Int']>
    locale?: InputMaybe<Scalars['String']>
    order?: InputMaybe<
      Array<
        InputMaybe<ComponentPictureSectionBannerLinkingCollectionsTemplateForaXCollectionOrder>
      >
    >
    preview?: InputMaybe<Scalars['Boolean']>
    skip?: InputMaybe<Scalars['Int']>
  }

export type ComponentPictureSectionBannerLinkingCollectionsTemplateHomeCollectionArgs =
  {
    limit?: InputMaybe<Scalars['Int']>
    locale?: InputMaybe<Scalars['String']>
    order?: InputMaybe<
      Array<
        InputMaybe<ComponentPictureSectionBannerLinkingCollectionsTemplateHomeCollectionOrder>
      >
    >
    preview?: InputMaybe<Scalars['Boolean']>
    skip?: InputMaybe<Scalars['Int']>
  }

export type ComponentPictureSectionBannerLinkingCollectionsTemplateJoinUsCollectionArgs =
  {
    limit?: InputMaybe<Scalars['Int']>
    locale?: InputMaybe<Scalars['String']>
    order?: InputMaybe<
      Array<
        InputMaybe<ComponentPictureSectionBannerLinkingCollectionsTemplateJoinUsCollectionOrder>
      >
    >
    preview?: InputMaybe<Scalars['Boolean']>
    skip?: InputMaybe<Scalars['Int']>
  }

export type ComponentPictureSectionBannerLinkingCollectionsTemplateNewsletterCollectionArgs =
  {
    limit?: InputMaybe<Scalars['Int']>
    locale?: InputMaybe<Scalars['String']>
    order?: InputMaybe<
      Array<
        InputMaybe<ComponentPictureSectionBannerLinkingCollectionsTemplateNewsletterCollectionOrder>
      >
    >
    preview?: InputMaybe<Scalars['Boolean']>
    skip?: InputMaybe<Scalars['Int']>
  }

export type ComponentPictureSectionBannerLinkingCollectionsTemplatePageCollectionArgs =
  {
    limit?: InputMaybe<Scalars['Int']>
    locale?: InputMaybe<Scalars['String']>
    order?: InputMaybe<
      Array<
        InputMaybe<ComponentPictureSectionBannerLinkingCollectionsTemplatePageCollectionOrder>
      >
    >
    preview?: InputMaybe<Scalars['Boolean']>
    skip?: InputMaybe<Scalars['Int']>
  }

export type ComponentPictureSectionBannerLinkingCollectionsTemplatePartnersCollectionArgs =
  {
    limit?: InputMaybe<Scalars['Int']>
    locale?: InputMaybe<Scalars['String']>
    order?: InputMaybe<
      Array<
        InputMaybe<ComponentPictureSectionBannerLinkingCollectionsTemplatePartnersCollectionOrder>
      >
    >
    preview?: InputMaybe<Scalars['Boolean']>
    skip?: InputMaybe<Scalars['Int']>
  }

export type ComponentPictureSectionBannerLinkingCollectionsTemplateReviewsCollectionArgs =
  {
    limit?: InputMaybe<Scalars['Int']>
    locale?: InputMaybe<Scalars['String']>
    order?: InputMaybe<
      Array<
        InputMaybe<ComponentPictureSectionBannerLinkingCollectionsTemplateReviewsCollectionOrder>
      >
    >
    preview?: InputMaybe<Scalars['Boolean']>
    skip?: InputMaybe<Scalars['Int']>
  }

export type ComponentPictureSectionBannerLinkingCollectionsTemplateWhyBookWithForaCollectionArgs =
  {
    limit?: InputMaybe<Scalars['Int']>
    locale?: InputMaybe<Scalars['String']>
    order?: InputMaybe<
      Array<
        InputMaybe<ComponentPictureSectionBannerLinkingCollectionsTemplateWhyBookWithForaCollectionOrder>
      >
    >
    preview?: InputMaybe<Scalars['Boolean']>
    skip?: InputMaybe<Scalars['Int']>
  }

export enum ComponentPictureSectionBannerLinkingCollectionsCampaignPagesCollectionOrder {
  CollapseFooterAsc = 'collapseFooter_ASC',
  CollapseFooterDesc = 'collapseFooter_DESC',
  CollapseHeaderAsc = 'collapseHeader_ASC',
  CollapseHeaderDesc = 'collapseHeader_DESC',
  HideFooterAsc = 'hideFooter_ASC',
  HideFooterDesc = 'hideFooter_DESC',
  HideGlobalBannerAsc = 'hideGlobalBanner_ASC',
  HideGlobalBannerDesc = 'hideGlobalBanner_DESC',
  JotformIdAsc = 'jotformId_ASC',
  JotformIdDesc = 'jotformId_DESC',
  NoindexNofollowAsc = 'noindexNofollow_ASC',
  NoindexNofollowDesc = 'noindexNofollow_DESC',
  ShowHeaderAsc = 'showHeader_ASC',
  ShowHeaderDesc = 'showHeader_DESC',
  SlugAsc = 'slug_ASC',
  SlugDesc = 'slug_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC',
}

export enum ComponentPictureSectionBannerLinkingCollectionsTemplateAboutUsCollectionOrder {
  CollapseHeaderAsc = 'collapseHeader_ASC',
  CollapseHeaderDesc = 'collapseHeader_DESC',
  HeaderBlockTitleAsc = 'headerBlockTitle_ASC',
  HeaderBlockTitleDesc = 'headerBlockTitle_DESC',
  ShowHeaderAsc = 'showHeader_ASC',
  ShowHeaderDesc = 'showHeader_DESC',
  SubtitleAsc = 'subtitle_ASC',
  SubtitleDesc = 'subtitle_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC',
}

export enum ComponentPictureSectionBannerLinkingCollectionsTemplateBookingCollectionOrder {
  CollapseHeaderAsc = 'collapseHeader_ASC',
  CollapseHeaderDesc = 'collapseHeader_DESC',
  ShowHeaderAsc = 'showHeader_ASC',
  ShowHeaderDesc = 'showHeader_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC',
}

export enum ComponentPictureSectionBannerLinkingCollectionsTemplateCareersCollectionOrder {
  CollapseHeaderAsc = 'collapseHeader_ASC',
  CollapseHeaderDesc = 'collapseHeader_DESC',
  ShowHeaderAsc = 'showHeader_ASC',
  ShowHeaderDesc = 'showHeader_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC',
}

export enum ComponentPictureSectionBannerLinkingCollectionsTemplateContactUsCollectionOrder {
  CollapseHeaderAsc = 'collapseHeader_ASC',
  CollapseHeaderDesc = 'collapseHeader_DESC',
  ShowHeaderAsc = 'showHeader_ASC',
  ShowHeaderDesc = 'showHeader_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC',
}

export enum ComponentPictureSectionBannerLinkingCollectionsTemplateDestinationsCollectionOrder {
  CollapseHeaderAsc = 'collapseHeader_ASC',
  CollapseHeaderDesc = 'collapseHeader_DESC',
  CollapsibleHeadingAsc = 'collapsibleHeading_ASC',
  CollapsibleHeadingDesc = 'collapsibleHeading_DESC',
  ShowHeaderAsc = 'showHeader_ASC',
  ShowHeaderDesc = 'showHeader_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC',
}

export enum ComponentPictureSectionBannerLinkingCollectionsTemplateForaReserveCollectionOrder {
  CollapseHeaderAsc = 'collapseHeader_ASC',
  CollapseHeaderDesc = 'collapseHeader_DESC',
  ShowHeaderAsc = 'showHeader_ASC',
  ShowHeaderDesc = 'showHeader_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC',
}

export enum ComponentPictureSectionBannerLinkingCollectionsTemplateForaXCollectionOrder {
  AdvisorGridHeaderAsc = 'advisorGridHeader_ASC',
  AdvisorGridHeaderDesc = 'advisorGridHeader_DESC',
  AdvisorGridSubCopyAsc = 'advisorGridSubCopy_ASC',
  AdvisorGridSubCopyDesc = 'advisorGridSubCopy_DESC',
  CollapseHeaderAsc = 'collapseHeader_ASC',
  CollapseHeaderDesc = 'collapseHeader_DESC',
  ShowHeaderAsc = 'showHeader_ASC',
  ShowHeaderDesc = 'showHeader_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC',
}

export enum ComponentPictureSectionBannerLinkingCollectionsTemplateHomeCollectionOrder {
  CollapseHeaderAsc = 'collapseHeader_ASC',
  CollapseHeaderDesc = 'collapseHeader_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC',
}

export enum ComponentPictureSectionBannerLinkingCollectionsTemplateJoinUsCollectionOrder {
  CollapseHeaderAsc = 'collapseHeader_ASC',
  CollapseHeaderDesc = 'collapseHeader_DESC',
  HideGlobalBannerAsc = 'hideGlobalBanner_ASC',
  HideGlobalBannerDesc = 'hideGlobalBanner_DESC',
  ShowHeaderAsc = 'showHeader_ASC',
  ShowHeaderDesc = 'showHeader_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC',
}

export enum ComponentPictureSectionBannerLinkingCollectionsTemplateNewsletterCollectionOrder {
  CollapseHeaderAsc = 'collapseHeader_ASC',
  CollapseHeaderDesc = 'collapseHeader_DESC',
  ShowHeaderAsc = 'showHeader_ASC',
  ShowHeaderDesc = 'showHeader_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC',
}

export enum ComponentPictureSectionBannerLinkingCollectionsTemplatePageCollectionOrder {
  DirectoryAsc = 'directory_ASC',
  DirectoryDesc = 'directory_DESC',
  InternalLabelAsc = 'internalLabel_ASC',
  InternalLabelDesc = 'internalLabel_DESC',
  MetaTitleAsc = 'metaTitle_ASC',
  MetaTitleDesc = 'metaTitle_DESC',
  NoindexNofollowAsc = 'noindexNofollow_ASC',
  NoindexNofollowDesc = 'noindexNofollow_DESC',
  SlugAsc = 'slug_ASC',
  SlugDesc = 'slug_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
}

export enum ComponentPictureSectionBannerLinkingCollectionsTemplatePartnersCollectionOrder {
  CollapseHeaderAsc = 'collapseHeader_ASC',
  CollapseHeaderDesc = 'collapseHeader_DESC',
  ShowHeaderAsc = 'showHeader_ASC',
  ShowHeaderDesc = 'showHeader_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC',
}

export enum ComponentPictureSectionBannerLinkingCollectionsTemplateReviewsCollectionOrder {
  CollapseHeaderAsc = 'collapseHeader_ASC',
  CollapseHeaderDesc = 'collapseHeader_DESC',
  ShowHeaderAsc = 'showHeader_ASC',
  ShowHeaderDesc = 'showHeader_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC',
}

export enum ComponentPictureSectionBannerLinkingCollectionsTemplateWhyBookWithForaCollectionOrder {
  CollapseHeaderAsc = 'collapseHeader_ASC',
  CollapseHeaderDesc = 'collapseHeader_DESC',
  ShowHeaderAsc = 'showHeader_ASC',
  ShowHeaderDesc = 'showHeader_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC',
}

export enum ComponentPictureSectionBannerOrder {
  CardSizeAsc = 'cardSize_ASC',
  CardSizeDesc = 'cardSize_DESC',
  InternalLabelAsc = 'internalLabel_ASC',
  InternalLabelDesc = 'internalLabel_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC',
}

/** [See type definition](https://app.contentful.com/spaces/9cxgygtadmvl/content_types/componentReviewCarousel) */
export type ComponentReviewCarousel = Entry &
  _Node & {
    __typename?: 'ComponentReviewCarousel'
    _id: Scalars['ID']
    analyticsModule?: Maybe<SettingsAnalyticsModule>
    contentfulMetadata: ContentfulMetadata
    heading?: Maybe<Scalars['String']>
    internalLabel?: Maybe<Scalars['String']>
    linkedFrom?: Maybe<ComponentReviewCarouselLinkingCollections>
    sectionId?: Maybe<Scalars['String']>
    sys: Sys
    testimonialsCollection?: Maybe<ComponentReviewCarouselTestimonialsCollection>
    titleStyle?: Maybe<Scalars['String']>
  }

/** [See type definition](https://app.contentful.com/spaces/9cxgygtadmvl/content_types/componentReviewCarousel) */
export type ComponentReviewCarouselAnalyticsModuleArgs = {
  locale?: InputMaybe<Scalars['String']>
  preview?: InputMaybe<Scalars['Boolean']>
  where?: InputMaybe<SettingsAnalyticsModuleFilter>
}

/** [See type definition](https://app.contentful.com/spaces/9cxgygtadmvl/content_types/componentReviewCarousel) */
export type ComponentReviewCarouselHeadingArgs = {
  locale?: InputMaybe<Scalars['String']>
}

/** [See type definition](https://app.contentful.com/spaces/9cxgygtadmvl/content_types/componentReviewCarousel) */
export type ComponentReviewCarouselInternalLabelArgs = {
  locale?: InputMaybe<Scalars['String']>
}

/** [See type definition](https://app.contentful.com/spaces/9cxgygtadmvl/content_types/componentReviewCarousel) */
export type ComponentReviewCarouselLinkedFromArgs = {
  allowedLocales?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
}

/** [See type definition](https://app.contentful.com/spaces/9cxgygtadmvl/content_types/componentReviewCarousel) */
export type ComponentReviewCarouselSectionIdArgs = {
  locale?: InputMaybe<Scalars['String']>
}

/** [See type definition](https://app.contentful.com/spaces/9cxgygtadmvl/content_types/componentReviewCarousel) */
export type ComponentReviewCarouselTestimonialsCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>
  locale?: InputMaybe<Scalars['String']>
  order?: InputMaybe<
    Array<InputMaybe<ComponentReviewCarouselTestimonialsCollectionOrder>>
  >
  preview?: InputMaybe<Scalars['Boolean']>
  skip?: InputMaybe<Scalars['Int']>
  where?: InputMaybe<EntryTestimonialFilter>
}

/** [See type definition](https://app.contentful.com/spaces/9cxgygtadmvl/content_types/componentReviewCarousel) */
export type ComponentReviewCarouselTitleStyleArgs = {
  locale?: InputMaybe<Scalars['String']>
}

export type ComponentReviewCarouselCollection = {
  __typename?: 'ComponentReviewCarouselCollection'
  items: Array<Maybe<ComponentReviewCarousel>>
  limit: Scalars['Int']
  skip: Scalars['Int']
  total: Scalars['Int']
}

export type ComponentReviewCarouselFilter = {
  AND?: InputMaybe<Array<InputMaybe<ComponentReviewCarouselFilter>>>
  OR?: InputMaybe<Array<InputMaybe<ComponentReviewCarouselFilter>>>
  analyticsModule?: InputMaybe<CfSettingsAnalyticsModuleNestedFilter>
  analyticsModule_exists?: InputMaybe<Scalars['Boolean']>
  contentfulMetadata?: InputMaybe<ContentfulMetadataFilter>
  heading?: InputMaybe<Scalars['String']>
  heading_contains?: InputMaybe<Scalars['String']>
  heading_exists?: InputMaybe<Scalars['Boolean']>
  heading_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  heading_not?: InputMaybe<Scalars['String']>
  heading_not_contains?: InputMaybe<Scalars['String']>
  heading_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  internalLabel?: InputMaybe<Scalars['String']>
  internalLabel_contains?: InputMaybe<Scalars['String']>
  internalLabel_exists?: InputMaybe<Scalars['Boolean']>
  internalLabel_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  internalLabel_not?: InputMaybe<Scalars['String']>
  internalLabel_not_contains?: InputMaybe<Scalars['String']>
  internalLabel_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  sectionId?: InputMaybe<Scalars['String']>
  sectionId_contains?: InputMaybe<Scalars['String']>
  sectionId_exists?: InputMaybe<Scalars['Boolean']>
  sectionId_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  sectionId_not?: InputMaybe<Scalars['String']>
  sectionId_not_contains?: InputMaybe<Scalars['String']>
  sectionId_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  sys?: InputMaybe<SysFilter>
  testimonials?: InputMaybe<CfEntryTestimonialNestedFilter>
  testimonialsCollection_exists?: InputMaybe<Scalars['Boolean']>
  titleStyle?: InputMaybe<Scalars['String']>
  titleStyle_contains?: InputMaybe<Scalars['String']>
  titleStyle_exists?: InputMaybe<Scalars['Boolean']>
  titleStyle_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  titleStyle_not?: InputMaybe<Scalars['String']>
  titleStyle_not_contains?: InputMaybe<Scalars['String']>
  titleStyle_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
}

export type ComponentReviewCarouselLinkingCollections = {
  __typename?: 'ComponentReviewCarouselLinkingCollections'
  entryCollection?: Maybe<EntryCollection>
}

export type ComponentReviewCarouselLinkingCollectionsEntryCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>
  locale?: InputMaybe<Scalars['String']>
  preview?: InputMaybe<Scalars['Boolean']>
  skip?: InputMaybe<Scalars['Int']>
}

export enum ComponentReviewCarouselOrder {
  HeadingAsc = 'heading_ASC',
  HeadingDesc = 'heading_DESC',
  InternalLabelAsc = 'internalLabel_ASC',
  InternalLabelDesc = 'internalLabel_DESC',
  SectionIdAsc = 'sectionId_ASC',
  SectionIdDesc = 'sectionId_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  TitleStyleAsc = 'titleStyle_ASC',
  TitleStyleDesc = 'titleStyle_DESC',
}

export type ComponentReviewCarouselTestimonialsCollection = {
  __typename?: 'ComponentReviewCarouselTestimonialsCollection'
  items: Array<Maybe<EntryTestimonial>>
  limit: Scalars['Int']
  skip: Scalars['Int']
  total: Scalars['Int']
}

export enum ComponentReviewCarouselTestimonialsCollectionOrder {
  AboutForaAsc = 'aboutFora_ASC',
  AboutForaDesc = 'aboutFora_DESC',
  BeginTravelDateAsc = 'beginTravelDate_ASC',
  BeginTravelDateDesc = 'beginTravelDate_DESC',
  DateSubmittedAsc = 'dateSubmitted_ASC',
  DateSubmittedDesc = 'dateSubmitted_DESC',
  DisplayAsc = 'display_ASC',
  DisplayDesc = 'display_DESC',
  EndTravelDateAsc = 'endTravelDate_ASC',
  EndTravelDateDesc = 'endTravelDate_DESC',
  InternalLabelAsc = 'internalLabel_ASC',
  InternalLabelDesc = 'internalLabel_DESC',
  IsFeaturedAsc = 'isFeatured_ASC',
  IsFeaturedDesc = 'isFeatured_DESC',
  StarsAsc = 'stars_ASC',
  StarsDesc = 'stars_DESC',
  SubmitterEmailAsc = 'submitterEmail_ASC',
  SubmitterEmailDesc = 'submitterEmail_DESC',
  SubmitterFirstNameAsc = 'submitterFirstName_ASC',
  SubmitterFirstNameDesc = 'submitterFirstName_DESC',
  SubmitterLastNameAsc = 'submitterLastName_ASC',
  SubmitterLastNameDesc = 'submitterLastName_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  TestimonialSourceAsc = 'testimonialSource_ASC',
  TestimonialSourceDesc = 'testimonialSource_DESC',
  TitleOfReviewAsc = 'titleOfReview_ASC',
  TitleOfReviewDesc = 'titleOfReview_DESC',
}

/** Displays testimonial entries that have been approved for public display. Also displays filter and sort controls [See type definition](https://app.contentful.com/spaces/9cxgygtadmvl/content_types/componentReviews) */
export type ComponentReviews = Entry &
  _Node & {
    __typename?: 'ComponentReviews'
    _id: Scalars['ID']
    contentfulMetadata: ContentfulMetadata
    ctaText?: Maybe<Scalars['String']>
    ctaUrl?: Maybe<Scalars['String']>
    internalLabel?: Maybe<Scalars['String']>
    linkedFrom?: Maybe<ComponentReviewsLinkingCollections>
    sectionId?: Maybe<Scalars['String']>
    sys: Sys
  }

/** Displays testimonial entries that have been approved for public display. Also displays filter and sort controls [See type definition](https://app.contentful.com/spaces/9cxgygtadmvl/content_types/componentReviews) */
export type ComponentReviewsCtaTextArgs = {
  locale?: InputMaybe<Scalars['String']>
}

/** Displays testimonial entries that have been approved for public display. Also displays filter and sort controls [See type definition](https://app.contentful.com/spaces/9cxgygtadmvl/content_types/componentReviews) */
export type ComponentReviewsCtaUrlArgs = {
  locale?: InputMaybe<Scalars['String']>
}

/** Displays testimonial entries that have been approved for public display. Also displays filter and sort controls [See type definition](https://app.contentful.com/spaces/9cxgygtadmvl/content_types/componentReviews) */
export type ComponentReviewsInternalLabelArgs = {
  locale?: InputMaybe<Scalars['String']>
}

/** Displays testimonial entries that have been approved for public display. Also displays filter and sort controls [See type definition](https://app.contentful.com/spaces/9cxgygtadmvl/content_types/componentReviews) */
export type ComponentReviewsLinkedFromArgs = {
  allowedLocales?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
}

/** Displays testimonial entries that have been approved for public display. Also displays filter and sort controls [See type definition](https://app.contentful.com/spaces/9cxgygtadmvl/content_types/componentReviews) */
export type ComponentReviewsSectionIdArgs = {
  locale?: InputMaybe<Scalars['String']>
}

export type ComponentReviewsCollection = {
  __typename?: 'ComponentReviewsCollection'
  items: Array<Maybe<ComponentReviews>>
  limit: Scalars['Int']
  skip: Scalars['Int']
  total: Scalars['Int']
}

export type ComponentReviewsFilter = {
  AND?: InputMaybe<Array<InputMaybe<ComponentReviewsFilter>>>
  OR?: InputMaybe<Array<InputMaybe<ComponentReviewsFilter>>>
  contentfulMetadata?: InputMaybe<ContentfulMetadataFilter>
  ctaText?: InputMaybe<Scalars['String']>
  ctaText_contains?: InputMaybe<Scalars['String']>
  ctaText_exists?: InputMaybe<Scalars['Boolean']>
  ctaText_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  ctaText_not?: InputMaybe<Scalars['String']>
  ctaText_not_contains?: InputMaybe<Scalars['String']>
  ctaText_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  ctaUrl?: InputMaybe<Scalars['String']>
  ctaUrl_contains?: InputMaybe<Scalars['String']>
  ctaUrl_exists?: InputMaybe<Scalars['Boolean']>
  ctaUrl_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  ctaUrl_not?: InputMaybe<Scalars['String']>
  ctaUrl_not_contains?: InputMaybe<Scalars['String']>
  ctaUrl_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  internalLabel?: InputMaybe<Scalars['String']>
  internalLabel_contains?: InputMaybe<Scalars['String']>
  internalLabel_exists?: InputMaybe<Scalars['Boolean']>
  internalLabel_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  internalLabel_not?: InputMaybe<Scalars['String']>
  internalLabel_not_contains?: InputMaybe<Scalars['String']>
  internalLabel_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  sectionId?: InputMaybe<Scalars['String']>
  sectionId_contains?: InputMaybe<Scalars['String']>
  sectionId_exists?: InputMaybe<Scalars['Boolean']>
  sectionId_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  sectionId_not?: InputMaybe<Scalars['String']>
  sectionId_not_contains?: InputMaybe<Scalars['String']>
  sectionId_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  sys?: InputMaybe<SysFilter>
}

export type ComponentReviewsLinkingCollections = {
  __typename?: 'ComponentReviewsLinkingCollections'
  campaignPagesCollection?: Maybe<CampaignPagesCollection>
  entryCollection?: Maybe<EntryCollection>
  templateAboutUsCollection?: Maybe<TemplateAboutUsCollection>
  templateBookingCollection?: Maybe<TemplateBookingCollection>
  templateCareersCollection?: Maybe<TemplateCareersCollection>
  templateContactUsCollection?: Maybe<TemplateContactUsCollection>
  templateDestinationsCollection?: Maybe<TemplateDestinationsCollection>
  templateForaReserveCollection?: Maybe<TemplateForaReserveCollection>
  templateForaXCollection?: Maybe<TemplateForaXCollection>
  templateHomeCollection?: Maybe<TemplateHomeCollection>
  templateJoinUsCollection?: Maybe<TemplateJoinUsCollection>
  templateNewsletterCollection?: Maybe<TemplateNewsletterCollection>
  templatePartnersCollection?: Maybe<TemplatePartnersCollection>
  templateReviewsCollection?: Maybe<TemplateReviewsCollection>
  templateWhyBookWithForaCollection?: Maybe<TemplateWhyBookWithForaCollection>
}

export type ComponentReviewsLinkingCollectionsCampaignPagesCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>
  locale?: InputMaybe<Scalars['String']>
  order?: InputMaybe<
    Array<
      InputMaybe<ComponentReviewsLinkingCollectionsCampaignPagesCollectionOrder>
    >
  >
  preview?: InputMaybe<Scalars['Boolean']>
  skip?: InputMaybe<Scalars['Int']>
}

export type ComponentReviewsLinkingCollectionsEntryCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>
  locale?: InputMaybe<Scalars['String']>
  preview?: InputMaybe<Scalars['Boolean']>
  skip?: InputMaybe<Scalars['Int']>
}

export type ComponentReviewsLinkingCollectionsTemplateAboutUsCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>
  locale?: InputMaybe<Scalars['String']>
  order?: InputMaybe<
    Array<
      InputMaybe<ComponentReviewsLinkingCollectionsTemplateAboutUsCollectionOrder>
    >
  >
  preview?: InputMaybe<Scalars['Boolean']>
  skip?: InputMaybe<Scalars['Int']>
}

export type ComponentReviewsLinkingCollectionsTemplateBookingCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>
  locale?: InputMaybe<Scalars['String']>
  order?: InputMaybe<
    Array<
      InputMaybe<ComponentReviewsLinkingCollectionsTemplateBookingCollectionOrder>
    >
  >
  preview?: InputMaybe<Scalars['Boolean']>
  skip?: InputMaybe<Scalars['Int']>
}

export type ComponentReviewsLinkingCollectionsTemplateCareersCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>
  locale?: InputMaybe<Scalars['String']>
  order?: InputMaybe<
    Array<
      InputMaybe<ComponentReviewsLinkingCollectionsTemplateCareersCollectionOrder>
    >
  >
  preview?: InputMaybe<Scalars['Boolean']>
  skip?: InputMaybe<Scalars['Int']>
}

export type ComponentReviewsLinkingCollectionsTemplateContactUsCollectionArgs =
  {
    limit?: InputMaybe<Scalars['Int']>
    locale?: InputMaybe<Scalars['String']>
    order?: InputMaybe<
      Array<
        InputMaybe<ComponentReviewsLinkingCollectionsTemplateContactUsCollectionOrder>
      >
    >
    preview?: InputMaybe<Scalars['Boolean']>
    skip?: InputMaybe<Scalars['Int']>
  }

export type ComponentReviewsLinkingCollectionsTemplateDestinationsCollectionArgs =
  {
    limit?: InputMaybe<Scalars['Int']>
    locale?: InputMaybe<Scalars['String']>
    order?: InputMaybe<
      Array<
        InputMaybe<ComponentReviewsLinkingCollectionsTemplateDestinationsCollectionOrder>
      >
    >
    preview?: InputMaybe<Scalars['Boolean']>
    skip?: InputMaybe<Scalars['Int']>
  }

export type ComponentReviewsLinkingCollectionsTemplateForaReserveCollectionArgs =
  {
    limit?: InputMaybe<Scalars['Int']>
    locale?: InputMaybe<Scalars['String']>
    order?: InputMaybe<
      Array<
        InputMaybe<ComponentReviewsLinkingCollectionsTemplateForaReserveCollectionOrder>
      >
    >
    preview?: InputMaybe<Scalars['Boolean']>
    skip?: InputMaybe<Scalars['Int']>
  }

export type ComponentReviewsLinkingCollectionsTemplateForaXCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>
  locale?: InputMaybe<Scalars['String']>
  order?: InputMaybe<
    Array<
      InputMaybe<ComponentReviewsLinkingCollectionsTemplateForaXCollectionOrder>
    >
  >
  preview?: InputMaybe<Scalars['Boolean']>
  skip?: InputMaybe<Scalars['Int']>
}

export type ComponentReviewsLinkingCollectionsTemplateHomeCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>
  locale?: InputMaybe<Scalars['String']>
  order?: InputMaybe<
    Array<
      InputMaybe<ComponentReviewsLinkingCollectionsTemplateHomeCollectionOrder>
    >
  >
  preview?: InputMaybe<Scalars['Boolean']>
  skip?: InputMaybe<Scalars['Int']>
}

export type ComponentReviewsLinkingCollectionsTemplateJoinUsCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>
  locale?: InputMaybe<Scalars['String']>
  order?: InputMaybe<
    Array<
      InputMaybe<ComponentReviewsLinkingCollectionsTemplateJoinUsCollectionOrder>
    >
  >
  preview?: InputMaybe<Scalars['Boolean']>
  skip?: InputMaybe<Scalars['Int']>
}

export type ComponentReviewsLinkingCollectionsTemplateNewsletterCollectionArgs =
  {
    limit?: InputMaybe<Scalars['Int']>
    locale?: InputMaybe<Scalars['String']>
    order?: InputMaybe<
      Array<
        InputMaybe<ComponentReviewsLinkingCollectionsTemplateNewsletterCollectionOrder>
      >
    >
    preview?: InputMaybe<Scalars['Boolean']>
    skip?: InputMaybe<Scalars['Int']>
  }

export type ComponentReviewsLinkingCollectionsTemplatePartnersCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>
  locale?: InputMaybe<Scalars['String']>
  order?: InputMaybe<
    Array<
      InputMaybe<ComponentReviewsLinkingCollectionsTemplatePartnersCollectionOrder>
    >
  >
  preview?: InputMaybe<Scalars['Boolean']>
  skip?: InputMaybe<Scalars['Int']>
}

export type ComponentReviewsLinkingCollectionsTemplateReviewsCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>
  locale?: InputMaybe<Scalars['String']>
  order?: InputMaybe<
    Array<
      InputMaybe<ComponentReviewsLinkingCollectionsTemplateReviewsCollectionOrder>
    >
  >
  preview?: InputMaybe<Scalars['Boolean']>
  skip?: InputMaybe<Scalars['Int']>
}

export type ComponentReviewsLinkingCollectionsTemplateWhyBookWithForaCollectionArgs =
  {
    limit?: InputMaybe<Scalars['Int']>
    locale?: InputMaybe<Scalars['String']>
    order?: InputMaybe<
      Array<
        InputMaybe<ComponentReviewsLinkingCollectionsTemplateWhyBookWithForaCollectionOrder>
      >
    >
    preview?: InputMaybe<Scalars['Boolean']>
    skip?: InputMaybe<Scalars['Int']>
  }

export enum ComponentReviewsLinkingCollectionsCampaignPagesCollectionOrder {
  CollapseFooterAsc = 'collapseFooter_ASC',
  CollapseFooterDesc = 'collapseFooter_DESC',
  CollapseHeaderAsc = 'collapseHeader_ASC',
  CollapseHeaderDesc = 'collapseHeader_DESC',
  HideFooterAsc = 'hideFooter_ASC',
  HideFooterDesc = 'hideFooter_DESC',
  HideGlobalBannerAsc = 'hideGlobalBanner_ASC',
  HideGlobalBannerDesc = 'hideGlobalBanner_DESC',
  JotformIdAsc = 'jotformId_ASC',
  JotformIdDesc = 'jotformId_DESC',
  NoindexNofollowAsc = 'noindexNofollow_ASC',
  NoindexNofollowDesc = 'noindexNofollow_DESC',
  ShowHeaderAsc = 'showHeader_ASC',
  ShowHeaderDesc = 'showHeader_DESC',
  SlugAsc = 'slug_ASC',
  SlugDesc = 'slug_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC',
}

export enum ComponentReviewsLinkingCollectionsTemplateAboutUsCollectionOrder {
  CollapseHeaderAsc = 'collapseHeader_ASC',
  CollapseHeaderDesc = 'collapseHeader_DESC',
  HeaderBlockTitleAsc = 'headerBlockTitle_ASC',
  HeaderBlockTitleDesc = 'headerBlockTitle_DESC',
  ShowHeaderAsc = 'showHeader_ASC',
  ShowHeaderDesc = 'showHeader_DESC',
  SubtitleAsc = 'subtitle_ASC',
  SubtitleDesc = 'subtitle_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC',
}

export enum ComponentReviewsLinkingCollectionsTemplateBookingCollectionOrder {
  CollapseHeaderAsc = 'collapseHeader_ASC',
  CollapseHeaderDesc = 'collapseHeader_DESC',
  ShowHeaderAsc = 'showHeader_ASC',
  ShowHeaderDesc = 'showHeader_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC',
}

export enum ComponentReviewsLinkingCollectionsTemplateCareersCollectionOrder {
  CollapseHeaderAsc = 'collapseHeader_ASC',
  CollapseHeaderDesc = 'collapseHeader_DESC',
  ShowHeaderAsc = 'showHeader_ASC',
  ShowHeaderDesc = 'showHeader_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC',
}

export enum ComponentReviewsLinkingCollectionsTemplateContactUsCollectionOrder {
  CollapseHeaderAsc = 'collapseHeader_ASC',
  CollapseHeaderDesc = 'collapseHeader_DESC',
  ShowHeaderAsc = 'showHeader_ASC',
  ShowHeaderDesc = 'showHeader_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC',
}

export enum ComponentReviewsLinkingCollectionsTemplateDestinationsCollectionOrder {
  CollapseHeaderAsc = 'collapseHeader_ASC',
  CollapseHeaderDesc = 'collapseHeader_DESC',
  CollapsibleHeadingAsc = 'collapsibleHeading_ASC',
  CollapsibleHeadingDesc = 'collapsibleHeading_DESC',
  ShowHeaderAsc = 'showHeader_ASC',
  ShowHeaderDesc = 'showHeader_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC',
}

export enum ComponentReviewsLinkingCollectionsTemplateForaReserveCollectionOrder {
  CollapseHeaderAsc = 'collapseHeader_ASC',
  CollapseHeaderDesc = 'collapseHeader_DESC',
  ShowHeaderAsc = 'showHeader_ASC',
  ShowHeaderDesc = 'showHeader_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC',
}

export enum ComponentReviewsLinkingCollectionsTemplateForaXCollectionOrder {
  AdvisorGridHeaderAsc = 'advisorGridHeader_ASC',
  AdvisorGridHeaderDesc = 'advisorGridHeader_DESC',
  AdvisorGridSubCopyAsc = 'advisorGridSubCopy_ASC',
  AdvisorGridSubCopyDesc = 'advisorGridSubCopy_DESC',
  CollapseHeaderAsc = 'collapseHeader_ASC',
  CollapseHeaderDesc = 'collapseHeader_DESC',
  ShowHeaderAsc = 'showHeader_ASC',
  ShowHeaderDesc = 'showHeader_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC',
}

export enum ComponentReviewsLinkingCollectionsTemplateHomeCollectionOrder {
  CollapseHeaderAsc = 'collapseHeader_ASC',
  CollapseHeaderDesc = 'collapseHeader_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC',
}

export enum ComponentReviewsLinkingCollectionsTemplateJoinUsCollectionOrder {
  CollapseHeaderAsc = 'collapseHeader_ASC',
  CollapseHeaderDesc = 'collapseHeader_DESC',
  HideGlobalBannerAsc = 'hideGlobalBanner_ASC',
  HideGlobalBannerDesc = 'hideGlobalBanner_DESC',
  ShowHeaderAsc = 'showHeader_ASC',
  ShowHeaderDesc = 'showHeader_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC',
}

export enum ComponentReviewsLinkingCollectionsTemplateNewsletterCollectionOrder {
  CollapseHeaderAsc = 'collapseHeader_ASC',
  CollapseHeaderDesc = 'collapseHeader_DESC',
  ShowHeaderAsc = 'showHeader_ASC',
  ShowHeaderDesc = 'showHeader_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC',
}

export enum ComponentReviewsLinkingCollectionsTemplatePartnersCollectionOrder {
  CollapseHeaderAsc = 'collapseHeader_ASC',
  CollapseHeaderDesc = 'collapseHeader_DESC',
  ShowHeaderAsc = 'showHeader_ASC',
  ShowHeaderDesc = 'showHeader_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC',
}

export enum ComponentReviewsLinkingCollectionsTemplateReviewsCollectionOrder {
  CollapseHeaderAsc = 'collapseHeader_ASC',
  CollapseHeaderDesc = 'collapseHeader_DESC',
  ShowHeaderAsc = 'showHeader_ASC',
  ShowHeaderDesc = 'showHeader_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC',
}

export enum ComponentReviewsLinkingCollectionsTemplateWhyBookWithForaCollectionOrder {
  CollapseHeaderAsc = 'collapseHeader_ASC',
  CollapseHeaderDesc = 'collapseHeader_DESC',
  ShowHeaderAsc = 'showHeader_ASC',
  ShowHeaderDesc = 'showHeader_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC',
}

export enum ComponentReviewsOrder {
  CtaTextAsc = 'ctaText_ASC',
  CtaTextDesc = 'ctaText_DESC',
  CtaUrlAsc = 'ctaUrl_ASC',
  CtaUrlDesc = 'ctaUrl_DESC',
  InternalLabelAsc = 'internalLabel_ASC',
  InternalLabelDesc = 'internalLabel_DESC',
  SectionIdAsc = 'sectionId_ASC',
  SectionIdDesc = 'sectionId_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
}

/** [See type definition](https://app.contentful.com/spaces/9cxgygtadmvl/content_types/componentSingleUse) */
export type ComponentSingleUse = Entry &
  _Node & {
    __typename?: 'ComponentSingleUse'
    _id: Scalars['ID']
    contentfulMetadata: ContentfulMetadata
    linkedFrom?: Maybe<ComponentSingleUseLinkingCollections>
    sectionId?: Maybe<Scalars['String']>
    sys: Sys
    title?: Maybe<Scalars['String']>
    type?: Maybe<Scalars['String']>
  }

/** [See type definition](https://app.contentful.com/spaces/9cxgygtadmvl/content_types/componentSingleUse) */
export type ComponentSingleUseLinkedFromArgs = {
  allowedLocales?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
}

/** [See type definition](https://app.contentful.com/spaces/9cxgygtadmvl/content_types/componentSingleUse) */
export type ComponentSingleUseSectionIdArgs = {
  locale?: InputMaybe<Scalars['String']>
}

/** [See type definition](https://app.contentful.com/spaces/9cxgygtadmvl/content_types/componentSingleUse) */
export type ComponentSingleUseTitleArgs = {
  locale?: InputMaybe<Scalars['String']>
}

/** [See type definition](https://app.contentful.com/spaces/9cxgygtadmvl/content_types/componentSingleUse) */
export type ComponentSingleUseTypeArgs = {
  locale?: InputMaybe<Scalars['String']>
}

export type ComponentSingleUseCollection = {
  __typename?: 'ComponentSingleUseCollection'
  items: Array<Maybe<ComponentSingleUse>>
  limit: Scalars['Int']
  skip: Scalars['Int']
  total: Scalars['Int']
}

export type ComponentSingleUseFilter = {
  AND?: InputMaybe<Array<InputMaybe<ComponentSingleUseFilter>>>
  OR?: InputMaybe<Array<InputMaybe<ComponentSingleUseFilter>>>
  contentfulMetadata?: InputMaybe<ContentfulMetadataFilter>
  sectionId?: InputMaybe<Scalars['String']>
  sectionId_contains?: InputMaybe<Scalars['String']>
  sectionId_exists?: InputMaybe<Scalars['Boolean']>
  sectionId_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  sectionId_not?: InputMaybe<Scalars['String']>
  sectionId_not_contains?: InputMaybe<Scalars['String']>
  sectionId_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  sys?: InputMaybe<SysFilter>
  title?: InputMaybe<Scalars['String']>
  title_contains?: InputMaybe<Scalars['String']>
  title_exists?: InputMaybe<Scalars['Boolean']>
  title_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  title_not?: InputMaybe<Scalars['String']>
  title_not_contains?: InputMaybe<Scalars['String']>
  title_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  type?: InputMaybe<Scalars['String']>
  type_contains?: InputMaybe<Scalars['String']>
  type_exists?: InputMaybe<Scalars['Boolean']>
  type_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  type_not?: InputMaybe<Scalars['String']>
  type_not_contains?: InputMaybe<Scalars['String']>
  type_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
}

export type ComponentSingleUseLinkingCollections = {
  __typename?: 'ComponentSingleUseLinkingCollections'
  campaignPagesCollection?: Maybe<CampaignPagesCollection>
  entryCollection?: Maybe<EntryCollection>
  templateAboutUsCollection?: Maybe<TemplateAboutUsCollection>
  templateBookingCollection?: Maybe<TemplateBookingCollection>
  templateCareersCollection?: Maybe<TemplateCareersCollection>
  templateContactUsCollection?: Maybe<TemplateContactUsCollection>
  templateDestinationsCollection?: Maybe<TemplateDestinationsCollection>
  templateForaReserveCollection?: Maybe<TemplateForaReserveCollection>
  templateForaXCollection?: Maybe<TemplateForaXCollection>
  templateHomeCollection?: Maybe<TemplateHomeCollection>
  templateJoinUsCollection?: Maybe<TemplateJoinUsCollection>
  templateNewsletterCollection?: Maybe<TemplateNewsletterCollection>
  templatePartnersCollection?: Maybe<TemplatePartnersCollection>
  templateReviewsCollection?: Maybe<TemplateReviewsCollection>
  templateWhyBookWithForaCollection?: Maybe<TemplateWhyBookWithForaCollection>
}

export type ComponentSingleUseLinkingCollectionsCampaignPagesCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>
  locale?: InputMaybe<Scalars['String']>
  order?: InputMaybe<
    Array<
      InputMaybe<ComponentSingleUseLinkingCollectionsCampaignPagesCollectionOrder>
    >
  >
  preview?: InputMaybe<Scalars['Boolean']>
  skip?: InputMaybe<Scalars['Int']>
}

export type ComponentSingleUseLinkingCollectionsEntryCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>
  locale?: InputMaybe<Scalars['String']>
  preview?: InputMaybe<Scalars['Boolean']>
  skip?: InputMaybe<Scalars['Int']>
}

export type ComponentSingleUseLinkingCollectionsTemplateAboutUsCollectionArgs =
  {
    limit?: InputMaybe<Scalars['Int']>
    locale?: InputMaybe<Scalars['String']>
    order?: InputMaybe<
      Array<
        InputMaybe<ComponentSingleUseLinkingCollectionsTemplateAboutUsCollectionOrder>
      >
    >
    preview?: InputMaybe<Scalars['Boolean']>
    skip?: InputMaybe<Scalars['Int']>
  }

export type ComponentSingleUseLinkingCollectionsTemplateBookingCollectionArgs =
  {
    limit?: InputMaybe<Scalars['Int']>
    locale?: InputMaybe<Scalars['String']>
    order?: InputMaybe<
      Array<
        InputMaybe<ComponentSingleUseLinkingCollectionsTemplateBookingCollectionOrder>
      >
    >
    preview?: InputMaybe<Scalars['Boolean']>
    skip?: InputMaybe<Scalars['Int']>
  }

export type ComponentSingleUseLinkingCollectionsTemplateCareersCollectionArgs =
  {
    limit?: InputMaybe<Scalars['Int']>
    locale?: InputMaybe<Scalars['String']>
    order?: InputMaybe<
      Array<
        InputMaybe<ComponentSingleUseLinkingCollectionsTemplateCareersCollectionOrder>
      >
    >
    preview?: InputMaybe<Scalars['Boolean']>
    skip?: InputMaybe<Scalars['Int']>
  }

export type ComponentSingleUseLinkingCollectionsTemplateContactUsCollectionArgs =
  {
    limit?: InputMaybe<Scalars['Int']>
    locale?: InputMaybe<Scalars['String']>
    order?: InputMaybe<
      Array<
        InputMaybe<ComponentSingleUseLinkingCollectionsTemplateContactUsCollectionOrder>
      >
    >
    preview?: InputMaybe<Scalars['Boolean']>
    skip?: InputMaybe<Scalars['Int']>
  }

export type ComponentSingleUseLinkingCollectionsTemplateDestinationsCollectionArgs =
  {
    limit?: InputMaybe<Scalars['Int']>
    locale?: InputMaybe<Scalars['String']>
    order?: InputMaybe<
      Array<
        InputMaybe<ComponentSingleUseLinkingCollectionsTemplateDestinationsCollectionOrder>
      >
    >
    preview?: InputMaybe<Scalars['Boolean']>
    skip?: InputMaybe<Scalars['Int']>
  }

export type ComponentSingleUseLinkingCollectionsTemplateForaReserveCollectionArgs =
  {
    limit?: InputMaybe<Scalars['Int']>
    locale?: InputMaybe<Scalars['String']>
    order?: InputMaybe<
      Array<
        InputMaybe<ComponentSingleUseLinkingCollectionsTemplateForaReserveCollectionOrder>
      >
    >
    preview?: InputMaybe<Scalars['Boolean']>
    skip?: InputMaybe<Scalars['Int']>
  }

export type ComponentSingleUseLinkingCollectionsTemplateForaXCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>
  locale?: InputMaybe<Scalars['String']>
  order?: InputMaybe<
    Array<
      InputMaybe<ComponentSingleUseLinkingCollectionsTemplateForaXCollectionOrder>
    >
  >
  preview?: InputMaybe<Scalars['Boolean']>
  skip?: InputMaybe<Scalars['Int']>
}

export type ComponentSingleUseLinkingCollectionsTemplateHomeCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>
  locale?: InputMaybe<Scalars['String']>
  order?: InputMaybe<
    Array<
      InputMaybe<ComponentSingleUseLinkingCollectionsTemplateHomeCollectionOrder>
    >
  >
  preview?: InputMaybe<Scalars['Boolean']>
  skip?: InputMaybe<Scalars['Int']>
}

export type ComponentSingleUseLinkingCollectionsTemplateJoinUsCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>
  locale?: InputMaybe<Scalars['String']>
  order?: InputMaybe<
    Array<
      InputMaybe<ComponentSingleUseLinkingCollectionsTemplateJoinUsCollectionOrder>
    >
  >
  preview?: InputMaybe<Scalars['Boolean']>
  skip?: InputMaybe<Scalars['Int']>
}

export type ComponentSingleUseLinkingCollectionsTemplateNewsletterCollectionArgs =
  {
    limit?: InputMaybe<Scalars['Int']>
    locale?: InputMaybe<Scalars['String']>
    order?: InputMaybe<
      Array<
        InputMaybe<ComponentSingleUseLinkingCollectionsTemplateNewsletterCollectionOrder>
      >
    >
    preview?: InputMaybe<Scalars['Boolean']>
    skip?: InputMaybe<Scalars['Int']>
  }

export type ComponentSingleUseLinkingCollectionsTemplatePartnersCollectionArgs =
  {
    limit?: InputMaybe<Scalars['Int']>
    locale?: InputMaybe<Scalars['String']>
    order?: InputMaybe<
      Array<
        InputMaybe<ComponentSingleUseLinkingCollectionsTemplatePartnersCollectionOrder>
      >
    >
    preview?: InputMaybe<Scalars['Boolean']>
    skip?: InputMaybe<Scalars['Int']>
  }

export type ComponentSingleUseLinkingCollectionsTemplateReviewsCollectionArgs =
  {
    limit?: InputMaybe<Scalars['Int']>
    locale?: InputMaybe<Scalars['String']>
    order?: InputMaybe<
      Array<
        InputMaybe<ComponentSingleUseLinkingCollectionsTemplateReviewsCollectionOrder>
      >
    >
    preview?: InputMaybe<Scalars['Boolean']>
    skip?: InputMaybe<Scalars['Int']>
  }

export type ComponentSingleUseLinkingCollectionsTemplateWhyBookWithForaCollectionArgs =
  {
    limit?: InputMaybe<Scalars['Int']>
    locale?: InputMaybe<Scalars['String']>
    order?: InputMaybe<
      Array<
        InputMaybe<ComponentSingleUseLinkingCollectionsTemplateWhyBookWithForaCollectionOrder>
      >
    >
    preview?: InputMaybe<Scalars['Boolean']>
    skip?: InputMaybe<Scalars['Int']>
  }

export enum ComponentSingleUseLinkingCollectionsCampaignPagesCollectionOrder {
  CollapseFooterAsc = 'collapseFooter_ASC',
  CollapseFooterDesc = 'collapseFooter_DESC',
  CollapseHeaderAsc = 'collapseHeader_ASC',
  CollapseHeaderDesc = 'collapseHeader_DESC',
  HideFooterAsc = 'hideFooter_ASC',
  HideFooterDesc = 'hideFooter_DESC',
  HideGlobalBannerAsc = 'hideGlobalBanner_ASC',
  HideGlobalBannerDesc = 'hideGlobalBanner_DESC',
  JotformIdAsc = 'jotformId_ASC',
  JotformIdDesc = 'jotformId_DESC',
  NoindexNofollowAsc = 'noindexNofollow_ASC',
  NoindexNofollowDesc = 'noindexNofollow_DESC',
  ShowHeaderAsc = 'showHeader_ASC',
  ShowHeaderDesc = 'showHeader_DESC',
  SlugAsc = 'slug_ASC',
  SlugDesc = 'slug_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC',
}

export enum ComponentSingleUseLinkingCollectionsTemplateAboutUsCollectionOrder {
  CollapseHeaderAsc = 'collapseHeader_ASC',
  CollapseHeaderDesc = 'collapseHeader_DESC',
  HeaderBlockTitleAsc = 'headerBlockTitle_ASC',
  HeaderBlockTitleDesc = 'headerBlockTitle_DESC',
  ShowHeaderAsc = 'showHeader_ASC',
  ShowHeaderDesc = 'showHeader_DESC',
  SubtitleAsc = 'subtitle_ASC',
  SubtitleDesc = 'subtitle_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC',
}

export enum ComponentSingleUseLinkingCollectionsTemplateBookingCollectionOrder {
  CollapseHeaderAsc = 'collapseHeader_ASC',
  CollapseHeaderDesc = 'collapseHeader_DESC',
  ShowHeaderAsc = 'showHeader_ASC',
  ShowHeaderDesc = 'showHeader_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC',
}

export enum ComponentSingleUseLinkingCollectionsTemplateCareersCollectionOrder {
  CollapseHeaderAsc = 'collapseHeader_ASC',
  CollapseHeaderDesc = 'collapseHeader_DESC',
  ShowHeaderAsc = 'showHeader_ASC',
  ShowHeaderDesc = 'showHeader_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC',
}

export enum ComponentSingleUseLinkingCollectionsTemplateContactUsCollectionOrder {
  CollapseHeaderAsc = 'collapseHeader_ASC',
  CollapseHeaderDesc = 'collapseHeader_DESC',
  ShowHeaderAsc = 'showHeader_ASC',
  ShowHeaderDesc = 'showHeader_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC',
}

export enum ComponentSingleUseLinkingCollectionsTemplateDestinationsCollectionOrder {
  CollapseHeaderAsc = 'collapseHeader_ASC',
  CollapseHeaderDesc = 'collapseHeader_DESC',
  CollapsibleHeadingAsc = 'collapsibleHeading_ASC',
  CollapsibleHeadingDesc = 'collapsibleHeading_DESC',
  ShowHeaderAsc = 'showHeader_ASC',
  ShowHeaderDesc = 'showHeader_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC',
}

export enum ComponentSingleUseLinkingCollectionsTemplateForaReserveCollectionOrder {
  CollapseHeaderAsc = 'collapseHeader_ASC',
  CollapseHeaderDesc = 'collapseHeader_DESC',
  ShowHeaderAsc = 'showHeader_ASC',
  ShowHeaderDesc = 'showHeader_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC',
}

export enum ComponentSingleUseLinkingCollectionsTemplateForaXCollectionOrder {
  AdvisorGridHeaderAsc = 'advisorGridHeader_ASC',
  AdvisorGridHeaderDesc = 'advisorGridHeader_DESC',
  AdvisorGridSubCopyAsc = 'advisorGridSubCopy_ASC',
  AdvisorGridSubCopyDesc = 'advisorGridSubCopy_DESC',
  CollapseHeaderAsc = 'collapseHeader_ASC',
  CollapseHeaderDesc = 'collapseHeader_DESC',
  ShowHeaderAsc = 'showHeader_ASC',
  ShowHeaderDesc = 'showHeader_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC',
}

export enum ComponentSingleUseLinkingCollectionsTemplateHomeCollectionOrder {
  CollapseHeaderAsc = 'collapseHeader_ASC',
  CollapseHeaderDesc = 'collapseHeader_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC',
}

export enum ComponentSingleUseLinkingCollectionsTemplateJoinUsCollectionOrder {
  CollapseHeaderAsc = 'collapseHeader_ASC',
  CollapseHeaderDesc = 'collapseHeader_DESC',
  HideGlobalBannerAsc = 'hideGlobalBanner_ASC',
  HideGlobalBannerDesc = 'hideGlobalBanner_DESC',
  ShowHeaderAsc = 'showHeader_ASC',
  ShowHeaderDesc = 'showHeader_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC',
}

export enum ComponentSingleUseLinkingCollectionsTemplateNewsletterCollectionOrder {
  CollapseHeaderAsc = 'collapseHeader_ASC',
  CollapseHeaderDesc = 'collapseHeader_DESC',
  ShowHeaderAsc = 'showHeader_ASC',
  ShowHeaderDesc = 'showHeader_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC',
}

export enum ComponentSingleUseLinkingCollectionsTemplatePartnersCollectionOrder {
  CollapseHeaderAsc = 'collapseHeader_ASC',
  CollapseHeaderDesc = 'collapseHeader_DESC',
  ShowHeaderAsc = 'showHeader_ASC',
  ShowHeaderDesc = 'showHeader_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC',
}

export enum ComponentSingleUseLinkingCollectionsTemplateReviewsCollectionOrder {
  CollapseHeaderAsc = 'collapseHeader_ASC',
  CollapseHeaderDesc = 'collapseHeader_DESC',
  ShowHeaderAsc = 'showHeader_ASC',
  ShowHeaderDesc = 'showHeader_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC',
}

export enum ComponentSingleUseLinkingCollectionsTemplateWhyBookWithForaCollectionOrder {
  CollapseHeaderAsc = 'collapseHeader_ASC',
  CollapseHeaderDesc = 'collapseHeader_DESC',
  ShowHeaderAsc = 'showHeader_ASC',
  ShowHeaderDesc = 'showHeader_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC',
}

export enum ComponentSingleUseOrder {
  SectionIdAsc = 'sectionId_ASC',
  SectionIdDesc = 'sectionId_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC',
  TypeAsc = 'type_ASC',
  TypeDesc = 'type_DESC',
}

/** Define vertical space between two components [See type definition](https://app.contentful.com/spaces/9cxgygtadmvl/content_types/componentSpacer) */
export type ComponentSpacer = Entry &
  _Node & {
    __typename?: 'ComponentSpacer'
    _id: Scalars['ID']
    contentfulMetadata: ContentfulMetadata
    hasDivider?: Maybe<Scalars['Boolean']>
    icon?: Maybe<Asset>
    internalLabel?: Maybe<Scalars['String']>
    linkedFrom?: Maybe<ComponentSpacerLinkingCollections>
    paddingBelow?: Maybe<Scalars['String']>
    paddingBelowMobile?: Maybe<Scalars['String']>
    paddingTop?: Maybe<Scalars['String']>
    paddingTopMobile?: Maybe<Scalars['String']>
    sys: Sys
  }

/** Define vertical space between two components [See type definition](https://app.contentful.com/spaces/9cxgygtadmvl/content_types/componentSpacer) */
export type ComponentSpacerHasDividerArgs = {
  locale?: InputMaybe<Scalars['String']>
}

/** Define vertical space between two components [See type definition](https://app.contentful.com/spaces/9cxgygtadmvl/content_types/componentSpacer) */
export type ComponentSpacerIconArgs = {
  locale?: InputMaybe<Scalars['String']>
  preview?: InputMaybe<Scalars['Boolean']>
}

/** Define vertical space between two components [See type definition](https://app.contentful.com/spaces/9cxgygtadmvl/content_types/componentSpacer) */
export type ComponentSpacerInternalLabelArgs = {
  locale?: InputMaybe<Scalars['String']>
}

/** Define vertical space between two components [See type definition](https://app.contentful.com/spaces/9cxgygtadmvl/content_types/componentSpacer) */
export type ComponentSpacerLinkedFromArgs = {
  allowedLocales?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
}

/** Define vertical space between two components [See type definition](https://app.contentful.com/spaces/9cxgygtadmvl/content_types/componentSpacer) */
export type ComponentSpacerPaddingBelowArgs = {
  locale?: InputMaybe<Scalars['String']>
}

/** Define vertical space between two components [See type definition](https://app.contentful.com/spaces/9cxgygtadmvl/content_types/componentSpacer) */
export type ComponentSpacerPaddingBelowMobileArgs = {
  locale?: InputMaybe<Scalars['String']>
}

/** Define vertical space between two components [See type definition](https://app.contentful.com/spaces/9cxgygtadmvl/content_types/componentSpacer) */
export type ComponentSpacerPaddingTopArgs = {
  locale?: InputMaybe<Scalars['String']>
}

/** Define vertical space between two components [See type definition](https://app.contentful.com/spaces/9cxgygtadmvl/content_types/componentSpacer) */
export type ComponentSpacerPaddingTopMobileArgs = {
  locale?: InputMaybe<Scalars['String']>
}

export type ComponentSpacerCollection = {
  __typename?: 'ComponentSpacerCollection'
  items: Array<Maybe<ComponentSpacer>>
  limit: Scalars['Int']
  skip: Scalars['Int']
  total: Scalars['Int']
}

export type ComponentSpacerFilter = {
  AND?: InputMaybe<Array<InputMaybe<ComponentSpacerFilter>>>
  OR?: InputMaybe<Array<InputMaybe<ComponentSpacerFilter>>>
  contentfulMetadata?: InputMaybe<ContentfulMetadataFilter>
  hasDivider?: InputMaybe<Scalars['Boolean']>
  hasDivider_exists?: InputMaybe<Scalars['Boolean']>
  hasDivider_not?: InputMaybe<Scalars['Boolean']>
  icon_exists?: InputMaybe<Scalars['Boolean']>
  internalLabel?: InputMaybe<Scalars['String']>
  internalLabel_contains?: InputMaybe<Scalars['String']>
  internalLabel_exists?: InputMaybe<Scalars['Boolean']>
  internalLabel_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  internalLabel_not?: InputMaybe<Scalars['String']>
  internalLabel_not_contains?: InputMaybe<Scalars['String']>
  internalLabel_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  paddingBelow?: InputMaybe<Scalars['String']>
  paddingBelowMobile?: InputMaybe<Scalars['String']>
  paddingBelowMobile_contains?: InputMaybe<Scalars['String']>
  paddingBelowMobile_exists?: InputMaybe<Scalars['Boolean']>
  paddingBelowMobile_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  paddingBelowMobile_not?: InputMaybe<Scalars['String']>
  paddingBelowMobile_not_contains?: InputMaybe<Scalars['String']>
  paddingBelowMobile_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  paddingBelow_contains?: InputMaybe<Scalars['String']>
  paddingBelow_exists?: InputMaybe<Scalars['Boolean']>
  paddingBelow_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  paddingBelow_not?: InputMaybe<Scalars['String']>
  paddingBelow_not_contains?: InputMaybe<Scalars['String']>
  paddingBelow_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  paddingTop?: InputMaybe<Scalars['String']>
  paddingTopMobile?: InputMaybe<Scalars['String']>
  paddingTopMobile_contains?: InputMaybe<Scalars['String']>
  paddingTopMobile_exists?: InputMaybe<Scalars['Boolean']>
  paddingTopMobile_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  paddingTopMobile_not?: InputMaybe<Scalars['String']>
  paddingTopMobile_not_contains?: InputMaybe<Scalars['String']>
  paddingTopMobile_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  paddingTop_contains?: InputMaybe<Scalars['String']>
  paddingTop_exists?: InputMaybe<Scalars['Boolean']>
  paddingTop_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  paddingTop_not?: InputMaybe<Scalars['String']>
  paddingTop_not_contains?: InputMaybe<Scalars['String']>
  paddingTop_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  sys?: InputMaybe<SysFilter>
}

export type ComponentSpacerLinkingCollections = {
  __typename?: 'ComponentSpacerLinkingCollections'
  campaignPagesCollection?: Maybe<CampaignPagesCollection>
  entryCollection?: Maybe<EntryCollection>
  templateAboutUsCollection?: Maybe<TemplateAboutUsCollection>
  templateBookingCollection?: Maybe<TemplateBookingCollection>
  templateCareersCollection?: Maybe<TemplateCareersCollection>
  templateContactUsCollection?: Maybe<TemplateContactUsCollection>
  templateDestinationsCollection?: Maybe<TemplateDestinationsCollection>
  templateForaReserveCollection?: Maybe<TemplateForaReserveCollection>
  templateForaXCollection?: Maybe<TemplateForaXCollection>
  templateHomeCollection?: Maybe<TemplateHomeCollection>
  templateJoinUsCollection?: Maybe<TemplateJoinUsCollection>
  templateNewsletterCollection?: Maybe<TemplateNewsletterCollection>
  templatePageCollection?: Maybe<TemplatePageCollection>
  templatePartnersCollection?: Maybe<TemplatePartnersCollection>
  templateReviewsCollection?: Maybe<TemplateReviewsCollection>
  templateWhyBookWithForaCollection?: Maybe<TemplateWhyBookWithForaCollection>
}

export type ComponentSpacerLinkingCollectionsCampaignPagesCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>
  locale?: InputMaybe<Scalars['String']>
  order?: InputMaybe<
    Array<
      InputMaybe<ComponentSpacerLinkingCollectionsCampaignPagesCollectionOrder>
    >
  >
  preview?: InputMaybe<Scalars['Boolean']>
  skip?: InputMaybe<Scalars['Int']>
}

export type ComponentSpacerLinkingCollectionsEntryCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>
  locale?: InputMaybe<Scalars['String']>
  preview?: InputMaybe<Scalars['Boolean']>
  skip?: InputMaybe<Scalars['Int']>
}

export type ComponentSpacerLinkingCollectionsTemplateAboutUsCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>
  locale?: InputMaybe<Scalars['String']>
  order?: InputMaybe<
    Array<
      InputMaybe<ComponentSpacerLinkingCollectionsTemplateAboutUsCollectionOrder>
    >
  >
  preview?: InputMaybe<Scalars['Boolean']>
  skip?: InputMaybe<Scalars['Int']>
}

export type ComponentSpacerLinkingCollectionsTemplateBookingCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>
  locale?: InputMaybe<Scalars['String']>
  order?: InputMaybe<
    Array<
      InputMaybe<ComponentSpacerLinkingCollectionsTemplateBookingCollectionOrder>
    >
  >
  preview?: InputMaybe<Scalars['Boolean']>
  skip?: InputMaybe<Scalars['Int']>
}

export type ComponentSpacerLinkingCollectionsTemplateCareersCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>
  locale?: InputMaybe<Scalars['String']>
  order?: InputMaybe<
    Array<
      InputMaybe<ComponentSpacerLinkingCollectionsTemplateCareersCollectionOrder>
    >
  >
  preview?: InputMaybe<Scalars['Boolean']>
  skip?: InputMaybe<Scalars['Int']>
}

export type ComponentSpacerLinkingCollectionsTemplateContactUsCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>
  locale?: InputMaybe<Scalars['String']>
  order?: InputMaybe<
    Array<
      InputMaybe<ComponentSpacerLinkingCollectionsTemplateContactUsCollectionOrder>
    >
  >
  preview?: InputMaybe<Scalars['Boolean']>
  skip?: InputMaybe<Scalars['Int']>
}

export type ComponentSpacerLinkingCollectionsTemplateDestinationsCollectionArgs =
  {
    limit?: InputMaybe<Scalars['Int']>
    locale?: InputMaybe<Scalars['String']>
    order?: InputMaybe<
      Array<
        InputMaybe<ComponentSpacerLinkingCollectionsTemplateDestinationsCollectionOrder>
      >
    >
    preview?: InputMaybe<Scalars['Boolean']>
    skip?: InputMaybe<Scalars['Int']>
  }

export type ComponentSpacerLinkingCollectionsTemplateForaReserveCollectionArgs =
  {
    limit?: InputMaybe<Scalars['Int']>
    locale?: InputMaybe<Scalars['String']>
    order?: InputMaybe<
      Array<
        InputMaybe<ComponentSpacerLinkingCollectionsTemplateForaReserveCollectionOrder>
      >
    >
    preview?: InputMaybe<Scalars['Boolean']>
    skip?: InputMaybe<Scalars['Int']>
  }

export type ComponentSpacerLinkingCollectionsTemplateForaXCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>
  locale?: InputMaybe<Scalars['String']>
  order?: InputMaybe<
    Array<
      InputMaybe<ComponentSpacerLinkingCollectionsTemplateForaXCollectionOrder>
    >
  >
  preview?: InputMaybe<Scalars['Boolean']>
  skip?: InputMaybe<Scalars['Int']>
}

export type ComponentSpacerLinkingCollectionsTemplateHomeCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>
  locale?: InputMaybe<Scalars['String']>
  order?: InputMaybe<
    Array<
      InputMaybe<ComponentSpacerLinkingCollectionsTemplateHomeCollectionOrder>
    >
  >
  preview?: InputMaybe<Scalars['Boolean']>
  skip?: InputMaybe<Scalars['Int']>
}

export type ComponentSpacerLinkingCollectionsTemplateJoinUsCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>
  locale?: InputMaybe<Scalars['String']>
  order?: InputMaybe<
    Array<
      InputMaybe<ComponentSpacerLinkingCollectionsTemplateJoinUsCollectionOrder>
    >
  >
  preview?: InputMaybe<Scalars['Boolean']>
  skip?: InputMaybe<Scalars['Int']>
}

export type ComponentSpacerLinkingCollectionsTemplateNewsletterCollectionArgs =
  {
    limit?: InputMaybe<Scalars['Int']>
    locale?: InputMaybe<Scalars['String']>
    order?: InputMaybe<
      Array<
        InputMaybe<ComponentSpacerLinkingCollectionsTemplateNewsletterCollectionOrder>
      >
    >
    preview?: InputMaybe<Scalars['Boolean']>
    skip?: InputMaybe<Scalars['Int']>
  }

export type ComponentSpacerLinkingCollectionsTemplatePageCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>
  locale?: InputMaybe<Scalars['String']>
  order?: InputMaybe<
    Array<
      InputMaybe<ComponentSpacerLinkingCollectionsTemplatePageCollectionOrder>
    >
  >
  preview?: InputMaybe<Scalars['Boolean']>
  skip?: InputMaybe<Scalars['Int']>
}

export type ComponentSpacerLinkingCollectionsTemplatePartnersCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>
  locale?: InputMaybe<Scalars['String']>
  order?: InputMaybe<
    Array<
      InputMaybe<ComponentSpacerLinkingCollectionsTemplatePartnersCollectionOrder>
    >
  >
  preview?: InputMaybe<Scalars['Boolean']>
  skip?: InputMaybe<Scalars['Int']>
}

export type ComponentSpacerLinkingCollectionsTemplateReviewsCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>
  locale?: InputMaybe<Scalars['String']>
  order?: InputMaybe<
    Array<
      InputMaybe<ComponentSpacerLinkingCollectionsTemplateReviewsCollectionOrder>
    >
  >
  preview?: InputMaybe<Scalars['Boolean']>
  skip?: InputMaybe<Scalars['Int']>
}

export type ComponentSpacerLinkingCollectionsTemplateWhyBookWithForaCollectionArgs =
  {
    limit?: InputMaybe<Scalars['Int']>
    locale?: InputMaybe<Scalars['String']>
    order?: InputMaybe<
      Array<
        InputMaybe<ComponentSpacerLinkingCollectionsTemplateWhyBookWithForaCollectionOrder>
      >
    >
    preview?: InputMaybe<Scalars['Boolean']>
    skip?: InputMaybe<Scalars['Int']>
  }

export enum ComponentSpacerLinkingCollectionsCampaignPagesCollectionOrder {
  CollapseFooterAsc = 'collapseFooter_ASC',
  CollapseFooterDesc = 'collapseFooter_DESC',
  CollapseHeaderAsc = 'collapseHeader_ASC',
  CollapseHeaderDesc = 'collapseHeader_DESC',
  HideFooterAsc = 'hideFooter_ASC',
  HideFooterDesc = 'hideFooter_DESC',
  HideGlobalBannerAsc = 'hideGlobalBanner_ASC',
  HideGlobalBannerDesc = 'hideGlobalBanner_DESC',
  JotformIdAsc = 'jotformId_ASC',
  JotformIdDesc = 'jotformId_DESC',
  NoindexNofollowAsc = 'noindexNofollow_ASC',
  NoindexNofollowDesc = 'noindexNofollow_DESC',
  ShowHeaderAsc = 'showHeader_ASC',
  ShowHeaderDesc = 'showHeader_DESC',
  SlugAsc = 'slug_ASC',
  SlugDesc = 'slug_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC',
}

export enum ComponentSpacerLinkingCollectionsTemplateAboutUsCollectionOrder {
  CollapseHeaderAsc = 'collapseHeader_ASC',
  CollapseHeaderDesc = 'collapseHeader_DESC',
  HeaderBlockTitleAsc = 'headerBlockTitle_ASC',
  HeaderBlockTitleDesc = 'headerBlockTitle_DESC',
  ShowHeaderAsc = 'showHeader_ASC',
  ShowHeaderDesc = 'showHeader_DESC',
  SubtitleAsc = 'subtitle_ASC',
  SubtitleDesc = 'subtitle_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC',
}

export enum ComponentSpacerLinkingCollectionsTemplateBookingCollectionOrder {
  CollapseHeaderAsc = 'collapseHeader_ASC',
  CollapseHeaderDesc = 'collapseHeader_DESC',
  ShowHeaderAsc = 'showHeader_ASC',
  ShowHeaderDesc = 'showHeader_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC',
}

export enum ComponentSpacerLinkingCollectionsTemplateCareersCollectionOrder {
  CollapseHeaderAsc = 'collapseHeader_ASC',
  CollapseHeaderDesc = 'collapseHeader_DESC',
  ShowHeaderAsc = 'showHeader_ASC',
  ShowHeaderDesc = 'showHeader_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC',
}

export enum ComponentSpacerLinkingCollectionsTemplateContactUsCollectionOrder {
  CollapseHeaderAsc = 'collapseHeader_ASC',
  CollapseHeaderDesc = 'collapseHeader_DESC',
  ShowHeaderAsc = 'showHeader_ASC',
  ShowHeaderDesc = 'showHeader_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC',
}

export enum ComponentSpacerLinkingCollectionsTemplateDestinationsCollectionOrder {
  CollapseHeaderAsc = 'collapseHeader_ASC',
  CollapseHeaderDesc = 'collapseHeader_DESC',
  CollapsibleHeadingAsc = 'collapsibleHeading_ASC',
  CollapsibleHeadingDesc = 'collapsibleHeading_DESC',
  ShowHeaderAsc = 'showHeader_ASC',
  ShowHeaderDesc = 'showHeader_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC',
}

export enum ComponentSpacerLinkingCollectionsTemplateForaReserveCollectionOrder {
  CollapseHeaderAsc = 'collapseHeader_ASC',
  CollapseHeaderDesc = 'collapseHeader_DESC',
  ShowHeaderAsc = 'showHeader_ASC',
  ShowHeaderDesc = 'showHeader_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC',
}

export enum ComponentSpacerLinkingCollectionsTemplateForaXCollectionOrder {
  AdvisorGridHeaderAsc = 'advisorGridHeader_ASC',
  AdvisorGridHeaderDesc = 'advisorGridHeader_DESC',
  AdvisorGridSubCopyAsc = 'advisorGridSubCopy_ASC',
  AdvisorGridSubCopyDesc = 'advisorGridSubCopy_DESC',
  CollapseHeaderAsc = 'collapseHeader_ASC',
  CollapseHeaderDesc = 'collapseHeader_DESC',
  ShowHeaderAsc = 'showHeader_ASC',
  ShowHeaderDesc = 'showHeader_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC',
}

export enum ComponentSpacerLinkingCollectionsTemplateHomeCollectionOrder {
  CollapseHeaderAsc = 'collapseHeader_ASC',
  CollapseHeaderDesc = 'collapseHeader_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC',
}

export enum ComponentSpacerLinkingCollectionsTemplateJoinUsCollectionOrder {
  CollapseHeaderAsc = 'collapseHeader_ASC',
  CollapseHeaderDesc = 'collapseHeader_DESC',
  HideGlobalBannerAsc = 'hideGlobalBanner_ASC',
  HideGlobalBannerDesc = 'hideGlobalBanner_DESC',
  ShowHeaderAsc = 'showHeader_ASC',
  ShowHeaderDesc = 'showHeader_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC',
}

export enum ComponentSpacerLinkingCollectionsTemplateNewsletterCollectionOrder {
  CollapseHeaderAsc = 'collapseHeader_ASC',
  CollapseHeaderDesc = 'collapseHeader_DESC',
  ShowHeaderAsc = 'showHeader_ASC',
  ShowHeaderDesc = 'showHeader_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC',
}

export enum ComponentSpacerLinkingCollectionsTemplatePageCollectionOrder {
  DirectoryAsc = 'directory_ASC',
  DirectoryDesc = 'directory_DESC',
  InternalLabelAsc = 'internalLabel_ASC',
  InternalLabelDesc = 'internalLabel_DESC',
  MetaTitleAsc = 'metaTitle_ASC',
  MetaTitleDesc = 'metaTitle_DESC',
  NoindexNofollowAsc = 'noindexNofollow_ASC',
  NoindexNofollowDesc = 'noindexNofollow_DESC',
  SlugAsc = 'slug_ASC',
  SlugDesc = 'slug_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
}

export enum ComponentSpacerLinkingCollectionsTemplatePartnersCollectionOrder {
  CollapseHeaderAsc = 'collapseHeader_ASC',
  CollapseHeaderDesc = 'collapseHeader_DESC',
  ShowHeaderAsc = 'showHeader_ASC',
  ShowHeaderDesc = 'showHeader_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC',
}

export enum ComponentSpacerLinkingCollectionsTemplateReviewsCollectionOrder {
  CollapseHeaderAsc = 'collapseHeader_ASC',
  CollapseHeaderDesc = 'collapseHeader_DESC',
  ShowHeaderAsc = 'showHeader_ASC',
  ShowHeaderDesc = 'showHeader_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC',
}

export enum ComponentSpacerLinkingCollectionsTemplateWhyBookWithForaCollectionOrder {
  CollapseHeaderAsc = 'collapseHeader_ASC',
  CollapseHeaderDesc = 'collapseHeader_DESC',
  ShowHeaderAsc = 'showHeader_ASC',
  ShowHeaderDesc = 'showHeader_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC',
}

export enum ComponentSpacerOrder {
  HasDividerAsc = 'hasDivider_ASC',
  HasDividerDesc = 'hasDivider_DESC',
  InternalLabelAsc = 'internalLabel_ASC',
  InternalLabelDesc = 'internalLabel_DESC',
  PaddingBelowMobileAsc = 'paddingBelowMobile_ASC',
  PaddingBelowMobileDesc = 'paddingBelowMobile_DESC',
  PaddingBelowAsc = 'paddingBelow_ASC',
  PaddingBelowDesc = 'paddingBelow_DESC',
  PaddingTopMobileAsc = 'paddingTopMobile_ASC',
  PaddingTopMobileDesc = 'paddingTopMobile_DESC',
  PaddingTopAsc = 'paddingTop_ASC',
  PaddingTopDesc = 'paddingTop_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
}

/** Sticky header with two links. [See type definition](https://app.contentful.com/spaces/9cxgygtadmvl/content_types/componentStickyHeader) */
export type ComponentStickyHeader = Entry &
  _Node & {
    __typename?: 'ComponentStickyHeader'
    _id: Scalars['ID']
    bgColor?: Maybe<Scalars['String']>
    contentfulMetadata: ContentfulMetadata
    ctaText?: Maybe<Scalars['String']>
    ctaUrl?: Maybe<Scalars['String']>
    icon?: Maybe<Asset>
    internalLabel?: Maybe<Scalars['String']>
    leftLink?: Maybe<Scalars['String']>
    linkedFrom?: Maybe<ComponentStickyHeaderLinkingCollections>
    sys: Sys
    text?: Maybe<Scalars['String']>
    textPosition?: Maybe<Scalars['String']>
  }

/** Sticky header with two links. [See type definition](https://app.contentful.com/spaces/9cxgygtadmvl/content_types/componentStickyHeader) */
export type ComponentStickyHeaderBgColorArgs = {
  locale?: InputMaybe<Scalars['String']>
}

/** Sticky header with two links. [See type definition](https://app.contentful.com/spaces/9cxgygtadmvl/content_types/componentStickyHeader) */
export type ComponentStickyHeaderCtaTextArgs = {
  locale?: InputMaybe<Scalars['String']>
}

/** Sticky header with two links. [See type definition](https://app.contentful.com/spaces/9cxgygtadmvl/content_types/componentStickyHeader) */
export type ComponentStickyHeaderCtaUrlArgs = {
  locale?: InputMaybe<Scalars['String']>
}

/** Sticky header with two links. [See type definition](https://app.contentful.com/spaces/9cxgygtadmvl/content_types/componentStickyHeader) */
export type ComponentStickyHeaderIconArgs = {
  locale?: InputMaybe<Scalars['String']>
  preview?: InputMaybe<Scalars['Boolean']>
}

/** Sticky header with two links. [See type definition](https://app.contentful.com/spaces/9cxgygtadmvl/content_types/componentStickyHeader) */
export type ComponentStickyHeaderInternalLabelArgs = {
  locale?: InputMaybe<Scalars['String']>
}

/** Sticky header with two links. [See type definition](https://app.contentful.com/spaces/9cxgygtadmvl/content_types/componentStickyHeader) */
export type ComponentStickyHeaderLeftLinkArgs = {
  locale?: InputMaybe<Scalars['String']>
}

/** Sticky header with two links. [See type definition](https://app.contentful.com/spaces/9cxgygtadmvl/content_types/componentStickyHeader) */
export type ComponentStickyHeaderLinkedFromArgs = {
  allowedLocales?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
}

/** Sticky header with two links. [See type definition](https://app.contentful.com/spaces/9cxgygtadmvl/content_types/componentStickyHeader) */
export type ComponentStickyHeaderTextArgs = {
  locale?: InputMaybe<Scalars['String']>
}

/** Sticky header with two links. [See type definition](https://app.contentful.com/spaces/9cxgygtadmvl/content_types/componentStickyHeader) */
export type ComponentStickyHeaderTextPositionArgs = {
  locale?: InputMaybe<Scalars['String']>
}

export type ComponentStickyHeaderCollection = {
  __typename?: 'ComponentStickyHeaderCollection'
  items: Array<Maybe<ComponentStickyHeader>>
  limit: Scalars['Int']
  skip: Scalars['Int']
  total: Scalars['Int']
}

export type ComponentStickyHeaderFilter = {
  AND?: InputMaybe<Array<InputMaybe<ComponentStickyHeaderFilter>>>
  OR?: InputMaybe<Array<InputMaybe<ComponentStickyHeaderFilter>>>
  bgColor?: InputMaybe<Scalars['String']>
  bgColor_contains?: InputMaybe<Scalars['String']>
  bgColor_exists?: InputMaybe<Scalars['Boolean']>
  bgColor_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  bgColor_not?: InputMaybe<Scalars['String']>
  bgColor_not_contains?: InputMaybe<Scalars['String']>
  bgColor_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  contentfulMetadata?: InputMaybe<ContentfulMetadataFilter>
  ctaText?: InputMaybe<Scalars['String']>
  ctaText_contains?: InputMaybe<Scalars['String']>
  ctaText_exists?: InputMaybe<Scalars['Boolean']>
  ctaText_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  ctaText_not?: InputMaybe<Scalars['String']>
  ctaText_not_contains?: InputMaybe<Scalars['String']>
  ctaText_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  ctaUrl?: InputMaybe<Scalars['String']>
  ctaUrl_contains?: InputMaybe<Scalars['String']>
  ctaUrl_exists?: InputMaybe<Scalars['Boolean']>
  ctaUrl_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  ctaUrl_not?: InputMaybe<Scalars['String']>
  ctaUrl_not_contains?: InputMaybe<Scalars['String']>
  ctaUrl_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  icon_exists?: InputMaybe<Scalars['Boolean']>
  internalLabel?: InputMaybe<Scalars['String']>
  internalLabel_contains?: InputMaybe<Scalars['String']>
  internalLabel_exists?: InputMaybe<Scalars['Boolean']>
  internalLabel_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  internalLabel_not?: InputMaybe<Scalars['String']>
  internalLabel_not_contains?: InputMaybe<Scalars['String']>
  internalLabel_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  leftLink?: InputMaybe<Scalars['String']>
  leftLink_contains?: InputMaybe<Scalars['String']>
  leftLink_exists?: InputMaybe<Scalars['Boolean']>
  leftLink_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  leftLink_not?: InputMaybe<Scalars['String']>
  leftLink_not_contains?: InputMaybe<Scalars['String']>
  leftLink_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  sys?: InputMaybe<SysFilter>
  text?: InputMaybe<Scalars['String']>
  textPosition?: InputMaybe<Scalars['String']>
  textPosition_contains?: InputMaybe<Scalars['String']>
  textPosition_exists?: InputMaybe<Scalars['Boolean']>
  textPosition_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  textPosition_not?: InputMaybe<Scalars['String']>
  textPosition_not_contains?: InputMaybe<Scalars['String']>
  textPosition_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  text_contains?: InputMaybe<Scalars['String']>
  text_exists?: InputMaybe<Scalars['Boolean']>
  text_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  text_not?: InputMaybe<Scalars['String']>
  text_not_contains?: InputMaybe<Scalars['String']>
  text_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
}

export type ComponentStickyHeaderLinkingCollections = {
  __typename?: 'ComponentStickyHeaderLinkingCollections'
  campaignPagesCollection?: Maybe<CampaignPagesCollection>
  entryCollection?: Maybe<EntryCollection>
  templateAboutUsCollection?: Maybe<TemplateAboutUsCollection>
  templateBookingCollection?: Maybe<TemplateBookingCollection>
  templateCareersCollection?: Maybe<TemplateCareersCollection>
  templateContactUsCollection?: Maybe<TemplateContactUsCollection>
  templateDestinationsCollection?: Maybe<TemplateDestinationsCollection>
  templateForaReserveCollection?: Maybe<TemplateForaReserveCollection>
  templateForaXCollection?: Maybe<TemplateForaXCollection>
  templateHomeCollection?: Maybe<TemplateHomeCollection>
  templateJoinUsCollection?: Maybe<TemplateJoinUsCollection>
  templateNewsletterCollection?: Maybe<TemplateNewsletterCollection>
  templatePartnersCollection?: Maybe<TemplatePartnersCollection>
  templateReviewsCollection?: Maybe<TemplateReviewsCollection>
  templateWhyBookWithForaCollection?: Maybe<TemplateWhyBookWithForaCollection>
}

export type ComponentStickyHeaderLinkingCollectionsCampaignPagesCollectionArgs =
  {
    limit?: InputMaybe<Scalars['Int']>
    locale?: InputMaybe<Scalars['String']>
    order?: InputMaybe<
      Array<
        InputMaybe<ComponentStickyHeaderLinkingCollectionsCampaignPagesCollectionOrder>
      >
    >
    preview?: InputMaybe<Scalars['Boolean']>
    skip?: InputMaybe<Scalars['Int']>
  }

export type ComponentStickyHeaderLinkingCollectionsEntryCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>
  locale?: InputMaybe<Scalars['String']>
  preview?: InputMaybe<Scalars['Boolean']>
  skip?: InputMaybe<Scalars['Int']>
}

export type ComponentStickyHeaderLinkingCollectionsTemplateAboutUsCollectionArgs =
  {
    limit?: InputMaybe<Scalars['Int']>
    locale?: InputMaybe<Scalars['String']>
    order?: InputMaybe<
      Array<
        InputMaybe<ComponentStickyHeaderLinkingCollectionsTemplateAboutUsCollectionOrder>
      >
    >
    preview?: InputMaybe<Scalars['Boolean']>
    skip?: InputMaybe<Scalars['Int']>
  }

export type ComponentStickyHeaderLinkingCollectionsTemplateBookingCollectionArgs =
  {
    limit?: InputMaybe<Scalars['Int']>
    locale?: InputMaybe<Scalars['String']>
    order?: InputMaybe<
      Array<
        InputMaybe<ComponentStickyHeaderLinkingCollectionsTemplateBookingCollectionOrder>
      >
    >
    preview?: InputMaybe<Scalars['Boolean']>
    skip?: InputMaybe<Scalars['Int']>
  }

export type ComponentStickyHeaderLinkingCollectionsTemplateCareersCollectionArgs =
  {
    limit?: InputMaybe<Scalars['Int']>
    locale?: InputMaybe<Scalars['String']>
    order?: InputMaybe<
      Array<
        InputMaybe<ComponentStickyHeaderLinkingCollectionsTemplateCareersCollectionOrder>
      >
    >
    preview?: InputMaybe<Scalars['Boolean']>
    skip?: InputMaybe<Scalars['Int']>
  }

export type ComponentStickyHeaderLinkingCollectionsTemplateContactUsCollectionArgs =
  {
    limit?: InputMaybe<Scalars['Int']>
    locale?: InputMaybe<Scalars['String']>
    order?: InputMaybe<
      Array<
        InputMaybe<ComponentStickyHeaderLinkingCollectionsTemplateContactUsCollectionOrder>
      >
    >
    preview?: InputMaybe<Scalars['Boolean']>
    skip?: InputMaybe<Scalars['Int']>
  }

export type ComponentStickyHeaderLinkingCollectionsTemplateDestinationsCollectionArgs =
  {
    limit?: InputMaybe<Scalars['Int']>
    locale?: InputMaybe<Scalars['String']>
    order?: InputMaybe<
      Array<
        InputMaybe<ComponentStickyHeaderLinkingCollectionsTemplateDestinationsCollectionOrder>
      >
    >
    preview?: InputMaybe<Scalars['Boolean']>
    skip?: InputMaybe<Scalars['Int']>
  }

export type ComponentStickyHeaderLinkingCollectionsTemplateForaReserveCollectionArgs =
  {
    limit?: InputMaybe<Scalars['Int']>
    locale?: InputMaybe<Scalars['String']>
    order?: InputMaybe<
      Array<
        InputMaybe<ComponentStickyHeaderLinkingCollectionsTemplateForaReserveCollectionOrder>
      >
    >
    preview?: InputMaybe<Scalars['Boolean']>
    skip?: InputMaybe<Scalars['Int']>
  }

export type ComponentStickyHeaderLinkingCollectionsTemplateForaXCollectionArgs =
  {
    limit?: InputMaybe<Scalars['Int']>
    locale?: InputMaybe<Scalars['String']>
    order?: InputMaybe<
      Array<
        InputMaybe<ComponentStickyHeaderLinkingCollectionsTemplateForaXCollectionOrder>
      >
    >
    preview?: InputMaybe<Scalars['Boolean']>
    skip?: InputMaybe<Scalars['Int']>
  }

export type ComponentStickyHeaderLinkingCollectionsTemplateHomeCollectionArgs =
  {
    limit?: InputMaybe<Scalars['Int']>
    locale?: InputMaybe<Scalars['String']>
    order?: InputMaybe<
      Array<
        InputMaybe<ComponentStickyHeaderLinkingCollectionsTemplateHomeCollectionOrder>
      >
    >
    preview?: InputMaybe<Scalars['Boolean']>
    skip?: InputMaybe<Scalars['Int']>
  }

export type ComponentStickyHeaderLinkingCollectionsTemplateJoinUsCollectionArgs =
  {
    limit?: InputMaybe<Scalars['Int']>
    locale?: InputMaybe<Scalars['String']>
    order?: InputMaybe<
      Array<
        InputMaybe<ComponentStickyHeaderLinkingCollectionsTemplateJoinUsCollectionOrder>
      >
    >
    preview?: InputMaybe<Scalars['Boolean']>
    skip?: InputMaybe<Scalars['Int']>
  }

export type ComponentStickyHeaderLinkingCollectionsTemplateNewsletterCollectionArgs =
  {
    limit?: InputMaybe<Scalars['Int']>
    locale?: InputMaybe<Scalars['String']>
    order?: InputMaybe<
      Array<
        InputMaybe<ComponentStickyHeaderLinkingCollectionsTemplateNewsletterCollectionOrder>
      >
    >
    preview?: InputMaybe<Scalars['Boolean']>
    skip?: InputMaybe<Scalars['Int']>
  }

export type ComponentStickyHeaderLinkingCollectionsTemplatePartnersCollectionArgs =
  {
    limit?: InputMaybe<Scalars['Int']>
    locale?: InputMaybe<Scalars['String']>
    order?: InputMaybe<
      Array<
        InputMaybe<ComponentStickyHeaderLinkingCollectionsTemplatePartnersCollectionOrder>
      >
    >
    preview?: InputMaybe<Scalars['Boolean']>
    skip?: InputMaybe<Scalars['Int']>
  }

export type ComponentStickyHeaderLinkingCollectionsTemplateReviewsCollectionArgs =
  {
    limit?: InputMaybe<Scalars['Int']>
    locale?: InputMaybe<Scalars['String']>
    order?: InputMaybe<
      Array<
        InputMaybe<ComponentStickyHeaderLinkingCollectionsTemplateReviewsCollectionOrder>
      >
    >
    preview?: InputMaybe<Scalars['Boolean']>
    skip?: InputMaybe<Scalars['Int']>
  }

export type ComponentStickyHeaderLinkingCollectionsTemplateWhyBookWithForaCollectionArgs =
  {
    limit?: InputMaybe<Scalars['Int']>
    locale?: InputMaybe<Scalars['String']>
    order?: InputMaybe<
      Array<
        InputMaybe<ComponentStickyHeaderLinkingCollectionsTemplateWhyBookWithForaCollectionOrder>
      >
    >
    preview?: InputMaybe<Scalars['Boolean']>
    skip?: InputMaybe<Scalars['Int']>
  }

export enum ComponentStickyHeaderLinkingCollectionsCampaignPagesCollectionOrder {
  CollapseFooterAsc = 'collapseFooter_ASC',
  CollapseFooterDesc = 'collapseFooter_DESC',
  CollapseHeaderAsc = 'collapseHeader_ASC',
  CollapseHeaderDesc = 'collapseHeader_DESC',
  HideFooterAsc = 'hideFooter_ASC',
  HideFooterDesc = 'hideFooter_DESC',
  HideGlobalBannerAsc = 'hideGlobalBanner_ASC',
  HideGlobalBannerDesc = 'hideGlobalBanner_DESC',
  JotformIdAsc = 'jotformId_ASC',
  JotformIdDesc = 'jotformId_DESC',
  NoindexNofollowAsc = 'noindexNofollow_ASC',
  NoindexNofollowDesc = 'noindexNofollow_DESC',
  ShowHeaderAsc = 'showHeader_ASC',
  ShowHeaderDesc = 'showHeader_DESC',
  SlugAsc = 'slug_ASC',
  SlugDesc = 'slug_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC',
}

export enum ComponentStickyHeaderLinkingCollectionsTemplateAboutUsCollectionOrder {
  CollapseHeaderAsc = 'collapseHeader_ASC',
  CollapseHeaderDesc = 'collapseHeader_DESC',
  HeaderBlockTitleAsc = 'headerBlockTitle_ASC',
  HeaderBlockTitleDesc = 'headerBlockTitle_DESC',
  ShowHeaderAsc = 'showHeader_ASC',
  ShowHeaderDesc = 'showHeader_DESC',
  SubtitleAsc = 'subtitle_ASC',
  SubtitleDesc = 'subtitle_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC',
}

export enum ComponentStickyHeaderLinkingCollectionsTemplateBookingCollectionOrder {
  CollapseHeaderAsc = 'collapseHeader_ASC',
  CollapseHeaderDesc = 'collapseHeader_DESC',
  ShowHeaderAsc = 'showHeader_ASC',
  ShowHeaderDesc = 'showHeader_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC',
}

export enum ComponentStickyHeaderLinkingCollectionsTemplateCareersCollectionOrder {
  CollapseHeaderAsc = 'collapseHeader_ASC',
  CollapseHeaderDesc = 'collapseHeader_DESC',
  ShowHeaderAsc = 'showHeader_ASC',
  ShowHeaderDesc = 'showHeader_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC',
}

export enum ComponentStickyHeaderLinkingCollectionsTemplateContactUsCollectionOrder {
  CollapseHeaderAsc = 'collapseHeader_ASC',
  CollapseHeaderDesc = 'collapseHeader_DESC',
  ShowHeaderAsc = 'showHeader_ASC',
  ShowHeaderDesc = 'showHeader_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC',
}

export enum ComponentStickyHeaderLinkingCollectionsTemplateDestinationsCollectionOrder {
  CollapseHeaderAsc = 'collapseHeader_ASC',
  CollapseHeaderDesc = 'collapseHeader_DESC',
  CollapsibleHeadingAsc = 'collapsibleHeading_ASC',
  CollapsibleHeadingDesc = 'collapsibleHeading_DESC',
  ShowHeaderAsc = 'showHeader_ASC',
  ShowHeaderDesc = 'showHeader_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC',
}

export enum ComponentStickyHeaderLinkingCollectionsTemplateForaReserveCollectionOrder {
  CollapseHeaderAsc = 'collapseHeader_ASC',
  CollapseHeaderDesc = 'collapseHeader_DESC',
  ShowHeaderAsc = 'showHeader_ASC',
  ShowHeaderDesc = 'showHeader_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC',
}

export enum ComponentStickyHeaderLinkingCollectionsTemplateForaXCollectionOrder {
  AdvisorGridHeaderAsc = 'advisorGridHeader_ASC',
  AdvisorGridHeaderDesc = 'advisorGridHeader_DESC',
  AdvisorGridSubCopyAsc = 'advisorGridSubCopy_ASC',
  AdvisorGridSubCopyDesc = 'advisorGridSubCopy_DESC',
  CollapseHeaderAsc = 'collapseHeader_ASC',
  CollapseHeaderDesc = 'collapseHeader_DESC',
  ShowHeaderAsc = 'showHeader_ASC',
  ShowHeaderDesc = 'showHeader_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC',
}

export enum ComponentStickyHeaderLinkingCollectionsTemplateHomeCollectionOrder {
  CollapseHeaderAsc = 'collapseHeader_ASC',
  CollapseHeaderDesc = 'collapseHeader_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC',
}

export enum ComponentStickyHeaderLinkingCollectionsTemplateJoinUsCollectionOrder {
  CollapseHeaderAsc = 'collapseHeader_ASC',
  CollapseHeaderDesc = 'collapseHeader_DESC',
  HideGlobalBannerAsc = 'hideGlobalBanner_ASC',
  HideGlobalBannerDesc = 'hideGlobalBanner_DESC',
  ShowHeaderAsc = 'showHeader_ASC',
  ShowHeaderDesc = 'showHeader_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC',
}

export enum ComponentStickyHeaderLinkingCollectionsTemplateNewsletterCollectionOrder {
  CollapseHeaderAsc = 'collapseHeader_ASC',
  CollapseHeaderDesc = 'collapseHeader_DESC',
  ShowHeaderAsc = 'showHeader_ASC',
  ShowHeaderDesc = 'showHeader_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC',
}

export enum ComponentStickyHeaderLinkingCollectionsTemplatePartnersCollectionOrder {
  CollapseHeaderAsc = 'collapseHeader_ASC',
  CollapseHeaderDesc = 'collapseHeader_DESC',
  ShowHeaderAsc = 'showHeader_ASC',
  ShowHeaderDesc = 'showHeader_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC',
}

export enum ComponentStickyHeaderLinkingCollectionsTemplateReviewsCollectionOrder {
  CollapseHeaderAsc = 'collapseHeader_ASC',
  CollapseHeaderDesc = 'collapseHeader_DESC',
  ShowHeaderAsc = 'showHeader_ASC',
  ShowHeaderDesc = 'showHeader_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC',
}

export enum ComponentStickyHeaderLinkingCollectionsTemplateWhyBookWithForaCollectionOrder {
  CollapseHeaderAsc = 'collapseHeader_ASC',
  CollapseHeaderDesc = 'collapseHeader_DESC',
  ShowHeaderAsc = 'showHeader_ASC',
  ShowHeaderDesc = 'showHeader_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC',
}

export enum ComponentStickyHeaderOrder {
  BgColorAsc = 'bgColor_ASC',
  BgColorDesc = 'bgColor_DESC',
  CtaTextAsc = 'ctaText_ASC',
  CtaTextDesc = 'ctaText_DESC',
  CtaUrlAsc = 'ctaUrl_ASC',
  CtaUrlDesc = 'ctaUrl_DESC',
  InternalLabelAsc = 'internalLabel_ASC',
  InternalLabelDesc = 'internalLabel_DESC',
  LeftLinkAsc = 'leftLink_ASC',
  LeftLinkDesc = 'leftLink_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  TextPositionAsc = 'textPosition_ASC',
  TextPositionDesc = 'textPosition_DESC',
  TextAsc = 'text_ASC',
  TextDesc = 'text_DESC',
}

/** A row of in-page links to sections that can be added to a page, which sticks to the page top when scrolled past. [See type definition](https://app.contentful.com/spaces/9cxgygtadmvl/content_types/componentSubNavBar) */
export type ComponentSubNavBar = Entry &
  _Node & {
    __typename?: 'ComponentSubNavBar'
    _id: Scalars['ID']
    analyticsModule?: Maybe<SettingsAnalyticsModule>
    contentfulMetadata: ContentfulMetadata
    internalLabel?: Maybe<Scalars['String']>
    linkedFrom?: Maybe<ComponentSubNavBarLinkingCollections>
    navItemsCollection?: Maybe<ComponentSubNavBarNavItemsCollection>
    sys: Sys
  }

/** A row of in-page links to sections that can be added to a page, which sticks to the page top when scrolled past. [See type definition](https://app.contentful.com/spaces/9cxgygtadmvl/content_types/componentSubNavBar) */
export type ComponentSubNavBarAnalyticsModuleArgs = {
  locale?: InputMaybe<Scalars['String']>
  preview?: InputMaybe<Scalars['Boolean']>
  where?: InputMaybe<SettingsAnalyticsModuleFilter>
}

/** A row of in-page links to sections that can be added to a page, which sticks to the page top when scrolled past. [See type definition](https://app.contentful.com/spaces/9cxgygtadmvl/content_types/componentSubNavBar) */
export type ComponentSubNavBarInternalLabelArgs = {
  locale?: InputMaybe<Scalars['String']>
}

/** A row of in-page links to sections that can be added to a page, which sticks to the page top when scrolled past. [See type definition](https://app.contentful.com/spaces/9cxgygtadmvl/content_types/componentSubNavBar) */
export type ComponentSubNavBarLinkedFromArgs = {
  allowedLocales?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
}

/** A row of in-page links to sections that can be added to a page, which sticks to the page top when scrolled past. [See type definition](https://app.contentful.com/spaces/9cxgygtadmvl/content_types/componentSubNavBar) */
export type ComponentSubNavBarNavItemsCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>
  locale?: InputMaybe<Scalars['String']>
  order?: InputMaybe<
    Array<InputMaybe<ComponentSubNavBarNavItemsCollectionOrder>>
  >
  preview?: InputMaybe<Scalars['Boolean']>
  skip?: InputMaybe<Scalars['Int']>
  where?: InputMaybe<ComponentSubNavItemFilter>
}

export type ComponentSubNavBarCollection = {
  __typename?: 'ComponentSubNavBarCollection'
  items: Array<Maybe<ComponentSubNavBar>>
  limit: Scalars['Int']
  skip: Scalars['Int']
  total: Scalars['Int']
}

export type ComponentSubNavBarFilter = {
  AND?: InputMaybe<Array<InputMaybe<ComponentSubNavBarFilter>>>
  OR?: InputMaybe<Array<InputMaybe<ComponentSubNavBarFilter>>>
  analyticsModule?: InputMaybe<CfSettingsAnalyticsModuleNestedFilter>
  analyticsModule_exists?: InputMaybe<Scalars['Boolean']>
  contentfulMetadata?: InputMaybe<ContentfulMetadataFilter>
  internalLabel?: InputMaybe<Scalars['String']>
  internalLabel_contains?: InputMaybe<Scalars['String']>
  internalLabel_exists?: InputMaybe<Scalars['Boolean']>
  internalLabel_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  internalLabel_not?: InputMaybe<Scalars['String']>
  internalLabel_not_contains?: InputMaybe<Scalars['String']>
  internalLabel_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  navItems?: InputMaybe<CfComponentSubNavItemNestedFilter>
  navItemsCollection_exists?: InputMaybe<Scalars['Boolean']>
  sys?: InputMaybe<SysFilter>
}

export type ComponentSubNavBarLinkingCollections = {
  __typename?: 'ComponentSubNavBarLinkingCollections'
  campaignPagesCollection?: Maybe<CampaignPagesCollection>
  entryCollection?: Maybe<EntryCollection>
  templateAboutUsCollection?: Maybe<TemplateAboutUsCollection>
  templateBookingCollection?: Maybe<TemplateBookingCollection>
  templateCareersCollection?: Maybe<TemplateCareersCollection>
  templateContactUsCollection?: Maybe<TemplateContactUsCollection>
  templateDestinationsCollection?: Maybe<TemplateDestinationsCollection>
  templateForaReserveCollection?: Maybe<TemplateForaReserveCollection>
  templateForaXCollection?: Maybe<TemplateForaXCollection>
  templateHomeCollection?: Maybe<TemplateHomeCollection>
  templateJoinUsCollection?: Maybe<TemplateJoinUsCollection>
  templateNewsletterCollection?: Maybe<TemplateNewsletterCollection>
  templatePageCollection?: Maybe<TemplatePageCollection>
  templatePartnersCollection?: Maybe<TemplatePartnersCollection>
  templateReviewsCollection?: Maybe<TemplateReviewsCollection>
  templateWhyBookWithForaCollection?: Maybe<TemplateWhyBookWithForaCollection>
}

export type ComponentSubNavBarLinkingCollectionsCampaignPagesCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>
  locale?: InputMaybe<Scalars['String']>
  order?: InputMaybe<
    Array<
      InputMaybe<ComponentSubNavBarLinkingCollectionsCampaignPagesCollectionOrder>
    >
  >
  preview?: InputMaybe<Scalars['Boolean']>
  skip?: InputMaybe<Scalars['Int']>
}

export type ComponentSubNavBarLinkingCollectionsEntryCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>
  locale?: InputMaybe<Scalars['String']>
  preview?: InputMaybe<Scalars['Boolean']>
  skip?: InputMaybe<Scalars['Int']>
}

export type ComponentSubNavBarLinkingCollectionsTemplateAboutUsCollectionArgs =
  {
    limit?: InputMaybe<Scalars['Int']>
    locale?: InputMaybe<Scalars['String']>
    order?: InputMaybe<
      Array<
        InputMaybe<ComponentSubNavBarLinkingCollectionsTemplateAboutUsCollectionOrder>
      >
    >
    preview?: InputMaybe<Scalars['Boolean']>
    skip?: InputMaybe<Scalars['Int']>
  }

export type ComponentSubNavBarLinkingCollectionsTemplateBookingCollectionArgs =
  {
    limit?: InputMaybe<Scalars['Int']>
    locale?: InputMaybe<Scalars['String']>
    order?: InputMaybe<
      Array<
        InputMaybe<ComponentSubNavBarLinkingCollectionsTemplateBookingCollectionOrder>
      >
    >
    preview?: InputMaybe<Scalars['Boolean']>
    skip?: InputMaybe<Scalars['Int']>
  }

export type ComponentSubNavBarLinkingCollectionsTemplateCareersCollectionArgs =
  {
    limit?: InputMaybe<Scalars['Int']>
    locale?: InputMaybe<Scalars['String']>
    order?: InputMaybe<
      Array<
        InputMaybe<ComponentSubNavBarLinkingCollectionsTemplateCareersCollectionOrder>
      >
    >
    preview?: InputMaybe<Scalars['Boolean']>
    skip?: InputMaybe<Scalars['Int']>
  }

export type ComponentSubNavBarLinkingCollectionsTemplateContactUsCollectionArgs =
  {
    limit?: InputMaybe<Scalars['Int']>
    locale?: InputMaybe<Scalars['String']>
    order?: InputMaybe<
      Array<
        InputMaybe<ComponentSubNavBarLinkingCollectionsTemplateContactUsCollectionOrder>
      >
    >
    preview?: InputMaybe<Scalars['Boolean']>
    skip?: InputMaybe<Scalars['Int']>
  }

export type ComponentSubNavBarLinkingCollectionsTemplateDestinationsCollectionArgs =
  {
    limit?: InputMaybe<Scalars['Int']>
    locale?: InputMaybe<Scalars['String']>
    order?: InputMaybe<
      Array<
        InputMaybe<ComponentSubNavBarLinkingCollectionsTemplateDestinationsCollectionOrder>
      >
    >
    preview?: InputMaybe<Scalars['Boolean']>
    skip?: InputMaybe<Scalars['Int']>
  }

export type ComponentSubNavBarLinkingCollectionsTemplateForaReserveCollectionArgs =
  {
    limit?: InputMaybe<Scalars['Int']>
    locale?: InputMaybe<Scalars['String']>
    order?: InputMaybe<
      Array<
        InputMaybe<ComponentSubNavBarLinkingCollectionsTemplateForaReserveCollectionOrder>
      >
    >
    preview?: InputMaybe<Scalars['Boolean']>
    skip?: InputMaybe<Scalars['Int']>
  }

export type ComponentSubNavBarLinkingCollectionsTemplateForaXCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>
  locale?: InputMaybe<Scalars['String']>
  order?: InputMaybe<
    Array<
      InputMaybe<ComponentSubNavBarLinkingCollectionsTemplateForaXCollectionOrder>
    >
  >
  preview?: InputMaybe<Scalars['Boolean']>
  skip?: InputMaybe<Scalars['Int']>
}

export type ComponentSubNavBarLinkingCollectionsTemplateHomeCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>
  locale?: InputMaybe<Scalars['String']>
  order?: InputMaybe<
    Array<
      InputMaybe<ComponentSubNavBarLinkingCollectionsTemplateHomeCollectionOrder>
    >
  >
  preview?: InputMaybe<Scalars['Boolean']>
  skip?: InputMaybe<Scalars['Int']>
}

export type ComponentSubNavBarLinkingCollectionsTemplateJoinUsCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>
  locale?: InputMaybe<Scalars['String']>
  order?: InputMaybe<
    Array<
      InputMaybe<ComponentSubNavBarLinkingCollectionsTemplateJoinUsCollectionOrder>
    >
  >
  preview?: InputMaybe<Scalars['Boolean']>
  skip?: InputMaybe<Scalars['Int']>
}

export type ComponentSubNavBarLinkingCollectionsTemplateNewsletterCollectionArgs =
  {
    limit?: InputMaybe<Scalars['Int']>
    locale?: InputMaybe<Scalars['String']>
    order?: InputMaybe<
      Array<
        InputMaybe<ComponentSubNavBarLinkingCollectionsTemplateNewsletterCollectionOrder>
      >
    >
    preview?: InputMaybe<Scalars['Boolean']>
    skip?: InputMaybe<Scalars['Int']>
  }

export type ComponentSubNavBarLinkingCollectionsTemplatePageCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>
  locale?: InputMaybe<Scalars['String']>
  order?: InputMaybe<
    Array<
      InputMaybe<ComponentSubNavBarLinkingCollectionsTemplatePageCollectionOrder>
    >
  >
  preview?: InputMaybe<Scalars['Boolean']>
  skip?: InputMaybe<Scalars['Int']>
}

export type ComponentSubNavBarLinkingCollectionsTemplatePartnersCollectionArgs =
  {
    limit?: InputMaybe<Scalars['Int']>
    locale?: InputMaybe<Scalars['String']>
    order?: InputMaybe<
      Array<
        InputMaybe<ComponentSubNavBarLinkingCollectionsTemplatePartnersCollectionOrder>
      >
    >
    preview?: InputMaybe<Scalars['Boolean']>
    skip?: InputMaybe<Scalars['Int']>
  }

export type ComponentSubNavBarLinkingCollectionsTemplateReviewsCollectionArgs =
  {
    limit?: InputMaybe<Scalars['Int']>
    locale?: InputMaybe<Scalars['String']>
    order?: InputMaybe<
      Array<
        InputMaybe<ComponentSubNavBarLinkingCollectionsTemplateReviewsCollectionOrder>
      >
    >
    preview?: InputMaybe<Scalars['Boolean']>
    skip?: InputMaybe<Scalars['Int']>
  }

export type ComponentSubNavBarLinkingCollectionsTemplateWhyBookWithForaCollectionArgs =
  {
    limit?: InputMaybe<Scalars['Int']>
    locale?: InputMaybe<Scalars['String']>
    order?: InputMaybe<
      Array<
        InputMaybe<ComponentSubNavBarLinkingCollectionsTemplateWhyBookWithForaCollectionOrder>
      >
    >
    preview?: InputMaybe<Scalars['Boolean']>
    skip?: InputMaybe<Scalars['Int']>
  }

export enum ComponentSubNavBarLinkingCollectionsCampaignPagesCollectionOrder {
  CollapseFooterAsc = 'collapseFooter_ASC',
  CollapseFooterDesc = 'collapseFooter_DESC',
  CollapseHeaderAsc = 'collapseHeader_ASC',
  CollapseHeaderDesc = 'collapseHeader_DESC',
  HideFooterAsc = 'hideFooter_ASC',
  HideFooterDesc = 'hideFooter_DESC',
  HideGlobalBannerAsc = 'hideGlobalBanner_ASC',
  HideGlobalBannerDesc = 'hideGlobalBanner_DESC',
  JotformIdAsc = 'jotformId_ASC',
  JotformIdDesc = 'jotformId_DESC',
  NoindexNofollowAsc = 'noindexNofollow_ASC',
  NoindexNofollowDesc = 'noindexNofollow_DESC',
  ShowHeaderAsc = 'showHeader_ASC',
  ShowHeaderDesc = 'showHeader_DESC',
  SlugAsc = 'slug_ASC',
  SlugDesc = 'slug_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC',
}

export enum ComponentSubNavBarLinkingCollectionsTemplateAboutUsCollectionOrder {
  CollapseHeaderAsc = 'collapseHeader_ASC',
  CollapseHeaderDesc = 'collapseHeader_DESC',
  HeaderBlockTitleAsc = 'headerBlockTitle_ASC',
  HeaderBlockTitleDesc = 'headerBlockTitle_DESC',
  ShowHeaderAsc = 'showHeader_ASC',
  ShowHeaderDesc = 'showHeader_DESC',
  SubtitleAsc = 'subtitle_ASC',
  SubtitleDesc = 'subtitle_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC',
}

export enum ComponentSubNavBarLinkingCollectionsTemplateBookingCollectionOrder {
  CollapseHeaderAsc = 'collapseHeader_ASC',
  CollapseHeaderDesc = 'collapseHeader_DESC',
  ShowHeaderAsc = 'showHeader_ASC',
  ShowHeaderDesc = 'showHeader_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC',
}

export enum ComponentSubNavBarLinkingCollectionsTemplateCareersCollectionOrder {
  CollapseHeaderAsc = 'collapseHeader_ASC',
  CollapseHeaderDesc = 'collapseHeader_DESC',
  ShowHeaderAsc = 'showHeader_ASC',
  ShowHeaderDesc = 'showHeader_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC',
}

export enum ComponentSubNavBarLinkingCollectionsTemplateContactUsCollectionOrder {
  CollapseHeaderAsc = 'collapseHeader_ASC',
  CollapseHeaderDesc = 'collapseHeader_DESC',
  ShowHeaderAsc = 'showHeader_ASC',
  ShowHeaderDesc = 'showHeader_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC',
}

export enum ComponentSubNavBarLinkingCollectionsTemplateDestinationsCollectionOrder {
  CollapseHeaderAsc = 'collapseHeader_ASC',
  CollapseHeaderDesc = 'collapseHeader_DESC',
  CollapsibleHeadingAsc = 'collapsibleHeading_ASC',
  CollapsibleHeadingDesc = 'collapsibleHeading_DESC',
  ShowHeaderAsc = 'showHeader_ASC',
  ShowHeaderDesc = 'showHeader_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC',
}

export enum ComponentSubNavBarLinkingCollectionsTemplateForaReserveCollectionOrder {
  CollapseHeaderAsc = 'collapseHeader_ASC',
  CollapseHeaderDesc = 'collapseHeader_DESC',
  ShowHeaderAsc = 'showHeader_ASC',
  ShowHeaderDesc = 'showHeader_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC',
}

export enum ComponentSubNavBarLinkingCollectionsTemplateForaXCollectionOrder {
  AdvisorGridHeaderAsc = 'advisorGridHeader_ASC',
  AdvisorGridHeaderDesc = 'advisorGridHeader_DESC',
  AdvisorGridSubCopyAsc = 'advisorGridSubCopy_ASC',
  AdvisorGridSubCopyDesc = 'advisorGridSubCopy_DESC',
  CollapseHeaderAsc = 'collapseHeader_ASC',
  CollapseHeaderDesc = 'collapseHeader_DESC',
  ShowHeaderAsc = 'showHeader_ASC',
  ShowHeaderDesc = 'showHeader_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC',
}

export enum ComponentSubNavBarLinkingCollectionsTemplateHomeCollectionOrder {
  CollapseHeaderAsc = 'collapseHeader_ASC',
  CollapseHeaderDesc = 'collapseHeader_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC',
}

export enum ComponentSubNavBarLinkingCollectionsTemplateJoinUsCollectionOrder {
  CollapseHeaderAsc = 'collapseHeader_ASC',
  CollapseHeaderDesc = 'collapseHeader_DESC',
  HideGlobalBannerAsc = 'hideGlobalBanner_ASC',
  HideGlobalBannerDesc = 'hideGlobalBanner_DESC',
  ShowHeaderAsc = 'showHeader_ASC',
  ShowHeaderDesc = 'showHeader_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC',
}

export enum ComponentSubNavBarLinkingCollectionsTemplateNewsletterCollectionOrder {
  CollapseHeaderAsc = 'collapseHeader_ASC',
  CollapseHeaderDesc = 'collapseHeader_DESC',
  ShowHeaderAsc = 'showHeader_ASC',
  ShowHeaderDesc = 'showHeader_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC',
}

export enum ComponentSubNavBarLinkingCollectionsTemplatePageCollectionOrder {
  DirectoryAsc = 'directory_ASC',
  DirectoryDesc = 'directory_DESC',
  InternalLabelAsc = 'internalLabel_ASC',
  InternalLabelDesc = 'internalLabel_DESC',
  MetaTitleAsc = 'metaTitle_ASC',
  MetaTitleDesc = 'metaTitle_DESC',
  NoindexNofollowAsc = 'noindexNofollow_ASC',
  NoindexNofollowDesc = 'noindexNofollow_DESC',
  SlugAsc = 'slug_ASC',
  SlugDesc = 'slug_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
}

export enum ComponentSubNavBarLinkingCollectionsTemplatePartnersCollectionOrder {
  CollapseHeaderAsc = 'collapseHeader_ASC',
  CollapseHeaderDesc = 'collapseHeader_DESC',
  ShowHeaderAsc = 'showHeader_ASC',
  ShowHeaderDesc = 'showHeader_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC',
}

export enum ComponentSubNavBarLinkingCollectionsTemplateReviewsCollectionOrder {
  CollapseHeaderAsc = 'collapseHeader_ASC',
  CollapseHeaderDesc = 'collapseHeader_DESC',
  ShowHeaderAsc = 'showHeader_ASC',
  ShowHeaderDesc = 'showHeader_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC',
}

export enum ComponentSubNavBarLinkingCollectionsTemplateWhyBookWithForaCollectionOrder {
  CollapseHeaderAsc = 'collapseHeader_ASC',
  CollapseHeaderDesc = 'collapseHeader_DESC',
  ShowHeaderAsc = 'showHeader_ASC',
  ShowHeaderDesc = 'showHeader_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC',
}

export type ComponentSubNavBarNavItemsCollection = {
  __typename?: 'ComponentSubNavBarNavItemsCollection'
  items: Array<Maybe<ComponentSubNavItem>>
  limit: Scalars['Int']
  skip: Scalars['Int']
  total: Scalars['Int']
}

export enum ComponentSubNavBarNavItemsCollectionOrder {
  LabelAsc = 'label_ASC',
  LabelDesc = 'label_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  UrlAsc = 'url_ASC',
  UrlDesc = 'url_DESC',
}

export enum ComponentSubNavBarOrder {
  InternalLabelAsc = 'internalLabel_ASC',
  InternalLabelDesc = 'internalLabel_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
}

/** A link item within a Component: SubNav [See type definition](https://app.contentful.com/spaces/9cxgygtadmvl/content_types/componentSubNavItem) */
export type ComponentSubNavItem = Entry &
  _Node & {
    __typename?: 'ComponentSubNavItem'
    _id: Scalars['ID']
    analyticsModule?: Maybe<SettingsAnalyticsModule>
    contentfulMetadata: ContentfulMetadata
    label?: Maybe<Scalars['String']>
    linkedFrom?: Maybe<ComponentSubNavItemLinkingCollections>
    sys: Sys
    url?: Maybe<Scalars['String']>
  }

/** A link item within a Component: SubNav [See type definition](https://app.contentful.com/spaces/9cxgygtadmvl/content_types/componentSubNavItem) */
export type ComponentSubNavItemAnalyticsModuleArgs = {
  locale?: InputMaybe<Scalars['String']>
  preview?: InputMaybe<Scalars['Boolean']>
  where?: InputMaybe<SettingsAnalyticsModuleFilter>
}

/** A link item within a Component: SubNav [See type definition](https://app.contentful.com/spaces/9cxgygtadmvl/content_types/componentSubNavItem) */
export type ComponentSubNavItemLabelArgs = {
  locale?: InputMaybe<Scalars['String']>
}

/** A link item within a Component: SubNav [See type definition](https://app.contentful.com/spaces/9cxgygtadmvl/content_types/componentSubNavItem) */
export type ComponentSubNavItemLinkedFromArgs = {
  allowedLocales?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
}

/** A link item within a Component: SubNav [See type definition](https://app.contentful.com/spaces/9cxgygtadmvl/content_types/componentSubNavItem) */
export type ComponentSubNavItemUrlArgs = {
  locale?: InputMaybe<Scalars['String']>
}

export type ComponentSubNavItemCollection = {
  __typename?: 'ComponentSubNavItemCollection'
  items: Array<Maybe<ComponentSubNavItem>>
  limit: Scalars['Int']
  skip: Scalars['Int']
  total: Scalars['Int']
}

export type ComponentSubNavItemFilter = {
  AND?: InputMaybe<Array<InputMaybe<ComponentSubNavItemFilter>>>
  OR?: InputMaybe<Array<InputMaybe<ComponentSubNavItemFilter>>>
  analyticsModule?: InputMaybe<CfSettingsAnalyticsModuleNestedFilter>
  analyticsModule_exists?: InputMaybe<Scalars['Boolean']>
  contentfulMetadata?: InputMaybe<ContentfulMetadataFilter>
  label?: InputMaybe<Scalars['String']>
  label_contains?: InputMaybe<Scalars['String']>
  label_exists?: InputMaybe<Scalars['Boolean']>
  label_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  label_not?: InputMaybe<Scalars['String']>
  label_not_contains?: InputMaybe<Scalars['String']>
  label_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  sys?: InputMaybe<SysFilter>
  url?: InputMaybe<Scalars['String']>
  url_contains?: InputMaybe<Scalars['String']>
  url_exists?: InputMaybe<Scalars['Boolean']>
  url_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  url_not?: InputMaybe<Scalars['String']>
  url_not_contains?: InputMaybe<Scalars['String']>
  url_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
}

export type ComponentSubNavItemLinkingCollections = {
  __typename?: 'ComponentSubNavItemLinkingCollections'
  componentSubNavBarCollection?: Maybe<ComponentSubNavBarCollection>
  entryCollection?: Maybe<EntryCollection>
}

export type ComponentSubNavItemLinkingCollectionsComponentSubNavBarCollectionArgs =
  {
    limit?: InputMaybe<Scalars['Int']>
    locale?: InputMaybe<Scalars['String']>
    order?: InputMaybe<
      Array<
        InputMaybe<ComponentSubNavItemLinkingCollectionsComponentSubNavBarCollectionOrder>
      >
    >
    preview?: InputMaybe<Scalars['Boolean']>
    skip?: InputMaybe<Scalars['Int']>
  }

export type ComponentSubNavItemLinkingCollectionsEntryCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>
  locale?: InputMaybe<Scalars['String']>
  preview?: InputMaybe<Scalars['Boolean']>
  skip?: InputMaybe<Scalars['Int']>
}

export enum ComponentSubNavItemLinkingCollectionsComponentSubNavBarCollectionOrder {
  InternalLabelAsc = 'internalLabel_ASC',
  InternalLabelDesc = 'internalLabel_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
}

export enum ComponentSubNavItemOrder {
  LabelAsc = 'label_ASC',
  LabelDesc = 'label_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  UrlAsc = 'url_ASC',
  UrlDesc = 'url_DESC',
}

/** A card which displays a subscription price and discount for a product offering [See type definition](https://app.contentful.com/spaces/9cxgygtadmvl/content_types/componentSubscriptionCard) */
export type ComponentSubscriptionCard = Entry &
  _Node & {
    __typename?: 'ComponentSubscriptionCard'
    _id: Scalars['ID']
    analyticsModule?: Maybe<SettingsAnalyticsModule>
    annualDiscountPrice?: Maybe<Scalars['Float']>
    annualFeaturedText?: Maybe<Scalars['String']>
    annualIsLimited?: Maybe<Scalars['Boolean']>
    annualOriginalPrice?: Maybe<Scalars['Float']>
    annualPriceDetails?: Maybe<Scalars['String']>
    bulletPoints?: Maybe<Array<Maybe<Scalars['String']>>>
    contentfulMetadata: ContentfulMetadata
    ctaText?: Maybe<Scalars['String']>
    ctaUrl?: Maybe<Scalars['String']>
    heading?: Maybe<Scalars['String']>
    internalLabel?: Maybe<Scalars['String']>
    linkedFrom?: Maybe<ComponentSubscriptionCardLinkingCollections>
    monthlyDiscountPrice?: Maybe<Scalars['Float']>
    monthlyFeaturedText?: Maybe<Scalars['String']>
    monthlyIsLimited?: Maybe<Scalars['Boolean']>
    monthlyOriginalPrice?: Maybe<Scalars['Float']>
    monthlyPriceDetails?: Maybe<Scalars['String']>
    sectionId?: Maybe<Scalars['String']>
    showMultiplePricingModels?: Maybe<Scalars['Boolean']>
    subCopy?: Maybe<ComponentSubscriptionCardSubCopy>
    sys: Sys
    teaserCopy?: Maybe<ComponentSubscriptionCardTeaserCopy>
    textOnRight?: Maybe<Scalars['Boolean']>
    titleStyle?: Maybe<Scalars['String']>
    urlTarget?: Maybe<Scalars['String']>
  }

/** A card which displays a subscription price and discount for a product offering [See type definition](https://app.contentful.com/spaces/9cxgygtadmvl/content_types/componentSubscriptionCard) */
export type ComponentSubscriptionCardAnalyticsModuleArgs = {
  locale?: InputMaybe<Scalars['String']>
  preview?: InputMaybe<Scalars['Boolean']>
  where?: InputMaybe<SettingsAnalyticsModuleFilter>
}

/** A card which displays a subscription price and discount for a product offering [See type definition](https://app.contentful.com/spaces/9cxgygtadmvl/content_types/componentSubscriptionCard) */
export type ComponentSubscriptionCardAnnualDiscountPriceArgs = {
  locale?: InputMaybe<Scalars['String']>
}

/** A card which displays a subscription price and discount for a product offering [See type definition](https://app.contentful.com/spaces/9cxgygtadmvl/content_types/componentSubscriptionCard) */
export type ComponentSubscriptionCardAnnualFeaturedTextArgs = {
  locale?: InputMaybe<Scalars['String']>
}

/** A card which displays a subscription price and discount for a product offering [See type definition](https://app.contentful.com/spaces/9cxgygtadmvl/content_types/componentSubscriptionCard) */
export type ComponentSubscriptionCardAnnualIsLimitedArgs = {
  locale?: InputMaybe<Scalars['String']>
}

/** A card which displays a subscription price and discount for a product offering [See type definition](https://app.contentful.com/spaces/9cxgygtadmvl/content_types/componentSubscriptionCard) */
export type ComponentSubscriptionCardAnnualOriginalPriceArgs = {
  locale?: InputMaybe<Scalars['String']>
}

/** A card which displays a subscription price and discount for a product offering [See type definition](https://app.contentful.com/spaces/9cxgygtadmvl/content_types/componentSubscriptionCard) */
export type ComponentSubscriptionCardAnnualPriceDetailsArgs = {
  locale?: InputMaybe<Scalars['String']>
}

/** A card which displays a subscription price and discount for a product offering [See type definition](https://app.contentful.com/spaces/9cxgygtadmvl/content_types/componentSubscriptionCard) */
export type ComponentSubscriptionCardBulletPointsArgs = {
  locale?: InputMaybe<Scalars['String']>
}

/** A card which displays a subscription price and discount for a product offering [See type definition](https://app.contentful.com/spaces/9cxgygtadmvl/content_types/componentSubscriptionCard) */
export type ComponentSubscriptionCardCtaTextArgs = {
  locale?: InputMaybe<Scalars['String']>
}

/** A card which displays a subscription price and discount for a product offering [See type definition](https://app.contentful.com/spaces/9cxgygtadmvl/content_types/componentSubscriptionCard) */
export type ComponentSubscriptionCardCtaUrlArgs = {
  locale?: InputMaybe<Scalars['String']>
}

/** A card which displays a subscription price and discount for a product offering [See type definition](https://app.contentful.com/spaces/9cxgygtadmvl/content_types/componentSubscriptionCard) */
export type ComponentSubscriptionCardHeadingArgs = {
  locale?: InputMaybe<Scalars['String']>
}

/** A card which displays a subscription price and discount for a product offering [See type definition](https://app.contentful.com/spaces/9cxgygtadmvl/content_types/componentSubscriptionCard) */
export type ComponentSubscriptionCardInternalLabelArgs = {
  locale?: InputMaybe<Scalars['String']>
}

/** A card which displays a subscription price and discount for a product offering [See type definition](https://app.contentful.com/spaces/9cxgygtadmvl/content_types/componentSubscriptionCard) */
export type ComponentSubscriptionCardLinkedFromArgs = {
  allowedLocales?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
}

/** A card which displays a subscription price and discount for a product offering [See type definition](https://app.contentful.com/spaces/9cxgygtadmvl/content_types/componentSubscriptionCard) */
export type ComponentSubscriptionCardMonthlyDiscountPriceArgs = {
  locale?: InputMaybe<Scalars['String']>
}

/** A card which displays a subscription price and discount for a product offering [See type definition](https://app.contentful.com/spaces/9cxgygtadmvl/content_types/componentSubscriptionCard) */
export type ComponentSubscriptionCardMonthlyFeaturedTextArgs = {
  locale?: InputMaybe<Scalars['String']>
}

/** A card which displays a subscription price and discount for a product offering [See type definition](https://app.contentful.com/spaces/9cxgygtadmvl/content_types/componentSubscriptionCard) */
export type ComponentSubscriptionCardMonthlyIsLimitedArgs = {
  locale?: InputMaybe<Scalars['String']>
}

/** A card which displays a subscription price and discount for a product offering [See type definition](https://app.contentful.com/spaces/9cxgygtadmvl/content_types/componentSubscriptionCard) */
export type ComponentSubscriptionCardMonthlyOriginalPriceArgs = {
  locale?: InputMaybe<Scalars['String']>
}

/** A card which displays a subscription price and discount for a product offering [See type definition](https://app.contentful.com/spaces/9cxgygtadmvl/content_types/componentSubscriptionCard) */
export type ComponentSubscriptionCardMonthlyPriceDetailsArgs = {
  locale?: InputMaybe<Scalars['String']>
}

/** A card which displays a subscription price and discount for a product offering [See type definition](https://app.contentful.com/spaces/9cxgygtadmvl/content_types/componentSubscriptionCard) */
export type ComponentSubscriptionCardSectionIdArgs = {
  locale?: InputMaybe<Scalars['String']>
}

/** A card which displays a subscription price and discount for a product offering [See type definition](https://app.contentful.com/spaces/9cxgygtadmvl/content_types/componentSubscriptionCard) */
export type ComponentSubscriptionCardShowMultiplePricingModelsArgs = {
  locale?: InputMaybe<Scalars['String']>
}

/** A card which displays a subscription price and discount for a product offering [See type definition](https://app.contentful.com/spaces/9cxgygtadmvl/content_types/componentSubscriptionCard) */
export type ComponentSubscriptionCardSubCopyArgs = {
  locale?: InputMaybe<Scalars['String']>
}

/** A card which displays a subscription price and discount for a product offering [See type definition](https://app.contentful.com/spaces/9cxgygtadmvl/content_types/componentSubscriptionCard) */
export type ComponentSubscriptionCardTeaserCopyArgs = {
  locale?: InputMaybe<Scalars['String']>
}

/** A card which displays a subscription price and discount for a product offering [See type definition](https://app.contentful.com/spaces/9cxgygtadmvl/content_types/componentSubscriptionCard) */
export type ComponentSubscriptionCardTextOnRightArgs = {
  locale?: InputMaybe<Scalars['String']>
}

/** A card which displays a subscription price and discount for a product offering [See type definition](https://app.contentful.com/spaces/9cxgygtadmvl/content_types/componentSubscriptionCard) */
export type ComponentSubscriptionCardTitleStyleArgs = {
  locale?: InputMaybe<Scalars['String']>
}

/** A card which displays a subscription price and discount for a product offering [See type definition](https://app.contentful.com/spaces/9cxgygtadmvl/content_types/componentSubscriptionCard) */
export type ComponentSubscriptionCardUrlTargetArgs = {
  locale?: InputMaybe<Scalars['String']>
}

export type ComponentSubscriptionCardCollection = {
  __typename?: 'ComponentSubscriptionCardCollection'
  items: Array<Maybe<ComponentSubscriptionCard>>
  limit: Scalars['Int']
  skip: Scalars['Int']
  total: Scalars['Int']
}

export type ComponentSubscriptionCardFilter = {
  AND?: InputMaybe<Array<InputMaybe<ComponentSubscriptionCardFilter>>>
  OR?: InputMaybe<Array<InputMaybe<ComponentSubscriptionCardFilter>>>
  analyticsModule?: InputMaybe<CfSettingsAnalyticsModuleNestedFilter>
  analyticsModule_exists?: InputMaybe<Scalars['Boolean']>
  annualDiscountPrice?: InputMaybe<Scalars['Float']>
  annualDiscountPrice_exists?: InputMaybe<Scalars['Boolean']>
  annualDiscountPrice_gt?: InputMaybe<Scalars['Float']>
  annualDiscountPrice_gte?: InputMaybe<Scalars['Float']>
  annualDiscountPrice_in?: InputMaybe<Array<InputMaybe<Scalars['Float']>>>
  annualDiscountPrice_lt?: InputMaybe<Scalars['Float']>
  annualDiscountPrice_lte?: InputMaybe<Scalars['Float']>
  annualDiscountPrice_not?: InputMaybe<Scalars['Float']>
  annualDiscountPrice_not_in?: InputMaybe<Array<InputMaybe<Scalars['Float']>>>
  annualFeaturedText?: InputMaybe<Scalars['String']>
  annualFeaturedText_contains?: InputMaybe<Scalars['String']>
  annualFeaturedText_exists?: InputMaybe<Scalars['Boolean']>
  annualFeaturedText_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  annualFeaturedText_not?: InputMaybe<Scalars['String']>
  annualFeaturedText_not_contains?: InputMaybe<Scalars['String']>
  annualFeaturedText_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  annualIsLimited?: InputMaybe<Scalars['Boolean']>
  annualIsLimited_exists?: InputMaybe<Scalars['Boolean']>
  annualIsLimited_not?: InputMaybe<Scalars['Boolean']>
  annualOriginalPrice?: InputMaybe<Scalars['Float']>
  annualOriginalPrice_exists?: InputMaybe<Scalars['Boolean']>
  annualOriginalPrice_gt?: InputMaybe<Scalars['Float']>
  annualOriginalPrice_gte?: InputMaybe<Scalars['Float']>
  annualOriginalPrice_in?: InputMaybe<Array<InputMaybe<Scalars['Float']>>>
  annualOriginalPrice_lt?: InputMaybe<Scalars['Float']>
  annualOriginalPrice_lte?: InputMaybe<Scalars['Float']>
  annualOriginalPrice_not?: InputMaybe<Scalars['Float']>
  annualOriginalPrice_not_in?: InputMaybe<Array<InputMaybe<Scalars['Float']>>>
  annualPriceDetails?: InputMaybe<Scalars['String']>
  annualPriceDetails_contains?: InputMaybe<Scalars['String']>
  annualPriceDetails_exists?: InputMaybe<Scalars['Boolean']>
  annualPriceDetails_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  annualPriceDetails_not?: InputMaybe<Scalars['String']>
  annualPriceDetails_not_contains?: InputMaybe<Scalars['String']>
  annualPriceDetails_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  bulletPoints_contains_all?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  bulletPoints_contains_none?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  bulletPoints_contains_some?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  bulletPoints_exists?: InputMaybe<Scalars['Boolean']>
  contentfulMetadata?: InputMaybe<ContentfulMetadataFilter>
  ctaText?: InputMaybe<Scalars['String']>
  ctaText_contains?: InputMaybe<Scalars['String']>
  ctaText_exists?: InputMaybe<Scalars['Boolean']>
  ctaText_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  ctaText_not?: InputMaybe<Scalars['String']>
  ctaText_not_contains?: InputMaybe<Scalars['String']>
  ctaText_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  ctaUrl?: InputMaybe<Scalars['String']>
  ctaUrl_contains?: InputMaybe<Scalars['String']>
  ctaUrl_exists?: InputMaybe<Scalars['Boolean']>
  ctaUrl_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  ctaUrl_not?: InputMaybe<Scalars['String']>
  ctaUrl_not_contains?: InputMaybe<Scalars['String']>
  ctaUrl_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  heading?: InputMaybe<Scalars['String']>
  heading_contains?: InputMaybe<Scalars['String']>
  heading_exists?: InputMaybe<Scalars['Boolean']>
  heading_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  heading_not?: InputMaybe<Scalars['String']>
  heading_not_contains?: InputMaybe<Scalars['String']>
  heading_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  internalLabel?: InputMaybe<Scalars['String']>
  internalLabel_contains?: InputMaybe<Scalars['String']>
  internalLabel_exists?: InputMaybe<Scalars['Boolean']>
  internalLabel_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  internalLabel_not?: InputMaybe<Scalars['String']>
  internalLabel_not_contains?: InputMaybe<Scalars['String']>
  internalLabel_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  monthlyDiscountPrice?: InputMaybe<Scalars['Float']>
  monthlyDiscountPrice_exists?: InputMaybe<Scalars['Boolean']>
  monthlyDiscountPrice_gt?: InputMaybe<Scalars['Float']>
  monthlyDiscountPrice_gte?: InputMaybe<Scalars['Float']>
  monthlyDiscountPrice_in?: InputMaybe<Array<InputMaybe<Scalars['Float']>>>
  monthlyDiscountPrice_lt?: InputMaybe<Scalars['Float']>
  monthlyDiscountPrice_lte?: InputMaybe<Scalars['Float']>
  monthlyDiscountPrice_not?: InputMaybe<Scalars['Float']>
  monthlyDiscountPrice_not_in?: InputMaybe<Array<InputMaybe<Scalars['Float']>>>
  monthlyFeaturedText?: InputMaybe<Scalars['String']>
  monthlyFeaturedText_contains?: InputMaybe<Scalars['String']>
  monthlyFeaturedText_exists?: InputMaybe<Scalars['Boolean']>
  monthlyFeaturedText_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  monthlyFeaturedText_not?: InputMaybe<Scalars['String']>
  monthlyFeaturedText_not_contains?: InputMaybe<Scalars['String']>
  monthlyFeaturedText_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  monthlyIsLimited?: InputMaybe<Scalars['Boolean']>
  monthlyIsLimited_exists?: InputMaybe<Scalars['Boolean']>
  monthlyIsLimited_not?: InputMaybe<Scalars['Boolean']>
  monthlyOriginalPrice?: InputMaybe<Scalars['Float']>
  monthlyOriginalPrice_exists?: InputMaybe<Scalars['Boolean']>
  monthlyOriginalPrice_gt?: InputMaybe<Scalars['Float']>
  monthlyOriginalPrice_gte?: InputMaybe<Scalars['Float']>
  monthlyOriginalPrice_in?: InputMaybe<Array<InputMaybe<Scalars['Float']>>>
  monthlyOriginalPrice_lt?: InputMaybe<Scalars['Float']>
  monthlyOriginalPrice_lte?: InputMaybe<Scalars['Float']>
  monthlyOriginalPrice_not?: InputMaybe<Scalars['Float']>
  monthlyOriginalPrice_not_in?: InputMaybe<Array<InputMaybe<Scalars['Float']>>>
  monthlyPriceDetails?: InputMaybe<Scalars['String']>
  monthlyPriceDetails_contains?: InputMaybe<Scalars['String']>
  monthlyPriceDetails_exists?: InputMaybe<Scalars['Boolean']>
  monthlyPriceDetails_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  monthlyPriceDetails_not?: InputMaybe<Scalars['String']>
  monthlyPriceDetails_not_contains?: InputMaybe<Scalars['String']>
  monthlyPriceDetails_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  sectionId?: InputMaybe<Scalars['String']>
  sectionId_contains?: InputMaybe<Scalars['String']>
  sectionId_exists?: InputMaybe<Scalars['Boolean']>
  sectionId_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  sectionId_not?: InputMaybe<Scalars['String']>
  sectionId_not_contains?: InputMaybe<Scalars['String']>
  sectionId_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  showMultiplePricingModels?: InputMaybe<Scalars['Boolean']>
  showMultiplePricingModels_exists?: InputMaybe<Scalars['Boolean']>
  showMultiplePricingModels_not?: InputMaybe<Scalars['Boolean']>
  subCopy_contains?: InputMaybe<Scalars['String']>
  subCopy_exists?: InputMaybe<Scalars['Boolean']>
  subCopy_not_contains?: InputMaybe<Scalars['String']>
  sys?: InputMaybe<SysFilter>
  teaserCopy_contains?: InputMaybe<Scalars['String']>
  teaserCopy_exists?: InputMaybe<Scalars['Boolean']>
  teaserCopy_not_contains?: InputMaybe<Scalars['String']>
  textOnRight?: InputMaybe<Scalars['Boolean']>
  textOnRight_exists?: InputMaybe<Scalars['Boolean']>
  textOnRight_not?: InputMaybe<Scalars['Boolean']>
  titleStyle?: InputMaybe<Scalars['String']>
  titleStyle_contains?: InputMaybe<Scalars['String']>
  titleStyle_exists?: InputMaybe<Scalars['Boolean']>
  titleStyle_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  titleStyle_not?: InputMaybe<Scalars['String']>
  titleStyle_not_contains?: InputMaybe<Scalars['String']>
  titleStyle_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  urlTarget?: InputMaybe<Scalars['String']>
  urlTarget_contains?: InputMaybe<Scalars['String']>
  urlTarget_exists?: InputMaybe<Scalars['Boolean']>
  urlTarget_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  urlTarget_not?: InputMaybe<Scalars['String']>
  urlTarget_not_contains?: InputMaybe<Scalars['String']>
  urlTarget_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
}

export type ComponentSubscriptionCardLinkingCollections = {
  __typename?: 'ComponentSubscriptionCardLinkingCollections'
  campaignPagesCollection?: Maybe<CampaignPagesCollection>
  componentFormCollection?: Maybe<ComponentFormCollection>
  entryCollection?: Maybe<EntryCollection>
  templateAboutUsCollection?: Maybe<TemplateAboutUsCollection>
  templateBookingCollection?: Maybe<TemplateBookingCollection>
  templateCareersCollection?: Maybe<TemplateCareersCollection>
  templateContactUsCollection?: Maybe<TemplateContactUsCollection>
  templateDestinationsCollection?: Maybe<TemplateDestinationsCollection>
  templateForaReserveCollection?: Maybe<TemplateForaReserveCollection>
  templateForaXCollection?: Maybe<TemplateForaXCollection>
  templateHomeCollection?: Maybe<TemplateHomeCollection>
  templateJoinUsCollection?: Maybe<TemplateJoinUsCollection>
  templateNewsletterCollection?: Maybe<TemplateNewsletterCollection>
  templatePageCollection?: Maybe<TemplatePageCollection>
  templatePartnersCollection?: Maybe<TemplatePartnersCollection>
  templateReviewsCollection?: Maybe<TemplateReviewsCollection>
  templateWhyBookWithForaCollection?: Maybe<TemplateWhyBookWithForaCollection>
}

export type ComponentSubscriptionCardLinkingCollectionsCampaignPagesCollectionArgs =
  {
    limit?: InputMaybe<Scalars['Int']>
    locale?: InputMaybe<Scalars['String']>
    order?: InputMaybe<
      Array<
        InputMaybe<ComponentSubscriptionCardLinkingCollectionsCampaignPagesCollectionOrder>
      >
    >
    preview?: InputMaybe<Scalars['Boolean']>
    skip?: InputMaybe<Scalars['Int']>
  }

export type ComponentSubscriptionCardLinkingCollectionsComponentFormCollectionArgs =
  {
    limit?: InputMaybe<Scalars['Int']>
    locale?: InputMaybe<Scalars['String']>
    order?: InputMaybe<
      Array<
        InputMaybe<ComponentSubscriptionCardLinkingCollectionsComponentFormCollectionOrder>
      >
    >
    preview?: InputMaybe<Scalars['Boolean']>
    skip?: InputMaybe<Scalars['Int']>
  }

export type ComponentSubscriptionCardLinkingCollectionsEntryCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>
  locale?: InputMaybe<Scalars['String']>
  preview?: InputMaybe<Scalars['Boolean']>
  skip?: InputMaybe<Scalars['Int']>
}

export type ComponentSubscriptionCardLinkingCollectionsTemplateAboutUsCollectionArgs =
  {
    limit?: InputMaybe<Scalars['Int']>
    locale?: InputMaybe<Scalars['String']>
    order?: InputMaybe<
      Array<
        InputMaybe<ComponentSubscriptionCardLinkingCollectionsTemplateAboutUsCollectionOrder>
      >
    >
    preview?: InputMaybe<Scalars['Boolean']>
    skip?: InputMaybe<Scalars['Int']>
  }

export type ComponentSubscriptionCardLinkingCollectionsTemplateBookingCollectionArgs =
  {
    limit?: InputMaybe<Scalars['Int']>
    locale?: InputMaybe<Scalars['String']>
    order?: InputMaybe<
      Array<
        InputMaybe<ComponentSubscriptionCardLinkingCollectionsTemplateBookingCollectionOrder>
      >
    >
    preview?: InputMaybe<Scalars['Boolean']>
    skip?: InputMaybe<Scalars['Int']>
  }

export type ComponentSubscriptionCardLinkingCollectionsTemplateCareersCollectionArgs =
  {
    limit?: InputMaybe<Scalars['Int']>
    locale?: InputMaybe<Scalars['String']>
    order?: InputMaybe<
      Array<
        InputMaybe<ComponentSubscriptionCardLinkingCollectionsTemplateCareersCollectionOrder>
      >
    >
    preview?: InputMaybe<Scalars['Boolean']>
    skip?: InputMaybe<Scalars['Int']>
  }

export type ComponentSubscriptionCardLinkingCollectionsTemplateContactUsCollectionArgs =
  {
    limit?: InputMaybe<Scalars['Int']>
    locale?: InputMaybe<Scalars['String']>
    order?: InputMaybe<
      Array<
        InputMaybe<ComponentSubscriptionCardLinkingCollectionsTemplateContactUsCollectionOrder>
      >
    >
    preview?: InputMaybe<Scalars['Boolean']>
    skip?: InputMaybe<Scalars['Int']>
  }

export type ComponentSubscriptionCardLinkingCollectionsTemplateDestinationsCollectionArgs =
  {
    limit?: InputMaybe<Scalars['Int']>
    locale?: InputMaybe<Scalars['String']>
    order?: InputMaybe<
      Array<
        InputMaybe<ComponentSubscriptionCardLinkingCollectionsTemplateDestinationsCollectionOrder>
      >
    >
    preview?: InputMaybe<Scalars['Boolean']>
    skip?: InputMaybe<Scalars['Int']>
  }

export type ComponentSubscriptionCardLinkingCollectionsTemplateForaReserveCollectionArgs =
  {
    limit?: InputMaybe<Scalars['Int']>
    locale?: InputMaybe<Scalars['String']>
    order?: InputMaybe<
      Array<
        InputMaybe<ComponentSubscriptionCardLinkingCollectionsTemplateForaReserveCollectionOrder>
      >
    >
    preview?: InputMaybe<Scalars['Boolean']>
    skip?: InputMaybe<Scalars['Int']>
  }

export type ComponentSubscriptionCardLinkingCollectionsTemplateForaXCollectionArgs =
  {
    limit?: InputMaybe<Scalars['Int']>
    locale?: InputMaybe<Scalars['String']>
    order?: InputMaybe<
      Array<
        InputMaybe<ComponentSubscriptionCardLinkingCollectionsTemplateForaXCollectionOrder>
      >
    >
    preview?: InputMaybe<Scalars['Boolean']>
    skip?: InputMaybe<Scalars['Int']>
  }

export type ComponentSubscriptionCardLinkingCollectionsTemplateHomeCollectionArgs =
  {
    limit?: InputMaybe<Scalars['Int']>
    locale?: InputMaybe<Scalars['String']>
    order?: InputMaybe<
      Array<
        InputMaybe<ComponentSubscriptionCardLinkingCollectionsTemplateHomeCollectionOrder>
      >
    >
    preview?: InputMaybe<Scalars['Boolean']>
    skip?: InputMaybe<Scalars['Int']>
  }

export type ComponentSubscriptionCardLinkingCollectionsTemplateJoinUsCollectionArgs =
  {
    limit?: InputMaybe<Scalars['Int']>
    locale?: InputMaybe<Scalars['String']>
    order?: InputMaybe<
      Array<
        InputMaybe<ComponentSubscriptionCardLinkingCollectionsTemplateJoinUsCollectionOrder>
      >
    >
    preview?: InputMaybe<Scalars['Boolean']>
    skip?: InputMaybe<Scalars['Int']>
  }

export type ComponentSubscriptionCardLinkingCollectionsTemplateNewsletterCollectionArgs =
  {
    limit?: InputMaybe<Scalars['Int']>
    locale?: InputMaybe<Scalars['String']>
    order?: InputMaybe<
      Array<
        InputMaybe<ComponentSubscriptionCardLinkingCollectionsTemplateNewsletterCollectionOrder>
      >
    >
    preview?: InputMaybe<Scalars['Boolean']>
    skip?: InputMaybe<Scalars['Int']>
  }

export type ComponentSubscriptionCardLinkingCollectionsTemplatePageCollectionArgs =
  {
    limit?: InputMaybe<Scalars['Int']>
    locale?: InputMaybe<Scalars['String']>
    order?: InputMaybe<
      Array<
        InputMaybe<ComponentSubscriptionCardLinkingCollectionsTemplatePageCollectionOrder>
      >
    >
    preview?: InputMaybe<Scalars['Boolean']>
    skip?: InputMaybe<Scalars['Int']>
  }

export type ComponentSubscriptionCardLinkingCollectionsTemplatePartnersCollectionArgs =
  {
    limit?: InputMaybe<Scalars['Int']>
    locale?: InputMaybe<Scalars['String']>
    order?: InputMaybe<
      Array<
        InputMaybe<ComponentSubscriptionCardLinkingCollectionsTemplatePartnersCollectionOrder>
      >
    >
    preview?: InputMaybe<Scalars['Boolean']>
    skip?: InputMaybe<Scalars['Int']>
  }

export type ComponentSubscriptionCardLinkingCollectionsTemplateReviewsCollectionArgs =
  {
    limit?: InputMaybe<Scalars['Int']>
    locale?: InputMaybe<Scalars['String']>
    order?: InputMaybe<
      Array<
        InputMaybe<ComponentSubscriptionCardLinkingCollectionsTemplateReviewsCollectionOrder>
      >
    >
    preview?: InputMaybe<Scalars['Boolean']>
    skip?: InputMaybe<Scalars['Int']>
  }

export type ComponentSubscriptionCardLinkingCollectionsTemplateWhyBookWithForaCollectionArgs =
  {
    limit?: InputMaybe<Scalars['Int']>
    locale?: InputMaybe<Scalars['String']>
    order?: InputMaybe<
      Array<
        InputMaybe<ComponentSubscriptionCardLinkingCollectionsTemplateWhyBookWithForaCollectionOrder>
      >
    >
    preview?: InputMaybe<Scalars['Boolean']>
    skip?: InputMaybe<Scalars['Int']>
  }

export enum ComponentSubscriptionCardLinkingCollectionsCampaignPagesCollectionOrder {
  CollapseFooterAsc = 'collapseFooter_ASC',
  CollapseFooterDesc = 'collapseFooter_DESC',
  CollapseHeaderAsc = 'collapseHeader_ASC',
  CollapseHeaderDesc = 'collapseHeader_DESC',
  HideFooterAsc = 'hideFooter_ASC',
  HideFooterDesc = 'hideFooter_DESC',
  HideGlobalBannerAsc = 'hideGlobalBanner_ASC',
  HideGlobalBannerDesc = 'hideGlobalBanner_DESC',
  JotformIdAsc = 'jotformId_ASC',
  JotformIdDesc = 'jotformId_DESC',
  NoindexNofollowAsc = 'noindexNofollow_ASC',
  NoindexNofollowDesc = 'noindexNofollow_DESC',
  ShowHeaderAsc = 'showHeader_ASC',
  ShowHeaderDesc = 'showHeader_DESC',
  SlugAsc = 'slug_ASC',
  SlugDesc = 'slug_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC',
}

export enum ComponentSubscriptionCardLinkingCollectionsComponentFormCollectionOrder {
  ButtonTextSharedStateAsc = 'buttonTextSharedState_ASC',
  ButtonTextSharedStateDesc = 'buttonTextSharedState_DESC',
  ButtonTextAsc = 'buttonText_ASC',
  ButtonTextDesc = 'buttonText_DESC',
  FormIdAsc = 'formId_ASC',
  FormIdDesc = 'formId_DESC',
  InternalLabelAsc = 'internalLabel_ASC',
  InternalLabelDesc = 'internalLabel_DESC',
  SectionIdAsc = 'sectionId_ASC',
  SectionIdDesc = 'sectionId_DESC',
  SuccessTextAsc = 'successText_ASC',
  SuccessTextDesc = 'successText_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  TitleSharedStateAsc = 'titleSharedState_ASC',
  TitleSharedStateDesc = 'titleSharedState_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC',
  TypeformPopOutUrlAsc = 'typeformPopOutUrl_ASC',
  TypeformPopOutUrlDesc = 'typeformPopOutUrl_DESC',
  TypeformUrlAsc = 'typeformUrl_ASC',
  TypeformUrlDesc = 'typeformUrl_DESC',
  ZapierIdAsc = 'zapierId_ASC',
  ZapierIdDesc = 'zapierId_DESC',
}

export enum ComponentSubscriptionCardLinkingCollectionsTemplateAboutUsCollectionOrder {
  CollapseHeaderAsc = 'collapseHeader_ASC',
  CollapseHeaderDesc = 'collapseHeader_DESC',
  HeaderBlockTitleAsc = 'headerBlockTitle_ASC',
  HeaderBlockTitleDesc = 'headerBlockTitle_DESC',
  ShowHeaderAsc = 'showHeader_ASC',
  ShowHeaderDesc = 'showHeader_DESC',
  SubtitleAsc = 'subtitle_ASC',
  SubtitleDesc = 'subtitle_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC',
}

export enum ComponentSubscriptionCardLinkingCollectionsTemplateBookingCollectionOrder {
  CollapseHeaderAsc = 'collapseHeader_ASC',
  CollapseHeaderDesc = 'collapseHeader_DESC',
  ShowHeaderAsc = 'showHeader_ASC',
  ShowHeaderDesc = 'showHeader_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC',
}

export enum ComponentSubscriptionCardLinkingCollectionsTemplateCareersCollectionOrder {
  CollapseHeaderAsc = 'collapseHeader_ASC',
  CollapseHeaderDesc = 'collapseHeader_DESC',
  ShowHeaderAsc = 'showHeader_ASC',
  ShowHeaderDesc = 'showHeader_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC',
}

export enum ComponentSubscriptionCardLinkingCollectionsTemplateContactUsCollectionOrder {
  CollapseHeaderAsc = 'collapseHeader_ASC',
  CollapseHeaderDesc = 'collapseHeader_DESC',
  ShowHeaderAsc = 'showHeader_ASC',
  ShowHeaderDesc = 'showHeader_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC',
}

export enum ComponentSubscriptionCardLinkingCollectionsTemplateDestinationsCollectionOrder {
  CollapseHeaderAsc = 'collapseHeader_ASC',
  CollapseHeaderDesc = 'collapseHeader_DESC',
  CollapsibleHeadingAsc = 'collapsibleHeading_ASC',
  CollapsibleHeadingDesc = 'collapsibleHeading_DESC',
  ShowHeaderAsc = 'showHeader_ASC',
  ShowHeaderDesc = 'showHeader_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC',
}

export enum ComponentSubscriptionCardLinkingCollectionsTemplateForaReserveCollectionOrder {
  CollapseHeaderAsc = 'collapseHeader_ASC',
  CollapseHeaderDesc = 'collapseHeader_DESC',
  ShowHeaderAsc = 'showHeader_ASC',
  ShowHeaderDesc = 'showHeader_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC',
}

export enum ComponentSubscriptionCardLinkingCollectionsTemplateForaXCollectionOrder {
  AdvisorGridHeaderAsc = 'advisorGridHeader_ASC',
  AdvisorGridHeaderDesc = 'advisorGridHeader_DESC',
  AdvisorGridSubCopyAsc = 'advisorGridSubCopy_ASC',
  AdvisorGridSubCopyDesc = 'advisorGridSubCopy_DESC',
  CollapseHeaderAsc = 'collapseHeader_ASC',
  CollapseHeaderDesc = 'collapseHeader_DESC',
  ShowHeaderAsc = 'showHeader_ASC',
  ShowHeaderDesc = 'showHeader_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC',
}

export enum ComponentSubscriptionCardLinkingCollectionsTemplateHomeCollectionOrder {
  CollapseHeaderAsc = 'collapseHeader_ASC',
  CollapseHeaderDesc = 'collapseHeader_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC',
}

export enum ComponentSubscriptionCardLinkingCollectionsTemplateJoinUsCollectionOrder {
  CollapseHeaderAsc = 'collapseHeader_ASC',
  CollapseHeaderDesc = 'collapseHeader_DESC',
  HideGlobalBannerAsc = 'hideGlobalBanner_ASC',
  HideGlobalBannerDesc = 'hideGlobalBanner_DESC',
  ShowHeaderAsc = 'showHeader_ASC',
  ShowHeaderDesc = 'showHeader_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC',
}

export enum ComponentSubscriptionCardLinkingCollectionsTemplateNewsletterCollectionOrder {
  CollapseHeaderAsc = 'collapseHeader_ASC',
  CollapseHeaderDesc = 'collapseHeader_DESC',
  ShowHeaderAsc = 'showHeader_ASC',
  ShowHeaderDesc = 'showHeader_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC',
}

export enum ComponentSubscriptionCardLinkingCollectionsTemplatePageCollectionOrder {
  DirectoryAsc = 'directory_ASC',
  DirectoryDesc = 'directory_DESC',
  InternalLabelAsc = 'internalLabel_ASC',
  InternalLabelDesc = 'internalLabel_DESC',
  MetaTitleAsc = 'metaTitle_ASC',
  MetaTitleDesc = 'metaTitle_DESC',
  NoindexNofollowAsc = 'noindexNofollow_ASC',
  NoindexNofollowDesc = 'noindexNofollow_DESC',
  SlugAsc = 'slug_ASC',
  SlugDesc = 'slug_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
}

export enum ComponentSubscriptionCardLinkingCollectionsTemplatePartnersCollectionOrder {
  CollapseHeaderAsc = 'collapseHeader_ASC',
  CollapseHeaderDesc = 'collapseHeader_DESC',
  ShowHeaderAsc = 'showHeader_ASC',
  ShowHeaderDesc = 'showHeader_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC',
}

export enum ComponentSubscriptionCardLinkingCollectionsTemplateReviewsCollectionOrder {
  CollapseHeaderAsc = 'collapseHeader_ASC',
  CollapseHeaderDesc = 'collapseHeader_DESC',
  ShowHeaderAsc = 'showHeader_ASC',
  ShowHeaderDesc = 'showHeader_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC',
}

export enum ComponentSubscriptionCardLinkingCollectionsTemplateWhyBookWithForaCollectionOrder {
  CollapseHeaderAsc = 'collapseHeader_ASC',
  CollapseHeaderDesc = 'collapseHeader_DESC',
  ShowHeaderAsc = 'showHeader_ASC',
  ShowHeaderDesc = 'showHeader_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC',
}

export enum ComponentSubscriptionCardOrder {
  AnnualDiscountPriceAsc = 'annualDiscountPrice_ASC',
  AnnualDiscountPriceDesc = 'annualDiscountPrice_DESC',
  AnnualFeaturedTextAsc = 'annualFeaturedText_ASC',
  AnnualFeaturedTextDesc = 'annualFeaturedText_DESC',
  AnnualIsLimitedAsc = 'annualIsLimited_ASC',
  AnnualIsLimitedDesc = 'annualIsLimited_DESC',
  AnnualOriginalPriceAsc = 'annualOriginalPrice_ASC',
  AnnualOriginalPriceDesc = 'annualOriginalPrice_DESC',
  AnnualPriceDetailsAsc = 'annualPriceDetails_ASC',
  AnnualPriceDetailsDesc = 'annualPriceDetails_DESC',
  CtaTextAsc = 'ctaText_ASC',
  CtaTextDesc = 'ctaText_DESC',
  CtaUrlAsc = 'ctaUrl_ASC',
  CtaUrlDesc = 'ctaUrl_DESC',
  HeadingAsc = 'heading_ASC',
  HeadingDesc = 'heading_DESC',
  InternalLabelAsc = 'internalLabel_ASC',
  InternalLabelDesc = 'internalLabel_DESC',
  MonthlyDiscountPriceAsc = 'monthlyDiscountPrice_ASC',
  MonthlyDiscountPriceDesc = 'monthlyDiscountPrice_DESC',
  MonthlyFeaturedTextAsc = 'monthlyFeaturedText_ASC',
  MonthlyFeaturedTextDesc = 'monthlyFeaturedText_DESC',
  MonthlyIsLimitedAsc = 'monthlyIsLimited_ASC',
  MonthlyIsLimitedDesc = 'monthlyIsLimited_DESC',
  MonthlyOriginalPriceAsc = 'monthlyOriginalPrice_ASC',
  MonthlyOriginalPriceDesc = 'monthlyOriginalPrice_DESC',
  MonthlyPriceDetailsAsc = 'monthlyPriceDetails_ASC',
  MonthlyPriceDetailsDesc = 'monthlyPriceDetails_DESC',
  SectionIdAsc = 'sectionId_ASC',
  SectionIdDesc = 'sectionId_DESC',
  ShowMultiplePricingModelsAsc = 'showMultiplePricingModels_ASC',
  ShowMultiplePricingModelsDesc = 'showMultiplePricingModels_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  TextOnRightAsc = 'textOnRight_ASC',
  TextOnRightDesc = 'textOnRight_DESC',
  TitleStyleAsc = 'titleStyle_ASC',
  TitleStyleDesc = 'titleStyle_DESC',
  UrlTargetAsc = 'urlTarget_ASC',
  UrlTargetDesc = 'urlTarget_DESC',
}

export type ComponentSubscriptionCardSubCopy = {
  __typename?: 'ComponentSubscriptionCardSubCopy'
  json: Scalars['JSON']
  links: ComponentSubscriptionCardSubCopyLinks
}

export type ComponentSubscriptionCardSubCopyAssets = {
  __typename?: 'ComponentSubscriptionCardSubCopyAssets'
  block: Array<Maybe<Asset>>
  hyperlink: Array<Maybe<Asset>>
}

export type ComponentSubscriptionCardSubCopyEntries = {
  __typename?: 'ComponentSubscriptionCardSubCopyEntries'
  block: Array<Maybe<Entry>>
  hyperlink: Array<Maybe<Entry>>
  inline: Array<Maybe<Entry>>
}

export type ComponentSubscriptionCardSubCopyLinks = {
  __typename?: 'ComponentSubscriptionCardSubCopyLinks'
  assets: ComponentSubscriptionCardSubCopyAssets
  entries: ComponentSubscriptionCardSubCopyEntries
  resources: ComponentSubscriptionCardSubCopyResources
}

export type ComponentSubscriptionCardSubCopyResources = {
  __typename?: 'ComponentSubscriptionCardSubCopyResources'
  block: Array<ComponentSubscriptionCardSubCopyResourcesBlock>
  hyperlink: Array<ComponentSubscriptionCardSubCopyResourcesHyperlink>
  inline: Array<ComponentSubscriptionCardSubCopyResourcesInline>
}

export type ComponentSubscriptionCardSubCopyResourcesBlock = ResourceLink & {
  __typename?: 'ComponentSubscriptionCardSubCopyResourcesBlock'
  sys: ResourceSys
}

export type ComponentSubscriptionCardSubCopyResourcesHyperlink =
  ResourceLink & {
    __typename?: 'ComponentSubscriptionCardSubCopyResourcesHyperlink'
    sys: ResourceSys
  }

export type ComponentSubscriptionCardSubCopyResourcesInline = ResourceLink & {
  __typename?: 'ComponentSubscriptionCardSubCopyResourcesInline'
  sys: ResourceSys
}

export type ComponentSubscriptionCardTeaserCopy = {
  __typename?: 'ComponentSubscriptionCardTeaserCopy'
  json: Scalars['JSON']
  links: ComponentSubscriptionCardTeaserCopyLinks
}

export type ComponentSubscriptionCardTeaserCopyAssets = {
  __typename?: 'ComponentSubscriptionCardTeaserCopyAssets'
  block: Array<Maybe<Asset>>
  hyperlink: Array<Maybe<Asset>>
}

export type ComponentSubscriptionCardTeaserCopyEntries = {
  __typename?: 'ComponentSubscriptionCardTeaserCopyEntries'
  block: Array<Maybe<Entry>>
  hyperlink: Array<Maybe<Entry>>
  inline: Array<Maybe<Entry>>
}

export type ComponentSubscriptionCardTeaserCopyLinks = {
  __typename?: 'ComponentSubscriptionCardTeaserCopyLinks'
  assets: ComponentSubscriptionCardTeaserCopyAssets
  entries: ComponentSubscriptionCardTeaserCopyEntries
  resources: ComponentSubscriptionCardTeaserCopyResources
}

export type ComponentSubscriptionCardTeaserCopyResources = {
  __typename?: 'ComponentSubscriptionCardTeaserCopyResources'
  block: Array<ComponentSubscriptionCardTeaserCopyResourcesBlock>
  hyperlink: Array<ComponentSubscriptionCardTeaserCopyResourcesHyperlink>
  inline: Array<ComponentSubscriptionCardTeaserCopyResourcesInline>
}

export type ComponentSubscriptionCardTeaserCopyResourcesBlock = ResourceLink & {
  __typename?: 'ComponentSubscriptionCardTeaserCopyResourcesBlock'
  sys: ResourceSys
}

export type ComponentSubscriptionCardTeaserCopyResourcesHyperlink =
  ResourceLink & {
    __typename?: 'ComponentSubscriptionCardTeaserCopyResourcesHyperlink'
    sys: ResourceSys
  }

export type ComponentSubscriptionCardTeaserCopyResourcesInline =
  ResourceLink & {
    __typename?: 'ComponentSubscriptionCardTeaserCopyResourcesInline'
    sys: ResourceSys
  }

/** A page section with a title and a grid of Fora team members. [See type definition](https://app.contentful.com/spaces/9cxgygtadmvl/content_types/componentTeamSection) */
export type ComponentTeamSection = Entry &
  _Node & {
    __typename?: 'ComponentTeamSection'
    _id: Scalars['ID']
    analyticsModule?: Maybe<SettingsAnalyticsModule>
    contentfulMetadata: ContentfulMetadata
    internalLabel?: Maybe<Scalars['String']>
    linkedFrom?: Maybe<ComponentTeamSectionLinkingCollections>
    sectionId?: Maybe<Scalars['String']>
    sys: Sys
    teamMembersCollection?: Maybe<ComponentTeamSectionTeamMembersCollection>
    title?: Maybe<Scalars['String']>
    titleStyle?: Maybe<Scalars['String']>
  }

/** A page section with a title and a grid of Fora team members. [See type definition](https://app.contentful.com/spaces/9cxgygtadmvl/content_types/componentTeamSection) */
export type ComponentTeamSectionAnalyticsModuleArgs = {
  locale?: InputMaybe<Scalars['String']>
  preview?: InputMaybe<Scalars['Boolean']>
  where?: InputMaybe<SettingsAnalyticsModuleFilter>
}

/** A page section with a title and a grid of Fora team members. [See type definition](https://app.contentful.com/spaces/9cxgygtadmvl/content_types/componentTeamSection) */
export type ComponentTeamSectionInternalLabelArgs = {
  locale?: InputMaybe<Scalars['String']>
}

/** A page section with a title and a grid of Fora team members. [See type definition](https://app.contentful.com/spaces/9cxgygtadmvl/content_types/componentTeamSection) */
export type ComponentTeamSectionLinkedFromArgs = {
  allowedLocales?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
}

/** A page section with a title and a grid of Fora team members. [See type definition](https://app.contentful.com/spaces/9cxgygtadmvl/content_types/componentTeamSection) */
export type ComponentTeamSectionSectionIdArgs = {
  locale?: InputMaybe<Scalars['String']>
}

/** A page section with a title and a grid of Fora team members. [See type definition](https://app.contentful.com/spaces/9cxgygtadmvl/content_types/componentTeamSection) */
export type ComponentTeamSectionTeamMembersCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>
  locale?: InputMaybe<Scalars['String']>
  order?: InputMaybe<
    Array<InputMaybe<ComponentTeamSectionTeamMembersCollectionOrder>>
  >
  preview?: InputMaybe<Scalars['Boolean']>
  skip?: InputMaybe<Scalars['Int']>
  where?: InputMaybe<TeamFilter>
}

/** A page section with a title and a grid of Fora team members. [See type definition](https://app.contentful.com/spaces/9cxgygtadmvl/content_types/componentTeamSection) */
export type ComponentTeamSectionTitleArgs = {
  locale?: InputMaybe<Scalars['String']>
}

/** A page section with a title and a grid of Fora team members. [See type definition](https://app.contentful.com/spaces/9cxgygtadmvl/content_types/componentTeamSection) */
export type ComponentTeamSectionTitleStyleArgs = {
  locale?: InputMaybe<Scalars['String']>
}

export type ComponentTeamSectionCollection = {
  __typename?: 'ComponentTeamSectionCollection'
  items: Array<Maybe<ComponentTeamSection>>
  limit: Scalars['Int']
  skip: Scalars['Int']
  total: Scalars['Int']
}

export type ComponentTeamSectionFilter = {
  AND?: InputMaybe<Array<InputMaybe<ComponentTeamSectionFilter>>>
  OR?: InputMaybe<Array<InputMaybe<ComponentTeamSectionFilter>>>
  analyticsModule?: InputMaybe<CfSettingsAnalyticsModuleNestedFilter>
  analyticsModule_exists?: InputMaybe<Scalars['Boolean']>
  contentfulMetadata?: InputMaybe<ContentfulMetadataFilter>
  internalLabel?: InputMaybe<Scalars['String']>
  internalLabel_contains?: InputMaybe<Scalars['String']>
  internalLabel_exists?: InputMaybe<Scalars['Boolean']>
  internalLabel_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  internalLabel_not?: InputMaybe<Scalars['String']>
  internalLabel_not_contains?: InputMaybe<Scalars['String']>
  internalLabel_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  sectionId?: InputMaybe<Scalars['String']>
  sectionId_contains?: InputMaybe<Scalars['String']>
  sectionId_exists?: InputMaybe<Scalars['Boolean']>
  sectionId_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  sectionId_not?: InputMaybe<Scalars['String']>
  sectionId_not_contains?: InputMaybe<Scalars['String']>
  sectionId_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  sys?: InputMaybe<SysFilter>
  teamMembers?: InputMaybe<CfTeamNestedFilter>
  teamMembersCollection_exists?: InputMaybe<Scalars['Boolean']>
  title?: InputMaybe<Scalars['String']>
  titleStyle?: InputMaybe<Scalars['String']>
  titleStyle_contains?: InputMaybe<Scalars['String']>
  titleStyle_exists?: InputMaybe<Scalars['Boolean']>
  titleStyle_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  titleStyle_not?: InputMaybe<Scalars['String']>
  titleStyle_not_contains?: InputMaybe<Scalars['String']>
  titleStyle_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  title_contains?: InputMaybe<Scalars['String']>
  title_exists?: InputMaybe<Scalars['Boolean']>
  title_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  title_not?: InputMaybe<Scalars['String']>
  title_not_contains?: InputMaybe<Scalars['String']>
  title_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
}

export type ComponentTeamSectionLinkingCollections = {
  __typename?: 'ComponentTeamSectionLinkingCollections'
  campaignPagesCollection?: Maybe<CampaignPagesCollection>
  entryCollection?: Maybe<EntryCollection>
  templateAboutUsCollection?: Maybe<TemplateAboutUsCollection>
  templateBookingCollection?: Maybe<TemplateBookingCollection>
  templateCareersCollection?: Maybe<TemplateCareersCollection>
  templateContactUsCollection?: Maybe<TemplateContactUsCollection>
  templateDestinationsCollection?: Maybe<TemplateDestinationsCollection>
  templateForaReserveCollection?: Maybe<TemplateForaReserveCollection>
  templateForaXCollection?: Maybe<TemplateForaXCollection>
  templateHomeCollection?: Maybe<TemplateHomeCollection>
  templateJoinUsCollection?: Maybe<TemplateJoinUsCollection>
  templateNewsletterCollection?: Maybe<TemplateNewsletterCollection>
  templatePageCollection?: Maybe<TemplatePageCollection>
  templatePartnersCollection?: Maybe<TemplatePartnersCollection>
  templateReviewsCollection?: Maybe<TemplateReviewsCollection>
  templateWhyBookWithForaCollection?: Maybe<TemplateWhyBookWithForaCollection>
}

export type ComponentTeamSectionLinkingCollectionsCampaignPagesCollectionArgs =
  {
    limit?: InputMaybe<Scalars['Int']>
    locale?: InputMaybe<Scalars['String']>
    order?: InputMaybe<
      Array<
        InputMaybe<ComponentTeamSectionLinkingCollectionsCampaignPagesCollectionOrder>
      >
    >
    preview?: InputMaybe<Scalars['Boolean']>
    skip?: InputMaybe<Scalars['Int']>
  }

export type ComponentTeamSectionLinkingCollectionsEntryCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>
  locale?: InputMaybe<Scalars['String']>
  preview?: InputMaybe<Scalars['Boolean']>
  skip?: InputMaybe<Scalars['Int']>
}

export type ComponentTeamSectionLinkingCollectionsTemplateAboutUsCollectionArgs =
  {
    limit?: InputMaybe<Scalars['Int']>
    locale?: InputMaybe<Scalars['String']>
    order?: InputMaybe<
      Array<
        InputMaybe<ComponentTeamSectionLinkingCollectionsTemplateAboutUsCollectionOrder>
      >
    >
    preview?: InputMaybe<Scalars['Boolean']>
    skip?: InputMaybe<Scalars['Int']>
  }

export type ComponentTeamSectionLinkingCollectionsTemplateBookingCollectionArgs =
  {
    limit?: InputMaybe<Scalars['Int']>
    locale?: InputMaybe<Scalars['String']>
    order?: InputMaybe<
      Array<
        InputMaybe<ComponentTeamSectionLinkingCollectionsTemplateBookingCollectionOrder>
      >
    >
    preview?: InputMaybe<Scalars['Boolean']>
    skip?: InputMaybe<Scalars['Int']>
  }

export type ComponentTeamSectionLinkingCollectionsTemplateCareersCollectionArgs =
  {
    limit?: InputMaybe<Scalars['Int']>
    locale?: InputMaybe<Scalars['String']>
    order?: InputMaybe<
      Array<
        InputMaybe<ComponentTeamSectionLinkingCollectionsTemplateCareersCollectionOrder>
      >
    >
    preview?: InputMaybe<Scalars['Boolean']>
    skip?: InputMaybe<Scalars['Int']>
  }

export type ComponentTeamSectionLinkingCollectionsTemplateContactUsCollectionArgs =
  {
    limit?: InputMaybe<Scalars['Int']>
    locale?: InputMaybe<Scalars['String']>
    order?: InputMaybe<
      Array<
        InputMaybe<ComponentTeamSectionLinkingCollectionsTemplateContactUsCollectionOrder>
      >
    >
    preview?: InputMaybe<Scalars['Boolean']>
    skip?: InputMaybe<Scalars['Int']>
  }

export type ComponentTeamSectionLinkingCollectionsTemplateDestinationsCollectionArgs =
  {
    limit?: InputMaybe<Scalars['Int']>
    locale?: InputMaybe<Scalars['String']>
    order?: InputMaybe<
      Array<
        InputMaybe<ComponentTeamSectionLinkingCollectionsTemplateDestinationsCollectionOrder>
      >
    >
    preview?: InputMaybe<Scalars['Boolean']>
    skip?: InputMaybe<Scalars['Int']>
  }

export type ComponentTeamSectionLinkingCollectionsTemplateForaReserveCollectionArgs =
  {
    limit?: InputMaybe<Scalars['Int']>
    locale?: InputMaybe<Scalars['String']>
    order?: InputMaybe<
      Array<
        InputMaybe<ComponentTeamSectionLinkingCollectionsTemplateForaReserveCollectionOrder>
      >
    >
    preview?: InputMaybe<Scalars['Boolean']>
    skip?: InputMaybe<Scalars['Int']>
  }

export type ComponentTeamSectionLinkingCollectionsTemplateForaXCollectionArgs =
  {
    limit?: InputMaybe<Scalars['Int']>
    locale?: InputMaybe<Scalars['String']>
    order?: InputMaybe<
      Array<
        InputMaybe<ComponentTeamSectionLinkingCollectionsTemplateForaXCollectionOrder>
      >
    >
    preview?: InputMaybe<Scalars['Boolean']>
    skip?: InputMaybe<Scalars['Int']>
  }

export type ComponentTeamSectionLinkingCollectionsTemplateHomeCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>
  locale?: InputMaybe<Scalars['String']>
  order?: InputMaybe<
    Array<
      InputMaybe<ComponentTeamSectionLinkingCollectionsTemplateHomeCollectionOrder>
    >
  >
  preview?: InputMaybe<Scalars['Boolean']>
  skip?: InputMaybe<Scalars['Int']>
}

export type ComponentTeamSectionLinkingCollectionsTemplateJoinUsCollectionArgs =
  {
    limit?: InputMaybe<Scalars['Int']>
    locale?: InputMaybe<Scalars['String']>
    order?: InputMaybe<
      Array<
        InputMaybe<ComponentTeamSectionLinkingCollectionsTemplateJoinUsCollectionOrder>
      >
    >
    preview?: InputMaybe<Scalars['Boolean']>
    skip?: InputMaybe<Scalars['Int']>
  }

export type ComponentTeamSectionLinkingCollectionsTemplateNewsletterCollectionArgs =
  {
    limit?: InputMaybe<Scalars['Int']>
    locale?: InputMaybe<Scalars['String']>
    order?: InputMaybe<
      Array<
        InputMaybe<ComponentTeamSectionLinkingCollectionsTemplateNewsletterCollectionOrder>
      >
    >
    preview?: InputMaybe<Scalars['Boolean']>
    skip?: InputMaybe<Scalars['Int']>
  }

export type ComponentTeamSectionLinkingCollectionsTemplatePageCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>
  locale?: InputMaybe<Scalars['String']>
  order?: InputMaybe<
    Array<
      InputMaybe<ComponentTeamSectionLinkingCollectionsTemplatePageCollectionOrder>
    >
  >
  preview?: InputMaybe<Scalars['Boolean']>
  skip?: InputMaybe<Scalars['Int']>
}

export type ComponentTeamSectionLinkingCollectionsTemplatePartnersCollectionArgs =
  {
    limit?: InputMaybe<Scalars['Int']>
    locale?: InputMaybe<Scalars['String']>
    order?: InputMaybe<
      Array<
        InputMaybe<ComponentTeamSectionLinkingCollectionsTemplatePartnersCollectionOrder>
      >
    >
    preview?: InputMaybe<Scalars['Boolean']>
    skip?: InputMaybe<Scalars['Int']>
  }

export type ComponentTeamSectionLinkingCollectionsTemplateReviewsCollectionArgs =
  {
    limit?: InputMaybe<Scalars['Int']>
    locale?: InputMaybe<Scalars['String']>
    order?: InputMaybe<
      Array<
        InputMaybe<ComponentTeamSectionLinkingCollectionsTemplateReviewsCollectionOrder>
      >
    >
    preview?: InputMaybe<Scalars['Boolean']>
    skip?: InputMaybe<Scalars['Int']>
  }

export type ComponentTeamSectionLinkingCollectionsTemplateWhyBookWithForaCollectionArgs =
  {
    limit?: InputMaybe<Scalars['Int']>
    locale?: InputMaybe<Scalars['String']>
    order?: InputMaybe<
      Array<
        InputMaybe<ComponentTeamSectionLinkingCollectionsTemplateWhyBookWithForaCollectionOrder>
      >
    >
    preview?: InputMaybe<Scalars['Boolean']>
    skip?: InputMaybe<Scalars['Int']>
  }

export enum ComponentTeamSectionLinkingCollectionsCampaignPagesCollectionOrder {
  CollapseFooterAsc = 'collapseFooter_ASC',
  CollapseFooterDesc = 'collapseFooter_DESC',
  CollapseHeaderAsc = 'collapseHeader_ASC',
  CollapseHeaderDesc = 'collapseHeader_DESC',
  HideFooterAsc = 'hideFooter_ASC',
  HideFooterDesc = 'hideFooter_DESC',
  HideGlobalBannerAsc = 'hideGlobalBanner_ASC',
  HideGlobalBannerDesc = 'hideGlobalBanner_DESC',
  JotformIdAsc = 'jotformId_ASC',
  JotformIdDesc = 'jotformId_DESC',
  NoindexNofollowAsc = 'noindexNofollow_ASC',
  NoindexNofollowDesc = 'noindexNofollow_DESC',
  ShowHeaderAsc = 'showHeader_ASC',
  ShowHeaderDesc = 'showHeader_DESC',
  SlugAsc = 'slug_ASC',
  SlugDesc = 'slug_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC',
}

export enum ComponentTeamSectionLinkingCollectionsTemplateAboutUsCollectionOrder {
  CollapseHeaderAsc = 'collapseHeader_ASC',
  CollapseHeaderDesc = 'collapseHeader_DESC',
  HeaderBlockTitleAsc = 'headerBlockTitle_ASC',
  HeaderBlockTitleDesc = 'headerBlockTitle_DESC',
  ShowHeaderAsc = 'showHeader_ASC',
  ShowHeaderDesc = 'showHeader_DESC',
  SubtitleAsc = 'subtitle_ASC',
  SubtitleDesc = 'subtitle_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC',
}

export enum ComponentTeamSectionLinkingCollectionsTemplateBookingCollectionOrder {
  CollapseHeaderAsc = 'collapseHeader_ASC',
  CollapseHeaderDesc = 'collapseHeader_DESC',
  ShowHeaderAsc = 'showHeader_ASC',
  ShowHeaderDesc = 'showHeader_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC',
}

export enum ComponentTeamSectionLinkingCollectionsTemplateCareersCollectionOrder {
  CollapseHeaderAsc = 'collapseHeader_ASC',
  CollapseHeaderDesc = 'collapseHeader_DESC',
  ShowHeaderAsc = 'showHeader_ASC',
  ShowHeaderDesc = 'showHeader_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC',
}

export enum ComponentTeamSectionLinkingCollectionsTemplateContactUsCollectionOrder {
  CollapseHeaderAsc = 'collapseHeader_ASC',
  CollapseHeaderDesc = 'collapseHeader_DESC',
  ShowHeaderAsc = 'showHeader_ASC',
  ShowHeaderDesc = 'showHeader_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC',
}

export enum ComponentTeamSectionLinkingCollectionsTemplateDestinationsCollectionOrder {
  CollapseHeaderAsc = 'collapseHeader_ASC',
  CollapseHeaderDesc = 'collapseHeader_DESC',
  CollapsibleHeadingAsc = 'collapsibleHeading_ASC',
  CollapsibleHeadingDesc = 'collapsibleHeading_DESC',
  ShowHeaderAsc = 'showHeader_ASC',
  ShowHeaderDesc = 'showHeader_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC',
}

export enum ComponentTeamSectionLinkingCollectionsTemplateForaReserveCollectionOrder {
  CollapseHeaderAsc = 'collapseHeader_ASC',
  CollapseHeaderDesc = 'collapseHeader_DESC',
  ShowHeaderAsc = 'showHeader_ASC',
  ShowHeaderDesc = 'showHeader_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC',
}

export enum ComponentTeamSectionLinkingCollectionsTemplateForaXCollectionOrder {
  AdvisorGridHeaderAsc = 'advisorGridHeader_ASC',
  AdvisorGridHeaderDesc = 'advisorGridHeader_DESC',
  AdvisorGridSubCopyAsc = 'advisorGridSubCopy_ASC',
  AdvisorGridSubCopyDesc = 'advisorGridSubCopy_DESC',
  CollapseHeaderAsc = 'collapseHeader_ASC',
  CollapseHeaderDesc = 'collapseHeader_DESC',
  ShowHeaderAsc = 'showHeader_ASC',
  ShowHeaderDesc = 'showHeader_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC',
}

export enum ComponentTeamSectionLinkingCollectionsTemplateHomeCollectionOrder {
  CollapseHeaderAsc = 'collapseHeader_ASC',
  CollapseHeaderDesc = 'collapseHeader_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC',
}

export enum ComponentTeamSectionLinkingCollectionsTemplateJoinUsCollectionOrder {
  CollapseHeaderAsc = 'collapseHeader_ASC',
  CollapseHeaderDesc = 'collapseHeader_DESC',
  HideGlobalBannerAsc = 'hideGlobalBanner_ASC',
  HideGlobalBannerDesc = 'hideGlobalBanner_DESC',
  ShowHeaderAsc = 'showHeader_ASC',
  ShowHeaderDesc = 'showHeader_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC',
}

export enum ComponentTeamSectionLinkingCollectionsTemplateNewsletterCollectionOrder {
  CollapseHeaderAsc = 'collapseHeader_ASC',
  CollapseHeaderDesc = 'collapseHeader_DESC',
  ShowHeaderAsc = 'showHeader_ASC',
  ShowHeaderDesc = 'showHeader_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC',
}

export enum ComponentTeamSectionLinkingCollectionsTemplatePageCollectionOrder {
  DirectoryAsc = 'directory_ASC',
  DirectoryDesc = 'directory_DESC',
  InternalLabelAsc = 'internalLabel_ASC',
  InternalLabelDesc = 'internalLabel_DESC',
  MetaTitleAsc = 'metaTitle_ASC',
  MetaTitleDesc = 'metaTitle_DESC',
  NoindexNofollowAsc = 'noindexNofollow_ASC',
  NoindexNofollowDesc = 'noindexNofollow_DESC',
  SlugAsc = 'slug_ASC',
  SlugDesc = 'slug_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
}

export enum ComponentTeamSectionLinkingCollectionsTemplatePartnersCollectionOrder {
  CollapseHeaderAsc = 'collapseHeader_ASC',
  CollapseHeaderDesc = 'collapseHeader_DESC',
  ShowHeaderAsc = 'showHeader_ASC',
  ShowHeaderDesc = 'showHeader_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC',
}

export enum ComponentTeamSectionLinkingCollectionsTemplateReviewsCollectionOrder {
  CollapseHeaderAsc = 'collapseHeader_ASC',
  CollapseHeaderDesc = 'collapseHeader_DESC',
  ShowHeaderAsc = 'showHeader_ASC',
  ShowHeaderDesc = 'showHeader_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC',
}

export enum ComponentTeamSectionLinkingCollectionsTemplateWhyBookWithForaCollectionOrder {
  CollapseHeaderAsc = 'collapseHeader_ASC',
  CollapseHeaderDesc = 'collapseHeader_DESC',
  ShowHeaderAsc = 'showHeader_ASC',
  ShowHeaderDesc = 'showHeader_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC',
}

export enum ComponentTeamSectionOrder {
  InternalLabelAsc = 'internalLabel_ASC',
  InternalLabelDesc = 'internalLabel_DESC',
  SectionIdAsc = 'sectionId_ASC',
  SectionIdDesc = 'sectionId_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  TitleStyleAsc = 'titleStyle_ASC',
  TitleStyleDesc = 'titleStyle_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC',
}

export type ComponentTeamSectionTeamMembersCollection = {
  __typename?: 'ComponentTeamSectionTeamMembersCollection'
  items: Array<Maybe<Team>>
  limit: Scalars['Int']
  skip: Scalars['Int']
  total: Scalars['Int']
}

export enum ComponentTeamSectionTeamMembersCollectionOrder {
  EmailAsc = 'email_ASC',
  EmailDesc = 'email_DESC',
  NameAsc = 'name_ASC',
  NameDesc = 'name_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC',
}

/** [See type definition](https://app.contentful.com/spaces/9cxgygtadmvl/content_types/componentTestimonialCarousel) */
export type ComponentTestimonialCarousel = Entry &
  _Node & {
    __typename?: 'ComponentTestimonialCarousel'
    _id: Scalars['ID']
    analyticsModule?: Maybe<SettingsAnalyticsModule>
    contentfulMetadata: ContentfulMetadata
    heading?: Maybe<Scalars['String']>
    internalLabel?: Maybe<Scalars['String']>
    linkedFrom?: Maybe<ComponentTestimonialCarouselLinkingCollections>
    sectionId?: Maybe<Scalars['String']>
    sys: Sys
    testimonialsCollection?: Maybe<ComponentTestimonialCarouselTestimonialsCollection>
    titleStyle?: Maybe<Scalars['String']>
  }

/** [See type definition](https://app.contentful.com/spaces/9cxgygtadmvl/content_types/componentTestimonialCarousel) */
export type ComponentTestimonialCarouselAnalyticsModuleArgs = {
  locale?: InputMaybe<Scalars['String']>
  preview?: InputMaybe<Scalars['Boolean']>
  where?: InputMaybe<SettingsAnalyticsModuleFilter>
}

/** [See type definition](https://app.contentful.com/spaces/9cxgygtadmvl/content_types/componentTestimonialCarousel) */
export type ComponentTestimonialCarouselHeadingArgs = {
  locale?: InputMaybe<Scalars['String']>
}

/** [See type definition](https://app.contentful.com/spaces/9cxgygtadmvl/content_types/componentTestimonialCarousel) */
export type ComponentTestimonialCarouselInternalLabelArgs = {
  locale?: InputMaybe<Scalars['String']>
}

/** [See type definition](https://app.contentful.com/spaces/9cxgygtadmvl/content_types/componentTestimonialCarousel) */
export type ComponentTestimonialCarouselLinkedFromArgs = {
  allowedLocales?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
}

/** [See type definition](https://app.contentful.com/spaces/9cxgygtadmvl/content_types/componentTestimonialCarousel) */
export type ComponentTestimonialCarouselSectionIdArgs = {
  locale?: InputMaybe<Scalars['String']>
}

/** [See type definition](https://app.contentful.com/spaces/9cxgygtadmvl/content_types/componentTestimonialCarousel) */
export type ComponentTestimonialCarouselTestimonialsCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>
  locale?: InputMaybe<Scalars['String']>
  order?: InputMaybe<
    Array<InputMaybe<ComponentTestimonialCarouselTestimonialsCollectionOrder>>
  >
  preview?: InputMaybe<Scalars['Boolean']>
  skip?: InputMaybe<Scalars['Int']>
  where?: InputMaybe<EntryTestimonialFilter>
}

/** [See type definition](https://app.contentful.com/spaces/9cxgygtadmvl/content_types/componentTestimonialCarousel) */
export type ComponentTestimonialCarouselTitleStyleArgs = {
  locale?: InputMaybe<Scalars['String']>
}

export type ComponentTestimonialCarouselCollection = {
  __typename?: 'ComponentTestimonialCarouselCollection'
  items: Array<Maybe<ComponentTestimonialCarousel>>
  limit: Scalars['Int']
  skip: Scalars['Int']
  total: Scalars['Int']
}

export type ComponentTestimonialCarouselFilter = {
  AND?: InputMaybe<Array<InputMaybe<ComponentTestimonialCarouselFilter>>>
  OR?: InputMaybe<Array<InputMaybe<ComponentTestimonialCarouselFilter>>>
  analyticsModule?: InputMaybe<CfSettingsAnalyticsModuleNestedFilter>
  analyticsModule_exists?: InputMaybe<Scalars['Boolean']>
  contentfulMetadata?: InputMaybe<ContentfulMetadataFilter>
  heading?: InputMaybe<Scalars['String']>
  heading_contains?: InputMaybe<Scalars['String']>
  heading_exists?: InputMaybe<Scalars['Boolean']>
  heading_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  heading_not?: InputMaybe<Scalars['String']>
  heading_not_contains?: InputMaybe<Scalars['String']>
  heading_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  internalLabel?: InputMaybe<Scalars['String']>
  internalLabel_contains?: InputMaybe<Scalars['String']>
  internalLabel_exists?: InputMaybe<Scalars['Boolean']>
  internalLabel_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  internalLabel_not?: InputMaybe<Scalars['String']>
  internalLabel_not_contains?: InputMaybe<Scalars['String']>
  internalLabel_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  sectionId?: InputMaybe<Scalars['String']>
  sectionId_contains?: InputMaybe<Scalars['String']>
  sectionId_exists?: InputMaybe<Scalars['Boolean']>
  sectionId_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  sectionId_not?: InputMaybe<Scalars['String']>
  sectionId_not_contains?: InputMaybe<Scalars['String']>
  sectionId_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  sys?: InputMaybe<SysFilter>
  testimonials?: InputMaybe<CfEntryTestimonialNestedFilter>
  testimonialsCollection_exists?: InputMaybe<Scalars['Boolean']>
  titleStyle?: InputMaybe<Scalars['String']>
  titleStyle_contains?: InputMaybe<Scalars['String']>
  titleStyle_exists?: InputMaybe<Scalars['Boolean']>
  titleStyle_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  titleStyle_not?: InputMaybe<Scalars['String']>
  titleStyle_not_contains?: InputMaybe<Scalars['String']>
  titleStyle_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
}

export type ComponentTestimonialCarouselLinkingCollections = {
  __typename?: 'ComponentTestimonialCarouselLinkingCollections'
  campaignPagesCollection?: Maybe<CampaignPagesCollection>
  entryCollection?: Maybe<EntryCollection>
  templateAboutUsCollection?: Maybe<TemplateAboutUsCollection>
  templateBookingCollection?: Maybe<TemplateBookingCollection>
  templateCareersCollection?: Maybe<TemplateCareersCollection>
  templateContactUsCollection?: Maybe<TemplateContactUsCollection>
  templateDestinationsCollection?: Maybe<TemplateDestinationsCollection>
  templateForaReserveCollection?: Maybe<TemplateForaReserveCollection>
  templateForaXCollection?: Maybe<TemplateForaXCollection>
  templateHomeCollection?: Maybe<TemplateHomeCollection>
  templateJoinUsCollection?: Maybe<TemplateJoinUsCollection>
  templateNewsletterCollection?: Maybe<TemplateNewsletterCollection>
  templatePageCollection?: Maybe<TemplatePageCollection>
  templatePartnersCollection?: Maybe<TemplatePartnersCollection>
  templateReviewsCollection?: Maybe<TemplateReviewsCollection>
  templateWhyBookWithForaCollection?: Maybe<TemplateWhyBookWithForaCollection>
}

export type ComponentTestimonialCarouselLinkingCollectionsCampaignPagesCollectionArgs =
  {
    limit?: InputMaybe<Scalars['Int']>
    locale?: InputMaybe<Scalars['String']>
    order?: InputMaybe<
      Array<
        InputMaybe<ComponentTestimonialCarouselLinkingCollectionsCampaignPagesCollectionOrder>
      >
    >
    preview?: InputMaybe<Scalars['Boolean']>
    skip?: InputMaybe<Scalars['Int']>
  }

export type ComponentTestimonialCarouselLinkingCollectionsEntryCollectionArgs =
  {
    limit?: InputMaybe<Scalars['Int']>
    locale?: InputMaybe<Scalars['String']>
    preview?: InputMaybe<Scalars['Boolean']>
    skip?: InputMaybe<Scalars['Int']>
  }

export type ComponentTestimonialCarouselLinkingCollectionsTemplateAboutUsCollectionArgs =
  {
    limit?: InputMaybe<Scalars['Int']>
    locale?: InputMaybe<Scalars['String']>
    order?: InputMaybe<
      Array<
        InputMaybe<ComponentTestimonialCarouselLinkingCollectionsTemplateAboutUsCollectionOrder>
      >
    >
    preview?: InputMaybe<Scalars['Boolean']>
    skip?: InputMaybe<Scalars['Int']>
  }

export type ComponentTestimonialCarouselLinkingCollectionsTemplateBookingCollectionArgs =
  {
    limit?: InputMaybe<Scalars['Int']>
    locale?: InputMaybe<Scalars['String']>
    order?: InputMaybe<
      Array<
        InputMaybe<ComponentTestimonialCarouselLinkingCollectionsTemplateBookingCollectionOrder>
      >
    >
    preview?: InputMaybe<Scalars['Boolean']>
    skip?: InputMaybe<Scalars['Int']>
  }

export type ComponentTestimonialCarouselLinkingCollectionsTemplateCareersCollectionArgs =
  {
    limit?: InputMaybe<Scalars['Int']>
    locale?: InputMaybe<Scalars['String']>
    order?: InputMaybe<
      Array<
        InputMaybe<ComponentTestimonialCarouselLinkingCollectionsTemplateCareersCollectionOrder>
      >
    >
    preview?: InputMaybe<Scalars['Boolean']>
    skip?: InputMaybe<Scalars['Int']>
  }

export type ComponentTestimonialCarouselLinkingCollectionsTemplateContactUsCollectionArgs =
  {
    limit?: InputMaybe<Scalars['Int']>
    locale?: InputMaybe<Scalars['String']>
    order?: InputMaybe<
      Array<
        InputMaybe<ComponentTestimonialCarouselLinkingCollectionsTemplateContactUsCollectionOrder>
      >
    >
    preview?: InputMaybe<Scalars['Boolean']>
    skip?: InputMaybe<Scalars['Int']>
  }

export type ComponentTestimonialCarouselLinkingCollectionsTemplateDestinationsCollectionArgs =
  {
    limit?: InputMaybe<Scalars['Int']>
    locale?: InputMaybe<Scalars['String']>
    order?: InputMaybe<
      Array<
        InputMaybe<ComponentTestimonialCarouselLinkingCollectionsTemplateDestinationsCollectionOrder>
      >
    >
    preview?: InputMaybe<Scalars['Boolean']>
    skip?: InputMaybe<Scalars['Int']>
  }

export type ComponentTestimonialCarouselLinkingCollectionsTemplateForaReserveCollectionArgs =
  {
    limit?: InputMaybe<Scalars['Int']>
    locale?: InputMaybe<Scalars['String']>
    order?: InputMaybe<
      Array<
        InputMaybe<ComponentTestimonialCarouselLinkingCollectionsTemplateForaReserveCollectionOrder>
      >
    >
    preview?: InputMaybe<Scalars['Boolean']>
    skip?: InputMaybe<Scalars['Int']>
  }

export type ComponentTestimonialCarouselLinkingCollectionsTemplateForaXCollectionArgs =
  {
    limit?: InputMaybe<Scalars['Int']>
    locale?: InputMaybe<Scalars['String']>
    order?: InputMaybe<
      Array<
        InputMaybe<ComponentTestimonialCarouselLinkingCollectionsTemplateForaXCollectionOrder>
      >
    >
    preview?: InputMaybe<Scalars['Boolean']>
    skip?: InputMaybe<Scalars['Int']>
  }

export type ComponentTestimonialCarouselLinkingCollectionsTemplateHomeCollectionArgs =
  {
    limit?: InputMaybe<Scalars['Int']>
    locale?: InputMaybe<Scalars['String']>
    order?: InputMaybe<
      Array<
        InputMaybe<ComponentTestimonialCarouselLinkingCollectionsTemplateHomeCollectionOrder>
      >
    >
    preview?: InputMaybe<Scalars['Boolean']>
    skip?: InputMaybe<Scalars['Int']>
  }

export type ComponentTestimonialCarouselLinkingCollectionsTemplateJoinUsCollectionArgs =
  {
    limit?: InputMaybe<Scalars['Int']>
    locale?: InputMaybe<Scalars['String']>
    order?: InputMaybe<
      Array<
        InputMaybe<ComponentTestimonialCarouselLinkingCollectionsTemplateJoinUsCollectionOrder>
      >
    >
    preview?: InputMaybe<Scalars['Boolean']>
    skip?: InputMaybe<Scalars['Int']>
  }

export type ComponentTestimonialCarouselLinkingCollectionsTemplateNewsletterCollectionArgs =
  {
    limit?: InputMaybe<Scalars['Int']>
    locale?: InputMaybe<Scalars['String']>
    order?: InputMaybe<
      Array<
        InputMaybe<ComponentTestimonialCarouselLinkingCollectionsTemplateNewsletterCollectionOrder>
      >
    >
    preview?: InputMaybe<Scalars['Boolean']>
    skip?: InputMaybe<Scalars['Int']>
  }

export type ComponentTestimonialCarouselLinkingCollectionsTemplatePageCollectionArgs =
  {
    limit?: InputMaybe<Scalars['Int']>
    locale?: InputMaybe<Scalars['String']>
    order?: InputMaybe<
      Array<
        InputMaybe<ComponentTestimonialCarouselLinkingCollectionsTemplatePageCollectionOrder>
      >
    >
    preview?: InputMaybe<Scalars['Boolean']>
    skip?: InputMaybe<Scalars['Int']>
  }

export type ComponentTestimonialCarouselLinkingCollectionsTemplatePartnersCollectionArgs =
  {
    limit?: InputMaybe<Scalars['Int']>
    locale?: InputMaybe<Scalars['String']>
    order?: InputMaybe<
      Array<
        InputMaybe<ComponentTestimonialCarouselLinkingCollectionsTemplatePartnersCollectionOrder>
      >
    >
    preview?: InputMaybe<Scalars['Boolean']>
    skip?: InputMaybe<Scalars['Int']>
  }

export type ComponentTestimonialCarouselLinkingCollectionsTemplateReviewsCollectionArgs =
  {
    limit?: InputMaybe<Scalars['Int']>
    locale?: InputMaybe<Scalars['String']>
    order?: InputMaybe<
      Array<
        InputMaybe<ComponentTestimonialCarouselLinkingCollectionsTemplateReviewsCollectionOrder>
      >
    >
    preview?: InputMaybe<Scalars['Boolean']>
    skip?: InputMaybe<Scalars['Int']>
  }

export type ComponentTestimonialCarouselLinkingCollectionsTemplateWhyBookWithForaCollectionArgs =
  {
    limit?: InputMaybe<Scalars['Int']>
    locale?: InputMaybe<Scalars['String']>
    order?: InputMaybe<
      Array<
        InputMaybe<ComponentTestimonialCarouselLinkingCollectionsTemplateWhyBookWithForaCollectionOrder>
      >
    >
    preview?: InputMaybe<Scalars['Boolean']>
    skip?: InputMaybe<Scalars['Int']>
  }

export enum ComponentTestimonialCarouselLinkingCollectionsCampaignPagesCollectionOrder {
  CollapseFooterAsc = 'collapseFooter_ASC',
  CollapseFooterDesc = 'collapseFooter_DESC',
  CollapseHeaderAsc = 'collapseHeader_ASC',
  CollapseHeaderDesc = 'collapseHeader_DESC',
  HideFooterAsc = 'hideFooter_ASC',
  HideFooterDesc = 'hideFooter_DESC',
  HideGlobalBannerAsc = 'hideGlobalBanner_ASC',
  HideGlobalBannerDesc = 'hideGlobalBanner_DESC',
  JotformIdAsc = 'jotformId_ASC',
  JotformIdDesc = 'jotformId_DESC',
  NoindexNofollowAsc = 'noindexNofollow_ASC',
  NoindexNofollowDesc = 'noindexNofollow_DESC',
  ShowHeaderAsc = 'showHeader_ASC',
  ShowHeaderDesc = 'showHeader_DESC',
  SlugAsc = 'slug_ASC',
  SlugDesc = 'slug_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC',
}

export enum ComponentTestimonialCarouselLinkingCollectionsTemplateAboutUsCollectionOrder {
  CollapseHeaderAsc = 'collapseHeader_ASC',
  CollapseHeaderDesc = 'collapseHeader_DESC',
  HeaderBlockTitleAsc = 'headerBlockTitle_ASC',
  HeaderBlockTitleDesc = 'headerBlockTitle_DESC',
  ShowHeaderAsc = 'showHeader_ASC',
  ShowHeaderDesc = 'showHeader_DESC',
  SubtitleAsc = 'subtitle_ASC',
  SubtitleDesc = 'subtitle_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC',
}

export enum ComponentTestimonialCarouselLinkingCollectionsTemplateBookingCollectionOrder {
  CollapseHeaderAsc = 'collapseHeader_ASC',
  CollapseHeaderDesc = 'collapseHeader_DESC',
  ShowHeaderAsc = 'showHeader_ASC',
  ShowHeaderDesc = 'showHeader_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC',
}

export enum ComponentTestimonialCarouselLinkingCollectionsTemplateCareersCollectionOrder {
  CollapseHeaderAsc = 'collapseHeader_ASC',
  CollapseHeaderDesc = 'collapseHeader_DESC',
  ShowHeaderAsc = 'showHeader_ASC',
  ShowHeaderDesc = 'showHeader_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC',
}

export enum ComponentTestimonialCarouselLinkingCollectionsTemplateContactUsCollectionOrder {
  CollapseHeaderAsc = 'collapseHeader_ASC',
  CollapseHeaderDesc = 'collapseHeader_DESC',
  ShowHeaderAsc = 'showHeader_ASC',
  ShowHeaderDesc = 'showHeader_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC',
}

export enum ComponentTestimonialCarouselLinkingCollectionsTemplateDestinationsCollectionOrder {
  CollapseHeaderAsc = 'collapseHeader_ASC',
  CollapseHeaderDesc = 'collapseHeader_DESC',
  CollapsibleHeadingAsc = 'collapsibleHeading_ASC',
  CollapsibleHeadingDesc = 'collapsibleHeading_DESC',
  ShowHeaderAsc = 'showHeader_ASC',
  ShowHeaderDesc = 'showHeader_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC',
}

export enum ComponentTestimonialCarouselLinkingCollectionsTemplateForaReserveCollectionOrder {
  CollapseHeaderAsc = 'collapseHeader_ASC',
  CollapseHeaderDesc = 'collapseHeader_DESC',
  ShowHeaderAsc = 'showHeader_ASC',
  ShowHeaderDesc = 'showHeader_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC',
}

export enum ComponentTestimonialCarouselLinkingCollectionsTemplateForaXCollectionOrder {
  AdvisorGridHeaderAsc = 'advisorGridHeader_ASC',
  AdvisorGridHeaderDesc = 'advisorGridHeader_DESC',
  AdvisorGridSubCopyAsc = 'advisorGridSubCopy_ASC',
  AdvisorGridSubCopyDesc = 'advisorGridSubCopy_DESC',
  CollapseHeaderAsc = 'collapseHeader_ASC',
  CollapseHeaderDesc = 'collapseHeader_DESC',
  ShowHeaderAsc = 'showHeader_ASC',
  ShowHeaderDesc = 'showHeader_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC',
}

export enum ComponentTestimonialCarouselLinkingCollectionsTemplateHomeCollectionOrder {
  CollapseHeaderAsc = 'collapseHeader_ASC',
  CollapseHeaderDesc = 'collapseHeader_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC',
}

export enum ComponentTestimonialCarouselLinkingCollectionsTemplateJoinUsCollectionOrder {
  CollapseHeaderAsc = 'collapseHeader_ASC',
  CollapseHeaderDesc = 'collapseHeader_DESC',
  HideGlobalBannerAsc = 'hideGlobalBanner_ASC',
  HideGlobalBannerDesc = 'hideGlobalBanner_DESC',
  ShowHeaderAsc = 'showHeader_ASC',
  ShowHeaderDesc = 'showHeader_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC',
}

export enum ComponentTestimonialCarouselLinkingCollectionsTemplateNewsletterCollectionOrder {
  CollapseHeaderAsc = 'collapseHeader_ASC',
  CollapseHeaderDesc = 'collapseHeader_DESC',
  ShowHeaderAsc = 'showHeader_ASC',
  ShowHeaderDesc = 'showHeader_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC',
}

export enum ComponentTestimonialCarouselLinkingCollectionsTemplatePageCollectionOrder {
  DirectoryAsc = 'directory_ASC',
  DirectoryDesc = 'directory_DESC',
  InternalLabelAsc = 'internalLabel_ASC',
  InternalLabelDesc = 'internalLabel_DESC',
  MetaTitleAsc = 'metaTitle_ASC',
  MetaTitleDesc = 'metaTitle_DESC',
  NoindexNofollowAsc = 'noindexNofollow_ASC',
  NoindexNofollowDesc = 'noindexNofollow_DESC',
  SlugAsc = 'slug_ASC',
  SlugDesc = 'slug_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
}

export enum ComponentTestimonialCarouselLinkingCollectionsTemplatePartnersCollectionOrder {
  CollapseHeaderAsc = 'collapseHeader_ASC',
  CollapseHeaderDesc = 'collapseHeader_DESC',
  ShowHeaderAsc = 'showHeader_ASC',
  ShowHeaderDesc = 'showHeader_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC',
}

export enum ComponentTestimonialCarouselLinkingCollectionsTemplateReviewsCollectionOrder {
  CollapseHeaderAsc = 'collapseHeader_ASC',
  CollapseHeaderDesc = 'collapseHeader_DESC',
  ShowHeaderAsc = 'showHeader_ASC',
  ShowHeaderDesc = 'showHeader_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC',
}

export enum ComponentTestimonialCarouselLinkingCollectionsTemplateWhyBookWithForaCollectionOrder {
  CollapseHeaderAsc = 'collapseHeader_ASC',
  CollapseHeaderDesc = 'collapseHeader_DESC',
  ShowHeaderAsc = 'showHeader_ASC',
  ShowHeaderDesc = 'showHeader_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC',
}

export enum ComponentTestimonialCarouselOrder {
  HeadingAsc = 'heading_ASC',
  HeadingDesc = 'heading_DESC',
  InternalLabelAsc = 'internalLabel_ASC',
  InternalLabelDesc = 'internalLabel_DESC',
  SectionIdAsc = 'sectionId_ASC',
  SectionIdDesc = 'sectionId_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  TitleStyleAsc = 'titleStyle_ASC',
  TitleStyleDesc = 'titleStyle_DESC',
}

export type ComponentTestimonialCarouselTestimonialsCollection = {
  __typename?: 'ComponentTestimonialCarouselTestimonialsCollection'
  items: Array<Maybe<EntryTestimonial>>
  limit: Scalars['Int']
  skip: Scalars['Int']
  total: Scalars['Int']
}

export enum ComponentTestimonialCarouselTestimonialsCollectionOrder {
  AboutForaAsc = 'aboutFora_ASC',
  AboutForaDesc = 'aboutFora_DESC',
  BeginTravelDateAsc = 'beginTravelDate_ASC',
  BeginTravelDateDesc = 'beginTravelDate_DESC',
  DateSubmittedAsc = 'dateSubmitted_ASC',
  DateSubmittedDesc = 'dateSubmitted_DESC',
  DisplayAsc = 'display_ASC',
  DisplayDesc = 'display_DESC',
  EndTravelDateAsc = 'endTravelDate_ASC',
  EndTravelDateDesc = 'endTravelDate_DESC',
  InternalLabelAsc = 'internalLabel_ASC',
  InternalLabelDesc = 'internalLabel_DESC',
  IsFeaturedAsc = 'isFeatured_ASC',
  IsFeaturedDesc = 'isFeatured_DESC',
  StarsAsc = 'stars_ASC',
  StarsDesc = 'stars_DESC',
  SubmitterEmailAsc = 'submitterEmail_ASC',
  SubmitterEmailDesc = 'submitterEmail_DESC',
  SubmitterFirstNameAsc = 'submitterFirstName_ASC',
  SubmitterFirstNameDesc = 'submitterFirstName_DESC',
  SubmitterLastNameAsc = 'submitterLastName_ASC',
  SubmitterLastNameDesc = 'submitterLastName_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  TestimonialSourceAsc = 'testimonialSource_ASC',
  TestimonialSourceDesc = 'testimonialSource_DESC',
  TitleOfReviewAsc = 'titleOfReview_ASC',
  TitleOfReviewDesc = 'titleOfReview_DESC',
}

/** Basic rich text section [See type definition](https://app.contentful.com/spaces/9cxgygtadmvl/content_types/componentTextSection) */
export type ComponentTextSection = Entry &
  _Node & {
    __typename?: 'ComponentTextSection'
    _id: Scalars['ID']
    alignCenter?: Maybe<Scalars['Boolean']>
    analyticsModule?: Maybe<SettingsAnalyticsModule>
    content?: Maybe<ComponentTextSectionContent>
    contentfulMetadata: ContentfulMetadata
    internalLabel?: Maybe<Scalars['String']>
    linkedFrom?: Maybe<ComponentTextSectionLinkingCollections>
    sectionId?: Maybe<Scalars['String']>
    sys: Sys
  }

/** Basic rich text section [See type definition](https://app.contentful.com/spaces/9cxgygtadmvl/content_types/componentTextSection) */
export type ComponentTextSectionAlignCenterArgs = {
  locale?: InputMaybe<Scalars['String']>
}

/** Basic rich text section [See type definition](https://app.contentful.com/spaces/9cxgygtadmvl/content_types/componentTextSection) */
export type ComponentTextSectionAnalyticsModuleArgs = {
  locale?: InputMaybe<Scalars['String']>
  preview?: InputMaybe<Scalars['Boolean']>
  where?: InputMaybe<SettingsAnalyticsModuleFilter>
}

/** Basic rich text section [See type definition](https://app.contentful.com/spaces/9cxgygtadmvl/content_types/componentTextSection) */
export type ComponentTextSectionContentArgs = {
  locale?: InputMaybe<Scalars['String']>
}

/** Basic rich text section [See type definition](https://app.contentful.com/spaces/9cxgygtadmvl/content_types/componentTextSection) */
export type ComponentTextSectionInternalLabelArgs = {
  locale?: InputMaybe<Scalars['String']>
}

/** Basic rich text section [See type definition](https://app.contentful.com/spaces/9cxgygtadmvl/content_types/componentTextSection) */
export type ComponentTextSectionLinkedFromArgs = {
  allowedLocales?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
}

/** Basic rich text section [See type definition](https://app.contentful.com/spaces/9cxgygtadmvl/content_types/componentTextSection) */
export type ComponentTextSectionSectionIdArgs = {
  locale?: InputMaybe<Scalars['String']>
}

export type ComponentTextSectionCollection = {
  __typename?: 'ComponentTextSectionCollection'
  items: Array<Maybe<ComponentTextSection>>
  limit: Scalars['Int']
  skip: Scalars['Int']
  total: Scalars['Int']
}

export type ComponentTextSectionContent = {
  __typename?: 'ComponentTextSectionContent'
  json: Scalars['JSON']
  links: ComponentTextSectionContentLinks
}

export type ComponentTextSectionContentAssets = {
  __typename?: 'ComponentTextSectionContentAssets'
  block: Array<Maybe<Asset>>
  hyperlink: Array<Maybe<Asset>>
}

export type ComponentTextSectionContentEntries = {
  __typename?: 'ComponentTextSectionContentEntries'
  block: Array<Maybe<Entry>>
  hyperlink: Array<Maybe<Entry>>
  inline: Array<Maybe<Entry>>
}

export type ComponentTextSectionContentLinks = {
  __typename?: 'ComponentTextSectionContentLinks'
  assets: ComponentTextSectionContentAssets
  entries: ComponentTextSectionContentEntries
  resources: ComponentTextSectionContentResources
}

export type ComponentTextSectionContentResources = {
  __typename?: 'ComponentTextSectionContentResources'
  block: Array<ComponentTextSectionContentResourcesBlock>
  hyperlink: Array<ComponentTextSectionContentResourcesHyperlink>
  inline: Array<ComponentTextSectionContentResourcesInline>
}

export type ComponentTextSectionContentResourcesBlock = ResourceLink & {
  __typename?: 'ComponentTextSectionContentResourcesBlock'
  sys: ResourceSys
}

export type ComponentTextSectionContentResourcesHyperlink = ResourceLink & {
  __typename?: 'ComponentTextSectionContentResourcesHyperlink'
  sys: ResourceSys
}

export type ComponentTextSectionContentResourcesInline = ResourceLink & {
  __typename?: 'ComponentTextSectionContentResourcesInline'
  sys: ResourceSys
}

export type ComponentTextSectionFilter = {
  AND?: InputMaybe<Array<InputMaybe<ComponentTextSectionFilter>>>
  OR?: InputMaybe<Array<InputMaybe<ComponentTextSectionFilter>>>
  alignCenter?: InputMaybe<Scalars['Boolean']>
  alignCenter_exists?: InputMaybe<Scalars['Boolean']>
  alignCenter_not?: InputMaybe<Scalars['Boolean']>
  analyticsModule?: InputMaybe<CfSettingsAnalyticsModuleNestedFilter>
  analyticsModule_exists?: InputMaybe<Scalars['Boolean']>
  content_contains?: InputMaybe<Scalars['String']>
  content_exists?: InputMaybe<Scalars['Boolean']>
  content_not_contains?: InputMaybe<Scalars['String']>
  contentfulMetadata?: InputMaybe<ContentfulMetadataFilter>
  internalLabel?: InputMaybe<Scalars['String']>
  internalLabel_contains?: InputMaybe<Scalars['String']>
  internalLabel_exists?: InputMaybe<Scalars['Boolean']>
  internalLabel_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  internalLabel_not?: InputMaybe<Scalars['String']>
  internalLabel_not_contains?: InputMaybe<Scalars['String']>
  internalLabel_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  sectionId?: InputMaybe<Scalars['String']>
  sectionId_contains?: InputMaybe<Scalars['String']>
  sectionId_exists?: InputMaybe<Scalars['Boolean']>
  sectionId_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  sectionId_not?: InputMaybe<Scalars['String']>
  sectionId_not_contains?: InputMaybe<Scalars['String']>
  sectionId_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  sys?: InputMaybe<SysFilter>
}

export type ComponentTextSectionLinkingCollections = {
  __typename?: 'ComponentTextSectionLinkingCollections'
  campaignPagesCollection?: Maybe<CampaignPagesCollection>
  entryCollection?: Maybe<EntryCollection>
  templateAboutUsCollection?: Maybe<TemplateAboutUsCollection>
  templateBookingCollection?: Maybe<TemplateBookingCollection>
  templateCareersCollection?: Maybe<TemplateCareersCollection>
  templateContactUsCollection?: Maybe<TemplateContactUsCollection>
  templateDestinationsCollection?: Maybe<TemplateDestinationsCollection>
  templateForaReserveCollection?: Maybe<TemplateForaReserveCollection>
  templateForaXCollection?: Maybe<TemplateForaXCollection>
  templateHomeCollection?: Maybe<TemplateHomeCollection>
  templateJoinUsCollection?: Maybe<TemplateJoinUsCollection>
  templateNewsletterCollection?: Maybe<TemplateNewsletterCollection>
  templatePageCollection?: Maybe<TemplatePageCollection>
  templatePartnersCollection?: Maybe<TemplatePartnersCollection>
  templateReviewsCollection?: Maybe<TemplateReviewsCollection>
  templateWhyBookWithForaCollection?: Maybe<TemplateWhyBookWithForaCollection>
}

export type ComponentTextSectionLinkingCollectionsCampaignPagesCollectionArgs =
  {
    limit?: InputMaybe<Scalars['Int']>
    locale?: InputMaybe<Scalars['String']>
    order?: InputMaybe<
      Array<
        InputMaybe<ComponentTextSectionLinkingCollectionsCampaignPagesCollectionOrder>
      >
    >
    preview?: InputMaybe<Scalars['Boolean']>
    skip?: InputMaybe<Scalars['Int']>
  }

export type ComponentTextSectionLinkingCollectionsEntryCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>
  locale?: InputMaybe<Scalars['String']>
  preview?: InputMaybe<Scalars['Boolean']>
  skip?: InputMaybe<Scalars['Int']>
}

export type ComponentTextSectionLinkingCollectionsTemplateAboutUsCollectionArgs =
  {
    limit?: InputMaybe<Scalars['Int']>
    locale?: InputMaybe<Scalars['String']>
    order?: InputMaybe<
      Array<
        InputMaybe<ComponentTextSectionLinkingCollectionsTemplateAboutUsCollectionOrder>
      >
    >
    preview?: InputMaybe<Scalars['Boolean']>
    skip?: InputMaybe<Scalars['Int']>
  }

export type ComponentTextSectionLinkingCollectionsTemplateBookingCollectionArgs =
  {
    limit?: InputMaybe<Scalars['Int']>
    locale?: InputMaybe<Scalars['String']>
    order?: InputMaybe<
      Array<
        InputMaybe<ComponentTextSectionLinkingCollectionsTemplateBookingCollectionOrder>
      >
    >
    preview?: InputMaybe<Scalars['Boolean']>
    skip?: InputMaybe<Scalars['Int']>
  }

export type ComponentTextSectionLinkingCollectionsTemplateCareersCollectionArgs =
  {
    limit?: InputMaybe<Scalars['Int']>
    locale?: InputMaybe<Scalars['String']>
    order?: InputMaybe<
      Array<
        InputMaybe<ComponentTextSectionLinkingCollectionsTemplateCareersCollectionOrder>
      >
    >
    preview?: InputMaybe<Scalars['Boolean']>
    skip?: InputMaybe<Scalars['Int']>
  }

export type ComponentTextSectionLinkingCollectionsTemplateContactUsCollectionArgs =
  {
    limit?: InputMaybe<Scalars['Int']>
    locale?: InputMaybe<Scalars['String']>
    order?: InputMaybe<
      Array<
        InputMaybe<ComponentTextSectionLinkingCollectionsTemplateContactUsCollectionOrder>
      >
    >
    preview?: InputMaybe<Scalars['Boolean']>
    skip?: InputMaybe<Scalars['Int']>
  }

export type ComponentTextSectionLinkingCollectionsTemplateDestinationsCollectionArgs =
  {
    limit?: InputMaybe<Scalars['Int']>
    locale?: InputMaybe<Scalars['String']>
    order?: InputMaybe<
      Array<
        InputMaybe<ComponentTextSectionLinkingCollectionsTemplateDestinationsCollectionOrder>
      >
    >
    preview?: InputMaybe<Scalars['Boolean']>
    skip?: InputMaybe<Scalars['Int']>
  }

export type ComponentTextSectionLinkingCollectionsTemplateForaReserveCollectionArgs =
  {
    limit?: InputMaybe<Scalars['Int']>
    locale?: InputMaybe<Scalars['String']>
    order?: InputMaybe<
      Array<
        InputMaybe<ComponentTextSectionLinkingCollectionsTemplateForaReserveCollectionOrder>
      >
    >
    preview?: InputMaybe<Scalars['Boolean']>
    skip?: InputMaybe<Scalars['Int']>
  }

export type ComponentTextSectionLinkingCollectionsTemplateForaXCollectionArgs =
  {
    limit?: InputMaybe<Scalars['Int']>
    locale?: InputMaybe<Scalars['String']>
    order?: InputMaybe<
      Array<
        InputMaybe<ComponentTextSectionLinkingCollectionsTemplateForaXCollectionOrder>
      >
    >
    preview?: InputMaybe<Scalars['Boolean']>
    skip?: InputMaybe<Scalars['Int']>
  }

export type ComponentTextSectionLinkingCollectionsTemplateHomeCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>
  locale?: InputMaybe<Scalars['String']>
  order?: InputMaybe<
    Array<
      InputMaybe<ComponentTextSectionLinkingCollectionsTemplateHomeCollectionOrder>
    >
  >
  preview?: InputMaybe<Scalars['Boolean']>
  skip?: InputMaybe<Scalars['Int']>
}

export type ComponentTextSectionLinkingCollectionsTemplateJoinUsCollectionArgs =
  {
    limit?: InputMaybe<Scalars['Int']>
    locale?: InputMaybe<Scalars['String']>
    order?: InputMaybe<
      Array<
        InputMaybe<ComponentTextSectionLinkingCollectionsTemplateJoinUsCollectionOrder>
      >
    >
    preview?: InputMaybe<Scalars['Boolean']>
    skip?: InputMaybe<Scalars['Int']>
  }

export type ComponentTextSectionLinkingCollectionsTemplateNewsletterCollectionArgs =
  {
    limit?: InputMaybe<Scalars['Int']>
    locale?: InputMaybe<Scalars['String']>
    order?: InputMaybe<
      Array<
        InputMaybe<ComponentTextSectionLinkingCollectionsTemplateNewsletterCollectionOrder>
      >
    >
    preview?: InputMaybe<Scalars['Boolean']>
    skip?: InputMaybe<Scalars['Int']>
  }

export type ComponentTextSectionLinkingCollectionsTemplatePageCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>
  locale?: InputMaybe<Scalars['String']>
  order?: InputMaybe<
    Array<
      InputMaybe<ComponentTextSectionLinkingCollectionsTemplatePageCollectionOrder>
    >
  >
  preview?: InputMaybe<Scalars['Boolean']>
  skip?: InputMaybe<Scalars['Int']>
}

export type ComponentTextSectionLinkingCollectionsTemplatePartnersCollectionArgs =
  {
    limit?: InputMaybe<Scalars['Int']>
    locale?: InputMaybe<Scalars['String']>
    order?: InputMaybe<
      Array<
        InputMaybe<ComponentTextSectionLinkingCollectionsTemplatePartnersCollectionOrder>
      >
    >
    preview?: InputMaybe<Scalars['Boolean']>
    skip?: InputMaybe<Scalars['Int']>
  }

export type ComponentTextSectionLinkingCollectionsTemplateReviewsCollectionArgs =
  {
    limit?: InputMaybe<Scalars['Int']>
    locale?: InputMaybe<Scalars['String']>
    order?: InputMaybe<
      Array<
        InputMaybe<ComponentTextSectionLinkingCollectionsTemplateReviewsCollectionOrder>
      >
    >
    preview?: InputMaybe<Scalars['Boolean']>
    skip?: InputMaybe<Scalars['Int']>
  }

export type ComponentTextSectionLinkingCollectionsTemplateWhyBookWithForaCollectionArgs =
  {
    limit?: InputMaybe<Scalars['Int']>
    locale?: InputMaybe<Scalars['String']>
    order?: InputMaybe<
      Array<
        InputMaybe<ComponentTextSectionLinkingCollectionsTemplateWhyBookWithForaCollectionOrder>
      >
    >
    preview?: InputMaybe<Scalars['Boolean']>
    skip?: InputMaybe<Scalars['Int']>
  }

export enum ComponentTextSectionLinkingCollectionsCampaignPagesCollectionOrder {
  CollapseFooterAsc = 'collapseFooter_ASC',
  CollapseFooterDesc = 'collapseFooter_DESC',
  CollapseHeaderAsc = 'collapseHeader_ASC',
  CollapseHeaderDesc = 'collapseHeader_DESC',
  HideFooterAsc = 'hideFooter_ASC',
  HideFooterDesc = 'hideFooter_DESC',
  HideGlobalBannerAsc = 'hideGlobalBanner_ASC',
  HideGlobalBannerDesc = 'hideGlobalBanner_DESC',
  JotformIdAsc = 'jotformId_ASC',
  JotformIdDesc = 'jotformId_DESC',
  NoindexNofollowAsc = 'noindexNofollow_ASC',
  NoindexNofollowDesc = 'noindexNofollow_DESC',
  ShowHeaderAsc = 'showHeader_ASC',
  ShowHeaderDesc = 'showHeader_DESC',
  SlugAsc = 'slug_ASC',
  SlugDesc = 'slug_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC',
}

export enum ComponentTextSectionLinkingCollectionsTemplateAboutUsCollectionOrder {
  CollapseHeaderAsc = 'collapseHeader_ASC',
  CollapseHeaderDesc = 'collapseHeader_DESC',
  HeaderBlockTitleAsc = 'headerBlockTitle_ASC',
  HeaderBlockTitleDesc = 'headerBlockTitle_DESC',
  ShowHeaderAsc = 'showHeader_ASC',
  ShowHeaderDesc = 'showHeader_DESC',
  SubtitleAsc = 'subtitle_ASC',
  SubtitleDesc = 'subtitle_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC',
}

export enum ComponentTextSectionLinkingCollectionsTemplateBookingCollectionOrder {
  CollapseHeaderAsc = 'collapseHeader_ASC',
  CollapseHeaderDesc = 'collapseHeader_DESC',
  ShowHeaderAsc = 'showHeader_ASC',
  ShowHeaderDesc = 'showHeader_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC',
}

export enum ComponentTextSectionLinkingCollectionsTemplateCareersCollectionOrder {
  CollapseHeaderAsc = 'collapseHeader_ASC',
  CollapseHeaderDesc = 'collapseHeader_DESC',
  ShowHeaderAsc = 'showHeader_ASC',
  ShowHeaderDesc = 'showHeader_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC',
}

export enum ComponentTextSectionLinkingCollectionsTemplateContactUsCollectionOrder {
  CollapseHeaderAsc = 'collapseHeader_ASC',
  CollapseHeaderDesc = 'collapseHeader_DESC',
  ShowHeaderAsc = 'showHeader_ASC',
  ShowHeaderDesc = 'showHeader_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC',
}

export enum ComponentTextSectionLinkingCollectionsTemplateDestinationsCollectionOrder {
  CollapseHeaderAsc = 'collapseHeader_ASC',
  CollapseHeaderDesc = 'collapseHeader_DESC',
  CollapsibleHeadingAsc = 'collapsibleHeading_ASC',
  CollapsibleHeadingDesc = 'collapsibleHeading_DESC',
  ShowHeaderAsc = 'showHeader_ASC',
  ShowHeaderDesc = 'showHeader_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC',
}

export enum ComponentTextSectionLinkingCollectionsTemplateForaReserveCollectionOrder {
  CollapseHeaderAsc = 'collapseHeader_ASC',
  CollapseHeaderDesc = 'collapseHeader_DESC',
  ShowHeaderAsc = 'showHeader_ASC',
  ShowHeaderDesc = 'showHeader_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC',
}

export enum ComponentTextSectionLinkingCollectionsTemplateForaXCollectionOrder {
  AdvisorGridHeaderAsc = 'advisorGridHeader_ASC',
  AdvisorGridHeaderDesc = 'advisorGridHeader_DESC',
  AdvisorGridSubCopyAsc = 'advisorGridSubCopy_ASC',
  AdvisorGridSubCopyDesc = 'advisorGridSubCopy_DESC',
  CollapseHeaderAsc = 'collapseHeader_ASC',
  CollapseHeaderDesc = 'collapseHeader_DESC',
  ShowHeaderAsc = 'showHeader_ASC',
  ShowHeaderDesc = 'showHeader_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC',
}

export enum ComponentTextSectionLinkingCollectionsTemplateHomeCollectionOrder {
  CollapseHeaderAsc = 'collapseHeader_ASC',
  CollapseHeaderDesc = 'collapseHeader_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC',
}

export enum ComponentTextSectionLinkingCollectionsTemplateJoinUsCollectionOrder {
  CollapseHeaderAsc = 'collapseHeader_ASC',
  CollapseHeaderDesc = 'collapseHeader_DESC',
  HideGlobalBannerAsc = 'hideGlobalBanner_ASC',
  HideGlobalBannerDesc = 'hideGlobalBanner_DESC',
  ShowHeaderAsc = 'showHeader_ASC',
  ShowHeaderDesc = 'showHeader_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC',
}

export enum ComponentTextSectionLinkingCollectionsTemplateNewsletterCollectionOrder {
  CollapseHeaderAsc = 'collapseHeader_ASC',
  CollapseHeaderDesc = 'collapseHeader_DESC',
  ShowHeaderAsc = 'showHeader_ASC',
  ShowHeaderDesc = 'showHeader_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC',
}

export enum ComponentTextSectionLinkingCollectionsTemplatePageCollectionOrder {
  DirectoryAsc = 'directory_ASC',
  DirectoryDesc = 'directory_DESC',
  InternalLabelAsc = 'internalLabel_ASC',
  InternalLabelDesc = 'internalLabel_DESC',
  MetaTitleAsc = 'metaTitle_ASC',
  MetaTitleDesc = 'metaTitle_DESC',
  NoindexNofollowAsc = 'noindexNofollow_ASC',
  NoindexNofollowDesc = 'noindexNofollow_DESC',
  SlugAsc = 'slug_ASC',
  SlugDesc = 'slug_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
}

export enum ComponentTextSectionLinkingCollectionsTemplatePartnersCollectionOrder {
  CollapseHeaderAsc = 'collapseHeader_ASC',
  CollapseHeaderDesc = 'collapseHeader_DESC',
  ShowHeaderAsc = 'showHeader_ASC',
  ShowHeaderDesc = 'showHeader_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC',
}

export enum ComponentTextSectionLinkingCollectionsTemplateReviewsCollectionOrder {
  CollapseHeaderAsc = 'collapseHeader_ASC',
  CollapseHeaderDesc = 'collapseHeader_DESC',
  ShowHeaderAsc = 'showHeader_ASC',
  ShowHeaderDesc = 'showHeader_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC',
}

export enum ComponentTextSectionLinkingCollectionsTemplateWhyBookWithForaCollectionOrder {
  CollapseHeaderAsc = 'collapseHeader_ASC',
  CollapseHeaderDesc = 'collapseHeader_DESC',
  ShowHeaderAsc = 'showHeader_ASC',
  ShowHeaderDesc = 'showHeader_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC',
}

export enum ComponentTextSectionOrder {
  AlignCenterAsc = 'alignCenter_ASC',
  AlignCenterDesc = 'alignCenter_DESC',
  InternalLabelAsc = 'internalLabel_ASC',
  InternalLabelDesc = 'internalLabel_DESC',
  SectionIdAsc = 'sectionId_ASC',
  SectionIdDesc = 'sectionId_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
}

/** Create and manage Learn More Blocks. [See type definition](https://app.contentful.com/spaces/9cxgygtadmvl/content_types/componentThreeIconSection) */
export type ComponentThreeIconSection = Entry &
  _Node & {
    __typename?: 'ComponentThreeIconSection'
    _id: Scalars['ID']
    analyticsModule?: Maybe<SettingsAnalyticsModule>
    contentfulMetadata: ContentfulMetadata
    ctaText?: Maybe<Scalars['String']>
    ctaUrl?: Maybe<Scalars['String']>
    imageColumnOne?: Maybe<Asset>
    imageColumnThree?: Maybe<Asset>
    imageColumnTwo?: Maybe<Asset>
    isCompact?: Maybe<Scalars['Boolean']>
    linkedFrom?: Maybe<ComponentThreeIconSectionLinkingCollections>
    sectionId?: Maybe<Scalars['String']>
    solidBackground?: Maybe<Scalars['Boolean']>
    sys: Sys
    teaserCopy?: Maybe<ComponentThreeIconSectionTeaserCopy>
    textColumnOne?: Maybe<ComponentThreeIconSectionTextColumnOne>
    textColumnThree?: Maybe<ComponentThreeIconSectionTextColumnThree>
    textColumnTwo?: Maybe<ComponentThreeIconSectionTextColumnTwo>
    title?: Maybe<Scalars['String']>
    titleStyle?: Maybe<Scalars['String']>
    urlTarget?: Maybe<Scalars['String']>
  }

/** Create and manage Learn More Blocks. [See type definition](https://app.contentful.com/spaces/9cxgygtadmvl/content_types/componentThreeIconSection) */
export type ComponentThreeIconSectionAnalyticsModuleArgs = {
  locale?: InputMaybe<Scalars['String']>
  preview?: InputMaybe<Scalars['Boolean']>
  where?: InputMaybe<SettingsAnalyticsModuleFilter>
}

/** Create and manage Learn More Blocks. [See type definition](https://app.contentful.com/spaces/9cxgygtadmvl/content_types/componentThreeIconSection) */
export type ComponentThreeIconSectionCtaTextArgs = {
  locale?: InputMaybe<Scalars['String']>
}

/** Create and manage Learn More Blocks. [See type definition](https://app.contentful.com/spaces/9cxgygtadmvl/content_types/componentThreeIconSection) */
export type ComponentThreeIconSectionCtaUrlArgs = {
  locale?: InputMaybe<Scalars['String']>
}

/** Create and manage Learn More Blocks. [See type definition](https://app.contentful.com/spaces/9cxgygtadmvl/content_types/componentThreeIconSection) */
export type ComponentThreeIconSectionImageColumnOneArgs = {
  locale?: InputMaybe<Scalars['String']>
  preview?: InputMaybe<Scalars['Boolean']>
}

/** Create and manage Learn More Blocks. [See type definition](https://app.contentful.com/spaces/9cxgygtadmvl/content_types/componentThreeIconSection) */
export type ComponentThreeIconSectionImageColumnThreeArgs = {
  locale?: InputMaybe<Scalars['String']>
  preview?: InputMaybe<Scalars['Boolean']>
}

/** Create and manage Learn More Blocks. [See type definition](https://app.contentful.com/spaces/9cxgygtadmvl/content_types/componentThreeIconSection) */
export type ComponentThreeIconSectionImageColumnTwoArgs = {
  locale?: InputMaybe<Scalars['String']>
  preview?: InputMaybe<Scalars['Boolean']>
}

/** Create and manage Learn More Blocks. [See type definition](https://app.contentful.com/spaces/9cxgygtadmvl/content_types/componentThreeIconSection) */
export type ComponentThreeIconSectionIsCompactArgs = {
  locale?: InputMaybe<Scalars['String']>
}

/** Create and manage Learn More Blocks. [See type definition](https://app.contentful.com/spaces/9cxgygtadmvl/content_types/componentThreeIconSection) */
export type ComponentThreeIconSectionLinkedFromArgs = {
  allowedLocales?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
}

/** Create and manage Learn More Blocks. [See type definition](https://app.contentful.com/spaces/9cxgygtadmvl/content_types/componentThreeIconSection) */
export type ComponentThreeIconSectionSectionIdArgs = {
  locale?: InputMaybe<Scalars['String']>
}

/** Create and manage Learn More Blocks. [See type definition](https://app.contentful.com/spaces/9cxgygtadmvl/content_types/componentThreeIconSection) */
export type ComponentThreeIconSectionSolidBackgroundArgs = {
  locale?: InputMaybe<Scalars['String']>
}

/** Create and manage Learn More Blocks. [See type definition](https://app.contentful.com/spaces/9cxgygtadmvl/content_types/componentThreeIconSection) */
export type ComponentThreeIconSectionTeaserCopyArgs = {
  locale?: InputMaybe<Scalars['String']>
}

/** Create and manage Learn More Blocks. [See type definition](https://app.contentful.com/spaces/9cxgygtadmvl/content_types/componentThreeIconSection) */
export type ComponentThreeIconSectionTextColumnOneArgs = {
  locale?: InputMaybe<Scalars['String']>
}

/** Create and manage Learn More Blocks. [See type definition](https://app.contentful.com/spaces/9cxgygtadmvl/content_types/componentThreeIconSection) */
export type ComponentThreeIconSectionTextColumnThreeArgs = {
  locale?: InputMaybe<Scalars['String']>
}

/** Create and manage Learn More Blocks. [See type definition](https://app.contentful.com/spaces/9cxgygtadmvl/content_types/componentThreeIconSection) */
export type ComponentThreeIconSectionTextColumnTwoArgs = {
  locale?: InputMaybe<Scalars['String']>
}

/** Create and manage Learn More Blocks. [See type definition](https://app.contentful.com/spaces/9cxgygtadmvl/content_types/componentThreeIconSection) */
export type ComponentThreeIconSectionTitleArgs = {
  locale?: InputMaybe<Scalars['String']>
}

/** Create and manage Learn More Blocks. [See type definition](https://app.contentful.com/spaces/9cxgygtadmvl/content_types/componentThreeIconSection) */
export type ComponentThreeIconSectionTitleStyleArgs = {
  locale?: InputMaybe<Scalars['String']>
}

/** Create and manage Learn More Blocks. [See type definition](https://app.contentful.com/spaces/9cxgygtadmvl/content_types/componentThreeIconSection) */
export type ComponentThreeIconSectionUrlTargetArgs = {
  locale?: InputMaybe<Scalars['String']>
}

export type ComponentThreeIconSectionCollection = {
  __typename?: 'ComponentThreeIconSectionCollection'
  items: Array<Maybe<ComponentThreeIconSection>>
  limit: Scalars['Int']
  skip: Scalars['Int']
  total: Scalars['Int']
}

export type ComponentThreeIconSectionFilter = {
  AND?: InputMaybe<Array<InputMaybe<ComponentThreeIconSectionFilter>>>
  OR?: InputMaybe<Array<InputMaybe<ComponentThreeIconSectionFilter>>>
  analyticsModule?: InputMaybe<CfSettingsAnalyticsModuleNestedFilter>
  analyticsModule_exists?: InputMaybe<Scalars['Boolean']>
  contentfulMetadata?: InputMaybe<ContentfulMetadataFilter>
  ctaText?: InputMaybe<Scalars['String']>
  ctaText_contains?: InputMaybe<Scalars['String']>
  ctaText_exists?: InputMaybe<Scalars['Boolean']>
  ctaText_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  ctaText_not?: InputMaybe<Scalars['String']>
  ctaText_not_contains?: InputMaybe<Scalars['String']>
  ctaText_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  ctaUrl?: InputMaybe<Scalars['String']>
  ctaUrl_contains?: InputMaybe<Scalars['String']>
  ctaUrl_exists?: InputMaybe<Scalars['Boolean']>
  ctaUrl_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  ctaUrl_not?: InputMaybe<Scalars['String']>
  ctaUrl_not_contains?: InputMaybe<Scalars['String']>
  ctaUrl_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  imageColumnOne_exists?: InputMaybe<Scalars['Boolean']>
  imageColumnThree_exists?: InputMaybe<Scalars['Boolean']>
  imageColumnTwo_exists?: InputMaybe<Scalars['Boolean']>
  isCompact?: InputMaybe<Scalars['Boolean']>
  isCompact_exists?: InputMaybe<Scalars['Boolean']>
  isCompact_not?: InputMaybe<Scalars['Boolean']>
  sectionId?: InputMaybe<Scalars['String']>
  sectionId_contains?: InputMaybe<Scalars['String']>
  sectionId_exists?: InputMaybe<Scalars['Boolean']>
  sectionId_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  sectionId_not?: InputMaybe<Scalars['String']>
  sectionId_not_contains?: InputMaybe<Scalars['String']>
  sectionId_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  solidBackground?: InputMaybe<Scalars['Boolean']>
  solidBackground_exists?: InputMaybe<Scalars['Boolean']>
  solidBackground_not?: InputMaybe<Scalars['Boolean']>
  sys?: InputMaybe<SysFilter>
  teaserCopy_contains?: InputMaybe<Scalars['String']>
  teaserCopy_exists?: InputMaybe<Scalars['Boolean']>
  teaserCopy_not_contains?: InputMaybe<Scalars['String']>
  textColumnOne_contains?: InputMaybe<Scalars['String']>
  textColumnOne_exists?: InputMaybe<Scalars['Boolean']>
  textColumnOne_not_contains?: InputMaybe<Scalars['String']>
  textColumnThree_contains?: InputMaybe<Scalars['String']>
  textColumnThree_exists?: InputMaybe<Scalars['Boolean']>
  textColumnThree_not_contains?: InputMaybe<Scalars['String']>
  textColumnTwo_contains?: InputMaybe<Scalars['String']>
  textColumnTwo_exists?: InputMaybe<Scalars['Boolean']>
  textColumnTwo_not_contains?: InputMaybe<Scalars['String']>
  title?: InputMaybe<Scalars['String']>
  titleStyle?: InputMaybe<Scalars['String']>
  titleStyle_contains?: InputMaybe<Scalars['String']>
  titleStyle_exists?: InputMaybe<Scalars['Boolean']>
  titleStyle_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  titleStyle_not?: InputMaybe<Scalars['String']>
  titleStyle_not_contains?: InputMaybe<Scalars['String']>
  titleStyle_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  title_contains?: InputMaybe<Scalars['String']>
  title_exists?: InputMaybe<Scalars['Boolean']>
  title_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  title_not?: InputMaybe<Scalars['String']>
  title_not_contains?: InputMaybe<Scalars['String']>
  title_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  urlTarget?: InputMaybe<Scalars['String']>
  urlTarget_contains?: InputMaybe<Scalars['String']>
  urlTarget_exists?: InputMaybe<Scalars['Boolean']>
  urlTarget_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  urlTarget_not?: InputMaybe<Scalars['String']>
  urlTarget_not_contains?: InputMaybe<Scalars['String']>
  urlTarget_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
}

export type ComponentThreeIconSectionLinkingCollections = {
  __typename?: 'ComponentThreeIconSectionLinkingCollections'
  entryCollection?: Maybe<EntryCollection>
  entryPartnerCollection?: Maybe<EntryPartnerCollection>
  templateAboutUsCollection?: Maybe<TemplateAboutUsCollection>
  templateDestinationsCollection?: Maybe<TemplateDestinationsCollection>
  templateGuidesCollection?: Maybe<TemplateGuidesCollection>
  templateHomeCollection?: Maybe<TemplateHomeCollection>
  templateItinerariesCollection?: Maybe<TemplateItinerariesCollection>
  templateJoinUsCollection?: Maybe<TemplateJoinUsCollection>
  templateTravelByCollection?: Maybe<TemplateTravelByCollection>
  templateTravelCollection?: Maybe<TemplateTravelCollection>
  templateWhyBookWithForaCollection?: Maybe<TemplateWhyBookWithForaCollection>
}

export type ComponentThreeIconSectionLinkingCollectionsEntryCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>
  locale?: InputMaybe<Scalars['String']>
  preview?: InputMaybe<Scalars['Boolean']>
  skip?: InputMaybe<Scalars['Int']>
}

export type ComponentThreeIconSectionLinkingCollectionsEntryPartnerCollectionArgs =
  {
    limit?: InputMaybe<Scalars['Int']>
    locale?: InputMaybe<Scalars['String']>
    order?: InputMaybe<
      Array<
        InputMaybe<ComponentThreeIconSectionLinkingCollectionsEntryPartnerCollectionOrder>
      >
    >
    preview?: InputMaybe<Scalars['Boolean']>
    skip?: InputMaybe<Scalars['Int']>
  }

export type ComponentThreeIconSectionLinkingCollectionsTemplateAboutUsCollectionArgs =
  {
    limit?: InputMaybe<Scalars['Int']>
    locale?: InputMaybe<Scalars['String']>
    order?: InputMaybe<
      Array<
        InputMaybe<ComponentThreeIconSectionLinkingCollectionsTemplateAboutUsCollectionOrder>
      >
    >
    preview?: InputMaybe<Scalars['Boolean']>
    skip?: InputMaybe<Scalars['Int']>
  }

export type ComponentThreeIconSectionLinkingCollectionsTemplateDestinationsCollectionArgs =
  {
    limit?: InputMaybe<Scalars['Int']>
    locale?: InputMaybe<Scalars['String']>
    order?: InputMaybe<
      Array<
        InputMaybe<ComponentThreeIconSectionLinkingCollectionsTemplateDestinationsCollectionOrder>
      >
    >
    preview?: InputMaybe<Scalars['Boolean']>
    skip?: InputMaybe<Scalars['Int']>
  }

export type ComponentThreeIconSectionLinkingCollectionsTemplateGuidesCollectionArgs =
  {
    limit?: InputMaybe<Scalars['Int']>
    locale?: InputMaybe<Scalars['String']>
    order?: InputMaybe<
      Array<
        InputMaybe<ComponentThreeIconSectionLinkingCollectionsTemplateGuidesCollectionOrder>
      >
    >
    preview?: InputMaybe<Scalars['Boolean']>
    skip?: InputMaybe<Scalars['Int']>
  }

export type ComponentThreeIconSectionLinkingCollectionsTemplateHomeCollectionArgs =
  {
    limit?: InputMaybe<Scalars['Int']>
    locale?: InputMaybe<Scalars['String']>
    order?: InputMaybe<
      Array<
        InputMaybe<ComponentThreeIconSectionLinkingCollectionsTemplateHomeCollectionOrder>
      >
    >
    preview?: InputMaybe<Scalars['Boolean']>
    skip?: InputMaybe<Scalars['Int']>
  }

export type ComponentThreeIconSectionLinkingCollectionsTemplateItinerariesCollectionArgs =
  {
    limit?: InputMaybe<Scalars['Int']>
    locale?: InputMaybe<Scalars['String']>
    order?: InputMaybe<
      Array<
        InputMaybe<ComponentThreeIconSectionLinkingCollectionsTemplateItinerariesCollectionOrder>
      >
    >
    preview?: InputMaybe<Scalars['Boolean']>
    skip?: InputMaybe<Scalars['Int']>
  }

export type ComponentThreeIconSectionLinkingCollectionsTemplateJoinUsCollectionArgs =
  {
    limit?: InputMaybe<Scalars['Int']>
    locale?: InputMaybe<Scalars['String']>
    order?: InputMaybe<
      Array<
        InputMaybe<ComponentThreeIconSectionLinkingCollectionsTemplateJoinUsCollectionOrder>
      >
    >
    preview?: InputMaybe<Scalars['Boolean']>
    skip?: InputMaybe<Scalars['Int']>
  }

export type ComponentThreeIconSectionLinkingCollectionsTemplateTravelByCollectionArgs =
  {
    limit?: InputMaybe<Scalars['Int']>
    locale?: InputMaybe<Scalars['String']>
    order?: InputMaybe<
      Array<
        InputMaybe<ComponentThreeIconSectionLinkingCollectionsTemplateTravelByCollectionOrder>
      >
    >
    preview?: InputMaybe<Scalars['Boolean']>
    skip?: InputMaybe<Scalars['Int']>
  }

export type ComponentThreeIconSectionLinkingCollectionsTemplateTravelCollectionArgs =
  {
    limit?: InputMaybe<Scalars['Int']>
    locale?: InputMaybe<Scalars['String']>
    order?: InputMaybe<
      Array<
        InputMaybe<ComponentThreeIconSectionLinkingCollectionsTemplateTravelCollectionOrder>
      >
    >
    preview?: InputMaybe<Scalars['Boolean']>
    skip?: InputMaybe<Scalars['Int']>
  }

export type ComponentThreeIconSectionLinkingCollectionsTemplateWhyBookWithForaCollectionArgs =
  {
    limit?: InputMaybe<Scalars['Int']>
    locale?: InputMaybe<Scalars['String']>
    order?: InputMaybe<
      Array<
        InputMaybe<ComponentThreeIconSectionLinkingCollectionsTemplateWhyBookWithForaCollectionOrder>
      >
    >
    preview?: InputMaybe<Scalars['Boolean']>
    skip?: InputMaybe<Scalars['Int']>
  }

export enum ComponentThreeIconSectionLinkingCollectionsEntryPartnerCollectionOrder {
  DetailSummaryAsc = 'detailSummary_ASC',
  DetailSummaryDesc = 'detailSummary_DESC',
  FeaturedAsc = 'featured_ASC',
  FeaturedDesc = 'featured_DESC',
  HasDetailPageAsc = 'hasDetailPage_ASC',
  HasDetailPageDesc = 'hasDetailPage_DESC',
  HideFromPartnerGridAsc = 'hideFromPartnerGrid_ASC',
  HideFromPartnerGridDesc = 'hideFromPartnerGrid_DESC',
  NameAsc = 'name_ASC',
  NameDesc = 'name_DESC',
  PartnerCategoryAsc = 'partnerCategory_ASC',
  PartnerCategoryDesc = 'partnerCategory_DESC',
  PartnerTypeAsc = 'partnerType_ASC',
  PartnerTypeDesc = 'partnerType_DESC',
  SlugAsc = 'slug_ASC',
  SlugDesc = 'slug_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
}

export enum ComponentThreeIconSectionLinkingCollectionsTemplateAboutUsCollectionOrder {
  CollapseHeaderAsc = 'collapseHeader_ASC',
  CollapseHeaderDesc = 'collapseHeader_DESC',
  HeaderBlockTitleAsc = 'headerBlockTitle_ASC',
  HeaderBlockTitleDesc = 'headerBlockTitle_DESC',
  ShowHeaderAsc = 'showHeader_ASC',
  ShowHeaderDesc = 'showHeader_DESC',
  SubtitleAsc = 'subtitle_ASC',
  SubtitleDesc = 'subtitle_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC',
}

export enum ComponentThreeIconSectionLinkingCollectionsTemplateDestinationsCollectionOrder {
  CollapseHeaderAsc = 'collapseHeader_ASC',
  CollapseHeaderDesc = 'collapseHeader_DESC',
  CollapsibleHeadingAsc = 'collapsibleHeading_ASC',
  CollapsibleHeadingDesc = 'collapsibleHeading_DESC',
  ShowHeaderAsc = 'showHeader_ASC',
  ShowHeaderDesc = 'showHeader_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC',
}

export enum ComponentThreeIconSectionLinkingCollectionsTemplateGuidesCollectionOrder {
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC',
}

export enum ComponentThreeIconSectionLinkingCollectionsTemplateHomeCollectionOrder {
  CollapseHeaderAsc = 'collapseHeader_ASC',
  CollapseHeaderDesc = 'collapseHeader_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC',
}

export enum ComponentThreeIconSectionLinkingCollectionsTemplateItinerariesCollectionOrder {
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC',
}

export enum ComponentThreeIconSectionLinkingCollectionsTemplateJoinUsCollectionOrder {
  CollapseHeaderAsc = 'collapseHeader_ASC',
  CollapseHeaderDesc = 'collapseHeader_DESC',
  HideGlobalBannerAsc = 'hideGlobalBanner_ASC',
  HideGlobalBannerDesc = 'hideGlobalBanner_DESC',
  ShowHeaderAsc = 'showHeader_ASC',
  ShowHeaderDesc = 'showHeader_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC',
}

export enum ComponentThreeIconSectionLinkingCollectionsTemplateTravelByCollectionOrder {
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC',
}

export enum ComponentThreeIconSectionLinkingCollectionsTemplateTravelCollectionOrder {
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC',
  TravelByHeadingAsc = 'travelByHeading_ASC',
  TravelByHeadingDesc = 'travelByHeading_DESC',
}

export enum ComponentThreeIconSectionLinkingCollectionsTemplateWhyBookWithForaCollectionOrder {
  CollapseHeaderAsc = 'collapseHeader_ASC',
  CollapseHeaderDesc = 'collapseHeader_DESC',
  ShowHeaderAsc = 'showHeader_ASC',
  ShowHeaderDesc = 'showHeader_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC',
}

export enum ComponentThreeIconSectionOrder {
  CtaTextAsc = 'ctaText_ASC',
  CtaTextDesc = 'ctaText_DESC',
  CtaUrlAsc = 'ctaUrl_ASC',
  CtaUrlDesc = 'ctaUrl_DESC',
  IsCompactAsc = 'isCompact_ASC',
  IsCompactDesc = 'isCompact_DESC',
  SectionIdAsc = 'sectionId_ASC',
  SectionIdDesc = 'sectionId_DESC',
  SolidBackgroundAsc = 'solidBackground_ASC',
  SolidBackgroundDesc = 'solidBackground_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  TitleStyleAsc = 'titleStyle_ASC',
  TitleStyleDesc = 'titleStyle_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC',
  UrlTargetAsc = 'urlTarget_ASC',
  UrlTargetDesc = 'urlTarget_DESC',
}

export type ComponentThreeIconSectionTeaserCopy = {
  __typename?: 'ComponentThreeIconSectionTeaserCopy'
  json: Scalars['JSON']
  links: ComponentThreeIconSectionTeaserCopyLinks
}

export type ComponentThreeIconSectionTeaserCopyAssets = {
  __typename?: 'ComponentThreeIconSectionTeaserCopyAssets'
  block: Array<Maybe<Asset>>
  hyperlink: Array<Maybe<Asset>>
}

export type ComponentThreeIconSectionTeaserCopyEntries = {
  __typename?: 'ComponentThreeIconSectionTeaserCopyEntries'
  block: Array<Maybe<Entry>>
  hyperlink: Array<Maybe<Entry>>
  inline: Array<Maybe<Entry>>
}

export type ComponentThreeIconSectionTeaserCopyLinks = {
  __typename?: 'ComponentThreeIconSectionTeaserCopyLinks'
  assets: ComponentThreeIconSectionTeaserCopyAssets
  entries: ComponentThreeIconSectionTeaserCopyEntries
  resources: ComponentThreeIconSectionTeaserCopyResources
}

export type ComponentThreeIconSectionTeaserCopyResources = {
  __typename?: 'ComponentThreeIconSectionTeaserCopyResources'
  block: Array<ComponentThreeIconSectionTeaserCopyResourcesBlock>
  hyperlink: Array<ComponentThreeIconSectionTeaserCopyResourcesHyperlink>
  inline: Array<ComponentThreeIconSectionTeaserCopyResourcesInline>
}

export type ComponentThreeIconSectionTeaserCopyResourcesBlock = ResourceLink & {
  __typename?: 'ComponentThreeIconSectionTeaserCopyResourcesBlock'
  sys: ResourceSys
}

export type ComponentThreeIconSectionTeaserCopyResourcesHyperlink =
  ResourceLink & {
    __typename?: 'ComponentThreeIconSectionTeaserCopyResourcesHyperlink'
    sys: ResourceSys
  }

export type ComponentThreeIconSectionTeaserCopyResourcesInline =
  ResourceLink & {
    __typename?: 'ComponentThreeIconSectionTeaserCopyResourcesInline'
    sys: ResourceSys
  }

export type ComponentThreeIconSectionTextColumnOne = {
  __typename?: 'ComponentThreeIconSectionTextColumnOne'
  json: Scalars['JSON']
  links: ComponentThreeIconSectionTextColumnOneLinks
}

export type ComponentThreeIconSectionTextColumnOneAssets = {
  __typename?: 'ComponentThreeIconSectionTextColumnOneAssets'
  block: Array<Maybe<Asset>>
  hyperlink: Array<Maybe<Asset>>
}

export type ComponentThreeIconSectionTextColumnOneEntries = {
  __typename?: 'ComponentThreeIconSectionTextColumnOneEntries'
  block: Array<Maybe<Entry>>
  hyperlink: Array<Maybe<Entry>>
  inline: Array<Maybe<Entry>>
}

export type ComponentThreeIconSectionTextColumnOneLinks = {
  __typename?: 'ComponentThreeIconSectionTextColumnOneLinks'
  assets: ComponentThreeIconSectionTextColumnOneAssets
  entries: ComponentThreeIconSectionTextColumnOneEntries
  resources: ComponentThreeIconSectionTextColumnOneResources
}

export type ComponentThreeIconSectionTextColumnOneResources = {
  __typename?: 'ComponentThreeIconSectionTextColumnOneResources'
  block: Array<ComponentThreeIconSectionTextColumnOneResourcesBlock>
  hyperlink: Array<ComponentThreeIconSectionTextColumnOneResourcesHyperlink>
  inline: Array<ComponentThreeIconSectionTextColumnOneResourcesInline>
}

export type ComponentThreeIconSectionTextColumnOneResourcesBlock =
  ResourceLink & {
    __typename?: 'ComponentThreeIconSectionTextColumnOneResourcesBlock'
    sys: ResourceSys
  }

export type ComponentThreeIconSectionTextColumnOneResourcesHyperlink =
  ResourceLink & {
    __typename?: 'ComponentThreeIconSectionTextColumnOneResourcesHyperlink'
    sys: ResourceSys
  }

export type ComponentThreeIconSectionTextColumnOneResourcesInline =
  ResourceLink & {
    __typename?: 'ComponentThreeIconSectionTextColumnOneResourcesInline'
    sys: ResourceSys
  }

export type ComponentThreeIconSectionTextColumnThree = {
  __typename?: 'ComponentThreeIconSectionTextColumnThree'
  json: Scalars['JSON']
  links: ComponentThreeIconSectionTextColumnThreeLinks
}

export type ComponentThreeIconSectionTextColumnThreeAssets = {
  __typename?: 'ComponentThreeIconSectionTextColumnThreeAssets'
  block: Array<Maybe<Asset>>
  hyperlink: Array<Maybe<Asset>>
}

export type ComponentThreeIconSectionTextColumnThreeEntries = {
  __typename?: 'ComponentThreeIconSectionTextColumnThreeEntries'
  block: Array<Maybe<Entry>>
  hyperlink: Array<Maybe<Entry>>
  inline: Array<Maybe<Entry>>
}

export type ComponentThreeIconSectionTextColumnThreeLinks = {
  __typename?: 'ComponentThreeIconSectionTextColumnThreeLinks'
  assets: ComponentThreeIconSectionTextColumnThreeAssets
  entries: ComponentThreeIconSectionTextColumnThreeEntries
  resources: ComponentThreeIconSectionTextColumnThreeResources
}

export type ComponentThreeIconSectionTextColumnThreeResources = {
  __typename?: 'ComponentThreeIconSectionTextColumnThreeResources'
  block: Array<ComponentThreeIconSectionTextColumnThreeResourcesBlock>
  hyperlink: Array<ComponentThreeIconSectionTextColumnThreeResourcesHyperlink>
  inline: Array<ComponentThreeIconSectionTextColumnThreeResourcesInline>
}

export type ComponentThreeIconSectionTextColumnThreeResourcesBlock =
  ResourceLink & {
    __typename?: 'ComponentThreeIconSectionTextColumnThreeResourcesBlock'
    sys: ResourceSys
  }

export type ComponentThreeIconSectionTextColumnThreeResourcesHyperlink =
  ResourceLink & {
    __typename?: 'ComponentThreeIconSectionTextColumnThreeResourcesHyperlink'
    sys: ResourceSys
  }

export type ComponentThreeIconSectionTextColumnThreeResourcesInline =
  ResourceLink & {
    __typename?: 'ComponentThreeIconSectionTextColumnThreeResourcesInline'
    sys: ResourceSys
  }

export type ComponentThreeIconSectionTextColumnTwo = {
  __typename?: 'ComponentThreeIconSectionTextColumnTwo'
  json: Scalars['JSON']
  links: ComponentThreeIconSectionTextColumnTwoLinks
}

export type ComponentThreeIconSectionTextColumnTwoAssets = {
  __typename?: 'ComponentThreeIconSectionTextColumnTwoAssets'
  block: Array<Maybe<Asset>>
  hyperlink: Array<Maybe<Asset>>
}

export type ComponentThreeIconSectionTextColumnTwoEntries = {
  __typename?: 'ComponentThreeIconSectionTextColumnTwoEntries'
  block: Array<Maybe<Entry>>
  hyperlink: Array<Maybe<Entry>>
  inline: Array<Maybe<Entry>>
}

export type ComponentThreeIconSectionTextColumnTwoLinks = {
  __typename?: 'ComponentThreeIconSectionTextColumnTwoLinks'
  assets: ComponentThreeIconSectionTextColumnTwoAssets
  entries: ComponentThreeIconSectionTextColumnTwoEntries
  resources: ComponentThreeIconSectionTextColumnTwoResources
}

export type ComponentThreeIconSectionTextColumnTwoResources = {
  __typename?: 'ComponentThreeIconSectionTextColumnTwoResources'
  block: Array<ComponentThreeIconSectionTextColumnTwoResourcesBlock>
  hyperlink: Array<ComponentThreeIconSectionTextColumnTwoResourcesHyperlink>
  inline: Array<ComponentThreeIconSectionTextColumnTwoResourcesInline>
}

export type ComponentThreeIconSectionTextColumnTwoResourcesBlock =
  ResourceLink & {
    __typename?: 'ComponentThreeIconSectionTextColumnTwoResourcesBlock'
    sys: ResourceSys
  }

export type ComponentThreeIconSectionTextColumnTwoResourcesHyperlink =
  ResourceLink & {
    __typename?: 'ComponentThreeIconSectionTextColumnTwoResourcesHyperlink'
    sys: ResourceSys
  }

export type ComponentThreeIconSectionTextColumnTwoResourcesInline =
  ResourceLink & {
    __typename?: 'ComponentThreeIconSectionTextColumnTwoResourcesInline'
    sys: ResourceSys
  }

/** [See type definition](https://app.contentful.com/spaces/9cxgygtadmvl/content_types/componentThreeIconSectionWithPicture) */
export type ComponentThreeIconSectionWithPicture = Entry &
  _Node & {
    __typename?: 'ComponentThreeIconSectionWithPicture'
    _id: Scalars['ID']
    animate?: Maybe<Scalars['Boolean']>
    bgColor?: Maybe<Scalars['String']>
    contentfulMetadata: ContentfulMetadata
    ctaText?: Maybe<Scalars['String']>
    ctaUrl?: Maybe<Scalars['String']>
    headerImage?: Maybe<Asset>
    imageColumnOne?: Maybe<Asset>
    imageColumnThree?: Maybe<Asset>
    imageColumnTwo?: Maybe<Asset>
    linkedFrom?: Maybe<ComponentThreeIconSectionWithPictureLinkingCollections>
    mobileHeaderImage?: Maybe<Scalars['JSON']>
    sectionId?: Maybe<Scalars['String']>
    separator?: Maybe<Asset>
    separatorMobileDisplay?: Maybe<Scalars['String']>
    sys: Sys
    textColumnOne?: Maybe<ComponentThreeIconSectionWithPictureTextColumnOne>
    textColumnThree?: Maybe<ComponentThreeIconSectionWithPictureTextColumnThree>
    textColumnTwo?: Maybe<ComponentThreeIconSectionWithPictureTextColumnTwo>
    title?: Maybe<Scalars['String']>
    urlTarget?: Maybe<Scalars['String']>
  }

/** [See type definition](https://app.contentful.com/spaces/9cxgygtadmvl/content_types/componentThreeIconSectionWithPicture) */
export type ComponentThreeIconSectionWithPictureAnimateArgs = {
  locale?: InputMaybe<Scalars['String']>
}

/** [See type definition](https://app.contentful.com/spaces/9cxgygtadmvl/content_types/componentThreeIconSectionWithPicture) */
export type ComponentThreeIconSectionWithPictureBgColorArgs = {
  locale?: InputMaybe<Scalars['String']>
}

/** [See type definition](https://app.contentful.com/spaces/9cxgygtadmvl/content_types/componentThreeIconSectionWithPicture) */
export type ComponentThreeIconSectionWithPictureCtaTextArgs = {
  locale?: InputMaybe<Scalars['String']>
}

/** [See type definition](https://app.contentful.com/spaces/9cxgygtadmvl/content_types/componentThreeIconSectionWithPicture) */
export type ComponentThreeIconSectionWithPictureCtaUrlArgs = {
  locale?: InputMaybe<Scalars['String']>
}

/** [See type definition](https://app.contentful.com/spaces/9cxgygtadmvl/content_types/componentThreeIconSectionWithPicture) */
export type ComponentThreeIconSectionWithPictureHeaderImageArgs = {
  locale?: InputMaybe<Scalars['String']>
  preview?: InputMaybe<Scalars['Boolean']>
}

/** [See type definition](https://app.contentful.com/spaces/9cxgygtadmvl/content_types/componentThreeIconSectionWithPicture) */
export type ComponentThreeIconSectionWithPictureImageColumnOneArgs = {
  locale?: InputMaybe<Scalars['String']>
  preview?: InputMaybe<Scalars['Boolean']>
}

/** [See type definition](https://app.contentful.com/spaces/9cxgygtadmvl/content_types/componentThreeIconSectionWithPicture) */
export type ComponentThreeIconSectionWithPictureImageColumnThreeArgs = {
  locale?: InputMaybe<Scalars['String']>
  preview?: InputMaybe<Scalars['Boolean']>
}

/** [See type definition](https://app.contentful.com/spaces/9cxgygtadmvl/content_types/componentThreeIconSectionWithPicture) */
export type ComponentThreeIconSectionWithPictureImageColumnTwoArgs = {
  locale?: InputMaybe<Scalars['String']>
  preview?: InputMaybe<Scalars['Boolean']>
}

/** [See type definition](https://app.contentful.com/spaces/9cxgygtadmvl/content_types/componentThreeIconSectionWithPicture) */
export type ComponentThreeIconSectionWithPictureLinkedFromArgs = {
  allowedLocales?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
}

/** [See type definition](https://app.contentful.com/spaces/9cxgygtadmvl/content_types/componentThreeIconSectionWithPicture) */
export type ComponentThreeIconSectionWithPictureMobileHeaderImageArgs = {
  locale?: InputMaybe<Scalars['String']>
}

/** [See type definition](https://app.contentful.com/spaces/9cxgygtadmvl/content_types/componentThreeIconSectionWithPicture) */
export type ComponentThreeIconSectionWithPictureSectionIdArgs = {
  locale?: InputMaybe<Scalars['String']>
}

/** [See type definition](https://app.contentful.com/spaces/9cxgygtadmvl/content_types/componentThreeIconSectionWithPicture) */
export type ComponentThreeIconSectionWithPictureSeparatorArgs = {
  locale?: InputMaybe<Scalars['String']>
  preview?: InputMaybe<Scalars['Boolean']>
}

/** [See type definition](https://app.contentful.com/spaces/9cxgygtadmvl/content_types/componentThreeIconSectionWithPicture) */
export type ComponentThreeIconSectionWithPictureSeparatorMobileDisplayArgs = {
  locale?: InputMaybe<Scalars['String']>
}

/** [See type definition](https://app.contentful.com/spaces/9cxgygtadmvl/content_types/componentThreeIconSectionWithPicture) */
export type ComponentThreeIconSectionWithPictureTextColumnOneArgs = {
  locale?: InputMaybe<Scalars['String']>
}

/** [See type definition](https://app.contentful.com/spaces/9cxgygtadmvl/content_types/componentThreeIconSectionWithPicture) */
export type ComponentThreeIconSectionWithPictureTextColumnThreeArgs = {
  locale?: InputMaybe<Scalars['String']>
}

/** [See type definition](https://app.contentful.com/spaces/9cxgygtadmvl/content_types/componentThreeIconSectionWithPicture) */
export type ComponentThreeIconSectionWithPictureTextColumnTwoArgs = {
  locale?: InputMaybe<Scalars['String']>
}

/** [See type definition](https://app.contentful.com/spaces/9cxgygtadmvl/content_types/componentThreeIconSectionWithPicture) */
export type ComponentThreeIconSectionWithPictureTitleArgs = {
  locale?: InputMaybe<Scalars['String']>
}

/** [See type definition](https://app.contentful.com/spaces/9cxgygtadmvl/content_types/componentThreeIconSectionWithPicture) */
export type ComponentThreeIconSectionWithPictureUrlTargetArgs = {
  locale?: InputMaybe<Scalars['String']>
}

export type ComponentThreeIconSectionWithPictureCollection = {
  __typename?: 'ComponentThreeIconSectionWithPictureCollection'
  items: Array<Maybe<ComponentThreeIconSectionWithPicture>>
  limit: Scalars['Int']
  skip: Scalars['Int']
  total: Scalars['Int']
}

export type ComponentThreeIconSectionWithPictureFilter = {
  AND?: InputMaybe<
    Array<InputMaybe<ComponentThreeIconSectionWithPictureFilter>>
  >
  OR?: InputMaybe<Array<InputMaybe<ComponentThreeIconSectionWithPictureFilter>>>
  animate?: InputMaybe<Scalars['Boolean']>
  animate_exists?: InputMaybe<Scalars['Boolean']>
  animate_not?: InputMaybe<Scalars['Boolean']>
  bgColor?: InputMaybe<Scalars['String']>
  bgColor_contains?: InputMaybe<Scalars['String']>
  bgColor_exists?: InputMaybe<Scalars['Boolean']>
  bgColor_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  bgColor_not?: InputMaybe<Scalars['String']>
  bgColor_not_contains?: InputMaybe<Scalars['String']>
  bgColor_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  contentfulMetadata?: InputMaybe<ContentfulMetadataFilter>
  ctaText?: InputMaybe<Scalars['String']>
  ctaText_contains?: InputMaybe<Scalars['String']>
  ctaText_exists?: InputMaybe<Scalars['Boolean']>
  ctaText_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  ctaText_not?: InputMaybe<Scalars['String']>
  ctaText_not_contains?: InputMaybe<Scalars['String']>
  ctaText_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  ctaUrl?: InputMaybe<Scalars['String']>
  ctaUrl_contains?: InputMaybe<Scalars['String']>
  ctaUrl_exists?: InputMaybe<Scalars['Boolean']>
  ctaUrl_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  ctaUrl_not?: InputMaybe<Scalars['String']>
  ctaUrl_not_contains?: InputMaybe<Scalars['String']>
  ctaUrl_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  headerImage_exists?: InputMaybe<Scalars['Boolean']>
  imageColumnOne_exists?: InputMaybe<Scalars['Boolean']>
  imageColumnThree_exists?: InputMaybe<Scalars['Boolean']>
  imageColumnTwo_exists?: InputMaybe<Scalars['Boolean']>
  mobileHeaderImage_exists?: InputMaybe<Scalars['Boolean']>
  sectionId?: InputMaybe<Scalars['String']>
  sectionId_contains?: InputMaybe<Scalars['String']>
  sectionId_exists?: InputMaybe<Scalars['Boolean']>
  sectionId_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  sectionId_not?: InputMaybe<Scalars['String']>
  sectionId_not_contains?: InputMaybe<Scalars['String']>
  sectionId_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  separatorMobileDisplay?: InputMaybe<Scalars['String']>
  separatorMobileDisplay_contains?: InputMaybe<Scalars['String']>
  separatorMobileDisplay_exists?: InputMaybe<Scalars['Boolean']>
  separatorMobileDisplay_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  separatorMobileDisplay_not?: InputMaybe<Scalars['String']>
  separatorMobileDisplay_not_contains?: InputMaybe<Scalars['String']>
  separatorMobileDisplay_not_in?: InputMaybe<
    Array<InputMaybe<Scalars['String']>>
  >
  separator_exists?: InputMaybe<Scalars['Boolean']>
  sys?: InputMaybe<SysFilter>
  textColumnOne_contains?: InputMaybe<Scalars['String']>
  textColumnOne_exists?: InputMaybe<Scalars['Boolean']>
  textColumnOne_not_contains?: InputMaybe<Scalars['String']>
  textColumnThree_contains?: InputMaybe<Scalars['String']>
  textColumnThree_exists?: InputMaybe<Scalars['Boolean']>
  textColumnThree_not_contains?: InputMaybe<Scalars['String']>
  textColumnTwo_contains?: InputMaybe<Scalars['String']>
  textColumnTwo_exists?: InputMaybe<Scalars['Boolean']>
  textColumnTwo_not_contains?: InputMaybe<Scalars['String']>
  title?: InputMaybe<Scalars['String']>
  title_contains?: InputMaybe<Scalars['String']>
  title_exists?: InputMaybe<Scalars['Boolean']>
  title_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  title_not?: InputMaybe<Scalars['String']>
  title_not_contains?: InputMaybe<Scalars['String']>
  title_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  urlTarget?: InputMaybe<Scalars['String']>
  urlTarget_contains?: InputMaybe<Scalars['String']>
  urlTarget_exists?: InputMaybe<Scalars['Boolean']>
  urlTarget_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  urlTarget_not?: InputMaybe<Scalars['String']>
  urlTarget_not_contains?: InputMaybe<Scalars['String']>
  urlTarget_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
}

export type ComponentThreeIconSectionWithPictureLinkingCollections = {
  __typename?: 'ComponentThreeIconSectionWithPictureLinkingCollections'
  campaignPagesCollection?: Maybe<CampaignPagesCollection>
  entryCollection?: Maybe<EntryCollection>
  templateAboutUsCollection?: Maybe<TemplateAboutUsCollection>
  templateBookingCollection?: Maybe<TemplateBookingCollection>
  templateCareersCollection?: Maybe<TemplateCareersCollection>
  templateContactUsCollection?: Maybe<TemplateContactUsCollection>
  templateDestinationsCollection?: Maybe<TemplateDestinationsCollection>
  templateForaReserveCollection?: Maybe<TemplateForaReserveCollection>
  templateForaXCollection?: Maybe<TemplateForaXCollection>
  templateHomeCollection?: Maybe<TemplateHomeCollection>
  templateJoinUsCollection?: Maybe<TemplateJoinUsCollection>
  templateNewsletterCollection?: Maybe<TemplateNewsletterCollection>
  templatePartnersCollection?: Maybe<TemplatePartnersCollection>
  templateReviewsCollection?: Maybe<TemplateReviewsCollection>
  templateWhyBookWithForaCollection?: Maybe<TemplateWhyBookWithForaCollection>
}

export type ComponentThreeIconSectionWithPictureLinkingCollectionsCampaignPagesCollectionArgs =
  {
    limit?: InputMaybe<Scalars['Int']>
    locale?: InputMaybe<Scalars['String']>
    order?: InputMaybe<
      Array<
        InputMaybe<ComponentThreeIconSectionWithPictureLinkingCollectionsCampaignPagesCollectionOrder>
      >
    >
    preview?: InputMaybe<Scalars['Boolean']>
    skip?: InputMaybe<Scalars['Int']>
  }

export type ComponentThreeIconSectionWithPictureLinkingCollectionsEntryCollectionArgs =
  {
    limit?: InputMaybe<Scalars['Int']>
    locale?: InputMaybe<Scalars['String']>
    preview?: InputMaybe<Scalars['Boolean']>
    skip?: InputMaybe<Scalars['Int']>
  }

export type ComponentThreeIconSectionWithPictureLinkingCollectionsTemplateAboutUsCollectionArgs =
  {
    limit?: InputMaybe<Scalars['Int']>
    locale?: InputMaybe<Scalars['String']>
    order?: InputMaybe<
      Array<
        InputMaybe<ComponentThreeIconSectionWithPictureLinkingCollectionsTemplateAboutUsCollectionOrder>
      >
    >
    preview?: InputMaybe<Scalars['Boolean']>
    skip?: InputMaybe<Scalars['Int']>
  }

export type ComponentThreeIconSectionWithPictureLinkingCollectionsTemplateBookingCollectionArgs =
  {
    limit?: InputMaybe<Scalars['Int']>
    locale?: InputMaybe<Scalars['String']>
    order?: InputMaybe<
      Array<
        InputMaybe<ComponentThreeIconSectionWithPictureLinkingCollectionsTemplateBookingCollectionOrder>
      >
    >
    preview?: InputMaybe<Scalars['Boolean']>
    skip?: InputMaybe<Scalars['Int']>
  }

export type ComponentThreeIconSectionWithPictureLinkingCollectionsTemplateCareersCollectionArgs =
  {
    limit?: InputMaybe<Scalars['Int']>
    locale?: InputMaybe<Scalars['String']>
    order?: InputMaybe<
      Array<
        InputMaybe<ComponentThreeIconSectionWithPictureLinkingCollectionsTemplateCareersCollectionOrder>
      >
    >
    preview?: InputMaybe<Scalars['Boolean']>
    skip?: InputMaybe<Scalars['Int']>
  }

export type ComponentThreeIconSectionWithPictureLinkingCollectionsTemplateContactUsCollectionArgs =
  {
    limit?: InputMaybe<Scalars['Int']>
    locale?: InputMaybe<Scalars['String']>
    order?: InputMaybe<
      Array<
        InputMaybe<ComponentThreeIconSectionWithPictureLinkingCollectionsTemplateContactUsCollectionOrder>
      >
    >
    preview?: InputMaybe<Scalars['Boolean']>
    skip?: InputMaybe<Scalars['Int']>
  }

export type ComponentThreeIconSectionWithPictureLinkingCollectionsTemplateDestinationsCollectionArgs =
  {
    limit?: InputMaybe<Scalars['Int']>
    locale?: InputMaybe<Scalars['String']>
    order?: InputMaybe<
      Array<
        InputMaybe<ComponentThreeIconSectionWithPictureLinkingCollectionsTemplateDestinationsCollectionOrder>
      >
    >
    preview?: InputMaybe<Scalars['Boolean']>
    skip?: InputMaybe<Scalars['Int']>
  }

export type ComponentThreeIconSectionWithPictureLinkingCollectionsTemplateForaReserveCollectionArgs =
  {
    limit?: InputMaybe<Scalars['Int']>
    locale?: InputMaybe<Scalars['String']>
    order?: InputMaybe<
      Array<
        InputMaybe<ComponentThreeIconSectionWithPictureLinkingCollectionsTemplateForaReserveCollectionOrder>
      >
    >
    preview?: InputMaybe<Scalars['Boolean']>
    skip?: InputMaybe<Scalars['Int']>
  }

export type ComponentThreeIconSectionWithPictureLinkingCollectionsTemplateForaXCollectionArgs =
  {
    limit?: InputMaybe<Scalars['Int']>
    locale?: InputMaybe<Scalars['String']>
    order?: InputMaybe<
      Array<
        InputMaybe<ComponentThreeIconSectionWithPictureLinkingCollectionsTemplateForaXCollectionOrder>
      >
    >
    preview?: InputMaybe<Scalars['Boolean']>
    skip?: InputMaybe<Scalars['Int']>
  }

export type ComponentThreeIconSectionWithPictureLinkingCollectionsTemplateHomeCollectionArgs =
  {
    limit?: InputMaybe<Scalars['Int']>
    locale?: InputMaybe<Scalars['String']>
    order?: InputMaybe<
      Array<
        InputMaybe<ComponentThreeIconSectionWithPictureLinkingCollectionsTemplateHomeCollectionOrder>
      >
    >
    preview?: InputMaybe<Scalars['Boolean']>
    skip?: InputMaybe<Scalars['Int']>
  }

export type ComponentThreeIconSectionWithPictureLinkingCollectionsTemplateJoinUsCollectionArgs =
  {
    limit?: InputMaybe<Scalars['Int']>
    locale?: InputMaybe<Scalars['String']>
    order?: InputMaybe<
      Array<
        InputMaybe<ComponentThreeIconSectionWithPictureLinkingCollectionsTemplateJoinUsCollectionOrder>
      >
    >
    preview?: InputMaybe<Scalars['Boolean']>
    skip?: InputMaybe<Scalars['Int']>
  }

export type ComponentThreeIconSectionWithPictureLinkingCollectionsTemplateNewsletterCollectionArgs =
  {
    limit?: InputMaybe<Scalars['Int']>
    locale?: InputMaybe<Scalars['String']>
    order?: InputMaybe<
      Array<
        InputMaybe<ComponentThreeIconSectionWithPictureLinkingCollectionsTemplateNewsletterCollectionOrder>
      >
    >
    preview?: InputMaybe<Scalars['Boolean']>
    skip?: InputMaybe<Scalars['Int']>
  }

export type ComponentThreeIconSectionWithPictureLinkingCollectionsTemplatePartnersCollectionArgs =
  {
    limit?: InputMaybe<Scalars['Int']>
    locale?: InputMaybe<Scalars['String']>
    order?: InputMaybe<
      Array<
        InputMaybe<ComponentThreeIconSectionWithPictureLinkingCollectionsTemplatePartnersCollectionOrder>
      >
    >
    preview?: InputMaybe<Scalars['Boolean']>
    skip?: InputMaybe<Scalars['Int']>
  }

export type ComponentThreeIconSectionWithPictureLinkingCollectionsTemplateReviewsCollectionArgs =
  {
    limit?: InputMaybe<Scalars['Int']>
    locale?: InputMaybe<Scalars['String']>
    order?: InputMaybe<
      Array<
        InputMaybe<ComponentThreeIconSectionWithPictureLinkingCollectionsTemplateReviewsCollectionOrder>
      >
    >
    preview?: InputMaybe<Scalars['Boolean']>
    skip?: InputMaybe<Scalars['Int']>
  }

export type ComponentThreeIconSectionWithPictureLinkingCollectionsTemplateWhyBookWithForaCollectionArgs =
  {
    limit?: InputMaybe<Scalars['Int']>
    locale?: InputMaybe<Scalars['String']>
    order?: InputMaybe<
      Array<
        InputMaybe<ComponentThreeIconSectionWithPictureLinkingCollectionsTemplateWhyBookWithForaCollectionOrder>
      >
    >
    preview?: InputMaybe<Scalars['Boolean']>
    skip?: InputMaybe<Scalars['Int']>
  }

export enum ComponentThreeIconSectionWithPictureLinkingCollectionsCampaignPagesCollectionOrder {
  CollapseFooterAsc = 'collapseFooter_ASC',
  CollapseFooterDesc = 'collapseFooter_DESC',
  CollapseHeaderAsc = 'collapseHeader_ASC',
  CollapseHeaderDesc = 'collapseHeader_DESC',
  HideFooterAsc = 'hideFooter_ASC',
  HideFooterDesc = 'hideFooter_DESC',
  HideGlobalBannerAsc = 'hideGlobalBanner_ASC',
  HideGlobalBannerDesc = 'hideGlobalBanner_DESC',
  JotformIdAsc = 'jotformId_ASC',
  JotformIdDesc = 'jotformId_DESC',
  NoindexNofollowAsc = 'noindexNofollow_ASC',
  NoindexNofollowDesc = 'noindexNofollow_DESC',
  ShowHeaderAsc = 'showHeader_ASC',
  ShowHeaderDesc = 'showHeader_DESC',
  SlugAsc = 'slug_ASC',
  SlugDesc = 'slug_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC',
}

export enum ComponentThreeIconSectionWithPictureLinkingCollectionsTemplateAboutUsCollectionOrder {
  CollapseHeaderAsc = 'collapseHeader_ASC',
  CollapseHeaderDesc = 'collapseHeader_DESC',
  HeaderBlockTitleAsc = 'headerBlockTitle_ASC',
  HeaderBlockTitleDesc = 'headerBlockTitle_DESC',
  ShowHeaderAsc = 'showHeader_ASC',
  ShowHeaderDesc = 'showHeader_DESC',
  SubtitleAsc = 'subtitle_ASC',
  SubtitleDesc = 'subtitle_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC',
}

export enum ComponentThreeIconSectionWithPictureLinkingCollectionsTemplateBookingCollectionOrder {
  CollapseHeaderAsc = 'collapseHeader_ASC',
  CollapseHeaderDesc = 'collapseHeader_DESC',
  ShowHeaderAsc = 'showHeader_ASC',
  ShowHeaderDesc = 'showHeader_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC',
}

export enum ComponentThreeIconSectionWithPictureLinkingCollectionsTemplateCareersCollectionOrder {
  CollapseHeaderAsc = 'collapseHeader_ASC',
  CollapseHeaderDesc = 'collapseHeader_DESC',
  ShowHeaderAsc = 'showHeader_ASC',
  ShowHeaderDesc = 'showHeader_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC',
}

export enum ComponentThreeIconSectionWithPictureLinkingCollectionsTemplateContactUsCollectionOrder {
  CollapseHeaderAsc = 'collapseHeader_ASC',
  CollapseHeaderDesc = 'collapseHeader_DESC',
  ShowHeaderAsc = 'showHeader_ASC',
  ShowHeaderDesc = 'showHeader_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC',
}

export enum ComponentThreeIconSectionWithPictureLinkingCollectionsTemplateDestinationsCollectionOrder {
  CollapseHeaderAsc = 'collapseHeader_ASC',
  CollapseHeaderDesc = 'collapseHeader_DESC',
  CollapsibleHeadingAsc = 'collapsibleHeading_ASC',
  CollapsibleHeadingDesc = 'collapsibleHeading_DESC',
  ShowHeaderAsc = 'showHeader_ASC',
  ShowHeaderDesc = 'showHeader_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC',
}

export enum ComponentThreeIconSectionWithPictureLinkingCollectionsTemplateForaReserveCollectionOrder {
  CollapseHeaderAsc = 'collapseHeader_ASC',
  CollapseHeaderDesc = 'collapseHeader_DESC',
  ShowHeaderAsc = 'showHeader_ASC',
  ShowHeaderDesc = 'showHeader_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC',
}

export enum ComponentThreeIconSectionWithPictureLinkingCollectionsTemplateForaXCollectionOrder {
  AdvisorGridHeaderAsc = 'advisorGridHeader_ASC',
  AdvisorGridHeaderDesc = 'advisorGridHeader_DESC',
  AdvisorGridSubCopyAsc = 'advisorGridSubCopy_ASC',
  AdvisorGridSubCopyDesc = 'advisorGridSubCopy_DESC',
  CollapseHeaderAsc = 'collapseHeader_ASC',
  CollapseHeaderDesc = 'collapseHeader_DESC',
  ShowHeaderAsc = 'showHeader_ASC',
  ShowHeaderDesc = 'showHeader_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC',
}

export enum ComponentThreeIconSectionWithPictureLinkingCollectionsTemplateHomeCollectionOrder {
  CollapseHeaderAsc = 'collapseHeader_ASC',
  CollapseHeaderDesc = 'collapseHeader_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC',
}

export enum ComponentThreeIconSectionWithPictureLinkingCollectionsTemplateJoinUsCollectionOrder {
  CollapseHeaderAsc = 'collapseHeader_ASC',
  CollapseHeaderDesc = 'collapseHeader_DESC',
  HideGlobalBannerAsc = 'hideGlobalBanner_ASC',
  HideGlobalBannerDesc = 'hideGlobalBanner_DESC',
  ShowHeaderAsc = 'showHeader_ASC',
  ShowHeaderDesc = 'showHeader_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC',
}

export enum ComponentThreeIconSectionWithPictureLinkingCollectionsTemplateNewsletterCollectionOrder {
  CollapseHeaderAsc = 'collapseHeader_ASC',
  CollapseHeaderDesc = 'collapseHeader_DESC',
  ShowHeaderAsc = 'showHeader_ASC',
  ShowHeaderDesc = 'showHeader_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC',
}

export enum ComponentThreeIconSectionWithPictureLinkingCollectionsTemplatePartnersCollectionOrder {
  CollapseHeaderAsc = 'collapseHeader_ASC',
  CollapseHeaderDesc = 'collapseHeader_DESC',
  ShowHeaderAsc = 'showHeader_ASC',
  ShowHeaderDesc = 'showHeader_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC',
}

export enum ComponentThreeIconSectionWithPictureLinkingCollectionsTemplateReviewsCollectionOrder {
  CollapseHeaderAsc = 'collapseHeader_ASC',
  CollapseHeaderDesc = 'collapseHeader_DESC',
  ShowHeaderAsc = 'showHeader_ASC',
  ShowHeaderDesc = 'showHeader_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC',
}

export enum ComponentThreeIconSectionWithPictureLinkingCollectionsTemplateWhyBookWithForaCollectionOrder {
  CollapseHeaderAsc = 'collapseHeader_ASC',
  CollapseHeaderDesc = 'collapseHeader_DESC',
  ShowHeaderAsc = 'showHeader_ASC',
  ShowHeaderDesc = 'showHeader_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC',
}

export enum ComponentThreeIconSectionWithPictureOrder {
  AnimateAsc = 'animate_ASC',
  AnimateDesc = 'animate_DESC',
  BgColorAsc = 'bgColor_ASC',
  BgColorDesc = 'bgColor_DESC',
  CtaTextAsc = 'ctaText_ASC',
  CtaTextDesc = 'ctaText_DESC',
  CtaUrlAsc = 'ctaUrl_ASC',
  CtaUrlDesc = 'ctaUrl_DESC',
  SectionIdAsc = 'sectionId_ASC',
  SectionIdDesc = 'sectionId_DESC',
  SeparatorMobileDisplayAsc = 'separatorMobileDisplay_ASC',
  SeparatorMobileDisplayDesc = 'separatorMobileDisplay_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC',
  UrlTargetAsc = 'urlTarget_ASC',
  UrlTargetDesc = 'urlTarget_DESC',
}

export type ComponentThreeIconSectionWithPictureTextColumnOne = {
  __typename?: 'ComponentThreeIconSectionWithPictureTextColumnOne'
  json: Scalars['JSON']
  links: ComponentThreeIconSectionWithPictureTextColumnOneLinks
}

export type ComponentThreeIconSectionWithPictureTextColumnOneAssets = {
  __typename?: 'ComponentThreeIconSectionWithPictureTextColumnOneAssets'
  block: Array<Maybe<Asset>>
  hyperlink: Array<Maybe<Asset>>
}

export type ComponentThreeIconSectionWithPictureTextColumnOneEntries = {
  __typename?: 'ComponentThreeIconSectionWithPictureTextColumnOneEntries'
  block: Array<Maybe<Entry>>
  hyperlink: Array<Maybe<Entry>>
  inline: Array<Maybe<Entry>>
}

export type ComponentThreeIconSectionWithPictureTextColumnOneLinks = {
  __typename?: 'ComponentThreeIconSectionWithPictureTextColumnOneLinks'
  assets: ComponentThreeIconSectionWithPictureTextColumnOneAssets
  entries: ComponentThreeIconSectionWithPictureTextColumnOneEntries
  resources: ComponentThreeIconSectionWithPictureTextColumnOneResources
}

export type ComponentThreeIconSectionWithPictureTextColumnOneResources = {
  __typename?: 'ComponentThreeIconSectionWithPictureTextColumnOneResources'
  block: Array<ComponentThreeIconSectionWithPictureTextColumnOneResourcesBlock>
  hyperlink: Array<ComponentThreeIconSectionWithPictureTextColumnOneResourcesHyperlink>
  inline: Array<ComponentThreeIconSectionWithPictureTextColumnOneResourcesInline>
}

export type ComponentThreeIconSectionWithPictureTextColumnOneResourcesBlock =
  ResourceLink & {
    __typename?: 'ComponentThreeIconSectionWithPictureTextColumnOneResourcesBlock'
    sys: ResourceSys
  }

export type ComponentThreeIconSectionWithPictureTextColumnOneResourcesHyperlink =
  ResourceLink & {
    __typename?: 'ComponentThreeIconSectionWithPictureTextColumnOneResourcesHyperlink'
    sys: ResourceSys
  }

export type ComponentThreeIconSectionWithPictureTextColumnOneResourcesInline =
  ResourceLink & {
    __typename?: 'ComponentThreeIconSectionWithPictureTextColumnOneResourcesInline'
    sys: ResourceSys
  }

export type ComponentThreeIconSectionWithPictureTextColumnThree = {
  __typename?: 'ComponentThreeIconSectionWithPictureTextColumnThree'
  json: Scalars['JSON']
  links: ComponentThreeIconSectionWithPictureTextColumnThreeLinks
}

export type ComponentThreeIconSectionWithPictureTextColumnThreeAssets = {
  __typename?: 'ComponentThreeIconSectionWithPictureTextColumnThreeAssets'
  block: Array<Maybe<Asset>>
  hyperlink: Array<Maybe<Asset>>
}

export type ComponentThreeIconSectionWithPictureTextColumnThreeEntries = {
  __typename?: 'ComponentThreeIconSectionWithPictureTextColumnThreeEntries'
  block: Array<Maybe<Entry>>
  hyperlink: Array<Maybe<Entry>>
  inline: Array<Maybe<Entry>>
}

export type ComponentThreeIconSectionWithPictureTextColumnThreeLinks = {
  __typename?: 'ComponentThreeIconSectionWithPictureTextColumnThreeLinks'
  assets: ComponentThreeIconSectionWithPictureTextColumnThreeAssets
  entries: ComponentThreeIconSectionWithPictureTextColumnThreeEntries
  resources: ComponentThreeIconSectionWithPictureTextColumnThreeResources
}

export type ComponentThreeIconSectionWithPictureTextColumnThreeResources = {
  __typename?: 'ComponentThreeIconSectionWithPictureTextColumnThreeResources'
  block: Array<ComponentThreeIconSectionWithPictureTextColumnThreeResourcesBlock>
  hyperlink: Array<ComponentThreeIconSectionWithPictureTextColumnThreeResourcesHyperlink>
  inline: Array<ComponentThreeIconSectionWithPictureTextColumnThreeResourcesInline>
}

export type ComponentThreeIconSectionWithPictureTextColumnThreeResourcesBlock =
  ResourceLink & {
    __typename?: 'ComponentThreeIconSectionWithPictureTextColumnThreeResourcesBlock'
    sys: ResourceSys
  }

export type ComponentThreeIconSectionWithPictureTextColumnThreeResourcesHyperlink =
  ResourceLink & {
    __typename?: 'ComponentThreeIconSectionWithPictureTextColumnThreeResourcesHyperlink'
    sys: ResourceSys
  }

export type ComponentThreeIconSectionWithPictureTextColumnThreeResourcesInline =
  ResourceLink & {
    __typename?: 'ComponentThreeIconSectionWithPictureTextColumnThreeResourcesInline'
    sys: ResourceSys
  }

export type ComponentThreeIconSectionWithPictureTextColumnTwo = {
  __typename?: 'ComponentThreeIconSectionWithPictureTextColumnTwo'
  json: Scalars['JSON']
  links: ComponentThreeIconSectionWithPictureTextColumnTwoLinks
}

export type ComponentThreeIconSectionWithPictureTextColumnTwoAssets = {
  __typename?: 'ComponentThreeIconSectionWithPictureTextColumnTwoAssets'
  block: Array<Maybe<Asset>>
  hyperlink: Array<Maybe<Asset>>
}

export type ComponentThreeIconSectionWithPictureTextColumnTwoEntries = {
  __typename?: 'ComponentThreeIconSectionWithPictureTextColumnTwoEntries'
  block: Array<Maybe<Entry>>
  hyperlink: Array<Maybe<Entry>>
  inline: Array<Maybe<Entry>>
}

export type ComponentThreeIconSectionWithPictureTextColumnTwoLinks = {
  __typename?: 'ComponentThreeIconSectionWithPictureTextColumnTwoLinks'
  assets: ComponentThreeIconSectionWithPictureTextColumnTwoAssets
  entries: ComponentThreeIconSectionWithPictureTextColumnTwoEntries
  resources: ComponentThreeIconSectionWithPictureTextColumnTwoResources
}

export type ComponentThreeIconSectionWithPictureTextColumnTwoResources = {
  __typename?: 'ComponentThreeIconSectionWithPictureTextColumnTwoResources'
  block: Array<ComponentThreeIconSectionWithPictureTextColumnTwoResourcesBlock>
  hyperlink: Array<ComponentThreeIconSectionWithPictureTextColumnTwoResourcesHyperlink>
  inline: Array<ComponentThreeIconSectionWithPictureTextColumnTwoResourcesInline>
}

export type ComponentThreeIconSectionWithPictureTextColumnTwoResourcesBlock =
  ResourceLink & {
    __typename?: 'ComponentThreeIconSectionWithPictureTextColumnTwoResourcesBlock'
    sys: ResourceSys
  }

export type ComponentThreeIconSectionWithPictureTextColumnTwoResourcesHyperlink =
  ResourceLink & {
    __typename?: 'ComponentThreeIconSectionWithPictureTextColumnTwoResourcesHyperlink'
    sys: ResourceSys
  }

export type ComponentThreeIconSectionWithPictureTextColumnTwoResourcesInline =
  ResourceLink & {
    __typename?: 'ComponentThreeIconSectionWithPictureTextColumnTwoResourcesInline'
    sys: ResourceSys
  }

/** [See type definition](https://app.contentful.com/spaces/9cxgygtadmvl/content_types/componentTwoColumnCallout) */
export type ComponentTwoColumnCallout = Entry &
  _Node & {
    __typename?: 'ComponentTwoColumnCallout'
    _id: Scalars['ID']
    analyticsModule?: Maybe<SettingsAnalyticsModule>
    content?: Maybe<ComponentTwoColumnCalloutContent>
    contentfulMetadata: ContentfulMetadata
    ctaText?: Maybe<Scalars['String']>
    ctaTheme?: Maybe<Scalars['String']>
    ctaUrl?: Maybe<Scalars['String']>
    image?: Maybe<Asset>
    internalLabel?: Maybe<Scalars['String']>
    linkedFrom?: Maybe<ComponentTwoColumnCalloutLinkingCollections>
    sectionId?: Maybe<Scalars['String']>
    sys: Sys
  }

/** [See type definition](https://app.contentful.com/spaces/9cxgygtadmvl/content_types/componentTwoColumnCallout) */
export type ComponentTwoColumnCalloutAnalyticsModuleArgs = {
  locale?: InputMaybe<Scalars['String']>
  preview?: InputMaybe<Scalars['Boolean']>
  where?: InputMaybe<SettingsAnalyticsModuleFilter>
}

/** [See type definition](https://app.contentful.com/spaces/9cxgygtadmvl/content_types/componentTwoColumnCallout) */
export type ComponentTwoColumnCalloutContentArgs = {
  locale?: InputMaybe<Scalars['String']>
}

/** [See type definition](https://app.contentful.com/spaces/9cxgygtadmvl/content_types/componentTwoColumnCallout) */
export type ComponentTwoColumnCalloutCtaTextArgs = {
  locale?: InputMaybe<Scalars['String']>
}

/** [See type definition](https://app.contentful.com/spaces/9cxgygtadmvl/content_types/componentTwoColumnCallout) */
export type ComponentTwoColumnCalloutCtaThemeArgs = {
  locale?: InputMaybe<Scalars['String']>
}

/** [See type definition](https://app.contentful.com/spaces/9cxgygtadmvl/content_types/componentTwoColumnCallout) */
export type ComponentTwoColumnCalloutCtaUrlArgs = {
  locale?: InputMaybe<Scalars['String']>
}

/** [See type definition](https://app.contentful.com/spaces/9cxgygtadmvl/content_types/componentTwoColumnCallout) */
export type ComponentTwoColumnCalloutImageArgs = {
  locale?: InputMaybe<Scalars['String']>
  preview?: InputMaybe<Scalars['Boolean']>
}

/** [See type definition](https://app.contentful.com/spaces/9cxgygtadmvl/content_types/componentTwoColumnCallout) */
export type ComponentTwoColumnCalloutInternalLabelArgs = {
  locale?: InputMaybe<Scalars['String']>
}

/** [See type definition](https://app.contentful.com/spaces/9cxgygtadmvl/content_types/componentTwoColumnCallout) */
export type ComponentTwoColumnCalloutLinkedFromArgs = {
  allowedLocales?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
}

/** [See type definition](https://app.contentful.com/spaces/9cxgygtadmvl/content_types/componentTwoColumnCallout) */
export type ComponentTwoColumnCalloutSectionIdArgs = {
  locale?: InputMaybe<Scalars['String']>
}

export type ComponentTwoColumnCalloutCollection = {
  __typename?: 'ComponentTwoColumnCalloutCollection'
  items: Array<Maybe<ComponentTwoColumnCallout>>
  limit: Scalars['Int']
  skip: Scalars['Int']
  total: Scalars['Int']
}

export type ComponentTwoColumnCalloutContent = {
  __typename?: 'ComponentTwoColumnCalloutContent'
  json: Scalars['JSON']
  links: ComponentTwoColumnCalloutContentLinks
}

export type ComponentTwoColumnCalloutContentAssets = {
  __typename?: 'ComponentTwoColumnCalloutContentAssets'
  block: Array<Maybe<Asset>>
  hyperlink: Array<Maybe<Asset>>
}

export type ComponentTwoColumnCalloutContentEntries = {
  __typename?: 'ComponentTwoColumnCalloutContentEntries'
  block: Array<Maybe<Entry>>
  hyperlink: Array<Maybe<Entry>>
  inline: Array<Maybe<Entry>>
}

export type ComponentTwoColumnCalloutContentLinks = {
  __typename?: 'ComponentTwoColumnCalloutContentLinks'
  assets: ComponentTwoColumnCalloutContentAssets
  entries: ComponentTwoColumnCalloutContentEntries
  resources: ComponentTwoColumnCalloutContentResources
}

export type ComponentTwoColumnCalloutContentResources = {
  __typename?: 'ComponentTwoColumnCalloutContentResources'
  block: Array<ComponentTwoColumnCalloutContentResourcesBlock>
  hyperlink: Array<ComponentTwoColumnCalloutContentResourcesHyperlink>
  inline: Array<ComponentTwoColumnCalloutContentResourcesInline>
}

export type ComponentTwoColumnCalloutContentResourcesBlock = ResourceLink & {
  __typename?: 'ComponentTwoColumnCalloutContentResourcesBlock'
  sys: ResourceSys
}

export type ComponentTwoColumnCalloutContentResourcesHyperlink =
  ResourceLink & {
    __typename?: 'ComponentTwoColumnCalloutContentResourcesHyperlink'
    sys: ResourceSys
  }

export type ComponentTwoColumnCalloutContentResourcesInline = ResourceLink & {
  __typename?: 'ComponentTwoColumnCalloutContentResourcesInline'
  sys: ResourceSys
}

export type ComponentTwoColumnCalloutFilter = {
  AND?: InputMaybe<Array<InputMaybe<ComponentTwoColumnCalloutFilter>>>
  OR?: InputMaybe<Array<InputMaybe<ComponentTwoColumnCalloutFilter>>>
  analyticsModule?: InputMaybe<CfSettingsAnalyticsModuleNestedFilter>
  analyticsModule_exists?: InputMaybe<Scalars['Boolean']>
  content_contains?: InputMaybe<Scalars['String']>
  content_exists?: InputMaybe<Scalars['Boolean']>
  content_not_contains?: InputMaybe<Scalars['String']>
  contentfulMetadata?: InputMaybe<ContentfulMetadataFilter>
  ctaText?: InputMaybe<Scalars['String']>
  ctaText_contains?: InputMaybe<Scalars['String']>
  ctaText_exists?: InputMaybe<Scalars['Boolean']>
  ctaText_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  ctaText_not?: InputMaybe<Scalars['String']>
  ctaText_not_contains?: InputMaybe<Scalars['String']>
  ctaText_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  ctaTheme?: InputMaybe<Scalars['String']>
  ctaTheme_contains?: InputMaybe<Scalars['String']>
  ctaTheme_exists?: InputMaybe<Scalars['Boolean']>
  ctaTheme_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  ctaTheme_not?: InputMaybe<Scalars['String']>
  ctaTheme_not_contains?: InputMaybe<Scalars['String']>
  ctaTheme_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  ctaUrl?: InputMaybe<Scalars['String']>
  ctaUrl_contains?: InputMaybe<Scalars['String']>
  ctaUrl_exists?: InputMaybe<Scalars['Boolean']>
  ctaUrl_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  ctaUrl_not?: InputMaybe<Scalars['String']>
  ctaUrl_not_contains?: InputMaybe<Scalars['String']>
  ctaUrl_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  image_exists?: InputMaybe<Scalars['Boolean']>
  internalLabel?: InputMaybe<Scalars['String']>
  internalLabel_contains?: InputMaybe<Scalars['String']>
  internalLabel_exists?: InputMaybe<Scalars['Boolean']>
  internalLabel_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  internalLabel_not?: InputMaybe<Scalars['String']>
  internalLabel_not_contains?: InputMaybe<Scalars['String']>
  internalLabel_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  sectionId?: InputMaybe<Scalars['String']>
  sectionId_contains?: InputMaybe<Scalars['String']>
  sectionId_exists?: InputMaybe<Scalars['Boolean']>
  sectionId_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  sectionId_not?: InputMaybe<Scalars['String']>
  sectionId_not_contains?: InputMaybe<Scalars['String']>
  sectionId_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  sys?: InputMaybe<SysFilter>
}

export type ComponentTwoColumnCalloutLinkingCollections = {
  __typename?: 'ComponentTwoColumnCalloutLinkingCollections'
  componentCheckeredCalloutCollection?: Maybe<ComponentCheckeredCalloutCollection>
  entryCollection?: Maybe<EntryCollection>
  templateAboutUsCollection?: Maybe<TemplateAboutUsCollection>
  templateJoinUsCollection?: Maybe<TemplateJoinUsCollection>
  templatePageCollection?: Maybe<TemplatePageCollection>
}

export type ComponentTwoColumnCalloutLinkingCollectionsComponentCheckeredCalloutCollectionArgs =
  {
    limit?: InputMaybe<Scalars['Int']>
    locale?: InputMaybe<Scalars['String']>
    order?: InputMaybe<
      Array<
        InputMaybe<ComponentTwoColumnCalloutLinkingCollectionsComponentCheckeredCalloutCollectionOrder>
      >
    >
    preview?: InputMaybe<Scalars['Boolean']>
    skip?: InputMaybe<Scalars['Int']>
  }

export type ComponentTwoColumnCalloutLinkingCollectionsEntryCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>
  locale?: InputMaybe<Scalars['String']>
  preview?: InputMaybe<Scalars['Boolean']>
  skip?: InputMaybe<Scalars['Int']>
}

export type ComponentTwoColumnCalloutLinkingCollectionsTemplateAboutUsCollectionArgs =
  {
    limit?: InputMaybe<Scalars['Int']>
    locale?: InputMaybe<Scalars['String']>
    order?: InputMaybe<
      Array<
        InputMaybe<ComponentTwoColumnCalloutLinkingCollectionsTemplateAboutUsCollectionOrder>
      >
    >
    preview?: InputMaybe<Scalars['Boolean']>
    skip?: InputMaybe<Scalars['Int']>
  }

export type ComponentTwoColumnCalloutLinkingCollectionsTemplateJoinUsCollectionArgs =
  {
    limit?: InputMaybe<Scalars['Int']>
    locale?: InputMaybe<Scalars['String']>
    order?: InputMaybe<
      Array<
        InputMaybe<ComponentTwoColumnCalloutLinkingCollectionsTemplateJoinUsCollectionOrder>
      >
    >
    preview?: InputMaybe<Scalars['Boolean']>
    skip?: InputMaybe<Scalars['Int']>
  }

export type ComponentTwoColumnCalloutLinkingCollectionsTemplatePageCollectionArgs =
  {
    limit?: InputMaybe<Scalars['Int']>
    locale?: InputMaybe<Scalars['String']>
    order?: InputMaybe<
      Array<
        InputMaybe<ComponentTwoColumnCalloutLinkingCollectionsTemplatePageCollectionOrder>
      >
    >
    preview?: InputMaybe<Scalars['Boolean']>
    skip?: InputMaybe<Scalars['Int']>
  }

export enum ComponentTwoColumnCalloutLinkingCollectionsComponentCheckeredCalloutCollectionOrder {
  FullWidthAsc = 'fullWidth_ASC',
  FullWidthDesc = 'fullWidth_DESC',
  InternalLabelAsc = 'internalLabel_ASC',
  InternalLabelDesc = 'internalLabel_DESC',
  SectionIdAsc = 'sectionId_ASC',
  SectionIdDesc = 'sectionId_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
}

export enum ComponentTwoColumnCalloutLinkingCollectionsTemplateAboutUsCollectionOrder {
  CollapseHeaderAsc = 'collapseHeader_ASC',
  CollapseHeaderDesc = 'collapseHeader_DESC',
  HeaderBlockTitleAsc = 'headerBlockTitle_ASC',
  HeaderBlockTitleDesc = 'headerBlockTitle_DESC',
  ShowHeaderAsc = 'showHeader_ASC',
  ShowHeaderDesc = 'showHeader_DESC',
  SubtitleAsc = 'subtitle_ASC',
  SubtitleDesc = 'subtitle_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC',
}

export enum ComponentTwoColumnCalloutLinkingCollectionsTemplateJoinUsCollectionOrder {
  CollapseHeaderAsc = 'collapseHeader_ASC',
  CollapseHeaderDesc = 'collapseHeader_DESC',
  HideGlobalBannerAsc = 'hideGlobalBanner_ASC',
  HideGlobalBannerDesc = 'hideGlobalBanner_DESC',
  ShowHeaderAsc = 'showHeader_ASC',
  ShowHeaderDesc = 'showHeader_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC',
}

export enum ComponentTwoColumnCalloutLinkingCollectionsTemplatePageCollectionOrder {
  DirectoryAsc = 'directory_ASC',
  DirectoryDesc = 'directory_DESC',
  InternalLabelAsc = 'internalLabel_ASC',
  InternalLabelDesc = 'internalLabel_DESC',
  MetaTitleAsc = 'metaTitle_ASC',
  MetaTitleDesc = 'metaTitle_DESC',
  NoindexNofollowAsc = 'noindexNofollow_ASC',
  NoindexNofollowDesc = 'noindexNofollow_DESC',
  SlugAsc = 'slug_ASC',
  SlugDesc = 'slug_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
}

export enum ComponentTwoColumnCalloutOrder {
  CtaTextAsc = 'ctaText_ASC',
  CtaTextDesc = 'ctaText_DESC',
  CtaThemeAsc = 'ctaTheme_ASC',
  CtaThemeDesc = 'ctaTheme_DESC',
  CtaUrlAsc = 'ctaUrl_ASC',
  CtaUrlDesc = 'ctaUrl_DESC',
  InternalLabelAsc = 'internalLabel_ASC',
  InternalLabelDesc = 'internalLabel_DESC',
  SectionIdAsc = 'sectionId_ASC',
  SectionIdDesc = 'sectionId_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
}

/** [See type definition](https://app.contentful.com/spaces/9cxgygtadmvl/content_types/componentTwoColumnInfographic) */
export type ComponentTwoColumnInfographic = Entry &
  _Node & {
    __typename?: 'ComponentTwoColumnInfographic'
    _id: Scalars['ID']
    contentfulMetadata: ContentfulMetadata
    copyOne?: Maybe<Scalars['String']>
    copyThree?: Maybe<Scalars['String']>
    copyTwo?: Maybe<Scalars['String']>
    ctaText?: Maybe<Scalars['String']>
    ctaUrl?: Maybe<Scalars['String']>
    headingOne?: Maybe<Scalars['String']>
    headingThree?: Maybe<Scalars['String']>
    headingTwo?: Maybe<Scalars['String']>
    linkedFrom?: Maybe<ComponentTwoColumnInfographicLinkingCollections>
    sectionId?: Maybe<Scalars['String']>
    sys: Sys
    teaserCopy?: Maybe<ComponentTwoColumnInfographicTeaserCopy>
    title?: Maybe<Scalars['String']>
    urlTarget?: Maybe<Scalars['String']>
  }

/** [See type definition](https://app.contentful.com/spaces/9cxgygtadmvl/content_types/componentTwoColumnInfographic) */
export type ComponentTwoColumnInfographicCopyOneArgs = {
  locale?: InputMaybe<Scalars['String']>
}

/** [See type definition](https://app.contentful.com/spaces/9cxgygtadmvl/content_types/componentTwoColumnInfographic) */
export type ComponentTwoColumnInfographicCopyThreeArgs = {
  locale?: InputMaybe<Scalars['String']>
}

/** [See type definition](https://app.contentful.com/spaces/9cxgygtadmvl/content_types/componentTwoColumnInfographic) */
export type ComponentTwoColumnInfographicCopyTwoArgs = {
  locale?: InputMaybe<Scalars['String']>
}

/** [See type definition](https://app.contentful.com/spaces/9cxgygtadmvl/content_types/componentTwoColumnInfographic) */
export type ComponentTwoColumnInfographicCtaTextArgs = {
  locale?: InputMaybe<Scalars['String']>
}

/** [See type definition](https://app.contentful.com/spaces/9cxgygtadmvl/content_types/componentTwoColumnInfographic) */
export type ComponentTwoColumnInfographicCtaUrlArgs = {
  locale?: InputMaybe<Scalars['String']>
}

/** [See type definition](https://app.contentful.com/spaces/9cxgygtadmvl/content_types/componentTwoColumnInfographic) */
export type ComponentTwoColumnInfographicHeadingOneArgs = {
  locale?: InputMaybe<Scalars['String']>
}

/** [See type definition](https://app.contentful.com/spaces/9cxgygtadmvl/content_types/componentTwoColumnInfographic) */
export type ComponentTwoColumnInfographicHeadingThreeArgs = {
  locale?: InputMaybe<Scalars['String']>
}

/** [See type definition](https://app.contentful.com/spaces/9cxgygtadmvl/content_types/componentTwoColumnInfographic) */
export type ComponentTwoColumnInfographicHeadingTwoArgs = {
  locale?: InputMaybe<Scalars['String']>
}

/** [See type definition](https://app.contentful.com/spaces/9cxgygtadmvl/content_types/componentTwoColumnInfographic) */
export type ComponentTwoColumnInfographicLinkedFromArgs = {
  allowedLocales?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
}

/** [See type definition](https://app.contentful.com/spaces/9cxgygtadmvl/content_types/componentTwoColumnInfographic) */
export type ComponentTwoColumnInfographicSectionIdArgs = {
  locale?: InputMaybe<Scalars['String']>
}

/** [See type definition](https://app.contentful.com/spaces/9cxgygtadmvl/content_types/componentTwoColumnInfographic) */
export type ComponentTwoColumnInfographicTeaserCopyArgs = {
  locale?: InputMaybe<Scalars['String']>
}

/** [See type definition](https://app.contentful.com/spaces/9cxgygtadmvl/content_types/componentTwoColumnInfographic) */
export type ComponentTwoColumnInfographicTitleArgs = {
  locale?: InputMaybe<Scalars['String']>
}

/** [See type definition](https://app.contentful.com/spaces/9cxgygtadmvl/content_types/componentTwoColumnInfographic) */
export type ComponentTwoColumnInfographicUrlTargetArgs = {
  locale?: InputMaybe<Scalars['String']>
}

export type ComponentTwoColumnInfographicCollection = {
  __typename?: 'ComponentTwoColumnInfographicCollection'
  items: Array<Maybe<ComponentTwoColumnInfographic>>
  limit: Scalars['Int']
  skip: Scalars['Int']
  total: Scalars['Int']
}

export type ComponentTwoColumnInfographicFilter = {
  AND?: InputMaybe<Array<InputMaybe<ComponentTwoColumnInfographicFilter>>>
  OR?: InputMaybe<Array<InputMaybe<ComponentTwoColumnInfographicFilter>>>
  contentfulMetadata?: InputMaybe<ContentfulMetadataFilter>
  copyOne?: InputMaybe<Scalars['String']>
  copyOne_contains?: InputMaybe<Scalars['String']>
  copyOne_exists?: InputMaybe<Scalars['Boolean']>
  copyOne_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  copyOne_not?: InputMaybe<Scalars['String']>
  copyOne_not_contains?: InputMaybe<Scalars['String']>
  copyOne_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  copyThree?: InputMaybe<Scalars['String']>
  copyThree_contains?: InputMaybe<Scalars['String']>
  copyThree_exists?: InputMaybe<Scalars['Boolean']>
  copyThree_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  copyThree_not?: InputMaybe<Scalars['String']>
  copyThree_not_contains?: InputMaybe<Scalars['String']>
  copyThree_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  copyTwo?: InputMaybe<Scalars['String']>
  copyTwo_contains?: InputMaybe<Scalars['String']>
  copyTwo_exists?: InputMaybe<Scalars['Boolean']>
  copyTwo_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  copyTwo_not?: InputMaybe<Scalars['String']>
  copyTwo_not_contains?: InputMaybe<Scalars['String']>
  copyTwo_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  ctaText?: InputMaybe<Scalars['String']>
  ctaText_contains?: InputMaybe<Scalars['String']>
  ctaText_exists?: InputMaybe<Scalars['Boolean']>
  ctaText_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  ctaText_not?: InputMaybe<Scalars['String']>
  ctaText_not_contains?: InputMaybe<Scalars['String']>
  ctaText_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  ctaUrl?: InputMaybe<Scalars['String']>
  ctaUrl_contains?: InputMaybe<Scalars['String']>
  ctaUrl_exists?: InputMaybe<Scalars['Boolean']>
  ctaUrl_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  ctaUrl_not?: InputMaybe<Scalars['String']>
  ctaUrl_not_contains?: InputMaybe<Scalars['String']>
  ctaUrl_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  headingOne?: InputMaybe<Scalars['String']>
  headingOne_contains?: InputMaybe<Scalars['String']>
  headingOne_exists?: InputMaybe<Scalars['Boolean']>
  headingOne_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  headingOne_not?: InputMaybe<Scalars['String']>
  headingOne_not_contains?: InputMaybe<Scalars['String']>
  headingOne_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  headingThree?: InputMaybe<Scalars['String']>
  headingThree_contains?: InputMaybe<Scalars['String']>
  headingThree_exists?: InputMaybe<Scalars['Boolean']>
  headingThree_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  headingThree_not?: InputMaybe<Scalars['String']>
  headingThree_not_contains?: InputMaybe<Scalars['String']>
  headingThree_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  headingTwo?: InputMaybe<Scalars['String']>
  headingTwo_contains?: InputMaybe<Scalars['String']>
  headingTwo_exists?: InputMaybe<Scalars['Boolean']>
  headingTwo_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  headingTwo_not?: InputMaybe<Scalars['String']>
  headingTwo_not_contains?: InputMaybe<Scalars['String']>
  headingTwo_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  sectionId?: InputMaybe<Scalars['String']>
  sectionId_contains?: InputMaybe<Scalars['String']>
  sectionId_exists?: InputMaybe<Scalars['Boolean']>
  sectionId_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  sectionId_not?: InputMaybe<Scalars['String']>
  sectionId_not_contains?: InputMaybe<Scalars['String']>
  sectionId_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  sys?: InputMaybe<SysFilter>
  teaserCopy_contains?: InputMaybe<Scalars['String']>
  teaserCopy_exists?: InputMaybe<Scalars['Boolean']>
  teaserCopy_not_contains?: InputMaybe<Scalars['String']>
  title?: InputMaybe<Scalars['String']>
  title_contains?: InputMaybe<Scalars['String']>
  title_exists?: InputMaybe<Scalars['Boolean']>
  title_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  title_not?: InputMaybe<Scalars['String']>
  title_not_contains?: InputMaybe<Scalars['String']>
  title_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  urlTarget?: InputMaybe<Scalars['String']>
  urlTarget_contains?: InputMaybe<Scalars['String']>
  urlTarget_exists?: InputMaybe<Scalars['Boolean']>
  urlTarget_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  urlTarget_not?: InputMaybe<Scalars['String']>
  urlTarget_not_contains?: InputMaybe<Scalars['String']>
  urlTarget_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
}

export type ComponentTwoColumnInfographicLinkingCollections = {
  __typename?: 'ComponentTwoColumnInfographicLinkingCollections'
  campaignPagesCollection?: Maybe<CampaignPagesCollection>
  entryCollection?: Maybe<EntryCollection>
  templateAboutUsCollection?: Maybe<TemplateAboutUsCollection>
  templateBookingCollection?: Maybe<TemplateBookingCollection>
  templateCareersCollection?: Maybe<TemplateCareersCollection>
  templateContactUsCollection?: Maybe<TemplateContactUsCollection>
  templateDestinationsCollection?: Maybe<TemplateDestinationsCollection>
  templateForaReserveCollection?: Maybe<TemplateForaReserveCollection>
  templateForaXCollection?: Maybe<TemplateForaXCollection>
  templateHomeCollection?: Maybe<TemplateHomeCollection>
  templateJoinUsCollection?: Maybe<TemplateJoinUsCollection>
  templateNewsletterCollection?: Maybe<TemplateNewsletterCollection>
  templatePartnersCollection?: Maybe<TemplatePartnersCollection>
  templateReviewsCollection?: Maybe<TemplateReviewsCollection>
  templateWhyBookWithForaCollection?: Maybe<TemplateWhyBookWithForaCollection>
}

export type ComponentTwoColumnInfographicLinkingCollectionsCampaignPagesCollectionArgs =
  {
    limit?: InputMaybe<Scalars['Int']>
    locale?: InputMaybe<Scalars['String']>
    order?: InputMaybe<
      Array<
        InputMaybe<ComponentTwoColumnInfographicLinkingCollectionsCampaignPagesCollectionOrder>
      >
    >
    preview?: InputMaybe<Scalars['Boolean']>
    skip?: InputMaybe<Scalars['Int']>
  }

export type ComponentTwoColumnInfographicLinkingCollectionsEntryCollectionArgs =
  {
    limit?: InputMaybe<Scalars['Int']>
    locale?: InputMaybe<Scalars['String']>
    preview?: InputMaybe<Scalars['Boolean']>
    skip?: InputMaybe<Scalars['Int']>
  }

export type ComponentTwoColumnInfographicLinkingCollectionsTemplateAboutUsCollectionArgs =
  {
    limit?: InputMaybe<Scalars['Int']>
    locale?: InputMaybe<Scalars['String']>
    order?: InputMaybe<
      Array<
        InputMaybe<ComponentTwoColumnInfographicLinkingCollectionsTemplateAboutUsCollectionOrder>
      >
    >
    preview?: InputMaybe<Scalars['Boolean']>
    skip?: InputMaybe<Scalars['Int']>
  }

export type ComponentTwoColumnInfographicLinkingCollectionsTemplateBookingCollectionArgs =
  {
    limit?: InputMaybe<Scalars['Int']>
    locale?: InputMaybe<Scalars['String']>
    order?: InputMaybe<
      Array<
        InputMaybe<ComponentTwoColumnInfographicLinkingCollectionsTemplateBookingCollectionOrder>
      >
    >
    preview?: InputMaybe<Scalars['Boolean']>
    skip?: InputMaybe<Scalars['Int']>
  }

export type ComponentTwoColumnInfographicLinkingCollectionsTemplateCareersCollectionArgs =
  {
    limit?: InputMaybe<Scalars['Int']>
    locale?: InputMaybe<Scalars['String']>
    order?: InputMaybe<
      Array<
        InputMaybe<ComponentTwoColumnInfographicLinkingCollectionsTemplateCareersCollectionOrder>
      >
    >
    preview?: InputMaybe<Scalars['Boolean']>
    skip?: InputMaybe<Scalars['Int']>
  }

export type ComponentTwoColumnInfographicLinkingCollectionsTemplateContactUsCollectionArgs =
  {
    limit?: InputMaybe<Scalars['Int']>
    locale?: InputMaybe<Scalars['String']>
    order?: InputMaybe<
      Array<
        InputMaybe<ComponentTwoColumnInfographicLinkingCollectionsTemplateContactUsCollectionOrder>
      >
    >
    preview?: InputMaybe<Scalars['Boolean']>
    skip?: InputMaybe<Scalars['Int']>
  }

export type ComponentTwoColumnInfographicLinkingCollectionsTemplateDestinationsCollectionArgs =
  {
    limit?: InputMaybe<Scalars['Int']>
    locale?: InputMaybe<Scalars['String']>
    order?: InputMaybe<
      Array<
        InputMaybe<ComponentTwoColumnInfographicLinkingCollectionsTemplateDestinationsCollectionOrder>
      >
    >
    preview?: InputMaybe<Scalars['Boolean']>
    skip?: InputMaybe<Scalars['Int']>
  }

export type ComponentTwoColumnInfographicLinkingCollectionsTemplateForaReserveCollectionArgs =
  {
    limit?: InputMaybe<Scalars['Int']>
    locale?: InputMaybe<Scalars['String']>
    order?: InputMaybe<
      Array<
        InputMaybe<ComponentTwoColumnInfographicLinkingCollectionsTemplateForaReserveCollectionOrder>
      >
    >
    preview?: InputMaybe<Scalars['Boolean']>
    skip?: InputMaybe<Scalars['Int']>
  }

export type ComponentTwoColumnInfographicLinkingCollectionsTemplateForaXCollectionArgs =
  {
    limit?: InputMaybe<Scalars['Int']>
    locale?: InputMaybe<Scalars['String']>
    order?: InputMaybe<
      Array<
        InputMaybe<ComponentTwoColumnInfographicLinkingCollectionsTemplateForaXCollectionOrder>
      >
    >
    preview?: InputMaybe<Scalars['Boolean']>
    skip?: InputMaybe<Scalars['Int']>
  }

export type ComponentTwoColumnInfographicLinkingCollectionsTemplateHomeCollectionArgs =
  {
    limit?: InputMaybe<Scalars['Int']>
    locale?: InputMaybe<Scalars['String']>
    order?: InputMaybe<
      Array<
        InputMaybe<ComponentTwoColumnInfographicLinkingCollectionsTemplateHomeCollectionOrder>
      >
    >
    preview?: InputMaybe<Scalars['Boolean']>
    skip?: InputMaybe<Scalars['Int']>
  }

export type ComponentTwoColumnInfographicLinkingCollectionsTemplateJoinUsCollectionArgs =
  {
    limit?: InputMaybe<Scalars['Int']>
    locale?: InputMaybe<Scalars['String']>
    order?: InputMaybe<
      Array<
        InputMaybe<ComponentTwoColumnInfographicLinkingCollectionsTemplateJoinUsCollectionOrder>
      >
    >
    preview?: InputMaybe<Scalars['Boolean']>
    skip?: InputMaybe<Scalars['Int']>
  }

export type ComponentTwoColumnInfographicLinkingCollectionsTemplateNewsletterCollectionArgs =
  {
    limit?: InputMaybe<Scalars['Int']>
    locale?: InputMaybe<Scalars['String']>
    order?: InputMaybe<
      Array<
        InputMaybe<ComponentTwoColumnInfographicLinkingCollectionsTemplateNewsletterCollectionOrder>
      >
    >
    preview?: InputMaybe<Scalars['Boolean']>
    skip?: InputMaybe<Scalars['Int']>
  }

export type ComponentTwoColumnInfographicLinkingCollectionsTemplatePartnersCollectionArgs =
  {
    limit?: InputMaybe<Scalars['Int']>
    locale?: InputMaybe<Scalars['String']>
    order?: InputMaybe<
      Array<
        InputMaybe<ComponentTwoColumnInfographicLinkingCollectionsTemplatePartnersCollectionOrder>
      >
    >
    preview?: InputMaybe<Scalars['Boolean']>
    skip?: InputMaybe<Scalars['Int']>
  }

export type ComponentTwoColumnInfographicLinkingCollectionsTemplateReviewsCollectionArgs =
  {
    limit?: InputMaybe<Scalars['Int']>
    locale?: InputMaybe<Scalars['String']>
    order?: InputMaybe<
      Array<
        InputMaybe<ComponentTwoColumnInfographicLinkingCollectionsTemplateReviewsCollectionOrder>
      >
    >
    preview?: InputMaybe<Scalars['Boolean']>
    skip?: InputMaybe<Scalars['Int']>
  }

export type ComponentTwoColumnInfographicLinkingCollectionsTemplateWhyBookWithForaCollectionArgs =
  {
    limit?: InputMaybe<Scalars['Int']>
    locale?: InputMaybe<Scalars['String']>
    order?: InputMaybe<
      Array<
        InputMaybe<ComponentTwoColumnInfographicLinkingCollectionsTemplateWhyBookWithForaCollectionOrder>
      >
    >
    preview?: InputMaybe<Scalars['Boolean']>
    skip?: InputMaybe<Scalars['Int']>
  }

export enum ComponentTwoColumnInfographicLinkingCollectionsCampaignPagesCollectionOrder {
  CollapseFooterAsc = 'collapseFooter_ASC',
  CollapseFooterDesc = 'collapseFooter_DESC',
  CollapseHeaderAsc = 'collapseHeader_ASC',
  CollapseHeaderDesc = 'collapseHeader_DESC',
  HideFooterAsc = 'hideFooter_ASC',
  HideFooterDesc = 'hideFooter_DESC',
  HideGlobalBannerAsc = 'hideGlobalBanner_ASC',
  HideGlobalBannerDesc = 'hideGlobalBanner_DESC',
  JotformIdAsc = 'jotformId_ASC',
  JotformIdDesc = 'jotformId_DESC',
  NoindexNofollowAsc = 'noindexNofollow_ASC',
  NoindexNofollowDesc = 'noindexNofollow_DESC',
  ShowHeaderAsc = 'showHeader_ASC',
  ShowHeaderDesc = 'showHeader_DESC',
  SlugAsc = 'slug_ASC',
  SlugDesc = 'slug_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC',
}

export enum ComponentTwoColumnInfographicLinkingCollectionsTemplateAboutUsCollectionOrder {
  CollapseHeaderAsc = 'collapseHeader_ASC',
  CollapseHeaderDesc = 'collapseHeader_DESC',
  HeaderBlockTitleAsc = 'headerBlockTitle_ASC',
  HeaderBlockTitleDesc = 'headerBlockTitle_DESC',
  ShowHeaderAsc = 'showHeader_ASC',
  ShowHeaderDesc = 'showHeader_DESC',
  SubtitleAsc = 'subtitle_ASC',
  SubtitleDesc = 'subtitle_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC',
}

export enum ComponentTwoColumnInfographicLinkingCollectionsTemplateBookingCollectionOrder {
  CollapseHeaderAsc = 'collapseHeader_ASC',
  CollapseHeaderDesc = 'collapseHeader_DESC',
  ShowHeaderAsc = 'showHeader_ASC',
  ShowHeaderDesc = 'showHeader_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC',
}

export enum ComponentTwoColumnInfographicLinkingCollectionsTemplateCareersCollectionOrder {
  CollapseHeaderAsc = 'collapseHeader_ASC',
  CollapseHeaderDesc = 'collapseHeader_DESC',
  ShowHeaderAsc = 'showHeader_ASC',
  ShowHeaderDesc = 'showHeader_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC',
}

export enum ComponentTwoColumnInfographicLinkingCollectionsTemplateContactUsCollectionOrder {
  CollapseHeaderAsc = 'collapseHeader_ASC',
  CollapseHeaderDesc = 'collapseHeader_DESC',
  ShowHeaderAsc = 'showHeader_ASC',
  ShowHeaderDesc = 'showHeader_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC',
}

export enum ComponentTwoColumnInfographicLinkingCollectionsTemplateDestinationsCollectionOrder {
  CollapseHeaderAsc = 'collapseHeader_ASC',
  CollapseHeaderDesc = 'collapseHeader_DESC',
  CollapsibleHeadingAsc = 'collapsibleHeading_ASC',
  CollapsibleHeadingDesc = 'collapsibleHeading_DESC',
  ShowHeaderAsc = 'showHeader_ASC',
  ShowHeaderDesc = 'showHeader_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC',
}

export enum ComponentTwoColumnInfographicLinkingCollectionsTemplateForaReserveCollectionOrder {
  CollapseHeaderAsc = 'collapseHeader_ASC',
  CollapseHeaderDesc = 'collapseHeader_DESC',
  ShowHeaderAsc = 'showHeader_ASC',
  ShowHeaderDesc = 'showHeader_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC',
}

export enum ComponentTwoColumnInfographicLinkingCollectionsTemplateForaXCollectionOrder {
  AdvisorGridHeaderAsc = 'advisorGridHeader_ASC',
  AdvisorGridHeaderDesc = 'advisorGridHeader_DESC',
  AdvisorGridSubCopyAsc = 'advisorGridSubCopy_ASC',
  AdvisorGridSubCopyDesc = 'advisorGridSubCopy_DESC',
  CollapseHeaderAsc = 'collapseHeader_ASC',
  CollapseHeaderDesc = 'collapseHeader_DESC',
  ShowHeaderAsc = 'showHeader_ASC',
  ShowHeaderDesc = 'showHeader_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC',
}

export enum ComponentTwoColumnInfographicLinkingCollectionsTemplateHomeCollectionOrder {
  CollapseHeaderAsc = 'collapseHeader_ASC',
  CollapseHeaderDesc = 'collapseHeader_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC',
}

export enum ComponentTwoColumnInfographicLinkingCollectionsTemplateJoinUsCollectionOrder {
  CollapseHeaderAsc = 'collapseHeader_ASC',
  CollapseHeaderDesc = 'collapseHeader_DESC',
  HideGlobalBannerAsc = 'hideGlobalBanner_ASC',
  HideGlobalBannerDesc = 'hideGlobalBanner_DESC',
  ShowHeaderAsc = 'showHeader_ASC',
  ShowHeaderDesc = 'showHeader_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC',
}

export enum ComponentTwoColumnInfographicLinkingCollectionsTemplateNewsletterCollectionOrder {
  CollapseHeaderAsc = 'collapseHeader_ASC',
  CollapseHeaderDesc = 'collapseHeader_DESC',
  ShowHeaderAsc = 'showHeader_ASC',
  ShowHeaderDesc = 'showHeader_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC',
}

export enum ComponentTwoColumnInfographicLinkingCollectionsTemplatePartnersCollectionOrder {
  CollapseHeaderAsc = 'collapseHeader_ASC',
  CollapseHeaderDesc = 'collapseHeader_DESC',
  ShowHeaderAsc = 'showHeader_ASC',
  ShowHeaderDesc = 'showHeader_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC',
}

export enum ComponentTwoColumnInfographicLinkingCollectionsTemplateReviewsCollectionOrder {
  CollapseHeaderAsc = 'collapseHeader_ASC',
  CollapseHeaderDesc = 'collapseHeader_DESC',
  ShowHeaderAsc = 'showHeader_ASC',
  ShowHeaderDesc = 'showHeader_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC',
}

export enum ComponentTwoColumnInfographicLinkingCollectionsTemplateWhyBookWithForaCollectionOrder {
  CollapseHeaderAsc = 'collapseHeader_ASC',
  CollapseHeaderDesc = 'collapseHeader_DESC',
  ShowHeaderAsc = 'showHeader_ASC',
  ShowHeaderDesc = 'showHeader_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC',
}

export enum ComponentTwoColumnInfographicOrder {
  CopyOneAsc = 'copyOne_ASC',
  CopyOneDesc = 'copyOne_DESC',
  CopyThreeAsc = 'copyThree_ASC',
  CopyThreeDesc = 'copyThree_DESC',
  CopyTwoAsc = 'copyTwo_ASC',
  CopyTwoDesc = 'copyTwo_DESC',
  CtaTextAsc = 'ctaText_ASC',
  CtaTextDesc = 'ctaText_DESC',
  CtaUrlAsc = 'ctaUrl_ASC',
  CtaUrlDesc = 'ctaUrl_DESC',
  HeadingOneAsc = 'headingOne_ASC',
  HeadingOneDesc = 'headingOne_DESC',
  HeadingThreeAsc = 'headingThree_ASC',
  HeadingThreeDesc = 'headingThree_DESC',
  HeadingTwoAsc = 'headingTwo_ASC',
  HeadingTwoDesc = 'headingTwo_DESC',
  SectionIdAsc = 'sectionId_ASC',
  SectionIdDesc = 'sectionId_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC',
  UrlTargetAsc = 'urlTarget_ASC',
  UrlTargetDesc = 'urlTarget_DESC',
}

export type ComponentTwoColumnInfographicTeaserCopy = {
  __typename?: 'ComponentTwoColumnInfographicTeaserCopy'
  json: Scalars['JSON']
  links: ComponentTwoColumnInfographicTeaserCopyLinks
}

export type ComponentTwoColumnInfographicTeaserCopyAssets = {
  __typename?: 'ComponentTwoColumnInfographicTeaserCopyAssets'
  block: Array<Maybe<Asset>>
  hyperlink: Array<Maybe<Asset>>
}

export type ComponentTwoColumnInfographicTeaserCopyEntries = {
  __typename?: 'ComponentTwoColumnInfographicTeaserCopyEntries'
  block: Array<Maybe<Entry>>
  hyperlink: Array<Maybe<Entry>>
  inline: Array<Maybe<Entry>>
}

export type ComponentTwoColumnInfographicTeaserCopyLinks = {
  __typename?: 'ComponentTwoColumnInfographicTeaserCopyLinks'
  assets: ComponentTwoColumnInfographicTeaserCopyAssets
  entries: ComponentTwoColumnInfographicTeaserCopyEntries
  resources: ComponentTwoColumnInfographicTeaserCopyResources
}

export type ComponentTwoColumnInfographicTeaserCopyResources = {
  __typename?: 'ComponentTwoColumnInfographicTeaserCopyResources'
  block: Array<ComponentTwoColumnInfographicTeaserCopyResourcesBlock>
  hyperlink: Array<ComponentTwoColumnInfographicTeaserCopyResourcesHyperlink>
  inline: Array<ComponentTwoColumnInfographicTeaserCopyResourcesInline>
}

export type ComponentTwoColumnInfographicTeaserCopyResourcesBlock =
  ResourceLink & {
    __typename?: 'ComponentTwoColumnInfographicTeaserCopyResourcesBlock'
    sys: ResourceSys
  }

export type ComponentTwoColumnInfographicTeaserCopyResourcesHyperlink =
  ResourceLink & {
    __typename?: 'ComponentTwoColumnInfographicTeaserCopyResourcesHyperlink'
    sys: ResourceSys
  }

export type ComponentTwoColumnInfographicTeaserCopyResourcesInline =
  ResourceLink & {
    __typename?: 'ComponentTwoColumnInfographicTeaserCopyResourcesInline'
    sys: ResourceSys
  }

/** [See type definition](https://app.contentful.com/spaces/9cxgygtadmvl/content_types/componentTwoColumnPerksSection) */
export type ComponentTwoColumnPerksSection = Entry &
  _Node & {
    __typename?: 'ComponentTwoColumnPerksSection'
    _id: Scalars['ID']
    analyticsModule?: Maybe<SettingsAnalyticsModule>
    contentfulMetadata: ContentfulMetadata
    copyOne?: Maybe<Scalars['String']>
    copyThree?: Maybe<Scalars['String']>
    copyTwo?: Maybe<Scalars['String']>
    heading?: Maybe<Scalars['String']>
    headingStyle?: Maybe<Scalars['String']>
    iconOne?: Maybe<Asset>
    iconThree?: Maybe<Asset>
    iconTwo?: Maybe<Asset>
    image?: Maybe<Asset>
    imageOnRight?: Maybe<Scalars['Boolean']>
    internalLabel?: Maybe<Scalars['String']>
    linkedFrom?: Maybe<ComponentTwoColumnPerksSectionLinkingCollections>
    sectionId?: Maybe<Scalars['String']>
    subtitleOne?: Maybe<Scalars['String']>
    subtitleThree?: Maybe<Scalars['String']>
    subtitleTwo?: Maybe<Scalars['String']>
    sys: Sys
    teaserCopy?: Maybe<ComponentTwoColumnPerksSectionTeaserCopy>
    title?: Maybe<Scalars['String']>
    titleStyle?: Maybe<Scalars['String']>
  }

/** [See type definition](https://app.contentful.com/spaces/9cxgygtadmvl/content_types/componentTwoColumnPerksSection) */
export type ComponentTwoColumnPerksSectionAnalyticsModuleArgs = {
  locale?: InputMaybe<Scalars['String']>
  preview?: InputMaybe<Scalars['Boolean']>
  where?: InputMaybe<SettingsAnalyticsModuleFilter>
}

/** [See type definition](https://app.contentful.com/spaces/9cxgygtadmvl/content_types/componentTwoColumnPerksSection) */
export type ComponentTwoColumnPerksSectionCopyOneArgs = {
  locale?: InputMaybe<Scalars['String']>
}

/** [See type definition](https://app.contentful.com/spaces/9cxgygtadmvl/content_types/componentTwoColumnPerksSection) */
export type ComponentTwoColumnPerksSectionCopyThreeArgs = {
  locale?: InputMaybe<Scalars['String']>
}

/** [See type definition](https://app.contentful.com/spaces/9cxgygtadmvl/content_types/componentTwoColumnPerksSection) */
export type ComponentTwoColumnPerksSectionCopyTwoArgs = {
  locale?: InputMaybe<Scalars['String']>
}

/** [See type definition](https://app.contentful.com/spaces/9cxgygtadmvl/content_types/componentTwoColumnPerksSection) */
export type ComponentTwoColumnPerksSectionHeadingArgs = {
  locale?: InputMaybe<Scalars['String']>
}

/** [See type definition](https://app.contentful.com/spaces/9cxgygtadmvl/content_types/componentTwoColumnPerksSection) */
export type ComponentTwoColumnPerksSectionHeadingStyleArgs = {
  locale?: InputMaybe<Scalars['String']>
}

/** [See type definition](https://app.contentful.com/spaces/9cxgygtadmvl/content_types/componentTwoColumnPerksSection) */
export type ComponentTwoColumnPerksSectionIconOneArgs = {
  locale?: InputMaybe<Scalars['String']>
  preview?: InputMaybe<Scalars['Boolean']>
}

/** [See type definition](https://app.contentful.com/spaces/9cxgygtadmvl/content_types/componentTwoColumnPerksSection) */
export type ComponentTwoColumnPerksSectionIconThreeArgs = {
  locale?: InputMaybe<Scalars['String']>
  preview?: InputMaybe<Scalars['Boolean']>
}

/** [See type definition](https://app.contentful.com/spaces/9cxgygtadmvl/content_types/componentTwoColumnPerksSection) */
export type ComponentTwoColumnPerksSectionIconTwoArgs = {
  locale?: InputMaybe<Scalars['String']>
  preview?: InputMaybe<Scalars['Boolean']>
}

/** [See type definition](https://app.contentful.com/spaces/9cxgygtadmvl/content_types/componentTwoColumnPerksSection) */
export type ComponentTwoColumnPerksSectionImageArgs = {
  locale?: InputMaybe<Scalars['String']>
  preview?: InputMaybe<Scalars['Boolean']>
}

/** [See type definition](https://app.contentful.com/spaces/9cxgygtadmvl/content_types/componentTwoColumnPerksSection) */
export type ComponentTwoColumnPerksSectionImageOnRightArgs = {
  locale?: InputMaybe<Scalars['String']>
}

/** [See type definition](https://app.contentful.com/spaces/9cxgygtadmvl/content_types/componentTwoColumnPerksSection) */
export type ComponentTwoColumnPerksSectionInternalLabelArgs = {
  locale?: InputMaybe<Scalars['String']>
}

/** [See type definition](https://app.contentful.com/spaces/9cxgygtadmvl/content_types/componentTwoColumnPerksSection) */
export type ComponentTwoColumnPerksSectionLinkedFromArgs = {
  allowedLocales?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
}

/** [See type definition](https://app.contentful.com/spaces/9cxgygtadmvl/content_types/componentTwoColumnPerksSection) */
export type ComponentTwoColumnPerksSectionSectionIdArgs = {
  locale?: InputMaybe<Scalars['String']>
}

/** [See type definition](https://app.contentful.com/spaces/9cxgygtadmvl/content_types/componentTwoColumnPerksSection) */
export type ComponentTwoColumnPerksSectionSubtitleOneArgs = {
  locale?: InputMaybe<Scalars['String']>
}

/** [See type definition](https://app.contentful.com/spaces/9cxgygtadmvl/content_types/componentTwoColumnPerksSection) */
export type ComponentTwoColumnPerksSectionSubtitleThreeArgs = {
  locale?: InputMaybe<Scalars['String']>
}

/** [See type definition](https://app.contentful.com/spaces/9cxgygtadmvl/content_types/componentTwoColumnPerksSection) */
export type ComponentTwoColumnPerksSectionSubtitleTwoArgs = {
  locale?: InputMaybe<Scalars['String']>
}

/** [See type definition](https://app.contentful.com/spaces/9cxgygtadmvl/content_types/componentTwoColumnPerksSection) */
export type ComponentTwoColumnPerksSectionTeaserCopyArgs = {
  locale?: InputMaybe<Scalars['String']>
}

/** [See type definition](https://app.contentful.com/spaces/9cxgygtadmvl/content_types/componentTwoColumnPerksSection) */
export type ComponentTwoColumnPerksSectionTitleArgs = {
  locale?: InputMaybe<Scalars['String']>
}

/** [See type definition](https://app.contentful.com/spaces/9cxgygtadmvl/content_types/componentTwoColumnPerksSection) */
export type ComponentTwoColumnPerksSectionTitleStyleArgs = {
  locale?: InputMaybe<Scalars['String']>
}

export type ComponentTwoColumnPerksSectionCollection = {
  __typename?: 'ComponentTwoColumnPerksSectionCollection'
  items: Array<Maybe<ComponentTwoColumnPerksSection>>
  limit: Scalars['Int']
  skip: Scalars['Int']
  total: Scalars['Int']
}

export type ComponentTwoColumnPerksSectionFilter = {
  AND?: InputMaybe<Array<InputMaybe<ComponentTwoColumnPerksSectionFilter>>>
  OR?: InputMaybe<Array<InputMaybe<ComponentTwoColumnPerksSectionFilter>>>
  analyticsModule?: InputMaybe<CfSettingsAnalyticsModuleNestedFilter>
  analyticsModule_exists?: InputMaybe<Scalars['Boolean']>
  contentfulMetadata?: InputMaybe<ContentfulMetadataFilter>
  copyOne?: InputMaybe<Scalars['String']>
  copyOne_contains?: InputMaybe<Scalars['String']>
  copyOne_exists?: InputMaybe<Scalars['Boolean']>
  copyOne_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  copyOne_not?: InputMaybe<Scalars['String']>
  copyOne_not_contains?: InputMaybe<Scalars['String']>
  copyOne_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  copyThree?: InputMaybe<Scalars['String']>
  copyThree_contains?: InputMaybe<Scalars['String']>
  copyThree_exists?: InputMaybe<Scalars['Boolean']>
  copyThree_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  copyThree_not?: InputMaybe<Scalars['String']>
  copyThree_not_contains?: InputMaybe<Scalars['String']>
  copyThree_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  copyTwo?: InputMaybe<Scalars['String']>
  copyTwo_contains?: InputMaybe<Scalars['String']>
  copyTwo_exists?: InputMaybe<Scalars['Boolean']>
  copyTwo_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  copyTwo_not?: InputMaybe<Scalars['String']>
  copyTwo_not_contains?: InputMaybe<Scalars['String']>
  copyTwo_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  heading?: InputMaybe<Scalars['String']>
  headingStyle?: InputMaybe<Scalars['String']>
  headingStyle_contains?: InputMaybe<Scalars['String']>
  headingStyle_exists?: InputMaybe<Scalars['Boolean']>
  headingStyle_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  headingStyle_not?: InputMaybe<Scalars['String']>
  headingStyle_not_contains?: InputMaybe<Scalars['String']>
  headingStyle_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  heading_contains?: InputMaybe<Scalars['String']>
  heading_exists?: InputMaybe<Scalars['Boolean']>
  heading_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  heading_not?: InputMaybe<Scalars['String']>
  heading_not_contains?: InputMaybe<Scalars['String']>
  heading_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  iconOne_exists?: InputMaybe<Scalars['Boolean']>
  iconThree_exists?: InputMaybe<Scalars['Boolean']>
  iconTwo_exists?: InputMaybe<Scalars['Boolean']>
  imageOnRight?: InputMaybe<Scalars['Boolean']>
  imageOnRight_exists?: InputMaybe<Scalars['Boolean']>
  imageOnRight_not?: InputMaybe<Scalars['Boolean']>
  image_exists?: InputMaybe<Scalars['Boolean']>
  internalLabel?: InputMaybe<Scalars['String']>
  internalLabel_contains?: InputMaybe<Scalars['String']>
  internalLabel_exists?: InputMaybe<Scalars['Boolean']>
  internalLabel_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  internalLabel_not?: InputMaybe<Scalars['String']>
  internalLabel_not_contains?: InputMaybe<Scalars['String']>
  internalLabel_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  sectionId?: InputMaybe<Scalars['String']>
  sectionId_contains?: InputMaybe<Scalars['String']>
  sectionId_exists?: InputMaybe<Scalars['Boolean']>
  sectionId_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  sectionId_not?: InputMaybe<Scalars['String']>
  sectionId_not_contains?: InputMaybe<Scalars['String']>
  sectionId_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  subtitleOne?: InputMaybe<Scalars['String']>
  subtitleOne_contains?: InputMaybe<Scalars['String']>
  subtitleOne_exists?: InputMaybe<Scalars['Boolean']>
  subtitleOne_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  subtitleOne_not?: InputMaybe<Scalars['String']>
  subtitleOne_not_contains?: InputMaybe<Scalars['String']>
  subtitleOne_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  subtitleThree?: InputMaybe<Scalars['String']>
  subtitleThree_contains?: InputMaybe<Scalars['String']>
  subtitleThree_exists?: InputMaybe<Scalars['Boolean']>
  subtitleThree_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  subtitleThree_not?: InputMaybe<Scalars['String']>
  subtitleThree_not_contains?: InputMaybe<Scalars['String']>
  subtitleThree_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  subtitleTwo?: InputMaybe<Scalars['String']>
  subtitleTwo_contains?: InputMaybe<Scalars['String']>
  subtitleTwo_exists?: InputMaybe<Scalars['Boolean']>
  subtitleTwo_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  subtitleTwo_not?: InputMaybe<Scalars['String']>
  subtitleTwo_not_contains?: InputMaybe<Scalars['String']>
  subtitleTwo_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  sys?: InputMaybe<SysFilter>
  teaserCopy_contains?: InputMaybe<Scalars['String']>
  teaserCopy_exists?: InputMaybe<Scalars['Boolean']>
  teaserCopy_not_contains?: InputMaybe<Scalars['String']>
  title?: InputMaybe<Scalars['String']>
  titleStyle?: InputMaybe<Scalars['String']>
  titleStyle_contains?: InputMaybe<Scalars['String']>
  titleStyle_exists?: InputMaybe<Scalars['Boolean']>
  titleStyle_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  titleStyle_not?: InputMaybe<Scalars['String']>
  titleStyle_not_contains?: InputMaybe<Scalars['String']>
  titleStyle_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  title_contains?: InputMaybe<Scalars['String']>
  title_exists?: InputMaybe<Scalars['Boolean']>
  title_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  title_not?: InputMaybe<Scalars['String']>
  title_not_contains?: InputMaybe<Scalars['String']>
  title_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
}

export type ComponentTwoColumnPerksSectionLinkingCollections = {
  __typename?: 'ComponentTwoColumnPerksSectionLinkingCollections'
  entryCollection?: Maybe<EntryCollection>
  templateAboutUsCollection?: Maybe<TemplateAboutUsCollection>
  templateForaXCollection?: Maybe<TemplateForaXCollection>
  templateJoinUsCollection?: Maybe<TemplateJoinUsCollection>
  templatePageCollection?: Maybe<TemplatePageCollection>
  templateWhyBookWithForaCollection?: Maybe<TemplateWhyBookWithForaCollection>
}

export type ComponentTwoColumnPerksSectionLinkingCollectionsEntryCollectionArgs =
  {
    limit?: InputMaybe<Scalars['Int']>
    locale?: InputMaybe<Scalars['String']>
    preview?: InputMaybe<Scalars['Boolean']>
    skip?: InputMaybe<Scalars['Int']>
  }

export type ComponentTwoColumnPerksSectionLinkingCollectionsTemplateAboutUsCollectionArgs =
  {
    limit?: InputMaybe<Scalars['Int']>
    locale?: InputMaybe<Scalars['String']>
    order?: InputMaybe<
      Array<
        InputMaybe<ComponentTwoColumnPerksSectionLinkingCollectionsTemplateAboutUsCollectionOrder>
      >
    >
    preview?: InputMaybe<Scalars['Boolean']>
    skip?: InputMaybe<Scalars['Int']>
  }

export type ComponentTwoColumnPerksSectionLinkingCollectionsTemplateForaXCollectionArgs =
  {
    limit?: InputMaybe<Scalars['Int']>
    locale?: InputMaybe<Scalars['String']>
    order?: InputMaybe<
      Array<
        InputMaybe<ComponentTwoColumnPerksSectionLinkingCollectionsTemplateForaXCollectionOrder>
      >
    >
    preview?: InputMaybe<Scalars['Boolean']>
    skip?: InputMaybe<Scalars['Int']>
  }

export type ComponentTwoColumnPerksSectionLinkingCollectionsTemplateJoinUsCollectionArgs =
  {
    limit?: InputMaybe<Scalars['Int']>
    locale?: InputMaybe<Scalars['String']>
    order?: InputMaybe<
      Array<
        InputMaybe<ComponentTwoColumnPerksSectionLinkingCollectionsTemplateJoinUsCollectionOrder>
      >
    >
    preview?: InputMaybe<Scalars['Boolean']>
    skip?: InputMaybe<Scalars['Int']>
  }

export type ComponentTwoColumnPerksSectionLinkingCollectionsTemplatePageCollectionArgs =
  {
    limit?: InputMaybe<Scalars['Int']>
    locale?: InputMaybe<Scalars['String']>
    order?: InputMaybe<
      Array<
        InputMaybe<ComponentTwoColumnPerksSectionLinkingCollectionsTemplatePageCollectionOrder>
      >
    >
    preview?: InputMaybe<Scalars['Boolean']>
    skip?: InputMaybe<Scalars['Int']>
  }

export type ComponentTwoColumnPerksSectionLinkingCollectionsTemplateWhyBookWithForaCollectionArgs =
  {
    limit?: InputMaybe<Scalars['Int']>
    locale?: InputMaybe<Scalars['String']>
    order?: InputMaybe<
      Array<
        InputMaybe<ComponentTwoColumnPerksSectionLinkingCollectionsTemplateWhyBookWithForaCollectionOrder>
      >
    >
    preview?: InputMaybe<Scalars['Boolean']>
    skip?: InputMaybe<Scalars['Int']>
  }

export enum ComponentTwoColumnPerksSectionLinkingCollectionsTemplateAboutUsCollectionOrder {
  CollapseHeaderAsc = 'collapseHeader_ASC',
  CollapseHeaderDesc = 'collapseHeader_DESC',
  HeaderBlockTitleAsc = 'headerBlockTitle_ASC',
  HeaderBlockTitleDesc = 'headerBlockTitle_DESC',
  ShowHeaderAsc = 'showHeader_ASC',
  ShowHeaderDesc = 'showHeader_DESC',
  SubtitleAsc = 'subtitle_ASC',
  SubtitleDesc = 'subtitle_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC',
}

export enum ComponentTwoColumnPerksSectionLinkingCollectionsTemplateForaXCollectionOrder {
  AdvisorGridHeaderAsc = 'advisorGridHeader_ASC',
  AdvisorGridHeaderDesc = 'advisorGridHeader_DESC',
  AdvisorGridSubCopyAsc = 'advisorGridSubCopy_ASC',
  AdvisorGridSubCopyDesc = 'advisorGridSubCopy_DESC',
  CollapseHeaderAsc = 'collapseHeader_ASC',
  CollapseHeaderDesc = 'collapseHeader_DESC',
  ShowHeaderAsc = 'showHeader_ASC',
  ShowHeaderDesc = 'showHeader_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC',
}

export enum ComponentTwoColumnPerksSectionLinkingCollectionsTemplateJoinUsCollectionOrder {
  CollapseHeaderAsc = 'collapseHeader_ASC',
  CollapseHeaderDesc = 'collapseHeader_DESC',
  HideGlobalBannerAsc = 'hideGlobalBanner_ASC',
  HideGlobalBannerDesc = 'hideGlobalBanner_DESC',
  ShowHeaderAsc = 'showHeader_ASC',
  ShowHeaderDesc = 'showHeader_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC',
}

export enum ComponentTwoColumnPerksSectionLinkingCollectionsTemplatePageCollectionOrder {
  DirectoryAsc = 'directory_ASC',
  DirectoryDesc = 'directory_DESC',
  InternalLabelAsc = 'internalLabel_ASC',
  InternalLabelDesc = 'internalLabel_DESC',
  MetaTitleAsc = 'metaTitle_ASC',
  MetaTitleDesc = 'metaTitle_DESC',
  NoindexNofollowAsc = 'noindexNofollow_ASC',
  NoindexNofollowDesc = 'noindexNofollow_DESC',
  SlugAsc = 'slug_ASC',
  SlugDesc = 'slug_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
}

export enum ComponentTwoColumnPerksSectionLinkingCollectionsTemplateWhyBookWithForaCollectionOrder {
  CollapseHeaderAsc = 'collapseHeader_ASC',
  CollapseHeaderDesc = 'collapseHeader_DESC',
  ShowHeaderAsc = 'showHeader_ASC',
  ShowHeaderDesc = 'showHeader_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC',
}

export enum ComponentTwoColumnPerksSectionOrder {
  HeadingStyleAsc = 'headingStyle_ASC',
  HeadingStyleDesc = 'headingStyle_DESC',
  HeadingAsc = 'heading_ASC',
  HeadingDesc = 'heading_DESC',
  ImageOnRightAsc = 'imageOnRight_ASC',
  ImageOnRightDesc = 'imageOnRight_DESC',
  InternalLabelAsc = 'internalLabel_ASC',
  InternalLabelDesc = 'internalLabel_DESC',
  SectionIdAsc = 'sectionId_ASC',
  SectionIdDesc = 'sectionId_DESC',
  SubtitleOneAsc = 'subtitleOne_ASC',
  SubtitleOneDesc = 'subtitleOne_DESC',
  SubtitleThreeAsc = 'subtitleThree_ASC',
  SubtitleThreeDesc = 'subtitleThree_DESC',
  SubtitleTwoAsc = 'subtitleTwo_ASC',
  SubtitleTwoDesc = 'subtitleTwo_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  TitleStyleAsc = 'titleStyle_ASC',
  TitleStyleDesc = 'titleStyle_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC',
}

export type ComponentTwoColumnPerksSectionTeaserCopy = {
  __typename?: 'ComponentTwoColumnPerksSectionTeaserCopy'
  json: Scalars['JSON']
  links: ComponentTwoColumnPerksSectionTeaserCopyLinks
}

export type ComponentTwoColumnPerksSectionTeaserCopyAssets = {
  __typename?: 'ComponentTwoColumnPerksSectionTeaserCopyAssets'
  block: Array<Maybe<Asset>>
  hyperlink: Array<Maybe<Asset>>
}

export type ComponentTwoColumnPerksSectionTeaserCopyEntries = {
  __typename?: 'ComponentTwoColumnPerksSectionTeaserCopyEntries'
  block: Array<Maybe<Entry>>
  hyperlink: Array<Maybe<Entry>>
  inline: Array<Maybe<Entry>>
}

export type ComponentTwoColumnPerksSectionTeaserCopyLinks = {
  __typename?: 'ComponentTwoColumnPerksSectionTeaserCopyLinks'
  assets: ComponentTwoColumnPerksSectionTeaserCopyAssets
  entries: ComponentTwoColumnPerksSectionTeaserCopyEntries
  resources: ComponentTwoColumnPerksSectionTeaserCopyResources
}

export type ComponentTwoColumnPerksSectionTeaserCopyResources = {
  __typename?: 'ComponentTwoColumnPerksSectionTeaserCopyResources'
  block: Array<ComponentTwoColumnPerksSectionTeaserCopyResourcesBlock>
  hyperlink: Array<ComponentTwoColumnPerksSectionTeaserCopyResourcesHyperlink>
  inline: Array<ComponentTwoColumnPerksSectionTeaserCopyResourcesInline>
}

export type ComponentTwoColumnPerksSectionTeaserCopyResourcesBlock =
  ResourceLink & {
    __typename?: 'ComponentTwoColumnPerksSectionTeaserCopyResourcesBlock'
    sys: ResourceSys
  }

export type ComponentTwoColumnPerksSectionTeaserCopyResourcesHyperlink =
  ResourceLink & {
    __typename?: 'ComponentTwoColumnPerksSectionTeaserCopyResourcesHyperlink'
    sys: ResourceSys
  }

export type ComponentTwoColumnPerksSectionTeaserCopyResourcesInline =
  ResourceLink & {
    __typename?: 'ComponentTwoColumnPerksSectionTeaserCopyResourcesInline'
    sys: ResourceSys
  }

/** A page section with a title and two text sections divided into two columns on large screens. [See type definition](https://app.contentful.com/spaces/9cxgygtadmvl/content_types/componentTwoColumnTextSection) */
export type ComponentTwoColumnTextSection = Entry &
  _Node & {
    __typename?: 'ComponentTwoColumnTextSection'
    _id: Scalars['ID']
    analyticsModule?: Maybe<SettingsAnalyticsModule>
    contentfulMetadata: ContentfulMetadata
    emphasizedColumn?: Maybe<Scalars['Boolean']>
    emphasizedCopy?: Maybe<ComponentTwoColumnTextSectionEmphasizedCopy>
    internalLabel?: Maybe<Scalars['String']>
    linkedFrom?: Maybe<ComponentTwoColumnTextSectionLinkingCollections>
    normalCopy?: Maybe<ComponentTwoColumnTextSectionNormalCopy>
    sys: Sys
    title?: Maybe<Scalars['String']>
    titleStyle?: Maybe<Scalars['String']>
  }

/** A page section with a title and two text sections divided into two columns on large screens. [See type definition](https://app.contentful.com/spaces/9cxgygtadmvl/content_types/componentTwoColumnTextSection) */
export type ComponentTwoColumnTextSectionAnalyticsModuleArgs = {
  locale?: InputMaybe<Scalars['String']>
  preview?: InputMaybe<Scalars['Boolean']>
  where?: InputMaybe<SettingsAnalyticsModuleFilter>
}

/** A page section with a title and two text sections divided into two columns on large screens. [See type definition](https://app.contentful.com/spaces/9cxgygtadmvl/content_types/componentTwoColumnTextSection) */
export type ComponentTwoColumnTextSectionEmphasizedColumnArgs = {
  locale?: InputMaybe<Scalars['String']>
}

/** A page section with a title and two text sections divided into two columns on large screens. [See type definition](https://app.contentful.com/spaces/9cxgygtadmvl/content_types/componentTwoColumnTextSection) */
export type ComponentTwoColumnTextSectionEmphasizedCopyArgs = {
  locale?: InputMaybe<Scalars['String']>
}

/** A page section with a title and two text sections divided into two columns on large screens. [See type definition](https://app.contentful.com/spaces/9cxgygtadmvl/content_types/componentTwoColumnTextSection) */
export type ComponentTwoColumnTextSectionInternalLabelArgs = {
  locale?: InputMaybe<Scalars['String']>
}

/** A page section with a title and two text sections divided into two columns on large screens. [See type definition](https://app.contentful.com/spaces/9cxgygtadmvl/content_types/componentTwoColumnTextSection) */
export type ComponentTwoColumnTextSectionLinkedFromArgs = {
  allowedLocales?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
}

/** A page section with a title and two text sections divided into two columns on large screens. [See type definition](https://app.contentful.com/spaces/9cxgygtadmvl/content_types/componentTwoColumnTextSection) */
export type ComponentTwoColumnTextSectionNormalCopyArgs = {
  locale?: InputMaybe<Scalars['String']>
}

/** A page section with a title and two text sections divided into two columns on large screens. [See type definition](https://app.contentful.com/spaces/9cxgygtadmvl/content_types/componentTwoColumnTextSection) */
export type ComponentTwoColumnTextSectionTitleArgs = {
  locale?: InputMaybe<Scalars['String']>
}

/** A page section with a title and two text sections divided into two columns on large screens. [See type definition](https://app.contentful.com/spaces/9cxgygtadmvl/content_types/componentTwoColumnTextSection) */
export type ComponentTwoColumnTextSectionTitleStyleArgs = {
  locale?: InputMaybe<Scalars['String']>
}

export type ComponentTwoColumnTextSectionCollection = {
  __typename?: 'ComponentTwoColumnTextSectionCollection'
  items: Array<Maybe<ComponentTwoColumnTextSection>>
  limit: Scalars['Int']
  skip: Scalars['Int']
  total: Scalars['Int']
}

export type ComponentTwoColumnTextSectionEmphasizedCopy = {
  __typename?: 'ComponentTwoColumnTextSectionEmphasizedCopy'
  json: Scalars['JSON']
  links: ComponentTwoColumnTextSectionEmphasizedCopyLinks
}

export type ComponentTwoColumnTextSectionEmphasizedCopyAssets = {
  __typename?: 'ComponentTwoColumnTextSectionEmphasizedCopyAssets'
  block: Array<Maybe<Asset>>
  hyperlink: Array<Maybe<Asset>>
}

export type ComponentTwoColumnTextSectionEmphasizedCopyEntries = {
  __typename?: 'ComponentTwoColumnTextSectionEmphasizedCopyEntries'
  block: Array<Maybe<Entry>>
  hyperlink: Array<Maybe<Entry>>
  inline: Array<Maybe<Entry>>
}

export type ComponentTwoColumnTextSectionEmphasizedCopyLinks = {
  __typename?: 'ComponentTwoColumnTextSectionEmphasizedCopyLinks'
  assets: ComponentTwoColumnTextSectionEmphasizedCopyAssets
  entries: ComponentTwoColumnTextSectionEmphasizedCopyEntries
  resources: ComponentTwoColumnTextSectionEmphasizedCopyResources
}

export type ComponentTwoColumnTextSectionEmphasizedCopyResources = {
  __typename?: 'ComponentTwoColumnTextSectionEmphasizedCopyResources'
  block: Array<ComponentTwoColumnTextSectionEmphasizedCopyResourcesBlock>
  hyperlink: Array<ComponentTwoColumnTextSectionEmphasizedCopyResourcesHyperlink>
  inline: Array<ComponentTwoColumnTextSectionEmphasizedCopyResourcesInline>
}

export type ComponentTwoColumnTextSectionEmphasizedCopyResourcesBlock =
  ResourceLink & {
    __typename?: 'ComponentTwoColumnTextSectionEmphasizedCopyResourcesBlock'
    sys: ResourceSys
  }

export type ComponentTwoColumnTextSectionEmphasizedCopyResourcesHyperlink =
  ResourceLink & {
    __typename?: 'ComponentTwoColumnTextSectionEmphasizedCopyResourcesHyperlink'
    sys: ResourceSys
  }

export type ComponentTwoColumnTextSectionEmphasizedCopyResourcesInline =
  ResourceLink & {
    __typename?: 'ComponentTwoColumnTextSectionEmphasizedCopyResourcesInline'
    sys: ResourceSys
  }

export type ComponentTwoColumnTextSectionFilter = {
  AND?: InputMaybe<Array<InputMaybe<ComponentTwoColumnTextSectionFilter>>>
  OR?: InputMaybe<Array<InputMaybe<ComponentTwoColumnTextSectionFilter>>>
  analyticsModule?: InputMaybe<CfSettingsAnalyticsModuleNestedFilter>
  analyticsModule_exists?: InputMaybe<Scalars['Boolean']>
  contentfulMetadata?: InputMaybe<ContentfulMetadataFilter>
  emphasizedColumn?: InputMaybe<Scalars['Boolean']>
  emphasizedColumn_exists?: InputMaybe<Scalars['Boolean']>
  emphasizedColumn_not?: InputMaybe<Scalars['Boolean']>
  emphasizedCopy_contains?: InputMaybe<Scalars['String']>
  emphasizedCopy_exists?: InputMaybe<Scalars['Boolean']>
  emphasizedCopy_not_contains?: InputMaybe<Scalars['String']>
  internalLabel?: InputMaybe<Scalars['String']>
  internalLabel_contains?: InputMaybe<Scalars['String']>
  internalLabel_exists?: InputMaybe<Scalars['Boolean']>
  internalLabel_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  internalLabel_not?: InputMaybe<Scalars['String']>
  internalLabel_not_contains?: InputMaybe<Scalars['String']>
  internalLabel_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  normalCopy_contains?: InputMaybe<Scalars['String']>
  normalCopy_exists?: InputMaybe<Scalars['Boolean']>
  normalCopy_not_contains?: InputMaybe<Scalars['String']>
  sys?: InputMaybe<SysFilter>
  title?: InputMaybe<Scalars['String']>
  titleStyle?: InputMaybe<Scalars['String']>
  titleStyle_contains?: InputMaybe<Scalars['String']>
  titleStyle_exists?: InputMaybe<Scalars['Boolean']>
  titleStyle_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  titleStyle_not?: InputMaybe<Scalars['String']>
  titleStyle_not_contains?: InputMaybe<Scalars['String']>
  titleStyle_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  title_contains?: InputMaybe<Scalars['String']>
  title_exists?: InputMaybe<Scalars['Boolean']>
  title_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  title_not?: InputMaybe<Scalars['String']>
  title_not_contains?: InputMaybe<Scalars['String']>
  title_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
}

export type ComponentTwoColumnTextSectionLinkingCollections = {
  __typename?: 'ComponentTwoColumnTextSectionLinkingCollections'
  campaignPagesCollection?: Maybe<CampaignPagesCollection>
  entryCollection?: Maybe<EntryCollection>
  templateAboutUsCollection?: Maybe<TemplateAboutUsCollection>
  templateBookingCollection?: Maybe<TemplateBookingCollection>
  templateCareersCollection?: Maybe<TemplateCareersCollection>
  templateContactUsCollection?: Maybe<TemplateContactUsCollection>
  templateDestinationsCollection?: Maybe<TemplateDestinationsCollection>
  templateForaReserveCollection?: Maybe<TemplateForaReserveCollection>
  templateForaXCollection?: Maybe<TemplateForaXCollection>
  templateHomeCollection?: Maybe<TemplateHomeCollection>
  templateJoinUsCollection?: Maybe<TemplateJoinUsCollection>
  templateNewsletterCollection?: Maybe<TemplateNewsletterCollection>
  templatePageCollection?: Maybe<TemplatePageCollection>
  templatePartnersCollection?: Maybe<TemplatePartnersCollection>
  templateReviewsCollection?: Maybe<TemplateReviewsCollection>
  templateWhyBookWithForaCollection?: Maybe<TemplateWhyBookWithForaCollection>
}

export type ComponentTwoColumnTextSectionLinkingCollectionsCampaignPagesCollectionArgs =
  {
    limit?: InputMaybe<Scalars['Int']>
    locale?: InputMaybe<Scalars['String']>
    order?: InputMaybe<
      Array<
        InputMaybe<ComponentTwoColumnTextSectionLinkingCollectionsCampaignPagesCollectionOrder>
      >
    >
    preview?: InputMaybe<Scalars['Boolean']>
    skip?: InputMaybe<Scalars['Int']>
  }

export type ComponentTwoColumnTextSectionLinkingCollectionsEntryCollectionArgs =
  {
    limit?: InputMaybe<Scalars['Int']>
    locale?: InputMaybe<Scalars['String']>
    preview?: InputMaybe<Scalars['Boolean']>
    skip?: InputMaybe<Scalars['Int']>
  }

export type ComponentTwoColumnTextSectionLinkingCollectionsTemplateAboutUsCollectionArgs =
  {
    limit?: InputMaybe<Scalars['Int']>
    locale?: InputMaybe<Scalars['String']>
    order?: InputMaybe<
      Array<
        InputMaybe<ComponentTwoColumnTextSectionLinkingCollectionsTemplateAboutUsCollectionOrder>
      >
    >
    preview?: InputMaybe<Scalars['Boolean']>
    skip?: InputMaybe<Scalars['Int']>
  }

export type ComponentTwoColumnTextSectionLinkingCollectionsTemplateBookingCollectionArgs =
  {
    limit?: InputMaybe<Scalars['Int']>
    locale?: InputMaybe<Scalars['String']>
    order?: InputMaybe<
      Array<
        InputMaybe<ComponentTwoColumnTextSectionLinkingCollectionsTemplateBookingCollectionOrder>
      >
    >
    preview?: InputMaybe<Scalars['Boolean']>
    skip?: InputMaybe<Scalars['Int']>
  }

export type ComponentTwoColumnTextSectionLinkingCollectionsTemplateCareersCollectionArgs =
  {
    limit?: InputMaybe<Scalars['Int']>
    locale?: InputMaybe<Scalars['String']>
    order?: InputMaybe<
      Array<
        InputMaybe<ComponentTwoColumnTextSectionLinkingCollectionsTemplateCareersCollectionOrder>
      >
    >
    preview?: InputMaybe<Scalars['Boolean']>
    skip?: InputMaybe<Scalars['Int']>
  }

export type ComponentTwoColumnTextSectionLinkingCollectionsTemplateContactUsCollectionArgs =
  {
    limit?: InputMaybe<Scalars['Int']>
    locale?: InputMaybe<Scalars['String']>
    order?: InputMaybe<
      Array<
        InputMaybe<ComponentTwoColumnTextSectionLinkingCollectionsTemplateContactUsCollectionOrder>
      >
    >
    preview?: InputMaybe<Scalars['Boolean']>
    skip?: InputMaybe<Scalars['Int']>
  }

export type ComponentTwoColumnTextSectionLinkingCollectionsTemplateDestinationsCollectionArgs =
  {
    limit?: InputMaybe<Scalars['Int']>
    locale?: InputMaybe<Scalars['String']>
    order?: InputMaybe<
      Array<
        InputMaybe<ComponentTwoColumnTextSectionLinkingCollectionsTemplateDestinationsCollectionOrder>
      >
    >
    preview?: InputMaybe<Scalars['Boolean']>
    skip?: InputMaybe<Scalars['Int']>
  }

export type ComponentTwoColumnTextSectionLinkingCollectionsTemplateForaReserveCollectionArgs =
  {
    limit?: InputMaybe<Scalars['Int']>
    locale?: InputMaybe<Scalars['String']>
    order?: InputMaybe<
      Array<
        InputMaybe<ComponentTwoColumnTextSectionLinkingCollectionsTemplateForaReserveCollectionOrder>
      >
    >
    preview?: InputMaybe<Scalars['Boolean']>
    skip?: InputMaybe<Scalars['Int']>
  }

export type ComponentTwoColumnTextSectionLinkingCollectionsTemplateForaXCollectionArgs =
  {
    limit?: InputMaybe<Scalars['Int']>
    locale?: InputMaybe<Scalars['String']>
    order?: InputMaybe<
      Array<
        InputMaybe<ComponentTwoColumnTextSectionLinkingCollectionsTemplateForaXCollectionOrder>
      >
    >
    preview?: InputMaybe<Scalars['Boolean']>
    skip?: InputMaybe<Scalars['Int']>
  }

export type ComponentTwoColumnTextSectionLinkingCollectionsTemplateHomeCollectionArgs =
  {
    limit?: InputMaybe<Scalars['Int']>
    locale?: InputMaybe<Scalars['String']>
    order?: InputMaybe<
      Array<
        InputMaybe<ComponentTwoColumnTextSectionLinkingCollectionsTemplateHomeCollectionOrder>
      >
    >
    preview?: InputMaybe<Scalars['Boolean']>
    skip?: InputMaybe<Scalars['Int']>
  }

export type ComponentTwoColumnTextSectionLinkingCollectionsTemplateJoinUsCollectionArgs =
  {
    limit?: InputMaybe<Scalars['Int']>
    locale?: InputMaybe<Scalars['String']>
    order?: InputMaybe<
      Array<
        InputMaybe<ComponentTwoColumnTextSectionLinkingCollectionsTemplateJoinUsCollectionOrder>
      >
    >
    preview?: InputMaybe<Scalars['Boolean']>
    skip?: InputMaybe<Scalars['Int']>
  }

export type ComponentTwoColumnTextSectionLinkingCollectionsTemplateNewsletterCollectionArgs =
  {
    limit?: InputMaybe<Scalars['Int']>
    locale?: InputMaybe<Scalars['String']>
    order?: InputMaybe<
      Array<
        InputMaybe<ComponentTwoColumnTextSectionLinkingCollectionsTemplateNewsletterCollectionOrder>
      >
    >
    preview?: InputMaybe<Scalars['Boolean']>
    skip?: InputMaybe<Scalars['Int']>
  }

export type ComponentTwoColumnTextSectionLinkingCollectionsTemplatePageCollectionArgs =
  {
    limit?: InputMaybe<Scalars['Int']>
    locale?: InputMaybe<Scalars['String']>
    order?: InputMaybe<
      Array<
        InputMaybe<ComponentTwoColumnTextSectionLinkingCollectionsTemplatePageCollectionOrder>
      >
    >
    preview?: InputMaybe<Scalars['Boolean']>
    skip?: InputMaybe<Scalars['Int']>
  }

export type ComponentTwoColumnTextSectionLinkingCollectionsTemplatePartnersCollectionArgs =
  {
    limit?: InputMaybe<Scalars['Int']>
    locale?: InputMaybe<Scalars['String']>
    order?: InputMaybe<
      Array<
        InputMaybe<ComponentTwoColumnTextSectionLinkingCollectionsTemplatePartnersCollectionOrder>
      >
    >
    preview?: InputMaybe<Scalars['Boolean']>
    skip?: InputMaybe<Scalars['Int']>
  }

export type ComponentTwoColumnTextSectionLinkingCollectionsTemplateReviewsCollectionArgs =
  {
    limit?: InputMaybe<Scalars['Int']>
    locale?: InputMaybe<Scalars['String']>
    order?: InputMaybe<
      Array<
        InputMaybe<ComponentTwoColumnTextSectionLinkingCollectionsTemplateReviewsCollectionOrder>
      >
    >
    preview?: InputMaybe<Scalars['Boolean']>
    skip?: InputMaybe<Scalars['Int']>
  }

export type ComponentTwoColumnTextSectionLinkingCollectionsTemplateWhyBookWithForaCollectionArgs =
  {
    limit?: InputMaybe<Scalars['Int']>
    locale?: InputMaybe<Scalars['String']>
    order?: InputMaybe<
      Array<
        InputMaybe<ComponentTwoColumnTextSectionLinkingCollectionsTemplateWhyBookWithForaCollectionOrder>
      >
    >
    preview?: InputMaybe<Scalars['Boolean']>
    skip?: InputMaybe<Scalars['Int']>
  }

export enum ComponentTwoColumnTextSectionLinkingCollectionsCampaignPagesCollectionOrder {
  CollapseFooterAsc = 'collapseFooter_ASC',
  CollapseFooterDesc = 'collapseFooter_DESC',
  CollapseHeaderAsc = 'collapseHeader_ASC',
  CollapseHeaderDesc = 'collapseHeader_DESC',
  HideFooterAsc = 'hideFooter_ASC',
  HideFooterDesc = 'hideFooter_DESC',
  HideGlobalBannerAsc = 'hideGlobalBanner_ASC',
  HideGlobalBannerDesc = 'hideGlobalBanner_DESC',
  JotformIdAsc = 'jotformId_ASC',
  JotformIdDesc = 'jotformId_DESC',
  NoindexNofollowAsc = 'noindexNofollow_ASC',
  NoindexNofollowDesc = 'noindexNofollow_DESC',
  ShowHeaderAsc = 'showHeader_ASC',
  ShowHeaderDesc = 'showHeader_DESC',
  SlugAsc = 'slug_ASC',
  SlugDesc = 'slug_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC',
}

export enum ComponentTwoColumnTextSectionLinkingCollectionsTemplateAboutUsCollectionOrder {
  CollapseHeaderAsc = 'collapseHeader_ASC',
  CollapseHeaderDesc = 'collapseHeader_DESC',
  HeaderBlockTitleAsc = 'headerBlockTitle_ASC',
  HeaderBlockTitleDesc = 'headerBlockTitle_DESC',
  ShowHeaderAsc = 'showHeader_ASC',
  ShowHeaderDesc = 'showHeader_DESC',
  SubtitleAsc = 'subtitle_ASC',
  SubtitleDesc = 'subtitle_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC',
}

export enum ComponentTwoColumnTextSectionLinkingCollectionsTemplateBookingCollectionOrder {
  CollapseHeaderAsc = 'collapseHeader_ASC',
  CollapseHeaderDesc = 'collapseHeader_DESC',
  ShowHeaderAsc = 'showHeader_ASC',
  ShowHeaderDesc = 'showHeader_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC',
}

export enum ComponentTwoColumnTextSectionLinkingCollectionsTemplateCareersCollectionOrder {
  CollapseHeaderAsc = 'collapseHeader_ASC',
  CollapseHeaderDesc = 'collapseHeader_DESC',
  ShowHeaderAsc = 'showHeader_ASC',
  ShowHeaderDesc = 'showHeader_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC',
}

export enum ComponentTwoColumnTextSectionLinkingCollectionsTemplateContactUsCollectionOrder {
  CollapseHeaderAsc = 'collapseHeader_ASC',
  CollapseHeaderDesc = 'collapseHeader_DESC',
  ShowHeaderAsc = 'showHeader_ASC',
  ShowHeaderDesc = 'showHeader_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC',
}

export enum ComponentTwoColumnTextSectionLinkingCollectionsTemplateDestinationsCollectionOrder {
  CollapseHeaderAsc = 'collapseHeader_ASC',
  CollapseHeaderDesc = 'collapseHeader_DESC',
  CollapsibleHeadingAsc = 'collapsibleHeading_ASC',
  CollapsibleHeadingDesc = 'collapsibleHeading_DESC',
  ShowHeaderAsc = 'showHeader_ASC',
  ShowHeaderDesc = 'showHeader_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC',
}

export enum ComponentTwoColumnTextSectionLinkingCollectionsTemplateForaReserveCollectionOrder {
  CollapseHeaderAsc = 'collapseHeader_ASC',
  CollapseHeaderDesc = 'collapseHeader_DESC',
  ShowHeaderAsc = 'showHeader_ASC',
  ShowHeaderDesc = 'showHeader_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC',
}

export enum ComponentTwoColumnTextSectionLinkingCollectionsTemplateForaXCollectionOrder {
  AdvisorGridHeaderAsc = 'advisorGridHeader_ASC',
  AdvisorGridHeaderDesc = 'advisorGridHeader_DESC',
  AdvisorGridSubCopyAsc = 'advisorGridSubCopy_ASC',
  AdvisorGridSubCopyDesc = 'advisorGridSubCopy_DESC',
  CollapseHeaderAsc = 'collapseHeader_ASC',
  CollapseHeaderDesc = 'collapseHeader_DESC',
  ShowHeaderAsc = 'showHeader_ASC',
  ShowHeaderDesc = 'showHeader_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC',
}

export enum ComponentTwoColumnTextSectionLinkingCollectionsTemplateHomeCollectionOrder {
  CollapseHeaderAsc = 'collapseHeader_ASC',
  CollapseHeaderDesc = 'collapseHeader_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC',
}

export enum ComponentTwoColumnTextSectionLinkingCollectionsTemplateJoinUsCollectionOrder {
  CollapseHeaderAsc = 'collapseHeader_ASC',
  CollapseHeaderDesc = 'collapseHeader_DESC',
  HideGlobalBannerAsc = 'hideGlobalBanner_ASC',
  HideGlobalBannerDesc = 'hideGlobalBanner_DESC',
  ShowHeaderAsc = 'showHeader_ASC',
  ShowHeaderDesc = 'showHeader_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC',
}

export enum ComponentTwoColumnTextSectionLinkingCollectionsTemplateNewsletterCollectionOrder {
  CollapseHeaderAsc = 'collapseHeader_ASC',
  CollapseHeaderDesc = 'collapseHeader_DESC',
  ShowHeaderAsc = 'showHeader_ASC',
  ShowHeaderDesc = 'showHeader_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC',
}

export enum ComponentTwoColumnTextSectionLinkingCollectionsTemplatePageCollectionOrder {
  DirectoryAsc = 'directory_ASC',
  DirectoryDesc = 'directory_DESC',
  InternalLabelAsc = 'internalLabel_ASC',
  InternalLabelDesc = 'internalLabel_DESC',
  MetaTitleAsc = 'metaTitle_ASC',
  MetaTitleDesc = 'metaTitle_DESC',
  NoindexNofollowAsc = 'noindexNofollow_ASC',
  NoindexNofollowDesc = 'noindexNofollow_DESC',
  SlugAsc = 'slug_ASC',
  SlugDesc = 'slug_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
}

export enum ComponentTwoColumnTextSectionLinkingCollectionsTemplatePartnersCollectionOrder {
  CollapseHeaderAsc = 'collapseHeader_ASC',
  CollapseHeaderDesc = 'collapseHeader_DESC',
  ShowHeaderAsc = 'showHeader_ASC',
  ShowHeaderDesc = 'showHeader_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC',
}

export enum ComponentTwoColumnTextSectionLinkingCollectionsTemplateReviewsCollectionOrder {
  CollapseHeaderAsc = 'collapseHeader_ASC',
  CollapseHeaderDesc = 'collapseHeader_DESC',
  ShowHeaderAsc = 'showHeader_ASC',
  ShowHeaderDesc = 'showHeader_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC',
}

export enum ComponentTwoColumnTextSectionLinkingCollectionsTemplateWhyBookWithForaCollectionOrder {
  CollapseHeaderAsc = 'collapseHeader_ASC',
  CollapseHeaderDesc = 'collapseHeader_DESC',
  ShowHeaderAsc = 'showHeader_ASC',
  ShowHeaderDesc = 'showHeader_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC',
}

export type ComponentTwoColumnTextSectionNormalCopy = {
  __typename?: 'ComponentTwoColumnTextSectionNormalCopy'
  json: Scalars['JSON']
  links: ComponentTwoColumnTextSectionNormalCopyLinks
}

export type ComponentTwoColumnTextSectionNormalCopyAssets = {
  __typename?: 'ComponentTwoColumnTextSectionNormalCopyAssets'
  block: Array<Maybe<Asset>>
  hyperlink: Array<Maybe<Asset>>
}

export type ComponentTwoColumnTextSectionNormalCopyEntries = {
  __typename?: 'ComponentTwoColumnTextSectionNormalCopyEntries'
  block: Array<Maybe<Entry>>
  hyperlink: Array<Maybe<Entry>>
  inline: Array<Maybe<Entry>>
}

export type ComponentTwoColumnTextSectionNormalCopyLinks = {
  __typename?: 'ComponentTwoColumnTextSectionNormalCopyLinks'
  assets: ComponentTwoColumnTextSectionNormalCopyAssets
  entries: ComponentTwoColumnTextSectionNormalCopyEntries
  resources: ComponentTwoColumnTextSectionNormalCopyResources
}

export type ComponentTwoColumnTextSectionNormalCopyResources = {
  __typename?: 'ComponentTwoColumnTextSectionNormalCopyResources'
  block: Array<ComponentTwoColumnTextSectionNormalCopyResourcesBlock>
  hyperlink: Array<ComponentTwoColumnTextSectionNormalCopyResourcesHyperlink>
  inline: Array<ComponentTwoColumnTextSectionNormalCopyResourcesInline>
}

export type ComponentTwoColumnTextSectionNormalCopyResourcesBlock =
  ResourceLink & {
    __typename?: 'ComponentTwoColumnTextSectionNormalCopyResourcesBlock'
    sys: ResourceSys
  }

export type ComponentTwoColumnTextSectionNormalCopyResourcesHyperlink =
  ResourceLink & {
    __typename?: 'ComponentTwoColumnTextSectionNormalCopyResourcesHyperlink'
    sys: ResourceSys
  }

export type ComponentTwoColumnTextSectionNormalCopyResourcesInline =
  ResourceLink & {
    __typename?: 'ComponentTwoColumnTextSectionNormalCopyResourcesInline'
    sys: ResourceSys
  }

export enum ComponentTwoColumnTextSectionOrder {
  EmphasizedColumnAsc = 'emphasizedColumn_ASC',
  EmphasizedColumnDesc = 'emphasizedColumn_DESC',
  InternalLabelAsc = 'internalLabel_ASC',
  InternalLabelDesc = 'internalLabel_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  TitleStyleAsc = 'titleStyle_ASC',
  TitleStyleDesc = 'titleStyle_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC',
}

/** Specify a Typeform URL to embed [See type definition](https://app.contentful.com/spaces/9cxgygtadmvl/content_types/componentTypeformEmbed) */
export type ComponentTypeformEmbed = Entry &
  _Node & {
    __typename?: 'ComponentTypeformEmbed'
    _id: Scalars['ID']
    contentfulMetadata: ContentfulMetadata
    form?: Maybe<Scalars['String']>
    internalLabel?: Maybe<Scalars['String']>
    linkedFrom?: Maybe<ComponentTypeformEmbedLinkingCollections>
    sectionId?: Maybe<Scalars['String']>
    sys: Sys
    typeformFallbackUrl?: Maybe<Scalars['String']>
  }

/** Specify a Typeform URL to embed [See type definition](https://app.contentful.com/spaces/9cxgygtadmvl/content_types/componentTypeformEmbed) */
export type ComponentTypeformEmbedFormArgs = {
  locale?: InputMaybe<Scalars['String']>
}

/** Specify a Typeform URL to embed [See type definition](https://app.contentful.com/spaces/9cxgygtadmvl/content_types/componentTypeformEmbed) */
export type ComponentTypeformEmbedInternalLabelArgs = {
  locale?: InputMaybe<Scalars['String']>
}

/** Specify a Typeform URL to embed [See type definition](https://app.contentful.com/spaces/9cxgygtadmvl/content_types/componentTypeformEmbed) */
export type ComponentTypeformEmbedLinkedFromArgs = {
  allowedLocales?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
}

/** Specify a Typeform URL to embed [See type definition](https://app.contentful.com/spaces/9cxgygtadmvl/content_types/componentTypeformEmbed) */
export type ComponentTypeformEmbedSectionIdArgs = {
  locale?: InputMaybe<Scalars['String']>
}

/** Specify a Typeform URL to embed [See type definition](https://app.contentful.com/spaces/9cxgygtadmvl/content_types/componentTypeformEmbed) */
export type ComponentTypeformEmbedTypeformFallbackUrlArgs = {
  locale?: InputMaybe<Scalars['String']>
}

export type ComponentTypeformEmbedCollection = {
  __typename?: 'ComponentTypeformEmbedCollection'
  items: Array<Maybe<ComponentTypeformEmbed>>
  limit: Scalars['Int']
  skip: Scalars['Int']
  total: Scalars['Int']
}

export type ComponentTypeformEmbedFilter = {
  AND?: InputMaybe<Array<InputMaybe<ComponentTypeformEmbedFilter>>>
  OR?: InputMaybe<Array<InputMaybe<ComponentTypeformEmbedFilter>>>
  contentfulMetadata?: InputMaybe<ContentfulMetadataFilter>
  form?: InputMaybe<Scalars['String']>
  form_contains?: InputMaybe<Scalars['String']>
  form_exists?: InputMaybe<Scalars['Boolean']>
  form_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  form_not?: InputMaybe<Scalars['String']>
  form_not_contains?: InputMaybe<Scalars['String']>
  form_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  internalLabel?: InputMaybe<Scalars['String']>
  internalLabel_contains?: InputMaybe<Scalars['String']>
  internalLabel_exists?: InputMaybe<Scalars['Boolean']>
  internalLabel_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  internalLabel_not?: InputMaybe<Scalars['String']>
  internalLabel_not_contains?: InputMaybe<Scalars['String']>
  internalLabel_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  sectionId?: InputMaybe<Scalars['String']>
  sectionId_contains?: InputMaybe<Scalars['String']>
  sectionId_exists?: InputMaybe<Scalars['Boolean']>
  sectionId_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  sectionId_not?: InputMaybe<Scalars['String']>
  sectionId_not_contains?: InputMaybe<Scalars['String']>
  sectionId_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  sys?: InputMaybe<SysFilter>
  typeformFallbackUrl?: InputMaybe<Scalars['String']>
  typeformFallbackUrl_contains?: InputMaybe<Scalars['String']>
  typeformFallbackUrl_exists?: InputMaybe<Scalars['Boolean']>
  typeformFallbackUrl_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  typeformFallbackUrl_not?: InputMaybe<Scalars['String']>
  typeformFallbackUrl_not_contains?: InputMaybe<Scalars['String']>
  typeformFallbackUrl_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
}

export type ComponentTypeformEmbedLinkingCollections = {
  __typename?: 'ComponentTypeformEmbedLinkingCollections'
  campaignPagesCollection?: Maybe<CampaignPagesCollection>
  entryCollection?: Maybe<EntryCollection>
  templateAboutUsCollection?: Maybe<TemplateAboutUsCollection>
  templateBookingCollection?: Maybe<TemplateBookingCollection>
  templateCareersCollection?: Maybe<TemplateCareersCollection>
  templateContactUsCollection?: Maybe<TemplateContactUsCollection>
  templateDestinationsCollection?: Maybe<TemplateDestinationsCollection>
  templateForaReserveCollection?: Maybe<TemplateForaReserveCollection>
  templateForaXCollection?: Maybe<TemplateForaXCollection>
  templateHomeCollection?: Maybe<TemplateHomeCollection>
  templateJoinUsCollection?: Maybe<TemplateJoinUsCollection>
  templateNewsletterCollection?: Maybe<TemplateNewsletterCollection>
  templatePartnersCollection?: Maybe<TemplatePartnersCollection>
  templateReviewsCollection?: Maybe<TemplateReviewsCollection>
  templateWhyBookWithForaCollection?: Maybe<TemplateWhyBookWithForaCollection>
}

export type ComponentTypeformEmbedLinkingCollectionsCampaignPagesCollectionArgs =
  {
    limit?: InputMaybe<Scalars['Int']>
    locale?: InputMaybe<Scalars['String']>
    order?: InputMaybe<
      Array<
        InputMaybe<ComponentTypeformEmbedLinkingCollectionsCampaignPagesCollectionOrder>
      >
    >
    preview?: InputMaybe<Scalars['Boolean']>
    skip?: InputMaybe<Scalars['Int']>
  }

export type ComponentTypeformEmbedLinkingCollectionsEntryCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>
  locale?: InputMaybe<Scalars['String']>
  preview?: InputMaybe<Scalars['Boolean']>
  skip?: InputMaybe<Scalars['Int']>
}

export type ComponentTypeformEmbedLinkingCollectionsTemplateAboutUsCollectionArgs =
  {
    limit?: InputMaybe<Scalars['Int']>
    locale?: InputMaybe<Scalars['String']>
    order?: InputMaybe<
      Array<
        InputMaybe<ComponentTypeformEmbedLinkingCollectionsTemplateAboutUsCollectionOrder>
      >
    >
    preview?: InputMaybe<Scalars['Boolean']>
    skip?: InputMaybe<Scalars['Int']>
  }

export type ComponentTypeformEmbedLinkingCollectionsTemplateBookingCollectionArgs =
  {
    limit?: InputMaybe<Scalars['Int']>
    locale?: InputMaybe<Scalars['String']>
    order?: InputMaybe<
      Array<
        InputMaybe<ComponentTypeformEmbedLinkingCollectionsTemplateBookingCollectionOrder>
      >
    >
    preview?: InputMaybe<Scalars['Boolean']>
    skip?: InputMaybe<Scalars['Int']>
  }

export type ComponentTypeformEmbedLinkingCollectionsTemplateCareersCollectionArgs =
  {
    limit?: InputMaybe<Scalars['Int']>
    locale?: InputMaybe<Scalars['String']>
    order?: InputMaybe<
      Array<
        InputMaybe<ComponentTypeformEmbedLinkingCollectionsTemplateCareersCollectionOrder>
      >
    >
    preview?: InputMaybe<Scalars['Boolean']>
    skip?: InputMaybe<Scalars['Int']>
  }

export type ComponentTypeformEmbedLinkingCollectionsTemplateContactUsCollectionArgs =
  {
    limit?: InputMaybe<Scalars['Int']>
    locale?: InputMaybe<Scalars['String']>
    order?: InputMaybe<
      Array<
        InputMaybe<ComponentTypeformEmbedLinkingCollectionsTemplateContactUsCollectionOrder>
      >
    >
    preview?: InputMaybe<Scalars['Boolean']>
    skip?: InputMaybe<Scalars['Int']>
  }

export type ComponentTypeformEmbedLinkingCollectionsTemplateDestinationsCollectionArgs =
  {
    limit?: InputMaybe<Scalars['Int']>
    locale?: InputMaybe<Scalars['String']>
    order?: InputMaybe<
      Array<
        InputMaybe<ComponentTypeformEmbedLinkingCollectionsTemplateDestinationsCollectionOrder>
      >
    >
    preview?: InputMaybe<Scalars['Boolean']>
    skip?: InputMaybe<Scalars['Int']>
  }

export type ComponentTypeformEmbedLinkingCollectionsTemplateForaReserveCollectionArgs =
  {
    limit?: InputMaybe<Scalars['Int']>
    locale?: InputMaybe<Scalars['String']>
    order?: InputMaybe<
      Array<
        InputMaybe<ComponentTypeformEmbedLinkingCollectionsTemplateForaReserveCollectionOrder>
      >
    >
    preview?: InputMaybe<Scalars['Boolean']>
    skip?: InputMaybe<Scalars['Int']>
  }

export type ComponentTypeformEmbedLinkingCollectionsTemplateForaXCollectionArgs =
  {
    limit?: InputMaybe<Scalars['Int']>
    locale?: InputMaybe<Scalars['String']>
    order?: InputMaybe<
      Array<
        InputMaybe<ComponentTypeformEmbedLinkingCollectionsTemplateForaXCollectionOrder>
      >
    >
    preview?: InputMaybe<Scalars['Boolean']>
    skip?: InputMaybe<Scalars['Int']>
  }

export type ComponentTypeformEmbedLinkingCollectionsTemplateHomeCollectionArgs =
  {
    limit?: InputMaybe<Scalars['Int']>
    locale?: InputMaybe<Scalars['String']>
    order?: InputMaybe<
      Array<
        InputMaybe<ComponentTypeformEmbedLinkingCollectionsTemplateHomeCollectionOrder>
      >
    >
    preview?: InputMaybe<Scalars['Boolean']>
    skip?: InputMaybe<Scalars['Int']>
  }

export type ComponentTypeformEmbedLinkingCollectionsTemplateJoinUsCollectionArgs =
  {
    limit?: InputMaybe<Scalars['Int']>
    locale?: InputMaybe<Scalars['String']>
    order?: InputMaybe<
      Array<
        InputMaybe<ComponentTypeformEmbedLinkingCollectionsTemplateJoinUsCollectionOrder>
      >
    >
    preview?: InputMaybe<Scalars['Boolean']>
    skip?: InputMaybe<Scalars['Int']>
  }

export type ComponentTypeformEmbedLinkingCollectionsTemplateNewsletterCollectionArgs =
  {
    limit?: InputMaybe<Scalars['Int']>
    locale?: InputMaybe<Scalars['String']>
    order?: InputMaybe<
      Array<
        InputMaybe<ComponentTypeformEmbedLinkingCollectionsTemplateNewsletterCollectionOrder>
      >
    >
    preview?: InputMaybe<Scalars['Boolean']>
    skip?: InputMaybe<Scalars['Int']>
  }

export type ComponentTypeformEmbedLinkingCollectionsTemplatePartnersCollectionArgs =
  {
    limit?: InputMaybe<Scalars['Int']>
    locale?: InputMaybe<Scalars['String']>
    order?: InputMaybe<
      Array<
        InputMaybe<ComponentTypeformEmbedLinkingCollectionsTemplatePartnersCollectionOrder>
      >
    >
    preview?: InputMaybe<Scalars['Boolean']>
    skip?: InputMaybe<Scalars['Int']>
  }

export type ComponentTypeformEmbedLinkingCollectionsTemplateReviewsCollectionArgs =
  {
    limit?: InputMaybe<Scalars['Int']>
    locale?: InputMaybe<Scalars['String']>
    order?: InputMaybe<
      Array<
        InputMaybe<ComponentTypeformEmbedLinkingCollectionsTemplateReviewsCollectionOrder>
      >
    >
    preview?: InputMaybe<Scalars['Boolean']>
    skip?: InputMaybe<Scalars['Int']>
  }

export type ComponentTypeformEmbedLinkingCollectionsTemplateWhyBookWithForaCollectionArgs =
  {
    limit?: InputMaybe<Scalars['Int']>
    locale?: InputMaybe<Scalars['String']>
    order?: InputMaybe<
      Array<
        InputMaybe<ComponentTypeformEmbedLinkingCollectionsTemplateWhyBookWithForaCollectionOrder>
      >
    >
    preview?: InputMaybe<Scalars['Boolean']>
    skip?: InputMaybe<Scalars['Int']>
  }

export enum ComponentTypeformEmbedLinkingCollectionsCampaignPagesCollectionOrder {
  CollapseFooterAsc = 'collapseFooter_ASC',
  CollapseFooterDesc = 'collapseFooter_DESC',
  CollapseHeaderAsc = 'collapseHeader_ASC',
  CollapseHeaderDesc = 'collapseHeader_DESC',
  HideFooterAsc = 'hideFooter_ASC',
  HideFooterDesc = 'hideFooter_DESC',
  HideGlobalBannerAsc = 'hideGlobalBanner_ASC',
  HideGlobalBannerDesc = 'hideGlobalBanner_DESC',
  JotformIdAsc = 'jotformId_ASC',
  JotformIdDesc = 'jotformId_DESC',
  NoindexNofollowAsc = 'noindexNofollow_ASC',
  NoindexNofollowDesc = 'noindexNofollow_DESC',
  ShowHeaderAsc = 'showHeader_ASC',
  ShowHeaderDesc = 'showHeader_DESC',
  SlugAsc = 'slug_ASC',
  SlugDesc = 'slug_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC',
}

export enum ComponentTypeformEmbedLinkingCollectionsTemplateAboutUsCollectionOrder {
  CollapseHeaderAsc = 'collapseHeader_ASC',
  CollapseHeaderDesc = 'collapseHeader_DESC',
  HeaderBlockTitleAsc = 'headerBlockTitle_ASC',
  HeaderBlockTitleDesc = 'headerBlockTitle_DESC',
  ShowHeaderAsc = 'showHeader_ASC',
  ShowHeaderDesc = 'showHeader_DESC',
  SubtitleAsc = 'subtitle_ASC',
  SubtitleDesc = 'subtitle_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC',
}

export enum ComponentTypeformEmbedLinkingCollectionsTemplateBookingCollectionOrder {
  CollapseHeaderAsc = 'collapseHeader_ASC',
  CollapseHeaderDesc = 'collapseHeader_DESC',
  ShowHeaderAsc = 'showHeader_ASC',
  ShowHeaderDesc = 'showHeader_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC',
}

export enum ComponentTypeformEmbedLinkingCollectionsTemplateCareersCollectionOrder {
  CollapseHeaderAsc = 'collapseHeader_ASC',
  CollapseHeaderDesc = 'collapseHeader_DESC',
  ShowHeaderAsc = 'showHeader_ASC',
  ShowHeaderDesc = 'showHeader_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC',
}

export enum ComponentTypeformEmbedLinkingCollectionsTemplateContactUsCollectionOrder {
  CollapseHeaderAsc = 'collapseHeader_ASC',
  CollapseHeaderDesc = 'collapseHeader_DESC',
  ShowHeaderAsc = 'showHeader_ASC',
  ShowHeaderDesc = 'showHeader_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC',
}

export enum ComponentTypeformEmbedLinkingCollectionsTemplateDestinationsCollectionOrder {
  CollapseHeaderAsc = 'collapseHeader_ASC',
  CollapseHeaderDesc = 'collapseHeader_DESC',
  CollapsibleHeadingAsc = 'collapsibleHeading_ASC',
  CollapsibleHeadingDesc = 'collapsibleHeading_DESC',
  ShowHeaderAsc = 'showHeader_ASC',
  ShowHeaderDesc = 'showHeader_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC',
}

export enum ComponentTypeformEmbedLinkingCollectionsTemplateForaReserveCollectionOrder {
  CollapseHeaderAsc = 'collapseHeader_ASC',
  CollapseHeaderDesc = 'collapseHeader_DESC',
  ShowHeaderAsc = 'showHeader_ASC',
  ShowHeaderDesc = 'showHeader_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC',
}

export enum ComponentTypeformEmbedLinkingCollectionsTemplateForaXCollectionOrder {
  AdvisorGridHeaderAsc = 'advisorGridHeader_ASC',
  AdvisorGridHeaderDesc = 'advisorGridHeader_DESC',
  AdvisorGridSubCopyAsc = 'advisorGridSubCopy_ASC',
  AdvisorGridSubCopyDesc = 'advisorGridSubCopy_DESC',
  CollapseHeaderAsc = 'collapseHeader_ASC',
  CollapseHeaderDesc = 'collapseHeader_DESC',
  ShowHeaderAsc = 'showHeader_ASC',
  ShowHeaderDesc = 'showHeader_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC',
}

export enum ComponentTypeformEmbedLinkingCollectionsTemplateHomeCollectionOrder {
  CollapseHeaderAsc = 'collapseHeader_ASC',
  CollapseHeaderDesc = 'collapseHeader_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC',
}

export enum ComponentTypeformEmbedLinkingCollectionsTemplateJoinUsCollectionOrder {
  CollapseHeaderAsc = 'collapseHeader_ASC',
  CollapseHeaderDesc = 'collapseHeader_DESC',
  HideGlobalBannerAsc = 'hideGlobalBanner_ASC',
  HideGlobalBannerDesc = 'hideGlobalBanner_DESC',
  ShowHeaderAsc = 'showHeader_ASC',
  ShowHeaderDesc = 'showHeader_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC',
}

export enum ComponentTypeformEmbedLinkingCollectionsTemplateNewsletterCollectionOrder {
  CollapseHeaderAsc = 'collapseHeader_ASC',
  CollapseHeaderDesc = 'collapseHeader_DESC',
  ShowHeaderAsc = 'showHeader_ASC',
  ShowHeaderDesc = 'showHeader_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC',
}

export enum ComponentTypeformEmbedLinkingCollectionsTemplatePartnersCollectionOrder {
  CollapseHeaderAsc = 'collapseHeader_ASC',
  CollapseHeaderDesc = 'collapseHeader_DESC',
  ShowHeaderAsc = 'showHeader_ASC',
  ShowHeaderDesc = 'showHeader_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC',
}

export enum ComponentTypeformEmbedLinkingCollectionsTemplateReviewsCollectionOrder {
  CollapseHeaderAsc = 'collapseHeader_ASC',
  CollapseHeaderDesc = 'collapseHeader_DESC',
  ShowHeaderAsc = 'showHeader_ASC',
  ShowHeaderDesc = 'showHeader_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC',
}

export enum ComponentTypeformEmbedLinkingCollectionsTemplateWhyBookWithForaCollectionOrder {
  CollapseHeaderAsc = 'collapseHeader_ASC',
  CollapseHeaderDesc = 'collapseHeader_DESC',
  ShowHeaderAsc = 'showHeader_ASC',
  ShowHeaderDesc = 'showHeader_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC',
}

export enum ComponentTypeformEmbedOrder {
  FormAsc = 'form_ASC',
  FormDesc = 'form_DESC',
  InternalLabelAsc = 'internalLabel_ASC',
  InternalLabelDesc = 'internalLabel_DESC',
  SectionIdAsc = 'sectionId_ASC',
  SectionIdDesc = 'sectionId_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  TypeformFallbackUrlAsc = 'typeformFallbackUrl_ASC',
  TypeformFallbackUrlDesc = 'typeformFallbackUrl_DESC',
}

/** [See type definition](https://app.contentful.com/spaces/9cxgygtadmvl/content_types/componentVideoSection) */
export type ComponentVideoSection = Entry &
  _Node & {
    __typename?: 'ComponentVideoSection'
    _id: Scalars['ID']
    analyticsModule?: Maybe<SettingsAnalyticsModule>
    autoplay?: Maybe<Scalars['Boolean']>
    cloudinaryFallbackImage?: Maybe<Scalars['JSON']>
    cloudinaryVideo?: Maybe<Scalars['JSON']>
    contentfulMetadata: ContentfulMetadata
    copy?: Maybe<ComponentVideoSectionCopy>
    fallbackImage?: Maybe<Asset>
    heading?: Maybe<Scalars['String']>
    internalLabel?: Maybe<Scalars['String']>
    linkedFrom?: Maybe<ComponentVideoSectionLinkingCollections>
    loop?: Maybe<Scalars['Boolean']>
    mobileMediaAsset?: Maybe<Scalars['JSON']>
    mobilePreviewImage?: Maybe<Scalars['JSON']>
    muted?: Maybe<Scalars['Boolean']>
    sectionId?: Maybe<Scalars['String']>
    sys: Sys
    titleStyle?: Maybe<Scalars['String']>
    video?: Maybe<Asset>
  }

/** [See type definition](https://app.contentful.com/spaces/9cxgygtadmvl/content_types/componentVideoSection) */
export type ComponentVideoSectionAnalyticsModuleArgs = {
  locale?: InputMaybe<Scalars['String']>
  preview?: InputMaybe<Scalars['Boolean']>
  where?: InputMaybe<SettingsAnalyticsModuleFilter>
}

/** [See type definition](https://app.contentful.com/spaces/9cxgygtadmvl/content_types/componentVideoSection) */
export type ComponentVideoSectionAutoplayArgs = {
  locale?: InputMaybe<Scalars['String']>
}

/** [See type definition](https://app.contentful.com/spaces/9cxgygtadmvl/content_types/componentVideoSection) */
export type ComponentVideoSectionCloudinaryFallbackImageArgs = {
  locale?: InputMaybe<Scalars['String']>
}

/** [See type definition](https://app.contentful.com/spaces/9cxgygtadmvl/content_types/componentVideoSection) */
export type ComponentVideoSectionCloudinaryVideoArgs = {
  locale?: InputMaybe<Scalars['String']>
}

/** [See type definition](https://app.contentful.com/spaces/9cxgygtadmvl/content_types/componentVideoSection) */
export type ComponentVideoSectionCopyArgs = {
  locale?: InputMaybe<Scalars['String']>
}

/** [See type definition](https://app.contentful.com/spaces/9cxgygtadmvl/content_types/componentVideoSection) */
export type ComponentVideoSectionFallbackImageArgs = {
  locale?: InputMaybe<Scalars['String']>
  preview?: InputMaybe<Scalars['Boolean']>
}

/** [See type definition](https://app.contentful.com/spaces/9cxgygtadmvl/content_types/componentVideoSection) */
export type ComponentVideoSectionHeadingArgs = {
  locale?: InputMaybe<Scalars['String']>
}

/** [See type definition](https://app.contentful.com/spaces/9cxgygtadmvl/content_types/componentVideoSection) */
export type ComponentVideoSectionInternalLabelArgs = {
  locale?: InputMaybe<Scalars['String']>
}

/** [See type definition](https://app.contentful.com/spaces/9cxgygtadmvl/content_types/componentVideoSection) */
export type ComponentVideoSectionLinkedFromArgs = {
  allowedLocales?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
}

/** [See type definition](https://app.contentful.com/spaces/9cxgygtadmvl/content_types/componentVideoSection) */
export type ComponentVideoSectionLoopArgs = {
  locale?: InputMaybe<Scalars['String']>
}

/** [See type definition](https://app.contentful.com/spaces/9cxgygtadmvl/content_types/componentVideoSection) */
export type ComponentVideoSectionMobileMediaAssetArgs = {
  locale?: InputMaybe<Scalars['String']>
}

/** [See type definition](https://app.contentful.com/spaces/9cxgygtadmvl/content_types/componentVideoSection) */
export type ComponentVideoSectionMobilePreviewImageArgs = {
  locale?: InputMaybe<Scalars['String']>
}

/** [See type definition](https://app.contentful.com/spaces/9cxgygtadmvl/content_types/componentVideoSection) */
export type ComponentVideoSectionMutedArgs = {
  locale?: InputMaybe<Scalars['String']>
}

/** [See type definition](https://app.contentful.com/spaces/9cxgygtadmvl/content_types/componentVideoSection) */
export type ComponentVideoSectionSectionIdArgs = {
  locale?: InputMaybe<Scalars['String']>
}

/** [See type definition](https://app.contentful.com/spaces/9cxgygtadmvl/content_types/componentVideoSection) */
export type ComponentVideoSectionTitleStyleArgs = {
  locale?: InputMaybe<Scalars['String']>
}

/** [See type definition](https://app.contentful.com/spaces/9cxgygtadmvl/content_types/componentVideoSection) */
export type ComponentVideoSectionVideoArgs = {
  locale?: InputMaybe<Scalars['String']>
  preview?: InputMaybe<Scalars['Boolean']>
}

export type ComponentVideoSectionCollection = {
  __typename?: 'ComponentVideoSectionCollection'
  items: Array<Maybe<ComponentVideoSection>>
  limit: Scalars['Int']
  skip: Scalars['Int']
  total: Scalars['Int']
}

export type ComponentVideoSectionCopy = {
  __typename?: 'ComponentVideoSectionCopy'
  json: Scalars['JSON']
  links: ComponentVideoSectionCopyLinks
}

export type ComponentVideoSectionCopyAssets = {
  __typename?: 'ComponentVideoSectionCopyAssets'
  block: Array<Maybe<Asset>>
  hyperlink: Array<Maybe<Asset>>
}

export type ComponentVideoSectionCopyEntries = {
  __typename?: 'ComponentVideoSectionCopyEntries'
  block: Array<Maybe<Entry>>
  hyperlink: Array<Maybe<Entry>>
  inline: Array<Maybe<Entry>>
}

export type ComponentVideoSectionCopyLinks = {
  __typename?: 'ComponentVideoSectionCopyLinks'
  assets: ComponentVideoSectionCopyAssets
  entries: ComponentVideoSectionCopyEntries
  resources: ComponentVideoSectionCopyResources
}

export type ComponentVideoSectionCopyResources = {
  __typename?: 'ComponentVideoSectionCopyResources'
  block: Array<ComponentVideoSectionCopyResourcesBlock>
  hyperlink: Array<ComponentVideoSectionCopyResourcesHyperlink>
  inline: Array<ComponentVideoSectionCopyResourcesInline>
}

export type ComponentVideoSectionCopyResourcesBlock = ResourceLink & {
  __typename?: 'ComponentVideoSectionCopyResourcesBlock'
  sys: ResourceSys
}

export type ComponentVideoSectionCopyResourcesHyperlink = ResourceLink & {
  __typename?: 'ComponentVideoSectionCopyResourcesHyperlink'
  sys: ResourceSys
}

export type ComponentVideoSectionCopyResourcesInline = ResourceLink & {
  __typename?: 'ComponentVideoSectionCopyResourcesInline'
  sys: ResourceSys
}

export type ComponentVideoSectionFilter = {
  AND?: InputMaybe<Array<InputMaybe<ComponentVideoSectionFilter>>>
  OR?: InputMaybe<Array<InputMaybe<ComponentVideoSectionFilter>>>
  analyticsModule?: InputMaybe<CfSettingsAnalyticsModuleNestedFilter>
  analyticsModule_exists?: InputMaybe<Scalars['Boolean']>
  autoplay?: InputMaybe<Scalars['Boolean']>
  autoplay_exists?: InputMaybe<Scalars['Boolean']>
  autoplay_not?: InputMaybe<Scalars['Boolean']>
  cloudinaryFallbackImage_exists?: InputMaybe<Scalars['Boolean']>
  cloudinaryVideo_exists?: InputMaybe<Scalars['Boolean']>
  contentfulMetadata?: InputMaybe<ContentfulMetadataFilter>
  copy_contains?: InputMaybe<Scalars['String']>
  copy_exists?: InputMaybe<Scalars['Boolean']>
  copy_not_contains?: InputMaybe<Scalars['String']>
  fallbackImage_exists?: InputMaybe<Scalars['Boolean']>
  heading?: InputMaybe<Scalars['String']>
  heading_contains?: InputMaybe<Scalars['String']>
  heading_exists?: InputMaybe<Scalars['Boolean']>
  heading_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  heading_not?: InputMaybe<Scalars['String']>
  heading_not_contains?: InputMaybe<Scalars['String']>
  heading_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  internalLabel?: InputMaybe<Scalars['String']>
  internalLabel_contains?: InputMaybe<Scalars['String']>
  internalLabel_exists?: InputMaybe<Scalars['Boolean']>
  internalLabel_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  internalLabel_not?: InputMaybe<Scalars['String']>
  internalLabel_not_contains?: InputMaybe<Scalars['String']>
  internalLabel_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  loop?: InputMaybe<Scalars['Boolean']>
  loop_exists?: InputMaybe<Scalars['Boolean']>
  loop_not?: InputMaybe<Scalars['Boolean']>
  mobileMediaAsset_exists?: InputMaybe<Scalars['Boolean']>
  mobilePreviewImage_exists?: InputMaybe<Scalars['Boolean']>
  muted?: InputMaybe<Scalars['Boolean']>
  muted_exists?: InputMaybe<Scalars['Boolean']>
  muted_not?: InputMaybe<Scalars['Boolean']>
  sectionId?: InputMaybe<Scalars['String']>
  sectionId_contains?: InputMaybe<Scalars['String']>
  sectionId_exists?: InputMaybe<Scalars['Boolean']>
  sectionId_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  sectionId_not?: InputMaybe<Scalars['String']>
  sectionId_not_contains?: InputMaybe<Scalars['String']>
  sectionId_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  sys?: InputMaybe<SysFilter>
  titleStyle?: InputMaybe<Scalars['String']>
  titleStyle_contains?: InputMaybe<Scalars['String']>
  titleStyle_exists?: InputMaybe<Scalars['Boolean']>
  titleStyle_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  titleStyle_not?: InputMaybe<Scalars['String']>
  titleStyle_not_contains?: InputMaybe<Scalars['String']>
  titleStyle_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  video_exists?: InputMaybe<Scalars['Boolean']>
}

export type ComponentVideoSectionLinkingCollections = {
  __typename?: 'ComponentVideoSectionLinkingCollections'
  campaignPagesCollection?: Maybe<CampaignPagesCollection>
  entryCollection?: Maybe<EntryCollection>
  templateAboutUsCollection?: Maybe<TemplateAboutUsCollection>
  templateBookingCollection?: Maybe<TemplateBookingCollection>
  templateCareersCollection?: Maybe<TemplateCareersCollection>
  templateContactUsCollection?: Maybe<TemplateContactUsCollection>
  templateDestinationsCollection?: Maybe<TemplateDestinationsCollection>
  templateForaReserveCollection?: Maybe<TemplateForaReserveCollection>
  templateForaXCollection?: Maybe<TemplateForaXCollection>
  templateHomeCollection?: Maybe<TemplateHomeCollection>
  templateJoinUsCollection?: Maybe<TemplateJoinUsCollection>
  templateNewsletterCollection?: Maybe<TemplateNewsletterCollection>
  templatePageCollection?: Maybe<TemplatePageCollection>
  templatePartnersCollection?: Maybe<TemplatePartnersCollection>
  templateReviewsCollection?: Maybe<TemplateReviewsCollection>
  templateWhyBookWithForaCollection?: Maybe<TemplateWhyBookWithForaCollection>
}

export type ComponentVideoSectionLinkingCollectionsCampaignPagesCollectionArgs =
  {
    limit?: InputMaybe<Scalars['Int']>
    locale?: InputMaybe<Scalars['String']>
    order?: InputMaybe<
      Array<
        InputMaybe<ComponentVideoSectionLinkingCollectionsCampaignPagesCollectionOrder>
      >
    >
    preview?: InputMaybe<Scalars['Boolean']>
    skip?: InputMaybe<Scalars['Int']>
  }

export type ComponentVideoSectionLinkingCollectionsEntryCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>
  locale?: InputMaybe<Scalars['String']>
  preview?: InputMaybe<Scalars['Boolean']>
  skip?: InputMaybe<Scalars['Int']>
}

export type ComponentVideoSectionLinkingCollectionsTemplateAboutUsCollectionArgs =
  {
    limit?: InputMaybe<Scalars['Int']>
    locale?: InputMaybe<Scalars['String']>
    order?: InputMaybe<
      Array<
        InputMaybe<ComponentVideoSectionLinkingCollectionsTemplateAboutUsCollectionOrder>
      >
    >
    preview?: InputMaybe<Scalars['Boolean']>
    skip?: InputMaybe<Scalars['Int']>
  }

export type ComponentVideoSectionLinkingCollectionsTemplateBookingCollectionArgs =
  {
    limit?: InputMaybe<Scalars['Int']>
    locale?: InputMaybe<Scalars['String']>
    order?: InputMaybe<
      Array<
        InputMaybe<ComponentVideoSectionLinkingCollectionsTemplateBookingCollectionOrder>
      >
    >
    preview?: InputMaybe<Scalars['Boolean']>
    skip?: InputMaybe<Scalars['Int']>
  }

export type ComponentVideoSectionLinkingCollectionsTemplateCareersCollectionArgs =
  {
    limit?: InputMaybe<Scalars['Int']>
    locale?: InputMaybe<Scalars['String']>
    order?: InputMaybe<
      Array<
        InputMaybe<ComponentVideoSectionLinkingCollectionsTemplateCareersCollectionOrder>
      >
    >
    preview?: InputMaybe<Scalars['Boolean']>
    skip?: InputMaybe<Scalars['Int']>
  }

export type ComponentVideoSectionLinkingCollectionsTemplateContactUsCollectionArgs =
  {
    limit?: InputMaybe<Scalars['Int']>
    locale?: InputMaybe<Scalars['String']>
    order?: InputMaybe<
      Array<
        InputMaybe<ComponentVideoSectionLinkingCollectionsTemplateContactUsCollectionOrder>
      >
    >
    preview?: InputMaybe<Scalars['Boolean']>
    skip?: InputMaybe<Scalars['Int']>
  }

export type ComponentVideoSectionLinkingCollectionsTemplateDestinationsCollectionArgs =
  {
    limit?: InputMaybe<Scalars['Int']>
    locale?: InputMaybe<Scalars['String']>
    order?: InputMaybe<
      Array<
        InputMaybe<ComponentVideoSectionLinkingCollectionsTemplateDestinationsCollectionOrder>
      >
    >
    preview?: InputMaybe<Scalars['Boolean']>
    skip?: InputMaybe<Scalars['Int']>
  }

export type ComponentVideoSectionLinkingCollectionsTemplateForaReserveCollectionArgs =
  {
    limit?: InputMaybe<Scalars['Int']>
    locale?: InputMaybe<Scalars['String']>
    order?: InputMaybe<
      Array<
        InputMaybe<ComponentVideoSectionLinkingCollectionsTemplateForaReserveCollectionOrder>
      >
    >
    preview?: InputMaybe<Scalars['Boolean']>
    skip?: InputMaybe<Scalars['Int']>
  }

export type ComponentVideoSectionLinkingCollectionsTemplateForaXCollectionArgs =
  {
    limit?: InputMaybe<Scalars['Int']>
    locale?: InputMaybe<Scalars['String']>
    order?: InputMaybe<
      Array<
        InputMaybe<ComponentVideoSectionLinkingCollectionsTemplateForaXCollectionOrder>
      >
    >
    preview?: InputMaybe<Scalars['Boolean']>
    skip?: InputMaybe<Scalars['Int']>
  }

export type ComponentVideoSectionLinkingCollectionsTemplateHomeCollectionArgs =
  {
    limit?: InputMaybe<Scalars['Int']>
    locale?: InputMaybe<Scalars['String']>
    order?: InputMaybe<
      Array<
        InputMaybe<ComponentVideoSectionLinkingCollectionsTemplateHomeCollectionOrder>
      >
    >
    preview?: InputMaybe<Scalars['Boolean']>
    skip?: InputMaybe<Scalars['Int']>
  }

export type ComponentVideoSectionLinkingCollectionsTemplateJoinUsCollectionArgs =
  {
    limit?: InputMaybe<Scalars['Int']>
    locale?: InputMaybe<Scalars['String']>
    order?: InputMaybe<
      Array<
        InputMaybe<ComponentVideoSectionLinkingCollectionsTemplateJoinUsCollectionOrder>
      >
    >
    preview?: InputMaybe<Scalars['Boolean']>
    skip?: InputMaybe<Scalars['Int']>
  }

export type ComponentVideoSectionLinkingCollectionsTemplateNewsletterCollectionArgs =
  {
    limit?: InputMaybe<Scalars['Int']>
    locale?: InputMaybe<Scalars['String']>
    order?: InputMaybe<
      Array<
        InputMaybe<ComponentVideoSectionLinkingCollectionsTemplateNewsletterCollectionOrder>
      >
    >
    preview?: InputMaybe<Scalars['Boolean']>
    skip?: InputMaybe<Scalars['Int']>
  }

export type ComponentVideoSectionLinkingCollectionsTemplatePageCollectionArgs =
  {
    limit?: InputMaybe<Scalars['Int']>
    locale?: InputMaybe<Scalars['String']>
    order?: InputMaybe<
      Array<
        InputMaybe<ComponentVideoSectionLinkingCollectionsTemplatePageCollectionOrder>
      >
    >
    preview?: InputMaybe<Scalars['Boolean']>
    skip?: InputMaybe<Scalars['Int']>
  }

export type ComponentVideoSectionLinkingCollectionsTemplatePartnersCollectionArgs =
  {
    limit?: InputMaybe<Scalars['Int']>
    locale?: InputMaybe<Scalars['String']>
    order?: InputMaybe<
      Array<
        InputMaybe<ComponentVideoSectionLinkingCollectionsTemplatePartnersCollectionOrder>
      >
    >
    preview?: InputMaybe<Scalars['Boolean']>
    skip?: InputMaybe<Scalars['Int']>
  }

export type ComponentVideoSectionLinkingCollectionsTemplateReviewsCollectionArgs =
  {
    limit?: InputMaybe<Scalars['Int']>
    locale?: InputMaybe<Scalars['String']>
    order?: InputMaybe<
      Array<
        InputMaybe<ComponentVideoSectionLinkingCollectionsTemplateReviewsCollectionOrder>
      >
    >
    preview?: InputMaybe<Scalars['Boolean']>
    skip?: InputMaybe<Scalars['Int']>
  }

export type ComponentVideoSectionLinkingCollectionsTemplateWhyBookWithForaCollectionArgs =
  {
    limit?: InputMaybe<Scalars['Int']>
    locale?: InputMaybe<Scalars['String']>
    order?: InputMaybe<
      Array<
        InputMaybe<ComponentVideoSectionLinkingCollectionsTemplateWhyBookWithForaCollectionOrder>
      >
    >
    preview?: InputMaybe<Scalars['Boolean']>
    skip?: InputMaybe<Scalars['Int']>
  }

export enum ComponentVideoSectionLinkingCollectionsCampaignPagesCollectionOrder {
  CollapseFooterAsc = 'collapseFooter_ASC',
  CollapseFooterDesc = 'collapseFooter_DESC',
  CollapseHeaderAsc = 'collapseHeader_ASC',
  CollapseHeaderDesc = 'collapseHeader_DESC',
  HideFooterAsc = 'hideFooter_ASC',
  HideFooterDesc = 'hideFooter_DESC',
  HideGlobalBannerAsc = 'hideGlobalBanner_ASC',
  HideGlobalBannerDesc = 'hideGlobalBanner_DESC',
  JotformIdAsc = 'jotformId_ASC',
  JotformIdDesc = 'jotformId_DESC',
  NoindexNofollowAsc = 'noindexNofollow_ASC',
  NoindexNofollowDesc = 'noindexNofollow_DESC',
  ShowHeaderAsc = 'showHeader_ASC',
  ShowHeaderDesc = 'showHeader_DESC',
  SlugAsc = 'slug_ASC',
  SlugDesc = 'slug_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC',
}

export enum ComponentVideoSectionLinkingCollectionsTemplateAboutUsCollectionOrder {
  CollapseHeaderAsc = 'collapseHeader_ASC',
  CollapseHeaderDesc = 'collapseHeader_DESC',
  HeaderBlockTitleAsc = 'headerBlockTitle_ASC',
  HeaderBlockTitleDesc = 'headerBlockTitle_DESC',
  ShowHeaderAsc = 'showHeader_ASC',
  ShowHeaderDesc = 'showHeader_DESC',
  SubtitleAsc = 'subtitle_ASC',
  SubtitleDesc = 'subtitle_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC',
}

export enum ComponentVideoSectionLinkingCollectionsTemplateBookingCollectionOrder {
  CollapseHeaderAsc = 'collapseHeader_ASC',
  CollapseHeaderDesc = 'collapseHeader_DESC',
  ShowHeaderAsc = 'showHeader_ASC',
  ShowHeaderDesc = 'showHeader_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC',
}

export enum ComponentVideoSectionLinkingCollectionsTemplateCareersCollectionOrder {
  CollapseHeaderAsc = 'collapseHeader_ASC',
  CollapseHeaderDesc = 'collapseHeader_DESC',
  ShowHeaderAsc = 'showHeader_ASC',
  ShowHeaderDesc = 'showHeader_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC',
}

export enum ComponentVideoSectionLinkingCollectionsTemplateContactUsCollectionOrder {
  CollapseHeaderAsc = 'collapseHeader_ASC',
  CollapseHeaderDesc = 'collapseHeader_DESC',
  ShowHeaderAsc = 'showHeader_ASC',
  ShowHeaderDesc = 'showHeader_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC',
}

export enum ComponentVideoSectionLinkingCollectionsTemplateDestinationsCollectionOrder {
  CollapseHeaderAsc = 'collapseHeader_ASC',
  CollapseHeaderDesc = 'collapseHeader_DESC',
  CollapsibleHeadingAsc = 'collapsibleHeading_ASC',
  CollapsibleHeadingDesc = 'collapsibleHeading_DESC',
  ShowHeaderAsc = 'showHeader_ASC',
  ShowHeaderDesc = 'showHeader_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC',
}

export enum ComponentVideoSectionLinkingCollectionsTemplateForaReserveCollectionOrder {
  CollapseHeaderAsc = 'collapseHeader_ASC',
  CollapseHeaderDesc = 'collapseHeader_DESC',
  ShowHeaderAsc = 'showHeader_ASC',
  ShowHeaderDesc = 'showHeader_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC',
}

export enum ComponentVideoSectionLinkingCollectionsTemplateForaXCollectionOrder {
  AdvisorGridHeaderAsc = 'advisorGridHeader_ASC',
  AdvisorGridHeaderDesc = 'advisorGridHeader_DESC',
  AdvisorGridSubCopyAsc = 'advisorGridSubCopy_ASC',
  AdvisorGridSubCopyDesc = 'advisorGridSubCopy_DESC',
  CollapseHeaderAsc = 'collapseHeader_ASC',
  CollapseHeaderDesc = 'collapseHeader_DESC',
  ShowHeaderAsc = 'showHeader_ASC',
  ShowHeaderDesc = 'showHeader_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC',
}

export enum ComponentVideoSectionLinkingCollectionsTemplateHomeCollectionOrder {
  CollapseHeaderAsc = 'collapseHeader_ASC',
  CollapseHeaderDesc = 'collapseHeader_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC',
}

export enum ComponentVideoSectionLinkingCollectionsTemplateJoinUsCollectionOrder {
  CollapseHeaderAsc = 'collapseHeader_ASC',
  CollapseHeaderDesc = 'collapseHeader_DESC',
  HideGlobalBannerAsc = 'hideGlobalBanner_ASC',
  HideGlobalBannerDesc = 'hideGlobalBanner_DESC',
  ShowHeaderAsc = 'showHeader_ASC',
  ShowHeaderDesc = 'showHeader_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC',
}

export enum ComponentVideoSectionLinkingCollectionsTemplateNewsletterCollectionOrder {
  CollapseHeaderAsc = 'collapseHeader_ASC',
  CollapseHeaderDesc = 'collapseHeader_DESC',
  ShowHeaderAsc = 'showHeader_ASC',
  ShowHeaderDesc = 'showHeader_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC',
}

export enum ComponentVideoSectionLinkingCollectionsTemplatePageCollectionOrder {
  DirectoryAsc = 'directory_ASC',
  DirectoryDesc = 'directory_DESC',
  InternalLabelAsc = 'internalLabel_ASC',
  InternalLabelDesc = 'internalLabel_DESC',
  MetaTitleAsc = 'metaTitle_ASC',
  MetaTitleDesc = 'metaTitle_DESC',
  NoindexNofollowAsc = 'noindexNofollow_ASC',
  NoindexNofollowDesc = 'noindexNofollow_DESC',
  SlugAsc = 'slug_ASC',
  SlugDesc = 'slug_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
}

export enum ComponentVideoSectionLinkingCollectionsTemplatePartnersCollectionOrder {
  CollapseHeaderAsc = 'collapseHeader_ASC',
  CollapseHeaderDesc = 'collapseHeader_DESC',
  ShowHeaderAsc = 'showHeader_ASC',
  ShowHeaderDesc = 'showHeader_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC',
}

export enum ComponentVideoSectionLinkingCollectionsTemplateReviewsCollectionOrder {
  CollapseHeaderAsc = 'collapseHeader_ASC',
  CollapseHeaderDesc = 'collapseHeader_DESC',
  ShowHeaderAsc = 'showHeader_ASC',
  ShowHeaderDesc = 'showHeader_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC',
}

export enum ComponentVideoSectionLinkingCollectionsTemplateWhyBookWithForaCollectionOrder {
  CollapseHeaderAsc = 'collapseHeader_ASC',
  CollapseHeaderDesc = 'collapseHeader_DESC',
  ShowHeaderAsc = 'showHeader_ASC',
  ShowHeaderDesc = 'showHeader_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC',
}

export enum ComponentVideoSectionOrder {
  AutoplayAsc = 'autoplay_ASC',
  AutoplayDesc = 'autoplay_DESC',
  HeadingAsc = 'heading_ASC',
  HeadingDesc = 'heading_DESC',
  InternalLabelAsc = 'internalLabel_ASC',
  InternalLabelDesc = 'internalLabel_DESC',
  LoopAsc = 'loop_ASC',
  LoopDesc = 'loop_DESC',
  MutedAsc = 'muted_ASC',
  MutedDesc = 'muted_DESC',
  SectionIdAsc = 'sectionId_ASC',
  SectionIdDesc = 'sectionId_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  TitleStyleAsc = 'titleStyle_ASC',
  TitleStyleDesc = 'titleStyle_DESC',
}

/** [See type definition](https://app.contentful.com/spaces/9cxgygtadmvl/content_types/componentWhatYouGetCard) */
export type ComponentWhatYouGetCard = Entry &
  _Node & {
    __typename?: 'ComponentWhatYouGetCard'
    _id: Scalars['ID']
    bulletFour?: Maybe<Scalars['String']>
    bulletOne?: Maybe<Scalars['String']>
    bulletThree?: Maybe<Scalars['String']>
    bulletTwo?: Maybe<Scalars['String']>
    buttonText?: Maybe<Scalars['String']>
    buttonUrl?: Maybe<Scalars['String']>
    contentfulMetadata: ContentfulMetadata
    fallbackImage?: Maybe<Asset>
    headline?: Maybe<Scalars['String']>
    iconFour?: Maybe<Asset>
    iconOne?: Maybe<Asset>
    iconThree?: Maybe<Asset>
    iconTwo?: Maybe<Asset>
    image?: Maybe<Asset>
    linkedFrom?: Maybe<ComponentWhatYouGetCardLinkingCollections>
    subHead?: Maybe<Scalars['String']>
    sys: Sys
  }

/** [See type definition](https://app.contentful.com/spaces/9cxgygtadmvl/content_types/componentWhatYouGetCard) */
export type ComponentWhatYouGetCardBulletFourArgs = {
  locale?: InputMaybe<Scalars['String']>
}

/** [See type definition](https://app.contentful.com/spaces/9cxgygtadmvl/content_types/componentWhatYouGetCard) */
export type ComponentWhatYouGetCardBulletOneArgs = {
  locale?: InputMaybe<Scalars['String']>
}

/** [See type definition](https://app.contentful.com/spaces/9cxgygtadmvl/content_types/componentWhatYouGetCard) */
export type ComponentWhatYouGetCardBulletThreeArgs = {
  locale?: InputMaybe<Scalars['String']>
}

/** [See type definition](https://app.contentful.com/spaces/9cxgygtadmvl/content_types/componentWhatYouGetCard) */
export type ComponentWhatYouGetCardBulletTwoArgs = {
  locale?: InputMaybe<Scalars['String']>
}

/** [See type definition](https://app.contentful.com/spaces/9cxgygtadmvl/content_types/componentWhatYouGetCard) */
export type ComponentWhatYouGetCardButtonTextArgs = {
  locale?: InputMaybe<Scalars['String']>
}

/** [See type definition](https://app.contentful.com/spaces/9cxgygtadmvl/content_types/componentWhatYouGetCard) */
export type ComponentWhatYouGetCardButtonUrlArgs = {
  locale?: InputMaybe<Scalars['String']>
}

/** [See type definition](https://app.contentful.com/spaces/9cxgygtadmvl/content_types/componentWhatYouGetCard) */
export type ComponentWhatYouGetCardFallbackImageArgs = {
  locale?: InputMaybe<Scalars['String']>
  preview?: InputMaybe<Scalars['Boolean']>
}

/** [See type definition](https://app.contentful.com/spaces/9cxgygtadmvl/content_types/componentWhatYouGetCard) */
export type ComponentWhatYouGetCardHeadlineArgs = {
  locale?: InputMaybe<Scalars['String']>
}

/** [See type definition](https://app.contentful.com/spaces/9cxgygtadmvl/content_types/componentWhatYouGetCard) */
export type ComponentWhatYouGetCardIconFourArgs = {
  locale?: InputMaybe<Scalars['String']>
  preview?: InputMaybe<Scalars['Boolean']>
}

/** [See type definition](https://app.contentful.com/spaces/9cxgygtadmvl/content_types/componentWhatYouGetCard) */
export type ComponentWhatYouGetCardIconOneArgs = {
  locale?: InputMaybe<Scalars['String']>
  preview?: InputMaybe<Scalars['Boolean']>
}

/** [See type definition](https://app.contentful.com/spaces/9cxgygtadmvl/content_types/componentWhatYouGetCard) */
export type ComponentWhatYouGetCardIconThreeArgs = {
  locale?: InputMaybe<Scalars['String']>
  preview?: InputMaybe<Scalars['Boolean']>
}

/** [See type definition](https://app.contentful.com/spaces/9cxgygtadmvl/content_types/componentWhatYouGetCard) */
export type ComponentWhatYouGetCardIconTwoArgs = {
  locale?: InputMaybe<Scalars['String']>
  preview?: InputMaybe<Scalars['Boolean']>
}

/** [See type definition](https://app.contentful.com/spaces/9cxgygtadmvl/content_types/componentWhatYouGetCard) */
export type ComponentWhatYouGetCardImageArgs = {
  locale?: InputMaybe<Scalars['String']>
  preview?: InputMaybe<Scalars['Boolean']>
}

/** [See type definition](https://app.contentful.com/spaces/9cxgygtadmvl/content_types/componentWhatYouGetCard) */
export type ComponentWhatYouGetCardLinkedFromArgs = {
  allowedLocales?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
}

/** [See type definition](https://app.contentful.com/spaces/9cxgygtadmvl/content_types/componentWhatYouGetCard) */
export type ComponentWhatYouGetCardSubHeadArgs = {
  locale?: InputMaybe<Scalars['String']>
}

export type ComponentWhatYouGetCardCollection = {
  __typename?: 'ComponentWhatYouGetCardCollection'
  items: Array<Maybe<ComponentWhatYouGetCard>>
  limit: Scalars['Int']
  skip: Scalars['Int']
  total: Scalars['Int']
}

export type ComponentWhatYouGetCardFilter = {
  AND?: InputMaybe<Array<InputMaybe<ComponentWhatYouGetCardFilter>>>
  OR?: InputMaybe<Array<InputMaybe<ComponentWhatYouGetCardFilter>>>
  bulletFour?: InputMaybe<Scalars['String']>
  bulletFour_contains?: InputMaybe<Scalars['String']>
  bulletFour_exists?: InputMaybe<Scalars['Boolean']>
  bulletFour_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  bulletFour_not?: InputMaybe<Scalars['String']>
  bulletFour_not_contains?: InputMaybe<Scalars['String']>
  bulletFour_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  bulletOne?: InputMaybe<Scalars['String']>
  bulletOne_contains?: InputMaybe<Scalars['String']>
  bulletOne_exists?: InputMaybe<Scalars['Boolean']>
  bulletOne_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  bulletOne_not?: InputMaybe<Scalars['String']>
  bulletOne_not_contains?: InputMaybe<Scalars['String']>
  bulletOne_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  bulletThree?: InputMaybe<Scalars['String']>
  bulletThree_contains?: InputMaybe<Scalars['String']>
  bulletThree_exists?: InputMaybe<Scalars['Boolean']>
  bulletThree_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  bulletThree_not?: InputMaybe<Scalars['String']>
  bulletThree_not_contains?: InputMaybe<Scalars['String']>
  bulletThree_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  bulletTwo?: InputMaybe<Scalars['String']>
  bulletTwo_contains?: InputMaybe<Scalars['String']>
  bulletTwo_exists?: InputMaybe<Scalars['Boolean']>
  bulletTwo_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  bulletTwo_not?: InputMaybe<Scalars['String']>
  bulletTwo_not_contains?: InputMaybe<Scalars['String']>
  bulletTwo_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  buttonText?: InputMaybe<Scalars['String']>
  buttonText_contains?: InputMaybe<Scalars['String']>
  buttonText_exists?: InputMaybe<Scalars['Boolean']>
  buttonText_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  buttonText_not?: InputMaybe<Scalars['String']>
  buttonText_not_contains?: InputMaybe<Scalars['String']>
  buttonText_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  buttonUrl?: InputMaybe<Scalars['String']>
  buttonUrl_contains?: InputMaybe<Scalars['String']>
  buttonUrl_exists?: InputMaybe<Scalars['Boolean']>
  buttonUrl_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  buttonUrl_not?: InputMaybe<Scalars['String']>
  buttonUrl_not_contains?: InputMaybe<Scalars['String']>
  buttonUrl_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  contentfulMetadata?: InputMaybe<ContentfulMetadataFilter>
  fallbackImage_exists?: InputMaybe<Scalars['Boolean']>
  headline?: InputMaybe<Scalars['String']>
  headline_contains?: InputMaybe<Scalars['String']>
  headline_exists?: InputMaybe<Scalars['Boolean']>
  headline_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  headline_not?: InputMaybe<Scalars['String']>
  headline_not_contains?: InputMaybe<Scalars['String']>
  headline_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  iconFour_exists?: InputMaybe<Scalars['Boolean']>
  iconOne_exists?: InputMaybe<Scalars['Boolean']>
  iconThree_exists?: InputMaybe<Scalars['Boolean']>
  iconTwo_exists?: InputMaybe<Scalars['Boolean']>
  image_exists?: InputMaybe<Scalars['Boolean']>
  subHead?: InputMaybe<Scalars['String']>
  subHead_contains?: InputMaybe<Scalars['String']>
  subHead_exists?: InputMaybe<Scalars['Boolean']>
  subHead_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  subHead_not?: InputMaybe<Scalars['String']>
  subHead_not_contains?: InputMaybe<Scalars['String']>
  subHead_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  sys?: InputMaybe<SysFilter>
}

export type ComponentWhatYouGetCardLinkingCollections = {
  __typename?: 'ComponentWhatYouGetCardLinkingCollections'
  componentWhatYouGetModuleCollection?: Maybe<ComponentWhatYouGetModuleCollection>
  entryCollection?: Maybe<EntryCollection>
}

export type ComponentWhatYouGetCardLinkingCollectionsComponentWhatYouGetModuleCollectionArgs =
  {
    limit?: InputMaybe<Scalars['Int']>
    locale?: InputMaybe<Scalars['String']>
    order?: InputMaybe<
      Array<
        InputMaybe<ComponentWhatYouGetCardLinkingCollectionsComponentWhatYouGetModuleCollectionOrder>
      >
    >
    preview?: InputMaybe<Scalars['Boolean']>
    skip?: InputMaybe<Scalars['Int']>
  }

export type ComponentWhatYouGetCardLinkingCollectionsEntryCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>
  locale?: InputMaybe<Scalars['String']>
  preview?: InputMaybe<Scalars['Boolean']>
  skip?: InputMaybe<Scalars['Int']>
}

export enum ComponentWhatYouGetCardLinkingCollectionsComponentWhatYouGetModuleCollectionOrder {
  DisplayAsc = 'display_ASC',
  DisplayDesc = 'display_DESC',
  HeadlineAsc = 'headline_ASC',
  HeadlineDesc = 'headline_DESC',
  InternalLabelAsc = 'internalLabel_ASC',
  InternalLabelDesc = 'internalLabel_DESC',
  SectionIdAsc = 'sectionId_ASC',
  SectionIdDesc = 'sectionId_DESC',
  SubHeadAsc = 'subHead_ASC',
  SubHeadDesc = 'subHead_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
}

export enum ComponentWhatYouGetCardOrder {
  BulletFourAsc = 'bulletFour_ASC',
  BulletFourDesc = 'bulletFour_DESC',
  BulletOneAsc = 'bulletOne_ASC',
  BulletOneDesc = 'bulletOne_DESC',
  BulletThreeAsc = 'bulletThree_ASC',
  BulletThreeDesc = 'bulletThree_DESC',
  BulletTwoAsc = 'bulletTwo_ASC',
  BulletTwoDesc = 'bulletTwo_DESC',
  ButtonTextAsc = 'buttonText_ASC',
  ButtonTextDesc = 'buttonText_DESC',
  ButtonUrlAsc = 'buttonUrl_ASC',
  ButtonUrlDesc = 'buttonUrl_DESC',
  HeadlineAsc = 'headline_ASC',
  HeadlineDesc = 'headline_DESC',
  SubHeadAsc = 'subHead_ASC',
  SubHeadDesc = 'subHead_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
}

/** [See type definition](https://app.contentful.com/spaces/9cxgygtadmvl/content_types/componentWhatYouGetModule) */
export type ComponentWhatYouGetModule = Entry &
  _Node & {
    __typename?: 'ComponentWhatYouGetModule'
    _id: Scalars['ID']
    contentfulMetadata: ContentfulMetadata
    display?: Maybe<Scalars['String']>
    headline?: Maybe<Scalars['String']>
    internalLabel?: Maybe<Scalars['String']>
    linkedFrom?: Maybe<ComponentWhatYouGetModuleLinkingCollections>
    sectionId?: Maybe<Scalars['String']>
    subHead?: Maybe<Scalars['String']>
    sys: Sys
    whatYouGetCardsCollection?: Maybe<ComponentWhatYouGetModuleWhatYouGetCardsCollection>
  }

/** [See type definition](https://app.contentful.com/spaces/9cxgygtadmvl/content_types/componentWhatYouGetModule) */
export type ComponentWhatYouGetModuleDisplayArgs = {
  locale?: InputMaybe<Scalars['String']>
}

/** [See type definition](https://app.contentful.com/spaces/9cxgygtadmvl/content_types/componentWhatYouGetModule) */
export type ComponentWhatYouGetModuleHeadlineArgs = {
  locale?: InputMaybe<Scalars['String']>
}

/** [See type definition](https://app.contentful.com/spaces/9cxgygtadmvl/content_types/componentWhatYouGetModule) */
export type ComponentWhatYouGetModuleInternalLabelArgs = {
  locale?: InputMaybe<Scalars['String']>
}

/** [See type definition](https://app.contentful.com/spaces/9cxgygtadmvl/content_types/componentWhatYouGetModule) */
export type ComponentWhatYouGetModuleLinkedFromArgs = {
  allowedLocales?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
}

/** [See type definition](https://app.contentful.com/spaces/9cxgygtadmvl/content_types/componentWhatYouGetModule) */
export type ComponentWhatYouGetModuleSectionIdArgs = {
  locale?: InputMaybe<Scalars['String']>
}

/** [See type definition](https://app.contentful.com/spaces/9cxgygtadmvl/content_types/componentWhatYouGetModule) */
export type ComponentWhatYouGetModuleSubHeadArgs = {
  locale?: InputMaybe<Scalars['String']>
}

/** [See type definition](https://app.contentful.com/spaces/9cxgygtadmvl/content_types/componentWhatYouGetModule) */
export type ComponentWhatYouGetModuleWhatYouGetCardsCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>
  locale?: InputMaybe<Scalars['String']>
  order?: InputMaybe<
    Array<InputMaybe<ComponentWhatYouGetModuleWhatYouGetCardsCollectionOrder>>
  >
  preview?: InputMaybe<Scalars['Boolean']>
  skip?: InputMaybe<Scalars['Int']>
  where?: InputMaybe<ComponentWhatYouGetCardFilter>
}

export type ComponentWhatYouGetModuleCollection = {
  __typename?: 'ComponentWhatYouGetModuleCollection'
  items: Array<Maybe<ComponentWhatYouGetModule>>
  limit: Scalars['Int']
  skip: Scalars['Int']
  total: Scalars['Int']
}

export type ComponentWhatYouGetModuleFilter = {
  AND?: InputMaybe<Array<InputMaybe<ComponentWhatYouGetModuleFilter>>>
  OR?: InputMaybe<Array<InputMaybe<ComponentWhatYouGetModuleFilter>>>
  contentfulMetadata?: InputMaybe<ContentfulMetadataFilter>
  display?: InputMaybe<Scalars['String']>
  display_contains?: InputMaybe<Scalars['String']>
  display_exists?: InputMaybe<Scalars['Boolean']>
  display_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  display_not?: InputMaybe<Scalars['String']>
  display_not_contains?: InputMaybe<Scalars['String']>
  display_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  headline?: InputMaybe<Scalars['String']>
  headline_contains?: InputMaybe<Scalars['String']>
  headline_exists?: InputMaybe<Scalars['Boolean']>
  headline_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  headline_not?: InputMaybe<Scalars['String']>
  headline_not_contains?: InputMaybe<Scalars['String']>
  headline_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  internalLabel?: InputMaybe<Scalars['String']>
  internalLabel_contains?: InputMaybe<Scalars['String']>
  internalLabel_exists?: InputMaybe<Scalars['Boolean']>
  internalLabel_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  internalLabel_not?: InputMaybe<Scalars['String']>
  internalLabel_not_contains?: InputMaybe<Scalars['String']>
  internalLabel_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  sectionId?: InputMaybe<Scalars['String']>
  sectionId_contains?: InputMaybe<Scalars['String']>
  sectionId_exists?: InputMaybe<Scalars['Boolean']>
  sectionId_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  sectionId_not?: InputMaybe<Scalars['String']>
  sectionId_not_contains?: InputMaybe<Scalars['String']>
  sectionId_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  subHead?: InputMaybe<Scalars['String']>
  subHead_contains?: InputMaybe<Scalars['String']>
  subHead_exists?: InputMaybe<Scalars['Boolean']>
  subHead_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  subHead_not?: InputMaybe<Scalars['String']>
  subHead_not_contains?: InputMaybe<Scalars['String']>
  subHead_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  sys?: InputMaybe<SysFilter>
  whatYouGetCards?: InputMaybe<CfComponentWhatYouGetCardNestedFilter>
  whatYouGetCardsCollection_exists?: InputMaybe<Scalars['Boolean']>
}

export type ComponentWhatYouGetModuleLinkingCollections = {
  __typename?: 'ComponentWhatYouGetModuleLinkingCollections'
  campaignPagesCollection?: Maybe<CampaignPagesCollection>
  entryCollection?: Maybe<EntryCollection>
  templateAboutUsCollection?: Maybe<TemplateAboutUsCollection>
  templateBookingCollection?: Maybe<TemplateBookingCollection>
  templateCareersCollection?: Maybe<TemplateCareersCollection>
  templateContactUsCollection?: Maybe<TemplateContactUsCollection>
  templateDestinationsCollection?: Maybe<TemplateDestinationsCollection>
  templateForaReserveCollection?: Maybe<TemplateForaReserveCollection>
  templateForaXCollection?: Maybe<TemplateForaXCollection>
  templateHomeCollection?: Maybe<TemplateHomeCollection>
  templateJoinUsCollection?: Maybe<TemplateJoinUsCollection>
  templateNewsletterCollection?: Maybe<TemplateNewsletterCollection>
  templatePartnersCollection?: Maybe<TemplatePartnersCollection>
  templateReviewsCollection?: Maybe<TemplateReviewsCollection>
  templateWhyBookWithForaCollection?: Maybe<TemplateWhyBookWithForaCollection>
}

export type ComponentWhatYouGetModuleLinkingCollectionsCampaignPagesCollectionArgs =
  {
    limit?: InputMaybe<Scalars['Int']>
    locale?: InputMaybe<Scalars['String']>
    order?: InputMaybe<
      Array<
        InputMaybe<ComponentWhatYouGetModuleLinkingCollectionsCampaignPagesCollectionOrder>
      >
    >
    preview?: InputMaybe<Scalars['Boolean']>
    skip?: InputMaybe<Scalars['Int']>
  }

export type ComponentWhatYouGetModuleLinkingCollectionsEntryCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>
  locale?: InputMaybe<Scalars['String']>
  preview?: InputMaybe<Scalars['Boolean']>
  skip?: InputMaybe<Scalars['Int']>
}

export type ComponentWhatYouGetModuleLinkingCollectionsTemplateAboutUsCollectionArgs =
  {
    limit?: InputMaybe<Scalars['Int']>
    locale?: InputMaybe<Scalars['String']>
    order?: InputMaybe<
      Array<
        InputMaybe<ComponentWhatYouGetModuleLinkingCollectionsTemplateAboutUsCollectionOrder>
      >
    >
    preview?: InputMaybe<Scalars['Boolean']>
    skip?: InputMaybe<Scalars['Int']>
  }

export type ComponentWhatYouGetModuleLinkingCollectionsTemplateBookingCollectionArgs =
  {
    limit?: InputMaybe<Scalars['Int']>
    locale?: InputMaybe<Scalars['String']>
    order?: InputMaybe<
      Array<
        InputMaybe<ComponentWhatYouGetModuleLinkingCollectionsTemplateBookingCollectionOrder>
      >
    >
    preview?: InputMaybe<Scalars['Boolean']>
    skip?: InputMaybe<Scalars['Int']>
  }

export type ComponentWhatYouGetModuleLinkingCollectionsTemplateCareersCollectionArgs =
  {
    limit?: InputMaybe<Scalars['Int']>
    locale?: InputMaybe<Scalars['String']>
    order?: InputMaybe<
      Array<
        InputMaybe<ComponentWhatYouGetModuleLinkingCollectionsTemplateCareersCollectionOrder>
      >
    >
    preview?: InputMaybe<Scalars['Boolean']>
    skip?: InputMaybe<Scalars['Int']>
  }

export type ComponentWhatYouGetModuleLinkingCollectionsTemplateContactUsCollectionArgs =
  {
    limit?: InputMaybe<Scalars['Int']>
    locale?: InputMaybe<Scalars['String']>
    order?: InputMaybe<
      Array<
        InputMaybe<ComponentWhatYouGetModuleLinkingCollectionsTemplateContactUsCollectionOrder>
      >
    >
    preview?: InputMaybe<Scalars['Boolean']>
    skip?: InputMaybe<Scalars['Int']>
  }

export type ComponentWhatYouGetModuleLinkingCollectionsTemplateDestinationsCollectionArgs =
  {
    limit?: InputMaybe<Scalars['Int']>
    locale?: InputMaybe<Scalars['String']>
    order?: InputMaybe<
      Array<
        InputMaybe<ComponentWhatYouGetModuleLinkingCollectionsTemplateDestinationsCollectionOrder>
      >
    >
    preview?: InputMaybe<Scalars['Boolean']>
    skip?: InputMaybe<Scalars['Int']>
  }

export type ComponentWhatYouGetModuleLinkingCollectionsTemplateForaReserveCollectionArgs =
  {
    limit?: InputMaybe<Scalars['Int']>
    locale?: InputMaybe<Scalars['String']>
    order?: InputMaybe<
      Array<
        InputMaybe<ComponentWhatYouGetModuleLinkingCollectionsTemplateForaReserveCollectionOrder>
      >
    >
    preview?: InputMaybe<Scalars['Boolean']>
    skip?: InputMaybe<Scalars['Int']>
  }

export type ComponentWhatYouGetModuleLinkingCollectionsTemplateForaXCollectionArgs =
  {
    limit?: InputMaybe<Scalars['Int']>
    locale?: InputMaybe<Scalars['String']>
    order?: InputMaybe<
      Array<
        InputMaybe<ComponentWhatYouGetModuleLinkingCollectionsTemplateForaXCollectionOrder>
      >
    >
    preview?: InputMaybe<Scalars['Boolean']>
    skip?: InputMaybe<Scalars['Int']>
  }

export type ComponentWhatYouGetModuleLinkingCollectionsTemplateHomeCollectionArgs =
  {
    limit?: InputMaybe<Scalars['Int']>
    locale?: InputMaybe<Scalars['String']>
    order?: InputMaybe<
      Array<
        InputMaybe<ComponentWhatYouGetModuleLinkingCollectionsTemplateHomeCollectionOrder>
      >
    >
    preview?: InputMaybe<Scalars['Boolean']>
    skip?: InputMaybe<Scalars['Int']>
  }

export type ComponentWhatYouGetModuleLinkingCollectionsTemplateJoinUsCollectionArgs =
  {
    limit?: InputMaybe<Scalars['Int']>
    locale?: InputMaybe<Scalars['String']>
    order?: InputMaybe<
      Array<
        InputMaybe<ComponentWhatYouGetModuleLinkingCollectionsTemplateJoinUsCollectionOrder>
      >
    >
    preview?: InputMaybe<Scalars['Boolean']>
    skip?: InputMaybe<Scalars['Int']>
  }

export type ComponentWhatYouGetModuleLinkingCollectionsTemplateNewsletterCollectionArgs =
  {
    limit?: InputMaybe<Scalars['Int']>
    locale?: InputMaybe<Scalars['String']>
    order?: InputMaybe<
      Array<
        InputMaybe<ComponentWhatYouGetModuleLinkingCollectionsTemplateNewsletterCollectionOrder>
      >
    >
    preview?: InputMaybe<Scalars['Boolean']>
    skip?: InputMaybe<Scalars['Int']>
  }

export type ComponentWhatYouGetModuleLinkingCollectionsTemplatePartnersCollectionArgs =
  {
    limit?: InputMaybe<Scalars['Int']>
    locale?: InputMaybe<Scalars['String']>
    order?: InputMaybe<
      Array<
        InputMaybe<ComponentWhatYouGetModuleLinkingCollectionsTemplatePartnersCollectionOrder>
      >
    >
    preview?: InputMaybe<Scalars['Boolean']>
    skip?: InputMaybe<Scalars['Int']>
  }

export type ComponentWhatYouGetModuleLinkingCollectionsTemplateReviewsCollectionArgs =
  {
    limit?: InputMaybe<Scalars['Int']>
    locale?: InputMaybe<Scalars['String']>
    order?: InputMaybe<
      Array<
        InputMaybe<ComponentWhatYouGetModuleLinkingCollectionsTemplateReviewsCollectionOrder>
      >
    >
    preview?: InputMaybe<Scalars['Boolean']>
    skip?: InputMaybe<Scalars['Int']>
  }

export type ComponentWhatYouGetModuleLinkingCollectionsTemplateWhyBookWithForaCollectionArgs =
  {
    limit?: InputMaybe<Scalars['Int']>
    locale?: InputMaybe<Scalars['String']>
    order?: InputMaybe<
      Array<
        InputMaybe<ComponentWhatYouGetModuleLinkingCollectionsTemplateWhyBookWithForaCollectionOrder>
      >
    >
    preview?: InputMaybe<Scalars['Boolean']>
    skip?: InputMaybe<Scalars['Int']>
  }

export enum ComponentWhatYouGetModuleLinkingCollectionsCampaignPagesCollectionOrder {
  CollapseFooterAsc = 'collapseFooter_ASC',
  CollapseFooterDesc = 'collapseFooter_DESC',
  CollapseHeaderAsc = 'collapseHeader_ASC',
  CollapseHeaderDesc = 'collapseHeader_DESC',
  HideFooterAsc = 'hideFooter_ASC',
  HideFooterDesc = 'hideFooter_DESC',
  HideGlobalBannerAsc = 'hideGlobalBanner_ASC',
  HideGlobalBannerDesc = 'hideGlobalBanner_DESC',
  JotformIdAsc = 'jotformId_ASC',
  JotformIdDesc = 'jotformId_DESC',
  NoindexNofollowAsc = 'noindexNofollow_ASC',
  NoindexNofollowDesc = 'noindexNofollow_DESC',
  ShowHeaderAsc = 'showHeader_ASC',
  ShowHeaderDesc = 'showHeader_DESC',
  SlugAsc = 'slug_ASC',
  SlugDesc = 'slug_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC',
}

export enum ComponentWhatYouGetModuleLinkingCollectionsTemplateAboutUsCollectionOrder {
  CollapseHeaderAsc = 'collapseHeader_ASC',
  CollapseHeaderDesc = 'collapseHeader_DESC',
  HeaderBlockTitleAsc = 'headerBlockTitle_ASC',
  HeaderBlockTitleDesc = 'headerBlockTitle_DESC',
  ShowHeaderAsc = 'showHeader_ASC',
  ShowHeaderDesc = 'showHeader_DESC',
  SubtitleAsc = 'subtitle_ASC',
  SubtitleDesc = 'subtitle_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC',
}

export enum ComponentWhatYouGetModuleLinkingCollectionsTemplateBookingCollectionOrder {
  CollapseHeaderAsc = 'collapseHeader_ASC',
  CollapseHeaderDesc = 'collapseHeader_DESC',
  ShowHeaderAsc = 'showHeader_ASC',
  ShowHeaderDesc = 'showHeader_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC',
}

export enum ComponentWhatYouGetModuleLinkingCollectionsTemplateCareersCollectionOrder {
  CollapseHeaderAsc = 'collapseHeader_ASC',
  CollapseHeaderDesc = 'collapseHeader_DESC',
  ShowHeaderAsc = 'showHeader_ASC',
  ShowHeaderDesc = 'showHeader_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC',
}

export enum ComponentWhatYouGetModuleLinkingCollectionsTemplateContactUsCollectionOrder {
  CollapseHeaderAsc = 'collapseHeader_ASC',
  CollapseHeaderDesc = 'collapseHeader_DESC',
  ShowHeaderAsc = 'showHeader_ASC',
  ShowHeaderDesc = 'showHeader_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC',
}

export enum ComponentWhatYouGetModuleLinkingCollectionsTemplateDestinationsCollectionOrder {
  CollapseHeaderAsc = 'collapseHeader_ASC',
  CollapseHeaderDesc = 'collapseHeader_DESC',
  CollapsibleHeadingAsc = 'collapsibleHeading_ASC',
  CollapsibleHeadingDesc = 'collapsibleHeading_DESC',
  ShowHeaderAsc = 'showHeader_ASC',
  ShowHeaderDesc = 'showHeader_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC',
}

export enum ComponentWhatYouGetModuleLinkingCollectionsTemplateForaReserveCollectionOrder {
  CollapseHeaderAsc = 'collapseHeader_ASC',
  CollapseHeaderDesc = 'collapseHeader_DESC',
  ShowHeaderAsc = 'showHeader_ASC',
  ShowHeaderDesc = 'showHeader_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC',
}

export enum ComponentWhatYouGetModuleLinkingCollectionsTemplateForaXCollectionOrder {
  AdvisorGridHeaderAsc = 'advisorGridHeader_ASC',
  AdvisorGridHeaderDesc = 'advisorGridHeader_DESC',
  AdvisorGridSubCopyAsc = 'advisorGridSubCopy_ASC',
  AdvisorGridSubCopyDesc = 'advisorGridSubCopy_DESC',
  CollapseHeaderAsc = 'collapseHeader_ASC',
  CollapseHeaderDesc = 'collapseHeader_DESC',
  ShowHeaderAsc = 'showHeader_ASC',
  ShowHeaderDesc = 'showHeader_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC',
}

export enum ComponentWhatYouGetModuleLinkingCollectionsTemplateHomeCollectionOrder {
  CollapseHeaderAsc = 'collapseHeader_ASC',
  CollapseHeaderDesc = 'collapseHeader_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC',
}

export enum ComponentWhatYouGetModuleLinkingCollectionsTemplateJoinUsCollectionOrder {
  CollapseHeaderAsc = 'collapseHeader_ASC',
  CollapseHeaderDesc = 'collapseHeader_DESC',
  HideGlobalBannerAsc = 'hideGlobalBanner_ASC',
  HideGlobalBannerDesc = 'hideGlobalBanner_DESC',
  ShowHeaderAsc = 'showHeader_ASC',
  ShowHeaderDesc = 'showHeader_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC',
}

export enum ComponentWhatYouGetModuleLinkingCollectionsTemplateNewsletterCollectionOrder {
  CollapseHeaderAsc = 'collapseHeader_ASC',
  CollapseHeaderDesc = 'collapseHeader_DESC',
  ShowHeaderAsc = 'showHeader_ASC',
  ShowHeaderDesc = 'showHeader_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC',
}

export enum ComponentWhatYouGetModuleLinkingCollectionsTemplatePartnersCollectionOrder {
  CollapseHeaderAsc = 'collapseHeader_ASC',
  CollapseHeaderDesc = 'collapseHeader_DESC',
  ShowHeaderAsc = 'showHeader_ASC',
  ShowHeaderDesc = 'showHeader_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC',
}

export enum ComponentWhatYouGetModuleLinkingCollectionsTemplateReviewsCollectionOrder {
  CollapseHeaderAsc = 'collapseHeader_ASC',
  CollapseHeaderDesc = 'collapseHeader_DESC',
  ShowHeaderAsc = 'showHeader_ASC',
  ShowHeaderDesc = 'showHeader_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC',
}

export enum ComponentWhatYouGetModuleLinkingCollectionsTemplateWhyBookWithForaCollectionOrder {
  CollapseHeaderAsc = 'collapseHeader_ASC',
  CollapseHeaderDesc = 'collapseHeader_DESC',
  ShowHeaderAsc = 'showHeader_ASC',
  ShowHeaderDesc = 'showHeader_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC',
}

export enum ComponentWhatYouGetModuleOrder {
  DisplayAsc = 'display_ASC',
  DisplayDesc = 'display_DESC',
  HeadlineAsc = 'headline_ASC',
  HeadlineDesc = 'headline_DESC',
  InternalLabelAsc = 'internalLabel_ASC',
  InternalLabelDesc = 'internalLabel_DESC',
  SectionIdAsc = 'sectionId_ASC',
  SectionIdDesc = 'sectionId_DESC',
  SubHeadAsc = 'subHead_ASC',
  SubHeadDesc = 'subHead_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
}

export type ComponentWhatYouGetModuleWhatYouGetCardsCollection = {
  __typename?: 'ComponentWhatYouGetModuleWhatYouGetCardsCollection'
  items: Array<Maybe<ComponentWhatYouGetCard>>
  limit: Scalars['Int']
  skip: Scalars['Int']
  total: Scalars['Int']
}

export enum ComponentWhatYouGetModuleWhatYouGetCardsCollectionOrder {
  BulletFourAsc = 'bulletFour_ASC',
  BulletFourDesc = 'bulletFour_DESC',
  BulletOneAsc = 'bulletOne_ASC',
  BulletOneDesc = 'bulletOne_DESC',
  BulletThreeAsc = 'bulletThree_ASC',
  BulletThreeDesc = 'bulletThree_DESC',
  BulletTwoAsc = 'bulletTwo_ASC',
  BulletTwoDesc = 'bulletTwo_DESC',
  ButtonTextAsc = 'buttonText_ASC',
  ButtonTextDesc = 'buttonText_DESC',
  ButtonUrlAsc = 'buttonUrl_ASC',
  ButtonUrlDesc = 'buttonUrl_DESC',
  HeadlineAsc = 'headline_ASC',
  HeadlineDesc = 'headline_DESC',
  SubHeadAsc = 'subHead_ASC',
  SubHeadDesc = 'subHead_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
}

export type ContentfulMetadata = {
  __typename?: 'ContentfulMetadata'
  tags: Array<Maybe<ContentfulTag>>
}

export type ContentfulMetadataConceptsFilter = {
  id_contains_all?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  id_contains_none?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  id_contains_some?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
}

export type ContentfulMetadataFilter = {
  concepts?: InputMaybe<ContentfulMetadataConceptsFilter>
  tags?: InputMaybe<ContentfulMetadataTagsFilter>
  tags_exists?: InputMaybe<Scalars['Boolean']>
}

export type ContentfulMetadataTagsFilter = {
  id_contains_all?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  id_contains_none?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  id_contains_some?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
}

/**
 * Represents a tag entity for finding and organizing content easily.
 *       Find out more here: https://www.contentful.com/developers/docs/references/content-delivery-api/#/reference/content-tags
 */
export type ContentfulTag = {
  __typename?: 'ContentfulTag'
  id?: Maybe<Scalars['String']>
  name?: Maybe<Scalars['String']>
}

/** Create and manage top-level Destinations categories. Top-level categories are not used for landing pages or in URL segments. [See type definition](https://app.contentful.com/spaces/9cxgygtadmvl/content_types/destinationsCategories) */
export type DestinationsCategories = Entry &
  _Node & {
    __typename?: 'DestinationsCategories'
    _id: Scalars['ID']
    cloudinaryFeaturedImage?: Maybe<Scalars['JSON']>
    cloudinaryMobileFeaturedImage?: Maybe<Scalars['JSON']>
    contentfulMetadata: ContentfulMetadata
    featuredHotelsCollection?: Maybe<DestinationsCategoriesFeaturedHotelsCollection>
    featuredImage?: Maybe<Asset>
    generateTopHotelsPage?: Maybe<Scalars['Boolean']>
    grammarDefiniteArticle?: Maybe<Scalars['Boolean']>
    linkedFrom?: Maybe<DestinationsCategoriesLinkingCollections>
    metaContent?: Maybe<PagesMetaContent>
    mobileFeaturedImage?: Maybe<Asset>
    questionsCollection?: Maybe<DestinationsCategoriesQuestionsCollection>
    rankedHotels?: Maybe<Scalars['JSON']>
    slug?: Maybe<Scalars['String']>
    subcategoriesCollection?: Maybe<DestinationsCategoriesSubcategoriesCollection>
    sys: Sys
    teaserCopy?: Maybe<DestinationsCategoriesTeaserCopy>
    title?: Maybe<Scalars['String']>
  }

/** Create and manage top-level Destinations categories. Top-level categories are not used for landing pages or in URL segments. [See type definition](https://app.contentful.com/spaces/9cxgygtadmvl/content_types/destinationsCategories) */
export type DestinationsCategoriesCloudinaryFeaturedImageArgs = {
  locale?: InputMaybe<Scalars['String']>
}

/** Create and manage top-level Destinations categories. Top-level categories are not used for landing pages or in URL segments. [See type definition](https://app.contentful.com/spaces/9cxgygtadmvl/content_types/destinationsCategories) */
export type DestinationsCategoriesCloudinaryMobileFeaturedImageArgs = {
  locale?: InputMaybe<Scalars['String']>
}

/** Create and manage top-level Destinations categories. Top-level categories are not used for landing pages or in URL segments. [See type definition](https://app.contentful.com/spaces/9cxgygtadmvl/content_types/destinationsCategories) */
export type DestinationsCategoriesFeaturedHotelsCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>
  locale?: InputMaybe<Scalars['String']>
  order?: InputMaybe<
    Array<InputMaybe<DestinationsCategoriesFeaturedHotelsCollectionOrder>>
  >
  preview?: InputMaybe<Scalars['Boolean']>
  skip?: InputMaybe<Scalars['Int']>
  where?: InputMaybe<HotelsFilter>
}

/** Create and manage top-level Destinations categories. Top-level categories are not used for landing pages or in URL segments. [See type definition](https://app.contentful.com/spaces/9cxgygtadmvl/content_types/destinationsCategories) */
export type DestinationsCategoriesFeaturedImageArgs = {
  locale?: InputMaybe<Scalars['String']>
  preview?: InputMaybe<Scalars['Boolean']>
}

/** Create and manage top-level Destinations categories. Top-level categories are not used for landing pages or in URL segments. [See type definition](https://app.contentful.com/spaces/9cxgygtadmvl/content_types/destinationsCategories) */
export type DestinationsCategoriesGenerateTopHotelsPageArgs = {
  locale?: InputMaybe<Scalars['String']>
}

/** Create and manage top-level Destinations categories. Top-level categories are not used for landing pages or in URL segments. [See type definition](https://app.contentful.com/spaces/9cxgygtadmvl/content_types/destinationsCategories) */
export type DestinationsCategoriesGrammarDefiniteArticleArgs = {
  locale?: InputMaybe<Scalars['String']>
}

/** Create and manage top-level Destinations categories. Top-level categories are not used for landing pages or in URL segments. [See type definition](https://app.contentful.com/spaces/9cxgygtadmvl/content_types/destinationsCategories) */
export type DestinationsCategoriesLinkedFromArgs = {
  allowedLocales?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
}

/** Create and manage top-level Destinations categories. Top-level categories are not used for landing pages or in URL segments. [See type definition](https://app.contentful.com/spaces/9cxgygtadmvl/content_types/destinationsCategories) */
export type DestinationsCategoriesMetaContentArgs = {
  locale?: InputMaybe<Scalars['String']>
  preview?: InputMaybe<Scalars['Boolean']>
  where?: InputMaybe<PagesMetaContentFilter>
}

/** Create and manage top-level Destinations categories. Top-level categories are not used for landing pages or in URL segments. [See type definition](https://app.contentful.com/spaces/9cxgygtadmvl/content_types/destinationsCategories) */
export type DestinationsCategoriesMobileFeaturedImageArgs = {
  locale?: InputMaybe<Scalars['String']>
  preview?: InputMaybe<Scalars['Boolean']>
}

/** Create and manage top-level Destinations categories. Top-level categories are not used for landing pages or in URL segments. [See type definition](https://app.contentful.com/spaces/9cxgygtadmvl/content_types/destinationsCategories) */
export type DestinationsCategoriesQuestionsCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>
  locale?: InputMaybe<Scalars['String']>
  order?: InputMaybe<
    Array<InputMaybe<DestinationsCategoriesQuestionsCollectionOrder>>
  >
  preview?: InputMaybe<Scalars['Boolean']>
  skip?: InputMaybe<Scalars['Int']>
  where?: InputMaybe<FaqsQuestionsFilter>
}

/** Create and manage top-level Destinations categories. Top-level categories are not used for landing pages or in URL segments. [See type definition](https://app.contentful.com/spaces/9cxgygtadmvl/content_types/destinationsCategories) */
export type DestinationsCategoriesRankedHotelsArgs = {
  locale?: InputMaybe<Scalars['String']>
}

/** Create and manage top-level Destinations categories. Top-level categories are not used for landing pages or in URL segments. [See type definition](https://app.contentful.com/spaces/9cxgygtadmvl/content_types/destinationsCategories) */
export type DestinationsCategoriesSlugArgs = {
  locale?: InputMaybe<Scalars['String']>
}

/** Create and manage top-level Destinations categories. Top-level categories are not used for landing pages or in URL segments. [See type definition](https://app.contentful.com/spaces/9cxgygtadmvl/content_types/destinationsCategories) */
export type DestinationsCategoriesSubcategoriesCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>
  locale?: InputMaybe<Scalars['String']>
  order?: InputMaybe<
    Array<InputMaybe<DestinationsCategoriesSubcategoriesCollectionOrder>>
  >
  preview?: InputMaybe<Scalars['Boolean']>
  skip?: InputMaybe<Scalars['Int']>
  where?: InputMaybe<DestinationsSubcategoriesFilter>
}

/** Create and manage top-level Destinations categories. Top-level categories are not used for landing pages or in URL segments. [See type definition](https://app.contentful.com/spaces/9cxgygtadmvl/content_types/destinationsCategories) */
export type DestinationsCategoriesTeaserCopyArgs = {
  locale?: InputMaybe<Scalars['String']>
}

/** Create and manage top-level Destinations categories. Top-level categories are not used for landing pages or in URL segments. [See type definition](https://app.contentful.com/spaces/9cxgygtadmvl/content_types/destinationsCategories) */
export type DestinationsCategoriesTitleArgs = {
  locale?: InputMaybe<Scalars['String']>
}

export type DestinationsCategoriesCollection = {
  __typename?: 'DestinationsCategoriesCollection'
  items: Array<Maybe<DestinationsCategories>>
  limit: Scalars['Int']
  skip: Scalars['Int']
  total: Scalars['Int']
}

export type DestinationsCategoriesFeaturedHotelsCollection = {
  __typename?: 'DestinationsCategoriesFeaturedHotelsCollection'
  items: Array<Maybe<Hotels>>
  limit: Scalars['Int']
  skip: Scalars['Int']
  total: Scalars['Int']
}

export enum DestinationsCategoriesFeaturedHotelsCollectionOrder {
  ArchivedAsc = 'archived_ASC',
  ArchivedDesc = 'archived_DESC',
  ForaSupplierIdAsc = 'foraSupplierId_ASC',
  ForaSupplierIdDesc = 'foraSupplierId_DESC',
  GenerateLandingPageAsc = 'generateLandingPage_ASC',
  GenerateLandingPageDesc = 'generateLandingPage_DESC',
  HeroImageAsc = 'heroImage_ASC',
  HeroImageDesc = 'heroImage_DESC',
  HideFromTopHotelsPagesAsc = 'hideFromTopHotelsPages_ASC',
  HideFromTopHotelsPagesDesc = 'hideFromTopHotelsPages_DESC',
  MichelinKeysAsc = 'michelinKeys_ASC',
  MichelinKeysDesc = 'michelinKeys_DESC',
  SlugAsc = 'slug_ASC',
  SlugDesc = 'slug_DESC',
  StarRatingAsc = 'starRating_ASC',
  StarRatingDesc = 'starRating_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC',
}

export type DestinationsCategoriesFilter = {
  AND?: InputMaybe<Array<InputMaybe<DestinationsCategoriesFilter>>>
  OR?: InputMaybe<Array<InputMaybe<DestinationsCategoriesFilter>>>
  cloudinaryFeaturedImage_exists?: InputMaybe<Scalars['Boolean']>
  cloudinaryMobileFeaturedImage_exists?: InputMaybe<Scalars['Boolean']>
  contentfulMetadata?: InputMaybe<ContentfulMetadataFilter>
  featuredHotels?: InputMaybe<CfHotelsNestedFilter>
  featuredHotelsCollection_exists?: InputMaybe<Scalars['Boolean']>
  featuredImage_exists?: InputMaybe<Scalars['Boolean']>
  generateTopHotelsPage?: InputMaybe<Scalars['Boolean']>
  generateTopHotelsPage_exists?: InputMaybe<Scalars['Boolean']>
  generateTopHotelsPage_not?: InputMaybe<Scalars['Boolean']>
  grammarDefiniteArticle?: InputMaybe<Scalars['Boolean']>
  grammarDefiniteArticle_exists?: InputMaybe<Scalars['Boolean']>
  grammarDefiniteArticle_not?: InputMaybe<Scalars['Boolean']>
  metaContent?: InputMaybe<CfPagesMetaContentNestedFilter>
  metaContent_exists?: InputMaybe<Scalars['Boolean']>
  mobileFeaturedImage_exists?: InputMaybe<Scalars['Boolean']>
  questions?: InputMaybe<CfFaqsQuestionsNestedFilter>
  questionsCollection_exists?: InputMaybe<Scalars['Boolean']>
  rankedHotels_exists?: InputMaybe<Scalars['Boolean']>
  slug?: InputMaybe<Scalars['String']>
  slug_contains?: InputMaybe<Scalars['String']>
  slug_exists?: InputMaybe<Scalars['Boolean']>
  slug_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  slug_not?: InputMaybe<Scalars['String']>
  slug_not_contains?: InputMaybe<Scalars['String']>
  slug_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  subcategories?: InputMaybe<CfDestinationsSubcategoriesNestedFilter>
  subcategoriesCollection_exists?: InputMaybe<Scalars['Boolean']>
  sys?: InputMaybe<SysFilter>
  teaserCopy_contains?: InputMaybe<Scalars['String']>
  teaserCopy_exists?: InputMaybe<Scalars['Boolean']>
  teaserCopy_not_contains?: InputMaybe<Scalars['String']>
  title?: InputMaybe<Scalars['String']>
  title_contains?: InputMaybe<Scalars['String']>
  title_exists?: InputMaybe<Scalars['Boolean']>
  title_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  title_not?: InputMaybe<Scalars['String']>
  title_not_contains?: InputMaybe<Scalars['String']>
  title_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
}

export type DestinationsCategoriesLinkingCollections = {
  __typename?: 'DestinationsCategoriesLinkingCollections'
  entryCollection?: Maybe<EntryCollection>
  entryTestimonialCollection?: Maybe<EntryTestimonialCollection>
  hotelsCollection?: Maybe<HotelsCollection>
  templateDestinationsCollection?: Maybe<TemplateDestinationsCollection>
}

export type DestinationsCategoriesLinkingCollectionsEntryCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>
  locale?: InputMaybe<Scalars['String']>
  preview?: InputMaybe<Scalars['Boolean']>
  skip?: InputMaybe<Scalars['Int']>
}

export type DestinationsCategoriesLinkingCollectionsEntryTestimonialCollectionArgs =
  {
    limit?: InputMaybe<Scalars['Int']>
    locale?: InputMaybe<Scalars['String']>
    order?: InputMaybe<
      Array<
        InputMaybe<DestinationsCategoriesLinkingCollectionsEntryTestimonialCollectionOrder>
      >
    >
    preview?: InputMaybe<Scalars['Boolean']>
    skip?: InputMaybe<Scalars['Int']>
  }

export type DestinationsCategoriesLinkingCollectionsHotelsCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>
  locale?: InputMaybe<Scalars['String']>
  order?: InputMaybe<
    Array<
      InputMaybe<DestinationsCategoriesLinkingCollectionsHotelsCollectionOrder>
    >
  >
  preview?: InputMaybe<Scalars['Boolean']>
  skip?: InputMaybe<Scalars['Int']>
}

export type DestinationsCategoriesLinkingCollectionsTemplateDestinationsCollectionArgs =
  {
    limit?: InputMaybe<Scalars['Int']>
    locale?: InputMaybe<Scalars['String']>
    order?: InputMaybe<
      Array<
        InputMaybe<DestinationsCategoriesLinkingCollectionsTemplateDestinationsCollectionOrder>
      >
    >
    preview?: InputMaybe<Scalars['Boolean']>
    skip?: InputMaybe<Scalars['Int']>
  }

export enum DestinationsCategoriesLinkingCollectionsEntryTestimonialCollectionOrder {
  AboutForaAsc = 'aboutFora_ASC',
  AboutForaDesc = 'aboutFora_DESC',
  BeginTravelDateAsc = 'beginTravelDate_ASC',
  BeginTravelDateDesc = 'beginTravelDate_DESC',
  DateSubmittedAsc = 'dateSubmitted_ASC',
  DateSubmittedDesc = 'dateSubmitted_DESC',
  DisplayAsc = 'display_ASC',
  DisplayDesc = 'display_DESC',
  EndTravelDateAsc = 'endTravelDate_ASC',
  EndTravelDateDesc = 'endTravelDate_DESC',
  InternalLabelAsc = 'internalLabel_ASC',
  InternalLabelDesc = 'internalLabel_DESC',
  IsFeaturedAsc = 'isFeatured_ASC',
  IsFeaturedDesc = 'isFeatured_DESC',
  StarsAsc = 'stars_ASC',
  StarsDesc = 'stars_DESC',
  SubmitterEmailAsc = 'submitterEmail_ASC',
  SubmitterEmailDesc = 'submitterEmail_DESC',
  SubmitterFirstNameAsc = 'submitterFirstName_ASC',
  SubmitterFirstNameDesc = 'submitterFirstName_DESC',
  SubmitterLastNameAsc = 'submitterLastName_ASC',
  SubmitterLastNameDesc = 'submitterLastName_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  TestimonialSourceAsc = 'testimonialSource_ASC',
  TestimonialSourceDesc = 'testimonialSource_DESC',
  TitleOfReviewAsc = 'titleOfReview_ASC',
  TitleOfReviewDesc = 'titleOfReview_DESC',
}

export enum DestinationsCategoriesLinkingCollectionsHotelsCollectionOrder {
  ArchivedAsc = 'archived_ASC',
  ArchivedDesc = 'archived_DESC',
  ForaSupplierIdAsc = 'foraSupplierId_ASC',
  ForaSupplierIdDesc = 'foraSupplierId_DESC',
  GenerateLandingPageAsc = 'generateLandingPage_ASC',
  GenerateLandingPageDesc = 'generateLandingPage_DESC',
  HeroImageAsc = 'heroImage_ASC',
  HeroImageDesc = 'heroImage_DESC',
  HideFromTopHotelsPagesAsc = 'hideFromTopHotelsPages_ASC',
  HideFromTopHotelsPagesDesc = 'hideFromTopHotelsPages_DESC',
  MichelinKeysAsc = 'michelinKeys_ASC',
  MichelinKeysDesc = 'michelinKeys_DESC',
  SlugAsc = 'slug_ASC',
  SlugDesc = 'slug_DESC',
  StarRatingAsc = 'starRating_ASC',
  StarRatingDesc = 'starRating_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC',
}

export enum DestinationsCategoriesLinkingCollectionsTemplateDestinationsCollectionOrder {
  CollapseHeaderAsc = 'collapseHeader_ASC',
  CollapseHeaderDesc = 'collapseHeader_DESC',
  CollapsibleHeadingAsc = 'collapsibleHeading_ASC',
  CollapsibleHeadingDesc = 'collapsibleHeading_DESC',
  ShowHeaderAsc = 'showHeader_ASC',
  ShowHeaderDesc = 'showHeader_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC',
}

export enum DestinationsCategoriesOrder {
  GenerateTopHotelsPageAsc = 'generateTopHotelsPage_ASC',
  GenerateTopHotelsPageDesc = 'generateTopHotelsPage_DESC',
  GrammarDefiniteArticleAsc = 'grammarDefiniteArticle_ASC',
  GrammarDefiniteArticleDesc = 'grammarDefiniteArticle_DESC',
  SlugAsc = 'slug_ASC',
  SlugDesc = 'slug_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC',
}

export type DestinationsCategoriesQuestionsCollection = {
  __typename?: 'DestinationsCategoriesQuestionsCollection'
  items: Array<Maybe<FaqsQuestions>>
  limit: Scalars['Int']
  skip: Scalars['Int']
  total: Scalars['Int']
}

export enum DestinationsCategoriesQuestionsCollectionOrder {
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC',
}

export type DestinationsCategoriesSubcategoriesCollection = {
  __typename?: 'DestinationsCategoriesSubcategoriesCollection'
  items: Array<Maybe<DestinationsSubcategories>>
  limit: Scalars['Int']
  skip: Scalars['Int']
  total: Scalars['Int']
}

export enum DestinationsCategoriesSubcategoriesCollectionOrder {
  GenerateLandingPageAsc = 'generateLandingPage_ASC',
  GenerateLandingPageDesc = 'generateLandingPage_DESC',
  GenerateTopHotelsPageAsc = 'generateTopHotelsPage_ASC',
  GenerateTopHotelsPageDesc = 'generateTopHotelsPage_DESC',
  GrammarDefiniteArticleAsc = 'grammarDefiniteArticle_ASC',
  GrammarDefiniteArticleDesc = 'grammarDefiniteArticle_DESC',
  ShowInCategoryListAsc = 'showInCategoryList_ASC',
  ShowInCategoryListDesc = 'showInCategoryList_DESC',
  SlugAsc = 'slug_ASC',
  SlugDesc = 'slug_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC',
}

export type DestinationsCategoriesTeaserCopy = {
  __typename?: 'DestinationsCategoriesTeaserCopy'
  json: Scalars['JSON']
  links: DestinationsCategoriesTeaserCopyLinks
}

export type DestinationsCategoriesTeaserCopyAssets = {
  __typename?: 'DestinationsCategoriesTeaserCopyAssets'
  block: Array<Maybe<Asset>>
  hyperlink: Array<Maybe<Asset>>
}

export type DestinationsCategoriesTeaserCopyEntries = {
  __typename?: 'DestinationsCategoriesTeaserCopyEntries'
  block: Array<Maybe<Entry>>
  hyperlink: Array<Maybe<Entry>>
  inline: Array<Maybe<Entry>>
}

export type DestinationsCategoriesTeaserCopyLinks = {
  __typename?: 'DestinationsCategoriesTeaserCopyLinks'
  assets: DestinationsCategoriesTeaserCopyAssets
  entries: DestinationsCategoriesTeaserCopyEntries
  resources: DestinationsCategoriesTeaserCopyResources
}

export type DestinationsCategoriesTeaserCopyResources = {
  __typename?: 'DestinationsCategoriesTeaserCopyResources'
  block: Array<DestinationsCategoriesTeaserCopyResourcesBlock>
  hyperlink: Array<DestinationsCategoriesTeaserCopyResourcesHyperlink>
  inline: Array<DestinationsCategoriesTeaserCopyResourcesInline>
}

export type DestinationsCategoriesTeaserCopyResourcesBlock = ResourceLink & {
  __typename?: 'DestinationsCategoriesTeaserCopyResourcesBlock'
  sys: ResourceSys
}

export type DestinationsCategoriesTeaserCopyResourcesHyperlink =
  ResourceLink & {
    __typename?: 'DestinationsCategoriesTeaserCopyResourcesHyperlink'
    sys: ResourceSys
  }

export type DestinationsCategoriesTeaserCopyResourcesInline = ResourceLink & {
  __typename?: 'DestinationsCategoriesTeaserCopyResourcesInline'
  sys: ResourceSys
}

/** Create and manage child-level Destinations subcategories and nested relationship. Subcategories are sometimes used for landing pages and make use of slugs in URL segments [See type definition](https://app.contentful.com/spaces/9cxgygtadmvl/content_types/destinationsSubcategories) */
export type DestinationsSubcategories = Entry &
  _Node & {
    __typename?: 'DestinationsSubcategories'
    _id: Scalars['ID']
    childSubcategoriesCollection?: Maybe<DestinationsSubcategoriesChildSubcategoriesCollection>
    cloudinaryFeaturedImage?: Maybe<Scalars['JSON']>
    cloudinaryMobileFeaturedImage?: Maybe<Scalars['JSON']>
    contentfulMetadata: ContentfulMetadata
    country?: Maybe<DestinationsSubcategories>
    featuredHotelsCollection?: Maybe<DestinationsSubcategoriesFeaturedHotelsCollection>
    featuredImage?: Maybe<Asset>
    generateLandingPage?: Maybe<Scalars['Boolean']>
    generateTopHotelsPage?: Maybe<Scalars['Boolean']>
    grammarDefiniteArticle?: Maybe<Scalars['Boolean']>
    linkedFrom?: Maybe<DestinationsSubcategoriesLinkingCollections>
    metaContent?: Maybe<PagesMetaContent>
    mobileFeaturedImage?: Maybe<Asset>
    questionsCollection?: Maybe<DestinationsSubcategoriesQuestionsCollection>
    rankedHotels?: Maybe<Scalars['JSON']>
    relatedDestinationsCollection?: Maybe<DestinationsSubcategoriesRelatedDestinationsCollection>
    showInCategoryList?: Maybe<Scalars['Boolean']>
    slug?: Maybe<Scalars['String']>
    sys: Sys
    teaserCopy?: Maybe<DestinationsSubcategoriesTeaserCopy>
    title?: Maybe<Scalars['String']>
  }

/** Create and manage child-level Destinations subcategories and nested relationship. Subcategories are sometimes used for landing pages and make use of slugs in URL segments [See type definition](https://app.contentful.com/spaces/9cxgygtadmvl/content_types/destinationsSubcategories) */
export type DestinationsSubcategoriesChildSubcategoriesCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>
  locale?: InputMaybe<Scalars['String']>
  order?: InputMaybe<
    Array<
      InputMaybe<DestinationsSubcategoriesChildSubcategoriesCollectionOrder>
    >
  >
  preview?: InputMaybe<Scalars['Boolean']>
  skip?: InputMaybe<Scalars['Int']>
  where?: InputMaybe<DestinationsSubcategoriesFilter>
}

/** Create and manage child-level Destinations subcategories and nested relationship. Subcategories are sometimes used for landing pages and make use of slugs in URL segments [See type definition](https://app.contentful.com/spaces/9cxgygtadmvl/content_types/destinationsSubcategories) */
export type DestinationsSubcategoriesCloudinaryFeaturedImageArgs = {
  locale?: InputMaybe<Scalars['String']>
}

/** Create and manage child-level Destinations subcategories and nested relationship. Subcategories are sometimes used for landing pages and make use of slugs in URL segments [See type definition](https://app.contentful.com/spaces/9cxgygtadmvl/content_types/destinationsSubcategories) */
export type DestinationsSubcategoriesCloudinaryMobileFeaturedImageArgs = {
  locale?: InputMaybe<Scalars['String']>
}

/** Create and manage child-level Destinations subcategories and nested relationship. Subcategories are sometimes used for landing pages and make use of slugs in URL segments [See type definition](https://app.contentful.com/spaces/9cxgygtadmvl/content_types/destinationsSubcategories) */
export type DestinationsSubcategoriesCountryArgs = {
  locale?: InputMaybe<Scalars['String']>
  preview?: InputMaybe<Scalars['Boolean']>
  where?: InputMaybe<DestinationsSubcategoriesFilter>
}

/** Create and manage child-level Destinations subcategories and nested relationship. Subcategories are sometimes used for landing pages and make use of slugs in URL segments [See type definition](https://app.contentful.com/spaces/9cxgygtadmvl/content_types/destinationsSubcategories) */
export type DestinationsSubcategoriesFeaturedHotelsCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>
  locale?: InputMaybe<Scalars['String']>
  order?: InputMaybe<
    Array<InputMaybe<DestinationsSubcategoriesFeaturedHotelsCollectionOrder>>
  >
  preview?: InputMaybe<Scalars['Boolean']>
  skip?: InputMaybe<Scalars['Int']>
  where?: InputMaybe<HotelsFilter>
}

/** Create and manage child-level Destinations subcategories and nested relationship. Subcategories are sometimes used for landing pages and make use of slugs in URL segments [See type definition](https://app.contentful.com/spaces/9cxgygtadmvl/content_types/destinationsSubcategories) */
export type DestinationsSubcategoriesFeaturedImageArgs = {
  locale?: InputMaybe<Scalars['String']>
  preview?: InputMaybe<Scalars['Boolean']>
}

/** Create and manage child-level Destinations subcategories and nested relationship. Subcategories are sometimes used for landing pages and make use of slugs in URL segments [See type definition](https://app.contentful.com/spaces/9cxgygtadmvl/content_types/destinationsSubcategories) */
export type DestinationsSubcategoriesGenerateLandingPageArgs = {
  locale?: InputMaybe<Scalars['String']>
}

/** Create and manage child-level Destinations subcategories and nested relationship. Subcategories are sometimes used for landing pages and make use of slugs in URL segments [See type definition](https://app.contentful.com/spaces/9cxgygtadmvl/content_types/destinationsSubcategories) */
export type DestinationsSubcategoriesGenerateTopHotelsPageArgs = {
  locale?: InputMaybe<Scalars['String']>
}

/** Create and manage child-level Destinations subcategories and nested relationship. Subcategories are sometimes used for landing pages and make use of slugs in URL segments [See type definition](https://app.contentful.com/spaces/9cxgygtadmvl/content_types/destinationsSubcategories) */
export type DestinationsSubcategoriesGrammarDefiniteArticleArgs = {
  locale?: InputMaybe<Scalars['String']>
}

/** Create and manage child-level Destinations subcategories and nested relationship. Subcategories are sometimes used for landing pages and make use of slugs in URL segments [See type definition](https://app.contentful.com/spaces/9cxgygtadmvl/content_types/destinationsSubcategories) */
export type DestinationsSubcategoriesLinkedFromArgs = {
  allowedLocales?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
}

/** Create and manage child-level Destinations subcategories and nested relationship. Subcategories are sometimes used for landing pages and make use of slugs in URL segments [See type definition](https://app.contentful.com/spaces/9cxgygtadmvl/content_types/destinationsSubcategories) */
export type DestinationsSubcategoriesMetaContentArgs = {
  locale?: InputMaybe<Scalars['String']>
  preview?: InputMaybe<Scalars['Boolean']>
  where?: InputMaybe<PagesMetaContentFilter>
}

/** Create and manage child-level Destinations subcategories and nested relationship. Subcategories are sometimes used for landing pages and make use of slugs in URL segments [See type definition](https://app.contentful.com/spaces/9cxgygtadmvl/content_types/destinationsSubcategories) */
export type DestinationsSubcategoriesMobileFeaturedImageArgs = {
  locale?: InputMaybe<Scalars['String']>
  preview?: InputMaybe<Scalars['Boolean']>
}

/** Create and manage child-level Destinations subcategories and nested relationship. Subcategories are sometimes used for landing pages and make use of slugs in URL segments [See type definition](https://app.contentful.com/spaces/9cxgygtadmvl/content_types/destinationsSubcategories) */
export type DestinationsSubcategoriesQuestionsCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>
  locale?: InputMaybe<Scalars['String']>
  order?: InputMaybe<
    Array<InputMaybe<DestinationsSubcategoriesQuestionsCollectionOrder>>
  >
  preview?: InputMaybe<Scalars['Boolean']>
  skip?: InputMaybe<Scalars['Int']>
  where?: InputMaybe<FaqsQuestionsFilter>
}

/** Create and manage child-level Destinations subcategories and nested relationship. Subcategories are sometimes used for landing pages and make use of slugs in URL segments [See type definition](https://app.contentful.com/spaces/9cxgygtadmvl/content_types/destinationsSubcategories) */
export type DestinationsSubcategoriesRankedHotelsArgs = {
  locale?: InputMaybe<Scalars['String']>
}

/** Create and manage child-level Destinations subcategories and nested relationship. Subcategories are sometimes used for landing pages and make use of slugs in URL segments [See type definition](https://app.contentful.com/spaces/9cxgygtadmvl/content_types/destinationsSubcategories) */
export type DestinationsSubcategoriesRelatedDestinationsCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>
  locale?: InputMaybe<Scalars['String']>
  order?: InputMaybe<
    Array<
      InputMaybe<DestinationsSubcategoriesRelatedDestinationsCollectionOrder>
    >
  >
  preview?: InputMaybe<Scalars['Boolean']>
  skip?: InputMaybe<Scalars['Int']>
  where?: InputMaybe<DestinationsSubcategoriesFilter>
}

/** Create and manage child-level Destinations subcategories and nested relationship. Subcategories are sometimes used for landing pages and make use of slugs in URL segments [See type definition](https://app.contentful.com/spaces/9cxgygtadmvl/content_types/destinationsSubcategories) */
export type DestinationsSubcategoriesShowInCategoryListArgs = {
  locale?: InputMaybe<Scalars['String']>
}

/** Create and manage child-level Destinations subcategories and nested relationship. Subcategories are sometimes used for landing pages and make use of slugs in URL segments [See type definition](https://app.contentful.com/spaces/9cxgygtadmvl/content_types/destinationsSubcategories) */
export type DestinationsSubcategoriesSlugArgs = {
  locale?: InputMaybe<Scalars['String']>
}

/** Create and manage child-level Destinations subcategories and nested relationship. Subcategories are sometimes used for landing pages and make use of slugs in URL segments [See type definition](https://app.contentful.com/spaces/9cxgygtadmvl/content_types/destinationsSubcategories) */
export type DestinationsSubcategoriesTeaserCopyArgs = {
  locale?: InputMaybe<Scalars['String']>
}

/** Create and manage child-level Destinations subcategories and nested relationship. Subcategories are sometimes used for landing pages and make use of slugs in URL segments [See type definition](https://app.contentful.com/spaces/9cxgygtadmvl/content_types/destinationsSubcategories) */
export type DestinationsSubcategoriesTitleArgs = {
  locale?: InputMaybe<Scalars['String']>
}

export type DestinationsSubcategoriesChildSubcategoriesCollection = {
  __typename?: 'DestinationsSubcategoriesChildSubcategoriesCollection'
  items: Array<Maybe<DestinationsSubcategories>>
  limit: Scalars['Int']
  skip: Scalars['Int']
  total: Scalars['Int']
}

export enum DestinationsSubcategoriesChildSubcategoriesCollectionOrder {
  GenerateLandingPageAsc = 'generateLandingPage_ASC',
  GenerateLandingPageDesc = 'generateLandingPage_DESC',
  GenerateTopHotelsPageAsc = 'generateTopHotelsPage_ASC',
  GenerateTopHotelsPageDesc = 'generateTopHotelsPage_DESC',
  GrammarDefiniteArticleAsc = 'grammarDefiniteArticle_ASC',
  GrammarDefiniteArticleDesc = 'grammarDefiniteArticle_DESC',
  ShowInCategoryListAsc = 'showInCategoryList_ASC',
  ShowInCategoryListDesc = 'showInCategoryList_DESC',
  SlugAsc = 'slug_ASC',
  SlugDesc = 'slug_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC',
}

export type DestinationsSubcategoriesCollection = {
  __typename?: 'DestinationsSubcategoriesCollection'
  items: Array<Maybe<DestinationsSubcategories>>
  limit: Scalars['Int']
  skip: Scalars['Int']
  total: Scalars['Int']
}

export type DestinationsSubcategoriesFeaturedHotelsCollection = {
  __typename?: 'DestinationsSubcategoriesFeaturedHotelsCollection'
  items: Array<Maybe<Hotels>>
  limit: Scalars['Int']
  skip: Scalars['Int']
  total: Scalars['Int']
}

export enum DestinationsSubcategoriesFeaturedHotelsCollectionOrder {
  ArchivedAsc = 'archived_ASC',
  ArchivedDesc = 'archived_DESC',
  ForaSupplierIdAsc = 'foraSupplierId_ASC',
  ForaSupplierIdDesc = 'foraSupplierId_DESC',
  GenerateLandingPageAsc = 'generateLandingPage_ASC',
  GenerateLandingPageDesc = 'generateLandingPage_DESC',
  HeroImageAsc = 'heroImage_ASC',
  HeroImageDesc = 'heroImage_DESC',
  HideFromTopHotelsPagesAsc = 'hideFromTopHotelsPages_ASC',
  HideFromTopHotelsPagesDesc = 'hideFromTopHotelsPages_DESC',
  MichelinKeysAsc = 'michelinKeys_ASC',
  MichelinKeysDesc = 'michelinKeys_DESC',
  SlugAsc = 'slug_ASC',
  SlugDesc = 'slug_DESC',
  StarRatingAsc = 'starRating_ASC',
  StarRatingDesc = 'starRating_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC',
}

export type DestinationsSubcategoriesFilter = {
  AND?: InputMaybe<Array<InputMaybe<DestinationsSubcategoriesFilter>>>
  OR?: InputMaybe<Array<InputMaybe<DestinationsSubcategoriesFilter>>>
  childSubcategories?: InputMaybe<CfDestinationsSubcategoriesNestedFilter>
  childSubcategoriesCollection_exists?: InputMaybe<Scalars['Boolean']>
  cloudinaryFeaturedImage_exists?: InputMaybe<Scalars['Boolean']>
  cloudinaryMobileFeaturedImage_exists?: InputMaybe<Scalars['Boolean']>
  contentfulMetadata?: InputMaybe<ContentfulMetadataFilter>
  country?: InputMaybe<CfDestinationsSubcategoriesNestedFilter>
  country_exists?: InputMaybe<Scalars['Boolean']>
  featuredHotels?: InputMaybe<CfHotelsNestedFilter>
  featuredHotelsCollection_exists?: InputMaybe<Scalars['Boolean']>
  featuredImage_exists?: InputMaybe<Scalars['Boolean']>
  generateLandingPage?: InputMaybe<Scalars['Boolean']>
  generateLandingPage_exists?: InputMaybe<Scalars['Boolean']>
  generateLandingPage_not?: InputMaybe<Scalars['Boolean']>
  generateTopHotelsPage?: InputMaybe<Scalars['Boolean']>
  generateTopHotelsPage_exists?: InputMaybe<Scalars['Boolean']>
  generateTopHotelsPage_not?: InputMaybe<Scalars['Boolean']>
  grammarDefiniteArticle?: InputMaybe<Scalars['Boolean']>
  grammarDefiniteArticle_exists?: InputMaybe<Scalars['Boolean']>
  grammarDefiniteArticle_not?: InputMaybe<Scalars['Boolean']>
  metaContent?: InputMaybe<CfPagesMetaContentNestedFilter>
  metaContent_exists?: InputMaybe<Scalars['Boolean']>
  mobileFeaturedImage_exists?: InputMaybe<Scalars['Boolean']>
  questions?: InputMaybe<CfFaqsQuestionsNestedFilter>
  questionsCollection_exists?: InputMaybe<Scalars['Boolean']>
  rankedHotels_exists?: InputMaybe<Scalars['Boolean']>
  relatedDestinations?: InputMaybe<CfDestinationsSubcategoriesNestedFilter>
  relatedDestinationsCollection_exists?: InputMaybe<Scalars['Boolean']>
  showInCategoryList?: InputMaybe<Scalars['Boolean']>
  showInCategoryList_exists?: InputMaybe<Scalars['Boolean']>
  showInCategoryList_not?: InputMaybe<Scalars['Boolean']>
  slug?: InputMaybe<Scalars['String']>
  slug_contains?: InputMaybe<Scalars['String']>
  slug_exists?: InputMaybe<Scalars['Boolean']>
  slug_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  slug_not?: InputMaybe<Scalars['String']>
  slug_not_contains?: InputMaybe<Scalars['String']>
  slug_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  sys?: InputMaybe<SysFilter>
  teaserCopy_contains?: InputMaybe<Scalars['String']>
  teaserCopy_exists?: InputMaybe<Scalars['Boolean']>
  teaserCopy_not_contains?: InputMaybe<Scalars['String']>
  title?: InputMaybe<Scalars['String']>
  title_contains?: InputMaybe<Scalars['String']>
  title_exists?: InputMaybe<Scalars['Boolean']>
  title_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  title_not?: InputMaybe<Scalars['String']>
  title_not_contains?: InputMaybe<Scalars['String']>
  title_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
}

export type DestinationsSubcategoriesLinkingCollections = {
  __typename?: 'DestinationsSubcategoriesLinkingCollections'
  advisorsCollection?: Maybe<AdvisorsCollection>
  blogPostsCollection?: Maybe<BlogPostsCollection>
  destinationsCategoriesCollection?: Maybe<DestinationsCategoriesCollection>
  destinationsSubcategoriesCollection?: Maybe<DestinationsSubcategoriesCollection>
  entryCollection?: Maybe<EntryCollection>
  entryTestimonialCollection?: Maybe<EntryTestimonialCollection>
  entryTripReportCollection?: Maybe<EntryTripReportCollection>
  hotelsCollection?: Maybe<HotelsCollection>
  itinerariesCollection?: Maybe<ItinerariesCollection>
  templateDestinationsCollection?: Maybe<TemplateDestinationsCollection>
}

export type DestinationsSubcategoriesLinkingCollectionsAdvisorsCollectionArgs =
  {
    limit?: InputMaybe<Scalars['Int']>
    locale?: InputMaybe<Scalars['String']>
    order?: InputMaybe<
      Array<
        InputMaybe<DestinationsSubcategoriesLinkingCollectionsAdvisorsCollectionOrder>
      >
    >
    preview?: InputMaybe<Scalars['Boolean']>
    skip?: InputMaybe<Scalars['Int']>
  }

export type DestinationsSubcategoriesLinkingCollectionsBlogPostsCollectionArgs =
  {
    limit?: InputMaybe<Scalars['Int']>
    locale?: InputMaybe<Scalars['String']>
    order?: InputMaybe<
      Array<
        InputMaybe<DestinationsSubcategoriesLinkingCollectionsBlogPostsCollectionOrder>
      >
    >
    preview?: InputMaybe<Scalars['Boolean']>
    skip?: InputMaybe<Scalars['Int']>
  }

export type DestinationsSubcategoriesLinkingCollectionsDestinationsCategoriesCollectionArgs =
  {
    limit?: InputMaybe<Scalars['Int']>
    locale?: InputMaybe<Scalars['String']>
    order?: InputMaybe<
      Array<
        InputMaybe<DestinationsSubcategoriesLinkingCollectionsDestinationsCategoriesCollectionOrder>
      >
    >
    preview?: InputMaybe<Scalars['Boolean']>
    skip?: InputMaybe<Scalars['Int']>
  }

export type DestinationsSubcategoriesLinkingCollectionsDestinationsSubcategoriesCollectionArgs =
  {
    limit?: InputMaybe<Scalars['Int']>
    locale?: InputMaybe<Scalars['String']>
    order?: InputMaybe<
      Array<
        InputMaybe<DestinationsSubcategoriesLinkingCollectionsDestinationsSubcategoriesCollectionOrder>
      >
    >
    preview?: InputMaybe<Scalars['Boolean']>
    skip?: InputMaybe<Scalars['Int']>
  }

export type DestinationsSubcategoriesLinkingCollectionsEntryCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>
  locale?: InputMaybe<Scalars['String']>
  preview?: InputMaybe<Scalars['Boolean']>
  skip?: InputMaybe<Scalars['Int']>
}

export type DestinationsSubcategoriesLinkingCollectionsEntryTestimonialCollectionArgs =
  {
    limit?: InputMaybe<Scalars['Int']>
    locale?: InputMaybe<Scalars['String']>
    order?: InputMaybe<
      Array<
        InputMaybe<DestinationsSubcategoriesLinkingCollectionsEntryTestimonialCollectionOrder>
      >
    >
    preview?: InputMaybe<Scalars['Boolean']>
    skip?: InputMaybe<Scalars['Int']>
  }

export type DestinationsSubcategoriesLinkingCollectionsEntryTripReportCollectionArgs =
  {
    limit?: InputMaybe<Scalars['Int']>
    locale?: InputMaybe<Scalars['String']>
    order?: InputMaybe<
      Array<
        InputMaybe<DestinationsSubcategoriesLinkingCollectionsEntryTripReportCollectionOrder>
      >
    >
    preview?: InputMaybe<Scalars['Boolean']>
    skip?: InputMaybe<Scalars['Int']>
  }

export type DestinationsSubcategoriesLinkingCollectionsHotelsCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>
  locale?: InputMaybe<Scalars['String']>
  order?: InputMaybe<
    Array<
      InputMaybe<DestinationsSubcategoriesLinkingCollectionsHotelsCollectionOrder>
    >
  >
  preview?: InputMaybe<Scalars['Boolean']>
  skip?: InputMaybe<Scalars['Int']>
}

export type DestinationsSubcategoriesLinkingCollectionsItinerariesCollectionArgs =
  {
    limit?: InputMaybe<Scalars['Int']>
    locale?: InputMaybe<Scalars['String']>
    order?: InputMaybe<
      Array<
        InputMaybe<DestinationsSubcategoriesLinkingCollectionsItinerariesCollectionOrder>
      >
    >
    preview?: InputMaybe<Scalars['Boolean']>
    skip?: InputMaybe<Scalars['Int']>
  }

export type DestinationsSubcategoriesLinkingCollectionsTemplateDestinationsCollectionArgs =
  {
    limit?: InputMaybe<Scalars['Int']>
    locale?: InputMaybe<Scalars['String']>
    order?: InputMaybe<
      Array<
        InputMaybe<DestinationsSubcategoriesLinkingCollectionsTemplateDestinationsCollectionOrder>
      >
    >
    preview?: InputMaybe<Scalars['Boolean']>
    skip?: InputMaybe<Scalars['Int']>
  }

export enum DestinationsSubcategoriesLinkingCollectionsAdvisorsCollectionOrder {
  AdvisorContactEnabledAsc = 'advisorContactEnabled_ASC',
  AdvisorContactEnabledDesc = 'advisorContactEnabled_DESC',
  AdvisorVariantAsc = 'advisorVariant_ASC',
  AdvisorVariantDesc = 'advisorVariant_DESC',
  EmailAddressAsc = 'emailAddress_ASC',
  EmailAddressDesc = 'emailAddress_DESC',
  FacebookUrlAsc = 'facebookUrl_ASC',
  FacebookUrlDesc = 'facebookUrl_DESC',
  FirstNameAsc = 'firstName_ASC',
  FirstNameDesc = 'firstName_DESC',
  ForaAdvisorHotelsAsc = 'foraAdvisorHotels_ASC',
  ForaAdvisorHotelsDesc = 'foraAdvisorHotels_DESC',
  ForaAdvisorIdAsc = 'foraAdvisorId_ASC',
  ForaAdvisorIdDesc = 'foraAdvisorId_DESC',
  InstagramUrlAsc = 'instagramUrl_ASC',
  InstagramUrlDesc = 'instagramUrl_DESC',
  LastNameAsc = 'lastName_ASC',
  LastNameDesc = 'lastName_DESC',
  LinkedinUrlAsc = 'linkedinUrl_ASC',
  LinkedinUrlDesc = 'linkedinUrl_DESC',
  MinimumBookingAsc = 'minimumBooking_ASC',
  MinimumBookingDesc = 'minimumBooking_DESC',
  PinterestUrlAsc = 'pinterestUrl_ASC',
  PinterestUrlDesc = 'pinterestUrl_DESC',
  PronounsAsc = 'pronouns_ASC',
  PronounsDesc = 'pronouns_DESC',
  ShortDescriptionAsc = 'shortDescription_ASC',
  ShortDescriptionDesc = 'shortDescription_DESC',
  ShowOnAdvisorsPageAsc = 'showOnAdvisorsPage_ASC',
  ShowOnAdvisorsPageDesc = 'showOnAdvisorsPage_DESC',
  ShowOnHomepageAsc = 'showOnHomepage_ASC',
  ShowOnHomepageDesc = 'showOnHomepage_DESC',
  ShowOnOtherLandingPagesAsc = 'showOnOtherLandingPages_ASC',
  ShowOnOtherLandingPagesDesc = 'showOnOtherLandingPages_DESC',
  SlugAsc = 'slug_ASC',
  SlugDesc = 'slug_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  TierAsc = 'tier_ASC',
  TierDesc = 'tier_DESC',
  TikTokUrlAsc = 'tikTokUrl_ASC',
  TikTokUrlDesc = 'tikTokUrl_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC',
  TwitterUrlAsc = 'twitterUrl_ASC',
  TwitterUrlDesc = 'twitterUrl_DESC',
  WebsiteUrlAsc = 'websiteUrl_ASC',
  WebsiteUrlDesc = 'websiteUrl_DESC',
  YoutubeUrlAsc = 'youtubeUrl_ASC',
  YoutubeUrlDesc = 'youtubeUrl_DESC',
}

export enum DestinationsSubcategoriesLinkingCollectionsBlogPostsCollectionOrder {
  BlogCategoryAsc = 'blogCategory_ASC',
  BlogCategoryDesc = 'blogCategory_DESC',
  BottomCtaAsc = 'bottomCta_ASC',
  BottomCtaDesc = 'bottomCta_DESC',
  EnableAdvisorShareAsc = 'enableAdvisorShare_ASC',
  EnableAdvisorShareDesc = 'enableAdvisorShare_DESC',
  MetaDescriptionAsc = 'metaDescription_ASC',
  MetaDescriptionDesc = 'metaDescription_DESC',
  MetaTitleAsc = 'metaTitle_ASC',
  MetaTitleDesc = 'metaTitle_DESC',
  NoindexNofollowAsc = 'noindexNofollow_ASC',
  NoindexNofollowDesc = 'noindexNofollow_DESC',
  PublishedDateAsc = 'publishedDate_ASC',
  PublishedDateDesc = 'publishedDate_DESC',
  ShowOnBlogCategoryPageAsc = 'showOnBlogCategoryPage_ASC',
  ShowOnBlogCategoryPageDesc = 'showOnBlogCategoryPage_DESC',
  ShowOnBlogLandingPageAsc = 'showOnBlogLandingPage_ASC',
  ShowOnBlogLandingPageDesc = 'showOnBlogLandingPage_DESC',
  ShowOnTravelPagesAsc = 'showOnTravelPages_ASC',
  ShowOnTravelPagesDesc = 'showOnTravelPages_DESC',
  SlugAsc = 'slug_ASC',
  SlugDesc = 'slug_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC',
}

export enum DestinationsSubcategoriesLinkingCollectionsDestinationsCategoriesCollectionOrder {
  GenerateTopHotelsPageAsc = 'generateTopHotelsPage_ASC',
  GenerateTopHotelsPageDesc = 'generateTopHotelsPage_DESC',
  GrammarDefiniteArticleAsc = 'grammarDefiniteArticle_ASC',
  GrammarDefiniteArticleDesc = 'grammarDefiniteArticle_DESC',
  SlugAsc = 'slug_ASC',
  SlugDesc = 'slug_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC',
}

export enum DestinationsSubcategoriesLinkingCollectionsDestinationsSubcategoriesCollectionOrder {
  GenerateLandingPageAsc = 'generateLandingPage_ASC',
  GenerateLandingPageDesc = 'generateLandingPage_DESC',
  GenerateTopHotelsPageAsc = 'generateTopHotelsPage_ASC',
  GenerateTopHotelsPageDesc = 'generateTopHotelsPage_DESC',
  GrammarDefiniteArticleAsc = 'grammarDefiniteArticle_ASC',
  GrammarDefiniteArticleDesc = 'grammarDefiniteArticle_DESC',
  ShowInCategoryListAsc = 'showInCategoryList_ASC',
  ShowInCategoryListDesc = 'showInCategoryList_DESC',
  SlugAsc = 'slug_ASC',
  SlugDesc = 'slug_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC',
}

export enum DestinationsSubcategoriesLinkingCollectionsEntryTestimonialCollectionOrder {
  AboutForaAsc = 'aboutFora_ASC',
  AboutForaDesc = 'aboutFora_DESC',
  BeginTravelDateAsc = 'beginTravelDate_ASC',
  BeginTravelDateDesc = 'beginTravelDate_DESC',
  DateSubmittedAsc = 'dateSubmitted_ASC',
  DateSubmittedDesc = 'dateSubmitted_DESC',
  DisplayAsc = 'display_ASC',
  DisplayDesc = 'display_DESC',
  EndTravelDateAsc = 'endTravelDate_ASC',
  EndTravelDateDesc = 'endTravelDate_DESC',
  InternalLabelAsc = 'internalLabel_ASC',
  InternalLabelDesc = 'internalLabel_DESC',
  IsFeaturedAsc = 'isFeatured_ASC',
  IsFeaturedDesc = 'isFeatured_DESC',
  StarsAsc = 'stars_ASC',
  StarsDesc = 'stars_DESC',
  SubmitterEmailAsc = 'submitterEmail_ASC',
  SubmitterEmailDesc = 'submitterEmail_DESC',
  SubmitterFirstNameAsc = 'submitterFirstName_ASC',
  SubmitterFirstNameDesc = 'submitterFirstName_DESC',
  SubmitterLastNameAsc = 'submitterLastName_ASC',
  SubmitterLastNameDesc = 'submitterLastName_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  TestimonialSourceAsc = 'testimonialSource_ASC',
  TestimonialSourceDesc = 'testimonialSource_DESC',
  TitleOfReviewAsc = 'titleOfReview_ASC',
  TitleOfReviewDesc = 'titleOfReview_DESC',
}

export enum DestinationsSubcategoriesLinkingCollectionsEntryTripReportCollectionOrder {
  LocationForTitlesAsc = 'locationForTitles_ASC',
  LocationForTitlesDesc = 'locationForTitles_DESC',
  MetaDescriptionAsc = 'metaDescription_ASC',
  MetaDescriptionDesc = 'metaDescription_DESC',
  MetaTitleAsc = 'metaTitle_ASC',
  MetaTitleDesc = 'metaTitle_DESC',
  SeoSlugAsc = 'seoSlug_ASC',
  SeoSlugDesc = 'seoSlug_DESC',
  ShowOnGuidesAndDestinationsPageAsc = 'showOnGuidesAndDestinationsPage_ASC',
  ShowOnGuidesAndDestinationsPageDesc = 'showOnGuidesAndDestinationsPage_DESC',
  ShowOnHomepageAsc = 'showOnHomepage_ASC',
  ShowOnHomepageDesc = 'showOnHomepage_DESC',
  ShowOnOtherLandingPagesAsc = 'showOnOtherLandingPages_ASC',
  ShowOnOtherLandingPagesDesc = 'showOnOtherLandingPages_DESC',
  SlugIdAsc = 'slugID_ASC',
  SlugIdDesc = 'slugID_DESC',
  SlugAsc = 'slug_ASC',
  SlugDesc = 'slug_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  TestUpdatedSlugIdAsc = 'testUpdatedSlugId_ASC',
  TestUpdatedSlugIdDesc = 'testUpdatedSlugId_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC',
}

export enum DestinationsSubcategoriesLinkingCollectionsHotelsCollectionOrder {
  ArchivedAsc = 'archived_ASC',
  ArchivedDesc = 'archived_DESC',
  ForaSupplierIdAsc = 'foraSupplierId_ASC',
  ForaSupplierIdDesc = 'foraSupplierId_DESC',
  GenerateLandingPageAsc = 'generateLandingPage_ASC',
  GenerateLandingPageDesc = 'generateLandingPage_DESC',
  HeroImageAsc = 'heroImage_ASC',
  HeroImageDesc = 'heroImage_DESC',
  HideFromTopHotelsPagesAsc = 'hideFromTopHotelsPages_ASC',
  HideFromTopHotelsPagesDesc = 'hideFromTopHotelsPages_DESC',
  MichelinKeysAsc = 'michelinKeys_ASC',
  MichelinKeysDesc = 'michelinKeys_DESC',
  SlugAsc = 'slug_ASC',
  SlugDesc = 'slug_DESC',
  StarRatingAsc = 'starRating_ASC',
  StarRatingDesc = 'starRating_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC',
}

export enum DestinationsSubcategoriesLinkingCollectionsItinerariesCollectionOrder {
  ItineraryVariantAsc = 'itineraryVariant_ASC',
  ItineraryVariantDesc = 'itineraryVariant_DESC',
  LocationForTitlesAsc = 'locationForTitles_ASC',
  LocationForTitlesDesc = 'locationForTitles_DESC',
  MetaDescriptionAsc = 'metaDescription_ASC',
  MetaDescriptionDesc = 'metaDescription_DESC',
  MetaTitleAsc = 'metaTitle_ASC',
  MetaTitleDesc = 'metaTitle_DESC',
  SeoSlugAsc = 'seoSlug_ASC',
  SeoSlugDesc = 'seoSlug_DESC',
  ShowOnHomepageAsc = 'showOnHomepage_ASC',
  ShowOnHomepageDesc = 'showOnHomepage_DESC',
  ShowOnItinerariesPageAsc = 'showOnItinerariesPage_ASC',
  ShowOnItinerariesPageDesc = 'showOnItinerariesPage_DESC',
  ShowOnOtherLandingPagesAsc = 'showOnOtherLandingPages_ASC',
  ShowOnOtherLandingPagesDesc = 'showOnOtherLandingPages_DESC',
  SlugIdAsc = 'slugID_ASC',
  SlugIdDesc = 'slugID_DESC',
  SlugAsc = 'slug_ASC',
  SlugDesc = 'slug_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC',
}

export enum DestinationsSubcategoriesLinkingCollectionsTemplateDestinationsCollectionOrder {
  CollapseHeaderAsc = 'collapseHeader_ASC',
  CollapseHeaderDesc = 'collapseHeader_DESC',
  CollapsibleHeadingAsc = 'collapsibleHeading_ASC',
  CollapsibleHeadingDesc = 'collapsibleHeading_DESC',
  ShowHeaderAsc = 'showHeader_ASC',
  ShowHeaderDesc = 'showHeader_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC',
}

export enum DestinationsSubcategoriesOrder {
  GenerateLandingPageAsc = 'generateLandingPage_ASC',
  GenerateLandingPageDesc = 'generateLandingPage_DESC',
  GenerateTopHotelsPageAsc = 'generateTopHotelsPage_ASC',
  GenerateTopHotelsPageDesc = 'generateTopHotelsPage_DESC',
  GrammarDefiniteArticleAsc = 'grammarDefiniteArticle_ASC',
  GrammarDefiniteArticleDesc = 'grammarDefiniteArticle_DESC',
  ShowInCategoryListAsc = 'showInCategoryList_ASC',
  ShowInCategoryListDesc = 'showInCategoryList_DESC',
  SlugAsc = 'slug_ASC',
  SlugDesc = 'slug_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC',
}

export type DestinationsSubcategoriesQuestionsCollection = {
  __typename?: 'DestinationsSubcategoriesQuestionsCollection'
  items: Array<Maybe<FaqsQuestions>>
  limit: Scalars['Int']
  skip: Scalars['Int']
  total: Scalars['Int']
}

export enum DestinationsSubcategoriesQuestionsCollectionOrder {
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC',
}

export type DestinationsSubcategoriesRelatedDestinationsCollection = {
  __typename?: 'DestinationsSubcategoriesRelatedDestinationsCollection'
  items: Array<Maybe<DestinationsSubcategories>>
  limit: Scalars['Int']
  skip: Scalars['Int']
  total: Scalars['Int']
}

export enum DestinationsSubcategoriesRelatedDestinationsCollectionOrder {
  GenerateLandingPageAsc = 'generateLandingPage_ASC',
  GenerateLandingPageDesc = 'generateLandingPage_DESC',
  GenerateTopHotelsPageAsc = 'generateTopHotelsPage_ASC',
  GenerateTopHotelsPageDesc = 'generateTopHotelsPage_DESC',
  GrammarDefiniteArticleAsc = 'grammarDefiniteArticle_ASC',
  GrammarDefiniteArticleDesc = 'grammarDefiniteArticle_DESC',
  ShowInCategoryListAsc = 'showInCategoryList_ASC',
  ShowInCategoryListDesc = 'showInCategoryList_DESC',
  SlugAsc = 'slug_ASC',
  SlugDesc = 'slug_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC',
}

export type DestinationsSubcategoriesTeaserCopy = {
  __typename?: 'DestinationsSubcategoriesTeaserCopy'
  json: Scalars['JSON']
  links: DestinationsSubcategoriesTeaserCopyLinks
}

export type DestinationsSubcategoriesTeaserCopyAssets = {
  __typename?: 'DestinationsSubcategoriesTeaserCopyAssets'
  block: Array<Maybe<Asset>>
  hyperlink: Array<Maybe<Asset>>
}

export type DestinationsSubcategoriesTeaserCopyEntries = {
  __typename?: 'DestinationsSubcategoriesTeaserCopyEntries'
  block: Array<Maybe<Entry>>
  hyperlink: Array<Maybe<Entry>>
  inline: Array<Maybe<Entry>>
}

export type DestinationsSubcategoriesTeaserCopyLinks = {
  __typename?: 'DestinationsSubcategoriesTeaserCopyLinks'
  assets: DestinationsSubcategoriesTeaserCopyAssets
  entries: DestinationsSubcategoriesTeaserCopyEntries
  resources: DestinationsSubcategoriesTeaserCopyResources
}

export type DestinationsSubcategoriesTeaserCopyResources = {
  __typename?: 'DestinationsSubcategoriesTeaserCopyResources'
  block: Array<DestinationsSubcategoriesTeaserCopyResourcesBlock>
  hyperlink: Array<DestinationsSubcategoriesTeaserCopyResourcesHyperlink>
  inline: Array<DestinationsSubcategoriesTeaserCopyResourcesInline>
}

export type DestinationsSubcategoriesTeaserCopyResourcesBlock = ResourceLink & {
  __typename?: 'DestinationsSubcategoriesTeaserCopyResourcesBlock'
  sys: ResourceSys
}

export type DestinationsSubcategoriesTeaserCopyResourcesHyperlink =
  ResourceLink & {
    __typename?: 'DestinationsSubcategoriesTeaserCopyResourcesHyperlink'
    sys: ResourceSys
  }

export type DestinationsSubcategoriesTeaserCopyResourcesInline =
  ResourceLink & {
    __typename?: 'DestinationsSubcategoriesTeaserCopyResourcesInline'
    sys: ResourceSys
  }

export type Entry = {
  contentfulMetadata: ContentfulMetadata
  sys: Sys
}

export type EntryCollection = {
  __typename?: 'EntryCollection'
  items: Array<Maybe<Entry>>
  limit: Scalars['Int']
  skip: Scalars['Int']
  total: Scalars['Int']
}

export type EntryFilter = {
  AND?: InputMaybe<Array<InputMaybe<EntryFilter>>>
  OR?: InputMaybe<Array<InputMaybe<EntryFilter>>>
  contentfulMetadata?: InputMaybe<ContentfulMetadataFilter>
  sys?: InputMaybe<SysFilter>
}

export enum EntryOrder {
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
}

/** [See type definition](https://app.contentful.com/spaces/9cxgygtadmvl/content_types/entryPartner) */
export type EntryPartner = Entry &
  _Node & {
    __typename?: 'EntryPartner'
    _id: Scalars['ID']
    benefits?: Maybe<EntryPartnerBenefits>
    cardCopy?: Maybe<EntryPartnerCardCopy>
    contentfulMetadata: ContentfulMetadata
    detailSummary?: Maybe<Scalars['String']>
    featured?: Maybe<Scalars['Boolean']>
    featuredArticlesCollection?: Maybe<EntryPartnerFeaturedArticlesCollection>
    featuredPropertiesCollection?: Maybe<EntryPartnerFeaturedPropertiesCollection>
    hasDetailPage?: Maybe<Scalars['Boolean']>
    headline?: Maybe<EntryPartnerHeadline>
    hideFromPartnerGrid?: Maybe<Scalars['Boolean']>
    howToBookSection?: Maybe<ComponentThreeIconSection>
    introSection?: Maybe<PagesTwoColumnCta>
    linkedFrom?: Maybe<EntryPartnerLinkingCollections>
    logo?: Maybe<Asset>
    mainImage?: Maybe<Asset>
    metaContent?: Maybe<PagesMetaContent>
    name?: Maybe<Scalars['String']>
    partnerCategory?: Maybe<Scalars['String']>
    partnerType?: Maybe<Scalars['String']>
    perksSection?: Maybe<ComponentThreeIconSection>
    questionGroup?: Maybe<FaqsQuestionGroups>
    relatedPartnersCollection?: Maybe<EntryPartnerRelatedPartnersCollection>
    slug?: Maybe<Scalars['String']>
    sys: Sys
  }

/** [See type definition](https://app.contentful.com/spaces/9cxgygtadmvl/content_types/entryPartner) */
export type EntryPartnerBenefitsArgs = {
  locale?: InputMaybe<Scalars['String']>
}

/** [See type definition](https://app.contentful.com/spaces/9cxgygtadmvl/content_types/entryPartner) */
export type EntryPartnerCardCopyArgs = {
  locale?: InputMaybe<Scalars['String']>
}

/** [See type definition](https://app.contentful.com/spaces/9cxgygtadmvl/content_types/entryPartner) */
export type EntryPartnerDetailSummaryArgs = {
  locale?: InputMaybe<Scalars['String']>
}

/** [See type definition](https://app.contentful.com/spaces/9cxgygtadmvl/content_types/entryPartner) */
export type EntryPartnerFeaturedArgs = {
  locale?: InputMaybe<Scalars['String']>
}

/** [See type definition](https://app.contentful.com/spaces/9cxgygtadmvl/content_types/entryPartner) */
export type EntryPartnerFeaturedArticlesCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>
  locale?: InputMaybe<Scalars['String']>
  order?: InputMaybe<
    Array<InputMaybe<EntryPartnerFeaturedArticlesCollectionOrder>>
  >
  preview?: InputMaybe<Scalars['Boolean']>
  skip?: InputMaybe<Scalars['Int']>
  where?: InputMaybe<BlogPostsFilter>
}

/** [See type definition](https://app.contentful.com/spaces/9cxgygtadmvl/content_types/entryPartner) */
export type EntryPartnerFeaturedPropertiesCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>
  locale?: InputMaybe<Scalars['String']>
  order?: InputMaybe<
    Array<InputMaybe<EntryPartnerFeaturedPropertiesCollectionOrder>>
  >
  preview?: InputMaybe<Scalars['Boolean']>
  skip?: InputMaybe<Scalars['Int']>
  where?: InputMaybe<HotelsFilter>
}

/** [See type definition](https://app.contentful.com/spaces/9cxgygtadmvl/content_types/entryPartner) */
export type EntryPartnerHasDetailPageArgs = {
  locale?: InputMaybe<Scalars['String']>
}

/** [See type definition](https://app.contentful.com/spaces/9cxgygtadmvl/content_types/entryPartner) */
export type EntryPartnerHeadlineArgs = {
  locale?: InputMaybe<Scalars['String']>
}

/** [See type definition](https://app.contentful.com/spaces/9cxgygtadmvl/content_types/entryPartner) */
export type EntryPartnerHideFromPartnerGridArgs = {
  locale?: InputMaybe<Scalars['String']>
}

/** [See type definition](https://app.contentful.com/spaces/9cxgygtadmvl/content_types/entryPartner) */
export type EntryPartnerHowToBookSectionArgs = {
  locale?: InputMaybe<Scalars['String']>
  preview?: InputMaybe<Scalars['Boolean']>
  where?: InputMaybe<ComponentThreeIconSectionFilter>
}

/** [See type definition](https://app.contentful.com/spaces/9cxgygtadmvl/content_types/entryPartner) */
export type EntryPartnerIntroSectionArgs = {
  locale?: InputMaybe<Scalars['String']>
  preview?: InputMaybe<Scalars['Boolean']>
  where?: InputMaybe<PagesTwoColumnCtaFilter>
}

/** [See type definition](https://app.contentful.com/spaces/9cxgygtadmvl/content_types/entryPartner) */
export type EntryPartnerLinkedFromArgs = {
  allowedLocales?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
}

/** [See type definition](https://app.contentful.com/spaces/9cxgygtadmvl/content_types/entryPartner) */
export type EntryPartnerLogoArgs = {
  locale?: InputMaybe<Scalars['String']>
  preview?: InputMaybe<Scalars['Boolean']>
}

/** [See type definition](https://app.contentful.com/spaces/9cxgygtadmvl/content_types/entryPartner) */
export type EntryPartnerMainImageArgs = {
  locale?: InputMaybe<Scalars['String']>
  preview?: InputMaybe<Scalars['Boolean']>
}

/** [See type definition](https://app.contentful.com/spaces/9cxgygtadmvl/content_types/entryPartner) */
export type EntryPartnerMetaContentArgs = {
  locale?: InputMaybe<Scalars['String']>
  preview?: InputMaybe<Scalars['Boolean']>
  where?: InputMaybe<PagesMetaContentFilter>
}

/** [See type definition](https://app.contentful.com/spaces/9cxgygtadmvl/content_types/entryPartner) */
export type EntryPartnerNameArgs = {
  locale?: InputMaybe<Scalars['String']>
}

/** [See type definition](https://app.contentful.com/spaces/9cxgygtadmvl/content_types/entryPartner) */
export type EntryPartnerPartnerCategoryArgs = {
  locale?: InputMaybe<Scalars['String']>
}

/** [See type definition](https://app.contentful.com/spaces/9cxgygtadmvl/content_types/entryPartner) */
export type EntryPartnerPartnerTypeArgs = {
  locale?: InputMaybe<Scalars['String']>
}

/** [See type definition](https://app.contentful.com/spaces/9cxgygtadmvl/content_types/entryPartner) */
export type EntryPartnerPerksSectionArgs = {
  locale?: InputMaybe<Scalars['String']>
  preview?: InputMaybe<Scalars['Boolean']>
  where?: InputMaybe<ComponentThreeIconSectionFilter>
}

/** [See type definition](https://app.contentful.com/spaces/9cxgygtadmvl/content_types/entryPartner) */
export type EntryPartnerQuestionGroupArgs = {
  locale?: InputMaybe<Scalars['String']>
  preview?: InputMaybe<Scalars['Boolean']>
  where?: InputMaybe<FaqsQuestionGroupsFilter>
}

/** [See type definition](https://app.contentful.com/spaces/9cxgygtadmvl/content_types/entryPartner) */
export type EntryPartnerRelatedPartnersCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>
  locale?: InputMaybe<Scalars['String']>
  order?: InputMaybe<
    Array<InputMaybe<EntryPartnerRelatedPartnersCollectionOrder>>
  >
  preview?: InputMaybe<Scalars['Boolean']>
  skip?: InputMaybe<Scalars['Int']>
  where?: InputMaybe<EntryPartnerFilter>
}

/** [See type definition](https://app.contentful.com/spaces/9cxgygtadmvl/content_types/entryPartner) */
export type EntryPartnerSlugArgs = {
  locale?: InputMaybe<Scalars['String']>
}

export type EntryPartnerBenefits = {
  __typename?: 'EntryPartnerBenefits'
  json: Scalars['JSON']
  links: EntryPartnerBenefitsLinks
}

export type EntryPartnerBenefitsAssets = {
  __typename?: 'EntryPartnerBenefitsAssets'
  block: Array<Maybe<Asset>>
  hyperlink: Array<Maybe<Asset>>
}

export type EntryPartnerBenefitsEntries = {
  __typename?: 'EntryPartnerBenefitsEntries'
  block: Array<Maybe<Entry>>
  hyperlink: Array<Maybe<Entry>>
  inline: Array<Maybe<Entry>>
}

export type EntryPartnerBenefitsLinks = {
  __typename?: 'EntryPartnerBenefitsLinks'
  assets: EntryPartnerBenefitsAssets
  entries: EntryPartnerBenefitsEntries
  resources: EntryPartnerBenefitsResources
}

export type EntryPartnerBenefitsResources = {
  __typename?: 'EntryPartnerBenefitsResources'
  block: Array<EntryPartnerBenefitsResourcesBlock>
  hyperlink: Array<EntryPartnerBenefitsResourcesHyperlink>
  inline: Array<EntryPartnerBenefitsResourcesInline>
}

export type EntryPartnerBenefitsResourcesBlock = ResourceLink & {
  __typename?: 'EntryPartnerBenefitsResourcesBlock'
  sys: ResourceSys
}

export type EntryPartnerBenefitsResourcesHyperlink = ResourceLink & {
  __typename?: 'EntryPartnerBenefitsResourcesHyperlink'
  sys: ResourceSys
}

export type EntryPartnerBenefitsResourcesInline = ResourceLink & {
  __typename?: 'EntryPartnerBenefitsResourcesInline'
  sys: ResourceSys
}

export type EntryPartnerCardCopy = {
  __typename?: 'EntryPartnerCardCopy'
  json: Scalars['JSON']
  links: EntryPartnerCardCopyLinks
}

export type EntryPartnerCardCopyAssets = {
  __typename?: 'EntryPartnerCardCopyAssets'
  block: Array<Maybe<Asset>>
  hyperlink: Array<Maybe<Asset>>
}

export type EntryPartnerCardCopyEntries = {
  __typename?: 'EntryPartnerCardCopyEntries'
  block: Array<Maybe<Entry>>
  hyperlink: Array<Maybe<Entry>>
  inline: Array<Maybe<Entry>>
}

export type EntryPartnerCardCopyLinks = {
  __typename?: 'EntryPartnerCardCopyLinks'
  assets: EntryPartnerCardCopyAssets
  entries: EntryPartnerCardCopyEntries
  resources: EntryPartnerCardCopyResources
}

export type EntryPartnerCardCopyResources = {
  __typename?: 'EntryPartnerCardCopyResources'
  block: Array<EntryPartnerCardCopyResourcesBlock>
  hyperlink: Array<EntryPartnerCardCopyResourcesHyperlink>
  inline: Array<EntryPartnerCardCopyResourcesInline>
}

export type EntryPartnerCardCopyResourcesBlock = ResourceLink & {
  __typename?: 'EntryPartnerCardCopyResourcesBlock'
  sys: ResourceSys
}

export type EntryPartnerCardCopyResourcesHyperlink = ResourceLink & {
  __typename?: 'EntryPartnerCardCopyResourcesHyperlink'
  sys: ResourceSys
}

export type EntryPartnerCardCopyResourcesInline = ResourceLink & {
  __typename?: 'EntryPartnerCardCopyResourcesInline'
  sys: ResourceSys
}

export type EntryPartnerCollection = {
  __typename?: 'EntryPartnerCollection'
  items: Array<Maybe<EntryPartner>>
  limit: Scalars['Int']
  skip: Scalars['Int']
  total: Scalars['Int']
}

export type EntryPartnerFeaturedArticlesCollection = {
  __typename?: 'EntryPartnerFeaturedArticlesCollection'
  items: Array<Maybe<BlogPosts>>
  limit: Scalars['Int']
  skip: Scalars['Int']
  total: Scalars['Int']
}

export enum EntryPartnerFeaturedArticlesCollectionOrder {
  BlogCategoryAsc = 'blogCategory_ASC',
  BlogCategoryDesc = 'blogCategory_DESC',
  BottomCtaAsc = 'bottomCta_ASC',
  BottomCtaDesc = 'bottomCta_DESC',
  EnableAdvisorShareAsc = 'enableAdvisorShare_ASC',
  EnableAdvisorShareDesc = 'enableAdvisorShare_DESC',
  MetaDescriptionAsc = 'metaDescription_ASC',
  MetaDescriptionDesc = 'metaDescription_DESC',
  MetaTitleAsc = 'metaTitle_ASC',
  MetaTitleDesc = 'metaTitle_DESC',
  NoindexNofollowAsc = 'noindexNofollow_ASC',
  NoindexNofollowDesc = 'noindexNofollow_DESC',
  PublishedDateAsc = 'publishedDate_ASC',
  PublishedDateDesc = 'publishedDate_DESC',
  ShowOnBlogCategoryPageAsc = 'showOnBlogCategoryPage_ASC',
  ShowOnBlogCategoryPageDesc = 'showOnBlogCategoryPage_DESC',
  ShowOnBlogLandingPageAsc = 'showOnBlogLandingPage_ASC',
  ShowOnBlogLandingPageDesc = 'showOnBlogLandingPage_DESC',
  ShowOnTravelPagesAsc = 'showOnTravelPages_ASC',
  ShowOnTravelPagesDesc = 'showOnTravelPages_DESC',
  SlugAsc = 'slug_ASC',
  SlugDesc = 'slug_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC',
}

export type EntryPartnerFeaturedPropertiesCollection = {
  __typename?: 'EntryPartnerFeaturedPropertiesCollection'
  items: Array<Maybe<Hotels>>
  limit: Scalars['Int']
  skip: Scalars['Int']
  total: Scalars['Int']
}

export enum EntryPartnerFeaturedPropertiesCollectionOrder {
  ArchivedAsc = 'archived_ASC',
  ArchivedDesc = 'archived_DESC',
  ForaSupplierIdAsc = 'foraSupplierId_ASC',
  ForaSupplierIdDesc = 'foraSupplierId_DESC',
  GenerateLandingPageAsc = 'generateLandingPage_ASC',
  GenerateLandingPageDesc = 'generateLandingPage_DESC',
  HeroImageAsc = 'heroImage_ASC',
  HeroImageDesc = 'heroImage_DESC',
  HideFromTopHotelsPagesAsc = 'hideFromTopHotelsPages_ASC',
  HideFromTopHotelsPagesDesc = 'hideFromTopHotelsPages_DESC',
  MichelinKeysAsc = 'michelinKeys_ASC',
  MichelinKeysDesc = 'michelinKeys_DESC',
  SlugAsc = 'slug_ASC',
  SlugDesc = 'slug_DESC',
  StarRatingAsc = 'starRating_ASC',
  StarRatingDesc = 'starRating_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC',
}

export type EntryPartnerFilter = {
  AND?: InputMaybe<Array<InputMaybe<EntryPartnerFilter>>>
  OR?: InputMaybe<Array<InputMaybe<EntryPartnerFilter>>>
  benefits_contains?: InputMaybe<Scalars['String']>
  benefits_exists?: InputMaybe<Scalars['Boolean']>
  benefits_not_contains?: InputMaybe<Scalars['String']>
  cardCopy_contains?: InputMaybe<Scalars['String']>
  cardCopy_exists?: InputMaybe<Scalars['Boolean']>
  cardCopy_not_contains?: InputMaybe<Scalars['String']>
  contentfulMetadata?: InputMaybe<ContentfulMetadataFilter>
  detailSummary?: InputMaybe<Scalars['String']>
  detailSummary_contains?: InputMaybe<Scalars['String']>
  detailSummary_exists?: InputMaybe<Scalars['Boolean']>
  detailSummary_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  detailSummary_not?: InputMaybe<Scalars['String']>
  detailSummary_not_contains?: InputMaybe<Scalars['String']>
  detailSummary_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  featured?: InputMaybe<Scalars['Boolean']>
  featuredArticles?: InputMaybe<CfBlogPostsNestedFilter>
  featuredArticlesCollection_exists?: InputMaybe<Scalars['Boolean']>
  featuredProperties?: InputMaybe<CfHotelsNestedFilter>
  featuredPropertiesCollection_exists?: InputMaybe<Scalars['Boolean']>
  featured_exists?: InputMaybe<Scalars['Boolean']>
  featured_not?: InputMaybe<Scalars['Boolean']>
  hasDetailPage?: InputMaybe<Scalars['Boolean']>
  hasDetailPage_exists?: InputMaybe<Scalars['Boolean']>
  hasDetailPage_not?: InputMaybe<Scalars['Boolean']>
  headline_contains?: InputMaybe<Scalars['String']>
  headline_exists?: InputMaybe<Scalars['Boolean']>
  headline_not_contains?: InputMaybe<Scalars['String']>
  hideFromPartnerGrid?: InputMaybe<Scalars['Boolean']>
  hideFromPartnerGrid_exists?: InputMaybe<Scalars['Boolean']>
  hideFromPartnerGrid_not?: InputMaybe<Scalars['Boolean']>
  howToBookSection?: InputMaybe<CfComponentThreeIconSectionNestedFilter>
  howToBookSection_exists?: InputMaybe<Scalars['Boolean']>
  introSection?: InputMaybe<CfPagesTwoColumnCtaNestedFilter>
  introSection_exists?: InputMaybe<Scalars['Boolean']>
  logo_exists?: InputMaybe<Scalars['Boolean']>
  mainImage_exists?: InputMaybe<Scalars['Boolean']>
  metaContent?: InputMaybe<CfPagesMetaContentNestedFilter>
  metaContent_exists?: InputMaybe<Scalars['Boolean']>
  name?: InputMaybe<Scalars['String']>
  name_contains?: InputMaybe<Scalars['String']>
  name_exists?: InputMaybe<Scalars['Boolean']>
  name_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  name_not?: InputMaybe<Scalars['String']>
  name_not_contains?: InputMaybe<Scalars['String']>
  name_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  partnerCategory?: InputMaybe<Scalars['String']>
  partnerCategory_contains?: InputMaybe<Scalars['String']>
  partnerCategory_exists?: InputMaybe<Scalars['Boolean']>
  partnerCategory_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  partnerCategory_not?: InputMaybe<Scalars['String']>
  partnerCategory_not_contains?: InputMaybe<Scalars['String']>
  partnerCategory_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  partnerType?: InputMaybe<Scalars['String']>
  partnerType_contains?: InputMaybe<Scalars['String']>
  partnerType_exists?: InputMaybe<Scalars['Boolean']>
  partnerType_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  partnerType_not?: InputMaybe<Scalars['String']>
  partnerType_not_contains?: InputMaybe<Scalars['String']>
  partnerType_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  perksSection?: InputMaybe<CfComponentThreeIconSectionNestedFilter>
  perksSection_exists?: InputMaybe<Scalars['Boolean']>
  questionGroup?: InputMaybe<CfFaqsQuestionGroupsNestedFilter>
  questionGroup_exists?: InputMaybe<Scalars['Boolean']>
  relatedPartners?: InputMaybe<CfEntryPartnerNestedFilter>
  relatedPartnersCollection_exists?: InputMaybe<Scalars['Boolean']>
  slug?: InputMaybe<Scalars['String']>
  slug_contains?: InputMaybe<Scalars['String']>
  slug_exists?: InputMaybe<Scalars['Boolean']>
  slug_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  slug_not?: InputMaybe<Scalars['String']>
  slug_not_contains?: InputMaybe<Scalars['String']>
  slug_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  sys?: InputMaybe<SysFilter>
}

export type EntryPartnerHeadline = {
  __typename?: 'EntryPartnerHeadline'
  json: Scalars['JSON']
  links: EntryPartnerHeadlineLinks
}

export type EntryPartnerHeadlineAssets = {
  __typename?: 'EntryPartnerHeadlineAssets'
  block: Array<Maybe<Asset>>
  hyperlink: Array<Maybe<Asset>>
}

export type EntryPartnerHeadlineEntries = {
  __typename?: 'EntryPartnerHeadlineEntries'
  block: Array<Maybe<Entry>>
  hyperlink: Array<Maybe<Entry>>
  inline: Array<Maybe<Entry>>
}

export type EntryPartnerHeadlineLinks = {
  __typename?: 'EntryPartnerHeadlineLinks'
  assets: EntryPartnerHeadlineAssets
  entries: EntryPartnerHeadlineEntries
  resources: EntryPartnerHeadlineResources
}

export type EntryPartnerHeadlineResources = {
  __typename?: 'EntryPartnerHeadlineResources'
  block: Array<EntryPartnerHeadlineResourcesBlock>
  hyperlink: Array<EntryPartnerHeadlineResourcesHyperlink>
  inline: Array<EntryPartnerHeadlineResourcesInline>
}

export type EntryPartnerHeadlineResourcesBlock = ResourceLink & {
  __typename?: 'EntryPartnerHeadlineResourcesBlock'
  sys: ResourceSys
}

export type EntryPartnerHeadlineResourcesHyperlink = ResourceLink & {
  __typename?: 'EntryPartnerHeadlineResourcesHyperlink'
  sys: ResourceSys
}

export type EntryPartnerHeadlineResourcesInline = ResourceLink & {
  __typename?: 'EntryPartnerHeadlineResourcesInline'
  sys: ResourceSys
}

export type EntryPartnerLinkingCollections = {
  __typename?: 'EntryPartnerLinkingCollections'
  componentPartnerGridCollection?: Maybe<ComponentPartnerGridCollection>
  entryCollection?: Maybe<EntryCollection>
  entryPartnerCollection?: Maybe<EntryPartnerCollection>
  hotelsCollection?: Maybe<HotelsCollection>
}

export type EntryPartnerLinkingCollectionsComponentPartnerGridCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>
  locale?: InputMaybe<Scalars['String']>
  order?: InputMaybe<
    Array<
      InputMaybe<EntryPartnerLinkingCollectionsComponentPartnerGridCollectionOrder>
    >
  >
  preview?: InputMaybe<Scalars['Boolean']>
  skip?: InputMaybe<Scalars['Int']>
}

export type EntryPartnerLinkingCollectionsEntryCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>
  locale?: InputMaybe<Scalars['String']>
  preview?: InputMaybe<Scalars['Boolean']>
  skip?: InputMaybe<Scalars['Int']>
}

export type EntryPartnerLinkingCollectionsEntryPartnerCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>
  locale?: InputMaybe<Scalars['String']>
  order?: InputMaybe<
    Array<InputMaybe<EntryPartnerLinkingCollectionsEntryPartnerCollectionOrder>>
  >
  preview?: InputMaybe<Scalars['Boolean']>
  skip?: InputMaybe<Scalars['Int']>
}

export type EntryPartnerLinkingCollectionsHotelsCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>
  locale?: InputMaybe<Scalars['String']>
  order?: InputMaybe<
    Array<InputMaybe<EntryPartnerLinkingCollectionsHotelsCollectionOrder>>
  >
  preview?: InputMaybe<Scalars['Boolean']>
  skip?: InputMaybe<Scalars['Int']>
}

export enum EntryPartnerLinkingCollectionsComponentPartnerGridCollectionOrder {
  HeadingSharedStateAsc = 'headingSharedState_ASC',
  HeadingSharedStateDesc = 'headingSharedState_DESC',
  HeadingAsc = 'heading_ASC',
  HeadingDesc = 'heading_DESC',
  InternalLabelAsc = 'internalLabel_ASC',
  InternalLabelDesc = 'internalLabel_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  TitleStyleAsc = 'titleStyle_ASC',
  TitleStyleDesc = 'titleStyle_DESC',
}

export enum EntryPartnerLinkingCollectionsEntryPartnerCollectionOrder {
  DetailSummaryAsc = 'detailSummary_ASC',
  DetailSummaryDesc = 'detailSummary_DESC',
  FeaturedAsc = 'featured_ASC',
  FeaturedDesc = 'featured_DESC',
  HasDetailPageAsc = 'hasDetailPage_ASC',
  HasDetailPageDesc = 'hasDetailPage_DESC',
  HideFromPartnerGridAsc = 'hideFromPartnerGrid_ASC',
  HideFromPartnerGridDesc = 'hideFromPartnerGrid_DESC',
  NameAsc = 'name_ASC',
  NameDesc = 'name_DESC',
  PartnerCategoryAsc = 'partnerCategory_ASC',
  PartnerCategoryDesc = 'partnerCategory_DESC',
  PartnerTypeAsc = 'partnerType_ASC',
  PartnerTypeDesc = 'partnerType_DESC',
  SlugAsc = 'slug_ASC',
  SlugDesc = 'slug_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
}

export enum EntryPartnerLinkingCollectionsHotelsCollectionOrder {
  ArchivedAsc = 'archived_ASC',
  ArchivedDesc = 'archived_DESC',
  ForaSupplierIdAsc = 'foraSupplierId_ASC',
  ForaSupplierIdDesc = 'foraSupplierId_DESC',
  GenerateLandingPageAsc = 'generateLandingPage_ASC',
  GenerateLandingPageDesc = 'generateLandingPage_DESC',
  HeroImageAsc = 'heroImage_ASC',
  HeroImageDesc = 'heroImage_DESC',
  HideFromTopHotelsPagesAsc = 'hideFromTopHotelsPages_ASC',
  HideFromTopHotelsPagesDesc = 'hideFromTopHotelsPages_DESC',
  MichelinKeysAsc = 'michelinKeys_ASC',
  MichelinKeysDesc = 'michelinKeys_DESC',
  SlugAsc = 'slug_ASC',
  SlugDesc = 'slug_DESC',
  StarRatingAsc = 'starRating_ASC',
  StarRatingDesc = 'starRating_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC',
}

export enum EntryPartnerOrder {
  DetailSummaryAsc = 'detailSummary_ASC',
  DetailSummaryDesc = 'detailSummary_DESC',
  FeaturedAsc = 'featured_ASC',
  FeaturedDesc = 'featured_DESC',
  HasDetailPageAsc = 'hasDetailPage_ASC',
  HasDetailPageDesc = 'hasDetailPage_DESC',
  HideFromPartnerGridAsc = 'hideFromPartnerGrid_ASC',
  HideFromPartnerGridDesc = 'hideFromPartnerGrid_DESC',
  NameAsc = 'name_ASC',
  NameDesc = 'name_DESC',
  PartnerCategoryAsc = 'partnerCategory_ASC',
  PartnerCategoryDesc = 'partnerCategory_DESC',
  PartnerTypeAsc = 'partnerType_ASC',
  PartnerTypeDesc = 'partnerType_DESC',
  SlugAsc = 'slug_ASC',
  SlugDesc = 'slug_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
}

export type EntryPartnerRelatedPartnersCollection = {
  __typename?: 'EntryPartnerRelatedPartnersCollection'
  items: Array<Maybe<EntryPartner>>
  limit: Scalars['Int']
  skip: Scalars['Int']
  total: Scalars['Int']
}

export enum EntryPartnerRelatedPartnersCollectionOrder {
  DetailSummaryAsc = 'detailSummary_ASC',
  DetailSummaryDesc = 'detailSummary_DESC',
  FeaturedAsc = 'featured_ASC',
  FeaturedDesc = 'featured_DESC',
  HasDetailPageAsc = 'hasDetailPage_ASC',
  HasDetailPageDesc = 'hasDetailPage_DESC',
  HideFromPartnerGridAsc = 'hideFromPartnerGrid_ASC',
  HideFromPartnerGridDesc = 'hideFromPartnerGrid_DESC',
  NameAsc = 'name_ASC',
  NameDesc = 'name_DESC',
  PartnerCategoryAsc = 'partnerCategory_ASC',
  PartnerCategoryDesc = 'partnerCategory_DESC',
  PartnerTypeAsc = 'partnerType_ASC',
  PartnerTypeDesc = 'partnerType_DESC',
  SlugAsc = 'slug_ASC',
  SlugDesc = 'slug_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
}

/** Testimonials are traveler feedback items that are related to advisors, hotels, or Fora in general [See type definition](https://app.contentful.com/spaces/9cxgygtadmvl/content_types/entryTestimonial) */
export type EntryTestimonial = Entry &
  _Node & {
    __typename?: 'EntryTestimonial'
    _id: Scalars['ID']
    aboutFora?: Maybe<Scalars['Boolean']>
    advisor?: Maybe<Advisors>
    beginTravelDate?: Maybe<Scalars['DateTime']>
    categoriesCollection?: Maybe<EntryTestimonialCategoriesCollection>
    contentfulMetadata: ContentfulMetadata
    dateSubmitted?: Maybe<Scalars['DateTime']>
    destinationsSummary?: Maybe<EntryTestimonialDestinationsSummary>
    display?: Maybe<Scalars['Boolean']>
    endTravelDate?: Maybe<Scalars['DateTime']>
    hotel?: Maybe<Hotels>
    hotelsCollection?: Maybe<EntryTestimonialHotelsCollection>
    internalLabel?: Maybe<Scalars['String']>
    isFeatured?: Maybe<Scalars['Boolean']>
    linkedFrom?: Maybe<EntryTestimonialLinkingCollections>
    relatedGuide?: Maybe<Itineraries>
    stars?: Maybe<Scalars['Int']>
    submittedTestimonial?: Maybe<Scalars['String']>
    submitterEmail?: Maybe<Scalars['String']>
    submitterFirstName?: Maybe<Scalars['String']>
    submitterImage?: Maybe<Asset>
    submitterLastName?: Maybe<Scalars['String']>
    sys: Sys
    testimonial?: Maybe<EntryTestimonialTestimonial>
    testimonialSource?: Maybe<Scalars['String']>
    titleOfReview?: Maybe<Scalars['String']>
  }

/** Testimonials are traveler feedback items that are related to advisors, hotels, or Fora in general [See type definition](https://app.contentful.com/spaces/9cxgygtadmvl/content_types/entryTestimonial) */
export type EntryTestimonialAboutForaArgs = {
  locale?: InputMaybe<Scalars['String']>
}

/** Testimonials are traveler feedback items that are related to advisors, hotels, or Fora in general [See type definition](https://app.contentful.com/spaces/9cxgygtadmvl/content_types/entryTestimonial) */
export type EntryTestimonialAdvisorArgs = {
  locale?: InputMaybe<Scalars['String']>
  preview?: InputMaybe<Scalars['Boolean']>
  where?: InputMaybe<AdvisorsFilter>
}

/** Testimonials are traveler feedback items that are related to advisors, hotels, or Fora in general [See type definition](https://app.contentful.com/spaces/9cxgygtadmvl/content_types/entryTestimonial) */
export type EntryTestimonialBeginTravelDateArgs = {
  locale?: InputMaybe<Scalars['String']>
}

/** Testimonials are traveler feedback items that are related to advisors, hotels, or Fora in general [See type definition](https://app.contentful.com/spaces/9cxgygtadmvl/content_types/entryTestimonial) */
export type EntryTestimonialCategoriesCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>
  locale?: InputMaybe<Scalars['String']>
  preview?: InputMaybe<Scalars['Boolean']>
  skip?: InputMaybe<Scalars['Int']>
  where?: InputMaybe<EntryTestimonialCategoriesFilter>
}

/** Testimonials are traveler feedback items that are related to advisors, hotels, or Fora in general [See type definition](https://app.contentful.com/spaces/9cxgygtadmvl/content_types/entryTestimonial) */
export type EntryTestimonialDateSubmittedArgs = {
  locale?: InputMaybe<Scalars['String']>
}

/** Testimonials are traveler feedback items that are related to advisors, hotels, or Fora in general [See type definition](https://app.contentful.com/spaces/9cxgygtadmvl/content_types/entryTestimonial) */
export type EntryTestimonialDestinationsSummaryArgs = {
  locale?: InputMaybe<Scalars['String']>
}

/** Testimonials are traveler feedback items that are related to advisors, hotels, or Fora in general [See type definition](https://app.contentful.com/spaces/9cxgygtadmvl/content_types/entryTestimonial) */
export type EntryTestimonialDisplayArgs = {
  locale?: InputMaybe<Scalars['String']>
}

/** Testimonials are traveler feedback items that are related to advisors, hotels, or Fora in general [See type definition](https://app.contentful.com/spaces/9cxgygtadmvl/content_types/entryTestimonial) */
export type EntryTestimonialEndTravelDateArgs = {
  locale?: InputMaybe<Scalars['String']>
}

/** Testimonials are traveler feedback items that are related to advisors, hotels, or Fora in general [See type definition](https://app.contentful.com/spaces/9cxgygtadmvl/content_types/entryTestimonial) */
export type EntryTestimonialHotelArgs = {
  locale?: InputMaybe<Scalars['String']>
  preview?: InputMaybe<Scalars['Boolean']>
  where?: InputMaybe<HotelsFilter>
}

/** Testimonials are traveler feedback items that are related to advisors, hotels, or Fora in general [See type definition](https://app.contentful.com/spaces/9cxgygtadmvl/content_types/entryTestimonial) */
export type EntryTestimonialHotelsCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>
  locale?: InputMaybe<Scalars['String']>
  order?: InputMaybe<Array<InputMaybe<EntryTestimonialHotelsCollectionOrder>>>
  preview?: InputMaybe<Scalars['Boolean']>
  skip?: InputMaybe<Scalars['Int']>
  where?: InputMaybe<HotelsFilter>
}

/** Testimonials are traveler feedback items that are related to advisors, hotels, or Fora in general [See type definition](https://app.contentful.com/spaces/9cxgygtadmvl/content_types/entryTestimonial) */
export type EntryTestimonialInternalLabelArgs = {
  locale?: InputMaybe<Scalars['String']>
}

/** Testimonials are traveler feedback items that are related to advisors, hotels, or Fora in general [See type definition](https://app.contentful.com/spaces/9cxgygtadmvl/content_types/entryTestimonial) */
export type EntryTestimonialIsFeaturedArgs = {
  locale?: InputMaybe<Scalars['String']>
}

/** Testimonials are traveler feedback items that are related to advisors, hotels, or Fora in general [See type definition](https://app.contentful.com/spaces/9cxgygtadmvl/content_types/entryTestimonial) */
export type EntryTestimonialLinkedFromArgs = {
  allowedLocales?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
}

/** Testimonials are traveler feedback items that are related to advisors, hotels, or Fora in general [See type definition](https://app.contentful.com/spaces/9cxgygtadmvl/content_types/entryTestimonial) */
export type EntryTestimonialRelatedGuideArgs = {
  locale?: InputMaybe<Scalars['String']>
  preview?: InputMaybe<Scalars['Boolean']>
  where?: InputMaybe<ItinerariesFilter>
}

/** Testimonials are traveler feedback items that are related to advisors, hotels, or Fora in general [See type definition](https://app.contentful.com/spaces/9cxgygtadmvl/content_types/entryTestimonial) */
export type EntryTestimonialStarsArgs = {
  locale?: InputMaybe<Scalars['String']>
}

/** Testimonials are traveler feedback items that are related to advisors, hotels, or Fora in general [See type definition](https://app.contentful.com/spaces/9cxgygtadmvl/content_types/entryTestimonial) */
export type EntryTestimonialSubmittedTestimonialArgs = {
  locale?: InputMaybe<Scalars['String']>
}

/** Testimonials are traveler feedback items that are related to advisors, hotels, or Fora in general [See type definition](https://app.contentful.com/spaces/9cxgygtadmvl/content_types/entryTestimonial) */
export type EntryTestimonialSubmitterEmailArgs = {
  locale?: InputMaybe<Scalars['String']>
}

/** Testimonials are traveler feedback items that are related to advisors, hotels, or Fora in general [See type definition](https://app.contentful.com/spaces/9cxgygtadmvl/content_types/entryTestimonial) */
export type EntryTestimonialSubmitterFirstNameArgs = {
  locale?: InputMaybe<Scalars['String']>
}

/** Testimonials are traveler feedback items that are related to advisors, hotels, or Fora in general [See type definition](https://app.contentful.com/spaces/9cxgygtadmvl/content_types/entryTestimonial) */
export type EntryTestimonialSubmitterImageArgs = {
  locale?: InputMaybe<Scalars['String']>
  preview?: InputMaybe<Scalars['Boolean']>
}

/** Testimonials are traveler feedback items that are related to advisors, hotels, or Fora in general [See type definition](https://app.contentful.com/spaces/9cxgygtadmvl/content_types/entryTestimonial) */
export type EntryTestimonialSubmitterLastNameArgs = {
  locale?: InputMaybe<Scalars['String']>
}

/** Testimonials are traveler feedback items that are related to advisors, hotels, or Fora in general [See type definition](https://app.contentful.com/spaces/9cxgygtadmvl/content_types/entryTestimonial) */
export type EntryTestimonialTestimonialArgs = {
  locale?: InputMaybe<Scalars['String']>
}

/** Testimonials are traveler feedback items that are related to advisors, hotels, or Fora in general [See type definition](https://app.contentful.com/spaces/9cxgygtadmvl/content_types/entryTestimonial) */
export type EntryTestimonialTestimonialSourceArgs = {
  locale?: InputMaybe<Scalars['String']>
}

/** Testimonials are traveler feedback items that are related to advisors, hotels, or Fora in general [See type definition](https://app.contentful.com/spaces/9cxgygtadmvl/content_types/entryTestimonial) */
export type EntryTestimonialTitleOfReviewArgs = {
  locale?: InputMaybe<Scalars['String']>
}

export type EntryTestimonialCategoriesCollection = {
  __typename?: 'EntryTestimonialCategoriesCollection'
  items: Array<Maybe<EntryTestimonialCategoriesItem>>
  limit: Scalars['Int']
  skip: Scalars['Int']
  total: Scalars['Int']
}

export type EntryTestimonialCategoriesFilter = {
  AND?: InputMaybe<Array<InputMaybe<EntryTestimonialCategoriesFilter>>>
  OR?: InputMaybe<Array<InputMaybe<EntryTestimonialCategoriesFilter>>>
  contentfulMetadata?: InputMaybe<ContentfulMetadataFilter>
  featuredImage_exists?: InputMaybe<Scalars['Boolean']>
  slug?: InputMaybe<Scalars['String']>
  slug_contains?: InputMaybe<Scalars['String']>
  slug_exists?: InputMaybe<Scalars['Boolean']>
  slug_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  slug_not?: InputMaybe<Scalars['String']>
  slug_not_contains?: InputMaybe<Scalars['String']>
  slug_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  sys?: InputMaybe<SysFilter>
  title?: InputMaybe<Scalars['String']>
  title_contains?: InputMaybe<Scalars['String']>
  title_exists?: InputMaybe<Scalars['Boolean']>
  title_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  title_not?: InputMaybe<Scalars['String']>
  title_not_contains?: InputMaybe<Scalars['String']>
  title_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
}

export type EntryTestimonialCategoriesItem =
  | DestinationsCategories
  | DestinationsSubcategories
  | TravelByCategories
  | TravelBySubcategories

export type EntryTestimonialCollection = {
  __typename?: 'EntryTestimonialCollection'
  items: Array<Maybe<EntryTestimonial>>
  limit: Scalars['Int']
  skip: Scalars['Int']
  total: Scalars['Int']
}

export type EntryTestimonialDestinationsSummary = {
  __typename?: 'EntryTestimonialDestinationsSummary'
  json: Scalars['JSON']
  links: EntryTestimonialDestinationsSummaryLinks
}

export type EntryTestimonialDestinationsSummaryAssets = {
  __typename?: 'EntryTestimonialDestinationsSummaryAssets'
  block: Array<Maybe<Asset>>
  hyperlink: Array<Maybe<Asset>>
}

export type EntryTestimonialDestinationsSummaryEntries = {
  __typename?: 'EntryTestimonialDestinationsSummaryEntries'
  block: Array<Maybe<Entry>>
  hyperlink: Array<Maybe<Entry>>
  inline: Array<Maybe<Entry>>
}

export type EntryTestimonialDestinationsSummaryLinks = {
  __typename?: 'EntryTestimonialDestinationsSummaryLinks'
  assets: EntryTestimonialDestinationsSummaryAssets
  entries: EntryTestimonialDestinationsSummaryEntries
  resources: EntryTestimonialDestinationsSummaryResources
}

export type EntryTestimonialDestinationsSummaryResources = {
  __typename?: 'EntryTestimonialDestinationsSummaryResources'
  block: Array<EntryTestimonialDestinationsSummaryResourcesBlock>
  hyperlink: Array<EntryTestimonialDestinationsSummaryResourcesHyperlink>
  inline: Array<EntryTestimonialDestinationsSummaryResourcesInline>
}

export type EntryTestimonialDestinationsSummaryResourcesBlock = ResourceLink & {
  __typename?: 'EntryTestimonialDestinationsSummaryResourcesBlock'
  sys: ResourceSys
}

export type EntryTestimonialDestinationsSummaryResourcesHyperlink =
  ResourceLink & {
    __typename?: 'EntryTestimonialDestinationsSummaryResourcesHyperlink'
    sys: ResourceSys
  }

export type EntryTestimonialDestinationsSummaryResourcesInline =
  ResourceLink & {
    __typename?: 'EntryTestimonialDestinationsSummaryResourcesInline'
    sys: ResourceSys
  }

export type EntryTestimonialFilter = {
  AND?: InputMaybe<Array<InputMaybe<EntryTestimonialFilter>>>
  OR?: InputMaybe<Array<InputMaybe<EntryTestimonialFilter>>>
  aboutFora?: InputMaybe<Scalars['Boolean']>
  aboutFora_exists?: InputMaybe<Scalars['Boolean']>
  aboutFora_not?: InputMaybe<Scalars['Boolean']>
  advisor?: InputMaybe<CfAdvisorsNestedFilter>
  advisor_exists?: InputMaybe<Scalars['Boolean']>
  beginTravelDate?: InputMaybe<Scalars['DateTime']>
  beginTravelDate_exists?: InputMaybe<Scalars['Boolean']>
  beginTravelDate_gt?: InputMaybe<Scalars['DateTime']>
  beginTravelDate_gte?: InputMaybe<Scalars['DateTime']>
  beginTravelDate_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']>>>
  beginTravelDate_lt?: InputMaybe<Scalars['DateTime']>
  beginTravelDate_lte?: InputMaybe<Scalars['DateTime']>
  beginTravelDate_not?: InputMaybe<Scalars['DateTime']>
  beginTravelDate_not_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']>>>
  categories?: InputMaybe<CfcategoriesMultiTypeNestedFilter>
  categoriesCollection_exists?: InputMaybe<Scalars['Boolean']>
  contentfulMetadata?: InputMaybe<ContentfulMetadataFilter>
  dateSubmitted?: InputMaybe<Scalars['DateTime']>
  dateSubmitted_exists?: InputMaybe<Scalars['Boolean']>
  dateSubmitted_gt?: InputMaybe<Scalars['DateTime']>
  dateSubmitted_gte?: InputMaybe<Scalars['DateTime']>
  dateSubmitted_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']>>>
  dateSubmitted_lt?: InputMaybe<Scalars['DateTime']>
  dateSubmitted_lte?: InputMaybe<Scalars['DateTime']>
  dateSubmitted_not?: InputMaybe<Scalars['DateTime']>
  dateSubmitted_not_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']>>>
  destinationsSummary_contains?: InputMaybe<Scalars['String']>
  destinationsSummary_exists?: InputMaybe<Scalars['Boolean']>
  destinationsSummary_not_contains?: InputMaybe<Scalars['String']>
  display?: InputMaybe<Scalars['Boolean']>
  display_exists?: InputMaybe<Scalars['Boolean']>
  display_not?: InputMaybe<Scalars['Boolean']>
  endTravelDate?: InputMaybe<Scalars['DateTime']>
  endTravelDate_exists?: InputMaybe<Scalars['Boolean']>
  endTravelDate_gt?: InputMaybe<Scalars['DateTime']>
  endTravelDate_gte?: InputMaybe<Scalars['DateTime']>
  endTravelDate_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']>>>
  endTravelDate_lt?: InputMaybe<Scalars['DateTime']>
  endTravelDate_lte?: InputMaybe<Scalars['DateTime']>
  endTravelDate_not?: InputMaybe<Scalars['DateTime']>
  endTravelDate_not_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']>>>
  hotel?: InputMaybe<CfHotelsNestedFilter>
  hotel_exists?: InputMaybe<Scalars['Boolean']>
  hotels?: InputMaybe<CfHotelsNestedFilter>
  hotelsCollection_exists?: InputMaybe<Scalars['Boolean']>
  internalLabel?: InputMaybe<Scalars['String']>
  internalLabel_contains?: InputMaybe<Scalars['String']>
  internalLabel_exists?: InputMaybe<Scalars['Boolean']>
  internalLabel_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  internalLabel_not?: InputMaybe<Scalars['String']>
  internalLabel_not_contains?: InputMaybe<Scalars['String']>
  internalLabel_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  isFeatured?: InputMaybe<Scalars['Boolean']>
  isFeatured_exists?: InputMaybe<Scalars['Boolean']>
  isFeatured_not?: InputMaybe<Scalars['Boolean']>
  relatedGuide?: InputMaybe<CfItinerariesNestedFilter>
  relatedGuide_exists?: InputMaybe<Scalars['Boolean']>
  stars?: InputMaybe<Scalars['Int']>
  stars_exists?: InputMaybe<Scalars['Boolean']>
  stars_gt?: InputMaybe<Scalars['Int']>
  stars_gte?: InputMaybe<Scalars['Int']>
  stars_in?: InputMaybe<Array<InputMaybe<Scalars['Int']>>>
  stars_lt?: InputMaybe<Scalars['Int']>
  stars_lte?: InputMaybe<Scalars['Int']>
  stars_not?: InputMaybe<Scalars['Int']>
  stars_not_in?: InputMaybe<Array<InputMaybe<Scalars['Int']>>>
  submittedTestimonial?: InputMaybe<Scalars['String']>
  submittedTestimonial_contains?: InputMaybe<Scalars['String']>
  submittedTestimonial_exists?: InputMaybe<Scalars['Boolean']>
  submittedTestimonial_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  submittedTestimonial_not?: InputMaybe<Scalars['String']>
  submittedTestimonial_not_contains?: InputMaybe<Scalars['String']>
  submittedTestimonial_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  submitterEmail?: InputMaybe<Scalars['String']>
  submitterEmail_contains?: InputMaybe<Scalars['String']>
  submitterEmail_exists?: InputMaybe<Scalars['Boolean']>
  submitterEmail_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  submitterEmail_not?: InputMaybe<Scalars['String']>
  submitterEmail_not_contains?: InputMaybe<Scalars['String']>
  submitterEmail_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  submitterFirstName?: InputMaybe<Scalars['String']>
  submitterFirstName_contains?: InputMaybe<Scalars['String']>
  submitterFirstName_exists?: InputMaybe<Scalars['Boolean']>
  submitterFirstName_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  submitterFirstName_not?: InputMaybe<Scalars['String']>
  submitterFirstName_not_contains?: InputMaybe<Scalars['String']>
  submitterFirstName_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  submitterImage_exists?: InputMaybe<Scalars['Boolean']>
  submitterLastName?: InputMaybe<Scalars['String']>
  submitterLastName_contains?: InputMaybe<Scalars['String']>
  submitterLastName_exists?: InputMaybe<Scalars['Boolean']>
  submitterLastName_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  submitterLastName_not?: InputMaybe<Scalars['String']>
  submitterLastName_not_contains?: InputMaybe<Scalars['String']>
  submitterLastName_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  sys?: InputMaybe<SysFilter>
  testimonialSource?: InputMaybe<Scalars['String']>
  testimonialSource_contains?: InputMaybe<Scalars['String']>
  testimonialSource_exists?: InputMaybe<Scalars['Boolean']>
  testimonialSource_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  testimonialSource_not?: InputMaybe<Scalars['String']>
  testimonialSource_not_contains?: InputMaybe<Scalars['String']>
  testimonialSource_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  testimonial_contains?: InputMaybe<Scalars['String']>
  testimonial_exists?: InputMaybe<Scalars['Boolean']>
  testimonial_not_contains?: InputMaybe<Scalars['String']>
  titleOfReview?: InputMaybe<Scalars['String']>
  titleOfReview_contains?: InputMaybe<Scalars['String']>
  titleOfReview_exists?: InputMaybe<Scalars['Boolean']>
  titleOfReview_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  titleOfReview_not?: InputMaybe<Scalars['String']>
  titleOfReview_not_contains?: InputMaybe<Scalars['String']>
  titleOfReview_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
}

export type EntryTestimonialHotelsCollection = {
  __typename?: 'EntryTestimonialHotelsCollection'
  items: Array<Maybe<Hotels>>
  limit: Scalars['Int']
  skip: Scalars['Int']
  total: Scalars['Int']
}

export enum EntryTestimonialHotelsCollectionOrder {
  ArchivedAsc = 'archived_ASC',
  ArchivedDesc = 'archived_DESC',
  ForaSupplierIdAsc = 'foraSupplierId_ASC',
  ForaSupplierIdDesc = 'foraSupplierId_DESC',
  GenerateLandingPageAsc = 'generateLandingPage_ASC',
  GenerateLandingPageDesc = 'generateLandingPage_DESC',
  HeroImageAsc = 'heroImage_ASC',
  HeroImageDesc = 'heroImage_DESC',
  HideFromTopHotelsPagesAsc = 'hideFromTopHotelsPages_ASC',
  HideFromTopHotelsPagesDesc = 'hideFromTopHotelsPages_DESC',
  MichelinKeysAsc = 'michelinKeys_ASC',
  MichelinKeysDesc = 'michelinKeys_DESC',
  SlugAsc = 'slug_ASC',
  SlugDesc = 'slug_DESC',
  StarRatingAsc = 'starRating_ASC',
  StarRatingDesc = 'starRating_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC',
}

export type EntryTestimonialLinkingCollections = {
  __typename?: 'EntryTestimonialLinkingCollections'
  componentFeaturedReviewsCollection?: Maybe<ComponentFeaturedReviewsCollection>
  componentReviewCarouselCollection?: Maybe<ComponentReviewCarouselCollection>
  componentTestimonialCarouselCollection?: Maybe<ComponentTestimonialCarouselCollection>
  entryCollection?: Maybe<EntryCollection>
}

export type EntryTestimonialLinkingCollectionsComponentFeaturedReviewsCollectionArgs =
  {
    limit?: InputMaybe<Scalars['Int']>
    locale?: InputMaybe<Scalars['String']>
    order?: InputMaybe<
      Array<
        InputMaybe<EntryTestimonialLinkingCollectionsComponentFeaturedReviewsCollectionOrder>
      >
    >
    preview?: InputMaybe<Scalars['Boolean']>
    skip?: InputMaybe<Scalars['Int']>
  }

export type EntryTestimonialLinkingCollectionsComponentReviewCarouselCollectionArgs =
  {
    limit?: InputMaybe<Scalars['Int']>
    locale?: InputMaybe<Scalars['String']>
    order?: InputMaybe<
      Array<
        InputMaybe<EntryTestimonialLinkingCollectionsComponentReviewCarouselCollectionOrder>
      >
    >
    preview?: InputMaybe<Scalars['Boolean']>
    skip?: InputMaybe<Scalars['Int']>
  }

export type EntryTestimonialLinkingCollectionsComponentTestimonialCarouselCollectionArgs =
  {
    limit?: InputMaybe<Scalars['Int']>
    locale?: InputMaybe<Scalars['String']>
    order?: InputMaybe<
      Array<
        InputMaybe<EntryTestimonialLinkingCollectionsComponentTestimonialCarouselCollectionOrder>
      >
    >
    preview?: InputMaybe<Scalars['Boolean']>
    skip?: InputMaybe<Scalars['Int']>
  }

export type EntryTestimonialLinkingCollectionsEntryCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>
  locale?: InputMaybe<Scalars['String']>
  preview?: InputMaybe<Scalars['Boolean']>
  skip?: InputMaybe<Scalars['Int']>
}

export enum EntryTestimonialLinkingCollectionsComponentFeaturedReviewsCollectionOrder {
  HeadingAsc = 'heading_ASC',
  HeadingDesc = 'heading_DESC',
  InternalLabelAsc = 'internalLabel_ASC',
  InternalLabelDesc = 'internalLabel_DESC',
  SectionIdAsc = 'sectionId_ASC',
  SectionIdDesc = 'sectionId_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  TitleStyleAsc = 'titleStyle_ASC',
  TitleStyleDesc = 'titleStyle_DESC',
}

export enum EntryTestimonialLinkingCollectionsComponentReviewCarouselCollectionOrder {
  HeadingAsc = 'heading_ASC',
  HeadingDesc = 'heading_DESC',
  InternalLabelAsc = 'internalLabel_ASC',
  InternalLabelDesc = 'internalLabel_DESC',
  SectionIdAsc = 'sectionId_ASC',
  SectionIdDesc = 'sectionId_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  TitleStyleAsc = 'titleStyle_ASC',
  TitleStyleDesc = 'titleStyle_DESC',
}

export enum EntryTestimonialLinkingCollectionsComponentTestimonialCarouselCollectionOrder {
  HeadingAsc = 'heading_ASC',
  HeadingDesc = 'heading_DESC',
  InternalLabelAsc = 'internalLabel_ASC',
  InternalLabelDesc = 'internalLabel_DESC',
  SectionIdAsc = 'sectionId_ASC',
  SectionIdDesc = 'sectionId_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  TitleStyleAsc = 'titleStyle_ASC',
  TitleStyleDesc = 'titleStyle_DESC',
}

export enum EntryTestimonialOrder {
  AboutForaAsc = 'aboutFora_ASC',
  AboutForaDesc = 'aboutFora_DESC',
  BeginTravelDateAsc = 'beginTravelDate_ASC',
  BeginTravelDateDesc = 'beginTravelDate_DESC',
  DateSubmittedAsc = 'dateSubmitted_ASC',
  DateSubmittedDesc = 'dateSubmitted_DESC',
  DisplayAsc = 'display_ASC',
  DisplayDesc = 'display_DESC',
  EndTravelDateAsc = 'endTravelDate_ASC',
  EndTravelDateDesc = 'endTravelDate_DESC',
  InternalLabelAsc = 'internalLabel_ASC',
  InternalLabelDesc = 'internalLabel_DESC',
  IsFeaturedAsc = 'isFeatured_ASC',
  IsFeaturedDesc = 'isFeatured_DESC',
  StarsAsc = 'stars_ASC',
  StarsDesc = 'stars_DESC',
  SubmitterEmailAsc = 'submitterEmail_ASC',
  SubmitterEmailDesc = 'submitterEmail_DESC',
  SubmitterFirstNameAsc = 'submitterFirstName_ASC',
  SubmitterFirstNameDesc = 'submitterFirstName_DESC',
  SubmitterLastNameAsc = 'submitterLastName_ASC',
  SubmitterLastNameDesc = 'submitterLastName_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  TestimonialSourceAsc = 'testimonialSource_ASC',
  TestimonialSourceDesc = 'testimonialSource_DESC',
  TitleOfReviewAsc = 'titleOfReview_ASC',
  TitleOfReviewDesc = 'titleOfReview_DESC',
}

export type EntryTestimonialTestimonial = {
  __typename?: 'EntryTestimonialTestimonial'
  json: Scalars['JSON']
  links: EntryTestimonialTestimonialLinks
}

export type EntryTestimonialTestimonialAssets = {
  __typename?: 'EntryTestimonialTestimonialAssets'
  block: Array<Maybe<Asset>>
  hyperlink: Array<Maybe<Asset>>
}

export type EntryTestimonialTestimonialEntries = {
  __typename?: 'EntryTestimonialTestimonialEntries'
  block: Array<Maybe<Entry>>
  hyperlink: Array<Maybe<Entry>>
  inline: Array<Maybe<Entry>>
}

export type EntryTestimonialTestimonialLinks = {
  __typename?: 'EntryTestimonialTestimonialLinks'
  assets: EntryTestimonialTestimonialAssets
  entries: EntryTestimonialTestimonialEntries
  resources: EntryTestimonialTestimonialResources
}

export type EntryTestimonialTestimonialResources = {
  __typename?: 'EntryTestimonialTestimonialResources'
  block: Array<EntryTestimonialTestimonialResourcesBlock>
  hyperlink: Array<EntryTestimonialTestimonialResourcesHyperlink>
  inline: Array<EntryTestimonialTestimonialResourcesInline>
}

export type EntryTestimonialTestimonialResourcesBlock = ResourceLink & {
  __typename?: 'EntryTestimonialTestimonialResourcesBlock'
  sys: ResourceSys
}

export type EntryTestimonialTestimonialResourcesHyperlink = ResourceLink & {
  __typename?: 'EntryTestimonialTestimonialResourcesHyperlink'
  sys: ResourceSys
}

export type EntryTestimonialTestimonialResourcesInline = ResourceLink & {
  __typename?: 'EntryTestimonialTestimonialResourcesInline'
  sys: ResourceSys
}

/** [See type definition](https://app.contentful.com/spaces/9cxgygtadmvl/content_types/entryTripReport) */
export type EntryTripReport = Entry &
  _Node & {
    __typename?: 'EntryTripReport'
    _id: Scalars['ID']
    advisor?: Maybe<Advisors>
    atAGlanceTags?: Maybe<Array<Maybe<Scalars['String']>>>
    categoriesCollection?: Maybe<EntryTripReportCategoriesCollection>
    cloudinaryImage?: Maybe<Scalars['JSON']>
    content?: Maybe<EntryTripReportContent>
    contentfulMetadata: ContentfulMetadata
    curatorsStatement?: Maybe<EntryTripReportCuratorsStatement>
    hotelsCollection?: Maybe<EntryTripReportHotelsCollection>
    image?: Maybe<Asset>
    linkedFrom?: Maybe<EntryTripReportLinkingCollections>
    locationForTitles?: Maybe<Scalars['String']>
    metaDescription?: Maybe<Scalars['String']>
    metaTitle?: Maybe<Scalars['String']>
    needToKnow?: Maybe<EntryTripReportNeedToKnow>
    seoSlug?: Maybe<Scalars['String']>
    showOnGuidesAndDestinationsPage?: Maybe<Scalars['Boolean']>
    showOnHomepage?: Maybe<Scalars['Boolean']>
    showOnOtherLandingPages?: Maybe<Scalars['Boolean']>
    slug?: Maybe<Scalars['String']>
    slugId?: Maybe<Scalars['String']>
    sys: Sys
    testUpdatedSlugId?: Maybe<Scalars['String']>
    title?: Maybe<Scalars['String']>
  }

/** [See type definition](https://app.contentful.com/spaces/9cxgygtadmvl/content_types/entryTripReport) */
export type EntryTripReportAdvisorArgs = {
  locale?: InputMaybe<Scalars['String']>
  preview?: InputMaybe<Scalars['Boolean']>
  where?: InputMaybe<AdvisorsFilter>
}

/** [See type definition](https://app.contentful.com/spaces/9cxgygtadmvl/content_types/entryTripReport) */
export type EntryTripReportAtAGlanceTagsArgs = {
  locale?: InputMaybe<Scalars['String']>
}

/** [See type definition](https://app.contentful.com/spaces/9cxgygtadmvl/content_types/entryTripReport) */
export type EntryTripReportCategoriesCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>
  locale?: InputMaybe<Scalars['String']>
  preview?: InputMaybe<Scalars['Boolean']>
  skip?: InputMaybe<Scalars['Int']>
  where?: InputMaybe<EntryTripReportCategoriesFilter>
}

/** [See type definition](https://app.contentful.com/spaces/9cxgygtadmvl/content_types/entryTripReport) */
export type EntryTripReportCloudinaryImageArgs = {
  locale?: InputMaybe<Scalars['String']>
}

/** [See type definition](https://app.contentful.com/spaces/9cxgygtadmvl/content_types/entryTripReport) */
export type EntryTripReportContentArgs = {
  locale?: InputMaybe<Scalars['String']>
}

/** [See type definition](https://app.contentful.com/spaces/9cxgygtadmvl/content_types/entryTripReport) */
export type EntryTripReportCuratorsStatementArgs = {
  locale?: InputMaybe<Scalars['String']>
}

/** [See type definition](https://app.contentful.com/spaces/9cxgygtadmvl/content_types/entryTripReport) */
export type EntryTripReportHotelsCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>
  locale?: InputMaybe<Scalars['String']>
  order?: InputMaybe<Array<InputMaybe<EntryTripReportHotelsCollectionOrder>>>
  preview?: InputMaybe<Scalars['Boolean']>
  skip?: InputMaybe<Scalars['Int']>
  where?: InputMaybe<HotelsFilter>
}

/** [See type definition](https://app.contentful.com/spaces/9cxgygtadmvl/content_types/entryTripReport) */
export type EntryTripReportImageArgs = {
  locale?: InputMaybe<Scalars['String']>
  preview?: InputMaybe<Scalars['Boolean']>
}

/** [See type definition](https://app.contentful.com/spaces/9cxgygtadmvl/content_types/entryTripReport) */
export type EntryTripReportLinkedFromArgs = {
  allowedLocales?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
}

/** [See type definition](https://app.contentful.com/spaces/9cxgygtadmvl/content_types/entryTripReport) */
export type EntryTripReportLocationForTitlesArgs = {
  locale?: InputMaybe<Scalars['String']>
}

/** [See type definition](https://app.contentful.com/spaces/9cxgygtadmvl/content_types/entryTripReport) */
export type EntryTripReportMetaDescriptionArgs = {
  locale?: InputMaybe<Scalars['String']>
}

/** [See type definition](https://app.contentful.com/spaces/9cxgygtadmvl/content_types/entryTripReport) */
export type EntryTripReportMetaTitleArgs = {
  locale?: InputMaybe<Scalars['String']>
}

/** [See type definition](https://app.contentful.com/spaces/9cxgygtadmvl/content_types/entryTripReport) */
export type EntryTripReportNeedToKnowArgs = {
  locale?: InputMaybe<Scalars['String']>
}

/** [See type definition](https://app.contentful.com/spaces/9cxgygtadmvl/content_types/entryTripReport) */
export type EntryTripReportSeoSlugArgs = {
  locale?: InputMaybe<Scalars['String']>
}

/** [See type definition](https://app.contentful.com/spaces/9cxgygtadmvl/content_types/entryTripReport) */
export type EntryTripReportShowOnGuidesAndDestinationsPageArgs = {
  locale?: InputMaybe<Scalars['String']>
}

/** [See type definition](https://app.contentful.com/spaces/9cxgygtadmvl/content_types/entryTripReport) */
export type EntryTripReportShowOnHomepageArgs = {
  locale?: InputMaybe<Scalars['String']>
}

/** [See type definition](https://app.contentful.com/spaces/9cxgygtadmvl/content_types/entryTripReport) */
export type EntryTripReportShowOnOtherLandingPagesArgs = {
  locale?: InputMaybe<Scalars['String']>
}

/** [See type definition](https://app.contentful.com/spaces/9cxgygtadmvl/content_types/entryTripReport) */
export type EntryTripReportSlugArgs = {
  locale?: InputMaybe<Scalars['String']>
}

/** [See type definition](https://app.contentful.com/spaces/9cxgygtadmvl/content_types/entryTripReport) */
export type EntryTripReportSlugIdArgs = {
  locale?: InputMaybe<Scalars['String']>
}

/** [See type definition](https://app.contentful.com/spaces/9cxgygtadmvl/content_types/entryTripReport) */
export type EntryTripReportTestUpdatedSlugIdArgs = {
  locale?: InputMaybe<Scalars['String']>
}

/** [See type definition](https://app.contentful.com/spaces/9cxgygtadmvl/content_types/entryTripReport) */
export type EntryTripReportTitleArgs = {
  locale?: InputMaybe<Scalars['String']>
}

export type EntryTripReportCategoriesCollection = {
  __typename?: 'EntryTripReportCategoriesCollection'
  items: Array<Maybe<EntryTripReportCategoriesItem>>
  limit: Scalars['Int']
  skip: Scalars['Int']
  total: Scalars['Int']
}

export type EntryTripReportCategoriesFilter = {
  AND?: InputMaybe<Array<InputMaybe<EntryTripReportCategoriesFilter>>>
  OR?: InputMaybe<Array<InputMaybe<EntryTripReportCategoriesFilter>>>
  contentfulMetadata?: InputMaybe<ContentfulMetadataFilter>
  featuredImage_exists?: InputMaybe<Scalars['Boolean']>
  generateLandingPage?: InputMaybe<Scalars['Boolean']>
  generateLandingPage_exists?: InputMaybe<Scalars['Boolean']>
  generateLandingPage_not?: InputMaybe<Scalars['Boolean']>
  metaContent_exists?: InputMaybe<Scalars['Boolean']>
  questionsCollection_exists?: InputMaybe<Scalars['Boolean']>
  slug?: InputMaybe<Scalars['String']>
  slug_contains?: InputMaybe<Scalars['String']>
  slug_exists?: InputMaybe<Scalars['Boolean']>
  slug_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  slug_not?: InputMaybe<Scalars['String']>
  slug_not_contains?: InputMaybe<Scalars['String']>
  slug_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  sys?: InputMaybe<SysFilter>
  teaserCopy_contains?: InputMaybe<Scalars['String']>
  teaserCopy_exists?: InputMaybe<Scalars['Boolean']>
  teaserCopy_not_contains?: InputMaybe<Scalars['String']>
  title?: InputMaybe<Scalars['String']>
  title_contains?: InputMaybe<Scalars['String']>
  title_exists?: InputMaybe<Scalars['Boolean']>
  title_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  title_not?: InputMaybe<Scalars['String']>
  title_not_contains?: InputMaybe<Scalars['String']>
  title_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
}

export type EntryTripReportCategoriesItem =
  | DestinationsSubcategories
  | TravelBySubcategories

export type EntryTripReportCollection = {
  __typename?: 'EntryTripReportCollection'
  items: Array<Maybe<EntryTripReport>>
  limit: Scalars['Int']
  skip: Scalars['Int']
  total: Scalars['Int']
}

export type EntryTripReportContent = {
  __typename?: 'EntryTripReportContent'
  json: Scalars['JSON']
  links: EntryTripReportContentLinks
}

export type EntryTripReportContentAssets = {
  __typename?: 'EntryTripReportContentAssets'
  block: Array<Maybe<Asset>>
  hyperlink: Array<Maybe<Asset>>
}

export type EntryTripReportContentEntries = {
  __typename?: 'EntryTripReportContentEntries'
  block: Array<Maybe<Entry>>
  hyperlink: Array<Maybe<Entry>>
  inline: Array<Maybe<Entry>>
}

export type EntryTripReportContentLinks = {
  __typename?: 'EntryTripReportContentLinks'
  assets: EntryTripReportContentAssets
  entries: EntryTripReportContentEntries
  resources: EntryTripReportContentResources
}

export type EntryTripReportContentResources = {
  __typename?: 'EntryTripReportContentResources'
  block: Array<EntryTripReportContentResourcesBlock>
  hyperlink: Array<EntryTripReportContentResourcesHyperlink>
  inline: Array<EntryTripReportContentResourcesInline>
}

export type EntryTripReportContentResourcesBlock = ResourceLink & {
  __typename?: 'EntryTripReportContentResourcesBlock'
  sys: ResourceSys
}

export type EntryTripReportContentResourcesHyperlink = ResourceLink & {
  __typename?: 'EntryTripReportContentResourcesHyperlink'
  sys: ResourceSys
}

export type EntryTripReportContentResourcesInline = ResourceLink & {
  __typename?: 'EntryTripReportContentResourcesInline'
  sys: ResourceSys
}

export type EntryTripReportCuratorsStatement = {
  __typename?: 'EntryTripReportCuratorsStatement'
  json: Scalars['JSON']
  links: EntryTripReportCuratorsStatementLinks
}

export type EntryTripReportCuratorsStatementAssets = {
  __typename?: 'EntryTripReportCuratorsStatementAssets'
  block: Array<Maybe<Asset>>
  hyperlink: Array<Maybe<Asset>>
}

export type EntryTripReportCuratorsStatementEntries = {
  __typename?: 'EntryTripReportCuratorsStatementEntries'
  block: Array<Maybe<Entry>>
  hyperlink: Array<Maybe<Entry>>
  inline: Array<Maybe<Entry>>
}

export type EntryTripReportCuratorsStatementLinks = {
  __typename?: 'EntryTripReportCuratorsStatementLinks'
  assets: EntryTripReportCuratorsStatementAssets
  entries: EntryTripReportCuratorsStatementEntries
  resources: EntryTripReportCuratorsStatementResources
}

export type EntryTripReportCuratorsStatementResources = {
  __typename?: 'EntryTripReportCuratorsStatementResources'
  block: Array<EntryTripReportCuratorsStatementResourcesBlock>
  hyperlink: Array<EntryTripReportCuratorsStatementResourcesHyperlink>
  inline: Array<EntryTripReportCuratorsStatementResourcesInline>
}

export type EntryTripReportCuratorsStatementResourcesBlock = ResourceLink & {
  __typename?: 'EntryTripReportCuratorsStatementResourcesBlock'
  sys: ResourceSys
}

export type EntryTripReportCuratorsStatementResourcesHyperlink =
  ResourceLink & {
    __typename?: 'EntryTripReportCuratorsStatementResourcesHyperlink'
    sys: ResourceSys
  }

export type EntryTripReportCuratorsStatementResourcesInline = ResourceLink & {
  __typename?: 'EntryTripReportCuratorsStatementResourcesInline'
  sys: ResourceSys
}

export type EntryTripReportFilter = {
  AND?: InputMaybe<Array<InputMaybe<EntryTripReportFilter>>>
  OR?: InputMaybe<Array<InputMaybe<EntryTripReportFilter>>>
  advisor?: InputMaybe<CfAdvisorsNestedFilter>
  advisor_exists?: InputMaybe<Scalars['Boolean']>
  atAGlanceTags_contains_all?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  atAGlanceTags_contains_none?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  atAGlanceTags_contains_some?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  atAGlanceTags_exists?: InputMaybe<Scalars['Boolean']>
  categories?: InputMaybe<CfcategoriesMultiTypeNestedFilter>
  categoriesCollection_exists?: InputMaybe<Scalars['Boolean']>
  cloudinaryImage_exists?: InputMaybe<Scalars['Boolean']>
  content_contains?: InputMaybe<Scalars['String']>
  content_exists?: InputMaybe<Scalars['Boolean']>
  content_not_contains?: InputMaybe<Scalars['String']>
  contentfulMetadata?: InputMaybe<ContentfulMetadataFilter>
  curatorsStatement_contains?: InputMaybe<Scalars['String']>
  curatorsStatement_exists?: InputMaybe<Scalars['Boolean']>
  curatorsStatement_not_contains?: InputMaybe<Scalars['String']>
  hotels?: InputMaybe<CfHotelsNestedFilter>
  hotelsCollection_exists?: InputMaybe<Scalars['Boolean']>
  image_exists?: InputMaybe<Scalars['Boolean']>
  locationForTitles?: InputMaybe<Scalars['String']>
  locationForTitles_contains?: InputMaybe<Scalars['String']>
  locationForTitles_exists?: InputMaybe<Scalars['Boolean']>
  locationForTitles_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  locationForTitles_not?: InputMaybe<Scalars['String']>
  locationForTitles_not_contains?: InputMaybe<Scalars['String']>
  locationForTitles_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  metaDescription?: InputMaybe<Scalars['String']>
  metaDescription_contains?: InputMaybe<Scalars['String']>
  metaDescription_exists?: InputMaybe<Scalars['Boolean']>
  metaDescription_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  metaDescription_not?: InputMaybe<Scalars['String']>
  metaDescription_not_contains?: InputMaybe<Scalars['String']>
  metaDescription_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  metaTitle?: InputMaybe<Scalars['String']>
  metaTitle_contains?: InputMaybe<Scalars['String']>
  metaTitle_exists?: InputMaybe<Scalars['Boolean']>
  metaTitle_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  metaTitle_not?: InputMaybe<Scalars['String']>
  metaTitle_not_contains?: InputMaybe<Scalars['String']>
  metaTitle_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  needToKnow_contains?: InputMaybe<Scalars['String']>
  needToKnow_exists?: InputMaybe<Scalars['Boolean']>
  needToKnow_not_contains?: InputMaybe<Scalars['String']>
  seoSlug?: InputMaybe<Scalars['String']>
  seoSlug_contains?: InputMaybe<Scalars['String']>
  seoSlug_exists?: InputMaybe<Scalars['Boolean']>
  seoSlug_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  seoSlug_not?: InputMaybe<Scalars['String']>
  seoSlug_not_contains?: InputMaybe<Scalars['String']>
  seoSlug_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  showOnGuidesAndDestinationsPage?: InputMaybe<Scalars['Boolean']>
  showOnGuidesAndDestinationsPage_exists?: InputMaybe<Scalars['Boolean']>
  showOnGuidesAndDestinationsPage_not?: InputMaybe<Scalars['Boolean']>
  showOnHomepage?: InputMaybe<Scalars['Boolean']>
  showOnHomepage_exists?: InputMaybe<Scalars['Boolean']>
  showOnHomepage_not?: InputMaybe<Scalars['Boolean']>
  showOnOtherLandingPages?: InputMaybe<Scalars['Boolean']>
  showOnOtherLandingPages_exists?: InputMaybe<Scalars['Boolean']>
  showOnOtherLandingPages_not?: InputMaybe<Scalars['Boolean']>
  slug?: InputMaybe<Scalars['String']>
  slugID?: InputMaybe<Scalars['String']>
  slugID_contains?: InputMaybe<Scalars['String']>
  slugID_exists?: InputMaybe<Scalars['Boolean']>
  slugID_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  slugID_not?: InputMaybe<Scalars['String']>
  slugID_not_contains?: InputMaybe<Scalars['String']>
  slugID_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  slug_contains?: InputMaybe<Scalars['String']>
  slug_exists?: InputMaybe<Scalars['Boolean']>
  slug_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  slug_not?: InputMaybe<Scalars['String']>
  slug_not_contains?: InputMaybe<Scalars['String']>
  slug_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  sys?: InputMaybe<SysFilter>
  testUpdatedSlugId?: InputMaybe<Scalars['String']>
  testUpdatedSlugId_contains?: InputMaybe<Scalars['String']>
  testUpdatedSlugId_exists?: InputMaybe<Scalars['Boolean']>
  testUpdatedSlugId_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  testUpdatedSlugId_not?: InputMaybe<Scalars['String']>
  testUpdatedSlugId_not_contains?: InputMaybe<Scalars['String']>
  testUpdatedSlugId_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  title?: InputMaybe<Scalars['String']>
  title_contains?: InputMaybe<Scalars['String']>
  title_exists?: InputMaybe<Scalars['Boolean']>
  title_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  title_not?: InputMaybe<Scalars['String']>
  title_not_contains?: InputMaybe<Scalars['String']>
  title_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
}

export type EntryTripReportHotelsCollection = {
  __typename?: 'EntryTripReportHotelsCollection'
  items: Array<Maybe<Hotels>>
  limit: Scalars['Int']
  skip: Scalars['Int']
  total: Scalars['Int']
}

export enum EntryTripReportHotelsCollectionOrder {
  ArchivedAsc = 'archived_ASC',
  ArchivedDesc = 'archived_DESC',
  ForaSupplierIdAsc = 'foraSupplierId_ASC',
  ForaSupplierIdDesc = 'foraSupplierId_DESC',
  GenerateLandingPageAsc = 'generateLandingPage_ASC',
  GenerateLandingPageDesc = 'generateLandingPage_DESC',
  HeroImageAsc = 'heroImage_ASC',
  HeroImageDesc = 'heroImage_DESC',
  HideFromTopHotelsPagesAsc = 'hideFromTopHotelsPages_ASC',
  HideFromTopHotelsPagesDesc = 'hideFromTopHotelsPages_DESC',
  MichelinKeysAsc = 'michelinKeys_ASC',
  MichelinKeysDesc = 'michelinKeys_DESC',
  SlugAsc = 'slug_ASC',
  SlugDesc = 'slug_DESC',
  StarRatingAsc = 'starRating_ASC',
  StarRatingDesc = 'starRating_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC',
}

export type EntryTripReportLinkingCollections = {
  __typename?: 'EntryTripReportLinkingCollections'
  advisorsCollection?: Maybe<AdvisorsCollection>
  entryCollection?: Maybe<EntryCollection>
}

export type EntryTripReportLinkingCollectionsAdvisorsCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>
  locale?: InputMaybe<Scalars['String']>
  order?: InputMaybe<
    Array<InputMaybe<EntryTripReportLinkingCollectionsAdvisorsCollectionOrder>>
  >
  preview?: InputMaybe<Scalars['Boolean']>
  skip?: InputMaybe<Scalars['Int']>
}

export type EntryTripReportLinkingCollectionsEntryCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>
  locale?: InputMaybe<Scalars['String']>
  preview?: InputMaybe<Scalars['Boolean']>
  skip?: InputMaybe<Scalars['Int']>
}

export enum EntryTripReportLinkingCollectionsAdvisorsCollectionOrder {
  AdvisorContactEnabledAsc = 'advisorContactEnabled_ASC',
  AdvisorContactEnabledDesc = 'advisorContactEnabled_DESC',
  AdvisorVariantAsc = 'advisorVariant_ASC',
  AdvisorVariantDesc = 'advisorVariant_DESC',
  EmailAddressAsc = 'emailAddress_ASC',
  EmailAddressDesc = 'emailAddress_DESC',
  FacebookUrlAsc = 'facebookUrl_ASC',
  FacebookUrlDesc = 'facebookUrl_DESC',
  FirstNameAsc = 'firstName_ASC',
  FirstNameDesc = 'firstName_DESC',
  ForaAdvisorHotelsAsc = 'foraAdvisorHotels_ASC',
  ForaAdvisorHotelsDesc = 'foraAdvisorHotels_DESC',
  ForaAdvisorIdAsc = 'foraAdvisorId_ASC',
  ForaAdvisorIdDesc = 'foraAdvisorId_DESC',
  InstagramUrlAsc = 'instagramUrl_ASC',
  InstagramUrlDesc = 'instagramUrl_DESC',
  LastNameAsc = 'lastName_ASC',
  LastNameDesc = 'lastName_DESC',
  LinkedinUrlAsc = 'linkedinUrl_ASC',
  LinkedinUrlDesc = 'linkedinUrl_DESC',
  MinimumBookingAsc = 'minimumBooking_ASC',
  MinimumBookingDesc = 'minimumBooking_DESC',
  PinterestUrlAsc = 'pinterestUrl_ASC',
  PinterestUrlDesc = 'pinterestUrl_DESC',
  PronounsAsc = 'pronouns_ASC',
  PronounsDesc = 'pronouns_DESC',
  ShortDescriptionAsc = 'shortDescription_ASC',
  ShortDescriptionDesc = 'shortDescription_DESC',
  ShowOnAdvisorsPageAsc = 'showOnAdvisorsPage_ASC',
  ShowOnAdvisorsPageDesc = 'showOnAdvisorsPage_DESC',
  ShowOnHomepageAsc = 'showOnHomepage_ASC',
  ShowOnHomepageDesc = 'showOnHomepage_DESC',
  ShowOnOtherLandingPagesAsc = 'showOnOtherLandingPages_ASC',
  ShowOnOtherLandingPagesDesc = 'showOnOtherLandingPages_DESC',
  SlugAsc = 'slug_ASC',
  SlugDesc = 'slug_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  TierAsc = 'tier_ASC',
  TierDesc = 'tier_DESC',
  TikTokUrlAsc = 'tikTokUrl_ASC',
  TikTokUrlDesc = 'tikTokUrl_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC',
  TwitterUrlAsc = 'twitterUrl_ASC',
  TwitterUrlDesc = 'twitterUrl_DESC',
  WebsiteUrlAsc = 'websiteUrl_ASC',
  WebsiteUrlDesc = 'websiteUrl_DESC',
  YoutubeUrlAsc = 'youtubeUrl_ASC',
  YoutubeUrlDesc = 'youtubeUrl_DESC',
}

export type EntryTripReportNeedToKnow = {
  __typename?: 'EntryTripReportNeedToKnow'
  json: Scalars['JSON']
  links: EntryTripReportNeedToKnowLinks
}

export type EntryTripReportNeedToKnowAssets = {
  __typename?: 'EntryTripReportNeedToKnowAssets'
  block: Array<Maybe<Asset>>
  hyperlink: Array<Maybe<Asset>>
}

export type EntryTripReportNeedToKnowEntries = {
  __typename?: 'EntryTripReportNeedToKnowEntries'
  block: Array<Maybe<Entry>>
  hyperlink: Array<Maybe<Entry>>
  inline: Array<Maybe<Entry>>
}

export type EntryTripReportNeedToKnowLinks = {
  __typename?: 'EntryTripReportNeedToKnowLinks'
  assets: EntryTripReportNeedToKnowAssets
  entries: EntryTripReportNeedToKnowEntries
  resources: EntryTripReportNeedToKnowResources
}

export type EntryTripReportNeedToKnowResources = {
  __typename?: 'EntryTripReportNeedToKnowResources'
  block: Array<EntryTripReportNeedToKnowResourcesBlock>
  hyperlink: Array<EntryTripReportNeedToKnowResourcesHyperlink>
  inline: Array<EntryTripReportNeedToKnowResourcesInline>
}

export type EntryTripReportNeedToKnowResourcesBlock = ResourceLink & {
  __typename?: 'EntryTripReportNeedToKnowResourcesBlock'
  sys: ResourceSys
}

export type EntryTripReportNeedToKnowResourcesHyperlink = ResourceLink & {
  __typename?: 'EntryTripReportNeedToKnowResourcesHyperlink'
  sys: ResourceSys
}

export type EntryTripReportNeedToKnowResourcesInline = ResourceLink & {
  __typename?: 'EntryTripReportNeedToKnowResourcesInline'
  sys: ResourceSys
}

export enum EntryTripReportOrder {
  LocationForTitlesAsc = 'locationForTitles_ASC',
  LocationForTitlesDesc = 'locationForTitles_DESC',
  MetaDescriptionAsc = 'metaDescription_ASC',
  MetaDescriptionDesc = 'metaDescription_DESC',
  MetaTitleAsc = 'metaTitle_ASC',
  MetaTitleDesc = 'metaTitle_DESC',
  SeoSlugAsc = 'seoSlug_ASC',
  SeoSlugDesc = 'seoSlug_DESC',
  ShowOnGuidesAndDestinationsPageAsc = 'showOnGuidesAndDestinationsPage_ASC',
  ShowOnGuidesAndDestinationsPageDesc = 'showOnGuidesAndDestinationsPage_DESC',
  ShowOnHomepageAsc = 'showOnHomepage_ASC',
  ShowOnHomepageDesc = 'showOnHomepage_DESC',
  ShowOnOtherLandingPagesAsc = 'showOnOtherLandingPages_ASC',
  ShowOnOtherLandingPagesDesc = 'showOnOtherLandingPages_DESC',
  SlugIdAsc = 'slugID_ASC',
  SlugIdDesc = 'slugID_DESC',
  SlugAsc = 'slug_ASC',
  SlugDesc = 'slug_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  TestUpdatedSlugIdAsc = 'testUpdatedSlugId_ASC',
  TestUpdatedSlugIdDesc = 'testUpdatedSlugId_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC',
}

/** Sequel.io Webinar events [See type definition](https://app.contentful.com/spaces/9cxgygtadmvl/content_types/events) */
export type Events = Entry &
  _Node & {
    __typename?: 'Events'
    _id: Scalars['ID']
    autoSelectLatestReplay?: Maybe<Scalars['Boolean']>
    bannerImage?: Maybe<Asset>
    closeCaptioning?: Maybe<Scalars['Boolean']>
    contentfulMetadata: ContentfulMetadata
    disableEventRecording?: Maybe<Scalars['Boolean']>
    eventDescription?: Maybe<EventsEventDescription>
    eventDuration?: Maybe<Scalars['String']>
    eventType?: Maybe<Scalars['String']>
    hideEvent?: Maybe<Scalars['Boolean']>
    isAttendeeRegistrationModeEnabled?: Maybe<Scalars['Boolean']>
    isRegistrationModeEnabled?: Maybe<Scalars['Boolean']>
    linkedFrom?: Maybe<EventsLinkingCollections>
    name?: Maybe<Scalars['String']>
    organizersCollection?: Maybe<EventsOrganizersCollection>
    presentersCollection?: Maybe<EventsPresentersCollection>
    sequelEventId?: Maybe<Scalars['String']>
    slug?: Maybe<Scalars['String']>
    startDate?: Maybe<Scalars['DateTime']>
    startTime?: Maybe<Scalars['String']>
    sys: Sys
    timeZone?: Maybe<Scalars['String']>
  }

/** Sequel.io Webinar events [See type definition](https://app.contentful.com/spaces/9cxgygtadmvl/content_types/events) */
export type EventsAutoSelectLatestReplayArgs = {
  locale?: InputMaybe<Scalars['String']>
}

/** Sequel.io Webinar events [See type definition](https://app.contentful.com/spaces/9cxgygtadmvl/content_types/events) */
export type EventsBannerImageArgs = {
  locale?: InputMaybe<Scalars['String']>
  preview?: InputMaybe<Scalars['Boolean']>
}

/** Sequel.io Webinar events [See type definition](https://app.contentful.com/spaces/9cxgygtadmvl/content_types/events) */
export type EventsCloseCaptioningArgs = {
  locale?: InputMaybe<Scalars['String']>
}

/** Sequel.io Webinar events [See type definition](https://app.contentful.com/spaces/9cxgygtadmvl/content_types/events) */
export type EventsDisableEventRecordingArgs = {
  locale?: InputMaybe<Scalars['String']>
}

/** Sequel.io Webinar events [See type definition](https://app.contentful.com/spaces/9cxgygtadmvl/content_types/events) */
export type EventsEventDescriptionArgs = {
  locale?: InputMaybe<Scalars['String']>
}

/** Sequel.io Webinar events [See type definition](https://app.contentful.com/spaces/9cxgygtadmvl/content_types/events) */
export type EventsEventDurationArgs = {
  locale?: InputMaybe<Scalars['String']>
}

/** Sequel.io Webinar events [See type definition](https://app.contentful.com/spaces/9cxgygtadmvl/content_types/events) */
export type EventsEventTypeArgs = {
  locale?: InputMaybe<Scalars['String']>
}

/** Sequel.io Webinar events [See type definition](https://app.contentful.com/spaces/9cxgygtadmvl/content_types/events) */
export type EventsHideEventArgs = {
  locale?: InputMaybe<Scalars['String']>
}

/** Sequel.io Webinar events [See type definition](https://app.contentful.com/spaces/9cxgygtadmvl/content_types/events) */
export type EventsIsAttendeeRegistrationModeEnabledArgs = {
  locale?: InputMaybe<Scalars['String']>
}

/** Sequel.io Webinar events [See type definition](https://app.contentful.com/spaces/9cxgygtadmvl/content_types/events) */
export type EventsIsRegistrationModeEnabledArgs = {
  locale?: InputMaybe<Scalars['String']>
}

/** Sequel.io Webinar events [See type definition](https://app.contentful.com/spaces/9cxgygtadmvl/content_types/events) */
export type EventsLinkedFromArgs = {
  allowedLocales?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
}

/** Sequel.io Webinar events [See type definition](https://app.contentful.com/spaces/9cxgygtadmvl/content_types/events) */
export type EventsNameArgs = {
  locale?: InputMaybe<Scalars['String']>
}

/** Sequel.io Webinar events [See type definition](https://app.contentful.com/spaces/9cxgygtadmvl/content_types/events) */
export type EventsOrganizersCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>
  locale?: InputMaybe<Scalars['String']>
  order?: InputMaybe<Array<InputMaybe<EventsOrganizersCollectionOrder>>>
  preview?: InputMaybe<Scalars['Boolean']>
  skip?: InputMaybe<Scalars['Int']>
  where?: InputMaybe<TeamFilter>
}

/** Sequel.io Webinar events [See type definition](https://app.contentful.com/spaces/9cxgygtadmvl/content_types/events) */
export type EventsPresentersCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>
  locale?: InputMaybe<Scalars['String']>
  order?: InputMaybe<Array<InputMaybe<EventsPresentersCollectionOrder>>>
  preview?: InputMaybe<Scalars['Boolean']>
  skip?: InputMaybe<Scalars['Int']>
  where?: InputMaybe<TeamFilter>
}

/** Sequel.io Webinar events [See type definition](https://app.contentful.com/spaces/9cxgygtadmvl/content_types/events) */
export type EventsSequelEventIdArgs = {
  locale?: InputMaybe<Scalars['String']>
}

/** Sequel.io Webinar events [See type definition](https://app.contentful.com/spaces/9cxgygtadmvl/content_types/events) */
export type EventsSlugArgs = {
  locale?: InputMaybe<Scalars['String']>
}

/** Sequel.io Webinar events [See type definition](https://app.contentful.com/spaces/9cxgygtadmvl/content_types/events) */
export type EventsStartDateArgs = {
  locale?: InputMaybe<Scalars['String']>
}

/** Sequel.io Webinar events [See type definition](https://app.contentful.com/spaces/9cxgygtadmvl/content_types/events) */
export type EventsStartTimeArgs = {
  locale?: InputMaybe<Scalars['String']>
}

/** Sequel.io Webinar events [See type definition](https://app.contentful.com/spaces/9cxgygtadmvl/content_types/events) */
export type EventsTimeZoneArgs = {
  locale?: InputMaybe<Scalars['String']>
}

export type EventsCollection = {
  __typename?: 'EventsCollection'
  items: Array<Maybe<Events>>
  limit: Scalars['Int']
  skip: Scalars['Int']
  total: Scalars['Int']
}

export type EventsEventDescription = {
  __typename?: 'EventsEventDescription'
  json: Scalars['JSON']
  links: EventsEventDescriptionLinks
}

export type EventsEventDescriptionAssets = {
  __typename?: 'EventsEventDescriptionAssets'
  block: Array<Maybe<Asset>>
  hyperlink: Array<Maybe<Asset>>
}

export type EventsEventDescriptionEntries = {
  __typename?: 'EventsEventDescriptionEntries'
  block: Array<Maybe<Entry>>
  hyperlink: Array<Maybe<Entry>>
  inline: Array<Maybe<Entry>>
}

export type EventsEventDescriptionLinks = {
  __typename?: 'EventsEventDescriptionLinks'
  assets: EventsEventDescriptionAssets
  entries: EventsEventDescriptionEntries
  resources: EventsEventDescriptionResources
}

export type EventsEventDescriptionResources = {
  __typename?: 'EventsEventDescriptionResources'
  block: Array<EventsEventDescriptionResourcesBlock>
  hyperlink: Array<EventsEventDescriptionResourcesHyperlink>
  inline: Array<EventsEventDescriptionResourcesInline>
}

export type EventsEventDescriptionResourcesBlock = ResourceLink & {
  __typename?: 'EventsEventDescriptionResourcesBlock'
  sys: ResourceSys
}

export type EventsEventDescriptionResourcesHyperlink = ResourceLink & {
  __typename?: 'EventsEventDescriptionResourcesHyperlink'
  sys: ResourceSys
}

export type EventsEventDescriptionResourcesInline = ResourceLink & {
  __typename?: 'EventsEventDescriptionResourcesInline'
  sys: ResourceSys
}

export type EventsFilter = {
  AND?: InputMaybe<Array<InputMaybe<EventsFilter>>>
  OR?: InputMaybe<Array<InputMaybe<EventsFilter>>>
  autoSelectLatestReplay?: InputMaybe<Scalars['Boolean']>
  autoSelectLatestReplay_exists?: InputMaybe<Scalars['Boolean']>
  autoSelectLatestReplay_not?: InputMaybe<Scalars['Boolean']>
  bannerImage_exists?: InputMaybe<Scalars['Boolean']>
  closeCaptioning?: InputMaybe<Scalars['Boolean']>
  closeCaptioning_exists?: InputMaybe<Scalars['Boolean']>
  closeCaptioning_not?: InputMaybe<Scalars['Boolean']>
  contentfulMetadata?: InputMaybe<ContentfulMetadataFilter>
  disableEventRecording?: InputMaybe<Scalars['Boolean']>
  disableEventRecording_exists?: InputMaybe<Scalars['Boolean']>
  disableEventRecording_not?: InputMaybe<Scalars['Boolean']>
  eventDescription_contains?: InputMaybe<Scalars['String']>
  eventDescription_exists?: InputMaybe<Scalars['Boolean']>
  eventDescription_not_contains?: InputMaybe<Scalars['String']>
  eventDuration?: InputMaybe<Scalars['String']>
  eventDuration_contains?: InputMaybe<Scalars['String']>
  eventDuration_exists?: InputMaybe<Scalars['Boolean']>
  eventDuration_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  eventDuration_not?: InputMaybe<Scalars['String']>
  eventDuration_not_contains?: InputMaybe<Scalars['String']>
  eventDuration_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  eventType?: InputMaybe<Scalars['String']>
  eventType_contains?: InputMaybe<Scalars['String']>
  eventType_exists?: InputMaybe<Scalars['Boolean']>
  eventType_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  eventType_not?: InputMaybe<Scalars['String']>
  eventType_not_contains?: InputMaybe<Scalars['String']>
  eventType_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  hideEvent?: InputMaybe<Scalars['Boolean']>
  hideEvent_exists?: InputMaybe<Scalars['Boolean']>
  hideEvent_not?: InputMaybe<Scalars['Boolean']>
  isAttendeeRegistrationModeEnabled?: InputMaybe<Scalars['Boolean']>
  isAttendeeRegistrationModeEnabled_exists?: InputMaybe<Scalars['Boolean']>
  isAttendeeRegistrationModeEnabled_not?: InputMaybe<Scalars['Boolean']>
  isRegistrationModeEnabled?: InputMaybe<Scalars['Boolean']>
  isRegistrationModeEnabled_exists?: InputMaybe<Scalars['Boolean']>
  isRegistrationModeEnabled_not?: InputMaybe<Scalars['Boolean']>
  name?: InputMaybe<Scalars['String']>
  name_contains?: InputMaybe<Scalars['String']>
  name_exists?: InputMaybe<Scalars['Boolean']>
  name_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  name_not?: InputMaybe<Scalars['String']>
  name_not_contains?: InputMaybe<Scalars['String']>
  name_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  organizers?: InputMaybe<CfTeamNestedFilter>
  organizersCollection_exists?: InputMaybe<Scalars['Boolean']>
  presenters?: InputMaybe<CfTeamNestedFilter>
  presentersCollection_exists?: InputMaybe<Scalars['Boolean']>
  sequelEventId?: InputMaybe<Scalars['String']>
  sequelEventId_contains?: InputMaybe<Scalars['String']>
  sequelEventId_exists?: InputMaybe<Scalars['Boolean']>
  sequelEventId_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  sequelEventId_not?: InputMaybe<Scalars['String']>
  sequelEventId_not_contains?: InputMaybe<Scalars['String']>
  sequelEventId_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  slug?: InputMaybe<Scalars['String']>
  slug_contains?: InputMaybe<Scalars['String']>
  slug_exists?: InputMaybe<Scalars['Boolean']>
  slug_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  slug_not?: InputMaybe<Scalars['String']>
  slug_not_contains?: InputMaybe<Scalars['String']>
  slug_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  startDate?: InputMaybe<Scalars['DateTime']>
  startDate_exists?: InputMaybe<Scalars['Boolean']>
  startDate_gt?: InputMaybe<Scalars['DateTime']>
  startDate_gte?: InputMaybe<Scalars['DateTime']>
  startDate_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']>>>
  startDate_lt?: InputMaybe<Scalars['DateTime']>
  startDate_lte?: InputMaybe<Scalars['DateTime']>
  startDate_not?: InputMaybe<Scalars['DateTime']>
  startDate_not_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']>>>
  startTime?: InputMaybe<Scalars['String']>
  startTime_contains?: InputMaybe<Scalars['String']>
  startTime_exists?: InputMaybe<Scalars['Boolean']>
  startTime_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  startTime_not?: InputMaybe<Scalars['String']>
  startTime_not_contains?: InputMaybe<Scalars['String']>
  startTime_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  sys?: InputMaybe<SysFilter>
  timeZone?: InputMaybe<Scalars['String']>
  timeZone_contains?: InputMaybe<Scalars['String']>
  timeZone_exists?: InputMaybe<Scalars['Boolean']>
  timeZone_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  timeZone_not?: InputMaybe<Scalars['String']>
  timeZone_not_contains?: InputMaybe<Scalars['String']>
  timeZone_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
}

export type EventsLinkingCollections = {
  __typename?: 'EventsLinkingCollections'
  entryCollection?: Maybe<EntryCollection>
}

export type EventsLinkingCollectionsEntryCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>
  locale?: InputMaybe<Scalars['String']>
  preview?: InputMaybe<Scalars['Boolean']>
  skip?: InputMaybe<Scalars['Int']>
}

export enum EventsOrder {
  AutoSelectLatestReplayAsc = 'autoSelectLatestReplay_ASC',
  AutoSelectLatestReplayDesc = 'autoSelectLatestReplay_DESC',
  CloseCaptioningAsc = 'closeCaptioning_ASC',
  CloseCaptioningDesc = 'closeCaptioning_DESC',
  DisableEventRecordingAsc = 'disableEventRecording_ASC',
  DisableEventRecordingDesc = 'disableEventRecording_DESC',
  EventDurationAsc = 'eventDuration_ASC',
  EventDurationDesc = 'eventDuration_DESC',
  EventTypeAsc = 'eventType_ASC',
  EventTypeDesc = 'eventType_DESC',
  HideEventAsc = 'hideEvent_ASC',
  HideEventDesc = 'hideEvent_DESC',
  IsAttendeeRegistrationModeEnabledAsc = 'isAttendeeRegistrationModeEnabled_ASC',
  IsAttendeeRegistrationModeEnabledDesc = 'isAttendeeRegistrationModeEnabled_DESC',
  IsRegistrationModeEnabledAsc = 'isRegistrationModeEnabled_ASC',
  IsRegistrationModeEnabledDesc = 'isRegistrationModeEnabled_DESC',
  NameAsc = 'name_ASC',
  NameDesc = 'name_DESC',
  SequelEventIdAsc = 'sequelEventId_ASC',
  SequelEventIdDesc = 'sequelEventId_DESC',
  SlugAsc = 'slug_ASC',
  SlugDesc = 'slug_DESC',
  StartDateAsc = 'startDate_ASC',
  StartDateDesc = 'startDate_DESC',
  StartTimeAsc = 'startTime_ASC',
  StartTimeDesc = 'startTime_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  TimeZoneAsc = 'timeZone_ASC',
  TimeZoneDesc = 'timeZone_DESC',
}

export type EventsOrganizersCollection = {
  __typename?: 'EventsOrganizersCollection'
  items: Array<Maybe<Team>>
  limit: Scalars['Int']
  skip: Scalars['Int']
  total: Scalars['Int']
}

export enum EventsOrganizersCollectionOrder {
  EmailAsc = 'email_ASC',
  EmailDesc = 'email_DESC',
  NameAsc = 'name_ASC',
  NameDesc = 'name_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC',
}

export type EventsPresentersCollection = {
  __typename?: 'EventsPresentersCollection'
  items: Array<Maybe<Team>>
  limit: Scalars['Int']
  skip: Scalars['Int']
  total: Scalars['Int']
}

export enum EventsPresentersCollectionOrder {
  EmailAsc = 'email_ASC',
  EmailDesc = 'email_DESC',
  NameAsc = 'name_ASC',
  NameDesc = 'name_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC',
}

/** Create and manage all groups and categories of FAQs. [See type definition](https://app.contentful.com/spaces/9cxgygtadmvl/content_types/faqsQuestionGroups) */
export type FaqsQuestionGroups = Entry &
  _Node & {
    __typename?: 'FaqsQuestionGroups'
    _id: Scalars['ID']
    adminLabel?: Maybe<Scalars['String']>
    automaticallyExpandQuestions?: Maybe<Scalars['Boolean']>
    contentfulMetadata: ContentfulMetadata
    linkedFrom?: Maybe<FaqsQuestionGroupsLinkingCollections>
    questionsCollection?: Maybe<FaqsQuestionGroupsQuestionsCollection>
    richTitle?: Maybe<FaqsQuestionGroupsRichTitle>
    sectionId?: Maybe<Scalars['String']>
    sys: Sys
    title?: Maybe<Scalars['String']>
    titleStyle?: Maybe<Scalars['String']>
  }

/** Create and manage all groups and categories of FAQs. [See type definition](https://app.contentful.com/spaces/9cxgygtadmvl/content_types/faqsQuestionGroups) */
export type FaqsQuestionGroupsAdminLabelArgs = {
  locale?: InputMaybe<Scalars['String']>
}

/** Create and manage all groups and categories of FAQs. [See type definition](https://app.contentful.com/spaces/9cxgygtadmvl/content_types/faqsQuestionGroups) */
export type FaqsQuestionGroupsAutomaticallyExpandQuestionsArgs = {
  locale?: InputMaybe<Scalars['String']>
}

/** Create and manage all groups and categories of FAQs. [See type definition](https://app.contentful.com/spaces/9cxgygtadmvl/content_types/faqsQuestionGroups) */
export type FaqsQuestionGroupsLinkedFromArgs = {
  allowedLocales?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
}

/** Create and manage all groups and categories of FAQs. [See type definition](https://app.contentful.com/spaces/9cxgygtadmvl/content_types/faqsQuestionGroups) */
export type FaqsQuestionGroupsQuestionsCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>
  locale?: InputMaybe<Scalars['String']>
  order?: InputMaybe<
    Array<InputMaybe<FaqsQuestionGroupsQuestionsCollectionOrder>>
  >
  preview?: InputMaybe<Scalars['Boolean']>
  skip?: InputMaybe<Scalars['Int']>
  where?: InputMaybe<FaqsQuestionsFilter>
}

/** Create and manage all groups and categories of FAQs. [See type definition](https://app.contentful.com/spaces/9cxgygtadmvl/content_types/faqsQuestionGroups) */
export type FaqsQuestionGroupsRichTitleArgs = {
  locale?: InputMaybe<Scalars['String']>
}

/** Create and manage all groups and categories of FAQs. [See type definition](https://app.contentful.com/spaces/9cxgygtadmvl/content_types/faqsQuestionGroups) */
export type FaqsQuestionGroupsSectionIdArgs = {
  locale?: InputMaybe<Scalars['String']>
}

/** Create and manage all groups and categories of FAQs. [See type definition](https://app.contentful.com/spaces/9cxgygtadmvl/content_types/faqsQuestionGroups) */
export type FaqsQuestionGroupsTitleArgs = {
  locale?: InputMaybe<Scalars['String']>
}

/** Create and manage all groups and categories of FAQs. [See type definition](https://app.contentful.com/spaces/9cxgygtadmvl/content_types/faqsQuestionGroups) */
export type FaqsQuestionGroupsTitleStyleArgs = {
  locale?: InputMaybe<Scalars['String']>
}

export type FaqsQuestionGroupsCollection = {
  __typename?: 'FaqsQuestionGroupsCollection'
  items: Array<Maybe<FaqsQuestionGroups>>
  limit: Scalars['Int']
  skip: Scalars['Int']
  total: Scalars['Int']
}

export type FaqsQuestionGroupsFilter = {
  AND?: InputMaybe<Array<InputMaybe<FaqsQuestionGroupsFilter>>>
  OR?: InputMaybe<Array<InputMaybe<FaqsQuestionGroupsFilter>>>
  adminLabel?: InputMaybe<Scalars['String']>
  adminLabel_contains?: InputMaybe<Scalars['String']>
  adminLabel_exists?: InputMaybe<Scalars['Boolean']>
  adminLabel_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  adminLabel_not?: InputMaybe<Scalars['String']>
  adminLabel_not_contains?: InputMaybe<Scalars['String']>
  adminLabel_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  automaticallyExpandQuestions?: InputMaybe<Scalars['Boolean']>
  automaticallyExpandQuestions_exists?: InputMaybe<Scalars['Boolean']>
  automaticallyExpandQuestions_not?: InputMaybe<Scalars['Boolean']>
  contentfulMetadata?: InputMaybe<ContentfulMetadataFilter>
  questions?: InputMaybe<CfFaqsQuestionsNestedFilter>
  questionsCollection_exists?: InputMaybe<Scalars['Boolean']>
  richTitle_contains?: InputMaybe<Scalars['String']>
  richTitle_exists?: InputMaybe<Scalars['Boolean']>
  richTitle_not_contains?: InputMaybe<Scalars['String']>
  sectionId?: InputMaybe<Scalars['String']>
  sectionId_contains?: InputMaybe<Scalars['String']>
  sectionId_exists?: InputMaybe<Scalars['Boolean']>
  sectionId_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  sectionId_not?: InputMaybe<Scalars['String']>
  sectionId_not_contains?: InputMaybe<Scalars['String']>
  sectionId_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  sys?: InputMaybe<SysFilter>
  title?: InputMaybe<Scalars['String']>
  titleStyle?: InputMaybe<Scalars['String']>
  titleStyle_contains?: InputMaybe<Scalars['String']>
  titleStyle_exists?: InputMaybe<Scalars['Boolean']>
  titleStyle_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  titleStyle_not?: InputMaybe<Scalars['String']>
  titleStyle_not_contains?: InputMaybe<Scalars['String']>
  titleStyle_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  title_contains?: InputMaybe<Scalars['String']>
  title_exists?: InputMaybe<Scalars['Boolean']>
  title_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  title_not?: InputMaybe<Scalars['String']>
  title_not_contains?: InputMaybe<Scalars['String']>
  title_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
}

export type FaqsQuestionGroupsLinkingCollections = {
  __typename?: 'FaqsQuestionGroupsLinkingCollections'
  campaignPagesCollection?: Maybe<CampaignPagesCollection>
  entryCollection?: Maybe<EntryCollection>
  entryPartnerCollection?: Maybe<EntryPartnerCollection>
  templateAboutUsCollection?: Maybe<TemplateAboutUsCollection>
  templateAdvisorsCollection?: Maybe<TemplateAdvisorsCollection>
  templateBookingCollection?: Maybe<TemplateBookingCollection>
  templateCareersCollection?: Maybe<TemplateCareersCollection>
  templateContactUsCollection?: Maybe<TemplateContactUsCollection>
  templateDestinationsCollection?: Maybe<TemplateDestinationsCollection>
  templateFaqsCollection?: Maybe<TemplateFaqsCollection>
  templateForaReserveCollection?: Maybe<TemplateForaReserveCollection>
  templateForaXCollection?: Maybe<TemplateForaXCollection>
  templateGuidesCollection?: Maybe<TemplateGuidesCollection>
  templateHomeCollection?: Maybe<TemplateHomeCollection>
  templateItinerariesCollection?: Maybe<TemplateItinerariesCollection>
  templateJoinUsCollection?: Maybe<TemplateJoinUsCollection>
  templateNewsletterCollection?: Maybe<TemplateNewsletterCollection>
  templatePageCollection?: Maybe<TemplatePageCollection>
  templatePartnersCollection?: Maybe<TemplatePartnersCollection>
  templateReviewsCollection?: Maybe<TemplateReviewsCollection>
  templateTravelByCollection?: Maybe<TemplateTravelByCollection>
  templateTravelCollection?: Maybe<TemplateTravelCollection>
  templateWhyBookWithForaCollection?: Maybe<TemplateWhyBookWithForaCollection>
}

export type FaqsQuestionGroupsLinkingCollectionsCampaignPagesCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>
  locale?: InputMaybe<Scalars['String']>
  order?: InputMaybe<
    Array<
      InputMaybe<FaqsQuestionGroupsLinkingCollectionsCampaignPagesCollectionOrder>
    >
  >
  preview?: InputMaybe<Scalars['Boolean']>
  skip?: InputMaybe<Scalars['Int']>
}

export type FaqsQuestionGroupsLinkingCollectionsEntryCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>
  locale?: InputMaybe<Scalars['String']>
  preview?: InputMaybe<Scalars['Boolean']>
  skip?: InputMaybe<Scalars['Int']>
}

export type FaqsQuestionGroupsLinkingCollectionsEntryPartnerCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>
  locale?: InputMaybe<Scalars['String']>
  order?: InputMaybe<
    Array<
      InputMaybe<FaqsQuestionGroupsLinkingCollectionsEntryPartnerCollectionOrder>
    >
  >
  preview?: InputMaybe<Scalars['Boolean']>
  skip?: InputMaybe<Scalars['Int']>
}

export type FaqsQuestionGroupsLinkingCollectionsTemplateAboutUsCollectionArgs =
  {
    limit?: InputMaybe<Scalars['Int']>
    locale?: InputMaybe<Scalars['String']>
    order?: InputMaybe<
      Array<
        InputMaybe<FaqsQuestionGroupsLinkingCollectionsTemplateAboutUsCollectionOrder>
      >
    >
    preview?: InputMaybe<Scalars['Boolean']>
    skip?: InputMaybe<Scalars['Int']>
  }

export type FaqsQuestionGroupsLinkingCollectionsTemplateAdvisorsCollectionArgs =
  {
    limit?: InputMaybe<Scalars['Int']>
    locale?: InputMaybe<Scalars['String']>
    order?: InputMaybe<
      Array<
        InputMaybe<FaqsQuestionGroupsLinkingCollectionsTemplateAdvisorsCollectionOrder>
      >
    >
    preview?: InputMaybe<Scalars['Boolean']>
    skip?: InputMaybe<Scalars['Int']>
  }

export type FaqsQuestionGroupsLinkingCollectionsTemplateBookingCollectionArgs =
  {
    limit?: InputMaybe<Scalars['Int']>
    locale?: InputMaybe<Scalars['String']>
    order?: InputMaybe<
      Array<
        InputMaybe<FaqsQuestionGroupsLinkingCollectionsTemplateBookingCollectionOrder>
      >
    >
    preview?: InputMaybe<Scalars['Boolean']>
    skip?: InputMaybe<Scalars['Int']>
  }

export type FaqsQuestionGroupsLinkingCollectionsTemplateCareersCollectionArgs =
  {
    limit?: InputMaybe<Scalars['Int']>
    locale?: InputMaybe<Scalars['String']>
    order?: InputMaybe<
      Array<
        InputMaybe<FaqsQuestionGroupsLinkingCollectionsTemplateCareersCollectionOrder>
      >
    >
    preview?: InputMaybe<Scalars['Boolean']>
    skip?: InputMaybe<Scalars['Int']>
  }

export type FaqsQuestionGroupsLinkingCollectionsTemplateContactUsCollectionArgs =
  {
    limit?: InputMaybe<Scalars['Int']>
    locale?: InputMaybe<Scalars['String']>
    order?: InputMaybe<
      Array<
        InputMaybe<FaqsQuestionGroupsLinkingCollectionsTemplateContactUsCollectionOrder>
      >
    >
    preview?: InputMaybe<Scalars['Boolean']>
    skip?: InputMaybe<Scalars['Int']>
  }

export type FaqsQuestionGroupsLinkingCollectionsTemplateDestinationsCollectionArgs =
  {
    limit?: InputMaybe<Scalars['Int']>
    locale?: InputMaybe<Scalars['String']>
    order?: InputMaybe<
      Array<
        InputMaybe<FaqsQuestionGroupsLinkingCollectionsTemplateDestinationsCollectionOrder>
      >
    >
    preview?: InputMaybe<Scalars['Boolean']>
    skip?: InputMaybe<Scalars['Int']>
  }

export type FaqsQuestionGroupsLinkingCollectionsTemplateFaqsCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>
  locale?: InputMaybe<Scalars['String']>
  order?: InputMaybe<
    Array<
      InputMaybe<FaqsQuestionGroupsLinkingCollectionsTemplateFaqsCollectionOrder>
    >
  >
  preview?: InputMaybe<Scalars['Boolean']>
  skip?: InputMaybe<Scalars['Int']>
}

export type FaqsQuestionGroupsLinkingCollectionsTemplateForaReserveCollectionArgs =
  {
    limit?: InputMaybe<Scalars['Int']>
    locale?: InputMaybe<Scalars['String']>
    order?: InputMaybe<
      Array<
        InputMaybe<FaqsQuestionGroupsLinkingCollectionsTemplateForaReserveCollectionOrder>
      >
    >
    preview?: InputMaybe<Scalars['Boolean']>
    skip?: InputMaybe<Scalars['Int']>
  }

export type FaqsQuestionGroupsLinkingCollectionsTemplateForaXCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>
  locale?: InputMaybe<Scalars['String']>
  order?: InputMaybe<
    Array<
      InputMaybe<FaqsQuestionGroupsLinkingCollectionsTemplateForaXCollectionOrder>
    >
  >
  preview?: InputMaybe<Scalars['Boolean']>
  skip?: InputMaybe<Scalars['Int']>
}

export type FaqsQuestionGroupsLinkingCollectionsTemplateGuidesCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>
  locale?: InputMaybe<Scalars['String']>
  order?: InputMaybe<
    Array<
      InputMaybe<FaqsQuestionGroupsLinkingCollectionsTemplateGuidesCollectionOrder>
    >
  >
  preview?: InputMaybe<Scalars['Boolean']>
  skip?: InputMaybe<Scalars['Int']>
}

export type FaqsQuestionGroupsLinkingCollectionsTemplateHomeCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>
  locale?: InputMaybe<Scalars['String']>
  order?: InputMaybe<
    Array<
      InputMaybe<FaqsQuestionGroupsLinkingCollectionsTemplateHomeCollectionOrder>
    >
  >
  preview?: InputMaybe<Scalars['Boolean']>
  skip?: InputMaybe<Scalars['Int']>
}

export type FaqsQuestionGroupsLinkingCollectionsTemplateItinerariesCollectionArgs =
  {
    limit?: InputMaybe<Scalars['Int']>
    locale?: InputMaybe<Scalars['String']>
    order?: InputMaybe<
      Array<
        InputMaybe<FaqsQuestionGroupsLinkingCollectionsTemplateItinerariesCollectionOrder>
      >
    >
    preview?: InputMaybe<Scalars['Boolean']>
    skip?: InputMaybe<Scalars['Int']>
  }

export type FaqsQuestionGroupsLinkingCollectionsTemplateJoinUsCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>
  locale?: InputMaybe<Scalars['String']>
  order?: InputMaybe<
    Array<
      InputMaybe<FaqsQuestionGroupsLinkingCollectionsTemplateJoinUsCollectionOrder>
    >
  >
  preview?: InputMaybe<Scalars['Boolean']>
  skip?: InputMaybe<Scalars['Int']>
}

export type FaqsQuestionGroupsLinkingCollectionsTemplateNewsletterCollectionArgs =
  {
    limit?: InputMaybe<Scalars['Int']>
    locale?: InputMaybe<Scalars['String']>
    order?: InputMaybe<
      Array<
        InputMaybe<FaqsQuestionGroupsLinkingCollectionsTemplateNewsletterCollectionOrder>
      >
    >
    preview?: InputMaybe<Scalars['Boolean']>
    skip?: InputMaybe<Scalars['Int']>
  }

export type FaqsQuestionGroupsLinkingCollectionsTemplatePageCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>
  locale?: InputMaybe<Scalars['String']>
  order?: InputMaybe<
    Array<
      InputMaybe<FaqsQuestionGroupsLinkingCollectionsTemplatePageCollectionOrder>
    >
  >
  preview?: InputMaybe<Scalars['Boolean']>
  skip?: InputMaybe<Scalars['Int']>
}

export type FaqsQuestionGroupsLinkingCollectionsTemplatePartnersCollectionArgs =
  {
    limit?: InputMaybe<Scalars['Int']>
    locale?: InputMaybe<Scalars['String']>
    order?: InputMaybe<
      Array<
        InputMaybe<FaqsQuestionGroupsLinkingCollectionsTemplatePartnersCollectionOrder>
      >
    >
    preview?: InputMaybe<Scalars['Boolean']>
    skip?: InputMaybe<Scalars['Int']>
  }

export type FaqsQuestionGroupsLinkingCollectionsTemplateReviewsCollectionArgs =
  {
    limit?: InputMaybe<Scalars['Int']>
    locale?: InputMaybe<Scalars['String']>
    order?: InputMaybe<
      Array<
        InputMaybe<FaqsQuestionGroupsLinkingCollectionsTemplateReviewsCollectionOrder>
      >
    >
    preview?: InputMaybe<Scalars['Boolean']>
    skip?: InputMaybe<Scalars['Int']>
  }

export type FaqsQuestionGroupsLinkingCollectionsTemplateTravelByCollectionArgs =
  {
    limit?: InputMaybe<Scalars['Int']>
    locale?: InputMaybe<Scalars['String']>
    order?: InputMaybe<
      Array<
        InputMaybe<FaqsQuestionGroupsLinkingCollectionsTemplateTravelByCollectionOrder>
      >
    >
    preview?: InputMaybe<Scalars['Boolean']>
    skip?: InputMaybe<Scalars['Int']>
  }

export type FaqsQuestionGroupsLinkingCollectionsTemplateTravelCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>
  locale?: InputMaybe<Scalars['String']>
  order?: InputMaybe<
    Array<
      InputMaybe<FaqsQuestionGroupsLinkingCollectionsTemplateTravelCollectionOrder>
    >
  >
  preview?: InputMaybe<Scalars['Boolean']>
  skip?: InputMaybe<Scalars['Int']>
}

export type FaqsQuestionGroupsLinkingCollectionsTemplateWhyBookWithForaCollectionArgs =
  {
    limit?: InputMaybe<Scalars['Int']>
    locale?: InputMaybe<Scalars['String']>
    order?: InputMaybe<
      Array<
        InputMaybe<FaqsQuestionGroupsLinkingCollectionsTemplateWhyBookWithForaCollectionOrder>
      >
    >
    preview?: InputMaybe<Scalars['Boolean']>
    skip?: InputMaybe<Scalars['Int']>
  }

export enum FaqsQuestionGroupsLinkingCollectionsCampaignPagesCollectionOrder {
  CollapseFooterAsc = 'collapseFooter_ASC',
  CollapseFooterDesc = 'collapseFooter_DESC',
  CollapseHeaderAsc = 'collapseHeader_ASC',
  CollapseHeaderDesc = 'collapseHeader_DESC',
  HideFooterAsc = 'hideFooter_ASC',
  HideFooterDesc = 'hideFooter_DESC',
  HideGlobalBannerAsc = 'hideGlobalBanner_ASC',
  HideGlobalBannerDesc = 'hideGlobalBanner_DESC',
  JotformIdAsc = 'jotformId_ASC',
  JotformIdDesc = 'jotformId_DESC',
  NoindexNofollowAsc = 'noindexNofollow_ASC',
  NoindexNofollowDesc = 'noindexNofollow_DESC',
  ShowHeaderAsc = 'showHeader_ASC',
  ShowHeaderDesc = 'showHeader_DESC',
  SlugAsc = 'slug_ASC',
  SlugDesc = 'slug_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC',
}

export enum FaqsQuestionGroupsLinkingCollectionsEntryPartnerCollectionOrder {
  DetailSummaryAsc = 'detailSummary_ASC',
  DetailSummaryDesc = 'detailSummary_DESC',
  FeaturedAsc = 'featured_ASC',
  FeaturedDesc = 'featured_DESC',
  HasDetailPageAsc = 'hasDetailPage_ASC',
  HasDetailPageDesc = 'hasDetailPage_DESC',
  HideFromPartnerGridAsc = 'hideFromPartnerGrid_ASC',
  HideFromPartnerGridDesc = 'hideFromPartnerGrid_DESC',
  NameAsc = 'name_ASC',
  NameDesc = 'name_DESC',
  PartnerCategoryAsc = 'partnerCategory_ASC',
  PartnerCategoryDesc = 'partnerCategory_DESC',
  PartnerTypeAsc = 'partnerType_ASC',
  PartnerTypeDesc = 'partnerType_DESC',
  SlugAsc = 'slug_ASC',
  SlugDesc = 'slug_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
}

export enum FaqsQuestionGroupsLinkingCollectionsTemplateAboutUsCollectionOrder {
  CollapseHeaderAsc = 'collapseHeader_ASC',
  CollapseHeaderDesc = 'collapseHeader_DESC',
  HeaderBlockTitleAsc = 'headerBlockTitle_ASC',
  HeaderBlockTitleDesc = 'headerBlockTitle_DESC',
  ShowHeaderAsc = 'showHeader_ASC',
  ShowHeaderDesc = 'showHeader_DESC',
  SubtitleAsc = 'subtitle_ASC',
  SubtitleDesc = 'subtitle_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC',
}

export enum FaqsQuestionGroupsLinkingCollectionsTemplateAdvisorsCollectionOrder {
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC',
}

export enum FaqsQuestionGroupsLinkingCollectionsTemplateBookingCollectionOrder {
  CollapseHeaderAsc = 'collapseHeader_ASC',
  CollapseHeaderDesc = 'collapseHeader_DESC',
  ShowHeaderAsc = 'showHeader_ASC',
  ShowHeaderDesc = 'showHeader_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC',
}

export enum FaqsQuestionGroupsLinkingCollectionsTemplateCareersCollectionOrder {
  CollapseHeaderAsc = 'collapseHeader_ASC',
  CollapseHeaderDesc = 'collapseHeader_DESC',
  ShowHeaderAsc = 'showHeader_ASC',
  ShowHeaderDesc = 'showHeader_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC',
}

export enum FaqsQuestionGroupsLinkingCollectionsTemplateContactUsCollectionOrder {
  CollapseHeaderAsc = 'collapseHeader_ASC',
  CollapseHeaderDesc = 'collapseHeader_DESC',
  ShowHeaderAsc = 'showHeader_ASC',
  ShowHeaderDesc = 'showHeader_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC',
}

export enum FaqsQuestionGroupsLinkingCollectionsTemplateDestinationsCollectionOrder {
  CollapseHeaderAsc = 'collapseHeader_ASC',
  CollapseHeaderDesc = 'collapseHeader_DESC',
  CollapsibleHeadingAsc = 'collapsibleHeading_ASC',
  CollapsibleHeadingDesc = 'collapsibleHeading_DESC',
  ShowHeaderAsc = 'showHeader_ASC',
  ShowHeaderDesc = 'showHeader_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC',
}

export enum FaqsQuestionGroupsLinkingCollectionsTemplateFaqsCollectionOrder {
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC',
}

export enum FaqsQuestionGroupsLinkingCollectionsTemplateForaReserveCollectionOrder {
  CollapseHeaderAsc = 'collapseHeader_ASC',
  CollapseHeaderDesc = 'collapseHeader_DESC',
  ShowHeaderAsc = 'showHeader_ASC',
  ShowHeaderDesc = 'showHeader_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC',
}

export enum FaqsQuestionGroupsLinkingCollectionsTemplateForaXCollectionOrder {
  AdvisorGridHeaderAsc = 'advisorGridHeader_ASC',
  AdvisorGridHeaderDesc = 'advisorGridHeader_DESC',
  AdvisorGridSubCopyAsc = 'advisorGridSubCopy_ASC',
  AdvisorGridSubCopyDesc = 'advisorGridSubCopy_DESC',
  CollapseHeaderAsc = 'collapseHeader_ASC',
  CollapseHeaderDesc = 'collapseHeader_DESC',
  ShowHeaderAsc = 'showHeader_ASC',
  ShowHeaderDesc = 'showHeader_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC',
}

export enum FaqsQuestionGroupsLinkingCollectionsTemplateGuidesCollectionOrder {
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC',
}

export enum FaqsQuestionGroupsLinkingCollectionsTemplateHomeCollectionOrder {
  CollapseHeaderAsc = 'collapseHeader_ASC',
  CollapseHeaderDesc = 'collapseHeader_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC',
}

export enum FaqsQuestionGroupsLinkingCollectionsTemplateItinerariesCollectionOrder {
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC',
}

export enum FaqsQuestionGroupsLinkingCollectionsTemplateJoinUsCollectionOrder {
  CollapseHeaderAsc = 'collapseHeader_ASC',
  CollapseHeaderDesc = 'collapseHeader_DESC',
  HideGlobalBannerAsc = 'hideGlobalBanner_ASC',
  HideGlobalBannerDesc = 'hideGlobalBanner_DESC',
  ShowHeaderAsc = 'showHeader_ASC',
  ShowHeaderDesc = 'showHeader_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC',
}

export enum FaqsQuestionGroupsLinkingCollectionsTemplateNewsletterCollectionOrder {
  CollapseHeaderAsc = 'collapseHeader_ASC',
  CollapseHeaderDesc = 'collapseHeader_DESC',
  ShowHeaderAsc = 'showHeader_ASC',
  ShowHeaderDesc = 'showHeader_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC',
}

export enum FaqsQuestionGroupsLinkingCollectionsTemplatePageCollectionOrder {
  DirectoryAsc = 'directory_ASC',
  DirectoryDesc = 'directory_DESC',
  InternalLabelAsc = 'internalLabel_ASC',
  InternalLabelDesc = 'internalLabel_DESC',
  MetaTitleAsc = 'metaTitle_ASC',
  MetaTitleDesc = 'metaTitle_DESC',
  NoindexNofollowAsc = 'noindexNofollow_ASC',
  NoindexNofollowDesc = 'noindexNofollow_DESC',
  SlugAsc = 'slug_ASC',
  SlugDesc = 'slug_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
}

export enum FaqsQuestionGroupsLinkingCollectionsTemplatePartnersCollectionOrder {
  CollapseHeaderAsc = 'collapseHeader_ASC',
  CollapseHeaderDesc = 'collapseHeader_DESC',
  ShowHeaderAsc = 'showHeader_ASC',
  ShowHeaderDesc = 'showHeader_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC',
}

export enum FaqsQuestionGroupsLinkingCollectionsTemplateReviewsCollectionOrder {
  CollapseHeaderAsc = 'collapseHeader_ASC',
  CollapseHeaderDesc = 'collapseHeader_DESC',
  ShowHeaderAsc = 'showHeader_ASC',
  ShowHeaderDesc = 'showHeader_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC',
}

export enum FaqsQuestionGroupsLinkingCollectionsTemplateTravelByCollectionOrder {
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC',
}

export enum FaqsQuestionGroupsLinkingCollectionsTemplateTravelCollectionOrder {
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC',
  TravelByHeadingAsc = 'travelByHeading_ASC',
  TravelByHeadingDesc = 'travelByHeading_DESC',
}

export enum FaqsQuestionGroupsLinkingCollectionsTemplateWhyBookWithForaCollectionOrder {
  CollapseHeaderAsc = 'collapseHeader_ASC',
  CollapseHeaderDesc = 'collapseHeader_DESC',
  ShowHeaderAsc = 'showHeader_ASC',
  ShowHeaderDesc = 'showHeader_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC',
}

export enum FaqsQuestionGroupsOrder {
  AdminLabelAsc = 'adminLabel_ASC',
  AdminLabelDesc = 'adminLabel_DESC',
  AutomaticallyExpandQuestionsAsc = 'automaticallyExpandQuestions_ASC',
  AutomaticallyExpandQuestionsDesc = 'automaticallyExpandQuestions_DESC',
  SectionIdAsc = 'sectionId_ASC',
  SectionIdDesc = 'sectionId_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  TitleStyleAsc = 'titleStyle_ASC',
  TitleStyleDesc = 'titleStyle_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC',
}

export type FaqsQuestionGroupsQuestionsCollection = {
  __typename?: 'FaqsQuestionGroupsQuestionsCollection'
  items: Array<Maybe<FaqsQuestions>>
  limit: Scalars['Int']
  skip: Scalars['Int']
  total: Scalars['Int']
}

export enum FaqsQuestionGroupsQuestionsCollectionOrder {
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC',
}

export type FaqsQuestionGroupsRichTitle = {
  __typename?: 'FaqsQuestionGroupsRichTitle'
  json: Scalars['JSON']
  links: FaqsQuestionGroupsRichTitleLinks
}

export type FaqsQuestionGroupsRichTitleAssets = {
  __typename?: 'FaqsQuestionGroupsRichTitleAssets'
  block: Array<Maybe<Asset>>
  hyperlink: Array<Maybe<Asset>>
}

export type FaqsQuestionGroupsRichTitleEntries = {
  __typename?: 'FaqsQuestionGroupsRichTitleEntries'
  block: Array<Maybe<Entry>>
  hyperlink: Array<Maybe<Entry>>
  inline: Array<Maybe<Entry>>
}

export type FaqsQuestionGroupsRichTitleLinks = {
  __typename?: 'FaqsQuestionGroupsRichTitleLinks'
  assets: FaqsQuestionGroupsRichTitleAssets
  entries: FaqsQuestionGroupsRichTitleEntries
  resources: FaqsQuestionGroupsRichTitleResources
}

export type FaqsQuestionGroupsRichTitleResources = {
  __typename?: 'FaqsQuestionGroupsRichTitleResources'
  block: Array<FaqsQuestionGroupsRichTitleResourcesBlock>
  hyperlink: Array<FaqsQuestionGroupsRichTitleResourcesHyperlink>
  inline: Array<FaqsQuestionGroupsRichTitleResourcesInline>
}

export type FaqsQuestionGroupsRichTitleResourcesBlock = ResourceLink & {
  __typename?: 'FaqsQuestionGroupsRichTitleResourcesBlock'
  sys: ResourceSys
}

export type FaqsQuestionGroupsRichTitleResourcesHyperlink = ResourceLink & {
  __typename?: 'FaqsQuestionGroupsRichTitleResourcesHyperlink'
  sys: ResourceSys
}

export type FaqsQuestionGroupsRichTitleResourcesInline = ResourceLink & {
  __typename?: 'FaqsQuestionGroupsRichTitleResourcesInline'
  sys: ResourceSys
}

/** Create and manage all FAQ questions. [See type definition](https://app.contentful.com/spaces/9cxgygtadmvl/content_types/faqsQuestions) */
export type FaqsQuestions = Entry &
  _Node & {
    __typename?: 'FaqsQuestions'
    _id: Scalars['ID']
    answer?: Maybe<FaqsQuestionsAnswer>
    contentfulMetadata: ContentfulMetadata
    linkedFrom?: Maybe<FaqsQuestionsLinkingCollections>
    sys: Sys
    title?: Maybe<Scalars['String']>
  }

/** Create and manage all FAQ questions. [See type definition](https://app.contentful.com/spaces/9cxgygtadmvl/content_types/faqsQuestions) */
export type FaqsQuestionsAnswerArgs = {
  locale?: InputMaybe<Scalars['String']>
}

/** Create and manage all FAQ questions. [See type definition](https://app.contentful.com/spaces/9cxgygtadmvl/content_types/faqsQuestions) */
export type FaqsQuestionsLinkedFromArgs = {
  allowedLocales?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
}

/** Create and manage all FAQ questions. [See type definition](https://app.contentful.com/spaces/9cxgygtadmvl/content_types/faqsQuestions) */
export type FaqsQuestionsTitleArgs = {
  locale?: InputMaybe<Scalars['String']>
}

export type FaqsQuestionsAnswer = {
  __typename?: 'FaqsQuestionsAnswer'
  json: Scalars['JSON']
  links: FaqsQuestionsAnswerLinks
}

export type FaqsQuestionsAnswerAssets = {
  __typename?: 'FaqsQuestionsAnswerAssets'
  block: Array<Maybe<Asset>>
  hyperlink: Array<Maybe<Asset>>
}

export type FaqsQuestionsAnswerEntries = {
  __typename?: 'FaqsQuestionsAnswerEntries'
  block: Array<Maybe<Entry>>
  hyperlink: Array<Maybe<Entry>>
  inline: Array<Maybe<Entry>>
}

export type FaqsQuestionsAnswerLinks = {
  __typename?: 'FaqsQuestionsAnswerLinks'
  assets: FaqsQuestionsAnswerAssets
  entries: FaqsQuestionsAnswerEntries
  resources: FaqsQuestionsAnswerResources
}

export type FaqsQuestionsAnswerResources = {
  __typename?: 'FaqsQuestionsAnswerResources'
  block: Array<FaqsQuestionsAnswerResourcesBlock>
  hyperlink: Array<FaqsQuestionsAnswerResourcesHyperlink>
  inline: Array<FaqsQuestionsAnswerResourcesInline>
}

export type FaqsQuestionsAnswerResourcesBlock = ResourceLink & {
  __typename?: 'FaqsQuestionsAnswerResourcesBlock'
  sys: ResourceSys
}

export type FaqsQuestionsAnswerResourcesHyperlink = ResourceLink & {
  __typename?: 'FaqsQuestionsAnswerResourcesHyperlink'
  sys: ResourceSys
}

export type FaqsQuestionsAnswerResourcesInline = ResourceLink & {
  __typename?: 'FaqsQuestionsAnswerResourcesInline'
  sys: ResourceSys
}

export type FaqsQuestionsCollection = {
  __typename?: 'FaqsQuestionsCollection'
  items: Array<Maybe<FaqsQuestions>>
  limit: Scalars['Int']
  skip: Scalars['Int']
  total: Scalars['Int']
}

export type FaqsQuestionsFilter = {
  AND?: InputMaybe<Array<InputMaybe<FaqsQuestionsFilter>>>
  OR?: InputMaybe<Array<InputMaybe<FaqsQuestionsFilter>>>
  answer_contains?: InputMaybe<Scalars['String']>
  answer_exists?: InputMaybe<Scalars['Boolean']>
  answer_not_contains?: InputMaybe<Scalars['String']>
  contentfulMetadata?: InputMaybe<ContentfulMetadataFilter>
  sys?: InputMaybe<SysFilter>
  title?: InputMaybe<Scalars['String']>
  title_contains?: InputMaybe<Scalars['String']>
  title_exists?: InputMaybe<Scalars['Boolean']>
  title_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  title_not?: InputMaybe<Scalars['String']>
  title_not_contains?: InputMaybe<Scalars['String']>
  title_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
}

export type FaqsQuestionsLinkingCollections = {
  __typename?: 'FaqsQuestionsLinkingCollections'
  destinationsCategoriesCollection?: Maybe<DestinationsCategoriesCollection>
  destinationsSubcategoriesCollection?: Maybe<DestinationsSubcategoriesCollection>
  entryCollection?: Maybe<EntryCollection>
  faqsQuestionGroupsCollection?: Maybe<FaqsQuestionGroupsCollection>
  travelBySubcategoriesCollection?: Maybe<TravelBySubcategoriesCollection>
}

export type FaqsQuestionsLinkingCollectionsDestinationsCategoriesCollectionArgs =
  {
    limit?: InputMaybe<Scalars['Int']>
    locale?: InputMaybe<Scalars['String']>
    order?: InputMaybe<
      Array<
        InputMaybe<FaqsQuestionsLinkingCollectionsDestinationsCategoriesCollectionOrder>
      >
    >
    preview?: InputMaybe<Scalars['Boolean']>
    skip?: InputMaybe<Scalars['Int']>
  }

export type FaqsQuestionsLinkingCollectionsDestinationsSubcategoriesCollectionArgs =
  {
    limit?: InputMaybe<Scalars['Int']>
    locale?: InputMaybe<Scalars['String']>
    order?: InputMaybe<
      Array<
        InputMaybe<FaqsQuestionsLinkingCollectionsDestinationsSubcategoriesCollectionOrder>
      >
    >
    preview?: InputMaybe<Scalars['Boolean']>
    skip?: InputMaybe<Scalars['Int']>
  }

export type FaqsQuestionsLinkingCollectionsEntryCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>
  locale?: InputMaybe<Scalars['String']>
  preview?: InputMaybe<Scalars['Boolean']>
  skip?: InputMaybe<Scalars['Int']>
}

export type FaqsQuestionsLinkingCollectionsFaqsQuestionGroupsCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>
  locale?: InputMaybe<Scalars['String']>
  order?: InputMaybe<
    Array<
      InputMaybe<FaqsQuestionsLinkingCollectionsFaqsQuestionGroupsCollectionOrder>
    >
  >
  preview?: InputMaybe<Scalars['Boolean']>
  skip?: InputMaybe<Scalars['Int']>
}

export type FaqsQuestionsLinkingCollectionsTravelBySubcategoriesCollectionArgs =
  {
    limit?: InputMaybe<Scalars['Int']>
    locale?: InputMaybe<Scalars['String']>
    order?: InputMaybe<
      Array<
        InputMaybe<FaqsQuestionsLinkingCollectionsTravelBySubcategoriesCollectionOrder>
      >
    >
    preview?: InputMaybe<Scalars['Boolean']>
    skip?: InputMaybe<Scalars['Int']>
  }

export enum FaqsQuestionsLinkingCollectionsDestinationsCategoriesCollectionOrder {
  GenerateTopHotelsPageAsc = 'generateTopHotelsPage_ASC',
  GenerateTopHotelsPageDesc = 'generateTopHotelsPage_DESC',
  GrammarDefiniteArticleAsc = 'grammarDefiniteArticle_ASC',
  GrammarDefiniteArticleDesc = 'grammarDefiniteArticle_DESC',
  SlugAsc = 'slug_ASC',
  SlugDesc = 'slug_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC',
}

export enum FaqsQuestionsLinkingCollectionsDestinationsSubcategoriesCollectionOrder {
  GenerateLandingPageAsc = 'generateLandingPage_ASC',
  GenerateLandingPageDesc = 'generateLandingPage_DESC',
  GenerateTopHotelsPageAsc = 'generateTopHotelsPage_ASC',
  GenerateTopHotelsPageDesc = 'generateTopHotelsPage_DESC',
  GrammarDefiniteArticleAsc = 'grammarDefiniteArticle_ASC',
  GrammarDefiniteArticleDesc = 'grammarDefiniteArticle_DESC',
  ShowInCategoryListAsc = 'showInCategoryList_ASC',
  ShowInCategoryListDesc = 'showInCategoryList_DESC',
  SlugAsc = 'slug_ASC',
  SlugDesc = 'slug_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC',
}

export enum FaqsQuestionsLinkingCollectionsFaqsQuestionGroupsCollectionOrder {
  AdminLabelAsc = 'adminLabel_ASC',
  AdminLabelDesc = 'adminLabel_DESC',
  AutomaticallyExpandQuestionsAsc = 'automaticallyExpandQuestions_ASC',
  AutomaticallyExpandQuestionsDesc = 'automaticallyExpandQuestions_DESC',
  SectionIdAsc = 'sectionId_ASC',
  SectionIdDesc = 'sectionId_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  TitleStyleAsc = 'titleStyle_ASC',
  TitleStyleDesc = 'titleStyle_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC',
}

export enum FaqsQuestionsLinkingCollectionsTravelBySubcategoriesCollectionOrder {
  GenerateLandingPageAsc = 'generateLandingPage_ASC',
  GenerateLandingPageDesc = 'generateLandingPage_DESC',
  ShowInGuidesCategoryListAsc = 'showInGuidesCategoryList_ASC',
  ShowInGuidesCategoryListDesc = 'showInGuidesCategoryList_DESC',
  ShowInItinerariesCategoryListAsc = 'showInItinerariesCategoryList_ASC',
  ShowInItinerariesCategoryListDesc = 'showInItinerariesCategoryList_DESC',
  ShowInStoriesCategoryListAsc = 'showInStoriesCategoryList_ASC',
  ShowInStoriesCategoryListDesc = 'showInStoriesCategoryList_DESC',
  SlugAsc = 'slug_ASC',
  SlugDesc = 'slug_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC',
}

export enum FaqsQuestionsOrder {
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC',
}

/** Create and manage all Hotels data, used on Itineraries and Advisors pages. [See type definition](https://app.contentful.com/spaces/9cxgygtadmvl/content_types/hotels) */
export type Hotels = Entry &
  _Node & {
    __typename?: 'Hotels'
    _id: Scalars['ID']
    activitiesAndExperiencesCollection?: Maybe<HotelsActivitiesAndExperiencesCollection>
    activitiesAndExperiencesSectionImage?: Maybe<Scalars['JSON']>
    amenitiesCollection?: Maybe<HotelsAmenitiesCollection>
    amenitiesSectionImage?: Maybe<Scalars['JSON']>
    archived?: Maybe<Scalars['Boolean']>
    contentfulMetadata: ContentfulMetadata
    description?: Maybe<HotelsDescription>
    destinationCollection?: Maybe<HotelsDestinationCollection>
    duplicateOf?: Maybe<Hotels>
    foraSupplier?: Maybe<Scalars['JSON']>
    foraSupplierId?: Maybe<Scalars['String']>
    foraVipPerksCollection?: Maybe<HotelsForaVipPerksCollection>
    fullHotelDescription?: Maybe<HotelsFullHotelDescription>
    generateLandingPage?: Maybe<Scalars['Boolean']>
    heroImage?: Maybe<Scalars['String']>
    hideFromTopHotelsPages?: Maybe<Scalars['Boolean']>
    highlightsCollection?: Maybe<HotelsHighlightsCollection>
    hotelIntroParagraph?: Maybe<HotelsHotelIntroParagraph>
    image?: Maybe<Asset>
    imageGallery?: Maybe<Scalars['JSON']>
    linkedFrom?: Maybe<HotelsLinkingCollections>
    location?: Maybe<HotelsLocation>
    locationSectionImage?: Maybe<Scalars['JSON']>
    michelinKeys?: Maybe<Scalars['String']>
    partner?: Maybe<EntryPartner>
    perks?: Maybe<Array<Maybe<Scalars['String']>>>
    rooms?: Maybe<HotelsRooms>
    roomsSectionImage?: Maybe<Scalars['JSON']>
    slug?: Maybe<Scalars['String']>
    starRating?: Maybe<Scalars['String']>
    sys: Sys
    title?: Maybe<Scalars['String']>
  }

/** Create and manage all Hotels data, used on Itineraries and Advisors pages. [See type definition](https://app.contentful.com/spaces/9cxgygtadmvl/content_types/hotels) */
export type HotelsActivitiesAndExperiencesCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>
  locale?: InputMaybe<Scalars['String']>
  order?: InputMaybe<
    Array<InputMaybe<HotelsActivitiesAndExperiencesCollectionOrder>>
  >
  preview?: InputMaybe<Scalars['Boolean']>
  skip?: InputMaybe<Scalars['Int']>
  where?: InputMaybe<ComponentImageHeaderSubFilter>
}

/** Create and manage all Hotels data, used on Itineraries and Advisors pages. [See type definition](https://app.contentful.com/spaces/9cxgygtadmvl/content_types/hotels) */
export type HotelsActivitiesAndExperiencesSectionImageArgs = {
  locale?: InputMaybe<Scalars['String']>
}

/** Create and manage all Hotels data, used on Itineraries and Advisors pages. [See type definition](https://app.contentful.com/spaces/9cxgygtadmvl/content_types/hotels) */
export type HotelsAmenitiesCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>
  locale?: InputMaybe<Scalars['String']>
  order?: InputMaybe<Array<InputMaybe<HotelsAmenitiesCollectionOrder>>>
  preview?: InputMaybe<Scalars['Boolean']>
  skip?: InputMaybe<Scalars['Int']>
  where?: InputMaybe<ComponentImageHeaderSubFilter>
}

/** Create and manage all Hotels data, used on Itineraries and Advisors pages. [See type definition](https://app.contentful.com/spaces/9cxgygtadmvl/content_types/hotels) */
export type HotelsAmenitiesSectionImageArgs = {
  locale?: InputMaybe<Scalars['String']>
}

/** Create and manage all Hotels data, used on Itineraries and Advisors pages. [See type definition](https://app.contentful.com/spaces/9cxgygtadmvl/content_types/hotels) */
export type HotelsArchivedArgs = {
  locale?: InputMaybe<Scalars['String']>
}

/** Create and manage all Hotels data, used on Itineraries and Advisors pages. [See type definition](https://app.contentful.com/spaces/9cxgygtadmvl/content_types/hotels) */
export type HotelsDescriptionArgs = {
  locale?: InputMaybe<Scalars['String']>
}

/** Create and manage all Hotels data, used on Itineraries and Advisors pages. [See type definition](https://app.contentful.com/spaces/9cxgygtadmvl/content_types/hotels) */
export type HotelsDestinationCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>
  locale?: InputMaybe<Scalars['String']>
  preview?: InputMaybe<Scalars['Boolean']>
  skip?: InputMaybe<Scalars['Int']>
  where?: InputMaybe<HotelsDestinationFilter>
}

/** Create and manage all Hotels data, used on Itineraries and Advisors pages. [See type definition](https://app.contentful.com/spaces/9cxgygtadmvl/content_types/hotels) */
export type HotelsDuplicateOfArgs = {
  locale?: InputMaybe<Scalars['String']>
  preview?: InputMaybe<Scalars['Boolean']>
  where?: InputMaybe<HotelsFilter>
}

/** Create and manage all Hotels data, used on Itineraries and Advisors pages. [See type definition](https://app.contentful.com/spaces/9cxgygtadmvl/content_types/hotels) */
export type HotelsForaSupplierArgs = {
  locale?: InputMaybe<Scalars['String']>
}

/** Create and manage all Hotels data, used on Itineraries and Advisors pages. [See type definition](https://app.contentful.com/spaces/9cxgygtadmvl/content_types/hotels) */
export type HotelsForaSupplierIdArgs = {
  locale?: InputMaybe<Scalars['String']>
}

/** Create and manage all Hotels data, used on Itineraries and Advisors pages. [See type definition](https://app.contentful.com/spaces/9cxgygtadmvl/content_types/hotels) */
export type HotelsForaVipPerksCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>
  locale?: InputMaybe<Scalars['String']>
  order?: InputMaybe<Array<InputMaybe<HotelsForaVipPerksCollectionOrder>>>
  preview?: InputMaybe<Scalars['Boolean']>
  skip?: InputMaybe<Scalars['Int']>
  where?: InputMaybe<ComponentImageHeaderSubFilter>
}

/** Create and manage all Hotels data, used on Itineraries and Advisors pages. [See type definition](https://app.contentful.com/spaces/9cxgygtadmvl/content_types/hotels) */
export type HotelsFullHotelDescriptionArgs = {
  locale?: InputMaybe<Scalars['String']>
}

/** Create and manage all Hotels data, used on Itineraries and Advisors pages. [See type definition](https://app.contentful.com/spaces/9cxgygtadmvl/content_types/hotels) */
export type HotelsGenerateLandingPageArgs = {
  locale?: InputMaybe<Scalars['String']>
}

/** Create and manage all Hotels data, used on Itineraries and Advisors pages. [See type definition](https://app.contentful.com/spaces/9cxgygtadmvl/content_types/hotels) */
export type HotelsHeroImageArgs = {
  locale?: InputMaybe<Scalars['String']>
}

/** Create and manage all Hotels data, used on Itineraries and Advisors pages. [See type definition](https://app.contentful.com/spaces/9cxgygtadmvl/content_types/hotels) */
export type HotelsHideFromTopHotelsPagesArgs = {
  locale?: InputMaybe<Scalars['String']>
}

/** Create and manage all Hotels data, used on Itineraries and Advisors pages. [See type definition](https://app.contentful.com/spaces/9cxgygtadmvl/content_types/hotels) */
export type HotelsHighlightsCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>
  locale?: InputMaybe<Scalars['String']>
  order?: InputMaybe<Array<InputMaybe<HotelsHighlightsCollectionOrder>>>
  preview?: InputMaybe<Scalars['Boolean']>
  skip?: InputMaybe<Scalars['Int']>
  where?: InputMaybe<ComponentImageHeaderSubFilter>
}

/** Create and manage all Hotels data, used on Itineraries and Advisors pages. [See type definition](https://app.contentful.com/spaces/9cxgygtadmvl/content_types/hotels) */
export type HotelsHotelIntroParagraphArgs = {
  locale?: InputMaybe<Scalars['String']>
}

/** Create and manage all Hotels data, used on Itineraries and Advisors pages. [See type definition](https://app.contentful.com/spaces/9cxgygtadmvl/content_types/hotels) */
export type HotelsImageArgs = {
  locale?: InputMaybe<Scalars['String']>
  preview?: InputMaybe<Scalars['Boolean']>
}

/** Create and manage all Hotels data, used on Itineraries and Advisors pages. [See type definition](https://app.contentful.com/spaces/9cxgygtadmvl/content_types/hotels) */
export type HotelsImageGalleryArgs = {
  locale?: InputMaybe<Scalars['String']>
}

/** Create and manage all Hotels data, used on Itineraries and Advisors pages. [See type definition](https://app.contentful.com/spaces/9cxgygtadmvl/content_types/hotels) */
export type HotelsLinkedFromArgs = {
  allowedLocales?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
}

/** Create and manage all Hotels data, used on Itineraries and Advisors pages. [See type definition](https://app.contentful.com/spaces/9cxgygtadmvl/content_types/hotels) */
export type HotelsLocationArgs = {
  locale?: InputMaybe<Scalars['String']>
}

/** Create and manage all Hotels data, used on Itineraries and Advisors pages. [See type definition](https://app.contentful.com/spaces/9cxgygtadmvl/content_types/hotels) */
export type HotelsLocationSectionImageArgs = {
  locale?: InputMaybe<Scalars['String']>
}

/** Create and manage all Hotels data, used on Itineraries and Advisors pages. [See type definition](https://app.contentful.com/spaces/9cxgygtadmvl/content_types/hotels) */
export type HotelsMichelinKeysArgs = {
  locale?: InputMaybe<Scalars['String']>
}

/** Create and manage all Hotels data, used on Itineraries and Advisors pages. [See type definition](https://app.contentful.com/spaces/9cxgygtadmvl/content_types/hotels) */
export type HotelsPartnerArgs = {
  locale?: InputMaybe<Scalars['String']>
  preview?: InputMaybe<Scalars['Boolean']>
  where?: InputMaybe<EntryPartnerFilter>
}

/** Create and manage all Hotels data, used on Itineraries and Advisors pages. [See type definition](https://app.contentful.com/spaces/9cxgygtadmvl/content_types/hotels) */
export type HotelsPerksArgs = {
  locale?: InputMaybe<Scalars['String']>
}

/** Create and manage all Hotels data, used on Itineraries and Advisors pages. [See type definition](https://app.contentful.com/spaces/9cxgygtadmvl/content_types/hotels) */
export type HotelsRoomsArgs = {
  locale?: InputMaybe<Scalars['String']>
}

/** Create and manage all Hotels data, used on Itineraries and Advisors pages. [See type definition](https://app.contentful.com/spaces/9cxgygtadmvl/content_types/hotels) */
export type HotelsRoomsSectionImageArgs = {
  locale?: InputMaybe<Scalars['String']>
}

/** Create and manage all Hotels data, used on Itineraries and Advisors pages. [See type definition](https://app.contentful.com/spaces/9cxgygtadmvl/content_types/hotels) */
export type HotelsSlugArgs = {
  locale?: InputMaybe<Scalars['String']>
}

/** Create and manage all Hotels data, used on Itineraries and Advisors pages. [See type definition](https://app.contentful.com/spaces/9cxgygtadmvl/content_types/hotels) */
export type HotelsStarRatingArgs = {
  locale?: InputMaybe<Scalars['String']>
}

/** Create and manage all Hotels data, used on Itineraries and Advisors pages. [See type definition](https://app.contentful.com/spaces/9cxgygtadmvl/content_types/hotels) */
export type HotelsTitleArgs = {
  locale?: InputMaybe<Scalars['String']>
}

export type HotelsActivitiesAndExperiencesCollection = {
  __typename?: 'HotelsActivitiesAndExperiencesCollection'
  items: Array<Maybe<ComponentImageHeaderSub>>
  limit: Scalars['Int']
  skip: Scalars['Int']
  total: Scalars['Int']
}

export enum HotelsActivitiesAndExperiencesCollectionOrder {
  HeaderAsc = 'header_ASC',
  HeaderDesc = 'header_DESC',
  InternalLabelAsc = 'internalLabel_ASC',
  InternalLabelDesc = 'internalLabel_DESC',
  SubHeadingAsc = 'subHeading_ASC',
  SubHeadingDesc = 'subHeading_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  TitleStyleAsc = 'titleStyle_ASC',
  TitleStyleDesc = 'titleStyle_DESC',
}

export type HotelsAmenitiesCollection = {
  __typename?: 'HotelsAmenitiesCollection'
  items: Array<Maybe<ComponentImageHeaderSub>>
  limit: Scalars['Int']
  skip: Scalars['Int']
  total: Scalars['Int']
}

export enum HotelsAmenitiesCollectionOrder {
  HeaderAsc = 'header_ASC',
  HeaderDesc = 'header_DESC',
  InternalLabelAsc = 'internalLabel_ASC',
  InternalLabelDesc = 'internalLabel_DESC',
  SubHeadingAsc = 'subHeading_ASC',
  SubHeadingDesc = 'subHeading_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  TitleStyleAsc = 'titleStyle_ASC',
  TitleStyleDesc = 'titleStyle_DESC',
}

export type HotelsCollection = {
  __typename?: 'HotelsCollection'
  items: Array<Maybe<Hotels>>
  limit: Scalars['Int']
  skip: Scalars['Int']
  total: Scalars['Int']
}

export type HotelsDescription = {
  __typename?: 'HotelsDescription'
  json: Scalars['JSON']
  links: HotelsDescriptionLinks
}

export type HotelsDescriptionAssets = {
  __typename?: 'HotelsDescriptionAssets'
  block: Array<Maybe<Asset>>
  hyperlink: Array<Maybe<Asset>>
}

export type HotelsDescriptionEntries = {
  __typename?: 'HotelsDescriptionEntries'
  block: Array<Maybe<Entry>>
  hyperlink: Array<Maybe<Entry>>
  inline: Array<Maybe<Entry>>
}

export type HotelsDescriptionLinks = {
  __typename?: 'HotelsDescriptionLinks'
  assets: HotelsDescriptionAssets
  entries: HotelsDescriptionEntries
  resources: HotelsDescriptionResources
}

export type HotelsDescriptionResources = {
  __typename?: 'HotelsDescriptionResources'
  block: Array<HotelsDescriptionResourcesBlock>
  hyperlink: Array<HotelsDescriptionResourcesHyperlink>
  inline: Array<HotelsDescriptionResourcesInline>
}

export type HotelsDescriptionResourcesBlock = ResourceLink & {
  __typename?: 'HotelsDescriptionResourcesBlock'
  sys: ResourceSys
}

export type HotelsDescriptionResourcesHyperlink = ResourceLink & {
  __typename?: 'HotelsDescriptionResourcesHyperlink'
  sys: ResourceSys
}

export type HotelsDescriptionResourcesInline = ResourceLink & {
  __typename?: 'HotelsDescriptionResourcesInline'
  sys: ResourceSys
}

export type HotelsDestinationCollection = {
  __typename?: 'HotelsDestinationCollection'
  items: Array<Maybe<HotelsDestinationItem>>
  limit: Scalars['Int']
  skip: Scalars['Int']
  total: Scalars['Int']
}

export type HotelsDestinationFilter = {
  AND?: InputMaybe<Array<InputMaybe<HotelsDestinationFilter>>>
  OR?: InputMaybe<Array<InputMaybe<HotelsDestinationFilter>>>
  cloudinaryFeaturedImage_exists?: InputMaybe<Scalars['Boolean']>
  cloudinaryMobileFeaturedImage_exists?: InputMaybe<Scalars['Boolean']>
  contentfulMetadata?: InputMaybe<ContentfulMetadataFilter>
  featuredHotelsCollection_exists?: InputMaybe<Scalars['Boolean']>
  featuredImage_exists?: InputMaybe<Scalars['Boolean']>
  generateTopHotelsPage?: InputMaybe<Scalars['Boolean']>
  generateTopHotelsPage_exists?: InputMaybe<Scalars['Boolean']>
  generateTopHotelsPage_not?: InputMaybe<Scalars['Boolean']>
  grammarDefiniteArticle?: InputMaybe<Scalars['Boolean']>
  grammarDefiniteArticle_exists?: InputMaybe<Scalars['Boolean']>
  grammarDefiniteArticle_not?: InputMaybe<Scalars['Boolean']>
  metaContent_exists?: InputMaybe<Scalars['Boolean']>
  mobileFeaturedImage_exists?: InputMaybe<Scalars['Boolean']>
  questionsCollection_exists?: InputMaybe<Scalars['Boolean']>
  rankedHotels_exists?: InputMaybe<Scalars['Boolean']>
  slug?: InputMaybe<Scalars['String']>
  slug_contains?: InputMaybe<Scalars['String']>
  slug_exists?: InputMaybe<Scalars['Boolean']>
  slug_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  slug_not?: InputMaybe<Scalars['String']>
  slug_not_contains?: InputMaybe<Scalars['String']>
  slug_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  sys?: InputMaybe<SysFilter>
  teaserCopy_contains?: InputMaybe<Scalars['String']>
  teaserCopy_exists?: InputMaybe<Scalars['Boolean']>
  teaserCopy_not_contains?: InputMaybe<Scalars['String']>
  title?: InputMaybe<Scalars['String']>
  title_contains?: InputMaybe<Scalars['String']>
  title_exists?: InputMaybe<Scalars['Boolean']>
  title_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  title_not?: InputMaybe<Scalars['String']>
  title_not_contains?: InputMaybe<Scalars['String']>
  title_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
}

export type HotelsDestinationItem =
  | DestinationsCategories
  | DestinationsSubcategories

export type HotelsFilter = {
  AND?: InputMaybe<Array<InputMaybe<HotelsFilter>>>
  OR?: InputMaybe<Array<InputMaybe<HotelsFilter>>>
  activitiesAndExperiences?: InputMaybe<CfComponentImageHeaderSubNestedFilter>
  activitiesAndExperiencesCollection_exists?: InputMaybe<Scalars['Boolean']>
  activitiesAndExperiencesSectionImage_exists?: InputMaybe<Scalars['Boolean']>
  amenities?: InputMaybe<CfComponentImageHeaderSubNestedFilter>
  amenitiesCollection_exists?: InputMaybe<Scalars['Boolean']>
  amenitiesSectionImage_exists?: InputMaybe<Scalars['Boolean']>
  archived?: InputMaybe<Scalars['Boolean']>
  archived_exists?: InputMaybe<Scalars['Boolean']>
  archived_not?: InputMaybe<Scalars['Boolean']>
  contentfulMetadata?: InputMaybe<ContentfulMetadataFilter>
  description_contains?: InputMaybe<Scalars['String']>
  description_exists?: InputMaybe<Scalars['Boolean']>
  description_not_contains?: InputMaybe<Scalars['String']>
  destination?: InputMaybe<CfdestinationMultiTypeNestedFilter>
  destinationCollection_exists?: InputMaybe<Scalars['Boolean']>
  duplicateOf?: InputMaybe<CfHotelsNestedFilter>
  duplicateOf_exists?: InputMaybe<Scalars['Boolean']>
  foraSupplierId?: InputMaybe<Scalars['String']>
  foraSupplierId_contains?: InputMaybe<Scalars['String']>
  foraSupplierId_exists?: InputMaybe<Scalars['Boolean']>
  foraSupplierId_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  foraSupplierId_not?: InputMaybe<Scalars['String']>
  foraSupplierId_not_contains?: InputMaybe<Scalars['String']>
  foraSupplierId_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  foraSupplier_exists?: InputMaybe<Scalars['Boolean']>
  foraVipPerks?: InputMaybe<CfComponentImageHeaderSubNestedFilter>
  foraVipPerksCollection_exists?: InputMaybe<Scalars['Boolean']>
  fullHotelDescription_contains?: InputMaybe<Scalars['String']>
  fullHotelDescription_exists?: InputMaybe<Scalars['Boolean']>
  fullHotelDescription_not_contains?: InputMaybe<Scalars['String']>
  generateLandingPage?: InputMaybe<Scalars['Boolean']>
  generateLandingPage_exists?: InputMaybe<Scalars['Boolean']>
  generateLandingPage_not?: InputMaybe<Scalars['Boolean']>
  heroImage?: InputMaybe<Scalars['String']>
  heroImage_contains?: InputMaybe<Scalars['String']>
  heroImage_exists?: InputMaybe<Scalars['Boolean']>
  heroImage_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  heroImage_not?: InputMaybe<Scalars['String']>
  heroImage_not_contains?: InputMaybe<Scalars['String']>
  heroImage_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  hideFromTopHotelsPages?: InputMaybe<Scalars['Boolean']>
  hideFromTopHotelsPages_exists?: InputMaybe<Scalars['Boolean']>
  hideFromTopHotelsPages_not?: InputMaybe<Scalars['Boolean']>
  highlights?: InputMaybe<CfComponentImageHeaderSubNestedFilter>
  highlightsCollection_exists?: InputMaybe<Scalars['Boolean']>
  hotelIntroParagraph_contains?: InputMaybe<Scalars['String']>
  hotelIntroParagraph_exists?: InputMaybe<Scalars['Boolean']>
  hotelIntroParagraph_not_contains?: InputMaybe<Scalars['String']>
  imageGallery_exists?: InputMaybe<Scalars['Boolean']>
  image_exists?: InputMaybe<Scalars['Boolean']>
  locationSectionImage_exists?: InputMaybe<Scalars['Boolean']>
  location_contains?: InputMaybe<Scalars['String']>
  location_exists?: InputMaybe<Scalars['Boolean']>
  location_not_contains?: InputMaybe<Scalars['String']>
  michelinKeys?: InputMaybe<Scalars['String']>
  michelinKeys_contains?: InputMaybe<Scalars['String']>
  michelinKeys_exists?: InputMaybe<Scalars['Boolean']>
  michelinKeys_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  michelinKeys_not?: InputMaybe<Scalars['String']>
  michelinKeys_not_contains?: InputMaybe<Scalars['String']>
  michelinKeys_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  partner?: InputMaybe<CfEntryPartnerNestedFilter>
  partner_exists?: InputMaybe<Scalars['Boolean']>
  perks_contains_all?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  perks_contains_none?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  perks_contains_some?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  perks_exists?: InputMaybe<Scalars['Boolean']>
  roomsSectionImage_exists?: InputMaybe<Scalars['Boolean']>
  rooms_contains?: InputMaybe<Scalars['String']>
  rooms_exists?: InputMaybe<Scalars['Boolean']>
  rooms_not_contains?: InputMaybe<Scalars['String']>
  slug?: InputMaybe<Scalars['String']>
  slug_contains?: InputMaybe<Scalars['String']>
  slug_exists?: InputMaybe<Scalars['Boolean']>
  slug_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  slug_not?: InputMaybe<Scalars['String']>
  slug_not_contains?: InputMaybe<Scalars['String']>
  slug_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  starRating?: InputMaybe<Scalars['String']>
  starRating_contains?: InputMaybe<Scalars['String']>
  starRating_exists?: InputMaybe<Scalars['Boolean']>
  starRating_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  starRating_not?: InputMaybe<Scalars['String']>
  starRating_not_contains?: InputMaybe<Scalars['String']>
  starRating_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  sys?: InputMaybe<SysFilter>
  title?: InputMaybe<Scalars['String']>
  title_contains?: InputMaybe<Scalars['String']>
  title_exists?: InputMaybe<Scalars['Boolean']>
  title_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  title_not?: InputMaybe<Scalars['String']>
  title_not_contains?: InputMaybe<Scalars['String']>
  title_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
}

export type HotelsForaVipPerksCollection = {
  __typename?: 'HotelsForaVipPerksCollection'
  items: Array<Maybe<ComponentImageHeaderSub>>
  limit: Scalars['Int']
  skip: Scalars['Int']
  total: Scalars['Int']
}

export enum HotelsForaVipPerksCollectionOrder {
  HeaderAsc = 'header_ASC',
  HeaderDesc = 'header_DESC',
  InternalLabelAsc = 'internalLabel_ASC',
  InternalLabelDesc = 'internalLabel_DESC',
  SubHeadingAsc = 'subHeading_ASC',
  SubHeadingDesc = 'subHeading_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  TitleStyleAsc = 'titleStyle_ASC',
  TitleStyleDesc = 'titleStyle_DESC',
}

export type HotelsFullHotelDescription = {
  __typename?: 'HotelsFullHotelDescription'
  json: Scalars['JSON']
  links: HotelsFullHotelDescriptionLinks
}

export type HotelsFullHotelDescriptionAssets = {
  __typename?: 'HotelsFullHotelDescriptionAssets'
  block: Array<Maybe<Asset>>
  hyperlink: Array<Maybe<Asset>>
}

export type HotelsFullHotelDescriptionEntries = {
  __typename?: 'HotelsFullHotelDescriptionEntries'
  block: Array<Maybe<Entry>>
  hyperlink: Array<Maybe<Entry>>
  inline: Array<Maybe<Entry>>
}

export type HotelsFullHotelDescriptionLinks = {
  __typename?: 'HotelsFullHotelDescriptionLinks'
  assets: HotelsFullHotelDescriptionAssets
  entries: HotelsFullHotelDescriptionEntries
  resources: HotelsFullHotelDescriptionResources
}

export type HotelsFullHotelDescriptionResources = {
  __typename?: 'HotelsFullHotelDescriptionResources'
  block: Array<HotelsFullHotelDescriptionResourcesBlock>
  hyperlink: Array<HotelsFullHotelDescriptionResourcesHyperlink>
  inline: Array<HotelsFullHotelDescriptionResourcesInline>
}

export type HotelsFullHotelDescriptionResourcesBlock = ResourceLink & {
  __typename?: 'HotelsFullHotelDescriptionResourcesBlock'
  sys: ResourceSys
}

export type HotelsFullHotelDescriptionResourcesHyperlink = ResourceLink & {
  __typename?: 'HotelsFullHotelDescriptionResourcesHyperlink'
  sys: ResourceSys
}

export type HotelsFullHotelDescriptionResourcesInline = ResourceLink & {
  __typename?: 'HotelsFullHotelDescriptionResourcesInline'
  sys: ResourceSys
}

export type HotelsHighlightsCollection = {
  __typename?: 'HotelsHighlightsCollection'
  items: Array<Maybe<ComponentImageHeaderSub>>
  limit: Scalars['Int']
  skip: Scalars['Int']
  total: Scalars['Int']
}

export enum HotelsHighlightsCollectionOrder {
  HeaderAsc = 'header_ASC',
  HeaderDesc = 'header_DESC',
  InternalLabelAsc = 'internalLabel_ASC',
  InternalLabelDesc = 'internalLabel_DESC',
  SubHeadingAsc = 'subHeading_ASC',
  SubHeadingDesc = 'subHeading_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  TitleStyleAsc = 'titleStyle_ASC',
  TitleStyleDesc = 'titleStyle_DESC',
}

export type HotelsHotelIntroParagraph = {
  __typename?: 'HotelsHotelIntroParagraph'
  json: Scalars['JSON']
  links: HotelsHotelIntroParagraphLinks
}

export type HotelsHotelIntroParagraphAssets = {
  __typename?: 'HotelsHotelIntroParagraphAssets'
  block: Array<Maybe<Asset>>
  hyperlink: Array<Maybe<Asset>>
}

export type HotelsHotelIntroParagraphEntries = {
  __typename?: 'HotelsHotelIntroParagraphEntries'
  block: Array<Maybe<Entry>>
  hyperlink: Array<Maybe<Entry>>
  inline: Array<Maybe<Entry>>
}

export type HotelsHotelIntroParagraphLinks = {
  __typename?: 'HotelsHotelIntroParagraphLinks'
  assets: HotelsHotelIntroParagraphAssets
  entries: HotelsHotelIntroParagraphEntries
  resources: HotelsHotelIntroParagraphResources
}

export type HotelsHotelIntroParagraphResources = {
  __typename?: 'HotelsHotelIntroParagraphResources'
  block: Array<HotelsHotelIntroParagraphResourcesBlock>
  hyperlink: Array<HotelsHotelIntroParagraphResourcesHyperlink>
  inline: Array<HotelsHotelIntroParagraphResourcesInline>
}

export type HotelsHotelIntroParagraphResourcesBlock = ResourceLink & {
  __typename?: 'HotelsHotelIntroParagraphResourcesBlock'
  sys: ResourceSys
}

export type HotelsHotelIntroParagraphResourcesHyperlink = ResourceLink & {
  __typename?: 'HotelsHotelIntroParagraphResourcesHyperlink'
  sys: ResourceSys
}

export type HotelsHotelIntroParagraphResourcesInline = ResourceLink & {
  __typename?: 'HotelsHotelIntroParagraphResourcesInline'
  sys: ResourceSys
}

export type HotelsLinkingCollections = {
  __typename?: 'HotelsLinkingCollections'
  advisorsCollection?: Maybe<AdvisorsCollection>
  destinationsCategoriesCollection?: Maybe<DestinationsCategoriesCollection>
  destinationsSubcategoriesCollection?: Maybe<DestinationsSubcategoriesCollection>
  entryCollection?: Maybe<EntryCollection>
  entryPartnerCollection?: Maybe<EntryPartnerCollection>
  entryTestimonialCollection?: Maybe<EntryTestimonialCollection>
  entryTripReportCollection?: Maybe<EntryTripReportCollection>
  hotelsCollection?: Maybe<HotelsCollection>
  itinerariesCollection?: Maybe<ItinerariesCollection>
  pagesFeaturedHotelsCollection?: Maybe<PagesFeaturedHotelsCollection>
}

export type HotelsLinkingCollectionsAdvisorsCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>
  locale?: InputMaybe<Scalars['String']>
  order?: InputMaybe<
    Array<InputMaybe<HotelsLinkingCollectionsAdvisorsCollectionOrder>>
  >
  preview?: InputMaybe<Scalars['Boolean']>
  skip?: InputMaybe<Scalars['Int']>
}

export type HotelsLinkingCollectionsDestinationsCategoriesCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>
  locale?: InputMaybe<Scalars['String']>
  order?: InputMaybe<
    Array<
      InputMaybe<HotelsLinkingCollectionsDestinationsCategoriesCollectionOrder>
    >
  >
  preview?: InputMaybe<Scalars['Boolean']>
  skip?: InputMaybe<Scalars['Int']>
}

export type HotelsLinkingCollectionsDestinationsSubcategoriesCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>
  locale?: InputMaybe<Scalars['String']>
  order?: InputMaybe<
    Array<
      InputMaybe<HotelsLinkingCollectionsDestinationsSubcategoriesCollectionOrder>
    >
  >
  preview?: InputMaybe<Scalars['Boolean']>
  skip?: InputMaybe<Scalars['Int']>
}

export type HotelsLinkingCollectionsEntryCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>
  locale?: InputMaybe<Scalars['String']>
  preview?: InputMaybe<Scalars['Boolean']>
  skip?: InputMaybe<Scalars['Int']>
}

export type HotelsLinkingCollectionsEntryPartnerCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>
  locale?: InputMaybe<Scalars['String']>
  order?: InputMaybe<
    Array<InputMaybe<HotelsLinkingCollectionsEntryPartnerCollectionOrder>>
  >
  preview?: InputMaybe<Scalars['Boolean']>
  skip?: InputMaybe<Scalars['Int']>
}

export type HotelsLinkingCollectionsEntryTestimonialCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>
  locale?: InputMaybe<Scalars['String']>
  order?: InputMaybe<
    Array<InputMaybe<HotelsLinkingCollectionsEntryTestimonialCollectionOrder>>
  >
  preview?: InputMaybe<Scalars['Boolean']>
  skip?: InputMaybe<Scalars['Int']>
}

export type HotelsLinkingCollectionsEntryTripReportCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>
  locale?: InputMaybe<Scalars['String']>
  order?: InputMaybe<
    Array<InputMaybe<HotelsLinkingCollectionsEntryTripReportCollectionOrder>>
  >
  preview?: InputMaybe<Scalars['Boolean']>
  skip?: InputMaybe<Scalars['Int']>
}

export type HotelsLinkingCollectionsHotelsCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>
  locale?: InputMaybe<Scalars['String']>
  order?: InputMaybe<
    Array<InputMaybe<HotelsLinkingCollectionsHotelsCollectionOrder>>
  >
  preview?: InputMaybe<Scalars['Boolean']>
  skip?: InputMaybe<Scalars['Int']>
}

export type HotelsLinkingCollectionsItinerariesCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>
  locale?: InputMaybe<Scalars['String']>
  order?: InputMaybe<
    Array<InputMaybe<HotelsLinkingCollectionsItinerariesCollectionOrder>>
  >
  preview?: InputMaybe<Scalars['Boolean']>
  skip?: InputMaybe<Scalars['Int']>
}

export type HotelsLinkingCollectionsPagesFeaturedHotelsCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>
  locale?: InputMaybe<Scalars['String']>
  order?: InputMaybe<
    Array<
      InputMaybe<HotelsLinkingCollectionsPagesFeaturedHotelsCollectionOrder>
    >
  >
  preview?: InputMaybe<Scalars['Boolean']>
  skip?: InputMaybe<Scalars['Int']>
}

export enum HotelsLinkingCollectionsAdvisorsCollectionOrder {
  AdvisorContactEnabledAsc = 'advisorContactEnabled_ASC',
  AdvisorContactEnabledDesc = 'advisorContactEnabled_DESC',
  AdvisorVariantAsc = 'advisorVariant_ASC',
  AdvisorVariantDesc = 'advisorVariant_DESC',
  EmailAddressAsc = 'emailAddress_ASC',
  EmailAddressDesc = 'emailAddress_DESC',
  FacebookUrlAsc = 'facebookUrl_ASC',
  FacebookUrlDesc = 'facebookUrl_DESC',
  FirstNameAsc = 'firstName_ASC',
  FirstNameDesc = 'firstName_DESC',
  ForaAdvisorHotelsAsc = 'foraAdvisorHotels_ASC',
  ForaAdvisorHotelsDesc = 'foraAdvisorHotels_DESC',
  ForaAdvisorIdAsc = 'foraAdvisorId_ASC',
  ForaAdvisorIdDesc = 'foraAdvisorId_DESC',
  InstagramUrlAsc = 'instagramUrl_ASC',
  InstagramUrlDesc = 'instagramUrl_DESC',
  LastNameAsc = 'lastName_ASC',
  LastNameDesc = 'lastName_DESC',
  LinkedinUrlAsc = 'linkedinUrl_ASC',
  LinkedinUrlDesc = 'linkedinUrl_DESC',
  MinimumBookingAsc = 'minimumBooking_ASC',
  MinimumBookingDesc = 'minimumBooking_DESC',
  PinterestUrlAsc = 'pinterestUrl_ASC',
  PinterestUrlDesc = 'pinterestUrl_DESC',
  PronounsAsc = 'pronouns_ASC',
  PronounsDesc = 'pronouns_DESC',
  ShortDescriptionAsc = 'shortDescription_ASC',
  ShortDescriptionDesc = 'shortDescription_DESC',
  ShowOnAdvisorsPageAsc = 'showOnAdvisorsPage_ASC',
  ShowOnAdvisorsPageDesc = 'showOnAdvisorsPage_DESC',
  ShowOnHomepageAsc = 'showOnHomepage_ASC',
  ShowOnHomepageDesc = 'showOnHomepage_DESC',
  ShowOnOtherLandingPagesAsc = 'showOnOtherLandingPages_ASC',
  ShowOnOtherLandingPagesDesc = 'showOnOtherLandingPages_DESC',
  SlugAsc = 'slug_ASC',
  SlugDesc = 'slug_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  TierAsc = 'tier_ASC',
  TierDesc = 'tier_DESC',
  TikTokUrlAsc = 'tikTokUrl_ASC',
  TikTokUrlDesc = 'tikTokUrl_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC',
  TwitterUrlAsc = 'twitterUrl_ASC',
  TwitterUrlDesc = 'twitterUrl_DESC',
  WebsiteUrlAsc = 'websiteUrl_ASC',
  WebsiteUrlDesc = 'websiteUrl_DESC',
  YoutubeUrlAsc = 'youtubeUrl_ASC',
  YoutubeUrlDesc = 'youtubeUrl_DESC',
}

export enum HotelsLinkingCollectionsDestinationsCategoriesCollectionOrder {
  GenerateTopHotelsPageAsc = 'generateTopHotelsPage_ASC',
  GenerateTopHotelsPageDesc = 'generateTopHotelsPage_DESC',
  GrammarDefiniteArticleAsc = 'grammarDefiniteArticle_ASC',
  GrammarDefiniteArticleDesc = 'grammarDefiniteArticle_DESC',
  SlugAsc = 'slug_ASC',
  SlugDesc = 'slug_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC',
}

export enum HotelsLinkingCollectionsDestinationsSubcategoriesCollectionOrder {
  GenerateLandingPageAsc = 'generateLandingPage_ASC',
  GenerateLandingPageDesc = 'generateLandingPage_DESC',
  GenerateTopHotelsPageAsc = 'generateTopHotelsPage_ASC',
  GenerateTopHotelsPageDesc = 'generateTopHotelsPage_DESC',
  GrammarDefiniteArticleAsc = 'grammarDefiniteArticle_ASC',
  GrammarDefiniteArticleDesc = 'grammarDefiniteArticle_DESC',
  ShowInCategoryListAsc = 'showInCategoryList_ASC',
  ShowInCategoryListDesc = 'showInCategoryList_DESC',
  SlugAsc = 'slug_ASC',
  SlugDesc = 'slug_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC',
}

export enum HotelsLinkingCollectionsEntryPartnerCollectionOrder {
  DetailSummaryAsc = 'detailSummary_ASC',
  DetailSummaryDesc = 'detailSummary_DESC',
  FeaturedAsc = 'featured_ASC',
  FeaturedDesc = 'featured_DESC',
  HasDetailPageAsc = 'hasDetailPage_ASC',
  HasDetailPageDesc = 'hasDetailPage_DESC',
  HideFromPartnerGridAsc = 'hideFromPartnerGrid_ASC',
  HideFromPartnerGridDesc = 'hideFromPartnerGrid_DESC',
  NameAsc = 'name_ASC',
  NameDesc = 'name_DESC',
  PartnerCategoryAsc = 'partnerCategory_ASC',
  PartnerCategoryDesc = 'partnerCategory_DESC',
  PartnerTypeAsc = 'partnerType_ASC',
  PartnerTypeDesc = 'partnerType_DESC',
  SlugAsc = 'slug_ASC',
  SlugDesc = 'slug_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
}

export enum HotelsLinkingCollectionsEntryTestimonialCollectionOrder {
  AboutForaAsc = 'aboutFora_ASC',
  AboutForaDesc = 'aboutFora_DESC',
  BeginTravelDateAsc = 'beginTravelDate_ASC',
  BeginTravelDateDesc = 'beginTravelDate_DESC',
  DateSubmittedAsc = 'dateSubmitted_ASC',
  DateSubmittedDesc = 'dateSubmitted_DESC',
  DisplayAsc = 'display_ASC',
  DisplayDesc = 'display_DESC',
  EndTravelDateAsc = 'endTravelDate_ASC',
  EndTravelDateDesc = 'endTravelDate_DESC',
  InternalLabelAsc = 'internalLabel_ASC',
  InternalLabelDesc = 'internalLabel_DESC',
  IsFeaturedAsc = 'isFeatured_ASC',
  IsFeaturedDesc = 'isFeatured_DESC',
  StarsAsc = 'stars_ASC',
  StarsDesc = 'stars_DESC',
  SubmitterEmailAsc = 'submitterEmail_ASC',
  SubmitterEmailDesc = 'submitterEmail_DESC',
  SubmitterFirstNameAsc = 'submitterFirstName_ASC',
  SubmitterFirstNameDesc = 'submitterFirstName_DESC',
  SubmitterLastNameAsc = 'submitterLastName_ASC',
  SubmitterLastNameDesc = 'submitterLastName_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  TestimonialSourceAsc = 'testimonialSource_ASC',
  TestimonialSourceDesc = 'testimonialSource_DESC',
  TitleOfReviewAsc = 'titleOfReview_ASC',
  TitleOfReviewDesc = 'titleOfReview_DESC',
}

export enum HotelsLinkingCollectionsEntryTripReportCollectionOrder {
  LocationForTitlesAsc = 'locationForTitles_ASC',
  LocationForTitlesDesc = 'locationForTitles_DESC',
  MetaDescriptionAsc = 'metaDescription_ASC',
  MetaDescriptionDesc = 'metaDescription_DESC',
  MetaTitleAsc = 'metaTitle_ASC',
  MetaTitleDesc = 'metaTitle_DESC',
  SeoSlugAsc = 'seoSlug_ASC',
  SeoSlugDesc = 'seoSlug_DESC',
  ShowOnGuidesAndDestinationsPageAsc = 'showOnGuidesAndDestinationsPage_ASC',
  ShowOnGuidesAndDestinationsPageDesc = 'showOnGuidesAndDestinationsPage_DESC',
  ShowOnHomepageAsc = 'showOnHomepage_ASC',
  ShowOnHomepageDesc = 'showOnHomepage_DESC',
  ShowOnOtherLandingPagesAsc = 'showOnOtherLandingPages_ASC',
  ShowOnOtherLandingPagesDesc = 'showOnOtherLandingPages_DESC',
  SlugIdAsc = 'slugID_ASC',
  SlugIdDesc = 'slugID_DESC',
  SlugAsc = 'slug_ASC',
  SlugDesc = 'slug_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  TestUpdatedSlugIdAsc = 'testUpdatedSlugId_ASC',
  TestUpdatedSlugIdDesc = 'testUpdatedSlugId_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC',
}

export enum HotelsLinkingCollectionsHotelsCollectionOrder {
  ArchivedAsc = 'archived_ASC',
  ArchivedDesc = 'archived_DESC',
  ForaSupplierIdAsc = 'foraSupplierId_ASC',
  ForaSupplierIdDesc = 'foraSupplierId_DESC',
  GenerateLandingPageAsc = 'generateLandingPage_ASC',
  GenerateLandingPageDesc = 'generateLandingPage_DESC',
  HeroImageAsc = 'heroImage_ASC',
  HeroImageDesc = 'heroImage_DESC',
  HideFromTopHotelsPagesAsc = 'hideFromTopHotelsPages_ASC',
  HideFromTopHotelsPagesDesc = 'hideFromTopHotelsPages_DESC',
  MichelinKeysAsc = 'michelinKeys_ASC',
  MichelinKeysDesc = 'michelinKeys_DESC',
  SlugAsc = 'slug_ASC',
  SlugDesc = 'slug_DESC',
  StarRatingAsc = 'starRating_ASC',
  StarRatingDesc = 'starRating_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC',
}

export enum HotelsLinkingCollectionsItinerariesCollectionOrder {
  ItineraryVariantAsc = 'itineraryVariant_ASC',
  ItineraryVariantDesc = 'itineraryVariant_DESC',
  LocationForTitlesAsc = 'locationForTitles_ASC',
  LocationForTitlesDesc = 'locationForTitles_DESC',
  MetaDescriptionAsc = 'metaDescription_ASC',
  MetaDescriptionDesc = 'metaDescription_DESC',
  MetaTitleAsc = 'metaTitle_ASC',
  MetaTitleDesc = 'metaTitle_DESC',
  SeoSlugAsc = 'seoSlug_ASC',
  SeoSlugDesc = 'seoSlug_DESC',
  ShowOnHomepageAsc = 'showOnHomepage_ASC',
  ShowOnHomepageDesc = 'showOnHomepage_DESC',
  ShowOnItinerariesPageAsc = 'showOnItinerariesPage_ASC',
  ShowOnItinerariesPageDesc = 'showOnItinerariesPage_DESC',
  ShowOnOtherLandingPagesAsc = 'showOnOtherLandingPages_ASC',
  ShowOnOtherLandingPagesDesc = 'showOnOtherLandingPages_DESC',
  SlugIdAsc = 'slugID_ASC',
  SlugIdDesc = 'slugID_DESC',
  SlugAsc = 'slug_ASC',
  SlugDesc = 'slug_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC',
}

export enum HotelsLinkingCollectionsPagesFeaturedHotelsCollectionOrder {
  DisplayAsc = 'display_ASC',
  DisplayDesc = 'display_DESC',
  HeaderAlignmentAsc = 'headerAlignment_ASC',
  HeaderAlignmentDesc = 'headerAlignment_DESC',
  ShowControlsAsc = 'showControls_ASC',
  ShowControlsDesc = 'showControls_DESC',
  ShowTitleAsc = 'showTitle_ASC',
  ShowTitleDesc = 'showTitle_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC',
}

export type HotelsLocation = {
  __typename?: 'HotelsLocation'
  json: Scalars['JSON']
  links: HotelsLocationLinks
}

export type HotelsLocationAssets = {
  __typename?: 'HotelsLocationAssets'
  block: Array<Maybe<Asset>>
  hyperlink: Array<Maybe<Asset>>
}

export type HotelsLocationEntries = {
  __typename?: 'HotelsLocationEntries'
  block: Array<Maybe<Entry>>
  hyperlink: Array<Maybe<Entry>>
  inline: Array<Maybe<Entry>>
}

export type HotelsLocationLinks = {
  __typename?: 'HotelsLocationLinks'
  assets: HotelsLocationAssets
  entries: HotelsLocationEntries
  resources: HotelsLocationResources
}

export type HotelsLocationResources = {
  __typename?: 'HotelsLocationResources'
  block: Array<HotelsLocationResourcesBlock>
  hyperlink: Array<HotelsLocationResourcesHyperlink>
  inline: Array<HotelsLocationResourcesInline>
}

export type HotelsLocationResourcesBlock = ResourceLink & {
  __typename?: 'HotelsLocationResourcesBlock'
  sys: ResourceSys
}

export type HotelsLocationResourcesHyperlink = ResourceLink & {
  __typename?: 'HotelsLocationResourcesHyperlink'
  sys: ResourceSys
}

export type HotelsLocationResourcesInline = ResourceLink & {
  __typename?: 'HotelsLocationResourcesInline'
  sys: ResourceSys
}

export enum HotelsOrder {
  ArchivedAsc = 'archived_ASC',
  ArchivedDesc = 'archived_DESC',
  ForaSupplierIdAsc = 'foraSupplierId_ASC',
  ForaSupplierIdDesc = 'foraSupplierId_DESC',
  GenerateLandingPageAsc = 'generateLandingPage_ASC',
  GenerateLandingPageDesc = 'generateLandingPage_DESC',
  HeroImageAsc = 'heroImage_ASC',
  HeroImageDesc = 'heroImage_DESC',
  HideFromTopHotelsPagesAsc = 'hideFromTopHotelsPages_ASC',
  HideFromTopHotelsPagesDesc = 'hideFromTopHotelsPages_DESC',
  MichelinKeysAsc = 'michelinKeys_ASC',
  MichelinKeysDesc = 'michelinKeys_DESC',
  SlugAsc = 'slug_ASC',
  SlugDesc = 'slug_DESC',
  StarRatingAsc = 'starRating_ASC',
  StarRatingDesc = 'starRating_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC',
}

export type HotelsRooms = {
  __typename?: 'HotelsRooms'
  json: Scalars['JSON']
  links: HotelsRoomsLinks
}

export type HotelsRoomsAssets = {
  __typename?: 'HotelsRoomsAssets'
  block: Array<Maybe<Asset>>
  hyperlink: Array<Maybe<Asset>>
}

export type HotelsRoomsEntries = {
  __typename?: 'HotelsRoomsEntries'
  block: Array<Maybe<Entry>>
  hyperlink: Array<Maybe<Entry>>
  inline: Array<Maybe<Entry>>
}

export type HotelsRoomsLinks = {
  __typename?: 'HotelsRoomsLinks'
  assets: HotelsRoomsAssets
  entries: HotelsRoomsEntries
  resources: HotelsRoomsResources
}

export type HotelsRoomsResources = {
  __typename?: 'HotelsRoomsResources'
  block: Array<HotelsRoomsResourcesBlock>
  hyperlink: Array<HotelsRoomsResourcesHyperlink>
  inline: Array<HotelsRoomsResourcesInline>
}

export type HotelsRoomsResourcesBlock = ResourceLink & {
  __typename?: 'HotelsRoomsResourcesBlock'
  sys: ResourceSys
}

export type HotelsRoomsResourcesHyperlink = ResourceLink & {
  __typename?: 'HotelsRoomsResourcesHyperlink'
  sys: ResourceSys
}

export type HotelsRoomsResourcesInline = ResourceLink & {
  __typename?: 'HotelsRoomsResourcesInline'
  sys: ResourceSys
}

export enum ImageFormat {
  Avif = 'AVIF',
  /** JPG image format. */
  Jpg = 'JPG',
  /**
   * Progressive JPG format stores multiple passes of an image in progressively higher detail.
   *         When a progressive image is loading, the viewer will first see a lower quality pixelated version which
   *         will gradually improve in detail, until the image is fully downloaded. This is to display an image as
   *         early as possible to make the layout look as designed.
   */
  JpgProgressive = 'JPG_PROGRESSIVE',
  /** PNG image format */
  Png = 'PNG',
  /**
   * 8-bit PNG images support up to 256 colors and weigh less than the standard 24-bit PNG equivalent.
   *         The 8-bit PNG format is mostly used for simple images, such as icons or logos.
   */
  Png8 = 'PNG8',
  /** WebP image format. */
  Webp = 'WEBP',
}

export enum ImageResizeFocus {
  /** Focus the resizing on the bottom. */
  Bottom = 'BOTTOM',
  /** Focus the resizing on the bottom left. */
  BottomLeft = 'BOTTOM_LEFT',
  /** Focus the resizing on the bottom right. */
  BottomRight = 'BOTTOM_RIGHT',
  /** Focus the resizing on the center. */
  Center = 'CENTER',
  /** Focus the resizing on the largest face. */
  Face = 'FACE',
  /** Focus the resizing on the area containing all the faces. */
  Faces = 'FACES',
  /** Focus the resizing on the left. */
  Left = 'LEFT',
  /** Focus the resizing on the right. */
  Right = 'RIGHT',
  /** Focus the resizing on the top. */
  Top = 'TOP',
  /** Focus the resizing on the top left. */
  TopLeft = 'TOP_LEFT',
  /** Focus the resizing on the top right. */
  TopRight = 'TOP_RIGHT',
}

export enum ImageResizeStrategy {
  /** Crops a part of the original image to fit into the specified dimensions. */
  Crop = 'CROP',
  /** Resizes the image to the specified dimensions, cropping the image if needed. */
  Fill = 'FILL',
  /** Resizes the image to fit into the specified dimensions. */
  Fit = 'FIT',
  /**
   * Resizes the image to the specified dimensions, padding the image if needed.
   *         Uses desired background color as padding color.
   */
  Pad = 'PAD',
  /** Resizes the image to the specified dimensions, changing the original aspect ratio if needed. */
  Scale = 'SCALE',
  /** Creates a thumbnail from the image. */
  Thumb = 'THUMB',
}

export type ImageTransformOptions = {
  /**
   * Desired background color, used with corner radius or `PAD` resize strategy.
   *         Defaults to transparent (for `PNG`, `PNG8` and `WEBP`) or white (for `JPG` and `JPG_PROGRESSIVE`).
   */
  backgroundColor?: InputMaybe<Scalars['HexColor']>
  /**
   * Desired corner radius in pixels.
   *         Results in an image with rounded corners (pass `-1` for a full circle/ellipse).
   *         Defaults to `0`. Uses desired background color as padding color,
   *         unless the format is `JPG` or `JPG_PROGRESSIVE` and resize strategy is `PAD`, then defaults to white.
   */
  cornerRadius?: InputMaybe<Scalars['Int']>
  /** Desired image format. Defaults to the original image format. */
  format?: InputMaybe<ImageFormat>
  /** Desired height in pixels. Defaults to the original image height. */
  height?: InputMaybe<Scalars['Dimension']>
  /**
   * Desired quality of the image in percents.
   *         Used for `PNG8`, `JPG`, `JPG_PROGRESSIVE` and `WEBP` formats.
   */
  quality?: InputMaybe<Scalars['Quality']>
  /** Desired resize focus area. Defaults to `CENTER`. */
  resizeFocus?: InputMaybe<ImageResizeFocus>
  /** Desired resize strategy. Defaults to `FIT`. */
  resizeStrategy?: InputMaybe<ImageResizeStrategy>
  /** Desired width in pixels. Defaults to the original image width. */
  width?: InputMaybe<Scalars['Dimension']>
}

/** Create and manage all Itineraries content. [See type definition](https://app.contentful.com/spaces/9cxgygtadmvl/content_types/itineraries) */
export type Itineraries = Entry &
  _Node & {
    __typename?: 'Itineraries'
    _id: Scalars['ID']
    additionalNotes?: Maybe<ItinerariesAdditionalNotes>
    advisor?: Maybe<Advisors>
    atAGlanceTags?: Maybe<Array<Maybe<Scalars['String']>>>
    categoriesCollection?: Maybe<ItinerariesCategoriesCollection>
    cloudinaryImage?: Maybe<Scalars['JSON']>
    contentfulMetadata: ContentfulMetadata
    curatorsStatement?: Maybe<ItinerariesCuratorsStatement>
    hotelsCollection?: Maybe<ItinerariesHotelsCollection>
    image?: Maybe<Asset>
    itineraryVariant?: Maybe<Scalars['String']>
    linkedFrom?: Maybe<ItinerariesLinkingCollections>
    locationForTitles?: Maybe<Scalars['String']>
    metaDescription?: Maybe<Scalars['String']>
    metaTitle?: Maybe<Scalars['String']>
    needToKnow?: Maybe<ItinerariesNeedToKnow>
    plansCollection?: Maybe<ItinerariesPlansCollection>
    seoSlug?: Maybe<Scalars['String']>
    showOnHomepage?: Maybe<Scalars['Boolean']>
    showOnItinerariesPage?: Maybe<Scalars['Boolean']>
    showOnOtherLandingPages?: Maybe<Scalars['Boolean']>
    slug?: Maybe<Scalars['String']>
    slugId?: Maybe<Scalars['String']>
    sys: Sys
    title?: Maybe<Scalars['String']>
  }

/** Create and manage all Itineraries content. [See type definition](https://app.contentful.com/spaces/9cxgygtadmvl/content_types/itineraries) */
export type ItinerariesAdditionalNotesArgs = {
  locale?: InputMaybe<Scalars['String']>
}

/** Create and manage all Itineraries content. [See type definition](https://app.contentful.com/spaces/9cxgygtadmvl/content_types/itineraries) */
export type ItinerariesAdvisorArgs = {
  locale?: InputMaybe<Scalars['String']>
  preview?: InputMaybe<Scalars['Boolean']>
  where?: InputMaybe<AdvisorsFilter>
}

/** Create and manage all Itineraries content. [See type definition](https://app.contentful.com/spaces/9cxgygtadmvl/content_types/itineraries) */
export type ItinerariesAtAGlanceTagsArgs = {
  locale?: InputMaybe<Scalars['String']>
}

/** Create and manage all Itineraries content. [See type definition](https://app.contentful.com/spaces/9cxgygtadmvl/content_types/itineraries) */
export type ItinerariesCategoriesCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>
  locale?: InputMaybe<Scalars['String']>
  preview?: InputMaybe<Scalars['Boolean']>
  skip?: InputMaybe<Scalars['Int']>
  where?: InputMaybe<ItinerariesCategoriesFilter>
}

/** Create and manage all Itineraries content. [See type definition](https://app.contentful.com/spaces/9cxgygtadmvl/content_types/itineraries) */
export type ItinerariesCloudinaryImageArgs = {
  locale?: InputMaybe<Scalars['String']>
}

/** Create and manage all Itineraries content. [See type definition](https://app.contentful.com/spaces/9cxgygtadmvl/content_types/itineraries) */
export type ItinerariesCuratorsStatementArgs = {
  locale?: InputMaybe<Scalars['String']>
}

/** Create and manage all Itineraries content. [See type definition](https://app.contentful.com/spaces/9cxgygtadmvl/content_types/itineraries) */
export type ItinerariesHotelsCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>
  locale?: InputMaybe<Scalars['String']>
  order?: InputMaybe<Array<InputMaybe<ItinerariesHotelsCollectionOrder>>>
  preview?: InputMaybe<Scalars['Boolean']>
  skip?: InputMaybe<Scalars['Int']>
  where?: InputMaybe<HotelsFilter>
}

/** Create and manage all Itineraries content. [See type definition](https://app.contentful.com/spaces/9cxgygtadmvl/content_types/itineraries) */
export type ItinerariesImageArgs = {
  locale?: InputMaybe<Scalars['String']>
  preview?: InputMaybe<Scalars['Boolean']>
}

/** Create and manage all Itineraries content. [See type definition](https://app.contentful.com/spaces/9cxgygtadmvl/content_types/itineraries) */
export type ItinerariesItineraryVariantArgs = {
  locale?: InputMaybe<Scalars['String']>
}

/** Create and manage all Itineraries content. [See type definition](https://app.contentful.com/spaces/9cxgygtadmvl/content_types/itineraries) */
export type ItinerariesLinkedFromArgs = {
  allowedLocales?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
}

/** Create and manage all Itineraries content. [See type definition](https://app.contentful.com/spaces/9cxgygtadmvl/content_types/itineraries) */
export type ItinerariesLocationForTitlesArgs = {
  locale?: InputMaybe<Scalars['String']>
}

/** Create and manage all Itineraries content. [See type definition](https://app.contentful.com/spaces/9cxgygtadmvl/content_types/itineraries) */
export type ItinerariesMetaDescriptionArgs = {
  locale?: InputMaybe<Scalars['String']>
}

/** Create and manage all Itineraries content. [See type definition](https://app.contentful.com/spaces/9cxgygtadmvl/content_types/itineraries) */
export type ItinerariesMetaTitleArgs = {
  locale?: InputMaybe<Scalars['String']>
}

/** Create and manage all Itineraries content. [See type definition](https://app.contentful.com/spaces/9cxgygtadmvl/content_types/itineraries) */
export type ItinerariesNeedToKnowArgs = {
  locale?: InputMaybe<Scalars['String']>
}

/** Create and manage all Itineraries content. [See type definition](https://app.contentful.com/spaces/9cxgygtadmvl/content_types/itineraries) */
export type ItinerariesPlansCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>
  locale?: InputMaybe<Scalars['String']>
  order?: InputMaybe<Array<InputMaybe<ItinerariesPlansCollectionOrder>>>
  preview?: InputMaybe<Scalars['Boolean']>
  skip?: InputMaybe<Scalars['Int']>
  where?: InputMaybe<ItinerariesPlansFilter>
}

/** Create and manage all Itineraries content. [See type definition](https://app.contentful.com/spaces/9cxgygtadmvl/content_types/itineraries) */
export type ItinerariesSeoSlugArgs = {
  locale?: InputMaybe<Scalars['String']>
}

/** Create and manage all Itineraries content. [See type definition](https://app.contentful.com/spaces/9cxgygtadmvl/content_types/itineraries) */
export type ItinerariesShowOnHomepageArgs = {
  locale?: InputMaybe<Scalars['String']>
}

/** Create and manage all Itineraries content. [See type definition](https://app.contentful.com/spaces/9cxgygtadmvl/content_types/itineraries) */
export type ItinerariesShowOnItinerariesPageArgs = {
  locale?: InputMaybe<Scalars['String']>
}

/** Create and manage all Itineraries content. [See type definition](https://app.contentful.com/spaces/9cxgygtadmvl/content_types/itineraries) */
export type ItinerariesShowOnOtherLandingPagesArgs = {
  locale?: InputMaybe<Scalars['String']>
}

/** Create and manage all Itineraries content. [See type definition](https://app.contentful.com/spaces/9cxgygtadmvl/content_types/itineraries) */
export type ItinerariesSlugArgs = {
  locale?: InputMaybe<Scalars['String']>
}

/** Create and manage all Itineraries content. [See type definition](https://app.contentful.com/spaces/9cxgygtadmvl/content_types/itineraries) */
export type ItinerariesSlugIdArgs = {
  locale?: InputMaybe<Scalars['String']>
}

/** Create and manage all Itineraries content. [See type definition](https://app.contentful.com/spaces/9cxgygtadmvl/content_types/itineraries) */
export type ItinerariesTitleArgs = {
  locale?: InputMaybe<Scalars['String']>
}

export type ItinerariesAdditionalNotes = {
  __typename?: 'ItinerariesAdditionalNotes'
  json: Scalars['JSON']
  links: ItinerariesAdditionalNotesLinks
}

export type ItinerariesAdditionalNotesAssets = {
  __typename?: 'ItinerariesAdditionalNotesAssets'
  block: Array<Maybe<Asset>>
  hyperlink: Array<Maybe<Asset>>
}

export type ItinerariesAdditionalNotesEntries = {
  __typename?: 'ItinerariesAdditionalNotesEntries'
  block: Array<Maybe<Entry>>
  hyperlink: Array<Maybe<Entry>>
  inline: Array<Maybe<Entry>>
}

export type ItinerariesAdditionalNotesLinks = {
  __typename?: 'ItinerariesAdditionalNotesLinks'
  assets: ItinerariesAdditionalNotesAssets
  entries: ItinerariesAdditionalNotesEntries
  resources: ItinerariesAdditionalNotesResources
}

export type ItinerariesAdditionalNotesResources = {
  __typename?: 'ItinerariesAdditionalNotesResources'
  block: Array<ItinerariesAdditionalNotesResourcesBlock>
  hyperlink: Array<ItinerariesAdditionalNotesResourcesHyperlink>
  inline: Array<ItinerariesAdditionalNotesResourcesInline>
}

export type ItinerariesAdditionalNotesResourcesBlock = ResourceLink & {
  __typename?: 'ItinerariesAdditionalNotesResourcesBlock'
  sys: ResourceSys
}

export type ItinerariesAdditionalNotesResourcesHyperlink = ResourceLink & {
  __typename?: 'ItinerariesAdditionalNotesResourcesHyperlink'
  sys: ResourceSys
}

export type ItinerariesAdditionalNotesResourcesInline = ResourceLink & {
  __typename?: 'ItinerariesAdditionalNotesResourcesInline'
  sys: ResourceSys
}

export type ItinerariesCategoriesCollection = {
  __typename?: 'ItinerariesCategoriesCollection'
  items: Array<Maybe<ItinerariesCategoriesItem>>
  limit: Scalars['Int']
  skip: Scalars['Int']
  total: Scalars['Int']
}

export type ItinerariesCategoriesFilter = {
  AND?: InputMaybe<Array<InputMaybe<ItinerariesCategoriesFilter>>>
  OR?: InputMaybe<Array<InputMaybe<ItinerariesCategoriesFilter>>>
  contentfulMetadata?: InputMaybe<ContentfulMetadataFilter>
  featuredImage_exists?: InputMaybe<Scalars['Boolean']>
  generateLandingPage?: InputMaybe<Scalars['Boolean']>
  generateLandingPage_exists?: InputMaybe<Scalars['Boolean']>
  generateLandingPage_not?: InputMaybe<Scalars['Boolean']>
  metaContent_exists?: InputMaybe<Scalars['Boolean']>
  questionsCollection_exists?: InputMaybe<Scalars['Boolean']>
  slug?: InputMaybe<Scalars['String']>
  slug_contains?: InputMaybe<Scalars['String']>
  slug_exists?: InputMaybe<Scalars['Boolean']>
  slug_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  slug_not?: InputMaybe<Scalars['String']>
  slug_not_contains?: InputMaybe<Scalars['String']>
  slug_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  sys?: InputMaybe<SysFilter>
  teaserCopy_contains?: InputMaybe<Scalars['String']>
  teaserCopy_exists?: InputMaybe<Scalars['Boolean']>
  teaserCopy_not_contains?: InputMaybe<Scalars['String']>
  title?: InputMaybe<Scalars['String']>
  title_contains?: InputMaybe<Scalars['String']>
  title_exists?: InputMaybe<Scalars['Boolean']>
  title_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  title_not?: InputMaybe<Scalars['String']>
  title_not_contains?: InputMaybe<Scalars['String']>
  title_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
}

export type ItinerariesCategoriesItem =
  | DestinationsSubcategories
  | TravelBySubcategories

export type ItinerariesCollection = {
  __typename?: 'ItinerariesCollection'
  items: Array<Maybe<Itineraries>>
  limit: Scalars['Int']
  skip: Scalars['Int']
  total: Scalars['Int']
}

export type ItinerariesCuratorsStatement = {
  __typename?: 'ItinerariesCuratorsStatement'
  json: Scalars['JSON']
  links: ItinerariesCuratorsStatementLinks
}

export type ItinerariesCuratorsStatementAssets = {
  __typename?: 'ItinerariesCuratorsStatementAssets'
  block: Array<Maybe<Asset>>
  hyperlink: Array<Maybe<Asset>>
}

export type ItinerariesCuratorsStatementEntries = {
  __typename?: 'ItinerariesCuratorsStatementEntries'
  block: Array<Maybe<Entry>>
  hyperlink: Array<Maybe<Entry>>
  inline: Array<Maybe<Entry>>
}

export type ItinerariesCuratorsStatementLinks = {
  __typename?: 'ItinerariesCuratorsStatementLinks'
  assets: ItinerariesCuratorsStatementAssets
  entries: ItinerariesCuratorsStatementEntries
  resources: ItinerariesCuratorsStatementResources
}

export type ItinerariesCuratorsStatementResources = {
  __typename?: 'ItinerariesCuratorsStatementResources'
  block: Array<ItinerariesCuratorsStatementResourcesBlock>
  hyperlink: Array<ItinerariesCuratorsStatementResourcesHyperlink>
  inline: Array<ItinerariesCuratorsStatementResourcesInline>
}

export type ItinerariesCuratorsStatementResourcesBlock = ResourceLink & {
  __typename?: 'ItinerariesCuratorsStatementResourcesBlock'
  sys: ResourceSys
}

export type ItinerariesCuratorsStatementResourcesHyperlink = ResourceLink & {
  __typename?: 'ItinerariesCuratorsStatementResourcesHyperlink'
  sys: ResourceSys
}

export type ItinerariesCuratorsStatementResourcesInline = ResourceLink & {
  __typename?: 'ItinerariesCuratorsStatementResourcesInline'
  sys: ResourceSys
}

export type ItinerariesFilter = {
  AND?: InputMaybe<Array<InputMaybe<ItinerariesFilter>>>
  OR?: InputMaybe<Array<InputMaybe<ItinerariesFilter>>>
  additionalNotes_contains?: InputMaybe<Scalars['String']>
  additionalNotes_exists?: InputMaybe<Scalars['Boolean']>
  additionalNotes_not_contains?: InputMaybe<Scalars['String']>
  advisor?: InputMaybe<CfAdvisorsNestedFilter>
  advisor_exists?: InputMaybe<Scalars['Boolean']>
  atAGlanceTags_contains_all?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  atAGlanceTags_contains_none?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  atAGlanceTags_contains_some?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  atAGlanceTags_exists?: InputMaybe<Scalars['Boolean']>
  categories?: InputMaybe<CfcategoriesMultiTypeNestedFilter>
  categoriesCollection_exists?: InputMaybe<Scalars['Boolean']>
  cloudinaryImage_exists?: InputMaybe<Scalars['Boolean']>
  contentfulMetadata?: InputMaybe<ContentfulMetadataFilter>
  curatorsStatement_contains?: InputMaybe<Scalars['String']>
  curatorsStatement_exists?: InputMaybe<Scalars['Boolean']>
  curatorsStatement_not_contains?: InputMaybe<Scalars['String']>
  hotels?: InputMaybe<CfHotelsNestedFilter>
  hotelsCollection_exists?: InputMaybe<Scalars['Boolean']>
  image_exists?: InputMaybe<Scalars['Boolean']>
  itineraryVariant?: InputMaybe<Scalars['String']>
  itineraryVariant_contains?: InputMaybe<Scalars['String']>
  itineraryVariant_exists?: InputMaybe<Scalars['Boolean']>
  itineraryVariant_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  itineraryVariant_not?: InputMaybe<Scalars['String']>
  itineraryVariant_not_contains?: InputMaybe<Scalars['String']>
  itineraryVariant_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  locationForTitles?: InputMaybe<Scalars['String']>
  locationForTitles_contains?: InputMaybe<Scalars['String']>
  locationForTitles_exists?: InputMaybe<Scalars['Boolean']>
  locationForTitles_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  locationForTitles_not?: InputMaybe<Scalars['String']>
  locationForTitles_not_contains?: InputMaybe<Scalars['String']>
  locationForTitles_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  metaDescription?: InputMaybe<Scalars['String']>
  metaDescription_contains?: InputMaybe<Scalars['String']>
  metaDescription_exists?: InputMaybe<Scalars['Boolean']>
  metaDescription_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  metaDescription_not?: InputMaybe<Scalars['String']>
  metaDescription_not_contains?: InputMaybe<Scalars['String']>
  metaDescription_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  metaTitle?: InputMaybe<Scalars['String']>
  metaTitle_contains?: InputMaybe<Scalars['String']>
  metaTitle_exists?: InputMaybe<Scalars['Boolean']>
  metaTitle_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  metaTitle_not?: InputMaybe<Scalars['String']>
  metaTitle_not_contains?: InputMaybe<Scalars['String']>
  metaTitle_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  needToKnow_contains?: InputMaybe<Scalars['String']>
  needToKnow_exists?: InputMaybe<Scalars['Boolean']>
  needToKnow_not_contains?: InputMaybe<Scalars['String']>
  plans?: InputMaybe<CfItinerariesPlansNestedFilter>
  plansCollection_exists?: InputMaybe<Scalars['Boolean']>
  seoSlug?: InputMaybe<Scalars['String']>
  seoSlug_contains?: InputMaybe<Scalars['String']>
  seoSlug_exists?: InputMaybe<Scalars['Boolean']>
  seoSlug_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  seoSlug_not?: InputMaybe<Scalars['String']>
  seoSlug_not_contains?: InputMaybe<Scalars['String']>
  seoSlug_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  showOnHomepage?: InputMaybe<Scalars['Boolean']>
  showOnHomepage_exists?: InputMaybe<Scalars['Boolean']>
  showOnHomepage_not?: InputMaybe<Scalars['Boolean']>
  showOnItinerariesPage?: InputMaybe<Scalars['Boolean']>
  showOnItinerariesPage_exists?: InputMaybe<Scalars['Boolean']>
  showOnItinerariesPage_not?: InputMaybe<Scalars['Boolean']>
  showOnOtherLandingPages?: InputMaybe<Scalars['Boolean']>
  showOnOtherLandingPages_exists?: InputMaybe<Scalars['Boolean']>
  showOnOtherLandingPages_not?: InputMaybe<Scalars['Boolean']>
  slug?: InputMaybe<Scalars['String']>
  slugID?: InputMaybe<Scalars['String']>
  slugID_contains?: InputMaybe<Scalars['String']>
  slugID_exists?: InputMaybe<Scalars['Boolean']>
  slugID_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  slugID_not?: InputMaybe<Scalars['String']>
  slugID_not_contains?: InputMaybe<Scalars['String']>
  slugID_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  slug_contains?: InputMaybe<Scalars['String']>
  slug_exists?: InputMaybe<Scalars['Boolean']>
  slug_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  slug_not?: InputMaybe<Scalars['String']>
  slug_not_contains?: InputMaybe<Scalars['String']>
  slug_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  sys?: InputMaybe<SysFilter>
  title?: InputMaybe<Scalars['String']>
  title_contains?: InputMaybe<Scalars['String']>
  title_exists?: InputMaybe<Scalars['Boolean']>
  title_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  title_not?: InputMaybe<Scalars['String']>
  title_not_contains?: InputMaybe<Scalars['String']>
  title_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
}

export type ItinerariesHotelsCollection = {
  __typename?: 'ItinerariesHotelsCollection'
  items: Array<Maybe<Hotels>>
  limit: Scalars['Int']
  skip: Scalars['Int']
  total: Scalars['Int']
}

export enum ItinerariesHotelsCollectionOrder {
  ArchivedAsc = 'archived_ASC',
  ArchivedDesc = 'archived_DESC',
  ForaSupplierIdAsc = 'foraSupplierId_ASC',
  ForaSupplierIdDesc = 'foraSupplierId_DESC',
  GenerateLandingPageAsc = 'generateLandingPage_ASC',
  GenerateLandingPageDesc = 'generateLandingPage_DESC',
  HeroImageAsc = 'heroImage_ASC',
  HeroImageDesc = 'heroImage_DESC',
  HideFromTopHotelsPagesAsc = 'hideFromTopHotelsPages_ASC',
  HideFromTopHotelsPagesDesc = 'hideFromTopHotelsPages_DESC',
  MichelinKeysAsc = 'michelinKeys_ASC',
  MichelinKeysDesc = 'michelinKeys_DESC',
  SlugAsc = 'slug_ASC',
  SlugDesc = 'slug_DESC',
  StarRatingAsc = 'starRating_ASC',
  StarRatingDesc = 'starRating_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC',
}

export type ItinerariesLinkingCollections = {
  __typename?: 'ItinerariesLinkingCollections'
  advisorsCollection?: Maybe<AdvisorsCollection>
  componentFeaturedGuidesCollection?: Maybe<ComponentFeaturedGuidesCollection>
  entryCollection?: Maybe<EntryCollection>
  entryTestimonialCollection?: Maybe<EntryTestimonialCollection>
}

export type ItinerariesLinkingCollectionsAdvisorsCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>
  locale?: InputMaybe<Scalars['String']>
  order?: InputMaybe<
    Array<InputMaybe<ItinerariesLinkingCollectionsAdvisorsCollectionOrder>>
  >
  preview?: InputMaybe<Scalars['Boolean']>
  skip?: InputMaybe<Scalars['Int']>
}

export type ItinerariesLinkingCollectionsComponentFeaturedGuidesCollectionArgs =
  {
    limit?: InputMaybe<Scalars['Int']>
    locale?: InputMaybe<Scalars['String']>
    order?: InputMaybe<
      Array<
        InputMaybe<ItinerariesLinkingCollectionsComponentFeaturedGuidesCollectionOrder>
      >
    >
    preview?: InputMaybe<Scalars['Boolean']>
    skip?: InputMaybe<Scalars['Int']>
  }

export type ItinerariesLinkingCollectionsEntryCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>
  locale?: InputMaybe<Scalars['String']>
  preview?: InputMaybe<Scalars['Boolean']>
  skip?: InputMaybe<Scalars['Int']>
}

export type ItinerariesLinkingCollectionsEntryTestimonialCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>
  locale?: InputMaybe<Scalars['String']>
  order?: InputMaybe<
    Array<
      InputMaybe<ItinerariesLinkingCollectionsEntryTestimonialCollectionOrder>
    >
  >
  preview?: InputMaybe<Scalars['Boolean']>
  skip?: InputMaybe<Scalars['Int']>
}

export enum ItinerariesLinkingCollectionsAdvisorsCollectionOrder {
  AdvisorContactEnabledAsc = 'advisorContactEnabled_ASC',
  AdvisorContactEnabledDesc = 'advisorContactEnabled_DESC',
  AdvisorVariantAsc = 'advisorVariant_ASC',
  AdvisorVariantDesc = 'advisorVariant_DESC',
  EmailAddressAsc = 'emailAddress_ASC',
  EmailAddressDesc = 'emailAddress_DESC',
  FacebookUrlAsc = 'facebookUrl_ASC',
  FacebookUrlDesc = 'facebookUrl_DESC',
  FirstNameAsc = 'firstName_ASC',
  FirstNameDesc = 'firstName_DESC',
  ForaAdvisorHotelsAsc = 'foraAdvisorHotels_ASC',
  ForaAdvisorHotelsDesc = 'foraAdvisorHotels_DESC',
  ForaAdvisorIdAsc = 'foraAdvisorId_ASC',
  ForaAdvisorIdDesc = 'foraAdvisorId_DESC',
  InstagramUrlAsc = 'instagramUrl_ASC',
  InstagramUrlDesc = 'instagramUrl_DESC',
  LastNameAsc = 'lastName_ASC',
  LastNameDesc = 'lastName_DESC',
  LinkedinUrlAsc = 'linkedinUrl_ASC',
  LinkedinUrlDesc = 'linkedinUrl_DESC',
  MinimumBookingAsc = 'minimumBooking_ASC',
  MinimumBookingDesc = 'minimumBooking_DESC',
  PinterestUrlAsc = 'pinterestUrl_ASC',
  PinterestUrlDesc = 'pinterestUrl_DESC',
  PronounsAsc = 'pronouns_ASC',
  PronounsDesc = 'pronouns_DESC',
  ShortDescriptionAsc = 'shortDescription_ASC',
  ShortDescriptionDesc = 'shortDescription_DESC',
  ShowOnAdvisorsPageAsc = 'showOnAdvisorsPage_ASC',
  ShowOnAdvisorsPageDesc = 'showOnAdvisorsPage_DESC',
  ShowOnHomepageAsc = 'showOnHomepage_ASC',
  ShowOnHomepageDesc = 'showOnHomepage_DESC',
  ShowOnOtherLandingPagesAsc = 'showOnOtherLandingPages_ASC',
  ShowOnOtherLandingPagesDesc = 'showOnOtherLandingPages_DESC',
  SlugAsc = 'slug_ASC',
  SlugDesc = 'slug_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  TierAsc = 'tier_ASC',
  TierDesc = 'tier_DESC',
  TikTokUrlAsc = 'tikTokUrl_ASC',
  TikTokUrlDesc = 'tikTokUrl_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC',
  TwitterUrlAsc = 'twitterUrl_ASC',
  TwitterUrlDesc = 'twitterUrl_DESC',
  WebsiteUrlAsc = 'websiteUrl_ASC',
  WebsiteUrlDesc = 'websiteUrl_DESC',
  YoutubeUrlAsc = 'youtubeUrl_ASC',
  YoutubeUrlDesc = 'youtubeUrl_DESC',
}

export enum ItinerariesLinkingCollectionsComponentFeaturedGuidesCollectionOrder {
  CtaTargetAsc = 'ctaTarget_ASC',
  CtaTargetDesc = 'ctaTarget_DESC',
  CtaTextAsc = 'ctaText_ASC',
  CtaTextDesc = 'ctaText_DESC',
  InternalLabelAsc = 'internalLabel_ASC',
  InternalLabelDesc = 'internalLabel_DESC',
  SectionIdAsc = 'sectionId_ASC',
  SectionIdDesc = 'sectionId_DESC',
  ShowCtaAsc = 'showCta_ASC',
  ShowCtaDesc = 'showCta_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  TitleStyleAsc = 'titleStyle_ASC',
  TitleStyleDesc = 'titleStyle_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC',
}

export enum ItinerariesLinkingCollectionsEntryTestimonialCollectionOrder {
  AboutForaAsc = 'aboutFora_ASC',
  AboutForaDesc = 'aboutFora_DESC',
  BeginTravelDateAsc = 'beginTravelDate_ASC',
  BeginTravelDateDesc = 'beginTravelDate_DESC',
  DateSubmittedAsc = 'dateSubmitted_ASC',
  DateSubmittedDesc = 'dateSubmitted_DESC',
  DisplayAsc = 'display_ASC',
  DisplayDesc = 'display_DESC',
  EndTravelDateAsc = 'endTravelDate_ASC',
  EndTravelDateDesc = 'endTravelDate_DESC',
  InternalLabelAsc = 'internalLabel_ASC',
  InternalLabelDesc = 'internalLabel_DESC',
  IsFeaturedAsc = 'isFeatured_ASC',
  IsFeaturedDesc = 'isFeatured_DESC',
  StarsAsc = 'stars_ASC',
  StarsDesc = 'stars_DESC',
  SubmitterEmailAsc = 'submitterEmail_ASC',
  SubmitterEmailDesc = 'submitterEmail_DESC',
  SubmitterFirstNameAsc = 'submitterFirstName_ASC',
  SubmitterFirstNameDesc = 'submitterFirstName_DESC',
  SubmitterLastNameAsc = 'submitterLastName_ASC',
  SubmitterLastNameDesc = 'submitterLastName_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  TestimonialSourceAsc = 'testimonialSource_ASC',
  TestimonialSourceDesc = 'testimonialSource_DESC',
  TitleOfReviewAsc = 'titleOfReview_ASC',
  TitleOfReviewDesc = 'titleOfReview_DESC',
}

export type ItinerariesNeedToKnow = {
  __typename?: 'ItinerariesNeedToKnow'
  json: Scalars['JSON']
  links: ItinerariesNeedToKnowLinks
}

export type ItinerariesNeedToKnowAssets = {
  __typename?: 'ItinerariesNeedToKnowAssets'
  block: Array<Maybe<Asset>>
  hyperlink: Array<Maybe<Asset>>
}

export type ItinerariesNeedToKnowEntries = {
  __typename?: 'ItinerariesNeedToKnowEntries'
  block: Array<Maybe<Entry>>
  hyperlink: Array<Maybe<Entry>>
  inline: Array<Maybe<Entry>>
}

export type ItinerariesNeedToKnowLinks = {
  __typename?: 'ItinerariesNeedToKnowLinks'
  assets: ItinerariesNeedToKnowAssets
  entries: ItinerariesNeedToKnowEntries
  resources: ItinerariesNeedToKnowResources
}

export type ItinerariesNeedToKnowResources = {
  __typename?: 'ItinerariesNeedToKnowResources'
  block: Array<ItinerariesNeedToKnowResourcesBlock>
  hyperlink: Array<ItinerariesNeedToKnowResourcesHyperlink>
  inline: Array<ItinerariesNeedToKnowResourcesInline>
}

export type ItinerariesNeedToKnowResourcesBlock = ResourceLink & {
  __typename?: 'ItinerariesNeedToKnowResourcesBlock'
  sys: ResourceSys
}

export type ItinerariesNeedToKnowResourcesHyperlink = ResourceLink & {
  __typename?: 'ItinerariesNeedToKnowResourcesHyperlink'
  sys: ResourceSys
}

export type ItinerariesNeedToKnowResourcesInline = ResourceLink & {
  __typename?: 'ItinerariesNeedToKnowResourcesInline'
  sys: ResourceSys
}

export enum ItinerariesOrder {
  ItineraryVariantAsc = 'itineraryVariant_ASC',
  ItineraryVariantDesc = 'itineraryVariant_DESC',
  LocationForTitlesAsc = 'locationForTitles_ASC',
  LocationForTitlesDesc = 'locationForTitles_DESC',
  MetaDescriptionAsc = 'metaDescription_ASC',
  MetaDescriptionDesc = 'metaDescription_DESC',
  MetaTitleAsc = 'metaTitle_ASC',
  MetaTitleDesc = 'metaTitle_DESC',
  SeoSlugAsc = 'seoSlug_ASC',
  SeoSlugDesc = 'seoSlug_DESC',
  ShowOnHomepageAsc = 'showOnHomepage_ASC',
  ShowOnHomepageDesc = 'showOnHomepage_DESC',
  ShowOnItinerariesPageAsc = 'showOnItinerariesPage_ASC',
  ShowOnItinerariesPageDesc = 'showOnItinerariesPage_DESC',
  ShowOnOtherLandingPagesAsc = 'showOnOtherLandingPages_ASC',
  ShowOnOtherLandingPagesDesc = 'showOnOtherLandingPages_DESC',
  SlugIdAsc = 'slugID_ASC',
  SlugIdDesc = 'slugID_DESC',
  SlugAsc = 'slug_ASC',
  SlugDesc = 'slug_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC',
}

/** Create and manage all Plans for Itineraries and Stories. [See type definition](https://app.contentful.com/spaces/9cxgygtadmvl/content_types/itinerariesPlans) */
export type ItinerariesPlans = Entry &
  _Node & {
    __typename?: 'ItinerariesPlans'
    _id: Scalars['ID']
    content?: Maybe<ItinerariesPlansContent>
    contentfulMetadata: ContentfulMetadata
    image?: Maybe<Asset>
    imageCaption?: Maybe<Scalars['String']>
    imageContent?: Maybe<ItinerariesPlansImageContent>
    linkedFrom?: Maybe<ItinerariesPlansLinkingCollections>
    note?: Maybe<ItinerariesPlansNote>
    sys: Sys
    title?: Maybe<Scalars['String']>
  }

/** Create and manage all Plans for Itineraries and Stories. [See type definition](https://app.contentful.com/spaces/9cxgygtadmvl/content_types/itinerariesPlans) */
export type ItinerariesPlansContentArgs = {
  locale?: InputMaybe<Scalars['String']>
}

/** Create and manage all Plans for Itineraries and Stories. [See type definition](https://app.contentful.com/spaces/9cxgygtadmvl/content_types/itinerariesPlans) */
export type ItinerariesPlansImageArgs = {
  locale?: InputMaybe<Scalars['String']>
  preview?: InputMaybe<Scalars['Boolean']>
}

/** Create and manage all Plans for Itineraries and Stories. [See type definition](https://app.contentful.com/spaces/9cxgygtadmvl/content_types/itinerariesPlans) */
export type ItinerariesPlansImageCaptionArgs = {
  locale?: InputMaybe<Scalars['String']>
}

/** Create and manage all Plans for Itineraries and Stories. [See type definition](https://app.contentful.com/spaces/9cxgygtadmvl/content_types/itinerariesPlans) */
export type ItinerariesPlansImageContentArgs = {
  locale?: InputMaybe<Scalars['String']>
  preview?: InputMaybe<Scalars['Boolean']>
}

/** Create and manage all Plans for Itineraries and Stories. [See type definition](https://app.contentful.com/spaces/9cxgygtadmvl/content_types/itinerariesPlans) */
export type ItinerariesPlansLinkedFromArgs = {
  allowedLocales?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
}

/** Create and manage all Plans for Itineraries and Stories. [See type definition](https://app.contentful.com/spaces/9cxgygtadmvl/content_types/itinerariesPlans) */
export type ItinerariesPlansNoteArgs = {
  locale?: InputMaybe<Scalars['String']>
}

/** Create and manage all Plans for Itineraries and Stories. [See type definition](https://app.contentful.com/spaces/9cxgygtadmvl/content_types/itinerariesPlans) */
export type ItinerariesPlansTitleArgs = {
  locale?: InputMaybe<Scalars['String']>
}

export type ItinerariesPlansCollection = {
  __typename?: 'ItinerariesPlansCollection'
  items: Array<Maybe<ItinerariesPlans>>
  limit: Scalars['Int']
  skip: Scalars['Int']
  total: Scalars['Int']
}

export enum ItinerariesPlansCollectionOrder {
  ImageCaptionAsc = 'imageCaption_ASC',
  ImageCaptionDesc = 'imageCaption_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC',
}

export type ItinerariesPlansContent = {
  __typename?: 'ItinerariesPlansContent'
  json: Scalars['JSON']
  links: ItinerariesPlansContentLinks
}

export type ItinerariesPlansContentAssets = {
  __typename?: 'ItinerariesPlansContentAssets'
  block: Array<Maybe<Asset>>
  hyperlink: Array<Maybe<Asset>>
}

export type ItinerariesPlansContentEntries = {
  __typename?: 'ItinerariesPlansContentEntries'
  block: Array<Maybe<Entry>>
  hyperlink: Array<Maybe<Entry>>
  inline: Array<Maybe<Entry>>
}

export type ItinerariesPlansContentLinks = {
  __typename?: 'ItinerariesPlansContentLinks'
  assets: ItinerariesPlansContentAssets
  entries: ItinerariesPlansContentEntries
  resources: ItinerariesPlansContentResources
}

export type ItinerariesPlansContentResources = {
  __typename?: 'ItinerariesPlansContentResources'
  block: Array<ItinerariesPlansContentResourcesBlock>
  hyperlink: Array<ItinerariesPlansContentResourcesHyperlink>
  inline: Array<ItinerariesPlansContentResourcesInline>
}

export type ItinerariesPlansContentResourcesBlock = ResourceLink & {
  __typename?: 'ItinerariesPlansContentResourcesBlock'
  sys: ResourceSys
}

export type ItinerariesPlansContentResourcesHyperlink = ResourceLink & {
  __typename?: 'ItinerariesPlansContentResourcesHyperlink'
  sys: ResourceSys
}

export type ItinerariesPlansContentResourcesInline = ResourceLink & {
  __typename?: 'ItinerariesPlansContentResourcesInline'
  sys: ResourceSys
}

export type ItinerariesPlansFilter = {
  AND?: InputMaybe<Array<InputMaybe<ItinerariesPlansFilter>>>
  OR?: InputMaybe<Array<InputMaybe<ItinerariesPlansFilter>>>
  content_contains?: InputMaybe<Scalars['String']>
  content_exists?: InputMaybe<Scalars['Boolean']>
  content_not_contains?: InputMaybe<Scalars['String']>
  contentfulMetadata?: InputMaybe<ContentfulMetadataFilter>
  imageCaption?: InputMaybe<Scalars['String']>
  imageCaption_contains?: InputMaybe<Scalars['String']>
  imageCaption_exists?: InputMaybe<Scalars['Boolean']>
  imageCaption_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  imageCaption_not?: InputMaybe<Scalars['String']>
  imageCaption_not_contains?: InputMaybe<Scalars['String']>
  imageCaption_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  imageContent_exists?: InputMaybe<Scalars['Boolean']>
  image_exists?: InputMaybe<Scalars['Boolean']>
  note_contains?: InputMaybe<Scalars['String']>
  note_exists?: InputMaybe<Scalars['Boolean']>
  note_not_contains?: InputMaybe<Scalars['String']>
  sys?: InputMaybe<SysFilter>
  title?: InputMaybe<Scalars['String']>
  title_contains?: InputMaybe<Scalars['String']>
  title_exists?: InputMaybe<Scalars['Boolean']>
  title_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  title_not?: InputMaybe<Scalars['String']>
  title_not_contains?: InputMaybe<Scalars['String']>
  title_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
}

export type ItinerariesPlansImageContent =
  | ComponentImage
  | ComponentImageSideBySide

export type ItinerariesPlansLinkingCollections = {
  __typename?: 'ItinerariesPlansLinkingCollections'
  entryCollection?: Maybe<EntryCollection>
  itinerariesCollection?: Maybe<ItinerariesCollection>
}

export type ItinerariesPlansLinkingCollectionsEntryCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>
  locale?: InputMaybe<Scalars['String']>
  preview?: InputMaybe<Scalars['Boolean']>
  skip?: InputMaybe<Scalars['Int']>
}

export type ItinerariesPlansLinkingCollectionsItinerariesCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>
  locale?: InputMaybe<Scalars['String']>
  order?: InputMaybe<
    Array<
      InputMaybe<ItinerariesPlansLinkingCollectionsItinerariesCollectionOrder>
    >
  >
  preview?: InputMaybe<Scalars['Boolean']>
  skip?: InputMaybe<Scalars['Int']>
}

export enum ItinerariesPlansLinkingCollectionsItinerariesCollectionOrder {
  ItineraryVariantAsc = 'itineraryVariant_ASC',
  ItineraryVariantDesc = 'itineraryVariant_DESC',
  LocationForTitlesAsc = 'locationForTitles_ASC',
  LocationForTitlesDesc = 'locationForTitles_DESC',
  MetaDescriptionAsc = 'metaDescription_ASC',
  MetaDescriptionDesc = 'metaDescription_DESC',
  MetaTitleAsc = 'metaTitle_ASC',
  MetaTitleDesc = 'metaTitle_DESC',
  SeoSlugAsc = 'seoSlug_ASC',
  SeoSlugDesc = 'seoSlug_DESC',
  ShowOnHomepageAsc = 'showOnHomepage_ASC',
  ShowOnHomepageDesc = 'showOnHomepage_DESC',
  ShowOnItinerariesPageAsc = 'showOnItinerariesPage_ASC',
  ShowOnItinerariesPageDesc = 'showOnItinerariesPage_DESC',
  ShowOnOtherLandingPagesAsc = 'showOnOtherLandingPages_ASC',
  ShowOnOtherLandingPagesDesc = 'showOnOtherLandingPages_DESC',
  SlugIdAsc = 'slugID_ASC',
  SlugIdDesc = 'slugID_DESC',
  SlugAsc = 'slug_ASC',
  SlugDesc = 'slug_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC',
}

export type ItinerariesPlansNote = {
  __typename?: 'ItinerariesPlansNote'
  json: Scalars['JSON']
  links: ItinerariesPlansNoteLinks
}

export type ItinerariesPlansNoteAssets = {
  __typename?: 'ItinerariesPlansNoteAssets'
  block: Array<Maybe<Asset>>
  hyperlink: Array<Maybe<Asset>>
}

export type ItinerariesPlansNoteEntries = {
  __typename?: 'ItinerariesPlansNoteEntries'
  block: Array<Maybe<Entry>>
  hyperlink: Array<Maybe<Entry>>
  inline: Array<Maybe<Entry>>
}

export type ItinerariesPlansNoteLinks = {
  __typename?: 'ItinerariesPlansNoteLinks'
  assets: ItinerariesPlansNoteAssets
  entries: ItinerariesPlansNoteEntries
  resources: ItinerariesPlansNoteResources
}

export type ItinerariesPlansNoteResources = {
  __typename?: 'ItinerariesPlansNoteResources'
  block: Array<ItinerariesPlansNoteResourcesBlock>
  hyperlink: Array<ItinerariesPlansNoteResourcesHyperlink>
  inline: Array<ItinerariesPlansNoteResourcesInline>
}

export type ItinerariesPlansNoteResourcesBlock = ResourceLink & {
  __typename?: 'ItinerariesPlansNoteResourcesBlock'
  sys: ResourceSys
}

export type ItinerariesPlansNoteResourcesHyperlink = ResourceLink & {
  __typename?: 'ItinerariesPlansNoteResourcesHyperlink'
  sys: ResourceSys
}

export type ItinerariesPlansNoteResourcesInline = ResourceLink & {
  __typename?: 'ItinerariesPlansNoteResourcesInline'
  sys: ResourceSys
}

export enum ItinerariesPlansOrder {
  ImageCaptionAsc = 'imageCaption_ASC',
  ImageCaptionDesc = 'imageCaption_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC',
}

/** A page section with teaser copy followed by a curate grid of featured hotels. [See type definition](https://app.contentful.com/spaces/9cxgygtadmvl/content_types/pagesFeaturedHotels) */
export type PagesFeaturedHotels = Entry &
  _Node & {
    __typename?: 'PagesFeaturedHotels'
    _id: Scalars['ID']
    analyticsModule?: Maybe<SettingsAnalyticsModule>
    contentfulMetadata: ContentfulMetadata
    display?: Maybe<Scalars['String']>
    headerAlignment?: Maybe<Scalars['String']>
    hotelsCollection?: Maybe<PagesFeaturedHotelsHotelsCollection>
    linkedFrom?: Maybe<PagesFeaturedHotelsLinkingCollections>
    showControls?: Maybe<Scalars['Boolean']>
    showTitle?: Maybe<Scalars['Boolean']>
    sys: Sys
    teaserCopy?: Maybe<PagesFeaturedHotelsTeaserCopy>
    title?: Maybe<Scalars['String']>
  }

/** A page section with teaser copy followed by a curate grid of featured hotels. [See type definition](https://app.contentful.com/spaces/9cxgygtadmvl/content_types/pagesFeaturedHotels) */
export type PagesFeaturedHotelsAnalyticsModuleArgs = {
  locale?: InputMaybe<Scalars['String']>
  preview?: InputMaybe<Scalars['Boolean']>
  where?: InputMaybe<SettingsAnalyticsModuleFilter>
}

/** A page section with teaser copy followed by a curate grid of featured hotels. [See type definition](https://app.contentful.com/spaces/9cxgygtadmvl/content_types/pagesFeaturedHotels) */
export type PagesFeaturedHotelsDisplayArgs = {
  locale?: InputMaybe<Scalars['String']>
}

/** A page section with teaser copy followed by a curate grid of featured hotels. [See type definition](https://app.contentful.com/spaces/9cxgygtadmvl/content_types/pagesFeaturedHotels) */
export type PagesFeaturedHotelsHeaderAlignmentArgs = {
  locale?: InputMaybe<Scalars['String']>
}

/** A page section with teaser copy followed by a curate grid of featured hotels. [See type definition](https://app.contentful.com/spaces/9cxgygtadmvl/content_types/pagesFeaturedHotels) */
export type PagesFeaturedHotelsHotelsCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>
  locale?: InputMaybe<Scalars['String']>
  order?: InputMaybe<
    Array<InputMaybe<PagesFeaturedHotelsHotelsCollectionOrder>>
  >
  preview?: InputMaybe<Scalars['Boolean']>
  skip?: InputMaybe<Scalars['Int']>
  where?: InputMaybe<HotelsFilter>
}

/** A page section with teaser copy followed by a curate grid of featured hotels. [See type definition](https://app.contentful.com/spaces/9cxgygtadmvl/content_types/pagesFeaturedHotels) */
export type PagesFeaturedHotelsLinkedFromArgs = {
  allowedLocales?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
}

/** A page section with teaser copy followed by a curate grid of featured hotels. [See type definition](https://app.contentful.com/spaces/9cxgygtadmvl/content_types/pagesFeaturedHotels) */
export type PagesFeaturedHotelsShowControlsArgs = {
  locale?: InputMaybe<Scalars['String']>
}

/** A page section with teaser copy followed by a curate grid of featured hotels. [See type definition](https://app.contentful.com/spaces/9cxgygtadmvl/content_types/pagesFeaturedHotels) */
export type PagesFeaturedHotelsShowTitleArgs = {
  locale?: InputMaybe<Scalars['String']>
}

/** A page section with teaser copy followed by a curate grid of featured hotels. [See type definition](https://app.contentful.com/spaces/9cxgygtadmvl/content_types/pagesFeaturedHotels) */
export type PagesFeaturedHotelsTeaserCopyArgs = {
  locale?: InputMaybe<Scalars['String']>
}

/** A page section with teaser copy followed by a curate grid of featured hotels. [See type definition](https://app.contentful.com/spaces/9cxgygtadmvl/content_types/pagesFeaturedHotels) */
export type PagesFeaturedHotelsTitleArgs = {
  locale?: InputMaybe<Scalars['String']>
}

export type PagesFeaturedHotelsCollection = {
  __typename?: 'PagesFeaturedHotelsCollection'
  items: Array<Maybe<PagesFeaturedHotels>>
  limit: Scalars['Int']
  skip: Scalars['Int']
  total: Scalars['Int']
}

export type PagesFeaturedHotelsFilter = {
  AND?: InputMaybe<Array<InputMaybe<PagesFeaturedHotelsFilter>>>
  OR?: InputMaybe<Array<InputMaybe<PagesFeaturedHotelsFilter>>>
  analyticsModule?: InputMaybe<CfSettingsAnalyticsModuleNestedFilter>
  analyticsModule_exists?: InputMaybe<Scalars['Boolean']>
  contentfulMetadata?: InputMaybe<ContentfulMetadataFilter>
  display?: InputMaybe<Scalars['String']>
  display_contains?: InputMaybe<Scalars['String']>
  display_exists?: InputMaybe<Scalars['Boolean']>
  display_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  display_not?: InputMaybe<Scalars['String']>
  display_not_contains?: InputMaybe<Scalars['String']>
  display_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  headerAlignment?: InputMaybe<Scalars['String']>
  headerAlignment_contains?: InputMaybe<Scalars['String']>
  headerAlignment_exists?: InputMaybe<Scalars['Boolean']>
  headerAlignment_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  headerAlignment_not?: InputMaybe<Scalars['String']>
  headerAlignment_not_contains?: InputMaybe<Scalars['String']>
  headerAlignment_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  hotels?: InputMaybe<CfHotelsNestedFilter>
  hotelsCollection_exists?: InputMaybe<Scalars['Boolean']>
  showControls?: InputMaybe<Scalars['Boolean']>
  showControls_exists?: InputMaybe<Scalars['Boolean']>
  showControls_not?: InputMaybe<Scalars['Boolean']>
  showTitle?: InputMaybe<Scalars['Boolean']>
  showTitle_exists?: InputMaybe<Scalars['Boolean']>
  showTitle_not?: InputMaybe<Scalars['Boolean']>
  sys?: InputMaybe<SysFilter>
  teaserCopy_contains?: InputMaybe<Scalars['String']>
  teaserCopy_exists?: InputMaybe<Scalars['Boolean']>
  teaserCopy_not_contains?: InputMaybe<Scalars['String']>
  title?: InputMaybe<Scalars['String']>
  title_contains?: InputMaybe<Scalars['String']>
  title_exists?: InputMaybe<Scalars['Boolean']>
  title_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  title_not?: InputMaybe<Scalars['String']>
  title_not_contains?: InputMaybe<Scalars['String']>
  title_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
}

export type PagesFeaturedHotelsHotelsCollection = {
  __typename?: 'PagesFeaturedHotelsHotelsCollection'
  items: Array<Maybe<Hotels>>
  limit: Scalars['Int']
  skip: Scalars['Int']
  total: Scalars['Int']
}

export enum PagesFeaturedHotelsHotelsCollectionOrder {
  ArchivedAsc = 'archived_ASC',
  ArchivedDesc = 'archived_DESC',
  ForaSupplierIdAsc = 'foraSupplierId_ASC',
  ForaSupplierIdDesc = 'foraSupplierId_DESC',
  GenerateLandingPageAsc = 'generateLandingPage_ASC',
  GenerateLandingPageDesc = 'generateLandingPage_DESC',
  HeroImageAsc = 'heroImage_ASC',
  HeroImageDesc = 'heroImage_DESC',
  HideFromTopHotelsPagesAsc = 'hideFromTopHotelsPages_ASC',
  HideFromTopHotelsPagesDesc = 'hideFromTopHotelsPages_DESC',
  MichelinKeysAsc = 'michelinKeys_ASC',
  MichelinKeysDesc = 'michelinKeys_DESC',
  SlugAsc = 'slug_ASC',
  SlugDesc = 'slug_DESC',
  StarRatingAsc = 'starRating_ASC',
  StarRatingDesc = 'starRating_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC',
}

export type PagesFeaturedHotelsLinkingCollections = {
  __typename?: 'PagesFeaturedHotelsLinkingCollections'
  campaignPagesCollection?: Maybe<CampaignPagesCollection>
  entryCollection?: Maybe<EntryCollection>
  templateAboutUsCollection?: Maybe<TemplateAboutUsCollection>
  templateBookingCollection?: Maybe<TemplateBookingCollection>
  templateCareersCollection?: Maybe<TemplateCareersCollection>
  templateContactUsCollection?: Maybe<TemplateContactUsCollection>
  templateDestinationsCollection?: Maybe<TemplateDestinationsCollection>
  templateForaReserveCollection?: Maybe<TemplateForaReserveCollection>
  templateForaXCollection?: Maybe<TemplateForaXCollection>
  templateHomeCollection?: Maybe<TemplateHomeCollection>
  templateJoinUsCollection?: Maybe<TemplateJoinUsCollection>
  templateNewsletterCollection?: Maybe<TemplateNewsletterCollection>
  templatePageCollection?: Maybe<TemplatePageCollection>
  templatePartnersCollection?: Maybe<TemplatePartnersCollection>
  templateReviewsCollection?: Maybe<TemplateReviewsCollection>
  templateWhyBookWithForaCollection?: Maybe<TemplateWhyBookWithForaCollection>
}

export type PagesFeaturedHotelsLinkingCollectionsCampaignPagesCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>
  locale?: InputMaybe<Scalars['String']>
  order?: InputMaybe<
    Array<
      InputMaybe<PagesFeaturedHotelsLinkingCollectionsCampaignPagesCollectionOrder>
    >
  >
  preview?: InputMaybe<Scalars['Boolean']>
  skip?: InputMaybe<Scalars['Int']>
}

export type PagesFeaturedHotelsLinkingCollectionsEntryCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>
  locale?: InputMaybe<Scalars['String']>
  preview?: InputMaybe<Scalars['Boolean']>
  skip?: InputMaybe<Scalars['Int']>
}

export type PagesFeaturedHotelsLinkingCollectionsTemplateAboutUsCollectionArgs =
  {
    limit?: InputMaybe<Scalars['Int']>
    locale?: InputMaybe<Scalars['String']>
    order?: InputMaybe<
      Array<
        InputMaybe<PagesFeaturedHotelsLinkingCollectionsTemplateAboutUsCollectionOrder>
      >
    >
    preview?: InputMaybe<Scalars['Boolean']>
    skip?: InputMaybe<Scalars['Int']>
  }

export type PagesFeaturedHotelsLinkingCollectionsTemplateBookingCollectionArgs =
  {
    limit?: InputMaybe<Scalars['Int']>
    locale?: InputMaybe<Scalars['String']>
    order?: InputMaybe<
      Array<
        InputMaybe<PagesFeaturedHotelsLinkingCollectionsTemplateBookingCollectionOrder>
      >
    >
    preview?: InputMaybe<Scalars['Boolean']>
    skip?: InputMaybe<Scalars['Int']>
  }

export type PagesFeaturedHotelsLinkingCollectionsTemplateCareersCollectionArgs =
  {
    limit?: InputMaybe<Scalars['Int']>
    locale?: InputMaybe<Scalars['String']>
    order?: InputMaybe<
      Array<
        InputMaybe<PagesFeaturedHotelsLinkingCollectionsTemplateCareersCollectionOrder>
      >
    >
    preview?: InputMaybe<Scalars['Boolean']>
    skip?: InputMaybe<Scalars['Int']>
  }

export type PagesFeaturedHotelsLinkingCollectionsTemplateContactUsCollectionArgs =
  {
    limit?: InputMaybe<Scalars['Int']>
    locale?: InputMaybe<Scalars['String']>
    order?: InputMaybe<
      Array<
        InputMaybe<PagesFeaturedHotelsLinkingCollectionsTemplateContactUsCollectionOrder>
      >
    >
    preview?: InputMaybe<Scalars['Boolean']>
    skip?: InputMaybe<Scalars['Int']>
  }

export type PagesFeaturedHotelsLinkingCollectionsTemplateDestinationsCollectionArgs =
  {
    limit?: InputMaybe<Scalars['Int']>
    locale?: InputMaybe<Scalars['String']>
    order?: InputMaybe<
      Array<
        InputMaybe<PagesFeaturedHotelsLinkingCollectionsTemplateDestinationsCollectionOrder>
      >
    >
    preview?: InputMaybe<Scalars['Boolean']>
    skip?: InputMaybe<Scalars['Int']>
  }

export type PagesFeaturedHotelsLinkingCollectionsTemplateForaReserveCollectionArgs =
  {
    limit?: InputMaybe<Scalars['Int']>
    locale?: InputMaybe<Scalars['String']>
    order?: InputMaybe<
      Array<
        InputMaybe<PagesFeaturedHotelsLinkingCollectionsTemplateForaReserveCollectionOrder>
      >
    >
    preview?: InputMaybe<Scalars['Boolean']>
    skip?: InputMaybe<Scalars['Int']>
  }

export type PagesFeaturedHotelsLinkingCollectionsTemplateForaXCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>
  locale?: InputMaybe<Scalars['String']>
  order?: InputMaybe<
    Array<
      InputMaybe<PagesFeaturedHotelsLinkingCollectionsTemplateForaXCollectionOrder>
    >
  >
  preview?: InputMaybe<Scalars['Boolean']>
  skip?: InputMaybe<Scalars['Int']>
}

export type PagesFeaturedHotelsLinkingCollectionsTemplateHomeCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>
  locale?: InputMaybe<Scalars['String']>
  order?: InputMaybe<
    Array<
      InputMaybe<PagesFeaturedHotelsLinkingCollectionsTemplateHomeCollectionOrder>
    >
  >
  preview?: InputMaybe<Scalars['Boolean']>
  skip?: InputMaybe<Scalars['Int']>
}

export type PagesFeaturedHotelsLinkingCollectionsTemplateJoinUsCollectionArgs =
  {
    limit?: InputMaybe<Scalars['Int']>
    locale?: InputMaybe<Scalars['String']>
    order?: InputMaybe<
      Array<
        InputMaybe<PagesFeaturedHotelsLinkingCollectionsTemplateJoinUsCollectionOrder>
      >
    >
    preview?: InputMaybe<Scalars['Boolean']>
    skip?: InputMaybe<Scalars['Int']>
  }

export type PagesFeaturedHotelsLinkingCollectionsTemplateNewsletterCollectionArgs =
  {
    limit?: InputMaybe<Scalars['Int']>
    locale?: InputMaybe<Scalars['String']>
    order?: InputMaybe<
      Array<
        InputMaybe<PagesFeaturedHotelsLinkingCollectionsTemplateNewsletterCollectionOrder>
      >
    >
    preview?: InputMaybe<Scalars['Boolean']>
    skip?: InputMaybe<Scalars['Int']>
  }

export type PagesFeaturedHotelsLinkingCollectionsTemplatePageCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>
  locale?: InputMaybe<Scalars['String']>
  order?: InputMaybe<
    Array<
      InputMaybe<PagesFeaturedHotelsLinkingCollectionsTemplatePageCollectionOrder>
    >
  >
  preview?: InputMaybe<Scalars['Boolean']>
  skip?: InputMaybe<Scalars['Int']>
}

export type PagesFeaturedHotelsLinkingCollectionsTemplatePartnersCollectionArgs =
  {
    limit?: InputMaybe<Scalars['Int']>
    locale?: InputMaybe<Scalars['String']>
    order?: InputMaybe<
      Array<
        InputMaybe<PagesFeaturedHotelsLinkingCollectionsTemplatePartnersCollectionOrder>
      >
    >
    preview?: InputMaybe<Scalars['Boolean']>
    skip?: InputMaybe<Scalars['Int']>
  }

export type PagesFeaturedHotelsLinkingCollectionsTemplateReviewsCollectionArgs =
  {
    limit?: InputMaybe<Scalars['Int']>
    locale?: InputMaybe<Scalars['String']>
    order?: InputMaybe<
      Array<
        InputMaybe<PagesFeaturedHotelsLinkingCollectionsTemplateReviewsCollectionOrder>
      >
    >
    preview?: InputMaybe<Scalars['Boolean']>
    skip?: InputMaybe<Scalars['Int']>
  }

export type PagesFeaturedHotelsLinkingCollectionsTemplateWhyBookWithForaCollectionArgs =
  {
    limit?: InputMaybe<Scalars['Int']>
    locale?: InputMaybe<Scalars['String']>
    order?: InputMaybe<
      Array<
        InputMaybe<PagesFeaturedHotelsLinkingCollectionsTemplateWhyBookWithForaCollectionOrder>
      >
    >
    preview?: InputMaybe<Scalars['Boolean']>
    skip?: InputMaybe<Scalars['Int']>
  }

export enum PagesFeaturedHotelsLinkingCollectionsCampaignPagesCollectionOrder {
  CollapseFooterAsc = 'collapseFooter_ASC',
  CollapseFooterDesc = 'collapseFooter_DESC',
  CollapseHeaderAsc = 'collapseHeader_ASC',
  CollapseHeaderDesc = 'collapseHeader_DESC',
  HideFooterAsc = 'hideFooter_ASC',
  HideFooterDesc = 'hideFooter_DESC',
  HideGlobalBannerAsc = 'hideGlobalBanner_ASC',
  HideGlobalBannerDesc = 'hideGlobalBanner_DESC',
  JotformIdAsc = 'jotformId_ASC',
  JotformIdDesc = 'jotformId_DESC',
  NoindexNofollowAsc = 'noindexNofollow_ASC',
  NoindexNofollowDesc = 'noindexNofollow_DESC',
  ShowHeaderAsc = 'showHeader_ASC',
  ShowHeaderDesc = 'showHeader_DESC',
  SlugAsc = 'slug_ASC',
  SlugDesc = 'slug_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC',
}

export enum PagesFeaturedHotelsLinkingCollectionsTemplateAboutUsCollectionOrder {
  CollapseHeaderAsc = 'collapseHeader_ASC',
  CollapseHeaderDesc = 'collapseHeader_DESC',
  HeaderBlockTitleAsc = 'headerBlockTitle_ASC',
  HeaderBlockTitleDesc = 'headerBlockTitle_DESC',
  ShowHeaderAsc = 'showHeader_ASC',
  ShowHeaderDesc = 'showHeader_DESC',
  SubtitleAsc = 'subtitle_ASC',
  SubtitleDesc = 'subtitle_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC',
}

export enum PagesFeaturedHotelsLinkingCollectionsTemplateBookingCollectionOrder {
  CollapseHeaderAsc = 'collapseHeader_ASC',
  CollapseHeaderDesc = 'collapseHeader_DESC',
  ShowHeaderAsc = 'showHeader_ASC',
  ShowHeaderDesc = 'showHeader_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC',
}

export enum PagesFeaturedHotelsLinkingCollectionsTemplateCareersCollectionOrder {
  CollapseHeaderAsc = 'collapseHeader_ASC',
  CollapseHeaderDesc = 'collapseHeader_DESC',
  ShowHeaderAsc = 'showHeader_ASC',
  ShowHeaderDesc = 'showHeader_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC',
}

export enum PagesFeaturedHotelsLinkingCollectionsTemplateContactUsCollectionOrder {
  CollapseHeaderAsc = 'collapseHeader_ASC',
  CollapseHeaderDesc = 'collapseHeader_DESC',
  ShowHeaderAsc = 'showHeader_ASC',
  ShowHeaderDesc = 'showHeader_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC',
}

export enum PagesFeaturedHotelsLinkingCollectionsTemplateDestinationsCollectionOrder {
  CollapseHeaderAsc = 'collapseHeader_ASC',
  CollapseHeaderDesc = 'collapseHeader_DESC',
  CollapsibleHeadingAsc = 'collapsibleHeading_ASC',
  CollapsibleHeadingDesc = 'collapsibleHeading_DESC',
  ShowHeaderAsc = 'showHeader_ASC',
  ShowHeaderDesc = 'showHeader_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC',
}

export enum PagesFeaturedHotelsLinkingCollectionsTemplateForaReserveCollectionOrder {
  CollapseHeaderAsc = 'collapseHeader_ASC',
  CollapseHeaderDesc = 'collapseHeader_DESC',
  ShowHeaderAsc = 'showHeader_ASC',
  ShowHeaderDesc = 'showHeader_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC',
}

export enum PagesFeaturedHotelsLinkingCollectionsTemplateForaXCollectionOrder {
  AdvisorGridHeaderAsc = 'advisorGridHeader_ASC',
  AdvisorGridHeaderDesc = 'advisorGridHeader_DESC',
  AdvisorGridSubCopyAsc = 'advisorGridSubCopy_ASC',
  AdvisorGridSubCopyDesc = 'advisorGridSubCopy_DESC',
  CollapseHeaderAsc = 'collapseHeader_ASC',
  CollapseHeaderDesc = 'collapseHeader_DESC',
  ShowHeaderAsc = 'showHeader_ASC',
  ShowHeaderDesc = 'showHeader_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC',
}

export enum PagesFeaturedHotelsLinkingCollectionsTemplateHomeCollectionOrder {
  CollapseHeaderAsc = 'collapseHeader_ASC',
  CollapseHeaderDesc = 'collapseHeader_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC',
}

export enum PagesFeaturedHotelsLinkingCollectionsTemplateJoinUsCollectionOrder {
  CollapseHeaderAsc = 'collapseHeader_ASC',
  CollapseHeaderDesc = 'collapseHeader_DESC',
  HideGlobalBannerAsc = 'hideGlobalBanner_ASC',
  HideGlobalBannerDesc = 'hideGlobalBanner_DESC',
  ShowHeaderAsc = 'showHeader_ASC',
  ShowHeaderDesc = 'showHeader_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC',
}

export enum PagesFeaturedHotelsLinkingCollectionsTemplateNewsletterCollectionOrder {
  CollapseHeaderAsc = 'collapseHeader_ASC',
  CollapseHeaderDesc = 'collapseHeader_DESC',
  ShowHeaderAsc = 'showHeader_ASC',
  ShowHeaderDesc = 'showHeader_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC',
}

export enum PagesFeaturedHotelsLinkingCollectionsTemplatePageCollectionOrder {
  DirectoryAsc = 'directory_ASC',
  DirectoryDesc = 'directory_DESC',
  InternalLabelAsc = 'internalLabel_ASC',
  InternalLabelDesc = 'internalLabel_DESC',
  MetaTitleAsc = 'metaTitle_ASC',
  MetaTitleDesc = 'metaTitle_DESC',
  NoindexNofollowAsc = 'noindexNofollow_ASC',
  NoindexNofollowDesc = 'noindexNofollow_DESC',
  SlugAsc = 'slug_ASC',
  SlugDesc = 'slug_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
}

export enum PagesFeaturedHotelsLinkingCollectionsTemplatePartnersCollectionOrder {
  CollapseHeaderAsc = 'collapseHeader_ASC',
  CollapseHeaderDesc = 'collapseHeader_DESC',
  ShowHeaderAsc = 'showHeader_ASC',
  ShowHeaderDesc = 'showHeader_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC',
}

export enum PagesFeaturedHotelsLinkingCollectionsTemplateReviewsCollectionOrder {
  CollapseHeaderAsc = 'collapseHeader_ASC',
  CollapseHeaderDesc = 'collapseHeader_DESC',
  ShowHeaderAsc = 'showHeader_ASC',
  ShowHeaderDesc = 'showHeader_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC',
}

export enum PagesFeaturedHotelsLinkingCollectionsTemplateWhyBookWithForaCollectionOrder {
  CollapseHeaderAsc = 'collapseHeader_ASC',
  CollapseHeaderDesc = 'collapseHeader_DESC',
  ShowHeaderAsc = 'showHeader_ASC',
  ShowHeaderDesc = 'showHeader_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC',
}

export enum PagesFeaturedHotelsOrder {
  DisplayAsc = 'display_ASC',
  DisplayDesc = 'display_DESC',
  HeaderAlignmentAsc = 'headerAlignment_ASC',
  HeaderAlignmentDesc = 'headerAlignment_DESC',
  ShowControlsAsc = 'showControls_ASC',
  ShowControlsDesc = 'showControls_DESC',
  ShowTitleAsc = 'showTitle_ASC',
  ShowTitleDesc = 'showTitle_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC',
}

export type PagesFeaturedHotelsTeaserCopy = {
  __typename?: 'PagesFeaturedHotelsTeaserCopy'
  json: Scalars['JSON']
  links: PagesFeaturedHotelsTeaserCopyLinks
}

export type PagesFeaturedHotelsTeaserCopyAssets = {
  __typename?: 'PagesFeaturedHotelsTeaserCopyAssets'
  block: Array<Maybe<Asset>>
  hyperlink: Array<Maybe<Asset>>
}

export type PagesFeaturedHotelsTeaserCopyEntries = {
  __typename?: 'PagesFeaturedHotelsTeaserCopyEntries'
  block: Array<Maybe<Entry>>
  hyperlink: Array<Maybe<Entry>>
  inline: Array<Maybe<Entry>>
}

export type PagesFeaturedHotelsTeaserCopyLinks = {
  __typename?: 'PagesFeaturedHotelsTeaserCopyLinks'
  assets: PagesFeaturedHotelsTeaserCopyAssets
  entries: PagesFeaturedHotelsTeaserCopyEntries
  resources: PagesFeaturedHotelsTeaserCopyResources
}

export type PagesFeaturedHotelsTeaserCopyResources = {
  __typename?: 'PagesFeaturedHotelsTeaserCopyResources'
  block: Array<PagesFeaturedHotelsTeaserCopyResourcesBlock>
  hyperlink: Array<PagesFeaturedHotelsTeaserCopyResourcesHyperlink>
  inline: Array<PagesFeaturedHotelsTeaserCopyResourcesInline>
}

export type PagesFeaturedHotelsTeaserCopyResourcesBlock = ResourceLink & {
  __typename?: 'PagesFeaturedHotelsTeaserCopyResourcesBlock'
  sys: ResourceSys
}

export type PagesFeaturedHotelsTeaserCopyResourcesHyperlink = ResourceLink & {
  __typename?: 'PagesFeaturedHotelsTeaserCopyResourcesHyperlink'
  sys: ResourceSys
}

export type PagesFeaturedHotelsTeaserCopyResourcesInline = ResourceLink & {
  __typename?: 'PagesFeaturedHotelsTeaserCopyResourcesInline'
  sys: ResourceSys
}

/** Create and manage Header Blocks. [See type definition](https://app.contentful.com/spaces/9cxgygtadmvl/content_types/pagesHeaderBlock) */
export type PagesHeaderBlock = Entry &
  _Node & {
    __typename?: 'PagesHeaderBlock'
    _id: Scalars['ID']
    analyticsModule?: Maybe<SettingsAnalyticsModule>
    borders?: Maybe<Scalars['Boolean']>
    contentfulMetadata: ContentfulMetadata
    ctaText?: Maybe<Scalars['String']>
    ctaTheme?: Maybe<Scalars['String']>
    ctaUrl?: Maybe<Scalars['String']>
    footNote?: Maybe<Scalars['String']>
    icon?: Maybe<Asset>
    linkedFrom?: Maybe<PagesHeaderBlockLinkingCollections>
    secondaryCtaText?: Maybe<Scalars['String']>
    secondaryCtaUrl?: Maybe<Scalars['String']>
    secondaryUrlTarget?: Maybe<Scalars['String']>
    sectionId?: Maybe<Scalars['String']>
    sys: Sys
    teaserCopy?: Maybe<PagesHeaderBlockTeaserCopy>
    textContent?: Maybe<PagesHeaderBlockTextContent>
    title?: Maybe<Scalars['String']>
    urlTarget?: Maybe<Scalars['String']>
  }

/** Create and manage Header Blocks. [See type definition](https://app.contentful.com/spaces/9cxgygtadmvl/content_types/pagesHeaderBlock) */
export type PagesHeaderBlockAnalyticsModuleArgs = {
  locale?: InputMaybe<Scalars['String']>
  preview?: InputMaybe<Scalars['Boolean']>
  where?: InputMaybe<SettingsAnalyticsModuleFilter>
}

/** Create and manage Header Blocks. [See type definition](https://app.contentful.com/spaces/9cxgygtadmvl/content_types/pagesHeaderBlock) */
export type PagesHeaderBlockBordersArgs = {
  locale?: InputMaybe<Scalars['String']>
}

/** Create and manage Header Blocks. [See type definition](https://app.contentful.com/spaces/9cxgygtadmvl/content_types/pagesHeaderBlock) */
export type PagesHeaderBlockCtaTextArgs = {
  locale?: InputMaybe<Scalars['String']>
}

/** Create and manage Header Blocks. [See type definition](https://app.contentful.com/spaces/9cxgygtadmvl/content_types/pagesHeaderBlock) */
export type PagesHeaderBlockCtaThemeArgs = {
  locale?: InputMaybe<Scalars['String']>
}

/** Create and manage Header Blocks. [See type definition](https://app.contentful.com/spaces/9cxgygtadmvl/content_types/pagesHeaderBlock) */
export type PagesHeaderBlockCtaUrlArgs = {
  locale?: InputMaybe<Scalars['String']>
}

/** Create and manage Header Blocks. [See type definition](https://app.contentful.com/spaces/9cxgygtadmvl/content_types/pagesHeaderBlock) */
export type PagesHeaderBlockFootNoteArgs = {
  locale?: InputMaybe<Scalars['String']>
}

/** Create and manage Header Blocks. [See type definition](https://app.contentful.com/spaces/9cxgygtadmvl/content_types/pagesHeaderBlock) */
export type PagesHeaderBlockIconArgs = {
  locale?: InputMaybe<Scalars['String']>
  preview?: InputMaybe<Scalars['Boolean']>
}

/** Create and manage Header Blocks. [See type definition](https://app.contentful.com/spaces/9cxgygtadmvl/content_types/pagesHeaderBlock) */
export type PagesHeaderBlockLinkedFromArgs = {
  allowedLocales?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
}

/** Create and manage Header Blocks. [See type definition](https://app.contentful.com/spaces/9cxgygtadmvl/content_types/pagesHeaderBlock) */
export type PagesHeaderBlockSecondaryCtaTextArgs = {
  locale?: InputMaybe<Scalars['String']>
}

/** Create and manage Header Blocks. [See type definition](https://app.contentful.com/spaces/9cxgygtadmvl/content_types/pagesHeaderBlock) */
export type PagesHeaderBlockSecondaryCtaUrlArgs = {
  locale?: InputMaybe<Scalars['String']>
}

/** Create and manage Header Blocks. [See type definition](https://app.contentful.com/spaces/9cxgygtadmvl/content_types/pagesHeaderBlock) */
export type PagesHeaderBlockSecondaryUrlTargetArgs = {
  locale?: InputMaybe<Scalars['String']>
}

/** Create and manage Header Blocks. [See type definition](https://app.contentful.com/spaces/9cxgygtadmvl/content_types/pagesHeaderBlock) */
export type PagesHeaderBlockSectionIdArgs = {
  locale?: InputMaybe<Scalars['String']>
}

/** Create and manage Header Blocks. [See type definition](https://app.contentful.com/spaces/9cxgygtadmvl/content_types/pagesHeaderBlock) */
export type PagesHeaderBlockTeaserCopyArgs = {
  locale?: InputMaybe<Scalars['String']>
}

/** Create and manage Header Blocks. [See type definition](https://app.contentful.com/spaces/9cxgygtadmvl/content_types/pagesHeaderBlock) */
export type PagesHeaderBlockTextContentArgs = {
  locale?: InputMaybe<Scalars['String']>
}

/** Create and manage Header Blocks. [See type definition](https://app.contentful.com/spaces/9cxgygtadmvl/content_types/pagesHeaderBlock) */
export type PagesHeaderBlockTitleArgs = {
  locale?: InputMaybe<Scalars['String']>
}

/** Create and manage Header Blocks. [See type definition](https://app.contentful.com/spaces/9cxgygtadmvl/content_types/pagesHeaderBlock) */
export type PagesHeaderBlockUrlTargetArgs = {
  locale?: InputMaybe<Scalars['String']>
}

export type PagesHeaderBlockCollection = {
  __typename?: 'PagesHeaderBlockCollection'
  items: Array<Maybe<PagesHeaderBlock>>
  limit: Scalars['Int']
  skip: Scalars['Int']
  total: Scalars['Int']
}

export type PagesHeaderBlockFilter = {
  AND?: InputMaybe<Array<InputMaybe<PagesHeaderBlockFilter>>>
  OR?: InputMaybe<Array<InputMaybe<PagesHeaderBlockFilter>>>
  analyticsModule?: InputMaybe<CfSettingsAnalyticsModuleNestedFilter>
  analyticsModule_exists?: InputMaybe<Scalars['Boolean']>
  borders?: InputMaybe<Scalars['Boolean']>
  borders_exists?: InputMaybe<Scalars['Boolean']>
  borders_not?: InputMaybe<Scalars['Boolean']>
  contentfulMetadata?: InputMaybe<ContentfulMetadataFilter>
  ctaText?: InputMaybe<Scalars['String']>
  ctaText_contains?: InputMaybe<Scalars['String']>
  ctaText_exists?: InputMaybe<Scalars['Boolean']>
  ctaText_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  ctaText_not?: InputMaybe<Scalars['String']>
  ctaText_not_contains?: InputMaybe<Scalars['String']>
  ctaText_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  ctaTheme?: InputMaybe<Scalars['String']>
  ctaTheme_contains?: InputMaybe<Scalars['String']>
  ctaTheme_exists?: InputMaybe<Scalars['Boolean']>
  ctaTheme_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  ctaTheme_not?: InputMaybe<Scalars['String']>
  ctaTheme_not_contains?: InputMaybe<Scalars['String']>
  ctaTheme_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  ctaUrl?: InputMaybe<Scalars['String']>
  ctaUrl_contains?: InputMaybe<Scalars['String']>
  ctaUrl_exists?: InputMaybe<Scalars['Boolean']>
  ctaUrl_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  ctaUrl_not?: InputMaybe<Scalars['String']>
  ctaUrl_not_contains?: InputMaybe<Scalars['String']>
  ctaUrl_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  footNote?: InputMaybe<Scalars['String']>
  footNote_contains?: InputMaybe<Scalars['String']>
  footNote_exists?: InputMaybe<Scalars['Boolean']>
  footNote_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  footNote_not?: InputMaybe<Scalars['String']>
  footNote_not_contains?: InputMaybe<Scalars['String']>
  footNote_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  icon_exists?: InputMaybe<Scalars['Boolean']>
  secondaryCtaText?: InputMaybe<Scalars['String']>
  secondaryCtaText_contains?: InputMaybe<Scalars['String']>
  secondaryCtaText_exists?: InputMaybe<Scalars['Boolean']>
  secondaryCtaText_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  secondaryCtaText_not?: InputMaybe<Scalars['String']>
  secondaryCtaText_not_contains?: InputMaybe<Scalars['String']>
  secondaryCtaText_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  secondaryCtaUrl?: InputMaybe<Scalars['String']>
  secondaryCtaUrl_contains?: InputMaybe<Scalars['String']>
  secondaryCtaUrl_exists?: InputMaybe<Scalars['Boolean']>
  secondaryCtaUrl_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  secondaryCtaUrl_not?: InputMaybe<Scalars['String']>
  secondaryCtaUrl_not_contains?: InputMaybe<Scalars['String']>
  secondaryCtaUrl_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  secondaryUrlTarget?: InputMaybe<Scalars['String']>
  secondaryUrlTarget_contains?: InputMaybe<Scalars['String']>
  secondaryUrlTarget_exists?: InputMaybe<Scalars['Boolean']>
  secondaryUrlTarget_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  secondaryUrlTarget_not?: InputMaybe<Scalars['String']>
  secondaryUrlTarget_not_contains?: InputMaybe<Scalars['String']>
  secondaryUrlTarget_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  sectionId?: InputMaybe<Scalars['String']>
  sectionId_contains?: InputMaybe<Scalars['String']>
  sectionId_exists?: InputMaybe<Scalars['Boolean']>
  sectionId_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  sectionId_not?: InputMaybe<Scalars['String']>
  sectionId_not_contains?: InputMaybe<Scalars['String']>
  sectionId_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  sys?: InputMaybe<SysFilter>
  teaserCopy_contains?: InputMaybe<Scalars['String']>
  teaserCopy_exists?: InputMaybe<Scalars['Boolean']>
  teaserCopy_not_contains?: InputMaybe<Scalars['String']>
  textContent_contains?: InputMaybe<Scalars['String']>
  textContent_exists?: InputMaybe<Scalars['Boolean']>
  textContent_not_contains?: InputMaybe<Scalars['String']>
  title?: InputMaybe<Scalars['String']>
  title_contains?: InputMaybe<Scalars['String']>
  title_exists?: InputMaybe<Scalars['Boolean']>
  title_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  title_not?: InputMaybe<Scalars['String']>
  title_not_contains?: InputMaybe<Scalars['String']>
  title_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  urlTarget?: InputMaybe<Scalars['String']>
  urlTarget_contains?: InputMaybe<Scalars['String']>
  urlTarget_exists?: InputMaybe<Scalars['Boolean']>
  urlTarget_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  urlTarget_not?: InputMaybe<Scalars['String']>
  urlTarget_not_contains?: InputMaybe<Scalars['String']>
  urlTarget_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
}

export type PagesHeaderBlockLinkingCollections = {
  __typename?: 'PagesHeaderBlockLinkingCollections'
  campaignPagesCollection?: Maybe<CampaignPagesCollection>
  entryCollection?: Maybe<EntryCollection>
  templateAboutUsCollection?: Maybe<TemplateAboutUsCollection>
  templateBookingCollection?: Maybe<TemplateBookingCollection>
  templateCareersCollection?: Maybe<TemplateCareersCollection>
  templateContactUsCollection?: Maybe<TemplateContactUsCollection>
  templateDestinationsCollection?: Maybe<TemplateDestinationsCollection>
  templateForaReserveCollection?: Maybe<TemplateForaReserveCollection>
  templateForaXCollection?: Maybe<TemplateForaXCollection>
  templateHomeCollection?: Maybe<TemplateHomeCollection>
  templateJoinUsCollection?: Maybe<TemplateJoinUsCollection>
  templateNewsletterCollection?: Maybe<TemplateNewsletterCollection>
  templatePageCollection?: Maybe<TemplatePageCollection>
  templatePartnerDetailCollection?: Maybe<TemplatePartnerDetailCollection>
  templatePartnersCollection?: Maybe<TemplatePartnersCollection>
  templateReviewsCollection?: Maybe<TemplateReviewsCollection>
  templateWhyBookWithForaCollection?: Maybe<TemplateWhyBookWithForaCollection>
}

export type PagesHeaderBlockLinkingCollectionsCampaignPagesCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>
  locale?: InputMaybe<Scalars['String']>
  order?: InputMaybe<
    Array<
      InputMaybe<PagesHeaderBlockLinkingCollectionsCampaignPagesCollectionOrder>
    >
  >
  preview?: InputMaybe<Scalars['Boolean']>
  skip?: InputMaybe<Scalars['Int']>
}

export type PagesHeaderBlockLinkingCollectionsEntryCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>
  locale?: InputMaybe<Scalars['String']>
  preview?: InputMaybe<Scalars['Boolean']>
  skip?: InputMaybe<Scalars['Int']>
}

export type PagesHeaderBlockLinkingCollectionsTemplateAboutUsCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>
  locale?: InputMaybe<Scalars['String']>
  order?: InputMaybe<
    Array<
      InputMaybe<PagesHeaderBlockLinkingCollectionsTemplateAboutUsCollectionOrder>
    >
  >
  preview?: InputMaybe<Scalars['Boolean']>
  skip?: InputMaybe<Scalars['Int']>
}

export type PagesHeaderBlockLinkingCollectionsTemplateBookingCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>
  locale?: InputMaybe<Scalars['String']>
  order?: InputMaybe<
    Array<
      InputMaybe<PagesHeaderBlockLinkingCollectionsTemplateBookingCollectionOrder>
    >
  >
  preview?: InputMaybe<Scalars['Boolean']>
  skip?: InputMaybe<Scalars['Int']>
}

export type PagesHeaderBlockLinkingCollectionsTemplateCareersCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>
  locale?: InputMaybe<Scalars['String']>
  order?: InputMaybe<
    Array<
      InputMaybe<PagesHeaderBlockLinkingCollectionsTemplateCareersCollectionOrder>
    >
  >
  preview?: InputMaybe<Scalars['Boolean']>
  skip?: InputMaybe<Scalars['Int']>
}

export type PagesHeaderBlockLinkingCollectionsTemplateContactUsCollectionArgs =
  {
    limit?: InputMaybe<Scalars['Int']>
    locale?: InputMaybe<Scalars['String']>
    order?: InputMaybe<
      Array<
        InputMaybe<PagesHeaderBlockLinkingCollectionsTemplateContactUsCollectionOrder>
      >
    >
    preview?: InputMaybe<Scalars['Boolean']>
    skip?: InputMaybe<Scalars['Int']>
  }

export type PagesHeaderBlockLinkingCollectionsTemplateDestinationsCollectionArgs =
  {
    limit?: InputMaybe<Scalars['Int']>
    locale?: InputMaybe<Scalars['String']>
    order?: InputMaybe<
      Array<
        InputMaybe<PagesHeaderBlockLinkingCollectionsTemplateDestinationsCollectionOrder>
      >
    >
    preview?: InputMaybe<Scalars['Boolean']>
    skip?: InputMaybe<Scalars['Int']>
  }

export type PagesHeaderBlockLinkingCollectionsTemplateForaReserveCollectionArgs =
  {
    limit?: InputMaybe<Scalars['Int']>
    locale?: InputMaybe<Scalars['String']>
    order?: InputMaybe<
      Array<
        InputMaybe<PagesHeaderBlockLinkingCollectionsTemplateForaReserveCollectionOrder>
      >
    >
    preview?: InputMaybe<Scalars['Boolean']>
    skip?: InputMaybe<Scalars['Int']>
  }

export type PagesHeaderBlockLinkingCollectionsTemplateForaXCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>
  locale?: InputMaybe<Scalars['String']>
  order?: InputMaybe<
    Array<
      InputMaybe<PagesHeaderBlockLinkingCollectionsTemplateForaXCollectionOrder>
    >
  >
  preview?: InputMaybe<Scalars['Boolean']>
  skip?: InputMaybe<Scalars['Int']>
}

export type PagesHeaderBlockLinkingCollectionsTemplateHomeCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>
  locale?: InputMaybe<Scalars['String']>
  order?: InputMaybe<
    Array<
      InputMaybe<PagesHeaderBlockLinkingCollectionsTemplateHomeCollectionOrder>
    >
  >
  preview?: InputMaybe<Scalars['Boolean']>
  skip?: InputMaybe<Scalars['Int']>
}

export type PagesHeaderBlockLinkingCollectionsTemplateJoinUsCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>
  locale?: InputMaybe<Scalars['String']>
  order?: InputMaybe<
    Array<
      InputMaybe<PagesHeaderBlockLinkingCollectionsTemplateJoinUsCollectionOrder>
    >
  >
  preview?: InputMaybe<Scalars['Boolean']>
  skip?: InputMaybe<Scalars['Int']>
}

export type PagesHeaderBlockLinkingCollectionsTemplateNewsletterCollectionArgs =
  {
    limit?: InputMaybe<Scalars['Int']>
    locale?: InputMaybe<Scalars['String']>
    order?: InputMaybe<
      Array<
        InputMaybe<PagesHeaderBlockLinkingCollectionsTemplateNewsletterCollectionOrder>
      >
    >
    preview?: InputMaybe<Scalars['Boolean']>
    skip?: InputMaybe<Scalars['Int']>
  }

export type PagesHeaderBlockLinkingCollectionsTemplatePageCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>
  locale?: InputMaybe<Scalars['String']>
  order?: InputMaybe<
    Array<
      InputMaybe<PagesHeaderBlockLinkingCollectionsTemplatePageCollectionOrder>
    >
  >
  preview?: InputMaybe<Scalars['Boolean']>
  skip?: InputMaybe<Scalars['Int']>
}

export type PagesHeaderBlockLinkingCollectionsTemplatePartnerDetailCollectionArgs =
  {
    limit?: InputMaybe<Scalars['Int']>
    locale?: InputMaybe<Scalars['String']>
    order?: InputMaybe<
      Array<
        InputMaybe<PagesHeaderBlockLinkingCollectionsTemplatePartnerDetailCollectionOrder>
      >
    >
    preview?: InputMaybe<Scalars['Boolean']>
    skip?: InputMaybe<Scalars['Int']>
  }

export type PagesHeaderBlockLinkingCollectionsTemplatePartnersCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>
  locale?: InputMaybe<Scalars['String']>
  order?: InputMaybe<
    Array<
      InputMaybe<PagesHeaderBlockLinkingCollectionsTemplatePartnersCollectionOrder>
    >
  >
  preview?: InputMaybe<Scalars['Boolean']>
  skip?: InputMaybe<Scalars['Int']>
}

export type PagesHeaderBlockLinkingCollectionsTemplateReviewsCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>
  locale?: InputMaybe<Scalars['String']>
  order?: InputMaybe<
    Array<
      InputMaybe<PagesHeaderBlockLinkingCollectionsTemplateReviewsCollectionOrder>
    >
  >
  preview?: InputMaybe<Scalars['Boolean']>
  skip?: InputMaybe<Scalars['Int']>
}

export type PagesHeaderBlockLinkingCollectionsTemplateWhyBookWithForaCollectionArgs =
  {
    limit?: InputMaybe<Scalars['Int']>
    locale?: InputMaybe<Scalars['String']>
    order?: InputMaybe<
      Array<
        InputMaybe<PagesHeaderBlockLinkingCollectionsTemplateWhyBookWithForaCollectionOrder>
      >
    >
    preview?: InputMaybe<Scalars['Boolean']>
    skip?: InputMaybe<Scalars['Int']>
  }

export enum PagesHeaderBlockLinkingCollectionsCampaignPagesCollectionOrder {
  CollapseFooterAsc = 'collapseFooter_ASC',
  CollapseFooterDesc = 'collapseFooter_DESC',
  CollapseHeaderAsc = 'collapseHeader_ASC',
  CollapseHeaderDesc = 'collapseHeader_DESC',
  HideFooterAsc = 'hideFooter_ASC',
  HideFooterDesc = 'hideFooter_DESC',
  HideGlobalBannerAsc = 'hideGlobalBanner_ASC',
  HideGlobalBannerDesc = 'hideGlobalBanner_DESC',
  JotformIdAsc = 'jotformId_ASC',
  JotformIdDesc = 'jotformId_DESC',
  NoindexNofollowAsc = 'noindexNofollow_ASC',
  NoindexNofollowDesc = 'noindexNofollow_DESC',
  ShowHeaderAsc = 'showHeader_ASC',
  ShowHeaderDesc = 'showHeader_DESC',
  SlugAsc = 'slug_ASC',
  SlugDesc = 'slug_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC',
}

export enum PagesHeaderBlockLinkingCollectionsTemplateAboutUsCollectionOrder {
  CollapseHeaderAsc = 'collapseHeader_ASC',
  CollapseHeaderDesc = 'collapseHeader_DESC',
  HeaderBlockTitleAsc = 'headerBlockTitle_ASC',
  HeaderBlockTitleDesc = 'headerBlockTitle_DESC',
  ShowHeaderAsc = 'showHeader_ASC',
  ShowHeaderDesc = 'showHeader_DESC',
  SubtitleAsc = 'subtitle_ASC',
  SubtitleDesc = 'subtitle_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC',
}

export enum PagesHeaderBlockLinkingCollectionsTemplateBookingCollectionOrder {
  CollapseHeaderAsc = 'collapseHeader_ASC',
  CollapseHeaderDesc = 'collapseHeader_DESC',
  ShowHeaderAsc = 'showHeader_ASC',
  ShowHeaderDesc = 'showHeader_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC',
}

export enum PagesHeaderBlockLinkingCollectionsTemplateCareersCollectionOrder {
  CollapseHeaderAsc = 'collapseHeader_ASC',
  CollapseHeaderDesc = 'collapseHeader_DESC',
  ShowHeaderAsc = 'showHeader_ASC',
  ShowHeaderDesc = 'showHeader_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC',
}

export enum PagesHeaderBlockLinkingCollectionsTemplateContactUsCollectionOrder {
  CollapseHeaderAsc = 'collapseHeader_ASC',
  CollapseHeaderDesc = 'collapseHeader_DESC',
  ShowHeaderAsc = 'showHeader_ASC',
  ShowHeaderDesc = 'showHeader_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC',
}

export enum PagesHeaderBlockLinkingCollectionsTemplateDestinationsCollectionOrder {
  CollapseHeaderAsc = 'collapseHeader_ASC',
  CollapseHeaderDesc = 'collapseHeader_DESC',
  CollapsibleHeadingAsc = 'collapsibleHeading_ASC',
  CollapsibleHeadingDesc = 'collapsibleHeading_DESC',
  ShowHeaderAsc = 'showHeader_ASC',
  ShowHeaderDesc = 'showHeader_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC',
}

export enum PagesHeaderBlockLinkingCollectionsTemplateForaReserveCollectionOrder {
  CollapseHeaderAsc = 'collapseHeader_ASC',
  CollapseHeaderDesc = 'collapseHeader_DESC',
  ShowHeaderAsc = 'showHeader_ASC',
  ShowHeaderDesc = 'showHeader_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC',
}

export enum PagesHeaderBlockLinkingCollectionsTemplateForaXCollectionOrder {
  AdvisorGridHeaderAsc = 'advisorGridHeader_ASC',
  AdvisorGridHeaderDesc = 'advisorGridHeader_DESC',
  AdvisorGridSubCopyAsc = 'advisorGridSubCopy_ASC',
  AdvisorGridSubCopyDesc = 'advisorGridSubCopy_DESC',
  CollapseHeaderAsc = 'collapseHeader_ASC',
  CollapseHeaderDesc = 'collapseHeader_DESC',
  ShowHeaderAsc = 'showHeader_ASC',
  ShowHeaderDesc = 'showHeader_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC',
}

export enum PagesHeaderBlockLinkingCollectionsTemplateHomeCollectionOrder {
  CollapseHeaderAsc = 'collapseHeader_ASC',
  CollapseHeaderDesc = 'collapseHeader_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC',
}

export enum PagesHeaderBlockLinkingCollectionsTemplateJoinUsCollectionOrder {
  CollapseHeaderAsc = 'collapseHeader_ASC',
  CollapseHeaderDesc = 'collapseHeader_DESC',
  HideGlobalBannerAsc = 'hideGlobalBanner_ASC',
  HideGlobalBannerDesc = 'hideGlobalBanner_DESC',
  ShowHeaderAsc = 'showHeader_ASC',
  ShowHeaderDesc = 'showHeader_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC',
}

export enum PagesHeaderBlockLinkingCollectionsTemplateNewsletterCollectionOrder {
  CollapseHeaderAsc = 'collapseHeader_ASC',
  CollapseHeaderDesc = 'collapseHeader_DESC',
  ShowHeaderAsc = 'showHeader_ASC',
  ShowHeaderDesc = 'showHeader_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC',
}

export enum PagesHeaderBlockLinkingCollectionsTemplatePageCollectionOrder {
  DirectoryAsc = 'directory_ASC',
  DirectoryDesc = 'directory_DESC',
  InternalLabelAsc = 'internalLabel_ASC',
  InternalLabelDesc = 'internalLabel_DESC',
  MetaTitleAsc = 'metaTitle_ASC',
  MetaTitleDesc = 'metaTitle_DESC',
  NoindexNofollowAsc = 'noindexNofollow_ASC',
  NoindexNofollowDesc = 'noindexNofollow_DESC',
  SlugAsc = 'slug_ASC',
  SlugDesc = 'slug_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
}

export enum PagesHeaderBlockLinkingCollectionsTemplatePartnerDetailCollectionOrder {
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
}

export enum PagesHeaderBlockLinkingCollectionsTemplatePartnersCollectionOrder {
  CollapseHeaderAsc = 'collapseHeader_ASC',
  CollapseHeaderDesc = 'collapseHeader_DESC',
  ShowHeaderAsc = 'showHeader_ASC',
  ShowHeaderDesc = 'showHeader_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC',
}

export enum PagesHeaderBlockLinkingCollectionsTemplateReviewsCollectionOrder {
  CollapseHeaderAsc = 'collapseHeader_ASC',
  CollapseHeaderDesc = 'collapseHeader_DESC',
  ShowHeaderAsc = 'showHeader_ASC',
  ShowHeaderDesc = 'showHeader_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC',
}

export enum PagesHeaderBlockLinkingCollectionsTemplateWhyBookWithForaCollectionOrder {
  CollapseHeaderAsc = 'collapseHeader_ASC',
  CollapseHeaderDesc = 'collapseHeader_DESC',
  ShowHeaderAsc = 'showHeader_ASC',
  ShowHeaderDesc = 'showHeader_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC',
}

export enum PagesHeaderBlockOrder {
  BordersAsc = 'borders_ASC',
  BordersDesc = 'borders_DESC',
  CtaTextAsc = 'ctaText_ASC',
  CtaTextDesc = 'ctaText_DESC',
  CtaThemeAsc = 'ctaTheme_ASC',
  CtaThemeDesc = 'ctaTheme_DESC',
  CtaUrlAsc = 'ctaUrl_ASC',
  CtaUrlDesc = 'ctaUrl_DESC',
  FootNoteAsc = 'footNote_ASC',
  FootNoteDesc = 'footNote_DESC',
  SecondaryCtaTextAsc = 'secondaryCtaText_ASC',
  SecondaryCtaTextDesc = 'secondaryCtaText_DESC',
  SecondaryCtaUrlAsc = 'secondaryCtaUrl_ASC',
  SecondaryCtaUrlDesc = 'secondaryCtaUrl_DESC',
  SecondaryUrlTargetAsc = 'secondaryUrlTarget_ASC',
  SecondaryUrlTargetDesc = 'secondaryUrlTarget_DESC',
  SectionIdAsc = 'sectionId_ASC',
  SectionIdDesc = 'sectionId_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC',
  UrlTargetAsc = 'urlTarget_ASC',
  UrlTargetDesc = 'urlTarget_DESC',
}

export type PagesHeaderBlockTeaserCopy = {
  __typename?: 'PagesHeaderBlockTeaserCopy'
  json: Scalars['JSON']
  links: PagesHeaderBlockTeaserCopyLinks
}

export type PagesHeaderBlockTeaserCopyAssets = {
  __typename?: 'PagesHeaderBlockTeaserCopyAssets'
  block: Array<Maybe<Asset>>
  hyperlink: Array<Maybe<Asset>>
}

export type PagesHeaderBlockTeaserCopyEntries = {
  __typename?: 'PagesHeaderBlockTeaserCopyEntries'
  block: Array<Maybe<Entry>>
  hyperlink: Array<Maybe<Entry>>
  inline: Array<Maybe<Entry>>
}

export type PagesHeaderBlockTeaserCopyLinks = {
  __typename?: 'PagesHeaderBlockTeaserCopyLinks'
  assets: PagesHeaderBlockTeaserCopyAssets
  entries: PagesHeaderBlockTeaserCopyEntries
  resources: PagesHeaderBlockTeaserCopyResources
}

export type PagesHeaderBlockTeaserCopyResources = {
  __typename?: 'PagesHeaderBlockTeaserCopyResources'
  block: Array<PagesHeaderBlockTeaserCopyResourcesBlock>
  hyperlink: Array<PagesHeaderBlockTeaserCopyResourcesHyperlink>
  inline: Array<PagesHeaderBlockTeaserCopyResourcesInline>
}

export type PagesHeaderBlockTeaserCopyResourcesBlock = ResourceLink & {
  __typename?: 'PagesHeaderBlockTeaserCopyResourcesBlock'
  sys: ResourceSys
}

export type PagesHeaderBlockTeaserCopyResourcesHyperlink = ResourceLink & {
  __typename?: 'PagesHeaderBlockTeaserCopyResourcesHyperlink'
  sys: ResourceSys
}

export type PagesHeaderBlockTeaserCopyResourcesInline = ResourceLink & {
  __typename?: 'PagesHeaderBlockTeaserCopyResourcesInline'
  sys: ResourceSys
}

export type PagesHeaderBlockTextContent = {
  __typename?: 'PagesHeaderBlockTextContent'
  json: Scalars['JSON']
  links: PagesHeaderBlockTextContentLinks
}

export type PagesHeaderBlockTextContentAssets = {
  __typename?: 'PagesHeaderBlockTextContentAssets'
  block: Array<Maybe<Asset>>
  hyperlink: Array<Maybe<Asset>>
}

export type PagesHeaderBlockTextContentEntries = {
  __typename?: 'PagesHeaderBlockTextContentEntries'
  block: Array<Maybe<Entry>>
  hyperlink: Array<Maybe<Entry>>
  inline: Array<Maybe<Entry>>
}

export type PagesHeaderBlockTextContentLinks = {
  __typename?: 'PagesHeaderBlockTextContentLinks'
  assets: PagesHeaderBlockTextContentAssets
  entries: PagesHeaderBlockTextContentEntries
  resources: PagesHeaderBlockTextContentResources
}

export type PagesHeaderBlockTextContentResources = {
  __typename?: 'PagesHeaderBlockTextContentResources'
  block: Array<PagesHeaderBlockTextContentResourcesBlock>
  hyperlink: Array<PagesHeaderBlockTextContentResourcesHyperlink>
  inline: Array<PagesHeaderBlockTextContentResourcesInline>
}

export type PagesHeaderBlockTextContentResourcesBlock = ResourceLink & {
  __typename?: 'PagesHeaderBlockTextContentResourcesBlock'
  sys: ResourceSys
}

export type PagesHeaderBlockTextContentResourcesHyperlink = ResourceLink & {
  __typename?: 'PagesHeaderBlockTextContentResourcesHyperlink'
  sys: ResourceSys
}

export type PagesHeaderBlockTextContentResourcesInline = ResourceLink & {
  __typename?: 'PagesHeaderBlockTextContentResourcesInline'
  sys: ResourceSys
}

/** Create and manage Hero Blocks. [See type definition](https://app.contentful.com/spaces/9cxgygtadmvl/content_types/pagesHeroBlock) */
export type PagesHeroBlock = Entry &
  _Node & {
    __typename?: 'PagesHeroBlock'
    _id: Scalars['ID']
    analyticsModule?: Maybe<SettingsAnalyticsModule>
    contentfulMetadata: ContentfulMetadata
    image?: Maybe<Asset>
    linkedFrom?: Maybe<PagesHeroBlockLinkingCollections>
    primaryCtaLinkText?: Maybe<Scalars['String']>
    primaryCtaLinkUrl?: Maybe<Scalars['String']>
    primaryUrlTarget?: Maybe<Scalars['String']>
    secondaryCtaLinkText?: Maybe<Scalars['String']>
    secondaryCtaLinkUrl?: Maybe<Scalars['String']>
    secondaryUrlTarget?: Maybe<Scalars['String']>
    showFormAsPrimaryCta?: Maybe<Scalars['String']>
    showSignUpFormAsPrimaryCta?: Maybe<Scalars['Boolean']>
    subFormCopy?: Maybe<PagesHeroBlockSubFormCopy>
    subFormHeader?: Maybe<Scalars['String']>
    sys: Sys
    teaserCopy?: Maybe<PagesHeroBlockTeaserCopy>
    title?: Maybe<Scalars['String']>
    titleStyle?: Maybe<Scalars['String']>
  }

/** Create and manage Hero Blocks. [See type definition](https://app.contentful.com/spaces/9cxgygtadmvl/content_types/pagesHeroBlock) */
export type PagesHeroBlockAnalyticsModuleArgs = {
  locale?: InputMaybe<Scalars['String']>
  preview?: InputMaybe<Scalars['Boolean']>
  where?: InputMaybe<SettingsAnalyticsModuleFilter>
}

/** Create and manage Hero Blocks. [See type definition](https://app.contentful.com/spaces/9cxgygtadmvl/content_types/pagesHeroBlock) */
export type PagesHeroBlockImageArgs = {
  locale?: InputMaybe<Scalars['String']>
  preview?: InputMaybe<Scalars['Boolean']>
}

/** Create and manage Hero Blocks. [See type definition](https://app.contentful.com/spaces/9cxgygtadmvl/content_types/pagesHeroBlock) */
export type PagesHeroBlockLinkedFromArgs = {
  allowedLocales?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
}

/** Create and manage Hero Blocks. [See type definition](https://app.contentful.com/spaces/9cxgygtadmvl/content_types/pagesHeroBlock) */
export type PagesHeroBlockPrimaryCtaLinkTextArgs = {
  locale?: InputMaybe<Scalars['String']>
}

/** Create and manage Hero Blocks. [See type definition](https://app.contentful.com/spaces/9cxgygtadmvl/content_types/pagesHeroBlock) */
export type PagesHeroBlockPrimaryCtaLinkUrlArgs = {
  locale?: InputMaybe<Scalars['String']>
}

/** Create and manage Hero Blocks. [See type definition](https://app.contentful.com/spaces/9cxgygtadmvl/content_types/pagesHeroBlock) */
export type PagesHeroBlockPrimaryUrlTargetArgs = {
  locale?: InputMaybe<Scalars['String']>
}

/** Create and manage Hero Blocks. [See type definition](https://app.contentful.com/spaces/9cxgygtadmvl/content_types/pagesHeroBlock) */
export type PagesHeroBlockSecondaryCtaLinkTextArgs = {
  locale?: InputMaybe<Scalars['String']>
}

/** Create and manage Hero Blocks. [See type definition](https://app.contentful.com/spaces/9cxgygtadmvl/content_types/pagesHeroBlock) */
export type PagesHeroBlockSecondaryCtaLinkUrlArgs = {
  locale?: InputMaybe<Scalars['String']>
}

/** Create and manage Hero Blocks. [See type definition](https://app.contentful.com/spaces/9cxgygtadmvl/content_types/pagesHeroBlock) */
export type PagesHeroBlockSecondaryUrlTargetArgs = {
  locale?: InputMaybe<Scalars['String']>
}

/** Create and manage Hero Blocks. [See type definition](https://app.contentful.com/spaces/9cxgygtadmvl/content_types/pagesHeroBlock) */
export type PagesHeroBlockShowFormAsPrimaryCtaArgs = {
  locale?: InputMaybe<Scalars['String']>
}

/** Create and manage Hero Blocks. [See type definition](https://app.contentful.com/spaces/9cxgygtadmvl/content_types/pagesHeroBlock) */
export type PagesHeroBlockShowSignUpFormAsPrimaryCtaArgs = {
  locale?: InputMaybe<Scalars['String']>
}

/** Create and manage Hero Blocks. [See type definition](https://app.contentful.com/spaces/9cxgygtadmvl/content_types/pagesHeroBlock) */
export type PagesHeroBlockSubFormCopyArgs = {
  locale?: InputMaybe<Scalars['String']>
}

/** Create and manage Hero Blocks. [See type definition](https://app.contentful.com/spaces/9cxgygtadmvl/content_types/pagesHeroBlock) */
export type PagesHeroBlockSubFormHeaderArgs = {
  locale?: InputMaybe<Scalars['String']>
}

/** Create and manage Hero Blocks. [See type definition](https://app.contentful.com/spaces/9cxgygtadmvl/content_types/pagesHeroBlock) */
export type PagesHeroBlockTeaserCopyArgs = {
  locale?: InputMaybe<Scalars['String']>
}

/** Create and manage Hero Blocks. [See type definition](https://app.contentful.com/spaces/9cxgygtadmvl/content_types/pagesHeroBlock) */
export type PagesHeroBlockTitleArgs = {
  locale?: InputMaybe<Scalars['String']>
}

/** Create and manage Hero Blocks. [See type definition](https://app.contentful.com/spaces/9cxgygtadmvl/content_types/pagesHeroBlock) */
export type PagesHeroBlockTitleStyleArgs = {
  locale?: InputMaybe<Scalars['String']>
}

export type PagesHeroBlockCollection = {
  __typename?: 'PagesHeroBlockCollection'
  items: Array<Maybe<PagesHeroBlock>>
  limit: Scalars['Int']
  skip: Scalars['Int']
  total: Scalars['Int']
}

export type PagesHeroBlockFilter = {
  AND?: InputMaybe<Array<InputMaybe<PagesHeroBlockFilter>>>
  OR?: InputMaybe<Array<InputMaybe<PagesHeroBlockFilter>>>
  analyticsModule?: InputMaybe<CfSettingsAnalyticsModuleNestedFilter>
  analyticsModule_exists?: InputMaybe<Scalars['Boolean']>
  contentfulMetadata?: InputMaybe<ContentfulMetadataFilter>
  image_exists?: InputMaybe<Scalars['Boolean']>
  primaryCtaLinkText?: InputMaybe<Scalars['String']>
  primaryCtaLinkText_contains?: InputMaybe<Scalars['String']>
  primaryCtaLinkText_exists?: InputMaybe<Scalars['Boolean']>
  primaryCtaLinkText_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  primaryCtaLinkText_not?: InputMaybe<Scalars['String']>
  primaryCtaLinkText_not_contains?: InputMaybe<Scalars['String']>
  primaryCtaLinkText_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  primaryCtaLinkUrl?: InputMaybe<Scalars['String']>
  primaryCtaLinkUrl_contains?: InputMaybe<Scalars['String']>
  primaryCtaLinkUrl_exists?: InputMaybe<Scalars['Boolean']>
  primaryCtaLinkUrl_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  primaryCtaLinkUrl_not?: InputMaybe<Scalars['String']>
  primaryCtaLinkUrl_not_contains?: InputMaybe<Scalars['String']>
  primaryCtaLinkUrl_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  primaryUrlTarget?: InputMaybe<Scalars['String']>
  primaryUrlTarget_contains?: InputMaybe<Scalars['String']>
  primaryUrlTarget_exists?: InputMaybe<Scalars['Boolean']>
  primaryUrlTarget_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  primaryUrlTarget_not?: InputMaybe<Scalars['String']>
  primaryUrlTarget_not_contains?: InputMaybe<Scalars['String']>
  primaryUrlTarget_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  secondaryCtaLinkText?: InputMaybe<Scalars['String']>
  secondaryCtaLinkText_contains?: InputMaybe<Scalars['String']>
  secondaryCtaLinkText_exists?: InputMaybe<Scalars['Boolean']>
  secondaryCtaLinkText_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  secondaryCtaLinkText_not?: InputMaybe<Scalars['String']>
  secondaryCtaLinkText_not_contains?: InputMaybe<Scalars['String']>
  secondaryCtaLinkText_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  secondaryCtaLinkUrl?: InputMaybe<Scalars['String']>
  secondaryCtaLinkUrl_contains?: InputMaybe<Scalars['String']>
  secondaryCtaLinkUrl_exists?: InputMaybe<Scalars['Boolean']>
  secondaryCtaLinkUrl_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  secondaryCtaLinkUrl_not?: InputMaybe<Scalars['String']>
  secondaryCtaLinkUrl_not_contains?: InputMaybe<Scalars['String']>
  secondaryCtaLinkUrl_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  secondaryUrlTarget?: InputMaybe<Scalars['String']>
  secondaryUrlTarget_contains?: InputMaybe<Scalars['String']>
  secondaryUrlTarget_exists?: InputMaybe<Scalars['Boolean']>
  secondaryUrlTarget_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  secondaryUrlTarget_not?: InputMaybe<Scalars['String']>
  secondaryUrlTarget_not_contains?: InputMaybe<Scalars['String']>
  secondaryUrlTarget_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  showFormAsPrimaryCta?: InputMaybe<Scalars['String']>
  showFormAsPrimaryCta_contains?: InputMaybe<Scalars['String']>
  showFormAsPrimaryCta_exists?: InputMaybe<Scalars['Boolean']>
  showFormAsPrimaryCta_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  showFormAsPrimaryCta_not?: InputMaybe<Scalars['String']>
  showFormAsPrimaryCta_not_contains?: InputMaybe<Scalars['String']>
  showFormAsPrimaryCta_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  showSignUpFormAsPrimaryCta?: InputMaybe<Scalars['Boolean']>
  showSignUpFormAsPrimaryCta_exists?: InputMaybe<Scalars['Boolean']>
  showSignUpFormAsPrimaryCta_not?: InputMaybe<Scalars['Boolean']>
  subFormCopy_contains?: InputMaybe<Scalars['String']>
  subFormCopy_exists?: InputMaybe<Scalars['Boolean']>
  subFormCopy_not_contains?: InputMaybe<Scalars['String']>
  subFormHeader?: InputMaybe<Scalars['String']>
  subFormHeader_contains?: InputMaybe<Scalars['String']>
  subFormHeader_exists?: InputMaybe<Scalars['Boolean']>
  subFormHeader_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  subFormHeader_not?: InputMaybe<Scalars['String']>
  subFormHeader_not_contains?: InputMaybe<Scalars['String']>
  subFormHeader_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  sys?: InputMaybe<SysFilter>
  teaserCopy_contains?: InputMaybe<Scalars['String']>
  teaserCopy_exists?: InputMaybe<Scalars['Boolean']>
  teaserCopy_not_contains?: InputMaybe<Scalars['String']>
  title?: InputMaybe<Scalars['String']>
  titleStyle?: InputMaybe<Scalars['String']>
  titleStyle_contains?: InputMaybe<Scalars['String']>
  titleStyle_exists?: InputMaybe<Scalars['Boolean']>
  titleStyle_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  titleStyle_not?: InputMaybe<Scalars['String']>
  titleStyle_not_contains?: InputMaybe<Scalars['String']>
  titleStyle_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  title_contains?: InputMaybe<Scalars['String']>
  title_exists?: InputMaybe<Scalars['Boolean']>
  title_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  title_not?: InputMaybe<Scalars['String']>
  title_not_contains?: InputMaybe<Scalars['String']>
  title_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
}

export type PagesHeroBlockLinkingCollections = {
  __typename?: 'PagesHeroBlockLinkingCollections'
  campaignPagesCollection?: Maybe<CampaignPagesCollection>
  entryCollection?: Maybe<EntryCollection>
  templateAboutUsCollection?: Maybe<TemplateAboutUsCollection>
  templateBookingCollection?: Maybe<TemplateBookingCollection>
  templateCareersCollection?: Maybe<TemplateCareersCollection>
  templateContactUsCollection?: Maybe<TemplateContactUsCollection>
  templateDestinationsCollection?: Maybe<TemplateDestinationsCollection>
  templateForaReserveCollection?: Maybe<TemplateForaReserveCollection>
  templateForaXCollection?: Maybe<TemplateForaXCollection>
  templateHomeCollection?: Maybe<TemplateHomeCollection>
  templateJoinUsCollection?: Maybe<TemplateJoinUsCollection>
  templateNewsletterCollection?: Maybe<TemplateNewsletterCollection>
  templatePageCollection?: Maybe<TemplatePageCollection>
  templatePartnerDetailCollection?: Maybe<TemplatePartnerDetailCollection>
  templatePartnersCollection?: Maybe<TemplatePartnersCollection>
  templateReviewsCollection?: Maybe<TemplateReviewsCollection>
  templateWhyBookWithForaCollection?: Maybe<TemplateWhyBookWithForaCollection>
}

export type PagesHeroBlockLinkingCollectionsCampaignPagesCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>
  locale?: InputMaybe<Scalars['String']>
  order?: InputMaybe<
    Array<
      InputMaybe<PagesHeroBlockLinkingCollectionsCampaignPagesCollectionOrder>
    >
  >
  preview?: InputMaybe<Scalars['Boolean']>
  skip?: InputMaybe<Scalars['Int']>
}

export type PagesHeroBlockLinkingCollectionsEntryCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>
  locale?: InputMaybe<Scalars['String']>
  preview?: InputMaybe<Scalars['Boolean']>
  skip?: InputMaybe<Scalars['Int']>
}

export type PagesHeroBlockLinkingCollectionsTemplateAboutUsCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>
  locale?: InputMaybe<Scalars['String']>
  order?: InputMaybe<
    Array<
      InputMaybe<PagesHeroBlockLinkingCollectionsTemplateAboutUsCollectionOrder>
    >
  >
  preview?: InputMaybe<Scalars['Boolean']>
  skip?: InputMaybe<Scalars['Int']>
}

export type PagesHeroBlockLinkingCollectionsTemplateBookingCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>
  locale?: InputMaybe<Scalars['String']>
  order?: InputMaybe<
    Array<
      InputMaybe<PagesHeroBlockLinkingCollectionsTemplateBookingCollectionOrder>
    >
  >
  preview?: InputMaybe<Scalars['Boolean']>
  skip?: InputMaybe<Scalars['Int']>
}

export type PagesHeroBlockLinkingCollectionsTemplateCareersCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>
  locale?: InputMaybe<Scalars['String']>
  order?: InputMaybe<
    Array<
      InputMaybe<PagesHeroBlockLinkingCollectionsTemplateCareersCollectionOrder>
    >
  >
  preview?: InputMaybe<Scalars['Boolean']>
  skip?: InputMaybe<Scalars['Int']>
}

export type PagesHeroBlockLinkingCollectionsTemplateContactUsCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>
  locale?: InputMaybe<Scalars['String']>
  order?: InputMaybe<
    Array<
      InputMaybe<PagesHeroBlockLinkingCollectionsTemplateContactUsCollectionOrder>
    >
  >
  preview?: InputMaybe<Scalars['Boolean']>
  skip?: InputMaybe<Scalars['Int']>
}

export type PagesHeroBlockLinkingCollectionsTemplateDestinationsCollectionArgs =
  {
    limit?: InputMaybe<Scalars['Int']>
    locale?: InputMaybe<Scalars['String']>
    order?: InputMaybe<
      Array<
        InputMaybe<PagesHeroBlockLinkingCollectionsTemplateDestinationsCollectionOrder>
      >
    >
    preview?: InputMaybe<Scalars['Boolean']>
    skip?: InputMaybe<Scalars['Int']>
  }

export type PagesHeroBlockLinkingCollectionsTemplateForaReserveCollectionArgs =
  {
    limit?: InputMaybe<Scalars['Int']>
    locale?: InputMaybe<Scalars['String']>
    order?: InputMaybe<
      Array<
        InputMaybe<PagesHeroBlockLinkingCollectionsTemplateForaReserveCollectionOrder>
      >
    >
    preview?: InputMaybe<Scalars['Boolean']>
    skip?: InputMaybe<Scalars['Int']>
  }

export type PagesHeroBlockLinkingCollectionsTemplateForaXCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>
  locale?: InputMaybe<Scalars['String']>
  order?: InputMaybe<
    Array<
      InputMaybe<PagesHeroBlockLinkingCollectionsTemplateForaXCollectionOrder>
    >
  >
  preview?: InputMaybe<Scalars['Boolean']>
  skip?: InputMaybe<Scalars['Int']>
}

export type PagesHeroBlockLinkingCollectionsTemplateHomeCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>
  locale?: InputMaybe<Scalars['String']>
  order?: InputMaybe<
    Array<
      InputMaybe<PagesHeroBlockLinkingCollectionsTemplateHomeCollectionOrder>
    >
  >
  preview?: InputMaybe<Scalars['Boolean']>
  skip?: InputMaybe<Scalars['Int']>
}

export type PagesHeroBlockLinkingCollectionsTemplateJoinUsCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>
  locale?: InputMaybe<Scalars['String']>
  order?: InputMaybe<
    Array<
      InputMaybe<PagesHeroBlockLinkingCollectionsTemplateJoinUsCollectionOrder>
    >
  >
  preview?: InputMaybe<Scalars['Boolean']>
  skip?: InputMaybe<Scalars['Int']>
}

export type PagesHeroBlockLinkingCollectionsTemplateNewsletterCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>
  locale?: InputMaybe<Scalars['String']>
  order?: InputMaybe<
    Array<
      InputMaybe<PagesHeroBlockLinkingCollectionsTemplateNewsletterCollectionOrder>
    >
  >
  preview?: InputMaybe<Scalars['Boolean']>
  skip?: InputMaybe<Scalars['Int']>
}

export type PagesHeroBlockLinkingCollectionsTemplatePageCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>
  locale?: InputMaybe<Scalars['String']>
  order?: InputMaybe<
    Array<
      InputMaybe<PagesHeroBlockLinkingCollectionsTemplatePageCollectionOrder>
    >
  >
  preview?: InputMaybe<Scalars['Boolean']>
  skip?: InputMaybe<Scalars['Int']>
}

export type PagesHeroBlockLinkingCollectionsTemplatePartnerDetailCollectionArgs =
  {
    limit?: InputMaybe<Scalars['Int']>
    locale?: InputMaybe<Scalars['String']>
    order?: InputMaybe<
      Array<
        InputMaybe<PagesHeroBlockLinkingCollectionsTemplatePartnerDetailCollectionOrder>
      >
    >
    preview?: InputMaybe<Scalars['Boolean']>
    skip?: InputMaybe<Scalars['Int']>
  }

export type PagesHeroBlockLinkingCollectionsTemplatePartnersCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>
  locale?: InputMaybe<Scalars['String']>
  order?: InputMaybe<
    Array<
      InputMaybe<PagesHeroBlockLinkingCollectionsTemplatePartnersCollectionOrder>
    >
  >
  preview?: InputMaybe<Scalars['Boolean']>
  skip?: InputMaybe<Scalars['Int']>
}

export type PagesHeroBlockLinkingCollectionsTemplateReviewsCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>
  locale?: InputMaybe<Scalars['String']>
  order?: InputMaybe<
    Array<
      InputMaybe<PagesHeroBlockLinkingCollectionsTemplateReviewsCollectionOrder>
    >
  >
  preview?: InputMaybe<Scalars['Boolean']>
  skip?: InputMaybe<Scalars['Int']>
}

export type PagesHeroBlockLinkingCollectionsTemplateWhyBookWithForaCollectionArgs =
  {
    limit?: InputMaybe<Scalars['Int']>
    locale?: InputMaybe<Scalars['String']>
    order?: InputMaybe<
      Array<
        InputMaybe<PagesHeroBlockLinkingCollectionsTemplateWhyBookWithForaCollectionOrder>
      >
    >
    preview?: InputMaybe<Scalars['Boolean']>
    skip?: InputMaybe<Scalars['Int']>
  }

export enum PagesHeroBlockLinkingCollectionsCampaignPagesCollectionOrder {
  CollapseFooterAsc = 'collapseFooter_ASC',
  CollapseFooterDesc = 'collapseFooter_DESC',
  CollapseHeaderAsc = 'collapseHeader_ASC',
  CollapseHeaderDesc = 'collapseHeader_DESC',
  HideFooterAsc = 'hideFooter_ASC',
  HideFooterDesc = 'hideFooter_DESC',
  HideGlobalBannerAsc = 'hideGlobalBanner_ASC',
  HideGlobalBannerDesc = 'hideGlobalBanner_DESC',
  JotformIdAsc = 'jotformId_ASC',
  JotformIdDesc = 'jotformId_DESC',
  NoindexNofollowAsc = 'noindexNofollow_ASC',
  NoindexNofollowDesc = 'noindexNofollow_DESC',
  ShowHeaderAsc = 'showHeader_ASC',
  ShowHeaderDesc = 'showHeader_DESC',
  SlugAsc = 'slug_ASC',
  SlugDesc = 'slug_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC',
}

export enum PagesHeroBlockLinkingCollectionsTemplateAboutUsCollectionOrder {
  CollapseHeaderAsc = 'collapseHeader_ASC',
  CollapseHeaderDesc = 'collapseHeader_DESC',
  HeaderBlockTitleAsc = 'headerBlockTitle_ASC',
  HeaderBlockTitleDesc = 'headerBlockTitle_DESC',
  ShowHeaderAsc = 'showHeader_ASC',
  ShowHeaderDesc = 'showHeader_DESC',
  SubtitleAsc = 'subtitle_ASC',
  SubtitleDesc = 'subtitle_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC',
}

export enum PagesHeroBlockLinkingCollectionsTemplateBookingCollectionOrder {
  CollapseHeaderAsc = 'collapseHeader_ASC',
  CollapseHeaderDesc = 'collapseHeader_DESC',
  ShowHeaderAsc = 'showHeader_ASC',
  ShowHeaderDesc = 'showHeader_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC',
}

export enum PagesHeroBlockLinkingCollectionsTemplateCareersCollectionOrder {
  CollapseHeaderAsc = 'collapseHeader_ASC',
  CollapseHeaderDesc = 'collapseHeader_DESC',
  ShowHeaderAsc = 'showHeader_ASC',
  ShowHeaderDesc = 'showHeader_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC',
}

export enum PagesHeroBlockLinkingCollectionsTemplateContactUsCollectionOrder {
  CollapseHeaderAsc = 'collapseHeader_ASC',
  CollapseHeaderDesc = 'collapseHeader_DESC',
  ShowHeaderAsc = 'showHeader_ASC',
  ShowHeaderDesc = 'showHeader_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC',
}

export enum PagesHeroBlockLinkingCollectionsTemplateDestinationsCollectionOrder {
  CollapseHeaderAsc = 'collapseHeader_ASC',
  CollapseHeaderDesc = 'collapseHeader_DESC',
  CollapsibleHeadingAsc = 'collapsibleHeading_ASC',
  CollapsibleHeadingDesc = 'collapsibleHeading_DESC',
  ShowHeaderAsc = 'showHeader_ASC',
  ShowHeaderDesc = 'showHeader_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC',
}

export enum PagesHeroBlockLinkingCollectionsTemplateForaReserveCollectionOrder {
  CollapseHeaderAsc = 'collapseHeader_ASC',
  CollapseHeaderDesc = 'collapseHeader_DESC',
  ShowHeaderAsc = 'showHeader_ASC',
  ShowHeaderDesc = 'showHeader_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC',
}

export enum PagesHeroBlockLinkingCollectionsTemplateForaXCollectionOrder {
  AdvisorGridHeaderAsc = 'advisorGridHeader_ASC',
  AdvisorGridHeaderDesc = 'advisorGridHeader_DESC',
  AdvisorGridSubCopyAsc = 'advisorGridSubCopy_ASC',
  AdvisorGridSubCopyDesc = 'advisorGridSubCopy_DESC',
  CollapseHeaderAsc = 'collapseHeader_ASC',
  CollapseHeaderDesc = 'collapseHeader_DESC',
  ShowHeaderAsc = 'showHeader_ASC',
  ShowHeaderDesc = 'showHeader_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC',
}

export enum PagesHeroBlockLinkingCollectionsTemplateHomeCollectionOrder {
  CollapseHeaderAsc = 'collapseHeader_ASC',
  CollapseHeaderDesc = 'collapseHeader_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC',
}

export enum PagesHeroBlockLinkingCollectionsTemplateJoinUsCollectionOrder {
  CollapseHeaderAsc = 'collapseHeader_ASC',
  CollapseHeaderDesc = 'collapseHeader_DESC',
  HideGlobalBannerAsc = 'hideGlobalBanner_ASC',
  HideGlobalBannerDesc = 'hideGlobalBanner_DESC',
  ShowHeaderAsc = 'showHeader_ASC',
  ShowHeaderDesc = 'showHeader_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC',
}

export enum PagesHeroBlockLinkingCollectionsTemplateNewsletterCollectionOrder {
  CollapseHeaderAsc = 'collapseHeader_ASC',
  CollapseHeaderDesc = 'collapseHeader_DESC',
  ShowHeaderAsc = 'showHeader_ASC',
  ShowHeaderDesc = 'showHeader_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC',
}

export enum PagesHeroBlockLinkingCollectionsTemplatePageCollectionOrder {
  DirectoryAsc = 'directory_ASC',
  DirectoryDesc = 'directory_DESC',
  InternalLabelAsc = 'internalLabel_ASC',
  InternalLabelDesc = 'internalLabel_DESC',
  MetaTitleAsc = 'metaTitle_ASC',
  MetaTitleDesc = 'metaTitle_DESC',
  NoindexNofollowAsc = 'noindexNofollow_ASC',
  NoindexNofollowDesc = 'noindexNofollow_DESC',
  SlugAsc = 'slug_ASC',
  SlugDesc = 'slug_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
}

export enum PagesHeroBlockLinkingCollectionsTemplatePartnerDetailCollectionOrder {
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
}

export enum PagesHeroBlockLinkingCollectionsTemplatePartnersCollectionOrder {
  CollapseHeaderAsc = 'collapseHeader_ASC',
  CollapseHeaderDesc = 'collapseHeader_DESC',
  ShowHeaderAsc = 'showHeader_ASC',
  ShowHeaderDesc = 'showHeader_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC',
}

export enum PagesHeroBlockLinkingCollectionsTemplateReviewsCollectionOrder {
  CollapseHeaderAsc = 'collapseHeader_ASC',
  CollapseHeaderDesc = 'collapseHeader_DESC',
  ShowHeaderAsc = 'showHeader_ASC',
  ShowHeaderDesc = 'showHeader_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC',
}

export enum PagesHeroBlockLinkingCollectionsTemplateWhyBookWithForaCollectionOrder {
  CollapseHeaderAsc = 'collapseHeader_ASC',
  CollapseHeaderDesc = 'collapseHeader_DESC',
  ShowHeaderAsc = 'showHeader_ASC',
  ShowHeaderDesc = 'showHeader_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC',
}

export enum PagesHeroBlockOrder {
  PrimaryCtaLinkTextAsc = 'primaryCtaLinkText_ASC',
  PrimaryCtaLinkTextDesc = 'primaryCtaLinkText_DESC',
  PrimaryCtaLinkUrlAsc = 'primaryCtaLinkUrl_ASC',
  PrimaryCtaLinkUrlDesc = 'primaryCtaLinkUrl_DESC',
  PrimaryUrlTargetAsc = 'primaryUrlTarget_ASC',
  PrimaryUrlTargetDesc = 'primaryUrlTarget_DESC',
  SecondaryCtaLinkTextAsc = 'secondaryCtaLinkText_ASC',
  SecondaryCtaLinkTextDesc = 'secondaryCtaLinkText_DESC',
  SecondaryCtaLinkUrlAsc = 'secondaryCtaLinkUrl_ASC',
  SecondaryCtaLinkUrlDesc = 'secondaryCtaLinkUrl_DESC',
  SecondaryUrlTargetAsc = 'secondaryUrlTarget_ASC',
  SecondaryUrlTargetDesc = 'secondaryUrlTarget_DESC',
  ShowFormAsPrimaryCtaAsc = 'showFormAsPrimaryCta_ASC',
  ShowFormAsPrimaryCtaDesc = 'showFormAsPrimaryCta_DESC',
  ShowSignUpFormAsPrimaryCtaAsc = 'showSignUpFormAsPrimaryCta_ASC',
  ShowSignUpFormAsPrimaryCtaDesc = 'showSignUpFormAsPrimaryCta_DESC',
  SubFormHeaderAsc = 'subFormHeader_ASC',
  SubFormHeaderDesc = 'subFormHeader_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  TitleStyleAsc = 'titleStyle_ASC',
  TitleStyleDesc = 'titleStyle_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC',
}

export type PagesHeroBlockSubFormCopy = {
  __typename?: 'PagesHeroBlockSubFormCopy'
  json: Scalars['JSON']
  links: PagesHeroBlockSubFormCopyLinks
}

export type PagesHeroBlockSubFormCopyAssets = {
  __typename?: 'PagesHeroBlockSubFormCopyAssets'
  block: Array<Maybe<Asset>>
  hyperlink: Array<Maybe<Asset>>
}

export type PagesHeroBlockSubFormCopyEntries = {
  __typename?: 'PagesHeroBlockSubFormCopyEntries'
  block: Array<Maybe<Entry>>
  hyperlink: Array<Maybe<Entry>>
  inline: Array<Maybe<Entry>>
}

export type PagesHeroBlockSubFormCopyLinks = {
  __typename?: 'PagesHeroBlockSubFormCopyLinks'
  assets: PagesHeroBlockSubFormCopyAssets
  entries: PagesHeroBlockSubFormCopyEntries
  resources: PagesHeroBlockSubFormCopyResources
}

export type PagesHeroBlockSubFormCopyResources = {
  __typename?: 'PagesHeroBlockSubFormCopyResources'
  block: Array<PagesHeroBlockSubFormCopyResourcesBlock>
  hyperlink: Array<PagesHeroBlockSubFormCopyResourcesHyperlink>
  inline: Array<PagesHeroBlockSubFormCopyResourcesInline>
}

export type PagesHeroBlockSubFormCopyResourcesBlock = ResourceLink & {
  __typename?: 'PagesHeroBlockSubFormCopyResourcesBlock'
  sys: ResourceSys
}

export type PagesHeroBlockSubFormCopyResourcesHyperlink = ResourceLink & {
  __typename?: 'PagesHeroBlockSubFormCopyResourcesHyperlink'
  sys: ResourceSys
}

export type PagesHeroBlockSubFormCopyResourcesInline = ResourceLink & {
  __typename?: 'PagesHeroBlockSubFormCopyResourcesInline'
  sys: ResourceSys
}

export type PagesHeroBlockTeaserCopy = {
  __typename?: 'PagesHeroBlockTeaserCopy'
  json: Scalars['JSON']
  links: PagesHeroBlockTeaserCopyLinks
}

export type PagesHeroBlockTeaserCopyAssets = {
  __typename?: 'PagesHeroBlockTeaserCopyAssets'
  block: Array<Maybe<Asset>>
  hyperlink: Array<Maybe<Asset>>
}

export type PagesHeroBlockTeaserCopyEntries = {
  __typename?: 'PagesHeroBlockTeaserCopyEntries'
  block: Array<Maybe<Entry>>
  hyperlink: Array<Maybe<Entry>>
  inline: Array<Maybe<Entry>>
}

export type PagesHeroBlockTeaserCopyLinks = {
  __typename?: 'PagesHeroBlockTeaserCopyLinks'
  assets: PagesHeroBlockTeaserCopyAssets
  entries: PagesHeroBlockTeaserCopyEntries
  resources: PagesHeroBlockTeaserCopyResources
}

export type PagesHeroBlockTeaserCopyResources = {
  __typename?: 'PagesHeroBlockTeaserCopyResources'
  block: Array<PagesHeroBlockTeaserCopyResourcesBlock>
  hyperlink: Array<PagesHeroBlockTeaserCopyResourcesHyperlink>
  inline: Array<PagesHeroBlockTeaserCopyResourcesInline>
}

export type PagesHeroBlockTeaserCopyResourcesBlock = ResourceLink & {
  __typename?: 'PagesHeroBlockTeaserCopyResourcesBlock'
  sys: ResourceSys
}

export type PagesHeroBlockTeaserCopyResourcesHyperlink = ResourceLink & {
  __typename?: 'PagesHeroBlockTeaserCopyResourcesHyperlink'
  sys: ResourceSys
}

export type PagesHeroBlockTeaserCopyResourcesInline = ResourceLink & {
  __typename?: 'PagesHeroBlockTeaserCopyResourcesInline'
  sys: ResourceSys
}

/** Create and manage Learn More Blocks. [See type definition](https://app.contentful.com/spaces/9cxgygtadmvl/content_types/pagesLearnMoreBlock) */
export type PagesLearnMoreBlock = Entry &
  _Node & {
    __typename?: 'PagesLearnMoreBlock'
    _id: Scalars['ID']
    analyticsModule?: Maybe<SettingsAnalyticsModule>
    animate?: Maybe<Scalars['Boolean']>
    bgColor?: Maybe<Scalars['String']>
    contentfulMetadata: ContentfulMetadata
    ctaText?: Maybe<Scalars['String']>
    ctaUrl?: Maybe<Scalars['String']>
    headerImage?: Maybe<Asset>
    imageColumnOne?: Maybe<Asset>
    imageColumnThree?: Maybe<Asset>
    imageColumnTwo?: Maybe<Asset>
    internalLabel?: Maybe<Scalars['String']>
    isCompact?: Maybe<Scalars['Boolean']>
    linkedFrom?: Maybe<PagesLearnMoreBlockLinkingCollections>
    sectionId?: Maybe<Scalars['String']>
    separator?: Maybe<Asset>
    separatorMobileDisplay?: Maybe<Scalars['String']>
    solidBackground?: Maybe<Scalars['Boolean']>
    sys: Sys
    teaserCopy?: Maybe<PagesLearnMoreBlockTeaserCopy>
    textColumnOne?: Maybe<PagesLearnMoreBlockTextColumnOne>
    textColumnThree?: Maybe<PagesLearnMoreBlockTextColumnThree>
    textColumnTwo?: Maybe<PagesLearnMoreBlockTextColumnTwo>
    title?: Maybe<Scalars['String']>
    titleStyle?: Maybe<Scalars['String']>
    urlTarget?: Maybe<Scalars['String']>
  }

/** Create and manage Learn More Blocks. [See type definition](https://app.contentful.com/spaces/9cxgygtadmvl/content_types/pagesLearnMoreBlock) */
export type PagesLearnMoreBlockAnalyticsModuleArgs = {
  locale?: InputMaybe<Scalars['String']>
  preview?: InputMaybe<Scalars['Boolean']>
  where?: InputMaybe<SettingsAnalyticsModuleFilter>
}

/** Create and manage Learn More Blocks. [See type definition](https://app.contentful.com/spaces/9cxgygtadmvl/content_types/pagesLearnMoreBlock) */
export type PagesLearnMoreBlockAnimateArgs = {
  locale?: InputMaybe<Scalars['String']>
}

/** Create and manage Learn More Blocks. [See type definition](https://app.contentful.com/spaces/9cxgygtadmvl/content_types/pagesLearnMoreBlock) */
export type PagesLearnMoreBlockBgColorArgs = {
  locale?: InputMaybe<Scalars['String']>
}

/** Create and manage Learn More Blocks. [See type definition](https://app.contentful.com/spaces/9cxgygtadmvl/content_types/pagesLearnMoreBlock) */
export type PagesLearnMoreBlockCtaTextArgs = {
  locale?: InputMaybe<Scalars['String']>
}

/** Create and manage Learn More Blocks. [See type definition](https://app.contentful.com/spaces/9cxgygtadmvl/content_types/pagesLearnMoreBlock) */
export type PagesLearnMoreBlockCtaUrlArgs = {
  locale?: InputMaybe<Scalars['String']>
}

/** Create and manage Learn More Blocks. [See type definition](https://app.contentful.com/spaces/9cxgygtadmvl/content_types/pagesLearnMoreBlock) */
export type PagesLearnMoreBlockHeaderImageArgs = {
  locale?: InputMaybe<Scalars['String']>
  preview?: InputMaybe<Scalars['Boolean']>
}

/** Create and manage Learn More Blocks. [See type definition](https://app.contentful.com/spaces/9cxgygtadmvl/content_types/pagesLearnMoreBlock) */
export type PagesLearnMoreBlockImageColumnOneArgs = {
  locale?: InputMaybe<Scalars['String']>
  preview?: InputMaybe<Scalars['Boolean']>
}

/** Create and manage Learn More Blocks. [See type definition](https://app.contentful.com/spaces/9cxgygtadmvl/content_types/pagesLearnMoreBlock) */
export type PagesLearnMoreBlockImageColumnThreeArgs = {
  locale?: InputMaybe<Scalars['String']>
  preview?: InputMaybe<Scalars['Boolean']>
}

/** Create and manage Learn More Blocks. [See type definition](https://app.contentful.com/spaces/9cxgygtadmvl/content_types/pagesLearnMoreBlock) */
export type PagesLearnMoreBlockImageColumnTwoArgs = {
  locale?: InputMaybe<Scalars['String']>
  preview?: InputMaybe<Scalars['Boolean']>
}

/** Create and manage Learn More Blocks. [See type definition](https://app.contentful.com/spaces/9cxgygtadmvl/content_types/pagesLearnMoreBlock) */
export type PagesLearnMoreBlockInternalLabelArgs = {
  locale?: InputMaybe<Scalars['String']>
}

/** Create and manage Learn More Blocks. [See type definition](https://app.contentful.com/spaces/9cxgygtadmvl/content_types/pagesLearnMoreBlock) */
export type PagesLearnMoreBlockIsCompactArgs = {
  locale?: InputMaybe<Scalars['String']>
}

/** Create and manage Learn More Blocks. [See type definition](https://app.contentful.com/spaces/9cxgygtadmvl/content_types/pagesLearnMoreBlock) */
export type PagesLearnMoreBlockLinkedFromArgs = {
  allowedLocales?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
}

/** Create and manage Learn More Blocks. [See type definition](https://app.contentful.com/spaces/9cxgygtadmvl/content_types/pagesLearnMoreBlock) */
export type PagesLearnMoreBlockSectionIdArgs = {
  locale?: InputMaybe<Scalars['String']>
}

/** Create and manage Learn More Blocks. [See type definition](https://app.contentful.com/spaces/9cxgygtadmvl/content_types/pagesLearnMoreBlock) */
export type PagesLearnMoreBlockSeparatorArgs = {
  locale?: InputMaybe<Scalars['String']>
  preview?: InputMaybe<Scalars['Boolean']>
}

/** Create and manage Learn More Blocks. [See type definition](https://app.contentful.com/spaces/9cxgygtadmvl/content_types/pagesLearnMoreBlock) */
export type PagesLearnMoreBlockSeparatorMobileDisplayArgs = {
  locale?: InputMaybe<Scalars['String']>
}

/** Create and manage Learn More Blocks. [See type definition](https://app.contentful.com/spaces/9cxgygtadmvl/content_types/pagesLearnMoreBlock) */
export type PagesLearnMoreBlockSolidBackgroundArgs = {
  locale?: InputMaybe<Scalars['String']>
}

/** Create and manage Learn More Blocks. [See type definition](https://app.contentful.com/spaces/9cxgygtadmvl/content_types/pagesLearnMoreBlock) */
export type PagesLearnMoreBlockTeaserCopyArgs = {
  locale?: InputMaybe<Scalars['String']>
}

/** Create and manage Learn More Blocks. [See type definition](https://app.contentful.com/spaces/9cxgygtadmvl/content_types/pagesLearnMoreBlock) */
export type PagesLearnMoreBlockTextColumnOneArgs = {
  locale?: InputMaybe<Scalars['String']>
}

/** Create and manage Learn More Blocks. [See type definition](https://app.contentful.com/spaces/9cxgygtadmvl/content_types/pagesLearnMoreBlock) */
export type PagesLearnMoreBlockTextColumnThreeArgs = {
  locale?: InputMaybe<Scalars['String']>
}

/** Create and manage Learn More Blocks. [See type definition](https://app.contentful.com/spaces/9cxgygtadmvl/content_types/pagesLearnMoreBlock) */
export type PagesLearnMoreBlockTextColumnTwoArgs = {
  locale?: InputMaybe<Scalars['String']>
}

/** Create and manage Learn More Blocks. [See type definition](https://app.contentful.com/spaces/9cxgygtadmvl/content_types/pagesLearnMoreBlock) */
export type PagesLearnMoreBlockTitleArgs = {
  locale?: InputMaybe<Scalars['String']>
}

/** Create and manage Learn More Blocks. [See type definition](https://app.contentful.com/spaces/9cxgygtadmvl/content_types/pagesLearnMoreBlock) */
export type PagesLearnMoreBlockTitleStyleArgs = {
  locale?: InputMaybe<Scalars['String']>
}

/** Create and manage Learn More Blocks. [See type definition](https://app.contentful.com/spaces/9cxgygtadmvl/content_types/pagesLearnMoreBlock) */
export type PagesLearnMoreBlockUrlTargetArgs = {
  locale?: InputMaybe<Scalars['String']>
}

export type PagesLearnMoreBlockCollection = {
  __typename?: 'PagesLearnMoreBlockCollection'
  items: Array<Maybe<PagesLearnMoreBlock>>
  limit: Scalars['Int']
  skip: Scalars['Int']
  total: Scalars['Int']
}

export type PagesLearnMoreBlockFilter = {
  AND?: InputMaybe<Array<InputMaybe<PagesLearnMoreBlockFilter>>>
  OR?: InputMaybe<Array<InputMaybe<PagesLearnMoreBlockFilter>>>
  analyticsModule?: InputMaybe<CfSettingsAnalyticsModuleNestedFilter>
  analyticsModule_exists?: InputMaybe<Scalars['Boolean']>
  animate?: InputMaybe<Scalars['Boolean']>
  animate_exists?: InputMaybe<Scalars['Boolean']>
  animate_not?: InputMaybe<Scalars['Boolean']>
  bgColor?: InputMaybe<Scalars['String']>
  bgColor_contains?: InputMaybe<Scalars['String']>
  bgColor_exists?: InputMaybe<Scalars['Boolean']>
  bgColor_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  bgColor_not?: InputMaybe<Scalars['String']>
  bgColor_not_contains?: InputMaybe<Scalars['String']>
  bgColor_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  contentfulMetadata?: InputMaybe<ContentfulMetadataFilter>
  ctaText?: InputMaybe<Scalars['String']>
  ctaText_contains?: InputMaybe<Scalars['String']>
  ctaText_exists?: InputMaybe<Scalars['Boolean']>
  ctaText_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  ctaText_not?: InputMaybe<Scalars['String']>
  ctaText_not_contains?: InputMaybe<Scalars['String']>
  ctaText_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  ctaUrl?: InputMaybe<Scalars['String']>
  ctaUrl_contains?: InputMaybe<Scalars['String']>
  ctaUrl_exists?: InputMaybe<Scalars['Boolean']>
  ctaUrl_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  ctaUrl_not?: InputMaybe<Scalars['String']>
  ctaUrl_not_contains?: InputMaybe<Scalars['String']>
  ctaUrl_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  headerImage_exists?: InputMaybe<Scalars['Boolean']>
  imageColumnOne_exists?: InputMaybe<Scalars['Boolean']>
  imageColumnThree_exists?: InputMaybe<Scalars['Boolean']>
  imageColumnTwo_exists?: InputMaybe<Scalars['Boolean']>
  internalLabel?: InputMaybe<Scalars['String']>
  internalLabel_contains?: InputMaybe<Scalars['String']>
  internalLabel_exists?: InputMaybe<Scalars['Boolean']>
  internalLabel_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  internalLabel_not?: InputMaybe<Scalars['String']>
  internalLabel_not_contains?: InputMaybe<Scalars['String']>
  internalLabel_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  isCompact?: InputMaybe<Scalars['Boolean']>
  isCompact_exists?: InputMaybe<Scalars['Boolean']>
  isCompact_not?: InputMaybe<Scalars['Boolean']>
  sectionId?: InputMaybe<Scalars['String']>
  sectionId_contains?: InputMaybe<Scalars['String']>
  sectionId_exists?: InputMaybe<Scalars['Boolean']>
  sectionId_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  sectionId_not?: InputMaybe<Scalars['String']>
  sectionId_not_contains?: InputMaybe<Scalars['String']>
  sectionId_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  separatorMobileDisplay?: InputMaybe<Scalars['String']>
  separatorMobileDisplay_contains?: InputMaybe<Scalars['String']>
  separatorMobileDisplay_exists?: InputMaybe<Scalars['Boolean']>
  separatorMobileDisplay_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  separatorMobileDisplay_not?: InputMaybe<Scalars['String']>
  separatorMobileDisplay_not_contains?: InputMaybe<Scalars['String']>
  separatorMobileDisplay_not_in?: InputMaybe<
    Array<InputMaybe<Scalars['String']>>
  >
  separator_exists?: InputMaybe<Scalars['Boolean']>
  solidBackground?: InputMaybe<Scalars['Boolean']>
  solidBackground_exists?: InputMaybe<Scalars['Boolean']>
  solidBackground_not?: InputMaybe<Scalars['Boolean']>
  sys?: InputMaybe<SysFilter>
  teaserCopy_contains?: InputMaybe<Scalars['String']>
  teaserCopy_exists?: InputMaybe<Scalars['Boolean']>
  teaserCopy_not_contains?: InputMaybe<Scalars['String']>
  textColumnOne_contains?: InputMaybe<Scalars['String']>
  textColumnOne_exists?: InputMaybe<Scalars['Boolean']>
  textColumnOne_not_contains?: InputMaybe<Scalars['String']>
  textColumnThree_contains?: InputMaybe<Scalars['String']>
  textColumnThree_exists?: InputMaybe<Scalars['Boolean']>
  textColumnThree_not_contains?: InputMaybe<Scalars['String']>
  textColumnTwo_contains?: InputMaybe<Scalars['String']>
  textColumnTwo_exists?: InputMaybe<Scalars['Boolean']>
  textColumnTwo_not_contains?: InputMaybe<Scalars['String']>
  title?: InputMaybe<Scalars['String']>
  titleStyle?: InputMaybe<Scalars['String']>
  titleStyle_contains?: InputMaybe<Scalars['String']>
  titleStyle_exists?: InputMaybe<Scalars['Boolean']>
  titleStyle_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  titleStyle_not?: InputMaybe<Scalars['String']>
  titleStyle_not_contains?: InputMaybe<Scalars['String']>
  titleStyle_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  title_contains?: InputMaybe<Scalars['String']>
  title_exists?: InputMaybe<Scalars['Boolean']>
  title_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  title_not?: InputMaybe<Scalars['String']>
  title_not_contains?: InputMaybe<Scalars['String']>
  title_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  urlTarget?: InputMaybe<Scalars['String']>
  urlTarget_contains?: InputMaybe<Scalars['String']>
  urlTarget_exists?: InputMaybe<Scalars['Boolean']>
  urlTarget_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  urlTarget_not?: InputMaybe<Scalars['String']>
  urlTarget_not_contains?: InputMaybe<Scalars['String']>
  urlTarget_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
}

export type PagesLearnMoreBlockLinkingCollections = {
  __typename?: 'PagesLearnMoreBlockLinkingCollections'
  campaignPagesCollection?: Maybe<CampaignPagesCollection>
  entryCollection?: Maybe<EntryCollection>
  templateAboutUsCollection?: Maybe<TemplateAboutUsCollection>
  templateAdvisorsCollection?: Maybe<TemplateAdvisorsCollection>
  templateBookingCollection?: Maybe<TemplateBookingCollection>
  templateCareersCollection?: Maybe<TemplateCareersCollection>
  templateContactUsCollection?: Maybe<TemplateContactUsCollection>
  templateDestinationsCollection?: Maybe<TemplateDestinationsCollection>
  templateForaReserveCollection?: Maybe<TemplateForaReserveCollection>
  templateForaXCollection?: Maybe<TemplateForaXCollection>
  templateHomeCollection?: Maybe<TemplateHomeCollection>
  templateJoinUsCollection?: Maybe<TemplateJoinUsCollection>
  templateNewsletterCollection?: Maybe<TemplateNewsletterCollection>
  templatePageCollection?: Maybe<TemplatePageCollection>
  templatePartnerDetailCollection?: Maybe<TemplatePartnerDetailCollection>
  templatePartnersCollection?: Maybe<TemplatePartnersCollection>
  templateReviewsCollection?: Maybe<TemplateReviewsCollection>
  templateWhyBookWithForaCollection?: Maybe<TemplateWhyBookWithForaCollection>
}

export type PagesLearnMoreBlockLinkingCollectionsCampaignPagesCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>
  locale?: InputMaybe<Scalars['String']>
  order?: InputMaybe<
    Array<
      InputMaybe<PagesLearnMoreBlockLinkingCollectionsCampaignPagesCollectionOrder>
    >
  >
  preview?: InputMaybe<Scalars['Boolean']>
  skip?: InputMaybe<Scalars['Int']>
}

export type PagesLearnMoreBlockLinkingCollectionsEntryCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>
  locale?: InputMaybe<Scalars['String']>
  preview?: InputMaybe<Scalars['Boolean']>
  skip?: InputMaybe<Scalars['Int']>
}

export type PagesLearnMoreBlockLinkingCollectionsTemplateAboutUsCollectionArgs =
  {
    limit?: InputMaybe<Scalars['Int']>
    locale?: InputMaybe<Scalars['String']>
    order?: InputMaybe<
      Array<
        InputMaybe<PagesLearnMoreBlockLinkingCollectionsTemplateAboutUsCollectionOrder>
      >
    >
    preview?: InputMaybe<Scalars['Boolean']>
    skip?: InputMaybe<Scalars['Int']>
  }

export type PagesLearnMoreBlockLinkingCollectionsTemplateAdvisorsCollectionArgs =
  {
    limit?: InputMaybe<Scalars['Int']>
    locale?: InputMaybe<Scalars['String']>
    order?: InputMaybe<
      Array<
        InputMaybe<PagesLearnMoreBlockLinkingCollectionsTemplateAdvisorsCollectionOrder>
      >
    >
    preview?: InputMaybe<Scalars['Boolean']>
    skip?: InputMaybe<Scalars['Int']>
  }

export type PagesLearnMoreBlockLinkingCollectionsTemplateBookingCollectionArgs =
  {
    limit?: InputMaybe<Scalars['Int']>
    locale?: InputMaybe<Scalars['String']>
    order?: InputMaybe<
      Array<
        InputMaybe<PagesLearnMoreBlockLinkingCollectionsTemplateBookingCollectionOrder>
      >
    >
    preview?: InputMaybe<Scalars['Boolean']>
    skip?: InputMaybe<Scalars['Int']>
  }

export type PagesLearnMoreBlockLinkingCollectionsTemplateCareersCollectionArgs =
  {
    limit?: InputMaybe<Scalars['Int']>
    locale?: InputMaybe<Scalars['String']>
    order?: InputMaybe<
      Array<
        InputMaybe<PagesLearnMoreBlockLinkingCollectionsTemplateCareersCollectionOrder>
      >
    >
    preview?: InputMaybe<Scalars['Boolean']>
    skip?: InputMaybe<Scalars['Int']>
  }

export type PagesLearnMoreBlockLinkingCollectionsTemplateContactUsCollectionArgs =
  {
    limit?: InputMaybe<Scalars['Int']>
    locale?: InputMaybe<Scalars['String']>
    order?: InputMaybe<
      Array<
        InputMaybe<PagesLearnMoreBlockLinkingCollectionsTemplateContactUsCollectionOrder>
      >
    >
    preview?: InputMaybe<Scalars['Boolean']>
    skip?: InputMaybe<Scalars['Int']>
  }

export type PagesLearnMoreBlockLinkingCollectionsTemplateDestinationsCollectionArgs =
  {
    limit?: InputMaybe<Scalars['Int']>
    locale?: InputMaybe<Scalars['String']>
    order?: InputMaybe<
      Array<
        InputMaybe<PagesLearnMoreBlockLinkingCollectionsTemplateDestinationsCollectionOrder>
      >
    >
    preview?: InputMaybe<Scalars['Boolean']>
    skip?: InputMaybe<Scalars['Int']>
  }

export type PagesLearnMoreBlockLinkingCollectionsTemplateForaReserveCollectionArgs =
  {
    limit?: InputMaybe<Scalars['Int']>
    locale?: InputMaybe<Scalars['String']>
    order?: InputMaybe<
      Array<
        InputMaybe<PagesLearnMoreBlockLinkingCollectionsTemplateForaReserveCollectionOrder>
      >
    >
    preview?: InputMaybe<Scalars['Boolean']>
    skip?: InputMaybe<Scalars['Int']>
  }

export type PagesLearnMoreBlockLinkingCollectionsTemplateForaXCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>
  locale?: InputMaybe<Scalars['String']>
  order?: InputMaybe<
    Array<
      InputMaybe<PagesLearnMoreBlockLinkingCollectionsTemplateForaXCollectionOrder>
    >
  >
  preview?: InputMaybe<Scalars['Boolean']>
  skip?: InputMaybe<Scalars['Int']>
}

export type PagesLearnMoreBlockLinkingCollectionsTemplateHomeCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>
  locale?: InputMaybe<Scalars['String']>
  order?: InputMaybe<
    Array<
      InputMaybe<PagesLearnMoreBlockLinkingCollectionsTemplateHomeCollectionOrder>
    >
  >
  preview?: InputMaybe<Scalars['Boolean']>
  skip?: InputMaybe<Scalars['Int']>
}

export type PagesLearnMoreBlockLinkingCollectionsTemplateJoinUsCollectionArgs =
  {
    limit?: InputMaybe<Scalars['Int']>
    locale?: InputMaybe<Scalars['String']>
    order?: InputMaybe<
      Array<
        InputMaybe<PagesLearnMoreBlockLinkingCollectionsTemplateJoinUsCollectionOrder>
      >
    >
    preview?: InputMaybe<Scalars['Boolean']>
    skip?: InputMaybe<Scalars['Int']>
  }

export type PagesLearnMoreBlockLinkingCollectionsTemplateNewsletterCollectionArgs =
  {
    limit?: InputMaybe<Scalars['Int']>
    locale?: InputMaybe<Scalars['String']>
    order?: InputMaybe<
      Array<
        InputMaybe<PagesLearnMoreBlockLinkingCollectionsTemplateNewsletterCollectionOrder>
      >
    >
    preview?: InputMaybe<Scalars['Boolean']>
    skip?: InputMaybe<Scalars['Int']>
  }

export type PagesLearnMoreBlockLinkingCollectionsTemplatePageCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>
  locale?: InputMaybe<Scalars['String']>
  order?: InputMaybe<
    Array<
      InputMaybe<PagesLearnMoreBlockLinkingCollectionsTemplatePageCollectionOrder>
    >
  >
  preview?: InputMaybe<Scalars['Boolean']>
  skip?: InputMaybe<Scalars['Int']>
}

export type PagesLearnMoreBlockLinkingCollectionsTemplatePartnerDetailCollectionArgs =
  {
    limit?: InputMaybe<Scalars['Int']>
    locale?: InputMaybe<Scalars['String']>
    order?: InputMaybe<
      Array<
        InputMaybe<PagesLearnMoreBlockLinkingCollectionsTemplatePartnerDetailCollectionOrder>
      >
    >
    preview?: InputMaybe<Scalars['Boolean']>
    skip?: InputMaybe<Scalars['Int']>
  }

export type PagesLearnMoreBlockLinkingCollectionsTemplatePartnersCollectionArgs =
  {
    limit?: InputMaybe<Scalars['Int']>
    locale?: InputMaybe<Scalars['String']>
    order?: InputMaybe<
      Array<
        InputMaybe<PagesLearnMoreBlockLinkingCollectionsTemplatePartnersCollectionOrder>
      >
    >
    preview?: InputMaybe<Scalars['Boolean']>
    skip?: InputMaybe<Scalars['Int']>
  }

export type PagesLearnMoreBlockLinkingCollectionsTemplateReviewsCollectionArgs =
  {
    limit?: InputMaybe<Scalars['Int']>
    locale?: InputMaybe<Scalars['String']>
    order?: InputMaybe<
      Array<
        InputMaybe<PagesLearnMoreBlockLinkingCollectionsTemplateReviewsCollectionOrder>
      >
    >
    preview?: InputMaybe<Scalars['Boolean']>
    skip?: InputMaybe<Scalars['Int']>
  }

export type PagesLearnMoreBlockLinkingCollectionsTemplateWhyBookWithForaCollectionArgs =
  {
    limit?: InputMaybe<Scalars['Int']>
    locale?: InputMaybe<Scalars['String']>
    order?: InputMaybe<
      Array<
        InputMaybe<PagesLearnMoreBlockLinkingCollectionsTemplateWhyBookWithForaCollectionOrder>
      >
    >
    preview?: InputMaybe<Scalars['Boolean']>
    skip?: InputMaybe<Scalars['Int']>
  }

export enum PagesLearnMoreBlockLinkingCollectionsCampaignPagesCollectionOrder {
  CollapseFooterAsc = 'collapseFooter_ASC',
  CollapseFooterDesc = 'collapseFooter_DESC',
  CollapseHeaderAsc = 'collapseHeader_ASC',
  CollapseHeaderDesc = 'collapseHeader_DESC',
  HideFooterAsc = 'hideFooter_ASC',
  HideFooterDesc = 'hideFooter_DESC',
  HideGlobalBannerAsc = 'hideGlobalBanner_ASC',
  HideGlobalBannerDesc = 'hideGlobalBanner_DESC',
  JotformIdAsc = 'jotformId_ASC',
  JotformIdDesc = 'jotformId_DESC',
  NoindexNofollowAsc = 'noindexNofollow_ASC',
  NoindexNofollowDesc = 'noindexNofollow_DESC',
  ShowHeaderAsc = 'showHeader_ASC',
  ShowHeaderDesc = 'showHeader_DESC',
  SlugAsc = 'slug_ASC',
  SlugDesc = 'slug_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC',
}

export enum PagesLearnMoreBlockLinkingCollectionsTemplateAboutUsCollectionOrder {
  CollapseHeaderAsc = 'collapseHeader_ASC',
  CollapseHeaderDesc = 'collapseHeader_DESC',
  HeaderBlockTitleAsc = 'headerBlockTitle_ASC',
  HeaderBlockTitleDesc = 'headerBlockTitle_DESC',
  ShowHeaderAsc = 'showHeader_ASC',
  ShowHeaderDesc = 'showHeader_DESC',
  SubtitleAsc = 'subtitle_ASC',
  SubtitleDesc = 'subtitle_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC',
}

export enum PagesLearnMoreBlockLinkingCollectionsTemplateAdvisorsCollectionOrder {
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC',
}

export enum PagesLearnMoreBlockLinkingCollectionsTemplateBookingCollectionOrder {
  CollapseHeaderAsc = 'collapseHeader_ASC',
  CollapseHeaderDesc = 'collapseHeader_DESC',
  ShowHeaderAsc = 'showHeader_ASC',
  ShowHeaderDesc = 'showHeader_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC',
}

export enum PagesLearnMoreBlockLinkingCollectionsTemplateCareersCollectionOrder {
  CollapseHeaderAsc = 'collapseHeader_ASC',
  CollapseHeaderDesc = 'collapseHeader_DESC',
  ShowHeaderAsc = 'showHeader_ASC',
  ShowHeaderDesc = 'showHeader_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC',
}

export enum PagesLearnMoreBlockLinkingCollectionsTemplateContactUsCollectionOrder {
  CollapseHeaderAsc = 'collapseHeader_ASC',
  CollapseHeaderDesc = 'collapseHeader_DESC',
  ShowHeaderAsc = 'showHeader_ASC',
  ShowHeaderDesc = 'showHeader_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC',
}

export enum PagesLearnMoreBlockLinkingCollectionsTemplateDestinationsCollectionOrder {
  CollapseHeaderAsc = 'collapseHeader_ASC',
  CollapseHeaderDesc = 'collapseHeader_DESC',
  CollapsibleHeadingAsc = 'collapsibleHeading_ASC',
  CollapsibleHeadingDesc = 'collapsibleHeading_DESC',
  ShowHeaderAsc = 'showHeader_ASC',
  ShowHeaderDesc = 'showHeader_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC',
}

export enum PagesLearnMoreBlockLinkingCollectionsTemplateForaReserveCollectionOrder {
  CollapseHeaderAsc = 'collapseHeader_ASC',
  CollapseHeaderDesc = 'collapseHeader_DESC',
  ShowHeaderAsc = 'showHeader_ASC',
  ShowHeaderDesc = 'showHeader_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC',
}

export enum PagesLearnMoreBlockLinkingCollectionsTemplateForaXCollectionOrder {
  AdvisorGridHeaderAsc = 'advisorGridHeader_ASC',
  AdvisorGridHeaderDesc = 'advisorGridHeader_DESC',
  AdvisorGridSubCopyAsc = 'advisorGridSubCopy_ASC',
  AdvisorGridSubCopyDesc = 'advisorGridSubCopy_DESC',
  CollapseHeaderAsc = 'collapseHeader_ASC',
  CollapseHeaderDesc = 'collapseHeader_DESC',
  ShowHeaderAsc = 'showHeader_ASC',
  ShowHeaderDesc = 'showHeader_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC',
}

export enum PagesLearnMoreBlockLinkingCollectionsTemplateHomeCollectionOrder {
  CollapseHeaderAsc = 'collapseHeader_ASC',
  CollapseHeaderDesc = 'collapseHeader_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC',
}

export enum PagesLearnMoreBlockLinkingCollectionsTemplateJoinUsCollectionOrder {
  CollapseHeaderAsc = 'collapseHeader_ASC',
  CollapseHeaderDesc = 'collapseHeader_DESC',
  HideGlobalBannerAsc = 'hideGlobalBanner_ASC',
  HideGlobalBannerDesc = 'hideGlobalBanner_DESC',
  ShowHeaderAsc = 'showHeader_ASC',
  ShowHeaderDesc = 'showHeader_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC',
}

export enum PagesLearnMoreBlockLinkingCollectionsTemplateNewsletterCollectionOrder {
  CollapseHeaderAsc = 'collapseHeader_ASC',
  CollapseHeaderDesc = 'collapseHeader_DESC',
  ShowHeaderAsc = 'showHeader_ASC',
  ShowHeaderDesc = 'showHeader_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC',
}

export enum PagesLearnMoreBlockLinkingCollectionsTemplatePageCollectionOrder {
  DirectoryAsc = 'directory_ASC',
  DirectoryDesc = 'directory_DESC',
  InternalLabelAsc = 'internalLabel_ASC',
  InternalLabelDesc = 'internalLabel_DESC',
  MetaTitleAsc = 'metaTitle_ASC',
  MetaTitleDesc = 'metaTitle_DESC',
  NoindexNofollowAsc = 'noindexNofollow_ASC',
  NoindexNofollowDesc = 'noindexNofollow_DESC',
  SlugAsc = 'slug_ASC',
  SlugDesc = 'slug_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
}

export enum PagesLearnMoreBlockLinkingCollectionsTemplatePartnerDetailCollectionOrder {
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
}

export enum PagesLearnMoreBlockLinkingCollectionsTemplatePartnersCollectionOrder {
  CollapseHeaderAsc = 'collapseHeader_ASC',
  CollapseHeaderDesc = 'collapseHeader_DESC',
  ShowHeaderAsc = 'showHeader_ASC',
  ShowHeaderDesc = 'showHeader_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC',
}

export enum PagesLearnMoreBlockLinkingCollectionsTemplateReviewsCollectionOrder {
  CollapseHeaderAsc = 'collapseHeader_ASC',
  CollapseHeaderDesc = 'collapseHeader_DESC',
  ShowHeaderAsc = 'showHeader_ASC',
  ShowHeaderDesc = 'showHeader_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC',
}

export enum PagesLearnMoreBlockLinkingCollectionsTemplateWhyBookWithForaCollectionOrder {
  CollapseHeaderAsc = 'collapseHeader_ASC',
  CollapseHeaderDesc = 'collapseHeader_DESC',
  ShowHeaderAsc = 'showHeader_ASC',
  ShowHeaderDesc = 'showHeader_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC',
}

export enum PagesLearnMoreBlockOrder {
  AnimateAsc = 'animate_ASC',
  AnimateDesc = 'animate_DESC',
  BgColorAsc = 'bgColor_ASC',
  BgColorDesc = 'bgColor_DESC',
  CtaTextAsc = 'ctaText_ASC',
  CtaTextDesc = 'ctaText_DESC',
  CtaUrlAsc = 'ctaUrl_ASC',
  CtaUrlDesc = 'ctaUrl_DESC',
  InternalLabelAsc = 'internalLabel_ASC',
  InternalLabelDesc = 'internalLabel_DESC',
  IsCompactAsc = 'isCompact_ASC',
  IsCompactDesc = 'isCompact_DESC',
  SectionIdAsc = 'sectionId_ASC',
  SectionIdDesc = 'sectionId_DESC',
  SeparatorMobileDisplayAsc = 'separatorMobileDisplay_ASC',
  SeparatorMobileDisplayDesc = 'separatorMobileDisplay_DESC',
  SolidBackgroundAsc = 'solidBackground_ASC',
  SolidBackgroundDesc = 'solidBackground_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  TitleStyleAsc = 'titleStyle_ASC',
  TitleStyleDesc = 'titleStyle_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC',
  UrlTargetAsc = 'urlTarget_ASC',
  UrlTargetDesc = 'urlTarget_DESC',
}

export type PagesLearnMoreBlockTeaserCopy = {
  __typename?: 'PagesLearnMoreBlockTeaserCopy'
  json: Scalars['JSON']
  links: PagesLearnMoreBlockTeaserCopyLinks
}

export type PagesLearnMoreBlockTeaserCopyAssets = {
  __typename?: 'PagesLearnMoreBlockTeaserCopyAssets'
  block: Array<Maybe<Asset>>
  hyperlink: Array<Maybe<Asset>>
}

export type PagesLearnMoreBlockTeaserCopyEntries = {
  __typename?: 'PagesLearnMoreBlockTeaserCopyEntries'
  block: Array<Maybe<Entry>>
  hyperlink: Array<Maybe<Entry>>
  inline: Array<Maybe<Entry>>
}

export type PagesLearnMoreBlockTeaserCopyLinks = {
  __typename?: 'PagesLearnMoreBlockTeaserCopyLinks'
  assets: PagesLearnMoreBlockTeaserCopyAssets
  entries: PagesLearnMoreBlockTeaserCopyEntries
  resources: PagesLearnMoreBlockTeaserCopyResources
}

export type PagesLearnMoreBlockTeaserCopyResources = {
  __typename?: 'PagesLearnMoreBlockTeaserCopyResources'
  block: Array<PagesLearnMoreBlockTeaserCopyResourcesBlock>
  hyperlink: Array<PagesLearnMoreBlockTeaserCopyResourcesHyperlink>
  inline: Array<PagesLearnMoreBlockTeaserCopyResourcesInline>
}

export type PagesLearnMoreBlockTeaserCopyResourcesBlock = ResourceLink & {
  __typename?: 'PagesLearnMoreBlockTeaserCopyResourcesBlock'
  sys: ResourceSys
}

export type PagesLearnMoreBlockTeaserCopyResourcesHyperlink = ResourceLink & {
  __typename?: 'PagesLearnMoreBlockTeaserCopyResourcesHyperlink'
  sys: ResourceSys
}

export type PagesLearnMoreBlockTeaserCopyResourcesInline = ResourceLink & {
  __typename?: 'PagesLearnMoreBlockTeaserCopyResourcesInline'
  sys: ResourceSys
}

export type PagesLearnMoreBlockTextColumnOne = {
  __typename?: 'PagesLearnMoreBlockTextColumnOne'
  json: Scalars['JSON']
  links: PagesLearnMoreBlockTextColumnOneLinks
}

export type PagesLearnMoreBlockTextColumnOneAssets = {
  __typename?: 'PagesLearnMoreBlockTextColumnOneAssets'
  block: Array<Maybe<Asset>>
  hyperlink: Array<Maybe<Asset>>
}

export type PagesLearnMoreBlockTextColumnOneEntries = {
  __typename?: 'PagesLearnMoreBlockTextColumnOneEntries'
  block: Array<Maybe<Entry>>
  hyperlink: Array<Maybe<Entry>>
  inline: Array<Maybe<Entry>>
}

export type PagesLearnMoreBlockTextColumnOneLinks = {
  __typename?: 'PagesLearnMoreBlockTextColumnOneLinks'
  assets: PagesLearnMoreBlockTextColumnOneAssets
  entries: PagesLearnMoreBlockTextColumnOneEntries
  resources: PagesLearnMoreBlockTextColumnOneResources
}

export type PagesLearnMoreBlockTextColumnOneResources = {
  __typename?: 'PagesLearnMoreBlockTextColumnOneResources'
  block: Array<PagesLearnMoreBlockTextColumnOneResourcesBlock>
  hyperlink: Array<PagesLearnMoreBlockTextColumnOneResourcesHyperlink>
  inline: Array<PagesLearnMoreBlockTextColumnOneResourcesInline>
}

export type PagesLearnMoreBlockTextColumnOneResourcesBlock = ResourceLink & {
  __typename?: 'PagesLearnMoreBlockTextColumnOneResourcesBlock'
  sys: ResourceSys
}

export type PagesLearnMoreBlockTextColumnOneResourcesHyperlink =
  ResourceLink & {
    __typename?: 'PagesLearnMoreBlockTextColumnOneResourcesHyperlink'
    sys: ResourceSys
  }

export type PagesLearnMoreBlockTextColumnOneResourcesInline = ResourceLink & {
  __typename?: 'PagesLearnMoreBlockTextColumnOneResourcesInline'
  sys: ResourceSys
}

export type PagesLearnMoreBlockTextColumnThree = {
  __typename?: 'PagesLearnMoreBlockTextColumnThree'
  json: Scalars['JSON']
  links: PagesLearnMoreBlockTextColumnThreeLinks
}

export type PagesLearnMoreBlockTextColumnThreeAssets = {
  __typename?: 'PagesLearnMoreBlockTextColumnThreeAssets'
  block: Array<Maybe<Asset>>
  hyperlink: Array<Maybe<Asset>>
}

export type PagesLearnMoreBlockTextColumnThreeEntries = {
  __typename?: 'PagesLearnMoreBlockTextColumnThreeEntries'
  block: Array<Maybe<Entry>>
  hyperlink: Array<Maybe<Entry>>
  inline: Array<Maybe<Entry>>
}

export type PagesLearnMoreBlockTextColumnThreeLinks = {
  __typename?: 'PagesLearnMoreBlockTextColumnThreeLinks'
  assets: PagesLearnMoreBlockTextColumnThreeAssets
  entries: PagesLearnMoreBlockTextColumnThreeEntries
  resources: PagesLearnMoreBlockTextColumnThreeResources
}

export type PagesLearnMoreBlockTextColumnThreeResources = {
  __typename?: 'PagesLearnMoreBlockTextColumnThreeResources'
  block: Array<PagesLearnMoreBlockTextColumnThreeResourcesBlock>
  hyperlink: Array<PagesLearnMoreBlockTextColumnThreeResourcesHyperlink>
  inline: Array<PagesLearnMoreBlockTextColumnThreeResourcesInline>
}

export type PagesLearnMoreBlockTextColumnThreeResourcesBlock = ResourceLink & {
  __typename?: 'PagesLearnMoreBlockTextColumnThreeResourcesBlock'
  sys: ResourceSys
}

export type PagesLearnMoreBlockTextColumnThreeResourcesHyperlink =
  ResourceLink & {
    __typename?: 'PagesLearnMoreBlockTextColumnThreeResourcesHyperlink'
    sys: ResourceSys
  }

export type PagesLearnMoreBlockTextColumnThreeResourcesInline = ResourceLink & {
  __typename?: 'PagesLearnMoreBlockTextColumnThreeResourcesInline'
  sys: ResourceSys
}

export type PagesLearnMoreBlockTextColumnTwo = {
  __typename?: 'PagesLearnMoreBlockTextColumnTwo'
  json: Scalars['JSON']
  links: PagesLearnMoreBlockTextColumnTwoLinks
}

export type PagesLearnMoreBlockTextColumnTwoAssets = {
  __typename?: 'PagesLearnMoreBlockTextColumnTwoAssets'
  block: Array<Maybe<Asset>>
  hyperlink: Array<Maybe<Asset>>
}

export type PagesLearnMoreBlockTextColumnTwoEntries = {
  __typename?: 'PagesLearnMoreBlockTextColumnTwoEntries'
  block: Array<Maybe<Entry>>
  hyperlink: Array<Maybe<Entry>>
  inline: Array<Maybe<Entry>>
}

export type PagesLearnMoreBlockTextColumnTwoLinks = {
  __typename?: 'PagesLearnMoreBlockTextColumnTwoLinks'
  assets: PagesLearnMoreBlockTextColumnTwoAssets
  entries: PagesLearnMoreBlockTextColumnTwoEntries
  resources: PagesLearnMoreBlockTextColumnTwoResources
}

export type PagesLearnMoreBlockTextColumnTwoResources = {
  __typename?: 'PagesLearnMoreBlockTextColumnTwoResources'
  block: Array<PagesLearnMoreBlockTextColumnTwoResourcesBlock>
  hyperlink: Array<PagesLearnMoreBlockTextColumnTwoResourcesHyperlink>
  inline: Array<PagesLearnMoreBlockTextColumnTwoResourcesInline>
}

export type PagesLearnMoreBlockTextColumnTwoResourcesBlock = ResourceLink & {
  __typename?: 'PagesLearnMoreBlockTextColumnTwoResourcesBlock'
  sys: ResourceSys
}

export type PagesLearnMoreBlockTextColumnTwoResourcesHyperlink =
  ResourceLink & {
    __typename?: 'PagesLearnMoreBlockTextColumnTwoResourcesHyperlink'
    sys: ResourceSys
  }

export type PagesLearnMoreBlockTextColumnTwoResourcesInline = ResourceLink & {
  __typename?: 'PagesLearnMoreBlockTextColumnTwoResourcesInline'
  sys: ResourceSys
}

/** Create and manage meta content for SEO purposes at the page level. [See type definition](https://app.contentful.com/spaces/9cxgygtadmvl/content_types/pagesMetaContent) */
export type PagesMetaContent = Entry &
  _Node & {
    __typename?: 'PagesMetaContent'
    _id: Scalars['ID']
    contentfulMetadata: ContentfulMetadata
    linkedFrom?: Maybe<PagesMetaContentLinkingCollections>
    metaDescription?: Maybe<Scalars['String']>
    metaImage?: Maybe<Asset>
    metaTitle?: Maybe<Scalars['String']>
    sys: Sys
    title?: Maybe<Scalars['String']>
  }

/** Create and manage meta content for SEO purposes at the page level. [See type definition](https://app.contentful.com/spaces/9cxgygtadmvl/content_types/pagesMetaContent) */
export type PagesMetaContentLinkedFromArgs = {
  allowedLocales?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
}

/** Create and manage meta content for SEO purposes at the page level. [See type definition](https://app.contentful.com/spaces/9cxgygtadmvl/content_types/pagesMetaContent) */
export type PagesMetaContentMetaDescriptionArgs = {
  locale?: InputMaybe<Scalars['String']>
}

/** Create and manage meta content for SEO purposes at the page level. [See type definition](https://app.contentful.com/spaces/9cxgygtadmvl/content_types/pagesMetaContent) */
export type PagesMetaContentMetaImageArgs = {
  locale?: InputMaybe<Scalars['String']>
  preview?: InputMaybe<Scalars['Boolean']>
}

/** Create and manage meta content for SEO purposes at the page level. [See type definition](https://app.contentful.com/spaces/9cxgygtadmvl/content_types/pagesMetaContent) */
export type PagesMetaContentMetaTitleArgs = {
  locale?: InputMaybe<Scalars['String']>
}

/** Create and manage meta content for SEO purposes at the page level. [See type definition](https://app.contentful.com/spaces/9cxgygtadmvl/content_types/pagesMetaContent) */
export type PagesMetaContentTitleArgs = {
  locale?: InputMaybe<Scalars['String']>
}

export type PagesMetaContentCollection = {
  __typename?: 'PagesMetaContentCollection'
  items: Array<Maybe<PagesMetaContent>>
  limit: Scalars['Int']
  skip: Scalars['Int']
  total: Scalars['Int']
}

export type PagesMetaContentFilter = {
  AND?: InputMaybe<Array<InputMaybe<PagesMetaContentFilter>>>
  OR?: InputMaybe<Array<InputMaybe<PagesMetaContentFilter>>>
  contentfulMetadata?: InputMaybe<ContentfulMetadataFilter>
  metaDescription?: InputMaybe<Scalars['String']>
  metaDescription_contains?: InputMaybe<Scalars['String']>
  metaDescription_exists?: InputMaybe<Scalars['Boolean']>
  metaDescription_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  metaDescription_not?: InputMaybe<Scalars['String']>
  metaDescription_not_contains?: InputMaybe<Scalars['String']>
  metaDescription_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  metaImage_exists?: InputMaybe<Scalars['Boolean']>
  metaTitle?: InputMaybe<Scalars['String']>
  metaTitle_contains?: InputMaybe<Scalars['String']>
  metaTitle_exists?: InputMaybe<Scalars['Boolean']>
  metaTitle_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  metaTitle_not?: InputMaybe<Scalars['String']>
  metaTitle_not_contains?: InputMaybe<Scalars['String']>
  metaTitle_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  sys?: InputMaybe<SysFilter>
  title?: InputMaybe<Scalars['String']>
  title_contains?: InputMaybe<Scalars['String']>
  title_exists?: InputMaybe<Scalars['Boolean']>
  title_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  title_not?: InputMaybe<Scalars['String']>
  title_not_contains?: InputMaybe<Scalars['String']>
  title_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
}

export type PagesMetaContentLinkingCollections = {
  __typename?: 'PagesMetaContentLinkingCollections'
  campaignPagesCollection?: Maybe<CampaignPagesCollection>
  destinationsCategoriesCollection?: Maybe<DestinationsCategoriesCollection>
  destinationsSubcategoriesCollection?: Maybe<DestinationsSubcategoriesCollection>
  entryCollection?: Maybe<EntryCollection>
  entryPartnerCollection?: Maybe<EntryPartnerCollection>
  templateAboutUsCollection?: Maybe<TemplateAboutUsCollection>
  templateAdvisorsCollection?: Maybe<TemplateAdvisorsCollection>
  templateBlogCollection?: Maybe<TemplateBlogCollection>
  templateBookingCollection?: Maybe<TemplateBookingCollection>
  templateCareersCollection?: Maybe<TemplateCareersCollection>
  templateContactUsCollection?: Maybe<TemplateContactUsCollection>
  templateDestinationsCollection?: Maybe<TemplateDestinationsCollection>
  templateEventsCollection?: Maybe<TemplateEventsCollection>
  templateFaqsCollection?: Maybe<TemplateFaqsCollection>
  templateForaReserveCollection?: Maybe<TemplateForaReserveCollection>
  templateForaXCollection?: Maybe<TemplateForaXCollection>
  templateGuidesCollection?: Maybe<TemplateGuidesCollection>
  templateHomeCollection?: Maybe<TemplateHomeCollection>
  templateItinerariesCollection?: Maybe<TemplateItinerariesCollection>
  templateJoinUsCollection?: Maybe<TemplateJoinUsCollection>
  templateNewsletterCollection?: Maybe<TemplateNewsletterCollection>
  templatePartnersCollection?: Maybe<TemplatePartnersCollection>
  templatePressCollection?: Maybe<TemplatePressCollection>
  templatePrivacyPolicyCollection?: Maybe<TemplatePrivacyPolicyCollection>
  templateReviewsCollection?: Maybe<TemplateReviewsCollection>
  templateSearchCollection?: Maybe<TemplateSearchCollection>
  templateTermsAndConditionsCollection?: Maybe<TemplateTermsAndConditionsCollection>
  templateTravelByCollection?: Maybe<TemplateTravelByCollection>
  templateTravelCollection?: Maybe<TemplateTravelCollection>
  templateWhyBookWithForaCollection?: Maybe<TemplateWhyBookWithForaCollection>
  travelBySubcategoriesCollection?: Maybe<TravelBySubcategoriesCollection>
}

export type PagesMetaContentLinkingCollectionsCampaignPagesCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>
  locale?: InputMaybe<Scalars['String']>
  order?: InputMaybe<
    Array<
      InputMaybe<PagesMetaContentLinkingCollectionsCampaignPagesCollectionOrder>
    >
  >
  preview?: InputMaybe<Scalars['Boolean']>
  skip?: InputMaybe<Scalars['Int']>
}

export type PagesMetaContentLinkingCollectionsDestinationsCategoriesCollectionArgs =
  {
    limit?: InputMaybe<Scalars['Int']>
    locale?: InputMaybe<Scalars['String']>
    order?: InputMaybe<
      Array<
        InputMaybe<PagesMetaContentLinkingCollectionsDestinationsCategoriesCollectionOrder>
      >
    >
    preview?: InputMaybe<Scalars['Boolean']>
    skip?: InputMaybe<Scalars['Int']>
  }

export type PagesMetaContentLinkingCollectionsDestinationsSubcategoriesCollectionArgs =
  {
    limit?: InputMaybe<Scalars['Int']>
    locale?: InputMaybe<Scalars['String']>
    order?: InputMaybe<
      Array<
        InputMaybe<PagesMetaContentLinkingCollectionsDestinationsSubcategoriesCollectionOrder>
      >
    >
    preview?: InputMaybe<Scalars['Boolean']>
    skip?: InputMaybe<Scalars['Int']>
  }

export type PagesMetaContentLinkingCollectionsEntryCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>
  locale?: InputMaybe<Scalars['String']>
  preview?: InputMaybe<Scalars['Boolean']>
  skip?: InputMaybe<Scalars['Int']>
}

export type PagesMetaContentLinkingCollectionsEntryPartnerCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>
  locale?: InputMaybe<Scalars['String']>
  order?: InputMaybe<
    Array<
      InputMaybe<PagesMetaContentLinkingCollectionsEntryPartnerCollectionOrder>
    >
  >
  preview?: InputMaybe<Scalars['Boolean']>
  skip?: InputMaybe<Scalars['Int']>
}

export type PagesMetaContentLinkingCollectionsTemplateAboutUsCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>
  locale?: InputMaybe<Scalars['String']>
  order?: InputMaybe<
    Array<
      InputMaybe<PagesMetaContentLinkingCollectionsTemplateAboutUsCollectionOrder>
    >
  >
  preview?: InputMaybe<Scalars['Boolean']>
  skip?: InputMaybe<Scalars['Int']>
}

export type PagesMetaContentLinkingCollectionsTemplateAdvisorsCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>
  locale?: InputMaybe<Scalars['String']>
  order?: InputMaybe<
    Array<
      InputMaybe<PagesMetaContentLinkingCollectionsTemplateAdvisorsCollectionOrder>
    >
  >
  preview?: InputMaybe<Scalars['Boolean']>
  skip?: InputMaybe<Scalars['Int']>
}

export type PagesMetaContentLinkingCollectionsTemplateBlogCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>
  locale?: InputMaybe<Scalars['String']>
  order?: InputMaybe<
    Array<
      InputMaybe<PagesMetaContentLinkingCollectionsTemplateBlogCollectionOrder>
    >
  >
  preview?: InputMaybe<Scalars['Boolean']>
  skip?: InputMaybe<Scalars['Int']>
}

export type PagesMetaContentLinkingCollectionsTemplateBookingCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>
  locale?: InputMaybe<Scalars['String']>
  order?: InputMaybe<
    Array<
      InputMaybe<PagesMetaContentLinkingCollectionsTemplateBookingCollectionOrder>
    >
  >
  preview?: InputMaybe<Scalars['Boolean']>
  skip?: InputMaybe<Scalars['Int']>
}

export type PagesMetaContentLinkingCollectionsTemplateCareersCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>
  locale?: InputMaybe<Scalars['String']>
  order?: InputMaybe<
    Array<
      InputMaybe<PagesMetaContentLinkingCollectionsTemplateCareersCollectionOrder>
    >
  >
  preview?: InputMaybe<Scalars['Boolean']>
  skip?: InputMaybe<Scalars['Int']>
}

export type PagesMetaContentLinkingCollectionsTemplateContactUsCollectionArgs =
  {
    limit?: InputMaybe<Scalars['Int']>
    locale?: InputMaybe<Scalars['String']>
    order?: InputMaybe<
      Array<
        InputMaybe<PagesMetaContentLinkingCollectionsTemplateContactUsCollectionOrder>
      >
    >
    preview?: InputMaybe<Scalars['Boolean']>
    skip?: InputMaybe<Scalars['Int']>
  }

export type PagesMetaContentLinkingCollectionsTemplateDestinationsCollectionArgs =
  {
    limit?: InputMaybe<Scalars['Int']>
    locale?: InputMaybe<Scalars['String']>
    order?: InputMaybe<
      Array<
        InputMaybe<PagesMetaContentLinkingCollectionsTemplateDestinationsCollectionOrder>
      >
    >
    preview?: InputMaybe<Scalars['Boolean']>
    skip?: InputMaybe<Scalars['Int']>
  }

export type PagesMetaContentLinkingCollectionsTemplateEventsCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>
  locale?: InputMaybe<Scalars['String']>
  order?: InputMaybe<
    Array<
      InputMaybe<PagesMetaContentLinkingCollectionsTemplateEventsCollectionOrder>
    >
  >
  preview?: InputMaybe<Scalars['Boolean']>
  skip?: InputMaybe<Scalars['Int']>
}

export type PagesMetaContentLinkingCollectionsTemplateFaqsCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>
  locale?: InputMaybe<Scalars['String']>
  order?: InputMaybe<
    Array<
      InputMaybe<PagesMetaContentLinkingCollectionsTemplateFaqsCollectionOrder>
    >
  >
  preview?: InputMaybe<Scalars['Boolean']>
  skip?: InputMaybe<Scalars['Int']>
}

export type PagesMetaContentLinkingCollectionsTemplateForaReserveCollectionArgs =
  {
    limit?: InputMaybe<Scalars['Int']>
    locale?: InputMaybe<Scalars['String']>
    order?: InputMaybe<
      Array<
        InputMaybe<PagesMetaContentLinkingCollectionsTemplateForaReserveCollectionOrder>
      >
    >
    preview?: InputMaybe<Scalars['Boolean']>
    skip?: InputMaybe<Scalars['Int']>
  }

export type PagesMetaContentLinkingCollectionsTemplateForaXCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>
  locale?: InputMaybe<Scalars['String']>
  order?: InputMaybe<
    Array<
      InputMaybe<PagesMetaContentLinkingCollectionsTemplateForaXCollectionOrder>
    >
  >
  preview?: InputMaybe<Scalars['Boolean']>
  skip?: InputMaybe<Scalars['Int']>
}

export type PagesMetaContentLinkingCollectionsTemplateGuidesCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>
  locale?: InputMaybe<Scalars['String']>
  order?: InputMaybe<
    Array<
      InputMaybe<PagesMetaContentLinkingCollectionsTemplateGuidesCollectionOrder>
    >
  >
  preview?: InputMaybe<Scalars['Boolean']>
  skip?: InputMaybe<Scalars['Int']>
}

export type PagesMetaContentLinkingCollectionsTemplateHomeCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>
  locale?: InputMaybe<Scalars['String']>
  order?: InputMaybe<
    Array<
      InputMaybe<PagesMetaContentLinkingCollectionsTemplateHomeCollectionOrder>
    >
  >
  preview?: InputMaybe<Scalars['Boolean']>
  skip?: InputMaybe<Scalars['Int']>
}

export type PagesMetaContentLinkingCollectionsTemplateItinerariesCollectionArgs =
  {
    limit?: InputMaybe<Scalars['Int']>
    locale?: InputMaybe<Scalars['String']>
    order?: InputMaybe<
      Array<
        InputMaybe<PagesMetaContentLinkingCollectionsTemplateItinerariesCollectionOrder>
      >
    >
    preview?: InputMaybe<Scalars['Boolean']>
    skip?: InputMaybe<Scalars['Int']>
  }

export type PagesMetaContentLinkingCollectionsTemplateJoinUsCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>
  locale?: InputMaybe<Scalars['String']>
  order?: InputMaybe<
    Array<
      InputMaybe<PagesMetaContentLinkingCollectionsTemplateJoinUsCollectionOrder>
    >
  >
  preview?: InputMaybe<Scalars['Boolean']>
  skip?: InputMaybe<Scalars['Int']>
}

export type PagesMetaContentLinkingCollectionsTemplateNewsletterCollectionArgs =
  {
    limit?: InputMaybe<Scalars['Int']>
    locale?: InputMaybe<Scalars['String']>
    order?: InputMaybe<
      Array<
        InputMaybe<PagesMetaContentLinkingCollectionsTemplateNewsletterCollectionOrder>
      >
    >
    preview?: InputMaybe<Scalars['Boolean']>
    skip?: InputMaybe<Scalars['Int']>
  }

export type PagesMetaContentLinkingCollectionsTemplatePartnersCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>
  locale?: InputMaybe<Scalars['String']>
  order?: InputMaybe<
    Array<
      InputMaybe<PagesMetaContentLinkingCollectionsTemplatePartnersCollectionOrder>
    >
  >
  preview?: InputMaybe<Scalars['Boolean']>
  skip?: InputMaybe<Scalars['Int']>
}

export type PagesMetaContentLinkingCollectionsTemplatePressCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>
  locale?: InputMaybe<Scalars['String']>
  order?: InputMaybe<
    Array<
      InputMaybe<PagesMetaContentLinkingCollectionsTemplatePressCollectionOrder>
    >
  >
  preview?: InputMaybe<Scalars['Boolean']>
  skip?: InputMaybe<Scalars['Int']>
}

export type PagesMetaContentLinkingCollectionsTemplatePrivacyPolicyCollectionArgs =
  {
    limit?: InputMaybe<Scalars['Int']>
    locale?: InputMaybe<Scalars['String']>
    order?: InputMaybe<
      Array<
        InputMaybe<PagesMetaContentLinkingCollectionsTemplatePrivacyPolicyCollectionOrder>
      >
    >
    preview?: InputMaybe<Scalars['Boolean']>
    skip?: InputMaybe<Scalars['Int']>
  }

export type PagesMetaContentLinkingCollectionsTemplateReviewsCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>
  locale?: InputMaybe<Scalars['String']>
  order?: InputMaybe<
    Array<
      InputMaybe<PagesMetaContentLinkingCollectionsTemplateReviewsCollectionOrder>
    >
  >
  preview?: InputMaybe<Scalars['Boolean']>
  skip?: InputMaybe<Scalars['Int']>
}

export type PagesMetaContentLinkingCollectionsTemplateSearchCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>
  locale?: InputMaybe<Scalars['String']>
  order?: InputMaybe<
    Array<
      InputMaybe<PagesMetaContentLinkingCollectionsTemplateSearchCollectionOrder>
    >
  >
  preview?: InputMaybe<Scalars['Boolean']>
  skip?: InputMaybe<Scalars['Int']>
}

export type PagesMetaContentLinkingCollectionsTemplateTermsAndConditionsCollectionArgs =
  {
    limit?: InputMaybe<Scalars['Int']>
    locale?: InputMaybe<Scalars['String']>
    order?: InputMaybe<
      Array<
        InputMaybe<PagesMetaContentLinkingCollectionsTemplateTermsAndConditionsCollectionOrder>
      >
    >
    preview?: InputMaybe<Scalars['Boolean']>
    skip?: InputMaybe<Scalars['Int']>
  }

export type PagesMetaContentLinkingCollectionsTemplateTravelByCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>
  locale?: InputMaybe<Scalars['String']>
  order?: InputMaybe<
    Array<
      InputMaybe<PagesMetaContentLinkingCollectionsTemplateTravelByCollectionOrder>
    >
  >
  preview?: InputMaybe<Scalars['Boolean']>
  skip?: InputMaybe<Scalars['Int']>
}

export type PagesMetaContentLinkingCollectionsTemplateTravelCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>
  locale?: InputMaybe<Scalars['String']>
  order?: InputMaybe<
    Array<
      InputMaybe<PagesMetaContentLinkingCollectionsTemplateTravelCollectionOrder>
    >
  >
  preview?: InputMaybe<Scalars['Boolean']>
  skip?: InputMaybe<Scalars['Int']>
}

export type PagesMetaContentLinkingCollectionsTemplateWhyBookWithForaCollectionArgs =
  {
    limit?: InputMaybe<Scalars['Int']>
    locale?: InputMaybe<Scalars['String']>
    order?: InputMaybe<
      Array<
        InputMaybe<PagesMetaContentLinkingCollectionsTemplateWhyBookWithForaCollectionOrder>
      >
    >
    preview?: InputMaybe<Scalars['Boolean']>
    skip?: InputMaybe<Scalars['Int']>
  }

export type PagesMetaContentLinkingCollectionsTravelBySubcategoriesCollectionArgs =
  {
    limit?: InputMaybe<Scalars['Int']>
    locale?: InputMaybe<Scalars['String']>
    order?: InputMaybe<
      Array<
        InputMaybe<PagesMetaContentLinkingCollectionsTravelBySubcategoriesCollectionOrder>
      >
    >
    preview?: InputMaybe<Scalars['Boolean']>
    skip?: InputMaybe<Scalars['Int']>
  }

export enum PagesMetaContentLinkingCollectionsCampaignPagesCollectionOrder {
  CollapseFooterAsc = 'collapseFooter_ASC',
  CollapseFooterDesc = 'collapseFooter_DESC',
  CollapseHeaderAsc = 'collapseHeader_ASC',
  CollapseHeaderDesc = 'collapseHeader_DESC',
  HideFooterAsc = 'hideFooter_ASC',
  HideFooterDesc = 'hideFooter_DESC',
  HideGlobalBannerAsc = 'hideGlobalBanner_ASC',
  HideGlobalBannerDesc = 'hideGlobalBanner_DESC',
  JotformIdAsc = 'jotformId_ASC',
  JotformIdDesc = 'jotformId_DESC',
  NoindexNofollowAsc = 'noindexNofollow_ASC',
  NoindexNofollowDesc = 'noindexNofollow_DESC',
  ShowHeaderAsc = 'showHeader_ASC',
  ShowHeaderDesc = 'showHeader_DESC',
  SlugAsc = 'slug_ASC',
  SlugDesc = 'slug_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC',
}

export enum PagesMetaContentLinkingCollectionsDestinationsCategoriesCollectionOrder {
  GenerateTopHotelsPageAsc = 'generateTopHotelsPage_ASC',
  GenerateTopHotelsPageDesc = 'generateTopHotelsPage_DESC',
  GrammarDefiniteArticleAsc = 'grammarDefiniteArticle_ASC',
  GrammarDefiniteArticleDesc = 'grammarDefiniteArticle_DESC',
  SlugAsc = 'slug_ASC',
  SlugDesc = 'slug_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC',
}

export enum PagesMetaContentLinkingCollectionsDestinationsSubcategoriesCollectionOrder {
  GenerateLandingPageAsc = 'generateLandingPage_ASC',
  GenerateLandingPageDesc = 'generateLandingPage_DESC',
  GenerateTopHotelsPageAsc = 'generateTopHotelsPage_ASC',
  GenerateTopHotelsPageDesc = 'generateTopHotelsPage_DESC',
  GrammarDefiniteArticleAsc = 'grammarDefiniteArticle_ASC',
  GrammarDefiniteArticleDesc = 'grammarDefiniteArticle_DESC',
  ShowInCategoryListAsc = 'showInCategoryList_ASC',
  ShowInCategoryListDesc = 'showInCategoryList_DESC',
  SlugAsc = 'slug_ASC',
  SlugDesc = 'slug_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC',
}

export enum PagesMetaContentLinkingCollectionsEntryPartnerCollectionOrder {
  DetailSummaryAsc = 'detailSummary_ASC',
  DetailSummaryDesc = 'detailSummary_DESC',
  FeaturedAsc = 'featured_ASC',
  FeaturedDesc = 'featured_DESC',
  HasDetailPageAsc = 'hasDetailPage_ASC',
  HasDetailPageDesc = 'hasDetailPage_DESC',
  HideFromPartnerGridAsc = 'hideFromPartnerGrid_ASC',
  HideFromPartnerGridDesc = 'hideFromPartnerGrid_DESC',
  NameAsc = 'name_ASC',
  NameDesc = 'name_DESC',
  PartnerCategoryAsc = 'partnerCategory_ASC',
  PartnerCategoryDesc = 'partnerCategory_DESC',
  PartnerTypeAsc = 'partnerType_ASC',
  PartnerTypeDesc = 'partnerType_DESC',
  SlugAsc = 'slug_ASC',
  SlugDesc = 'slug_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
}

export enum PagesMetaContentLinkingCollectionsTemplateAboutUsCollectionOrder {
  CollapseHeaderAsc = 'collapseHeader_ASC',
  CollapseHeaderDesc = 'collapseHeader_DESC',
  HeaderBlockTitleAsc = 'headerBlockTitle_ASC',
  HeaderBlockTitleDesc = 'headerBlockTitle_DESC',
  ShowHeaderAsc = 'showHeader_ASC',
  ShowHeaderDesc = 'showHeader_DESC',
  SubtitleAsc = 'subtitle_ASC',
  SubtitleDesc = 'subtitle_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC',
}

export enum PagesMetaContentLinkingCollectionsTemplateAdvisorsCollectionOrder {
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC',
}

export enum PagesMetaContentLinkingCollectionsTemplateBlogCollectionOrder {
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC',
}

export enum PagesMetaContentLinkingCollectionsTemplateBookingCollectionOrder {
  CollapseHeaderAsc = 'collapseHeader_ASC',
  CollapseHeaderDesc = 'collapseHeader_DESC',
  ShowHeaderAsc = 'showHeader_ASC',
  ShowHeaderDesc = 'showHeader_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC',
}

export enum PagesMetaContentLinkingCollectionsTemplateCareersCollectionOrder {
  CollapseHeaderAsc = 'collapseHeader_ASC',
  CollapseHeaderDesc = 'collapseHeader_DESC',
  ShowHeaderAsc = 'showHeader_ASC',
  ShowHeaderDesc = 'showHeader_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC',
}

export enum PagesMetaContentLinkingCollectionsTemplateContactUsCollectionOrder {
  CollapseHeaderAsc = 'collapseHeader_ASC',
  CollapseHeaderDesc = 'collapseHeader_DESC',
  ShowHeaderAsc = 'showHeader_ASC',
  ShowHeaderDesc = 'showHeader_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC',
}

export enum PagesMetaContentLinkingCollectionsTemplateDestinationsCollectionOrder {
  CollapseHeaderAsc = 'collapseHeader_ASC',
  CollapseHeaderDesc = 'collapseHeader_DESC',
  CollapsibleHeadingAsc = 'collapsibleHeading_ASC',
  CollapsibleHeadingDesc = 'collapsibleHeading_DESC',
  ShowHeaderAsc = 'showHeader_ASC',
  ShowHeaderDesc = 'showHeader_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC',
}

export enum PagesMetaContentLinkingCollectionsTemplateEventsCollectionOrder {
  CollapseHeaderAsc = 'collapseHeader_ASC',
  CollapseHeaderDesc = 'collapseHeader_DESC',
  ShowHeaderAsc = 'showHeader_ASC',
  ShowHeaderDesc = 'showHeader_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC',
}

export enum PagesMetaContentLinkingCollectionsTemplateFaqsCollectionOrder {
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC',
}

export enum PagesMetaContentLinkingCollectionsTemplateForaReserveCollectionOrder {
  CollapseHeaderAsc = 'collapseHeader_ASC',
  CollapseHeaderDesc = 'collapseHeader_DESC',
  ShowHeaderAsc = 'showHeader_ASC',
  ShowHeaderDesc = 'showHeader_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC',
}

export enum PagesMetaContentLinkingCollectionsTemplateForaXCollectionOrder {
  AdvisorGridHeaderAsc = 'advisorGridHeader_ASC',
  AdvisorGridHeaderDesc = 'advisorGridHeader_DESC',
  AdvisorGridSubCopyAsc = 'advisorGridSubCopy_ASC',
  AdvisorGridSubCopyDesc = 'advisorGridSubCopy_DESC',
  CollapseHeaderAsc = 'collapseHeader_ASC',
  CollapseHeaderDesc = 'collapseHeader_DESC',
  ShowHeaderAsc = 'showHeader_ASC',
  ShowHeaderDesc = 'showHeader_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC',
}

export enum PagesMetaContentLinkingCollectionsTemplateGuidesCollectionOrder {
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC',
}

export enum PagesMetaContentLinkingCollectionsTemplateHomeCollectionOrder {
  CollapseHeaderAsc = 'collapseHeader_ASC',
  CollapseHeaderDesc = 'collapseHeader_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC',
}

export enum PagesMetaContentLinkingCollectionsTemplateItinerariesCollectionOrder {
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC',
}

export enum PagesMetaContentLinkingCollectionsTemplateJoinUsCollectionOrder {
  CollapseHeaderAsc = 'collapseHeader_ASC',
  CollapseHeaderDesc = 'collapseHeader_DESC',
  HideGlobalBannerAsc = 'hideGlobalBanner_ASC',
  HideGlobalBannerDesc = 'hideGlobalBanner_DESC',
  ShowHeaderAsc = 'showHeader_ASC',
  ShowHeaderDesc = 'showHeader_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC',
}

export enum PagesMetaContentLinkingCollectionsTemplateNewsletterCollectionOrder {
  CollapseHeaderAsc = 'collapseHeader_ASC',
  CollapseHeaderDesc = 'collapseHeader_DESC',
  ShowHeaderAsc = 'showHeader_ASC',
  ShowHeaderDesc = 'showHeader_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC',
}

export enum PagesMetaContentLinkingCollectionsTemplatePartnersCollectionOrder {
  CollapseHeaderAsc = 'collapseHeader_ASC',
  CollapseHeaderDesc = 'collapseHeader_DESC',
  ShowHeaderAsc = 'showHeader_ASC',
  ShowHeaderDesc = 'showHeader_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC',
}

export enum PagesMetaContentLinkingCollectionsTemplatePressCollectionOrder {
  SubheadAsc = 'subhead_ASC',
  SubheadDesc = 'subhead_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC',
}

export enum PagesMetaContentLinkingCollectionsTemplatePrivacyPolicyCollectionOrder {
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC',
}

export enum PagesMetaContentLinkingCollectionsTemplateReviewsCollectionOrder {
  CollapseHeaderAsc = 'collapseHeader_ASC',
  CollapseHeaderDesc = 'collapseHeader_DESC',
  ShowHeaderAsc = 'showHeader_ASC',
  ShowHeaderDesc = 'showHeader_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC',
}

export enum PagesMetaContentLinkingCollectionsTemplateSearchCollectionOrder {
  AdminLabelAsc = 'adminLabel_ASC',
  AdminLabelDesc = 'adminLabel_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
}

export enum PagesMetaContentLinkingCollectionsTemplateTermsAndConditionsCollectionOrder {
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC',
}

export enum PagesMetaContentLinkingCollectionsTemplateTravelByCollectionOrder {
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC',
}

export enum PagesMetaContentLinkingCollectionsTemplateTravelCollectionOrder {
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC',
  TravelByHeadingAsc = 'travelByHeading_ASC',
  TravelByHeadingDesc = 'travelByHeading_DESC',
}

export enum PagesMetaContentLinkingCollectionsTemplateWhyBookWithForaCollectionOrder {
  CollapseHeaderAsc = 'collapseHeader_ASC',
  CollapseHeaderDesc = 'collapseHeader_DESC',
  ShowHeaderAsc = 'showHeader_ASC',
  ShowHeaderDesc = 'showHeader_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC',
}

export enum PagesMetaContentLinkingCollectionsTravelBySubcategoriesCollectionOrder {
  GenerateLandingPageAsc = 'generateLandingPage_ASC',
  GenerateLandingPageDesc = 'generateLandingPage_DESC',
  ShowInGuidesCategoryListAsc = 'showInGuidesCategoryList_ASC',
  ShowInGuidesCategoryListDesc = 'showInGuidesCategoryList_DESC',
  ShowInItinerariesCategoryListAsc = 'showInItinerariesCategoryList_ASC',
  ShowInItinerariesCategoryListDesc = 'showInItinerariesCategoryList_DESC',
  ShowInStoriesCategoryListAsc = 'showInStoriesCategoryList_ASC',
  ShowInStoriesCategoryListDesc = 'showInStoriesCategoryList_DESC',
  SlugAsc = 'slug_ASC',
  SlugDesc = 'slug_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC',
}

export enum PagesMetaContentOrder {
  MetaTitleAsc = 'metaTitle_ASC',
  MetaTitleDesc = 'metaTitle_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC',
}

/** A page section for showing a banner of partner logo images. [See type definition](https://app.contentful.com/spaces/9cxgygtadmvl/content_types/pagesPartnerBanner) */
export type PagesPartnerBanner = Entry &
  _Node & {
    __typename?: 'PagesPartnerBanner'
    _id: Scalars['ID']
    adminLabel?: Maybe<Scalars['String']>
    analyticsModule?: Maybe<SettingsAnalyticsModule>
    contentfulMetadata: ContentfulMetadata
    linkedFrom?: Maybe<PagesPartnerBannerLinkingCollections>
    logosCollection?: Maybe<AssetCollection>
    showViewMoreButton?: Maybe<Scalars['Boolean']>
    sys: Sys
  }

/** A page section for showing a banner of partner logo images. [See type definition](https://app.contentful.com/spaces/9cxgygtadmvl/content_types/pagesPartnerBanner) */
export type PagesPartnerBannerAdminLabelArgs = {
  locale?: InputMaybe<Scalars['String']>
}

/** A page section for showing a banner of partner logo images. [See type definition](https://app.contentful.com/spaces/9cxgygtadmvl/content_types/pagesPartnerBanner) */
export type PagesPartnerBannerAnalyticsModuleArgs = {
  locale?: InputMaybe<Scalars['String']>
  preview?: InputMaybe<Scalars['Boolean']>
  where?: InputMaybe<SettingsAnalyticsModuleFilter>
}

/** A page section for showing a banner of partner logo images. [See type definition](https://app.contentful.com/spaces/9cxgygtadmvl/content_types/pagesPartnerBanner) */
export type PagesPartnerBannerLinkedFromArgs = {
  allowedLocales?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
}

/** A page section for showing a banner of partner logo images. [See type definition](https://app.contentful.com/spaces/9cxgygtadmvl/content_types/pagesPartnerBanner) */
export type PagesPartnerBannerLogosCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>
  locale?: InputMaybe<Scalars['String']>
  preview?: InputMaybe<Scalars['Boolean']>
  skip?: InputMaybe<Scalars['Int']>
}

/** A page section for showing a banner of partner logo images. [See type definition](https://app.contentful.com/spaces/9cxgygtadmvl/content_types/pagesPartnerBanner) */
export type PagesPartnerBannerShowViewMoreButtonArgs = {
  locale?: InputMaybe<Scalars['String']>
}

export type PagesPartnerBannerCollection = {
  __typename?: 'PagesPartnerBannerCollection'
  items: Array<Maybe<PagesPartnerBanner>>
  limit: Scalars['Int']
  skip: Scalars['Int']
  total: Scalars['Int']
}

export type PagesPartnerBannerFilter = {
  AND?: InputMaybe<Array<InputMaybe<PagesPartnerBannerFilter>>>
  OR?: InputMaybe<Array<InputMaybe<PagesPartnerBannerFilter>>>
  adminLabel?: InputMaybe<Scalars['String']>
  adminLabel_contains?: InputMaybe<Scalars['String']>
  adminLabel_exists?: InputMaybe<Scalars['Boolean']>
  adminLabel_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  adminLabel_not?: InputMaybe<Scalars['String']>
  adminLabel_not_contains?: InputMaybe<Scalars['String']>
  adminLabel_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  analyticsModule?: InputMaybe<CfSettingsAnalyticsModuleNestedFilter>
  analyticsModule_exists?: InputMaybe<Scalars['Boolean']>
  contentfulMetadata?: InputMaybe<ContentfulMetadataFilter>
  logosCollection_exists?: InputMaybe<Scalars['Boolean']>
  showViewMoreButton?: InputMaybe<Scalars['Boolean']>
  showViewMoreButton_exists?: InputMaybe<Scalars['Boolean']>
  showViewMoreButton_not?: InputMaybe<Scalars['Boolean']>
  sys?: InputMaybe<SysFilter>
}

export type PagesPartnerBannerLinkingCollections = {
  __typename?: 'PagesPartnerBannerLinkingCollections'
  campaignPagesCollection?: Maybe<CampaignPagesCollection>
  entryCollection?: Maybe<EntryCollection>
  templateAboutUsCollection?: Maybe<TemplateAboutUsCollection>
  templateBookingCollection?: Maybe<TemplateBookingCollection>
  templateCareersCollection?: Maybe<TemplateCareersCollection>
  templateContactUsCollection?: Maybe<TemplateContactUsCollection>
  templateDestinationsCollection?: Maybe<TemplateDestinationsCollection>
  templateForaReserveCollection?: Maybe<TemplateForaReserveCollection>
  templateForaXCollection?: Maybe<TemplateForaXCollection>
  templateHomeCollection?: Maybe<TemplateHomeCollection>
  templateJoinUsCollection?: Maybe<TemplateJoinUsCollection>
  templateNewsletterCollection?: Maybe<TemplateNewsletterCollection>
  templatePageCollection?: Maybe<TemplatePageCollection>
  templatePartnersCollection?: Maybe<TemplatePartnersCollection>
  templateReviewsCollection?: Maybe<TemplateReviewsCollection>
  templateWhyBookWithForaCollection?: Maybe<TemplateWhyBookWithForaCollection>
}

export type PagesPartnerBannerLinkingCollectionsCampaignPagesCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>
  locale?: InputMaybe<Scalars['String']>
  order?: InputMaybe<
    Array<
      InputMaybe<PagesPartnerBannerLinkingCollectionsCampaignPagesCollectionOrder>
    >
  >
  preview?: InputMaybe<Scalars['Boolean']>
  skip?: InputMaybe<Scalars['Int']>
}

export type PagesPartnerBannerLinkingCollectionsEntryCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>
  locale?: InputMaybe<Scalars['String']>
  preview?: InputMaybe<Scalars['Boolean']>
  skip?: InputMaybe<Scalars['Int']>
}

export type PagesPartnerBannerLinkingCollectionsTemplateAboutUsCollectionArgs =
  {
    limit?: InputMaybe<Scalars['Int']>
    locale?: InputMaybe<Scalars['String']>
    order?: InputMaybe<
      Array<
        InputMaybe<PagesPartnerBannerLinkingCollectionsTemplateAboutUsCollectionOrder>
      >
    >
    preview?: InputMaybe<Scalars['Boolean']>
    skip?: InputMaybe<Scalars['Int']>
  }

export type PagesPartnerBannerLinkingCollectionsTemplateBookingCollectionArgs =
  {
    limit?: InputMaybe<Scalars['Int']>
    locale?: InputMaybe<Scalars['String']>
    order?: InputMaybe<
      Array<
        InputMaybe<PagesPartnerBannerLinkingCollectionsTemplateBookingCollectionOrder>
      >
    >
    preview?: InputMaybe<Scalars['Boolean']>
    skip?: InputMaybe<Scalars['Int']>
  }

export type PagesPartnerBannerLinkingCollectionsTemplateCareersCollectionArgs =
  {
    limit?: InputMaybe<Scalars['Int']>
    locale?: InputMaybe<Scalars['String']>
    order?: InputMaybe<
      Array<
        InputMaybe<PagesPartnerBannerLinkingCollectionsTemplateCareersCollectionOrder>
      >
    >
    preview?: InputMaybe<Scalars['Boolean']>
    skip?: InputMaybe<Scalars['Int']>
  }

export type PagesPartnerBannerLinkingCollectionsTemplateContactUsCollectionArgs =
  {
    limit?: InputMaybe<Scalars['Int']>
    locale?: InputMaybe<Scalars['String']>
    order?: InputMaybe<
      Array<
        InputMaybe<PagesPartnerBannerLinkingCollectionsTemplateContactUsCollectionOrder>
      >
    >
    preview?: InputMaybe<Scalars['Boolean']>
    skip?: InputMaybe<Scalars['Int']>
  }

export type PagesPartnerBannerLinkingCollectionsTemplateDestinationsCollectionArgs =
  {
    limit?: InputMaybe<Scalars['Int']>
    locale?: InputMaybe<Scalars['String']>
    order?: InputMaybe<
      Array<
        InputMaybe<PagesPartnerBannerLinkingCollectionsTemplateDestinationsCollectionOrder>
      >
    >
    preview?: InputMaybe<Scalars['Boolean']>
    skip?: InputMaybe<Scalars['Int']>
  }

export type PagesPartnerBannerLinkingCollectionsTemplateForaReserveCollectionArgs =
  {
    limit?: InputMaybe<Scalars['Int']>
    locale?: InputMaybe<Scalars['String']>
    order?: InputMaybe<
      Array<
        InputMaybe<PagesPartnerBannerLinkingCollectionsTemplateForaReserveCollectionOrder>
      >
    >
    preview?: InputMaybe<Scalars['Boolean']>
    skip?: InputMaybe<Scalars['Int']>
  }

export type PagesPartnerBannerLinkingCollectionsTemplateForaXCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>
  locale?: InputMaybe<Scalars['String']>
  order?: InputMaybe<
    Array<
      InputMaybe<PagesPartnerBannerLinkingCollectionsTemplateForaXCollectionOrder>
    >
  >
  preview?: InputMaybe<Scalars['Boolean']>
  skip?: InputMaybe<Scalars['Int']>
}

export type PagesPartnerBannerLinkingCollectionsTemplateHomeCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>
  locale?: InputMaybe<Scalars['String']>
  order?: InputMaybe<
    Array<
      InputMaybe<PagesPartnerBannerLinkingCollectionsTemplateHomeCollectionOrder>
    >
  >
  preview?: InputMaybe<Scalars['Boolean']>
  skip?: InputMaybe<Scalars['Int']>
}

export type PagesPartnerBannerLinkingCollectionsTemplateJoinUsCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>
  locale?: InputMaybe<Scalars['String']>
  order?: InputMaybe<
    Array<
      InputMaybe<PagesPartnerBannerLinkingCollectionsTemplateJoinUsCollectionOrder>
    >
  >
  preview?: InputMaybe<Scalars['Boolean']>
  skip?: InputMaybe<Scalars['Int']>
}

export type PagesPartnerBannerLinkingCollectionsTemplateNewsletterCollectionArgs =
  {
    limit?: InputMaybe<Scalars['Int']>
    locale?: InputMaybe<Scalars['String']>
    order?: InputMaybe<
      Array<
        InputMaybe<PagesPartnerBannerLinkingCollectionsTemplateNewsletterCollectionOrder>
      >
    >
    preview?: InputMaybe<Scalars['Boolean']>
    skip?: InputMaybe<Scalars['Int']>
  }

export type PagesPartnerBannerLinkingCollectionsTemplatePageCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>
  locale?: InputMaybe<Scalars['String']>
  order?: InputMaybe<
    Array<
      InputMaybe<PagesPartnerBannerLinkingCollectionsTemplatePageCollectionOrder>
    >
  >
  preview?: InputMaybe<Scalars['Boolean']>
  skip?: InputMaybe<Scalars['Int']>
}

export type PagesPartnerBannerLinkingCollectionsTemplatePartnersCollectionArgs =
  {
    limit?: InputMaybe<Scalars['Int']>
    locale?: InputMaybe<Scalars['String']>
    order?: InputMaybe<
      Array<
        InputMaybe<PagesPartnerBannerLinkingCollectionsTemplatePartnersCollectionOrder>
      >
    >
    preview?: InputMaybe<Scalars['Boolean']>
    skip?: InputMaybe<Scalars['Int']>
  }

export type PagesPartnerBannerLinkingCollectionsTemplateReviewsCollectionArgs =
  {
    limit?: InputMaybe<Scalars['Int']>
    locale?: InputMaybe<Scalars['String']>
    order?: InputMaybe<
      Array<
        InputMaybe<PagesPartnerBannerLinkingCollectionsTemplateReviewsCollectionOrder>
      >
    >
    preview?: InputMaybe<Scalars['Boolean']>
    skip?: InputMaybe<Scalars['Int']>
  }

export type PagesPartnerBannerLinkingCollectionsTemplateWhyBookWithForaCollectionArgs =
  {
    limit?: InputMaybe<Scalars['Int']>
    locale?: InputMaybe<Scalars['String']>
    order?: InputMaybe<
      Array<
        InputMaybe<PagesPartnerBannerLinkingCollectionsTemplateWhyBookWithForaCollectionOrder>
      >
    >
    preview?: InputMaybe<Scalars['Boolean']>
    skip?: InputMaybe<Scalars['Int']>
  }

export enum PagesPartnerBannerLinkingCollectionsCampaignPagesCollectionOrder {
  CollapseFooterAsc = 'collapseFooter_ASC',
  CollapseFooterDesc = 'collapseFooter_DESC',
  CollapseHeaderAsc = 'collapseHeader_ASC',
  CollapseHeaderDesc = 'collapseHeader_DESC',
  HideFooterAsc = 'hideFooter_ASC',
  HideFooterDesc = 'hideFooter_DESC',
  HideGlobalBannerAsc = 'hideGlobalBanner_ASC',
  HideGlobalBannerDesc = 'hideGlobalBanner_DESC',
  JotformIdAsc = 'jotformId_ASC',
  JotformIdDesc = 'jotformId_DESC',
  NoindexNofollowAsc = 'noindexNofollow_ASC',
  NoindexNofollowDesc = 'noindexNofollow_DESC',
  ShowHeaderAsc = 'showHeader_ASC',
  ShowHeaderDesc = 'showHeader_DESC',
  SlugAsc = 'slug_ASC',
  SlugDesc = 'slug_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC',
}

export enum PagesPartnerBannerLinkingCollectionsTemplateAboutUsCollectionOrder {
  CollapseHeaderAsc = 'collapseHeader_ASC',
  CollapseHeaderDesc = 'collapseHeader_DESC',
  HeaderBlockTitleAsc = 'headerBlockTitle_ASC',
  HeaderBlockTitleDesc = 'headerBlockTitle_DESC',
  ShowHeaderAsc = 'showHeader_ASC',
  ShowHeaderDesc = 'showHeader_DESC',
  SubtitleAsc = 'subtitle_ASC',
  SubtitleDesc = 'subtitle_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC',
}

export enum PagesPartnerBannerLinkingCollectionsTemplateBookingCollectionOrder {
  CollapseHeaderAsc = 'collapseHeader_ASC',
  CollapseHeaderDesc = 'collapseHeader_DESC',
  ShowHeaderAsc = 'showHeader_ASC',
  ShowHeaderDesc = 'showHeader_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC',
}

export enum PagesPartnerBannerLinkingCollectionsTemplateCareersCollectionOrder {
  CollapseHeaderAsc = 'collapseHeader_ASC',
  CollapseHeaderDesc = 'collapseHeader_DESC',
  ShowHeaderAsc = 'showHeader_ASC',
  ShowHeaderDesc = 'showHeader_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC',
}

export enum PagesPartnerBannerLinkingCollectionsTemplateContactUsCollectionOrder {
  CollapseHeaderAsc = 'collapseHeader_ASC',
  CollapseHeaderDesc = 'collapseHeader_DESC',
  ShowHeaderAsc = 'showHeader_ASC',
  ShowHeaderDesc = 'showHeader_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC',
}

export enum PagesPartnerBannerLinkingCollectionsTemplateDestinationsCollectionOrder {
  CollapseHeaderAsc = 'collapseHeader_ASC',
  CollapseHeaderDesc = 'collapseHeader_DESC',
  CollapsibleHeadingAsc = 'collapsibleHeading_ASC',
  CollapsibleHeadingDesc = 'collapsibleHeading_DESC',
  ShowHeaderAsc = 'showHeader_ASC',
  ShowHeaderDesc = 'showHeader_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC',
}

export enum PagesPartnerBannerLinkingCollectionsTemplateForaReserveCollectionOrder {
  CollapseHeaderAsc = 'collapseHeader_ASC',
  CollapseHeaderDesc = 'collapseHeader_DESC',
  ShowHeaderAsc = 'showHeader_ASC',
  ShowHeaderDesc = 'showHeader_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC',
}

export enum PagesPartnerBannerLinkingCollectionsTemplateForaXCollectionOrder {
  AdvisorGridHeaderAsc = 'advisorGridHeader_ASC',
  AdvisorGridHeaderDesc = 'advisorGridHeader_DESC',
  AdvisorGridSubCopyAsc = 'advisorGridSubCopy_ASC',
  AdvisorGridSubCopyDesc = 'advisorGridSubCopy_DESC',
  CollapseHeaderAsc = 'collapseHeader_ASC',
  CollapseHeaderDesc = 'collapseHeader_DESC',
  ShowHeaderAsc = 'showHeader_ASC',
  ShowHeaderDesc = 'showHeader_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC',
}

export enum PagesPartnerBannerLinkingCollectionsTemplateHomeCollectionOrder {
  CollapseHeaderAsc = 'collapseHeader_ASC',
  CollapseHeaderDesc = 'collapseHeader_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC',
}

export enum PagesPartnerBannerLinkingCollectionsTemplateJoinUsCollectionOrder {
  CollapseHeaderAsc = 'collapseHeader_ASC',
  CollapseHeaderDesc = 'collapseHeader_DESC',
  HideGlobalBannerAsc = 'hideGlobalBanner_ASC',
  HideGlobalBannerDesc = 'hideGlobalBanner_DESC',
  ShowHeaderAsc = 'showHeader_ASC',
  ShowHeaderDesc = 'showHeader_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC',
}

export enum PagesPartnerBannerLinkingCollectionsTemplateNewsletterCollectionOrder {
  CollapseHeaderAsc = 'collapseHeader_ASC',
  CollapseHeaderDesc = 'collapseHeader_DESC',
  ShowHeaderAsc = 'showHeader_ASC',
  ShowHeaderDesc = 'showHeader_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC',
}

export enum PagesPartnerBannerLinkingCollectionsTemplatePageCollectionOrder {
  DirectoryAsc = 'directory_ASC',
  DirectoryDesc = 'directory_DESC',
  InternalLabelAsc = 'internalLabel_ASC',
  InternalLabelDesc = 'internalLabel_DESC',
  MetaTitleAsc = 'metaTitle_ASC',
  MetaTitleDesc = 'metaTitle_DESC',
  NoindexNofollowAsc = 'noindexNofollow_ASC',
  NoindexNofollowDesc = 'noindexNofollow_DESC',
  SlugAsc = 'slug_ASC',
  SlugDesc = 'slug_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
}

export enum PagesPartnerBannerLinkingCollectionsTemplatePartnersCollectionOrder {
  CollapseHeaderAsc = 'collapseHeader_ASC',
  CollapseHeaderDesc = 'collapseHeader_DESC',
  ShowHeaderAsc = 'showHeader_ASC',
  ShowHeaderDesc = 'showHeader_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC',
}

export enum PagesPartnerBannerLinkingCollectionsTemplateReviewsCollectionOrder {
  CollapseHeaderAsc = 'collapseHeader_ASC',
  CollapseHeaderDesc = 'collapseHeader_DESC',
  ShowHeaderAsc = 'showHeader_ASC',
  ShowHeaderDesc = 'showHeader_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC',
}

export enum PagesPartnerBannerLinkingCollectionsTemplateWhyBookWithForaCollectionOrder {
  CollapseHeaderAsc = 'collapseHeader_ASC',
  CollapseHeaderDesc = 'collapseHeader_DESC',
  ShowHeaderAsc = 'showHeader_ASC',
  ShowHeaderDesc = 'showHeader_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC',
}

export enum PagesPartnerBannerOrder {
  AdminLabelAsc = 'adminLabel_ASC',
  AdminLabelDesc = 'adminLabel_DESC',
  ShowViewMoreButtonAsc = 'showViewMoreButton_ASC',
  ShowViewMoreButtonDesc = 'showViewMoreButton_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
}

/** Create and manage Perks Lists. [See type definition](https://app.contentful.com/spaces/9cxgygtadmvl/content_types/pagesPerksList) */
export type PagesPerksList = Entry &
  _Node & {
    __typename?: 'PagesPerksList'
    _id: Scalars['ID']
    analyticsModule?: Maybe<SettingsAnalyticsModule>
    contentfulMetadata: ContentfulMetadata
    iconImage?: Maybe<Asset>
    image?: Maybe<Asset>
    linkedFrom?: Maybe<PagesPerksListLinkingCollections>
    listItems?: Maybe<Array<Maybe<Scalars['String']>>>
    sys: Sys
    title?: Maybe<Scalars['String']>
    titleStyle?: Maybe<Scalars['String']>
  }

/** Create and manage Perks Lists. [See type definition](https://app.contentful.com/spaces/9cxgygtadmvl/content_types/pagesPerksList) */
export type PagesPerksListAnalyticsModuleArgs = {
  locale?: InputMaybe<Scalars['String']>
  preview?: InputMaybe<Scalars['Boolean']>
  where?: InputMaybe<SettingsAnalyticsModuleFilter>
}

/** Create and manage Perks Lists. [See type definition](https://app.contentful.com/spaces/9cxgygtadmvl/content_types/pagesPerksList) */
export type PagesPerksListIconImageArgs = {
  locale?: InputMaybe<Scalars['String']>
  preview?: InputMaybe<Scalars['Boolean']>
}

/** Create and manage Perks Lists. [See type definition](https://app.contentful.com/spaces/9cxgygtadmvl/content_types/pagesPerksList) */
export type PagesPerksListImageArgs = {
  locale?: InputMaybe<Scalars['String']>
  preview?: InputMaybe<Scalars['Boolean']>
}

/** Create and manage Perks Lists. [See type definition](https://app.contentful.com/spaces/9cxgygtadmvl/content_types/pagesPerksList) */
export type PagesPerksListLinkedFromArgs = {
  allowedLocales?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
}

/** Create and manage Perks Lists. [See type definition](https://app.contentful.com/spaces/9cxgygtadmvl/content_types/pagesPerksList) */
export type PagesPerksListListItemsArgs = {
  locale?: InputMaybe<Scalars['String']>
}

/** Create and manage Perks Lists. [See type definition](https://app.contentful.com/spaces/9cxgygtadmvl/content_types/pagesPerksList) */
export type PagesPerksListTitleArgs = {
  locale?: InputMaybe<Scalars['String']>
}

/** Create and manage Perks Lists. [See type definition](https://app.contentful.com/spaces/9cxgygtadmvl/content_types/pagesPerksList) */
export type PagesPerksListTitleStyleArgs = {
  locale?: InputMaybe<Scalars['String']>
}

export type PagesPerksListCollection = {
  __typename?: 'PagesPerksListCollection'
  items: Array<Maybe<PagesPerksList>>
  limit: Scalars['Int']
  skip: Scalars['Int']
  total: Scalars['Int']
}

export type PagesPerksListFilter = {
  AND?: InputMaybe<Array<InputMaybe<PagesPerksListFilter>>>
  OR?: InputMaybe<Array<InputMaybe<PagesPerksListFilter>>>
  analyticsModule?: InputMaybe<CfSettingsAnalyticsModuleNestedFilter>
  analyticsModule_exists?: InputMaybe<Scalars['Boolean']>
  contentfulMetadata?: InputMaybe<ContentfulMetadataFilter>
  iconImage_exists?: InputMaybe<Scalars['Boolean']>
  image_exists?: InputMaybe<Scalars['Boolean']>
  listItems_contains_all?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  listItems_contains_none?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  listItems_contains_some?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  listItems_exists?: InputMaybe<Scalars['Boolean']>
  sys?: InputMaybe<SysFilter>
  title?: InputMaybe<Scalars['String']>
  titleStyle?: InputMaybe<Scalars['String']>
  titleStyle_contains?: InputMaybe<Scalars['String']>
  titleStyle_exists?: InputMaybe<Scalars['Boolean']>
  titleStyle_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  titleStyle_not?: InputMaybe<Scalars['String']>
  titleStyle_not_contains?: InputMaybe<Scalars['String']>
  titleStyle_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  title_contains?: InputMaybe<Scalars['String']>
  title_exists?: InputMaybe<Scalars['Boolean']>
  title_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  title_not?: InputMaybe<Scalars['String']>
  title_not_contains?: InputMaybe<Scalars['String']>
  title_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
}

export type PagesPerksListLinkingCollections = {
  __typename?: 'PagesPerksListLinkingCollections'
  campaignPagesCollection?: Maybe<CampaignPagesCollection>
  entryCollection?: Maybe<EntryCollection>
  templateAboutUsCollection?: Maybe<TemplateAboutUsCollection>
  templateBookingCollection?: Maybe<TemplateBookingCollection>
  templateCareersCollection?: Maybe<TemplateCareersCollection>
  templateContactUsCollection?: Maybe<TemplateContactUsCollection>
  templateDestinationsCollection?: Maybe<TemplateDestinationsCollection>
  templateForaReserveCollection?: Maybe<TemplateForaReserveCollection>
  templateForaXCollection?: Maybe<TemplateForaXCollection>
  templateHomeCollection?: Maybe<TemplateHomeCollection>
  templateJoinUsCollection?: Maybe<TemplateJoinUsCollection>
  templateNewsletterCollection?: Maybe<TemplateNewsletterCollection>
  templatePageCollection?: Maybe<TemplatePageCollection>
  templatePartnersCollection?: Maybe<TemplatePartnersCollection>
  templateReviewsCollection?: Maybe<TemplateReviewsCollection>
  templateWhyBookWithForaCollection?: Maybe<TemplateWhyBookWithForaCollection>
}

export type PagesPerksListLinkingCollectionsCampaignPagesCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>
  locale?: InputMaybe<Scalars['String']>
  order?: InputMaybe<
    Array<
      InputMaybe<PagesPerksListLinkingCollectionsCampaignPagesCollectionOrder>
    >
  >
  preview?: InputMaybe<Scalars['Boolean']>
  skip?: InputMaybe<Scalars['Int']>
}

export type PagesPerksListLinkingCollectionsEntryCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>
  locale?: InputMaybe<Scalars['String']>
  preview?: InputMaybe<Scalars['Boolean']>
  skip?: InputMaybe<Scalars['Int']>
}

export type PagesPerksListLinkingCollectionsTemplateAboutUsCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>
  locale?: InputMaybe<Scalars['String']>
  order?: InputMaybe<
    Array<
      InputMaybe<PagesPerksListLinkingCollectionsTemplateAboutUsCollectionOrder>
    >
  >
  preview?: InputMaybe<Scalars['Boolean']>
  skip?: InputMaybe<Scalars['Int']>
}

export type PagesPerksListLinkingCollectionsTemplateBookingCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>
  locale?: InputMaybe<Scalars['String']>
  order?: InputMaybe<
    Array<
      InputMaybe<PagesPerksListLinkingCollectionsTemplateBookingCollectionOrder>
    >
  >
  preview?: InputMaybe<Scalars['Boolean']>
  skip?: InputMaybe<Scalars['Int']>
}

export type PagesPerksListLinkingCollectionsTemplateCareersCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>
  locale?: InputMaybe<Scalars['String']>
  order?: InputMaybe<
    Array<
      InputMaybe<PagesPerksListLinkingCollectionsTemplateCareersCollectionOrder>
    >
  >
  preview?: InputMaybe<Scalars['Boolean']>
  skip?: InputMaybe<Scalars['Int']>
}

export type PagesPerksListLinkingCollectionsTemplateContactUsCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>
  locale?: InputMaybe<Scalars['String']>
  order?: InputMaybe<
    Array<
      InputMaybe<PagesPerksListLinkingCollectionsTemplateContactUsCollectionOrder>
    >
  >
  preview?: InputMaybe<Scalars['Boolean']>
  skip?: InputMaybe<Scalars['Int']>
}

export type PagesPerksListLinkingCollectionsTemplateDestinationsCollectionArgs =
  {
    limit?: InputMaybe<Scalars['Int']>
    locale?: InputMaybe<Scalars['String']>
    order?: InputMaybe<
      Array<
        InputMaybe<PagesPerksListLinkingCollectionsTemplateDestinationsCollectionOrder>
      >
    >
    preview?: InputMaybe<Scalars['Boolean']>
    skip?: InputMaybe<Scalars['Int']>
  }

export type PagesPerksListLinkingCollectionsTemplateForaReserveCollectionArgs =
  {
    limit?: InputMaybe<Scalars['Int']>
    locale?: InputMaybe<Scalars['String']>
    order?: InputMaybe<
      Array<
        InputMaybe<PagesPerksListLinkingCollectionsTemplateForaReserveCollectionOrder>
      >
    >
    preview?: InputMaybe<Scalars['Boolean']>
    skip?: InputMaybe<Scalars['Int']>
  }

export type PagesPerksListLinkingCollectionsTemplateForaXCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>
  locale?: InputMaybe<Scalars['String']>
  order?: InputMaybe<
    Array<
      InputMaybe<PagesPerksListLinkingCollectionsTemplateForaXCollectionOrder>
    >
  >
  preview?: InputMaybe<Scalars['Boolean']>
  skip?: InputMaybe<Scalars['Int']>
}

export type PagesPerksListLinkingCollectionsTemplateHomeCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>
  locale?: InputMaybe<Scalars['String']>
  order?: InputMaybe<
    Array<
      InputMaybe<PagesPerksListLinkingCollectionsTemplateHomeCollectionOrder>
    >
  >
  preview?: InputMaybe<Scalars['Boolean']>
  skip?: InputMaybe<Scalars['Int']>
}

export type PagesPerksListLinkingCollectionsTemplateJoinUsCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>
  locale?: InputMaybe<Scalars['String']>
  order?: InputMaybe<
    Array<
      InputMaybe<PagesPerksListLinkingCollectionsTemplateJoinUsCollectionOrder>
    >
  >
  preview?: InputMaybe<Scalars['Boolean']>
  skip?: InputMaybe<Scalars['Int']>
}

export type PagesPerksListLinkingCollectionsTemplateNewsletterCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>
  locale?: InputMaybe<Scalars['String']>
  order?: InputMaybe<
    Array<
      InputMaybe<PagesPerksListLinkingCollectionsTemplateNewsletterCollectionOrder>
    >
  >
  preview?: InputMaybe<Scalars['Boolean']>
  skip?: InputMaybe<Scalars['Int']>
}

export type PagesPerksListLinkingCollectionsTemplatePageCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>
  locale?: InputMaybe<Scalars['String']>
  order?: InputMaybe<
    Array<
      InputMaybe<PagesPerksListLinkingCollectionsTemplatePageCollectionOrder>
    >
  >
  preview?: InputMaybe<Scalars['Boolean']>
  skip?: InputMaybe<Scalars['Int']>
}

export type PagesPerksListLinkingCollectionsTemplatePartnersCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>
  locale?: InputMaybe<Scalars['String']>
  order?: InputMaybe<
    Array<
      InputMaybe<PagesPerksListLinkingCollectionsTemplatePartnersCollectionOrder>
    >
  >
  preview?: InputMaybe<Scalars['Boolean']>
  skip?: InputMaybe<Scalars['Int']>
}

export type PagesPerksListLinkingCollectionsTemplateReviewsCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>
  locale?: InputMaybe<Scalars['String']>
  order?: InputMaybe<
    Array<
      InputMaybe<PagesPerksListLinkingCollectionsTemplateReviewsCollectionOrder>
    >
  >
  preview?: InputMaybe<Scalars['Boolean']>
  skip?: InputMaybe<Scalars['Int']>
}

export type PagesPerksListLinkingCollectionsTemplateWhyBookWithForaCollectionArgs =
  {
    limit?: InputMaybe<Scalars['Int']>
    locale?: InputMaybe<Scalars['String']>
    order?: InputMaybe<
      Array<
        InputMaybe<PagesPerksListLinkingCollectionsTemplateWhyBookWithForaCollectionOrder>
      >
    >
    preview?: InputMaybe<Scalars['Boolean']>
    skip?: InputMaybe<Scalars['Int']>
  }

export enum PagesPerksListLinkingCollectionsCampaignPagesCollectionOrder {
  CollapseFooterAsc = 'collapseFooter_ASC',
  CollapseFooterDesc = 'collapseFooter_DESC',
  CollapseHeaderAsc = 'collapseHeader_ASC',
  CollapseHeaderDesc = 'collapseHeader_DESC',
  HideFooterAsc = 'hideFooter_ASC',
  HideFooterDesc = 'hideFooter_DESC',
  HideGlobalBannerAsc = 'hideGlobalBanner_ASC',
  HideGlobalBannerDesc = 'hideGlobalBanner_DESC',
  JotformIdAsc = 'jotformId_ASC',
  JotformIdDesc = 'jotformId_DESC',
  NoindexNofollowAsc = 'noindexNofollow_ASC',
  NoindexNofollowDesc = 'noindexNofollow_DESC',
  ShowHeaderAsc = 'showHeader_ASC',
  ShowHeaderDesc = 'showHeader_DESC',
  SlugAsc = 'slug_ASC',
  SlugDesc = 'slug_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC',
}

export enum PagesPerksListLinkingCollectionsTemplateAboutUsCollectionOrder {
  CollapseHeaderAsc = 'collapseHeader_ASC',
  CollapseHeaderDesc = 'collapseHeader_DESC',
  HeaderBlockTitleAsc = 'headerBlockTitle_ASC',
  HeaderBlockTitleDesc = 'headerBlockTitle_DESC',
  ShowHeaderAsc = 'showHeader_ASC',
  ShowHeaderDesc = 'showHeader_DESC',
  SubtitleAsc = 'subtitle_ASC',
  SubtitleDesc = 'subtitle_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC',
}

export enum PagesPerksListLinkingCollectionsTemplateBookingCollectionOrder {
  CollapseHeaderAsc = 'collapseHeader_ASC',
  CollapseHeaderDesc = 'collapseHeader_DESC',
  ShowHeaderAsc = 'showHeader_ASC',
  ShowHeaderDesc = 'showHeader_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC',
}

export enum PagesPerksListLinkingCollectionsTemplateCareersCollectionOrder {
  CollapseHeaderAsc = 'collapseHeader_ASC',
  CollapseHeaderDesc = 'collapseHeader_DESC',
  ShowHeaderAsc = 'showHeader_ASC',
  ShowHeaderDesc = 'showHeader_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC',
}

export enum PagesPerksListLinkingCollectionsTemplateContactUsCollectionOrder {
  CollapseHeaderAsc = 'collapseHeader_ASC',
  CollapseHeaderDesc = 'collapseHeader_DESC',
  ShowHeaderAsc = 'showHeader_ASC',
  ShowHeaderDesc = 'showHeader_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC',
}

export enum PagesPerksListLinkingCollectionsTemplateDestinationsCollectionOrder {
  CollapseHeaderAsc = 'collapseHeader_ASC',
  CollapseHeaderDesc = 'collapseHeader_DESC',
  CollapsibleHeadingAsc = 'collapsibleHeading_ASC',
  CollapsibleHeadingDesc = 'collapsibleHeading_DESC',
  ShowHeaderAsc = 'showHeader_ASC',
  ShowHeaderDesc = 'showHeader_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC',
}

export enum PagesPerksListLinkingCollectionsTemplateForaReserveCollectionOrder {
  CollapseHeaderAsc = 'collapseHeader_ASC',
  CollapseHeaderDesc = 'collapseHeader_DESC',
  ShowHeaderAsc = 'showHeader_ASC',
  ShowHeaderDesc = 'showHeader_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC',
}

export enum PagesPerksListLinkingCollectionsTemplateForaXCollectionOrder {
  AdvisorGridHeaderAsc = 'advisorGridHeader_ASC',
  AdvisorGridHeaderDesc = 'advisorGridHeader_DESC',
  AdvisorGridSubCopyAsc = 'advisorGridSubCopy_ASC',
  AdvisorGridSubCopyDesc = 'advisorGridSubCopy_DESC',
  CollapseHeaderAsc = 'collapseHeader_ASC',
  CollapseHeaderDesc = 'collapseHeader_DESC',
  ShowHeaderAsc = 'showHeader_ASC',
  ShowHeaderDesc = 'showHeader_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC',
}

export enum PagesPerksListLinkingCollectionsTemplateHomeCollectionOrder {
  CollapseHeaderAsc = 'collapseHeader_ASC',
  CollapseHeaderDesc = 'collapseHeader_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC',
}

export enum PagesPerksListLinkingCollectionsTemplateJoinUsCollectionOrder {
  CollapseHeaderAsc = 'collapseHeader_ASC',
  CollapseHeaderDesc = 'collapseHeader_DESC',
  HideGlobalBannerAsc = 'hideGlobalBanner_ASC',
  HideGlobalBannerDesc = 'hideGlobalBanner_DESC',
  ShowHeaderAsc = 'showHeader_ASC',
  ShowHeaderDesc = 'showHeader_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC',
}

export enum PagesPerksListLinkingCollectionsTemplateNewsletterCollectionOrder {
  CollapseHeaderAsc = 'collapseHeader_ASC',
  CollapseHeaderDesc = 'collapseHeader_DESC',
  ShowHeaderAsc = 'showHeader_ASC',
  ShowHeaderDesc = 'showHeader_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC',
}

export enum PagesPerksListLinkingCollectionsTemplatePageCollectionOrder {
  DirectoryAsc = 'directory_ASC',
  DirectoryDesc = 'directory_DESC',
  InternalLabelAsc = 'internalLabel_ASC',
  InternalLabelDesc = 'internalLabel_DESC',
  MetaTitleAsc = 'metaTitle_ASC',
  MetaTitleDesc = 'metaTitle_DESC',
  NoindexNofollowAsc = 'noindexNofollow_ASC',
  NoindexNofollowDesc = 'noindexNofollow_DESC',
  SlugAsc = 'slug_ASC',
  SlugDesc = 'slug_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
}

export enum PagesPerksListLinkingCollectionsTemplatePartnersCollectionOrder {
  CollapseHeaderAsc = 'collapseHeader_ASC',
  CollapseHeaderDesc = 'collapseHeader_DESC',
  ShowHeaderAsc = 'showHeader_ASC',
  ShowHeaderDesc = 'showHeader_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC',
}

export enum PagesPerksListLinkingCollectionsTemplateReviewsCollectionOrder {
  CollapseHeaderAsc = 'collapseHeader_ASC',
  CollapseHeaderDesc = 'collapseHeader_DESC',
  ShowHeaderAsc = 'showHeader_ASC',
  ShowHeaderDesc = 'showHeader_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC',
}

export enum PagesPerksListLinkingCollectionsTemplateWhyBookWithForaCollectionOrder {
  CollapseHeaderAsc = 'collapseHeader_ASC',
  CollapseHeaderDesc = 'collapseHeader_DESC',
  ShowHeaderAsc = 'showHeader_ASC',
  ShowHeaderDesc = 'showHeader_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC',
}

export enum PagesPerksListOrder {
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  TitleStyleAsc = 'titleStyle_ASC',
  TitleStyleDesc = 'titleStyle_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC',
}

/** A page section for showing a banner of logo images. On the website, this section always links to the /press page. [See type definition](https://app.contentful.com/spaces/9cxgygtadmvl/content_types/pagesPressBanner) */
export type PagesPressBanner = Entry &
  _Node & {
    __typename?: 'PagesPressBanner'
    _id: Scalars['ID']
    adminLabel?: Maybe<Scalars['String']>
    analyticsModule?: Maybe<SettingsAnalyticsModule>
    applyBackground?: Maybe<Scalars['Boolean']>
    contentfulMetadata: ContentfulMetadata
    linkedFrom?: Maybe<PagesPressBannerLinkingCollections>
    logoSize?: Maybe<Scalars['Boolean']>
    logosCollection?: Maybe<AssetCollection>
    redirectSlug?: Maybe<Scalars['String']>
    sys: Sys
    title?: Maybe<Scalars['String']>
  }

/** A page section for showing a banner of logo images. On the website, this section always links to the /press page. [See type definition](https://app.contentful.com/spaces/9cxgygtadmvl/content_types/pagesPressBanner) */
export type PagesPressBannerAdminLabelArgs = {
  locale?: InputMaybe<Scalars['String']>
}

/** A page section for showing a banner of logo images. On the website, this section always links to the /press page. [See type definition](https://app.contentful.com/spaces/9cxgygtadmvl/content_types/pagesPressBanner) */
export type PagesPressBannerAnalyticsModuleArgs = {
  locale?: InputMaybe<Scalars['String']>
  preview?: InputMaybe<Scalars['Boolean']>
  where?: InputMaybe<SettingsAnalyticsModuleFilter>
}

/** A page section for showing a banner of logo images. On the website, this section always links to the /press page. [See type definition](https://app.contentful.com/spaces/9cxgygtadmvl/content_types/pagesPressBanner) */
export type PagesPressBannerApplyBackgroundArgs = {
  locale?: InputMaybe<Scalars['String']>
}

/** A page section for showing a banner of logo images. On the website, this section always links to the /press page. [See type definition](https://app.contentful.com/spaces/9cxgygtadmvl/content_types/pagesPressBanner) */
export type PagesPressBannerLinkedFromArgs = {
  allowedLocales?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
}

/** A page section for showing a banner of logo images. On the website, this section always links to the /press page. [See type definition](https://app.contentful.com/spaces/9cxgygtadmvl/content_types/pagesPressBanner) */
export type PagesPressBannerLogoSizeArgs = {
  locale?: InputMaybe<Scalars['String']>
}

/** A page section for showing a banner of logo images. On the website, this section always links to the /press page. [See type definition](https://app.contentful.com/spaces/9cxgygtadmvl/content_types/pagesPressBanner) */
export type PagesPressBannerLogosCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>
  locale?: InputMaybe<Scalars['String']>
  preview?: InputMaybe<Scalars['Boolean']>
  skip?: InputMaybe<Scalars['Int']>
}

/** A page section for showing a banner of logo images. On the website, this section always links to the /press page. [See type definition](https://app.contentful.com/spaces/9cxgygtadmvl/content_types/pagesPressBanner) */
export type PagesPressBannerRedirectSlugArgs = {
  locale?: InputMaybe<Scalars['String']>
}

/** A page section for showing a banner of logo images. On the website, this section always links to the /press page. [See type definition](https://app.contentful.com/spaces/9cxgygtadmvl/content_types/pagesPressBanner) */
export type PagesPressBannerTitleArgs = {
  locale?: InputMaybe<Scalars['String']>
}

export type PagesPressBannerCollection = {
  __typename?: 'PagesPressBannerCollection'
  items: Array<Maybe<PagesPressBanner>>
  limit: Scalars['Int']
  skip: Scalars['Int']
  total: Scalars['Int']
}

export type PagesPressBannerFilter = {
  AND?: InputMaybe<Array<InputMaybe<PagesPressBannerFilter>>>
  OR?: InputMaybe<Array<InputMaybe<PagesPressBannerFilter>>>
  adminLabel?: InputMaybe<Scalars['String']>
  adminLabel_contains?: InputMaybe<Scalars['String']>
  adminLabel_exists?: InputMaybe<Scalars['Boolean']>
  adminLabel_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  adminLabel_not?: InputMaybe<Scalars['String']>
  adminLabel_not_contains?: InputMaybe<Scalars['String']>
  adminLabel_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  analyticsModule?: InputMaybe<CfSettingsAnalyticsModuleNestedFilter>
  analyticsModule_exists?: InputMaybe<Scalars['Boolean']>
  applyBackground?: InputMaybe<Scalars['Boolean']>
  applyBackground_exists?: InputMaybe<Scalars['Boolean']>
  applyBackground_not?: InputMaybe<Scalars['Boolean']>
  contentfulMetadata?: InputMaybe<ContentfulMetadataFilter>
  logoSize?: InputMaybe<Scalars['Boolean']>
  logoSize_exists?: InputMaybe<Scalars['Boolean']>
  logoSize_not?: InputMaybe<Scalars['Boolean']>
  logosCollection_exists?: InputMaybe<Scalars['Boolean']>
  redirectSlug?: InputMaybe<Scalars['String']>
  redirectSlug_contains?: InputMaybe<Scalars['String']>
  redirectSlug_exists?: InputMaybe<Scalars['Boolean']>
  redirectSlug_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  redirectSlug_not?: InputMaybe<Scalars['String']>
  redirectSlug_not_contains?: InputMaybe<Scalars['String']>
  redirectSlug_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  sys?: InputMaybe<SysFilter>
  title?: InputMaybe<Scalars['String']>
  title_contains?: InputMaybe<Scalars['String']>
  title_exists?: InputMaybe<Scalars['Boolean']>
  title_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  title_not?: InputMaybe<Scalars['String']>
  title_not_contains?: InputMaybe<Scalars['String']>
  title_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
}

export type PagesPressBannerLinkingCollections = {
  __typename?: 'PagesPressBannerLinkingCollections'
  campaignPagesCollection?: Maybe<CampaignPagesCollection>
  entryCollection?: Maybe<EntryCollection>
  templateAboutUsCollection?: Maybe<TemplateAboutUsCollection>
  templateBookingCollection?: Maybe<TemplateBookingCollection>
  templateCareersCollection?: Maybe<TemplateCareersCollection>
  templateContactUsCollection?: Maybe<TemplateContactUsCollection>
  templateDestinationsCollection?: Maybe<TemplateDestinationsCollection>
  templateForaReserveCollection?: Maybe<TemplateForaReserveCollection>
  templateForaXCollection?: Maybe<TemplateForaXCollection>
  templateHomeCollection?: Maybe<TemplateHomeCollection>
  templateJoinUsCollection?: Maybe<TemplateJoinUsCollection>
  templateNewsletterCollection?: Maybe<TemplateNewsletterCollection>
  templatePageCollection?: Maybe<TemplatePageCollection>
  templatePartnersCollection?: Maybe<TemplatePartnersCollection>
  templatePressCollection?: Maybe<TemplatePressCollection>
  templateReviewsCollection?: Maybe<TemplateReviewsCollection>
  templateWhyBookWithForaCollection?: Maybe<TemplateWhyBookWithForaCollection>
}

export type PagesPressBannerLinkingCollectionsCampaignPagesCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>
  locale?: InputMaybe<Scalars['String']>
  order?: InputMaybe<
    Array<
      InputMaybe<PagesPressBannerLinkingCollectionsCampaignPagesCollectionOrder>
    >
  >
  preview?: InputMaybe<Scalars['Boolean']>
  skip?: InputMaybe<Scalars['Int']>
}

export type PagesPressBannerLinkingCollectionsEntryCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>
  locale?: InputMaybe<Scalars['String']>
  preview?: InputMaybe<Scalars['Boolean']>
  skip?: InputMaybe<Scalars['Int']>
}

export type PagesPressBannerLinkingCollectionsTemplateAboutUsCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>
  locale?: InputMaybe<Scalars['String']>
  order?: InputMaybe<
    Array<
      InputMaybe<PagesPressBannerLinkingCollectionsTemplateAboutUsCollectionOrder>
    >
  >
  preview?: InputMaybe<Scalars['Boolean']>
  skip?: InputMaybe<Scalars['Int']>
}

export type PagesPressBannerLinkingCollectionsTemplateBookingCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>
  locale?: InputMaybe<Scalars['String']>
  order?: InputMaybe<
    Array<
      InputMaybe<PagesPressBannerLinkingCollectionsTemplateBookingCollectionOrder>
    >
  >
  preview?: InputMaybe<Scalars['Boolean']>
  skip?: InputMaybe<Scalars['Int']>
}

export type PagesPressBannerLinkingCollectionsTemplateCareersCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>
  locale?: InputMaybe<Scalars['String']>
  order?: InputMaybe<
    Array<
      InputMaybe<PagesPressBannerLinkingCollectionsTemplateCareersCollectionOrder>
    >
  >
  preview?: InputMaybe<Scalars['Boolean']>
  skip?: InputMaybe<Scalars['Int']>
}

export type PagesPressBannerLinkingCollectionsTemplateContactUsCollectionArgs =
  {
    limit?: InputMaybe<Scalars['Int']>
    locale?: InputMaybe<Scalars['String']>
    order?: InputMaybe<
      Array<
        InputMaybe<PagesPressBannerLinkingCollectionsTemplateContactUsCollectionOrder>
      >
    >
    preview?: InputMaybe<Scalars['Boolean']>
    skip?: InputMaybe<Scalars['Int']>
  }

export type PagesPressBannerLinkingCollectionsTemplateDestinationsCollectionArgs =
  {
    limit?: InputMaybe<Scalars['Int']>
    locale?: InputMaybe<Scalars['String']>
    order?: InputMaybe<
      Array<
        InputMaybe<PagesPressBannerLinkingCollectionsTemplateDestinationsCollectionOrder>
      >
    >
    preview?: InputMaybe<Scalars['Boolean']>
    skip?: InputMaybe<Scalars['Int']>
  }

export type PagesPressBannerLinkingCollectionsTemplateForaReserveCollectionArgs =
  {
    limit?: InputMaybe<Scalars['Int']>
    locale?: InputMaybe<Scalars['String']>
    order?: InputMaybe<
      Array<
        InputMaybe<PagesPressBannerLinkingCollectionsTemplateForaReserveCollectionOrder>
      >
    >
    preview?: InputMaybe<Scalars['Boolean']>
    skip?: InputMaybe<Scalars['Int']>
  }

export type PagesPressBannerLinkingCollectionsTemplateForaXCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>
  locale?: InputMaybe<Scalars['String']>
  order?: InputMaybe<
    Array<
      InputMaybe<PagesPressBannerLinkingCollectionsTemplateForaXCollectionOrder>
    >
  >
  preview?: InputMaybe<Scalars['Boolean']>
  skip?: InputMaybe<Scalars['Int']>
}

export type PagesPressBannerLinkingCollectionsTemplateHomeCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>
  locale?: InputMaybe<Scalars['String']>
  order?: InputMaybe<
    Array<
      InputMaybe<PagesPressBannerLinkingCollectionsTemplateHomeCollectionOrder>
    >
  >
  preview?: InputMaybe<Scalars['Boolean']>
  skip?: InputMaybe<Scalars['Int']>
}

export type PagesPressBannerLinkingCollectionsTemplateJoinUsCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>
  locale?: InputMaybe<Scalars['String']>
  order?: InputMaybe<
    Array<
      InputMaybe<PagesPressBannerLinkingCollectionsTemplateJoinUsCollectionOrder>
    >
  >
  preview?: InputMaybe<Scalars['Boolean']>
  skip?: InputMaybe<Scalars['Int']>
}

export type PagesPressBannerLinkingCollectionsTemplateNewsletterCollectionArgs =
  {
    limit?: InputMaybe<Scalars['Int']>
    locale?: InputMaybe<Scalars['String']>
    order?: InputMaybe<
      Array<
        InputMaybe<PagesPressBannerLinkingCollectionsTemplateNewsletterCollectionOrder>
      >
    >
    preview?: InputMaybe<Scalars['Boolean']>
    skip?: InputMaybe<Scalars['Int']>
  }

export type PagesPressBannerLinkingCollectionsTemplatePageCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>
  locale?: InputMaybe<Scalars['String']>
  order?: InputMaybe<
    Array<
      InputMaybe<PagesPressBannerLinkingCollectionsTemplatePageCollectionOrder>
    >
  >
  preview?: InputMaybe<Scalars['Boolean']>
  skip?: InputMaybe<Scalars['Int']>
}

export type PagesPressBannerLinkingCollectionsTemplatePartnersCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>
  locale?: InputMaybe<Scalars['String']>
  order?: InputMaybe<
    Array<
      InputMaybe<PagesPressBannerLinkingCollectionsTemplatePartnersCollectionOrder>
    >
  >
  preview?: InputMaybe<Scalars['Boolean']>
  skip?: InputMaybe<Scalars['Int']>
}

export type PagesPressBannerLinkingCollectionsTemplatePressCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>
  locale?: InputMaybe<Scalars['String']>
  order?: InputMaybe<
    Array<
      InputMaybe<PagesPressBannerLinkingCollectionsTemplatePressCollectionOrder>
    >
  >
  preview?: InputMaybe<Scalars['Boolean']>
  skip?: InputMaybe<Scalars['Int']>
}

export type PagesPressBannerLinkingCollectionsTemplateReviewsCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>
  locale?: InputMaybe<Scalars['String']>
  order?: InputMaybe<
    Array<
      InputMaybe<PagesPressBannerLinkingCollectionsTemplateReviewsCollectionOrder>
    >
  >
  preview?: InputMaybe<Scalars['Boolean']>
  skip?: InputMaybe<Scalars['Int']>
}

export type PagesPressBannerLinkingCollectionsTemplateWhyBookWithForaCollectionArgs =
  {
    limit?: InputMaybe<Scalars['Int']>
    locale?: InputMaybe<Scalars['String']>
    order?: InputMaybe<
      Array<
        InputMaybe<PagesPressBannerLinkingCollectionsTemplateWhyBookWithForaCollectionOrder>
      >
    >
    preview?: InputMaybe<Scalars['Boolean']>
    skip?: InputMaybe<Scalars['Int']>
  }

export enum PagesPressBannerLinkingCollectionsCampaignPagesCollectionOrder {
  CollapseFooterAsc = 'collapseFooter_ASC',
  CollapseFooterDesc = 'collapseFooter_DESC',
  CollapseHeaderAsc = 'collapseHeader_ASC',
  CollapseHeaderDesc = 'collapseHeader_DESC',
  HideFooterAsc = 'hideFooter_ASC',
  HideFooterDesc = 'hideFooter_DESC',
  HideGlobalBannerAsc = 'hideGlobalBanner_ASC',
  HideGlobalBannerDesc = 'hideGlobalBanner_DESC',
  JotformIdAsc = 'jotformId_ASC',
  JotformIdDesc = 'jotformId_DESC',
  NoindexNofollowAsc = 'noindexNofollow_ASC',
  NoindexNofollowDesc = 'noindexNofollow_DESC',
  ShowHeaderAsc = 'showHeader_ASC',
  ShowHeaderDesc = 'showHeader_DESC',
  SlugAsc = 'slug_ASC',
  SlugDesc = 'slug_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC',
}

export enum PagesPressBannerLinkingCollectionsTemplateAboutUsCollectionOrder {
  CollapseHeaderAsc = 'collapseHeader_ASC',
  CollapseHeaderDesc = 'collapseHeader_DESC',
  HeaderBlockTitleAsc = 'headerBlockTitle_ASC',
  HeaderBlockTitleDesc = 'headerBlockTitle_DESC',
  ShowHeaderAsc = 'showHeader_ASC',
  ShowHeaderDesc = 'showHeader_DESC',
  SubtitleAsc = 'subtitle_ASC',
  SubtitleDesc = 'subtitle_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC',
}

export enum PagesPressBannerLinkingCollectionsTemplateBookingCollectionOrder {
  CollapseHeaderAsc = 'collapseHeader_ASC',
  CollapseHeaderDesc = 'collapseHeader_DESC',
  ShowHeaderAsc = 'showHeader_ASC',
  ShowHeaderDesc = 'showHeader_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC',
}

export enum PagesPressBannerLinkingCollectionsTemplateCareersCollectionOrder {
  CollapseHeaderAsc = 'collapseHeader_ASC',
  CollapseHeaderDesc = 'collapseHeader_DESC',
  ShowHeaderAsc = 'showHeader_ASC',
  ShowHeaderDesc = 'showHeader_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC',
}

export enum PagesPressBannerLinkingCollectionsTemplateContactUsCollectionOrder {
  CollapseHeaderAsc = 'collapseHeader_ASC',
  CollapseHeaderDesc = 'collapseHeader_DESC',
  ShowHeaderAsc = 'showHeader_ASC',
  ShowHeaderDesc = 'showHeader_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC',
}

export enum PagesPressBannerLinkingCollectionsTemplateDestinationsCollectionOrder {
  CollapseHeaderAsc = 'collapseHeader_ASC',
  CollapseHeaderDesc = 'collapseHeader_DESC',
  CollapsibleHeadingAsc = 'collapsibleHeading_ASC',
  CollapsibleHeadingDesc = 'collapsibleHeading_DESC',
  ShowHeaderAsc = 'showHeader_ASC',
  ShowHeaderDesc = 'showHeader_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC',
}

export enum PagesPressBannerLinkingCollectionsTemplateForaReserveCollectionOrder {
  CollapseHeaderAsc = 'collapseHeader_ASC',
  CollapseHeaderDesc = 'collapseHeader_DESC',
  ShowHeaderAsc = 'showHeader_ASC',
  ShowHeaderDesc = 'showHeader_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC',
}

export enum PagesPressBannerLinkingCollectionsTemplateForaXCollectionOrder {
  AdvisorGridHeaderAsc = 'advisorGridHeader_ASC',
  AdvisorGridHeaderDesc = 'advisorGridHeader_DESC',
  AdvisorGridSubCopyAsc = 'advisorGridSubCopy_ASC',
  AdvisorGridSubCopyDesc = 'advisorGridSubCopy_DESC',
  CollapseHeaderAsc = 'collapseHeader_ASC',
  CollapseHeaderDesc = 'collapseHeader_DESC',
  ShowHeaderAsc = 'showHeader_ASC',
  ShowHeaderDesc = 'showHeader_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC',
}

export enum PagesPressBannerLinkingCollectionsTemplateHomeCollectionOrder {
  CollapseHeaderAsc = 'collapseHeader_ASC',
  CollapseHeaderDesc = 'collapseHeader_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC',
}

export enum PagesPressBannerLinkingCollectionsTemplateJoinUsCollectionOrder {
  CollapseHeaderAsc = 'collapseHeader_ASC',
  CollapseHeaderDesc = 'collapseHeader_DESC',
  HideGlobalBannerAsc = 'hideGlobalBanner_ASC',
  HideGlobalBannerDesc = 'hideGlobalBanner_DESC',
  ShowHeaderAsc = 'showHeader_ASC',
  ShowHeaderDesc = 'showHeader_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC',
}

export enum PagesPressBannerLinkingCollectionsTemplateNewsletterCollectionOrder {
  CollapseHeaderAsc = 'collapseHeader_ASC',
  CollapseHeaderDesc = 'collapseHeader_DESC',
  ShowHeaderAsc = 'showHeader_ASC',
  ShowHeaderDesc = 'showHeader_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC',
}

export enum PagesPressBannerLinkingCollectionsTemplatePageCollectionOrder {
  DirectoryAsc = 'directory_ASC',
  DirectoryDesc = 'directory_DESC',
  InternalLabelAsc = 'internalLabel_ASC',
  InternalLabelDesc = 'internalLabel_DESC',
  MetaTitleAsc = 'metaTitle_ASC',
  MetaTitleDesc = 'metaTitle_DESC',
  NoindexNofollowAsc = 'noindexNofollow_ASC',
  NoindexNofollowDesc = 'noindexNofollow_DESC',
  SlugAsc = 'slug_ASC',
  SlugDesc = 'slug_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
}

export enum PagesPressBannerLinkingCollectionsTemplatePartnersCollectionOrder {
  CollapseHeaderAsc = 'collapseHeader_ASC',
  CollapseHeaderDesc = 'collapseHeader_DESC',
  ShowHeaderAsc = 'showHeader_ASC',
  ShowHeaderDesc = 'showHeader_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC',
}

export enum PagesPressBannerLinkingCollectionsTemplatePressCollectionOrder {
  SubheadAsc = 'subhead_ASC',
  SubheadDesc = 'subhead_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC',
}

export enum PagesPressBannerLinkingCollectionsTemplateReviewsCollectionOrder {
  CollapseHeaderAsc = 'collapseHeader_ASC',
  CollapseHeaderDesc = 'collapseHeader_DESC',
  ShowHeaderAsc = 'showHeader_ASC',
  ShowHeaderDesc = 'showHeader_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC',
}

export enum PagesPressBannerLinkingCollectionsTemplateWhyBookWithForaCollectionOrder {
  CollapseHeaderAsc = 'collapseHeader_ASC',
  CollapseHeaderDesc = 'collapseHeader_DESC',
  ShowHeaderAsc = 'showHeader_ASC',
  ShowHeaderDesc = 'showHeader_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC',
}

export enum PagesPressBannerOrder {
  AdminLabelAsc = 'adminLabel_ASC',
  AdminLabelDesc = 'adminLabel_DESC',
  ApplyBackgroundAsc = 'applyBackground_ASC',
  ApplyBackgroundDesc = 'applyBackground_DESC',
  LogoSizeAsc = 'logoSize_ASC',
  LogoSizeDesc = 'logoSize_DESC',
  RedirectSlugAsc = 'redirectSlug_ASC',
  RedirectSlugDesc = 'redirectSlug_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC',
}

/** Create and manage Two Columns CTAs. [See type definition](https://app.contentful.com/spaces/9cxgygtadmvl/content_types/pagesTwoColumnCTA) */
export type PagesTwoColumnCta = Entry &
  _Node & {
    __typename?: 'PagesTwoColumnCta'
    _id: Scalars['ID']
    advisor?: Maybe<Advisors>
    advisorDescription?: Maybe<Scalars['String']>
    analyticsModule?: Maybe<SettingsAnalyticsModule>
    backgroundColor?: Maybe<Scalars['String']>
    buttonText?: Maybe<Scalars['String']>
    buttonTheme?: Maybe<Scalars['String']>
    buttonUrl?: Maybe<Scalars['String']>
    cardSize?: Maybe<Scalars['String']>
    contentColumn?: Maybe<Scalars['String']>
    contentfulMetadata: ContentfulMetadata
    formId?: Maybe<Scalars['String']>
    image?: Maybe<Asset>
    internalLabel?: Maybe<Scalars['String']>
    linkedFrom?: Maybe<PagesTwoColumnCtaLinkingCollections>
    mobileAlignment?: Maybe<Scalars['String']>
    perksCollection?: Maybe<PagesTwoColumnCtaPerksCollection>
    sectionId?: Maybe<Scalars['String']>
    solidBackground?: Maybe<Scalars['Boolean']>
    sys: Sys
    textBlockLeft?: Maybe<PagesTwoColumnCtaTextBlockLeft>
    textUnderneathButton?: Maybe<PagesTwoColumnCtaTextUnderneathButton>
    title?: Maybe<Scalars['String']>
    titleIcon?: Maybe<Asset>
    titleStyle?: Maybe<Scalars['String']>
    urlTarget?: Maybe<Scalars['String']>
    yMargin?: Maybe<Scalars['Float']>
  }

/** Create and manage Two Columns CTAs. [See type definition](https://app.contentful.com/spaces/9cxgygtadmvl/content_types/pagesTwoColumnCTA) */
export type PagesTwoColumnCtaAdvisorArgs = {
  locale?: InputMaybe<Scalars['String']>
  preview?: InputMaybe<Scalars['Boolean']>
  where?: InputMaybe<AdvisorsFilter>
}

/** Create and manage Two Columns CTAs. [See type definition](https://app.contentful.com/spaces/9cxgygtadmvl/content_types/pagesTwoColumnCTA) */
export type PagesTwoColumnCtaAdvisorDescriptionArgs = {
  locale?: InputMaybe<Scalars['String']>
}

/** Create and manage Two Columns CTAs. [See type definition](https://app.contentful.com/spaces/9cxgygtadmvl/content_types/pagesTwoColumnCTA) */
export type PagesTwoColumnCtaAnalyticsModuleArgs = {
  locale?: InputMaybe<Scalars['String']>
  preview?: InputMaybe<Scalars['Boolean']>
  where?: InputMaybe<SettingsAnalyticsModuleFilter>
}

/** Create and manage Two Columns CTAs. [See type definition](https://app.contentful.com/spaces/9cxgygtadmvl/content_types/pagesTwoColumnCTA) */
export type PagesTwoColumnCtaBackgroundColorArgs = {
  locale?: InputMaybe<Scalars['String']>
}

/** Create and manage Two Columns CTAs. [See type definition](https://app.contentful.com/spaces/9cxgygtadmvl/content_types/pagesTwoColumnCTA) */
export type PagesTwoColumnCtaButtonTextArgs = {
  locale?: InputMaybe<Scalars['String']>
}

/** Create and manage Two Columns CTAs. [See type definition](https://app.contentful.com/spaces/9cxgygtadmvl/content_types/pagesTwoColumnCTA) */
export type PagesTwoColumnCtaButtonThemeArgs = {
  locale?: InputMaybe<Scalars['String']>
}

/** Create and manage Two Columns CTAs. [See type definition](https://app.contentful.com/spaces/9cxgygtadmvl/content_types/pagesTwoColumnCTA) */
export type PagesTwoColumnCtaButtonUrlArgs = {
  locale?: InputMaybe<Scalars['String']>
}

/** Create and manage Two Columns CTAs. [See type definition](https://app.contentful.com/spaces/9cxgygtadmvl/content_types/pagesTwoColumnCTA) */
export type PagesTwoColumnCtaCardSizeArgs = {
  locale?: InputMaybe<Scalars['String']>
}

/** Create and manage Two Columns CTAs. [See type definition](https://app.contentful.com/spaces/9cxgygtadmvl/content_types/pagesTwoColumnCTA) */
export type PagesTwoColumnCtaContentColumnArgs = {
  locale?: InputMaybe<Scalars['String']>
}

/** Create and manage Two Columns CTAs. [See type definition](https://app.contentful.com/spaces/9cxgygtadmvl/content_types/pagesTwoColumnCTA) */
export type PagesTwoColumnCtaFormIdArgs = {
  locale?: InputMaybe<Scalars['String']>
}

/** Create and manage Two Columns CTAs. [See type definition](https://app.contentful.com/spaces/9cxgygtadmvl/content_types/pagesTwoColumnCTA) */
export type PagesTwoColumnCtaImageArgs = {
  locale?: InputMaybe<Scalars['String']>
  preview?: InputMaybe<Scalars['Boolean']>
}

/** Create and manage Two Columns CTAs. [See type definition](https://app.contentful.com/spaces/9cxgygtadmvl/content_types/pagesTwoColumnCTA) */
export type PagesTwoColumnCtaInternalLabelArgs = {
  locale?: InputMaybe<Scalars['String']>
}

/** Create and manage Two Columns CTAs. [See type definition](https://app.contentful.com/spaces/9cxgygtadmvl/content_types/pagesTwoColumnCTA) */
export type PagesTwoColumnCtaLinkedFromArgs = {
  allowedLocales?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
}

/** Create and manage Two Columns CTAs. [See type definition](https://app.contentful.com/spaces/9cxgygtadmvl/content_types/pagesTwoColumnCTA) */
export type PagesTwoColumnCtaMobileAlignmentArgs = {
  locale?: InputMaybe<Scalars['String']>
}

/** Create and manage Two Columns CTAs. [See type definition](https://app.contentful.com/spaces/9cxgygtadmvl/content_types/pagesTwoColumnCTA) */
export type PagesTwoColumnCtaPerksCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>
  locale?: InputMaybe<Scalars['String']>
  order?: InputMaybe<Array<InputMaybe<PagesTwoColumnCtaPerksCollectionOrder>>>
  preview?: InputMaybe<Scalars['Boolean']>
  skip?: InputMaybe<Scalars['Int']>
  where?: InputMaybe<ComponentImageHeaderSubFilter>
}

/** Create and manage Two Columns CTAs. [See type definition](https://app.contentful.com/spaces/9cxgygtadmvl/content_types/pagesTwoColumnCTA) */
export type PagesTwoColumnCtaSectionIdArgs = {
  locale?: InputMaybe<Scalars['String']>
}

/** Create and manage Two Columns CTAs. [See type definition](https://app.contentful.com/spaces/9cxgygtadmvl/content_types/pagesTwoColumnCTA) */
export type PagesTwoColumnCtaSolidBackgroundArgs = {
  locale?: InputMaybe<Scalars['String']>
}

/** Create and manage Two Columns CTAs. [See type definition](https://app.contentful.com/spaces/9cxgygtadmvl/content_types/pagesTwoColumnCTA) */
export type PagesTwoColumnCtaTextBlockLeftArgs = {
  locale?: InputMaybe<Scalars['String']>
}

/** Create and manage Two Columns CTAs. [See type definition](https://app.contentful.com/spaces/9cxgygtadmvl/content_types/pagesTwoColumnCTA) */
export type PagesTwoColumnCtaTextUnderneathButtonArgs = {
  locale?: InputMaybe<Scalars['String']>
}

/** Create and manage Two Columns CTAs. [See type definition](https://app.contentful.com/spaces/9cxgygtadmvl/content_types/pagesTwoColumnCTA) */
export type PagesTwoColumnCtaTitleArgs = {
  locale?: InputMaybe<Scalars['String']>
}

/** Create and manage Two Columns CTAs. [See type definition](https://app.contentful.com/spaces/9cxgygtadmvl/content_types/pagesTwoColumnCTA) */
export type PagesTwoColumnCtaTitleIconArgs = {
  locale?: InputMaybe<Scalars['String']>
  preview?: InputMaybe<Scalars['Boolean']>
}

/** Create and manage Two Columns CTAs. [See type definition](https://app.contentful.com/spaces/9cxgygtadmvl/content_types/pagesTwoColumnCTA) */
export type PagesTwoColumnCtaTitleStyleArgs = {
  locale?: InputMaybe<Scalars['String']>
}

/** Create and manage Two Columns CTAs. [See type definition](https://app.contentful.com/spaces/9cxgygtadmvl/content_types/pagesTwoColumnCTA) */
export type PagesTwoColumnCtaUrlTargetArgs = {
  locale?: InputMaybe<Scalars['String']>
}

/** Create and manage Two Columns CTAs. [See type definition](https://app.contentful.com/spaces/9cxgygtadmvl/content_types/pagesTwoColumnCTA) */
export type PagesTwoColumnCtaYMarginArgs = {
  locale?: InputMaybe<Scalars['String']>
}

export type PagesTwoColumnCtaCollection = {
  __typename?: 'PagesTwoColumnCtaCollection'
  items: Array<Maybe<PagesTwoColumnCta>>
  limit: Scalars['Int']
  skip: Scalars['Int']
  total: Scalars['Int']
}

export type PagesTwoColumnCtaFilter = {
  AND?: InputMaybe<Array<InputMaybe<PagesTwoColumnCtaFilter>>>
  OR?: InputMaybe<Array<InputMaybe<PagesTwoColumnCtaFilter>>>
  advisor?: InputMaybe<CfAdvisorsNestedFilter>
  advisorDescription?: InputMaybe<Scalars['String']>
  advisorDescription_contains?: InputMaybe<Scalars['String']>
  advisorDescription_exists?: InputMaybe<Scalars['Boolean']>
  advisorDescription_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  advisorDescription_not?: InputMaybe<Scalars['String']>
  advisorDescription_not_contains?: InputMaybe<Scalars['String']>
  advisorDescription_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  advisor_exists?: InputMaybe<Scalars['Boolean']>
  analyticsModule?: InputMaybe<CfSettingsAnalyticsModuleNestedFilter>
  analyticsModule_exists?: InputMaybe<Scalars['Boolean']>
  backgroundColor?: InputMaybe<Scalars['String']>
  backgroundColor_contains?: InputMaybe<Scalars['String']>
  backgroundColor_exists?: InputMaybe<Scalars['Boolean']>
  backgroundColor_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  backgroundColor_not?: InputMaybe<Scalars['String']>
  backgroundColor_not_contains?: InputMaybe<Scalars['String']>
  backgroundColor_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  buttonText?: InputMaybe<Scalars['String']>
  buttonText_contains?: InputMaybe<Scalars['String']>
  buttonText_exists?: InputMaybe<Scalars['Boolean']>
  buttonText_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  buttonText_not?: InputMaybe<Scalars['String']>
  buttonText_not_contains?: InputMaybe<Scalars['String']>
  buttonText_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  buttonTheme?: InputMaybe<Scalars['String']>
  buttonTheme_contains?: InputMaybe<Scalars['String']>
  buttonTheme_exists?: InputMaybe<Scalars['Boolean']>
  buttonTheme_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  buttonTheme_not?: InputMaybe<Scalars['String']>
  buttonTheme_not_contains?: InputMaybe<Scalars['String']>
  buttonTheme_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  buttonUrl?: InputMaybe<Scalars['String']>
  buttonUrl_contains?: InputMaybe<Scalars['String']>
  buttonUrl_exists?: InputMaybe<Scalars['Boolean']>
  buttonUrl_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  buttonUrl_not?: InputMaybe<Scalars['String']>
  buttonUrl_not_contains?: InputMaybe<Scalars['String']>
  buttonUrl_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  cardSize?: InputMaybe<Scalars['String']>
  cardSize_contains?: InputMaybe<Scalars['String']>
  cardSize_exists?: InputMaybe<Scalars['Boolean']>
  cardSize_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  cardSize_not?: InputMaybe<Scalars['String']>
  cardSize_not_contains?: InputMaybe<Scalars['String']>
  cardSize_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  contentColumn?: InputMaybe<Scalars['String']>
  contentColumn_contains?: InputMaybe<Scalars['String']>
  contentColumn_exists?: InputMaybe<Scalars['Boolean']>
  contentColumn_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  contentColumn_not?: InputMaybe<Scalars['String']>
  contentColumn_not_contains?: InputMaybe<Scalars['String']>
  contentColumn_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  contentfulMetadata?: InputMaybe<ContentfulMetadataFilter>
  formId?: InputMaybe<Scalars['String']>
  formId_contains?: InputMaybe<Scalars['String']>
  formId_exists?: InputMaybe<Scalars['Boolean']>
  formId_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  formId_not?: InputMaybe<Scalars['String']>
  formId_not_contains?: InputMaybe<Scalars['String']>
  formId_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  image_exists?: InputMaybe<Scalars['Boolean']>
  internalLabel?: InputMaybe<Scalars['String']>
  internalLabel_contains?: InputMaybe<Scalars['String']>
  internalLabel_exists?: InputMaybe<Scalars['Boolean']>
  internalLabel_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  internalLabel_not?: InputMaybe<Scalars['String']>
  internalLabel_not_contains?: InputMaybe<Scalars['String']>
  internalLabel_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  mobileAlignment?: InputMaybe<Scalars['String']>
  mobileAlignment_contains?: InputMaybe<Scalars['String']>
  mobileAlignment_exists?: InputMaybe<Scalars['Boolean']>
  mobileAlignment_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  mobileAlignment_not?: InputMaybe<Scalars['String']>
  mobileAlignment_not_contains?: InputMaybe<Scalars['String']>
  mobileAlignment_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  perks?: InputMaybe<CfComponentImageHeaderSubNestedFilter>
  perksCollection_exists?: InputMaybe<Scalars['Boolean']>
  sectionId?: InputMaybe<Scalars['String']>
  sectionId_contains?: InputMaybe<Scalars['String']>
  sectionId_exists?: InputMaybe<Scalars['Boolean']>
  sectionId_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  sectionId_not?: InputMaybe<Scalars['String']>
  sectionId_not_contains?: InputMaybe<Scalars['String']>
  sectionId_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  solidBackground?: InputMaybe<Scalars['Boolean']>
  solidBackground_exists?: InputMaybe<Scalars['Boolean']>
  solidBackground_not?: InputMaybe<Scalars['Boolean']>
  sys?: InputMaybe<SysFilter>
  textBlockLeft_contains?: InputMaybe<Scalars['String']>
  textBlockLeft_exists?: InputMaybe<Scalars['Boolean']>
  textBlockLeft_not_contains?: InputMaybe<Scalars['String']>
  textUnderneathButton_contains?: InputMaybe<Scalars['String']>
  textUnderneathButton_exists?: InputMaybe<Scalars['Boolean']>
  textUnderneathButton_not_contains?: InputMaybe<Scalars['String']>
  title?: InputMaybe<Scalars['String']>
  titleIcon_exists?: InputMaybe<Scalars['Boolean']>
  titleStyle?: InputMaybe<Scalars['String']>
  titleStyle_contains?: InputMaybe<Scalars['String']>
  titleStyle_exists?: InputMaybe<Scalars['Boolean']>
  titleStyle_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  titleStyle_not?: InputMaybe<Scalars['String']>
  titleStyle_not_contains?: InputMaybe<Scalars['String']>
  titleStyle_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  title_contains?: InputMaybe<Scalars['String']>
  title_exists?: InputMaybe<Scalars['Boolean']>
  title_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  title_not?: InputMaybe<Scalars['String']>
  title_not_contains?: InputMaybe<Scalars['String']>
  title_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  urlTarget?: InputMaybe<Scalars['String']>
  urlTarget_contains?: InputMaybe<Scalars['String']>
  urlTarget_exists?: InputMaybe<Scalars['Boolean']>
  urlTarget_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  urlTarget_not?: InputMaybe<Scalars['String']>
  urlTarget_not_contains?: InputMaybe<Scalars['String']>
  urlTarget_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  yMargin?: InputMaybe<Scalars['Float']>
  yMargin_exists?: InputMaybe<Scalars['Boolean']>
  yMargin_gt?: InputMaybe<Scalars['Float']>
  yMargin_gte?: InputMaybe<Scalars['Float']>
  yMargin_in?: InputMaybe<Array<InputMaybe<Scalars['Float']>>>
  yMargin_lt?: InputMaybe<Scalars['Float']>
  yMargin_lte?: InputMaybe<Scalars['Float']>
  yMargin_not?: InputMaybe<Scalars['Float']>
  yMargin_not_in?: InputMaybe<Array<InputMaybe<Scalars['Float']>>>
}

export type PagesTwoColumnCtaLinkingCollections = {
  __typename?: 'PagesTwoColumnCtaLinkingCollections'
  campaignPagesCollection?: Maybe<CampaignPagesCollection>
  entryCollection?: Maybe<EntryCollection>
  entryPartnerCollection?: Maybe<EntryPartnerCollection>
  templateAboutUsCollection?: Maybe<TemplateAboutUsCollection>
  templateBookingCollection?: Maybe<TemplateBookingCollection>
  templateCareersCollection?: Maybe<TemplateCareersCollection>
  templateContactUsCollection?: Maybe<TemplateContactUsCollection>
  templateDestinationsCollection?: Maybe<TemplateDestinationsCollection>
  templateForaReserveCollection?: Maybe<TemplateForaReserveCollection>
  templateForaXCollection?: Maybe<TemplateForaXCollection>
  templateHomeCollection?: Maybe<TemplateHomeCollection>
  templateJoinUsCollection?: Maybe<TemplateJoinUsCollection>
  templateNewsletterCollection?: Maybe<TemplateNewsletterCollection>
  templatePageCollection?: Maybe<TemplatePageCollection>
  templatePartnerDetailCollection?: Maybe<TemplatePartnerDetailCollection>
  templatePartnersCollection?: Maybe<TemplatePartnersCollection>
  templateReviewsCollection?: Maybe<TemplateReviewsCollection>
  templateWhyBookWithForaCollection?: Maybe<TemplateWhyBookWithForaCollection>
}

export type PagesTwoColumnCtaLinkingCollectionsCampaignPagesCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>
  locale?: InputMaybe<Scalars['String']>
  order?: InputMaybe<
    Array<
      InputMaybe<PagesTwoColumnCtaLinkingCollectionsCampaignPagesCollectionOrder>
    >
  >
  preview?: InputMaybe<Scalars['Boolean']>
  skip?: InputMaybe<Scalars['Int']>
}

export type PagesTwoColumnCtaLinkingCollectionsEntryCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>
  locale?: InputMaybe<Scalars['String']>
  preview?: InputMaybe<Scalars['Boolean']>
  skip?: InputMaybe<Scalars['Int']>
}

export type PagesTwoColumnCtaLinkingCollectionsEntryPartnerCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>
  locale?: InputMaybe<Scalars['String']>
  order?: InputMaybe<
    Array<
      InputMaybe<PagesTwoColumnCtaLinkingCollectionsEntryPartnerCollectionOrder>
    >
  >
  preview?: InputMaybe<Scalars['Boolean']>
  skip?: InputMaybe<Scalars['Int']>
}

export type PagesTwoColumnCtaLinkingCollectionsTemplateAboutUsCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>
  locale?: InputMaybe<Scalars['String']>
  order?: InputMaybe<
    Array<
      InputMaybe<PagesTwoColumnCtaLinkingCollectionsTemplateAboutUsCollectionOrder>
    >
  >
  preview?: InputMaybe<Scalars['Boolean']>
  skip?: InputMaybe<Scalars['Int']>
}

export type PagesTwoColumnCtaLinkingCollectionsTemplateBookingCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>
  locale?: InputMaybe<Scalars['String']>
  order?: InputMaybe<
    Array<
      InputMaybe<PagesTwoColumnCtaLinkingCollectionsTemplateBookingCollectionOrder>
    >
  >
  preview?: InputMaybe<Scalars['Boolean']>
  skip?: InputMaybe<Scalars['Int']>
}

export type PagesTwoColumnCtaLinkingCollectionsTemplateCareersCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>
  locale?: InputMaybe<Scalars['String']>
  order?: InputMaybe<
    Array<
      InputMaybe<PagesTwoColumnCtaLinkingCollectionsTemplateCareersCollectionOrder>
    >
  >
  preview?: InputMaybe<Scalars['Boolean']>
  skip?: InputMaybe<Scalars['Int']>
}

export type PagesTwoColumnCtaLinkingCollectionsTemplateContactUsCollectionArgs =
  {
    limit?: InputMaybe<Scalars['Int']>
    locale?: InputMaybe<Scalars['String']>
    order?: InputMaybe<
      Array<
        InputMaybe<PagesTwoColumnCtaLinkingCollectionsTemplateContactUsCollectionOrder>
      >
    >
    preview?: InputMaybe<Scalars['Boolean']>
    skip?: InputMaybe<Scalars['Int']>
  }

export type PagesTwoColumnCtaLinkingCollectionsTemplateDestinationsCollectionArgs =
  {
    limit?: InputMaybe<Scalars['Int']>
    locale?: InputMaybe<Scalars['String']>
    order?: InputMaybe<
      Array<
        InputMaybe<PagesTwoColumnCtaLinkingCollectionsTemplateDestinationsCollectionOrder>
      >
    >
    preview?: InputMaybe<Scalars['Boolean']>
    skip?: InputMaybe<Scalars['Int']>
  }

export type PagesTwoColumnCtaLinkingCollectionsTemplateForaReserveCollectionArgs =
  {
    limit?: InputMaybe<Scalars['Int']>
    locale?: InputMaybe<Scalars['String']>
    order?: InputMaybe<
      Array<
        InputMaybe<PagesTwoColumnCtaLinkingCollectionsTemplateForaReserveCollectionOrder>
      >
    >
    preview?: InputMaybe<Scalars['Boolean']>
    skip?: InputMaybe<Scalars['Int']>
  }

export type PagesTwoColumnCtaLinkingCollectionsTemplateForaXCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>
  locale?: InputMaybe<Scalars['String']>
  order?: InputMaybe<
    Array<
      InputMaybe<PagesTwoColumnCtaLinkingCollectionsTemplateForaXCollectionOrder>
    >
  >
  preview?: InputMaybe<Scalars['Boolean']>
  skip?: InputMaybe<Scalars['Int']>
}

export type PagesTwoColumnCtaLinkingCollectionsTemplateHomeCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>
  locale?: InputMaybe<Scalars['String']>
  order?: InputMaybe<
    Array<
      InputMaybe<PagesTwoColumnCtaLinkingCollectionsTemplateHomeCollectionOrder>
    >
  >
  preview?: InputMaybe<Scalars['Boolean']>
  skip?: InputMaybe<Scalars['Int']>
}

export type PagesTwoColumnCtaLinkingCollectionsTemplateJoinUsCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>
  locale?: InputMaybe<Scalars['String']>
  order?: InputMaybe<
    Array<
      InputMaybe<PagesTwoColumnCtaLinkingCollectionsTemplateJoinUsCollectionOrder>
    >
  >
  preview?: InputMaybe<Scalars['Boolean']>
  skip?: InputMaybe<Scalars['Int']>
}

export type PagesTwoColumnCtaLinkingCollectionsTemplateNewsletterCollectionArgs =
  {
    limit?: InputMaybe<Scalars['Int']>
    locale?: InputMaybe<Scalars['String']>
    order?: InputMaybe<
      Array<
        InputMaybe<PagesTwoColumnCtaLinkingCollectionsTemplateNewsletterCollectionOrder>
      >
    >
    preview?: InputMaybe<Scalars['Boolean']>
    skip?: InputMaybe<Scalars['Int']>
  }

export type PagesTwoColumnCtaLinkingCollectionsTemplatePageCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>
  locale?: InputMaybe<Scalars['String']>
  order?: InputMaybe<
    Array<
      InputMaybe<PagesTwoColumnCtaLinkingCollectionsTemplatePageCollectionOrder>
    >
  >
  preview?: InputMaybe<Scalars['Boolean']>
  skip?: InputMaybe<Scalars['Int']>
}

export type PagesTwoColumnCtaLinkingCollectionsTemplatePartnerDetailCollectionArgs =
  {
    limit?: InputMaybe<Scalars['Int']>
    locale?: InputMaybe<Scalars['String']>
    order?: InputMaybe<
      Array<
        InputMaybe<PagesTwoColumnCtaLinkingCollectionsTemplatePartnerDetailCollectionOrder>
      >
    >
    preview?: InputMaybe<Scalars['Boolean']>
    skip?: InputMaybe<Scalars['Int']>
  }

export type PagesTwoColumnCtaLinkingCollectionsTemplatePartnersCollectionArgs =
  {
    limit?: InputMaybe<Scalars['Int']>
    locale?: InputMaybe<Scalars['String']>
    order?: InputMaybe<
      Array<
        InputMaybe<PagesTwoColumnCtaLinkingCollectionsTemplatePartnersCollectionOrder>
      >
    >
    preview?: InputMaybe<Scalars['Boolean']>
    skip?: InputMaybe<Scalars['Int']>
  }

export type PagesTwoColumnCtaLinkingCollectionsTemplateReviewsCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>
  locale?: InputMaybe<Scalars['String']>
  order?: InputMaybe<
    Array<
      InputMaybe<PagesTwoColumnCtaLinkingCollectionsTemplateReviewsCollectionOrder>
    >
  >
  preview?: InputMaybe<Scalars['Boolean']>
  skip?: InputMaybe<Scalars['Int']>
}

export type PagesTwoColumnCtaLinkingCollectionsTemplateWhyBookWithForaCollectionArgs =
  {
    limit?: InputMaybe<Scalars['Int']>
    locale?: InputMaybe<Scalars['String']>
    order?: InputMaybe<
      Array<
        InputMaybe<PagesTwoColumnCtaLinkingCollectionsTemplateWhyBookWithForaCollectionOrder>
      >
    >
    preview?: InputMaybe<Scalars['Boolean']>
    skip?: InputMaybe<Scalars['Int']>
  }

export enum PagesTwoColumnCtaLinkingCollectionsCampaignPagesCollectionOrder {
  CollapseFooterAsc = 'collapseFooter_ASC',
  CollapseFooterDesc = 'collapseFooter_DESC',
  CollapseHeaderAsc = 'collapseHeader_ASC',
  CollapseHeaderDesc = 'collapseHeader_DESC',
  HideFooterAsc = 'hideFooter_ASC',
  HideFooterDesc = 'hideFooter_DESC',
  HideGlobalBannerAsc = 'hideGlobalBanner_ASC',
  HideGlobalBannerDesc = 'hideGlobalBanner_DESC',
  JotformIdAsc = 'jotformId_ASC',
  JotformIdDesc = 'jotformId_DESC',
  NoindexNofollowAsc = 'noindexNofollow_ASC',
  NoindexNofollowDesc = 'noindexNofollow_DESC',
  ShowHeaderAsc = 'showHeader_ASC',
  ShowHeaderDesc = 'showHeader_DESC',
  SlugAsc = 'slug_ASC',
  SlugDesc = 'slug_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC',
}

export enum PagesTwoColumnCtaLinkingCollectionsEntryPartnerCollectionOrder {
  DetailSummaryAsc = 'detailSummary_ASC',
  DetailSummaryDesc = 'detailSummary_DESC',
  FeaturedAsc = 'featured_ASC',
  FeaturedDesc = 'featured_DESC',
  HasDetailPageAsc = 'hasDetailPage_ASC',
  HasDetailPageDesc = 'hasDetailPage_DESC',
  HideFromPartnerGridAsc = 'hideFromPartnerGrid_ASC',
  HideFromPartnerGridDesc = 'hideFromPartnerGrid_DESC',
  NameAsc = 'name_ASC',
  NameDesc = 'name_DESC',
  PartnerCategoryAsc = 'partnerCategory_ASC',
  PartnerCategoryDesc = 'partnerCategory_DESC',
  PartnerTypeAsc = 'partnerType_ASC',
  PartnerTypeDesc = 'partnerType_DESC',
  SlugAsc = 'slug_ASC',
  SlugDesc = 'slug_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
}

export enum PagesTwoColumnCtaLinkingCollectionsTemplateAboutUsCollectionOrder {
  CollapseHeaderAsc = 'collapseHeader_ASC',
  CollapseHeaderDesc = 'collapseHeader_DESC',
  HeaderBlockTitleAsc = 'headerBlockTitle_ASC',
  HeaderBlockTitleDesc = 'headerBlockTitle_DESC',
  ShowHeaderAsc = 'showHeader_ASC',
  ShowHeaderDesc = 'showHeader_DESC',
  SubtitleAsc = 'subtitle_ASC',
  SubtitleDesc = 'subtitle_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC',
}

export enum PagesTwoColumnCtaLinkingCollectionsTemplateBookingCollectionOrder {
  CollapseHeaderAsc = 'collapseHeader_ASC',
  CollapseHeaderDesc = 'collapseHeader_DESC',
  ShowHeaderAsc = 'showHeader_ASC',
  ShowHeaderDesc = 'showHeader_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC',
}

export enum PagesTwoColumnCtaLinkingCollectionsTemplateCareersCollectionOrder {
  CollapseHeaderAsc = 'collapseHeader_ASC',
  CollapseHeaderDesc = 'collapseHeader_DESC',
  ShowHeaderAsc = 'showHeader_ASC',
  ShowHeaderDesc = 'showHeader_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC',
}

export enum PagesTwoColumnCtaLinkingCollectionsTemplateContactUsCollectionOrder {
  CollapseHeaderAsc = 'collapseHeader_ASC',
  CollapseHeaderDesc = 'collapseHeader_DESC',
  ShowHeaderAsc = 'showHeader_ASC',
  ShowHeaderDesc = 'showHeader_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC',
}

export enum PagesTwoColumnCtaLinkingCollectionsTemplateDestinationsCollectionOrder {
  CollapseHeaderAsc = 'collapseHeader_ASC',
  CollapseHeaderDesc = 'collapseHeader_DESC',
  CollapsibleHeadingAsc = 'collapsibleHeading_ASC',
  CollapsibleHeadingDesc = 'collapsibleHeading_DESC',
  ShowHeaderAsc = 'showHeader_ASC',
  ShowHeaderDesc = 'showHeader_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC',
}

export enum PagesTwoColumnCtaLinkingCollectionsTemplateForaReserveCollectionOrder {
  CollapseHeaderAsc = 'collapseHeader_ASC',
  CollapseHeaderDesc = 'collapseHeader_DESC',
  ShowHeaderAsc = 'showHeader_ASC',
  ShowHeaderDesc = 'showHeader_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC',
}

export enum PagesTwoColumnCtaLinkingCollectionsTemplateForaXCollectionOrder {
  AdvisorGridHeaderAsc = 'advisorGridHeader_ASC',
  AdvisorGridHeaderDesc = 'advisorGridHeader_DESC',
  AdvisorGridSubCopyAsc = 'advisorGridSubCopy_ASC',
  AdvisorGridSubCopyDesc = 'advisorGridSubCopy_DESC',
  CollapseHeaderAsc = 'collapseHeader_ASC',
  CollapseHeaderDesc = 'collapseHeader_DESC',
  ShowHeaderAsc = 'showHeader_ASC',
  ShowHeaderDesc = 'showHeader_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC',
}

export enum PagesTwoColumnCtaLinkingCollectionsTemplateHomeCollectionOrder {
  CollapseHeaderAsc = 'collapseHeader_ASC',
  CollapseHeaderDesc = 'collapseHeader_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC',
}

export enum PagesTwoColumnCtaLinkingCollectionsTemplateJoinUsCollectionOrder {
  CollapseHeaderAsc = 'collapseHeader_ASC',
  CollapseHeaderDesc = 'collapseHeader_DESC',
  HideGlobalBannerAsc = 'hideGlobalBanner_ASC',
  HideGlobalBannerDesc = 'hideGlobalBanner_DESC',
  ShowHeaderAsc = 'showHeader_ASC',
  ShowHeaderDesc = 'showHeader_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC',
}

export enum PagesTwoColumnCtaLinkingCollectionsTemplateNewsletterCollectionOrder {
  CollapseHeaderAsc = 'collapseHeader_ASC',
  CollapseHeaderDesc = 'collapseHeader_DESC',
  ShowHeaderAsc = 'showHeader_ASC',
  ShowHeaderDesc = 'showHeader_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC',
}

export enum PagesTwoColumnCtaLinkingCollectionsTemplatePageCollectionOrder {
  DirectoryAsc = 'directory_ASC',
  DirectoryDesc = 'directory_DESC',
  InternalLabelAsc = 'internalLabel_ASC',
  InternalLabelDesc = 'internalLabel_DESC',
  MetaTitleAsc = 'metaTitle_ASC',
  MetaTitleDesc = 'metaTitle_DESC',
  NoindexNofollowAsc = 'noindexNofollow_ASC',
  NoindexNofollowDesc = 'noindexNofollow_DESC',
  SlugAsc = 'slug_ASC',
  SlugDesc = 'slug_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
}

export enum PagesTwoColumnCtaLinkingCollectionsTemplatePartnerDetailCollectionOrder {
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
}

export enum PagesTwoColumnCtaLinkingCollectionsTemplatePartnersCollectionOrder {
  CollapseHeaderAsc = 'collapseHeader_ASC',
  CollapseHeaderDesc = 'collapseHeader_DESC',
  ShowHeaderAsc = 'showHeader_ASC',
  ShowHeaderDesc = 'showHeader_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC',
}

export enum PagesTwoColumnCtaLinkingCollectionsTemplateReviewsCollectionOrder {
  CollapseHeaderAsc = 'collapseHeader_ASC',
  CollapseHeaderDesc = 'collapseHeader_DESC',
  ShowHeaderAsc = 'showHeader_ASC',
  ShowHeaderDesc = 'showHeader_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC',
}

export enum PagesTwoColumnCtaLinkingCollectionsTemplateWhyBookWithForaCollectionOrder {
  CollapseHeaderAsc = 'collapseHeader_ASC',
  CollapseHeaderDesc = 'collapseHeader_DESC',
  ShowHeaderAsc = 'showHeader_ASC',
  ShowHeaderDesc = 'showHeader_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC',
}

export enum PagesTwoColumnCtaOrder {
  AdvisorDescriptionAsc = 'advisorDescription_ASC',
  AdvisorDescriptionDesc = 'advisorDescription_DESC',
  BackgroundColorAsc = 'backgroundColor_ASC',
  BackgroundColorDesc = 'backgroundColor_DESC',
  ButtonTextAsc = 'buttonText_ASC',
  ButtonTextDesc = 'buttonText_DESC',
  ButtonThemeAsc = 'buttonTheme_ASC',
  ButtonThemeDesc = 'buttonTheme_DESC',
  ButtonUrlAsc = 'buttonUrl_ASC',
  ButtonUrlDesc = 'buttonUrl_DESC',
  CardSizeAsc = 'cardSize_ASC',
  CardSizeDesc = 'cardSize_DESC',
  ContentColumnAsc = 'contentColumn_ASC',
  ContentColumnDesc = 'contentColumn_DESC',
  FormIdAsc = 'formId_ASC',
  FormIdDesc = 'formId_DESC',
  InternalLabelAsc = 'internalLabel_ASC',
  InternalLabelDesc = 'internalLabel_DESC',
  MobileAlignmentAsc = 'mobileAlignment_ASC',
  MobileAlignmentDesc = 'mobileAlignment_DESC',
  SectionIdAsc = 'sectionId_ASC',
  SectionIdDesc = 'sectionId_DESC',
  SolidBackgroundAsc = 'solidBackground_ASC',
  SolidBackgroundDesc = 'solidBackground_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  TitleStyleAsc = 'titleStyle_ASC',
  TitleStyleDesc = 'titleStyle_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC',
  UrlTargetAsc = 'urlTarget_ASC',
  UrlTargetDesc = 'urlTarget_DESC',
  YMarginAsc = 'yMargin_ASC',
  YMarginDesc = 'yMargin_DESC',
}

export type PagesTwoColumnCtaPerksCollection = {
  __typename?: 'PagesTwoColumnCtaPerksCollection'
  items: Array<Maybe<ComponentImageHeaderSub>>
  limit: Scalars['Int']
  skip: Scalars['Int']
  total: Scalars['Int']
}

export enum PagesTwoColumnCtaPerksCollectionOrder {
  HeaderAsc = 'header_ASC',
  HeaderDesc = 'header_DESC',
  InternalLabelAsc = 'internalLabel_ASC',
  InternalLabelDesc = 'internalLabel_DESC',
  SubHeadingAsc = 'subHeading_ASC',
  SubHeadingDesc = 'subHeading_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  TitleStyleAsc = 'titleStyle_ASC',
  TitleStyleDesc = 'titleStyle_DESC',
}

export type PagesTwoColumnCtaTextBlockLeft = {
  __typename?: 'PagesTwoColumnCtaTextBlockLeft'
  json: Scalars['JSON']
  links: PagesTwoColumnCtaTextBlockLeftLinks
}

export type PagesTwoColumnCtaTextBlockLeftAssets = {
  __typename?: 'PagesTwoColumnCtaTextBlockLeftAssets'
  block: Array<Maybe<Asset>>
  hyperlink: Array<Maybe<Asset>>
}

export type PagesTwoColumnCtaTextBlockLeftEntries = {
  __typename?: 'PagesTwoColumnCtaTextBlockLeftEntries'
  block: Array<Maybe<Entry>>
  hyperlink: Array<Maybe<Entry>>
  inline: Array<Maybe<Entry>>
}

export type PagesTwoColumnCtaTextBlockLeftLinks = {
  __typename?: 'PagesTwoColumnCtaTextBlockLeftLinks'
  assets: PagesTwoColumnCtaTextBlockLeftAssets
  entries: PagesTwoColumnCtaTextBlockLeftEntries
  resources: PagesTwoColumnCtaTextBlockLeftResources
}

export type PagesTwoColumnCtaTextBlockLeftResources = {
  __typename?: 'PagesTwoColumnCtaTextBlockLeftResources'
  block: Array<PagesTwoColumnCtaTextBlockLeftResourcesBlock>
  hyperlink: Array<PagesTwoColumnCtaTextBlockLeftResourcesHyperlink>
  inline: Array<PagesTwoColumnCtaTextBlockLeftResourcesInline>
}

export type PagesTwoColumnCtaTextBlockLeftResourcesBlock = ResourceLink & {
  __typename?: 'PagesTwoColumnCtaTextBlockLeftResourcesBlock'
  sys: ResourceSys
}

export type PagesTwoColumnCtaTextBlockLeftResourcesHyperlink = ResourceLink & {
  __typename?: 'PagesTwoColumnCtaTextBlockLeftResourcesHyperlink'
  sys: ResourceSys
}

export type PagesTwoColumnCtaTextBlockLeftResourcesInline = ResourceLink & {
  __typename?: 'PagesTwoColumnCtaTextBlockLeftResourcesInline'
  sys: ResourceSys
}

export type PagesTwoColumnCtaTextUnderneathButton = {
  __typename?: 'PagesTwoColumnCtaTextUnderneathButton'
  json: Scalars['JSON']
  links: PagesTwoColumnCtaTextUnderneathButtonLinks
}

export type PagesTwoColumnCtaTextUnderneathButtonAssets = {
  __typename?: 'PagesTwoColumnCtaTextUnderneathButtonAssets'
  block: Array<Maybe<Asset>>
  hyperlink: Array<Maybe<Asset>>
}

export type PagesTwoColumnCtaTextUnderneathButtonEntries = {
  __typename?: 'PagesTwoColumnCtaTextUnderneathButtonEntries'
  block: Array<Maybe<Entry>>
  hyperlink: Array<Maybe<Entry>>
  inline: Array<Maybe<Entry>>
}

export type PagesTwoColumnCtaTextUnderneathButtonLinks = {
  __typename?: 'PagesTwoColumnCtaTextUnderneathButtonLinks'
  assets: PagesTwoColumnCtaTextUnderneathButtonAssets
  entries: PagesTwoColumnCtaTextUnderneathButtonEntries
  resources: PagesTwoColumnCtaTextUnderneathButtonResources
}

export type PagesTwoColumnCtaTextUnderneathButtonResources = {
  __typename?: 'PagesTwoColumnCtaTextUnderneathButtonResources'
  block: Array<PagesTwoColumnCtaTextUnderneathButtonResourcesBlock>
  hyperlink: Array<PagesTwoColumnCtaTextUnderneathButtonResourcesHyperlink>
  inline: Array<PagesTwoColumnCtaTextUnderneathButtonResourcesInline>
}

export type PagesTwoColumnCtaTextUnderneathButtonResourcesBlock =
  ResourceLink & {
    __typename?: 'PagesTwoColumnCtaTextUnderneathButtonResourcesBlock'
    sys: ResourceSys
  }

export type PagesTwoColumnCtaTextUnderneathButtonResourcesHyperlink =
  ResourceLink & {
    __typename?: 'PagesTwoColumnCtaTextUnderneathButtonResourcesHyperlink'
    sys: ResourceSys
  }

export type PagesTwoColumnCtaTextUnderneathButtonResourcesInline =
  ResourceLink & {
    __typename?: 'PagesTwoColumnCtaTextUnderneathButtonResourcesInline'
    sys: ResourceSys
  }

/** Press article items published about Fora around the web. [See type definition](https://app.contentful.com/spaces/9cxgygtadmvl/content_types/press) */
export type Press = Entry &
  _Node & {
    __typename?: 'Press'
    _id: Scalars['ID']
    contentfulMetadata: ContentfulMetadata
    externalUrl?: Maybe<Scalars['String']>
    linkedFrom?: Maybe<PressLinkingCollections>
    mainImage?: Maybe<Asset>
    publishDate?: Maybe<Scalars['DateTime']>
    sys: Sys
    title?: Maybe<Scalars['String']>
  }

/** Press article items published about Fora around the web. [See type definition](https://app.contentful.com/spaces/9cxgygtadmvl/content_types/press) */
export type PressExternalUrlArgs = {
  locale?: InputMaybe<Scalars['String']>
}

/** Press article items published about Fora around the web. [See type definition](https://app.contentful.com/spaces/9cxgygtadmvl/content_types/press) */
export type PressLinkedFromArgs = {
  allowedLocales?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
}

/** Press article items published about Fora around the web. [See type definition](https://app.contentful.com/spaces/9cxgygtadmvl/content_types/press) */
export type PressMainImageArgs = {
  locale?: InputMaybe<Scalars['String']>
  preview?: InputMaybe<Scalars['Boolean']>
}

/** Press article items published about Fora around the web. [See type definition](https://app.contentful.com/spaces/9cxgygtadmvl/content_types/press) */
export type PressPublishDateArgs = {
  locale?: InputMaybe<Scalars['String']>
}

/** Press article items published about Fora around the web. [See type definition](https://app.contentful.com/spaces/9cxgygtadmvl/content_types/press) */
export type PressTitleArgs = {
  locale?: InputMaybe<Scalars['String']>
}

export type PressCollection = {
  __typename?: 'PressCollection'
  items: Array<Maybe<Press>>
  limit: Scalars['Int']
  skip: Scalars['Int']
  total: Scalars['Int']
}

export type PressFilter = {
  AND?: InputMaybe<Array<InputMaybe<PressFilter>>>
  OR?: InputMaybe<Array<InputMaybe<PressFilter>>>
  contentfulMetadata?: InputMaybe<ContentfulMetadataFilter>
  externalUrl?: InputMaybe<Scalars['String']>
  externalUrl_contains?: InputMaybe<Scalars['String']>
  externalUrl_exists?: InputMaybe<Scalars['Boolean']>
  externalUrl_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  externalUrl_not?: InputMaybe<Scalars['String']>
  externalUrl_not_contains?: InputMaybe<Scalars['String']>
  externalUrl_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  mainImage_exists?: InputMaybe<Scalars['Boolean']>
  publishDate?: InputMaybe<Scalars['DateTime']>
  publishDate_exists?: InputMaybe<Scalars['Boolean']>
  publishDate_gt?: InputMaybe<Scalars['DateTime']>
  publishDate_gte?: InputMaybe<Scalars['DateTime']>
  publishDate_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']>>>
  publishDate_lt?: InputMaybe<Scalars['DateTime']>
  publishDate_lte?: InputMaybe<Scalars['DateTime']>
  publishDate_not?: InputMaybe<Scalars['DateTime']>
  publishDate_not_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']>>>
  sys?: InputMaybe<SysFilter>
  title?: InputMaybe<Scalars['String']>
  title_contains?: InputMaybe<Scalars['String']>
  title_exists?: InputMaybe<Scalars['Boolean']>
  title_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  title_not?: InputMaybe<Scalars['String']>
  title_not_contains?: InputMaybe<Scalars['String']>
  title_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
}

export type PressLinkingCollections = {
  __typename?: 'PressLinkingCollections'
  entryCollection?: Maybe<EntryCollection>
}

export type PressLinkingCollectionsEntryCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>
  locale?: InputMaybe<Scalars['String']>
  preview?: InputMaybe<Scalars['Boolean']>
  skip?: InputMaybe<Scalars['Int']>
}

export enum PressOrder {
  ExternalUrlAsc = 'externalUrl_ASC',
  ExternalUrlDesc = 'externalUrl_DESC',
  PublishDateAsc = 'publishDate_ASC',
  PublishDateDesc = 'publishDate_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC',
}

export type Query = {
  __typename?: 'Query'
  _node?: Maybe<_Node>
  advisorReviews?: Maybe<AdvisorReviews>
  advisorReviewsCollection?: Maybe<AdvisorReviewsCollection>
  advisors?: Maybe<Advisors>
  advisorsCollection?: Maybe<AdvisorsCollection>
  asset?: Maybe<Asset>
  assetCollection?: Maybe<AssetCollection>
  blogAuthors?: Maybe<BlogAuthors>
  blogAuthorsCollection?: Maybe<BlogAuthorsCollection>
  blogPosts?: Maybe<BlogPosts>
  blogPostsCollection?: Maybe<BlogPostsCollection>
  brynetteMediaTest?: Maybe<BrynetteMediaTest>
  brynetteMediaTestCollection?: Maybe<BrynetteMediaTestCollection>
  campaignPages?: Maybe<CampaignPages>
  campaignPagesCollection?: Maybe<CampaignPagesCollection>
  cloudinaryAssets?: Maybe<CloudinaryAssets>
  cloudinaryAssetsCollection?: Maybe<CloudinaryAssetsCollection>
  componentAdvisorPlanRow?: Maybe<ComponentAdvisorPlanRow>
  componentAdvisorPlanRowCollection?: Maybe<ComponentAdvisorPlanRowCollection>
  componentAdvisorPlanSection?: Maybe<ComponentAdvisorPlanSection>
  componentAdvisorPlanSectionCollection?: Maybe<ComponentAdvisorPlanSectionCollection>
  componentAdvisorPlanTable?: Maybe<ComponentAdvisorPlanTable>
  componentAdvisorPlanTableCollection?: Maybe<ComponentAdvisorPlanTableCollection>
  componentAdvisorReviews?: Maybe<ComponentAdvisorReviews>
  componentAdvisorReviewsCollection?: Maybe<ComponentAdvisorReviewsCollection>
  componentAnimatedTiles?: Maybe<ComponentAnimatedTiles>
  componentAnimatedTilesCollection?: Maybe<ComponentAnimatedTilesCollection>
  componentButtonCta?: Maybe<ComponentButtonCta>
  componentButtonCtaCollection?: Maybe<ComponentButtonCtaCollection>
  componentCarouselItem?: Maybe<ComponentCarouselItem>
  componentCarouselItemCollection?: Maybe<ComponentCarouselItemCollection>
  componentCarouselSection?: Maybe<ComponentCarouselSection>
  componentCarouselSectionCollection?: Maybe<ComponentCarouselSectionCollection>
  componentCenteredHero?: Maybe<ComponentCenteredHero>
  componentCenteredHeroCollection?: Maybe<ComponentCenteredHeroCollection>
  componentCheckeredCallout?: Maybe<ComponentCheckeredCallout>
  componentCheckeredCalloutCollection?: Maybe<ComponentCheckeredCalloutCollection>
  componentComparisonTable?: Maybe<ComponentComparisonTable>
  componentComparisonTableCollection?: Maybe<ComponentComparisonTableCollection>
  componentComparisonTableRow?: Maybe<ComponentComparisonTableRow>
  componentComparisonTableRowCollection?: Maybe<ComponentComparisonTableRowCollection>
  componentCtaBanner?: Maybe<ComponentCtaBanner>
  componentCtaBannerCollection?: Maybe<ComponentCtaBannerCollection>
  componentDataBanner?: Maybe<ComponentDataBanner>
  componentDataBannerCollection?: Maybe<ComponentDataBannerCollection>
  componentDataItem?: Maybe<ComponentDataItem>
  componentDataItemCollection?: Maybe<ComponentDataItemCollection>
  componentFeaturedAdvisors?: Maybe<ComponentFeaturedAdvisors>
  componentFeaturedAdvisorsCollection?: Maybe<ComponentFeaturedAdvisorsCollection>
  componentFeaturedArticles?: Maybe<ComponentFeaturedArticles>
  componentFeaturedArticlesCollection?: Maybe<ComponentFeaturedArticlesCollection>
  componentFeaturedGuides?: Maybe<ComponentFeaturedGuides>
  componentFeaturedGuidesCollection?: Maybe<ComponentFeaturedGuidesCollection>
  componentFeaturedReviews?: Maybe<ComponentFeaturedReviews>
  componentFeaturedReviewsCollection?: Maybe<ComponentFeaturedReviewsCollection>
  componentForaLogo?: Maybe<ComponentForaLogo>
  componentForaLogoCollection?: Maybe<ComponentForaLogoCollection>
  componentForm?: Maybe<ComponentForm>
  componentFormCollection?: Maybe<ComponentFormCollection>
  componentGlobalBanner?: Maybe<ComponentGlobalBanner>
  componentGlobalBannerCollection?: Maybe<ComponentGlobalBannerCollection>
  componentHeroCentered?: Maybe<ComponentHeroCentered>
  componentHeroCenteredCollection?: Maybe<ComponentHeroCenteredCollection>
  componentHeroFullWidth?: Maybe<ComponentHeroFullWidth>
  componentHeroFullWidthCollection?: Maybe<ComponentHeroFullWidthCollection>
  componentImage?: Maybe<ComponentImage>
  componentImageCollection?: Maybe<ComponentImageCollection>
  componentImageGalleryCarousel?: Maybe<ComponentImageGalleryCarousel>
  componentImageGalleryCarouselCollection?: Maybe<ComponentImageGalleryCarouselCollection>
  componentImageGallerySlideItem?: Maybe<ComponentImageGallerySlideItem>
  componentImageGallerySlideItemCollection?: Maybe<ComponentImageGallerySlideItemCollection>
  componentImageHeaderSub?: Maybe<ComponentImageHeaderSub>
  componentImageHeaderSubCollection?: Maybe<ComponentImageHeaderSubCollection>
  componentImageSideBySide?: Maybe<ComponentImageSideBySide>
  componentImageSideBySideCollection?: Maybe<ComponentImageSideBySideCollection>
  componentInfoGrid?: Maybe<ComponentInfoGrid>
  componentInfoGridCollection?: Maybe<ComponentInfoGridCollection>
  componentLargePhotoCarouselItem?: Maybe<ComponentLargePhotoCarouselItem>
  componentLargePhotoCarouselItemCollection?: Maybe<ComponentLargePhotoCarouselItemCollection>
  componentPartnerGrid?: Maybe<ComponentPartnerGrid>
  componentPartnerGridCollection?: Maybe<ComponentPartnerGridCollection>
  componentPictureSectionBanner?: Maybe<ComponentPictureSectionBanner>
  componentPictureSectionBannerCollection?: Maybe<ComponentPictureSectionBannerCollection>
  componentReviewCarousel?: Maybe<ComponentReviewCarousel>
  componentReviewCarouselCollection?: Maybe<ComponentReviewCarouselCollection>
  componentReviews?: Maybe<ComponentReviews>
  componentReviewsCollection?: Maybe<ComponentReviewsCollection>
  componentSingleUse?: Maybe<ComponentSingleUse>
  componentSingleUseCollection?: Maybe<ComponentSingleUseCollection>
  componentSpacer?: Maybe<ComponentSpacer>
  componentSpacerCollection?: Maybe<ComponentSpacerCollection>
  componentStickyHeader?: Maybe<ComponentStickyHeader>
  componentStickyHeaderCollection?: Maybe<ComponentStickyHeaderCollection>
  componentSubNavBar?: Maybe<ComponentSubNavBar>
  componentSubNavBarCollection?: Maybe<ComponentSubNavBarCollection>
  componentSubNavItem?: Maybe<ComponentSubNavItem>
  componentSubNavItemCollection?: Maybe<ComponentSubNavItemCollection>
  componentSubscriptionCard?: Maybe<ComponentSubscriptionCard>
  componentSubscriptionCardCollection?: Maybe<ComponentSubscriptionCardCollection>
  componentTeamSection?: Maybe<ComponentTeamSection>
  componentTeamSectionCollection?: Maybe<ComponentTeamSectionCollection>
  componentTestimonialCarousel?: Maybe<ComponentTestimonialCarousel>
  componentTestimonialCarouselCollection?: Maybe<ComponentTestimonialCarouselCollection>
  componentTextSection?: Maybe<ComponentTextSection>
  componentTextSectionCollection?: Maybe<ComponentTextSectionCollection>
  componentThreeIconSection?: Maybe<ComponentThreeIconSection>
  componentThreeIconSectionCollection?: Maybe<ComponentThreeIconSectionCollection>
  componentThreeIconSectionWithPicture?: Maybe<ComponentThreeIconSectionWithPicture>
  componentThreeIconSectionWithPictureCollection?: Maybe<ComponentThreeIconSectionWithPictureCollection>
  componentTwoColumnCallout?: Maybe<ComponentTwoColumnCallout>
  componentTwoColumnCalloutCollection?: Maybe<ComponentTwoColumnCalloutCollection>
  componentTwoColumnInfographic?: Maybe<ComponentTwoColumnInfographic>
  componentTwoColumnInfographicCollection?: Maybe<ComponentTwoColumnInfographicCollection>
  componentTwoColumnPerksSection?: Maybe<ComponentTwoColumnPerksSection>
  componentTwoColumnPerksSectionCollection?: Maybe<ComponentTwoColumnPerksSectionCollection>
  componentTwoColumnTextSection?: Maybe<ComponentTwoColumnTextSection>
  componentTwoColumnTextSectionCollection?: Maybe<ComponentTwoColumnTextSectionCollection>
  componentTypeformEmbed?: Maybe<ComponentTypeformEmbed>
  componentTypeformEmbedCollection?: Maybe<ComponentTypeformEmbedCollection>
  componentVideoSection?: Maybe<ComponentVideoSection>
  componentVideoSectionCollection?: Maybe<ComponentVideoSectionCollection>
  componentWhatYouGetCard?: Maybe<ComponentWhatYouGetCard>
  componentWhatYouGetCardCollection?: Maybe<ComponentWhatYouGetCardCollection>
  componentWhatYouGetModule?: Maybe<ComponentWhatYouGetModule>
  componentWhatYouGetModuleCollection?: Maybe<ComponentWhatYouGetModuleCollection>
  destinationsCategories?: Maybe<DestinationsCategories>
  destinationsCategoriesCollection?: Maybe<DestinationsCategoriesCollection>
  destinationsSubcategories?: Maybe<DestinationsSubcategories>
  destinationsSubcategoriesCollection?: Maybe<DestinationsSubcategoriesCollection>
  entryCollection?: Maybe<EntryCollection>
  entryPartner?: Maybe<EntryPartner>
  entryPartnerCollection?: Maybe<EntryPartnerCollection>
  entryTestimonial?: Maybe<EntryTestimonial>
  entryTestimonialCollection?: Maybe<EntryTestimonialCollection>
  entryTripReport?: Maybe<EntryTripReport>
  entryTripReportCollection?: Maybe<EntryTripReportCollection>
  events?: Maybe<Events>
  eventsCollection?: Maybe<EventsCollection>
  faqsQuestionGroups?: Maybe<FaqsQuestionGroups>
  faqsQuestionGroupsCollection?: Maybe<FaqsQuestionGroupsCollection>
  faqsQuestions?: Maybe<FaqsQuestions>
  faqsQuestionsCollection?: Maybe<FaqsQuestionsCollection>
  hotels?: Maybe<Hotels>
  hotelsCollection?: Maybe<HotelsCollection>
  itineraries?: Maybe<Itineraries>
  itinerariesCollection?: Maybe<ItinerariesCollection>
  itinerariesPlans?: Maybe<ItinerariesPlans>
  itinerariesPlansCollection?: Maybe<ItinerariesPlansCollection>
  pagesFeaturedHotels?: Maybe<PagesFeaturedHotels>
  pagesFeaturedHotelsCollection?: Maybe<PagesFeaturedHotelsCollection>
  pagesHeaderBlock?: Maybe<PagesHeaderBlock>
  pagesHeaderBlockCollection?: Maybe<PagesHeaderBlockCollection>
  pagesHeroBlock?: Maybe<PagesHeroBlock>
  pagesHeroBlockCollection?: Maybe<PagesHeroBlockCollection>
  pagesLearnMoreBlock?: Maybe<PagesLearnMoreBlock>
  pagesLearnMoreBlockCollection?: Maybe<PagesLearnMoreBlockCollection>
  pagesMetaContent?: Maybe<PagesMetaContent>
  pagesMetaContentCollection?: Maybe<PagesMetaContentCollection>
  pagesPartnerBanner?: Maybe<PagesPartnerBanner>
  pagesPartnerBannerCollection?: Maybe<PagesPartnerBannerCollection>
  pagesPerksList?: Maybe<PagesPerksList>
  pagesPerksListCollection?: Maybe<PagesPerksListCollection>
  pagesPressBanner?: Maybe<PagesPressBanner>
  pagesPressBannerCollection?: Maybe<PagesPressBannerCollection>
  pagesTwoColumnCta?: Maybe<PagesTwoColumnCta>
  pagesTwoColumnCtaCollection?: Maybe<PagesTwoColumnCtaCollection>
  press?: Maybe<Press>
  pressCollection?: Maybe<PressCollection>
  settingsAnalyticsEvent?: Maybe<SettingsAnalyticsEvent>
  settingsAnalyticsEventCollection?: Maybe<SettingsAnalyticsEventCollection>
  settingsAnalyticsModule?: Maybe<SettingsAnalyticsModule>
  settingsAnalyticsModuleCollection?: Maybe<SettingsAnalyticsModuleCollection>
  singleLine?: Maybe<SingleLine>
  singleLineCollection?: Maybe<SingleLineCollection>
  team?: Maybe<Team>
  teamCollection?: Maybe<TeamCollection>
  templateAboutUs?: Maybe<TemplateAboutUs>
  templateAboutUsCollection?: Maybe<TemplateAboutUsCollection>
  templateAdvisors?: Maybe<TemplateAdvisors>
  templateAdvisorsCollection?: Maybe<TemplateAdvisorsCollection>
  templateBlog?: Maybe<TemplateBlog>
  templateBlogCollection?: Maybe<TemplateBlogCollection>
  templateBooking?: Maybe<TemplateBooking>
  templateBookingCollection?: Maybe<TemplateBookingCollection>
  templateCareers?: Maybe<TemplateCareers>
  templateCareersCollection?: Maybe<TemplateCareersCollection>
  templateContactUs?: Maybe<TemplateContactUs>
  templateContactUsCollection?: Maybe<TemplateContactUsCollection>
  templateDestinations?: Maybe<TemplateDestinations>
  templateDestinationsCollection?: Maybe<TemplateDestinationsCollection>
  templateEvents?: Maybe<TemplateEvents>
  templateEventsCollection?: Maybe<TemplateEventsCollection>
  templateFaqs?: Maybe<TemplateFaqs>
  templateFaqsCollection?: Maybe<TemplateFaqsCollection>
  templateForaReserve?: Maybe<TemplateForaReserve>
  templateForaReserveCollection?: Maybe<TemplateForaReserveCollection>
  templateForaX?: Maybe<TemplateForaX>
  templateForaXCollection?: Maybe<TemplateForaXCollection>
  templateGuides?: Maybe<TemplateGuides>
  templateGuidesCollection?: Maybe<TemplateGuidesCollection>
  templateHome?: Maybe<TemplateHome>
  templateHomeCollection?: Maybe<TemplateHomeCollection>
  templateItineraries?: Maybe<TemplateItineraries>
  templateItinerariesCollection?: Maybe<TemplateItinerariesCollection>
  templateJoinUs?: Maybe<TemplateJoinUs>
  templateJoinUsCollection?: Maybe<TemplateJoinUsCollection>
  templateNewsletter?: Maybe<TemplateNewsletter>
  templateNewsletterCollection?: Maybe<TemplateNewsletterCollection>
  templatePage?: Maybe<TemplatePage>
  templatePageCollection?: Maybe<TemplatePageCollection>
  templatePartnerDetail?: Maybe<TemplatePartnerDetail>
  templatePartnerDetailCollection?: Maybe<TemplatePartnerDetailCollection>
  templatePartners?: Maybe<TemplatePartners>
  templatePartnersCollection?: Maybe<TemplatePartnersCollection>
  templatePress?: Maybe<TemplatePress>
  templatePressCollection?: Maybe<TemplatePressCollection>
  templatePrivacyPolicy?: Maybe<TemplatePrivacyPolicy>
  templatePrivacyPolicyCollection?: Maybe<TemplatePrivacyPolicyCollection>
  templateReviews?: Maybe<TemplateReviews>
  templateReviewsCollection?: Maybe<TemplateReviewsCollection>
  templateSearch?: Maybe<TemplateSearch>
  templateSearchCollection?: Maybe<TemplateSearchCollection>
  templateTermsAndConditions?: Maybe<TemplateTermsAndConditions>
  templateTermsAndConditionsCollection?: Maybe<TemplateTermsAndConditionsCollection>
  templateTravel?: Maybe<TemplateTravel>
  templateTravelBy?: Maybe<TemplateTravelBy>
  templateTravelByCollection?: Maybe<TemplateTravelByCollection>
  templateTravelCollection?: Maybe<TemplateTravelCollection>
  templateWhyBookWithFora?: Maybe<TemplateWhyBookWithFora>
  templateWhyBookWithForaCollection?: Maybe<TemplateWhyBookWithForaCollection>
  templatesGlobalConfigs?: Maybe<TemplatesGlobalConfigs>
  templatesGlobalConfigsCollection?: Maybe<TemplatesGlobalConfigsCollection>
  topHotelsByDestination?: Maybe<TopHotelsByDestination>
  topHotelsByDestinationCollection?: Maybe<TopHotelsByDestinationCollection>
  travelByCategories?: Maybe<TravelByCategories>
  travelByCategoriesCollection?: Maybe<TravelByCategoriesCollection>
  travelBySubcategories?: Maybe<TravelBySubcategories>
  travelBySubcategoriesCollection?: Maybe<TravelBySubcategoriesCollection>
}

export type Query_NodeArgs = {
  id: Scalars['ID']
  locale?: InputMaybe<Scalars['String']>
  preview?: InputMaybe<Scalars['Boolean']>
}

export type QueryAdvisorReviewsArgs = {
  id: Scalars['String']
  locale?: InputMaybe<Scalars['String']>
  preview?: InputMaybe<Scalars['Boolean']>
}

export type QueryAdvisorReviewsCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>
  locale?: InputMaybe<Scalars['String']>
  order?: InputMaybe<Array<InputMaybe<AdvisorReviewsOrder>>>
  preview?: InputMaybe<Scalars['Boolean']>
  skip?: InputMaybe<Scalars['Int']>
  where?: InputMaybe<AdvisorReviewsFilter>
}

export type QueryAdvisorsArgs = {
  id: Scalars['String']
  locale?: InputMaybe<Scalars['String']>
  preview?: InputMaybe<Scalars['Boolean']>
}

export type QueryAdvisorsCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>
  locale?: InputMaybe<Scalars['String']>
  order?: InputMaybe<Array<InputMaybe<AdvisorsOrder>>>
  preview?: InputMaybe<Scalars['Boolean']>
  skip?: InputMaybe<Scalars['Int']>
  where?: InputMaybe<AdvisorsFilter>
}

export type QueryAssetArgs = {
  id: Scalars['String']
  locale?: InputMaybe<Scalars['String']>
  preview?: InputMaybe<Scalars['Boolean']>
}

export type QueryAssetCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>
  locale?: InputMaybe<Scalars['String']>
  order?: InputMaybe<Array<InputMaybe<AssetOrder>>>
  preview?: InputMaybe<Scalars['Boolean']>
  skip?: InputMaybe<Scalars['Int']>
  where?: InputMaybe<AssetFilter>
}

export type QueryBlogAuthorsArgs = {
  id: Scalars['String']
  locale?: InputMaybe<Scalars['String']>
  preview?: InputMaybe<Scalars['Boolean']>
}

export type QueryBlogAuthorsCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>
  locale?: InputMaybe<Scalars['String']>
  order?: InputMaybe<Array<InputMaybe<BlogAuthorsOrder>>>
  preview?: InputMaybe<Scalars['Boolean']>
  skip?: InputMaybe<Scalars['Int']>
  where?: InputMaybe<BlogAuthorsFilter>
}

export type QueryBlogPostsArgs = {
  id: Scalars['String']
  locale?: InputMaybe<Scalars['String']>
  preview?: InputMaybe<Scalars['Boolean']>
}

export type QueryBlogPostsCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>
  locale?: InputMaybe<Scalars['String']>
  order?: InputMaybe<Array<InputMaybe<BlogPostsOrder>>>
  preview?: InputMaybe<Scalars['Boolean']>
  skip?: InputMaybe<Scalars['Int']>
  where?: InputMaybe<BlogPostsFilter>
}

export type QueryBrynetteMediaTestArgs = {
  id: Scalars['String']
  locale?: InputMaybe<Scalars['String']>
  preview?: InputMaybe<Scalars['Boolean']>
}

export type QueryBrynetteMediaTestCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>
  locale?: InputMaybe<Scalars['String']>
  order?: InputMaybe<Array<InputMaybe<BrynetteMediaTestOrder>>>
  preview?: InputMaybe<Scalars['Boolean']>
  skip?: InputMaybe<Scalars['Int']>
  where?: InputMaybe<BrynetteMediaTestFilter>
}

export type QueryCampaignPagesArgs = {
  id: Scalars['String']
  locale?: InputMaybe<Scalars['String']>
  preview?: InputMaybe<Scalars['Boolean']>
}

export type QueryCampaignPagesCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>
  locale?: InputMaybe<Scalars['String']>
  order?: InputMaybe<Array<InputMaybe<CampaignPagesOrder>>>
  preview?: InputMaybe<Scalars['Boolean']>
  skip?: InputMaybe<Scalars['Int']>
  where?: InputMaybe<CampaignPagesFilter>
}

export type QueryCloudinaryAssetsArgs = {
  id: Scalars['String']
  locale?: InputMaybe<Scalars['String']>
  preview?: InputMaybe<Scalars['Boolean']>
}

export type QueryCloudinaryAssetsCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>
  locale?: InputMaybe<Scalars['String']>
  order?: InputMaybe<Array<InputMaybe<CloudinaryAssetsOrder>>>
  preview?: InputMaybe<Scalars['Boolean']>
  skip?: InputMaybe<Scalars['Int']>
  where?: InputMaybe<CloudinaryAssetsFilter>
}

export type QueryComponentAdvisorPlanRowArgs = {
  id: Scalars['String']
  locale?: InputMaybe<Scalars['String']>
  preview?: InputMaybe<Scalars['Boolean']>
}

export type QueryComponentAdvisorPlanRowCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>
  locale?: InputMaybe<Scalars['String']>
  order?: InputMaybe<Array<InputMaybe<ComponentAdvisorPlanRowOrder>>>
  preview?: InputMaybe<Scalars['Boolean']>
  skip?: InputMaybe<Scalars['Int']>
  where?: InputMaybe<ComponentAdvisorPlanRowFilter>
}

export type QueryComponentAdvisorPlanSectionArgs = {
  id: Scalars['String']
  locale?: InputMaybe<Scalars['String']>
  preview?: InputMaybe<Scalars['Boolean']>
}

export type QueryComponentAdvisorPlanSectionCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>
  locale?: InputMaybe<Scalars['String']>
  order?: InputMaybe<Array<InputMaybe<ComponentAdvisorPlanSectionOrder>>>
  preview?: InputMaybe<Scalars['Boolean']>
  skip?: InputMaybe<Scalars['Int']>
  where?: InputMaybe<ComponentAdvisorPlanSectionFilter>
}

export type QueryComponentAdvisorPlanTableArgs = {
  id: Scalars['String']
  locale?: InputMaybe<Scalars['String']>
  preview?: InputMaybe<Scalars['Boolean']>
}

export type QueryComponentAdvisorPlanTableCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>
  locale?: InputMaybe<Scalars['String']>
  order?: InputMaybe<Array<InputMaybe<ComponentAdvisorPlanTableOrder>>>
  preview?: InputMaybe<Scalars['Boolean']>
  skip?: InputMaybe<Scalars['Int']>
  where?: InputMaybe<ComponentAdvisorPlanTableFilter>
}

export type QueryComponentAdvisorReviewsArgs = {
  id: Scalars['String']
  locale?: InputMaybe<Scalars['String']>
  preview?: InputMaybe<Scalars['Boolean']>
}

export type QueryComponentAdvisorReviewsCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>
  locale?: InputMaybe<Scalars['String']>
  order?: InputMaybe<Array<InputMaybe<ComponentAdvisorReviewsOrder>>>
  preview?: InputMaybe<Scalars['Boolean']>
  skip?: InputMaybe<Scalars['Int']>
  where?: InputMaybe<ComponentAdvisorReviewsFilter>
}

export type QueryComponentAnimatedTilesArgs = {
  id: Scalars['String']
  locale?: InputMaybe<Scalars['String']>
  preview?: InputMaybe<Scalars['Boolean']>
}

export type QueryComponentAnimatedTilesCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>
  locale?: InputMaybe<Scalars['String']>
  order?: InputMaybe<Array<InputMaybe<ComponentAnimatedTilesOrder>>>
  preview?: InputMaybe<Scalars['Boolean']>
  skip?: InputMaybe<Scalars['Int']>
  where?: InputMaybe<ComponentAnimatedTilesFilter>
}

export type QueryComponentButtonCtaArgs = {
  id: Scalars['String']
  locale?: InputMaybe<Scalars['String']>
  preview?: InputMaybe<Scalars['Boolean']>
}

export type QueryComponentButtonCtaCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>
  locale?: InputMaybe<Scalars['String']>
  order?: InputMaybe<Array<InputMaybe<ComponentButtonCtaOrder>>>
  preview?: InputMaybe<Scalars['Boolean']>
  skip?: InputMaybe<Scalars['Int']>
  where?: InputMaybe<ComponentButtonCtaFilter>
}

export type QueryComponentCarouselItemArgs = {
  id: Scalars['String']
  locale?: InputMaybe<Scalars['String']>
  preview?: InputMaybe<Scalars['Boolean']>
}

export type QueryComponentCarouselItemCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>
  locale?: InputMaybe<Scalars['String']>
  order?: InputMaybe<Array<InputMaybe<ComponentCarouselItemOrder>>>
  preview?: InputMaybe<Scalars['Boolean']>
  skip?: InputMaybe<Scalars['Int']>
  where?: InputMaybe<ComponentCarouselItemFilter>
}

export type QueryComponentCarouselSectionArgs = {
  id: Scalars['String']
  locale?: InputMaybe<Scalars['String']>
  preview?: InputMaybe<Scalars['Boolean']>
}

export type QueryComponentCarouselSectionCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>
  locale?: InputMaybe<Scalars['String']>
  order?: InputMaybe<Array<InputMaybe<ComponentCarouselSectionOrder>>>
  preview?: InputMaybe<Scalars['Boolean']>
  skip?: InputMaybe<Scalars['Int']>
  where?: InputMaybe<ComponentCarouselSectionFilter>
}

export type QueryComponentCenteredHeroArgs = {
  id: Scalars['String']
  locale?: InputMaybe<Scalars['String']>
  preview?: InputMaybe<Scalars['Boolean']>
}

export type QueryComponentCenteredHeroCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>
  locale?: InputMaybe<Scalars['String']>
  order?: InputMaybe<Array<InputMaybe<ComponentCenteredHeroOrder>>>
  preview?: InputMaybe<Scalars['Boolean']>
  skip?: InputMaybe<Scalars['Int']>
  where?: InputMaybe<ComponentCenteredHeroFilter>
}

export type QueryComponentCheckeredCalloutArgs = {
  id: Scalars['String']
  locale?: InputMaybe<Scalars['String']>
  preview?: InputMaybe<Scalars['Boolean']>
}

export type QueryComponentCheckeredCalloutCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>
  locale?: InputMaybe<Scalars['String']>
  order?: InputMaybe<Array<InputMaybe<ComponentCheckeredCalloutOrder>>>
  preview?: InputMaybe<Scalars['Boolean']>
  skip?: InputMaybe<Scalars['Int']>
  where?: InputMaybe<ComponentCheckeredCalloutFilter>
}

export type QueryComponentComparisonTableArgs = {
  id: Scalars['String']
  locale?: InputMaybe<Scalars['String']>
  preview?: InputMaybe<Scalars['Boolean']>
}

export type QueryComponentComparisonTableCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>
  locale?: InputMaybe<Scalars['String']>
  order?: InputMaybe<Array<InputMaybe<ComponentComparisonTableOrder>>>
  preview?: InputMaybe<Scalars['Boolean']>
  skip?: InputMaybe<Scalars['Int']>
  where?: InputMaybe<ComponentComparisonTableFilter>
}

export type QueryComponentComparisonTableRowArgs = {
  id: Scalars['String']
  locale?: InputMaybe<Scalars['String']>
  preview?: InputMaybe<Scalars['Boolean']>
}

export type QueryComponentComparisonTableRowCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>
  locale?: InputMaybe<Scalars['String']>
  order?: InputMaybe<Array<InputMaybe<ComponentComparisonTableRowOrder>>>
  preview?: InputMaybe<Scalars['Boolean']>
  skip?: InputMaybe<Scalars['Int']>
  where?: InputMaybe<ComponentComparisonTableRowFilter>
}

export type QueryComponentCtaBannerArgs = {
  id: Scalars['String']
  locale?: InputMaybe<Scalars['String']>
  preview?: InputMaybe<Scalars['Boolean']>
}

export type QueryComponentCtaBannerCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>
  locale?: InputMaybe<Scalars['String']>
  order?: InputMaybe<Array<InputMaybe<ComponentCtaBannerOrder>>>
  preview?: InputMaybe<Scalars['Boolean']>
  skip?: InputMaybe<Scalars['Int']>
  where?: InputMaybe<ComponentCtaBannerFilter>
}

export type QueryComponentDataBannerArgs = {
  id: Scalars['String']
  locale?: InputMaybe<Scalars['String']>
  preview?: InputMaybe<Scalars['Boolean']>
}

export type QueryComponentDataBannerCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>
  locale?: InputMaybe<Scalars['String']>
  order?: InputMaybe<Array<InputMaybe<ComponentDataBannerOrder>>>
  preview?: InputMaybe<Scalars['Boolean']>
  skip?: InputMaybe<Scalars['Int']>
  where?: InputMaybe<ComponentDataBannerFilter>
}

export type QueryComponentDataItemArgs = {
  id: Scalars['String']
  locale?: InputMaybe<Scalars['String']>
  preview?: InputMaybe<Scalars['Boolean']>
}

export type QueryComponentDataItemCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>
  locale?: InputMaybe<Scalars['String']>
  order?: InputMaybe<Array<InputMaybe<ComponentDataItemOrder>>>
  preview?: InputMaybe<Scalars['Boolean']>
  skip?: InputMaybe<Scalars['Int']>
  where?: InputMaybe<ComponentDataItemFilter>
}

export type QueryComponentFeaturedAdvisorsArgs = {
  id: Scalars['String']
  locale?: InputMaybe<Scalars['String']>
  preview?: InputMaybe<Scalars['Boolean']>
}

export type QueryComponentFeaturedAdvisorsCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>
  locale?: InputMaybe<Scalars['String']>
  order?: InputMaybe<Array<InputMaybe<ComponentFeaturedAdvisorsOrder>>>
  preview?: InputMaybe<Scalars['Boolean']>
  skip?: InputMaybe<Scalars['Int']>
  where?: InputMaybe<ComponentFeaturedAdvisorsFilter>
}

export type QueryComponentFeaturedArticlesArgs = {
  id: Scalars['String']
  locale?: InputMaybe<Scalars['String']>
  preview?: InputMaybe<Scalars['Boolean']>
}

export type QueryComponentFeaturedArticlesCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>
  locale?: InputMaybe<Scalars['String']>
  order?: InputMaybe<Array<InputMaybe<ComponentFeaturedArticlesOrder>>>
  preview?: InputMaybe<Scalars['Boolean']>
  skip?: InputMaybe<Scalars['Int']>
  where?: InputMaybe<ComponentFeaturedArticlesFilter>
}

export type QueryComponentFeaturedGuidesArgs = {
  id: Scalars['String']
  locale?: InputMaybe<Scalars['String']>
  preview?: InputMaybe<Scalars['Boolean']>
}

export type QueryComponentFeaturedGuidesCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>
  locale?: InputMaybe<Scalars['String']>
  order?: InputMaybe<Array<InputMaybe<ComponentFeaturedGuidesOrder>>>
  preview?: InputMaybe<Scalars['Boolean']>
  skip?: InputMaybe<Scalars['Int']>
  where?: InputMaybe<ComponentFeaturedGuidesFilter>
}

export type QueryComponentFeaturedReviewsArgs = {
  id: Scalars['String']
  locale?: InputMaybe<Scalars['String']>
  preview?: InputMaybe<Scalars['Boolean']>
}

export type QueryComponentFeaturedReviewsCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>
  locale?: InputMaybe<Scalars['String']>
  order?: InputMaybe<Array<InputMaybe<ComponentFeaturedReviewsOrder>>>
  preview?: InputMaybe<Scalars['Boolean']>
  skip?: InputMaybe<Scalars['Int']>
  where?: InputMaybe<ComponentFeaturedReviewsFilter>
}

export type QueryComponentForaLogoArgs = {
  id: Scalars['String']
  locale?: InputMaybe<Scalars['String']>
  preview?: InputMaybe<Scalars['Boolean']>
}

export type QueryComponentForaLogoCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>
  locale?: InputMaybe<Scalars['String']>
  order?: InputMaybe<Array<InputMaybe<ComponentForaLogoOrder>>>
  preview?: InputMaybe<Scalars['Boolean']>
  skip?: InputMaybe<Scalars['Int']>
  where?: InputMaybe<ComponentForaLogoFilter>
}

export type QueryComponentFormArgs = {
  id: Scalars['String']
  locale?: InputMaybe<Scalars['String']>
  preview?: InputMaybe<Scalars['Boolean']>
}

export type QueryComponentFormCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>
  locale?: InputMaybe<Scalars['String']>
  order?: InputMaybe<Array<InputMaybe<ComponentFormOrder>>>
  preview?: InputMaybe<Scalars['Boolean']>
  skip?: InputMaybe<Scalars['Int']>
  where?: InputMaybe<ComponentFormFilter>
}

export type QueryComponentGlobalBannerArgs = {
  id: Scalars['String']
  locale?: InputMaybe<Scalars['String']>
  preview?: InputMaybe<Scalars['Boolean']>
}

export type QueryComponentGlobalBannerCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>
  locale?: InputMaybe<Scalars['String']>
  order?: InputMaybe<Array<InputMaybe<ComponentGlobalBannerOrder>>>
  preview?: InputMaybe<Scalars['Boolean']>
  skip?: InputMaybe<Scalars['Int']>
  where?: InputMaybe<ComponentGlobalBannerFilter>
}

export type QueryComponentHeroCenteredArgs = {
  id: Scalars['String']
  locale?: InputMaybe<Scalars['String']>
  preview?: InputMaybe<Scalars['Boolean']>
}

export type QueryComponentHeroCenteredCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>
  locale?: InputMaybe<Scalars['String']>
  order?: InputMaybe<Array<InputMaybe<ComponentHeroCenteredOrder>>>
  preview?: InputMaybe<Scalars['Boolean']>
  skip?: InputMaybe<Scalars['Int']>
  where?: InputMaybe<ComponentHeroCenteredFilter>
}

export type QueryComponentHeroFullWidthArgs = {
  id: Scalars['String']
  locale?: InputMaybe<Scalars['String']>
  preview?: InputMaybe<Scalars['Boolean']>
}

export type QueryComponentHeroFullWidthCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>
  locale?: InputMaybe<Scalars['String']>
  order?: InputMaybe<Array<InputMaybe<ComponentHeroFullWidthOrder>>>
  preview?: InputMaybe<Scalars['Boolean']>
  skip?: InputMaybe<Scalars['Int']>
  where?: InputMaybe<ComponentHeroFullWidthFilter>
}

export type QueryComponentImageArgs = {
  id: Scalars['String']
  locale?: InputMaybe<Scalars['String']>
  preview?: InputMaybe<Scalars['Boolean']>
}

export type QueryComponentImageCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>
  locale?: InputMaybe<Scalars['String']>
  order?: InputMaybe<Array<InputMaybe<ComponentImageOrder>>>
  preview?: InputMaybe<Scalars['Boolean']>
  skip?: InputMaybe<Scalars['Int']>
  where?: InputMaybe<ComponentImageFilter>
}

export type QueryComponentImageGalleryCarouselArgs = {
  id: Scalars['String']
  locale?: InputMaybe<Scalars['String']>
  preview?: InputMaybe<Scalars['Boolean']>
}

export type QueryComponentImageGalleryCarouselCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>
  locale?: InputMaybe<Scalars['String']>
  order?: InputMaybe<Array<InputMaybe<ComponentImageGalleryCarouselOrder>>>
  preview?: InputMaybe<Scalars['Boolean']>
  skip?: InputMaybe<Scalars['Int']>
  where?: InputMaybe<ComponentImageGalleryCarouselFilter>
}

export type QueryComponentImageGallerySlideItemArgs = {
  id: Scalars['String']
  locale?: InputMaybe<Scalars['String']>
  preview?: InputMaybe<Scalars['Boolean']>
}

export type QueryComponentImageGallerySlideItemCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>
  locale?: InputMaybe<Scalars['String']>
  order?: InputMaybe<Array<InputMaybe<ComponentImageGallerySlideItemOrder>>>
  preview?: InputMaybe<Scalars['Boolean']>
  skip?: InputMaybe<Scalars['Int']>
  where?: InputMaybe<ComponentImageGallerySlideItemFilter>
}

export type QueryComponentImageHeaderSubArgs = {
  id: Scalars['String']
  locale?: InputMaybe<Scalars['String']>
  preview?: InputMaybe<Scalars['Boolean']>
}

export type QueryComponentImageHeaderSubCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>
  locale?: InputMaybe<Scalars['String']>
  order?: InputMaybe<Array<InputMaybe<ComponentImageHeaderSubOrder>>>
  preview?: InputMaybe<Scalars['Boolean']>
  skip?: InputMaybe<Scalars['Int']>
  where?: InputMaybe<ComponentImageHeaderSubFilter>
}

export type QueryComponentImageSideBySideArgs = {
  id: Scalars['String']
  locale?: InputMaybe<Scalars['String']>
  preview?: InputMaybe<Scalars['Boolean']>
}

export type QueryComponentImageSideBySideCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>
  locale?: InputMaybe<Scalars['String']>
  order?: InputMaybe<Array<InputMaybe<ComponentImageSideBySideOrder>>>
  preview?: InputMaybe<Scalars['Boolean']>
  skip?: InputMaybe<Scalars['Int']>
  where?: InputMaybe<ComponentImageSideBySideFilter>
}

export type QueryComponentInfoGridArgs = {
  id: Scalars['String']
  locale?: InputMaybe<Scalars['String']>
  preview?: InputMaybe<Scalars['Boolean']>
}

export type QueryComponentInfoGridCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>
  locale?: InputMaybe<Scalars['String']>
  order?: InputMaybe<Array<InputMaybe<ComponentInfoGridOrder>>>
  preview?: InputMaybe<Scalars['Boolean']>
  skip?: InputMaybe<Scalars['Int']>
  where?: InputMaybe<ComponentInfoGridFilter>
}

export type QueryComponentLargePhotoCarouselItemArgs = {
  id: Scalars['String']
  locale?: InputMaybe<Scalars['String']>
  preview?: InputMaybe<Scalars['Boolean']>
}

export type QueryComponentLargePhotoCarouselItemCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>
  locale?: InputMaybe<Scalars['String']>
  order?: InputMaybe<Array<InputMaybe<ComponentLargePhotoCarouselItemOrder>>>
  preview?: InputMaybe<Scalars['Boolean']>
  skip?: InputMaybe<Scalars['Int']>
  where?: InputMaybe<ComponentLargePhotoCarouselItemFilter>
}

export type QueryComponentPartnerGridArgs = {
  id: Scalars['String']
  locale?: InputMaybe<Scalars['String']>
  preview?: InputMaybe<Scalars['Boolean']>
}

export type QueryComponentPartnerGridCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>
  locale?: InputMaybe<Scalars['String']>
  order?: InputMaybe<Array<InputMaybe<ComponentPartnerGridOrder>>>
  preview?: InputMaybe<Scalars['Boolean']>
  skip?: InputMaybe<Scalars['Int']>
  where?: InputMaybe<ComponentPartnerGridFilter>
}

export type QueryComponentPictureSectionBannerArgs = {
  id: Scalars['String']
  locale?: InputMaybe<Scalars['String']>
  preview?: InputMaybe<Scalars['Boolean']>
}

export type QueryComponentPictureSectionBannerCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>
  locale?: InputMaybe<Scalars['String']>
  order?: InputMaybe<Array<InputMaybe<ComponentPictureSectionBannerOrder>>>
  preview?: InputMaybe<Scalars['Boolean']>
  skip?: InputMaybe<Scalars['Int']>
  where?: InputMaybe<ComponentPictureSectionBannerFilter>
}

export type QueryComponentReviewCarouselArgs = {
  id: Scalars['String']
  locale?: InputMaybe<Scalars['String']>
  preview?: InputMaybe<Scalars['Boolean']>
}

export type QueryComponentReviewCarouselCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>
  locale?: InputMaybe<Scalars['String']>
  order?: InputMaybe<Array<InputMaybe<ComponentReviewCarouselOrder>>>
  preview?: InputMaybe<Scalars['Boolean']>
  skip?: InputMaybe<Scalars['Int']>
  where?: InputMaybe<ComponentReviewCarouselFilter>
}

export type QueryComponentReviewsArgs = {
  id: Scalars['String']
  locale?: InputMaybe<Scalars['String']>
  preview?: InputMaybe<Scalars['Boolean']>
}

export type QueryComponentReviewsCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>
  locale?: InputMaybe<Scalars['String']>
  order?: InputMaybe<Array<InputMaybe<ComponentReviewsOrder>>>
  preview?: InputMaybe<Scalars['Boolean']>
  skip?: InputMaybe<Scalars['Int']>
  where?: InputMaybe<ComponentReviewsFilter>
}

export type QueryComponentSingleUseArgs = {
  id: Scalars['String']
  locale?: InputMaybe<Scalars['String']>
  preview?: InputMaybe<Scalars['Boolean']>
}

export type QueryComponentSingleUseCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>
  locale?: InputMaybe<Scalars['String']>
  order?: InputMaybe<Array<InputMaybe<ComponentSingleUseOrder>>>
  preview?: InputMaybe<Scalars['Boolean']>
  skip?: InputMaybe<Scalars['Int']>
  where?: InputMaybe<ComponentSingleUseFilter>
}

export type QueryComponentSpacerArgs = {
  id: Scalars['String']
  locale?: InputMaybe<Scalars['String']>
  preview?: InputMaybe<Scalars['Boolean']>
}

export type QueryComponentSpacerCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>
  locale?: InputMaybe<Scalars['String']>
  order?: InputMaybe<Array<InputMaybe<ComponentSpacerOrder>>>
  preview?: InputMaybe<Scalars['Boolean']>
  skip?: InputMaybe<Scalars['Int']>
  where?: InputMaybe<ComponentSpacerFilter>
}

export type QueryComponentStickyHeaderArgs = {
  id: Scalars['String']
  locale?: InputMaybe<Scalars['String']>
  preview?: InputMaybe<Scalars['Boolean']>
}

export type QueryComponentStickyHeaderCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>
  locale?: InputMaybe<Scalars['String']>
  order?: InputMaybe<Array<InputMaybe<ComponentStickyHeaderOrder>>>
  preview?: InputMaybe<Scalars['Boolean']>
  skip?: InputMaybe<Scalars['Int']>
  where?: InputMaybe<ComponentStickyHeaderFilter>
}

export type QueryComponentSubNavBarArgs = {
  id: Scalars['String']
  locale?: InputMaybe<Scalars['String']>
  preview?: InputMaybe<Scalars['Boolean']>
}

export type QueryComponentSubNavBarCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>
  locale?: InputMaybe<Scalars['String']>
  order?: InputMaybe<Array<InputMaybe<ComponentSubNavBarOrder>>>
  preview?: InputMaybe<Scalars['Boolean']>
  skip?: InputMaybe<Scalars['Int']>
  where?: InputMaybe<ComponentSubNavBarFilter>
}

export type QueryComponentSubNavItemArgs = {
  id: Scalars['String']
  locale?: InputMaybe<Scalars['String']>
  preview?: InputMaybe<Scalars['Boolean']>
}

export type QueryComponentSubNavItemCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>
  locale?: InputMaybe<Scalars['String']>
  order?: InputMaybe<Array<InputMaybe<ComponentSubNavItemOrder>>>
  preview?: InputMaybe<Scalars['Boolean']>
  skip?: InputMaybe<Scalars['Int']>
  where?: InputMaybe<ComponentSubNavItemFilter>
}

export type QueryComponentSubscriptionCardArgs = {
  id: Scalars['String']
  locale?: InputMaybe<Scalars['String']>
  preview?: InputMaybe<Scalars['Boolean']>
}

export type QueryComponentSubscriptionCardCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>
  locale?: InputMaybe<Scalars['String']>
  order?: InputMaybe<Array<InputMaybe<ComponentSubscriptionCardOrder>>>
  preview?: InputMaybe<Scalars['Boolean']>
  skip?: InputMaybe<Scalars['Int']>
  where?: InputMaybe<ComponentSubscriptionCardFilter>
}

export type QueryComponentTeamSectionArgs = {
  id: Scalars['String']
  locale?: InputMaybe<Scalars['String']>
  preview?: InputMaybe<Scalars['Boolean']>
}

export type QueryComponentTeamSectionCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>
  locale?: InputMaybe<Scalars['String']>
  order?: InputMaybe<Array<InputMaybe<ComponentTeamSectionOrder>>>
  preview?: InputMaybe<Scalars['Boolean']>
  skip?: InputMaybe<Scalars['Int']>
  where?: InputMaybe<ComponentTeamSectionFilter>
}

export type QueryComponentTestimonialCarouselArgs = {
  id: Scalars['String']
  locale?: InputMaybe<Scalars['String']>
  preview?: InputMaybe<Scalars['Boolean']>
}

export type QueryComponentTestimonialCarouselCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>
  locale?: InputMaybe<Scalars['String']>
  order?: InputMaybe<Array<InputMaybe<ComponentTestimonialCarouselOrder>>>
  preview?: InputMaybe<Scalars['Boolean']>
  skip?: InputMaybe<Scalars['Int']>
  where?: InputMaybe<ComponentTestimonialCarouselFilter>
}

export type QueryComponentTextSectionArgs = {
  id: Scalars['String']
  locale?: InputMaybe<Scalars['String']>
  preview?: InputMaybe<Scalars['Boolean']>
}

export type QueryComponentTextSectionCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>
  locale?: InputMaybe<Scalars['String']>
  order?: InputMaybe<Array<InputMaybe<ComponentTextSectionOrder>>>
  preview?: InputMaybe<Scalars['Boolean']>
  skip?: InputMaybe<Scalars['Int']>
  where?: InputMaybe<ComponentTextSectionFilter>
}

export type QueryComponentThreeIconSectionArgs = {
  id: Scalars['String']
  locale?: InputMaybe<Scalars['String']>
  preview?: InputMaybe<Scalars['Boolean']>
}

export type QueryComponentThreeIconSectionCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>
  locale?: InputMaybe<Scalars['String']>
  order?: InputMaybe<Array<InputMaybe<ComponentThreeIconSectionOrder>>>
  preview?: InputMaybe<Scalars['Boolean']>
  skip?: InputMaybe<Scalars['Int']>
  where?: InputMaybe<ComponentThreeIconSectionFilter>
}

export type QueryComponentThreeIconSectionWithPictureArgs = {
  id: Scalars['String']
  locale?: InputMaybe<Scalars['String']>
  preview?: InputMaybe<Scalars['Boolean']>
}

export type QueryComponentThreeIconSectionWithPictureCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>
  locale?: InputMaybe<Scalars['String']>
  order?: InputMaybe<
    Array<InputMaybe<ComponentThreeIconSectionWithPictureOrder>>
  >
  preview?: InputMaybe<Scalars['Boolean']>
  skip?: InputMaybe<Scalars['Int']>
  where?: InputMaybe<ComponentThreeIconSectionWithPictureFilter>
}

export type QueryComponentTwoColumnCalloutArgs = {
  id: Scalars['String']
  locale?: InputMaybe<Scalars['String']>
  preview?: InputMaybe<Scalars['Boolean']>
}

export type QueryComponentTwoColumnCalloutCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>
  locale?: InputMaybe<Scalars['String']>
  order?: InputMaybe<Array<InputMaybe<ComponentTwoColumnCalloutOrder>>>
  preview?: InputMaybe<Scalars['Boolean']>
  skip?: InputMaybe<Scalars['Int']>
  where?: InputMaybe<ComponentTwoColumnCalloutFilter>
}

export type QueryComponentTwoColumnInfographicArgs = {
  id: Scalars['String']
  locale?: InputMaybe<Scalars['String']>
  preview?: InputMaybe<Scalars['Boolean']>
}

export type QueryComponentTwoColumnInfographicCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>
  locale?: InputMaybe<Scalars['String']>
  order?: InputMaybe<Array<InputMaybe<ComponentTwoColumnInfographicOrder>>>
  preview?: InputMaybe<Scalars['Boolean']>
  skip?: InputMaybe<Scalars['Int']>
  where?: InputMaybe<ComponentTwoColumnInfographicFilter>
}

export type QueryComponentTwoColumnPerksSectionArgs = {
  id: Scalars['String']
  locale?: InputMaybe<Scalars['String']>
  preview?: InputMaybe<Scalars['Boolean']>
}

export type QueryComponentTwoColumnPerksSectionCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>
  locale?: InputMaybe<Scalars['String']>
  order?: InputMaybe<Array<InputMaybe<ComponentTwoColumnPerksSectionOrder>>>
  preview?: InputMaybe<Scalars['Boolean']>
  skip?: InputMaybe<Scalars['Int']>
  where?: InputMaybe<ComponentTwoColumnPerksSectionFilter>
}

export type QueryComponentTwoColumnTextSectionArgs = {
  id: Scalars['String']
  locale?: InputMaybe<Scalars['String']>
  preview?: InputMaybe<Scalars['Boolean']>
}

export type QueryComponentTwoColumnTextSectionCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>
  locale?: InputMaybe<Scalars['String']>
  order?: InputMaybe<Array<InputMaybe<ComponentTwoColumnTextSectionOrder>>>
  preview?: InputMaybe<Scalars['Boolean']>
  skip?: InputMaybe<Scalars['Int']>
  where?: InputMaybe<ComponentTwoColumnTextSectionFilter>
}

export type QueryComponentTypeformEmbedArgs = {
  id: Scalars['String']
  locale?: InputMaybe<Scalars['String']>
  preview?: InputMaybe<Scalars['Boolean']>
}

export type QueryComponentTypeformEmbedCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>
  locale?: InputMaybe<Scalars['String']>
  order?: InputMaybe<Array<InputMaybe<ComponentTypeformEmbedOrder>>>
  preview?: InputMaybe<Scalars['Boolean']>
  skip?: InputMaybe<Scalars['Int']>
  where?: InputMaybe<ComponentTypeformEmbedFilter>
}

export type QueryComponentVideoSectionArgs = {
  id: Scalars['String']
  locale?: InputMaybe<Scalars['String']>
  preview?: InputMaybe<Scalars['Boolean']>
}

export type QueryComponentVideoSectionCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>
  locale?: InputMaybe<Scalars['String']>
  order?: InputMaybe<Array<InputMaybe<ComponentVideoSectionOrder>>>
  preview?: InputMaybe<Scalars['Boolean']>
  skip?: InputMaybe<Scalars['Int']>
  where?: InputMaybe<ComponentVideoSectionFilter>
}

export type QueryComponentWhatYouGetCardArgs = {
  id: Scalars['String']
  locale?: InputMaybe<Scalars['String']>
  preview?: InputMaybe<Scalars['Boolean']>
}

export type QueryComponentWhatYouGetCardCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>
  locale?: InputMaybe<Scalars['String']>
  order?: InputMaybe<Array<InputMaybe<ComponentWhatYouGetCardOrder>>>
  preview?: InputMaybe<Scalars['Boolean']>
  skip?: InputMaybe<Scalars['Int']>
  where?: InputMaybe<ComponentWhatYouGetCardFilter>
}

export type QueryComponentWhatYouGetModuleArgs = {
  id: Scalars['String']
  locale?: InputMaybe<Scalars['String']>
  preview?: InputMaybe<Scalars['Boolean']>
}

export type QueryComponentWhatYouGetModuleCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>
  locale?: InputMaybe<Scalars['String']>
  order?: InputMaybe<Array<InputMaybe<ComponentWhatYouGetModuleOrder>>>
  preview?: InputMaybe<Scalars['Boolean']>
  skip?: InputMaybe<Scalars['Int']>
  where?: InputMaybe<ComponentWhatYouGetModuleFilter>
}

export type QueryDestinationsCategoriesArgs = {
  id: Scalars['String']
  locale?: InputMaybe<Scalars['String']>
  preview?: InputMaybe<Scalars['Boolean']>
}

export type QueryDestinationsCategoriesCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>
  locale?: InputMaybe<Scalars['String']>
  order?: InputMaybe<Array<InputMaybe<DestinationsCategoriesOrder>>>
  preview?: InputMaybe<Scalars['Boolean']>
  skip?: InputMaybe<Scalars['Int']>
  where?: InputMaybe<DestinationsCategoriesFilter>
}

export type QueryDestinationsSubcategoriesArgs = {
  id: Scalars['String']
  locale?: InputMaybe<Scalars['String']>
  preview?: InputMaybe<Scalars['Boolean']>
}

export type QueryDestinationsSubcategoriesCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>
  locale?: InputMaybe<Scalars['String']>
  order?: InputMaybe<Array<InputMaybe<DestinationsSubcategoriesOrder>>>
  preview?: InputMaybe<Scalars['Boolean']>
  skip?: InputMaybe<Scalars['Int']>
  where?: InputMaybe<DestinationsSubcategoriesFilter>
}

export type QueryEntryCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>
  locale?: InputMaybe<Scalars['String']>
  order?: InputMaybe<Array<InputMaybe<EntryOrder>>>
  preview?: InputMaybe<Scalars['Boolean']>
  skip?: InputMaybe<Scalars['Int']>
  where?: InputMaybe<EntryFilter>
}

export type QueryEntryPartnerArgs = {
  id: Scalars['String']
  locale?: InputMaybe<Scalars['String']>
  preview?: InputMaybe<Scalars['Boolean']>
}

export type QueryEntryPartnerCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>
  locale?: InputMaybe<Scalars['String']>
  order?: InputMaybe<Array<InputMaybe<EntryPartnerOrder>>>
  preview?: InputMaybe<Scalars['Boolean']>
  skip?: InputMaybe<Scalars['Int']>
  where?: InputMaybe<EntryPartnerFilter>
}

export type QueryEntryTestimonialArgs = {
  id: Scalars['String']
  locale?: InputMaybe<Scalars['String']>
  preview?: InputMaybe<Scalars['Boolean']>
}

export type QueryEntryTestimonialCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>
  locale?: InputMaybe<Scalars['String']>
  order?: InputMaybe<Array<InputMaybe<EntryTestimonialOrder>>>
  preview?: InputMaybe<Scalars['Boolean']>
  skip?: InputMaybe<Scalars['Int']>
  where?: InputMaybe<EntryTestimonialFilter>
}

export type QueryEntryTripReportArgs = {
  id: Scalars['String']
  locale?: InputMaybe<Scalars['String']>
  preview?: InputMaybe<Scalars['Boolean']>
}

export type QueryEntryTripReportCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>
  locale?: InputMaybe<Scalars['String']>
  order?: InputMaybe<Array<InputMaybe<EntryTripReportOrder>>>
  preview?: InputMaybe<Scalars['Boolean']>
  skip?: InputMaybe<Scalars['Int']>
  where?: InputMaybe<EntryTripReportFilter>
}

export type QueryEventsArgs = {
  id: Scalars['String']
  locale?: InputMaybe<Scalars['String']>
  preview?: InputMaybe<Scalars['Boolean']>
}

export type QueryEventsCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>
  locale?: InputMaybe<Scalars['String']>
  order?: InputMaybe<Array<InputMaybe<EventsOrder>>>
  preview?: InputMaybe<Scalars['Boolean']>
  skip?: InputMaybe<Scalars['Int']>
  where?: InputMaybe<EventsFilter>
}

export type QueryFaqsQuestionGroupsArgs = {
  id: Scalars['String']
  locale?: InputMaybe<Scalars['String']>
  preview?: InputMaybe<Scalars['Boolean']>
}

export type QueryFaqsQuestionGroupsCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>
  locale?: InputMaybe<Scalars['String']>
  order?: InputMaybe<Array<InputMaybe<FaqsQuestionGroupsOrder>>>
  preview?: InputMaybe<Scalars['Boolean']>
  skip?: InputMaybe<Scalars['Int']>
  where?: InputMaybe<FaqsQuestionGroupsFilter>
}

export type QueryFaqsQuestionsArgs = {
  id: Scalars['String']
  locale?: InputMaybe<Scalars['String']>
  preview?: InputMaybe<Scalars['Boolean']>
}

export type QueryFaqsQuestionsCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>
  locale?: InputMaybe<Scalars['String']>
  order?: InputMaybe<Array<InputMaybe<FaqsQuestionsOrder>>>
  preview?: InputMaybe<Scalars['Boolean']>
  skip?: InputMaybe<Scalars['Int']>
  where?: InputMaybe<FaqsQuestionsFilter>
}

export type QueryHotelsArgs = {
  id: Scalars['String']
  locale?: InputMaybe<Scalars['String']>
  preview?: InputMaybe<Scalars['Boolean']>
}

export type QueryHotelsCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>
  locale?: InputMaybe<Scalars['String']>
  order?: InputMaybe<Array<InputMaybe<HotelsOrder>>>
  preview?: InputMaybe<Scalars['Boolean']>
  skip?: InputMaybe<Scalars['Int']>
  where?: InputMaybe<HotelsFilter>
}

export type QueryItinerariesArgs = {
  id: Scalars['String']
  locale?: InputMaybe<Scalars['String']>
  preview?: InputMaybe<Scalars['Boolean']>
}

export type QueryItinerariesCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>
  locale?: InputMaybe<Scalars['String']>
  order?: InputMaybe<Array<InputMaybe<ItinerariesOrder>>>
  preview?: InputMaybe<Scalars['Boolean']>
  skip?: InputMaybe<Scalars['Int']>
  where?: InputMaybe<ItinerariesFilter>
}

export type QueryItinerariesPlansArgs = {
  id: Scalars['String']
  locale?: InputMaybe<Scalars['String']>
  preview?: InputMaybe<Scalars['Boolean']>
}

export type QueryItinerariesPlansCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>
  locale?: InputMaybe<Scalars['String']>
  order?: InputMaybe<Array<InputMaybe<ItinerariesPlansOrder>>>
  preview?: InputMaybe<Scalars['Boolean']>
  skip?: InputMaybe<Scalars['Int']>
  where?: InputMaybe<ItinerariesPlansFilter>
}

export type QueryPagesFeaturedHotelsArgs = {
  id: Scalars['String']
  locale?: InputMaybe<Scalars['String']>
  preview?: InputMaybe<Scalars['Boolean']>
}

export type QueryPagesFeaturedHotelsCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>
  locale?: InputMaybe<Scalars['String']>
  order?: InputMaybe<Array<InputMaybe<PagesFeaturedHotelsOrder>>>
  preview?: InputMaybe<Scalars['Boolean']>
  skip?: InputMaybe<Scalars['Int']>
  where?: InputMaybe<PagesFeaturedHotelsFilter>
}

export type QueryPagesHeaderBlockArgs = {
  id: Scalars['String']
  locale?: InputMaybe<Scalars['String']>
  preview?: InputMaybe<Scalars['Boolean']>
}

export type QueryPagesHeaderBlockCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>
  locale?: InputMaybe<Scalars['String']>
  order?: InputMaybe<Array<InputMaybe<PagesHeaderBlockOrder>>>
  preview?: InputMaybe<Scalars['Boolean']>
  skip?: InputMaybe<Scalars['Int']>
  where?: InputMaybe<PagesHeaderBlockFilter>
}

export type QueryPagesHeroBlockArgs = {
  id: Scalars['String']
  locale?: InputMaybe<Scalars['String']>
  preview?: InputMaybe<Scalars['Boolean']>
}

export type QueryPagesHeroBlockCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>
  locale?: InputMaybe<Scalars['String']>
  order?: InputMaybe<Array<InputMaybe<PagesHeroBlockOrder>>>
  preview?: InputMaybe<Scalars['Boolean']>
  skip?: InputMaybe<Scalars['Int']>
  where?: InputMaybe<PagesHeroBlockFilter>
}

export type QueryPagesLearnMoreBlockArgs = {
  id: Scalars['String']
  locale?: InputMaybe<Scalars['String']>
  preview?: InputMaybe<Scalars['Boolean']>
}

export type QueryPagesLearnMoreBlockCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>
  locale?: InputMaybe<Scalars['String']>
  order?: InputMaybe<Array<InputMaybe<PagesLearnMoreBlockOrder>>>
  preview?: InputMaybe<Scalars['Boolean']>
  skip?: InputMaybe<Scalars['Int']>
  where?: InputMaybe<PagesLearnMoreBlockFilter>
}

export type QueryPagesMetaContentArgs = {
  id: Scalars['String']
  locale?: InputMaybe<Scalars['String']>
  preview?: InputMaybe<Scalars['Boolean']>
}

export type QueryPagesMetaContentCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>
  locale?: InputMaybe<Scalars['String']>
  order?: InputMaybe<Array<InputMaybe<PagesMetaContentOrder>>>
  preview?: InputMaybe<Scalars['Boolean']>
  skip?: InputMaybe<Scalars['Int']>
  where?: InputMaybe<PagesMetaContentFilter>
}

export type QueryPagesPartnerBannerArgs = {
  id: Scalars['String']
  locale?: InputMaybe<Scalars['String']>
  preview?: InputMaybe<Scalars['Boolean']>
}

export type QueryPagesPartnerBannerCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>
  locale?: InputMaybe<Scalars['String']>
  order?: InputMaybe<Array<InputMaybe<PagesPartnerBannerOrder>>>
  preview?: InputMaybe<Scalars['Boolean']>
  skip?: InputMaybe<Scalars['Int']>
  where?: InputMaybe<PagesPartnerBannerFilter>
}

export type QueryPagesPerksListArgs = {
  id: Scalars['String']
  locale?: InputMaybe<Scalars['String']>
  preview?: InputMaybe<Scalars['Boolean']>
}

export type QueryPagesPerksListCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>
  locale?: InputMaybe<Scalars['String']>
  order?: InputMaybe<Array<InputMaybe<PagesPerksListOrder>>>
  preview?: InputMaybe<Scalars['Boolean']>
  skip?: InputMaybe<Scalars['Int']>
  where?: InputMaybe<PagesPerksListFilter>
}

export type QueryPagesPressBannerArgs = {
  id: Scalars['String']
  locale?: InputMaybe<Scalars['String']>
  preview?: InputMaybe<Scalars['Boolean']>
}

export type QueryPagesPressBannerCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>
  locale?: InputMaybe<Scalars['String']>
  order?: InputMaybe<Array<InputMaybe<PagesPressBannerOrder>>>
  preview?: InputMaybe<Scalars['Boolean']>
  skip?: InputMaybe<Scalars['Int']>
  where?: InputMaybe<PagesPressBannerFilter>
}

export type QueryPagesTwoColumnCtaArgs = {
  id: Scalars['String']
  locale?: InputMaybe<Scalars['String']>
  preview?: InputMaybe<Scalars['Boolean']>
}

export type QueryPagesTwoColumnCtaCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>
  locale?: InputMaybe<Scalars['String']>
  order?: InputMaybe<Array<InputMaybe<PagesTwoColumnCtaOrder>>>
  preview?: InputMaybe<Scalars['Boolean']>
  skip?: InputMaybe<Scalars['Int']>
  where?: InputMaybe<PagesTwoColumnCtaFilter>
}

export type QueryPressArgs = {
  id: Scalars['String']
  locale?: InputMaybe<Scalars['String']>
  preview?: InputMaybe<Scalars['Boolean']>
}

export type QueryPressCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>
  locale?: InputMaybe<Scalars['String']>
  order?: InputMaybe<Array<InputMaybe<PressOrder>>>
  preview?: InputMaybe<Scalars['Boolean']>
  skip?: InputMaybe<Scalars['Int']>
  where?: InputMaybe<PressFilter>
}

export type QuerySettingsAnalyticsEventArgs = {
  id: Scalars['String']
  locale?: InputMaybe<Scalars['String']>
  preview?: InputMaybe<Scalars['Boolean']>
}

export type QuerySettingsAnalyticsEventCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>
  locale?: InputMaybe<Scalars['String']>
  order?: InputMaybe<Array<InputMaybe<SettingsAnalyticsEventOrder>>>
  preview?: InputMaybe<Scalars['Boolean']>
  skip?: InputMaybe<Scalars['Int']>
  where?: InputMaybe<SettingsAnalyticsEventFilter>
}

export type QuerySettingsAnalyticsModuleArgs = {
  id: Scalars['String']
  locale?: InputMaybe<Scalars['String']>
  preview?: InputMaybe<Scalars['Boolean']>
}

export type QuerySettingsAnalyticsModuleCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>
  locale?: InputMaybe<Scalars['String']>
  order?: InputMaybe<Array<InputMaybe<SettingsAnalyticsModuleOrder>>>
  preview?: InputMaybe<Scalars['Boolean']>
  skip?: InputMaybe<Scalars['Int']>
  where?: InputMaybe<SettingsAnalyticsModuleFilter>
}

export type QuerySingleLineArgs = {
  id: Scalars['String']
  locale?: InputMaybe<Scalars['String']>
  preview?: InputMaybe<Scalars['Boolean']>
}

export type QuerySingleLineCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>
  locale?: InputMaybe<Scalars['String']>
  order?: InputMaybe<Array<InputMaybe<SingleLineOrder>>>
  preview?: InputMaybe<Scalars['Boolean']>
  skip?: InputMaybe<Scalars['Int']>
  where?: InputMaybe<SingleLineFilter>
}

export type QueryTeamArgs = {
  id: Scalars['String']
  locale?: InputMaybe<Scalars['String']>
  preview?: InputMaybe<Scalars['Boolean']>
}

export type QueryTeamCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>
  locale?: InputMaybe<Scalars['String']>
  order?: InputMaybe<Array<InputMaybe<TeamOrder>>>
  preview?: InputMaybe<Scalars['Boolean']>
  skip?: InputMaybe<Scalars['Int']>
  where?: InputMaybe<TeamFilter>
}

export type QueryTemplateAboutUsArgs = {
  id: Scalars['String']
  locale?: InputMaybe<Scalars['String']>
  preview?: InputMaybe<Scalars['Boolean']>
}

export type QueryTemplateAboutUsCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>
  locale?: InputMaybe<Scalars['String']>
  order?: InputMaybe<Array<InputMaybe<TemplateAboutUsOrder>>>
  preview?: InputMaybe<Scalars['Boolean']>
  skip?: InputMaybe<Scalars['Int']>
  where?: InputMaybe<TemplateAboutUsFilter>
}

export type QueryTemplateAdvisorsArgs = {
  id: Scalars['String']
  locale?: InputMaybe<Scalars['String']>
  preview?: InputMaybe<Scalars['Boolean']>
}

export type QueryTemplateAdvisorsCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>
  locale?: InputMaybe<Scalars['String']>
  order?: InputMaybe<Array<InputMaybe<TemplateAdvisorsOrder>>>
  preview?: InputMaybe<Scalars['Boolean']>
  skip?: InputMaybe<Scalars['Int']>
  where?: InputMaybe<TemplateAdvisorsFilter>
}

export type QueryTemplateBlogArgs = {
  id: Scalars['String']
  locale?: InputMaybe<Scalars['String']>
  preview?: InputMaybe<Scalars['Boolean']>
}

export type QueryTemplateBlogCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>
  locale?: InputMaybe<Scalars['String']>
  order?: InputMaybe<Array<InputMaybe<TemplateBlogOrder>>>
  preview?: InputMaybe<Scalars['Boolean']>
  skip?: InputMaybe<Scalars['Int']>
  where?: InputMaybe<TemplateBlogFilter>
}

export type QueryTemplateBookingArgs = {
  id: Scalars['String']
  locale?: InputMaybe<Scalars['String']>
  preview?: InputMaybe<Scalars['Boolean']>
}

export type QueryTemplateBookingCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>
  locale?: InputMaybe<Scalars['String']>
  order?: InputMaybe<Array<InputMaybe<TemplateBookingOrder>>>
  preview?: InputMaybe<Scalars['Boolean']>
  skip?: InputMaybe<Scalars['Int']>
  where?: InputMaybe<TemplateBookingFilter>
}

export type QueryTemplateCareersArgs = {
  id: Scalars['String']
  locale?: InputMaybe<Scalars['String']>
  preview?: InputMaybe<Scalars['Boolean']>
}

export type QueryTemplateCareersCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>
  locale?: InputMaybe<Scalars['String']>
  order?: InputMaybe<Array<InputMaybe<TemplateCareersOrder>>>
  preview?: InputMaybe<Scalars['Boolean']>
  skip?: InputMaybe<Scalars['Int']>
  where?: InputMaybe<TemplateCareersFilter>
}

export type QueryTemplateContactUsArgs = {
  id: Scalars['String']
  locale?: InputMaybe<Scalars['String']>
  preview?: InputMaybe<Scalars['Boolean']>
}

export type QueryTemplateContactUsCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>
  locale?: InputMaybe<Scalars['String']>
  order?: InputMaybe<Array<InputMaybe<TemplateContactUsOrder>>>
  preview?: InputMaybe<Scalars['Boolean']>
  skip?: InputMaybe<Scalars['Int']>
  where?: InputMaybe<TemplateContactUsFilter>
}

export type QueryTemplateDestinationsArgs = {
  id: Scalars['String']
  locale?: InputMaybe<Scalars['String']>
  preview?: InputMaybe<Scalars['Boolean']>
}

export type QueryTemplateDestinationsCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>
  locale?: InputMaybe<Scalars['String']>
  order?: InputMaybe<Array<InputMaybe<TemplateDestinationsOrder>>>
  preview?: InputMaybe<Scalars['Boolean']>
  skip?: InputMaybe<Scalars['Int']>
  where?: InputMaybe<TemplateDestinationsFilter>
}

export type QueryTemplateEventsArgs = {
  id: Scalars['String']
  locale?: InputMaybe<Scalars['String']>
  preview?: InputMaybe<Scalars['Boolean']>
}

export type QueryTemplateEventsCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>
  locale?: InputMaybe<Scalars['String']>
  order?: InputMaybe<Array<InputMaybe<TemplateEventsOrder>>>
  preview?: InputMaybe<Scalars['Boolean']>
  skip?: InputMaybe<Scalars['Int']>
  where?: InputMaybe<TemplateEventsFilter>
}

export type QueryTemplateFaqsArgs = {
  id: Scalars['String']
  locale?: InputMaybe<Scalars['String']>
  preview?: InputMaybe<Scalars['Boolean']>
}

export type QueryTemplateFaqsCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>
  locale?: InputMaybe<Scalars['String']>
  order?: InputMaybe<Array<InputMaybe<TemplateFaqsOrder>>>
  preview?: InputMaybe<Scalars['Boolean']>
  skip?: InputMaybe<Scalars['Int']>
  where?: InputMaybe<TemplateFaqsFilter>
}

export type QueryTemplateForaReserveArgs = {
  id: Scalars['String']
  locale?: InputMaybe<Scalars['String']>
  preview?: InputMaybe<Scalars['Boolean']>
}

export type QueryTemplateForaReserveCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>
  locale?: InputMaybe<Scalars['String']>
  order?: InputMaybe<Array<InputMaybe<TemplateForaReserveOrder>>>
  preview?: InputMaybe<Scalars['Boolean']>
  skip?: InputMaybe<Scalars['Int']>
  where?: InputMaybe<TemplateForaReserveFilter>
}

export type QueryTemplateForaXArgs = {
  id: Scalars['String']
  locale?: InputMaybe<Scalars['String']>
  preview?: InputMaybe<Scalars['Boolean']>
}

export type QueryTemplateForaXCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>
  locale?: InputMaybe<Scalars['String']>
  order?: InputMaybe<Array<InputMaybe<TemplateForaXOrder>>>
  preview?: InputMaybe<Scalars['Boolean']>
  skip?: InputMaybe<Scalars['Int']>
  where?: InputMaybe<TemplateForaXFilter>
}

export type QueryTemplateGuidesArgs = {
  id: Scalars['String']
  locale?: InputMaybe<Scalars['String']>
  preview?: InputMaybe<Scalars['Boolean']>
}

export type QueryTemplateGuidesCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>
  locale?: InputMaybe<Scalars['String']>
  order?: InputMaybe<Array<InputMaybe<TemplateGuidesOrder>>>
  preview?: InputMaybe<Scalars['Boolean']>
  skip?: InputMaybe<Scalars['Int']>
  where?: InputMaybe<TemplateGuidesFilter>
}

export type QueryTemplateHomeArgs = {
  id: Scalars['String']
  locale?: InputMaybe<Scalars['String']>
  preview?: InputMaybe<Scalars['Boolean']>
}

export type QueryTemplateHomeCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>
  locale?: InputMaybe<Scalars['String']>
  order?: InputMaybe<Array<InputMaybe<TemplateHomeOrder>>>
  preview?: InputMaybe<Scalars['Boolean']>
  skip?: InputMaybe<Scalars['Int']>
  where?: InputMaybe<TemplateHomeFilter>
}

export type QueryTemplateItinerariesArgs = {
  id: Scalars['String']
  locale?: InputMaybe<Scalars['String']>
  preview?: InputMaybe<Scalars['Boolean']>
}

export type QueryTemplateItinerariesCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>
  locale?: InputMaybe<Scalars['String']>
  order?: InputMaybe<Array<InputMaybe<TemplateItinerariesOrder>>>
  preview?: InputMaybe<Scalars['Boolean']>
  skip?: InputMaybe<Scalars['Int']>
  where?: InputMaybe<TemplateItinerariesFilter>
}

export type QueryTemplateJoinUsArgs = {
  id: Scalars['String']
  locale?: InputMaybe<Scalars['String']>
  preview?: InputMaybe<Scalars['Boolean']>
}

export type QueryTemplateJoinUsCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>
  locale?: InputMaybe<Scalars['String']>
  order?: InputMaybe<Array<InputMaybe<TemplateJoinUsOrder>>>
  preview?: InputMaybe<Scalars['Boolean']>
  skip?: InputMaybe<Scalars['Int']>
  where?: InputMaybe<TemplateJoinUsFilter>
}

export type QueryTemplateNewsletterArgs = {
  id: Scalars['String']
  locale?: InputMaybe<Scalars['String']>
  preview?: InputMaybe<Scalars['Boolean']>
}

export type QueryTemplateNewsletterCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>
  locale?: InputMaybe<Scalars['String']>
  order?: InputMaybe<Array<InputMaybe<TemplateNewsletterOrder>>>
  preview?: InputMaybe<Scalars['Boolean']>
  skip?: InputMaybe<Scalars['Int']>
  where?: InputMaybe<TemplateNewsletterFilter>
}

export type QueryTemplatePageArgs = {
  id: Scalars['String']
  locale?: InputMaybe<Scalars['String']>
  preview?: InputMaybe<Scalars['Boolean']>
}

export type QueryTemplatePageCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>
  locale?: InputMaybe<Scalars['String']>
  order?: InputMaybe<Array<InputMaybe<TemplatePageOrder>>>
  preview?: InputMaybe<Scalars['Boolean']>
  skip?: InputMaybe<Scalars['Int']>
  where?: InputMaybe<TemplatePageFilter>
}

export type QueryTemplatePartnerDetailArgs = {
  id: Scalars['String']
  locale?: InputMaybe<Scalars['String']>
  preview?: InputMaybe<Scalars['Boolean']>
}

export type QueryTemplatePartnerDetailCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>
  locale?: InputMaybe<Scalars['String']>
  order?: InputMaybe<Array<InputMaybe<TemplatePartnerDetailOrder>>>
  preview?: InputMaybe<Scalars['Boolean']>
  skip?: InputMaybe<Scalars['Int']>
  where?: InputMaybe<TemplatePartnerDetailFilter>
}

export type QueryTemplatePartnersArgs = {
  id: Scalars['String']
  locale?: InputMaybe<Scalars['String']>
  preview?: InputMaybe<Scalars['Boolean']>
}

export type QueryTemplatePartnersCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>
  locale?: InputMaybe<Scalars['String']>
  order?: InputMaybe<Array<InputMaybe<TemplatePartnersOrder>>>
  preview?: InputMaybe<Scalars['Boolean']>
  skip?: InputMaybe<Scalars['Int']>
  where?: InputMaybe<TemplatePartnersFilter>
}

export type QueryTemplatePressArgs = {
  id: Scalars['String']
  locale?: InputMaybe<Scalars['String']>
  preview?: InputMaybe<Scalars['Boolean']>
}

export type QueryTemplatePressCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>
  locale?: InputMaybe<Scalars['String']>
  order?: InputMaybe<Array<InputMaybe<TemplatePressOrder>>>
  preview?: InputMaybe<Scalars['Boolean']>
  skip?: InputMaybe<Scalars['Int']>
  where?: InputMaybe<TemplatePressFilter>
}

export type QueryTemplatePrivacyPolicyArgs = {
  id: Scalars['String']
  locale?: InputMaybe<Scalars['String']>
  preview?: InputMaybe<Scalars['Boolean']>
}

export type QueryTemplatePrivacyPolicyCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>
  locale?: InputMaybe<Scalars['String']>
  order?: InputMaybe<Array<InputMaybe<TemplatePrivacyPolicyOrder>>>
  preview?: InputMaybe<Scalars['Boolean']>
  skip?: InputMaybe<Scalars['Int']>
  where?: InputMaybe<TemplatePrivacyPolicyFilter>
}

export type QueryTemplateReviewsArgs = {
  id: Scalars['String']
  locale?: InputMaybe<Scalars['String']>
  preview?: InputMaybe<Scalars['Boolean']>
}

export type QueryTemplateReviewsCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>
  locale?: InputMaybe<Scalars['String']>
  order?: InputMaybe<Array<InputMaybe<TemplateReviewsOrder>>>
  preview?: InputMaybe<Scalars['Boolean']>
  skip?: InputMaybe<Scalars['Int']>
  where?: InputMaybe<TemplateReviewsFilter>
}

export type QueryTemplateSearchArgs = {
  id: Scalars['String']
  locale?: InputMaybe<Scalars['String']>
  preview?: InputMaybe<Scalars['Boolean']>
}

export type QueryTemplateSearchCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>
  locale?: InputMaybe<Scalars['String']>
  order?: InputMaybe<Array<InputMaybe<TemplateSearchOrder>>>
  preview?: InputMaybe<Scalars['Boolean']>
  skip?: InputMaybe<Scalars['Int']>
  where?: InputMaybe<TemplateSearchFilter>
}

export type QueryTemplateTermsAndConditionsArgs = {
  id: Scalars['String']
  locale?: InputMaybe<Scalars['String']>
  preview?: InputMaybe<Scalars['Boolean']>
}

export type QueryTemplateTermsAndConditionsCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>
  locale?: InputMaybe<Scalars['String']>
  order?: InputMaybe<Array<InputMaybe<TemplateTermsAndConditionsOrder>>>
  preview?: InputMaybe<Scalars['Boolean']>
  skip?: InputMaybe<Scalars['Int']>
  where?: InputMaybe<TemplateTermsAndConditionsFilter>
}

export type QueryTemplateTravelArgs = {
  id: Scalars['String']
  locale?: InputMaybe<Scalars['String']>
  preview?: InputMaybe<Scalars['Boolean']>
}

export type QueryTemplateTravelByArgs = {
  id: Scalars['String']
  locale?: InputMaybe<Scalars['String']>
  preview?: InputMaybe<Scalars['Boolean']>
}

export type QueryTemplateTravelByCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>
  locale?: InputMaybe<Scalars['String']>
  order?: InputMaybe<Array<InputMaybe<TemplateTravelByOrder>>>
  preview?: InputMaybe<Scalars['Boolean']>
  skip?: InputMaybe<Scalars['Int']>
  where?: InputMaybe<TemplateTravelByFilter>
}

export type QueryTemplateTravelCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>
  locale?: InputMaybe<Scalars['String']>
  order?: InputMaybe<Array<InputMaybe<TemplateTravelOrder>>>
  preview?: InputMaybe<Scalars['Boolean']>
  skip?: InputMaybe<Scalars['Int']>
  where?: InputMaybe<TemplateTravelFilter>
}

export type QueryTemplateWhyBookWithForaArgs = {
  id: Scalars['String']
  locale?: InputMaybe<Scalars['String']>
  preview?: InputMaybe<Scalars['Boolean']>
}

export type QueryTemplateWhyBookWithForaCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>
  locale?: InputMaybe<Scalars['String']>
  order?: InputMaybe<Array<InputMaybe<TemplateWhyBookWithForaOrder>>>
  preview?: InputMaybe<Scalars['Boolean']>
  skip?: InputMaybe<Scalars['Int']>
  where?: InputMaybe<TemplateWhyBookWithForaFilter>
}

export type QueryTemplatesGlobalConfigsArgs = {
  id: Scalars['String']
  locale?: InputMaybe<Scalars['String']>
  preview?: InputMaybe<Scalars['Boolean']>
}

export type QueryTemplatesGlobalConfigsCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>
  locale?: InputMaybe<Scalars['String']>
  order?: InputMaybe<Array<InputMaybe<TemplatesGlobalConfigsOrder>>>
  preview?: InputMaybe<Scalars['Boolean']>
  skip?: InputMaybe<Scalars['Int']>
  where?: InputMaybe<TemplatesGlobalConfigsFilter>
}

export type QueryTopHotelsByDestinationArgs = {
  id: Scalars['String']
  locale?: InputMaybe<Scalars['String']>
  preview?: InputMaybe<Scalars['Boolean']>
}

export type QueryTopHotelsByDestinationCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>
  locale?: InputMaybe<Scalars['String']>
  order?: InputMaybe<Array<InputMaybe<TopHotelsByDestinationOrder>>>
  preview?: InputMaybe<Scalars['Boolean']>
  skip?: InputMaybe<Scalars['Int']>
  where?: InputMaybe<TopHotelsByDestinationFilter>
}

export type QueryTravelByCategoriesArgs = {
  id: Scalars['String']
  locale?: InputMaybe<Scalars['String']>
  preview?: InputMaybe<Scalars['Boolean']>
}

export type QueryTravelByCategoriesCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>
  locale?: InputMaybe<Scalars['String']>
  order?: InputMaybe<Array<InputMaybe<TravelByCategoriesOrder>>>
  preview?: InputMaybe<Scalars['Boolean']>
  skip?: InputMaybe<Scalars['Int']>
  where?: InputMaybe<TravelByCategoriesFilter>
}

export type QueryTravelBySubcategoriesArgs = {
  id: Scalars['String']
  locale?: InputMaybe<Scalars['String']>
  preview?: InputMaybe<Scalars['Boolean']>
}

export type QueryTravelBySubcategoriesCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>
  locale?: InputMaybe<Scalars['String']>
  order?: InputMaybe<Array<InputMaybe<TravelBySubcategoriesOrder>>>
  preview?: InputMaybe<Scalars['Boolean']>
  skip?: InputMaybe<Scalars['Int']>
  where?: InputMaybe<TravelBySubcategoriesFilter>
}

export type ResourceLink = {
  sys: ResourceSys
}

export type ResourceSys = {
  __typename?: 'ResourceSys'
  linkType: Scalars['String']
  urn: Scalars['String']
}

/** Associates a Segment Analytics event to the parent component [See type definition](https://app.contentful.com/spaces/9cxgygtadmvl/content_types/settingsAnalyticsEvent) */
export type SettingsAnalyticsEvent = Entry &
  _Node & {
    __typename?: 'SettingsAnalyticsEvent'
    _id: Scalars['ID']
    contentfulMetadata: ContentfulMetadata
    customData?: Maybe<Scalars['JSON']>
    eventTypes?: Maybe<Array<Maybe<Scalars['String']>>>
    internalLabel?: Maybe<Scalars['String']>
    linkedFrom?: Maybe<SettingsAnalyticsEventLinkingCollections>
    sys: Sys
  }

/** Associates a Segment Analytics event to the parent component [See type definition](https://app.contentful.com/spaces/9cxgygtadmvl/content_types/settingsAnalyticsEvent) */
export type SettingsAnalyticsEventCustomDataArgs = {
  locale?: InputMaybe<Scalars['String']>
}

/** Associates a Segment Analytics event to the parent component [See type definition](https://app.contentful.com/spaces/9cxgygtadmvl/content_types/settingsAnalyticsEvent) */
export type SettingsAnalyticsEventEventTypesArgs = {
  locale?: InputMaybe<Scalars['String']>
}

/** Associates a Segment Analytics event to the parent component [See type definition](https://app.contentful.com/spaces/9cxgygtadmvl/content_types/settingsAnalyticsEvent) */
export type SettingsAnalyticsEventInternalLabelArgs = {
  locale?: InputMaybe<Scalars['String']>
}

/** Associates a Segment Analytics event to the parent component [See type definition](https://app.contentful.com/spaces/9cxgygtadmvl/content_types/settingsAnalyticsEvent) */
export type SettingsAnalyticsEventLinkedFromArgs = {
  allowedLocales?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
}

export type SettingsAnalyticsEventCollection = {
  __typename?: 'SettingsAnalyticsEventCollection'
  items: Array<Maybe<SettingsAnalyticsEvent>>
  limit: Scalars['Int']
  skip: Scalars['Int']
  total: Scalars['Int']
}

export type SettingsAnalyticsEventFilter = {
  AND?: InputMaybe<Array<InputMaybe<SettingsAnalyticsEventFilter>>>
  OR?: InputMaybe<Array<InputMaybe<SettingsAnalyticsEventFilter>>>
  contentfulMetadata?: InputMaybe<ContentfulMetadataFilter>
  customData_exists?: InputMaybe<Scalars['Boolean']>
  eventTypes_contains_all?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  eventTypes_contains_none?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  eventTypes_contains_some?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  eventTypes_exists?: InputMaybe<Scalars['Boolean']>
  internalLabel?: InputMaybe<Scalars['String']>
  internalLabel_contains?: InputMaybe<Scalars['String']>
  internalLabel_exists?: InputMaybe<Scalars['Boolean']>
  internalLabel_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  internalLabel_not?: InputMaybe<Scalars['String']>
  internalLabel_not_contains?: InputMaybe<Scalars['String']>
  internalLabel_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  sys?: InputMaybe<SysFilter>
}

export type SettingsAnalyticsEventLinkingCollections = {
  __typename?: 'SettingsAnalyticsEventLinkingCollections'
  entryCollection?: Maybe<EntryCollection>
}

export type SettingsAnalyticsEventLinkingCollectionsEntryCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>
  locale?: InputMaybe<Scalars['String']>
  preview?: InputMaybe<Scalars['Boolean']>
  skip?: InputMaybe<Scalars['Int']>
}

export enum SettingsAnalyticsEventOrder {
  InternalLabelAsc = 'internalLabel_ASC',
  InternalLabelDesc = 'internalLabel_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
}

/** Associate Segment Analytics Events to a content model [See type definition](https://app.contentful.com/spaces/9cxgygtadmvl/content_types/settingsAnalyticsModule) */
export type SettingsAnalyticsModule = Entry &
  _Node & {
    __typename?: 'SettingsAnalyticsModule'
    _id: Scalars['ID']
    contentfulMetadata: ContentfulMetadata
    eventContext?: Maybe<Scalars['String']>
    eventTypes?: Maybe<Array<Maybe<Scalars['String']>>>
    internalLabel?: Maybe<Scalars['String']>
    linkedFrom?: Maybe<SettingsAnalyticsModuleLinkingCollections>
    sys: Sys
  }

/** Associate Segment Analytics Events to a content model [See type definition](https://app.contentful.com/spaces/9cxgygtadmvl/content_types/settingsAnalyticsModule) */
export type SettingsAnalyticsModuleEventContextArgs = {
  locale?: InputMaybe<Scalars['String']>
}

/** Associate Segment Analytics Events to a content model [See type definition](https://app.contentful.com/spaces/9cxgygtadmvl/content_types/settingsAnalyticsModule) */
export type SettingsAnalyticsModuleEventTypesArgs = {
  locale?: InputMaybe<Scalars['String']>
}

/** Associate Segment Analytics Events to a content model [See type definition](https://app.contentful.com/spaces/9cxgygtadmvl/content_types/settingsAnalyticsModule) */
export type SettingsAnalyticsModuleInternalLabelArgs = {
  locale?: InputMaybe<Scalars['String']>
}

/** Associate Segment Analytics Events to a content model [See type definition](https://app.contentful.com/spaces/9cxgygtadmvl/content_types/settingsAnalyticsModule) */
export type SettingsAnalyticsModuleLinkedFromArgs = {
  allowedLocales?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
}

export type SettingsAnalyticsModuleCollection = {
  __typename?: 'SettingsAnalyticsModuleCollection'
  items: Array<Maybe<SettingsAnalyticsModule>>
  limit: Scalars['Int']
  skip: Scalars['Int']
  total: Scalars['Int']
}

export type SettingsAnalyticsModuleFilter = {
  AND?: InputMaybe<Array<InputMaybe<SettingsAnalyticsModuleFilter>>>
  OR?: InputMaybe<Array<InputMaybe<SettingsAnalyticsModuleFilter>>>
  contentfulMetadata?: InputMaybe<ContentfulMetadataFilter>
  eventContext?: InputMaybe<Scalars['String']>
  eventContext_contains?: InputMaybe<Scalars['String']>
  eventContext_exists?: InputMaybe<Scalars['Boolean']>
  eventContext_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  eventContext_not?: InputMaybe<Scalars['String']>
  eventContext_not_contains?: InputMaybe<Scalars['String']>
  eventContext_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  eventTypes_contains_all?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  eventTypes_contains_none?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  eventTypes_contains_some?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  eventTypes_exists?: InputMaybe<Scalars['Boolean']>
  internalLabel?: InputMaybe<Scalars['String']>
  internalLabel_contains?: InputMaybe<Scalars['String']>
  internalLabel_exists?: InputMaybe<Scalars['Boolean']>
  internalLabel_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  internalLabel_not?: InputMaybe<Scalars['String']>
  internalLabel_not_contains?: InputMaybe<Scalars['String']>
  internalLabel_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  sys?: InputMaybe<SysFilter>
}

export type SettingsAnalyticsModuleLinkingCollections = {
  __typename?: 'SettingsAnalyticsModuleLinkingCollections'
  campaignPagesCollection?: Maybe<CampaignPagesCollection>
  componentCarouselItemCollection?: Maybe<ComponentCarouselItemCollection>
  componentCarouselSectionCollection?: Maybe<ComponentCarouselSectionCollection>
  componentCenteredHeroCollection?: Maybe<ComponentCenteredHeroCollection>
  componentCheckeredCalloutCollection?: Maybe<ComponentCheckeredCalloutCollection>
  componentComparisonTableCollection?: Maybe<ComponentComparisonTableCollection>
  componentComparisonTableRowCollection?: Maybe<ComponentComparisonTableRowCollection>
  componentCtaBannerCollection?: Maybe<ComponentCtaBannerCollection>
  componentFeaturedAdvisorsCollection?: Maybe<ComponentFeaturedAdvisorsCollection>
  componentFeaturedGuidesCollection?: Maybe<ComponentFeaturedGuidesCollection>
  componentFeaturedReviewsCollection?: Maybe<ComponentFeaturedReviewsCollection>
  componentFormCollection?: Maybe<ComponentFormCollection>
  componentHeroCenteredCollection?: Maybe<ComponentHeroCenteredCollection>
  componentHeroFullWidthCollection?: Maybe<ComponentHeroFullWidthCollection>
  componentImageCollection?: Maybe<ComponentImageCollection>
  componentImageSideBySideCollection?: Maybe<ComponentImageSideBySideCollection>
  componentLargePhotoCarouselItemCollection?: Maybe<ComponentLargePhotoCarouselItemCollection>
  componentPartnerGridCollection?: Maybe<ComponentPartnerGridCollection>
  componentPictureSectionBannerCollection?: Maybe<ComponentPictureSectionBannerCollection>
  componentReviewCarouselCollection?: Maybe<ComponentReviewCarouselCollection>
  componentSubNavBarCollection?: Maybe<ComponentSubNavBarCollection>
  componentSubNavItemCollection?: Maybe<ComponentSubNavItemCollection>
  componentSubscriptionCardCollection?: Maybe<ComponentSubscriptionCardCollection>
  componentTeamSectionCollection?: Maybe<ComponentTeamSectionCollection>
  componentTestimonialCarouselCollection?: Maybe<ComponentTestimonialCarouselCollection>
  componentTextSectionCollection?: Maybe<ComponentTextSectionCollection>
  componentThreeIconSectionCollection?: Maybe<ComponentThreeIconSectionCollection>
  componentTwoColumnCalloutCollection?: Maybe<ComponentTwoColumnCalloutCollection>
  componentTwoColumnPerksSectionCollection?: Maybe<ComponentTwoColumnPerksSectionCollection>
  componentTwoColumnTextSectionCollection?: Maybe<ComponentTwoColumnTextSectionCollection>
  componentVideoSectionCollection?: Maybe<ComponentVideoSectionCollection>
  entryCollection?: Maybe<EntryCollection>
  pagesFeaturedHotelsCollection?: Maybe<PagesFeaturedHotelsCollection>
  pagesHeaderBlockCollection?: Maybe<PagesHeaderBlockCollection>
  pagesHeroBlockCollection?: Maybe<PagesHeroBlockCollection>
  pagesLearnMoreBlockCollection?: Maybe<PagesLearnMoreBlockCollection>
  pagesPartnerBannerCollection?: Maybe<PagesPartnerBannerCollection>
  pagesPerksListCollection?: Maybe<PagesPerksListCollection>
  pagesPressBannerCollection?: Maybe<PagesPressBannerCollection>
  pagesTwoColumnCtaCollection?: Maybe<PagesTwoColumnCtaCollection>
  templateAboutUsCollection?: Maybe<TemplateAboutUsCollection>
  templateAdvisorsCollection?: Maybe<TemplateAdvisorsCollection>
  templateBlogCollection?: Maybe<TemplateBlogCollection>
  templateCareersCollection?: Maybe<TemplateCareersCollection>
  templateContactUsCollection?: Maybe<TemplateContactUsCollection>
  templateDestinationsCollection?: Maybe<TemplateDestinationsCollection>
  templateFaqsCollection?: Maybe<TemplateFaqsCollection>
  templateGuidesCollection?: Maybe<TemplateGuidesCollection>
  templateHomeCollection?: Maybe<TemplateHomeCollection>
  templateItinerariesCollection?: Maybe<TemplateItinerariesCollection>
  templateJoinUsCollection?: Maybe<TemplateJoinUsCollection>
  templateNewsletterCollection?: Maybe<TemplateNewsletterCollection>
  templatePageCollection?: Maybe<TemplatePageCollection>
  templatePartnerDetailCollection?: Maybe<TemplatePartnerDetailCollection>
  templatePartnersCollection?: Maybe<TemplatePartnersCollection>
  templatePressCollection?: Maybe<TemplatePressCollection>
  templatePrivacyPolicyCollection?: Maybe<TemplatePrivacyPolicyCollection>
  templateSearchCollection?: Maybe<TemplateSearchCollection>
  templateTermsAndConditionsCollection?: Maybe<TemplateTermsAndConditionsCollection>
  templateTravelByCollection?: Maybe<TemplateTravelByCollection>
  templateTravelCollection?: Maybe<TemplateTravelCollection>
  templateWhyBookWithForaCollection?: Maybe<TemplateWhyBookWithForaCollection>
}

export type SettingsAnalyticsModuleLinkingCollectionsCampaignPagesCollectionArgs =
  {
    limit?: InputMaybe<Scalars['Int']>
    locale?: InputMaybe<Scalars['String']>
    order?: InputMaybe<
      Array<
        InputMaybe<SettingsAnalyticsModuleLinkingCollectionsCampaignPagesCollectionOrder>
      >
    >
    preview?: InputMaybe<Scalars['Boolean']>
    skip?: InputMaybe<Scalars['Int']>
  }

export type SettingsAnalyticsModuleLinkingCollectionsComponentCarouselItemCollectionArgs =
  {
    limit?: InputMaybe<Scalars['Int']>
    locale?: InputMaybe<Scalars['String']>
    order?: InputMaybe<
      Array<
        InputMaybe<SettingsAnalyticsModuleLinkingCollectionsComponentCarouselItemCollectionOrder>
      >
    >
    preview?: InputMaybe<Scalars['Boolean']>
    skip?: InputMaybe<Scalars['Int']>
  }

export type SettingsAnalyticsModuleLinkingCollectionsComponentCarouselSectionCollectionArgs =
  {
    limit?: InputMaybe<Scalars['Int']>
    locale?: InputMaybe<Scalars['String']>
    order?: InputMaybe<
      Array<
        InputMaybe<SettingsAnalyticsModuleLinkingCollectionsComponentCarouselSectionCollectionOrder>
      >
    >
    preview?: InputMaybe<Scalars['Boolean']>
    skip?: InputMaybe<Scalars['Int']>
  }

export type SettingsAnalyticsModuleLinkingCollectionsComponentCenteredHeroCollectionArgs =
  {
    limit?: InputMaybe<Scalars['Int']>
    locale?: InputMaybe<Scalars['String']>
    order?: InputMaybe<
      Array<
        InputMaybe<SettingsAnalyticsModuleLinkingCollectionsComponentCenteredHeroCollectionOrder>
      >
    >
    preview?: InputMaybe<Scalars['Boolean']>
    skip?: InputMaybe<Scalars['Int']>
  }

export type SettingsAnalyticsModuleLinkingCollectionsComponentCheckeredCalloutCollectionArgs =
  {
    limit?: InputMaybe<Scalars['Int']>
    locale?: InputMaybe<Scalars['String']>
    order?: InputMaybe<
      Array<
        InputMaybe<SettingsAnalyticsModuleLinkingCollectionsComponentCheckeredCalloutCollectionOrder>
      >
    >
    preview?: InputMaybe<Scalars['Boolean']>
    skip?: InputMaybe<Scalars['Int']>
  }

export type SettingsAnalyticsModuleLinkingCollectionsComponentComparisonTableCollectionArgs =
  {
    limit?: InputMaybe<Scalars['Int']>
    locale?: InputMaybe<Scalars['String']>
    order?: InputMaybe<
      Array<
        InputMaybe<SettingsAnalyticsModuleLinkingCollectionsComponentComparisonTableCollectionOrder>
      >
    >
    preview?: InputMaybe<Scalars['Boolean']>
    skip?: InputMaybe<Scalars['Int']>
  }

export type SettingsAnalyticsModuleLinkingCollectionsComponentComparisonTableRowCollectionArgs =
  {
    limit?: InputMaybe<Scalars['Int']>
    locale?: InputMaybe<Scalars['String']>
    order?: InputMaybe<
      Array<
        InputMaybe<SettingsAnalyticsModuleLinkingCollectionsComponentComparisonTableRowCollectionOrder>
      >
    >
    preview?: InputMaybe<Scalars['Boolean']>
    skip?: InputMaybe<Scalars['Int']>
  }

export type SettingsAnalyticsModuleLinkingCollectionsComponentCtaBannerCollectionArgs =
  {
    limit?: InputMaybe<Scalars['Int']>
    locale?: InputMaybe<Scalars['String']>
    order?: InputMaybe<
      Array<
        InputMaybe<SettingsAnalyticsModuleLinkingCollectionsComponentCtaBannerCollectionOrder>
      >
    >
    preview?: InputMaybe<Scalars['Boolean']>
    skip?: InputMaybe<Scalars['Int']>
  }

export type SettingsAnalyticsModuleLinkingCollectionsComponentFeaturedAdvisorsCollectionArgs =
  {
    limit?: InputMaybe<Scalars['Int']>
    locale?: InputMaybe<Scalars['String']>
    order?: InputMaybe<
      Array<
        InputMaybe<SettingsAnalyticsModuleLinkingCollectionsComponentFeaturedAdvisorsCollectionOrder>
      >
    >
    preview?: InputMaybe<Scalars['Boolean']>
    skip?: InputMaybe<Scalars['Int']>
  }

export type SettingsAnalyticsModuleLinkingCollectionsComponentFeaturedGuidesCollectionArgs =
  {
    limit?: InputMaybe<Scalars['Int']>
    locale?: InputMaybe<Scalars['String']>
    order?: InputMaybe<
      Array<
        InputMaybe<SettingsAnalyticsModuleLinkingCollectionsComponentFeaturedGuidesCollectionOrder>
      >
    >
    preview?: InputMaybe<Scalars['Boolean']>
    skip?: InputMaybe<Scalars['Int']>
  }

export type SettingsAnalyticsModuleLinkingCollectionsComponentFeaturedReviewsCollectionArgs =
  {
    limit?: InputMaybe<Scalars['Int']>
    locale?: InputMaybe<Scalars['String']>
    order?: InputMaybe<
      Array<
        InputMaybe<SettingsAnalyticsModuleLinkingCollectionsComponentFeaturedReviewsCollectionOrder>
      >
    >
    preview?: InputMaybe<Scalars['Boolean']>
    skip?: InputMaybe<Scalars['Int']>
  }

export type SettingsAnalyticsModuleLinkingCollectionsComponentFormCollectionArgs =
  {
    limit?: InputMaybe<Scalars['Int']>
    locale?: InputMaybe<Scalars['String']>
    order?: InputMaybe<
      Array<
        InputMaybe<SettingsAnalyticsModuleLinkingCollectionsComponentFormCollectionOrder>
      >
    >
    preview?: InputMaybe<Scalars['Boolean']>
    skip?: InputMaybe<Scalars['Int']>
  }

export type SettingsAnalyticsModuleLinkingCollectionsComponentHeroCenteredCollectionArgs =
  {
    limit?: InputMaybe<Scalars['Int']>
    locale?: InputMaybe<Scalars['String']>
    order?: InputMaybe<
      Array<
        InputMaybe<SettingsAnalyticsModuleLinkingCollectionsComponentHeroCenteredCollectionOrder>
      >
    >
    preview?: InputMaybe<Scalars['Boolean']>
    skip?: InputMaybe<Scalars['Int']>
  }

export type SettingsAnalyticsModuleLinkingCollectionsComponentHeroFullWidthCollectionArgs =
  {
    limit?: InputMaybe<Scalars['Int']>
    locale?: InputMaybe<Scalars['String']>
    order?: InputMaybe<
      Array<
        InputMaybe<SettingsAnalyticsModuleLinkingCollectionsComponentHeroFullWidthCollectionOrder>
      >
    >
    preview?: InputMaybe<Scalars['Boolean']>
    skip?: InputMaybe<Scalars['Int']>
  }

export type SettingsAnalyticsModuleLinkingCollectionsComponentImageCollectionArgs =
  {
    limit?: InputMaybe<Scalars['Int']>
    locale?: InputMaybe<Scalars['String']>
    order?: InputMaybe<
      Array<
        InputMaybe<SettingsAnalyticsModuleLinkingCollectionsComponentImageCollectionOrder>
      >
    >
    preview?: InputMaybe<Scalars['Boolean']>
    skip?: InputMaybe<Scalars['Int']>
  }

export type SettingsAnalyticsModuleLinkingCollectionsComponentImageSideBySideCollectionArgs =
  {
    limit?: InputMaybe<Scalars['Int']>
    locale?: InputMaybe<Scalars['String']>
    order?: InputMaybe<
      Array<
        InputMaybe<SettingsAnalyticsModuleLinkingCollectionsComponentImageSideBySideCollectionOrder>
      >
    >
    preview?: InputMaybe<Scalars['Boolean']>
    skip?: InputMaybe<Scalars['Int']>
  }

export type SettingsAnalyticsModuleLinkingCollectionsComponentLargePhotoCarouselItemCollectionArgs =
  {
    limit?: InputMaybe<Scalars['Int']>
    locale?: InputMaybe<Scalars['String']>
    order?: InputMaybe<
      Array<
        InputMaybe<SettingsAnalyticsModuleLinkingCollectionsComponentLargePhotoCarouselItemCollectionOrder>
      >
    >
    preview?: InputMaybe<Scalars['Boolean']>
    skip?: InputMaybe<Scalars['Int']>
  }

export type SettingsAnalyticsModuleLinkingCollectionsComponentPartnerGridCollectionArgs =
  {
    limit?: InputMaybe<Scalars['Int']>
    locale?: InputMaybe<Scalars['String']>
    order?: InputMaybe<
      Array<
        InputMaybe<SettingsAnalyticsModuleLinkingCollectionsComponentPartnerGridCollectionOrder>
      >
    >
    preview?: InputMaybe<Scalars['Boolean']>
    skip?: InputMaybe<Scalars['Int']>
  }

export type SettingsAnalyticsModuleLinkingCollectionsComponentPictureSectionBannerCollectionArgs =
  {
    limit?: InputMaybe<Scalars['Int']>
    locale?: InputMaybe<Scalars['String']>
    order?: InputMaybe<
      Array<
        InputMaybe<SettingsAnalyticsModuleLinkingCollectionsComponentPictureSectionBannerCollectionOrder>
      >
    >
    preview?: InputMaybe<Scalars['Boolean']>
    skip?: InputMaybe<Scalars['Int']>
  }

export type SettingsAnalyticsModuleLinkingCollectionsComponentReviewCarouselCollectionArgs =
  {
    limit?: InputMaybe<Scalars['Int']>
    locale?: InputMaybe<Scalars['String']>
    order?: InputMaybe<
      Array<
        InputMaybe<SettingsAnalyticsModuleLinkingCollectionsComponentReviewCarouselCollectionOrder>
      >
    >
    preview?: InputMaybe<Scalars['Boolean']>
    skip?: InputMaybe<Scalars['Int']>
  }

export type SettingsAnalyticsModuleLinkingCollectionsComponentSubNavBarCollectionArgs =
  {
    limit?: InputMaybe<Scalars['Int']>
    locale?: InputMaybe<Scalars['String']>
    order?: InputMaybe<
      Array<
        InputMaybe<SettingsAnalyticsModuleLinkingCollectionsComponentSubNavBarCollectionOrder>
      >
    >
    preview?: InputMaybe<Scalars['Boolean']>
    skip?: InputMaybe<Scalars['Int']>
  }

export type SettingsAnalyticsModuleLinkingCollectionsComponentSubNavItemCollectionArgs =
  {
    limit?: InputMaybe<Scalars['Int']>
    locale?: InputMaybe<Scalars['String']>
    order?: InputMaybe<
      Array<
        InputMaybe<SettingsAnalyticsModuleLinkingCollectionsComponentSubNavItemCollectionOrder>
      >
    >
    preview?: InputMaybe<Scalars['Boolean']>
    skip?: InputMaybe<Scalars['Int']>
  }

export type SettingsAnalyticsModuleLinkingCollectionsComponentSubscriptionCardCollectionArgs =
  {
    limit?: InputMaybe<Scalars['Int']>
    locale?: InputMaybe<Scalars['String']>
    order?: InputMaybe<
      Array<
        InputMaybe<SettingsAnalyticsModuleLinkingCollectionsComponentSubscriptionCardCollectionOrder>
      >
    >
    preview?: InputMaybe<Scalars['Boolean']>
    skip?: InputMaybe<Scalars['Int']>
  }

export type SettingsAnalyticsModuleLinkingCollectionsComponentTeamSectionCollectionArgs =
  {
    limit?: InputMaybe<Scalars['Int']>
    locale?: InputMaybe<Scalars['String']>
    order?: InputMaybe<
      Array<
        InputMaybe<SettingsAnalyticsModuleLinkingCollectionsComponentTeamSectionCollectionOrder>
      >
    >
    preview?: InputMaybe<Scalars['Boolean']>
    skip?: InputMaybe<Scalars['Int']>
  }

export type SettingsAnalyticsModuleLinkingCollectionsComponentTestimonialCarouselCollectionArgs =
  {
    limit?: InputMaybe<Scalars['Int']>
    locale?: InputMaybe<Scalars['String']>
    order?: InputMaybe<
      Array<
        InputMaybe<SettingsAnalyticsModuleLinkingCollectionsComponentTestimonialCarouselCollectionOrder>
      >
    >
    preview?: InputMaybe<Scalars['Boolean']>
    skip?: InputMaybe<Scalars['Int']>
  }

export type SettingsAnalyticsModuleLinkingCollectionsComponentTextSectionCollectionArgs =
  {
    limit?: InputMaybe<Scalars['Int']>
    locale?: InputMaybe<Scalars['String']>
    order?: InputMaybe<
      Array<
        InputMaybe<SettingsAnalyticsModuleLinkingCollectionsComponentTextSectionCollectionOrder>
      >
    >
    preview?: InputMaybe<Scalars['Boolean']>
    skip?: InputMaybe<Scalars['Int']>
  }

export type SettingsAnalyticsModuleLinkingCollectionsComponentThreeIconSectionCollectionArgs =
  {
    limit?: InputMaybe<Scalars['Int']>
    locale?: InputMaybe<Scalars['String']>
    order?: InputMaybe<
      Array<
        InputMaybe<SettingsAnalyticsModuleLinkingCollectionsComponentThreeIconSectionCollectionOrder>
      >
    >
    preview?: InputMaybe<Scalars['Boolean']>
    skip?: InputMaybe<Scalars['Int']>
  }

export type SettingsAnalyticsModuleLinkingCollectionsComponentTwoColumnCalloutCollectionArgs =
  {
    limit?: InputMaybe<Scalars['Int']>
    locale?: InputMaybe<Scalars['String']>
    order?: InputMaybe<
      Array<
        InputMaybe<SettingsAnalyticsModuleLinkingCollectionsComponentTwoColumnCalloutCollectionOrder>
      >
    >
    preview?: InputMaybe<Scalars['Boolean']>
    skip?: InputMaybe<Scalars['Int']>
  }

export type SettingsAnalyticsModuleLinkingCollectionsComponentTwoColumnPerksSectionCollectionArgs =
  {
    limit?: InputMaybe<Scalars['Int']>
    locale?: InputMaybe<Scalars['String']>
    order?: InputMaybe<
      Array<
        InputMaybe<SettingsAnalyticsModuleLinkingCollectionsComponentTwoColumnPerksSectionCollectionOrder>
      >
    >
    preview?: InputMaybe<Scalars['Boolean']>
    skip?: InputMaybe<Scalars['Int']>
  }

export type SettingsAnalyticsModuleLinkingCollectionsComponentTwoColumnTextSectionCollectionArgs =
  {
    limit?: InputMaybe<Scalars['Int']>
    locale?: InputMaybe<Scalars['String']>
    order?: InputMaybe<
      Array<
        InputMaybe<SettingsAnalyticsModuleLinkingCollectionsComponentTwoColumnTextSectionCollectionOrder>
      >
    >
    preview?: InputMaybe<Scalars['Boolean']>
    skip?: InputMaybe<Scalars['Int']>
  }

export type SettingsAnalyticsModuleLinkingCollectionsComponentVideoSectionCollectionArgs =
  {
    limit?: InputMaybe<Scalars['Int']>
    locale?: InputMaybe<Scalars['String']>
    order?: InputMaybe<
      Array<
        InputMaybe<SettingsAnalyticsModuleLinkingCollectionsComponentVideoSectionCollectionOrder>
      >
    >
    preview?: InputMaybe<Scalars['Boolean']>
    skip?: InputMaybe<Scalars['Int']>
  }

export type SettingsAnalyticsModuleLinkingCollectionsEntryCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>
  locale?: InputMaybe<Scalars['String']>
  preview?: InputMaybe<Scalars['Boolean']>
  skip?: InputMaybe<Scalars['Int']>
}

export type SettingsAnalyticsModuleLinkingCollectionsPagesFeaturedHotelsCollectionArgs =
  {
    limit?: InputMaybe<Scalars['Int']>
    locale?: InputMaybe<Scalars['String']>
    order?: InputMaybe<
      Array<
        InputMaybe<SettingsAnalyticsModuleLinkingCollectionsPagesFeaturedHotelsCollectionOrder>
      >
    >
    preview?: InputMaybe<Scalars['Boolean']>
    skip?: InputMaybe<Scalars['Int']>
  }

export type SettingsAnalyticsModuleLinkingCollectionsPagesHeaderBlockCollectionArgs =
  {
    limit?: InputMaybe<Scalars['Int']>
    locale?: InputMaybe<Scalars['String']>
    order?: InputMaybe<
      Array<
        InputMaybe<SettingsAnalyticsModuleLinkingCollectionsPagesHeaderBlockCollectionOrder>
      >
    >
    preview?: InputMaybe<Scalars['Boolean']>
    skip?: InputMaybe<Scalars['Int']>
  }

export type SettingsAnalyticsModuleLinkingCollectionsPagesHeroBlockCollectionArgs =
  {
    limit?: InputMaybe<Scalars['Int']>
    locale?: InputMaybe<Scalars['String']>
    order?: InputMaybe<
      Array<
        InputMaybe<SettingsAnalyticsModuleLinkingCollectionsPagesHeroBlockCollectionOrder>
      >
    >
    preview?: InputMaybe<Scalars['Boolean']>
    skip?: InputMaybe<Scalars['Int']>
  }

export type SettingsAnalyticsModuleLinkingCollectionsPagesLearnMoreBlockCollectionArgs =
  {
    limit?: InputMaybe<Scalars['Int']>
    locale?: InputMaybe<Scalars['String']>
    order?: InputMaybe<
      Array<
        InputMaybe<SettingsAnalyticsModuleLinkingCollectionsPagesLearnMoreBlockCollectionOrder>
      >
    >
    preview?: InputMaybe<Scalars['Boolean']>
    skip?: InputMaybe<Scalars['Int']>
  }

export type SettingsAnalyticsModuleLinkingCollectionsPagesPartnerBannerCollectionArgs =
  {
    limit?: InputMaybe<Scalars['Int']>
    locale?: InputMaybe<Scalars['String']>
    order?: InputMaybe<
      Array<
        InputMaybe<SettingsAnalyticsModuleLinkingCollectionsPagesPartnerBannerCollectionOrder>
      >
    >
    preview?: InputMaybe<Scalars['Boolean']>
    skip?: InputMaybe<Scalars['Int']>
  }

export type SettingsAnalyticsModuleLinkingCollectionsPagesPerksListCollectionArgs =
  {
    limit?: InputMaybe<Scalars['Int']>
    locale?: InputMaybe<Scalars['String']>
    order?: InputMaybe<
      Array<
        InputMaybe<SettingsAnalyticsModuleLinkingCollectionsPagesPerksListCollectionOrder>
      >
    >
    preview?: InputMaybe<Scalars['Boolean']>
    skip?: InputMaybe<Scalars['Int']>
  }

export type SettingsAnalyticsModuleLinkingCollectionsPagesPressBannerCollectionArgs =
  {
    limit?: InputMaybe<Scalars['Int']>
    locale?: InputMaybe<Scalars['String']>
    order?: InputMaybe<
      Array<
        InputMaybe<SettingsAnalyticsModuleLinkingCollectionsPagesPressBannerCollectionOrder>
      >
    >
    preview?: InputMaybe<Scalars['Boolean']>
    skip?: InputMaybe<Scalars['Int']>
  }

export type SettingsAnalyticsModuleLinkingCollectionsPagesTwoColumnCtaCollectionArgs =
  {
    limit?: InputMaybe<Scalars['Int']>
    locale?: InputMaybe<Scalars['String']>
    order?: InputMaybe<
      Array<
        InputMaybe<SettingsAnalyticsModuleLinkingCollectionsPagesTwoColumnCtaCollectionOrder>
      >
    >
    preview?: InputMaybe<Scalars['Boolean']>
    skip?: InputMaybe<Scalars['Int']>
  }

export type SettingsAnalyticsModuleLinkingCollectionsTemplateAboutUsCollectionArgs =
  {
    limit?: InputMaybe<Scalars['Int']>
    locale?: InputMaybe<Scalars['String']>
    order?: InputMaybe<
      Array<
        InputMaybe<SettingsAnalyticsModuleLinkingCollectionsTemplateAboutUsCollectionOrder>
      >
    >
    preview?: InputMaybe<Scalars['Boolean']>
    skip?: InputMaybe<Scalars['Int']>
  }

export type SettingsAnalyticsModuleLinkingCollectionsTemplateAdvisorsCollectionArgs =
  {
    limit?: InputMaybe<Scalars['Int']>
    locale?: InputMaybe<Scalars['String']>
    order?: InputMaybe<
      Array<
        InputMaybe<SettingsAnalyticsModuleLinkingCollectionsTemplateAdvisorsCollectionOrder>
      >
    >
    preview?: InputMaybe<Scalars['Boolean']>
    skip?: InputMaybe<Scalars['Int']>
  }

export type SettingsAnalyticsModuleLinkingCollectionsTemplateBlogCollectionArgs =
  {
    limit?: InputMaybe<Scalars['Int']>
    locale?: InputMaybe<Scalars['String']>
    order?: InputMaybe<
      Array<
        InputMaybe<SettingsAnalyticsModuleLinkingCollectionsTemplateBlogCollectionOrder>
      >
    >
    preview?: InputMaybe<Scalars['Boolean']>
    skip?: InputMaybe<Scalars['Int']>
  }

export type SettingsAnalyticsModuleLinkingCollectionsTemplateCareersCollectionArgs =
  {
    limit?: InputMaybe<Scalars['Int']>
    locale?: InputMaybe<Scalars['String']>
    order?: InputMaybe<
      Array<
        InputMaybe<SettingsAnalyticsModuleLinkingCollectionsTemplateCareersCollectionOrder>
      >
    >
    preview?: InputMaybe<Scalars['Boolean']>
    skip?: InputMaybe<Scalars['Int']>
  }

export type SettingsAnalyticsModuleLinkingCollectionsTemplateContactUsCollectionArgs =
  {
    limit?: InputMaybe<Scalars['Int']>
    locale?: InputMaybe<Scalars['String']>
    order?: InputMaybe<
      Array<
        InputMaybe<SettingsAnalyticsModuleLinkingCollectionsTemplateContactUsCollectionOrder>
      >
    >
    preview?: InputMaybe<Scalars['Boolean']>
    skip?: InputMaybe<Scalars['Int']>
  }

export type SettingsAnalyticsModuleLinkingCollectionsTemplateDestinationsCollectionArgs =
  {
    limit?: InputMaybe<Scalars['Int']>
    locale?: InputMaybe<Scalars['String']>
    order?: InputMaybe<
      Array<
        InputMaybe<SettingsAnalyticsModuleLinkingCollectionsTemplateDestinationsCollectionOrder>
      >
    >
    preview?: InputMaybe<Scalars['Boolean']>
    skip?: InputMaybe<Scalars['Int']>
  }

export type SettingsAnalyticsModuleLinkingCollectionsTemplateFaqsCollectionArgs =
  {
    limit?: InputMaybe<Scalars['Int']>
    locale?: InputMaybe<Scalars['String']>
    order?: InputMaybe<
      Array<
        InputMaybe<SettingsAnalyticsModuleLinkingCollectionsTemplateFaqsCollectionOrder>
      >
    >
    preview?: InputMaybe<Scalars['Boolean']>
    skip?: InputMaybe<Scalars['Int']>
  }

export type SettingsAnalyticsModuleLinkingCollectionsTemplateGuidesCollectionArgs =
  {
    limit?: InputMaybe<Scalars['Int']>
    locale?: InputMaybe<Scalars['String']>
    order?: InputMaybe<
      Array<
        InputMaybe<SettingsAnalyticsModuleLinkingCollectionsTemplateGuidesCollectionOrder>
      >
    >
    preview?: InputMaybe<Scalars['Boolean']>
    skip?: InputMaybe<Scalars['Int']>
  }

export type SettingsAnalyticsModuleLinkingCollectionsTemplateHomeCollectionArgs =
  {
    limit?: InputMaybe<Scalars['Int']>
    locale?: InputMaybe<Scalars['String']>
    order?: InputMaybe<
      Array<
        InputMaybe<SettingsAnalyticsModuleLinkingCollectionsTemplateHomeCollectionOrder>
      >
    >
    preview?: InputMaybe<Scalars['Boolean']>
    skip?: InputMaybe<Scalars['Int']>
  }

export type SettingsAnalyticsModuleLinkingCollectionsTemplateItinerariesCollectionArgs =
  {
    limit?: InputMaybe<Scalars['Int']>
    locale?: InputMaybe<Scalars['String']>
    order?: InputMaybe<
      Array<
        InputMaybe<SettingsAnalyticsModuleLinkingCollectionsTemplateItinerariesCollectionOrder>
      >
    >
    preview?: InputMaybe<Scalars['Boolean']>
    skip?: InputMaybe<Scalars['Int']>
  }

export type SettingsAnalyticsModuleLinkingCollectionsTemplateJoinUsCollectionArgs =
  {
    limit?: InputMaybe<Scalars['Int']>
    locale?: InputMaybe<Scalars['String']>
    order?: InputMaybe<
      Array<
        InputMaybe<SettingsAnalyticsModuleLinkingCollectionsTemplateJoinUsCollectionOrder>
      >
    >
    preview?: InputMaybe<Scalars['Boolean']>
    skip?: InputMaybe<Scalars['Int']>
  }

export type SettingsAnalyticsModuleLinkingCollectionsTemplateNewsletterCollectionArgs =
  {
    limit?: InputMaybe<Scalars['Int']>
    locale?: InputMaybe<Scalars['String']>
    order?: InputMaybe<
      Array<
        InputMaybe<SettingsAnalyticsModuleLinkingCollectionsTemplateNewsletterCollectionOrder>
      >
    >
    preview?: InputMaybe<Scalars['Boolean']>
    skip?: InputMaybe<Scalars['Int']>
  }

export type SettingsAnalyticsModuleLinkingCollectionsTemplatePageCollectionArgs =
  {
    limit?: InputMaybe<Scalars['Int']>
    locale?: InputMaybe<Scalars['String']>
    order?: InputMaybe<
      Array<
        InputMaybe<SettingsAnalyticsModuleLinkingCollectionsTemplatePageCollectionOrder>
      >
    >
    preview?: InputMaybe<Scalars['Boolean']>
    skip?: InputMaybe<Scalars['Int']>
  }

export type SettingsAnalyticsModuleLinkingCollectionsTemplatePartnerDetailCollectionArgs =
  {
    limit?: InputMaybe<Scalars['Int']>
    locale?: InputMaybe<Scalars['String']>
    order?: InputMaybe<
      Array<
        InputMaybe<SettingsAnalyticsModuleLinkingCollectionsTemplatePartnerDetailCollectionOrder>
      >
    >
    preview?: InputMaybe<Scalars['Boolean']>
    skip?: InputMaybe<Scalars['Int']>
  }

export type SettingsAnalyticsModuleLinkingCollectionsTemplatePartnersCollectionArgs =
  {
    limit?: InputMaybe<Scalars['Int']>
    locale?: InputMaybe<Scalars['String']>
    order?: InputMaybe<
      Array<
        InputMaybe<SettingsAnalyticsModuleLinkingCollectionsTemplatePartnersCollectionOrder>
      >
    >
    preview?: InputMaybe<Scalars['Boolean']>
    skip?: InputMaybe<Scalars['Int']>
  }

export type SettingsAnalyticsModuleLinkingCollectionsTemplatePressCollectionArgs =
  {
    limit?: InputMaybe<Scalars['Int']>
    locale?: InputMaybe<Scalars['String']>
    order?: InputMaybe<
      Array<
        InputMaybe<SettingsAnalyticsModuleLinkingCollectionsTemplatePressCollectionOrder>
      >
    >
    preview?: InputMaybe<Scalars['Boolean']>
    skip?: InputMaybe<Scalars['Int']>
  }

export type SettingsAnalyticsModuleLinkingCollectionsTemplatePrivacyPolicyCollectionArgs =
  {
    limit?: InputMaybe<Scalars['Int']>
    locale?: InputMaybe<Scalars['String']>
    order?: InputMaybe<
      Array<
        InputMaybe<SettingsAnalyticsModuleLinkingCollectionsTemplatePrivacyPolicyCollectionOrder>
      >
    >
    preview?: InputMaybe<Scalars['Boolean']>
    skip?: InputMaybe<Scalars['Int']>
  }

export type SettingsAnalyticsModuleLinkingCollectionsTemplateSearchCollectionArgs =
  {
    limit?: InputMaybe<Scalars['Int']>
    locale?: InputMaybe<Scalars['String']>
    order?: InputMaybe<
      Array<
        InputMaybe<SettingsAnalyticsModuleLinkingCollectionsTemplateSearchCollectionOrder>
      >
    >
    preview?: InputMaybe<Scalars['Boolean']>
    skip?: InputMaybe<Scalars['Int']>
  }

export type SettingsAnalyticsModuleLinkingCollectionsTemplateTermsAndConditionsCollectionArgs =
  {
    limit?: InputMaybe<Scalars['Int']>
    locale?: InputMaybe<Scalars['String']>
    order?: InputMaybe<
      Array<
        InputMaybe<SettingsAnalyticsModuleLinkingCollectionsTemplateTermsAndConditionsCollectionOrder>
      >
    >
    preview?: InputMaybe<Scalars['Boolean']>
    skip?: InputMaybe<Scalars['Int']>
  }

export type SettingsAnalyticsModuleLinkingCollectionsTemplateTravelByCollectionArgs =
  {
    limit?: InputMaybe<Scalars['Int']>
    locale?: InputMaybe<Scalars['String']>
    order?: InputMaybe<
      Array<
        InputMaybe<SettingsAnalyticsModuleLinkingCollectionsTemplateTravelByCollectionOrder>
      >
    >
    preview?: InputMaybe<Scalars['Boolean']>
    skip?: InputMaybe<Scalars['Int']>
  }

export type SettingsAnalyticsModuleLinkingCollectionsTemplateTravelCollectionArgs =
  {
    limit?: InputMaybe<Scalars['Int']>
    locale?: InputMaybe<Scalars['String']>
    order?: InputMaybe<
      Array<
        InputMaybe<SettingsAnalyticsModuleLinkingCollectionsTemplateTravelCollectionOrder>
      >
    >
    preview?: InputMaybe<Scalars['Boolean']>
    skip?: InputMaybe<Scalars['Int']>
  }

export type SettingsAnalyticsModuleLinkingCollectionsTemplateWhyBookWithForaCollectionArgs =
  {
    limit?: InputMaybe<Scalars['Int']>
    locale?: InputMaybe<Scalars['String']>
    order?: InputMaybe<
      Array<
        InputMaybe<SettingsAnalyticsModuleLinkingCollectionsTemplateWhyBookWithForaCollectionOrder>
      >
    >
    preview?: InputMaybe<Scalars['Boolean']>
    skip?: InputMaybe<Scalars['Int']>
  }

export enum SettingsAnalyticsModuleLinkingCollectionsCampaignPagesCollectionOrder {
  CollapseFooterAsc = 'collapseFooter_ASC',
  CollapseFooterDesc = 'collapseFooter_DESC',
  CollapseHeaderAsc = 'collapseHeader_ASC',
  CollapseHeaderDesc = 'collapseHeader_DESC',
  HideFooterAsc = 'hideFooter_ASC',
  HideFooterDesc = 'hideFooter_DESC',
  HideGlobalBannerAsc = 'hideGlobalBanner_ASC',
  HideGlobalBannerDesc = 'hideGlobalBanner_DESC',
  JotformIdAsc = 'jotformId_ASC',
  JotformIdDesc = 'jotformId_DESC',
  NoindexNofollowAsc = 'noindexNofollow_ASC',
  NoindexNofollowDesc = 'noindexNofollow_DESC',
  ShowHeaderAsc = 'showHeader_ASC',
  ShowHeaderDesc = 'showHeader_DESC',
  SlugAsc = 'slug_ASC',
  SlugDesc = 'slug_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC',
}

export enum SettingsAnalyticsModuleLinkingCollectionsComponentCarouselItemCollectionOrder {
  InternalLabelAsc = 'internalLabel_ASC',
  InternalLabelDesc = 'internalLabel_DESC',
  PrimaryLabelAsc = 'primaryLabel_ASC',
  PrimaryLabelDesc = 'primaryLabel_DESC',
  SecondaryLabelAsc = 'secondaryLabel_ASC',
  SecondaryLabelDesc = 'secondaryLabel_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
}

export enum SettingsAnalyticsModuleLinkingCollectionsComponentCarouselSectionCollectionOrder {
  ArrowStyleAsc = 'arrowStyle_ASC',
  ArrowStyleDesc = 'arrowStyle_DESC',
  DotsStyleAsc = 'dotsStyle_ASC',
  DotsStyleDesc = 'dotsStyle_DESC',
  HeadingAsc = 'heading_ASC',
  HeadingDesc = 'heading_DESC',
  InternalLabelAsc = 'internalLabel_ASC',
  InternalLabelDesc = 'internalLabel_DESC',
  ItemsPerPageAsc = 'itemsPerPage_ASC',
  ItemsPerPageDesc = 'itemsPerPage_DESC',
  SectionIdAsc = 'sectionId_ASC',
  SectionIdDesc = 'sectionId_DESC',
  ShowNextMobileAsc = 'showNextMobile_ASC',
  ShowNextMobileDesc = 'showNextMobile_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  TitleStyleAsc = 'titleStyle_ASC',
  TitleStyleDesc = 'titleStyle_DESC',
}

export enum SettingsAnalyticsModuleLinkingCollectionsComponentCenteredHeroCollectionOrder {
  CtaTextAsc = 'ctaText_ASC',
  CtaTextDesc = 'ctaText_DESC',
  CtaUrlAsc = 'ctaUrl_ASC',
  CtaUrlDesc = 'ctaUrl_DESC',
  InternalLabelAsc = 'internalLabel_ASC',
  InternalLabelDesc = 'internalLabel_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC',
  UrlTargetAsc = 'urlTarget_ASC',
  UrlTargetDesc = 'urlTarget_DESC',
}

export enum SettingsAnalyticsModuleLinkingCollectionsComponentCheckeredCalloutCollectionOrder {
  FullWidthAsc = 'fullWidth_ASC',
  FullWidthDesc = 'fullWidth_DESC',
  InternalLabelAsc = 'internalLabel_ASC',
  InternalLabelDesc = 'internalLabel_DESC',
  SectionIdAsc = 'sectionId_ASC',
  SectionIdDesc = 'sectionId_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
}

export enum SettingsAnalyticsModuleLinkingCollectionsComponentComparisonTableCollectionOrder {
  CtaTextAsc = 'ctaText_ASC',
  CtaTextDesc = 'ctaText_DESC',
  CtaUrlAsc = 'ctaUrl_ASC',
  CtaUrlDesc = 'ctaUrl_DESC',
  ForaColumnHeadingAsc = 'foraColumnHeading_ASC',
  ForaColumnHeadingDesc = 'foraColumnHeading_DESC',
  InternalLabelAsc = 'internalLabel_ASC',
  InternalLabelDesc = 'internalLabel_DESC',
  OthersColumnHeadingAsc = 'othersColumnHeading_ASC',
  OthersColumnHeadingDesc = 'othersColumnHeading_DESC',
  SectionIdAsc = 'sectionId_ASC',
  SectionIdDesc = 'sectionId_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  UrlTargetAsc = 'urlTarget_ASC',
  UrlTargetDesc = 'urlTarget_DESC',
}

export enum SettingsAnalyticsModuleLinkingCollectionsComponentComparisonTableRowCollectionOrder {
  InternalLabelAsc = 'internalLabel_ASC',
  InternalLabelDesc = 'internalLabel_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC',
}

export enum SettingsAnalyticsModuleLinkingCollectionsComponentCtaBannerCollectionOrder {
  CtaDestinationAsc = 'ctaDestination_ASC',
  CtaDestinationDesc = 'ctaDestination_DESC',
  CtaTextAsc = 'ctaText_ASC',
  CtaTextDesc = 'ctaText_DESC',
  FormTypeAsc = 'formType_ASC',
  FormTypeDesc = 'formType_DESC',
  HeadingAsc = 'heading_ASC',
  HeadingDesc = 'heading_DESC',
  IncludeFormAsc = 'includeForm_ASC',
  IncludeFormDesc = 'includeForm_DESC',
  InternalLabelAsc = 'internalLabel_ASC',
  InternalLabelDesc = 'internalLabel_DESC',
  SectionIdAsc = 'sectionId_ASC',
  SectionIdDesc = 'sectionId_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  TitleStyleAsc = 'titleStyle_ASC',
  TitleStyleDesc = 'titleStyle_DESC',
  UrlTargetAsc = 'urlTarget_ASC',
  UrlTargetDesc = 'urlTarget_DESC',
  ZapierIdAsc = 'zapierId_ASC',
  ZapierIdDesc = 'zapierId_DESC',
}

export enum SettingsAnalyticsModuleLinkingCollectionsComponentFeaturedAdvisorsCollectionOrder {
  CtaTargetAsc = 'ctaTarget_ASC',
  CtaTargetDesc = 'ctaTarget_DESC',
  CtaTextAsc = 'ctaText_ASC',
  CtaTextDesc = 'ctaText_DESC',
  InternalLabelAsc = 'internalLabel_ASC',
  InternalLabelDesc = 'internalLabel_DESC',
  SectionIdAsc = 'sectionId_ASC',
  SectionIdDesc = 'sectionId_DESC',
  ShowCtaAsc = 'showCta_ASC',
  ShowCtaDesc = 'showCta_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  TitleStyleAsc = 'titleStyle_ASC',
  TitleStyleDesc = 'titleStyle_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC',
}

export enum SettingsAnalyticsModuleLinkingCollectionsComponentFeaturedGuidesCollectionOrder {
  CtaTargetAsc = 'ctaTarget_ASC',
  CtaTargetDesc = 'ctaTarget_DESC',
  CtaTextAsc = 'ctaText_ASC',
  CtaTextDesc = 'ctaText_DESC',
  InternalLabelAsc = 'internalLabel_ASC',
  InternalLabelDesc = 'internalLabel_DESC',
  SectionIdAsc = 'sectionId_ASC',
  SectionIdDesc = 'sectionId_DESC',
  ShowCtaAsc = 'showCta_ASC',
  ShowCtaDesc = 'showCta_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  TitleStyleAsc = 'titleStyle_ASC',
  TitleStyleDesc = 'titleStyle_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC',
}

export enum SettingsAnalyticsModuleLinkingCollectionsComponentFeaturedReviewsCollectionOrder {
  HeadingAsc = 'heading_ASC',
  HeadingDesc = 'heading_DESC',
  InternalLabelAsc = 'internalLabel_ASC',
  InternalLabelDesc = 'internalLabel_DESC',
  SectionIdAsc = 'sectionId_ASC',
  SectionIdDesc = 'sectionId_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  TitleStyleAsc = 'titleStyle_ASC',
  TitleStyleDesc = 'titleStyle_DESC',
}

export enum SettingsAnalyticsModuleLinkingCollectionsComponentFormCollectionOrder {
  ButtonTextSharedStateAsc = 'buttonTextSharedState_ASC',
  ButtonTextSharedStateDesc = 'buttonTextSharedState_DESC',
  ButtonTextAsc = 'buttonText_ASC',
  ButtonTextDesc = 'buttonText_DESC',
  FormIdAsc = 'formId_ASC',
  FormIdDesc = 'formId_DESC',
  InternalLabelAsc = 'internalLabel_ASC',
  InternalLabelDesc = 'internalLabel_DESC',
  SectionIdAsc = 'sectionId_ASC',
  SectionIdDesc = 'sectionId_DESC',
  SuccessTextAsc = 'successText_ASC',
  SuccessTextDesc = 'successText_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  TitleSharedStateAsc = 'titleSharedState_ASC',
  TitleSharedStateDesc = 'titleSharedState_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC',
  TypeformPopOutUrlAsc = 'typeformPopOutUrl_ASC',
  TypeformPopOutUrlDesc = 'typeformPopOutUrl_DESC',
  TypeformUrlAsc = 'typeformUrl_ASC',
  TypeformUrlDesc = 'typeformUrl_DESC',
  ZapierIdAsc = 'zapierId_ASC',
  ZapierIdDesc = 'zapierId_DESC',
}

export enum SettingsAnalyticsModuleLinkingCollectionsComponentHeroCenteredCollectionOrder {
  CtaTextAsc = 'ctaText_ASC',
  CtaTextDesc = 'ctaText_DESC',
  CtaUrlAsc = 'ctaUrl_ASC',
  CtaUrlDesc = 'ctaUrl_DESC',
  InternalLabelAsc = 'internalLabel_ASC',
  InternalLabelDesc = 'internalLabel_DESC',
  SectionIdAsc = 'sectionId_ASC',
  SectionIdDesc = 'sectionId_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  TitleStyleAsc = 'titleStyle_ASC',
  TitleStyleDesc = 'titleStyle_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC',
  UrlTargetAsc = 'urlTarget_ASC',
  UrlTargetDesc = 'urlTarget_DESC',
}

export enum SettingsAnalyticsModuleLinkingCollectionsComponentHeroFullWidthCollectionOrder {
  BottomTextAsc = 'bottomText_ASC',
  BottomTextDesc = 'bottomText_DESC',
  ButtonCtaTextAsc = 'buttonCtaText_ASC',
  ButtonCtaTextDesc = 'buttonCtaText_DESC',
  ButtonCtaUrlAsc = 'buttonCtaUrl_ASC',
  ButtonCtaUrlDesc = 'buttonCtaUrl_DESC',
  InternalLabelAsc = 'internalLabel_ASC',
  InternalLabelDesc = 'internalLabel_DESC',
  LightTextAsc = 'lightText_ASC',
  LightTextDesc = 'lightText_DESC',
  MediaSourceAsc = 'mediaSource_ASC',
  MediaSourceDesc = 'mediaSource_DESC',
  MobileMediaSourceAsc = 'mobileMediaSource_ASC',
  MobileMediaSourceDesc = 'mobileMediaSource_DESC',
  SectionIdAsc = 'sectionId_ASC',
  SectionIdDesc = 'sectionId_DESC',
  ShowAverageRatingAsc = 'showAverageRating_ASC',
  ShowAverageRatingDesc = 'showAverageRating_DESC',
  ShowForaLogoAsc = 'showForaLogo_ASC',
  ShowForaLogoDesc = 'showForaLogo_DESC',
  SubCopyAsc = 'subCopy_ASC',
  SubCopyDesc = 'subCopy_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC',
  UrlTargetAsc = 'urlTarget_ASC',
  UrlTargetDesc = 'urlTarget_DESC',
}

export enum SettingsAnalyticsModuleLinkingCollectionsComponentImageCollectionOrder {
  AltTextAsc = 'altText_ASC',
  AltTextDesc = 'altText_DESC',
  ImageCreditAsc = 'imageCredit_ASC',
  ImageCreditDesc = 'imageCredit_DESC',
  InternalTitleAsc = 'internalTitle_ASC',
  InternalTitleDesc = 'internalTitle_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
}

export enum SettingsAnalyticsModuleLinkingCollectionsComponentImageSideBySideCollectionOrder {
  CaptionAsc = 'caption_ASC',
  CaptionDesc = 'caption_DESC',
  InternalLabelAsc = 'internalLabel_ASC',
  InternalLabelDesc = 'internalLabel_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
}

export enum SettingsAnalyticsModuleLinkingCollectionsComponentLargePhotoCarouselItemCollectionOrder {
  InternalLabelAsc = 'internalLabel_ASC',
  InternalLabelDesc = 'internalLabel_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
}

export enum SettingsAnalyticsModuleLinkingCollectionsComponentPartnerGridCollectionOrder {
  HeadingSharedStateAsc = 'headingSharedState_ASC',
  HeadingSharedStateDesc = 'headingSharedState_DESC',
  HeadingAsc = 'heading_ASC',
  HeadingDesc = 'heading_DESC',
  InternalLabelAsc = 'internalLabel_ASC',
  InternalLabelDesc = 'internalLabel_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  TitleStyleAsc = 'titleStyle_ASC',
  TitleStyleDesc = 'titleStyle_DESC',
}

export enum SettingsAnalyticsModuleLinkingCollectionsComponentPictureSectionBannerCollectionOrder {
  CardSizeAsc = 'cardSize_ASC',
  CardSizeDesc = 'cardSize_DESC',
  InternalLabelAsc = 'internalLabel_ASC',
  InternalLabelDesc = 'internalLabel_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC',
}

export enum SettingsAnalyticsModuleLinkingCollectionsComponentReviewCarouselCollectionOrder {
  HeadingAsc = 'heading_ASC',
  HeadingDesc = 'heading_DESC',
  InternalLabelAsc = 'internalLabel_ASC',
  InternalLabelDesc = 'internalLabel_DESC',
  SectionIdAsc = 'sectionId_ASC',
  SectionIdDesc = 'sectionId_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  TitleStyleAsc = 'titleStyle_ASC',
  TitleStyleDesc = 'titleStyle_DESC',
}

export enum SettingsAnalyticsModuleLinkingCollectionsComponentSubNavBarCollectionOrder {
  InternalLabelAsc = 'internalLabel_ASC',
  InternalLabelDesc = 'internalLabel_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
}

export enum SettingsAnalyticsModuleLinkingCollectionsComponentSubNavItemCollectionOrder {
  LabelAsc = 'label_ASC',
  LabelDesc = 'label_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  UrlAsc = 'url_ASC',
  UrlDesc = 'url_DESC',
}

export enum SettingsAnalyticsModuleLinkingCollectionsComponentSubscriptionCardCollectionOrder {
  AnnualDiscountPriceAsc = 'annualDiscountPrice_ASC',
  AnnualDiscountPriceDesc = 'annualDiscountPrice_DESC',
  AnnualFeaturedTextAsc = 'annualFeaturedText_ASC',
  AnnualFeaturedTextDesc = 'annualFeaturedText_DESC',
  AnnualIsLimitedAsc = 'annualIsLimited_ASC',
  AnnualIsLimitedDesc = 'annualIsLimited_DESC',
  AnnualOriginalPriceAsc = 'annualOriginalPrice_ASC',
  AnnualOriginalPriceDesc = 'annualOriginalPrice_DESC',
  AnnualPriceDetailsAsc = 'annualPriceDetails_ASC',
  AnnualPriceDetailsDesc = 'annualPriceDetails_DESC',
  CtaTextAsc = 'ctaText_ASC',
  CtaTextDesc = 'ctaText_DESC',
  CtaUrlAsc = 'ctaUrl_ASC',
  CtaUrlDesc = 'ctaUrl_DESC',
  HeadingAsc = 'heading_ASC',
  HeadingDesc = 'heading_DESC',
  InternalLabelAsc = 'internalLabel_ASC',
  InternalLabelDesc = 'internalLabel_DESC',
  MonthlyDiscountPriceAsc = 'monthlyDiscountPrice_ASC',
  MonthlyDiscountPriceDesc = 'monthlyDiscountPrice_DESC',
  MonthlyFeaturedTextAsc = 'monthlyFeaturedText_ASC',
  MonthlyFeaturedTextDesc = 'monthlyFeaturedText_DESC',
  MonthlyIsLimitedAsc = 'monthlyIsLimited_ASC',
  MonthlyIsLimitedDesc = 'monthlyIsLimited_DESC',
  MonthlyOriginalPriceAsc = 'monthlyOriginalPrice_ASC',
  MonthlyOriginalPriceDesc = 'monthlyOriginalPrice_DESC',
  MonthlyPriceDetailsAsc = 'monthlyPriceDetails_ASC',
  MonthlyPriceDetailsDesc = 'monthlyPriceDetails_DESC',
  SectionIdAsc = 'sectionId_ASC',
  SectionIdDesc = 'sectionId_DESC',
  ShowMultiplePricingModelsAsc = 'showMultiplePricingModels_ASC',
  ShowMultiplePricingModelsDesc = 'showMultiplePricingModels_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  TextOnRightAsc = 'textOnRight_ASC',
  TextOnRightDesc = 'textOnRight_DESC',
  TitleStyleAsc = 'titleStyle_ASC',
  TitleStyleDesc = 'titleStyle_DESC',
  UrlTargetAsc = 'urlTarget_ASC',
  UrlTargetDesc = 'urlTarget_DESC',
}

export enum SettingsAnalyticsModuleLinkingCollectionsComponentTeamSectionCollectionOrder {
  InternalLabelAsc = 'internalLabel_ASC',
  InternalLabelDesc = 'internalLabel_DESC',
  SectionIdAsc = 'sectionId_ASC',
  SectionIdDesc = 'sectionId_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  TitleStyleAsc = 'titleStyle_ASC',
  TitleStyleDesc = 'titleStyle_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC',
}

export enum SettingsAnalyticsModuleLinkingCollectionsComponentTestimonialCarouselCollectionOrder {
  HeadingAsc = 'heading_ASC',
  HeadingDesc = 'heading_DESC',
  InternalLabelAsc = 'internalLabel_ASC',
  InternalLabelDesc = 'internalLabel_DESC',
  SectionIdAsc = 'sectionId_ASC',
  SectionIdDesc = 'sectionId_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  TitleStyleAsc = 'titleStyle_ASC',
  TitleStyleDesc = 'titleStyle_DESC',
}

export enum SettingsAnalyticsModuleLinkingCollectionsComponentTextSectionCollectionOrder {
  AlignCenterAsc = 'alignCenter_ASC',
  AlignCenterDesc = 'alignCenter_DESC',
  InternalLabelAsc = 'internalLabel_ASC',
  InternalLabelDesc = 'internalLabel_DESC',
  SectionIdAsc = 'sectionId_ASC',
  SectionIdDesc = 'sectionId_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
}

export enum SettingsAnalyticsModuleLinkingCollectionsComponentThreeIconSectionCollectionOrder {
  CtaTextAsc = 'ctaText_ASC',
  CtaTextDesc = 'ctaText_DESC',
  CtaUrlAsc = 'ctaUrl_ASC',
  CtaUrlDesc = 'ctaUrl_DESC',
  IsCompactAsc = 'isCompact_ASC',
  IsCompactDesc = 'isCompact_DESC',
  SectionIdAsc = 'sectionId_ASC',
  SectionIdDesc = 'sectionId_DESC',
  SolidBackgroundAsc = 'solidBackground_ASC',
  SolidBackgroundDesc = 'solidBackground_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  TitleStyleAsc = 'titleStyle_ASC',
  TitleStyleDesc = 'titleStyle_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC',
  UrlTargetAsc = 'urlTarget_ASC',
  UrlTargetDesc = 'urlTarget_DESC',
}

export enum SettingsAnalyticsModuleLinkingCollectionsComponentTwoColumnCalloutCollectionOrder {
  CtaTextAsc = 'ctaText_ASC',
  CtaTextDesc = 'ctaText_DESC',
  CtaThemeAsc = 'ctaTheme_ASC',
  CtaThemeDesc = 'ctaTheme_DESC',
  CtaUrlAsc = 'ctaUrl_ASC',
  CtaUrlDesc = 'ctaUrl_DESC',
  InternalLabelAsc = 'internalLabel_ASC',
  InternalLabelDesc = 'internalLabel_DESC',
  SectionIdAsc = 'sectionId_ASC',
  SectionIdDesc = 'sectionId_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
}

export enum SettingsAnalyticsModuleLinkingCollectionsComponentTwoColumnPerksSectionCollectionOrder {
  HeadingStyleAsc = 'headingStyle_ASC',
  HeadingStyleDesc = 'headingStyle_DESC',
  HeadingAsc = 'heading_ASC',
  HeadingDesc = 'heading_DESC',
  ImageOnRightAsc = 'imageOnRight_ASC',
  ImageOnRightDesc = 'imageOnRight_DESC',
  InternalLabelAsc = 'internalLabel_ASC',
  InternalLabelDesc = 'internalLabel_DESC',
  SectionIdAsc = 'sectionId_ASC',
  SectionIdDesc = 'sectionId_DESC',
  SubtitleOneAsc = 'subtitleOne_ASC',
  SubtitleOneDesc = 'subtitleOne_DESC',
  SubtitleThreeAsc = 'subtitleThree_ASC',
  SubtitleThreeDesc = 'subtitleThree_DESC',
  SubtitleTwoAsc = 'subtitleTwo_ASC',
  SubtitleTwoDesc = 'subtitleTwo_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  TitleStyleAsc = 'titleStyle_ASC',
  TitleStyleDesc = 'titleStyle_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC',
}

export enum SettingsAnalyticsModuleLinkingCollectionsComponentTwoColumnTextSectionCollectionOrder {
  EmphasizedColumnAsc = 'emphasizedColumn_ASC',
  EmphasizedColumnDesc = 'emphasizedColumn_DESC',
  InternalLabelAsc = 'internalLabel_ASC',
  InternalLabelDesc = 'internalLabel_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  TitleStyleAsc = 'titleStyle_ASC',
  TitleStyleDesc = 'titleStyle_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC',
}

export enum SettingsAnalyticsModuleLinkingCollectionsComponentVideoSectionCollectionOrder {
  AutoplayAsc = 'autoplay_ASC',
  AutoplayDesc = 'autoplay_DESC',
  HeadingAsc = 'heading_ASC',
  HeadingDesc = 'heading_DESC',
  InternalLabelAsc = 'internalLabel_ASC',
  InternalLabelDesc = 'internalLabel_DESC',
  LoopAsc = 'loop_ASC',
  LoopDesc = 'loop_DESC',
  MutedAsc = 'muted_ASC',
  MutedDesc = 'muted_DESC',
  SectionIdAsc = 'sectionId_ASC',
  SectionIdDesc = 'sectionId_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  TitleStyleAsc = 'titleStyle_ASC',
  TitleStyleDesc = 'titleStyle_DESC',
}

export enum SettingsAnalyticsModuleLinkingCollectionsPagesFeaturedHotelsCollectionOrder {
  DisplayAsc = 'display_ASC',
  DisplayDesc = 'display_DESC',
  HeaderAlignmentAsc = 'headerAlignment_ASC',
  HeaderAlignmentDesc = 'headerAlignment_DESC',
  ShowControlsAsc = 'showControls_ASC',
  ShowControlsDesc = 'showControls_DESC',
  ShowTitleAsc = 'showTitle_ASC',
  ShowTitleDesc = 'showTitle_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC',
}

export enum SettingsAnalyticsModuleLinkingCollectionsPagesHeaderBlockCollectionOrder {
  BordersAsc = 'borders_ASC',
  BordersDesc = 'borders_DESC',
  CtaTextAsc = 'ctaText_ASC',
  CtaTextDesc = 'ctaText_DESC',
  CtaThemeAsc = 'ctaTheme_ASC',
  CtaThemeDesc = 'ctaTheme_DESC',
  CtaUrlAsc = 'ctaUrl_ASC',
  CtaUrlDesc = 'ctaUrl_DESC',
  FootNoteAsc = 'footNote_ASC',
  FootNoteDesc = 'footNote_DESC',
  SecondaryCtaTextAsc = 'secondaryCtaText_ASC',
  SecondaryCtaTextDesc = 'secondaryCtaText_DESC',
  SecondaryCtaUrlAsc = 'secondaryCtaUrl_ASC',
  SecondaryCtaUrlDesc = 'secondaryCtaUrl_DESC',
  SecondaryUrlTargetAsc = 'secondaryUrlTarget_ASC',
  SecondaryUrlTargetDesc = 'secondaryUrlTarget_DESC',
  SectionIdAsc = 'sectionId_ASC',
  SectionIdDesc = 'sectionId_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC',
  UrlTargetAsc = 'urlTarget_ASC',
  UrlTargetDesc = 'urlTarget_DESC',
}

export enum SettingsAnalyticsModuleLinkingCollectionsPagesHeroBlockCollectionOrder {
  PrimaryCtaLinkTextAsc = 'primaryCtaLinkText_ASC',
  PrimaryCtaLinkTextDesc = 'primaryCtaLinkText_DESC',
  PrimaryCtaLinkUrlAsc = 'primaryCtaLinkUrl_ASC',
  PrimaryCtaLinkUrlDesc = 'primaryCtaLinkUrl_DESC',
  PrimaryUrlTargetAsc = 'primaryUrlTarget_ASC',
  PrimaryUrlTargetDesc = 'primaryUrlTarget_DESC',
  SecondaryCtaLinkTextAsc = 'secondaryCtaLinkText_ASC',
  SecondaryCtaLinkTextDesc = 'secondaryCtaLinkText_DESC',
  SecondaryCtaLinkUrlAsc = 'secondaryCtaLinkUrl_ASC',
  SecondaryCtaLinkUrlDesc = 'secondaryCtaLinkUrl_DESC',
  SecondaryUrlTargetAsc = 'secondaryUrlTarget_ASC',
  SecondaryUrlTargetDesc = 'secondaryUrlTarget_DESC',
  ShowFormAsPrimaryCtaAsc = 'showFormAsPrimaryCta_ASC',
  ShowFormAsPrimaryCtaDesc = 'showFormAsPrimaryCta_DESC',
  ShowSignUpFormAsPrimaryCtaAsc = 'showSignUpFormAsPrimaryCta_ASC',
  ShowSignUpFormAsPrimaryCtaDesc = 'showSignUpFormAsPrimaryCta_DESC',
  SubFormHeaderAsc = 'subFormHeader_ASC',
  SubFormHeaderDesc = 'subFormHeader_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  TitleStyleAsc = 'titleStyle_ASC',
  TitleStyleDesc = 'titleStyle_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC',
}

export enum SettingsAnalyticsModuleLinkingCollectionsPagesLearnMoreBlockCollectionOrder {
  AnimateAsc = 'animate_ASC',
  AnimateDesc = 'animate_DESC',
  BgColorAsc = 'bgColor_ASC',
  BgColorDesc = 'bgColor_DESC',
  CtaTextAsc = 'ctaText_ASC',
  CtaTextDesc = 'ctaText_DESC',
  CtaUrlAsc = 'ctaUrl_ASC',
  CtaUrlDesc = 'ctaUrl_DESC',
  InternalLabelAsc = 'internalLabel_ASC',
  InternalLabelDesc = 'internalLabel_DESC',
  IsCompactAsc = 'isCompact_ASC',
  IsCompactDesc = 'isCompact_DESC',
  SectionIdAsc = 'sectionId_ASC',
  SectionIdDesc = 'sectionId_DESC',
  SeparatorMobileDisplayAsc = 'separatorMobileDisplay_ASC',
  SeparatorMobileDisplayDesc = 'separatorMobileDisplay_DESC',
  SolidBackgroundAsc = 'solidBackground_ASC',
  SolidBackgroundDesc = 'solidBackground_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  TitleStyleAsc = 'titleStyle_ASC',
  TitleStyleDesc = 'titleStyle_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC',
  UrlTargetAsc = 'urlTarget_ASC',
  UrlTargetDesc = 'urlTarget_DESC',
}

export enum SettingsAnalyticsModuleLinkingCollectionsPagesPartnerBannerCollectionOrder {
  AdminLabelAsc = 'adminLabel_ASC',
  AdminLabelDesc = 'adminLabel_DESC',
  ShowViewMoreButtonAsc = 'showViewMoreButton_ASC',
  ShowViewMoreButtonDesc = 'showViewMoreButton_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
}

export enum SettingsAnalyticsModuleLinkingCollectionsPagesPerksListCollectionOrder {
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  TitleStyleAsc = 'titleStyle_ASC',
  TitleStyleDesc = 'titleStyle_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC',
}

export enum SettingsAnalyticsModuleLinkingCollectionsPagesPressBannerCollectionOrder {
  AdminLabelAsc = 'adminLabel_ASC',
  AdminLabelDesc = 'adminLabel_DESC',
  ApplyBackgroundAsc = 'applyBackground_ASC',
  ApplyBackgroundDesc = 'applyBackground_DESC',
  LogoSizeAsc = 'logoSize_ASC',
  LogoSizeDesc = 'logoSize_DESC',
  RedirectSlugAsc = 'redirectSlug_ASC',
  RedirectSlugDesc = 'redirectSlug_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC',
}

export enum SettingsAnalyticsModuleLinkingCollectionsPagesTwoColumnCtaCollectionOrder {
  AdvisorDescriptionAsc = 'advisorDescription_ASC',
  AdvisorDescriptionDesc = 'advisorDescription_DESC',
  BackgroundColorAsc = 'backgroundColor_ASC',
  BackgroundColorDesc = 'backgroundColor_DESC',
  ButtonTextAsc = 'buttonText_ASC',
  ButtonTextDesc = 'buttonText_DESC',
  ButtonThemeAsc = 'buttonTheme_ASC',
  ButtonThemeDesc = 'buttonTheme_DESC',
  ButtonUrlAsc = 'buttonUrl_ASC',
  ButtonUrlDesc = 'buttonUrl_DESC',
  CardSizeAsc = 'cardSize_ASC',
  CardSizeDesc = 'cardSize_DESC',
  ContentColumnAsc = 'contentColumn_ASC',
  ContentColumnDesc = 'contentColumn_DESC',
  FormIdAsc = 'formId_ASC',
  FormIdDesc = 'formId_DESC',
  InternalLabelAsc = 'internalLabel_ASC',
  InternalLabelDesc = 'internalLabel_DESC',
  MobileAlignmentAsc = 'mobileAlignment_ASC',
  MobileAlignmentDesc = 'mobileAlignment_DESC',
  SectionIdAsc = 'sectionId_ASC',
  SectionIdDesc = 'sectionId_DESC',
  SolidBackgroundAsc = 'solidBackground_ASC',
  SolidBackgroundDesc = 'solidBackground_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  TitleStyleAsc = 'titleStyle_ASC',
  TitleStyleDesc = 'titleStyle_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC',
  UrlTargetAsc = 'urlTarget_ASC',
  UrlTargetDesc = 'urlTarget_DESC',
  YMarginAsc = 'yMargin_ASC',
  YMarginDesc = 'yMargin_DESC',
}

export enum SettingsAnalyticsModuleLinkingCollectionsTemplateAboutUsCollectionOrder {
  CollapseHeaderAsc = 'collapseHeader_ASC',
  CollapseHeaderDesc = 'collapseHeader_DESC',
  HeaderBlockTitleAsc = 'headerBlockTitle_ASC',
  HeaderBlockTitleDesc = 'headerBlockTitle_DESC',
  ShowHeaderAsc = 'showHeader_ASC',
  ShowHeaderDesc = 'showHeader_DESC',
  SubtitleAsc = 'subtitle_ASC',
  SubtitleDesc = 'subtitle_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC',
}

export enum SettingsAnalyticsModuleLinkingCollectionsTemplateAdvisorsCollectionOrder {
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC',
}

export enum SettingsAnalyticsModuleLinkingCollectionsTemplateBlogCollectionOrder {
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC',
}

export enum SettingsAnalyticsModuleLinkingCollectionsTemplateCareersCollectionOrder {
  CollapseHeaderAsc = 'collapseHeader_ASC',
  CollapseHeaderDesc = 'collapseHeader_DESC',
  ShowHeaderAsc = 'showHeader_ASC',
  ShowHeaderDesc = 'showHeader_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC',
}

export enum SettingsAnalyticsModuleLinkingCollectionsTemplateContactUsCollectionOrder {
  CollapseHeaderAsc = 'collapseHeader_ASC',
  CollapseHeaderDesc = 'collapseHeader_DESC',
  ShowHeaderAsc = 'showHeader_ASC',
  ShowHeaderDesc = 'showHeader_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC',
}

export enum SettingsAnalyticsModuleLinkingCollectionsTemplateDestinationsCollectionOrder {
  CollapseHeaderAsc = 'collapseHeader_ASC',
  CollapseHeaderDesc = 'collapseHeader_DESC',
  CollapsibleHeadingAsc = 'collapsibleHeading_ASC',
  CollapsibleHeadingDesc = 'collapsibleHeading_DESC',
  ShowHeaderAsc = 'showHeader_ASC',
  ShowHeaderDesc = 'showHeader_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC',
}

export enum SettingsAnalyticsModuleLinkingCollectionsTemplateFaqsCollectionOrder {
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC',
}

export enum SettingsAnalyticsModuleLinkingCollectionsTemplateGuidesCollectionOrder {
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC',
}

export enum SettingsAnalyticsModuleLinkingCollectionsTemplateHomeCollectionOrder {
  CollapseHeaderAsc = 'collapseHeader_ASC',
  CollapseHeaderDesc = 'collapseHeader_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC',
}

export enum SettingsAnalyticsModuleLinkingCollectionsTemplateItinerariesCollectionOrder {
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC',
}

export enum SettingsAnalyticsModuleLinkingCollectionsTemplateJoinUsCollectionOrder {
  CollapseHeaderAsc = 'collapseHeader_ASC',
  CollapseHeaderDesc = 'collapseHeader_DESC',
  HideGlobalBannerAsc = 'hideGlobalBanner_ASC',
  HideGlobalBannerDesc = 'hideGlobalBanner_DESC',
  ShowHeaderAsc = 'showHeader_ASC',
  ShowHeaderDesc = 'showHeader_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC',
}

export enum SettingsAnalyticsModuleLinkingCollectionsTemplateNewsletterCollectionOrder {
  CollapseHeaderAsc = 'collapseHeader_ASC',
  CollapseHeaderDesc = 'collapseHeader_DESC',
  ShowHeaderAsc = 'showHeader_ASC',
  ShowHeaderDesc = 'showHeader_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC',
}

export enum SettingsAnalyticsModuleLinkingCollectionsTemplatePageCollectionOrder {
  DirectoryAsc = 'directory_ASC',
  DirectoryDesc = 'directory_DESC',
  InternalLabelAsc = 'internalLabel_ASC',
  InternalLabelDesc = 'internalLabel_DESC',
  MetaTitleAsc = 'metaTitle_ASC',
  MetaTitleDesc = 'metaTitle_DESC',
  NoindexNofollowAsc = 'noindexNofollow_ASC',
  NoindexNofollowDesc = 'noindexNofollow_DESC',
  SlugAsc = 'slug_ASC',
  SlugDesc = 'slug_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
}

export enum SettingsAnalyticsModuleLinkingCollectionsTemplatePartnerDetailCollectionOrder {
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
}

export enum SettingsAnalyticsModuleLinkingCollectionsTemplatePartnersCollectionOrder {
  CollapseHeaderAsc = 'collapseHeader_ASC',
  CollapseHeaderDesc = 'collapseHeader_DESC',
  ShowHeaderAsc = 'showHeader_ASC',
  ShowHeaderDesc = 'showHeader_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC',
}

export enum SettingsAnalyticsModuleLinkingCollectionsTemplatePressCollectionOrder {
  SubheadAsc = 'subhead_ASC',
  SubheadDesc = 'subhead_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC',
}

export enum SettingsAnalyticsModuleLinkingCollectionsTemplatePrivacyPolicyCollectionOrder {
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC',
}

export enum SettingsAnalyticsModuleLinkingCollectionsTemplateSearchCollectionOrder {
  AdminLabelAsc = 'adminLabel_ASC',
  AdminLabelDesc = 'adminLabel_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
}

export enum SettingsAnalyticsModuleLinkingCollectionsTemplateTermsAndConditionsCollectionOrder {
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC',
}

export enum SettingsAnalyticsModuleLinkingCollectionsTemplateTravelByCollectionOrder {
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC',
}

export enum SettingsAnalyticsModuleLinkingCollectionsTemplateTravelCollectionOrder {
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC',
  TravelByHeadingAsc = 'travelByHeading_ASC',
  TravelByHeadingDesc = 'travelByHeading_DESC',
}

export enum SettingsAnalyticsModuleLinkingCollectionsTemplateWhyBookWithForaCollectionOrder {
  CollapseHeaderAsc = 'collapseHeader_ASC',
  CollapseHeaderDesc = 'collapseHeader_DESC',
  ShowHeaderAsc = 'showHeader_ASC',
  ShowHeaderDesc = 'showHeader_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC',
}

export enum SettingsAnalyticsModuleOrder {
  EventContextAsc = 'eventContext_ASC',
  EventContextDesc = 'eventContext_DESC',
  InternalLabelAsc = 'internalLabel_ASC',
  InternalLabelDesc = 'internalLabel_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
}

/** A single text line component for arrays of bullet points etc. [See type definition](https://app.contentful.com/spaces/9cxgygtadmvl/content_types/singleLine) */
export type SingleLine = Entry &
  _Node & {
    __typename?: 'SingleLine'
    _id: Scalars['ID']
    contentfulMetadata: ContentfulMetadata
    linkedFrom?: Maybe<SingleLineLinkingCollections>
    sys: Sys
    textLine?: Maybe<Scalars['String']>
  }

/** A single text line component for arrays of bullet points etc. [See type definition](https://app.contentful.com/spaces/9cxgygtadmvl/content_types/singleLine) */
export type SingleLineLinkedFromArgs = {
  allowedLocales?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
}

/** A single text line component for arrays of bullet points etc. [See type definition](https://app.contentful.com/spaces/9cxgygtadmvl/content_types/singleLine) */
export type SingleLineTextLineArgs = {
  locale?: InputMaybe<Scalars['String']>
}

export type SingleLineCollection = {
  __typename?: 'SingleLineCollection'
  items: Array<Maybe<SingleLine>>
  limit: Scalars['Int']
  skip: Scalars['Int']
  total: Scalars['Int']
}

export type SingleLineFilter = {
  AND?: InputMaybe<Array<InputMaybe<SingleLineFilter>>>
  OR?: InputMaybe<Array<InputMaybe<SingleLineFilter>>>
  contentfulMetadata?: InputMaybe<ContentfulMetadataFilter>
  sys?: InputMaybe<SysFilter>
  textLine?: InputMaybe<Scalars['String']>
  textLine_contains?: InputMaybe<Scalars['String']>
  textLine_exists?: InputMaybe<Scalars['Boolean']>
  textLine_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  textLine_not?: InputMaybe<Scalars['String']>
  textLine_not_contains?: InputMaybe<Scalars['String']>
  textLine_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
}

export type SingleLineLinkingCollections = {
  __typename?: 'SingleLineLinkingCollections'
  entryCollection?: Maybe<EntryCollection>
}

export type SingleLineLinkingCollectionsEntryCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>
  locale?: InputMaybe<Scalars['String']>
  preview?: InputMaybe<Scalars['Boolean']>
  skip?: InputMaybe<Scalars['Int']>
}

export enum SingleLineOrder {
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  TextLineAsc = 'textLine_ASC',
  TextLineDesc = 'textLine_DESC',
}

export type Sys = {
  __typename?: 'Sys'
  environmentId: Scalars['String']
  firstPublishedAt?: Maybe<Scalars['DateTime']>
  id: Scalars['String']
  /** The locale that was requested. */
  locale?: Maybe<Scalars['String']>
  publishedAt?: Maybe<Scalars['DateTime']>
  publishedVersion?: Maybe<Scalars['Int']>
  spaceId: Scalars['String']
}

export type SysFilter = {
  firstPublishedAt?: InputMaybe<Scalars['DateTime']>
  firstPublishedAt_exists?: InputMaybe<Scalars['Boolean']>
  firstPublishedAt_gt?: InputMaybe<Scalars['DateTime']>
  firstPublishedAt_gte?: InputMaybe<Scalars['DateTime']>
  firstPublishedAt_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']>>>
  firstPublishedAt_lt?: InputMaybe<Scalars['DateTime']>
  firstPublishedAt_lte?: InputMaybe<Scalars['DateTime']>
  firstPublishedAt_not?: InputMaybe<Scalars['DateTime']>
  firstPublishedAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']>>>
  id?: InputMaybe<Scalars['String']>
  id_contains?: InputMaybe<Scalars['String']>
  id_exists?: InputMaybe<Scalars['Boolean']>
  id_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  id_not?: InputMaybe<Scalars['String']>
  id_not_contains?: InputMaybe<Scalars['String']>
  id_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  publishedAt?: InputMaybe<Scalars['DateTime']>
  publishedAt_exists?: InputMaybe<Scalars['Boolean']>
  publishedAt_gt?: InputMaybe<Scalars['DateTime']>
  publishedAt_gte?: InputMaybe<Scalars['DateTime']>
  publishedAt_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']>>>
  publishedAt_lt?: InputMaybe<Scalars['DateTime']>
  publishedAt_lte?: InputMaybe<Scalars['DateTime']>
  publishedAt_not?: InputMaybe<Scalars['DateTime']>
  publishedAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']>>>
  publishedVersion?: InputMaybe<Scalars['Float']>
  publishedVersion_exists?: InputMaybe<Scalars['Boolean']>
  publishedVersion_gt?: InputMaybe<Scalars['Float']>
  publishedVersion_gte?: InputMaybe<Scalars['Float']>
  publishedVersion_in?: InputMaybe<Array<InputMaybe<Scalars['Float']>>>
  publishedVersion_lt?: InputMaybe<Scalars['Float']>
  publishedVersion_lte?: InputMaybe<Scalars['Float']>
  publishedVersion_not?: InputMaybe<Scalars['Float']>
  publishedVersion_not_in?: InputMaybe<Array<InputMaybe<Scalars['Float']>>>
}

/** Create and manage all Teams content. [See type definition](https://app.contentful.com/spaces/9cxgygtadmvl/content_types/team) */
export type Team = Entry &
  _Node & {
    __typename?: 'Team'
    _id: Scalars['ID']
    contentfulMetadata: ContentfulMetadata
    email?: Maybe<Scalars['String']>
    image?: Maybe<Asset>
    linkedFrom?: Maybe<TeamLinkingCollections>
    name?: Maybe<Scalars['String']>
    sys: Sys
    title?: Maybe<Scalars['String']>
  }

/** Create and manage all Teams content. [See type definition](https://app.contentful.com/spaces/9cxgygtadmvl/content_types/team) */
export type TeamEmailArgs = {
  locale?: InputMaybe<Scalars['String']>
}

/** Create and manage all Teams content. [See type definition](https://app.contentful.com/spaces/9cxgygtadmvl/content_types/team) */
export type TeamImageArgs = {
  locale?: InputMaybe<Scalars['String']>
  preview?: InputMaybe<Scalars['Boolean']>
}

/** Create and manage all Teams content. [See type definition](https://app.contentful.com/spaces/9cxgygtadmvl/content_types/team) */
export type TeamLinkedFromArgs = {
  allowedLocales?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
}

/** Create and manage all Teams content. [See type definition](https://app.contentful.com/spaces/9cxgygtadmvl/content_types/team) */
export type TeamNameArgs = {
  locale?: InputMaybe<Scalars['String']>
}

/** Create and manage all Teams content. [See type definition](https://app.contentful.com/spaces/9cxgygtadmvl/content_types/team) */
export type TeamTitleArgs = {
  locale?: InputMaybe<Scalars['String']>
}

export type TeamCollection = {
  __typename?: 'TeamCollection'
  items: Array<Maybe<Team>>
  limit: Scalars['Int']
  skip: Scalars['Int']
  total: Scalars['Int']
}

export type TeamFilter = {
  AND?: InputMaybe<Array<InputMaybe<TeamFilter>>>
  OR?: InputMaybe<Array<InputMaybe<TeamFilter>>>
  contentfulMetadata?: InputMaybe<ContentfulMetadataFilter>
  email?: InputMaybe<Scalars['String']>
  email_contains?: InputMaybe<Scalars['String']>
  email_exists?: InputMaybe<Scalars['Boolean']>
  email_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  email_not?: InputMaybe<Scalars['String']>
  email_not_contains?: InputMaybe<Scalars['String']>
  email_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  image_exists?: InputMaybe<Scalars['Boolean']>
  name?: InputMaybe<Scalars['String']>
  name_contains?: InputMaybe<Scalars['String']>
  name_exists?: InputMaybe<Scalars['Boolean']>
  name_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  name_not?: InputMaybe<Scalars['String']>
  name_not_contains?: InputMaybe<Scalars['String']>
  name_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  sys?: InputMaybe<SysFilter>
  title?: InputMaybe<Scalars['String']>
  title_contains?: InputMaybe<Scalars['String']>
  title_exists?: InputMaybe<Scalars['Boolean']>
  title_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  title_not?: InputMaybe<Scalars['String']>
  title_not_contains?: InputMaybe<Scalars['String']>
  title_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
}

export type TeamLinkingCollections = {
  __typename?: 'TeamLinkingCollections'
  componentTeamSectionCollection?: Maybe<ComponentTeamSectionCollection>
  entryCollection?: Maybe<EntryCollection>
  eventsCollection?: Maybe<EventsCollection>
  templateAboutUsCollection?: Maybe<TemplateAboutUsCollection>
}

export type TeamLinkingCollectionsComponentTeamSectionCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>
  locale?: InputMaybe<Scalars['String']>
  order?: InputMaybe<
    Array<InputMaybe<TeamLinkingCollectionsComponentTeamSectionCollectionOrder>>
  >
  preview?: InputMaybe<Scalars['Boolean']>
  skip?: InputMaybe<Scalars['Int']>
}

export type TeamLinkingCollectionsEntryCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>
  locale?: InputMaybe<Scalars['String']>
  preview?: InputMaybe<Scalars['Boolean']>
  skip?: InputMaybe<Scalars['Int']>
}

export type TeamLinkingCollectionsEventsCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>
  locale?: InputMaybe<Scalars['String']>
  order?: InputMaybe<
    Array<InputMaybe<TeamLinkingCollectionsEventsCollectionOrder>>
  >
  preview?: InputMaybe<Scalars['Boolean']>
  skip?: InputMaybe<Scalars['Int']>
}

export type TeamLinkingCollectionsTemplateAboutUsCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>
  locale?: InputMaybe<Scalars['String']>
  order?: InputMaybe<
    Array<InputMaybe<TeamLinkingCollectionsTemplateAboutUsCollectionOrder>>
  >
  preview?: InputMaybe<Scalars['Boolean']>
  skip?: InputMaybe<Scalars['Int']>
}

export enum TeamLinkingCollectionsComponentTeamSectionCollectionOrder {
  InternalLabelAsc = 'internalLabel_ASC',
  InternalLabelDesc = 'internalLabel_DESC',
  SectionIdAsc = 'sectionId_ASC',
  SectionIdDesc = 'sectionId_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  TitleStyleAsc = 'titleStyle_ASC',
  TitleStyleDesc = 'titleStyle_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC',
}

export enum TeamLinkingCollectionsEventsCollectionOrder {
  AutoSelectLatestReplayAsc = 'autoSelectLatestReplay_ASC',
  AutoSelectLatestReplayDesc = 'autoSelectLatestReplay_DESC',
  CloseCaptioningAsc = 'closeCaptioning_ASC',
  CloseCaptioningDesc = 'closeCaptioning_DESC',
  DisableEventRecordingAsc = 'disableEventRecording_ASC',
  DisableEventRecordingDesc = 'disableEventRecording_DESC',
  EventDurationAsc = 'eventDuration_ASC',
  EventDurationDesc = 'eventDuration_DESC',
  EventTypeAsc = 'eventType_ASC',
  EventTypeDesc = 'eventType_DESC',
  HideEventAsc = 'hideEvent_ASC',
  HideEventDesc = 'hideEvent_DESC',
  IsAttendeeRegistrationModeEnabledAsc = 'isAttendeeRegistrationModeEnabled_ASC',
  IsAttendeeRegistrationModeEnabledDesc = 'isAttendeeRegistrationModeEnabled_DESC',
  IsRegistrationModeEnabledAsc = 'isRegistrationModeEnabled_ASC',
  IsRegistrationModeEnabledDesc = 'isRegistrationModeEnabled_DESC',
  NameAsc = 'name_ASC',
  NameDesc = 'name_DESC',
  SequelEventIdAsc = 'sequelEventId_ASC',
  SequelEventIdDesc = 'sequelEventId_DESC',
  SlugAsc = 'slug_ASC',
  SlugDesc = 'slug_DESC',
  StartDateAsc = 'startDate_ASC',
  StartDateDesc = 'startDate_DESC',
  StartTimeAsc = 'startTime_ASC',
  StartTimeDesc = 'startTime_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  TimeZoneAsc = 'timeZone_ASC',
  TimeZoneDesc = 'timeZone_DESC',
}

export enum TeamLinkingCollectionsTemplateAboutUsCollectionOrder {
  CollapseHeaderAsc = 'collapseHeader_ASC',
  CollapseHeaderDesc = 'collapseHeader_DESC',
  HeaderBlockTitleAsc = 'headerBlockTitle_ASC',
  HeaderBlockTitleDesc = 'headerBlockTitle_DESC',
  ShowHeaderAsc = 'showHeader_ASC',
  ShowHeaderDesc = 'showHeader_DESC',
  SubtitleAsc = 'subtitle_ASC',
  SubtitleDesc = 'subtitle_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC',
}

export enum TeamOrder {
  EmailAsc = 'email_ASC',
  EmailDesc = 'email_DESC',
  NameAsc = 'name_ASC',
  NameDesc = 'name_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC',
}

/** Create and manage all About Us page content. [See type definition](https://app.contentful.com/spaces/9cxgygtadmvl/content_types/templateAboutUs) */
export type TemplateAboutUs = Entry &
  _Node & {
    __typename?: 'TemplateAboutUs'
    _id: Scalars['ID']
    analyticsModule?: Maybe<SettingsAnalyticsModule>
    collapseHeader?: Maybe<Scalars['Boolean']>
    contentfulMetadata: ContentfulMetadata
    cta?: Maybe<PagesTwoColumnCta>
    headerBlockImage?: Maybe<Asset>
    headerBlockTitle?: Maybe<Scalars['String']>
    linkedFrom?: Maybe<TemplateAboutUsLinkingCollections>
    metaContent?: Maybe<PagesMetaContent>
    pageBlocksCollection?: Maybe<TemplateAboutUsPageBlocksCollection>
    pageComponentsCollection?: Maybe<TemplateAboutUsPageComponentsCollection>
    showHeader?: Maybe<Scalars['Boolean']>
    subtitle?: Maybe<Scalars['String']>
    sys: Sys
    teamCollection?: Maybe<TemplateAboutUsTeamCollection>
    textBlockLeft?: Maybe<TemplateAboutUsTextBlockLeft>
    textBlockRight?: Maybe<TemplateAboutUsTextBlockRight>
    title?: Maybe<Scalars['String']>
  }

/** Create and manage all About Us page content. [See type definition](https://app.contentful.com/spaces/9cxgygtadmvl/content_types/templateAboutUs) */
export type TemplateAboutUsAnalyticsModuleArgs = {
  locale?: InputMaybe<Scalars['String']>
  preview?: InputMaybe<Scalars['Boolean']>
  where?: InputMaybe<SettingsAnalyticsModuleFilter>
}

/** Create and manage all About Us page content. [See type definition](https://app.contentful.com/spaces/9cxgygtadmvl/content_types/templateAboutUs) */
export type TemplateAboutUsCollapseHeaderArgs = {
  locale?: InputMaybe<Scalars['String']>
}

/** Create and manage all About Us page content. [See type definition](https://app.contentful.com/spaces/9cxgygtadmvl/content_types/templateAboutUs) */
export type TemplateAboutUsCtaArgs = {
  locale?: InputMaybe<Scalars['String']>
  preview?: InputMaybe<Scalars['Boolean']>
  where?: InputMaybe<PagesTwoColumnCtaFilter>
}

/** Create and manage all About Us page content. [See type definition](https://app.contentful.com/spaces/9cxgygtadmvl/content_types/templateAboutUs) */
export type TemplateAboutUsHeaderBlockImageArgs = {
  locale?: InputMaybe<Scalars['String']>
  preview?: InputMaybe<Scalars['Boolean']>
}

/** Create and manage all About Us page content. [See type definition](https://app.contentful.com/spaces/9cxgygtadmvl/content_types/templateAboutUs) */
export type TemplateAboutUsHeaderBlockTitleArgs = {
  locale?: InputMaybe<Scalars['String']>
}

/** Create and manage all About Us page content. [See type definition](https://app.contentful.com/spaces/9cxgygtadmvl/content_types/templateAboutUs) */
export type TemplateAboutUsLinkedFromArgs = {
  allowedLocales?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
}

/** Create and manage all About Us page content. [See type definition](https://app.contentful.com/spaces/9cxgygtadmvl/content_types/templateAboutUs) */
export type TemplateAboutUsMetaContentArgs = {
  locale?: InputMaybe<Scalars['String']>
  preview?: InputMaybe<Scalars['Boolean']>
  where?: InputMaybe<PagesMetaContentFilter>
}

/** Create and manage all About Us page content. [See type definition](https://app.contentful.com/spaces/9cxgygtadmvl/content_types/templateAboutUs) */
export type TemplateAboutUsPageBlocksCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>
  locale?: InputMaybe<Scalars['String']>
  preview?: InputMaybe<Scalars['Boolean']>
  skip?: InputMaybe<Scalars['Int']>
  where?: InputMaybe<TemplateAboutUsPageBlocksFilter>
}

/** Create and manage all About Us page content. [See type definition](https://app.contentful.com/spaces/9cxgygtadmvl/content_types/templateAboutUs) */
export type TemplateAboutUsPageComponentsCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>
  locale?: InputMaybe<Scalars['String']>
  preview?: InputMaybe<Scalars['Boolean']>
  skip?: InputMaybe<Scalars['Int']>
}

/** Create and manage all About Us page content. [See type definition](https://app.contentful.com/spaces/9cxgygtadmvl/content_types/templateAboutUs) */
export type TemplateAboutUsShowHeaderArgs = {
  locale?: InputMaybe<Scalars['String']>
}

/** Create and manage all About Us page content. [See type definition](https://app.contentful.com/spaces/9cxgygtadmvl/content_types/templateAboutUs) */
export type TemplateAboutUsSubtitleArgs = {
  locale?: InputMaybe<Scalars['String']>
}

/** Create and manage all About Us page content. [See type definition](https://app.contentful.com/spaces/9cxgygtadmvl/content_types/templateAboutUs) */
export type TemplateAboutUsTeamCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>
  locale?: InputMaybe<Scalars['String']>
  order?: InputMaybe<Array<InputMaybe<TemplateAboutUsTeamCollectionOrder>>>
  preview?: InputMaybe<Scalars['Boolean']>
  skip?: InputMaybe<Scalars['Int']>
  where?: InputMaybe<TeamFilter>
}

/** Create and manage all About Us page content. [See type definition](https://app.contentful.com/spaces/9cxgygtadmvl/content_types/templateAboutUs) */
export type TemplateAboutUsTextBlockLeftArgs = {
  locale?: InputMaybe<Scalars['String']>
}

/** Create and manage all About Us page content. [See type definition](https://app.contentful.com/spaces/9cxgygtadmvl/content_types/templateAboutUs) */
export type TemplateAboutUsTextBlockRightArgs = {
  locale?: InputMaybe<Scalars['String']>
}

/** Create and manage all About Us page content. [See type definition](https://app.contentful.com/spaces/9cxgygtadmvl/content_types/templateAboutUs) */
export type TemplateAboutUsTitleArgs = {
  locale?: InputMaybe<Scalars['String']>
}

export type TemplateAboutUsCollection = {
  __typename?: 'TemplateAboutUsCollection'
  items: Array<Maybe<TemplateAboutUs>>
  limit: Scalars['Int']
  skip: Scalars['Int']
  total: Scalars['Int']
}

export type TemplateAboutUsFilter = {
  AND?: InputMaybe<Array<InputMaybe<TemplateAboutUsFilter>>>
  OR?: InputMaybe<Array<InputMaybe<TemplateAboutUsFilter>>>
  analyticsModule?: InputMaybe<CfSettingsAnalyticsModuleNestedFilter>
  analyticsModule_exists?: InputMaybe<Scalars['Boolean']>
  collapseHeader?: InputMaybe<Scalars['Boolean']>
  collapseHeader_exists?: InputMaybe<Scalars['Boolean']>
  collapseHeader_not?: InputMaybe<Scalars['Boolean']>
  contentfulMetadata?: InputMaybe<ContentfulMetadataFilter>
  cta?: InputMaybe<CfPagesTwoColumnCtaNestedFilter>
  cta_exists?: InputMaybe<Scalars['Boolean']>
  headerBlockImage_exists?: InputMaybe<Scalars['Boolean']>
  headerBlockTitle?: InputMaybe<Scalars['String']>
  headerBlockTitle_contains?: InputMaybe<Scalars['String']>
  headerBlockTitle_exists?: InputMaybe<Scalars['Boolean']>
  headerBlockTitle_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  headerBlockTitle_not?: InputMaybe<Scalars['String']>
  headerBlockTitle_not_contains?: InputMaybe<Scalars['String']>
  headerBlockTitle_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  metaContent?: InputMaybe<CfPagesMetaContentNestedFilter>
  metaContent_exists?: InputMaybe<Scalars['Boolean']>
  pageBlocks?: InputMaybe<CfpageBlocksMultiTypeNestedFilter>
  pageBlocksCollection_exists?: InputMaybe<Scalars['Boolean']>
  pageComponentsCollection_exists?: InputMaybe<Scalars['Boolean']>
  showHeader?: InputMaybe<Scalars['Boolean']>
  showHeader_exists?: InputMaybe<Scalars['Boolean']>
  showHeader_not?: InputMaybe<Scalars['Boolean']>
  subtitle?: InputMaybe<Scalars['String']>
  subtitle_contains?: InputMaybe<Scalars['String']>
  subtitle_exists?: InputMaybe<Scalars['Boolean']>
  subtitle_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  subtitle_not?: InputMaybe<Scalars['String']>
  subtitle_not_contains?: InputMaybe<Scalars['String']>
  subtitle_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  sys?: InputMaybe<SysFilter>
  team?: InputMaybe<CfTeamNestedFilter>
  teamCollection_exists?: InputMaybe<Scalars['Boolean']>
  textBlockLeft_contains?: InputMaybe<Scalars['String']>
  textBlockLeft_exists?: InputMaybe<Scalars['Boolean']>
  textBlockLeft_not_contains?: InputMaybe<Scalars['String']>
  textBlockRight_contains?: InputMaybe<Scalars['String']>
  textBlockRight_exists?: InputMaybe<Scalars['Boolean']>
  textBlockRight_not_contains?: InputMaybe<Scalars['String']>
  title?: InputMaybe<Scalars['String']>
  title_contains?: InputMaybe<Scalars['String']>
  title_exists?: InputMaybe<Scalars['Boolean']>
  title_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  title_not?: InputMaybe<Scalars['String']>
  title_not_contains?: InputMaybe<Scalars['String']>
  title_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
}

export type TemplateAboutUsLinkingCollections = {
  __typename?: 'TemplateAboutUsLinkingCollections'
  entryCollection?: Maybe<EntryCollection>
}

export type TemplateAboutUsLinkingCollectionsEntryCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>
  locale?: InputMaybe<Scalars['String']>
  preview?: InputMaybe<Scalars['Boolean']>
  skip?: InputMaybe<Scalars['Int']>
}

export enum TemplateAboutUsOrder {
  CollapseHeaderAsc = 'collapseHeader_ASC',
  CollapseHeaderDesc = 'collapseHeader_DESC',
  HeaderBlockTitleAsc = 'headerBlockTitle_ASC',
  HeaderBlockTitleDesc = 'headerBlockTitle_DESC',
  ShowHeaderAsc = 'showHeader_ASC',
  ShowHeaderDesc = 'showHeader_DESC',
  SubtitleAsc = 'subtitle_ASC',
  SubtitleDesc = 'subtitle_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC',
}

export type TemplateAboutUsPageBlocksCollection = {
  __typename?: 'TemplateAboutUsPageBlocksCollection'
  items: Array<Maybe<TemplateAboutUsPageBlocksItem>>
  limit: Scalars['Int']
  skip: Scalars['Int']
  total: Scalars['Int']
}

export type TemplateAboutUsPageBlocksFilter = {
  AND?: InputMaybe<Array<InputMaybe<TemplateAboutUsPageBlocksFilter>>>
  OR?: InputMaybe<Array<InputMaybe<TemplateAboutUsPageBlocksFilter>>>
  contentfulMetadata?: InputMaybe<ContentfulMetadataFilter>
  sys?: InputMaybe<SysFilter>
}

export type TemplateAboutUsPageBlocksItem =
  | ComponentAnimatedTiles
  | ComponentCarouselSection
  | ComponentCheckeredCallout
  | ComponentComparisonTable
  | ComponentCtaBanner
  | ComponentFeaturedAdvisors
  | ComponentFeaturedArticles
  | ComponentFeaturedGuides
  | ComponentForm
  | ComponentHeroCentered
  | ComponentHeroFullWidth
  | ComponentImageSideBySide
  | ComponentPartnerGrid
  | ComponentPictureSectionBanner
  | ComponentSpacer
  | ComponentSubNavBar
  | ComponentSubscriptionCard
  | ComponentTeamSection
  | ComponentTestimonialCarousel
  | ComponentTextSection
  | ComponentThreeIconSection
  | ComponentTwoColumnCallout
  | ComponentTwoColumnPerksSection
  | ComponentTwoColumnTextSection
  | ComponentVideoSection
  | FaqsQuestionGroups
  | PagesFeaturedHotels
  | PagesHeaderBlock
  | PagesHeroBlock
  | PagesPartnerBanner
  | PagesPerksList
  | PagesPressBanner
  | PagesTwoColumnCta

export type TemplateAboutUsPageComponentsCollection = {
  __typename?: 'TemplateAboutUsPageComponentsCollection'
  items: Array<Maybe<TemplateAboutUsPageComponentsItem>>
  limit: Scalars['Int']
  skip: Scalars['Int']
  total: Scalars['Int']
}

export type TemplateAboutUsPageComponentsItem =
  | ComponentAdvisorPlanTable
  | ComponentAdvisorReviews
  | ComponentAnimatedTiles
  | ComponentButtonCta
  | ComponentCarouselSection
  | ComponentComparisonTable
  | ComponentCtaBanner
  | ComponentDataBanner
  | ComponentFeaturedAdvisors
  | ComponentFeaturedArticles
  | ComponentFeaturedGuides
  | ComponentFeaturedReviews
  | ComponentForaLogo
  | ComponentForm
  | ComponentHeroCentered
  | ComponentHeroFullWidth
  | ComponentImageSideBySide
  | ComponentInfoGrid
  | ComponentPartnerGrid
  | ComponentPictureSectionBanner
  | ComponentReviews
  | ComponentSingleUse
  | ComponentSpacer
  | ComponentStickyHeader
  | ComponentSubNavBar
  | ComponentSubscriptionCard
  | ComponentTeamSection
  | ComponentTestimonialCarousel
  | ComponentTextSection
  | ComponentThreeIconSectionWithPicture
  | ComponentTwoColumnInfographic
  | ComponentTwoColumnTextSection
  | ComponentTypeformEmbed
  | ComponentVideoSection
  | ComponentWhatYouGetModule
  | FaqsQuestionGroups
  | PagesFeaturedHotels
  | PagesHeaderBlock
  | PagesHeroBlock
  | PagesLearnMoreBlock
  | PagesPartnerBanner
  | PagesPerksList
  | PagesPressBanner
  | PagesTwoColumnCta

export type TemplateAboutUsTeamCollection = {
  __typename?: 'TemplateAboutUsTeamCollection'
  items: Array<Maybe<Team>>
  limit: Scalars['Int']
  skip: Scalars['Int']
  total: Scalars['Int']
}

export enum TemplateAboutUsTeamCollectionOrder {
  EmailAsc = 'email_ASC',
  EmailDesc = 'email_DESC',
  NameAsc = 'name_ASC',
  NameDesc = 'name_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC',
}

export type TemplateAboutUsTextBlockLeft = {
  __typename?: 'TemplateAboutUsTextBlockLeft'
  json: Scalars['JSON']
  links: TemplateAboutUsTextBlockLeftLinks
}

export type TemplateAboutUsTextBlockLeftAssets = {
  __typename?: 'TemplateAboutUsTextBlockLeftAssets'
  block: Array<Maybe<Asset>>
  hyperlink: Array<Maybe<Asset>>
}

export type TemplateAboutUsTextBlockLeftEntries = {
  __typename?: 'TemplateAboutUsTextBlockLeftEntries'
  block: Array<Maybe<Entry>>
  hyperlink: Array<Maybe<Entry>>
  inline: Array<Maybe<Entry>>
}

export type TemplateAboutUsTextBlockLeftLinks = {
  __typename?: 'TemplateAboutUsTextBlockLeftLinks'
  assets: TemplateAboutUsTextBlockLeftAssets
  entries: TemplateAboutUsTextBlockLeftEntries
  resources: TemplateAboutUsTextBlockLeftResources
}

export type TemplateAboutUsTextBlockLeftResources = {
  __typename?: 'TemplateAboutUsTextBlockLeftResources'
  block: Array<TemplateAboutUsTextBlockLeftResourcesBlock>
  hyperlink: Array<TemplateAboutUsTextBlockLeftResourcesHyperlink>
  inline: Array<TemplateAboutUsTextBlockLeftResourcesInline>
}

export type TemplateAboutUsTextBlockLeftResourcesBlock = ResourceLink & {
  __typename?: 'TemplateAboutUsTextBlockLeftResourcesBlock'
  sys: ResourceSys
}

export type TemplateAboutUsTextBlockLeftResourcesHyperlink = ResourceLink & {
  __typename?: 'TemplateAboutUsTextBlockLeftResourcesHyperlink'
  sys: ResourceSys
}

export type TemplateAboutUsTextBlockLeftResourcesInline = ResourceLink & {
  __typename?: 'TemplateAboutUsTextBlockLeftResourcesInline'
  sys: ResourceSys
}

export type TemplateAboutUsTextBlockRight = {
  __typename?: 'TemplateAboutUsTextBlockRight'
  json: Scalars['JSON']
  links: TemplateAboutUsTextBlockRightLinks
}

export type TemplateAboutUsTextBlockRightAssets = {
  __typename?: 'TemplateAboutUsTextBlockRightAssets'
  block: Array<Maybe<Asset>>
  hyperlink: Array<Maybe<Asset>>
}

export type TemplateAboutUsTextBlockRightEntries = {
  __typename?: 'TemplateAboutUsTextBlockRightEntries'
  block: Array<Maybe<Entry>>
  hyperlink: Array<Maybe<Entry>>
  inline: Array<Maybe<Entry>>
}

export type TemplateAboutUsTextBlockRightLinks = {
  __typename?: 'TemplateAboutUsTextBlockRightLinks'
  assets: TemplateAboutUsTextBlockRightAssets
  entries: TemplateAboutUsTextBlockRightEntries
  resources: TemplateAboutUsTextBlockRightResources
}

export type TemplateAboutUsTextBlockRightResources = {
  __typename?: 'TemplateAboutUsTextBlockRightResources'
  block: Array<TemplateAboutUsTextBlockRightResourcesBlock>
  hyperlink: Array<TemplateAboutUsTextBlockRightResourcesHyperlink>
  inline: Array<TemplateAboutUsTextBlockRightResourcesInline>
}

export type TemplateAboutUsTextBlockRightResourcesBlock = ResourceLink & {
  __typename?: 'TemplateAboutUsTextBlockRightResourcesBlock'
  sys: ResourceSys
}

export type TemplateAboutUsTextBlockRightResourcesHyperlink = ResourceLink & {
  __typename?: 'TemplateAboutUsTextBlockRightResourcesHyperlink'
  sys: ResourceSys
}

export type TemplateAboutUsTextBlockRightResourcesInline = ResourceLink & {
  __typename?: 'TemplateAboutUsTextBlockRightResourcesInline'
  sys: ResourceSys
}

/** Create and manage all Advisors landing page content. Advisor cards on this page are displayed in random order unique to the user for a day at a time. [See type definition](https://app.contentful.com/spaces/9cxgygtadmvl/content_types/templateAdvisors) */
export type TemplateAdvisors = Entry &
  _Node & {
    __typename?: 'TemplateAdvisors'
    _id: Scalars['ID']
    analyticsModule?: Maybe<SettingsAnalyticsModule>
    contentfulMetadata: ContentfulMetadata
    learnMoreBlock?: Maybe<PagesLearnMoreBlock>
    linkedFrom?: Maybe<TemplateAdvisorsLinkingCollections>
    metaContent?: Maybe<PagesMetaContent>
    questionGroup?: Maybe<FaqsQuestionGroups>
    sys: Sys
    teaserCopy?: Maybe<TemplateAdvisorsTeaserCopy>
    title?: Maybe<Scalars['String']>
  }

/** Create and manage all Advisors landing page content. Advisor cards on this page are displayed in random order unique to the user for a day at a time. [See type definition](https://app.contentful.com/spaces/9cxgygtadmvl/content_types/templateAdvisors) */
export type TemplateAdvisorsAnalyticsModuleArgs = {
  locale?: InputMaybe<Scalars['String']>
  preview?: InputMaybe<Scalars['Boolean']>
  where?: InputMaybe<SettingsAnalyticsModuleFilter>
}

/** Create and manage all Advisors landing page content. Advisor cards on this page are displayed in random order unique to the user for a day at a time. [See type definition](https://app.contentful.com/spaces/9cxgygtadmvl/content_types/templateAdvisors) */
export type TemplateAdvisorsLearnMoreBlockArgs = {
  locale?: InputMaybe<Scalars['String']>
  preview?: InputMaybe<Scalars['Boolean']>
  where?: InputMaybe<PagesLearnMoreBlockFilter>
}

/** Create and manage all Advisors landing page content. Advisor cards on this page are displayed in random order unique to the user for a day at a time. [See type definition](https://app.contentful.com/spaces/9cxgygtadmvl/content_types/templateAdvisors) */
export type TemplateAdvisorsLinkedFromArgs = {
  allowedLocales?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
}

/** Create and manage all Advisors landing page content. Advisor cards on this page are displayed in random order unique to the user for a day at a time. [See type definition](https://app.contentful.com/spaces/9cxgygtadmvl/content_types/templateAdvisors) */
export type TemplateAdvisorsMetaContentArgs = {
  locale?: InputMaybe<Scalars['String']>
  preview?: InputMaybe<Scalars['Boolean']>
  where?: InputMaybe<PagesMetaContentFilter>
}

/** Create and manage all Advisors landing page content. Advisor cards on this page are displayed in random order unique to the user for a day at a time. [See type definition](https://app.contentful.com/spaces/9cxgygtadmvl/content_types/templateAdvisors) */
export type TemplateAdvisorsQuestionGroupArgs = {
  locale?: InputMaybe<Scalars['String']>
  preview?: InputMaybe<Scalars['Boolean']>
  where?: InputMaybe<FaqsQuestionGroupsFilter>
}

/** Create and manage all Advisors landing page content. Advisor cards on this page are displayed in random order unique to the user for a day at a time. [See type definition](https://app.contentful.com/spaces/9cxgygtadmvl/content_types/templateAdvisors) */
export type TemplateAdvisorsTeaserCopyArgs = {
  locale?: InputMaybe<Scalars['String']>
}

/** Create and manage all Advisors landing page content. Advisor cards on this page are displayed in random order unique to the user for a day at a time. [See type definition](https://app.contentful.com/spaces/9cxgygtadmvl/content_types/templateAdvisors) */
export type TemplateAdvisorsTitleArgs = {
  locale?: InputMaybe<Scalars['String']>
}

export type TemplateAdvisorsCollection = {
  __typename?: 'TemplateAdvisorsCollection'
  items: Array<Maybe<TemplateAdvisors>>
  limit: Scalars['Int']
  skip: Scalars['Int']
  total: Scalars['Int']
}

export type TemplateAdvisorsFilter = {
  AND?: InputMaybe<Array<InputMaybe<TemplateAdvisorsFilter>>>
  OR?: InputMaybe<Array<InputMaybe<TemplateAdvisorsFilter>>>
  analyticsModule?: InputMaybe<CfSettingsAnalyticsModuleNestedFilter>
  analyticsModule_exists?: InputMaybe<Scalars['Boolean']>
  contentfulMetadata?: InputMaybe<ContentfulMetadataFilter>
  learnMoreBlock?: InputMaybe<CfPagesLearnMoreBlockNestedFilter>
  learnMoreBlock_exists?: InputMaybe<Scalars['Boolean']>
  metaContent?: InputMaybe<CfPagesMetaContentNestedFilter>
  metaContent_exists?: InputMaybe<Scalars['Boolean']>
  questionGroup?: InputMaybe<CfFaqsQuestionGroupsNestedFilter>
  questionGroup_exists?: InputMaybe<Scalars['Boolean']>
  sys?: InputMaybe<SysFilter>
  teaserCopy_contains?: InputMaybe<Scalars['String']>
  teaserCopy_exists?: InputMaybe<Scalars['Boolean']>
  teaserCopy_not_contains?: InputMaybe<Scalars['String']>
  title?: InputMaybe<Scalars['String']>
  title_contains?: InputMaybe<Scalars['String']>
  title_exists?: InputMaybe<Scalars['Boolean']>
  title_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  title_not?: InputMaybe<Scalars['String']>
  title_not_contains?: InputMaybe<Scalars['String']>
  title_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
}

export type TemplateAdvisorsLinkingCollections = {
  __typename?: 'TemplateAdvisorsLinkingCollections'
  entryCollection?: Maybe<EntryCollection>
}

export type TemplateAdvisorsLinkingCollectionsEntryCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>
  locale?: InputMaybe<Scalars['String']>
  preview?: InputMaybe<Scalars['Boolean']>
  skip?: InputMaybe<Scalars['Int']>
}

export enum TemplateAdvisorsOrder {
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC',
}

export type TemplateAdvisorsTeaserCopy = {
  __typename?: 'TemplateAdvisorsTeaserCopy'
  json: Scalars['JSON']
  links: TemplateAdvisorsTeaserCopyLinks
}

export type TemplateAdvisorsTeaserCopyAssets = {
  __typename?: 'TemplateAdvisorsTeaserCopyAssets'
  block: Array<Maybe<Asset>>
  hyperlink: Array<Maybe<Asset>>
}

export type TemplateAdvisorsTeaserCopyEntries = {
  __typename?: 'TemplateAdvisorsTeaserCopyEntries'
  block: Array<Maybe<Entry>>
  hyperlink: Array<Maybe<Entry>>
  inline: Array<Maybe<Entry>>
}

export type TemplateAdvisorsTeaserCopyLinks = {
  __typename?: 'TemplateAdvisorsTeaserCopyLinks'
  assets: TemplateAdvisorsTeaserCopyAssets
  entries: TemplateAdvisorsTeaserCopyEntries
  resources: TemplateAdvisorsTeaserCopyResources
}

export type TemplateAdvisorsTeaserCopyResources = {
  __typename?: 'TemplateAdvisorsTeaserCopyResources'
  block: Array<TemplateAdvisorsTeaserCopyResourcesBlock>
  hyperlink: Array<TemplateAdvisorsTeaserCopyResourcesHyperlink>
  inline: Array<TemplateAdvisorsTeaserCopyResourcesInline>
}

export type TemplateAdvisorsTeaserCopyResourcesBlock = ResourceLink & {
  __typename?: 'TemplateAdvisorsTeaserCopyResourcesBlock'
  sys: ResourceSys
}

export type TemplateAdvisorsTeaserCopyResourcesHyperlink = ResourceLink & {
  __typename?: 'TemplateAdvisorsTeaserCopyResourcesHyperlink'
  sys: ResourceSys
}

export type TemplateAdvisorsTeaserCopyResourcesInline = ResourceLink & {
  __typename?: 'TemplateAdvisorsTeaserCopyResourcesInline'
  sys: ResourceSys
}

/** Page content for the corporate blog page. [See type definition](https://app.contentful.com/spaces/9cxgygtadmvl/content_types/templateBlog) */
export type TemplateBlog = Entry &
  _Node & {
    __typename?: 'TemplateBlog'
    _id: Scalars['ID']
    analyticsModule?: Maybe<SettingsAnalyticsModule>
    contentfulMetadata: ContentfulMetadata
    linkedFrom?: Maybe<TemplateBlogLinkingCollections>
    metaContent?: Maybe<PagesMetaContent>
    sys: Sys
    teaserCopy?: Maybe<TemplateBlogTeaserCopy>
    title?: Maybe<Scalars['String']>
  }

/** Page content for the corporate blog page. [See type definition](https://app.contentful.com/spaces/9cxgygtadmvl/content_types/templateBlog) */
export type TemplateBlogAnalyticsModuleArgs = {
  locale?: InputMaybe<Scalars['String']>
  preview?: InputMaybe<Scalars['Boolean']>
  where?: InputMaybe<SettingsAnalyticsModuleFilter>
}

/** Page content for the corporate blog page. [See type definition](https://app.contentful.com/spaces/9cxgygtadmvl/content_types/templateBlog) */
export type TemplateBlogLinkedFromArgs = {
  allowedLocales?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
}

/** Page content for the corporate blog page. [See type definition](https://app.contentful.com/spaces/9cxgygtadmvl/content_types/templateBlog) */
export type TemplateBlogMetaContentArgs = {
  locale?: InputMaybe<Scalars['String']>
  preview?: InputMaybe<Scalars['Boolean']>
  where?: InputMaybe<PagesMetaContentFilter>
}

/** Page content for the corporate blog page. [See type definition](https://app.contentful.com/spaces/9cxgygtadmvl/content_types/templateBlog) */
export type TemplateBlogTeaserCopyArgs = {
  locale?: InputMaybe<Scalars['String']>
}

/** Page content for the corporate blog page. [See type definition](https://app.contentful.com/spaces/9cxgygtadmvl/content_types/templateBlog) */
export type TemplateBlogTitleArgs = {
  locale?: InputMaybe<Scalars['String']>
}

export type TemplateBlogCollection = {
  __typename?: 'TemplateBlogCollection'
  items: Array<Maybe<TemplateBlog>>
  limit: Scalars['Int']
  skip: Scalars['Int']
  total: Scalars['Int']
}

export type TemplateBlogFilter = {
  AND?: InputMaybe<Array<InputMaybe<TemplateBlogFilter>>>
  OR?: InputMaybe<Array<InputMaybe<TemplateBlogFilter>>>
  analyticsModule?: InputMaybe<CfSettingsAnalyticsModuleNestedFilter>
  analyticsModule_exists?: InputMaybe<Scalars['Boolean']>
  contentfulMetadata?: InputMaybe<ContentfulMetadataFilter>
  metaContent?: InputMaybe<CfPagesMetaContentNestedFilter>
  metaContent_exists?: InputMaybe<Scalars['Boolean']>
  sys?: InputMaybe<SysFilter>
  teaserCopy_contains?: InputMaybe<Scalars['String']>
  teaserCopy_exists?: InputMaybe<Scalars['Boolean']>
  teaserCopy_not_contains?: InputMaybe<Scalars['String']>
  title?: InputMaybe<Scalars['String']>
  title_contains?: InputMaybe<Scalars['String']>
  title_exists?: InputMaybe<Scalars['Boolean']>
  title_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  title_not?: InputMaybe<Scalars['String']>
  title_not_contains?: InputMaybe<Scalars['String']>
  title_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
}

export type TemplateBlogLinkingCollections = {
  __typename?: 'TemplateBlogLinkingCollections'
  entryCollection?: Maybe<EntryCollection>
}

export type TemplateBlogLinkingCollectionsEntryCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>
  locale?: InputMaybe<Scalars['String']>
  preview?: InputMaybe<Scalars['Boolean']>
  skip?: InputMaybe<Scalars['Int']>
}

export enum TemplateBlogOrder {
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC',
}

export type TemplateBlogTeaserCopy = {
  __typename?: 'TemplateBlogTeaserCopy'
  json: Scalars['JSON']
  links: TemplateBlogTeaserCopyLinks
}

export type TemplateBlogTeaserCopyAssets = {
  __typename?: 'TemplateBlogTeaserCopyAssets'
  block: Array<Maybe<Asset>>
  hyperlink: Array<Maybe<Asset>>
}

export type TemplateBlogTeaserCopyEntries = {
  __typename?: 'TemplateBlogTeaserCopyEntries'
  block: Array<Maybe<Entry>>
  hyperlink: Array<Maybe<Entry>>
  inline: Array<Maybe<Entry>>
}

export type TemplateBlogTeaserCopyLinks = {
  __typename?: 'TemplateBlogTeaserCopyLinks'
  assets: TemplateBlogTeaserCopyAssets
  entries: TemplateBlogTeaserCopyEntries
  resources: TemplateBlogTeaserCopyResources
}

export type TemplateBlogTeaserCopyResources = {
  __typename?: 'TemplateBlogTeaserCopyResources'
  block: Array<TemplateBlogTeaserCopyResourcesBlock>
  hyperlink: Array<TemplateBlogTeaserCopyResourcesHyperlink>
  inline: Array<TemplateBlogTeaserCopyResourcesInline>
}

export type TemplateBlogTeaserCopyResourcesBlock = ResourceLink & {
  __typename?: 'TemplateBlogTeaserCopyResourcesBlock'
  sys: ResourceSys
}

export type TemplateBlogTeaserCopyResourcesHyperlink = ResourceLink & {
  __typename?: 'TemplateBlogTeaserCopyResourcesHyperlink'
  sys: ResourceSys
}

export type TemplateBlogTeaserCopyResourcesInline = ResourceLink & {
  __typename?: 'TemplateBlogTeaserCopyResourcesInline'
  sys: ResourceSys
}

/** Create and manage all Booking page content. [See type definition](https://app.contentful.com/spaces/9cxgygtadmvl/content_types/templateBooking) */
export type TemplateBooking = Entry &
  _Node & {
    __typename?: 'TemplateBooking'
    _id: Scalars['ID']
    collapseHeader?: Maybe<Scalars['Boolean']>
    contentfulMetadata: ContentfulMetadata
    linkedFrom?: Maybe<TemplateBookingLinkingCollections>
    metaContent?: Maybe<PagesMetaContent>
    pageComponentsCollection?: Maybe<TemplateBookingPageComponentsCollection>
    showHeader?: Maybe<Scalars['Boolean']>
    sys: Sys
    title?: Maybe<Scalars['String']>
  }

/** Create and manage all Booking page content. [See type definition](https://app.contentful.com/spaces/9cxgygtadmvl/content_types/templateBooking) */
export type TemplateBookingCollapseHeaderArgs = {
  locale?: InputMaybe<Scalars['String']>
}

/** Create and manage all Booking page content. [See type definition](https://app.contentful.com/spaces/9cxgygtadmvl/content_types/templateBooking) */
export type TemplateBookingLinkedFromArgs = {
  allowedLocales?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
}

/** Create and manage all Booking page content. [See type definition](https://app.contentful.com/spaces/9cxgygtadmvl/content_types/templateBooking) */
export type TemplateBookingMetaContentArgs = {
  locale?: InputMaybe<Scalars['String']>
  preview?: InputMaybe<Scalars['Boolean']>
  where?: InputMaybe<PagesMetaContentFilter>
}

/** Create and manage all Booking page content. [See type definition](https://app.contentful.com/spaces/9cxgygtadmvl/content_types/templateBooking) */
export type TemplateBookingPageComponentsCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>
  locale?: InputMaybe<Scalars['String']>
  preview?: InputMaybe<Scalars['Boolean']>
  skip?: InputMaybe<Scalars['Int']>
}

/** Create and manage all Booking page content. [See type definition](https://app.contentful.com/spaces/9cxgygtadmvl/content_types/templateBooking) */
export type TemplateBookingShowHeaderArgs = {
  locale?: InputMaybe<Scalars['String']>
}

/** Create and manage all Booking page content. [See type definition](https://app.contentful.com/spaces/9cxgygtadmvl/content_types/templateBooking) */
export type TemplateBookingTitleArgs = {
  locale?: InputMaybe<Scalars['String']>
}

export type TemplateBookingCollection = {
  __typename?: 'TemplateBookingCollection'
  items: Array<Maybe<TemplateBooking>>
  limit: Scalars['Int']
  skip: Scalars['Int']
  total: Scalars['Int']
}

export type TemplateBookingFilter = {
  AND?: InputMaybe<Array<InputMaybe<TemplateBookingFilter>>>
  OR?: InputMaybe<Array<InputMaybe<TemplateBookingFilter>>>
  collapseHeader?: InputMaybe<Scalars['Boolean']>
  collapseHeader_exists?: InputMaybe<Scalars['Boolean']>
  collapseHeader_not?: InputMaybe<Scalars['Boolean']>
  contentfulMetadata?: InputMaybe<ContentfulMetadataFilter>
  metaContent?: InputMaybe<CfPagesMetaContentNestedFilter>
  metaContent_exists?: InputMaybe<Scalars['Boolean']>
  pageComponentsCollection_exists?: InputMaybe<Scalars['Boolean']>
  showHeader?: InputMaybe<Scalars['Boolean']>
  showHeader_exists?: InputMaybe<Scalars['Boolean']>
  showHeader_not?: InputMaybe<Scalars['Boolean']>
  sys?: InputMaybe<SysFilter>
  title?: InputMaybe<Scalars['String']>
  title_contains?: InputMaybe<Scalars['String']>
  title_exists?: InputMaybe<Scalars['Boolean']>
  title_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  title_not?: InputMaybe<Scalars['String']>
  title_not_contains?: InputMaybe<Scalars['String']>
  title_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
}

export type TemplateBookingLinkingCollections = {
  __typename?: 'TemplateBookingLinkingCollections'
  entryCollection?: Maybe<EntryCollection>
}

export type TemplateBookingLinkingCollectionsEntryCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>
  locale?: InputMaybe<Scalars['String']>
  preview?: InputMaybe<Scalars['Boolean']>
  skip?: InputMaybe<Scalars['Int']>
}

export enum TemplateBookingOrder {
  CollapseHeaderAsc = 'collapseHeader_ASC',
  CollapseHeaderDesc = 'collapseHeader_DESC',
  ShowHeaderAsc = 'showHeader_ASC',
  ShowHeaderDesc = 'showHeader_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC',
}

export type TemplateBookingPageComponentsCollection = {
  __typename?: 'TemplateBookingPageComponentsCollection'
  items: Array<Maybe<TemplateBookingPageComponentsItem>>
  limit: Scalars['Int']
  skip: Scalars['Int']
  total: Scalars['Int']
}

export type TemplateBookingPageComponentsItem =
  | ComponentAdvisorPlanTable
  | ComponentAdvisorReviews
  | ComponentAnimatedTiles
  | ComponentButtonCta
  | ComponentCarouselSection
  | ComponentComparisonTable
  | ComponentCtaBanner
  | ComponentDataBanner
  | ComponentFeaturedAdvisors
  | ComponentFeaturedArticles
  | ComponentFeaturedGuides
  | ComponentFeaturedReviews
  | ComponentForaLogo
  | ComponentForm
  | ComponentHeroCentered
  | ComponentHeroFullWidth
  | ComponentImageSideBySide
  | ComponentInfoGrid
  | ComponentPartnerGrid
  | ComponentPictureSectionBanner
  | ComponentReviews
  | ComponentSingleUse
  | ComponentSpacer
  | ComponentStickyHeader
  | ComponentSubNavBar
  | ComponentSubscriptionCard
  | ComponentTeamSection
  | ComponentTestimonialCarousel
  | ComponentTextSection
  | ComponentThreeIconSectionWithPicture
  | ComponentTwoColumnInfographic
  | ComponentTwoColumnTextSection
  | ComponentTypeformEmbed
  | ComponentVideoSection
  | ComponentWhatYouGetModule
  | FaqsQuestionGroups
  | PagesFeaturedHotels
  | PagesHeaderBlock
  | PagesHeroBlock
  | PagesLearnMoreBlock
  | PagesPartnerBanner
  | PagesPerksList
  | PagesPressBanner
  | PagesTwoColumnCta

/** Create and manage all Careers page content. [See type definition](https://app.contentful.com/spaces/9cxgygtadmvl/content_types/templateCareers) */
export type TemplateCareers = Entry &
  _Node & {
    __typename?: 'TemplateCareers'
    _id: Scalars['ID']
    analyticsModule?: Maybe<SettingsAnalyticsModule>
    collapseHeader?: Maybe<Scalars['Boolean']>
    contentfulMetadata: ContentfulMetadata
    linkedFrom?: Maybe<TemplateCareersLinkingCollections>
    metaContent?: Maybe<PagesMetaContent>
    pageComponentsCollection?: Maybe<TemplateCareersPageComponentsCollection>
    showHeader?: Maybe<Scalars['Boolean']>
    sys: Sys
    title?: Maybe<Scalars['String']>
  }

/** Create and manage all Careers page content. [See type definition](https://app.contentful.com/spaces/9cxgygtadmvl/content_types/templateCareers) */
export type TemplateCareersAnalyticsModuleArgs = {
  locale?: InputMaybe<Scalars['String']>
  preview?: InputMaybe<Scalars['Boolean']>
  where?: InputMaybe<SettingsAnalyticsModuleFilter>
}

/** Create and manage all Careers page content. [See type definition](https://app.contentful.com/spaces/9cxgygtadmvl/content_types/templateCareers) */
export type TemplateCareersCollapseHeaderArgs = {
  locale?: InputMaybe<Scalars['String']>
}

/** Create and manage all Careers page content. [See type definition](https://app.contentful.com/spaces/9cxgygtadmvl/content_types/templateCareers) */
export type TemplateCareersLinkedFromArgs = {
  allowedLocales?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
}

/** Create and manage all Careers page content. [See type definition](https://app.contentful.com/spaces/9cxgygtadmvl/content_types/templateCareers) */
export type TemplateCareersMetaContentArgs = {
  locale?: InputMaybe<Scalars['String']>
  preview?: InputMaybe<Scalars['Boolean']>
  where?: InputMaybe<PagesMetaContentFilter>
}

/** Create and manage all Careers page content. [See type definition](https://app.contentful.com/spaces/9cxgygtadmvl/content_types/templateCareers) */
export type TemplateCareersPageComponentsCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>
  locale?: InputMaybe<Scalars['String']>
  preview?: InputMaybe<Scalars['Boolean']>
  skip?: InputMaybe<Scalars['Int']>
}

/** Create and manage all Careers page content. [See type definition](https://app.contentful.com/spaces/9cxgygtadmvl/content_types/templateCareers) */
export type TemplateCareersShowHeaderArgs = {
  locale?: InputMaybe<Scalars['String']>
}

/** Create and manage all Careers page content. [See type definition](https://app.contentful.com/spaces/9cxgygtadmvl/content_types/templateCareers) */
export type TemplateCareersTitleArgs = {
  locale?: InputMaybe<Scalars['String']>
}

export type TemplateCareersCollection = {
  __typename?: 'TemplateCareersCollection'
  items: Array<Maybe<TemplateCareers>>
  limit: Scalars['Int']
  skip: Scalars['Int']
  total: Scalars['Int']
}

export type TemplateCareersFilter = {
  AND?: InputMaybe<Array<InputMaybe<TemplateCareersFilter>>>
  OR?: InputMaybe<Array<InputMaybe<TemplateCareersFilter>>>
  analyticsModule?: InputMaybe<CfSettingsAnalyticsModuleNestedFilter>
  analyticsModule_exists?: InputMaybe<Scalars['Boolean']>
  collapseHeader?: InputMaybe<Scalars['Boolean']>
  collapseHeader_exists?: InputMaybe<Scalars['Boolean']>
  collapseHeader_not?: InputMaybe<Scalars['Boolean']>
  contentfulMetadata?: InputMaybe<ContentfulMetadataFilter>
  metaContent?: InputMaybe<CfPagesMetaContentNestedFilter>
  metaContent_exists?: InputMaybe<Scalars['Boolean']>
  pageComponentsCollection_exists?: InputMaybe<Scalars['Boolean']>
  showHeader?: InputMaybe<Scalars['Boolean']>
  showHeader_exists?: InputMaybe<Scalars['Boolean']>
  showHeader_not?: InputMaybe<Scalars['Boolean']>
  sys?: InputMaybe<SysFilter>
  title?: InputMaybe<Scalars['String']>
  title_contains?: InputMaybe<Scalars['String']>
  title_exists?: InputMaybe<Scalars['Boolean']>
  title_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  title_not?: InputMaybe<Scalars['String']>
  title_not_contains?: InputMaybe<Scalars['String']>
  title_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
}

export type TemplateCareersLinkingCollections = {
  __typename?: 'TemplateCareersLinkingCollections'
  entryCollection?: Maybe<EntryCollection>
}

export type TemplateCareersLinkingCollectionsEntryCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>
  locale?: InputMaybe<Scalars['String']>
  preview?: InputMaybe<Scalars['Boolean']>
  skip?: InputMaybe<Scalars['Int']>
}

export enum TemplateCareersOrder {
  CollapseHeaderAsc = 'collapseHeader_ASC',
  CollapseHeaderDesc = 'collapseHeader_DESC',
  ShowHeaderAsc = 'showHeader_ASC',
  ShowHeaderDesc = 'showHeader_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC',
}

export type TemplateCareersPageComponentsCollection = {
  __typename?: 'TemplateCareersPageComponentsCollection'
  items: Array<Maybe<TemplateCareersPageComponentsItem>>
  limit: Scalars['Int']
  skip: Scalars['Int']
  total: Scalars['Int']
}

export type TemplateCareersPageComponentsItem =
  | ComponentAdvisorPlanTable
  | ComponentAdvisorReviews
  | ComponentAnimatedTiles
  | ComponentButtonCta
  | ComponentCarouselSection
  | ComponentComparisonTable
  | ComponentCtaBanner
  | ComponentDataBanner
  | ComponentFeaturedAdvisors
  | ComponentFeaturedArticles
  | ComponentFeaturedGuides
  | ComponentFeaturedReviews
  | ComponentForaLogo
  | ComponentForm
  | ComponentHeroCentered
  | ComponentHeroFullWidth
  | ComponentImageSideBySide
  | ComponentInfoGrid
  | ComponentPartnerGrid
  | ComponentPictureSectionBanner
  | ComponentReviews
  | ComponentSingleUse
  | ComponentSpacer
  | ComponentStickyHeader
  | ComponentSubNavBar
  | ComponentSubscriptionCard
  | ComponentTeamSection
  | ComponentTestimonialCarousel
  | ComponentTextSection
  | ComponentThreeIconSectionWithPicture
  | ComponentTwoColumnInfographic
  | ComponentTwoColumnTextSection
  | ComponentTypeformEmbed
  | ComponentVideoSection
  | ComponentWhatYouGetModule
  | FaqsQuestionGroups
  | PagesFeaturedHotels
  | PagesHeaderBlock
  | PagesHeroBlock
  | PagesLearnMoreBlock
  | PagesPartnerBanner
  | PagesPerksList
  | PagesPressBanner
  | PagesTwoColumnCta

/** [See type definition](https://app.contentful.com/spaces/9cxgygtadmvl/content_types/templateContactUs) */
export type TemplateContactUs = Entry &
  _Node & {
    __typename?: 'TemplateContactUs'
    _id: Scalars['ID']
    analyticsModule?: Maybe<SettingsAnalyticsModule>
    collapseHeader?: Maybe<Scalars['Boolean']>
    contentfulMetadata: ContentfulMetadata
    linkedFrom?: Maybe<TemplateContactUsLinkingCollections>
    metaContent?: Maybe<PagesMetaContent>
    pageComponentsCollection?: Maybe<TemplateContactUsPageComponentsCollection>
    showHeader?: Maybe<Scalars['Boolean']>
    sys: Sys
    title?: Maybe<Scalars['String']>
  }

/** [See type definition](https://app.contentful.com/spaces/9cxgygtadmvl/content_types/templateContactUs) */
export type TemplateContactUsAnalyticsModuleArgs = {
  locale?: InputMaybe<Scalars['String']>
  preview?: InputMaybe<Scalars['Boolean']>
  where?: InputMaybe<SettingsAnalyticsModuleFilter>
}

/** [See type definition](https://app.contentful.com/spaces/9cxgygtadmvl/content_types/templateContactUs) */
export type TemplateContactUsCollapseHeaderArgs = {
  locale?: InputMaybe<Scalars['String']>
}

/** [See type definition](https://app.contentful.com/spaces/9cxgygtadmvl/content_types/templateContactUs) */
export type TemplateContactUsLinkedFromArgs = {
  allowedLocales?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
}

/** [See type definition](https://app.contentful.com/spaces/9cxgygtadmvl/content_types/templateContactUs) */
export type TemplateContactUsMetaContentArgs = {
  locale?: InputMaybe<Scalars['String']>
  preview?: InputMaybe<Scalars['Boolean']>
  where?: InputMaybe<PagesMetaContentFilter>
}

/** [See type definition](https://app.contentful.com/spaces/9cxgygtadmvl/content_types/templateContactUs) */
export type TemplateContactUsPageComponentsCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>
  locale?: InputMaybe<Scalars['String']>
  preview?: InputMaybe<Scalars['Boolean']>
  skip?: InputMaybe<Scalars['Int']>
}

/** [See type definition](https://app.contentful.com/spaces/9cxgygtadmvl/content_types/templateContactUs) */
export type TemplateContactUsShowHeaderArgs = {
  locale?: InputMaybe<Scalars['String']>
}

/** [See type definition](https://app.contentful.com/spaces/9cxgygtadmvl/content_types/templateContactUs) */
export type TemplateContactUsTitleArgs = {
  locale?: InputMaybe<Scalars['String']>
}

export type TemplateContactUsCollection = {
  __typename?: 'TemplateContactUsCollection'
  items: Array<Maybe<TemplateContactUs>>
  limit: Scalars['Int']
  skip: Scalars['Int']
  total: Scalars['Int']
}

export type TemplateContactUsFilter = {
  AND?: InputMaybe<Array<InputMaybe<TemplateContactUsFilter>>>
  OR?: InputMaybe<Array<InputMaybe<TemplateContactUsFilter>>>
  analyticsModule?: InputMaybe<CfSettingsAnalyticsModuleNestedFilter>
  analyticsModule_exists?: InputMaybe<Scalars['Boolean']>
  collapseHeader?: InputMaybe<Scalars['Boolean']>
  collapseHeader_exists?: InputMaybe<Scalars['Boolean']>
  collapseHeader_not?: InputMaybe<Scalars['Boolean']>
  contentfulMetadata?: InputMaybe<ContentfulMetadataFilter>
  metaContent?: InputMaybe<CfPagesMetaContentNestedFilter>
  metaContent_exists?: InputMaybe<Scalars['Boolean']>
  pageComponentsCollection_exists?: InputMaybe<Scalars['Boolean']>
  showHeader?: InputMaybe<Scalars['Boolean']>
  showHeader_exists?: InputMaybe<Scalars['Boolean']>
  showHeader_not?: InputMaybe<Scalars['Boolean']>
  sys?: InputMaybe<SysFilter>
  title?: InputMaybe<Scalars['String']>
  title_contains?: InputMaybe<Scalars['String']>
  title_exists?: InputMaybe<Scalars['Boolean']>
  title_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  title_not?: InputMaybe<Scalars['String']>
  title_not_contains?: InputMaybe<Scalars['String']>
  title_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
}

export type TemplateContactUsLinkingCollections = {
  __typename?: 'TemplateContactUsLinkingCollections'
  entryCollection?: Maybe<EntryCollection>
}

export type TemplateContactUsLinkingCollectionsEntryCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>
  locale?: InputMaybe<Scalars['String']>
  preview?: InputMaybe<Scalars['Boolean']>
  skip?: InputMaybe<Scalars['Int']>
}

export enum TemplateContactUsOrder {
  CollapseHeaderAsc = 'collapseHeader_ASC',
  CollapseHeaderDesc = 'collapseHeader_DESC',
  ShowHeaderAsc = 'showHeader_ASC',
  ShowHeaderDesc = 'showHeader_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC',
}

export type TemplateContactUsPageComponentsCollection = {
  __typename?: 'TemplateContactUsPageComponentsCollection'
  items: Array<Maybe<TemplateContactUsPageComponentsItem>>
  limit: Scalars['Int']
  skip: Scalars['Int']
  total: Scalars['Int']
}

export type TemplateContactUsPageComponentsItem =
  | ComponentAdvisorPlanTable
  | ComponentAdvisorReviews
  | ComponentAnimatedTiles
  | ComponentButtonCta
  | ComponentCarouselSection
  | ComponentComparisonTable
  | ComponentCtaBanner
  | ComponentDataBanner
  | ComponentFeaturedAdvisors
  | ComponentFeaturedArticles
  | ComponentFeaturedGuides
  | ComponentFeaturedReviews
  | ComponentForaLogo
  | ComponentForm
  | ComponentHeroCentered
  | ComponentHeroFullWidth
  | ComponentImageSideBySide
  | ComponentInfoGrid
  | ComponentPartnerGrid
  | ComponentPictureSectionBanner
  | ComponentReviews
  | ComponentSingleUse
  | ComponentSpacer
  | ComponentStickyHeader
  | ComponentSubNavBar
  | ComponentSubscriptionCard
  | ComponentTeamSection
  | ComponentTestimonialCarousel
  | ComponentTextSection
  | ComponentThreeIconSectionWithPicture
  | ComponentTwoColumnInfographic
  | ComponentTwoColumnTextSection
  | ComponentTypeformEmbed
  | ComponentVideoSection
  | ComponentWhatYouGetModule
  | FaqsQuestionGroups
  | PagesFeaturedHotels
  | PagesHeaderBlock
  | PagesHeroBlock
  | PagesLearnMoreBlock
  | PagesPartnerBanner
  | PagesPerksList
  | PagesPressBanner
  | PagesTwoColumnCta

/** Model for the Destinations landing page. [See type definition](https://app.contentful.com/spaces/9cxgygtadmvl/content_types/templateDestinations) */
export type TemplateDestinations = Entry &
  _Node & {
    __typename?: 'TemplateDestinations'
    _id: Scalars['ID']
    analyticsModule?: Maybe<SettingsAnalyticsModule>
    categoriesCollection?: Maybe<TemplateDestinationsCategoriesCollection>
    collapseHeader?: Maybe<Scalars['Boolean']>
    collapsibleContent?: Maybe<TemplateDestinationsCollapsibleContent>
    collapsibleHeading?: Maybe<Scalars['String']>
    contentfulMetadata: ContentfulMetadata
    learnMoreBlock?: Maybe<ComponentThreeIconSection>
    linkedFrom?: Maybe<TemplateDestinationsLinkingCollections>
    metaContent?: Maybe<PagesMetaContent>
    pageComponentsCollection?: Maybe<TemplateDestinationsPageComponentsCollection>
    questionGroup?: Maybe<FaqsQuestionGroups>
    showHeader?: Maybe<Scalars['Boolean']>
    sys: Sys
    title?: Maybe<Scalars['String']>
  }

/** Model for the Destinations landing page. [See type definition](https://app.contentful.com/spaces/9cxgygtadmvl/content_types/templateDestinations) */
export type TemplateDestinationsAnalyticsModuleArgs = {
  locale?: InputMaybe<Scalars['String']>
  preview?: InputMaybe<Scalars['Boolean']>
  where?: InputMaybe<SettingsAnalyticsModuleFilter>
}

/** Model for the Destinations landing page. [See type definition](https://app.contentful.com/spaces/9cxgygtadmvl/content_types/templateDestinations) */
export type TemplateDestinationsCategoriesCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>
  locale?: InputMaybe<Scalars['String']>
  preview?: InputMaybe<Scalars['Boolean']>
  skip?: InputMaybe<Scalars['Int']>
  where?: InputMaybe<TemplateDestinationsCategoriesFilter>
}

/** Model for the Destinations landing page. [See type definition](https://app.contentful.com/spaces/9cxgygtadmvl/content_types/templateDestinations) */
export type TemplateDestinationsCollapseHeaderArgs = {
  locale?: InputMaybe<Scalars['String']>
}

/** Model for the Destinations landing page. [See type definition](https://app.contentful.com/spaces/9cxgygtadmvl/content_types/templateDestinations) */
export type TemplateDestinationsCollapsibleContentArgs = {
  locale?: InputMaybe<Scalars['String']>
}

/** Model for the Destinations landing page. [See type definition](https://app.contentful.com/spaces/9cxgygtadmvl/content_types/templateDestinations) */
export type TemplateDestinationsCollapsibleHeadingArgs = {
  locale?: InputMaybe<Scalars['String']>
}

/** Model for the Destinations landing page. [See type definition](https://app.contentful.com/spaces/9cxgygtadmvl/content_types/templateDestinations) */
export type TemplateDestinationsLearnMoreBlockArgs = {
  locale?: InputMaybe<Scalars['String']>
  preview?: InputMaybe<Scalars['Boolean']>
  where?: InputMaybe<ComponentThreeIconSectionFilter>
}

/** Model for the Destinations landing page. [See type definition](https://app.contentful.com/spaces/9cxgygtadmvl/content_types/templateDestinations) */
export type TemplateDestinationsLinkedFromArgs = {
  allowedLocales?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
}

/** Model for the Destinations landing page. [See type definition](https://app.contentful.com/spaces/9cxgygtadmvl/content_types/templateDestinations) */
export type TemplateDestinationsMetaContentArgs = {
  locale?: InputMaybe<Scalars['String']>
  preview?: InputMaybe<Scalars['Boolean']>
  where?: InputMaybe<PagesMetaContentFilter>
}

/** Model for the Destinations landing page. [See type definition](https://app.contentful.com/spaces/9cxgygtadmvl/content_types/templateDestinations) */
export type TemplateDestinationsPageComponentsCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>
  locale?: InputMaybe<Scalars['String']>
  preview?: InputMaybe<Scalars['Boolean']>
  skip?: InputMaybe<Scalars['Int']>
}

/** Model for the Destinations landing page. [See type definition](https://app.contentful.com/spaces/9cxgygtadmvl/content_types/templateDestinations) */
export type TemplateDestinationsQuestionGroupArgs = {
  locale?: InputMaybe<Scalars['String']>
  preview?: InputMaybe<Scalars['Boolean']>
  where?: InputMaybe<FaqsQuestionGroupsFilter>
}

/** Model for the Destinations landing page. [See type definition](https://app.contentful.com/spaces/9cxgygtadmvl/content_types/templateDestinations) */
export type TemplateDestinationsShowHeaderArgs = {
  locale?: InputMaybe<Scalars['String']>
}

/** Model for the Destinations landing page. [See type definition](https://app.contentful.com/spaces/9cxgygtadmvl/content_types/templateDestinations) */
export type TemplateDestinationsTitleArgs = {
  locale?: InputMaybe<Scalars['String']>
}

export type TemplateDestinationsCategoriesCollection = {
  __typename?: 'TemplateDestinationsCategoriesCollection'
  items: Array<Maybe<TemplateDestinationsCategoriesItem>>
  limit: Scalars['Int']
  skip: Scalars['Int']
  total: Scalars['Int']
}

export type TemplateDestinationsCategoriesFilter = {
  AND?: InputMaybe<Array<InputMaybe<TemplateDestinationsCategoriesFilter>>>
  OR?: InputMaybe<Array<InputMaybe<TemplateDestinationsCategoriesFilter>>>
  cloudinaryFeaturedImage_exists?: InputMaybe<Scalars['Boolean']>
  cloudinaryMobileFeaturedImage_exists?: InputMaybe<Scalars['Boolean']>
  contentfulMetadata?: InputMaybe<ContentfulMetadataFilter>
  featuredHotelsCollection_exists?: InputMaybe<Scalars['Boolean']>
  featuredImage_exists?: InputMaybe<Scalars['Boolean']>
  generateTopHotelsPage?: InputMaybe<Scalars['Boolean']>
  generateTopHotelsPage_exists?: InputMaybe<Scalars['Boolean']>
  generateTopHotelsPage_not?: InputMaybe<Scalars['Boolean']>
  grammarDefiniteArticle?: InputMaybe<Scalars['Boolean']>
  grammarDefiniteArticle_exists?: InputMaybe<Scalars['Boolean']>
  grammarDefiniteArticle_not?: InputMaybe<Scalars['Boolean']>
  metaContent_exists?: InputMaybe<Scalars['Boolean']>
  mobileFeaturedImage_exists?: InputMaybe<Scalars['Boolean']>
  questionsCollection_exists?: InputMaybe<Scalars['Boolean']>
  rankedHotels_exists?: InputMaybe<Scalars['Boolean']>
  slug?: InputMaybe<Scalars['String']>
  slug_contains?: InputMaybe<Scalars['String']>
  slug_exists?: InputMaybe<Scalars['Boolean']>
  slug_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  slug_not?: InputMaybe<Scalars['String']>
  slug_not_contains?: InputMaybe<Scalars['String']>
  slug_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  sys?: InputMaybe<SysFilter>
  teaserCopy_contains?: InputMaybe<Scalars['String']>
  teaserCopy_exists?: InputMaybe<Scalars['Boolean']>
  teaserCopy_not_contains?: InputMaybe<Scalars['String']>
  title?: InputMaybe<Scalars['String']>
  title_contains?: InputMaybe<Scalars['String']>
  title_exists?: InputMaybe<Scalars['Boolean']>
  title_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  title_not?: InputMaybe<Scalars['String']>
  title_not_contains?: InputMaybe<Scalars['String']>
  title_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
}

export type TemplateDestinationsCategoriesItem =
  | DestinationsCategories
  | DestinationsSubcategories

export type TemplateDestinationsCollapsibleContent = {
  __typename?: 'TemplateDestinationsCollapsibleContent'
  json: Scalars['JSON']
  links: TemplateDestinationsCollapsibleContentLinks
}

export type TemplateDestinationsCollapsibleContentAssets = {
  __typename?: 'TemplateDestinationsCollapsibleContentAssets'
  block: Array<Maybe<Asset>>
  hyperlink: Array<Maybe<Asset>>
}

export type TemplateDestinationsCollapsibleContentEntries = {
  __typename?: 'TemplateDestinationsCollapsibleContentEntries'
  block: Array<Maybe<Entry>>
  hyperlink: Array<Maybe<Entry>>
  inline: Array<Maybe<Entry>>
}

export type TemplateDestinationsCollapsibleContentLinks = {
  __typename?: 'TemplateDestinationsCollapsibleContentLinks'
  assets: TemplateDestinationsCollapsibleContentAssets
  entries: TemplateDestinationsCollapsibleContentEntries
  resources: TemplateDestinationsCollapsibleContentResources
}

export type TemplateDestinationsCollapsibleContentResources = {
  __typename?: 'TemplateDestinationsCollapsibleContentResources'
  block: Array<TemplateDestinationsCollapsibleContentResourcesBlock>
  hyperlink: Array<TemplateDestinationsCollapsibleContentResourcesHyperlink>
  inline: Array<TemplateDestinationsCollapsibleContentResourcesInline>
}

export type TemplateDestinationsCollapsibleContentResourcesBlock =
  ResourceLink & {
    __typename?: 'TemplateDestinationsCollapsibleContentResourcesBlock'
    sys: ResourceSys
  }

export type TemplateDestinationsCollapsibleContentResourcesHyperlink =
  ResourceLink & {
    __typename?: 'TemplateDestinationsCollapsibleContentResourcesHyperlink'
    sys: ResourceSys
  }

export type TemplateDestinationsCollapsibleContentResourcesInline =
  ResourceLink & {
    __typename?: 'TemplateDestinationsCollapsibleContentResourcesInline'
    sys: ResourceSys
  }

export type TemplateDestinationsCollection = {
  __typename?: 'TemplateDestinationsCollection'
  items: Array<Maybe<TemplateDestinations>>
  limit: Scalars['Int']
  skip: Scalars['Int']
  total: Scalars['Int']
}

export type TemplateDestinationsFilter = {
  AND?: InputMaybe<Array<InputMaybe<TemplateDestinationsFilter>>>
  OR?: InputMaybe<Array<InputMaybe<TemplateDestinationsFilter>>>
  analyticsModule?: InputMaybe<CfSettingsAnalyticsModuleNestedFilter>
  analyticsModule_exists?: InputMaybe<Scalars['Boolean']>
  categories?: InputMaybe<CfcategoriesMultiTypeNestedFilter>
  categoriesCollection_exists?: InputMaybe<Scalars['Boolean']>
  collapseHeader?: InputMaybe<Scalars['Boolean']>
  collapseHeader_exists?: InputMaybe<Scalars['Boolean']>
  collapseHeader_not?: InputMaybe<Scalars['Boolean']>
  collapsibleContent_contains?: InputMaybe<Scalars['String']>
  collapsibleContent_exists?: InputMaybe<Scalars['Boolean']>
  collapsibleContent_not_contains?: InputMaybe<Scalars['String']>
  collapsibleHeading?: InputMaybe<Scalars['String']>
  collapsibleHeading_contains?: InputMaybe<Scalars['String']>
  collapsibleHeading_exists?: InputMaybe<Scalars['Boolean']>
  collapsibleHeading_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  collapsibleHeading_not?: InputMaybe<Scalars['String']>
  collapsibleHeading_not_contains?: InputMaybe<Scalars['String']>
  collapsibleHeading_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  contentfulMetadata?: InputMaybe<ContentfulMetadataFilter>
  learnMoreBlock?: InputMaybe<CfComponentThreeIconSectionNestedFilter>
  learnMoreBlock_exists?: InputMaybe<Scalars['Boolean']>
  metaContent?: InputMaybe<CfPagesMetaContentNestedFilter>
  metaContent_exists?: InputMaybe<Scalars['Boolean']>
  pageComponentsCollection_exists?: InputMaybe<Scalars['Boolean']>
  questionGroup?: InputMaybe<CfFaqsQuestionGroupsNestedFilter>
  questionGroup_exists?: InputMaybe<Scalars['Boolean']>
  showHeader?: InputMaybe<Scalars['Boolean']>
  showHeader_exists?: InputMaybe<Scalars['Boolean']>
  showHeader_not?: InputMaybe<Scalars['Boolean']>
  sys?: InputMaybe<SysFilter>
  title?: InputMaybe<Scalars['String']>
  title_contains?: InputMaybe<Scalars['String']>
  title_exists?: InputMaybe<Scalars['Boolean']>
  title_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  title_not?: InputMaybe<Scalars['String']>
  title_not_contains?: InputMaybe<Scalars['String']>
  title_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
}

export type TemplateDestinationsLinkingCollections = {
  __typename?: 'TemplateDestinationsLinkingCollections'
  entryCollection?: Maybe<EntryCollection>
}

export type TemplateDestinationsLinkingCollectionsEntryCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>
  locale?: InputMaybe<Scalars['String']>
  preview?: InputMaybe<Scalars['Boolean']>
  skip?: InputMaybe<Scalars['Int']>
}

export enum TemplateDestinationsOrder {
  CollapseHeaderAsc = 'collapseHeader_ASC',
  CollapseHeaderDesc = 'collapseHeader_DESC',
  CollapsibleHeadingAsc = 'collapsibleHeading_ASC',
  CollapsibleHeadingDesc = 'collapsibleHeading_DESC',
  ShowHeaderAsc = 'showHeader_ASC',
  ShowHeaderDesc = 'showHeader_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC',
}

export type TemplateDestinationsPageComponentsCollection = {
  __typename?: 'TemplateDestinationsPageComponentsCollection'
  items: Array<Maybe<TemplateDestinationsPageComponentsItem>>
  limit: Scalars['Int']
  skip: Scalars['Int']
  total: Scalars['Int']
}

export type TemplateDestinationsPageComponentsItem =
  | ComponentAdvisorPlanTable
  | ComponentAdvisorReviews
  | ComponentAnimatedTiles
  | ComponentButtonCta
  | ComponentCarouselSection
  | ComponentComparisonTable
  | ComponentCtaBanner
  | ComponentDataBanner
  | ComponentFeaturedAdvisors
  | ComponentFeaturedArticles
  | ComponentFeaturedGuides
  | ComponentFeaturedReviews
  | ComponentForaLogo
  | ComponentForm
  | ComponentHeroCentered
  | ComponentHeroFullWidth
  | ComponentImageSideBySide
  | ComponentInfoGrid
  | ComponentPartnerGrid
  | ComponentPictureSectionBanner
  | ComponentReviews
  | ComponentSingleUse
  | ComponentSpacer
  | ComponentStickyHeader
  | ComponentSubNavBar
  | ComponentSubscriptionCard
  | ComponentTeamSection
  | ComponentTestimonialCarousel
  | ComponentTextSection
  | ComponentThreeIconSectionWithPicture
  | ComponentTwoColumnInfographic
  | ComponentTwoColumnTextSection
  | ComponentTypeformEmbed
  | ComponentVideoSection
  | ComponentWhatYouGetModule
  | FaqsQuestionGroups
  | PagesFeaturedHotels
  | PagesHeaderBlock
  | PagesHeroBlock
  | PagesLearnMoreBlock
  | PagesPartnerBanner
  | PagesPerksList
  | PagesPressBanner
  | PagesTwoColumnCta

/** Create and manage all Events page content. [See type definition](https://app.contentful.com/spaces/9cxgygtadmvl/content_types/templateEvents) */
export type TemplateEvents = Entry &
  _Node & {
    __typename?: 'TemplateEvents'
    _id: Scalars['ID']
    collapseHeader?: Maybe<Scalars['Boolean']>
    contentfulMetadata: ContentfulMetadata
    heroFullWidth?: Maybe<ComponentHeroFullWidth>
    linkedFrom?: Maybe<TemplateEventsLinkingCollections>
    metaContent?: Maybe<PagesMetaContent>
    showHeader?: Maybe<Scalars['Boolean']>
    sys: Sys
    title?: Maybe<Scalars['String']>
  }

/** Create and manage all Events page content. [See type definition](https://app.contentful.com/spaces/9cxgygtadmvl/content_types/templateEvents) */
export type TemplateEventsCollapseHeaderArgs = {
  locale?: InputMaybe<Scalars['String']>
}

/** Create and manage all Events page content. [See type definition](https://app.contentful.com/spaces/9cxgygtadmvl/content_types/templateEvents) */
export type TemplateEventsHeroFullWidthArgs = {
  locale?: InputMaybe<Scalars['String']>
  preview?: InputMaybe<Scalars['Boolean']>
  where?: InputMaybe<ComponentHeroFullWidthFilter>
}

/** Create and manage all Events page content. [See type definition](https://app.contentful.com/spaces/9cxgygtadmvl/content_types/templateEvents) */
export type TemplateEventsLinkedFromArgs = {
  allowedLocales?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
}

/** Create and manage all Events page content. [See type definition](https://app.contentful.com/spaces/9cxgygtadmvl/content_types/templateEvents) */
export type TemplateEventsMetaContentArgs = {
  locale?: InputMaybe<Scalars['String']>
  preview?: InputMaybe<Scalars['Boolean']>
  where?: InputMaybe<PagesMetaContentFilter>
}

/** Create and manage all Events page content. [See type definition](https://app.contentful.com/spaces/9cxgygtadmvl/content_types/templateEvents) */
export type TemplateEventsShowHeaderArgs = {
  locale?: InputMaybe<Scalars['String']>
}

/** Create and manage all Events page content. [See type definition](https://app.contentful.com/spaces/9cxgygtadmvl/content_types/templateEvents) */
export type TemplateEventsTitleArgs = {
  locale?: InputMaybe<Scalars['String']>
}

export type TemplateEventsCollection = {
  __typename?: 'TemplateEventsCollection'
  items: Array<Maybe<TemplateEvents>>
  limit: Scalars['Int']
  skip: Scalars['Int']
  total: Scalars['Int']
}

export type TemplateEventsFilter = {
  AND?: InputMaybe<Array<InputMaybe<TemplateEventsFilter>>>
  OR?: InputMaybe<Array<InputMaybe<TemplateEventsFilter>>>
  collapseHeader?: InputMaybe<Scalars['Boolean']>
  collapseHeader_exists?: InputMaybe<Scalars['Boolean']>
  collapseHeader_not?: InputMaybe<Scalars['Boolean']>
  contentfulMetadata?: InputMaybe<ContentfulMetadataFilter>
  heroFullWidth?: InputMaybe<CfComponentHeroFullWidthNestedFilter>
  heroFullWidth_exists?: InputMaybe<Scalars['Boolean']>
  metaContent?: InputMaybe<CfPagesMetaContentNestedFilter>
  metaContent_exists?: InputMaybe<Scalars['Boolean']>
  showHeader?: InputMaybe<Scalars['Boolean']>
  showHeader_exists?: InputMaybe<Scalars['Boolean']>
  showHeader_not?: InputMaybe<Scalars['Boolean']>
  sys?: InputMaybe<SysFilter>
  title?: InputMaybe<Scalars['String']>
  title_contains?: InputMaybe<Scalars['String']>
  title_exists?: InputMaybe<Scalars['Boolean']>
  title_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  title_not?: InputMaybe<Scalars['String']>
  title_not_contains?: InputMaybe<Scalars['String']>
  title_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
}

export type TemplateEventsLinkingCollections = {
  __typename?: 'TemplateEventsLinkingCollections'
  entryCollection?: Maybe<EntryCollection>
}

export type TemplateEventsLinkingCollectionsEntryCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>
  locale?: InputMaybe<Scalars['String']>
  preview?: InputMaybe<Scalars['Boolean']>
  skip?: InputMaybe<Scalars['Int']>
}

export enum TemplateEventsOrder {
  CollapseHeaderAsc = 'collapseHeader_ASC',
  CollapseHeaderDesc = 'collapseHeader_DESC',
  ShowHeaderAsc = 'showHeader_ASC',
  ShowHeaderDesc = 'showHeader_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC',
}

/** Create and manage all FAQs page content. [See type definition](https://app.contentful.com/spaces/9cxgygtadmvl/content_types/templateFaqs) */
export type TemplateFaqs = Entry &
  _Node & {
    __typename?: 'TemplateFaqs'
    _id: Scalars['ID']
    analyticsModule?: Maybe<SettingsAnalyticsModule>
    contentfulMetadata: ContentfulMetadata
    linkedFrom?: Maybe<TemplateFaqsLinkingCollections>
    metaContent?: Maybe<PagesMetaContent>
    questionGroupsCollection?: Maybe<TemplateFaqsQuestionGroupsCollection>
    sys: Sys
    title?: Maybe<Scalars['String']>
  }

/** Create and manage all FAQs page content. [See type definition](https://app.contentful.com/spaces/9cxgygtadmvl/content_types/templateFaqs) */
export type TemplateFaqsAnalyticsModuleArgs = {
  locale?: InputMaybe<Scalars['String']>
  preview?: InputMaybe<Scalars['Boolean']>
  where?: InputMaybe<SettingsAnalyticsModuleFilter>
}

/** Create and manage all FAQs page content. [See type definition](https://app.contentful.com/spaces/9cxgygtadmvl/content_types/templateFaqs) */
export type TemplateFaqsLinkedFromArgs = {
  allowedLocales?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
}

/** Create and manage all FAQs page content. [See type definition](https://app.contentful.com/spaces/9cxgygtadmvl/content_types/templateFaqs) */
export type TemplateFaqsMetaContentArgs = {
  locale?: InputMaybe<Scalars['String']>
  preview?: InputMaybe<Scalars['Boolean']>
  where?: InputMaybe<PagesMetaContentFilter>
}

/** Create and manage all FAQs page content. [See type definition](https://app.contentful.com/spaces/9cxgygtadmvl/content_types/templateFaqs) */
export type TemplateFaqsQuestionGroupsCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>
  locale?: InputMaybe<Scalars['String']>
  order?: InputMaybe<
    Array<InputMaybe<TemplateFaqsQuestionGroupsCollectionOrder>>
  >
  preview?: InputMaybe<Scalars['Boolean']>
  skip?: InputMaybe<Scalars['Int']>
  where?: InputMaybe<FaqsQuestionGroupsFilter>
}

/** Create and manage all FAQs page content. [See type definition](https://app.contentful.com/spaces/9cxgygtadmvl/content_types/templateFaqs) */
export type TemplateFaqsTitleArgs = {
  locale?: InputMaybe<Scalars['String']>
}

export type TemplateFaqsCollection = {
  __typename?: 'TemplateFaqsCollection'
  items: Array<Maybe<TemplateFaqs>>
  limit: Scalars['Int']
  skip: Scalars['Int']
  total: Scalars['Int']
}

export type TemplateFaqsFilter = {
  AND?: InputMaybe<Array<InputMaybe<TemplateFaqsFilter>>>
  OR?: InputMaybe<Array<InputMaybe<TemplateFaqsFilter>>>
  analyticsModule?: InputMaybe<CfSettingsAnalyticsModuleNestedFilter>
  analyticsModule_exists?: InputMaybe<Scalars['Boolean']>
  contentfulMetadata?: InputMaybe<ContentfulMetadataFilter>
  metaContent?: InputMaybe<CfPagesMetaContentNestedFilter>
  metaContent_exists?: InputMaybe<Scalars['Boolean']>
  questionGroups?: InputMaybe<CfFaqsQuestionGroupsNestedFilter>
  questionGroupsCollection_exists?: InputMaybe<Scalars['Boolean']>
  sys?: InputMaybe<SysFilter>
  title?: InputMaybe<Scalars['String']>
  title_contains?: InputMaybe<Scalars['String']>
  title_exists?: InputMaybe<Scalars['Boolean']>
  title_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  title_not?: InputMaybe<Scalars['String']>
  title_not_contains?: InputMaybe<Scalars['String']>
  title_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
}

export type TemplateFaqsLinkingCollections = {
  __typename?: 'TemplateFaqsLinkingCollections'
  entryCollection?: Maybe<EntryCollection>
  templatePartnerDetailCollection?: Maybe<TemplatePartnerDetailCollection>
}

export type TemplateFaqsLinkingCollectionsEntryCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>
  locale?: InputMaybe<Scalars['String']>
  preview?: InputMaybe<Scalars['Boolean']>
  skip?: InputMaybe<Scalars['Int']>
}

export type TemplateFaqsLinkingCollectionsTemplatePartnerDetailCollectionArgs =
  {
    limit?: InputMaybe<Scalars['Int']>
    locale?: InputMaybe<Scalars['String']>
    order?: InputMaybe<
      Array<
        InputMaybe<TemplateFaqsLinkingCollectionsTemplatePartnerDetailCollectionOrder>
      >
    >
    preview?: InputMaybe<Scalars['Boolean']>
    skip?: InputMaybe<Scalars['Int']>
  }

export enum TemplateFaqsLinkingCollectionsTemplatePartnerDetailCollectionOrder {
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
}

export enum TemplateFaqsOrder {
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC',
}

export type TemplateFaqsQuestionGroupsCollection = {
  __typename?: 'TemplateFaqsQuestionGroupsCollection'
  items: Array<Maybe<FaqsQuestionGroups>>
  limit: Scalars['Int']
  skip: Scalars['Int']
  total: Scalars['Int']
}

export enum TemplateFaqsQuestionGroupsCollectionOrder {
  AdminLabelAsc = 'adminLabel_ASC',
  AdminLabelDesc = 'adminLabel_DESC',
  AutomaticallyExpandQuestionsAsc = 'automaticallyExpandQuestions_ASC',
  AutomaticallyExpandQuestionsDesc = 'automaticallyExpandQuestions_DESC',
  SectionIdAsc = 'sectionId_ASC',
  SectionIdDesc = 'sectionId_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  TitleStyleAsc = 'titleStyle_ASC',
  TitleStyleDesc = 'titleStyle_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC',
}

/** Create and manage all Fora Reserve page content. [See type definition](https://app.contentful.com/spaces/9cxgygtadmvl/content_types/templateForaReserve) */
export type TemplateForaReserve = Entry &
  _Node & {
    __typename?: 'TemplateForaReserve'
    _id: Scalars['ID']
    collapseHeader?: Maybe<Scalars['Boolean']>
    contentfulMetadata: ContentfulMetadata
    linkedFrom?: Maybe<TemplateForaReserveLinkingCollections>
    metaContent?: Maybe<PagesMetaContent>
    pageComponentsCollection?: Maybe<TemplateForaReservePageComponentsCollection>
    showHeader?: Maybe<Scalars['Boolean']>
    sys: Sys
    title?: Maybe<Scalars['String']>
  }

/** Create and manage all Fora Reserve page content. [See type definition](https://app.contentful.com/spaces/9cxgygtadmvl/content_types/templateForaReserve) */
export type TemplateForaReserveCollapseHeaderArgs = {
  locale?: InputMaybe<Scalars['String']>
}

/** Create and manage all Fora Reserve page content. [See type definition](https://app.contentful.com/spaces/9cxgygtadmvl/content_types/templateForaReserve) */
export type TemplateForaReserveLinkedFromArgs = {
  allowedLocales?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
}

/** Create and manage all Fora Reserve page content. [See type definition](https://app.contentful.com/spaces/9cxgygtadmvl/content_types/templateForaReserve) */
export type TemplateForaReserveMetaContentArgs = {
  locale?: InputMaybe<Scalars['String']>
  preview?: InputMaybe<Scalars['Boolean']>
  where?: InputMaybe<PagesMetaContentFilter>
}

/** Create and manage all Fora Reserve page content. [See type definition](https://app.contentful.com/spaces/9cxgygtadmvl/content_types/templateForaReserve) */
export type TemplateForaReservePageComponentsCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>
  locale?: InputMaybe<Scalars['String']>
  preview?: InputMaybe<Scalars['Boolean']>
  skip?: InputMaybe<Scalars['Int']>
}

/** Create and manage all Fora Reserve page content. [See type definition](https://app.contentful.com/spaces/9cxgygtadmvl/content_types/templateForaReserve) */
export type TemplateForaReserveShowHeaderArgs = {
  locale?: InputMaybe<Scalars['String']>
}

/** Create and manage all Fora Reserve page content. [See type definition](https://app.contentful.com/spaces/9cxgygtadmvl/content_types/templateForaReserve) */
export type TemplateForaReserveTitleArgs = {
  locale?: InputMaybe<Scalars['String']>
}

export type TemplateForaReserveCollection = {
  __typename?: 'TemplateForaReserveCollection'
  items: Array<Maybe<TemplateForaReserve>>
  limit: Scalars['Int']
  skip: Scalars['Int']
  total: Scalars['Int']
}

export type TemplateForaReserveFilter = {
  AND?: InputMaybe<Array<InputMaybe<TemplateForaReserveFilter>>>
  OR?: InputMaybe<Array<InputMaybe<TemplateForaReserveFilter>>>
  collapseHeader?: InputMaybe<Scalars['Boolean']>
  collapseHeader_exists?: InputMaybe<Scalars['Boolean']>
  collapseHeader_not?: InputMaybe<Scalars['Boolean']>
  contentfulMetadata?: InputMaybe<ContentfulMetadataFilter>
  metaContent?: InputMaybe<CfPagesMetaContentNestedFilter>
  metaContent_exists?: InputMaybe<Scalars['Boolean']>
  pageComponentsCollection_exists?: InputMaybe<Scalars['Boolean']>
  showHeader?: InputMaybe<Scalars['Boolean']>
  showHeader_exists?: InputMaybe<Scalars['Boolean']>
  showHeader_not?: InputMaybe<Scalars['Boolean']>
  sys?: InputMaybe<SysFilter>
  title?: InputMaybe<Scalars['String']>
  title_contains?: InputMaybe<Scalars['String']>
  title_exists?: InputMaybe<Scalars['Boolean']>
  title_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  title_not?: InputMaybe<Scalars['String']>
  title_not_contains?: InputMaybe<Scalars['String']>
  title_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
}

export type TemplateForaReserveLinkingCollections = {
  __typename?: 'TemplateForaReserveLinkingCollections'
  entryCollection?: Maybe<EntryCollection>
}

export type TemplateForaReserveLinkingCollectionsEntryCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>
  locale?: InputMaybe<Scalars['String']>
  preview?: InputMaybe<Scalars['Boolean']>
  skip?: InputMaybe<Scalars['Int']>
}

export enum TemplateForaReserveOrder {
  CollapseHeaderAsc = 'collapseHeader_ASC',
  CollapseHeaderDesc = 'collapseHeader_DESC',
  ShowHeaderAsc = 'showHeader_ASC',
  ShowHeaderDesc = 'showHeader_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC',
}

export type TemplateForaReservePageComponentsCollection = {
  __typename?: 'TemplateForaReservePageComponentsCollection'
  items: Array<Maybe<TemplateForaReservePageComponentsItem>>
  limit: Scalars['Int']
  skip: Scalars['Int']
  total: Scalars['Int']
}

export type TemplateForaReservePageComponentsItem =
  | ComponentAdvisorPlanTable
  | ComponentAdvisorReviews
  | ComponentAnimatedTiles
  | ComponentButtonCta
  | ComponentCarouselSection
  | ComponentComparisonTable
  | ComponentCtaBanner
  | ComponentDataBanner
  | ComponentFeaturedAdvisors
  | ComponentFeaturedArticles
  | ComponentFeaturedGuides
  | ComponentFeaturedReviews
  | ComponentForaLogo
  | ComponentForm
  | ComponentHeroCentered
  | ComponentHeroFullWidth
  | ComponentImageSideBySide
  | ComponentInfoGrid
  | ComponentPartnerGrid
  | ComponentPictureSectionBanner
  | ComponentReviews
  | ComponentSingleUse
  | ComponentSpacer
  | ComponentStickyHeader
  | ComponentSubNavBar
  | ComponentSubscriptionCard
  | ComponentTeamSection
  | ComponentTestimonialCarousel
  | ComponentTextSection
  | ComponentThreeIconSectionWithPicture
  | ComponentTwoColumnInfographic
  | ComponentTwoColumnTextSection
  | ComponentTypeformEmbed
  | ComponentVideoSection
  | ComponentWhatYouGetModule
  | FaqsQuestionGroups
  | PagesFeaturedHotels
  | PagesHeaderBlock
  | PagesHeroBlock
  | PagesLearnMoreBlock
  | PagesPartnerBanner
  | PagesPerksList
  | PagesPressBanner
  | PagesTwoColumnCta

/** Create and manage all Fora X page content. [See type definition](https://app.contentful.com/spaces/9cxgygtadmvl/content_types/templateForaX) */
export type TemplateForaX = Entry &
  _Node & {
    __typename?: 'TemplateForaX'
    _id: Scalars['ID']
    advisorGridHeader?: Maybe<Scalars['String']>
    advisorGridSubCopy?: Maybe<Scalars['String']>
    collapseHeader?: Maybe<Scalars['Boolean']>
    contentfulMetadata: ContentfulMetadata
    linkedFrom?: Maybe<TemplateForaXLinkingCollections>
    metaContent?: Maybe<PagesMetaContent>
    pageComponentsCollection?: Maybe<TemplateForaXPageComponentsCollection>
    showHeader?: Maybe<Scalars['Boolean']>
    sys: Sys
    title?: Maybe<Scalars['String']>
  }

/** Create and manage all Fora X page content. [See type definition](https://app.contentful.com/spaces/9cxgygtadmvl/content_types/templateForaX) */
export type TemplateForaXAdvisorGridHeaderArgs = {
  locale?: InputMaybe<Scalars['String']>
}

/** Create and manage all Fora X page content. [See type definition](https://app.contentful.com/spaces/9cxgygtadmvl/content_types/templateForaX) */
export type TemplateForaXAdvisorGridSubCopyArgs = {
  locale?: InputMaybe<Scalars['String']>
}

/** Create and manage all Fora X page content. [See type definition](https://app.contentful.com/spaces/9cxgygtadmvl/content_types/templateForaX) */
export type TemplateForaXCollapseHeaderArgs = {
  locale?: InputMaybe<Scalars['String']>
}

/** Create and manage all Fora X page content. [See type definition](https://app.contentful.com/spaces/9cxgygtadmvl/content_types/templateForaX) */
export type TemplateForaXLinkedFromArgs = {
  allowedLocales?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
}

/** Create and manage all Fora X page content. [See type definition](https://app.contentful.com/spaces/9cxgygtadmvl/content_types/templateForaX) */
export type TemplateForaXMetaContentArgs = {
  locale?: InputMaybe<Scalars['String']>
  preview?: InputMaybe<Scalars['Boolean']>
  where?: InputMaybe<PagesMetaContentFilter>
}

/** Create and manage all Fora X page content. [See type definition](https://app.contentful.com/spaces/9cxgygtadmvl/content_types/templateForaX) */
export type TemplateForaXPageComponentsCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>
  locale?: InputMaybe<Scalars['String']>
  preview?: InputMaybe<Scalars['Boolean']>
  skip?: InputMaybe<Scalars['Int']>
}

/** Create and manage all Fora X page content. [See type definition](https://app.contentful.com/spaces/9cxgygtadmvl/content_types/templateForaX) */
export type TemplateForaXShowHeaderArgs = {
  locale?: InputMaybe<Scalars['String']>
}

/** Create and manage all Fora X page content. [See type definition](https://app.contentful.com/spaces/9cxgygtadmvl/content_types/templateForaX) */
export type TemplateForaXTitleArgs = {
  locale?: InputMaybe<Scalars['String']>
}

export type TemplateForaXCollection = {
  __typename?: 'TemplateForaXCollection'
  items: Array<Maybe<TemplateForaX>>
  limit: Scalars['Int']
  skip: Scalars['Int']
  total: Scalars['Int']
}

export type TemplateForaXFilter = {
  AND?: InputMaybe<Array<InputMaybe<TemplateForaXFilter>>>
  OR?: InputMaybe<Array<InputMaybe<TemplateForaXFilter>>>
  advisorGridHeader?: InputMaybe<Scalars['String']>
  advisorGridHeader_contains?: InputMaybe<Scalars['String']>
  advisorGridHeader_exists?: InputMaybe<Scalars['Boolean']>
  advisorGridHeader_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  advisorGridHeader_not?: InputMaybe<Scalars['String']>
  advisorGridHeader_not_contains?: InputMaybe<Scalars['String']>
  advisorGridHeader_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  advisorGridSubCopy?: InputMaybe<Scalars['String']>
  advisorGridSubCopy_contains?: InputMaybe<Scalars['String']>
  advisorGridSubCopy_exists?: InputMaybe<Scalars['Boolean']>
  advisorGridSubCopy_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  advisorGridSubCopy_not?: InputMaybe<Scalars['String']>
  advisorGridSubCopy_not_contains?: InputMaybe<Scalars['String']>
  advisorGridSubCopy_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  collapseHeader?: InputMaybe<Scalars['Boolean']>
  collapseHeader_exists?: InputMaybe<Scalars['Boolean']>
  collapseHeader_not?: InputMaybe<Scalars['Boolean']>
  contentfulMetadata?: InputMaybe<ContentfulMetadataFilter>
  metaContent?: InputMaybe<CfPagesMetaContentNestedFilter>
  metaContent_exists?: InputMaybe<Scalars['Boolean']>
  pageComponentsCollection_exists?: InputMaybe<Scalars['Boolean']>
  showHeader?: InputMaybe<Scalars['Boolean']>
  showHeader_exists?: InputMaybe<Scalars['Boolean']>
  showHeader_not?: InputMaybe<Scalars['Boolean']>
  sys?: InputMaybe<SysFilter>
  title?: InputMaybe<Scalars['String']>
  title_contains?: InputMaybe<Scalars['String']>
  title_exists?: InputMaybe<Scalars['Boolean']>
  title_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  title_not?: InputMaybe<Scalars['String']>
  title_not_contains?: InputMaybe<Scalars['String']>
  title_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
}

export type TemplateForaXLinkingCollections = {
  __typename?: 'TemplateForaXLinkingCollections'
  entryCollection?: Maybe<EntryCollection>
}

export type TemplateForaXLinkingCollectionsEntryCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>
  locale?: InputMaybe<Scalars['String']>
  preview?: InputMaybe<Scalars['Boolean']>
  skip?: InputMaybe<Scalars['Int']>
}

export enum TemplateForaXOrder {
  AdvisorGridHeaderAsc = 'advisorGridHeader_ASC',
  AdvisorGridHeaderDesc = 'advisorGridHeader_DESC',
  AdvisorGridSubCopyAsc = 'advisorGridSubCopy_ASC',
  AdvisorGridSubCopyDesc = 'advisorGridSubCopy_DESC',
  CollapseHeaderAsc = 'collapseHeader_ASC',
  CollapseHeaderDesc = 'collapseHeader_DESC',
  ShowHeaderAsc = 'showHeader_ASC',
  ShowHeaderDesc = 'showHeader_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC',
}

export type TemplateForaXPageComponentsCollection = {
  __typename?: 'TemplateForaXPageComponentsCollection'
  items: Array<Maybe<TemplateForaXPageComponentsItem>>
  limit: Scalars['Int']
  skip: Scalars['Int']
  total: Scalars['Int']
}

export type TemplateForaXPageComponentsItem =
  | ComponentAdvisorReviews
  | ComponentAnimatedTiles
  | ComponentButtonCta
  | ComponentCarouselSection
  | ComponentComparisonTable
  | ComponentCtaBanner
  | ComponentDataBanner
  | ComponentFeaturedAdvisors
  | ComponentFeaturedArticles
  | ComponentFeaturedGuides
  | ComponentFeaturedReviews
  | ComponentForaLogo
  | ComponentForm
  | ComponentHeroCentered
  | ComponentHeroFullWidth
  | ComponentImageSideBySide
  | ComponentInfoGrid
  | ComponentPartnerGrid
  | ComponentPictureSectionBanner
  | ComponentReviews
  | ComponentSingleUse
  | ComponentSpacer
  | ComponentStickyHeader
  | ComponentSubNavBar
  | ComponentSubscriptionCard
  | ComponentTeamSection
  | ComponentTestimonialCarousel
  | ComponentTextSection
  | ComponentThreeIconSectionWithPicture
  | ComponentTwoColumnInfographic
  | ComponentTwoColumnPerksSection
  | ComponentTwoColumnTextSection
  | ComponentTypeformEmbed
  | ComponentVideoSection
  | ComponentWhatYouGetModule
  | FaqsQuestionGroups
  | PagesFeaturedHotels
  | PagesHeaderBlock
  | PagesHeroBlock
  | PagesLearnMoreBlock
  | PagesPartnerBanner
  | PagesPerksList
  | PagesPressBanner
  | PagesTwoColumnCta

/** Create and manage all Guides landing page content. [See type definition](https://app.contentful.com/spaces/9cxgygtadmvl/content_types/templateGuides) */
export type TemplateGuides = Entry &
  _Node & {
    __typename?: 'TemplateGuides'
    _id: Scalars['ID']
    analyticsModule?: Maybe<SettingsAnalyticsModule>
    contentfulMetadata: ContentfulMetadata
    learnMoreBlock?: Maybe<ComponentThreeIconSection>
    linkedFrom?: Maybe<TemplateGuidesLinkingCollections>
    metaContent?: Maybe<PagesMetaContent>
    questionGroup?: Maybe<FaqsQuestionGroups>
    sys: Sys
    teaserCopy?: Maybe<TemplateGuidesTeaserCopy>
    title?: Maybe<Scalars['String']>
  }

/** Create and manage all Guides landing page content. [See type definition](https://app.contentful.com/spaces/9cxgygtadmvl/content_types/templateGuides) */
export type TemplateGuidesAnalyticsModuleArgs = {
  locale?: InputMaybe<Scalars['String']>
  preview?: InputMaybe<Scalars['Boolean']>
  where?: InputMaybe<SettingsAnalyticsModuleFilter>
}

/** Create and manage all Guides landing page content. [See type definition](https://app.contentful.com/spaces/9cxgygtadmvl/content_types/templateGuides) */
export type TemplateGuidesLearnMoreBlockArgs = {
  locale?: InputMaybe<Scalars['String']>
  preview?: InputMaybe<Scalars['Boolean']>
  where?: InputMaybe<ComponentThreeIconSectionFilter>
}

/** Create and manage all Guides landing page content. [See type definition](https://app.contentful.com/spaces/9cxgygtadmvl/content_types/templateGuides) */
export type TemplateGuidesLinkedFromArgs = {
  allowedLocales?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
}

/** Create and manage all Guides landing page content. [See type definition](https://app.contentful.com/spaces/9cxgygtadmvl/content_types/templateGuides) */
export type TemplateGuidesMetaContentArgs = {
  locale?: InputMaybe<Scalars['String']>
  preview?: InputMaybe<Scalars['Boolean']>
  where?: InputMaybe<PagesMetaContentFilter>
}

/** Create and manage all Guides landing page content. [See type definition](https://app.contentful.com/spaces/9cxgygtadmvl/content_types/templateGuides) */
export type TemplateGuidesQuestionGroupArgs = {
  locale?: InputMaybe<Scalars['String']>
  preview?: InputMaybe<Scalars['Boolean']>
  where?: InputMaybe<FaqsQuestionGroupsFilter>
}

/** Create and manage all Guides landing page content. [See type definition](https://app.contentful.com/spaces/9cxgygtadmvl/content_types/templateGuides) */
export type TemplateGuidesTeaserCopyArgs = {
  locale?: InputMaybe<Scalars['String']>
}

/** Create and manage all Guides landing page content. [See type definition](https://app.contentful.com/spaces/9cxgygtadmvl/content_types/templateGuides) */
export type TemplateGuidesTitleArgs = {
  locale?: InputMaybe<Scalars['String']>
}

export type TemplateGuidesCollection = {
  __typename?: 'TemplateGuidesCollection'
  items: Array<Maybe<TemplateGuides>>
  limit: Scalars['Int']
  skip: Scalars['Int']
  total: Scalars['Int']
}

export type TemplateGuidesFilter = {
  AND?: InputMaybe<Array<InputMaybe<TemplateGuidesFilter>>>
  OR?: InputMaybe<Array<InputMaybe<TemplateGuidesFilter>>>
  analyticsModule?: InputMaybe<CfSettingsAnalyticsModuleNestedFilter>
  analyticsModule_exists?: InputMaybe<Scalars['Boolean']>
  contentfulMetadata?: InputMaybe<ContentfulMetadataFilter>
  learnMoreBlock?: InputMaybe<CfComponentThreeIconSectionNestedFilter>
  learnMoreBlock_exists?: InputMaybe<Scalars['Boolean']>
  metaContent?: InputMaybe<CfPagesMetaContentNestedFilter>
  metaContent_exists?: InputMaybe<Scalars['Boolean']>
  questionGroup?: InputMaybe<CfFaqsQuestionGroupsNestedFilter>
  questionGroup_exists?: InputMaybe<Scalars['Boolean']>
  sys?: InputMaybe<SysFilter>
  teaserCopy_contains?: InputMaybe<Scalars['String']>
  teaserCopy_exists?: InputMaybe<Scalars['Boolean']>
  teaserCopy_not_contains?: InputMaybe<Scalars['String']>
  title?: InputMaybe<Scalars['String']>
  title_contains?: InputMaybe<Scalars['String']>
  title_exists?: InputMaybe<Scalars['Boolean']>
  title_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  title_not?: InputMaybe<Scalars['String']>
  title_not_contains?: InputMaybe<Scalars['String']>
  title_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
}

export type TemplateGuidesLinkingCollections = {
  __typename?: 'TemplateGuidesLinkingCollections'
  entryCollection?: Maybe<EntryCollection>
}

export type TemplateGuidesLinkingCollectionsEntryCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>
  locale?: InputMaybe<Scalars['String']>
  preview?: InputMaybe<Scalars['Boolean']>
  skip?: InputMaybe<Scalars['Int']>
}

export enum TemplateGuidesOrder {
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC',
}

export type TemplateGuidesTeaserCopy = {
  __typename?: 'TemplateGuidesTeaserCopy'
  json: Scalars['JSON']
  links: TemplateGuidesTeaserCopyLinks
}

export type TemplateGuidesTeaserCopyAssets = {
  __typename?: 'TemplateGuidesTeaserCopyAssets'
  block: Array<Maybe<Asset>>
  hyperlink: Array<Maybe<Asset>>
}

export type TemplateGuidesTeaserCopyEntries = {
  __typename?: 'TemplateGuidesTeaserCopyEntries'
  block: Array<Maybe<Entry>>
  hyperlink: Array<Maybe<Entry>>
  inline: Array<Maybe<Entry>>
}

export type TemplateGuidesTeaserCopyLinks = {
  __typename?: 'TemplateGuidesTeaserCopyLinks'
  assets: TemplateGuidesTeaserCopyAssets
  entries: TemplateGuidesTeaserCopyEntries
  resources: TemplateGuidesTeaserCopyResources
}

export type TemplateGuidesTeaserCopyResources = {
  __typename?: 'TemplateGuidesTeaserCopyResources'
  block: Array<TemplateGuidesTeaserCopyResourcesBlock>
  hyperlink: Array<TemplateGuidesTeaserCopyResourcesHyperlink>
  inline: Array<TemplateGuidesTeaserCopyResourcesInline>
}

export type TemplateGuidesTeaserCopyResourcesBlock = ResourceLink & {
  __typename?: 'TemplateGuidesTeaserCopyResourcesBlock'
  sys: ResourceSys
}

export type TemplateGuidesTeaserCopyResourcesHyperlink = ResourceLink & {
  __typename?: 'TemplateGuidesTeaserCopyResourcesHyperlink'
  sys: ResourceSys
}

export type TemplateGuidesTeaserCopyResourcesInline = ResourceLink & {
  __typename?: 'TemplateGuidesTeaserCopyResourcesInline'
  sys: ResourceSys
}

/** Create and manage all Home page content. [See type definition](https://app.contentful.com/spaces/9cxgygtadmvl/content_types/templateHome) */
export type TemplateHome = Entry &
  _Node & {
    __typename?: 'TemplateHome'
    _id: Scalars['ID']
    advisorsHeaderBlock?: Maybe<PagesHeaderBlock>
    analyticsModule?: Maybe<SettingsAnalyticsModule>
    collapseHeader?: Maybe<Scalars['Boolean']>
    contentfulMetadata: ContentfulMetadata
    ctaOne?: Maybe<PagesTwoColumnCta>
    ctaTwo?: Maybe<PagesTwoColumnCta>
    heroBlock?: Maybe<PagesHeroBlock>
    heroBlocksCollection?: Maybe<TemplateHomeHeroBlocksCollection>
    itinerariesHeaderBlock?: Maybe<PagesHeaderBlock>
    learnMoreBlock?: Maybe<ComponentThreeIconSection>
    linkedFrom?: Maybe<TemplateHomeLinkingCollections>
    metaContent?: Maybe<PagesMetaContent>
    pageComponentsCollection?: Maybe<TemplateHomePageComponentsCollection>
    partnerBanner?: Maybe<PagesPartnerBanner>
    pressBannerBlock?: Maybe<PagesPressBanner>
    sys: Sys
    title?: Maybe<Scalars['String']>
  }

/** Create and manage all Home page content. [See type definition](https://app.contentful.com/spaces/9cxgygtadmvl/content_types/templateHome) */
export type TemplateHomeAdvisorsHeaderBlockArgs = {
  locale?: InputMaybe<Scalars['String']>
  preview?: InputMaybe<Scalars['Boolean']>
  where?: InputMaybe<PagesHeaderBlockFilter>
}

/** Create and manage all Home page content. [See type definition](https://app.contentful.com/spaces/9cxgygtadmvl/content_types/templateHome) */
export type TemplateHomeAnalyticsModuleArgs = {
  locale?: InputMaybe<Scalars['String']>
  preview?: InputMaybe<Scalars['Boolean']>
  where?: InputMaybe<SettingsAnalyticsModuleFilter>
}

/** Create and manage all Home page content. [See type definition](https://app.contentful.com/spaces/9cxgygtadmvl/content_types/templateHome) */
export type TemplateHomeCollapseHeaderArgs = {
  locale?: InputMaybe<Scalars['String']>
}

/** Create and manage all Home page content. [See type definition](https://app.contentful.com/spaces/9cxgygtadmvl/content_types/templateHome) */
export type TemplateHomeCtaOneArgs = {
  locale?: InputMaybe<Scalars['String']>
  preview?: InputMaybe<Scalars['Boolean']>
  where?: InputMaybe<PagesTwoColumnCtaFilter>
}

/** Create and manage all Home page content. [See type definition](https://app.contentful.com/spaces/9cxgygtadmvl/content_types/templateHome) */
export type TemplateHomeCtaTwoArgs = {
  locale?: InputMaybe<Scalars['String']>
  preview?: InputMaybe<Scalars['Boolean']>
  where?: InputMaybe<PagesTwoColumnCtaFilter>
}

/** Create and manage all Home page content. [See type definition](https://app.contentful.com/spaces/9cxgygtadmvl/content_types/templateHome) */
export type TemplateHomeHeroBlockArgs = {
  locale?: InputMaybe<Scalars['String']>
  preview?: InputMaybe<Scalars['Boolean']>
  where?: InputMaybe<PagesHeroBlockFilter>
}

/** Create and manage all Home page content. [See type definition](https://app.contentful.com/spaces/9cxgygtadmvl/content_types/templateHome) */
export type TemplateHomeHeroBlocksCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>
  locale?: InputMaybe<Scalars['String']>
  order?: InputMaybe<Array<InputMaybe<TemplateHomeHeroBlocksCollectionOrder>>>
  preview?: InputMaybe<Scalars['Boolean']>
  skip?: InputMaybe<Scalars['Int']>
  where?: InputMaybe<PagesHeroBlockFilter>
}

/** Create and manage all Home page content. [See type definition](https://app.contentful.com/spaces/9cxgygtadmvl/content_types/templateHome) */
export type TemplateHomeItinerariesHeaderBlockArgs = {
  locale?: InputMaybe<Scalars['String']>
  preview?: InputMaybe<Scalars['Boolean']>
  where?: InputMaybe<PagesHeaderBlockFilter>
}

/** Create and manage all Home page content. [See type definition](https://app.contentful.com/spaces/9cxgygtadmvl/content_types/templateHome) */
export type TemplateHomeLearnMoreBlockArgs = {
  locale?: InputMaybe<Scalars['String']>
  preview?: InputMaybe<Scalars['Boolean']>
  where?: InputMaybe<ComponentThreeIconSectionFilter>
}

/** Create and manage all Home page content. [See type definition](https://app.contentful.com/spaces/9cxgygtadmvl/content_types/templateHome) */
export type TemplateHomeLinkedFromArgs = {
  allowedLocales?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
}

/** Create and manage all Home page content. [See type definition](https://app.contentful.com/spaces/9cxgygtadmvl/content_types/templateHome) */
export type TemplateHomeMetaContentArgs = {
  locale?: InputMaybe<Scalars['String']>
  preview?: InputMaybe<Scalars['Boolean']>
  where?: InputMaybe<PagesMetaContentFilter>
}

/** Create and manage all Home page content. [See type definition](https://app.contentful.com/spaces/9cxgygtadmvl/content_types/templateHome) */
export type TemplateHomePageComponentsCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>
  locale?: InputMaybe<Scalars['String']>
  preview?: InputMaybe<Scalars['Boolean']>
  skip?: InputMaybe<Scalars['Int']>
}

/** Create and manage all Home page content. [See type definition](https://app.contentful.com/spaces/9cxgygtadmvl/content_types/templateHome) */
export type TemplateHomePartnerBannerArgs = {
  locale?: InputMaybe<Scalars['String']>
  preview?: InputMaybe<Scalars['Boolean']>
  where?: InputMaybe<PagesPartnerBannerFilter>
}

/** Create and manage all Home page content. [See type definition](https://app.contentful.com/spaces/9cxgygtadmvl/content_types/templateHome) */
export type TemplateHomePressBannerBlockArgs = {
  locale?: InputMaybe<Scalars['String']>
  preview?: InputMaybe<Scalars['Boolean']>
  where?: InputMaybe<PagesPressBannerFilter>
}

/** Create and manage all Home page content. [See type definition](https://app.contentful.com/spaces/9cxgygtadmvl/content_types/templateHome) */
export type TemplateHomeTitleArgs = {
  locale?: InputMaybe<Scalars['String']>
}

export type TemplateHomeCollection = {
  __typename?: 'TemplateHomeCollection'
  items: Array<Maybe<TemplateHome>>
  limit: Scalars['Int']
  skip: Scalars['Int']
  total: Scalars['Int']
}

export type TemplateHomeFilter = {
  AND?: InputMaybe<Array<InputMaybe<TemplateHomeFilter>>>
  OR?: InputMaybe<Array<InputMaybe<TemplateHomeFilter>>>
  advisorsHeaderBlock?: InputMaybe<CfPagesHeaderBlockNestedFilter>
  advisorsHeaderBlock_exists?: InputMaybe<Scalars['Boolean']>
  analyticsModule?: InputMaybe<CfSettingsAnalyticsModuleNestedFilter>
  analyticsModule_exists?: InputMaybe<Scalars['Boolean']>
  collapseHeader?: InputMaybe<Scalars['Boolean']>
  collapseHeader_exists?: InputMaybe<Scalars['Boolean']>
  collapseHeader_not?: InputMaybe<Scalars['Boolean']>
  contentfulMetadata?: InputMaybe<ContentfulMetadataFilter>
  ctaOne?: InputMaybe<CfPagesTwoColumnCtaNestedFilter>
  ctaOne_exists?: InputMaybe<Scalars['Boolean']>
  ctaTwo?: InputMaybe<CfPagesTwoColumnCtaNestedFilter>
  ctaTwo_exists?: InputMaybe<Scalars['Boolean']>
  heroBlock?: InputMaybe<CfPagesHeroBlockNestedFilter>
  heroBlock_exists?: InputMaybe<Scalars['Boolean']>
  heroBlocks?: InputMaybe<CfPagesHeroBlockNestedFilter>
  heroBlocksCollection_exists?: InputMaybe<Scalars['Boolean']>
  itinerariesHeaderBlock?: InputMaybe<CfPagesHeaderBlockNestedFilter>
  itinerariesHeaderBlock_exists?: InputMaybe<Scalars['Boolean']>
  learnMoreBlock?: InputMaybe<CfComponentThreeIconSectionNestedFilter>
  learnMoreBlock_exists?: InputMaybe<Scalars['Boolean']>
  metaContent?: InputMaybe<CfPagesMetaContentNestedFilter>
  metaContent_exists?: InputMaybe<Scalars['Boolean']>
  pageComponentsCollection_exists?: InputMaybe<Scalars['Boolean']>
  partnerBanner?: InputMaybe<CfPagesPartnerBannerNestedFilter>
  partnerBanner_exists?: InputMaybe<Scalars['Boolean']>
  pressBannerBlock?: InputMaybe<CfPagesPressBannerNestedFilter>
  pressBannerBlock_exists?: InputMaybe<Scalars['Boolean']>
  sys?: InputMaybe<SysFilter>
  title?: InputMaybe<Scalars['String']>
  title_contains?: InputMaybe<Scalars['String']>
  title_exists?: InputMaybe<Scalars['Boolean']>
  title_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  title_not?: InputMaybe<Scalars['String']>
  title_not_contains?: InputMaybe<Scalars['String']>
  title_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
}

export type TemplateHomeHeroBlocksCollection = {
  __typename?: 'TemplateHomeHeroBlocksCollection'
  items: Array<Maybe<PagesHeroBlock>>
  limit: Scalars['Int']
  skip: Scalars['Int']
  total: Scalars['Int']
}

export enum TemplateHomeHeroBlocksCollectionOrder {
  PrimaryCtaLinkTextAsc = 'primaryCtaLinkText_ASC',
  PrimaryCtaLinkTextDesc = 'primaryCtaLinkText_DESC',
  PrimaryCtaLinkUrlAsc = 'primaryCtaLinkUrl_ASC',
  PrimaryCtaLinkUrlDesc = 'primaryCtaLinkUrl_DESC',
  PrimaryUrlTargetAsc = 'primaryUrlTarget_ASC',
  PrimaryUrlTargetDesc = 'primaryUrlTarget_DESC',
  SecondaryCtaLinkTextAsc = 'secondaryCtaLinkText_ASC',
  SecondaryCtaLinkTextDesc = 'secondaryCtaLinkText_DESC',
  SecondaryCtaLinkUrlAsc = 'secondaryCtaLinkUrl_ASC',
  SecondaryCtaLinkUrlDesc = 'secondaryCtaLinkUrl_DESC',
  SecondaryUrlTargetAsc = 'secondaryUrlTarget_ASC',
  SecondaryUrlTargetDesc = 'secondaryUrlTarget_DESC',
  ShowFormAsPrimaryCtaAsc = 'showFormAsPrimaryCta_ASC',
  ShowFormAsPrimaryCtaDesc = 'showFormAsPrimaryCta_DESC',
  ShowSignUpFormAsPrimaryCtaAsc = 'showSignUpFormAsPrimaryCta_ASC',
  ShowSignUpFormAsPrimaryCtaDesc = 'showSignUpFormAsPrimaryCta_DESC',
  SubFormHeaderAsc = 'subFormHeader_ASC',
  SubFormHeaderDesc = 'subFormHeader_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  TitleStyleAsc = 'titleStyle_ASC',
  TitleStyleDesc = 'titleStyle_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC',
}

export type TemplateHomeLinkingCollections = {
  __typename?: 'TemplateHomeLinkingCollections'
  entryCollection?: Maybe<EntryCollection>
}

export type TemplateHomeLinkingCollectionsEntryCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>
  locale?: InputMaybe<Scalars['String']>
  preview?: InputMaybe<Scalars['Boolean']>
  skip?: InputMaybe<Scalars['Int']>
}

export enum TemplateHomeOrder {
  CollapseHeaderAsc = 'collapseHeader_ASC',
  CollapseHeaderDesc = 'collapseHeader_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC',
}

export type TemplateHomePageComponentsCollection = {
  __typename?: 'TemplateHomePageComponentsCollection'
  items: Array<Maybe<TemplateHomePageComponentsItem>>
  limit: Scalars['Int']
  skip: Scalars['Int']
  total: Scalars['Int']
}

export type TemplateHomePageComponentsItem =
  | ComponentAdvisorPlanTable
  | ComponentAdvisorReviews
  | ComponentAnimatedTiles
  | ComponentButtonCta
  | ComponentCarouselSection
  | ComponentComparisonTable
  | ComponentCtaBanner
  | ComponentDataBanner
  | ComponentFeaturedAdvisors
  | ComponentFeaturedArticles
  | ComponentFeaturedGuides
  | ComponentFeaturedReviews
  | ComponentForaLogo
  | ComponentForm
  | ComponentHeroCentered
  | ComponentHeroFullWidth
  | ComponentImageSideBySide
  | ComponentInfoGrid
  | ComponentPartnerGrid
  | ComponentPictureSectionBanner
  | ComponentReviews
  | ComponentSingleUse
  | ComponentSpacer
  | ComponentStickyHeader
  | ComponentSubNavBar
  | ComponentSubscriptionCard
  | ComponentTeamSection
  | ComponentTestimonialCarousel
  | ComponentTextSection
  | ComponentThreeIconSectionWithPicture
  | ComponentTwoColumnInfographic
  | ComponentTwoColumnTextSection
  | ComponentTypeformEmbed
  | ComponentVideoSection
  | ComponentWhatYouGetModule
  | FaqsQuestionGroups
  | PagesFeaturedHotels
  | PagesHeaderBlock
  | PagesHeroBlock
  | PagesLearnMoreBlock
  | PagesPartnerBanner
  | PagesPerksList
  | PagesPressBanner
  | PagesTwoColumnCta

/** Create and manage all Itineraries landing page content. [See type definition](https://app.contentful.com/spaces/9cxgygtadmvl/content_types/templateItineraries) */
export type TemplateItineraries = Entry &
  _Node & {
    __typename?: 'TemplateItineraries'
    _id: Scalars['ID']
    analyticsModule?: Maybe<SettingsAnalyticsModule>
    contentfulMetadata: ContentfulMetadata
    learnMoreBlock?: Maybe<ComponentThreeIconSection>
    linkedFrom?: Maybe<TemplateItinerariesLinkingCollections>
    metaContent?: Maybe<PagesMetaContent>
    questionGroup?: Maybe<FaqsQuestionGroups>
    sys: Sys
    teaserCopy?: Maybe<TemplateItinerariesTeaserCopy>
    title?: Maybe<Scalars['String']>
  }

/** Create and manage all Itineraries landing page content. [See type definition](https://app.contentful.com/spaces/9cxgygtadmvl/content_types/templateItineraries) */
export type TemplateItinerariesAnalyticsModuleArgs = {
  locale?: InputMaybe<Scalars['String']>
  preview?: InputMaybe<Scalars['Boolean']>
  where?: InputMaybe<SettingsAnalyticsModuleFilter>
}

/** Create and manage all Itineraries landing page content. [See type definition](https://app.contentful.com/spaces/9cxgygtadmvl/content_types/templateItineraries) */
export type TemplateItinerariesLearnMoreBlockArgs = {
  locale?: InputMaybe<Scalars['String']>
  preview?: InputMaybe<Scalars['Boolean']>
  where?: InputMaybe<ComponentThreeIconSectionFilter>
}

/** Create and manage all Itineraries landing page content. [See type definition](https://app.contentful.com/spaces/9cxgygtadmvl/content_types/templateItineraries) */
export type TemplateItinerariesLinkedFromArgs = {
  allowedLocales?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
}

/** Create and manage all Itineraries landing page content. [See type definition](https://app.contentful.com/spaces/9cxgygtadmvl/content_types/templateItineraries) */
export type TemplateItinerariesMetaContentArgs = {
  locale?: InputMaybe<Scalars['String']>
  preview?: InputMaybe<Scalars['Boolean']>
  where?: InputMaybe<PagesMetaContentFilter>
}

/** Create and manage all Itineraries landing page content. [See type definition](https://app.contentful.com/spaces/9cxgygtadmvl/content_types/templateItineraries) */
export type TemplateItinerariesQuestionGroupArgs = {
  locale?: InputMaybe<Scalars['String']>
  preview?: InputMaybe<Scalars['Boolean']>
  where?: InputMaybe<FaqsQuestionGroupsFilter>
}

/** Create and manage all Itineraries landing page content. [See type definition](https://app.contentful.com/spaces/9cxgygtadmvl/content_types/templateItineraries) */
export type TemplateItinerariesTeaserCopyArgs = {
  locale?: InputMaybe<Scalars['String']>
}

/** Create and manage all Itineraries landing page content. [See type definition](https://app.contentful.com/spaces/9cxgygtadmvl/content_types/templateItineraries) */
export type TemplateItinerariesTitleArgs = {
  locale?: InputMaybe<Scalars['String']>
}

export type TemplateItinerariesCollection = {
  __typename?: 'TemplateItinerariesCollection'
  items: Array<Maybe<TemplateItineraries>>
  limit: Scalars['Int']
  skip: Scalars['Int']
  total: Scalars['Int']
}

export type TemplateItinerariesFilter = {
  AND?: InputMaybe<Array<InputMaybe<TemplateItinerariesFilter>>>
  OR?: InputMaybe<Array<InputMaybe<TemplateItinerariesFilter>>>
  analyticsModule?: InputMaybe<CfSettingsAnalyticsModuleNestedFilter>
  analyticsModule_exists?: InputMaybe<Scalars['Boolean']>
  contentfulMetadata?: InputMaybe<ContentfulMetadataFilter>
  learnMoreBlock?: InputMaybe<CfComponentThreeIconSectionNestedFilter>
  learnMoreBlock_exists?: InputMaybe<Scalars['Boolean']>
  metaContent?: InputMaybe<CfPagesMetaContentNestedFilter>
  metaContent_exists?: InputMaybe<Scalars['Boolean']>
  questionGroup?: InputMaybe<CfFaqsQuestionGroupsNestedFilter>
  questionGroup_exists?: InputMaybe<Scalars['Boolean']>
  sys?: InputMaybe<SysFilter>
  teaserCopy_contains?: InputMaybe<Scalars['String']>
  teaserCopy_exists?: InputMaybe<Scalars['Boolean']>
  teaserCopy_not_contains?: InputMaybe<Scalars['String']>
  title?: InputMaybe<Scalars['String']>
  title_contains?: InputMaybe<Scalars['String']>
  title_exists?: InputMaybe<Scalars['Boolean']>
  title_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  title_not?: InputMaybe<Scalars['String']>
  title_not_contains?: InputMaybe<Scalars['String']>
  title_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
}

export type TemplateItinerariesLinkingCollections = {
  __typename?: 'TemplateItinerariesLinkingCollections'
  entryCollection?: Maybe<EntryCollection>
}

export type TemplateItinerariesLinkingCollectionsEntryCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>
  locale?: InputMaybe<Scalars['String']>
  preview?: InputMaybe<Scalars['Boolean']>
  skip?: InputMaybe<Scalars['Int']>
}

export enum TemplateItinerariesOrder {
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC',
}

export type TemplateItinerariesTeaserCopy = {
  __typename?: 'TemplateItinerariesTeaserCopy'
  json: Scalars['JSON']
  links: TemplateItinerariesTeaserCopyLinks
}

export type TemplateItinerariesTeaserCopyAssets = {
  __typename?: 'TemplateItinerariesTeaserCopyAssets'
  block: Array<Maybe<Asset>>
  hyperlink: Array<Maybe<Asset>>
}

export type TemplateItinerariesTeaserCopyEntries = {
  __typename?: 'TemplateItinerariesTeaserCopyEntries'
  block: Array<Maybe<Entry>>
  hyperlink: Array<Maybe<Entry>>
  inline: Array<Maybe<Entry>>
}

export type TemplateItinerariesTeaserCopyLinks = {
  __typename?: 'TemplateItinerariesTeaserCopyLinks'
  assets: TemplateItinerariesTeaserCopyAssets
  entries: TemplateItinerariesTeaserCopyEntries
  resources: TemplateItinerariesTeaserCopyResources
}

export type TemplateItinerariesTeaserCopyResources = {
  __typename?: 'TemplateItinerariesTeaserCopyResources'
  block: Array<TemplateItinerariesTeaserCopyResourcesBlock>
  hyperlink: Array<TemplateItinerariesTeaserCopyResourcesHyperlink>
  inline: Array<TemplateItinerariesTeaserCopyResourcesInline>
}

export type TemplateItinerariesTeaserCopyResourcesBlock = ResourceLink & {
  __typename?: 'TemplateItinerariesTeaserCopyResourcesBlock'
  sys: ResourceSys
}

export type TemplateItinerariesTeaserCopyResourcesHyperlink = ResourceLink & {
  __typename?: 'TemplateItinerariesTeaserCopyResourcesHyperlink'
  sys: ResourceSys
}

export type TemplateItinerariesTeaserCopyResourcesInline = ResourceLink & {
  __typename?: 'TemplateItinerariesTeaserCopyResourcesInline'
  sys: ResourceSys
}

/** Create and manage all Join Us page content. [See type definition](https://app.contentful.com/spaces/9cxgygtadmvl/content_types/templateJoinUs) */
export type TemplateJoinUs = Entry &
  _Node & {
    __typename?: 'TemplateJoinUs'
    _id: Scalars['ID']
    advisorsHeaderBlock?: Maybe<PagesHeaderBlock>
    analyticsModule?: Maybe<SettingsAnalyticsModule>
    collapseHeader?: Maybe<Scalars['Boolean']>
    contentfulMetadata: ContentfulMetadata
    cta?: Maybe<PagesTwoColumnCta>
    headerBlock?: Maybe<PagesHeaderBlock>
    heroBlock?: Maybe<PagesHeroBlock>
    hideGlobalBanner?: Maybe<Scalars['Boolean']>
    linkedFrom?: Maybe<TemplateJoinUsLinkingCollections>
    metaContent?: Maybe<PagesMetaContent>
    pageBlocksCollection?: Maybe<TemplateJoinUsPageBlocksCollection>
    pageComponentsCollection?: Maybe<TemplateJoinUsPageComponentsCollection>
    showHeader?: Maybe<Scalars['Boolean']>
    sys: Sys
    title?: Maybe<Scalars['String']>
  }

/** Create and manage all Join Us page content. [See type definition](https://app.contentful.com/spaces/9cxgygtadmvl/content_types/templateJoinUs) */
export type TemplateJoinUsAdvisorsHeaderBlockArgs = {
  locale?: InputMaybe<Scalars['String']>
  preview?: InputMaybe<Scalars['Boolean']>
  where?: InputMaybe<PagesHeaderBlockFilter>
}

/** Create and manage all Join Us page content. [See type definition](https://app.contentful.com/spaces/9cxgygtadmvl/content_types/templateJoinUs) */
export type TemplateJoinUsAnalyticsModuleArgs = {
  locale?: InputMaybe<Scalars['String']>
  preview?: InputMaybe<Scalars['Boolean']>
  where?: InputMaybe<SettingsAnalyticsModuleFilter>
}

/** Create and manage all Join Us page content. [See type definition](https://app.contentful.com/spaces/9cxgygtadmvl/content_types/templateJoinUs) */
export type TemplateJoinUsCollapseHeaderArgs = {
  locale?: InputMaybe<Scalars['String']>
}

/** Create and manage all Join Us page content. [See type definition](https://app.contentful.com/spaces/9cxgygtadmvl/content_types/templateJoinUs) */
export type TemplateJoinUsCtaArgs = {
  locale?: InputMaybe<Scalars['String']>
  preview?: InputMaybe<Scalars['Boolean']>
  where?: InputMaybe<PagesTwoColumnCtaFilter>
}

/** Create and manage all Join Us page content. [See type definition](https://app.contentful.com/spaces/9cxgygtadmvl/content_types/templateJoinUs) */
export type TemplateJoinUsHeaderBlockArgs = {
  locale?: InputMaybe<Scalars['String']>
  preview?: InputMaybe<Scalars['Boolean']>
  where?: InputMaybe<PagesHeaderBlockFilter>
}

/** Create and manage all Join Us page content. [See type definition](https://app.contentful.com/spaces/9cxgygtadmvl/content_types/templateJoinUs) */
export type TemplateJoinUsHeroBlockArgs = {
  locale?: InputMaybe<Scalars['String']>
  preview?: InputMaybe<Scalars['Boolean']>
  where?: InputMaybe<PagesHeroBlockFilter>
}

/** Create and manage all Join Us page content. [See type definition](https://app.contentful.com/spaces/9cxgygtadmvl/content_types/templateJoinUs) */
export type TemplateJoinUsHideGlobalBannerArgs = {
  locale?: InputMaybe<Scalars['String']>
}

/** Create and manage all Join Us page content. [See type definition](https://app.contentful.com/spaces/9cxgygtadmvl/content_types/templateJoinUs) */
export type TemplateJoinUsLinkedFromArgs = {
  allowedLocales?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
}

/** Create and manage all Join Us page content. [See type definition](https://app.contentful.com/spaces/9cxgygtadmvl/content_types/templateJoinUs) */
export type TemplateJoinUsMetaContentArgs = {
  locale?: InputMaybe<Scalars['String']>
  preview?: InputMaybe<Scalars['Boolean']>
  where?: InputMaybe<PagesMetaContentFilter>
}

/** Create and manage all Join Us page content. [See type definition](https://app.contentful.com/spaces/9cxgygtadmvl/content_types/templateJoinUs) */
export type TemplateJoinUsPageBlocksCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>
  locale?: InputMaybe<Scalars['String']>
  preview?: InputMaybe<Scalars['Boolean']>
  skip?: InputMaybe<Scalars['Int']>
  where?: InputMaybe<TemplateJoinUsPageBlocksFilter>
}

/** Create and manage all Join Us page content. [See type definition](https://app.contentful.com/spaces/9cxgygtadmvl/content_types/templateJoinUs) */
export type TemplateJoinUsPageComponentsCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>
  locale?: InputMaybe<Scalars['String']>
  preview?: InputMaybe<Scalars['Boolean']>
  skip?: InputMaybe<Scalars['Int']>
}

/** Create and manage all Join Us page content. [See type definition](https://app.contentful.com/spaces/9cxgygtadmvl/content_types/templateJoinUs) */
export type TemplateJoinUsShowHeaderArgs = {
  locale?: InputMaybe<Scalars['String']>
}

/** Create and manage all Join Us page content. [See type definition](https://app.contentful.com/spaces/9cxgygtadmvl/content_types/templateJoinUs) */
export type TemplateJoinUsTitleArgs = {
  locale?: InputMaybe<Scalars['String']>
}

export type TemplateJoinUsCollection = {
  __typename?: 'TemplateJoinUsCollection'
  items: Array<Maybe<TemplateJoinUs>>
  limit: Scalars['Int']
  skip: Scalars['Int']
  total: Scalars['Int']
}

export type TemplateJoinUsFilter = {
  AND?: InputMaybe<Array<InputMaybe<TemplateJoinUsFilter>>>
  OR?: InputMaybe<Array<InputMaybe<TemplateJoinUsFilter>>>
  advisorsHeaderBlock?: InputMaybe<CfPagesHeaderBlockNestedFilter>
  advisorsHeaderBlock_exists?: InputMaybe<Scalars['Boolean']>
  analyticsModule?: InputMaybe<CfSettingsAnalyticsModuleNestedFilter>
  analyticsModule_exists?: InputMaybe<Scalars['Boolean']>
  collapseHeader?: InputMaybe<Scalars['Boolean']>
  collapseHeader_exists?: InputMaybe<Scalars['Boolean']>
  collapseHeader_not?: InputMaybe<Scalars['Boolean']>
  contentfulMetadata?: InputMaybe<ContentfulMetadataFilter>
  cta?: InputMaybe<CfPagesTwoColumnCtaNestedFilter>
  cta_exists?: InputMaybe<Scalars['Boolean']>
  headerBlock?: InputMaybe<CfPagesHeaderBlockNestedFilter>
  headerBlock_exists?: InputMaybe<Scalars['Boolean']>
  heroBlock?: InputMaybe<CfPagesHeroBlockNestedFilter>
  heroBlock_exists?: InputMaybe<Scalars['Boolean']>
  hideGlobalBanner?: InputMaybe<Scalars['Boolean']>
  hideGlobalBanner_exists?: InputMaybe<Scalars['Boolean']>
  hideGlobalBanner_not?: InputMaybe<Scalars['Boolean']>
  metaContent?: InputMaybe<CfPagesMetaContentNestedFilter>
  metaContent_exists?: InputMaybe<Scalars['Boolean']>
  pageBlocks?: InputMaybe<CfpageBlocksMultiTypeNestedFilter>
  pageBlocksCollection_exists?: InputMaybe<Scalars['Boolean']>
  pageComponentsCollection_exists?: InputMaybe<Scalars['Boolean']>
  showHeader?: InputMaybe<Scalars['Boolean']>
  showHeader_exists?: InputMaybe<Scalars['Boolean']>
  showHeader_not?: InputMaybe<Scalars['Boolean']>
  sys?: InputMaybe<SysFilter>
  title?: InputMaybe<Scalars['String']>
  title_contains?: InputMaybe<Scalars['String']>
  title_exists?: InputMaybe<Scalars['Boolean']>
  title_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  title_not?: InputMaybe<Scalars['String']>
  title_not_contains?: InputMaybe<Scalars['String']>
  title_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
}

export type TemplateJoinUsLinkingCollections = {
  __typename?: 'TemplateJoinUsLinkingCollections'
  entryCollection?: Maybe<EntryCollection>
}

export type TemplateJoinUsLinkingCollectionsEntryCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>
  locale?: InputMaybe<Scalars['String']>
  preview?: InputMaybe<Scalars['Boolean']>
  skip?: InputMaybe<Scalars['Int']>
}

export enum TemplateJoinUsOrder {
  CollapseHeaderAsc = 'collapseHeader_ASC',
  CollapseHeaderDesc = 'collapseHeader_DESC',
  HideGlobalBannerAsc = 'hideGlobalBanner_ASC',
  HideGlobalBannerDesc = 'hideGlobalBanner_DESC',
  ShowHeaderAsc = 'showHeader_ASC',
  ShowHeaderDesc = 'showHeader_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC',
}

export type TemplateJoinUsPageBlocksCollection = {
  __typename?: 'TemplateJoinUsPageBlocksCollection'
  items: Array<Maybe<TemplateJoinUsPageBlocksItem>>
  limit: Scalars['Int']
  skip: Scalars['Int']
  total: Scalars['Int']
}

export type TemplateJoinUsPageBlocksFilter = {
  AND?: InputMaybe<Array<InputMaybe<TemplateJoinUsPageBlocksFilter>>>
  OR?: InputMaybe<Array<InputMaybe<TemplateJoinUsPageBlocksFilter>>>
  contentfulMetadata?: InputMaybe<ContentfulMetadataFilter>
  sys?: InputMaybe<SysFilter>
}

export type TemplateJoinUsPageBlocksItem =
  | ComponentAnimatedTiles
  | ComponentCarouselSection
  | ComponentCheckeredCallout
  | ComponentComparisonTable
  | ComponentCtaBanner
  | ComponentFeaturedAdvisors
  | ComponentFeaturedArticles
  | ComponentFeaturedGuides
  | ComponentForm
  | ComponentHeroCentered
  | ComponentHeroFullWidth
  | ComponentImageSideBySide
  | ComponentPartnerGrid
  | ComponentPictureSectionBanner
  | ComponentSpacer
  | ComponentSubNavBar
  | ComponentSubscriptionCard
  | ComponentTeamSection
  | ComponentTestimonialCarousel
  | ComponentTextSection
  | ComponentThreeIconSection
  | ComponentTwoColumnCallout
  | ComponentTwoColumnPerksSection
  | ComponentTwoColumnTextSection
  | ComponentVideoSection
  | FaqsQuestionGroups
  | PagesFeaturedHotels
  | PagesHeaderBlock
  | PagesHeroBlock
  | PagesPartnerBanner
  | PagesPerksList
  | PagesPressBanner
  | PagesTwoColumnCta

export type TemplateJoinUsPageComponentsCollection = {
  __typename?: 'TemplateJoinUsPageComponentsCollection'
  items: Array<Maybe<TemplateJoinUsPageComponentsItem>>
  limit: Scalars['Int']
  skip: Scalars['Int']
  total: Scalars['Int']
}

export type TemplateJoinUsPageComponentsItem =
  | ComponentAdvisorPlanTable
  | ComponentAdvisorReviews
  | ComponentAnimatedTiles
  | ComponentButtonCta
  | ComponentCarouselSection
  | ComponentComparisonTable
  | ComponentCtaBanner
  | ComponentDataBanner
  | ComponentFeaturedAdvisors
  | ComponentFeaturedArticles
  | ComponentFeaturedGuides
  | ComponentFeaturedReviews
  | ComponentForaLogo
  | ComponentForm
  | ComponentHeroCentered
  | ComponentHeroFullWidth
  | ComponentImageSideBySide
  | ComponentInfoGrid
  | ComponentPartnerGrid
  | ComponentPictureSectionBanner
  | ComponentReviews
  | ComponentSingleUse
  | ComponentSpacer
  | ComponentStickyHeader
  | ComponentSubNavBar
  | ComponentSubscriptionCard
  | ComponentTeamSection
  | ComponentTestimonialCarousel
  | ComponentTextSection
  | ComponentThreeIconSectionWithPicture
  | ComponentTwoColumnInfographic
  | ComponentTwoColumnTextSection
  | ComponentTypeformEmbed
  | ComponentVideoSection
  | ComponentWhatYouGetModule
  | FaqsQuestionGroups
  | PagesFeaturedHotels
  | PagesHeaderBlock
  | PagesHeroBlock
  | PagesLearnMoreBlock
  | PagesPartnerBanner
  | PagesPerksList
  | PagesPressBanner
  | PagesTwoColumnCta

/** [See type definition](https://app.contentful.com/spaces/9cxgygtadmvl/content_types/templateNewsletter) */
export type TemplateNewsletter = Entry &
  _Node & {
    __typename?: 'TemplateNewsletter'
    _id: Scalars['ID']
    analyticsModule?: Maybe<SettingsAnalyticsModule>
    collapseHeader?: Maybe<Scalars['Boolean']>
    contentfulMetadata: ContentfulMetadata
    linkedFrom?: Maybe<TemplateNewsletterLinkingCollections>
    metaContent?: Maybe<PagesMetaContent>
    pageComponentsCollection?: Maybe<TemplateNewsletterPageComponentsCollection>
    showHeader?: Maybe<Scalars['Boolean']>
    sys: Sys
    title?: Maybe<Scalars['String']>
  }

/** [See type definition](https://app.contentful.com/spaces/9cxgygtadmvl/content_types/templateNewsletter) */
export type TemplateNewsletterAnalyticsModuleArgs = {
  locale?: InputMaybe<Scalars['String']>
  preview?: InputMaybe<Scalars['Boolean']>
  where?: InputMaybe<SettingsAnalyticsModuleFilter>
}

/** [See type definition](https://app.contentful.com/spaces/9cxgygtadmvl/content_types/templateNewsletter) */
export type TemplateNewsletterCollapseHeaderArgs = {
  locale?: InputMaybe<Scalars['String']>
}

/** [See type definition](https://app.contentful.com/spaces/9cxgygtadmvl/content_types/templateNewsletter) */
export type TemplateNewsletterLinkedFromArgs = {
  allowedLocales?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
}

/** [See type definition](https://app.contentful.com/spaces/9cxgygtadmvl/content_types/templateNewsletter) */
export type TemplateNewsletterMetaContentArgs = {
  locale?: InputMaybe<Scalars['String']>
  preview?: InputMaybe<Scalars['Boolean']>
  where?: InputMaybe<PagesMetaContentFilter>
}

/** [See type definition](https://app.contentful.com/spaces/9cxgygtadmvl/content_types/templateNewsletter) */
export type TemplateNewsletterPageComponentsCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>
  locale?: InputMaybe<Scalars['String']>
  preview?: InputMaybe<Scalars['Boolean']>
  skip?: InputMaybe<Scalars['Int']>
}

/** [See type definition](https://app.contentful.com/spaces/9cxgygtadmvl/content_types/templateNewsletter) */
export type TemplateNewsletterShowHeaderArgs = {
  locale?: InputMaybe<Scalars['String']>
}

/** [See type definition](https://app.contentful.com/spaces/9cxgygtadmvl/content_types/templateNewsletter) */
export type TemplateNewsletterTitleArgs = {
  locale?: InputMaybe<Scalars['String']>
}

export type TemplateNewsletterCollection = {
  __typename?: 'TemplateNewsletterCollection'
  items: Array<Maybe<TemplateNewsletter>>
  limit: Scalars['Int']
  skip: Scalars['Int']
  total: Scalars['Int']
}

export type TemplateNewsletterFilter = {
  AND?: InputMaybe<Array<InputMaybe<TemplateNewsletterFilter>>>
  OR?: InputMaybe<Array<InputMaybe<TemplateNewsletterFilter>>>
  analyticsModule?: InputMaybe<CfSettingsAnalyticsModuleNestedFilter>
  analyticsModule_exists?: InputMaybe<Scalars['Boolean']>
  collapseHeader?: InputMaybe<Scalars['Boolean']>
  collapseHeader_exists?: InputMaybe<Scalars['Boolean']>
  collapseHeader_not?: InputMaybe<Scalars['Boolean']>
  contentfulMetadata?: InputMaybe<ContentfulMetadataFilter>
  metaContent?: InputMaybe<CfPagesMetaContentNestedFilter>
  metaContent_exists?: InputMaybe<Scalars['Boolean']>
  pageComponentsCollection_exists?: InputMaybe<Scalars['Boolean']>
  showHeader?: InputMaybe<Scalars['Boolean']>
  showHeader_exists?: InputMaybe<Scalars['Boolean']>
  showHeader_not?: InputMaybe<Scalars['Boolean']>
  sys?: InputMaybe<SysFilter>
  title?: InputMaybe<Scalars['String']>
  title_contains?: InputMaybe<Scalars['String']>
  title_exists?: InputMaybe<Scalars['Boolean']>
  title_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  title_not?: InputMaybe<Scalars['String']>
  title_not_contains?: InputMaybe<Scalars['String']>
  title_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
}

export type TemplateNewsletterLinkingCollections = {
  __typename?: 'TemplateNewsletterLinkingCollections'
  entryCollection?: Maybe<EntryCollection>
}

export type TemplateNewsletterLinkingCollectionsEntryCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>
  locale?: InputMaybe<Scalars['String']>
  preview?: InputMaybe<Scalars['Boolean']>
  skip?: InputMaybe<Scalars['Int']>
}

export enum TemplateNewsletterOrder {
  CollapseHeaderAsc = 'collapseHeader_ASC',
  CollapseHeaderDesc = 'collapseHeader_DESC',
  ShowHeaderAsc = 'showHeader_ASC',
  ShowHeaderDesc = 'showHeader_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC',
}

export type TemplateNewsletterPageComponentsCollection = {
  __typename?: 'TemplateNewsletterPageComponentsCollection'
  items: Array<Maybe<TemplateNewsletterPageComponentsItem>>
  limit: Scalars['Int']
  skip: Scalars['Int']
  total: Scalars['Int']
}

export type TemplateNewsletterPageComponentsItem =
  | ComponentAdvisorPlanTable
  | ComponentAdvisorReviews
  | ComponentAnimatedTiles
  | ComponentButtonCta
  | ComponentCarouselSection
  | ComponentComparisonTable
  | ComponentCtaBanner
  | ComponentDataBanner
  | ComponentFeaturedAdvisors
  | ComponentFeaturedArticles
  | ComponentFeaturedGuides
  | ComponentFeaturedReviews
  | ComponentForaLogo
  | ComponentForm
  | ComponentHeroCentered
  | ComponentHeroFullWidth
  | ComponentImageSideBySide
  | ComponentInfoGrid
  | ComponentPartnerGrid
  | ComponentPictureSectionBanner
  | ComponentReviews
  | ComponentSingleUse
  | ComponentSpacer
  | ComponentStickyHeader
  | ComponentSubNavBar
  | ComponentSubscriptionCard
  | ComponentTeamSection
  | ComponentTestimonialCarousel
  | ComponentTextSection
  | ComponentThreeIconSectionWithPicture
  | ComponentTwoColumnInfographic
  | ComponentTwoColumnTextSection
  | ComponentTypeformEmbed
  | ComponentVideoSection
  | ComponentWhatYouGetModule
  | FaqsQuestionGroups
  | PagesFeaturedHotels
  | PagesHeaderBlock
  | PagesHeroBlock
  | PagesLearnMoreBlock
  | PagesPartnerBanner
  | PagesPerksList
  | PagesPressBanner
  | PagesTwoColumnCta

/** A content landing page on the website. [See type definition](https://app.contentful.com/spaces/9cxgygtadmvl/content_types/templatePage) */
export type TemplatePage = Entry &
  _Node & {
    __typename?: 'TemplatePage'
    _id: Scalars['ID']
    analyticsModule?: Maybe<SettingsAnalyticsModule>
    contentfulMetadata: ContentfulMetadata
    directory?: Maybe<Scalars['String']>
    internalLabel?: Maybe<Scalars['String']>
    linkedFrom?: Maybe<TemplatePageLinkingCollections>
    metaDescription?: Maybe<Scalars['String']>
    metaImage?: Maybe<Asset>
    metaTitle?: Maybe<Scalars['String']>
    noindexNofollow?: Maybe<Scalars['Boolean']>
    pageBlocksCollection?: Maybe<TemplatePagePageBlocksCollection>
    slug?: Maybe<Scalars['String']>
    sys: Sys
  }

/** A content landing page on the website. [See type definition](https://app.contentful.com/spaces/9cxgygtadmvl/content_types/templatePage) */
export type TemplatePageAnalyticsModuleArgs = {
  locale?: InputMaybe<Scalars['String']>
  preview?: InputMaybe<Scalars['Boolean']>
  where?: InputMaybe<SettingsAnalyticsModuleFilter>
}

/** A content landing page on the website. [See type definition](https://app.contentful.com/spaces/9cxgygtadmvl/content_types/templatePage) */
export type TemplatePageDirectoryArgs = {
  locale?: InputMaybe<Scalars['String']>
}

/** A content landing page on the website. [See type definition](https://app.contentful.com/spaces/9cxgygtadmvl/content_types/templatePage) */
export type TemplatePageInternalLabelArgs = {
  locale?: InputMaybe<Scalars['String']>
}

/** A content landing page on the website. [See type definition](https://app.contentful.com/spaces/9cxgygtadmvl/content_types/templatePage) */
export type TemplatePageLinkedFromArgs = {
  allowedLocales?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
}

/** A content landing page on the website. [See type definition](https://app.contentful.com/spaces/9cxgygtadmvl/content_types/templatePage) */
export type TemplatePageMetaDescriptionArgs = {
  locale?: InputMaybe<Scalars['String']>
}

/** A content landing page on the website. [See type definition](https://app.contentful.com/spaces/9cxgygtadmvl/content_types/templatePage) */
export type TemplatePageMetaImageArgs = {
  locale?: InputMaybe<Scalars['String']>
  preview?: InputMaybe<Scalars['Boolean']>
}

/** A content landing page on the website. [See type definition](https://app.contentful.com/spaces/9cxgygtadmvl/content_types/templatePage) */
export type TemplatePageMetaTitleArgs = {
  locale?: InputMaybe<Scalars['String']>
}

/** A content landing page on the website. [See type definition](https://app.contentful.com/spaces/9cxgygtadmvl/content_types/templatePage) */
export type TemplatePageNoindexNofollowArgs = {
  locale?: InputMaybe<Scalars['String']>
}

/** A content landing page on the website. [See type definition](https://app.contentful.com/spaces/9cxgygtadmvl/content_types/templatePage) */
export type TemplatePagePageBlocksCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>
  locale?: InputMaybe<Scalars['String']>
  preview?: InputMaybe<Scalars['Boolean']>
  skip?: InputMaybe<Scalars['Int']>
  where?: InputMaybe<TemplatePagePageBlocksFilter>
}

/** A content landing page on the website. [See type definition](https://app.contentful.com/spaces/9cxgygtadmvl/content_types/templatePage) */
export type TemplatePageSlugArgs = {
  locale?: InputMaybe<Scalars['String']>
}

export type TemplatePageCollection = {
  __typename?: 'TemplatePageCollection'
  items: Array<Maybe<TemplatePage>>
  limit: Scalars['Int']
  skip: Scalars['Int']
  total: Scalars['Int']
}

export type TemplatePageFilter = {
  AND?: InputMaybe<Array<InputMaybe<TemplatePageFilter>>>
  OR?: InputMaybe<Array<InputMaybe<TemplatePageFilter>>>
  analyticsModule?: InputMaybe<CfSettingsAnalyticsModuleNestedFilter>
  analyticsModule_exists?: InputMaybe<Scalars['Boolean']>
  contentfulMetadata?: InputMaybe<ContentfulMetadataFilter>
  directory?: InputMaybe<Scalars['String']>
  directory_contains?: InputMaybe<Scalars['String']>
  directory_exists?: InputMaybe<Scalars['Boolean']>
  directory_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  directory_not?: InputMaybe<Scalars['String']>
  directory_not_contains?: InputMaybe<Scalars['String']>
  directory_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  internalLabel?: InputMaybe<Scalars['String']>
  internalLabel_contains?: InputMaybe<Scalars['String']>
  internalLabel_exists?: InputMaybe<Scalars['Boolean']>
  internalLabel_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  internalLabel_not?: InputMaybe<Scalars['String']>
  internalLabel_not_contains?: InputMaybe<Scalars['String']>
  internalLabel_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  metaDescription?: InputMaybe<Scalars['String']>
  metaDescription_contains?: InputMaybe<Scalars['String']>
  metaDescription_exists?: InputMaybe<Scalars['Boolean']>
  metaDescription_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  metaDescription_not?: InputMaybe<Scalars['String']>
  metaDescription_not_contains?: InputMaybe<Scalars['String']>
  metaDescription_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  metaImage_exists?: InputMaybe<Scalars['Boolean']>
  metaTitle?: InputMaybe<Scalars['String']>
  metaTitle_contains?: InputMaybe<Scalars['String']>
  metaTitle_exists?: InputMaybe<Scalars['Boolean']>
  metaTitle_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  metaTitle_not?: InputMaybe<Scalars['String']>
  metaTitle_not_contains?: InputMaybe<Scalars['String']>
  metaTitle_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  noindexNofollow?: InputMaybe<Scalars['Boolean']>
  noindexNofollow_exists?: InputMaybe<Scalars['Boolean']>
  noindexNofollow_not?: InputMaybe<Scalars['Boolean']>
  pageBlocks?: InputMaybe<CfpageBlocksMultiTypeNestedFilter>
  pageBlocksCollection_exists?: InputMaybe<Scalars['Boolean']>
  slug?: InputMaybe<Scalars['String']>
  slug_contains?: InputMaybe<Scalars['String']>
  slug_exists?: InputMaybe<Scalars['Boolean']>
  slug_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  slug_not?: InputMaybe<Scalars['String']>
  slug_not_contains?: InputMaybe<Scalars['String']>
  slug_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  sys?: InputMaybe<SysFilter>
}

export type TemplatePageLinkingCollections = {
  __typename?: 'TemplatePageLinkingCollections'
  entryCollection?: Maybe<EntryCollection>
}

export type TemplatePageLinkingCollectionsEntryCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>
  locale?: InputMaybe<Scalars['String']>
  preview?: InputMaybe<Scalars['Boolean']>
  skip?: InputMaybe<Scalars['Int']>
}

export enum TemplatePageOrder {
  DirectoryAsc = 'directory_ASC',
  DirectoryDesc = 'directory_DESC',
  InternalLabelAsc = 'internalLabel_ASC',
  InternalLabelDesc = 'internalLabel_DESC',
  MetaTitleAsc = 'metaTitle_ASC',
  MetaTitleDesc = 'metaTitle_DESC',
  NoindexNofollowAsc = 'noindexNofollow_ASC',
  NoindexNofollowDesc = 'noindexNofollow_DESC',
  SlugAsc = 'slug_ASC',
  SlugDesc = 'slug_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
}

export type TemplatePagePageBlocksCollection = {
  __typename?: 'TemplatePagePageBlocksCollection'
  items: Array<Maybe<TemplatePagePageBlocksItem>>
  limit: Scalars['Int']
  skip: Scalars['Int']
  total: Scalars['Int']
}

export type TemplatePagePageBlocksFilter = {
  AND?: InputMaybe<Array<InputMaybe<TemplatePagePageBlocksFilter>>>
  OR?: InputMaybe<Array<InputMaybe<TemplatePagePageBlocksFilter>>>
  contentfulMetadata?: InputMaybe<ContentfulMetadataFilter>
  sys?: InputMaybe<SysFilter>
}

export type TemplatePagePageBlocksItem =
  | ComponentAnimatedTiles
  | ComponentButtonCta
  | ComponentCarouselSection
  | ComponentCheckeredCallout
  | ComponentComparisonTable
  | ComponentCtaBanner
  | ComponentFeaturedAdvisors
  | ComponentFeaturedArticles
  | ComponentFeaturedGuides
  | ComponentForm
  | ComponentHeroCentered
  | ComponentHeroFullWidth
  | ComponentImageSideBySide
  | ComponentPartnerGrid
  | ComponentPictureSectionBanner
  | ComponentSpacer
  | ComponentSubNavBar
  | ComponentSubscriptionCard
  | ComponentTeamSection
  | ComponentTestimonialCarousel
  | ComponentTextSection
  | ComponentTwoColumnCallout
  | ComponentTwoColumnPerksSection
  | ComponentTwoColumnTextSection
  | ComponentVideoSection
  | FaqsQuestionGroups
  | PagesFeaturedHotels
  | PagesHeaderBlock
  | PagesHeroBlock
  | PagesLearnMoreBlock
  | PagesPartnerBanner
  | PagesPerksList
  | PagesPressBanner
  | PagesTwoColumnCta

/** Model for the Partner Detail Page. [See type definition](https://app.contentful.com/spaces/9cxgygtadmvl/content_types/templatePartnerDetail) */
export type TemplatePartnerDetail = Entry &
  _Node & {
    __typename?: 'TemplatePartnerDetail'
    _id: Scalars['ID']
    analyticsModule?: Maybe<SettingsAnalyticsModule>
    benefits?: Maybe<PagesHeaderBlock>
    contentfulMetadata: ContentfulMetadata
    faq?: Maybe<TemplateFaqs>
    featuredAdvisors?: Maybe<Entry>
    featuredProperties?: Maybe<ComponentCarouselSection>
    heroBanner?: Maybe<PagesHeroBlock>
    learnMore?: Maybe<PagesLearnMoreBlock>
    linkedFrom?: Maybe<TemplatePartnerDetailLinkingCollections>
    secondLearnMore?: Maybe<PagesLearnMoreBlock>
    sys: Sys
    twoColumnCallout?: Maybe<PagesTwoColumnCta>
  }

/** Model for the Partner Detail Page. [See type definition](https://app.contentful.com/spaces/9cxgygtadmvl/content_types/templatePartnerDetail) */
export type TemplatePartnerDetailAnalyticsModuleArgs = {
  locale?: InputMaybe<Scalars['String']>
  preview?: InputMaybe<Scalars['Boolean']>
  where?: InputMaybe<SettingsAnalyticsModuleFilter>
}

/** Model for the Partner Detail Page. [See type definition](https://app.contentful.com/spaces/9cxgygtadmvl/content_types/templatePartnerDetail) */
export type TemplatePartnerDetailBenefitsArgs = {
  locale?: InputMaybe<Scalars['String']>
  preview?: InputMaybe<Scalars['Boolean']>
  where?: InputMaybe<PagesHeaderBlockFilter>
}

/** Model for the Partner Detail Page. [See type definition](https://app.contentful.com/spaces/9cxgygtadmvl/content_types/templatePartnerDetail) */
export type TemplatePartnerDetailFaqArgs = {
  locale?: InputMaybe<Scalars['String']>
  preview?: InputMaybe<Scalars['Boolean']>
  where?: InputMaybe<TemplateFaqsFilter>
}

/** Model for the Partner Detail Page. [See type definition](https://app.contentful.com/spaces/9cxgygtadmvl/content_types/templatePartnerDetail) */
export type TemplatePartnerDetailFeaturedAdvisorsArgs = {
  locale?: InputMaybe<Scalars['String']>
  preview?: InputMaybe<Scalars['Boolean']>
}

/** Model for the Partner Detail Page. [See type definition](https://app.contentful.com/spaces/9cxgygtadmvl/content_types/templatePartnerDetail) */
export type TemplatePartnerDetailFeaturedPropertiesArgs = {
  locale?: InputMaybe<Scalars['String']>
  preview?: InputMaybe<Scalars['Boolean']>
  where?: InputMaybe<ComponentCarouselSectionFilter>
}

/** Model for the Partner Detail Page. [See type definition](https://app.contentful.com/spaces/9cxgygtadmvl/content_types/templatePartnerDetail) */
export type TemplatePartnerDetailHeroBannerArgs = {
  locale?: InputMaybe<Scalars['String']>
  preview?: InputMaybe<Scalars['Boolean']>
  where?: InputMaybe<PagesHeroBlockFilter>
}

/** Model for the Partner Detail Page. [See type definition](https://app.contentful.com/spaces/9cxgygtadmvl/content_types/templatePartnerDetail) */
export type TemplatePartnerDetailLearnMoreArgs = {
  locale?: InputMaybe<Scalars['String']>
  preview?: InputMaybe<Scalars['Boolean']>
  where?: InputMaybe<PagesLearnMoreBlockFilter>
}

/** Model for the Partner Detail Page. [See type definition](https://app.contentful.com/spaces/9cxgygtadmvl/content_types/templatePartnerDetail) */
export type TemplatePartnerDetailLinkedFromArgs = {
  allowedLocales?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
}

/** Model for the Partner Detail Page. [See type definition](https://app.contentful.com/spaces/9cxgygtadmvl/content_types/templatePartnerDetail) */
export type TemplatePartnerDetailSecondLearnMoreArgs = {
  locale?: InputMaybe<Scalars['String']>
  preview?: InputMaybe<Scalars['Boolean']>
  where?: InputMaybe<PagesLearnMoreBlockFilter>
}

/** Model for the Partner Detail Page. [See type definition](https://app.contentful.com/spaces/9cxgygtadmvl/content_types/templatePartnerDetail) */
export type TemplatePartnerDetailTwoColumnCalloutArgs = {
  locale?: InputMaybe<Scalars['String']>
  preview?: InputMaybe<Scalars['Boolean']>
  where?: InputMaybe<PagesTwoColumnCtaFilter>
}

export type TemplatePartnerDetailCollection = {
  __typename?: 'TemplatePartnerDetailCollection'
  items: Array<Maybe<TemplatePartnerDetail>>
  limit: Scalars['Int']
  skip: Scalars['Int']
  total: Scalars['Int']
}

export type TemplatePartnerDetailFilter = {
  AND?: InputMaybe<Array<InputMaybe<TemplatePartnerDetailFilter>>>
  OR?: InputMaybe<Array<InputMaybe<TemplatePartnerDetailFilter>>>
  analyticsModule?: InputMaybe<CfSettingsAnalyticsModuleNestedFilter>
  analyticsModule_exists?: InputMaybe<Scalars['Boolean']>
  benefits?: InputMaybe<CfPagesHeaderBlockNestedFilter>
  benefits_exists?: InputMaybe<Scalars['Boolean']>
  contentfulMetadata?: InputMaybe<ContentfulMetadataFilter>
  faq?: InputMaybe<CfTemplateFaqsNestedFilter>
  faq_exists?: InputMaybe<Scalars['Boolean']>
  featuredAdvisors_exists?: InputMaybe<Scalars['Boolean']>
  featuredProperties?: InputMaybe<CfComponentCarouselSectionNestedFilter>
  featuredProperties_exists?: InputMaybe<Scalars['Boolean']>
  heroBanner?: InputMaybe<CfPagesHeroBlockNestedFilter>
  heroBanner_exists?: InputMaybe<Scalars['Boolean']>
  learnMore?: InputMaybe<CfPagesLearnMoreBlockNestedFilter>
  learnMore_exists?: InputMaybe<Scalars['Boolean']>
  secondLearnMore?: InputMaybe<CfPagesLearnMoreBlockNestedFilter>
  secondLearnMore_exists?: InputMaybe<Scalars['Boolean']>
  sys?: InputMaybe<SysFilter>
  twoColumnCallout?: InputMaybe<CfPagesTwoColumnCtaNestedFilter>
  twoColumnCallout_exists?: InputMaybe<Scalars['Boolean']>
}

export type TemplatePartnerDetailLinkingCollections = {
  __typename?: 'TemplatePartnerDetailLinkingCollections'
  entryCollection?: Maybe<EntryCollection>
}

export type TemplatePartnerDetailLinkingCollectionsEntryCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>
  locale?: InputMaybe<Scalars['String']>
  preview?: InputMaybe<Scalars['Boolean']>
  skip?: InputMaybe<Scalars['Int']>
}

export enum TemplatePartnerDetailOrder {
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
}

/** Create and manage all Partners landing page content. [See type definition](https://app.contentful.com/spaces/9cxgygtadmvl/content_types/templatePartners) */
export type TemplatePartners = Entry &
  _Node & {
    __typename?: 'TemplatePartners'
    _id: Scalars['ID']
    analyticsModule?: Maybe<SettingsAnalyticsModule>
    collapseHeader?: Maybe<Scalars['Boolean']>
    componentImage?: Maybe<ComponentImage>
    contentfulMetadata: ContentfulMetadata
    heroFullWidth?: Maybe<ComponentHeroFullWidth>
    linkedFrom?: Maybe<TemplatePartnersLinkingCollections>
    metaContent?: Maybe<PagesMetaContent>
    pageComponentsCollection?: Maybe<TemplatePartnersPageComponentsCollection>
    partnerGrid?: Maybe<ComponentPartnerGrid>
    pictureSectionBanner?: Maybe<ComponentPictureSectionBanner>
    showHeader?: Maybe<Scalars['Boolean']>
    sys: Sys
    title?: Maybe<Scalars['String']>
    twoColumnCta?: Maybe<PagesTwoColumnCta>
  }

/** Create and manage all Partners landing page content. [See type definition](https://app.contentful.com/spaces/9cxgygtadmvl/content_types/templatePartners) */
export type TemplatePartnersAnalyticsModuleArgs = {
  locale?: InputMaybe<Scalars['String']>
  preview?: InputMaybe<Scalars['Boolean']>
  where?: InputMaybe<SettingsAnalyticsModuleFilter>
}

/** Create and manage all Partners landing page content. [See type definition](https://app.contentful.com/spaces/9cxgygtadmvl/content_types/templatePartners) */
export type TemplatePartnersCollapseHeaderArgs = {
  locale?: InputMaybe<Scalars['String']>
}

/** Create and manage all Partners landing page content. [See type definition](https://app.contentful.com/spaces/9cxgygtadmvl/content_types/templatePartners) */
export type TemplatePartnersComponentImageArgs = {
  locale?: InputMaybe<Scalars['String']>
  preview?: InputMaybe<Scalars['Boolean']>
  where?: InputMaybe<ComponentImageFilter>
}

/** Create and manage all Partners landing page content. [See type definition](https://app.contentful.com/spaces/9cxgygtadmvl/content_types/templatePartners) */
export type TemplatePartnersHeroFullWidthArgs = {
  locale?: InputMaybe<Scalars['String']>
  preview?: InputMaybe<Scalars['Boolean']>
  where?: InputMaybe<ComponentHeroFullWidthFilter>
}

/** Create and manage all Partners landing page content. [See type definition](https://app.contentful.com/spaces/9cxgygtadmvl/content_types/templatePartners) */
export type TemplatePartnersLinkedFromArgs = {
  allowedLocales?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
}

/** Create and manage all Partners landing page content. [See type definition](https://app.contentful.com/spaces/9cxgygtadmvl/content_types/templatePartners) */
export type TemplatePartnersMetaContentArgs = {
  locale?: InputMaybe<Scalars['String']>
  preview?: InputMaybe<Scalars['Boolean']>
  where?: InputMaybe<PagesMetaContentFilter>
}

/** Create and manage all Partners landing page content. [See type definition](https://app.contentful.com/spaces/9cxgygtadmvl/content_types/templatePartners) */
export type TemplatePartnersPageComponentsCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>
  locale?: InputMaybe<Scalars['String']>
  preview?: InputMaybe<Scalars['Boolean']>
  skip?: InputMaybe<Scalars['Int']>
}

/** Create and manage all Partners landing page content. [See type definition](https://app.contentful.com/spaces/9cxgygtadmvl/content_types/templatePartners) */
export type TemplatePartnersPartnerGridArgs = {
  locale?: InputMaybe<Scalars['String']>
  preview?: InputMaybe<Scalars['Boolean']>
  where?: InputMaybe<ComponentPartnerGridFilter>
}

/** Create and manage all Partners landing page content. [See type definition](https://app.contentful.com/spaces/9cxgygtadmvl/content_types/templatePartners) */
export type TemplatePartnersPictureSectionBannerArgs = {
  locale?: InputMaybe<Scalars['String']>
  preview?: InputMaybe<Scalars['Boolean']>
  where?: InputMaybe<ComponentPictureSectionBannerFilter>
}

/** Create and manage all Partners landing page content. [See type definition](https://app.contentful.com/spaces/9cxgygtadmvl/content_types/templatePartners) */
export type TemplatePartnersShowHeaderArgs = {
  locale?: InputMaybe<Scalars['String']>
}

/** Create and manage all Partners landing page content. [See type definition](https://app.contentful.com/spaces/9cxgygtadmvl/content_types/templatePartners) */
export type TemplatePartnersTitleArgs = {
  locale?: InputMaybe<Scalars['String']>
}

/** Create and manage all Partners landing page content. [See type definition](https://app.contentful.com/spaces/9cxgygtadmvl/content_types/templatePartners) */
export type TemplatePartnersTwoColumnCtaArgs = {
  locale?: InputMaybe<Scalars['String']>
  preview?: InputMaybe<Scalars['Boolean']>
  where?: InputMaybe<PagesTwoColumnCtaFilter>
}

export type TemplatePartnersCollection = {
  __typename?: 'TemplatePartnersCollection'
  items: Array<Maybe<TemplatePartners>>
  limit: Scalars['Int']
  skip: Scalars['Int']
  total: Scalars['Int']
}

export type TemplatePartnersFilter = {
  AND?: InputMaybe<Array<InputMaybe<TemplatePartnersFilter>>>
  OR?: InputMaybe<Array<InputMaybe<TemplatePartnersFilter>>>
  analyticsModule?: InputMaybe<CfSettingsAnalyticsModuleNestedFilter>
  analyticsModule_exists?: InputMaybe<Scalars['Boolean']>
  collapseHeader?: InputMaybe<Scalars['Boolean']>
  collapseHeader_exists?: InputMaybe<Scalars['Boolean']>
  collapseHeader_not?: InputMaybe<Scalars['Boolean']>
  componentImage?: InputMaybe<CfComponentImageNestedFilter>
  componentImage_exists?: InputMaybe<Scalars['Boolean']>
  contentfulMetadata?: InputMaybe<ContentfulMetadataFilter>
  heroFullWidth?: InputMaybe<CfComponentHeroFullWidthNestedFilter>
  heroFullWidth_exists?: InputMaybe<Scalars['Boolean']>
  metaContent?: InputMaybe<CfPagesMetaContentNestedFilter>
  metaContent_exists?: InputMaybe<Scalars['Boolean']>
  pageComponentsCollection_exists?: InputMaybe<Scalars['Boolean']>
  partnerGrid?: InputMaybe<CfComponentPartnerGridNestedFilter>
  partnerGrid_exists?: InputMaybe<Scalars['Boolean']>
  pictureSectionBanner?: InputMaybe<CfComponentPictureSectionBannerNestedFilter>
  pictureSectionBanner_exists?: InputMaybe<Scalars['Boolean']>
  showHeader?: InputMaybe<Scalars['Boolean']>
  showHeader_exists?: InputMaybe<Scalars['Boolean']>
  showHeader_not?: InputMaybe<Scalars['Boolean']>
  sys?: InputMaybe<SysFilter>
  title?: InputMaybe<Scalars['String']>
  title_contains?: InputMaybe<Scalars['String']>
  title_exists?: InputMaybe<Scalars['Boolean']>
  title_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  title_not?: InputMaybe<Scalars['String']>
  title_not_contains?: InputMaybe<Scalars['String']>
  title_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  twoColumnCta?: InputMaybe<CfPagesTwoColumnCtaNestedFilter>
  twoColumnCta_exists?: InputMaybe<Scalars['Boolean']>
}

export type TemplatePartnersLinkingCollections = {
  __typename?: 'TemplatePartnersLinkingCollections'
  entryCollection?: Maybe<EntryCollection>
}

export type TemplatePartnersLinkingCollectionsEntryCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>
  locale?: InputMaybe<Scalars['String']>
  preview?: InputMaybe<Scalars['Boolean']>
  skip?: InputMaybe<Scalars['Int']>
}

export enum TemplatePartnersOrder {
  CollapseHeaderAsc = 'collapseHeader_ASC',
  CollapseHeaderDesc = 'collapseHeader_DESC',
  ShowHeaderAsc = 'showHeader_ASC',
  ShowHeaderDesc = 'showHeader_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC',
}

export type TemplatePartnersPageComponentsCollection = {
  __typename?: 'TemplatePartnersPageComponentsCollection'
  items: Array<Maybe<TemplatePartnersPageComponentsItem>>
  limit: Scalars['Int']
  skip: Scalars['Int']
  total: Scalars['Int']
}

export type TemplatePartnersPageComponentsItem =
  | ComponentAdvisorPlanTable
  | ComponentAdvisorReviews
  | ComponentAnimatedTiles
  | ComponentButtonCta
  | ComponentCarouselSection
  | ComponentComparisonTable
  | ComponentCtaBanner
  | ComponentDataBanner
  | ComponentFeaturedAdvisors
  | ComponentFeaturedArticles
  | ComponentFeaturedGuides
  | ComponentFeaturedReviews
  | ComponentForaLogo
  | ComponentForm
  | ComponentHeroCentered
  | ComponentHeroFullWidth
  | ComponentImageSideBySide
  | ComponentInfoGrid
  | ComponentPartnerGrid
  | ComponentPictureSectionBanner
  | ComponentReviews
  | ComponentSingleUse
  | ComponentSpacer
  | ComponentStickyHeader
  | ComponentSubNavBar
  | ComponentSubscriptionCard
  | ComponentTeamSection
  | ComponentTestimonialCarousel
  | ComponentTextSection
  | ComponentThreeIconSectionWithPicture
  | ComponentTwoColumnInfographic
  | ComponentTwoColumnTextSection
  | ComponentTypeformEmbed
  | ComponentVideoSection
  | ComponentWhatYouGetModule
  | FaqsQuestionGroups
  | PagesFeaturedHotels
  | PagesHeaderBlock
  | PagesHeroBlock
  | PagesLearnMoreBlock
  | PagesPartnerBanner
  | PagesPerksList
  | PagesPressBanner
  | PagesTwoColumnCta

/** Press landing page that lists all published Press items in a grid. [See type definition](https://app.contentful.com/spaces/9cxgygtadmvl/content_types/templatePress) */
export type TemplatePress = Entry &
  _Node & {
    __typename?: 'TemplatePress'
    _id: Scalars['ID']
    analyticsModule?: Maybe<SettingsAnalyticsModule>
    contentfulMetadata: ContentfulMetadata
    featuredImage?: Maybe<Asset>
    linkedFrom?: Maybe<TemplatePressLinkingCollections>
    metaContent?: Maybe<PagesMetaContent>
    mobileFeaturedImage?: Maybe<Asset>
    pressBannerCollection?: Maybe<TemplatePressPressBannerCollection>
    subhead?: Maybe<Scalars['String']>
    sys: Sys
    teaserCopy?: Maybe<TemplatePressTeaserCopy>
    title?: Maybe<Scalars['String']>
  }

/** Press landing page that lists all published Press items in a grid. [See type definition](https://app.contentful.com/spaces/9cxgygtadmvl/content_types/templatePress) */
export type TemplatePressAnalyticsModuleArgs = {
  locale?: InputMaybe<Scalars['String']>
  preview?: InputMaybe<Scalars['Boolean']>
  where?: InputMaybe<SettingsAnalyticsModuleFilter>
}

/** Press landing page that lists all published Press items in a grid. [See type definition](https://app.contentful.com/spaces/9cxgygtadmvl/content_types/templatePress) */
export type TemplatePressFeaturedImageArgs = {
  locale?: InputMaybe<Scalars['String']>
  preview?: InputMaybe<Scalars['Boolean']>
}

/** Press landing page that lists all published Press items in a grid. [See type definition](https://app.contentful.com/spaces/9cxgygtadmvl/content_types/templatePress) */
export type TemplatePressLinkedFromArgs = {
  allowedLocales?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
}

/** Press landing page that lists all published Press items in a grid. [See type definition](https://app.contentful.com/spaces/9cxgygtadmvl/content_types/templatePress) */
export type TemplatePressMetaContentArgs = {
  locale?: InputMaybe<Scalars['String']>
  preview?: InputMaybe<Scalars['Boolean']>
  where?: InputMaybe<PagesMetaContentFilter>
}

/** Press landing page that lists all published Press items in a grid. [See type definition](https://app.contentful.com/spaces/9cxgygtadmvl/content_types/templatePress) */
export type TemplatePressMobileFeaturedImageArgs = {
  locale?: InputMaybe<Scalars['String']>
  preview?: InputMaybe<Scalars['Boolean']>
}

/** Press landing page that lists all published Press items in a grid. [See type definition](https://app.contentful.com/spaces/9cxgygtadmvl/content_types/templatePress) */
export type TemplatePressPressBannerCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>
  locale?: InputMaybe<Scalars['String']>
  order?: InputMaybe<Array<InputMaybe<TemplatePressPressBannerCollectionOrder>>>
  preview?: InputMaybe<Scalars['Boolean']>
  skip?: InputMaybe<Scalars['Int']>
  where?: InputMaybe<PagesPressBannerFilter>
}

/** Press landing page that lists all published Press items in a grid. [See type definition](https://app.contentful.com/spaces/9cxgygtadmvl/content_types/templatePress) */
export type TemplatePressSubheadArgs = {
  locale?: InputMaybe<Scalars['String']>
}

/** Press landing page that lists all published Press items in a grid. [See type definition](https://app.contentful.com/spaces/9cxgygtadmvl/content_types/templatePress) */
export type TemplatePressTeaserCopyArgs = {
  locale?: InputMaybe<Scalars['String']>
}

/** Press landing page that lists all published Press items in a grid. [See type definition](https://app.contentful.com/spaces/9cxgygtadmvl/content_types/templatePress) */
export type TemplatePressTitleArgs = {
  locale?: InputMaybe<Scalars['String']>
}

export type TemplatePressCollection = {
  __typename?: 'TemplatePressCollection'
  items: Array<Maybe<TemplatePress>>
  limit: Scalars['Int']
  skip: Scalars['Int']
  total: Scalars['Int']
}

export type TemplatePressFilter = {
  AND?: InputMaybe<Array<InputMaybe<TemplatePressFilter>>>
  OR?: InputMaybe<Array<InputMaybe<TemplatePressFilter>>>
  analyticsModule?: InputMaybe<CfSettingsAnalyticsModuleNestedFilter>
  analyticsModule_exists?: InputMaybe<Scalars['Boolean']>
  contentfulMetadata?: InputMaybe<ContentfulMetadataFilter>
  featuredImage_exists?: InputMaybe<Scalars['Boolean']>
  metaContent?: InputMaybe<CfPagesMetaContentNestedFilter>
  metaContent_exists?: InputMaybe<Scalars['Boolean']>
  mobileFeaturedImage_exists?: InputMaybe<Scalars['Boolean']>
  pressBanner?: InputMaybe<CfPagesPressBannerNestedFilter>
  pressBannerCollection_exists?: InputMaybe<Scalars['Boolean']>
  subhead?: InputMaybe<Scalars['String']>
  subhead_contains?: InputMaybe<Scalars['String']>
  subhead_exists?: InputMaybe<Scalars['Boolean']>
  subhead_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  subhead_not?: InputMaybe<Scalars['String']>
  subhead_not_contains?: InputMaybe<Scalars['String']>
  subhead_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  sys?: InputMaybe<SysFilter>
  teaserCopy_contains?: InputMaybe<Scalars['String']>
  teaserCopy_exists?: InputMaybe<Scalars['Boolean']>
  teaserCopy_not_contains?: InputMaybe<Scalars['String']>
  title?: InputMaybe<Scalars['String']>
  title_contains?: InputMaybe<Scalars['String']>
  title_exists?: InputMaybe<Scalars['Boolean']>
  title_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  title_not?: InputMaybe<Scalars['String']>
  title_not_contains?: InputMaybe<Scalars['String']>
  title_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
}

export type TemplatePressLinkingCollections = {
  __typename?: 'TemplatePressLinkingCollections'
  entryCollection?: Maybe<EntryCollection>
}

export type TemplatePressLinkingCollectionsEntryCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>
  locale?: InputMaybe<Scalars['String']>
  preview?: InputMaybe<Scalars['Boolean']>
  skip?: InputMaybe<Scalars['Int']>
}

export enum TemplatePressOrder {
  SubheadAsc = 'subhead_ASC',
  SubheadDesc = 'subhead_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC',
}

export type TemplatePressPressBannerCollection = {
  __typename?: 'TemplatePressPressBannerCollection'
  items: Array<Maybe<PagesPressBanner>>
  limit: Scalars['Int']
  skip: Scalars['Int']
  total: Scalars['Int']
}

export enum TemplatePressPressBannerCollectionOrder {
  AdminLabelAsc = 'adminLabel_ASC',
  AdminLabelDesc = 'adminLabel_DESC',
  ApplyBackgroundAsc = 'applyBackground_ASC',
  ApplyBackgroundDesc = 'applyBackground_DESC',
  LogoSizeAsc = 'logoSize_ASC',
  LogoSizeDesc = 'logoSize_DESC',
  RedirectSlugAsc = 'redirectSlug_ASC',
  RedirectSlugDesc = 'redirectSlug_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC',
}

export type TemplatePressTeaserCopy = {
  __typename?: 'TemplatePressTeaserCopy'
  json: Scalars['JSON']
  links: TemplatePressTeaserCopyLinks
}

export type TemplatePressTeaserCopyAssets = {
  __typename?: 'TemplatePressTeaserCopyAssets'
  block: Array<Maybe<Asset>>
  hyperlink: Array<Maybe<Asset>>
}

export type TemplatePressTeaserCopyEntries = {
  __typename?: 'TemplatePressTeaserCopyEntries'
  block: Array<Maybe<Entry>>
  hyperlink: Array<Maybe<Entry>>
  inline: Array<Maybe<Entry>>
}

export type TemplatePressTeaserCopyLinks = {
  __typename?: 'TemplatePressTeaserCopyLinks'
  assets: TemplatePressTeaserCopyAssets
  entries: TemplatePressTeaserCopyEntries
  resources: TemplatePressTeaserCopyResources
}

export type TemplatePressTeaserCopyResources = {
  __typename?: 'TemplatePressTeaserCopyResources'
  block: Array<TemplatePressTeaserCopyResourcesBlock>
  hyperlink: Array<TemplatePressTeaserCopyResourcesHyperlink>
  inline: Array<TemplatePressTeaserCopyResourcesInline>
}

export type TemplatePressTeaserCopyResourcesBlock = ResourceLink & {
  __typename?: 'TemplatePressTeaserCopyResourcesBlock'
  sys: ResourceSys
}

export type TemplatePressTeaserCopyResourcesHyperlink = ResourceLink & {
  __typename?: 'TemplatePressTeaserCopyResourcesHyperlink'
  sys: ResourceSys
}

export type TemplatePressTeaserCopyResourcesInline = ResourceLink & {
  __typename?: 'TemplatePressTeaserCopyResourcesInline'
  sys: ResourceSys
}

/** Create and manage all Privacy Policy page content. [See type definition](https://app.contentful.com/spaces/9cxgygtadmvl/content_types/templatePrivacyPolicy) */
export type TemplatePrivacyPolicy = Entry &
  _Node & {
    __typename?: 'TemplatePrivacyPolicy'
    _id: Scalars['ID']
    analyticsModule?: Maybe<SettingsAnalyticsModule>
    contentfulMetadata: ContentfulMetadata
    linkedFrom?: Maybe<TemplatePrivacyPolicyLinkingCollections>
    metaContent?: Maybe<PagesMetaContent>
    pageContent?: Maybe<TemplatePrivacyPolicyPageContent>
    sys: Sys
    title?: Maybe<Scalars['String']>
  }

/** Create and manage all Privacy Policy page content. [See type definition](https://app.contentful.com/spaces/9cxgygtadmvl/content_types/templatePrivacyPolicy) */
export type TemplatePrivacyPolicyAnalyticsModuleArgs = {
  locale?: InputMaybe<Scalars['String']>
  preview?: InputMaybe<Scalars['Boolean']>
  where?: InputMaybe<SettingsAnalyticsModuleFilter>
}

/** Create and manage all Privacy Policy page content. [See type definition](https://app.contentful.com/spaces/9cxgygtadmvl/content_types/templatePrivacyPolicy) */
export type TemplatePrivacyPolicyLinkedFromArgs = {
  allowedLocales?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
}

/** Create and manage all Privacy Policy page content. [See type definition](https://app.contentful.com/spaces/9cxgygtadmvl/content_types/templatePrivacyPolicy) */
export type TemplatePrivacyPolicyMetaContentArgs = {
  locale?: InputMaybe<Scalars['String']>
  preview?: InputMaybe<Scalars['Boolean']>
  where?: InputMaybe<PagesMetaContentFilter>
}

/** Create and manage all Privacy Policy page content. [See type definition](https://app.contentful.com/spaces/9cxgygtadmvl/content_types/templatePrivacyPolicy) */
export type TemplatePrivacyPolicyPageContentArgs = {
  locale?: InputMaybe<Scalars['String']>
}

/** Create and manage all Privacy Policy page content. [See type definition](https://app.contentful.com/spaces/9cxgygtadmvl/content_types/templatePrivacyPolicy) */
export type TemplatePrivacyPolicyTitleArgs = {
  locale?: InputMaybe<Scalars['String']>
}

export type TemplatePrivacyPolicyCollection = {
  __typename?: 'TemplatePrivacyPolicyCollection'
  items: Array<Maybe<TemplatePrivacyPolicy>>
  limit: Scalars['Int']
  skip: Scalars['Int']
  total: Scalars['Int']
}

export type TemplatePrivacyPolicyFilter = {
  AND?: InputMaybe<Array<InputMaybe<TemplatePrivacyPolicyFilter>>>
  OR?: InputMaybe<Array<InputMaybe<TemplatePrivacyPolicyFilter>>>
  analyticsModule?: InputMaybe<CfSettingsAnalyticsModuleNestedFilter>
  analyticsModule_exists?: InputMaybe<Scalars['Boolean']>
  contentfulMetadata?: InputMaybe<ContentfulMetadataFilter>
  metaContent?: InputMaybe<CfPagesMetaContentNestedFilter>
  metaContent_exists?: InputMaybe<Scalars['Boolean']>
  pageContent_contains?: InputMaybe<Scalars['String']>
  pageContent_exists?: InputMaybe<Scalars['Boolean']>
  pageContent_not_contains?: InputMaybe<Scalars['String']>
  sys?: InputMaybe<SysFilter>
  title?: InputMaybe<Scalars['String']>
  title_contains?: InputMaybe<Scalars['String']>
  title_exists?: InputMaybe<Scalars['Boolean']>
  title_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  title_not?: InputMaybe<Scalars['String']>
  title_not_contains?: InputMaybe<Scalars['String']>
  title_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
}

export type TemplatePrivacyPolicyLinkingCollections = {
  __typename?: 'TemplatePrivacyPolicyLinkingCollections'
  entryCollection?: Maybe<EntryCollection>
}

export type TemplatePrivacyPolicyLinkingCollectionsEntryCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>
  locale?: InputMaybe<Scalars['String']>
  preview?: InputMaybe<Scalars['Boolean']>
  skip?: InputMaybe<Scalars['Int']>
}

export enum TemplatePrivacyPolicyOrder {
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC',
}

export type TemplatePrivacyPolicyPageContent = {
  __typename?: 'TemplatePrivacyPolicyPageContent'
  json: Scalars['JSON']
  links: TemplatePrivacyPolicyPageContentLinks
}

export type TemplatePrivacyPolicyPageContentAssets = {
  __typename?: 'TemplatePrivacyPolicyPageContentAssets'
  block: Array<Maybe<Asset>>
  hyperlink: Array<Maybe<Asset>>
}

export type TemplatePrivacyPolicyPageContentEntries = {
  __typename?: 'TemplatePrivacyPolicyPageContentEntries'
  block: Array<Maybe<Entry>>
  hyperlink: Array<Maybe<Entry>>
  inline: Array<Maybe<Entry>>
}

export type TemplatePrivacyPolicyPageContentLinks = {
  __typename?: 'TemplatePrivacyPolicyPageContentLinks'
  assets: TemplatePrivacyPolicyPageContentAssets
  entries: TemplatePrivacyPolicyPageContentEntries
  resources: TemplatePrivacyPolicyPageContentResources
}

export type TemplatePrivacyPolicyPageContentResources = {
  __typename?: 'TemplatePrivacyPolicyPageContentResources'
  block: Array<TemplatePrivacyPolicyPageContentResourcesBlock>
  hyperlink: Array<TemplatePrivacyPolicyPageContentResourcesHyperlink>
  inline: Array<TemplatePrivacyPolicyPageContentResourcesInline>
}

export type TemplatePrivacyPolicyPageContentResourcesBlock = ResourceLink & {
  __typename?: 'TemplatePrivacyPolicyPageContentResourcesBlock'
  sys: ResourceSys
}

export type TemplatePrivacyPolicyPageContentResourcesHyperlink =
  ResourceLink & {
    __typename?: 'TemplatePrivacyPolicyPageContentResourcesHyperlink'
    sys: ResourceSys
  }

export type TemplatePrivacyPolicyPageContentResourcesInline = ResourceLink & {
  __typename?: 'TemplatePrivacyPolicyPageContentResourcesInline'
  sys: ResourceSys
}

/** Create and manage all Reviews page content. [See type definition](https://app.contentful.com/spaces/9cxgygtadmvl/content_types/templateReviews) */
export type TemplateReviews = Entry &
  _Node & {
    __typename?: 'TemplateReviews'
    _id: Scalars['ID']
    collapseHeader?: Maybe<Scalars['Boolean']>
    contentfulMetadata: ContentfulMetadata
    linkedFrom?: Maybe<TemplateReviewsLinkingCollections>
    metaContent?: Maybe<PagesMetaContent>
    pageComponentsCollection?: Maybe<TemplateReviewsPageComponentsCollection>
    showHeader?: Maybe<Scalars['Boolean']>
    sys: Sys
    title?: Maybe<Scalars['String']>
  }

/** Create and manage all Reviews page content. [See type definition](https://app.contentful.com/spaces/9cxgygtadmvl/content_types/templateReviews) */
export type TemplateReviewsCollapseHeaderArgs = {
  locale?: InputMaybe<Scalars['String']>
}

/** Create and manage all Reviews page content. [See type definition](https://app.contentful.com/spaces/9cxgygtadmvl/content_types/templateReviews) */
export type TemplateReviewsLinkedFromArgs = {
  allowedLocales?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
}

/** Create and manage all Reviews page content. [See type definition](https://app.contentful.com/spaces/9cxgygtadmvl/content_types/templateReviews) */
export type TemplateReviewsMetaContentArgs = {
  locale?: InputMaybe<Scalars['String']>
  preview?: InputMaybe<Scalars['Boolean']>
  where?: InputMaybe<PagesMetaContentFilter>
}

/** Create and manage all Reviews page content. [See type definition](https://app.contentful.com/spaces/9cxgygtadmvl/content_types/templateReviews) */
export type TemplateReviewsPageComponentsCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>
  locale?: InputMaybe<Scalars['String']>
  preview?: InputMaybe<Scalars['Boolean']>
  skip?: InputMaybe<Scalars['Int']>
}

/** Create and manage all Reviews page content. [See type definition](https://app.contentful.com/spaces/9cxgygtadmvl/content_types/templateReviews) */
export type TemplateReviewsShowHeaderArgs = {
  locale?: InputMaybe<Scalars['String']>
}

/** Create and manage all Reviews page content. [See type definition](https://app.contentful.com/spaces/9cxgygtadmvl/content_types/templateReviews) */
export type TemplateReviewsTitleArgs = {
  locale?: InputMaybe<Scalars['String']>
}

export type TemplateReviewsCollection = {
  __typename?: 'TemplateReviewsCollection'
  items: Array<Maybe<TemplateReviews>>
  limit: Scalars['Int']
  skip: Scalars['Int']
  total: Scalars['Int']
}

export type TemplateReviewsFilter = {
  AND?: InputMaybe<Array<InputMaybe<TemplateReviewsFilter>>>
  OR?: InputMaybe<Array<InputMaybe<TemplateReviewsFilter>>>
  collapseHeader?: InputMaybe<Scalars['Boolean']>
  collapseHeader_exists?: InputMaybe<Scalars['Boolean']>
  collapseHeader_not?: InputMaybe<Scalars['Boolean']>
  contentfulMetadata?: InputMaybe<ContentfulMetadataFilter>
  metaContent?: InputMaybe<CfPagesMetaContentNestedFilter>
  metaContent_exists?: InputMaybe<Scalars['Boolean']>
  pageComponentsCollection_exists?: InputMaybe<Scalars['Boolean']>
  showHeader?: InputMaybe<Scalars['Boolean']>
  showHeader_exists?: InputMaybe<Scalars['Boolean']>
  showHeader_not?: InputMaybe<Scalars['Boolean']>
  sys?: InputMaybe<SysFilter>
  title?: InputMaybe<Scalars['String']>
  title_contains?: InputMaybe<Scalars['String']>
  title_exists?: InputMaybe<Scalars['Boolean']>
  title_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  title_not?: InputMaybe<Scalars['String']>
  title_not_contains?: InputMaybe<Scalars['String']>
  title_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
}

export type TemplateReviewsLinkingCollections = {
  __typename?: 'TemplateReviewsLinkingCollections'
  entryCollection?: Maybe<EntryCollection>
}

export type TemplateReviewsLinkingCollectionsEntryCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>
  locale?: InputMaybe<Scalars['String']>
  preview?: InputMaybe<Scalars['Boolean']>
  skip?: InputMaybe<Scalars['Int']>
}

export enum TemplateReviewsOrder {
  CollapseHeaderAsc = 'collapseHeader_ASC',
  CollapseHeaderDesc = 'collapseHeader_DESC',
  ShowHeaderAsc = 'showHeader_ASC',
  ShowHeaderDesc = 'showHeader_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC',
}

export type TemplateReviewsPageComponentsCollection = {
  __typename?: 'TemplateReviewsPageComponentsCollection'
  items: Array<Maybe<TemplateReviewsPageComponentsItem>>
  limit: Scalars['Int']
  skip: Scalars['Int']
  total: Scalars['Int']
}

export type TemplateReviewsPageComponentsItem =
  | ComponentAdvisorPlanTable
  | ComponentAdvisorReviews
  | ComponentAnimatedTiles
  | ComponentButtonCta
  | ComponentCarouselSection
  | ComponentComparisonTable
  | ComponentCtaBanner
  | ComponentDataBanner
  | ComponentFeaturedAdvisors
  | ComponentFeaturedArticles
  | ComponentFeaturedGuides
  | ComponentFeaturedReviews
  | ComponentForaLogo
  | ComponentForm
  | ComponentHeroCentered
  | ComponentHeroFullWidth
  | ComponentImageSideBySide
  | ComponentInfoGrid
  | ComponentPartnerGrid
  | ComponentPictureSectionBanner
  | ComponentReviews
  | ComponentSingleUse
  | ComponentSpacer
  | ComponentStickyHeader
  | ComponentSubNavBar
  | ComponentSubscriptionCard
  | ComponentTeamSection
  | ComponentTestimonialCarousel
  | ComponentTextSection
  | ComponentThreeIconSectionWithPicture
  | ComponentTwoColumnInfographic
  | ComponentTwoColumnTextSection
  | ComponentTypeformEmbed
  | ComponentVideoSection
  | ComponentWhatYouGetModule
  | FaqsQuestionGroups
  | PagesFeaturedHotels
  | PagesHeaderBlock
  | PagesHeroBlock
  | PagesLearnMoreBlock
  | PagesPartnerBanner
  | PagesPerksList
  | PagesPressBanner
  | PagesTwoColumnCta

/** The template for the Search results landing page. [See type definition](https://app.contentful.com/spaces/9cxgygtadmvl/content_types/templateSearch) */
export type TemplateSearch = Entry &
  _Node & {
    __typename?: 'TemplateSearch'
    _id: Scalars['ID']
    adminLabel?: Maybe<Scalars['String']>
    analyticsModule?: Maybe<SettingsAnalyticsModule>
    contentfulMetadata: ContentfulMetadata
    linkedFrom?: Maybe<TemplateSearchLinkingCollections>
    metaContent?: Maybe<PagesMetaContent>
    sys: Sys
  }

/** The template for the Search results landing page. [See type definition](https://app.contentful.com/spaces/9cxgygtadmvl/content_types/templateSearch) */
export type TemplateSearchAdminLabelArgs = {
  locale?: InputMaybe<Scalars['String']>
}

/** The template for the Search results landing page. [See type definition](https://app.contentful.com/spaces/9cxgygtadmvl/content_types/templateSearch) */
export type TemplateSearchAnalyticsModuleArgs = {
  locale?: InputMaybe<Scalars['String']>
  preview?: InputMaybe<Scalars['Boolean']>
  where?: InputMaybe<SettingsAnalyticsModuleFilter>
}

/** The template for the Search results landing page. [See type definition](https://app.contentful.com/spaces/9cxgygtadmvl/content_types/templateSearch) */
export type TemplateSearchLinkedFromArgs = {
  allowedLocales?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
}

/** The template for the Search results landing page. [See type definition](https://app.contentful.com/spaces/9cxgygtadmvl/content_types/templateSearch) */
export type TemplateSearchMetaContentArgs = {
  locale?: InputMaybe<Scalars['String']>
  preview?: InputMaybe<Scalars['Boolean']>
  where?: InputMaybe<PagesMetaContentFilter>
}

export type TemplateSearchCollection = {
  __typename?: 'TemplateSearchCollection'
  items: Array<Maybe<TemplateSearch>>
  limit: Scalars['Int']
  skip: Scalars['Int']
  total: Scalars['Int']
}

export type TemplateSearchFilter = {
  AND?: InputMaybe<Array<InputMaybe<TemplateSearchFilter>>>
  OR?: InputMaybe<Array<InputMaybe<TemplateSearchFilter>>>
  adminLabel?: InputMaybe<Scalars['String']>
  adminLabel_contains?: InputMaybe<Scalars['String']>
  adminLabel_exists?: InputMaybe<Scalars['Boolean']>
  adminLabel_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  adminLabel_not?: InputMaybe<Scalars['String']>
  adminLabel_not_contains?: InputMaybe<Scalars['String']>
  adminLabel_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  analyticsModule?: InputMaybe<CfSettingsAnalyticsModuleNestedFilter>
  analyticsModule_exists?: InputMaybe<Scalars['Boolean']>
  contentfulMetadata?: InputMaybe<ContentfulMetadataFilter>
  metaContent?: InputMaybe<CfPagesMetaContentNestedFilter>
  metaContent_exists?: InputMaybe<Scalars['Boolean']>
  sys?: InputMaybe<SysFilter>
}

export type TemplateSearchLinkingCollections = {
  __typename?: 'TemplateSearchLinkingCollections'
  entryCollection?: Maybe<EntryCollection>
}

export type TemplateSearchLinkingCollectionsEntryCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>
  locale?: InputMaybe<Scalars['String']>
  preview?: InputMaybe<Scalars['Boolean']>
  skip?: InputMaybe<Scalars['Int']>
}

export enum TemplateSearchOrder {
  AdminLabelAsc = 'adminLabel_ASC',
  AdminLabelDesc = 'adminLabel_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
}

/** Create and manage all Terms and Conditions page content. [See type definition](https://app.contentful.com/spaces/9cxgygtadmvl/content_types/templateTermsAndConditions) */
export type TemplateTermsAndConditions = Entry &
  _Node & {
    __typename?: 'TemplateTermsAndConditions'
    _id: Scalars['ID']
    analyticsModule?: Maybe<SettingsAnalyticsModule>
    contentfulMetadata: ContentfulMetadata
    linkedFrom?: Maybe<TemplateTermsAndConditionsLinkingCollections>
    metaContent?: Maybe<PagesMetaContent>
    pageContent?: Maybe<TemplateTermsAndConditionsPageContent>
    sys: Sys
    title?: Maybe<Scalars['String']>
  }

/** Create and manage all Terms and Conditions page content. [See type definition](https://app.contentful.com/spaces/9cxgygtadmvl/content_types/templateTermsAndConditions) */
export type TemplateTermsAndConditionsAnalyticsModuleArgs = {
  locale?: InputMaybe<Scalars['String']>
  preview?: InputMaybe<Scalars['Boolean']>
  where?: InputMaybe<SettingsAnalyticsModuleFilter>
}

/** Create and manage all Terms and Conditions page content. [See type definition](https://app.contentful.com/spaces/9cxgygtadmvl/content_types/templateTermsAndConditions) */
export type TemplateTermsAndConditionsLinkedFromArgs = {
  allowedLocales?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
}

/** Create and manage all Terms and Conditions page content. [See type definition](https://app.contentful.com/spaces/9cxgygtadmvl/content_types/templateTermsAndConditions) */
export type TemplateTermsAndConditionsMetaContentArgs = {
  locale?: InputMaybe<Scalars['String']>
  preview?: InputMaybe<Scalars['Boolean']>
  where?: InputMaybe<PagesMetaContentFilter>
}

/** Create and manage all Terms and Conditions page content. [See type definition](https://app.contentful.com/spaces/9cxgygtadmvl/content_types/templateTermsAndConditions) */
export type TemplateTermsAndConditionsPageContentArgs = {
  locale?: InputMaybe<Scalars['String']>
}

/** Create and manage all Terms and Conditions page content. [See type definition](https://app.contentful.com/spaces/9cxgygtadmvl/content_types/templateTermsAndConditions) */
export type TemplateTermsAndConditionsTitleArgs = {
  locale?: InputMaybe<Scalars['String']>
}

export type TemplateTermsAndConditionsCollection = {
  __typename?: 'TemplateTermsAndConditionsCollection'
  items: Array<Maybe<TemplateTermsAndConditions>>
  limit: Scalars['Int']
  skip: Scalars['Int']
  total: Scalars['Int']
}

export type TemplateTermsAndConditionsFilter = {
  AND?: InputMaybe<Array<InputMaybe<TemplateTermsAndConditionsFilter>>>
  OR?: InputMaybe<Array<InputMaybe<TemplateTermsAndConditionsFilter>>>
  analyticsModule?: InputMaybe<CfSettingsAnalyticsModuleNestedFilter>
  analyticsModule_exists?: InputMaybe<Scalars['Boolean']>
  contentfulMetadata?: InputMaybe<ContentfulMetadataFilter>
  metaContent?: InputMaybe<CfPagesMetaContentNestedFilter>
  metaContent_exists?: InputMaybe<Scalars['Boolean']>
  pageContent_contains?: InputMaybe<Scalars['String']>
  pageContent_exists?: InputMaybe<Scalars['Boolean']>
  pageContent_not_contains?: InputMaybe<Scalars['String']>
  sys?: InputMaybe<SysFilter>
  title?: InputMaybe<Scalars['String']>
  title_contains?: InputMaybe<Scalars['String']>
  title_exists?: InputMaybe<Scalars['Boolean']>
  title_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  title_not?: InputMaybe<Scalars['String']>
  title_not_contains?: InputMaybe<Scalars['String']>
  title_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
}

export type TemplateTermsAndConditionsLinkingCollections = {
  __typename?: 'TemplateTermsAndConditionsLinkingCollections'
  entryCollection?: Maybe<EntryCollection>
}

export type TemplateTermsAndConditionsLinkingCollectionsEntryCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>
  locale?: InputMaybe<Scalars['String']>
  preview?: InputMaybe<Scalars['Boolean']>
  skip?: InputMaybe<Scalars['Int']>
}

export enum TemplateTermsAndConditionsOrder {
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC',
}

export type TemplateTermsAndConditionsPageContent = {
  __typename?: 'TemplateTermsAndConditionsPageContent'
  json: Scalars['JSON']
  links: TemplateTermsAndConditionsPageContentLinks
}

export type TemplateTermsAndConditionsPageContentAssets = {
  __typename?: 'TemplateTermsAndConditionsPageContentAssets'
  block: Array<Maybe<Asset>>
  hyperlink: Array<Maybe<Asset>>
}

export type TemplateTermsAndConditionsPageContentEntries = {
  __typename?: 'TemplateTermsAndConditionsPageContentEntries'
  block: Array<Maybe<Entry>>
  hyperlink: Array<Maybe<Entry>>
  inline: Array<Maybe<Entry>>
}

export type TemplateTermsAndConditionsPageContentLinks = {
  __typename?: 'TemplateTermsAndConditionsPageContentLinks'
  assets: TemplateTermsAndConditionsPageContentAssets
  entries: TemplateTermsAndConditionsPageContentEntries
  resources: TemplateTermsAndConditionsPageContentResources
}

export type TemplateTermsAndConditionsPageContentResources = {
  __typename?: 'TemplateTermsAndConditionsPageContentResources'
  block: Array<TemplateTermsAndConditionsPageContentResourcesBlock>
  hyperlink: Array<TemplateTermsAndConditionsPageContentResourcesHyperlink>
  inline: Array<TemplateTermsAndConditionsPageContentResourcesInline>
}

export type TemplateTermsAndConditionsPageContentResourcesBlock =
  ResourceLink & {
    __typename?: 'TemplateTermsAndConditionsPageContentResourcesBlock'
    sys: ResourceSys
  }

export type TemplateTermsAndConditionsPageContentResourcesHyperlink =
  ResourceLink & {
    __typename?: 'TemplateTermsAndConditionsPageContentResourcesHyperlink'
    sys: ResourceSys
  }

export type TemplateTermsAndConditionsPageContentResourcesInline =
  ResourceLink & {
    __typename?: 'TemplateTermsAndConditionsPageContentResourcesInline'
    sys: ResourceSys
  }

/** Model for the Travel landing page. [See type definition](https://app.contentful.com/spaces/9cxgygtadmvl/content_types/templateTravel) */
export type TemplateTravel = Entry &
  _Node & {
    __typename?: 'TemplateTravel'
    _id: Scalars['ID']
    analyticsModule?: Maybe<SettingsAnalyticsModule>
    contentfulMetadata: ContentfulMetadata
    learnMoreBlock?: Maybe<ComponentThreeIconSection>
    linkedFrom?: Maybe<TemplateTravelLinkingCollections>
    metaContent?: Maybe<PagesMetaContent>
    questionGroup?: Maybe<FaqsQuestionGroups>
    sys: Sys
    teaserCopy?: Maybe<TemplateTravelTeaserCopy>
    title?: Maybe<Scalars['String']>
    travelByHeading?: Maybe<Scalars['String']>
    travelByTeaserCopy?: Maybe<TemplateTravelTravelByTeaserCopy>
  }

/** Model for the Travel landing page. [See type definition](https://app.contentful.com/spaces/9cxgygtadmvl/content_types/templateTravel) */
export type TemplateTravelAnalyticsModuleArgs = {
  locale?: InputMaybe<Scalars['String']>
  preview?: InputMaybe<Scalars['Boolean']>
  where?: InputMaybe<SettingsAnalyticsModuleFilter>
}

/** Model for the Travel landing page. [See type definition](https://app.contentful.com/spaces/9cxgygtadmvl/content_types/templateTravel) */
export type TemplateTravelLearnMoreBlockArgs = {
  locale?: InputMaybe<Scalars['String']>
  preview?: InputMaybe<Scalars['Boolean']>
  where?: InputMaybe<ComponentThreeIconSectionFilter>
}

/** Model for the Travel landing page. [See type definition](https://app.contentful.com/spaces/9cxgygtadmvl/content_types/templateTravel) */
export type TemplateTravelLinkedFromArgs = {
  allowedLocales?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
}

/** Model for the Travel landing page. [See type definition](https://app.contentful.com/spaces/9cxgygtadmvl/content_types/templateTravel) */
export type TemplateTravelMetaContentArgs = {
  locale?: InputMaybe<Scalars['String']>
  preview?: InputMaybe<Scalars['Boolean']>
  where?: InputMaybe<PagesMetaContentFilter>
}

/** Model for the Travel landing page. [See type definition](https://app.contentful.com/spaces/9cxgygtadmvl/content_types/templateTravel) */
export type TemplateTravelQuestionGroupArgs = {
  locale?: InputMaybe<Scalars['String']>
  preview?: InputMaybe<Scalars['Boolean']>
  where?: InputMaybe<FaqsQuestionGroupsFilter>
}

/** Model for the Travel landing page. [See type definition](https://app.contentful.com/spaces/9cxgygtadmvl/content_types/templateTravel) */
export type TemplateTravelTeaserCopyArgs = {
  locale?: InputMaybe<Scalars['String']>
}

/** Model for the Travel landing page. [See type definition](https://app.contentful.com/spaces/9cxgygtadmvl/content_types/templateTravel) */
export type TemplateTravelTitleArgs = {
  locale?: InputMaybe<Scalars['String']>
}

/** Model for the Travel landing page. [See type definition](https://app.contentful.com/spaces/9cxgygtadmvl/content_types/templateTravel) */
export type TemplateTravelTravelByHeadingArgs = {
  locale?: InputMaybe<Scalars['String']>
}

/** Model for the Travel landing page. [See type definition](https://app.contentful.com/spaces/9cxgygtadmvl/content_types/templateTravel) */
export type TemplateTravelTravelByTeaserCopyArgs = {
  locale?: InputMaybe<Scalars['String']>
}

/** Content for the landing page for all Travel By pages. [See type definition](https://app.contentful.com/spaces/9cxgygtadmvl/content_types/templateTravelBy) */
export type TemplateTravelBy = Entry &
  _Node & {
    __typename?: 'TemplateTravelBy'
    _id: Scalars['ID']
    analyticsModule?: Maybe<SettingsAnalyticsModule>
    categoriesCollection?: Maybe<TemplateTravelByCategoriesCollection>
    contentfulMetadata: ContentfulMetadata
    learnMoreBlock?: Maybe<ComponentThreeIconSection>
    linkedFrom?: Maybe<TemplateTravelByLinkingCollections>
    metaContent?: Maybe<PagesMetaContent>
    questionGroup?: Maybe<FaqsQuestionGroups>
    sys: Sys
    teaserCopy?: Maybe<TemplateTravelByTeaserCopy>
    title?: Maybe<Scalars['String']>
  }

/** Content for the landing page for all Travel By pages. [See type definition](https://app.contentful.com/spaces/9cxgygtadmvl/content_types/templateTravelBy) */
export type TemplateTravelByAnalyticsModuleArgs = {
  locale?: InputMaybe<Scalars['String']>
  preview?: InputMaybe<Scalars['Boolean']>
  where?: InputMaybe<SettingsAnalyticsModuleFilter>
}

/** Content for the landing page for all Travel By pages. [See type definition](https://app.contentful.com/spaces/9cxgygtadmvl/content_types/templateTravelBy) */
export type TemplateTravelByCategoriesCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>
  locale?: InputMaybe<Scalars['String']>
  order?: InputMaybe<
    Array<InputMaybe<TemplateTravelByCategoriesCollectionOrder>>
  >
  preview?: InputMaybe<Scalars['Boolean']>
  skip?: InputMaybe<Scalars['Int']>
  where?: InputMaybe<TravelByCategoriesFilter>
}

/** Content for the landing page for all Travel By pages. [See type definition](https://app.contentful.com/spaces/9cxgygtadmvl/content_types/templateTravelBy) */
export type TemplateTravelByLearnMoreBlockArgs = {
  locale?: InputMaybe<Scalars['String']>
  preview?: InputMaybe<Scalars['Boolean']>
  where?: InputMaybe<ComponentThreeIconSectionFilter>
}

/** Content for the landing page for all Travel By pages. [See type definition](https://app.contentful.com/spaces/9cxgygtadmvl/content_types/templateTravelBy) */
export type TemplateTravelByLinkedFromArgs = {
  allowedLocales?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
}

/** Content for the landing page for all Travel By pages. [See type definition](https://app.contentful.com/spaces/9cxgygtadmvl/content_types/templateTravelBy) */
export type TemplateTravelByMetaContentArgs = {
  locale?: InputMaybe<Scalars['String']>
  preview?: InputMaybe<Scalars['Boolean']>
  where?: InputMaybe<PagesMetaContentFilter>
}

/** Content for the landing page for all Travel By pages. [See type definition](https://app.contentful.com/spaces/9cxgygtadmvl/content_types/templateTravelBy) */
export type TemplateTravelByQuestionGroupArgs = {
  locale?: InputMaybe<Scalars['String']>
  preview?: InputMaybe<Scalars['Boolean']>
  where?: InputMaybe<FaqsQuestionGroupsFilter>
}

/** Content for the landing page for all Travel By pages. [See type definition](https://app.contentful.com/spaces/9cxgygtadmvl/content_types/templateTravelBy) */
export type TemplateTravelByTeaserCopyArgs = {
  locale?: InputMaybe<Scalars['String']>
}

/** Content for the landing page for all Travel By pages. [See type definition](https://app.contentful.com/spaces/9cxgygtadmvl/content_types/templateTravelBy) */
export type TemplateTravelByTitleArgs = {
  locale?: InputMaybe<Scalars['String']>
}

export type TemplateTravelByCategoriesCollection = {
  __typename?: 'TemplateTravelByCategoriesCollection'
  items: Array<Maybe<TravelByCategories>>
  limit: Scalars['Int']
  skip: Scalars['Int']
  total: Scalars['Int']
}

export enum TemplateTravelByCategoriesCollectionOrder {
  SlugAsc = 'slug_ASC',
  SlugDesc = 'slug_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC',
}

export type TemplateTravelByCollection = {
  __typename?: 'TemplateTravelByCollection'
  items: Array<Maybe<TemplateTravelBy>>
  limit: Scalars['Int']
  skip: Scalars['Int']
  total: Scalars['Int']
}

export type TemplateTravelByFilter = {
  AND?: InputMaybe<Array<InputMaybe<TemplateTravelByFilter>>>
  OR?: InputMaybe<Array<InputMaybe<TemplateTravelByFilter>>>
  analyticsModule?: InputMaybe<CfSettingsAnalyticsModuleNestedFilter>
  analyticsModule_exists?: InputMaybe<Scalars['Boolean']>
  categories?: InputMaybe<CfTravelByCategoriesNestedFilter>
  categoriesCollection_exists?: InputMaybe<Scalars['Boolean']>
  contentfulMetadata?: InputMaybe<ContentfulMetadataFilter>
  learnMoreBlock?: InputMaybe<CfComponentThreeIconSectionNestedFilter>
  learnMoreBlock_exists?: InputMaybe<Scalars['Boolean']>
  metaContent?: InputMaybe<CfPagesMetaContentNestedFilter>
  metaContent_exists?: InputMaybe<Scalars['Boolean']>
  questionGroup?: InputMaybe<CfFaqsQuestionGroupsNestedFilter>
  questionGroup_exists?: InputMaybe<Scalars['Boolean']>
  sys?: InputMaybe<SysFilter>
  teaserCopy_contains?: InputMaybe<Scalars['String']>
  teaserCopy_exists?: InputMaybe<Scalars['Boolean']>
  teaserCopy_not_contains?: InputMaybe<Scalars['String']>
  title?: InputMaybe<Scalars['String']>
  title_contains?: InputMaybe<Scalars['String']>
  title_exists?: InputMaybe<Scalars['Boolean']>
  title_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  title_not?: InputMaybe<Scalars['String']>
  title_not_contains?: InputMaybe<Scalars['String']>
  title_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
}

export type TemplateTravelByLinkingCollections = {
  __typename?: 'TemplateTravelByLinkingCollections'
  entryCollection?: Maybe<EntryCollection>
}

export type TemplateTravelByLinkingCollectionsEntryCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>
  locale?: InputMaybe<Scalars['String']>
  preview?: InputMaybe<Scalars['Boolean']>
  skip?: InputMaybe<Scalars['Int']>
}

export enum TemplateTravelByOrder {
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC',
}

export type TemplateTravelByTeaserCopy = {
  __typename?: 'TemplateTravelByTeaserCopy'
  json: Scalars['JSON']
  links: TemplateTravelByTeaserCopyLinks
}

export type TemplateTravelByTeaserCopyAssets = {
  __typename?: 'TemplateTravelByTeaserCopyAssets'
  block: Array<Maybe<Asset>>
  hyperlink: Array<Maybe<Asset>>
}

export type TemplateTravelByTeaserCopyEntries = {
  __typename?: 'TemplateTravelByTeaserCopyEntries'
  block: Array<Maybe<Entry>>
  hyperlink: Array<Maybe<Entry>>
  inline: Array<Maybe<Entry>>
}

export type TemplateTravelByTeaserCopyLinks = {
  __typename?: 'TemplateTravelByTeaserCopyLinks'
  assets: TemplateTravelByTeaserCopyAssets
  entries: TemplateTravelByTeaserCopyEntries
  resources: TemplateTravelByTeaserCopyResources
}

export type TemplateTravelByTeaserCopyResources = {
  __typename?: 'TemplateTravelByTeaserCopyResources'
  block: Array<TemplateTravelByTeaserCopyResourcesBlock>
  hyperlink: Array<TemplateTravelByTeaserCopyResourcesHyperlink>
  inline: Array<TemplateTravelByTeaserCopyResourcesInline>
}

export type TemplateTravelByTeaserCopyResourcesBlock = ResourceLink & {
  __typename?: 'TemplateTravelByTeaserCopyResourcesBlock'
  sys: ResourceSys
}

export type TemplateTravelByTeaserCopyResourcesHyperlink = ResourceLink & {
  __typename?: 'TemplateTravelByTeaserCopyResourcesHyperlink'
  sys: ResourceSys
}

export type TemplateTravelByTeaserCopyResourcesInline = ResourceLink & {
  __typename?: 'TemplateTravelByTeaserCopyResourcesInline'
  sys: ResourceSys
}

export type TemplateTravelCollection = {
  __typename?: 'TemplateTravelCollection'
  items: Array<Maybe<TemplateTravel>>
  limit: Scalars['Int']
  skip: Scalars['Int']
  total: Scalars['Int']
}

export type TemplateTravelFilter = {
  AND?: InputMaybe<Array<InputMaybe<TemplateTravelFilter>>>
  OR?: InputMaybe<Array<InputMaybe<TemplateTravelFilter>>>
  analyticsModule?: InputMaybe<CfSettingsAnalyticsModuleNestedFilter>
  analyticsModule_exists?: InputMaybe<Scalars['Boolean']>
  contentfulMetadata?: InputMaybe<ContentfulMetadataFilter>
  learnMoreBlock?: InputMaybe<CfComponentThreeIconSectionNestedFilter>
  learnMoreBlock_exists?: InputMaybe<Scalars['Boolean']>
  metaContent?: InputMaybe<CfPagesMetaContentNestedFilter>
  metaContent_exists?: InputMaybe<Scalars['Boolean']>
  questionGroup?: InputMaybe<CfFaqsQuestionGroupsNestedFilter>
  questionGroup_exists?: InputMaybe<Scalars['Boolean']>
  sys?: InputMaybe<SysFilter>
  teaserCopy_contains?: InputMaybe<Scalars['String']>
  teaserCopy_exists?: InputMaybe<Scalars['Boolean']>
  teaserCopy_not_contains?: InputMaybe<Scalars['String']>
  title?: InputMaybe<Scalars['String']>
  title_contains?: InputMaybe<Scalars['String']>
  title_exists?: InputMaybe<Scalars['Boolean']>
  title_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  title_not?: InputMaybe<Scalars['String']>
  title_not_contains?: InputMaybe<Scalars['String']>
  title_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  travelByHeading?: InputMaybe<Scalars['String']>
  travelByHeading_contains?: InputMaybe<Scalars['String']>
  travelByHeading_exists?: InputMaybe<Scalars['Boolean']>
  travelByHeading_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  travelByHeading_not?: InputMaybe<Scalars['String']>
  travelByHeading_not_contains?: InputMaybe<Scalars['String']>
  travelByHeading_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  travelByTeaserCopy_contains?: InputMaybe<Scalars['String']>
  travelByTeaserCopy_exists?: InputMaybe<Scalars['Boolean']>
  travelByTeaserCopy_not_contains?: InputMaybe<Scalars['String']>
}

export type TemplateTravelLinkingCollections = {
  __typename?: 'TemplateTravelLinkingCollections'
  entryCollection?: Maybe<EntryCollection>
}

export type TemplateTravelLinkingCollectionsEntryCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>
  locale?: InputMaybe<Scalars['String']>
  preview?: InputMaybe<Scalars['Boolean']>
  skip?: InputMaybe<Scalars['Int']>
}

export enum TemplateTravelOrder {
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC',
  TravelByHeadingAsc = 'travelByHeading_ASC',
  TravelByHeadingDesc = 'travelByHeading_DESC',
}

export type TemplateTravelTeaserCopy = {
  __typename?: 'TemplateTravelTeaserCopy'
  json: Scalars['JSON']
  links: TemplateTravelTeaserCopyLinks
}

export type TemplateTravelTeaserCopyAssets = {
  __typename?: 'TemplateTravelTeaserCopyAssets'
  block: Array<Maybe<Asset>>
  hyperlink: Array<Maybe<Asset>>
}

export type TemplateTravelTeaserCopyEntries = {
  __typename?: 'TemplateTravelTeaserCopyEntries'
  block: Array<Maybe<Entry>>
  hyperlink: Array<Maybe<Entry>>
  inline: Array<Maybe<Entry>>
}

export type TemplateTravelTeaserCopyLinks = {
  __typename?: 'TemplateTravelTeaserCopyLinks'
  assets: TemplateTravelTeaserCopyAssets
  entries: TemplateTravelTeaserCopyEntries
  resources: TemplateTravelTeaserCopyResources
}

export type TemplateTravelTeaserCopyResources = {
  __typename?: 'TemplateTravelTeaserCopyResources'
  block: Array<TemplateTravelTeaserCopyResourcesBlock>
  hyperlink: Array<TemplateTravelTeaserCopyResourcesHyperlink>
  inline: Array<TemplateTravelTeaserCopyResourcesInline>
}

export type TemplateTravelTeaserCopyResourcesBlock = ResourceLink & {
  __typename?: 'TemplateTravelTeaserCopyResourcesBlock'
  sys: ResourceSys
}

export type TemplateTravelTeaserCopyResourcesHyperlink = ResourceLink & {
  __typename?: 'TemplateTravelTeaserCopyResourcesHyperlink'
  sys: ResourceSys
}

export type TemplateTravelTeaserCopyResourcesInline = ResourceLink & {
  __typename?: 'TemplateTravelTeaserCopyResourcesInline'
  sys: ResourceSys
}

export type TemplateTravelTravelByTeaserCopy = {
  __typename?: 'TemplateTravelTravelByTeaserCopy'
  json: Scalars['JSON']
  links: TemplateTravelTravelByTeaserCopyLinks
}

export type TemplateTravelTravelByTeaserCopyAssets = {
  __typename?: 'TemplateTravelTravelByTeaserCopyAssets'
  block: Array<Maybe<Asset>>
  hyperlink: Array<Maybe<Asset>>
}

export type TemplateTravelTravelByTeaserCopyEntries = {
  __typename?: 'TemplateTravelTravelByTeaserCopyEntries'
  block: Array<Maybe<Entry>>
  hyperlink: Array<Maybe<Entry>>
  inline: Array<Maybe<Entry>>
}

export type TemplateTravelTravelByTeaserCopyLinks = {
  __typename?: 'TemplateTravelTravelByTeaserCopyLinks'
  assets: TemplateTravelTravelByTeaserCopyAssets
  entries: TemplateTravelTravelByTeaserCopyEntries
  resources: TemplateTravelTravelByTeaserCopyResources
}

export type TemplateTravelTravelByTeaserCopyResources = {
  __typename?: 'TemplateTravelTravelByTeaserCopyResources'
  block: Array<TemplateTravelTravelByTeaserCopyResourcesBlock>
  hyperlink: Array<TemplateTravelTravelByTeaserCopyResourcesHyperlink>
  inline: Array<TemplateTravelTravelByTeaserCopyResourcesInline>
}

export type TemplateTravelTravelByTeaserCopyResourcesBlock = ResourceLink & {
  __typename?: 'TemplateTravelTravelByTeaserCopyResourcesBlock'
  sys: ResourceSys
}

export type TemplateTravelTravelByTeaserCopyResourcesHyperlink =
  ResourceLink & {
    __typename?: 'TemplateTravelTravelByTeaserCopyResourcesHyperlink'
    sys: ResourceSys
  }

export type TemplateTravelTravelByTeaserCopyResourcesInline = ResourceLink & {
  __typename?: 'TemplateTravelTravelByTeaserCopyResourcesInline'
  sys: ResourceSys
}

/** Create and manage all Why Book with Fora (Join Us) page content. [See type definition](https://app.contentful.com/spaces/9cxgygtadmvl/content_types/templateWhyBookWithFora) */
export type TemplateWhyBookWithFora = Entry &
  _Node & {
    __typename?: 'TemplateWhyBookWithFora'
    _id: Scalars['ID']
    advisorsHeaderBlock?: Maybe<PagesHeaderBlock>
    analyticsModule?: Maybe<SettingsAnalyticsModule>
    collapseHeader?: Maybe<Scalars['Boolean']>
    contentfulMetadata: ContentfulMetadata
    cta?: Maybe<PagesTwoColumnCta>
    headerBlock?: Maybe<PagesHeaderBlock>
    headerBlock2?: Maybe<PagesHeaderBlock>
    heroBlock?: Maybe<PagesHeroBlock>
    itinerariesHeaderBlock?: Maybe<PagesHeaderBlock>
    linkedFrom?: Maybe<TemplateWhyBookWithForaLinkingCollections>
    metaContent?: Maybe<PagesMetaContent>
    pageBlocksCollection?: Maybe<TemplateWhyBookWithForaPageBlocksCollection>
    pageComponentsCollection?: Maybe<TemplateWhyBookWithForaPageComponentsCollection>
    showHeader?: Maybe<Scalars['Boolean']>
    sys: Sys
    title?: Maybe<Scalars['String']>
  }

/** Create and manage all Why Book with Fora (Join Us) page content. [See type definition](https://app.contentful.com/spaces/9cxgygtadmvl/content_types/templateWhyBookWithFora) */
export type TemplateWhyBookWithForaAdvisorsHeaderBlockArgs = {
  locale?: InputMaybe<Scalars['String']>
  preview?: InputMaybe<Scalars['Boolean']>
  where?: InputMaybe<PagesHeaderBlockFilter>
}

/** Create and manage all Why Book with Fora (Join Us) page content. [See type definition](https://app.contentful.com/spaces/9cxgygtadmvl/content_types/templateWhyBookWithFora) */
export type TemplateWhyBookWithForaAnalyticsModuleArgs = {
  locale?: InputMaybe<Scalars['String']>
  preview?: InputMaybe<Scalars['Boolean']>
  where?: InputMaybe<SettingsAnalyticsModuleFilter>
}

/** Create and manage all Why Book with Fora (Join Us) page content. [See type definition](https://app.contentful.com/spaces/9cxgygtadmvl/content_types/templateWhyBookWithFora) */
export type TemplateWhyBookWithForaCollapseHeaderArgs = {
  locale?: InputMaybe<Scalars['String']>
}

/** Create and manage all Why Book with Fora (Join Us) page content. [See type definition](https://app.contentful.com/spaces/9cxgygtadmvl/content_types/templateWhyBookWithFora) */
export type TemplateWhyBookWithForaCtaArgs = {
  locale?: InputMaybe<Scalars['String']>
  preview?: InputMaybe<Scalars['Boolean']>
  where?: InputMaybe<PagesTwoColumnCtaFilter>
}

/** Create and manage all Why Book with Fora (Join Us) page content. [See type definition](https://app.contentful.com/spaces/9cxgygtadmvl/content_types/templateWhyBookWithFora) */
export type TemplateWhyBookWithForaHeaderBlockArgs = {
  locale?: InputMaybe<Scalars['String']>
  preview?: InputMaybe<Scalars['Boolean']>
  where?: InputMaybe<PagesHeaderBlockFilter>
}

/** Create and manage all Why Book with Fora (Join Us) page content. [See type definition](https://app.contentful.com/spaces/9cxgygtadmvl/content_types/templateWhyBookWithFora) */
export type TemplateWhyBookWithForaHeaderBlock2Args = {
  locale?: InputMaybe<Scalars['String']>
  preview?: InputMaybe<Scalars['Boolean']>
  where?: InputMaybe<PagesHeaderBlockFilter>
}

/** Create and manage all Why Book with Fora (Join Us) page content. [See type definition](https://app.contentful.com/spaces/9cxgygtadmvl/content_types/templateWhyBookWithFora) */
export type TemplateWhyBookWithForaHeroBlockArgs = {
  locale?: InputMaybe<Scalars['String']>
  preview?: InputMaybe<Scalars['Boolean']>
  where?: InputMaybe<PagesHeroBlockFilter>
}

/** Create and manage all Why Book with Fora (Join Us) page content. [See type definition](https://app.contentful.com/spaces/9cxgygtadmvl/content_types/templateWhyBookWithFora) */
export type TemplateWhyBookWithForaItinerariesHeaderBlockArgs = {
  locale?: InputMaybe<Scalars['String']>
  preview?: InputMaybe<Scalars['Boolean']>
  where?: InputMaybe<PagesHeaderBlockFilter>
}

/** Create and manage all Why Book with Fora (Join Us) page content. [See type definition](https://app.contentful.com/spaces/9cxgygtadmvl/content_types/templateWhyBookWithFora) */
export type TemplateWhyBookWithForaLinkedFromArgs = {
  allowedLocales?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
}

/** Create and manage all Why Book with Fora (Join Us) page content. [See type definition](https://app.contentful.com/spaces/9cxgygtadmvl/content_types/templateWhyBookWithFora) */
export type TemplateWhyBookWithForaMetaContentArgs = {
  locale?: InputMaybe<Scalars['String']>
  preview?: InputMaybe<Scalars['Boolean']>
  where?: InputMaybe<PagesMetaContentFilter>
}

/** Create and manage all Why Book with Fora (Join Us) page content. [See type definition](https://app.contentful.com/spaces/9cxgygtadmvl/content_types/templateWhyBookWithFora) */
export type TemplateWhyBookWithForaPageBlocksCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>
  locale?: InputMaybe<Scalars['String']>
  preview?: InputMaybe<Scalars['Boolean']>
  skip?: InputMaybe<Scalars['Int']>
  where?: InputMaybe<TemplateWhyBookWithForaPageBlocksFilter>
}

/** Create and manage all Why Book with Fora (Join Us) page content. [See type definition](https://app.contentful.com/spaces/9cxgygtadmvl/content_types/templateWhyBookWithFora) */
export type TemplateWhyBookWithForaPageComponentsCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>
  locale?: InputMaybe<Scalars['String']>
  preview?: InputMaybe<Scalars['Boolean']>
  skip?: InputMaybe<Scalars['Int']>
}

/** Create and manage all Why Book with Fora (Join Us) page content. [See type definition](https://app.contentful.com/spaces/9cxgygtadmvl/content_types/templateWhyBookWithFora) */
export type TemplateWhyBookWithForaShowHeaderArgs = {
  locale?: InputMaybe<Scalars['String']>
}

/** Create and manage all Why Book with Fora (Join Us) page content. [See type definition](https://app.contentful.com/spaces/9cxgygtadmvl/content_types/templateWhyBookWithFora) */
export type TemplateWhyBookWithForaTitleArgs = {
  locale?: InputMaybe<Scalars['String']>
}

export type TemplateWhyBookWithForaCollection = {
  __typename?: 'TemplateWhyBookWithForaCollection'
  items: Array<Maybe<TemplateWhyBookWithFora>>
  limit: Scalars['Int']
  skip: Scalars['Int']
  total: Scalars['Int']
}

export type TemplateWhyBookWithForaFilter = {
  AND?: InputMaybe<Array<InputMaybe<TemplateWhyBookWithForaFilter>>>
  OR?: InputMaybe<Array<InputMaybe<TemplateWhyBookWithForaFilter>>>
  advisorsHeaderBlock?: InputMaybe<CfPagesHeaderBlockNestedFilter>
  advisorsHeaderBlock_exists?: InputMaybe<Scalars['Boolean']>
  analyticsModule?: InputMaybe<CfSettingsAnalyticsModuleNestedFilter>
  analyticsModule_exists?: InputMaybe<Scalars['Boolean']>
  collapseHeader?: InputMaybe<Scalars['Boolean']>
  collapseHeader_exists?: InputMaybe<Scalars['Boolean']>
  collapseHeader_not?: InputMaybe<Scalars['Boolean']>
  contentfulMetadata?: InputMaybe<ContentfulMetadataFilter>
  cta?: InputMaybe<CfPagesTwoColumnCtaNestedFilter>
  cta_exists?: InputMaybe<Scalars['Boolean']>
  headerBlock?: InputMaybe<CfPagesHeaderBlockNestedFilter>
  headerBlock2?: InputMaybe<CfPagesHeaderBlockNestedFilter>
  headerBlock2_exists?: InputMaybe<Scalars['Boolean']>
  headerBlock_exists?: InputMaybe<Scalars['Boolean']>
  heroBlock?: InputMaybe<CfPagesHeroBlockNestedFilter>
  heroBlock_exists?: InputMaybe<Scalars['Boolean']>
  itinerariesHeaderBlock?: InputMaybe<CfPagesHeaderBlockNestedFilter>
  itinerariesHeaderBlock_exists?: InputMaybe<Scalars['Boolean']>
  metaContent?: InputMaybe<CfPagesMetaContentNestedFilter>
  metaContent_exists?: InputMaybe<Scalars['Boolean']>
  pageBlocks?: InputMaybe<CfpageBlocksMultiTypeNestedFilter>
  pageBlocksCollection_exists?: InputMaybe<Scalars['Boolean']>
  pageComponentsCollection_exists?: InputMaybe<Scalars['Boolean']>
  showHeader?: InputMaybe<Scalars['Boolean']>
  showHeader_exists?: InputMaybe<Scalars['Boolean']>
  showHeader_not?: InputMaybe<Scalars['Boolean']>
  sys?: InputMaybe<SysFilter>
  title?: InputMaybe<Scalars['String']>
  title_contains?: InputMaybe<Scalars['String']>
  title_exists?: InputMaybe<Scalars['Boolean']>
  title_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  title_not?: InputMaybe<Scalars['String']>
  title_not_contains?: InputMaybe<Scalars['String']>
  title_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
}

export type TemplateWhyBookWithForaLinkingCollections = {
  __typename?: 'TemplateWhyBookWithForaLinkingCollections'
  entryCollection?: Maybe<EntryCollection>
}

export type TemplateWhyBookWithForaLinkingCollectionsEntryCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>
  locale?: InputMaybe<Scalars['String']>
  preview?: InputMaybe<Scalars['Boolean']>
  skip?: InputMaybe<Scalars['Int']>
}

export enum TemplateWhyBookWithForaOrder {
  CollapseHeaderAsc = 'collapseHeader_ASC',
  CollapseHeaderDesc = 'collapseHeader_DESC',
  ShowHeaderAsc = 'showHeader_ASC',
  ShowHeaderDesc = 'showHeader_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC',
}

export type TemplateWhyBookWithForaPageBlocksCollection = {
  __typename?: 'TemplateWhyBookWithForaPageBlocksCollection'
  items: Array<Maybe<TemplateWhyBookWithForaPageBlocksItem>>
  limit: Scalars['Int']
  skip: Scalars['Int']
  total: Scalars['Int']
}

export type TemplateWhyBookWithForaPageBlocksFilter = {
  AND?: InputMaybe<Array<InputMaybe<TemplateWhyBookWithForaPageBlocksFilter>>>
  OR?: InputMaybe<Array<InputMaybe<TemplateWhyBookWithForaPageBlocksFilter>>>
  contentfulMetadata?: InputMaybe<ContentfulMetadataFilter>
  sys?: InputMaybe<SysFilter>
}

export type TemplateWhyBookWithForaPageBlocksItem =
  | ComponentCarouselSection
  | ComponentComparisonTable
  | ComponentCtaBanner
  | ComponentFeaturedAdvisors
  | ComponentFeaturedGuides
  | ComponentForm
  | ComponentHeroCentered
  | ComponentHeroFullWidth
  | ComponentSpacer
  | ComponentSubNavBar
  | ComponentTeamSection
  | ComponentTextSection
  | ComponentThreeIconSection
  | ComponentTwoColumnPerksSection
  | ComponentTwoColumnTextSection
  | ComponentVideoSection
  | FaqsQuestionGroups
  | PagesFeaturedHotels
  | PagesHeaderBlock
  | PagesHeroBlock
  | PagesPartnerBanner
  | PagesPerksList
  | PagesPressBanner
  | PagesTwoColumnCta

export type TemplateWhyBookWithForaPageComponentsCollection = {
  __typename?: 'TemplateWhyBookWithForaPageComponentsCollection'
  items: Array<Maybe<TemplateWhyBookWithForaPageComponentsItem>>
  limit: Scalars['Int']
  skip: Scalars['Int']
  total: Scalars['Int']
}

export type TemplateWhyBookWithForaPageComponentsItem =
  | ComponentAdvisorPlanTable
  | ComponentAdvisorReviews
  | ComponentAnimatedTiles
  | ComponentButtonCta
  | ComponentCarouselSection
  | ComponentComparisonTable
  | ComponentCtaBanner
  | ComponentDataBanner
  | ComponentFeaturedAdvisors
  | ComponentFeaturedArticles
  | ComponentFeaturedGuides
  | ComponentFeaturedReviews
  | ComponentForaLogo
  | ComponentForm
  | ComponentHeroCentered
  | ComponentHeroFullWidth
  | ComponentImageSideBySide
  | ComponentInfoGrid
  | ComponentPartnerGrid
  | ComponentPictureSectionBanner
  | ComponentReviews
  | ComponentSingleUse
  | ComponentSpacer
  | ComponentStickyHeader
  | ComponentSubNavBar
  | ComponentSubscriptionCard
  | ComponentTeamSection
  | ComponentTestimonialCarousel
  | ComponentTextSection
  | ComponentThreeIconSectionWithPicture
  | ComponentTwoColumnInfographic
  | ComponentTwoColumnTextSection
  | ComponentTypeformEmbed
  | ComponentVideoSection
  | ComponentWhatYouGetModule
  | FaqsQuestionGroups
  | PagesFeaturedHotels
  | PagesHeaderBlock
  | PagesHeroBlock
  | PagesLearnMoreBlock
  | PagesPartnerBanner
  | PagesPerksList
  | PagesPressBanner
  | PagesTwoColumnCta

/** A publishing model to manage system-wide content, variables, and other configuration. [See type definition](https://app.contentful.com/spaces/9cxgygtadmvl/content_types/templatesGlobalConfigs) */
export type TemplatesGlobalConfigs = Entry &
  _Node & {
    __typename?: 'TemplatesGlobalConfigs'
    _id: Scalars['ID']
    advisorsMetaDescription?: Maybe<Scalars['String']>
    contentfulMetadata: ContentfulMetadata
    destinationCategoriesMetaTitle?: Maybe<Scalars['String']>
    destinationSubcategoriesMetaDescription?: Maybe<Scalars['String']>
    destinationSubcategoriesMetaTitle?: Maybe<Scalars['String']>
    destinationsCategoriesMetaDescription?: Maybe<Scalars['String']>
    itinerariesMetaDescription?: Maybe<Scalars['String']>
    linkedFrom?: Maybe<TemplatesGlobalConfigsLinkingCollections>
    sys: Sys
    title?: Maybe<Scalars['String']>
  }

/** A publishing model to manage system-wide content, variables, and other configuration. [See type definition](https://app.contentful.com/spaces/9cxgygtadmvl/content_types/templatesGlobalConfigs) */
export type TemplatesGlobalConfigsAdvisorsMetaDescriptionArgs = {
  locale?: InputMaybe<Scalars['String']>
}

/** A publishing model to manage system-wide content, variables, and other configuration. [See type definition](https://app.contentful.com/spaces/9cxgygtadmvl/content_types/templatesGlobalConfigs) */
export type TemplatesGlobalConfigsDestinationCategoriesMetaTitleArgs = {
  locale?: InputMaybe<Scalars['String']>
}

/** A publishing model to manage system-wide content, variables, and other configuration. [See type definition](https://app.contentful.com/spaces/9cxgygtadmvl/content_types/templatesGlobalConfigs) */
export type TemplatesGlobalConfigsDestinationSubcategoriesMetaDescriptionArgs =
  {
    locale?: InputMaybe<Scalars['String']>
  }

/** A publishing model to manage system-wide content, variables, and other configuration. [See type definition](https://app.contentful.com/spaces/9cxgygtadmvl/content_types/templatesGlobalConfigs) */
export type TemplatesGlobalConfigsDestinationSubcategoriesMetaTitleArgs = {
  locale?: InputMaybe<Scalars['String']>
}

/** A publishing model to manage system-wide content, variables, and other configuration. [See type definition](https://app.contentful.com/spaces/9cxgygtadmvl/content_types/templatesGlobalConfigs) */
export type TemplatesGlobalConfigsDestinationsCategoriesMetaDescriptionArgs = {
  locale?: InputMaybe<Scalars['String']>
}

/** A publishing model to manage system-wide content, variables, and other configuration. [See type definition](https://app.contentful.com/spaces/9cxgygtadmvl/content_types/templatesGlobalConfigs) */
export type TemplatesGlobalConfigsItinerariesMetaDescriptionArgs = {
  locale?: InputMaybe<Scalars['String']>
}

/** A publishing model to manage system-wide content, variables, and other configuration. [See type definition](https://app.contentful.com/spaces/9cxgygtadmvl/content_types/templatesGlobalConfigs) */
export type TemplatesGlobalConfigsLinkedFromArgs = {
  allowedLocales?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
}

/** A publishing model to manage system-wide content, variables, and other configuration. [See type definition](https://app.contentful.com/spaces/9cxgygtadmvl/content_types/templatesGlobalConfigs) */
export type TemplatesGlobalConfigsTitleArgs = {
  locale?: InputMaybe<Scalars['String']>
}

export type TemplatesGlobalConfigsCollection = {
  __typename?: 'TemplatesGlobalConfigsCollection'
  items: Array<Maybe<TemplatesGlobalConfigs>>
  limit: Scalars['Int']
  skip: Scalars['Int']
  total: Scalars['Int']
}

export type TemplatesGlobalConfigsFilter = {
  AND?: InputMaybe<Array<InputMaybe<TemplatesGlobalConfigsFilter>>>
  OR?: InputMaybe<Array<InputMaybe<TemplatesGlobalConfigsFilter>>>
  advisorsMetaDescription?: InputMaybe<Scalars['String']>
  advisorsMetaDescription_contains?: InputMaybe<Scalars['String']>
  advisorsMetaDescription_exists?: InputMaybe<Scalars['Boolean']>
  advisorsMetaDescription_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  advisorsMetaDescription_not?: InputMaybe<Scalars['String']>
  advisorsMetaDescription_not_contains?: InputMaybe<Scalars['String']>
  advisorsMetaDescription_not_in?: InputMaybe<
    Array<InputMaybe<Scalars['String']>>
  >
  contentfulMetadata?: InputMaybe<ContentfulMetadataFilter>
  destinationCategoriesMetaTitle?: InputMaybe<Scalars['String']>
  destinationCategoriesMetaTitle_contains?: InputMaybe<Scalars['String']>
  destinationCategoriesMetaTitle_exists?: InputMaybe<Scalars['Boolean']>
  destinationCategoriesMetaTitle_in?: InputMaybe<
    Array<InputMaybe<Scalars['String']>>
  >
  destinationCategoriesMetaTitle_not?: InputMaybe<Scalars['String']>
  destinationCategoriesMetaTitle_not_contains?: InputMaybe<Scalars['String']>
  destinationCategoriesMetaTitle_not_in?: InputMaybe<
    Array<InputMaybe<Scalars['String']>>
  >
  destinationSubcategoriesMetaDescription?: InputMaybe<Scalars['String']>
  destinationSubcategoriesMetaDescription_contains?: InputMaybe<
    Scalars['String']
  >
  destinationSubcategoriesMetaDescription_exists?: InputMaybe<
    Scalars['Boolean']
  >
  destinationSubcategoriesMetaDescription_in?: InputMaybe<
    Array<InputMaybe<Scalars['String']>>
  >
  destinationSubcategoriesMetaDescription_not?: InputMaybe<Scalars['String']>
  destinationSubcategoriesMetaDescription_not_contains?: InputMaybe<
    Scalars['String']
  >
  destinationSubcategoriesMetaDescription_not_in?: InputMaybe<
    Array<InputMaybe<Scalars['String']>>
  >
  destinationSubcategoriesMetaTitle?: InputMaybe<Scalars['String']>
  destinationSubcategoriesMetaTitle_contains?: InputMaybe<Scalars['String']>
  destinationSubcategoriesMetaTitle_exists?: InputMaybe<Scalars['Boolean']>
  destinationSubcategoriesMetaTitle_in?: InputMaybe<
    Array<InputMaybe<Scalars['String']>>
  >
  destinationSubcategoriesMetaTitle_not?: InputMaybe<Scalars['String']>
  destinationSubcategoriesMetaTitle_not_contains?: InputMaybe<Scalars['String']>
  destinationSubcategoriesMetaTitle_not_in?: InputMaybe<
    Array<InputMaybe<Scalars['String']>>
  >
  destinationsCategoriesMetaDescription?: InputMaybe<Scalars['String']>
  destinationsCategoriesMetaDescription_contains?: InputMaybe<Scalars['String']>
  destinationsCategoriesMetaDescription_exists?: InputMaybe<Scalars['Boolean']>
  destinationsCategoriesMetaDescription_in?: InputMaybe<
    Array<InputMaybe<Scalars['String']>>
  >
  destinationsCategoriesMetaDescription_not?: InputMaybe<Scalars['String']>
  destinationsCategoriesMetaDescription_not_contains?: InputMaybe<
    Scalars['String']
  >
  destinationsCategoriesMetaDescription_not_in?: InputMaybe<
    Array<InputMaybe<Scalars['String']>>
  >
  itinerariesMetaDescription?: InputMaybe<Scalars['String']>
  itinerariesMetaDescription_contains?: InputMaybe<Scalars['String']>
  itinerariesMetaDescription_exists?: InputMaybe<Scalars['Boolean']>
  itinerariesMetaDescription_in?: InputMaybe<
    Array<InputMaybe<Scalars['String']>>
  >
  itinerariesMetaDescription_not?: InputMaybe<Scalars['String']>
  itinerariesMetaDescription_not_contains?: InputMaybe<Scalars['String']>
  itinerariesMetaDescription_not_in?: InputMaybe<
    Array<InputMaybe<Scalars['String']>>
  >
  sys?: InputMaybe<SysFilter>
  title?: InputMaybe<Scalars['String']>
  title_contains?: InputMaybe<Scalars['String']>
  title_exists?: InputMaybe<Scalars['Boolean']>
  title_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  title_not?: InputMaybe<Scalars['String']>
  title_not_contains?: InputMaybe<Scalars['String']>
  title_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
}

export type TemplatesGlobalConfigsLinkingCollections = {
  __typename?: 'TemplatesGlobalConfigsLinkingCollections'
  entryCollection?: Maybe<EntryCollection>
}

export type TemplatesGlobalConfigsLinkingCollectionsEntryCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>
  locale?: InputMaybe<Scalars['String']>
  preview?: InputMaybe<Scalars['Boolean']>
  skip?: InputMaybe<Scalars['Int']>
}

export enum TemplatesGlobalConfigsOrder {
  DestinationCategoriesMetaTitleAsc = 'destinationCategoriesMetaTitle_ASC',
  DestinationCategoriesMetaTitleDesc = 'destinationCategoriesMetaTitle_DESC',
  DestinationSubcategoriesMetaTitleAsc = 'destinationSubcategoriesMetaTitle_ASC',
  DestinationSubcategoriesMetaTitleDesc = 'destinationSubcategoriesMetaTitle_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC',
}

/** [See type definition](https://app.contentful.com/spaces/9cxgygtadmvl/content_types/topHotelsByDestination) */
export type TopHotelsByDestination = Entry &
  _Node & {
    __typename?: 'TopHotelsByDestination'
    _id: Scalars['ID']
    contentfulMetadata: ContentfulMetadata
    destinationId?: Maybe<Scalars['String']>
    linkedFrom?: Maybe<TopHotelsByDestinationLinkingCollections>
    rankedAllTimeHotels?: Maybe<Scalars['JSON']>
    sys: Sys
  }

/** [See type definition](https://app.contentful.com/spaces/9cxgygtadmvl/content_types/topHotelsByDestination) */
export type TopHotelsByDestinationDestinationIdArgs = {
  locale?: InputMaybe<Scalars['String']>
}

/** [See type definition](https://app.contentful.com/spaces/9cxgygtadmvl/content_types/topHotelsByDestination) */
export type TopHotelsByDestinationLinkedFromArgs = {
  allowedLocales?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
}

/** [See type definition](https://app.contentful.com/spaces/9cxgygtadmvl/content_types/topHotelsByDestination) */
export type TopHotelsByDestinationRankedAllTimeHotelsArgs = {
  locale?: InputMaybe<Scalars['String']>
}

export type TopHotelsByDestinationCollection = {
  __typename?: 'TopHotelsByDestinationCollection'
  items: Array<Maybe<TopHotelsByDestination>>
  limit: Scalars['Int']
  skip: Scalars['Int']
  total: Scalars['Int']
}

export type TopHotelsByDestinationFilter = {
  AND?: InputMaybe<Array<InputMaybe<TopHotelsByDestinationFilter>>>
  OR?: InputMaybe<Array<InputMaybe<TopHotelsByDestinationFilter>>>
  contentfulMetadata?: InputMaybe<ContentfulMetadataFilter>
  destinationId?: InputMaybe<Scalars['String']>
  destinationId_contains?: InputMaybe<Scalars['String']>
  destinationId_exists?: InputMaybe<Scalars['Boolean']>
  destinationId_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  destinationId_not?: InputMaybe<Scalars['String']>
  destinationId_not_contains?: InputMaybe<Scalars['String']>
  destinationId_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  rankedAllTimeHotels_exists?: InputMaybe<Scalars['Boolean']>
  sys?: InputMaybe<SysFilter>
}

export type TopHotelsByDestinationLinkingCollections = {
  __typename?: 'TopHotelsByDestinationLinkingCollections'
  entryCollection?: Maybe<EntryCollection>
}

export type TopHotelsByDestinationLinkingCollectionsEntryCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>
  locale?: InputMaybe<Scalars['String']>
  preview?: InputMaybe<Scalars['Boolean']>
  skip?: InputMaybe<Scalars['Int']>
}

export enum TopHotelsByDestinationOrder {
  DestinationIdAsc = 'destinationId_ASC',
  DestinationIdDesc = 'destinationId_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
}

/** Create and manage top-level Travel By categories. Top-level categories are not used for landing pages or in URL segments. [See type definition](https://app.contentful.com/spaces/9cxgygtadmvl/content_types/travelByCategories) */
export type TravelByCategories = Entry &
  _Node & {
    __typename?: 'TravelByCategories'
    _id: Scalars['ID']
    contentfulMetadata: ContentfulMetadata
    featuredImage?: Maybe<Asset>
    linkedFrom?: Maybe<TravelByCategoriesLinkingCollections>
    mobileFeaturedImage?: Maybe<Asset>
    slug?: Maybe<Scalars['String']>
    subcategoriesCollection?: Maybe<TravelByCategoriesSubcategoriesCollection>
    sys: Sys
    title?: Maybe<Scalars['String']>
  }

/** Create and manage top-level Travel By categories. Top-level categories are not used for landing pages or in URL segments. [See type definition](https://app.contentful.com/spaces/9cxgygtadmvl/content_types/travelByCategories) */
export type TravelByCategoriesFeaturedImageArgs = {
  locale?: InputMaybe<Scalars['String']>
  preview?: InputMaybe<Scalars['Boolean']>
}

/** Create and manage top-level Travel By categories. Top-level categories are not used for landing pages or in URL segments. [See type definition](https://app.contentful.com/spaces/9cxgygtadmvl/content_types/travelByCategories) */
export type TravelByCategoriesLinkedFromArgs = {
  allowedLocales?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
}

/** Create and manage top-level Travel By categories. Top-level categories are not used for landing pages or in URL segments. [See type definition](https://app.contentful.com/spaces/9cxgygtadmvl/content_types/travelByCategories) */
export type TravelByCategoriesMobileFeaturedImageArgs = {
  locale?: InputMaybe<Scalars['String']>
  preview?: InputMaybe<Scalars['Boolean']>
}

/** Create and manage top-level Travel By categories. Top-level categories are not used for landing pages or in URL segments. [See type definition](https://app.contentful.com/spaces/9cxgygtadmvl/content_types/travelByCategories) */
export type TravelByCategoriesSlugArgs = {
  locale?: InputMaybe<Scalars['String']>
}

/** Create and manage top-level Travel By categories. Top-level categories are not used for landing pages or in URL segments. [See type definition](https://app.contentful.com/spaces/9cxgygtadmvl/content_types/travelByCategories) */
export type TravelByCategoriesSubcategoriesCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>
  locale?: InputMaybe<Scalars['String']>
  order?: InputMaybe<
    Array<InputMaybe<TravelByCategoriesSubcategoriesCollectionOrder>>
  >
  preview?: InputMaybe<Scalars['Boolean']>
  skip?: InputMaybe<Scalars['Int']>
  where?: InputMaybe<TravelBySubcategoriesFilter>
}

/** Create and manage top-level Travel By categories. Top-level categories are not used for landing pages or in URL segments. [See type definition](https://app.contentful.com/spaces/9cxgygtadmvl/content_types/travelByCategories) */
export type TravelByCategoriesTitleArgs = {
  locale?: InputMaybe<Scalars['String']>
}

export type TravelByCategoriesCollection = {
  __typename?: 'TravelByCategoriesCollection'
  items: Array<Maybe<TravelByCategories>>
  limit: Scalars['Int']
  skip: Scalars['Int']
  total: Scalars['Int']
}

export type TravelByCategoriesFilter = {
  AND?: InputMaybe<Array<InputMaybe<TravelByCategoriesFilter>>>
  OR?: InputMaybe<Array<InputMaybe<TravelByCategoriesFilter>>>
  contentfulMetadata?: InputMaybe<ContentfulMetadataFilter>
  featuredImage_exists?: InputMaybe<Scalars['Boolean']>
  mobileFeaturedImage_exists?: InputMaybe<Scalars['Boolean']>
  slug?: InputMaybe<Scalars['String']>
  slug_contains?: InputMaybe<Scalars['String']>
  slug_exists?: InputMaybe<Scalars['Boolean']>
  slug_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  slug_not?: InputMaybe<Scalars['String']>
  slug_not_contains?: InputMaybe<Scalars['String']>
  slug_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  subcategories?: InputMaybe<CfTravelBySubcategoriesNestedFilter>
  subcategoriesCollection_exists?: InputMaybe<Scalars['Boolean']>
  sys?: InputMaybe<SysFilter>
  title?: InputMaybe<Scalars['String']>
  title_contains?: InputMaybe<Scalars['String']>
  title_exists?: InputMaybe<Scalars['Boolean']>
  title_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  title_not?: InputMaybe<Scalars['String']>
  title_not_contains?: InputMaybe<Scalars['String']>
  title_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
}

export type TravelByCategoriesLinkingCollections = {
  __typename?: 'TravelByCategoriesLinkingCollections'
  entryCollection?: Maybe<EntryCollection>
  entryTestimonialCollection?: Maybe<EntryTestimonialCollection>
  templateTravelByCollection?: Maybe<TemplateTravelByCollection>
}

export type TravelByCategoriesLinkingCollectionsEntryCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>
  locale?: InputMaybe<Scalars['String']>
  preview?: InputMaybe<Scalars['Boolean']>
  skip?: InputMaybe<Scalars['Int']>
}

export type TravelByCategoriesLinkingCollectionsEntryTestimonialCollectionArgs =
  {
    limit?: InputMaybe<Scalars['Int']>
    locale?: InputMaybe<Scalars['String']>
    order?: InputMaybe<
      Array<
        InputMaybe<TravelByCategoriesLinkingCollectionsEntryTestimonialCollectionOrder>
      >
    >
    preview?: InputMaybe<Scalars['Boolean']>
    skip?: InputMaybe<Scalars['Int']>
  }

export type TravelByCategoriesLinkingCollectionsTemplateTravelByCollectionArgs =
  {
    limit?: InputMaybe<Scalars['Int']>
    locale?: InputMaybe<Scalars['String']>
    order?: InputMaybe<
      Array<
        InputMaybe<TravelByCategoriesLinkingCollectionsTemplateTravelByCollectionOrder>
      >
    >
    preview?: InputMaybe<Scalars['Boolean']>
    skip?: InputMaybe<Scalars['Int']>
  }

export enum TravelByCategoriesLinkingCollectionsEntryTestimonialCollectionOrder {
  AboutForaAsc = 'aboutFora_ASC',
  AboutForaDesc = 'aboutFora_DESC',
  BeginTravelDateAsc = 'beginTravelDate_ASC',
  BeginTravelDateDesc = 'beginTravelDate_DESC',
  DateSubmittedAsc = 'dateSubmitted_ASC',
  DateSubmittedDesc = 'dateSubmitted_DESC',
  DisplayAsc = 'display_ASC',
  DisplayDesc = 'display_DESC',
  EndTravelDateAsc = 'endTravelDate_ASC',
  EndTravelDateDesc = 'endTravelDate_DESC',
  InternalLabelAsc = 'internalLabel_ASC',
  InternalLabelDesc = 'internalLabel_DESC',
  IsFeaturedAsc = 'isFeatured_ASC',
  IsFeaturedDesc = 'isFeatured_DESC',
  StarsAsc = 'stars_ASC',
  StarsDesc = 'stars_DESC',
  SubmitterEmailAsc = 'submitterEmail_ASC',
  SubmitterEmailDesc = 'submitterEmail_DESC',
  SubmitterFirstNameAsc = 'submitterFirstName_ASC',
  SubmitterFirstNameDesc = 'submitterFirstName_DESC',
  SubmitterLastNameAsc = 'submitterLastName_ASC',
  SubmitterLastNameDesc = 'submitterLastName_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  TestimonialSourceAsc = 'testimonialSource_ASC',
  TestimonialSourceDesc = 'testimonialSource_DESC',
  TitleOfReviewAsc = 'titleOfReview_ASC',
  TitleOfReviewDesc = 'titleOfReview_DESC',
}

export enum TravelByCategoriesLinkingCollectionsTemplateTravelByCollectionOrder {
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC',
}

export enum TravelByCategoriesOrder {
  SlugAsc = 'slug_ASC',
  SlugDesc = 'slug_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC',
}

export type TravelByCategoriesSubcategoriesCollection = {
  __typename?: 'TravelByCategoriesSubcategoriesCollection'
  items: Array<Maybe<TravelBySubcategories>>
  limit: Scalars['Int']
  skip: Scalars['Int']
  total: Scalars['Int']
}

export enum TravelByCategoriesSubcategoriesCollectionOrder {
  GenerateLandingPageAsc = 'generateLandingPage_ASC',
  GenerateLandingPageDesc = 'generateLandingPage_DESC',
  ShowInGuidesCategoryListAsc = 'showInGuidesCategoryList_ASC',
  ShowInGuidesCategoryListDesc = 'showInGuidesCategoryList_DESC',
  ShowInItinerariesCategoryListAsc = 'showInItinerariesCategoryList_ASC',
  ShowInItinerariesCategoryListDesc = 'showInItinerariesCategoryList_DESC',
  ShowInStoriesCategoryListAsc = 'showInStoriesCategoryList_ASC',
  ShowInStoriesCategoryListDesc = 'showInStoriesCategoryList_DESC',
  SlugAsc = 'slug_ASC',
  SlugDesc = 'slug_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC',
}

/** Create and manage child-level Travel By subcategories and nested relationships. Subcategories are sometimes used for landing pages and make use of slugs in URL segments [See type definition](https://app.contentful.com/spaces/9cxgygtadmvl/content_types/travelBySubcategories) */
export type TravelBySubcategories = Entry &
  _Node & {
    __typename?: 'TravelBySubcategories'
    _id: Scalars['ID']
    contentfulMetadata: ContentfulMetadata
    featuredImage?: Maybe<Asset>
    generateLandingPage?: Maybe<Scalars['Boolean']>
    linkedFrom?: Maybe<TravelBySubcategoriesLinkingCollections>
    metaContent?: Maybe<PagesMetaContent>
    questionsCollection?: Maybe<TravelBySubcategoriesQuestionsCollection>
    showInGuidesCategoryList?: Maybe<Scalars['Boolean']>
    showInItinerariesCategoryList?: Maybe<Scalars['Boolean']>
    showInStoriesCategoryList?: Maybe<Scalars['Boolean']>
    slug?: Maybe<Scalars['String']>
    sys: Sys
    teaserCopy?: Maybe<TravelBySubcategoriesTeaserCopy>
    title?: Maybe<Scalars['String']>
  }

/** Create and manage child-level Travel By subcategories and nested relationships. Subcategories are sometimes used for landing pages and make use of slugs in URL segments [See type definition](https://app.contentful.com/spaces/9cxgygtadmvl/content_types/travelBySubcategories) */
export type TravelBySubcategoriesFeaturedImageArgs = {
  locale?: InputMaybe<Scalars['String']>
  preview?: InputMaybe<Scalars['Boolean']>
}

/** Create and manage child-level Travel By subcategories and nested relationships. Subcategories are sometimes used for landing pages and make use of slugs in URL segments [See type definition](https://app.contentful.com/spaces/9cxgygtadmvl/content_types/travelBySubcategories) */
export type TravelBySubcategoriesGenerateLandingPageArgs = {
  locale?: InputMaybe<Scalars['String']>
}

/** Create and manage child-level Travel By subcategories and nested relationships. Subcategories are sometimes used for landing pages and make use of slugs in URL segments [See type definition](https://app.contentful.com/spaces/9cxgygtadmvl/content_types/travelBySubcategories) */
export type TravelBySubcategoriesLinkedFromArgs = {
  allowedLocales?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
}

/** Create and manage child-level Travel By subcategories and nested relationships. Subcategories are sometimes used for landing pages and make use of slugs in URL segments [See type definition](https://app.contentful.com/spaces/9cxgygtadmvl/content_types/travelBySubcategories) */
export type TravelBySubcategoriesMetaContentArgs = {
  locale?: InputMaybe<Scalars['String']>
  preview?: InputMaybe<Scalars['Boolean']>
  where?: InputMaybe<PagesMetaContentFilter>
}

/** Create and manage child-level Travel By subcategories and nested relationships. Subcategories are sometimes used for landing pages and make use of slugs in URL segments [See type definition](https://app.contentful.com/spaces/9cxgygtadmvl/content_types/travelBySubcategories) */
export type TravelBySubcategoriesQuestionsCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>
  locale?: InputMaybe<Scalars['String']>
  order?: InputMaybe<
    Array<InputMaybe<TravelBySubcategoriesQuestionsCollectionOrder>>
  >
  preview?: InputMaybe<Scalars['Boolean']>
  skip?: InputMaybe<Scalars['Int']>
  where?: InputMaybe<FaqsQuestionsFilter>
}

/** Create and manage child-level Travel By subcategories and nested relationships. Subcategories are sometimes used for landing pages and make use of slugs in URL segments [See type definition](https://app.contentful.com/spaces/9cxgygtadmvl/content_types/travelBySubcategories) */
export type TravelBySubcategoriesShowInGuidesCategoryListArgs = {
  locale?: InputMaybe<Scalars['String']>
}

/** Create and manage child-level Travel By subcategories and nested relationships. Subcategories are sometimes used for landing pages and make use of slugs in URL segments [See type definition](https://app.contentful.com/spaces/9cxgygtadmvl/content_types/travelBySubcategories) */
export type TravelBySubcategoriesShowInItinerariesCategoryListArgs = {
  locale?: InputMaybe<Scalars['String']>
}

/** Create and manage child-level Travel By subcategories and nested relationships. Subcategories are sometimes used for landing pages and make use of slugs in URL segments [See type definition](https://app.contentful.com/spaces/9cxgygtadmvl/content_types/travelBySubcategories) */
export type TravelBySubcategoriesShowInStoriesCategoryListArgs = {
  locale?: InputMaybe<Scalars['String']>
}

/** Create and manage child-level Travel By subcategories and nested relationships. Subcategories are sometimes used for landing pages and make use of slugs in URL segments [See type definition](https://app.contentful.com/spaces/9cxgygtadmvl/content_types/travelBySubcategories) */
export type TravelBySubcategoriesSlugArgs = {
  locale?: InputMaybe<Scalars['String']>
}

/** Create and manage child-level Travel By subcategories and nested relationships. Subcategories are sometimes used for landing pages and make use of slugs in URL segments [See type definition](https://app.contentful.com/spaces/9cxgygtadmvl/content_types/travelBySubcategories) */
export type TravelBySubcategoriesTeaserCopyArgs = {
  locale?: InputMaybe<Scalars['String']>
}

/** Create and manage child-level Travel By subcategories and nested relationships. Subcategories are sometimes used for landing pages and make use of slugs in URL segments [See type definition](https://app.contentful.com/spaces/9cxgygtadmvl/content_types/travelBySubcategories) */
export type TravelBySubcategoriesTitleArgs = {
  locale?: InputMaybe<Scalars['String']>
}

export type TravelBySubcategoriesCollection = {
  __typename?: 'TravelBySubcategoriesCollection'
  items: Array<Maybe<TravelBySubcategories>>
  limit: Scalars['Int']
  skip: Scalars['Int']
  total: Scalars['Int']
}

export type TravelBySubcategoriesFilter = {
  AND?: InputMaybe<Array<InputMaybe<TravelBySubcategoriesFilter>>>
  OR?: InputMaybe<Array<InputMaybe<TravelBySubcategoriesFilter>>>
  contentfulMetadata?: InputMaybe<ContentfulMetadataFilter>
  featuredImage_exists?: InputMaybe<Scalars['Boolean']>
  generateLandingPage?: InputMaybe<Scalars['Boolean']>
  generateLandingPage_exists?: InputMaybe<Scalars['Boolean']>
  generateLandingPage_not?: InputMaybe<Scalars['Boolean']>
  metaContent?: InputMaybe<CfPagesMetaContentNestedFilter>
  metaContent_exists?: InputMaybe<Scalars['Boolean']>
  questions?: InputMaybe<CfFaqsQuestionsNestedFilter>
  questionsCollection_exists?: InputMaybe<Scalars['Boolean']>
  showInGuidesCategoryList?: InputMaybe<Scalars['Boolean']>
  showInGuidesCategoryList_exists?: InputMaybe<Scalars['Boolean']>
  showInGuidesCategoryList_not?: InputMaybe<Scalars['Boolean']>
  showInItinerariesCategoryList?: InputMaybe<Scalars['Boolean']>
  showInItinerariesCategoryList_exists?: InputMaybe<Scalars['Boolean']>
  showInItinerariesCategoryList_not?: InputMaybe<Scalars['Boolean']>
  showInStoriesCategoryList?: InputMaybe<Scalars['Boolean']>
  showInStoriesCategoryList_exists?: InputMaybe<Scalars['Boolean']>
  showInStoriesCategoryList_not?: InputMaybe<Scalars['Boolean']>
  slug?: InputMaybe<Scalars['String']>
  slug_contains?: InputMaybe<Scalars['String']>
  slug_exists?: InputMaybe<Scalars['Boolean']>
  slug_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  slug_not?: InputMaybe<Scalars['String']>
  slug_not_contains?: InputMaybe<Scalars['String']>
  slug_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  sys?: InputMaybe<SysFilter>
  teaserCopy_contains?: InputMaybe<Scalars['String']>
  teaserCopy_exists?: InputMaybe<Scalars['Boolean']>
  teaserCopy_not_contains?: InputMaybe<Scalars['String']>
  title?: InputMaybe<Scalars['String']>
  title_contains?: InputMaybe<Scalars['String']>
  title_exists?: InputMaybe<Scalars['Boolean']>
  title_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  title_not?: InputMaybe<Scalars['String']>
  title_not_contains?: InputMaybe<Scalars['String']>
  title_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
}

export type TravelBySubcategoriesLinkingCollections = {
  __typename?: 'TravelBySubcategoriesLinkingCollections'
  advisorsCollection?: Maybe<AdvisorsCollection>
  blogPostsCollection?: Maybe<BlogPostsCollection>
  entryCollection?: Maybe<EntryCollection>
  entryTestimonialCollection?: Maybe<EntryTestimonialCollection>
  entryTripReportCollection?: Maybe<EntryTripReportCollection>
  itinerariesCollection?: Maybe<ItinerariesCollection>
  travelByCategoriesCollection?: Maybe<TravelByCategoriesCollection>
}

export type TravelBySubcategoriesLinkingCollectionsAdvisorsCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>
  locale?: InputMaybe<Scalars['String']>
  order?: InputMaybe<
    Array<
      InputMaybe<TravelBySubcategoriesLinkingCollectionsAdvisorsCollectionOrder>
    >
  >
  preview?: InputMaybe<Scalars['Boolean']>
  skip?: InputMaybe<Scalars['Int']>
}

export type TravelBySubcategoriesLinkingCollectionsBlogPostsCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>
  locale?: InputMaybe<Scalars['String']>
  order?: InputMaybe<
    Array<
      InputMaybe<TravelBySubcategoriesLinkingCollectionsBlogPostsCollectionOrder>
    >
  >
  preview?: InputMaybe<Scalars['Boolean']>
  skip?: InputMaybe<Scalars['Int']>
}

export type TravelBySubcategoriesLinkingCollectionsEntryCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>
  locale?: InputMaybe<Scalars['String']>
  preview?: InputMaybe<Scalars['Boolean']>
  skip?: InputMaybe<Scalars['Int']>
}

export type TravelBySubcategoriesLinkingCollectionsEntryTestimonialCollectionArgs =
  {
    limit?: InputMaybe<Scalars['Int']>
    locale?: InputMaybe<Scalars['String']>
    order?: InputMaybe<
      Array<
        InputMaybe<TravelBySubcategoriesLinkingCollectionsEntryTestimonialCollectionOrder>
      >
    >
    preview?: InputMaybe<Scalars['Boolean']>
    skip?: InputMaybe<Scalars['Int']>
  }

export type TravelBySubcategoriesLinkingCollectionsEntryTripReportCollectionArgs =
  {
    limit?: InputMaybe<Scalars['Int']>
    locale?: InputMaybe<Scalars['String']>
    order?: InputMaybe<
      Array<
        InputMaybe<TravelBySubcategoriesLinkingCollectionsEntryTripReportCollectionOrder>
      >
    >
    preview?: InputMaybe<Scalars['Boolean']>
    skip?: InputMaybe<Scalars['Int']>
  }

export type TravelBySubcategoriesLinkingCollectionsItinerariesCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>
  locale?: InputMaybe<Scalars['String']>
  order?: InputMaybe<
    Array<
      InputMaybe<TravelBySubcategoriesLinkingCollectionsItinerariesCollectionOrder>
    >
  >
  preview?: InputMaybe<Scalars['Boolean']>
  skip?: InputMaybe<Scalars['Int']>
}

export type TravelBySubcategoriesLinkingCollectionsTravelByCategoriesCollectionArgs =
  {
    limit?: InputMaybe<Scalars['Int']>
    locale?: InputMaybe<Scalars['String']>
    order?: InputMaybe<
      Array<
        InputMaybe<TravelBySubcategoriesLinkingCollectionsTravelByCategoriesCollectionOrder>
      >
    >
    preview?: InputMaybe<Scalars['Boolean']>
    skip?: InputMaybe<Scalars['Int']>
  }

export enum TravelBySubcategoriesLinkingCollectionsAdvisorsCollectionOrder {
  AdvisorContactEnabledAsc = 'advisorContactEnabled_ASC',
  AdvisorContactEnabledDesc = 'advisorContactEnabled_DESC',
  AdvisorVariantAsc = 'advisorVariant_ASC',
  AdvisorVariantDesc = 'advisorVariant_DESC',
  EmailAddressAsc = 'emailAddress_ASC',
  EmailAddressDesc = 'emailAddress_DESC',
  FacebookUrlAsc = 'facebookUrl_ASC',
  FacebookUrlDesc = 'facebookUrl_DESC',
  FirstNameAsc = 'firstName_ASC',
  FirstNameDesc = 'firstName_DESC',
  ForaAdvisorHotelsAsc = 'foraAdvisorHotels_ASC',
  ForaAdvisorHotelsDesc = 'foraAdvisorHotels_DESC',
  ForaAdvisorIdAsc = 'foraAdvisorId_ASC',
  ForaAdvisorIdDesc = 'foraAdvisorId_DESC',
  InstagramUrlAsc = 'instagramUrl_ASC',
  InstagramUrlDesc = 'instagramUrl_DESC',
  LastNameAsc = 'lastName_ASC',
  LastNameDesc = 'lastName_DESC',
  LinkedinUrlAsc = 'linkedinUrl_ASC',
  LinkedinUrlDesc = 'linkedinUrl_DESC',
  MinimumBookingAsc = 'minimumBooking_ASC',
  MinimumBookingDesc = 'minimumBooking_DESC',
  PinterestUrlAsc = 'pinterestUrl_ASC',
  PinterestUrlDesc = 'pinterestUrl_DESC',
  PronounsAsc = 'pronouns_ASC',
  PronounsDesc = 'pronouns_DESC',
  ShortDescriptionAsc = 'shortDescription_ASC',
  ShortDescriptionDesc = 'shortDescription_DESC',
  ShowOnAdvisorsPageAsc = 'showOnAdvisorsPage_ASC',
  ShowOnAdvisorsPageDesc = 'showOnAdvisorsPage_DESC',
  ShowOnHomepageAsc = 'showOnHomepage_ASC',
  ShowOnHomepageDesc = 'showOnHomepage_DESC',
  ShowOnOtherLandingPagesAsc = 'showOnOtherLandingPages_ASC',
  ShowOnOtherLandingPagesDesc = 'showOnOtherLandingPages_DESC',
  SlugAsc = 'slug_ASC',
  SlugDesc = 'slug_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  TierAsc = 'tier_ASC',
  TierDesc = 'tier_DESC',
  TikTokUrlAsc = 'tikTokUrl_ASC',
  TikTokUrlDesc = 'tikTokUrl_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC',
  TwitterUrlAsc = 'twitterUrl_ASC',
  TwitterUrlDesc = 'twitterUrl_DESC',
  WebsiteUrlAsc = 'websiteUrl_ASC',
  WebsiteUrlDesc = 'websiteUrl_DESC',
  YoutubeUrlAsc = 'youtubeUrl_ASC',
  YoutubeUrlDesc = 'youtubeUrl_DESC',
}

export enum TravelBySubcategoriesLinkingCollectionsBlogPostsCollectionOrder {
  BlogCategoryAsc = 'blogCategory_ASC',
  BlogCategoryDesc = 'blogCategory_DESC',
  BottomCtaAsc = 'bottomCta_ASC',
  BottomCtaDesc = 'bottomCta_DESC',
  EnableAdvisorShareAsc = 'enableAdvisorShare_ASC',
  EnableAdvisorShareDesc = 'enableAdvisorShare_DESC',
  MetaDescriptionAsc = 'metaDescription_ASC',
  MetaDescriptionDesc = 'metaDescription_DESC',
  MetaTitleAsc = 'metaTitle_ASC',
  MetaTitleDesc = 'metaTitle_DESC',
  NoindexNofollowAsc = 'noindexNofollow_ASC',
  NoindexNofollowDesc = 'noindexNofollow_DESC',
  PublishedDateAsc = 'publishedDate_ASC',
  PublishedDateDesc = 'publishedDate_DESC',
  ShowOnBlogCategoryPageAsc = 'showOnBlogCategoryPage_ASC',
  ShowOnBlogCategoryPageDesc = 'showOnBlogCategoryPage_DESC',
  ShowOnBlogLandingPageAsc = 'showOnBlogLandingPage_ASC',
  ShowOnBlogLandingPageDesc = 'showOnBlogLandingPage_DESC',
  ShowOnTravelPagesAsc = 'showOnTravelPages_ASC',
  ShowOnTravelPagesDesc = 'showOnTravelPages_DESC',
  SlugAsc = 'slug_ASC',
  SlugDesc = 'slug_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC',
}

export enum TravelBySubcategoriesLinkingCollectionsEntryTestimonialCollectionOrder {
  AboutForaAsc = 'aboutFora_ASC',
  AboutForaDesc = 'aboutFora_DESC',
  BeginTravelDateAsc = 'beginTravelDate_ASC',
  BeginTravelDateDesc = 'beginTravelDate_DESC',
  DateSubmittedAsc = 'dateSubmitted_ASC',
  DateSubmittedDesc = 'dateSubmitted_DESC',
  DisplayAsc = 'display_ASC',
  DisplayDesc = 'display_DESC',
  EndTravelDateAsc = 'endTravelDate_ASC',
  EndTravelDateDesc = 'endTravelDate_DESC',
  InternalLabelAsc = 'internalLabel_ASC',
  InternalLabelDesc = 'internalLabel_DESC',
  IsFeaturedAsc = 'isFeatured_ASC',
  IsFeaturedDesc = 'isFeatured_DESC',
  StarsAsc = 'stars_ASC',
  StarsDesc = 'stars_DESC',
  SubmitterEmailAsc = 'submitterEmail_ASC',
  SubmitterEmailDesc = 'submitterEmail_DESC',
  SubmitterFirstNameAsc = 'submitterFirstName_ASC',
  SubmitterFirstNameDesc = 'submitterFirstName_DESC',
  SubmitterLastNameAsc = 'submitterLastName_ASC',
  SubmitterLastNameDesc = 'submitterLastName_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  TestimonialSourceAsc = 'testimonialSource_ASC',
  TestimonialSourceDesc = 'testimonialSource_DESC',
  TitleOfReviewAsc = 'titleOfReview_ASC',
  TitleOfReviewDesc = 'titleOfReview_DESC',
}

export enum TravelBySubcategoriesLinkingCollectionsEntryTripReportCollectionOrder {
  LocationForTitlesAsc = 'locationForTitles_ASC',
  LocationForTitlesDesc = 'locationForTitles_DESC',
  MetaDescriptionAsc = 'metaDescription_ASC',
  MetaDescriptionDesc = 'metaDescription_DESC',
  MetaTitleAsc = 'metaTitle_ASC',
  MetaTitleDesc = 'metaTitle_DESC',
  SeoSlugAsc = 'seoSlug_ASC',
  SeoSlugDesc = 'seoSlug_DESC',
  ShowOnGuidesAndDestinationsPageAsc = 'showOnGuidesAndDestinationsPage_ASC',
  ShowOnGuidesAndDestinationsPageDesc = 'showOnGuidesAndDestinationsPage_DESC',
  ShowOnHomepageAsc = 'showOnHomepage_ASC',
  ShowOnHomepageDesc = 'showOnHomepage_DESC',
  ShowOnOtherLandingPagesAsc = 'showOnOtherLandingPages_ASC',
  ShowOnOtherLandingPagesDesc = 'showOnOtherLandingPages_DESC',
  SlugIdAsc = 'slugID_ASC',
  SlugIdDesc = 'slugID_DESC',
  SlugAsc = 'slug_ASC',
  SlugDesc = 'slug_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  TestUpdatedSlugIdAsc = 'testUpdatedSlugId_ASC',
  TestUpdatedSlugIdDesc = 'testUpdatedSlugId_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC',
}

export enum TravelBySubcategoriesLinkingCollectionsItinerariesCollectionOrder {
  ItineraryVariantAsc = 'itineraryVariant_ASC',
  ItineraryVariantDesc = 'itineraryVariant_DESC',
  LocationForTitlesAsc = 'locationForTitles_ASC',
  LocationForTitlesDesc = 'locationForTitles_DESC',
  MetaDescriptionAsc = 'metaDescription_ASC',
  MetaDescriptionDesc = 'metaDescription_DESC',
  MetaTitleAsc = 'metaTitle_ASC',
  MetaTitleDesc = 'metaTitle_DESC',
  SeoSlugAsc = 'seoSlug_ASC',
  SeoSlugDesc = 'seoSlug_DESC',
  ShowOnHomepageAsc = 'showOnHomepage_ASC',
  ShowOnHomepageDesc = 'showOnHomepage_DESC',
  ShowOnItinerariesPageAsc = 'showOnItinerariesPage_ASC',
  ShowOnItinerariesPageDesc = 'showOnItinerariesPage_DESC',
  ShowOnOtherLandingPagesAsc = 'showOnOtherLandingPages_ASC',
  ShowOnOtherLandingPagesDesc = 'showOnOtherLandingPages_DESC',
  SlugIdAsc = 'slugID_ASC',
  SlugIdDesc = 'slugID_DESC',
  SlugAsc = 'slug_ASC',
  SlugDesc = 'slug_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC',
}

export enum TravelBySubcategoriesLinkingCollectionsTravelByCategoriesCollectionOrder {
  SlugAsc = 'slug_ASC',
  SlugDesc = 'slug_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC',
}

export enum TravelBySubcategoriesOrder {
  GenerateLandingPageAsc = 'generateLandingPage_ASC',
  GenerateLandingPageDesc = 'generateLandingPage_DESC',
  ShowInGuidesCategoryListAsc = 'showInGuidesCategoryList_ASC',
  ShowInGuidesCategoryListDesc = 'showInGuidesCategoryList_DESC',
  ShowInItinerariesCategoryListAsc = 'showInItinerariesCategoryList_ASC',
  ShowInItinerariesCategoryListDesc = 'showInItinerariesCategoryList_DESC',
  ShowInStoriesCategoryListAsc = 'showInStoriesCategoryList_ASC',
  ShowInStoriesCategoryListDesc = 'showInStoriesCategoryList_DESC',
  SlugAsc = 'slug_ASC',
  SlugDesc = 'slug_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC',
}

export type TravelBySubcategoriesQuestionsCollection = {
  __typename?: 'TravelBySubcategoriesQuestionsCollection'
  items: Array<Maybe<FaqsQuestions>>
  limit: Scalars['Int']
  skip: Scalars['Int']
  total: Scalars['Int']
}

export enum TravelBySubcategoriesQuestionsCollectionOrder {
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC',
}

export type TravelBySubcategoriesTeaserCopy = {
  __typename?: 'TravelBySubcategoriesTeaserCopy'
  json: Scalars['JSON']
  links: TravelBySubcategoriesTeaserCopyLinks
}

export type TravelBySubcategoriesTeaserCopyAssets = {
  __typename?: 'TravelBySubcategoriesTeaserCopyAssets'
  block: Array<Maybe<Asset>>
  hyperlink: Array<Maybe<Asset>>
}

export type TravelBySubcategoriesTeaserCopyEntries = {
  __typename?: 'TravelBySubcategoriesTeaserCopyEntries'
  block: Array<Maybe<Entry>>
  hyperlink: Array<Maybe<Entry>>
  inline: Array<Maybe<Entry>>
}

export type TravelBySubcategoriesTeaserCopyLinks = {
  __typename?: 'TravelBySubcategoriesTeaserCopyLinks'
  assets: TravelBySubcategoriesTeaserCopyAssets
  entries: TravelBySubcategoriesTeaserCopyEntries
  resources: TravelBySubcategoriesTeaserCopyResources
}

export type TravelBySubcategoriesTeaserCopyResources = {
  __typename?: 'TravelBySubcategoriesTeaserCopyResources'
  block: Array<TravelBySubcategoriesTeaserCopyResourcesBlock>
  hyperlink: Array<TravelBySubcategoriesTeaserCopyResourcesHyperlink>
  inline: Array<TravelBySubcategoriesTeaserCopyResourcesInline>
}

export type TravelBySubcategoriesTeaserCopyResourcesBlock = ResourceLink & {
  __typename?: 'TravelBySubcategoriesTeaserCopyResourcesBlock'
  sys: ResourceSys
}

export type TravelBySubcategoriesTeaserCopyResourcesHyperlink = ResourceLink & {
  __typename?: 'TravelBySubcategoriesTeaserCopyResourcesHyperlink'
  sys: ResourceSys
}

export type TravelBySubcategoriesTeaserCopyResourcesInline = ResourceLink & {
  __typename?: 'TravelBySubcategoriesTeaserCopyResourcesInline'
  sys: ResourceSys
}

export type _Node = {
  _id: Scalars['ID']
}

export type CfAdvisorReviewsNestedFilter = {
  AND?: InputMaybe<Array<InputMaybe<CfAdvisorReviewsNestedFilter>>>
  OR?: InputMaybe<Array<InputMaybe<CfAdvisorReviewsNestedFilter>>>
  Review?: InputMaybe<Scalars['String']>
  Review_contains?: InputMaybe<Scalars['String']>
  Review_exists?: InputMaybe<Scalars['Boolean']>
  Review_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  Review_not?: InputMaybe<Scalars['String']>
  Review_not_contains?: InputMaybe<Scalars['String']>
  Review_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  advisor_exists?: InputMaybe<Scalars['Boolean']>
  contentfulMetadata?: InputMaybe<ContentfulMetadataFilter>
  eyebrowText?: InputMaybe<Scalars['String']>
  eyebrowText_contains?: InputMaybe<Scalars['String']>
  eyebrowText_exists?: InputMaybe<Scalars['Boolean']>
  eyebrowText_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  eyebrowText_not?: InputMaybe<Scalars['String']>
  eyebrowText_not_contains?: InputMaybe<Scalars['String']>
  eyebrowText_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  internalLabel?: InputMaybe<Scalars['String']>
  internalLabel_contains?: InputMaybe<Scalars['String']>
  internalLabel_exists?: InputMaybe<Scalars['Boolean']>
  internalLabel_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  internalLabel_not?: InputMaybe<Scalars['String']>
  internalLabel_not_contains?: InputMaybe<Scalars['String']>
  internalLabel_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  mediaAsset_exists?: InputMaybe<Scalars['Boolean']>
  mobileMediaAsset_exists?: InputMaybe<Scalars['Boolean']>
  mobilePreviewImage_exists?: InputMaybe<Scalars['Boolean']>
  sys?: InputMaybe<SysFilter>
}

export type CfAdvisorsNestedFilter = {
  AND?: InputMaybe<Array<InputMaybe<CfAdvisorsNestedFilter>>>
  OR?: InputMaybe<Array<InputMaybe<CfAdvisorsNestedFilter>>>
  aboutMeHomeAirport_contains?: InputMaybe<Scalars['String']>
  aboutMeHomeAirport_exists?: InputMaybe<Scalars['Boolean']>
  aboutMeHomeAirport_not_contains?: InputMaybe<Scalars['String']>
  aboutMeIAm_contains?: InputMaybe<Scalars['String']>
  aboutMeIAm_exists?: InputMaybe<Scalars['Boolean']>
  aboutMeIAm_not_contains?: InputMaybe<Scalars['String']>
  advisorContactEnabled?: InputMaybe<Scalars['Boolean']>
  advisorContactEnabled_exists?: InputMaybe<Scalars['Boolean']>
  advisorContactEnabled_not?: InputMaybe<Scalars['Boolean']>
  advisorVariant?: InputMaybe<Scalars['String']>
  advisorVariant_contains?: InputMaybe<Scalars['String']>
  advisorVariant_exists?: InputMaybe<Scalars['Boolean']>
  advisorVariant_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  advisorVariant_not?: InputMaybe<Scalars['String']>
  advisorVariant_not_contains?: InputMaybe<Scalars['String']>
  advisorVariant_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  basedIn_contains?: InputMaybe<Scalars['String']>
  basedIn_exists?: InputMaybe<Scalars['Boolean']>
  basedIn_not_contains?: InputMaybe<Scalars['String']>
  categoriesCollection_exists?: InputMaybe<Scalars['Boolean']>
  cloudinaryImage_exists?: InputMaybe<Scalars['Boolean']>
  contentfulMetadata?: InputMaybe<ContentfulMetadataFilter>
  emailAddress?: InputMaybe<Scalars['String']>
  emailAddress_contains?: InputMaybe<Scalars['String']>
  emailAddress_exists?: InputMaybe<Scalars['Boolean']>
  emailAddress_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  emailAddress_not?: InputMaybe<Scalars['String']>
  emailAddress_not_contains?: InputMaybe<Scalars['String']>
  emailAddress_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  expertise_contains_all?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  expertise_contains_none?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  expertise_contains_some?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  expertise_exists?: InputMaybe<Scalars['Boolean']>
  facebookUrl?: InputMaybe<Scalars['String']>
  facebookUrl_contains?: InputMaybe<Scalars['String']>
  facebookUrl_exists?: InputMaybe<Scalars['Boolean']>
  facebookUrl_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  facebookUrl_not?: InputMaybe<Scalars['String']>
  facebookUrl_not_contains?: InputMaybe<Scalars['String']>
  facebookUrl_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  firstName?: InputMaybe<Scalars['String']>
  firstName_contains?: InputMaybe<Scalars['String']>
  firstName_exists?: InputMaybe<Scalars['Boolean']>
  firstName_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  firstName_not?: InputMaybe<Scalars['String']>
  firstName_not_contains?: InputMaybe<Scalars['String']>
  firstName_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  foraAdvisorHotels?: InputMaybe<Scalars['String']>
  foraAdvisorHotels_contains?: InputMaybe<Scalars['String']>
  foraAdvisorHotels_exists?: InputMaybe<Scalars['Boolean']>
  foraAdvisorHotels_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  foraAdvisorHotels_not?: InputMaybe<Scalars['String']>
  foraAdvisorHotels_not_contains?: InputMaybe<Scalars['String']>
  foraAdvisorHotels_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  foraAdvisorId?: InputMaybe<Scalars['String']>
  foraAdvisorId_contains?: InputMaybe<Scalars['String']>
  foraAdvisorId_exists?: InputMaybe<Scalars['Boolean']>
  foraAdvisorId_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  foraAdvisorId_not?: InputMaybe<Scalars['String']>
  foraAdvisorId_not_contains?: InputMaybe<Scalars['String']>
  foraAdvisorId_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  hotelsCollection_exists?: InputMaybe<Scalars['Boolean']>
  imageCarouselCollection_exists?: InputMaybe<Scalars['Boolean']>
  image_exists?: InputMaybe<Scalars['Boolean']>
  instagramUrl?: InputMaybe<Scalars['String']>
  instagramUrl_contains?: InputMaybe<Scalars['String']>
  instagramUrl_exists?: InputMaybe<Scalars['Boolean']>
  instagramUrl_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  instagramUrl_not?: InputMaybe<Scalars['String']>
  instagramUrl_not_contains?: InputMaybe<Scalars['String']>
  instagramUrl_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  internalComments?: InputMaybe<Scalars['String']>
  internalComments_contains?: InputMaybe<Scalars['String']>
  internalComments_exists?: InputMaybe<Scalars['Boolean']>
  internalComments_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  internalComments_not?: InputMaybe<Scalars['String']>
  internalComments_not_contains?: InputMaybe<Scalars['String']>
  internalComments_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  languagesSpoken_contains?: InputMaybe<Scalars['String']>
  languagesSpoken_exists?: InputMaybe<Scalars['Boolean']>
  languagesSpoken_not_contains?: InputMaybe<Scalars['String']>
  lastName?: InputMaybe<Scalars['String']>
  lastName_contains?: InputMaybe<Scalars['String']>
  lastName_exists?: InputMaybe<Scalars['Boolean']>
  lastName_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  lastName_not?: InputMaybe<Scalars['String']>
  lastName_not_contains?: InputMaybe<Scalars['String']>
  lastName_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  linkedinUrl?: InputMaybe<Scalars['String']>
  linkedinUrl_contains?: InputMaybe<Scalars['String']>
  linkedinUrl_exists?: InputMaybe<Scalars['Boolean']>
  linkedinUrl_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  linkedinUrl_not?: InputMaybe<Scalars['String']>
  linkedinUrl_not_contains?: InputMaybe<Scalars['String']>
  linkedinUrl_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  mailChimpForm?: InputMaybe<Scalars['String']>
  mailChimpForm_contains?: InputMaybe<Scalars['String']>
  mailChimpForm_exists?: InputMaybe<Scalars['Boolean']>
  mailChimpForm_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  mailChimpForm_not?: InputMaybe<Scalars['String']>
  mailChimpForm_not_contains?: InputMaybe<Scalars['String']>
  mailChimpForm_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  minimumBooking?: InputMaybe<Scalars['Int']>
  minimumBooking_exists?: InputMaybe<Scalars['Boolean']>
  minimumBooking_gt?: InputMaybe<Scalars['Int']>
  minimumBooking_gte?: InputMaybe<Scalars['Int']>
  minimumBooking_in?: InputMaybe<Array<InputMaybe<Scalars['Int']>>>
  minimumBooking_lt?: InputMaybe<Scalars['Int']>
  minimumBooking_lte?: InputMaybe<Scalars['Int']>
  minimumBooking_not?: InputMaybe<Scalars['Int']>
  minimumBooking_not_in?: InputMaybe<Array<InputMaybe<Scalars['Int']>>>
  myTravelStyleCantLiveWithout_contains?: InputMaybe<Scalars['String']>
  myTravelStyleCantLiveWithout_exists?: InputMaybe<Scalars['Boolean']>
  myTravelStyleCantLiveWithout_not_contains?: InputMaybe<Scalars['String']>
  myTravelStyleDreamingOf_contains?: InputMaybe<Scalars['String']>
  myTravelStyleDreamingOf_exists?: InputMaybe<Scalars['Boolean']>
  myTravelStyleDreamingOf_not_contains?: InputMaybe<Scalars['String']>
  myTravelStyleQuote_contains?: InputMaybe<Scalars['String']>
  myTravelStyleQuote_exists?: InputMaybe<Scalars['Boolean']>
  myTravelStyleQuote_not_contains?: InputMaybe<Scalars['String']>
  myTravelStyleRecentlyReturnedFrom_contains?: InputMaybe<Scalars['String']>
  myTravelStyleRecentlyReturnedFrom_exists?: InputMaybe<Scalars['Boolean']>
  myTravelStyleRecentlyReturnedFrom_not_contains?: InputMaybe<Scalars['String']>
  myTravelStyleTwoTruthsOneLie_contains?: InputMaybe<Scalars['String']>
  myTravelStyleTwoTruthsOneLie_exists?: InputMaybe<Scalars['Boolean']>
  myTravelStyleTwoTruthsOneLie_not_contains?: InputMaybe<Scalars['String']>
  pinterestUrl?: InputMaybe<Scalars['String']>
  pinterestUrl_contains?: InputMaybe<Scalars['String']>
  pinterestUrl_exists?: InputMaybe<Scalars['Boolean']>
  pinterestUrl_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  pinterestUrl_not?: InputMaybe<Scalars['String']>
  pinterestUrl_not_contains?: InputMaybe<Scalars['String']>
  pinterestUrl_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  preferredGuidesCollection_exists?: InputMaybe<Scalars['Boolean']>
  pronouns?: InputMaybe<Scalars['String']>
  pronouns_contains?: InputMaybe<Scalars['String']>
  pronouns_exists?: InputMaybe<Scalars['Boolean']>
  pronouns_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  pronouns_not?: InputMaybe<Scalars['String']>
  pronouns_not_contains?: InputMaybe<Scalars['String']>
  pronouns_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  shortDescription?: InputMaybe<Scalars['String']>
  shortDescription_contains?: InputMaybe<Scalars['String']>
  shortDescription_exists?: InputMaybe<Scalars['Boolean']>
  shortDescription_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  shortDescription_not?: InputMaybe<Scalars['String']>
  shortDescription_not_contains?: InputMaybe<Scalars['String']>
  shortDescription_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  showOnAdvisorsPage?: InputMaybe<Scalars['Boolean']>
  showOnAdvisorsPage_exists?: InputMaybe<Scalars['Boolean']>
  showOnAdvisorsPage_not?: InputMaybe<Scalars['Boolean']>
  showOnHomepage?: InputMaybe<Scalars['Boolean']>
  showOnHomepage_exists?: InputMaybe<Scalars['Boolean']>
  showOnHomepage_not?: InputMaybe<Scalars['Boolean']>
  showOnOtherLandingPages?: InputMaybe<Scalars['Boolean']>
  showOnOtherLandingPages_exists?: InputMaybe<Scalars['Boolean']>
  showOnOtherLandingPages_not?: InputMaybe<Scalars['Boolean']>
  slug?: InputMaybe<Scalars['String']>
  slug_contains?: InputMaybe<Scalars['String']>
  slug_exists?: InputMaybe<Scalars['Boolean']>
  slug_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  slug_not?: InputMaybe<Scalars['String']>
  slug_not_contains?: InputMaybe<Scalars['String']>
  slug_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  sys?: InputMaybe<SysFilter>
  tier?: InputMaybe<Scalars['String']>
  tier_contains?: InputMaybe<Scalars['String']>
  tier_exists?: InputMaybe<Scalars['Boolean']>
  tier_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  tier_not?: InputMaybe<Scalars['String']>
  tier_not_contains?: InputMaybe<Scalars['String']>
  tier_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  tikTokUrl?: InputMaybe<Scalars['String']>
  tikTokUrl_contains?: InputMaybe<Scalars['String']>
  tikTokUrl_exists?: InputMaybe<Scalars['Boolean']>
  tikTokUrl_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  tikTokUrl_not?: InputMaybe<Scalars['String']>
  tikTokUrl_not_contains?: InputMaybe<Scalars['String']>
  tikTokUrl_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  title?: InputMaybe<Scalars['String']>
  title_contains?: InputMaybe<Scalars['String']>
  title_exists?: InputMaybe<Scalars['Boolean']>
  title_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  title_not?: InputMaybe<Scalars['String']>
  title_not_contains?: InputMaybe<Scalars['String']>
  title_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  twitterUrl?: InputMaybe<Scalars['String']>
  twitterUrl_contains?: InputMaybe<Scalars['String']>
  twitterUrl_exists?: InputMaybe<Scalars['Boolean']>
  twitterUrl_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  twitterUrl_not?: InputMaybe<Scalars['String']>
  twitterUrl_not_contains?: InputMaybe<Scalars['String']>
  twitterUrl_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  websiteUrl?: InputMaybe<Scalars['String']>
  websiteUrl_contains?: InputMaybe<Scalars['String']>
  websiteUrl_exists?: InputMaybe<Scalars['Boolean']>
  websiteUrl_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  websiteUrl_not?: InputMaybe<Scalars['String']>
  websiteUrl_not_contains?: InputMaybe<Scalars['String']>
  websiteUrl_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  youtubeUrl?: InputMaybe<Scalars['String']>
  youtubeUrl_contains?: InputMaybe<Scalars['String']>
  youtubeUrl_exists?: InputMaybe<Scalars['Boolean']>
  youtubeUrl_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  youtubeUrl_not?: InputMaybe<Scalars['String']>
  youtubeUrl_not_contains?: InputMaybe<Scalars['String']>
  youtubeUrl_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
}

export type CfBlogAuthorsNestedFilter = {
  AND?: InputMaybe<Array<InputMaybe<CfBlogAuthorsNestedFilter>>>
  OR?: InputMaybe<Array<InputMaybe<CfBlogAuthorsNestedFilter>>>
  bio_contains?: InputMaybe<Scalars['String']>
  bio_exists?: InputMaybe<Scalars['Boolean']>
  bio_not_contains?: InputMaybe<Scalars['String']>
  contentfulMetadata?: InputMaybe<ContentfulMetadataFilter>
  image_exists?: InputMaybe<Scalars['Boolean']>
  jobTitle?: InputMaybe<Scalars['String']>
  jobTitle_contains?: InputMaybe<Scalars['String']>
  jobTitle_exists?: InputMaybe<Scalars['Boolean']>
  jobTitle_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  jobTitle_not?: InputMaybe<Scalars['String']>
  jobTitle_not_contains?: InputMaybe<Scalars['String']>
  jobTitle_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  name?: InputMaybe<Scalars['String']>
  name_contains?: InputMaybe<Scalars['String']>
  name_exists?: InputMaybe<Scalars['Boolean']>
  name_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  name_not?: InputMaybe<Scalars['String']>
  name_not_contains?: InputMaybe<Scalars['String']>
  name_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  sys?: InputMaybe<SysFilter>
}

export type CfBlogPostsNestedFilter = {
  AND?: InputMaybe<Array<InputMaybe<CfBlogPostsNestedFilter>>>
  OR?: InputMaybe<Array<InputMaybe<CfBlogPostsNestedFilter>>>
  author_exists?: InputMaybe<Scalars['Boolean']>
  blogCategory?: InputMaybe<Scalars['String']>
  blogCategory_contains?: InputMaybe<Scalars['String']>
  blogCategory_exists?: InputMaybe<Scalars['Boolean']>
  blogCategory_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  blogCategory_not?: InputMaybe<Scalars['String']>
  blogCategory_not_contains?: InputMaybe<Scalars['String']>
  blogCategory_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  bottomCta?: InputMaybe<Scalars['String']>
  bottomCta_contains?: InputMaybe<Scalars['String']>
  bottomCta_exists?: InputMaybe<Scalars['Boolean']>
  bottomCta_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  bottomCta_not?: InputMaybe<Scalars['String']>
  bottomCta_not_contains?: InputMaybe<Scalars['String']>
  bottomCta_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  content_contains?: InputMaybe<Scalars['String']>
  content_exists?: InputMaybe<Scalars['Boolean']>
  content_not_contains?: InputMaybe<Scalars['String']>
  contentfulMetadata?: InputMaybe<ContentfulMetadataFilter>
  enableAdvisorShare?: InputMaybe<Scalars['Boolean']>
  enableAdvisorShare_exists?: InputMaybe<Scalars['Boolean']>
  enableAdvisorShare_not?: InputMaybe<Scalars['Boolean']>
  featuredImage_exists?: InputMaybe<Scalars['Boolean']>
  internalComments?: InputMaybe<Scalars['String']>
  internalComments_contains?: InputMaybe<Scalars['String']>
  internalComments_exists?: InputMaybe<Scalars['Boolean']>
  internalComments_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  internalComments_not?: InputMaybe<Scalars['String']>
  internalComments_not_contains?: InputMaybe<Scalars['String']>
  internalComments_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  metaDescription?: InputMaybe<Scalars['String']>
  metaDescription_contains?: InputMaybe<Scalars['String']>
  metaDescription_exists?: InputMaybe<Scalars['Boolean']>
  metaDescription_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  metaDescription_not?: InputMaybe<Scalars['String']>
  metaDescription_not_contains?: InputMaybe<Scalars['String']>
  metaDescription_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  metaTitle?: InputMaybe<Scalars['String']>
  metaTitle_contains?: InputMaybe<Scalars['String']>
  metaTitle_exists?: InputMaybe<Scalars['Boolean']>
  metaTitle_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  metaTitle_not?: InputMaybe<Scalars['String']>
  metaTitle_not_contains?: InputMaybe<Scalars['String']>
  metaTitle_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  noindexNofollow?: InputMaybe<Scalars['Boolean']>
  noindexNofollow_exists?: InputMaybe<Scalars['Boolean']>
  noindexNofollow_not?: InputMaybe<Scalars['Boolean']>
  publishedDate?: InputMaybe<Scalars['DateTime']>
  publishedDate_exists?: InputMaybe<Scalars['Boolean']>
  publishedDate_gt?: InputMaybe<Scalars['DateTime']>
  publishedDate_gte?: InputMaybe<Scalars['DateTime']>
  publishedDate_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']>>>
  publishedDate_lt?: InputMaybe<Scalars['DateTime']>
  publishedDate_lte?: InputMaybe<Scalars['DateTime']>
  publishedDate_not?: InputMaybe<Scalars['DateTime']>
  publishedDate_not_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']>>>
  relatedCategoriesCollection_exists?: InputMaybe<Scalars['Boolean']>
  showOnBlogCategoryPage?: InputMaybe<Scalars['Boolean']>
  showOnBlogCategoryPage_exists?: InputMaybe<Scalars['Boolean']>
  showOnBlogCategoryPage_not?: InputMaybe<Scalars['Boolean']>
  showOnBlogLandingPage?: InputMaybe<Scalars['Boolean']>
  showOnBlogLandingPage_exists?: InputMaybe<Scalars['Boolean']>
  showOnBlogLandingPage_not?: InputMaybe<Scalars['Boolean']>
  showOnTravelPages?: InputMaybe<Scalars['Boolean']>
  showOnTravelPages_exists?: InputMaybe<Scalars['Boolean']>
  showOnTravelPages_not?: InputMaybe<Scalars['Boolean']>
  slug?: InputMaybe<Scalars['String']>
  slug_contains?: InputMaybe<Scalars['String']>
  slug_exists?: InputMaybe<Scalars['Boolean']>
  slug_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  slug_not?: InputMaybe<Scalars['String']>
  slug_not_contains?: InputMaybe<Scalars['String']>
  slug_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  sys?: InputMaybe<SysFilter>
  title?: InputMaybe<Scalars['String']>
  title_contains?: InputMaybe<Scalars['String']>
  title_exists?: InputMaybe<Scalars['Boolean']>
  title_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  title_not?: InputMaybe<Scalars['String']>
  title_not_contains?: InputMaybe<Scalars['String']>
  title_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
}

export type CfComponentAdvisorPlanRowNestedFilter = {
  AND?: InputMaybe<Array<InputMaybe<CfComponentAdvisorPlanRowNestedFilter>>>
  OR?: InputMaybe<Array<InputMaybe<CfComponentAdvisorPlanRowNestedFilter>>>
  contentfulMetadata?: InputMaybe<ContentfulMetadataFilter>
  sys?: InputMaybe<SysFilter>
  title?: InputMaybe<Scalars['String']>
  title_contains?: InputMaybe<Scalars['String']>
  title_exists?: InputMaybe<Scalars['Boolean']>
  title_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  title_not?: InputMaybe<Scalars['String']>
  title_not_contains?: InputMaybe<Scalars['String']>
  title_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  valueOne?: InputMaybe<Scalars['Boolean']>
  valueOne_exists?: InputMaybe<Scalars['Boolean']>
  valueOne_not?: InputMaybe<Scalars['Boolean']>
  valueThree?: InputMaybe<Scalars['Boolean']>
  valueThree_exists?: InputMaybe<Scalars['Boolean']>
  valueThree_not?: InputMaybe<Scalars['Boolean']>
  valueTwo?: InputMaybe<Scalars['Boolean']>
  valueTwo_exists?: InputMaybe<Scalars['Boolean']>
  valueTwo_not?: InputMaybe<Scalars['Boolean']>
}

export type CfComponentAdvisorPlanSectionNestedFilter = {
  AND?: InputMaybe<Array<InputMaybe<CfComponentAdvisorPlanSectionNestedFilter>>>
  OR?: InputMaybe<Array<InputMaybe<CfComponentAdvisorPlanSectionNestedFilter>>>
  contentfulMetadata?: InputMaybe<ContentfulMetadataFilter>
  header?: InputMaybe<Scalars['String']>
  header_contains?: InputMaybe<Scalars['String']>
  header_exists?: InputMaybe<Scalars['Boolean']>
  header_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  header_not?: InputMaybe<Scalars['String']>
  header_not_contains?: InputMaybe<Scalars['String']>
  header_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  rowsCollection_exists?: InputMaybe<Scalars['Boolean']>
  sys?: InputMaybe<SysFilter>
}

export type CfComponentCarouselSectionNestedFilter = {
  AND?: InputMaybe<Array<InputMaybe<CfComponentCarouselSectionNestedFilter>>>
  OR?: InputMaybe<Array<InputMaybe<CfComponentCarouselSectionNestedFilter>>>
  analyticsModule_exists?: InputMaybe<Scalars['Boolean']>
  arrowStyle?: InputMaybe<Scalars['String']>
  arrowStyle_contains?: InputMaybe<Scalars['String']>
  arrowStyle_exists?: InputMaybe<Scalars['Boolean']>
  arrowStyle_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  arrowStyle_not?: InputMaybe<Scalars['String']>
  arrowStyle_not_contains?: InputMaybe<Scalars['String']>
  arrowStyle_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  contentfulMetadata?: InputMaybe<ContentfulMetadataFilter>
  dotsStyle?: InputMaybe<Scalars['String']>
  dotsStyle_contains?: InputMaybe<Scalars['String']>
  dotsStyle_exists?: InputMaybe<Scalars['Boolean']>
  dotsStyle_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  dotsStyle_not?: InputMaybe<Scalars['String']>
  dotsStyle_not_contains?: InputMaybe<Scalars['String']>
  dotsStyle_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  heading?: InputMaybe<Scalars['String']>
  heading_contains?: InputMaybe<Scalars['String']>
  heading_exists?: InputMaybe<Scalars['Boolean']>
  heading_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  heading_not?: InputMaybe<Scalars['String']>
  heading_not_contains?: InputMaybe<Scalars['String']>
  heading_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  internalLabel?: InputMaybe<Scalars['String']>
  internalLabel_contains?: InputMaybe<Scalars['String']>
  internalLabel_exists?: InputMaybe<Scalars['Boolean']>
  internalLabel_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  internalLabel_not?: InputMaybe<Scalars['String']>
  internalLabel_not_contains?: InputMaybe<Scalars['String']>
  internalLabel_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  itemsCollection_exists?: InputMaybe<Scalars['Boolean']>
  itemsPerPage?: InputMaybe<Scalars['Int']>
  itemsPerPage_exists?: InputMaybe<Scalars['Boolean']>
  itemsPerPage_gt?: InputMaybe<Scalars['Int']>
  itemsPerPage_gte?: InputMaybe<Scalars['Int']>
  itemsPerPage_in?: InputMaybe<Array<InputMaybe<Scalars['Int']>>>
  itemsPerPage_lt?: InputMaybe<Scalars['Int']>
  itemsPerPage_lte?: InputMaybe<Scalars['Int']>
  itemsPerPage_not?: InputMaybe<Scalars['Int']>
  itemsPerPage_not_in?: InputMaybe<Array<InputMaybe<Scalars['Int']>>>
  sectionId?: InputMaybe<Scalars['String']>
  sectionId_contains?: InputMaybe<Scalars['String']>
  sectionId_exists?: InputMaybe<Scalars['Boolean']>
  sectionId_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  sectionId_not?: InputMaybe<Scalars['String']>
  sectionId_not_contains?: InputMaybe<Scalars['String']>
  sectionId_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  showNextMobile?: InputMaybe<Scalars['Boolean']>
  showNextMobile_exists?: InputMaybe<Scalars['Boolean']>
  showNextMobile_not?: InputMaybe<Scalars['Boolean']>
  sys?: InputMaybe<SysFilter>
  teaserCopy_contains?: InputMaybe<Scalars['String']>
  teaserCopy_exists?: InputMaybe<Scalars['Boolean']>
  teaserCopy_not_contains?: InputMaybe<Scalars['String']>
  titleStyle?: InputMaybe<Scalars['String']>
  titleStyle_contains?: InputMaybe<Scalars['String']>
  titleStyle_exists?: InputMaybe<Scalars['Boolean']>
  titleStyle_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  titleStyle_not?: InputMaybe<Scalars['String']>
  titleStyle_not_contains?: InputMaybe<Scalars['String']>
  titleStyle_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
}

export type CfComponentComparisonTableRowNestedFilter = {
  AND?: InputMaybe<Array<InputMaybe<CfComponentComparisonTableRowNestedFilter>>>
  OR?: InputMaybe<Array<InputMaybe<CfComponentComparisonTableRowNestedFilter>>>
  analyticsModule_exists?: InputMaybe<Scalars['Boolean']>
  contentfulMetadata?: InputMaybe<ContentfulMetadataFilter>
  foraValue?: InputMaybe<Scalars['String']>
  foraValue_contains?: InputMaybe<Scalars['String']>
  foraValue_exists?: InputMaybe<Scalars['Boolean']>
  foraValue_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  foraValue_not?: InputMaybe<Scalars['String']>
  foraValue_not_contains?: InputMaybe<Scalars['String']>
  foraValue_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  internalLabel?: InputMaybe<Scalars['String']>
  internalLabel_contains?: InputMaybe<Scalars['String']>
  internalLabel_exists?: InputMaybe<Scalars['Boolean']>
  internalLabel_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  internalLabel_not?: InputMaybe<Scalars['String']>
  internalLabel_not_contains?: InputMaybe<Scalars['String']>
  internalLabel_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  othersValue?: InputMaybe<Scalars['String']>
  othersValue_contains?: InputMaybe<Scalars['String']>
  othersValue_exists?: InputMaybe<Scalars['Boolean']>
  othersValue_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  othersValue_not?: InputMaybe<Scalars['String']>
  othersValue_not_contains?: InputMaybe<Scalars['String']>
  othersValue_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  sys?: InputMaybe<SysFilter>
  title?: InputMaybe<Scalars['String']>
  title_contains?: InputMaybe<Scalars['String']>
  title_exists?: InputMaybe<Scalars['Boolean']>
  title_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  title_not?: InputMaybe<Scalars['String']>
  title_not_contains?: InputMaybe<Scalars['String']>
  title_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
}

export type CfComponentDataItemNestedFilter = {
  AND?: InputMaybe<Array<InputMaybe<CfComponentDataItemNestedFilter>>>
  OR?: InputMaybe<Array<InputMaybe<CfComponentDataItemNestedFilter>>>
  contentfulMetadata?: InputMaybe<ContentfulMetadataFilter>
  icon_exists?: InputMaybe<Scalars['Boolean']>
  number?: InputMaybe<Scalars['Int']>
  number_exists?: InputMaybe<Scalars['Boolean']>
  number_gt?: InputMaybe<Scalars['Int']>
  number_gte?: InputMaybe<Scalars['Int']>
  number_in?: InputMaybe<Array<InputMaybe<Scalars['Int']>>>
  number_lt?: InputMaybe<Scalars['Int']>
  number_lte?: InputMaybe<Scalars['Int']>
  number_not?: InputMaybe<Scalars['Int']>
  number_not_in?: InputMaybe<Array<InputMaybe<Scalars['Int']>>>
  sys?: InputMaybe<SysFilter>
  title?: InputMaybe<Scalars['String']>
  title_contains?: InputMaybe<Scalars['String']>
  title_exists?: InputMaybe<Scalars['Boolean']>
  title_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  title_not?: InputMaybe<Scalars['String']>
  title_not_contains?: InputMaybe<Scalars['String']>
  title_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
}

export type CfComponentHeroFullWidthNestedFilter = {
  AND?: InputMaybe<Array<InputMaybe<CfComponentHeroFullWidthNestedFilter>>>
  OR?: InputMaybe<Array<InputMaybe<CfComponentHeroFullWidthNestedFilter>>>
  analyticsModule_exists?: InputMaybe<Scalars['Boolean']>
  bottomText?: InputMaybe<Scalars['String']>
  bottomText_contains?: InputMaybe<Scalars['String']>
  bottomText_exists?: InputMaybe<Scalars['Boolean']>
  bottomText_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  bottomText_not?: InputMaybe<Scalars['String']>
  bottomText_not_contains?: InputMaybe<Scalars['String']>
  bottomText_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  buttonCtaText?: InputMaybe<Scalars['String']>
  buttonCtaText_contains?: InputMaybe<Scalars['String']>
  buttonCtaText_exists?: InputMaybe<Scalars['Boolean']>
  buttonCtaText_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  buttonCtaText_not?: InputMaybe<Scalars['String']>
  buttonCtaText_not_contains?: InputMaybe<Scalars['String']>
  buttonCtaText_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  buttonCtaUrl?: InputMaybe<Scalars['String']>
  buttonCtaUrl_contains?: InputMaybe<Scalars['String']>
  buttonCtaUrl_exists?: InputMaybe<Scalars['Boolean']>
  buttonCtaUrl_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  buttonCtaUrl_not?: InputMaybe<Scalars['String']>
  buttonCtaUrl_not_contains?: InputMaybe<Scalars['String']>
  buttonCtaUrl_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  cloudinaryAsset_exists?: InputMaybe<Scalars['Boolean']>
  contentfulMetadata?: InputMaybe<ContentfulMetadataFilter>
  fallbackImage_exists?: InputMaybe<Scalars['Boolean']>
  image_exists?: InputMaybe<Scalars['Boolean']>
  internalLabel?: InputMaybe<Scalars['String']>
  internalLabel_contains?: InputMaybe<Scalars['String']>
  internalLabel_exists?: InputMaybe<Scalars['Boolean']>
  internalLabel_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  internalLabel_not?: InputMaybe<Scalars['String']>
  internalLabel_not_contains?: InputMaybe<Scalars['String']>
  internalLabel_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  lightText?: InputMaybe<Scalars['Boolean']>
  lightText_exists?: InputMaybe<Scalars['Boolean']>
  lightText_not?: InputMaybe<Scalars['Boolean']>
  mediaSource?: InputMaybe<Scalars['String']>
  mediaSource_contains?: InputMaybe<Scalars['String']>
  mediaSource_exists?: InputMaybe<Scalars['Boolean']>
  mediaSource_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  mediaSource_not?: InputMaybe<Scalars['String']>
  mediaSource_not_contains?: InputMaybe<Scalars['String']>
  mediaSource_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  mobileCloudinaryAsset_exists?: InputMaybe<Scalars['Boolean']>
  mobileImage_exists?: InputMaybe<Scalars['Boolean']>
  mobileMediaSource?: InputMaybe<Scalars['String']>
  mobileMediaSource_contains?: InputMaybe<Scalars['String']>
  mobileMediaSource_exists?: InputMaybe<Scalars['Boolean']>
  mobileMediaSource_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  mobileMediaSource_not?: InputMaybe<Scalars['String']>
  mobileMediaSource_not_contains?: InputMaybe<Scalars['String']>
  mobileMediaSource_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  rotatingText_contains_all?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  rotatingText_contains_none?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  rotatingText_contains_some?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  rotatingText_exists?: InputMaybe<Scalars['Boolean']>
  sectionId?: InputMaybe<Scalars['String']>
  sectionId_contains?: InputMaybe<Scalars['String']>
  sectionId_exists?: InputMaybe<Scalars['Boolean']>
  sectionId_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  sectionId_not?: InputMaybe<Scalars['String']>
  sectionId_not_contains?: InputMaybe<Scalars['String']>
  sectionId_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  showAverageRating?: InputMaybe<Scalars['Boolean']>
  showAverageRating_exists?: InputMaybe<Scalars['Boolean']>
  showAverageRating_not?: InputMaybe<Scalars['Boolean']>
  showForaLogo?: InputMaybe<Scalars['Boolean']>
  showForaLogo_exists?: InputMaybe<Scalars['Boolean']>
  showForaLogo_not?: InputMaybe<Scalars['Boolean']>
  subCopy?: InputMaybe<Scalars['String']>
  subCopy_contains?: InputMaybe<Scalars['String']>
  subCopy_exists?: InputMaybe<Scalars['Boolean']>
  subCopy_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  subCopy_not?: InputMaybe<Scalars['String']>
  subCopy_not_contains?: InputMaybe<Scalars['String']>
  subCopy_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  sys?: InputMaybe<SysFilter>
  title?: InputMaybe<Scalars['String']>
  title_contains?: InputMaybe<Scalars['String']>
  title_exists?: InputMaybe<Scalars['Boolean']>
  title_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  title_not?: InputMaybe<Scalars['String']>
  title_not_contains?: InputMaybe<Scalars['String']>
  title_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  urlTarget?: InputMaybe<Scalars['String']>
  urlTarget_contains?: InputMaybe<Scalars['String']>
  urlTarget_exists?: InputMaybe<Scalars['Boolean']>
  urlTarget_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  urlTarget_not?: InputMaybe<Scalars['String']>
  urlTarget_not_contains?: InputMaybe<Scalars['String']>
  urlTarget_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
}

export type CfComponentImageGallerySlideItemNestedFilter = {
  AND?: InputMaybe<
    Array<InputMaybe<CfComponentImageGallerySlideItemNestedFilter>>
  >
  OR?: InputMaybe<
    Array<InputMaybe<CfComponentImageGallerySlideItemNestedFilter>>
  >
  cloudinaryFeaturedImage_exists?: InputMaybe<Scalars['Boolean']>
  cloudinaryImages_exists?: InputMaybe<Scalars['Boolean']>
  contentfulMetadata?: InputMaybe<ContentfulMetadataFilter>
  ctaText?: InputMaybe<Scalars['String']>
  ctaText_contains?: InputMaybe<Scalars['String']>
  ctaText_exists?: InputMaybe<Scalars['Boolean']>
  ctaText_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  ctaText_not?: InputMaybe<Scalars['String']>
  ctaText_not_contains?: InputMaybe<Scalars['String']>
  ctaText_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  description_contains?: InputMaybe<Scalars['String']>
  description_exists?: InputMaybe<Scalars['Boolean']>
  description_not_contains?: InputMaybe<Scalars['String']>
  featuredImage_exists?: InputMaybe<Scalars['Boolean']>
  imagesCollection_exists?: InputMaybe<Scalars['Boolean']>
  sys?: InputMaybe<SysFilter>
  title?: InputMaybe<Scalars['String']>
  title_contains?: InputMaybe<Scalars['String']>
  title_exists?: InputMaybe<Scalars['Boolean']>
  title_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  title_not?: InputMaybe<Scalars['String']>
  title_not_contains?: InputMaybe<Scalars['String']>
  title_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
}

export type CfComponentImageHeaderSubNestedFilter = {
  AND?: InputMaybe<Array<InputMaybe<CfComponentImageHeaderSubNestedFilter>>>
  OR?: InputMaybe<Array<InputMaybe<CfComponentImageHeaderSubNestedFilter>>>
  contentfulMetadata?: InputMaybe<ContentfulMetadataFilter>
  header?: InputMaybe<Scalars['String']>
  header_contains?: InputMaybe<Scalars['String']>
  header_exists?: InputMaybe<Scalars['Boolean']>
  header_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  header_not?: InputMaybe<Scalars['String']>
  header_not_contains?: InputMaybe<Scalars['String']>
  header_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  image_exists?: InputMaybe<Scalars['Boolean']>
  internalLabel?: InputMaybe<Scalars['String']>
  internalLabel_contains?: InputMaybe<Scalars['String']>
  internalLabel_exists?: InputMaybe<Scalars['Boolean']>
  internalLabel_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  internalLabel_not?: InputMaybe<Scalars['String']>
  internalLabel_not_contains?: InputMaybe<Scalars['String']>
  internalLabel_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  subHeading?: InputMaybe<Scalars['String']>
  subHeading_contains?: InputMaybe<Scalars['String']>
  subHeading_exists?: InputMaybe<Scalars['Boolean']>
  subHeading_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  subHeading_not?: InputMaybe<Scalars['String']>
  subHeading_not_contains?: InputMaybe<Scalars['String']>
  subHeading_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  subheading?: InputMaybe<Scalars['String']>
  subheading_contains?: InputMaybe<Scalars['String']>
  subheading_exists?: InputMaybe<Scalars['Boolean']>
  subheading_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  subheading_not?: InputMaybe<Scalars['String']>
  subheading_not_contains?: InputMaybe<Scalars['String']>
  subheading_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  sys?: InputMaybe<SysFilter>
  titleStyle?: InputMaybe<Scalars['String']>
  titleStyle_contains?: InputMaybe<Scalars['String']>
  titleStyle_exists?: InputMaybe<Scalars['Boolean']>
  titleStyle_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  titleStyle_not?: InputMaybe<Scalars['String']>
  titleStyle_not_contains?: InputMaybe<Scalars['String']>
  titleStyle_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
}

export type CfComponentImageNestedFilter = {
  AND?: InputMaybe<Array<InputMaybe<CfComponentImageNestedFilter>>>
  OR?: InputMaybe<Array<InputMaybe<CfComponentImageNestedFilter>>>
  altText?: InputMaybe<Scalars['String']>
  altText_contains?: InputMaybe<Scalars['String']>
  altText_exists?: InputMaybe<Scalars['Boolean']>
  altText_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  altText_not?: InputMaybe<Scalars['String']>
  altText_not_contains?: InputMaybe<Scalars['String']>
  altText_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  analyticsModule_exists?: InputMaybe<Scalars['Boolean']>
  cloudinaryImage_exists?: InputMaybe<Scalars['Boolean']>
  contentfulMetadata?: InputMaybe<ContentfulMetadataFilter>
  file_exists?: InputMaybe<Scalars['Boolean']>
  imageCredit?: InputMaybe<Scalars['String']>
  imageCredit_contains?: InputMaybe<Scalars['String']>
  imageCredit_exists?: InputMaybe<Scalars['Boolean']>
  imageCredit_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  imageCredit_not?: InputMaybe<Scalars['String']>
  imageCredit_not_contains?: InputMaybe<Scalars['String']>
  imageCredit_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  internalTitle?: InputMaybe<Scalars['String']>
  internalTitle_contains?: InputMaybe<Scalars['String']>
  internalTitle_exists?: InputMaybe<Scalars['Boolean']>
  internalTitle_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  internalTitle_not?: InputMaybe<Scalars['String']>
  internalTitle_not_contains?: InputMaybe<Scalars['String']>
  internalTitle_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  sys?: InputMaybe<SysFilter>
}

export type CfComponentPartnerGridNestedFilter = {
  AND?: InputMaybe<Array<InputMaybe<CfComponentPartnerGridNestedFilter>>>
  OR?: InputMaybe<Array<InputMaybe<CfComponentPartnerGridNestedFilter>>>
  analyticsModule_exists?: InputMaybe<Scalars['Boolean']>
  contentfulMetadata?: InputMaybe<ContentfulMetadataFilter>
  featuredCruisesCollection_exists?: InputMaybe<Scalars['Boolean']>
  featuredExperiencesCollection_exists?: InputMaybe<Scalars['Boolean']>
  featuredHotelsCollection_exists?: InputMaybe<Scalars['Boolean']>
  foraReserveCollection_exists?: InputMaybe<Scalars['Boolean']>
  heading?: InputMaybe<Scalars['String']>
  headingSharedState?: InputMaybe<Scalars['String']>
  headingSharedState_contains?: InputMaybe<Scalars['String']>
  headingSharedState_exists?: InputMaybe<Scalars['Boolean']>
  headingSharedState_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  headingSharedState_not?: InputMaybe<Scalars['String']>
  headingSharedState_not_contains?: InputMaybe<Scalars['String']>
  headingSharedState_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  heading_contains?: InputMaybe<Scalars['String']>
  heading_exists?: InputMaybe<Scalars['Boolean']>
  heading_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  heading_not?: InputMaybe<Scalars['String']>
  heading_not_contains?: InputMaybe<Scalars['String']>
  heading_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  internalLabel?: InputMaybe<Scalars['String']>
  internalLabel_contains?: InputMaybe<Scalars['String']>
  internalLabel_exists?: InputMaybe<Scalars['Boolean']>
  internalLabel_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  internalLabel_not?: InputMaybe<Scalars['String']>
  internalLabel_not_contains?: InputMaybe<Scalars['String']>
  internalLabel_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  regularCruisesCollection_exists?: InputMaybe<Scalars['Boolean']>
  regularExperiencesCollection_exists?: InputMaybe<Scalars['Boolean']>
  regularHotelsCollection_exists?: InputMaybe<Scalars['Boolean']>
  subheadingSharedState_contains?: InputMaybe<Scalars['String']>
  subheadingSharedState_exists?: InputMaybe<Scalars['Boolean']>
  subheadingSharedState_not_contains?: InputMaybe<Scalars['String']>
  subheading_contains?: InputMaybe<Scalars['String']>
  subheading_exists?: InputMaybe<Scalars['Boolean']>
  subheading_not_contains?: InputMaybe<Scalars['String']>
  sys?: InputMaybe<SysFilter>
  titleStyle?: InputMaybe<Scalars['String']>
  titleStyle_contains?: InputMaybe<Scalars['String']>
  titleStyle_exists?: InputMaybe<Scalars['Boolean']>
  titleStyle_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  titleStyle_not?: InputMaybe<Scalars['String']>
  titleStyle_not_contains?: InputMaybe<Scalars['String']>
  titleStyle_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
}

export type CfComponentPictureSectionBannerNestedFilter = {
  AND?: InputMaybe<
    Array<InputMaybe<CfComponentPictureSectionBannerNestedFilter>>
  >
  OR?: InputMaybe<
    Array<InputMaybe<CfComponentPictureSectionBannerNestedFilter>>
  >
  analyticsModule_exists?: InputMaybe<Scalars['Boolean']>
  cardSize?: InputMaybe<Scalars['String']>
  cardSize_contains?: InputMaybe<Scalars['String']>
  cardSize_exists?: InputMaybe<Scalars['Boolean']>
  cardSize_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  cardSize_not?: InputMaybe<Scalars['String']>
  cardSize_not_contains?: InputMaybe<Scalars['String']>
  cardSize_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  contentfulMetadata?: InputMaybe<ContentfulMetadataFilter>
  internalLabel?: InputMaybe<Scalars['String']>
  internalLabel_contains?: InputMaybe<Scalars['String']>
  internalLabel_exists?: InputMaybe<Scalars['Boolean']>
  internalLabel_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  internalLabel_not?: InputMaybe<Scalars['String']>
  internalLabel_not_contains?: InputMaybe<Scalars['String']>
  internalLabel_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  mobilePicture_exists?: InputMaybe<Scalars['Boolean']>
  picture_exists?: InputMaybe<Scalars['Boolean']>
  sys?: InputMaybe<SysFilter>
  title?: InputMaybe<Scalars['String']>
  title_contains?: InputMaybe<Scalars['String']>
  title_exists?: InputMaybe<Scalars['Boolean']>
  title_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  title_not?: InputMaybe<Scalars['String']>
  title_not_contains?: InputMaybe<Scalars['String']>
  title_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
}

export type CfComponentSubNavItemNestedFilter = {
  AND?: InputMaybe<Array<InputMaybe<CfComponentSubNavItemNestedFilter>>>
  OR?: InputMaybe<Array<InputMaybe<CfComponentSubNavItemNestedFilter>>>
  analyticsModule_exists?: InputMaybe<Scalars['Boolean']>
  contentfulMetadata?: InputMaybe<ContentfulMetadataFilter>
  label?: InputMaybe<Scalars['String']>
  label_contains?: InputMaybe<Scalars['String']>
  label_exists?: InputMaybe<Scalars['Boolean']>
  label_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  label_not?: InputMaybe<Scalars['String']>
  label_not_contains?: InputMaybe<Scalars['String']>
  label_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  sys?: InputMaybe<SysFilter>
  url?: InputMaybe<Scalars['String']>
  url_contains?: InputMaybe<Scalars['String']>
  url_exists?: InputMaybe<Scalars['Boolean']>
  url_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  url_not?: InputMaybe<Scalars['String']>
  url_not_contains?: InputMaybe<Scalars['String']>
  url_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
}

export type CfComponentSubscriptionCardNestedFilter = {
  AND?: InputMaybe<Array<InputMaybe<CfComponentSubscriptionCardNestedFilter>>>
  OR?: InputMaybe<Array<InputMaybe<CfComponentSubscriptionCardNestedFilter>>>
  analyticsModule_exists?: InputMaybe<Scalars['Boolean']>
  annualDiscountPrice?: InputMaybe<Scalars['Float']>
  annualDiscountPrice_exists?: InputMaybe<Scalars['Boolean']>
  annualDiscountPrice_gt?: InputMaybe<Scalars['Float']>
  annualDiscountPrice_gte?: InputMaybe<Scalars['Float']>
  annualDiscountPrice_in?: InputMaybe<Array<InputMaybe<Scalars['Float']>>>
  annualDiscountPrice_lt?: InputMaybe<Scalars['Float']>
  annualDiscountPrice_lte?: InputMaybe<Scalars['Float']>
  annualDiscountPrice_not?: InputMaybe<Scalars['Float']>
  annualDiscountPrice_not_in?: InputMaybe<Array<InputMaybe<Scalars['Float']>>>
  annualFeaturedText?: InputMaybe<Scalars['String']>
  annualFeaturedText_contains?: InputMaybe<Scalars['String']>
  annualFeaturedText_exists?: InputMaybe<Scalars['Boolean']>
  annualFeaturedText_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  annualFeaturedText_not?: InputMaybe<Scalars['String']>
  annualFeaturedText_not_contains?: InputMaybe<Scalars['String']>
  annualFeaturedText_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  annualIsLimited?: InputMaybe<Scalars['Boolean']>
  annualIsLimited_exists?: InputMaybe<Scalars['Boolean']>
  annualIsLimited_not?: InputMaybe<Scalars['Boolean']>
  annualOriginalPrice?: InputMaybe<Scalars['Float']>
  annualOriginalPrice_exists?: InputMaybe<Scalars['Boolean']>
  annualOriginalPrice_gt?: InputMaybe<Scalars['Float']>
  annualOriginalPrice_gte?: InputMaybe<Scalars['Float']>
  annualOriginalPrice_in?: InputMaybe<Array<InputMaybe<Scalars['Float']>>>
  annualOriginalPrice_lt?: InputMaybe<Scalars['Float']>
  annualOriginalPrice_lte?: InputMaybe<Scalars['Float']>
  annualOriginalPrice_not?: InputMaybe<Scalars['Float']>
  annualOriginalPrice_not_in?: InputMaybe<Array<InputMaybe<Scalars['Float']>>>
  annualPriceDetails?: InputMaybe<Scalars['String']>
  annualPriceDetails_contains?: InputMaybe<Scalars['String']>
  annualPriceDetails_exists?: InputMaybe<Scalars['Boolean']>
  annualPriceDetails_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  annualPriceDetails_not?: InputMaybe<Scalars['String']>
  annualPriceDetails_not_contains?: InputMaybe<Scalars['String']>
  annualPriceDetails_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  bulletPoints_contains_all?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  bulletPoints_contains_none?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  bulletPoints_contains_some?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  bulletPoints_exists?: InputMaybe<Scalars['Boolean']>
  contentfulMetadata?: InputMaybe<ContentfulMetadataFilter>
  ctaText?: InputMaybe<Scalars['String']>
  ctaText_contains?: InputMaybe<Scalars['String']>
  ctaText_exists?: InputMaybe<Scalars['Boolean']>
  ctaText_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  ctaText_not?: InputMaybe<Scalars['String']>
  ctaText_not_contains?: InputMaybe<Scalars['String']>
  ctaText_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  ctaUrl?: InputMaybe<Scalars['String']>
  ctaUrl_contains?: InputMaybe<Scalars['String']>
  ctaUrl_exists?: InputMaybe<Scalars['Boolean']>
  ctaUrl_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  ctaUrl_not?: InputMaybe<Scalars['String']>
  ctaUrl_not_contains?: InputMaybe<Scalars['String']>
  ctaUrl_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  heading?: InputMaybe<Scalars['String']>
  heading_contains?: InputMaybe<Scalars['String']>
  heading_exists?: InputMaybe<Scalars['Boolean']>
  heading_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  heading_not?: InputMaybe<Scalars['String']>
  heading_not_contains?: InputMaybe<Scalars['String']>
  heading_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  internalLabel?: InputMaybe<Scalars['String']>
  internalLabel_contains?: InputMaybe<Scalars['String']>
  internalLabel_exists?: InputMaybe<Scalars['Boolean']>
  internalLabel_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  internalLabel_not?: InputMaybe<Scalars['String']>
  internalLabel_not_contains?: InputMaybe<Scalars['String']>
  internalLabel_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  monthlyDiscountPrice?: InputMaybe<Scalars['Float']>
  monthlyDiscountPrice_exists?: InputMaybe<Scalars['Boolean']>
  monthlyDiscountPrice_gt?: InputMaybe<Scalars['Float']>
  monthlyDiscountPrice_gte?: InputMaybe<Scalars['Float']>
  monthlyDiscountPrice_in?: InputMaybe<Array<InputMaybe<Scalars['Float']>>>
  monthlyDiscountPrice_lt?: InputMaybe<Scalars['Float']>
  monthlyDiscountPrice_lte?: InputMaybe<Scalars['Float']>
  monthlyDiscountPrice_not?: InputMaybe<Scalars['Float']>
  monthlyDiscountPrice_not_in?: InputMaybe<Array<InputMaybe<Scalars['Float']>>>
  monthlyFeaturedText?: InputMaybe<Scalars['String']>
  monthlyFeaturedText_contains?: InputMaybe<Scalars['String']>
  monthlyFeaturedText_exists?: InputMaybe<Scalars['Boolean']>
  monthlyFeaturedText_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  monthlyFeaturedText_not?: InputMaybe<Scalars['String']>
  monthlyFeaturedText_not_contains?: InputMaybe<Scalars['String']>
  monthlyFeaturedText_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  monthlyIsLimited?: InputMaybe<Scalars['Boolean']>
  monthlyIsLimited_exists?: InputMaybe<Scalars['Boolean']>
  monthlyIsLimited_not?: InputMaybe<Scalars['Boolean']>
  monthlyOriginalPrice?: InputMaybe<Scalars['Float']>
  monthlyOriginalPrice_exists?: InputMaybe<Scalars['Boolean']>
  monthlyOriginalPrice_gt?: InputMaybe<Scalars['Float']>
  monthlyOriginalPrice_gte?: InputMaybe<Scalars['Float']>
  monthlyOriginalPrice_in?: InputMaybe<Array<InputMaybe<Scalars['Float']>>>
  monthlyOriginalPrice_lt?: InputMaybe<Scalars['Float']>
  monthlyOriginalPrice_lte?: InputMaybe<Scalars['Float']>
  monthlyOriginalPrice_not?: InputMaybe<Scalars['Float']>
  monthlyOriginalPrice_not_in?: InputMaybe<Array<InputMaybe<Scalars['Float']>>>
  monthlyPriceDetails?: InputMaybe<Scalars['String']>
  monthlyPriceDetails_contains?: InputMaybe<Scalars['String']>
  monthlyPriceDetails_exists?: InputMaybe<Scalars['Boolean']>
  monthlyPriceDetails_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  monthlyPriceDetails_not?: InputMaybe<Scalars['String']>
  monthlyPriceDetails_not_contains?: InputMaybe<Scalars['String']>
  monthlyPriceDetails_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  sectionId?: InputMaybe<Scalars['String']>
  sectionId_contains?: InputMaybe<Scalars['String']>
  sectionId_exists?: InputMaybe<Scalars['Boolean']>
  sectionId_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  sectionId_not?: InputMaybe<Scalars['String']>
  sectionId_not_contains?: InputMaybe<Scalars['String']>
  sectionId_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  showMultiplePricingModels?: InputMaybe<Scalars['Boolean']>
  showMultiplePricingModels_exists?: InputMaybe<Scalars['Boolean']>
  showMultiplePricingModels_not?: InputMaybe<Scalars['Boolean']>
  subCopy_contains?: InputMaybe<Scalars['String']>
  subCopy_exists?: InputMaybe<Scalars['Boolean']>
  subCopy_not_contains?: InputMaybe<Scalars['String']>
  sys?: InputMaybe<SysFilter>
  teaserCopy_contains?: InputMaybe<Scalars['String']>
  teaserCopy_exists?: InputMaybe<Scalars['Boolean']>
  teaserCopy_not_contains?: InputMaybe<Scalars['String']>
  textOnRight?: InputMaybe<Scalars['Boolean']>
  textOnRight_exists?: InputMaybe<Scalars['Boolean']>
  textOnRight_not?: InputMaybe<Scalars['Boolean']>
  titleStyle?: InputMaybe<Scalars['String']>
  titleStyle_contains?: InputMaybe<Scalars['String']>
  titleStyle_exists?: InputMaybe<Scalars['Boolean']>
  titleStyle_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  titleStyle_not?: InputMaybe<Scalars['String']>
  titleStyle_not_contains?: InputMaybe<Scalars['String']>
  titleStyle_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  urlTarget?: InputMaybe<Scalars['String']>
  urlTarget_contains?: InputMaybe<Scalars['String']>
  urlTarget_exists?: InputMaybe<Scalars['Boolean']>
  urlTarget_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  urlTarget_not?: InputMaybe<Scalars['String']>
  urlTarget_not_contains?: InputMaybe<Scalars['String']>
  urlTarget_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
}

export type CfComponentThreeIconSectionNestedFilter = {
  AND?: InputMaybe<Array<InputMaybe<CfComponentThreeIconSectionNestedFilter>>>
  OR?: InputMaybe<Array<InputMaybe<CfComponentThreeIconSectionNestedFilter>>>
  analyticsModule_exists?: InputMaybe<Scalars['Boolean']>
  contentfulMetadata?: InputMaybe<ContentfulMetadataFilter>
  ctaText?: InputMaybe<Scalars['String']>
  ctaText_contains?: InputMaybe<Scalars['String']>
  ctaText_exists?: InputMaybe<Scalars['Boolean']>
  ctaText_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  ctaText_not?: InputMaybe<Scalars['String']>
  ctaText_not_contains?: InputMaybe<Scalars['String']>
  ctaText_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  ctaUrl?: InputMaybe<Scalars['String']>
  ctaUrl_contains?: InputMaybe<Scalars['String']>
  ctaUrl_exists?: InputMaybe<Scalars['Boolean']>
  ctaUrl_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  ctaUrl_not?: InputMaybe<Scalars['String']>
  ctaUrl_not_contains?: InputMaybe<Scalars['String']>
  ctaUrl_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  imageColumnOne_exists?: InputMaybe<Scalars['Boolean']>
  imageColumnThree_exists?: InputMaybe<Scalars['Boolean']>
  imageColumnTwo_exists?: InputMaybe<Scalars['Boolean']>
  isCompact?: InputMaybe<Scalars['Boolean']>
  isCompact_exists?: InputMaybe<Scalars['Boolean']>
  isCompact_not?: InputMaybe<Scalars['Boolean']>
  sectionId?: InputMaybe<Scalars['String']>
  sectionId_contains?: InputMaybe<Scalars['String']>
  sectionId_exists?: InputMaybe<Scalars['Boolean']>
  sectionId_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  sectionId_not?: InputMaybe<Scalars['String']>
  sectionId_not_contains?: InputMaybe<Scalars['String']>
  sectionId_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  solidBackground?: InputMaybe<Scalars['Boolean']>
  solidBackground_exists?: InputMaybe<Scalars['Boolean']>
  solidBackground_not?: InputMaybe<Scalars['Boolean']>
  sys?: InputMaybe<SysFilter>
  teaserCopy_contains?: InputMaybe<Scalars['String']>
  teaserCopy_exists?: InputMaybe<Scalars['Boolean']>
  teaserCopy_not_contains?: InputMaybe<Scalars['String']>
  textColumnOne_contains?: InputMaybe<Scalars['String']>
  textColumnOne_exists?: InputMaybe<Scalars['Boolean']>
  textColumnOne_not_contains?: InputMaybe<Scalars['String']>
  textColumnThree_contains?: InputMaybe<Scalars['String']>
  textColumnThree_exists?: InputMaybe<Scalars['Boolean']>
  textColumnThree_not_contains?: InputMaybe<Scalars['String']>
  textColumnTwo_contains?: InputMaybe<Scalars['String']>
  textColumnTwo_exists?: InputMaybe<Scalars['Boolean']>
  textColumnTwo_not_contains?: InputMaybe<Scalars['String']>
  title?: InputMaybe<Scalars['String']>
  titleStyle?: InputMaybe<Scalars['String']>
  titleStyle_contains?: InputMaybe<Scalars['String']>
  titleStyle_exists?: InputMaybe<Scalars['Boolean']>
  titleStyle_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  titleStyle_not?: InputMaybe<Scalars['String']>
  titleStyle_not_contains?: InputMaybe<Scalars['String']>
  titleStyle_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  title_contains?: InputMaybe<Scalars['String']>
  title_exists?: InputMaybe<Scalars['Boolean']>
  title_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  title_not?: InputMaybe<Scalars['String']>
  title_not_contains?: InputMaybe<Scalars['String']>
  title_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  urlTarget?: InputMaybe<Scalars['String']>
  urlTarget_contains?: InputMaybe<Scalars['String']>
  urlTarget_exists?: InputMaybe<Scalars['Boolean']>
  urlTarget_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  urlTarget_not?: InputMaybe<Scalars['String']>
  urlTarget_not_contains?: InputMaybe<Scalars['String']>
  urlTarget_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
}

export type CfComponentTwoColumnCalloutNestedFilter = {
  AND?: InputMaybe<Array<InputMaybe<CfComponentTwoColumnCalloutNestedFilter>>>
  OR?: InputMaybe<Array<InputMaybe<CfComponentTwoColumnCalloutNestedFilter>>>
  analyticsModule_exists?: InputMaybe<Scalars['Boolean']>
  content_contains?: InputMaybe<Scalars['String']>
  content_exists?: InputMaybe<Scalars['Boolean']>
  content_not_contains?: InputMaybe<Scalars['String']>
  contentfulMetadata?: InputMaybe<ContentfulMetadataFilter>
  ctaText?: InputMaybe<Scalars['String']>
  ctaText_contains?: InputMaybe<Scalars['String']>
  ctaText_exists?: InputMaybe<Scalars['Boolean']>
  ctaText_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  ctaText_not?: InputMaybe<Scalars['String']>
  ctaText_not_contains?: InputMaybe<Scalars['String']>
  ctaText_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  ctaTheme?: InputMaybe<Scalars['String']>
  ctaTheme_contains?: InputMaybe<Scalars['String']>
  ctaTheme_exists?: InputMaybe<Scalars['Boolean']>
  ctaTheme_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  ctaTheme_not?: InputMaybe<Scalars['String']>
  ctaTheme_not_contains?: InputMaybe<Scalars['String']>
  ctaTheme_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  ctaUrl?: InputMaybe<Scalars['String']>
  ctaUrl_contains?: InputMaybe<Scalars['String']>
  ctaUrl_exists?: InputMaybe<Scalars['Boolean']>
  ctaUrl_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  ctaUrl_not?: InputMaybe<Scalars['String']>
  ctaUrl_not_contains?: InputMaybe<Scalars['String']>
  ctaUrl_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  image_exists?: InputMaybe<Scalars['Boolean']>
  internalLabel?: InputMaybe<Scalars['String']>
  internalLabel_contains?: InputMaybe<Scalars['String']>
  internalLabel_exists?: InputMaybe<Scalars['Boolean']>
  internalLabel_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  internalLabel_not?: InputMaybe<Scalars['String']>
  internalLabel_not_contains?: InputMaybe<Scalars['String']>
  internalLabel_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  sectionId?: InputMaybe<Scalars['String']>
  sectionId_contains?: InputMaybe<Scalars['String']>
  sectionId_exists?: InputMaybe<Scalars['Boolean']>
  sectionId_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  sectionId_not?: InputMaybe<Scalars['String']>
  sectionId_not_contains?: InputMaybe<Scalars['String']>
  sectionId_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  sys?: InputMaybe<SysFilter>
}

export type CfComponentWhatYouGetCardNestedFilter = {
  AND?: InputMaybe<Array<InputMaybe<CfComponentWhatYouGetCardNestedFilter>>>
  OR?: InputMaybe<Array<InputMaybe<CfComponentWhatYouGetCardNestedFilter>>>
  bulletFour?: InputMaybe<Scalars['String']>
  bulletFour_contains?: InputMaybe<Scalars['String']>
  bulletFour_exists?: InputMaybe<Scalars['Boolean']>
  bulletFour_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  bulletFour_not?: InputMaybe<Scalars['String']>
  bulletFour_not_contains?: InputMaybe<Scalars['String']>
  bulletFour_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  bulletOne?: InputMaybe<Scalars['String']>
  bulletOne_contains?: InputMaybe<Scalars['String']>
  bulletOne_exists?: InputMaybe<Scalars['Boolean']>
  bulletOne_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  bulletOne_not?: InputMaybe<Scalars['String']>
  bulletOne_not_contains?: InputMaybe<Scalars['String']>
  bulletOne_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  bulletThree?: InputMaybe<Scalars['String']>
  bulletThree_contains?: InputMaybe<Scalars['String']>
  bulletThree_exists?: InputMaybe<Scalars['Boolean']>
  bulletThree_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  bulletThree_not?: InputMaybe<Scalars['String']>
  bulletThree_not_contains?: InputMaybe<Scalars['String']>
  bulletThree_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  bulletTwo?: InputMaybe<Scalars['String']>
  bulletTwo_contains?: InputMaybe<Scalars['String']>
  bulletTwo_exists?: InputMaybe<Scalars['Boolean']>
  bulletTwo_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  bulletTwo_not?: InputMaybe<Scalars['String']>
  bulletTwo_not_contains?: InputMaybe<Scalars['String']>
  bulletTwo_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  buttonText?: InputMaybe<Scalars['String']>
  buttonText_contains?: InputMaybe<Scalars['String']>
  buttonText_exists?: InputMaybe<Scalars['Boolean']>
  buttonText_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  buttonText_not?: InputMaybe<Scalars['String']>
  buttonText_not_contains?: InputMaybe<Scalars['String']>
  buttonText_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  buttonUrl?: InputMaybe<Scalars['String']>
  buttonUrl_contains?: InputMaybe<Scalars['String']>
  buttonUrl_exists?: InputMaybe<Scalars['Boolean']>
  buttonUrl_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  buttonUrl_not?: InputMaybe<Scalars['String']>
  buttonUrl_not_contains?: InputMaybe<Scalars['String']>
  buttonUrl_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  contentfulMetadata?: InputMaybe<ContentfulMetadataFilter>
  fallbackImage_exists?: InputMaybe<Scalars['Boolean']>
  headline?: InputMaybe<Scalars['String']>
  headline_contains?: InputMaybe<Scalars['String']>
  headline_exists?: InputMaybe<Scalars['Boolean']>
  headline_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  headline_not?: InputMaybe<Scalars['String']>
  headline_not_contains?: InputMaybe<Scalars['String']>
  headline_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  iconFour_exists?: InputMaybe<Scalars['Boolean']>
  iconOne_exists?: InputMaybe<Scalars['Boolean']>
  iconThree_exists?: InputMaybe<Scalars['Boolean']>
  iconTwo_exists?: InputMaybe<Scalars['Boolean']>
  image_exists?: InputMaybe<Scalars['Boolean']>
  subHead?: InputMaybe<Scalars['String']>
  subHead_contains?: InputMaybe<Scalars['String']>
  subHead_exists?: InputMaybe<Scalars['Boolean']>
  subHead_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  subHead_not?: InputMaybe<Scalars['String']>
  subHead_not_contains?: InputMaybe<Scalars['String']>
  subHead_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  sys?: InputMaybe<SysFilter>
}

export type CfDestinationsSubcategoriesNestedFilter = {
  AND?: InputMaybe<Array<InputMaybe<CfDestinationsSubcategoriesNestedFilter>>>
  OR?: InputMaybe<Array<InputMaybe<CfDestinationsSubcategoriesNestedFilter>>>
  childSubcategoriesCollection_exists?: InputMaybe<Scalars['Boolean']>
  cloudinaryFeaturedImage_exists?: InputMaybe<Scalars['Boolean']>
  cloudinaryMobileFeaturedImage_exists?: InputMaybe<Scalars['Boolean']>
  contentfulMetadata?: InputMaybe<ContentfulMetadataFilter>
  country_exists?: InputMaybe<Scalars['Boolean']>
  featuredHotelsCollection_exists?: InputMaybe<Scalars['Boolean']>
  featuredImage_exists?: InputMaybe<Scalars['Boolean']>
  generateLandingPage?: InputMaybe<Scalars['Boolean']>
  generateLandingPage_exists?: InputMaybe<Scalars['Boolean']>
  generateLandingPage_not?: InputMaybe<Scalars['Boolean']>
  generateTopHotelsPage?: InputMaybe<Scalars['Boolean']>
  generateTopHotelsPage_exists?: InputMaybe<Scalars['Boolean']>
  generateTopHotelsPage_not?: InputMaybe<Scalars['Boolean']>
  grammarDefiniteArticle?: InputMaybe<Scalars['Boolean']>
  grammarDefiniteArticle_exists?: InputMaybe<Scalars['Boolean']>
  grammarDefiniteArticle_not?: InputMaybe<Scalars['Boolean']>
  metaContent_exists?: InputMaybe<Scalars['Boolean']>
  mobileFeaturedImage_exists?: InputMaybe<Scalars['Boolean']>
  questionsCollection_exists?: InputMaybe<Scalars['Boolean']>
  rankedHotels_exists?: InputMaybe<Scalars['Boolean']>
  relatedDestinationsCollection_exists?: InputMaybe<Scalars['Boolean']>
  showInCategoryList?: InputMaybe<Scalars['Boolean']>
  showInCategoryList_exists?: InputMaybe<Scalars['Boolean']>
  showInCategoryList_not?: InputMaybe<Scalars['Boolean']>
  slug?: InputMaybe<Scalars['String']>
  slug_contains?: InputMaybe<Scalars['String']>
  slug_exists?: InputMaybe<Scalars['Boolean']>
  slug_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  slug_not?: InputMaybe<Scalars['String']>
  slug_not_contains?: InputMaybe<Scalars['String']>
  slug_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  sys?: InputMaybe<SysFilter>
  teaserCopy_contains?: InputMaybe<Scalars['String']>
  teaserCopy_exists?: InputMaybe<Scalars['Boolean']>
  teaserCopy_not_contains?: InputMaybe<Scalars['String']>
  title?: InputMaybe<Scalars['String']>
  title_contains?: InputMaybe<Scalars['String']>
  title_exists?: InputMaybe<Scalars['Boolean']>
  title_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  title_not?: InputMaybe<Scalars['String']>
  title_not_contains?: InputMaybe<Scalars['String']>
  title_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
}

export type CfEntryPartnerNestedFilter = {
  AND?: InputMaybe<Array<InputMaybe<CfEntryPartnerNestedFilter>>>
  OR?: InputMaybe<Array<InputMaybe<CfEntryPartnerNestedFilter>>>
  benefits_contains?: InputMaybe<Scalars['String']>
  benefits_exists?: InputMaybe<Scalars['Boolean']>
  benefits_not_contains?: InputMaybe<Scalars['String']>
  cardCopy_contains?: InputMaybe<Scalars['String']>
  cardCopy_exists?: InputMaybe<Scalars['Boolean']>
  cardCopy_not_contains?: InputMaybe<Scalars['String']>
  contentfulMetadata?: InputMaybe<ContentfulMetadataFilter>
  detailSummary?: InputMaybe<Scalars['String']>
  detailSummary_contains?: InputMaybe<Scalars['String']>
  detailSummary_exists?: InputMaybe<Scalars['Boolean']>
  detailSummary_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  detailSummary_not?: InputMaybe<Scalars['String']>
  detailSummary_not_contains?: InputMaybe<Scalars['String']>
  detailSummary_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  featured?: InputMaybe<Scalars['Boolean']>
  featuredArticlesCollection_exists?: InputMaybe<Scalars['Boolean']>
  featuredPropertiesCollection_exists?: InputMaybe<Scalars['Boolean']>
  featured_exists?: InputMaybe<Scalars['Boolean']>
  featured_not?: InputMaybe<Scalars['Boolean']>
  hasDetailPage?: InputMaybe<Scalars['Boolean']>
  hasDetailPage_exists?: InputMaybe<Scalars['Boolean']>
  hasDetailPage_not?: InputMaybe<Scalars['Boolean']>
  headline_contains?: InputMaybe<Scalars['String']>
  headline_exists?: InputMaybe<Scalars['Boolean']>
  headline_not_contains?: InputMaybe<Scalars['String']>
  hideFromPartnerGrid?: InputMaybe<Scalars['Boolean']>
  hideFromPartnerGrid_exists?: InputMaybe<Scalars['Boolean']>
  hideFromPartnerGrid_not?: InputMaybe<Scalars['Boolean']>
  howToBookSection_exists?: InputMaybe<Scalars['Boolean']>
  introSection_exists?: InputMaybe<Scalars['Boolean']>
  logo_exists?: InputMaybe<Scalars['Boolean']>
  mainImage_exists?: InputMaybe<Scalars['Boolean']>
  metaContent_exists?: InputMaybe<Scalars['Boolean']>
  name?: InputMaybe<Scalars['String']>
  name_contains?: InputMaybe<Scalars['String']>
  name_exists?: InputMaybe<Scalars['Boolean']>
  name_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  name_not?: InputMaybe<Scalars['String']>
  name_not_contains?: InputMaybe<Scalars['String']>
  name_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  partnerCategory?: InputMaybe<Scalars['String']>
  partnerCategory_contains?: InputMaybe<Scalars['String']>
  partnerCategory_exists?: InputMaybe<Scalars['Boolean']>
  partnerCategory_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  partnerCategory_not?: InputMaybe<Scalars['String']>
  partnerCategory_not_contains?: InputMaybe<Scalars['String']>
  partnerCategory_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  partnerType?: InputMaybe<Scalars['String']>
  partnerType_contains?: InputMaybe<Scalars['String']>
  partnerType_exists?: InputMaybe<Scalars['Boolean']>
  partnerType_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  partnerType_not?: InputMaybe<Scalars['String']>
  partnerType_not_contains?: InputMaybe<Scalars['String']>
  partnerType_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  perksSection_exists?: InputMaybe<Scalars['Boolean']>
  questionGroup_exists?: InputMaybe<Scalars['Boolean']>
  relatedPartnersCollection_exists?: InputMaybe<Scalars['Boolean']>
  slug?: InputMaybe<Scalars['String']>
  slug_contains?: InputMaybe<Scalars['String']>
  slug_exists?: InputMaybe<Scalars['Boolean']>
  slug_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  slug_not?: InputMaybe<Scalars['String']>
  slug_not_contains?: InputMaybe<Scalars['String']>
  slug_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  sys?: InputMaybe<SysFilter>
}

export type CfEntryTestimonialNestedFilter = {
  AND?: InputMaybe<Array<InputMaybe<CfEntryTestimonialNestedFilter>>>
  OR?: InputMaybe<Array<InputMaybe<CfEntryTestimonialNestedFilter>>>
  aboutFora?: InputMaybe<Scalars['Boolean']>
  aboutFora_exists?: InputMaybe<Scalars['Boolean']>
  aboutFora_not?: InputMaybe<Scalars['Boolean']>
  advisor_exists?: InputMaybe<Scalars['Boolean']>
  beginTravelDate?: InputMaybe<Scalars['DateTime']>
  beginTravelDate_exists?: InputMaybe<Scalars['Boolean']>
  beginTravelDate_gt?: InputMaybe<Scalars['DateTime']>
  beginTravelDate_gte?: InputMaybe<Scalars['DateTime']>
  beginTravelDate_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']>>>
  beginTravelDate_lt?: InputMaybe<Scalars['DateTime']>
  beginTravelDate_lte?: InputMaybe<Scalars['DateTime']>
  beginTravelDate_not?: InputMaybe<Scalars['DateTime']>
  beginTravelDate_not_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']>>>
  categoriesCollection_exists?: InputMaybe<Scalars['Boolean']>
  contentfulMetadata?: InputMaybe<ContentfulMetadataFilter>
  dateSubmitted?: InputMaybe<Scalars['DateTime']>
  dateSubmitted_exists?: InputMaybe<Scalars['Boolean']>
  dateSubmitted_gt?: InputMaybe<Scalars['DateTime']>
  dateSubmitted_gte?: InputMaybe<Scalars['DateTime']>
  dateSubmitted_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']>>>
  dateSubmitted_lt?: InputMaybe<Scalars['DateTime']>
  dateSubmitted_lte?: InputMaybe<Scalars['DateTime']>
  dateSubmitted_not?: InputMaybe<Scalars['DateTime']>
  dateSubmitted_not_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']>>>
  destinationsSummary_contains?: InputMaybe<Scalars['String']>
  destinationsSummary_exists?: InputMaybe<Scalars['Boolean']>
  destinationsSummary_not_contains?: InputMaybe<Scalars['String']>
  display?: InputMaybe<Scalars['Boolean']>
  display_exists?: InputMaybe<Scalars['Boolean']>
  display_not?: InputMaybe<Scalars['Boolean']>
  endTravelDate?: InputMaybe<Scalars['DateTime']>
  endTravelDate_exists?: InputMaybe<Scalars['Boolean']>
  endTravelDate_gt?: InputMaybe<Scalars['DateTime']>
  endTravelDate_gte?: InputMaybe<Scalars['DateTime']>
  endTravelDate_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']>>>
  endTravelDate_lt?: InputMaybe<Scalars['DateTime']>
  endTravelDate_lte?: InputMaybe<Scalars['DateTime']>
  endTravelDate_not?: InputMaybe<Scalars['DateTime']>
  endTravelDate_not_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']>>>
  hotel_exists?: InputMaybe<Scalars['Boolean']>
  hotelsCollection_exists?: InputMaybe<Scalars['Boolean']>
  internalLabel?: InputMaybe<Scalars['String']>
  internalLabel_contains?: InputMaybe<Scalars['String']>
  internalLabel_exists?: InputMaybe<Scalars['Boolean']>
  internalLabel_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  internalLabel_not?: InputMaybe<Scalars['String']>
  internalLabel_not_contains?: InputMaybe<Scalars['String']>
  internalLabel_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  isFeatured?: InputMaybe<Scalars['Boolean']>
  isFeatured_exists?: InputMaybe<Scalars['Boolean']>
  isFeatured_not?: InputMaybe<Scalars['Boolean']>
  relatedGuide_exists?: InputMaybe<Scalars['Boolean']>
  stars?: InputMaybe<Scalars['Int']>
  stars_exists?: InputMaybe<Scalars['Boolean']>
  stars_gt?: InputMaybe<Scalars['Int']>
  stars_gte?: InputMaybe<Scalars['Int']>
  stars_in?: InputMaybe<Array<InputMaybe<Scalars['Int']>>>
  stars_lt?: InputMaybe<Scalars['Int']>
  stars_lte?: InputMaybe<Scalars['Int']>
  stars_not?: InputMaybe<Scalars['Int']>
  stars_not_in?: InputMaybe<Array<InputMaybe<Scalars['Int']>>>
  submittedTestimonial?: InputMaybe<Scalars['String']>
  submittedTestimonial_contains?: InputMaybe<Scalars['String']>
  submittedTestimonial_exists?: InputMaybe<Scalars['Boolean']>
  submittedTestimonial_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  submittedTestimonial_not?: InputMaybe<Scalars['String']>
  submittedTestimonial_not_contains?: InputMaybe<Scalars['String']>
  submittedTestimonial_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  submitterEmail?: InputMaybe<Scalars['String']>
  submitterEmail_contains?: InputMaybe<Scalars['String']>
  submitterEmail_exists?: InputMaybe<Scalars['Boolean']>
  submitterEmail_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  submitterEmail_not?: InputMaybe<Scalars['String']>
  submitterEmail_not_contains?: InputMaybe<Scalars['String']>
  submitterEmail_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  submitterFirstName?: InputMaybe<Scalars['String']>
  submitterFirstName_contains?: InputMaybe<Scalars['String']>
  submitterFirstName_exists?: InputMaybe<Scalars['Boolean']>
  submitterFirstName_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  submitterFirstName_not?: InputMaybe<Scalars['String']>
  submitterFirstName_not_contains?: InputMaybe<Scalars['String']>
  submitterFirstName_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  submitterImage_exists?: InputMaybe<Scalars['Boolean']>
  submitterLastName?: InputMaybe<Scalars['String']>
  submitterLastName_contains?: InputMaybe<Scalars['String']>
  submitterLastName_exists?: InputMaybe<Scalars['Boolean']>
  submitterLastName_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  submitterLastName_not?: InputMaybe<Scalars['String']>
  submitterLastName_not_contains?: InputMaybe<Scalars['String']>
  submitterLastName_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  sys?: InputMaybe<SysFilter>
  testimonialSource?: InputMaybe<Scalars['String']>
  testimonialSource_contains?: InputMaybe<Scalars['String']>
  testimonialSource_exists?: InputMaybe<Scalars['Boolean']>
  testimonialSource_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  testimonialSource_not?: InputMaybe<Scalars['String']>
  testimonialSource_not_contains?: InputMaybe<Scalars['String']>
  testimonialSource_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  testimonial_contains?: InputMaybe<Scalars['String']>
  testimonial_exists?: InputMaybe<Scalars['Boolean']>
  testimonial_not_contains?: InputMaybe<Scalars['String']>
  titleOfReview?: InputMaybe<Scalars['String']>
  titleOfReview_contains?: InputMaybe<Scalars['String']>
  titleOfReview_exists?: InputMaybe<Scalars['Boolean']>
  titleOfReview_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  titleOfReview_not?: InputMaybe<Scalars['String']>
  titleOfReview_not_contains?: InputMaybe<Scalars['String']>
  titleOfReview_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
}

export type CfFaqsQuestionGroupsNestedFilter = {
  AND?: InputMaybe<Array<InputMaybe<CfFaqsQuestionGroupsNestedFilter>>>
  OR?: InputMaybe<Array<InputMaybe<CfFaqsQuestionGroupsNestedFilter>>>
  adminLabel?: InputMaybe<Scalars['String']>
  adminLabel_contains?: InputMaybe<Scalars['String']>
  adminLabel_exists?: InputMaybe<Scalars['Boolean']>
  adminLabel_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  adminLabel_not?: InputMaybe<Scalars['String']>
  adminLabel_not_contains?: InputMaybe<Scalars['String']>
  adminLabel_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  automaticallyExpandQuestions?: InputMaybe<Scalars['Boolean']>
  automaticallyExpandQuestions_exists?: InputMaybe<Scalars['Boolean']>
  automaticallyExpandQuestions_not?: InputMaybe<Scalars['Boolean']>
  contentfulMetadata?: InputMaybe<ContentfulMetadataFilter>
  questionsCollection_exists?: InputMaybe<Scalars['Boolean']>
  richTitle_contains?: InputMaybe<Scalars['String']>
  richTitle_exists?: InputMaybe<Scalars['Boolean']>
  richTitle_not_contains?: InputMaybe<Scalars['String']>
  sectionId?: InputMaybe<Scalars['String']>
  sectionId_contains?: InputMaybe<Scalars['String']>
  sectionId_exists?: InputMaybe<Scalars['Boolean']>
  sectionId_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  sectionId_not?: InputMaybe<Scalars['String']>
  sectionId_not_contains?: InputMaybe<Scalars['String']>
  sectionId_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  sys?: InputMaybe<SysFilter>
  title?: InputMaybe<Scalars['String']>
  titleStyle?: InputMaybe<Scalars['String']>
  titleStyle_contains?: InputMaybe<Scalars['String']>
  titleStyle_exists?: InputMaybe<Scalars['Boolean']>
  titleStyle_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  titleStyle_not?: InputMaybe<Scalars['String']>
  titleStyle_not_contains?: InputMaybe<Scalars['String']>
  titleStyle_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  title_contains?: InputMaybe<Scalars['String']>
  title_exists?: InputMaybe<Scalars['Boolean']>
  title_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  title_not?: InputMaybe<Scalars['String']>
  title_not_contains?: InputMaybe<Scalars['String']>
  title_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
}

export type CfFaqsQuestionsNestedFilter = {
  AND?: InputMaybe<Array<InputMaybe<CfFaqsQuestionsNestedFilter>>>
  OR?: InputMaybe<Array<InputMaybe<CfFaqsQuestionsNestedFilter>>>
  answer_contains?: InputMaybe<Scalars['String']>
  answer_exists?: InputMaybe<Scalars['Boolean']>
  answer_not_contains?: InputMaybe<Scalars['String']>
  contentfulMetadata?: InputMaybe<ContentfulMetadataFilter>
  sys?: InputMaybe<SysFilter>
  title?: InputMaybe<Scalars['String']>
  title_contains?: InputMaybe<Scalars['String']>
  title_exists?: InputMaybe<Scalars['Boolean']>
  title_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  title_not?: InputMaybe<Scalars['String']>
  title_not_contains?: InputMaybe<Scalars['String']>
  title_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
}

export type CfHotelsNestedFilter = {
  AND?: InputMaybe<Array<InputMaybe<CfHotelsNestedFilter>>>
  OR?: InputMaybe<Array<InputMaybe<CfHotelsNestedFilter>>>
  activitiesAndExperiencesCollection_exists?: InputMaybe<Scalars['Boolean']>
  activitiesAndExperiencesSectionImage_exists?: InputMaybe<Scalars['Boolean']>
  amenitiesCollection_exists?: InputMaybe<Scalars['Boolean']>
  amenitiesSectionImage_exists?: InputMaybe<Scalars['Boolean']>
  archived?: InputMaybe<Scalars['Boolean']>
  archived_exists?: InputMaybe<Scalars['Boolean']>
  archived_not?: InputMaybe<Scalars['Boolean']>
  contentfulMetadata?: InputMaybe<ContentfulMetadataFilter>
  description_contains?: InputMaybe<Scalars['String']>
  description_exists?: InputMaybe<Scalars['Boolean']>
  description_not_contains?: InputMaybe<Scalars['String']>
  destinationCollection_exists?: InputMaybe<Scalars['Boolean']>
  duplicateOf_exists?: InputMaybe<Scalars['Boolean']>
  foraSupplierId?: InputMaybe<Scalars['String']>
  foraSupplierId_contains?: InputMaybe<Scalars['String']>
  foraSupplierId_exists?: InputMaybe<Scalars['Boolean']>
  foraSupplierId_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  foraSupplierId_not?: InputMaybe<Scalars['String']>
  foraSupplierId_not_contains?: InputMaybe<Scalars['String']>
  foraSupplierId_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  foraSupplier_exists?: InputMaybe<Scalars['Boolean']>
  foraVipPerksCollection_exists?: InputMaybe<Scalars['Boolean']>
  fullHotelDescription_contains?: InputMaybe<Scalars['String']>
  fullHotelDescription_exists?: InputMaybe<Scalars['Boolean']>
  fullHotelDescription_not_contains?: InputMaybe<Scalars['String']>
  generateLandingPage?: InputMaybe<Scalars['Boolean']>
  generateLandingPage_exists?: InputMaybe<Scalars['Boolean']>
  generateLandingPage_not?: InputMaybe<Scalars['Boolean']>
  heroImage?: InputMaybe<Scalars['String']>
  heroImage_contains?: InputMaybe<Scalars['String']>
  heroImage_exists?: InputMaybe<Scalars['Boolean']>
  heroImage_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  heroImage_not?: InputMaybe<Scalars['String']>
  heroImage_not_contains?: InputMaybe<Scalars['String']>
  heroImage_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  hideFromTopHotelsPages?: InputMaybe<Scalars['Boolean']>
  hideFromTopHotelsPages_exists?: InputMaybe<Scalars['Boolean']>
  hideFromTopHotelsPages_not?: InputMaybe<Scalars['Boolean']>
  highlightsCollection_exists?: InputMaybe<Scalars['Boolean']>
  hotelIntroParagraph_contains?: InputMaybe<Scalars['String']>
  hotelIntroParagraph_exists?: InputMaybe<Scalars['Boolean']>
  hotelIntroParagraph_not_contains?: InputMaybe<Scalars['String']>
  imageGallery_exists?: InputMaybe<Scalars['Boolean']>
  image_exists?: InputMaybe<Scalars['Boolean']>
  locationSectionImage_exists?: InputMaybe<Scalars['Boolean']>
  location_contains?: InputMaybe<Scalars['String']>
  location_exists?: InputMaybe<Scalars['Boolean']>
  location_not_contains?: InputMaybe<Scalars['String']>
  michelinKeys?: InputMaybe<Scalars['String']>
  michelinKeys_contains?: InputMaybe<Scalars['String']>
  michelinKeys_exists?: InputMaybe<Scalars['Boolean']>
  michelinKeys_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  michelinKeys_not?: InputMaybe<Scalars['String']>
  michelinKeys_not_contains?: InputMaybe<Scalars['String']>
  michelinKeys_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  partner_exists?: InputMaybe<Scalars['Boolean']>
  perks_contains_all?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  perks_contains_none?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  perks_contains_some?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  perks_exists?: InputMaybe<Scalars['Boolean']>
  roomsSectionImage_exists?: InputMaybe<Scalars['Boolean']>
  rooms_contains?: InputMaybe<Scalars['String']>
  rooms_exists?: InputMaybe<Scalars['Boolean']>
  rooms_not_contains?: InputMaybe<Scalars['String']>
  slug?: InputMaybe<Scalars['String']>
  slug_contains?: InputMaybe<Scalars['String']>
  slug_exists?: InputMaybe<Scalars['Boolean']>
  slug_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  slug_not?: InputMaybe<Scalars['String']>
  slug_not_contains?: InputMaybe<Scalars['String']>
  slug_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  starRating?: InputMaybe<Scalars['String']>
  starRating_contains?: InputMaybe<Scalars['String']>
  starRating_exists?: InputMaybe<Scalars['Boolean']>
  starRating_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  starRating_not?: InputMaybe<Scalars['String']>
  starRating_not_contains?: InputMaybe<Scalars['String']>
  starRating_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  sys?: InputMaybe<SysFilter>
  title?: InputMaybe<Scalars['String']>
  title_contains?: InputMaybe<Scalars['String']>
  title_exists?: InputMaybe<Scalars['Boolean']>
  title_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  title_not?: InputMaybe<Scalars['String']>
  title_not_contains?: InputMaybe<Scalars['String']>
  title_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
}

export type CfItinerariesNestedFilter = {
  AND?: InputMaybe<Array<InputMaybe<CfItinerariesNestedFilter>>>
  OR?: InputMaybe<Array<InputMaybe<CfItinerariesNestedFilter>>>
  additionalNotes_contains?: InputMaybe<Scalars['String']>
  additionalNotes_exists?: InputMaybe<Scalars['Boolean']>
  additionalNotes_not_contains?: InputMaybe<Scalars['String']>
  advisor_exists?: InputMaybe<Scalars['Boolean']>
  atAGlanceTags_contains_all?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  atAGlanceTags_contains_none?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  atAGlanceTags_contains_some?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  atAGlanceTags_exists?: InputMaybe<Scalars['Boolean']>
  categoriesCollection_exists?: InputMaybe<Scalars['Boolean']>
  cloudinaryImage_exists?: InputMaybe<Scalars['Boolean']>
  contentfulMetadata?: InputMaybe<ContentfulMetadataFilter>
  curatorsStatement_contains?: InputMaybe<Scalars['String']>
  curatorsStatement_exists?: InputMaybe<Scalars['Boolean']>
  curatorsStatement_not_contains?: InputMaybe<Scalars['String']>
  hotelsCollection_exists?: InputMaybe<Scalars['Boolean']>
  image_exists?: InputMaybe<Scalars['Boolean']>
  itineraryVariant?: InputMaybe<Scalars['String']>
  itineraryVariant_contains?: InputMaybe<Scalars['String']>
  itineraryVariant_exists?: InputMaybe<Scalars['Boolean']>
  itineraryVariant_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  itineraryVariant_not?: InputMaybe<Scalars['String']>
  itineraryVariant_not_contains?: InputMaybe<Scalars['String']>
  itineraryVariant_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  locationForTitles?: InputMaybe<Scalars['String']>
  locationForTitles_contains?: InputMaybe<Scalars['String']>
  locationForTitles_exists?: InputMaybe<Scalars['Boolean']>
  locationForTitles_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  locationForTitles_not?: InputMaybe<Scalars['String']>
  locationForTitles_not_contains?: InputMaybe<Scalars['String']>
  locationForTitles_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  metaDescription?: InputMaybe<Scalars['String']>
  metaDescription_contains?: InputMaybe<Scalars['String']>
  metaDescription_exists?: InputMaybe<Scalars['Boolean']>
  metaDescription_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  metaDescription_not?: InputMaybe<Scalars['String']>
  metaDescription_not_contains?: InputMaybe<Scalars['String']>
  metaDescription_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  metaTitle?: InputMaybe<Scalars['String']>
  metaTitle_contains?: InputMaybe<Scalars['String']>
  metaTitle_exists?: InputMaybe<Scalars['Boolean']>
  metaTitle_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  metaTitle_not?: InputMaybe<Scalars['String']>
  metaTitle_not_contains?: InputMaybe<Scalars['String']>
  metaTitle_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  needToKnow_contains?: InputMaybe<Scalars['String']>
  needToKnow_exists?: InputMaybe<Scalars['Boolean']>
  needToKnow_not_contains?: InputMaybe<Scalars['String']>
  plansCollection_exists?: InputMaybe<Scalars['Boolean']>
  seoSlug?: InputMaybe<Scalars['String']>
  seoSlug_contains?: InputMaybe<Scalars['String']>
  seoSlug_exists?: InputMaybe<Scalars['Boolean']>
  seoSlug_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  seoSlug_not?: InputMaybe<Scalars['String']>
  seoSlug_not_contains?: InputMaybe<Scalars['String']>
  seoSlug_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  showOnHomepage?: InputMaybe<Scalars['Boolean']>
  showOnHomepage_exists?: InputMaybe<Scalars['Boolean']>
  showOnHomepage_not?: InputMaybe<Scalars['Boolean']>
  showOnItinerariesPage?: InputMaybe<Scalars['Boolean']>
  showOnItinerariesPage_exists?: InputMaybe<Scalars['Boolean']>
  showOnItinerariesPage_not?: InputMaybe<Scalars['Boolean']>
  showOnOtherLandingPages?: InputMaybe<Scalars['Boolean']>
  showOnOtherLandingPages_exists?: InputMaybe<Scalars['Boolean']>
  showOnOtherLandingPages_not?: InputMaybe<Scalars['Boolean']>
  slug?: InputMaybe<Scalars['String']>
  slugID?: InputMaybe<Scalars['String']>
  slugID_contains?: InputMaybe<Scalars['String']>
  slugID_exists?: InputMaybe<Scalars['Boolean']>
  slugID_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  slugID_not?: InputMaybe<Scalars['String']>
  slugID_not_contains?: InputMaybe<Scalars['String']>
  slugID_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  slug_contains?: InputMaybe<Scalars['String']>
  slug_exists?: InputMaybe<Scalars['Boolean']>
  slug_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  slug_not?: InputMaybe<Scalars['String']>
  slug_not_contains?: InputMaybe<Scalars['String']>
  slug_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  sys?: InputMaybe<SysFilter>
  title?: InputMaybe<Scalars['String']>
  title_contains?: InputMaybe<Scalars['String']>
  title_exists?: InputMaybe<Scalars['Boolean']>
  title_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  title_not?: InputMaybe<Scalars['String']>
  title_not_contains?: InputMaybe<Scalars['String']>
  title_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
}

export type CfItinerariesPlansNestedFilter = {
  AND?: InputMaybe<Array<InputMaybe<CfItinerariesPlansNestedFilter>>>
  OR?: InputMaybe<Array<InputMaybe<CfItinerariesPlansNestedFilter>>>
  content_contains?: InputMaybe<Scalars['String']>
  content_exists?: InputMaybe<Scalars['Boolean']>
  content_not_contains?: InputMaybe<Scalars['String']>
  contentfulMetadata?: InputMaybe<ContentfulMetadataFilter>
  imageCaption?: InputMaybe<Scalars['String']>
  imageCaption_contains?: InputMaybe<Scalars['String']>
  imageCaption_exists?: InputMaybe<Scalars['Boolean']>
  imageCaption_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  imageCaption_not?: InputMaybe<Scalars['String']>
  imageCaption_not_contains?: InputMaybe<Scalars['String']>
  imageCaption_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  imageContent_exists?: InputMaybe<Scalars['Boolean']>
  image_exists?: InputMaybe<Scalars['Boolean']>
  note_contains?: InputMaybe<Scalars['String']>
  note_exists?: InputMaybe<Scalars['Boolean']>
  note_not_contains?: InputMaybe<Scalars['String']>
  sys?: InputMaybe<SysFilter>
  title?: InputMaybe<Scalars['String']>
  title_contains?: InputMaybe<Scalars['String']>
  title_exists?: InputMaybe<Scalars['Boolean']>
  title_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  title_not?: InputMaybe<Scalars['String']>
  title_not_contains?: InputMaybe<Scalars['String']>
  title_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
}

export type CfPagesHeaderBlockNestedFilter = {
  AND?: InputMaybe<Array<InputMaybe<CfPagesHeaderBlockNestedFilter>>>
  OR?: InputMaybe<Array<InputMaybe<CfPagesHeaderBlockNestedFilter>>>
  analyticsModule_exists?: InputMaybe<Scalars['Boolean']>
  borders?: InputMaybe<Scalars['Boolean']>
  borders_exists?: InputMaybe<Scalars['Boolean']>
  borders_not?: InputMaybe<Scalars['Boolean']>
  contentfulMetadata?: InputMaybe<ContentfulMetadataFilter>
  ctaText?: InputMaybe<Scalars['String']>
  ctaText_contains?: InputMaybe<Scalars['String']>
  ctaText_exists?: InputMaybe<Scalars['Boolean']>
  ctaText_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  ctaText_not?: InputMaybe<Scalars['String']>
  ctaText_not_contains?: InputMaybe<Scalars['String']>
  ctaText_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  ctaTheme?: InputMaybe<Scalars['String']>
  ctaTheme_contains?: InputMaybe<Scalars['String']>
  ctaTheme_exists?: InputMaybe<Scalars['Boolean']>
  ctaTheme_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  ctaTheme_not?: InputMaybe<Scalars['String']>
  ctaTheme_not_contains?: InputMaybe<Scalars['String']>
  ctaTheme_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  ctaUrl?: InputMaybe<Scalars['String']>
  ctaUrl_contains?: InputMaybe<Scalars['String']>
  ctaUrl_exists?: InputMaybe<Scalars['Boolean']>
  ctaUrl_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  ctaUrl_not?: InputMaybe<Scalars['String']>
  ctaUrl_not_contains?: InputMaybe<Scalars['String']>
  ctaUrl_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  footNote?: InputMaybe<Scalars['String']>
  footNote_contains?: InputMaybe<Scalars['String']>
  footNote_exists?: InputMaybe<Scalars['Boolean']>
  footNote_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  footNote_not?: InputMaybe<Scalars['String']>
  footNote_not_contains?: InputMaybe<Scalars['String']>
  footNote_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  icon_exists?: InputMaybe<Scalars['Boolean']>
  secondaryCtaText?: InputMaybe<Scalars['String']>
  secondaryCtaText_contains?: InputMaybe<Scalars['String']>
  secondaryCtaText_exists?: InputMaybe<Scalars['Boolean']>
  secondaryCtaText_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  secondaryCtaText_not?: InputMaybe<Scalars['String']>
  secondaryCtaText_not_contains?: InputMaybe<Scalars['String']>
  secondaryCtaText_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  secondaryCtaUrl?: InputMaybe<Scalars['String']>
  secondaryCtaUrl_contains?: InputMaybe<Scalars['String']>
  secondaryCtaUrl_exists?: InputMaybe<Scalars['Boolean']>
  secondaryCtaUrl_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  secondaryCtaUrl_not?: InputMaybe<Scalars['String']>
  secondaryCtaUrl_not_contains?: InputMaybe<Scalars['String']>
  secondaryCtaUrl_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  secondaryUrlTarget?: InputMaybe<Scalars['String']>
  secondaryUrlTarget_contains?: InputMaybe<Scalars['String']>
  secondaryUrlTarget_exists?: InputMaybe<Scalars['Boolean']>
  secondaryUrlTarget_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  secondaryUrlTarget_not?: InputMaybe<Scalars['String']>
  secondaryUrlTarget_not_contains?: InputMaybe<Scalars['String']>
  secondaryUrlTarget_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  sectionId?: InputMaybe<Scalars['String']>
  sectionId_contains?: InputMaybe<Scalars['String']>
  sectionId_exists?: InputMaybe<Scalars['Boolean']>
  sectionId_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  sectionId_not?: InputMaybe<Scalars['String']>
  sectionId_not_contains?: InputMaybe<Scalars['String']>
  sectionId_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  sys?: InputMaybe<SysFilter>
  teaserCopy_contains?: InputMaybe<Scalars['String']>
  teaserCopy_exists?: InputMaybe<Scalars['Boolean']>
  teaserCopy_not_contains?: InputMaybe<Scalars['String']>
  textContent_contains?: InputMaybe<Scalars['String']>
  textContent_exists?: InputMaybe<Scalars['Boolean']>
  textContent_not_contains?: InputMaybe<Scalars['String']>
  title?: InputMaybe<Scalars['String']>
  title_contains?: InputMaybe<Scalars['String']>
  title_exists?: InputMaybe<Scalars['Boolean']>
  title_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  title_not?: InputMaybe<Scalars['String']>
  title_not_contains?: InputMaybe<Scalars['String']>
  title_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  urlTarget?: InputMaybe<Scalars['String']>
  urlTarget_contains?: InputMaybe<Scalars['String']>
  urlTarget_exists?: InputMaybe<Scalars['Boolean']>
  urlTarget_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  urlTarget_not?: InputMaybe<Scalars['String']>
  urlTarget_not_contains?: InputMaybe<Scalars['String']>
  urlTarget_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
}

export type CfPagesHeroBlockNestedFilter = {
  AND?: InputMaybe<Array<InputMaybe<CfPagesHeroBlockNestedFilter>>>
  OR?: InputMaybe<Array<InputMaybe<CfPagesHeroBlockNestedFilter>>>
  analyticsModule_exists?: InputMaybe<Scalars['Boolean']>
  contentfulMetadata?: InputMaybe<ContentfulMetadataFilter>
  image_exists?: InputMaybe<Scalars['Boolean']>
  primaryCtaLinkText?: InputMaybe<Scalars['String']>
  primaryCtaLinkText_contains?: InputMaybe<Scalars['String']>
  primaryCtaLinkText_exists?: InputMaybe<Scalars['Boolean']>
  primaryCtaLinkText_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  primaryCtaLinkText_not?: InputMaybe<Scalars['String']>
  primaryCtaLinkText_not_contains?: InputMaybe<Scalars['String']>
  primaryCtaLinkText_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  primaryCtaLinkUrl?: InputMaybe<Scalars['String']>
  primaryCtaLinkUrl_contains?: InputMaybe<Scalars['String']>
  primaryCtaLinkUrl_exists?: InputMaybe<Scalars['Boolean']>
  primaryCtaLinkUrl_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  primaryCtaLinkUrl_not?: InputMaybe<Scalars['String']>
  primaryCtaLinkUrl_not_contains?: InputMaybe<Scalars['String']>
  primaryCtaLinkUrl_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  primaryUrlTarget?: InputMaybe<Scalars['String']>
  primaryUrlTarget_contains?: InputMaybe<Scalars['String']>
  primaryUrlTarget_exists?: InputMaybe<Scalars['Boolean']>
  primaryUrlTarget_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  primaryUrlTarget_not?: InputMaybe<Scalars['String']>
  primaryUrlTarget_not_contains?: InputMaybe<Scalars['String']>
  primaryUrlTarget_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  secondaryCtaLinkText?: InputMaybe<Scalars['String']>
  secondaryCtaLinkText_contains?: InputMaybe<Scalars['String']>
  secondaryCtaLinkText_exists?: InputMaybe<Scalars['Boolean']>
  secondaryCtaLinkText_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  secondaryCtaLinkText_not?: InputMaybe<Scalars['String']>
  secondaryCtaLinkText_not_contains?: InputMaybe<Scalars['String']>
  secondaryCtaLinkText_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  secondaryCtaLinkUrl?: InputMaybe<Scalars['String']>
  secondaryCtaLinkUrl_contains?: InputMaybe<Scalars['String']>
  secondaryCtaLinkUrl_exists?: InputMaybe<Scalars['Boolean']>
  secondaryCtaLinkUrl_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  secondaryCtaLinkUrl_not?: InputMaybe<Scalars['String']>
  secondaryCtaLinkUrl_not_contains?: InputMaybe<Scalars['String']>
  secondaryCtaLinkUrl_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  secondaryUrlTarget?: InputMaybe<Scalars['String']>
  secondaryUrlTarget_contains?: InputMaybe<Scalars['String']>
  secondaryUrlTarget_exists?: InputMaybe<Scalars['Boolean']>
  secondaryUrlTarget_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  secondaryUrlTarget_not?: InputMaybe<Scalars['String']>
  secondaryUrlTarget_not_contains?: InputMaybe<Scalars['String']>
  secondaryUrlTarget_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  showFormAsPrimaryCta?: InputMaybe<Scalars['String']>
  showFormAsPrimaryCta_contains?: InputMaybe<Scalars['String']>
  showFormAsPrimaryCta_exists?: InputMaybe<Scalars['Boolean']>
  showFormAsPrimaryCta_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  showFormAsPrimaryCta_not?: InputMaybe<Scalars['String']>
  showFormAsPrimaryCta_not_contains?: InputMaybe<Scalars['String']>
  showFormAsPrimaryCta_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  showSignUpFormAsPrimaryCta?: InputMaybe<Scalars['Boolean']>
  showSignUpFormAsPrimaryCta_exists?: InputMaybe<Scalars['Boolean']>
  showSignUpFormAsPrimaryCta_not?: InputMaybe<Scalars['Boolean']>
  subFormCopy_contains?: InputMaybe<Scalars['String']>
  subFormCopy_exists?: InputMaybe<Scalars['Boolean']>
  subFormCopy_not_contains?: InputMaybe<Scalars['String']>
  subFormHeader?: InputMaybe<Scalars['String']>
  subFormHeader_contains?: InputMaybe<Scalars['String']>
  subFormHeader_exists?: InputMaybe<Scalars['Boolean']>
  subFormHeader_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  subFormHeader_not?: InputMaybe<Scalars['String']>
  subFormHeader_not_contains?: InputMaybe<Scalars['String']>
  subFormHeader_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  sys?: InputMaybe<SysFilter>
  teaserCopy_contains?: InputMaybe<Scalars['String']>
  teaserCopy_exists?: InputMaybe<Scalars['Boolean']>
  teaserCopy_not_contains?: InputMaybe<Scalars['String']>
  title?: InputMaybe<Scalars['String']>
  titleStyle?: InputMaybe<Scalars['String']>
  titleStyle_contains?: InputMaybe<Scalars['String']>
  titleStyle_exists?: InputMaybe<Scalars['Boolean']>
  titleStyle_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  titleStyle_not?: InputMaybe<Scalars['String']>
  titleStyle_not_contains?: InputMaybe<Scalars['String']>
  titleStyle_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  title_contains?: InputMaybe<Scalars['String']>
  title_exists?: InputMaybe<Scalars['Boolean']>
  title_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  title_not?: InputMaybe<Scalars['String']>
  title_not_contains?: InputMaybe<Scalars['String']>
  title_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
}

export type CfPagesLearnMoreBlockNestedFilter = {
  AND?: InputMaybe<Array<InputMaybe<CfPagesLearnMoreBlockNestedFilter>>>
  OR?: InputMaybe<Array<InputMaybe<CfPagesLearnMoreBlockNestedFilter>>>
  analyticsModule_exists?: InputMaybe<Scalars['Boolean']>
  animate?: InputMaybe<Scalars['Boolean']>
  animate_exists?: InputMaybe<Scalars['Boolean']>
  animate_not?: InputMaybe<Scalars['Boolean']>
  bgColor?: InputMaybe<Scalars['String']>
  bgColor_contains?: InputMaybe<Scalars['String']>
  bgColor_exists?: InputMaybe<Scalars['Boolean']>
  bgColor_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  bgColor_not?: InputMaybe<Scalars['String']>
  bgColor_not_contains?: InputMaybe<Scalars['String']>
  bgColor_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  contentfulMetadata?: InputMaybe<ContentfulMetadataFilter>
  ctaText?: InputMaybe<Scalars['String']>
  ctaText_contains?: InputMaybe<Scalars['String']>
  ctaText_exists?: InputMaybe<Scalars['Boolean']>
  ctaText_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  ctaText_not?: InputMaybe<Scalars['String']>
  ctaText_not_contains?: InputMaybe<Scalars['String']>
  ctaText_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  ctaUrl?: InputMaybe<Scalars['String']>
  ctaUrl_contains?: InputMaybe<Scalars['String']>
  ctaUrl_exists?: InputMaybe<Scalars['Boolean']>
  ctaUrl_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  ctaUrl_not?: InputMaybe<Scalars['String']>
  ctaUrl_not_contains?: InputMaybe<Scalars['String']>
  ctaUrl_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  headerImage_exists?: InputMaybe<Scalars['Boolean']>
  imageColumnOne_exists?: InputMaybe<Scalars['Boolean']>
  imageColumnThree_exists?: InputMaybe<Scalars['Boolean']>
  imageColumnTwo_exists?: InputMaybe<Scalars['Boolean']>
  internalLabel?: InputMaybe<Scalars['String']>
  internalLabel_contains?: InputMaybe<Scalars['String']>
  internalLabel_exists?: InputMaybe<Scalars['Boolean']>
  internalLabel_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  internalLabel_not?: InputMaybe<Scalars['String']>
  internalLabel_not_contains?: InputMaybe<Scalars['String']>
  internalLabel_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  isCompact?: InputMaybe<Scalars['Boolean']>
  isCompact_exists?: InputMaybe<Scalars['Boolean']>
  isCompact_not?: InputMaybe<Scalars['Boolean']>
  sectionId?: InputMaybe<Scalars['String']>
  sectionId_contains?: InputMaybe<Scalars['String']>
  sectionId_exists?: InputMaybe<Scalars['Boolean']>
  sectionId_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  sectionId_not?: InputMaybe<Scalars['String']>
  sectionId_not_contains?: InputMaybe<Scalars['String']>
  sectionId_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  separatorMobileDisplay?: InputMaybe<Scalars['String']>
  separatorMobileDisplay_contains?: InputMaybe<Scalars['String']>
  separatorMobileDisplay_exists?: InputMaybe<Scalars['Boolean']>
  separatorMobileDisplay_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  separatorMobileDisplay_not?: InputMaybe<Scalars['String']>
  separatorMobileDisplay_not_contains?: InputMaybe<Scalars['String']>
  separatorMobileDisplay_not_in?: InputMaybe<
    Array<InputMaybe<Scalars['String']>>
  >
  separator_exists?: InputMaybe<Scalars['Boolean']>
  solidBackground?: InputMaybe<Scalars['Boolean']>
  solidBackground_exists?: InputMaybe<Scalars['Boolean']>
  solidBackground_not?: InputMaybe<Scalars['Boolean']>
  sys?: InputMaybe<SysFilter>
  teaserCopy_contains?: InputMaybe<Scalars['String']>
  teaserCopy_exists?: InputMaybe<Scalars['Boolean']>
  teaserCopy_not_contains?: InputMaybe<Scalars['String']>
  textColumnOne_contains?: InputMaybe<Scalars['String']>
  textColumnOne_exists?: InputMaybe<Scalars['Boolean']>
  textColumnOne_not_contains?: InputMaybe<Scalars['String']>
  textColumnThree_contains?: InputMaybe<Scalars['String']>
  textColumnThree_exists?: InputMaybe<Scalars['Boolean']>
  textColumnThree_not_contains?: InputMaybe<Scalars['String']>
  textColumnTwo_contains?: InputMaybe<Scalars['String']>
  textColumnTwo_exists?: InputMaybe<Scalars['Boolean']>
  textColumnTwo_not_contains?: InputMaybe<Scalars['String']>
  title?: InputMaybe<Scalars['String']>
  titleStyle?: InputMaybe<Scalars['String']>
  titleStyle_contains?: InputMaybe<Scalars['String']>
  titleStyle_exists?: InputMaybe<Scalars['Boolean']>
  titleStyle_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  titleStyle_not?: InputMaybe<Scalars['String']>
  titleStyle_not_contains?: InputMaybe<Scalars['String']>
  titleStyle_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  title_contains?: InputMaybe<Scalars['String']>
  title_exists?: InputMaybe<Scalars['Boolean']>
  title_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  title_not?: InputMaybe<Scalars['String']>
  title_not_contains?: InputMaybe<Scalars['String']>
  title_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  urlTarget?: InputMaybe<Scalars['String']>
  urlTarget_contains?: InputMaybe<Scalars['String']>
  urlTarget_exists?: InputMaybe<Scalars['Boolean']>
  urlTarget_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  urlTarget_not?: InputMaybe<Scalars['String']>
  urlTarget_not_contains?: InputMaybe<Scalars['String']>
  urlTarget_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
}

export type CfPagesMetaContentNestedFilter = {
  AND?: InputMaybe<Array<InputMaybe<CfPagesMetaContentNestedFilter>>>
  OR?: InputMaybe<Array<InputMaybe<CfPagesMetaContentNestedFilter>>>
  contentfulMetadata?: InputMaybe<ContentfulMetadataFilter>
  metaDescription?: InputMaybe<Scalars['String']>
  metaDescription_contains?: InputMaybe<Scalars['String']>
  metaDescription_exists?: InputMaybe<Scalars['Boolean']>
  metaDescription_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  metaDescription_not?: InputMaybe<Scalars['String']>
  metaDescription_not_contains?: InputMaybe<Scalars['String']>
  metaDescription_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  metaImage_exists?: InputMaybe<Scalars['Boolean']>
  metaTitle?: InputMaybe<Scalars['String']>
  metaTitle_contains?: InputMaybe<Scalars['String']>
  metaTitle_exists?: InputMaybe<Scalars['Boolean']>
  metaTitle_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  metaTitle_not?: InputMaybe<Scalars['String']>
  metaTitle_not_contains?: InputMaybe<Scalars['String']>
  metaTitle_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  sys?: InputMaybe<SysFilter>
  title?: InputMaybe<Scalars['String']>
  title_contains?: InputMaybe<Scalars['String']>
  title_exists?: InputMaybe<Scalars['Boolean']>
  title_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  title_not?: InputMaybe<Scalars['String']>
  title_not_contains?: InputMaybe<Scalars['String']>
  title_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
}

export type CfPagesPartnerBannerNestedFilter = {
  AND?: InputMaybe<Array<InputMaybe<CfPagesPartnerBannerNestedFilter>>>
  OR?: InputMaybe<Array<InputMaybe<CfPagesPartnerBannerNestedFilter>>>
  adminLabel?: InputMaybe<Scalars['String']>
  adminLabel_contains?: InputMaybe<Scalars['String']>
  adminLabel_exists?: InputMaybe<Scalars['Boolean']>
  adminLabel_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  adminLabel_not?: InputMaybe<Scalars['String']>
  adminLabel_not_contains?: InputMaybe<Scalars['String']>
  adminLabel_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  analyticsModule_exists?: InputMaybe<Scalars['Boolean']>
  contentfulMetadata?: InputMaybe<ContentfulMetadataFilter>
  logosCollection_exists?: InputMaybe<Scalars['Boolean']>
  showViewMoreButton?: InputMaybe<Scalars['Boolean']>
  showViewMoreButton_exists?: InputMaybe<Scalars['Boolean']>
  showViewMoreButton_not?: InputMaybe<Scalars['Boolean']>
  sys?: InputMaybe<SysFilter>
}

export type CfPagesPressBannerNestedFilter = {
  AND?: InputMaybe<Array<InputMaybe<CfPagesPressBannerNestedFilter>>>
  OR?: InputMaybe<Array<InputMaybe<CfPagesPressBannerNestedFilter>>>
  adminLabel?: InputMaybe<Scalars['String']>
  adminLabel_contains?: InputMaybe<Scalars['String']>
  adminLabel_exists?: InputMaybe<Scalars['Boolean']>
  adminLabel_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  adminLabel_not?: InputMaybe<Scalars['String']>
  adminLabel_not_contains?: InputMaybe<Scalars['String']>
  adminLabel_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  analyticsModule_exists?: InputMaybe<Scalars['Boolean']>
  applyBackground?: InputMaybe<Scalars['Boolean']>
  applyBackground_exists?: InputMaybe<Scalars['Boolean']>
  applyBackground_not?: InputMaybe<Scalars['Boolean']>
  contentfulMetadata?: InputMaybe<ContentfulMetadataFilter>
  logoSize?: InputMaybe<Scalars['Boolean']>
  logoSize_exists?: InputMaybe<Scalars['Boolean']>
  logoSize_not?: InputMaybe<Scalars['Boolean']>
  logosCollection_exists?: InputMaybe<Scalars['Boolean']>
  redirectSlug?: InputMaybe<Scalars['String']>
  redirectSlug_contains?: InputMaybe<Scalars['String']>
  redirectSlug_exists?: InputMaybe<Scalars['Boolean']>
  redirectSlug_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  redirectSlug_not?: InputMaybe<Scalars['String']>
  redirectSlug_not_contains?: InputMaybe<Scalars['String']>
  redirectSlug_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  sys?: InputMaybe<SysFilter>
  title?: InputMaybe<Scalars['String']>
  title_contains?: InputMaybe<Scalars['String']>
  title_exists?: InputMaybe<Scalars['Boolean']>
  title_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  title_not?: InputMaybe<Scalars['String']>
  title_not_contains?: InputMaybe<Scalars['String']>
  title_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
}

export type CfPagesTwoColumnCtaNestedFilter = {
  AND?: InputMaybe<Array<InputMaybe<CfPagesTwoColumnCtaNestedFilter>>>
  OR?: InputMaybe<Array<InputMaybe<CfPagesTwoColumnCtaNestedFilter>>>
  advisorDescription?: InputMaybe<Scalars['String']>
  advisorDescription_contains?: InputMaybe<Scalars['String']>
  advisorDescription_exists?: InputMaybe<Scalars['Boolean']>
  advisorDescription_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  advisorDescription_not?: InputMaybe<Scalars['String']>
  advisorDescription_not_contains?: InputMaybe<Scalars['String']>
  advisorDescription_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  advisor_exists?: InputMaybe<Scalars['Boolean']>
  analyticsModule_exists?: InputMaybe<Scalars['Boolean']>
  backgroundColor?: InputMaybe<Scalars['String']>
  backgroundColor_contains?: InputMaybe<Scalars['String']>
  backgroundColor_exists?: InputMaybe<Scalars['Boolean']>
  backgroundColor_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  backgroundColor_not?: InputMaybe<Scalars['String']>
  backgroundColor_not_contains?: InputMaybe<Scalars['String']>
  backgroundColor_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  buttonText?: InputMaybe<Scalars['String']>
  buttonText_contains?: InputMaybe<Scalars['String']>
  buttonText_exists?: InputMaybe<Scalars['Boolean']>
  buttonText_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  buttonText_not?: InputMaybe<Scalars['String']>
  buttonText_not_contains?: InputMaybe<Scalars['String']>
  buttonText_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  buttonTheme?: InputMaybe<Scalars['String']>
  buttonTheme_contains?: InputMaybe<Scalars['String']>
  buttonTheme_exists?: InputMaybe<Scalars['Boolean']>
  buttonTheme_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  buttonTheme_not?: InputMaybe<Scalars['String']>
  buttonTheme_not_contains?: InputMaybe<Scalars['String']>
  buttonTheme_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  buttonUrl?: InputMaybe<Scalars['String']>
  buttonUrl_contains?: InputMaybe<Scalars['String']>
  buttonUrl_exists?: InputMaybe<Scalars['Boolean']>
  buttonUrl_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  buttonUrl_not?: InputMaybe<Scalars['String']>
  buttonUrl_not_contains?: InputMaybe<Scalars['String']>
  buttonUrl_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  cardSize?: InputMaybe<Scalars['String']>
  cardSize_contains?: InputMaybe<Scalars['String']>
  cardSize_exists?: InputMaybe<Scalars['Boolean']>
  cardSize_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  cardSize_not?: InputMaybe<Scalars['String']>
  cardSize_not_contains?: InputMaybe<Scalars['String']>
  cardSize_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  contentColumn?: InputMaybe<Scalars['String']>
  contentColumn_contains?: InputMaybe<Scalars['String']>
  contentColumn_exists?: InputMaybe<Scalars['Boolean']>
  contentColumn_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  contentColumn_not?: InputMaybe<Scalars['String']>
  contentColumn_not_contains?: InputMaybe<Scalars['String']>
  contentColumn_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  contentfulMetadata?: InputMaybe<ContentfulMetadataFilter>
  formId?: InputMaybe<Scalars['String']>
  formId_contains?: InputMaybe<Scalars['String']>
  formId_exists?: InputMaybe<Scalars['Boolean']>
  formId_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  formId_not?: InputMaybe<Scalars['String']>
  formId_not_contains?: InputMaybe<Scalars['String']>
  formId_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  image_exists?: InputMaybe<Scalars['Boolean']>
  internalLabel?: InputMaybe<Scalars['String']>
  internalLabel_contains?: InputMaybe<Scalars['String']>
  internalLabel_exists?: InputMaybe<Scalars['Boolean']>
  internalLabel_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  internalLabel_not?: InputMaybe<Scalars['String']>
  internalLabel_not_contains?: InputMaybe<Scalars['String']>
  internalLabel_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  mobileAlignment?: InputMaybe<Scalars['String']>
  mobileAlignment_contains?: InputMaybe<Scalars['String']>
  mobileAlignment_exists?: InputMaybe<Scalars['Boolean']>
  mobileAlignment_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  mobileAlignment_not?: InputMaybe<Scalars['String']>
  mobileAlignment_not_contains?: InputMaybe<Scalars['String']>
  mobileAlignment_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  perksCollection_exists?: InputMaybe<Scalars['Boolean']>
  sectionId?: InputMaybe<Scalars['String']>
  sectionId_contains?: InputMaybe<Scalars['String']>
  sectionId_exists?: InputMaybe<Scalars['Boolean']>
  sectionId_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  sectionId_not?: InputMaybe<Scalars['String']>
  sectionId_not_contains?: InputMaybe<Scalars['String']>
  sectionId_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  solidBackground?: InputMaybe<Scalars['Boolean']>
  solidBackground_exists?: InputMaybe<Scalars['Boolean']>
  solidBackground_not?: InputMaybe<Scalars['Boolean']>
  sys?: InputMaybe<SysFilter>
  textBlockLeft_contains?: InputMaybe<Scalars['String']>
  textBlockLeft_exists?: InputMaybe<Scalars['Boolean']>
  textBlockLeft_not_contains?: InputMaybe<Scalars['String']>
  textUnderneathButton_contains?: InputMaybe<Scalars['String']>
  textUnderneathButton_exists?: InputMaybe<Scalars['Boolean']>
  textUnderneathButton_not_contains?: InputMaybe<Scalars['String']>
  title?: InputMaybe<Scalars['String']>
  titleIcon_exists?: InputMaybe<Scalars['Boolean']>
  titleStyle?: InputMaybe<Scalars['String']>
  titleStyle_contains?: InputMaybe<Scalars['String']>
  titleStyle_exists?: InputMaybe<Scalars['Boolean']>
  titleStyle_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  titleStyle_not?: InputMaybe<Scalars['String']>
  titleStyle_not_contains?: InputMaybe<Scalars['String']>
  titleStyle_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  title_contains?: InputMaybe<Scalars['String']>
  title_exists?: InputMaybe<Scalars['Boolean']>
  title_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  title_not?: InputMaybe<Scalars['String']>
  title_not_contains?: InputMaybe<Scalars['String']>
  title_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  urlTarget?: InputMaybe<Scalars['String']>
  urlTarget_contains?: InputMaybe<Scalars['String']>
  urlTarget_exists?: InputMaybe<Scalars['Boolean']>
  urlTarget_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  urlTarget_not?: InputMaybe<Scalars['String']>
  urlTarget_not_contains?: InputMaybe<Scalars['String']>
  urlTarget_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  yMargin?: InputMaybe<Scalars['Float']>
  yMargin_exists?: InputMaybe<Scalars['Boolean']>
  yMargin_gt?: InputMaybe<Scalars['Float']>
  yMargin_gte?: InputMaybe<Scalars['Float']>
  yMargin_in?: InputMaybe<Array<InputMaybe<Scalars['Float']>>>
  yMargin_lt?: InputMaybe<Scalars['Float']>
  yMargin_lte?: InputMaybe<Scalars['Float']>
  yMargin_not?: InputMaybe<Scalars['Float']>
  yMargin_not_in?: InputMaybe<Array<InputMaybe<Scalars['Float']>>>
}

export type CfSettingsAnalyticsModuleNestedFilter = {
  AND?: InputMaybe<Array<InputMaybe<CfSettingsAnalyticsModuleNestedFilter>>>
  OR?: InputMaybe<Array<InputMaybe<CfSettingsAnalyticsModuleNestedFilter>>>
  contentfulMetadata?: InputMaybe<ContentfulMetadataFilter>
  eventContext?: InputMaybe<Scalars['String']>
  eventContext_contains?: InputMaybe<Scalars['String']>
  eventContext_exists?: InputMaybe<Scalars['Boolean']>
  eventContext_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  eventContext_not?: InputMaybe<Scalars['String']>
  eventContext_not_contains?: InputMaybe<Scalars['String']>
  eventContext_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  eventTypes_contains_all?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  eventTypes_contains_none?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  eventTypes_contains_some?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  eventTypes_exists?: InputMaybe<Scalars['Boolean']>
  internalLabel?: InputMaybe<Scalars['String']>
  internalLabel_contains?: InputMaybe<Scalars['String']>
  internalLabel_exists?: InputMaybe<Scalars['Boolean']>
  internalLabel_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  internalLabel_not?: InputMaybe<Scalars['String']>
  internalLabel_not_contains?: InputMaybe<Scalars['String']>
  internalLabel_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  sys?: InputMaybe<SysFilter>
}

export type CfTeamNestedFilter = {
  AND?: InputMaybe<Array<InputMaybe<CfTeamNestedFilter>>>
  OR?: InputMaybe<Array<InputMaybe<CfTeamNestedFilter>>>
  contentfulMetadata?: InputMaybe<ContentfulMetadataFilter>
  email?: InputMaybe<Scalars['String']>
  email_contains?: InputMaybe<Scalars['String']>
  email_exists?: InputMaybe<Scalars['Boolean']>
  email_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  email_not?: InputMaybe<Scalars['String']>
  email_not_contains?: InputMaybe<Scalars['String']>
  email_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  image_exists?: InputMaybe<Scalars['Boolean']>
  name?: InputMaybe<Scalars['String']>
  name_contains?: InputMaybe<Scalars['String']>
  name_exists?: InputMaybe<Scalars['Boolean']>
  name_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  name_not?: InputMaybe<Scalars['String']>
  name_not_contains?: InputMaybe<Scalars['String']>
  name_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  sys?: InputMaybe<SysFilter>
  title?: InputMaybe<Scalars['String']>
  title_contains?: InputMaybe<Scalars['String']>
  title_exists?: InputMaybe<Scalars['Boolean']>
  title_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  title_not?: InputMaybe<Scalars['String']>
  title_not_contains?: InputMaybe<Scalars['String']>
  title_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
}

export type CfTemplateFaqsNestedFilter = {
  AND?: InputMaybe<Array<InputMaybe<CfTemplateFaqsNestedFilter>>>
  OR?: InputMaybe<Array<InputMaybe<CfTemplateFaqsNestedFilter>>>
  analyticsModule_exists?: InputMaybe<Scalars['Boolean']>
  contentfulMetadata?: InputMaybe<ContentfulMetadataFilter>
  metaContent_exists?: InputMaybe<Scalars['Boolean']>
  questionGroupsCollection_exists?: InputMaybe<Scalars['Boolean']>
  sys?: InputMaybe<SysFilter>
  title?: InputMaybe<Scalars['String']>
  title_contains?: InputMaybe<Scalars['String']>
  title_exists?: InputMaybe<Scalars['Boolean']>
  title_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  title_not?: InputMaybe<Scalars['String']>
  title_not_contains?: InputMaybe<Scalars['String']>
  title_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
}

export type CfTravelByCategoriesNestedFilter = {
  AND?: InputMaybe<Array<InputMaybe<CfTravelByCategoriesNestedFilter>>>
  OR?: InputMaybe<Array<InputMaybe<CfTravelByCategoriesNestedFilter>>>
  contentfulMetadata?: InputMaybe<ContentfulMetadataFilter>
  featuredImage_exists?: InputMaybe<Scalars['Boolean']>
  mobileFeaturedImage_exists?: InputMaybe<Scalars['Boolean']>
  slug?: InputMaybe<Scalars['String']>
  slug_contains?: InputMaybe<Scalars['String']>
  slug_exists?: InputMaybe<Scalars['Boolean']>
  slug_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  slug_not?: InputMaybe<Scalars['String']>
  slug_not_contains?: InputMaybe<Scalars['String']>
  slug_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  subcategoriesCollection_exists?: InputMaybe<Scalars['Boolean']>
  sys?: InputMaybe<SysFilter>
  title?: InputMaybe<Scalars['String']>
  title_contains?: InputMaybe<Scalars['String']>
  title_exists?: InputMaybe<Scalars['Boolean']>
  title_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  title_not?: InputMaybe<Scalars['String']>
  title_not_contains?: InputMaybe<Scalars['String']>
  title_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
}

export type CfTravelBySubcategoriesNestedFilter = {
  AND?: InputMaybe<Array<InputMaybe<CfTravelBySubcategoriesNestedFilter>>>
  OR?: InputMaybe<Array<InputMaybe<CfTravelBySubcategoriesNestedFilter>>>
  contentfulMetadata?: InputMaybe<ContentfulMetadataFilter>
  featuredImage_exists?: InputMaybe<Scalars['Boolean']>
  generateLandingPage?: InputMaybe<Scalars['Boolean']>
  generateLandingPage_exists?: InputMaybe<Scalars['Boolean']>
  generateLandingPage_not?: InputMaybe<Scalars['Boolean']>
  metaContent_exists?: InputMaybe<Scalars['Boolean']>
  questionsCollection_exists?: InputMaybe<Scalars['Boolean']>
  showInGuidesCategoryList?: InputMaybe<Scalars['Boolean']>
  showInGuidesCategoryList_exists?: InputMaybe<Scalars['Boolean']>
  showInGuidesCategoryList_not?: InputMaybe<Scalars['Boolean']>
  showInItinerariesCategoryList?: InputMaybe<Scalars['Boolean']>
  showInItinerariesCategoryList_exists?: InputMaybe<Scalars['Boolean']>
  showInItinerariesCategoryList_not?: InputMaybe<Scalars['Boolean']>
  showInStoriesCategoryList?: InputMaybe<Scalars['Boolean']>
  showInStoriesCategoryList_exists?: InputMaybe<Scalars['Boolean']>
  showInStoriesCategoryList_not?: InputMaybe<Scalars['Boolean']>
  slug?: InputMaybe<Scalars['String']>
  slug_contains?: InputMaybe<Scalars['String']>
  slug_exists?: InputMaybe<Scalars['Boolean']>
  slug_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  slug_not?: InputMaybe<Scalars['String']>
  slug_not_contains?: InputMaybe<Scalars['String']>
  slug_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  sys?: InputMaybe<SysFilter>
  teaserCopy_contains?: InputMaybe<Scalars['String']>
  teaserCopy_exists?: InputMaybe<Scalars['Boolean']>
  teaserCopy_not_contains?: InputMaybe<Scalars['String']>
  title?: InputMaybe<Scalars['String']>
  title_contains?: InputMaybe<Scalars['String']>
  title_exists?: InputMaybe<Scalars['Boolean']>
  title_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  title_not?: InputMaybe<Scalars['String']>
  title_not_contains?: InputMaybe<Scalars['String']>
  title_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
}

export type CfcategoriesMultiTypeNestedFilter = {
  AND?: InputMaybe<Array<InputMaybe<CfcategoriesMultiTypeNestedFilter>>>
  OR?: InputMaybe<Array<InputMaybe<CfcategoriesMultiTypeNestedFilter>>>
  contentfulMetadata?: InputMaybe<ContentfulMetadataFilter>
  featuredImage_exists?: InputMaybe<Scalars['Boolean']>
  slug?: InputMaybe<Scalars['String']>
  slug_contains?: InputMaybe<Scalars['String']>
  slug_exists?: InputMaybe<Scalars['Boolean']>
  slug_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  slug_not?: InputMaybe<Scalars['String']>
  slug_not_contains?: InputMaybe<Scalars['String']>
  slug_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  sys?: InputMaybe<SysFilter>
  title?: InputMaybe<Scalars['String']>
  title_contains?: InputMaybe<Scalars['String']>
  title_exists?: InputMaybe<Scalars['Boolean']>
  title_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  title_not?: InputMaybe<Scalars['String']>
  title_not_contains?: InputMaybe<Scalars['String']>
  title_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
}

export type CfdestinationMultiTypeNestedFilter = {
  AND?: InputMaybe<Array<InputMaybe<CfdestinationMultiTypeNestedFilter>>>
  OR?: InputMaybe<Array<InputMaybe<CfdestinationMultiTypeNestedFilter>>>
  cloudinaryFeaturedImage_exists?: InputMaybe<Scalars['Boolean']>
  cloudinaryMobileFeaturedImage_exists?: InputMaybe<Scalars['Boolean']>
  contentfulMetadata?: InputMaybe<ContentfulMetadataFilter>
  featuredHotelsCollection_exists?: InputMaybe<Scalars['Boolean']>
  featuredImage_exists?: InputMaybe<Scalars['Boolean']>
  generateTopHotelsPage?: InputMaybe<Scalars['Boolean']>
  generateTopHotelsPage_exists?: InputMaybe<Scalars['Boolean']>
  generateTopHotelsPage_not?: InputMaybe<Scalars['Boolean']>
  grammarDefiniteArticle?: InputMaybe<Scalars['Boolean']>
  grammarDefiniteArticle_exists?: InputMaybe<Scalars['Boolean']>
  grammarDefiniteArticle_not?: InputMaybe<Scalars['Boolean']>
  metaContent_exists?: InputMaybe<Scalars['Boolean']>
  mobileFeaturedImage_exists?: InputMaybe<Scalars['Boolean']>
  questionsCollection_exists?: InputMaybe<Scalars['Boolean']>
  rankedHotels_exists?: InputMaybe<Scalars['Boolean']>
  slug?: InputMaybe<Scalars['String']>
  slug_contains?: InputMaybe<Scalars['String']>
  slug_exists?: InputMaybe<Scalars['Boolean']>
  slug_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  slug_not?: InputMaybe<Scalars['String']>
  slug_not_contains?: InputMaybe<Scalars['String']>
  slug_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  sys?: InputMaybe<SysFilter>
  teaserCopy_contains?: InputMaybe<Scalars['String']>
  teaserCopy_exists?: InputMaybe<Scalars['Boolean']>
  teaserCopy_not_contains?: InputMaybe<Scalars['String']>
  title?: InputMaybe<Scalars['String']>
  title_contains?: InputMaybe<Scalars['String']>
  title_exists?: InputMaybe<Scalars['Boolean']>
  title_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  title_not?: InputMaybe<Scalars['String']>
  title_not_contains?: InputMaybe<Scalars['String']>
  title_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
}

export type CfitemsMultiTypeNestedFilter = {
  AND?: InputMaybe<Array<InputMaybe<CfitemsMultiTypeNestedFilter>>>
  OR?: InputMaybe<Array<InputMaybe<CfitemsMultiTypeNestedFilter>>>
  analyticsModule_exists?: InputMaybe<Scalars['Boolean']>
  contentfulMetadata?: InputMaybe<ContentfulMetadataFilter>
  internalLabel?: InputMaybe<Scalars['String']>
  internalLabel_contains?: InputMaybe<Scalars['String']>
  internalLabel_exists?: InputMaybe<Scalars['Boolean']>
  internalLabel_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  internalLabel_not?: InputMaybe<Scalars['String']>
  internalLabel_not_contains?: InputMaybe<Scalars['String']>
  internalLabel_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  sys?: InputMaybe<SysFilter>
}

export type CfpageBlocksMultiTypeNestedFilter = {
  AND?: InputMaybe<Array<InputMaybe<CfpageBlocksMultiTypeNestedFilter>>>
  OR?: InputMaybe<Array<InputMaybe<CfpageBlocksMultiTypeNestedFilter>>>
  contentfulMetadata?: InputMaybe<ContentfulMetadataFilter>
  sys?: InputMaybe<SysFilter>
}

export type CfpreferredGuidesMultiTypeNestedFilter = {
  AND?: InputMaybe<Array<InputMaybe<CfpreferredGuidesMultiTypeNestedFilter>>>
  OR?: InputMaybe<Array<InputMaybe<CfpreferredGuidesMultiTypeNestedFilter>>>
  advisor_exists?: InputMaybe<Scalars['Boolean']>
  atAGlanceTags_contains_all?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  atAGlanceTags_contains_none?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  atAGlanceTags_contains_some?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  atAGlanceTags_exists?: InputMaybe<Scalars['Boolean']>
  cloudinaryImage_exists?: InputMaybe<Scalars['Boolean']>
  contentfulMetadata?: InputMaybe<ContentfulMetadataFilter>
  curatorsStatement_contains?: InputMaybe<Scalars['String']>
  curatorsStatement_exists?: InputMaybe<Scalars['Boolean']>
  curatorsStatement_not_contains?: InputMaybe<Scalars['String']>
  hotelsCollection_exists?: InputMaybe<Scalars['Boolean']>
  image_exists?: InputMaybe<Scalars['Boolean']>
  locationForTitles?: InputMaybe<Scalars['String']>
  locationForTitles_contains?: InputMaybe<Scalars['String']>
  locationForTitles_exists?: InputMaybe<Scalars['Boolean']>
  locationForTitles_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  locationForTitles_not?: InputMaybe<Scalars['String']>
  locationForTitles_not_contains?: InputMaybe<Scalars['String']>
  locationForTitles_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  metaDescription?: InputMaybe<Scalars['String']>
  metaDescription_contains?: InputMaybe<Scalars['String']>
  metaDescription_exists?: InputMaybe<Scalars['Boolean']>
  metaDescription_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  metaDescription_not?: InputMaybe<Scalars['String']>
  metaDescription_not_contains?: InputMaybe<Scalars['String']>
  metaDescription_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  metaTitle?: InputMaybe<Scalars['String']>
  metaTitle_contains?: InputMaybe<Scalars['String']>
  metaTitle_exists?: InputMaybe<Scalars['Boolean']>
  metaTitle_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  metaTitle_not?: InputMaybe<Scalars['String']>
  metaTitle_not_contains?: InputMaybe<Scalars['String']>
  metaTitle_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  needToKnow_contains?: InputMaybe<Scalars['String']>
  needToKnow_exists?: InputMaybe<Scalars['Boolean']>
  needToKnow_not_contains?: InputMaybe<Scalars['String']>
  seoSlug?: InputMaybe<Scalars['String']>
  seoSlug_contains?: InputMaybe<Scalars['String']>
  seoSlug_exists?: InputMaybe<Scalars['Boolean']>
  seoSlug_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  seoSlug_not?: InputMaybe<Scalars['String']>
  seoSlug_not_contains?: InputMaybe<Scalars['String']>
  seoSlug_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  showOnHomepage?: InputMaybe<Scalars['Boolean']>
  showOnHomepage_exists?: InputMaybe<Scalars['Boolean']>
  showOnHomepage_not?: InputMaybe<Scalars['Boolean']>
  showOnOtherLandingPages?: InputMaybe<Scalars['Boolean']>
  showOnOtherLandingPages_exists?: InputMaybe<Scalars['Boolean']>
  showOnOtherLandingPages_not?: InputMaybe<Scalars['Boolean']>
  slug?: InputMaybe<Scalars['String']>
  slugID?: InputMaybe<Scalars['String']>
  slugID_contains?: InputMaybe<Scalars['String']>
  slugID_exists?: InputMaybe<Scalars['Boolean']>
  slugID_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  slugID_not?: InputMaybe<Scalars['String']>
  slugID_not_contains?: InputMaybe<Scalars['String']>
  slugID_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  slug_contains?: InputMaybe<Scalars['String']>
  slug_exists?: InputMaybe<Scalars['Boolean']>
  slug_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  slug_not?: InputMaybe<Scalars['String']>
  slug_not_contains?: InputMaybe<Scalars['String']>
  slug_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  sys?: InputMaybe<SysFilter>
  title?: InputMaybe<Scalars['String']>
  title_contains?: InputMaybe<Scalars['String']>
  title_exists?: InputMaybe<Scalars['Boolean']>
  title_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  title_not?: InputMaybe<Scalars['String']>
  title_not_contains?: InputMaybe<Scalars['String']>
  title_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
}

export type CfrelatedCategoriesMultiTypeNestedFilter = {
  AND?: InputMaybe<Array<InputMaybe<CfrelatedCategoriesMultiTypeNestedFilter>>>
  OR?: InputMaybe<Array<InputMaybe<CfrelatedCategoriesMultiTypeNestedFilter>>>
  contentfulMetadata?: InputMaybe<ContentfulMetadataFilter>
  featuredImage_exists?: InputMaybe<Scalars['Boolean']>
  generateLandingPage?: InputMaybe<Scalars['Boolean']>
  generateLandingPage_exists?: InputMaybe<Scalars['Boolean']>
  generateLandingPage_not?: InputMaybe<Scalars['Boolean']>
  metaContent_exists?: InputMaybe<Scalars['Boolean']>
  questionsCollection_exists?: InputMaybe<Scalars['Boolean']>
  slug?: InputMaybe<Scalars['String']>
  slug_contains?: InputMaybe<Scalars['String']>
  slug_exists?: InputMaybe<Scalars['Boolean']>
  slug_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  slug_not?: InputMaybe<Scalars['String']>
  slug_not_contains?: InputMaybe<Scalars['String']>
  slug_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  sys?: InputMaybe<SysFilter>
  teaserCopy_contains?: InputMaybe<Scalars['String']>
  teaserCopy_exists?: InputMaybe<Scalars['Boolean']>
  teaserCopy_not_contains?: InputMaybe<Scalars['String']>
  title?: InputMaybe<Scalars['String']>
  title_contains?: InputMaybe<Scalars['String']>
  title_exists?: InputMaybe<Scalars['Boolean']>
  title_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  title_not?: InputMaybe<Scalars['String']>
  title_not_contains?: InputMaybe<Scalars['String']>
  title_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
}
