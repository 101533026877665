import React, { useEffect, useCallback } from 'react'
import Image from 'next/legacy/image'
import { imageLoader } from '@/utils/ImageLoaders'
import iconClose from '/public/images/icon-close.svg'

type ModalProps = {
  children: React.ReactNode
  visible: boolean
  onClose: () => void
  customClasses?: string
}

export function Modal({
  children,
  onClose,
  visible,
  customClasses,
}: ModalProps): JSX.Element {
  const escFunction = useCallback(
    (event) => {
      if (event.defaultPrevented) {
        return
      }
      if (event.key === 'Escape' && visible) {
        onClose()
      }
    },
    [onClose, visible]
  )

  useEffect(() => {
    document.addEventListener('keydown', escFunction, false)
    return () => {
      document.removeEventListener('keydown', escFunction, false)
    }
  }, [escFunction])

  useEffect(() => {
    const body = document.querySelector('body')
    if (body) {
      visible
        ? (body.style.overflow = 'hidden')
        : (body.style.overflow = 'unset')
    }
  }, [visible])

  return (
    <div
      className={`lg:my-0 fixed top-0 bottom-0 left-0 z-50 flex justify-center w-full items-center bg-white bg-opacity-80 overflow-scroll ${
        visible ? 'block' : 'hidden'
      }`}
      data-name="modal"
    >
      <div
        tabIndex={0}
        role="button"
        className="absolute top-0 left-0 w-full h-full"
        onClick={() => onClose()}
        onKeyDown={() => onClose()}
      />
      <div
        className={`absolute top-0 w-full mx-4 md:top-unset md:relative md:w-auto ${customClasses}`}
      >
        {children}

        <div
          tabIndex={0}
          role="button"
          className="absolute w-4 cursor-pointer top-5 right-8 lg:top-8 lg:right-8"
          onClick={() => onClose()}
          onKeyDown={() => onClose()}
        >
          <Image
            loader={({ src }) =>
              imageLoader({
                src: src,
                width: 24,
                quality: 90,
              })
            }
            src={iconClose}
            alt="Icon Close"
            width={24}
            height={24}
            layout="responsive"
            sizes="10vw"
          />
        </div>
      </div>
    </div>
  )
}
