import { Asset } from 'types/generated/contentful-types'
import Image, { ImageProps } from 'next/legacy/image'
import { CloudinaryAsset, isCloudinaryImage } from '../cloudinary/Types'
import { ReqImage, isReqImage, isTypedArrayOneOrMore } from 'types/util'
import {
  ContentfulImageLoaderProps,
  ImageSizer,
  compatibleCloudinaryImageLoader,
  contentfulImageLoader,
} from '@/utils/ImageLoaders'

type OmitSrcImageProps = CloudinaryPrioritySources &
  Omit<ImageProps, 'src'> & { fullWidth?: boolean }
interface DynamicallySourcedImageProps extends OmitSrcImageProps {
  srcBuilder: ISourceBuilder
  contentfulImage: Omit<Asset, 'sys' | 'contentfulMetadata'> | null
}

export const DynamicallySourcedImage = (
  props: DynamicallySourcedImageProps
) => {
  const {
    srcBuilder,
    alt,
    cloudinaryImage,
    contentfulImage,
    ...compatibleProps
  } = props

  if (srcBuilder.hasValidSource()) {
    return (
      <Image
        {...props}
        src={srcBuilder.getSource()}
        alt={alt || ''}
        data-src={srcBuilder.getOriginalUrl()}
      />
    )
  } else if (props.contentfulImage?.url) {
    return (
      <Image
        {...props}
        src={props.contentfulImage.url}
        alt={props.alt || ''}
        data-src={props.contentfulImage.url}
      />
    )
  } else {
    return <></>
  }
}

interface CloudinaryPrioritySources {
  contentfulImage: null | Omit<Asset, 'contentfulMetadata' | 'sys'>
  cloudinaryImage: null | CloudinaryAsset | Asset | CloudinaryAsset[]
}

interface ISourceBuilder {
  getSource: () => string
  getOriginalUrl: () => string
  hasValidSource: () => boolean
}

type CloudinaryPriorityImageProps = OmitSrcImageProps &
  CloudinaryPrioritySources &
  Omit<ContentfulImageLoaderProps, 'contentfulMetadata' | 'src'>

class CloudinaryPrioritySourceBuilder {
  private props: CloudinaryPriorityImageProps
  private contentfulImage: undefined | ReqImage
  private cloudinaryImage: undefined | CloudinaryAsset
  public sizer: ImageSizer
  constructor(props: CloudinaryPriorityImageProps) {
    this.props = props
    if (
      isTypedArrayOneOrMore<CloudinaryAsset>(
        props.cloudinaryImage,
        isCloudinaryImage
      )
    ) {
      this.cloudinaryImage = props.cloudinaryImage[0]
      this.sizer = new ImageSizer({
        asset: props.cloudinaryImage[0],
        fixedWidth: props.width,
        fixedHeight: props.height,
        fullWidth: props.fullWidth,
      })
    } else if (isReqImage(props.contentfulImage)) {
      this.contentfulImage = props.contentfulImage
      this.sizer = new ImageSizer({
        asset: props.contentfulImage,
        fixedWidth: props.width,
        fixedHeight: props.height,
        fullWidth: props.fullWidth,
      })
    } else {
      this.sizer = new ImageSizer({
        asset: { width: 0, height: 0 },
        fixedWidth: props.width,
        fixedHeight: props.height,
        fullWidth: props.fullWidth,
      })
    }
  }

  getOriginalUrl() {
    return this.cloudinaryImage?.url || ''
  }

  getSource() {
    if (this.cloudinaryImage) {
      return compatibleCloudinaryImageLoader({
        public_id: this.cloudinaryImage.public_id,
        width: this.sizer.width,
        height: this.sizer.height,
        fit: this.props.fit,
        format: this.props.format,
        quality: this.props.quality,
      })
    } else if (this.contentfulImage) {
      return contentfulImageLoader({
        src: this.contentfulImage.url,
        width: this.sizer.width,
        height: this.sizer.height,
        fit: this.props.fit,
        format: this.props.format || 'webp',
        quality: this.props.quality || 75,
        focus: this.props.focus,
      })
    }
    return ''
  }

  hasValidSource() {
    return Boolean(this.cloudinaryImage || this.contentfulImage)
  }
}

export const CloudinaryPriorityImage = (
  props: CloudinaryPriorityImageProps
) => {
  const srcBuilder = new CloudinaryPrioritySourceBuilder(props)
  // If layout is fill, we don't want to set width and height
  // If a width or height is set by the parent component, we want to use that
  // Otherwise, we want to use the sizer which will maintain aspect ratios
  // while fitting the image to the main content column width
  const width =
    props.layout === 'fill'
      ? undefined
      : props.width
      ? props.width
      : srcBuilder.sizer.width
  const height =
    props.layout === 'fill'
      ? undefined
      : props.height
      ? props.height
      : srcBuilder.sizer.height
  return (
    <DynamicallySourcedImage
      {...props}
      srcBuilder={srcBuilder}
      width={width}
      height={height}
    />
  )
}
