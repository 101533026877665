import {
  Asset,
  ComponentImage as ComponentImageTypes,
} from 'types/generated/contentful-types'
import { ArrayOneOrMore } from 'types/util'
import { CloudinaryPriorityImage } from '../ui/DynamicallySourcedImage'
import { CloudinaryAsset } from '../cloudinary/Types'

export enum ImagePositionForMobile {
  DEFAULT = 'top',
  ALTERNATE = 'bottom',
}

export type ComponentImageProps = ComponentImageTypes & {
  file?: null | Asset
  cloudinaryImage?: ArrayOneOrMore<CloudinaryAsset> | null
  width?: number
  height?: number
  key?: string
}

export default function ComponentImage({
  file,
  cloudinaryImage,
  altText,
  imageCredit,
  width,
  height,
  key,
}: ComponentImageProps): JSX.Element {
  return (
    <div key={key} className="mb-8">
      <CloudinaryPriorityImage
        contentfulImage={file || null}
        cloudinaryImage={cloudinaryImage || null}
        fit="fill"
        alt={altText || ''}
        width={width}
        height={height}
      />
      {imageCredit && (
        <div className="lg:my-5">
          <p className="text-center fora-text-caption-1 !text-stone">
            {imageCredit}
          </p>
        </div>
      )}
    </div>
  )
}
