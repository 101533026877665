import Image from 'next/legacy/image'
import useSWR, { preload } from 'swr'
import React, { useEffect, useRef, useState } from 'react'
import {
  EntryTestimonialOrder,
  EntryTestimonial,
} from 'types/generated/contentful-types.d'
import { CustomCache, ReviewsSWRKey, generateReviewsSWRKey } from '@/utils/SWR'
import { TestimonialAggregations, compact } from '@/utils/Helpers'
import { imageLoader } from '@/utils/ImageLoaders'
import { useSubNavBarContext } from 'context/SubNavBarContext'
import { useStickyHeaderContext } from 'context/StickyHeaderContext'
import { useReviewsContext } from 'context/ReviewsContext'
import { Button } from '../ui/Buttons'
import { Stars } from '../testimonial/Stars'
import { ReviewCard } from '../testimonial/ReviewCard'
import {
  AllFetcher,
  DestinationFetcher,
  IFetcher,
  REVIEWS_LIMIT,
  formatAverage,
} from './ReviewsData'
import IconDropdown from '/public/images/dropdown.svg'
import { ButtonLink } from '../ui/ButtonLinks'

export const defaultOrder = EntryTestimonialOrder.DateSubmittedDesc

export interface TestimonialsData {
  total: number
  items: EntryTestimonial[]
}

export type DisplayTestimonial = EntryTestimonial & {
  display: true
}

export const RatingCountsFragment = `
  fiveStar: entryTestimonialCollection(where: { stars: 5 }) {
    total
  }
  fourStar: entryTestimonialCollection(where: { stars: 4 }) {
    total
  }
  threeStar: entryTestimonialCollection(where: { stars: 3 }) {
    total
  }
  twoStar: entryTestimonialCollection(where: { stars: 2 }) {
    total
  }
  oneStar: entryTestimonialCollection(where: { stars: 1 }) {
    total
  }
`

/**
 * Controls for sorting and filtering reviews displayed.
 * - For faster update, determine if the controls are visible using CSS variables
 * - On mobile, the controls switch between `sticky` and `relative` at the transition point.
 */

interface ControlsProps {
  testimonialAggregations?: TestimonialAggregations
  starsIn: number[]
  order: EntryTestimonialOrder
  refetch: (newStarsIn?: number[], newOrder?: EntryTestimonialOrder) => void
}

enum ControlsCssVars {
  OPEN_BTN = '--reviews-controls-open-btn',
  CLOSE_BTN = '--reviews-controls-close-btn',
  OPEN_BDR = '--reviews-controls-open-border',
  CLOSE_BDR = '--reviews-controls-close-border',
  TITLE = '--reviews-controls-title',
  TITLE_ANIM = '--reviews-controls-title-animation',
  FORM_ANIM = '--reviews-controls-form-animation',
  FORM_CLICK = '--reviews-controls-form-click',
  FORM_BG = '--reviews-controls-form-background',
}

// Location in pixels below the sentinel where mobile transitions from `relative` to `sticky`
const TRANSITION_POINT = 200

const Controls = ({
  testimonialAggregations,
  starsIn,
  order,
  refetch,
}: ControlsProps): JSX.Element => {
  const ref = useRef<HTMLElement>(null)
  const sentinelRef = useRef<HTMLDivElement>(null)
  const expandedRef = useRef<HTMLDivElement>(null)
  const { subNavHeight } = useSubNavBarContext()
  const { stickyHeaderHeight } = useStickyHeaderContext()
  const stickyOffset = stickyHeaderHeight + subNavHeight

  // Review information
  const averageRating = testimonialAggregations?.averageRating ?? 0
  const counts = testimonialAggregations?.counts ?? [0, 0, 0, 0, 0]
  const totalCount = testimonialAggregations?.totalCount ?? 0

  // Control settings
  const scrollToTop = () =>
    sentinelRef.current?.scrollIntoView({ behavior: 'smooth' })
  const isSelectedStar = (star: number) => starsIn.includes(star)
  const toggleStar = (star: number) => {
    if (isSelectedStar(star)) refetch(starsIn.filter((s) => s !== star))
    else refetch([...starsIn, star])
    scrollToTop()
  }
  const isSelectedOrder = (o: EntryTestimonialOrder) => order === o
  const selectOrder = (o: EntryTestimonialOrder) => {
    if (!isSelectedOrder(o)) refetch(undefined, o)
    scrollToTop()
  }

  // Sticky behavior
  const handleStickyChange = (entry: IntersectionObserverEntry) => {
    if (!ref.current) return
    const isSticking = !entry.isIntersecting
    if (isSticking) {
      ref.current.style.setProperty(ControlsCssVars.TITLE_ANIM, '')
      ref.current.style.setProperty(ControlsCssVars.FORM_ANIM, '')
      void ref.current.offsetWidth // End browser batch changes
      ref.current.style.position = 'sticky'
      ref.current.style.setProperty(ControlsCssVars.OPEN_BTN, '100%')
      ref.current.style.setProperty(ControlsCssVars.CLOSE_BTN, '100%')
      ref.current.style.setProperty(ControlsCssVars.OPEN_BDR, 'transparent')
      ref.current.style.setProperty(ControlsCssVars.CLOSE_BDR, '#E5DBD0') // bg-darkShell
      ref.current.style.setProperty(ControlsCssVars.TITLE, 'start')
      ref.current.style.setProperty(ControlsCssVars.TITLE_ANIM, 'fadeIn 300ms forwards') // prettier-ignore
      ref.current.style.setProperty(ControlsCssVars.FORM_CLICK, 'none')
      ref.current.style.setProperty(ControlsCssVars.FORM_ANIM, 'fadeOut 0ms forwards') // prettier-ignore
      ref.current.style.setProperty(ControlsCssVars.FORM_BG, 'transparent')
    } else {
      ref.current.style.setProperty(ControlsCssVars.TITLE_ANIM, '')
      ref.current.style.setProperty(ControlsCssVars.FORM_ANIM, '')
      void ref.current.offsetWidth // End browser batch changes
      ref.current.style.position = 'relative'
      ref.current.style.setProperty(ControlsCssVars.OPEN_BTN, '0%')
      ref.current.style.setProperty(ControlsCssVars.CLOSE_BTN, '0%')
      ref.current.style.setProperty(ControlsCssVars.OPEN_BDR, 'transparent')
      ref.current.style.setProperty(ControlsCssVars.CLOSE_BDR, 'transparent')
      ref.current.style.setProperty(ControlsCssVars.TITLE, 'center')
      ref.current.style.setProperty(ControlsCssVars.TITLE_ANIM, 'fadeIn 300ms forwards') // prettier-ignore
      ref.current.style.setProperty(ControlsCssVars.FORM_CLICK, 'auto')
      ref.current.style.setProperty(ControlsCssVars.FORM_ANIM, 'fadeIn 300ms forwards') // prettier-ignore
      ref.current.style.setProperty(ControlsCssVars.FORM_BG, 'transparent')
    }
  }

  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach(handleStickyChange)
      },
      {
        rootMargin: `${TRANSITION_POINT}px 0px 0px 0px`,
        threshold: [0],
      }
    )

    if (sentinelRef.current) observer.observe(sentinelRef.current)
    const refToCleanUp = sentinelRef.current
    return () => {
      if (refToCleanUp) observer.unobserve(refToCleanUp)
    }
  }, [])

  const toggleMenu = (shouldOpen: boolean) => {
    if (!ref.current) return
    if (shouldOpen) {
      ref.current.style.setProperty(ControlsCssVars.TITLE_ANIM, '')
      ref.current.style.setProperty(ControlsCssVars.FORM_ANIM, '')
      void ref.current.offsetWidth // End browser batch changes
      ref.current.style.setProperty(ControlsCssVars.OPEN_BTN, '0%')
      ref.current.style.setProperty(ControlsCssVars.CLOSE_BTN, '100%')
      ref.current.style.setProperty(ControlsCssVars.OPEN_BDR, '#E5DBD0') // bg-darkShell
      ref.current.style.setProperty(ControlsCssVars.CLOSE_BDR, 'transparent')
      ref.current.style.setProperty(ControlsCssVars.TITLE, 'center')
      ref.current.style.setProperty(ControlsCssVars.TITLE_ANIM, 'fadeOutIn70 300ms') // prettier-ignore
      ref.current.style.setProperty(ControlsCssVars.FORM_CLICK, 'auto')
      ref.current.style.setProperty(ControlsCssVars.FORM_ANIM, 'fadeIn 300ms forwards') // prettier-ignore
      ref.current.style.setProperty(ControlsCssVars.FORM_BG, '#FEFAF5') // bg-sand
    } else {
      ref.current.style.setProperty(ControlsCssVars.TITLE_ANIM, '')
      ref.current.style.setProperty(ControlsCssVars.FORM_ANIM, '')
      void ref.current.offsetWidth // End browser batch changes
      ref.current.style.setProperty(ControlsCssVars.OPEN_BTN, '100%')
      ref.current.style.setProperty(ControlsCssVars.CLOSE_BTN, '0%')
      ref.current.style.setProperty(ControlsCssVars.OPEN_BDR, 'transparent')
      ref.current.style.setProperty(ControlsCssVars.CLOSE_BDR, '#E5DBD0') // bg-darkShell
      ref.current.style.setProperty(ControlsCssVars.TITLE, 'start')
      ref.current.style.setProperty(ControlsCssVars.TITLE_ANIM, 'fadeOutIn70 300ms') // prettier-ignore
      ref.current.style.setProperty(ControlsCssVars.FORM_CLICK, 'none')
      ref.current.style.setProperty(ControlsCssVars.FORM_ANIM, 'fadeOut 0ms forwards') // prettier-ignore
      ref.current.style.setProperty(ControlsCssVars.FORM_BG, 'transparent')
    }
  }

  return (
    <div className="contents lg:block lg:relative">
      <div
        ref={sentinelRef}
        className="h-px"
        aria-hidden
        style={{ scrollMarginTop: '32px' }}
      />

      <aside
        ref={ref}
        className="grid place-content-center sticky lg:!sticky pointer-events-none z-10"
      >
        <div
          className="border-b bg-sand px-4 lg:px-0 lg:border-b-0 w-screen lg:!w-auto"
          style={{ borderColor: `var(${ControlsCssVars.CLOSE_BDR})` }}
        >
          <div
            className="relative inset-x-0 block py-4 lg:pt-0 w-full max-w-[368px] lg:w-[300px] xl:w-[368px] mx-auto lg:!opacity-100"
            style={{ animation: `var(${ControlsCssVars.TITLE_ANIM})` }}
          >
            <div
              className="flex items-center justify-center w-full max-w-full gap-x-2 whitespace-nowrap lg:!justify-start"
              style={{ justifyContent: `var(${ControlsCssVars.TITLE})` }}
              data-name="controls-title"
            >
              <Stars rating={averageRating} aria-hidden showEmptyStars />
              <p
                aria-label="Average rating"
                className="relative top-[1px] fora-text-h5 md:fora-text-h7 tracking-btight"
              >
                {formatAverage(averageRating)}
              </p>
            </div>
            <button
              aria-label="Open sort and filter menu"
              className="absolute inset-0 flex items-center justify-end pointer-events-auto gap-x-2 lg:hidden"
              style={{ opacity: `var(${ControlsCssVars.OPEN_BTN})` }}
              onClick={() => toggleMenu(true)}
              data-name="open-button"
            >
              <span className="font-medium fora-text-button-2">
                Sort & Filter
              </span>
              <Image
                loader={({ src }) =>
                  imageLoader({
                    src: src,
                    width: 12,
                    quality: 90,
                  })
                }
                src={IconDropdown}
                alt="Down arrow"
                width={12}
                height={12}
              />
            </button>
          </div>
        </div>

        <div
          ref={expandedRef}
          className="w-screen relative top-[-6px] -mt-2 px-4 lg:px-0 lg:!w-auto lg:!opacity-100 lg:!pointer-events-auto border-b"
          style={{
            animation: `var(${ControlsCssVars.FORM_ANIM})`,
            pointerEvents: `var(${ControlsCssVars.FORM_CLICK})` as 'auto',
            borderColor: `var(${ControlsCssVars.OPEN_BDR})`,
            backgroundColor: `var(${ControlsCssVars.FORM_BG})`,
          }}
          data-name="controls-expanded"
        >
          <div className="w-full max-w-[368px] lg:w-[300px] xl:w-[368px] mx-auto">
            <p className="text-center fora-text-caption-1 tracking-btight lg:text-left">
              Based on {totalCount} reviews
            </p>

            <form className="flex flex-row-reverse justify-between mt-8 lg:flex-col lg:gap-y-8">
              <fieldset className="flex flex-col gap-y-2">
                <div>
                  <legend className="fora-text-caption-1 text-darkStone">
                    Filter by
                  </legend>
                </div>

                {Array.from({ length: 5 }).map((_, index) => {
                  const star = 5 - index // Reverse order
                  const total = counts[star - 1]
                  return (
                    <div key={star} className="flex items-center">
                      <input
                        type="checkbox"
                        id={`filter-by-${star}`}
                        checked={isSelectedStar(star)}
                        onChange={() => toggleStar(star)}
                      />
                      <label htmlFor={`filter-by-${star}`} className="contents">
                        <span className="sr-only">
                          Filter by {star} star ratings.
                        </span>
                        <span className="ml-3">
                          <Stars rating={star} aria-hidden showEmptyStars />
                        </span>
                        <span
                          className="ml-2 uppercase fora-text-caption-1 text-darkStone"
                          aria-label={`Total ${star} star reviews`}
                        >
                          ({total})
                        </span>
                      </label>
                    </div>
                  )
                })}
              </fieldset>
              <fieldset className="flex flex-col gap-y-2">
                <div>
                  <legend className="fora-text-caption-1 text-darkStone">
                    Sort by
                  </legend>
                </div>
                <div className="flex items-center gap-x-2">
                  <input
                    type="radio"
                    id="sort-by-newest"
                    name="sort-by"
                    value="newest"
                    checked={isSelectedOrder(
                      EntryTestimonialOrder.DateSubmittedDesc
                    )}
                    onChange={() =>
                      selectOrder(EntryTestimonialOrder.DateSubmittedDesc)
                    }
                  />
                  <label
                    htmlFor="sort-by-newest"
                    className="fora-text-caption-1 text-darkStone tracking-btight"
                  >
                    Newest
                  </label>
                </div>
                <div className="flex items-center gap-x-2">
                  <input
                    type="radio"
                    id="sort-by-oldest"
                    name="sort-by"
                    value="oldest"
                    checked={isSelectedOrder(
                      EntryTestimonialOrder.DateSubmittedAsc
                    )}
                    onChange={() =>
                      selectOrder(EntryTestimonialOrder.DateSubmittedAsc)
                    }
                  />
                  <label
                    htmlFor="sort-by-oldest"
                    className="fora-text-caption-1 text-darkStone tracking-btight"
                  >
                    Oldest
                  </label>
                </div>
              </fieldset>
            </form>

            <div
              className="py-6 ml-auto opacity-0 w-max lg:hidden"
              style={{ opacity: `var(${ControlsCssVars.CLOSE_BTN})` }}
              data-name="close-button"
            >
              <Button
                text="Done"
                theme="secondary"
                isDisabled={false}
                buttonFn={() => toggleMenu(false)}
              />
            </div>
          </div>
        </div>
      </aside>

      <style jsx>{`
        aside {
          top: 0px;
        }

        @media screen and (min-width: 1024px) {
          aside {
            /* If SubNavBar exists, adjust top by the SubNavBar's height + extra padding */
            top: ${(stickyOffset ? stickyOffset : 0) + 16}px;
          }
        }
      `}</style>
    </div>
  )
}

const ComponentReviewsTemplate = ({
  sectionId,
  testimonialAggregations,
  slug,
  slugType,
  ctaText,
  ctaUrl,
}: ComponentReviewsProps) => {
  const ref = useRef<HTMLElement>(null)
  const listRef = useRef<HTMLUListElement>(null)
  const [reviews, setReviews] = useState<EntryTestimonial[]>([])
  const [starsIn, setStarsIn] = useState<number[]>([])
  const [order, setOrder] = useState<EntryTestimonialOrder>(
    EntryTestimonialOrder.DateSubmittedDesc
  )

  const [key, setKey] = useState<ReviewsSWRKey>(
    generateReviewsSWRKey(0, REVIEWS_LIMIT, order, starsIn, slug)
  )

  let fetcher: IFetcher
  if (slugType === 'category' || slugType === 'subcategory') {
    fetcher = new DestinationFetcher(slug, slugType)
  } else {
    fetcher = new AllFetcher()
  }

  // Fetch reviews
  const { data, isLoading } = useSWR<TestimonialsData>(key, fetcher.fetch, {
    revalidateIfStale: false,
    revalidateOnFocus: false,
    revalidateOnReconnect: false,
  })
  useEffect(() => {
    const nonNullItems = compact(data?.items)
    setReviews((prev) => [...prev, ...nonNullItems])
  }, [data])

  const total = data?.total || 0
  const hasMore = reviews.length < total
  const loadMore = () => {
    if (!hasMore) return
    const newKey = generateReviewsSWRKey(reviews.length, REVIEWS_LIMIT, order, starsIn, slug) // prettier-ignore
    setKey(newKey)
  }
  const refetch = (newStarsIn?: number[], newOrder?: EntryTestimonialOrder) => {
    setReviews([])
    if (newStarsIn) setStarsIn(newStarsIn)
    if (newOrder) setOrder(newOrder)
    const newKey = generateReviewsSWRKey(0, REVIEWS_LIMIT, newOrder ?? order, newStarsIn ?? starsIn, slug) // prettier-ignore
    setKey(newKey)
  }

  // Smooth height change when number of reviews displayed decreases
  useEffect(() => {
    const handleResize = () => {
      if (!listRef.current || !ref.current) return
      if (listRef.current.offsetHeight >= ref.current.offsetHeight)
        return (ref.current.style.minHeight = `${listRef.current.offsetHeight}px`)
      setTimeout(() => {
        if (!listRef.current || !ref.current) return
        ref.current.style.minHeight = `${listRef.current.offsetHeight}px`
      }, 1000)
    }

    const observer = new ResizeObserver((entries) =>
      entries.forEach(handleResize)
    )
    const listEl = listRef.current
    if (listEl) observer.observe(listEl)
    return () => {
      if (listEl) observer.unobserve(listEl)
    }
  }, [])

  return (
    <section
      ref={ref}
      id={sectionId ?? undefined}
      className="relative transition duration-300 lg:flex lg:gap-x-12"
      style={{ transitionProperty: 'min-height', minHeight: '0px' }}
    >
      <Controls
        testimonialAggregations={testimonialAggregations}
        starsIn={starsIn}
        order={order}
        refetch={refetch}
      />
      <ul ref={listRef} className="-mt-5 h-[max-content] lg:mt-0">
        {!isLoading && reviews.length === 0 ? (
          <li className="fora-text-h6 tracking-btight">
            No advisor reviews here. Try checking the other star ratings.
          </li>
        ) : (
          reviews.map((review, index) => {
            const isLast = index === reviews.length - 1
            return (
              <React.Fragment key={index}>
                <li key={'review' + index} className="contents">
                  <ReviewCard {...review} parent="ComponentReviews" />
                </li>
                {(!isLast || hasMore) && (
                  <li
                    aria-hidden
                    key={'spacer' + index}
                    className={`border-t-0 sm:border-t-[1px] sm:border-darkShell sm:my-8 ${
                      isLast ? 'my-2' : 'my-5'
                    }`}
                  />
                )}
              </React.Fragment>
            )
          })
        )}
        {isLoading && (
          <div className="flex gap-4 my-4 align-center">
            <div
              className="inline-block h-6 w-6 animate-spin rounded-full border-4 border-solid border-darkShell border-r-transparent align-[-0.125em] motion-reduce:animate-[spin_1.5s_linear_infinite]"
              role="status"
            >
              <span className="!absolute !-m-px !h-px !w-px !overflow-hidden !whitespace-nowrap !border-0 !p-0 ![clip:rect(0,0,0,0)]" />
            </div>
            <span className="uppercase text-darkShell">Loading</span>
          </div>
        )}
        {reviews.length > 0 && hasMore && (
          <li data-name="load-more-button" className="text-center lg:text-left">
            {ctaUrl ? (
              <ButtonLink
                target="_self"
                href={ctaUrl}
                text={ctaText || 'Load more'}
                theme="secondary"
              />
            ) : (
              <Button
                text={ctaText || 'Load more'}
                theme="secondary"
                buttonFn={loadMore}
                isDisabled={!hasMore}
              />
            )}
          </li>
        )}
      </ul>
    </section>
  )
}

export type ComponentReviewsProps = {
  sectionId?: string
  testimonialAggregations?: TestimonialAggregations
  customCache?: CustomCache
  slug: string
  slugType: string
  ctaText?: string
  ctaUrl?: string
}

function ComponentReviews(props: ComponentReviewsProps): JSX.Element {
  const {
    customCache,
    sectionId = 'reviews-section',
    testimonialAggregations,
    slug = '_pageBlock',
    slugType = '_pageBlock',
  } = props
  const { averageRating, totalCount } = testimonialAggregations ?? {}
  const {
    sectionId: contextSectionId,
    setSectionId,
    averageRating: contextAverageRating,
    setAverageRating,
    totalCount: contextTotalCount,
    setTotalCount,
  } = useReviewsContext()
  const [cached, setCached] = useState<boolean>(false)

  useEffect(() => {
    if (!customCache) return
    for (const cache of customCache) preload(cache.key, () => cache.data)
    setCached(true)
  }, [customCache])

  useEffect(() => {
    if (sectionId && sectionId !== contextSectionId) setSectionId(sectionId)
  }, [sectionId, contextSectionId, setSectionId])

  useEffect(() => {
    if (averageRating && averageRating !== contextAverageRating)
      setAverageRating(averageRating)
  }, [averageRating, contextAverageRating, setAverageRating])

  useEffect(() => {
    if (totalCount && totalCount !== contextTotalCount)
      setTotalCount(totalCount)
  }, [totalCount, contextTotalCount, setTotalCount])

  if (customCache && !cached) return <></>
  else
    return (
      <ComponentReviewsTemplate {...props} slug={slug} slugType={slugType} />
    )
}

export default ComponentReviews
