import {
  ComponentSubscriptionCard,
  Maybe,
} from 'types/generated/contentful-types'
import IconSparkle from '../../public/images/icon-sparkle.svg'
import Image from 'next/legacy/image'
import { imageLoader } from '@/utils/ImageLoaders'
import { ButtonLink } from '../ui/ButtonLinks'
import { ResponsiveSpacer } from '../spacing/Spacer'
import { useState } from 'react'
import { Modal } from '../common/Modal'
import SubscriptionForm from '../forms/SubscriptionForm'

export const isString = (item: Maybe<string>): item is string => item !== null

export const renderBulletPoint = (bulletPoint: string, index: number) => (
  <li key={index}>{bulletPoint}</li>
)

type PricingModelsProps = Omit<
  ComponentSubscriptionCard,
  'sys' | 'contentfulMetadata' | '_id'
>

const PricingModels = ({
  ctaText,
  ctaUrl,
  urlTarget,
  bulletPoints,
  annualIsLimited,
  annualOriginalPrice,
  annualDiscountPrice,
  annualPriceDetails,
  annualFeaturedText,
  monthlyIsLimited,
  monthlyOriginalPrice,
  monthlyDiscountPrice,
  monthlyPriceDetails,
  monthlyFeaturedText,
  showMultiplePricingModels,
}: PricingModelsProps) => {
  const annualProductHasDiscount =
    annualDiscountPrice && annualDiscountPrice > 0
  const monthProductHasDiscount =
    monthlyDiscountPrice && monthlyDiscountPrice > 0
  const getPeriodData = (period: 'annual' | 'monthly') => {
    const annual = period === 'annual'
    return {
      period,
      hasDiscountPrice: annual
        ? annualProductHasDiscount
        : monthProductHasDiscount,
      isLimited: annual ? annualIsLimited : monthlyIsLimited,
      originalPrice: annual ? annualOriginalPrice : monthlyOriginalPrice,
      discountPrice: annual ? annualDiscountPrice : monthlyDiscountPrice,
      priceDetails: annual ? annualPriceDetails : monthlyPriceDetails,
      featuredText: annual ? annualFeaturedText : monthlyFeaturedText,
    }
  }

  const [periodData, setPeriodData] = useState(getPeriodData('annual'))
  const setAnnual = () => setPeriodData(getPeriodData('annual'))
  const setMonthly = () => setPeriodData(getPeriodData('monthly'))

  const [showingSubmitFormModal, toggleShowingSubmitFormModal] =
    useState<boolean>(false)

  return (
    <>
      <div
        data-name="subscription-card"
        className="flex flex-col items-center self-center justify-center px-8 py-8 md:px-9 md:py-9 bg-white border shadow-sm border-shell rounded-2xl lg:w-[398px] w-full whitespace-nowrap relative overflow-hidden"
      >
        {showMultiplePricingModels && (
          <>
            <div className="top-ribbon" />
            <div
              className={`flex justify-between w-full toggle-wrapper mb-8 ${
                periodData.period === 'annual' ? 'active-annual' : ''
              }`}
            >
              <button
                onClick={() => setAnnual()}
                className={`w-1/2 z-10 fora-text-eyebrow-1 uppercase font-semibold text-olive ${
                  periodData.period === 'annual' ? 'active' : ''
                }`}
                data-name="annual-btn"
              >
                Annual
              </button>
              <button
                onClick={() => setMonthly()}
                className={`w-1/2 z-10 fora-text-eyebrow-1 font-semibold text-olive uppercase ${
                  !(periodData.period === 'annual') ? 'active' : ''
                }`}
                data-name="monthly-btn"
              >
                Monthly
              </button>
              <div className="switch" />
            </div>
          </>
        )}

        <div className="min-h-auto">
          {periodData.isLimited && (
            <span
              className="mx-auto text-center fora-text-body-2 text-stone"
              data-name="limited-blurb"
            >
              Limited time offer
            </span>
          )}
          <div className="flex justify-center pb-2" data-name="plan-name">
            {!showMultiplePricingModels && (
              <Image
                loader={({ src }) =>
                  imageLoader({
                    src: src,
                    width: 24,
                    quality: 90,
                  })
                }
                src={IconSparkle}
                width={24}
                height={24}
                alt="Icon"
              />
            )}
          </div>
          <div
            className={`flex justify-center items-center mb-2 ${
              periodData.hasDiscountPrice ? 'row-reverse' : ''
            }`}
            data-name="plan-prices"
          >
            <div
              className={`${
                periodData.hasDiscountPrice
                  ? 'text-red-80 fora-text-h7 text-xl'
                  : 'fora-text-h1 text-olive'
              }`}
            >
              {periodData.hasDiscountPrice ? (
                <s>${periodData.originalPrice}</s>
              ) : (
                <>${periodData.originalPrice}</>
              )}
            </div>
            {periodData.hasDiscountPrice && (
              <div className="pl-4 text-olive fora-text-h3 text-[54px]">
                ${periodData.discountPrice}
              </div>
            )}
            <div
              className={`pl-4 fora-text-h9 uppercase text-olive ${
                periodData.hasDiscountPrice ? 'text-olive' : ''
              } `}
            >
              {periodData.period === 'annual' ? 'per year' : 'per month'}
            </div>
          </div>
          {periodData.priceDetails && (
            <div
              className="mb-2 text-center md:fora-text-body-italic-3 fora-text-body-italic-1 text-stone"
              data-name="plan-details"
            >
              {periodData.priceDetails}
            </div>
          )}
          {periodData.featuredText && (
            <ul className="flex justify-center checked-list green-check">
              <li className="text-olive fora-text-h9 before:!-top-1">
                {periodData.featuredText}
              </li>
            </ul>
          )}
        </div>
        {bulletPoints && bulletPoints.length > 0 && (
          <ul className="checked-list fora-text-body-1 md:fora-text-body-3 darkstone-check w-full pt-5 mt-3 border-t-[1px] border-shell">
            {bulletPoints.filter(isString).map(renderBulletPoint)}
          </ul>
        )}
        {ctaText && (
          <>
            <ResponsiveSpacer size="XS" />
            <div
              className="flex justify-center w-full"
              data-name="subscribe-btn"
            >
              <ButtonLink
                target={
                  (urlTarget as '_self' | '_blank' | undefined) || '_self'
                }
                href={ctaUrl || ''}
                text={ctaText}
                theme="primary"
                isFull
                isModal={!ctaUrl}
                onClose={() =>
                  toggleShowingSubmitFormModal(!showingSubmitFormModal)
                }
              />
            </div>
          </>
        )}
      </div>
      <Modal
        onClose={() => toggleShowingSubmitFormModal(!showingSubmitFormModal)}
        visible={showingSubmitFormModal}
      >
        <div className="m-auto text-center px-9 md:px-26 py-14 bg-shell max-w-[700px]">
          <SubscriptionForm
            conversionComponentName="Pricing Models"
            title="Take the first steps to become a Fora Advisor"
            teaserCopy="Enter your email to get started. Next, you'll take a survey
              to share more about yourself."
          />
        </div>
      </Modal>
    </>
  )
}

export default PricingModels
