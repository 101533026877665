import Image from 'next/legacy/image'
import { contentfulImageLoader } from '@/utils/ImageLoaders'
import { ComponentImageSideBySide } from 'types/generated/contentful-types'
import CarouselSlider from '../ui/CarouselSlider'

export enum ImagePositionForMobile {
  DEFAULT = 'top',
  ALTERNATE = 'bottom',
}

export type ImageSideBySideProps = Omit<ComponentImageSideBySide, '_id'> & {
  imagePositionForMobile?: string
  loadWithPriority?: boolean
}

export default function ImageSideBySide({
  imageOne,
  imageTwo,
  caption,
  imagePositionForMobile = 'top',
  loadWithPriority = false,
}: ImageSideBySideProps): JSX.Element {
  return (
    <section className="mb-8">
      <div className="lg:hidden">
        <CarouselSlider
          maxItems={1}
          arrowStyle="Default"
          dotsStyle="Dots"
          showNext={true}
        >
          <div
            className={`${
              imagePositionForMobile == ImagePositionForMobile.DEFAULT
                ? 'pb-8'
                : ''
            } lg:order-2 lg:pb-0`}
          >
            <Image
              loader={() =>
                contentfulImageLoader({
                  src: imageOne?.url ?? '',
                  width: 1200,
                  height: 1500,
                  quality: 90,
                  fit: 'fill',
                  format: 'jpg',
                })
              }
              src={imageOne?.url ?? ''}
              alt={imageOne?.description || ''}
              layout="responsive"
              width={1200}
              height={1500}
              priority={loadWithPriority}
              sizes="90vw"
            />
          </div>
          <div
            className={`${
              imagePositionForMobile == ImagePositionForMobile.DEFAULT
                ? 'pb-8'
                : ''
            } lg:order-2 lg:pb-0`}
          >
            <Image
              loader={() =>
                contentfulImageLoader({
                  src: imageTwo?.url ?? '',
                  width: 870,
                  height: 1100,
                  quality: 90,
                  fit: 'fill',
                  format: 'jpg',
                })
              }
              src={imageTwo?.url ?? ''}
              alt={imageTwo?.description || ''}
              layout="responsive"
              width={870}
              height={1100}
              priority={loadWithPriority}
              sizes="90vw"
            />
          </div>
        </CarouselSlider>
      </div>
      <div className="hidden mb-4 lg:grid lg:grid-cols-2 gap-x-7">
        <div
          className={`${
            imagePositionForMobile == ImagePositionForMobile.DEFAULT
              ? 'pb-8'
              : ''
          } lg:order-2 lg:pb-0`}
        >
          <Image
            loader={() =>
              contentfulImageLoader({
                src: imageOne?.url ?? '',
                width: 870,
                height: 1100,
                quality: 90,
                fit: 'fill',
                format: 'webp',
              })
            }
            src={imageOne?.url ?? ''}
            alt={imageOne?.description || ''}
            layout="responsive"
            width={870}
            height={1100}
            priority={loadWithPriority}
            sizes="90vw"
          />
        </div>
        <div
          className={`${
            imagePositionForMobile == ImagePositionForMobile.DEFAULT
              ? 'pb-8'
              : ''
          } lg:order-2 lg:pb-0`}
        >
          <Image
            loader={() =>
              contentfulImageLoader({
                src: imageTwo?.url ?? '',
                width: 870,
                height: 1100,
                quality: 90,
                fit: 'fill',
                format: 'webp',
              })
            }
            src={imageTwo?.url ?? ''}
            alt={imageTwo?.description || ''}
            layout="responsive"
            width={870}
            height={1100}
            priority={loadWithPriority}
            sizes="90vw"
          />
        </div>
      </div>
      {caption && (
        <div>
          <p className="text-center fora-text-caption-1 !text-stone">
            {caption}
          </p>
        </div>
      )}
    </section>
  )
}
