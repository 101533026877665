import Link from 'next/link'
import { Logo } from '@/components/common/Logo'
import IconFacebook from '/public/images/icon-facebook.svg'
import IconInstagram from '/public/images/icon-instagram.svg'
import IconTikTok from '/public/images/icon-tiktok.svg'
import IconPinterest from '/public/images/icon-pinterest.svg'
import IconLinkedin from '/public/images/icon-linkedin.svg'
import IconYoutube from '/public/images/icon-youtube.svg'
import IconTwitter from '/public/images/icon-twitter.svg'
import IconSpotify from '/public/images/icon-spotify.svg'
import IconRedirect from '/public/images/icon-redirect.svg'
import Image from 'next/image'
import { imageLoader } from '@/utils/ImageLoaders'
import { SharedAdvisorContactCTAProps } from '../advisors/AdvisorCTAs'
import { ButtonLink } from '../ui/ButtonLinks'

interface FooterProps {
  collapseMargin?: boolean
  advisor?: SharedAdvisorContactCTAProps | undefined
}

export function Footer({
  collapseMargin = false,
  advisor,
}: FooterProps): JSX.Element {
  const siteMapLinks = {
    col1: [
      {
        name: 'Become an Advisor',
        url: '/join-us',
        wrapperStyles: 'mb-5',
      },
      {
        name: 'Travel Advisor Resources',
        url: '/the-journal/travel-advisor-resources',
        wrapperStyles: 'mb-5',
      },
      {
        name: 'Travel Advisor FAQ',
        url: '/faq#faq-advisors',
        wrapperStyles: 'mb-5',
      },
      {
        name: 'Community Stories',
        url: '/the-journal/community-stories',
        wrapperStyles: 'mb-5',
      },
      {
        blank: true,
        icon: IconRedirect,
        name: 'Advisor Portal',
        url: 'https://advisor.fora.travel/',
        wrapperStyles: 'mb-5',
      },
    ],
    col2: [
      {
        name: 'Why Book with Fora',
        url: '/why-book-with-fora',
        wrapperStyles: 'mb-5',
      },
      {
        name: 'Travel Destinations',
        url: '/travel/destinations',
        wrapperStyles: 'mb-5',
      },
      {
        name: 'Travel Guides & Trip Reports',
        url: '/travel-guides-itineraries-and-trip-reports',
        wrapperStyles: 'mb-5',
      },
      {
        name: 'Travel Categories',
        url: '/travel/style',
        wrapperStyles: 'mb-5',
      },
      {
        name: 'All travel Advisors',
        url: '/advisors',
        wrapperStyles: 'mb-5',
      },
      {
        name: 'Fora X',
        url: '/fora-x',
        wrapperStyles: 'mb-5',
      },
      {
        name: 'Partners',
        url: advisor?.slug ? `/partners/${advisor.slug}` : '/partners',
        wrapperStyles: 'mb-5',
      },
      {
        name: 'Reviews',
        url: '/traveler-reviews',
        wrapperStyles: '',
      },
    ],
    col3: [
      { name: 'About us', url: '/about-us', wrapperStyles: 'mb-5' },
      {
        name: 'THE JOURNAL',
        url: '/the-journal',
        wrapperStyles: 'mb-5',
      },
      {
        name: 'Events',
        url: '/events',
        wrapperStyles: 'mb-5',
      },
      {
        name: 'Press',
        url: '/press',
        wrapperStyles: 'mb-5',
      },
      {
        name: 'Careers',
        url: '/careers',
        wrapperStyles: 'mb-5',
      },
      {
        name: 'Contact Us',
        url: '/contact-us',
        wrapperStyles: 'mb-5',
      },
      {
        name: 'Partner with us',
        url: '/partner-with-us',
        wrapperStyles: 'mb-5',
      },
      {
        name: 'FAQ',
        url: '/faq',
        wrapperStyles: 'mb-5',
      },
    ],
  }
  return (
    <footer role="contentinfo">
      <div
        className={`grid grid-cols-2 py-10 border-t md:grid-cols-8 grid-rows-auto lg:grid-cols-12 lg:py-14 gap-y-10 gap-x-0 lg:gap-y-0 auto-cols-fr border-darkShell ${
          collapseMargin ? 'mt-0' : 'mt-8 md:mt-12'
        }`}
      >
        <div className="col-span-1 row-span-1 md:col-span-2 md:row-start-1 md:row-end-2 md:col-start-1 md:col-end-2">
          <Logo isStamp />
        </div>
        <div className="col-span-1 row-span-1 md:col-start-3 md:col-end-6 lg:col-start-3 lg:col-end-5">
          <h5 className="mb-6 uppercase md:fora-text-navigation-1 fora-text-navigation-4">
            For Travel Advisors
          </h5>
          <ul className="mr-5">
            {siteMapLinks.col1.map((link, i) => (
              <li key={i} className={link.wrapperStyles}>
                <Link
                  href={link.url}
                  className="block uppercase md:fora-text-navigation-1 fora-text-navigation-4 text-stone"
                  target={link.blank ? '_blank' : '_self'}
                >
                  {link.name}
                  {link.icon && (
                    <div className="inline-block ml-1">
                      <Image
                        loader={({ src }) =>
                          imageLoader({
                            src: src,
                            width: 12,
                            quality: 90,
                          })
                        }
                        src={IconRedirect}
                        alt="Find Fora Travel on Twitter"
                        width={12}
                        height={12}
                      />
                    </div>
                  )}
                </Link>
              </li>
            ))}
          </ul>
        </div>
        <div className="col-span-1 row-span-1 md:col-start-3 md:col-end-6 lg:col-start-5 lg:col-end-7">
          <h5 className="mb-6 uppercase md:fora-text-navigation-1 fora-text-navigation-4">
            For Travelers
          </h5>
          <ul className="mr-5 ">
            {siteMapLinks.col2.map((link, i) => (
              <li key={i} className={link.wrapperStyles}>
                <Link
                  href={link.url}
                  className="block uppercase md:fora-text-navigation-1 fora-text-navigation-4 text-stone"
                  target="_self"
                >
                  {link.name}
                </Link>
              </li>
            ))}
          </ul>
        </div>
        <div className="col-span-1 row-span-1 md:col-start-6 md:col-end-9 md:row-start-1 md:row-end-2 lg:col-start-7">
          <h5 className="mb-6 uppercase md:fora-text-navigation-1 fora-text-navigation-4">
            Our Company
          </h5>
          <ul>
            {siteMapLinks.col3.map((link, i) => (
              <li key={i} className={link.wrapperStyles}>
                <Link
                  href={link.url}
                  className="block uppercase md:fora-text-navigation-1 fora-text-navigation-4 text-stone"
                  target="_self"
                >
                  {link.name}
                </Link>
              </li>
            ))}
          </ul>
        </div>
        <div className="col-span-2 row-span-1 md:col-start-9 md:col-end-13 md:row-start-1 md:row-end-2">
          <div className="mb-4 lg:mb-0">
            <h5 className="mb-6 uppercase md:fora-text-navigation-1 fora-text-navigation-4">
              Newsletter
            </h5>
            <p className="mb-6 md:fora-text-body-3 fora-text-body-2">
              Stay in-the-know with travel recs, hotel features & travel advisor
              news.
            </p>
            <div>
              <ButtonLink
                href="/newsletter"
                target="_self"
                text="Subscribe to our newsletter"
                theme="secondary"
              />
            </div>
          </div>

          <div className="mt-10 lg:mt-12">
            <h5 className="mb-5 uppercase md:fora-text-navigation-1 fora-text-navigation-4">
              Follow Us
            </h5>
            <ul className="flex">
              <li className="inline-flex mr-4">
                <a
                  href="https://www.facebook.com/hellofora"
                  className="flex"
                  target="_blank"
                  rel="noreferrer"
                >
                  <Image
                    className="align-bottom transition duration-500 transform"
                    loader={({ src }) =>
                      imageLoader({
                        src: src,
                        width: 24,
                        quality: 90,
                      })
                    }
                    src={IconFacebook}
                    alt="Find Fora Travel on Facebook"
                    width={24}
                    height={24}
                  />
                </a>
              </li>
              <li className="inline-flex mr-4">
                <a
                  href="https://www.instagram.com/hellofora/"
                  className="flex"
                  target="_blank"
                  rel="noreferrer"
                >
                  <Image
                    className="align-bottom transition duration-500 transform"
                    loader={({ src }) =>
                      imageLoader({
                        src: src,
                        width: 24,
                        quality: 90,
                      })
                    }
                    src={IconInstagram}
                    alt="Find Fora Travel on Instagram"
                    width={24}
                    height={24}
                  />
                </a>
              </li>
              <li className="inline-flex mr-4">
                <a
                  href="https://www.tiktok.com/@foratravel?_t=8WmqaZ9PsGE&_r=1"
                  className="flex"
                  target="_blank"
                  rel="noreferrer"
                >
                  <Image
                    className="align-bottom transition duration-500 transform"
                    loader={({ src }) =>
                      imageLoader({
                        src: src,
                        width: 24,
                        quality: 90,
                      })
                    }
                    src={IconTikTok}
                    alt="Find Fora Travel on TikTok"
                    width={24}
                    height={24}
                  />
                </a>
              </li>
              <li className="inline-flex mr-4">
                <a
                  href="https://www.pinterest.com/hellofora/"
                  className="flex"
                  target="_blank"
                  rel="noreferrer"
                >
                  <Image
                    className="align-bottom transition duration-500 transform"
                    loader={({ src }) =>
                      imageLoader({
                        src: src,
                        width: 24,
                        quality: 90,
                      })
                    }
                    src={IconPinterest}
                    alt="Find Fora Travel on Pinterest"
                    width={24}
                    height={24}
                  />
                </a>
              </li>
              <li className="inline-flex mr-4">
                <a
                  href="https://twitter.com/hellofora"
                  className="flex"
                  target="_blank"
                  rel="noreferrer"
                >
                  <Image
                    className="align-bottom transition duration-500 transform"
                    loader={({ src }) =>
                      imageLoader({
                        src: src,
                        width: 24,
                        quality: 90,
                      })
                    }
                    src={IconTwitter}
                    alt="Find Fora Travel on Twitter"
                    width={24}
                    height={24}
                  />
                </a>
              </li>
              <li className="inline-flex mr-4">
                <a
                  href="https://www.youtube.com/channel/UCQF53htkb8iImem1EFHbQsw"
                  className="flex"
                  target="_blank"
                  rel="noreferrer"
                >
                  <Image
                    className="align-bottom transition duration-500 transform"
                    loader={({ src }) =>
                      imageLoader({
                        src: src,
                        width: 24,
                        quality: 90,
                      })
                    }
                    src={IconYoutube}
                    alt="Find Fora Travel on Youtube"
                    width={24}
                    height={24}
                  />
                </a>
              </li>
              <li className="inline-flex mr-4">
                <a
                  href="https://open.spotify.com/show/5NN6XdqCpAwLTJuyrvUxsD?si=d47dcf4c1d404dae "
                  className="flex"
                  target="_blank"
                  rel="noreferrer"
                >
                  <Image
                    className="align-bottom transition duration-500 transform"
                    loader={({ src }) =>
                      imageLoader({
                        src: src,
                        width: 24,
                        quality: 90,
                      })
                    }
                    src={IconSpotify}
                    alt="Find Fora Travel on Spotify"
                    width={24}
                    height={24}
                  />
                </a>
              </li>
              <li className="inline-flex">
                <a
                  href="https://www.linkedin.com/company/foratravel/"
                  className="flex"
                  target="_blank"
                  rel="noreferrer"
                >
                  <Image
                    className="align-bottom transition duration-500 transform"
                    loader={({ src }) =>
                      imageLoader({
                        src: src,
                        width: 24,
                        quality: 90,
                      })
                    }
                    src={IconLinkedin}
                    alt="Find Fora Travel on LinkedIn"
                    width={24}
                    height={24}
                  />
                </a>
              </li>
            </ul>
          </div>
        </div>
      </div>
      <div className="pt-6 pb-6 border-t md:pt-8 lg:flex border-darkShell">
        <div className="flex-1">
          <p className="mb-6 fora-text-body-3 md:text-base text-14">
            &copy; {new Date().getFullYear()} Fora Travel, Inc. All Rights
            Reserved
          </p>
          <p className="mb-4 italic fora-text-body-3">
            Fora Travel, Inc. is registered with the following state licenses:
            <br />
            California - Seller of Travel Registration No. 2151995-50
            <br />
            Florida - Seller of Travel Registration No. ST43973
            <br />
            Washington - Seller of Travel Unified Business ID (UBI) # 605329242
          </p>
        </div>
        <nav id="nav-legal" role="navigation">
          <div>
            <Link
              href="/legal/privacy-policy"
              className="uppercase md:fora-text-navigation-1 fora-text-navigation-4 text-darkStone"
              target="_self"
            >
              Privacy Policy
            </Link>
            <span className="mx-2">&nbsp;&bull;&nbsp;</span>
            <Link
              href="/legal/terms-of-use"
              className="uppercase md:fora-text-navigation-1 fora-text-navigation-4 text-darkStone "
              target="_self"
            >
              Terms of use
            </Link>
            <span className="mx-2">&nbsp;&bull;&nbsp;</span>
            <Link
              href="/site-overview"
              className="uppercase md:fora-text-navigation-1 fora-text-navigation-4 text-darkStone "
              target="_self"
            >
              Site Overview
            </Link>
          </div>
        </nav>
        <div className="block m-auto mb-16 mt-9 md:hidden">
          <Image
            className="block m-auto lg:h-full"
            loader={({ src }) =>
              imageLoader({
                src: src,
                width: 672,
                quality: 90,
              })
            }
            src="https://media.foratravel.com/image/upload/v1715980779/Fora_Handwriting_Travel-by-Fora_Stacked_Stone_u91lur.png"
            alt="Travel By Fora"
            width={672}
            height={100}
          />
        </div>
      </div>
      <div className="hidden m-auto mt-2 mb-16 md:block">
        <Image
          className="block m-auto lg:h-full"
          loader={({ src }) =>
            imageLoader({
              src: src,
              width: 672,
              quality: 90,
            })
          }
          src="https://media.foratravel.com/image/upload/v1715980847/Fora_Handwriting_Travel-by-Fora_Sand_o0gbrs.png"
          alt="Travel By Fora"
          width={672}
          height={100}
        />
      </div>
    </footer>
  )
}
