import Image from 'next/legacy/image'
import { contentfulImageLoader } from '@/utils/ImageLoaders'

export type PerksListProps = {
  title: string
  titleStyle?: string
  listItems: string[]
  image: {
    url: string
    description: string
  }
  iconImage: {
    url: string
    description: string
  }
}

export default function PerksList({
  title,
  titleStyle,
  listItems,
  image,
  iconImage,
}: PerksListProps): JSX.Element {
  return (
    <section>
      <div>
        <div className="grid lg:grid-cols-2" data-name="perksList-wrapper">
          <div className="flex order-2 lg:w-4/5" data-name="perksList-content">
            <div className="min-w-full place-self-center">
              <div className="pb-8 lg:pb-4 ">
                {iconImage && (
                  <div
                    className="block w-12 mb-3"
                    data-name="perksList-iconImage"
                  >
                    <Image
                      loader={() =>
                        contentfulImageLoader({
                          src: iconImage.url,
                          width: 128,
                          height: 128,
                          quality: 90,
                          fit: 'thumb',
                        })
                      }
                      src={iconImage.url}
                      alt={iconImage.description || 'Icon'}
                      layout="responsive"
                      width={128}
                      height={128}
                      sizes="20vw"
                    />
                  </div>
                )}
                <h2
                  className={`pb-8 mb-4 border-b border-darkShell ${
                    titleStyle === 'Italic' ? 'fora-text-h3' : 'fora-text-h3'
                  } lg:pb-12 lg:mb-6`}
                >
                  {title}
                </h2>
                <ul className="list-disc list-outside">
                  {listItems &&
                    listItems.map((perk: string, i: number) => (
                      <span
                        key={`Perk_${i}`}
                        className="block pb-4 mb-4 border-b border-darkShell lg:pb-6 lg:mb-6"
                      >
                        <li className="ml-5 fora-text-body-1">{perk}</li>
                      </span>
                    ))}
                </ul>
              </div>
            </div>
          </div>
          <div
            className="pb-9 lg:order-2 lg:border-none"
            data-name="perksList-image"
          >
            <Image
              className=""
              loader={() =>
                contentfulImageLoader({
                  src: image.url,
                  width: 1200,
                  height: 1200,
                  quality: 90,
                  fit: 'fill',
                  format: 'webp',
                })
              }
              src={image.url}
              alt={image.description || `Fora - ${title}`}
              layout="responsive"
              width={1200}
              height={1200}
              sizes="50vw"
            />
          </div>
        </div>
      </div>
    </section>
  )
}
