import { ComponentGlobalBanner as ComponentGlobalBannerTypes } from 'types/generated/contentful-types'
import { RichTextContent } from '../design/RichTextContent'
import { fetchContent } from '@/utils/FetchContent'
import { ComponentGlobalBannerFragment } from './fragments'
import iconClose from '/public/images/icon-close-sand.svg'
import Image from 'next/legacy/image'
import { imageLoader } from '@/utils/ImageLoaders'
import { useEffect, useState } from 'react'
import { getCookie, setCookie, deleteCookie } from 'cookies-next'
import { SharedAdvisorContactCTAProps } from '../advisors/AdvisorCTAs'

export async function fetchGlobalBanner() {
  const date = new Date()

  const response = await fetchContent(`
  {
    componentGlobalBannerCollection(limit: 1, order: sys_publishedAt_DESC, where: {isActive: true  OR: [
      { expirationDate_gt: "${date.toISOString()}" }, 
      { expirationDate_exists: false }
    ]}) {
      items {
        ${ComponentGlobalBannerFragment}
      }
    }
  }
  `)

  return response.componentGlobalBannerCollection.items[0]
}

type ComponentGlobalBannerProps = ComponentGlobalBannerTypes & {
  advisor?: SharedAdvisorContactCTAProps
  handleBannerDisplay: (newValue: boolean) => void
}

export default function ComponentGlobalBanner({
  sys,
  isActive,
  backgroundColor,
  content,
  advisor,
  handleBannerDisplay,
}: ComponentGlobalBannerProps): JSX.Element {
  const [isClosed, setIsClosed] = useState<boolean>(true)

  useEffect(() => {
    const hasCookie = getCookie('banner-published-date')
    if (hasCookie) {
      setIsClosed(true)
      handleBannerDisplay(true)
    } else {
      setIsClosed(false)
      handleBannerDisplay(false)
    }
  }, [])

  useEffect(() => {
    if (sys.publishedAt !== getCookie('banner-published-date') && !advisor) {
      deleteCookie('banner-published-date')
      setIsClosed(false)
      handleBannerDisplay(false)
    }
  }, [sys.publishedAt])

  const onClose = () => {
    setCookie('banner-published-date', sys.publishedAt)
    setIsClosed(true)
    handleBannerDisplay(true)
  }

  if (isActive && !isClosed) {
    return (
      <section className="global-banner">
        <div
          style={{
            backgroundColor: backgroundColor ? backgroundColor : '#82A1AD',
          }}
          className="flex items-center justify-center py-3 px-10 lg:px-12 text-center min-h-[55px]"
        >
          <RichTextContent richText={content?.json} />
          <div
            tabIndex={0}
            role="button"
            className="absolute w-3 lg:w-3.5 cursor-pointer right-5 lg:right-[21px]"
            onClick={() => onClose()}
            onKeyDown={() => onClose()}
          >
            <Image
              loader={({ src }) =>
                imageLoader({
                  src: src,
                  width: 24,
                  quality: 90,
                })
              }
              src={iconClose}
              alt="Icon Close"
              width={24}
              height={24}
              layout="responsive"
              sizes="10vw"
            />
          </div>
        </div>
      </section>
    )
  } else {
    return <></>
  }
}
