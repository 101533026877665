import React, { useState, useEffect } from 'react'
import { useRouter } from 'next/router'
import { Button } from '@/components/ui/Buttons'
import { isEmailValid } from '@/utils/Helpers'
import { imageLoader } from '@/utils/ImageLoaders'
import Image from 'next/legacy/image'
import IconCheck from '/public/images/icon-check.svg'
import { getCookie } from 'cookies-next'
import { useLocalStorage } from 'hooks/useLocalStorage'
import { ComponentForm } from 'types/generated/contentful-types'
import {
  ForaConversionComponentNames,
  getReferralHistory,
  getURLParameters,
  trackConversionEvent,
} from 'analytics/ForaAnalytics'

export type GetMatchedFormProps = Pick<
  ComponentForm,
  'title' | 'teasercopy' | 'buttonText' | 'successText'
> & {
  onSubmit?: () => void
  state?: string
  conversionComponentName: ForaConversionComponentNames
}

enum FormState {
  INITIAL = 'initial',
  SUCCESS = 'success',
  ERROR = 'error',
}

export function GetMatchedForm({
  onSubmit,
  state,
  title,
  teasercopy,
  buttonText,
  conversionComponentName,
  successText,
}: GetMatchedFormProps): JSX.Element {
  const router = useRouter()
  const [pageReferralHistory] = useLocalStorage<string[]>('page-referrals', [])
  const [emailAddressInput, updateEmailAddress] = useState<string>('')
  const [firstNameInput, updateFirstName] = useState<string>('')
  const [lastNameInput, updateLastName] = useState<string>('')
  const [commentsInput, updateComments] = useState<string>('')

  const [formState, updateFormState] = useState<FormState>(FormState.INITIAL)
  const [validInput, setValidInput] = useState<boolean>(true)
  const [submissionSuccess, setSubmissionSuccess] = useState<boolean>(false)
  const isValidInput =
    isEmailValid(emailAddressInput) &&
    firstNameInput &&
    lastNameInput &&
    commentsInput
  const [fbpCookie, fbcCookie, ttpCookie, hubspotCookie] = [
    getCookie('_fbp'),
    getCookie('_fbc'),
    getCookie('_ttp'),
    getCookie('hubspotutk'),
  ]

  const handleEmail = (value: string) => {
    setValidInput(true)
    updateEmailAddress(value)
  }

  const handleOnBlur = (value: string) => {
    if (value) setValidInput(isEmailValid(value))
  }

  useEffect(() => {
    if (state === 'success') updateFormState(FormState.SUCCESS)
    if (state === 'formError') updateFormState(FormState.ERROR)
    if (state === 'emailError') setValidInput(false)
  }, [state])

  const submitForm = () => {
    const { foraLastSlug1, foraLastSlug2, foraLastSlug3 } =
      getReferralHistory(pageReferralHistory)

    const { campaign, content, fbclid, gclid, medium, source, term, ttclid } =
      getURLParameters(router.query)

    fetch(`/api/forms/match-advisor`, {
      method: 'post',
      body: JSON.stringify({
        email: emailAddressInput,
        firstName: firstNameInput,
        lastName: lastNameInput,
        comments: commentsInput,
        pageURL: router.asPath,
        metadata: {
          fbclid,
          gclid,
          ttclid,
          fbp: fbpCookie,
          fbc: fbcCookie,
          ttp: ttpCookie,
          hubspotutk: hubspotCookie,
          utm: {
            source,
            medium,
            campaign,
            term,
            content,
          },
        },
      }),
    })
      .then((response) =>
        response.json().then((data) => {
          if (data.status === 'success' && response.status === 200) {
            updateFormState(FormState.SUCCESS)
            setSubmissionSuccess(true)
            if (onSubmit) onSubmit()
            trackConversionEvent({
              componentName: conversionComponentName,
              foraFormEmail: emailAddressInput,
              foraFormFirstName: firstNameInput,
              foraFormLastName: lastNameInput,
              foraFormComments: commentsInput,
              fbc: fbcCookie?.toString() || '',
              fbp: fbpCookie?.toString() || '',
              hubspotCookie: hubspotCookie?.toString() || '',
              ttp: ttpCookie?.toString() || '',
              foraLastSlug1,
              foraLastSlug2,
              foraLastSlug3,
              fbclid,
              gclid,
              ip: data.request_ip,
              label: 'Advisor Match',
              ttclid,
              pagePath: router.pathname,
            })
          } else {
            updateFormState(FormState.ERROR)
          }
        })
      )
      .catch(() => updateFormState(FormState.ERROR))
  }

  return (
    <>
      <div className="flex justify-center px-8 py-8 text-center md:py-14 bg-shell">
        <div className="max-w-2xl">
          <div className="text-center">
            <h2 className="pb-4 md:pb-5 fora-text-h4">
              {title || 'Get matched with a travel advisor'}
            </h2>
            <p className="pb-6 fora-text-body-1">
              {teasercopy ||
                'Want to travel but don’t know where to begin? Reach out and we’ll match you with an advisor based on your style and preferences.'}
            </p>
          </div>
          <input
            className={`w-full p-5 bg-transparent border outline-none fora-text-button-2 placeholder-darkStone normal-case ${
              !validInput ? 'border-red text-red mb-2' : 'mb-4 md:mb-5'
            }`}
            type="text"
            id="match-advisor-email"
            autoComplete="email"
            value={emailAddressInput}
            onChange={(event) => handleEmail(event.target.value)}
            onBlur={(event) => handleOnBlur(event.target.value)}
            placeholder="*Email Address"
          />
          {!validInput && (
            <div className="mb-4 text-left error-message">
              <p>Invalid email address</p>
            </div>
          )}
          <div className="md:flex md:gap-4">
            <input
              className="w-full p-5 mb-4 normal-case bg-transparent border outline-none md:mb-5 lg:w-1/2 fora-text-button-2 placeholder-darkStone"
              type="text"
              id="match-advisor-first-name"
              autoComplete="given-name"
              value={firstNameInput}
              onChange={(event) => updateFirstName(event.target.value)}
              placeholder="*First Name"
            />
            <input
              className="w-full p-5 mb-4 normal-case bg-transparent border outline-none md:mb-5 lg:w-1/2 fora-text-button-2 placeholder-darkStone"
              type="text"
              id="match-advisor-last-name"
              autoComplete="family-name"
              value={lastNameInput}
              onChange={(event) => updateLastName(event.target.value)}
              placeholder="*Last Name"
            />
          </div>
          <textarea
            className="w-full px-5 pt-5 pb-4 mb-4 normal-case bg-transparent border outline-none resize-none border-blackSand fora-text-button-2 placeholder-darkStone"
            id="match-advisor-comments"
            value={commentsInput}
            onChange={(event) => updateComments(event.target.value)}
            placeholder="*Please include as many details as possible to help us make the best match"
            rows={4}
          />
          {formState !== FormState.SUCCESS && (
            <Button
              buttonFn={() => submitForm()}
              text={buttonText || 'REQUEST A MATCH'}
              theme="primary"
              isDisabled={!isValidInput || submissionSuccess}
            />
          )}
          {formState === FormState.SUCCESS && (
            <div className="flex my-2.5 text-sm uppercase justify-center py-1 px-5">
              <div className="mr-2.5 flex align-bottom">
                <Image
                  loader={({ src }) =>
                    imageLoader({
                      src: src,
                      width: 15,
                      quality: 90,
                    })
                  }
                  src={IconCheck}
                  alt="Icon Check"
                  width={15}
                  height={15}
                />
              </div>
              <p>
                {successText || 'Thank you! your submission has been sent.'}
              </p>
            </div>
          )}
          {formState === FormState.ERROR && (
            <div className="block my-2.5 error-message">
              <p>There was an error. Please try again.</p>
            </div>
          )}
        </div>
      </div>
    </>
  )
}
