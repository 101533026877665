import Image from 'next/legacy/image'
import { RichTextContent } from '@/components/design/RichTextContent'
import { contentfulImageLoader } from '@/utils/ImageLoaders'
import { Hotels, Maybe } from 'types/generated/contentful-types'

export type HotelProps = Omit<Hotels, '_id'> & {
  showImage?: boolean
}

export function Hotel({
  title,
  image,
  showImage = true,
  description,
  perks,
}: HotelProps): JSX.Element {
  return (
    <section className="hotel-card" data-name="hotel-card">
      {image && showImage && (
        <div className="pb-2 md:pb-3">
          <Image
            loader={() =>
              contentfulImageLoader({
                src: image.url ?? '',
                width: 492,
                height: 328,
                quality: 90,
                fit: 'fill',
                format: 'webp',
              })
            }
            src={image.url ?? ''}
            alt={image.description || `${title}`}
            layout="responsive"
            width={492}
            height={328}
            sizes="40vw"
            data-name="hotel-image"
          />
        </div>
      )}
      <h3
        data-name="hotel-title"
        className="pb-2 fora-text-h4 md:fora-text-h7 md:pb-3"
      >
        {title}
      </h3>
      {description?.json && (
        <div
          data-name="hotel-description"
          className="mb-6 md:mb-8 hotel-description-richtext"
        >
          <RichTextContent richText={description.json} />
        </div>
      )}
      {perks?.length && perks?.length > 0 && (
        <div>
          <h5 className="pb-2 mb-0 font-semibold uppercase fora-text-h9">
            Fora Perks
          </h5>
          <ul>
            {perks.map((perk: Maybe<string>, i: number) => {
              return (
                <li
                  data-name={`hotel-perk-${i}`}
                  key={`Perk_${i}`}
                  className="pb-2"
                >
                  &#9733;
                  <p className="inline pl-2 fora-text-body-2 text-darkStone">
                    {perk}
                  </p>
                </li>
              )
            })}
          </ul>
        </div>
      )}
    </section>
  )
}
