import { SwiperContainer, register } from 'swiper/element/bundle'
import React, { useEffect, useRef, useState } from 'react'
import type { SwiperOptions } from 'swiper/types/swiper-options'

register()

declare global {
  // eslint-disable-next-line @typescript-eslint/no-namespace
  namespace React.JSX {
    interface IntrinsicElements {
      'swiper-container': React.DetailedHTMLProps<
        React.HTMLAttributes<HTMLElement> & SwiperOptions,
        HTMLElement
      >
      'swiper-slide': React.DetailedHTMLProps<
        React.HTMLAttributes<HTMLElement>,
        HTMLElement
      >
    }
  }
}

interface ForaSwiperProps {
  params: SwiperOptions
  className?: string
  withNumberedPagination?: boolean
  children: React.ReactNode
  setSwiperRef?: (ref: SwiperContainer) => void
}

const ForaSwiper = ({
  params,
  className,
  children,
  setSwiperRef,
  withNumberedPagination = false,
}: ForaSwiperProps): JSX.Element => {
  const swiperRef = useRef<SwiperContainer>(null)
  const [currentSlide, setCurrentSlide] = useState<number>(1)
  useEffect(() => {
    if (swiperRef.current) {
      if (withNumberedPagination) {
        params.on = {
          slideChange(swiper) {
            setCurrentSlide(swiper.activeIndex + 1)
          },
        }
      }
      params.injectStyles = [
        ...(params.injectStyles ?? []),
        `
        .swiper-button-next svg,
        .swiper-button-prev svg {
          display: none; 
        }
        `,
      ]
      Object.assign(swiperRef.current, params)
      swiperRef.current.initialize()
      if (setSwiperRef) {
        setSwiperRef(swiperRef.current)
      }
    }
  }, [])

  return (
    <div className={`relative ${className}`}>
      <swiper-container ref={swiperRef} init={false}>
        {Array.isArray(children) &&
          children.map((child, index) => (
            <swiper-slide key={`swiper-slide${index}`}>{child}</swiper-slide>
          ))}
      </swiper-container>
      {withNumberedPagination && (
        <div className="hidden top-[-60px] lg:block slick-steps fora-text-h8">
          {currentSlide} / {React.Children.count(children)}
        </div>
      )}
    </div>
  )
}

export default ForaSwiper
