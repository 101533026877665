import { INLINES, BLOCKS } from '@contentful/rich-text-types'
import {
  EmbeddedAdvisorCard,
  EmbeddedItineraryTripReportCard,
} from '../blogs/EmbeddedCards'
import { documentToReactComponents } from '@contentful/rich-text-react-renderer'
import HeroBlock from '../pageBlocks/HeroBlock'
import LearnMoreBlock from '../pageBlocks/LearnMoreBlock'
import PerksList from '../pageBlocks/PerksList'
import TwoColumnCTA from '../pageBlocks/TwoColumnCTA'
import PartnersBanner from '../itineraries/Partners'
import FeaturedHotels from '../pageBlocks/FeaturedHotels'
import { useRouter } from 'next/router'
import { ContentfulImage } from '@/utils/ImageLoaders'
import ImageSideBySide from '../pageBlocks/ImageSideBySide'
import ComponentImage from '../pageBlocks/ComponentImage'
import ComponentThreeIconSectionWithPicture from '../pageBlocks/ComponentThreeIconSectionWithPicture'
import ComponentButtonCta from '../ui/ComponentButtonCta'
import {
  ImageSideBySideFragment,
  ComponentImageFragment,
} from '../pageBlocks/fragments'

export type RichTextContentProps = any

type optionsProps = {
  links: any
  isCampaign: boolean
  jotformId: string
  router: any
}

export const getRichTextContentFragment = ({ includeLinks = false }) => {
  return `
    json
    ${
      includeLinks
        ? `
    links {
      entries {
        block {
          __typename
          sys {
            id
          }
          ... on ComponentImageSideBySide {
            ${ImageSideBySideFragment}
          }
          ... on ComponentImage {
            ${ComponentImageFragment}
          }
          ...on Advisors {
            title
            slug
            firstName
            image {
              url
            }
            expertise
            advisorVariant
          }
          ...on Itineraries {
            title
            slugId
            seoSlug
            image {
              url
            }
            advisor {
              title
              image {
                url
              }
            cloudinaryImage
            }
          }
        }                 
      }
      assets {
        block {
          sys {
            id
          }
          url
          width
          height
          title
          description
        }
      }
    }
    `
        : ''
    }
  `
}

const options = ({ links, isCampaign, jotformId, router }: optionsProps) => {
  return {
    // Preserve newlines in rich text content by adding <br/> tags.
    renderText: (text) => {
      return text.split('\n').reduce((children, textSegment, index) => {
        return [...children, index > 0 && <br key={index} />, textSegment]
      }, [])
    },
    renderNode: {
      [INLINES.HYPERLINK]: (node: any) => {
        if (
          node.data.uri.includes('youtube.com/embed') ||
          node.data.uri.includes('youtube-nocookie.com/embed')
        ) {
          return (
            <div className="flex justify-center">
              <iframe
                className="youtube-embed"
                title="Youtube video player"
                width="840"
                height="472"
                src={node.data.uri}
                frameBorder="0"
                allow="accelerometer; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                allowFullScreen
              />
            </div>
          )
        } else {
          return (
            <a
              href={node.data.uri}
              target={
                router?.basePath?.includes(node.data.uri) ? '_self' : '_blank'
              }
              rel="noreferrer"
            >
              {node.content[0].value}
            </a>
          )
        }
      },
      [BLOCKS.EMBEDDED_ENTRY]: (node: any) => {
        let response = <></>
        links.entries.block.forEach((entry: any) => {
          if (entry && node.data.target.sys.id == entry.sys.id) {
            switch (entry.__typename) {
              case 'PagesHeroBlock':
                response = (
                  <div>
                    <HeroBlock jotformId={jotformId} {...entry} />
                  </div>
                )
                break
              case 'ComponentThreeIconSectionWithPicture':
                response = (
                  <div>
                    <ComponentThreeIconSectionWithPicture {...entry} />
                  </div>
                )
                break
              case 'PagesLearnMoreBlock':
                response = (
                  <div>
                    <LearnMoreBlock {...entry} />
                  </div>
                )
                break
              case 'ComponentImageSideBySide':
                response = (
                  <div>
                    <ImageSideBySide {...entry} />
                  </div>
                )
                break
              case 'ComponentImage':
                response = (
                  <div>
                    <ComponentImage {...entry} />
                  </div>
                )
                break
              case 'PagesPerksList':
                response = (
                  <div>
                    <PerksList {...entry} />
                  </div>
                )
                break
              case 'PagesPressBanner':
                response = (
                  <div>
                    <PartnersBanner
                      {...entry}
                      isCampaign={isCampaign}
                      content={entry.logosCollection.items}
                    />
                  </div>
                )
                break
              case 'PagesTwoColumnCta':
                response = (
                  <div>
                    <TwoColumnCTA {...entry} jotformId={jotformId} />
                  </div>
                )
                break
              case 'Advisors':
                response = (
                  <div className="max-w-xl pb-8 m-auto lg:pb-14">
                    <EmbeddedAdvisorCard {...entry} />
                  </div>
                )
                break
              case 'Itineraries':
              case 'EntryTripReport':
                response = (
                  <div className="max-w-xl pb-8 m-auto lg:pb-14">
                    <EmbeddedItineraryTripReportCard {...entry} />
                  </div>
                )
                break
              case 'PagesFeaturedHotels':
                response = (
                  <div className="featured-hotels">
                    <FeaturedHotels {...entry} />
                  </div>
                )
                break
              case 'ComponentButtonCta':
                response = (
                  <div>
                    <ComponentButtonCta {...entry} />
                  </div>
                )
                break
              default:
                response
            }
          }
        })
        return response
      },
      [BLOCKS.HEADING_1]: (node: any, children: any) => (
        <h1 className={isCampaign ? 'my-8 text-center' : ''}>{children}</h1>
      ),
      [BLOCKS.HEADING_2]: (node: any, children: any) => (
        <h2 className={isCampaign ? 'my-8 text-center' : ''}>{children}</h2>
      ),
      [BLOCKS.HEADING_3]: (node: any, children: any) => (
        <h3 className={isCampaign ? 'my-8 text-center' : ''}>{children}</h3>
      ),
      [BLOCKS.PARAGRAPH]: (node: any, children: any) => (
        <p className={isCampaign ? 'text-center' : ''}>{children}</p>
      ),
      [BLOCKS.TABLE]: (node: any, children: any) => (
        <table className="table-fixed">{children}</table>
      ),
      [BLOCKS.TABLE_HEADER_CELL]: (node: any, children: any) => (
        <th className="px-4 py-2 odd:w-[40%] even:w-[60%] border">
          {children}
        </th>
      ),
      [BLOCKS.TABLE_CELL]: (node: any, children: any) => (
        <td className="px-4 py-2 border">{children}</td>
      ),
      [BLOCKS.EMBEDDED_ASSET]: (node: any) => {
        let response = <></>
        links.assets.block.forEach((entry: any) => {
          if (entry && node.data.target.sys.id == entry.sys.id) {
            response = (
              <div className="pb-6 md:pb-8">
                <ContentfulImage asset={entry} />
              </div>
            )
          }
        })
        return response
      },
    },
  }
}

export function RichTextContent({
  richText,
  isCampaign,
}: RichTextContentProps): JSX.Element {
  let richContent, richLinks
  const router = useRouter()

  isCampaign
    ? (richContent = richText.richTextContent.json || richText.richTextContent)
    : (richContent = richText.json || richText)

  isCampaign
    ? (richLinks = richText.richTextContent?.links)
    : (richLinks = richText.links)
  return (
    <div className="global-richtext">
      {documentToReactComponents(
        richContent,
        options({
          links: richLinks,
          isCampaign,
          jotformId: richText.jotformId,
          router,
        })
      )}
    </div>
  )
}
